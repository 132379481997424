import React, { useEffect } from "react";
import ReportsOverview from "../../components/ReportsAnalytics/Overview";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import GeneraterReports from "../../components/ReportsAnalytics/GeneraterReports";
import Products from "../../components/ReportsAnalytics/Products";
import { useTranslation } from "react-i18next";

const Reports = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useNavigate();

  const goToCreateClient = () => {
    history("/patner-creation");
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between gap-3 my-3 align-items-center w-100">
        <div className="d-flex flex-row align-items-center gap-3">
          <span className="font-40 font-bold mb-2">{t("Reports")}</span>
        </div>
        {/* <div className="d-flex">
          <Button
            type="primary"
            text="+ CREATE A CLIENT"
            onClick={() => goToCreateClient()}
          />
        </div> */}
      </div>

      <div className="d-flex flex-column justify-content-start gap-3">
        <div className="d-flex flex-row gap-2">
          <div className="width-65">
            <ReportsOverview />
          </div>
          <div className="width-35">
            <GeneraterReports />
          </div>
        </div>
        <div className="d-flex">
          <Products lang={props?.lang}/>
        </div>
      </div>
    </div>
  );
};

export default Reports;
