export const FETCH_ALL_ORGANIZATION_REQUEST = "FETCH_ALL_ORGANIZATION_REQUEST"
export const FETCH_ALL_ORGANIZATION_SUCCESS = "FETCH_ALL_ORGANIZATION_SUCCESS"
export const FETCH_ALL_ORGANIZATION_FAILURE = "FETCH_ALL_ORGANIZATION_FAILURE"

export const POST_ALL_ORGANIZATION_REQUEST = "POST_ALL_ORGANIZATION_REQUEST"
export const POST_ALL_ORGANIZATION_SUCCESS = "POST_ALL_ORGANIZATION_SUCCESS"
export const POST_ALL_ORGANIZATION_FAILURE = "POST_ALL_ORGANIZATION_FAILURE"

export const POST_ORGANIZATION_RESPONSE = "POST_ORGANIZATION_RESPONSE"

export const PATCH_ORGANIZATION_REQUEST = "PATCH_ORGANIZATION_REQUEST"
export const PATCH_ORGANIZATION_SUCCESS = "PATCH_ORGANIZATION_SUCCESS"
export const PATCH_ORGANIZATION_FAILURE = "PATCH_ORGANIZATION_FAILURE"

export const SET_SELECTED_ORGANIZATION = "SET_SELECTED_ORGANIZATION"
export const SET_SELECTED_GIFTCARD = "SET_SELECTED_Organization"

export const POST_ORGANIZATION_MAPPING_REQUEST = "POST_ORGANIZATION_MAPPING_REQUEST"
export const POST_ORGANIZATION_MAPPING_SUCCESS = "POST_ORGANIZATION_MAPPING_SUCCESS"
export const POST_ORGANIZATION_MAPPING_FAILURE = "POST_ORGANIZATION_MAPPING_FAILURE"

export const POST_ORGANIZATION_MAPPING_FRESPONSE = "POST_ORGANIZATION_MAPPING_FAILURE"

export const POST_ORGANIZATION_MER_MAPPING_REQUEST = "POST_ORGANIZATION_MER_MAPPING_REQUEST"
export const POST_ORGANIZATION_MER_MAPPING_SUCCESS = "POST_ORGANIZATION_MER_MAPPING_SUCCESS"
export const POST_ORGANIZATION_MER_MAPPING_FAILURE = "POST_ORGANIZATION_MER_MAPPING_FAILURE"
// new get organization from partner_category 
export const GET_ALL_ORGANIZATION_REQUEST = "GET_ALL_ORGANIZATION_REQUEST"
export const GET_ALL_ORGANIZATION_SUCCESS = "GET_ALL_ORGANIZATION_SUCCESS"
export const GET_ALL_ORGANIZATION_FAILURE = "GET_ALL_ORGANIZATION_FAILURE"

export const DELETE_ORGANIZARION_LIST = "DELETE_ORGANIZARION_LIST"


export const GET_ORGANIZARION_MAPPED_GIFTCARD_REQUEST = "GET_ORGANIZARION_MAPPED_GIFTCARD_REQUEST"
export const GET_ORGANIZARION_MAPPED_GIFTCARD_SUCCESS = "GET_ORGANIZARION_MAPPED_GIFTCARD_SUCCESS"
export const GET_ORGANIZARION_MAPPED_GIFTCARD_FAILURE = "GET_ORGANIZARION_MAPPED_GIFTCARD_FAILURE"
export const GET_ORGANIZARION_MAPPED_GIFTCARD_DELETE = "GET_ORGANIZARION_MAPPED_GIFTCARD_DELETE"

export const ORGANIZARION_FILTER_DATA = "ORGANIZARION_FILTER_DATA"
export const CLEAR_ORGANIZATION_LIST = "CLEAR_ORGANIZATION_LIST"
