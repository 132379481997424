import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../assests/svg/Success.svg";
import Button from "../../components/Button/Button";
import { getUserList } from "../../Redux/UserManagement/userAction";
import { useTranslation } from "react-i18next";

const UserUpdateSuccess = () => {
    const history = useNavigate();
    const dispatch=useDispatch();
    const { t } = useTranslation();
    const rolesState = useSelector(
        (state) => state.users.userUpdateResponse
    );
    //   useEffect(() => {
    //     if (rolesState === null) {
    //       history("/users-management/users");
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [rolesState]);

    useEffect(() => {
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolesState]);

    const goToDashboard = () => {
        history("/users-management/users")
        dispatch(getUserList());
    }
    return (
        <div className="d-flex flex-column  align-item-center bg-light w-100 py-5">
            <div className="d-flex flex-row w-100 justify-content-center">
                <SuccessIcon />
            </div>
            <div className="d-flex flex-row w-100 justify-content-center mt-2">
                <span className="text-dark font-bold font-40">
                    {t("User updated successfully")}
                </span>
            </div>
            <div className="d-flex flex-row w-100 justify-content-center mt-2">
                <span className="text-muted font-bold font-15">
                    {t("User ID")}: {rolesState?.id}
                </span>
            </div>
            <div className="d-flex flex-row w-100 justify-content-center mt-2">
                <Button type={"primary"} text={t("View Dashboard")} onClick={goToDashboard} />
            </div>
        </div>
    )
}
export default UserUpdateSuccess