import axios from 'axios'
import { baseUrl, getToken } from '../../utils/prodURL'
import {
  GET_ACTIVATION_REQUIRED_REQUEST,
  GET_ACTIVATION_REQUIRED_SUCCESS,
  GET_ACTIVATION_REQUIRED_FAILURE
} from './activationType'

export const getActivationRequest = () => {
  return {
    type: GET_ACTIVATION_REQUIRED_REQUEST
  }
}
export const getActivationSuccess = (activations) => {
  return {
    type: GET_ACTIVATION_REQUIRED_SUCCESS,
    payload: activations
  }
}
export const getActivationFailure = (error) => {
  return {
    type: GET_ACTIVATION_REQUIRED_FAILURE,
    payload: error
  }
}

export const getAllActivations = () => {
  return (dispatch) => {
    dispatch(getActivationRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/activation_required`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    //   console.log(config);
    axios(config)
      .then((response) => {
        //console.log("response" + JSON.stringify(response.data));
        dispatch(getActivationSuccess(response.data))
      })
      .catch((error) => {
        //       const errorMsg = error;
        dispatch(
          getActivationFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};