import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ReactComponent as SuccessIcon } from "../../assests/svg/Success.svg";
import { useTranslation } from "react-i18next";

const FloatSuccess = () => {
  const history = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToDashboard = () => {
    history("/float/float-transactions");
  };


  return (
    <div className="d-flex flex-column  align-item-center bg-light w-100 gap-2">
      <div className="d-flex flex-row w-100 justify-content-center mt-5">
        <SuccessIcon />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-dark font-bold font-40">
          {t("Float map successfully")}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"primary"} text={t("Go to dashboard")} onClick={() => goToDashboard()} />
      </div>

      {/* <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"rounded"} text={"Map client to product"} onClick={() => goToClientMap()} />
      </div> */}
    </div>
  );
};

export default FloatSuccess;
