import React from "react";
import { Modal } from 'react-bootstrap';

const ModalComp = (props) => {
    const { size, showModal, handleClose, component } = props;


    return (
        <div className="d-flex justify-content-center">
            <Modal
                show={showModal}
                onHide={handleClose}
                size={size}
                backdrop={'static'}
            >
                {component}
            </Modal>
        </div>
    );
}

export default ModalComp