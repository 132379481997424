/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import Select from "react-select";
import { eposStoreDataSave, getStoreListMapToOrganization } from "../../../Redux/CreateStore/createStoreAction";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationList } from "../../../Redux/Organizations/organizationAction";
import { getGiftcardDetails, setStoreDetails } from "../../../Redux/Epos/eposAction";
import Toasts from "../../../components/Toasts/Toasts";
import PulseLoader from "../../../components/Loader/PulseLoader";
import { useTranslation } from "react-i18next";

const SelectStore = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();

  const inputElementOrg = useRef(null);
  const inputElementStore = useRef(null);
  const inputElementGiftcardNo = useRef(null);

  const organizationState = useSelector((state) => state?.organizations?.organizationList);
  const storeListByOrgnazation = useSelector((state) => state.createStore?.storeListByOrgnazationId);
  const storeState = useSelector((state) => state.eposState?.storeDetails);
  const loading = useSelector((state) => state.eposState?.loading);

  const [fields, setFields] = useState({
    organization: storeState?.organization ? storeState?.organization : "",
    store: storeState?.selectedStore ? storeState?.selectedStore : "",
    giftCardNumber: storeState?.giftCardNumber ? storeState?.giftCardNumber : "",
  });

  const [usertype, setusertype] = useState();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("")

  const [error, setError] = useState({
    organization: "",
    store: "",
    giftCardNumber: "",
    errorCode: "",
    successCode: ""
  });

  const toggleShowToast = () => setShowToast(!showToast);



  useEffect(() => {
    setusertype(JSON.parse(localStorage.getItem("user")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (usertype?.user_type === "organization" || usertype?.user_type === "organization_store") {
      // usertype?.type_id && dispatch(getClientDetailById(usertype?.type_id));
      usertype?.type_id && dispatch(getStoreListMapToOrganization(usertype?.type_id));
    } else {
      dispatch(getOrganizationList());
    }
  }, [usertype]);

  useEffect(() => {
    if (usertype?.user_type === "organization_store" || usertype?.user_type === "organization") {
      organizationState && organizationState.map((item) => {
        if (usertype?.type_id === item?.id) {
          setFields({ ...fields, organization: item });
        }
      })
    }
  }, [organizationState]);

  useEffect(() => {

    if (usertype?.user_type === "organization_store") {
      storeListByOrgnazation && storeListByOrgnazation.map((item) => {
        if (usertype?.sub_type_id === item?.id) {
          setFields({ ...fields, store: item });
        }
      })
    }
  }, [storeListByOrgnazation]);


  const changeHandler = (e) => {
    const value = e.target.value;
    setFields({ ...fields, [e.target.name]: value });
    setError({ ...error, errorCode: "", successCode: "" })
  };

  const handleChangeOrganization = (e) => {
    setFields({ ...fields, organization: e, store: "" });
    e && dispatch(getStoreListMapToOrganization(e?.id));
  };

  const handleChangeStore = (e) => {
    setFields({ ...fields, store: e });
  };

  const validate = () => {
    if (!fields.organization) {
      setError({ organization: t("Please select organization") });
      if(inputElementOrg.current){
        inputElementOrg.current.focus()
      }
      return false;
    } else if (!fields.store) {
      setError({ store: t("Please select store") });
      if(inputElementStore.current){
        inputElementStore.current.focus()
      }
      return false;
    } else if (!fields.giftCardNumber) {
      setError({ giftCardNumber: t("Please enter gift card number" )});
      if(inputElementGiftcardNo.current){
        inputElementGiftcardNo.current.focus()
      }
      return false;
    }

    return true;
  };

  const handlerError = (errorCode, successCode) => {
    if (successCode === 200) {
      setError({ ...error, errorCode: "", successCode: t("Your giftcard verify" )})
      setToastMessage("Your giftcard is verify")
      toggleShowToast()
      afterGiftcardNoVerify()
    } else if (errorCode === 404) {
      setError({ ...error, errorCode: t("Giftcard not found"), successCode: "" })
      setToastMessage("Giftcard not found")
      toggleShowToast()
    }
  }

  const submit = () => {
    let obj = {
      organization: fields.organization,
      selectedStore: fields.store,
      giftcardNumber: fields.giftCardNumber,
    }
    dispatch(eposStoreDataSave(obj))

    let val = validate();
    if (val) {
      dispatch(setStoreDetails(obj));
      dispatch(getGiftcardDetails(fields.giftCardNumber, handlerError))
      // history("/epos/selectstore-model", { state: { store: obj } });
    }
  };

  const afterGiftcardNoVerify = () => {
    let obj = {
      organization: fields.organization,
      selectedStore: fields.store,
      giftcardNumber: fields.giftCardNumber,
    }
    return history("/epos/selectstore-model", { state: { store: obj } });
  }


  return (
    <>
      {loading === false ? (
        <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row align-items-center pb-2 gap-3">
              <LeftIcon onClick={() => history(-1)} className="cursor" />
              <span className="font-bold font-32 my-2">{t("Store")}</span>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between gap-3">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65 gap-3">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Organization")}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Select Organization"}
                  />
                </div>
                <div className="d-flex flex-column gap-1">
                  <Select
                  ref={inputElementOrg}
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select organization")}
                    getOptionLabel={(e) => e.partner_name}
                    options={organizationState}
                    styles={CUSTOM_DROPDOWN_STYLE}
                    value={fields.organization}
                    onChange={handleChangeOrganization}
                    isClearable
                    isDisabled={usertype?.user_type === "organization_store" || usertype?.user_type === "organization" ? true : false}
                  />
                  {!fields.organization && error.organization ? (
                    <label className="text-error">{error.organization}</label>
                  ) : null}
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Store")}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Select Store"}
                  />
                </div>
                <div className="d-flex flex-column gap-1">
                  <Select
                  ref={inputElementStore}
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select store")}
                    getOptionLabel={(e) => e.store_name}
                    options={storeListByOrgnazation}
                    styles={CUSTOM_DROPDOWN_STYLE}
                    value={fields.store}
                    onChange={handleChangeStore}
                    isClearable
                    isDisabled={usertype?.user_type === "organization_store" ? true : false}
                  />
                  {!fields.store && error.store ? (
                    <label className="text-error">{error.store}</label>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-column gap-4 ">
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Enter giftcard number")}
                      <span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Enter giftcard number"}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <InputField
                    inputRef={inputElementGiftcardNo}
                      name="giftCardNumber"
                      type="text"
                      value={fields?.giftCardNumber}
                      onChange={changeHandler}
                    />
                    {!fields.giftCardNumber && error.giftCardNumber ? (
                      <label className="text-error">{error.giftCardNumber}</label>
                    ) : null}
                  </div>
                </div>

                {/* <div className="d-flex flex-row align-items-center ">
              <ScanIcon />
              <span className="font-15 font-bold text-light-blue cursor">
                 <u>Scan gift card number</u>
              </span>
            </div> */}
              </div>
            </div>
            <div
              className="position-fixed bottom-0 start-50 translate-middle-x w-100 mb-2"
              id="liveToast"
            >
              <Toasts
                position={"bottom-center"}
                showToast={showToast}
                toggleShowToast={toggleShowToast}
                warningMsg={toastMessage}
                imageType={"warning"}
              />
            </div>
          </div>

          <div className="d-flex py-1">
            <Button onClick={submit} text={t("Submit")} type="primary" />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )
      }
    </>
  );
};

export default SelectStore;
