import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import SuccessImg from "../../assests/img/success.png";
import WarningImg from "../../assests/img/warning.png";
import ErrorImg from "../../assests/img/error.png";
import signinWarning from '../../assests/img/signinWarning.png'

const SignInToast = (props) => {
  const {
    showToast,
    toggleShowToast,
    position,
    type,
    successMsg,
    warningMsg,
    errorMsg,
    imageType,
  } = props;

  return (
    <>
      <ToastContainer className="p-1" position={position}>
        <Toast
          show={showToast}
          onClose={() => toggleShowToast()}
          delay={3000}
          autohide
          type={type}
        >
          <div className="d-flex flex-row align-items-center mx-4 my-3 gap-3 ">
            <div className="notification-image">
              <img
                src={
                  imageType === "success"
                    ? SuccessImg
                    : imageType === "warning"
                    ? signinWarning
                    : imageType === "error"
                    ? ErrorImg
                    : ""
                }
                alt=""
              />
            </div>
            <div className="d-flex flex-column ">
              <span className="font-14 font-bold">
                {successMsg
                  ? successMsg
                  : warningMsg
                  ? warningMsg
                  : errorMsg
                  ? errorMsg
                  : ""}
              </span>
            </div>
          </div>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default SignInToast;
