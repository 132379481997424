/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import InputField from "../../components/InputField/InputField";
import InputGroupTxt from "../../components/InputField/InputGroupTxt";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import { useDispatch, useSelector } from "react-redux";
import DragandDrop from "../DragandDrop/DragandDrop";
import ReactSwitchButton from "../Button/ReactSwitchButton";
import { fetchAllCountrieDetails } from "../../Redux/Countries/countryAction";
import {
  putPartner,
  fetchPartnerCategory,
} from "../../Redux/Partner/partnerAction";
import {
  GIFTCARD_REDEMPTION_METHOD,
  PRODUCT_TYPE,
} from "../../utils/ConstantData";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import Select from "react-select";
import Toasts from "../Toasts/Toasts";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import PulseLoader from "../Loader/PulseLoader";
import { useTranslation } from "react-i18next";

const CreatePatner = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputElementName = useRef(null);
  const inputElementCountry = useRef(null);
  const inputElementAddress1 = useRef(null);
  const inputElementAddress2 = useRef(null);
  const inputElementEmail = useRef(null);
  const inputElementPassword = useRef(null);

  const countries = useSelector((state) => state?.countries?.countries);
  const loader = useSelector((state) => state?.partners?.loading);
  const states = useSelector(
    (state) => state?.countries?.countrieDetails?.states
  );

  const partnerData = useSelector((state) => state.partners?.createpartnerData);
  const partnerCategory = useSelector(
    (state) => state.partners?.partnerCategory
  );
  // for toast
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);
  // to hide and show password
  const [passShowHide, setPassShowHide] = useState(false);

  const togglePassword = () => {
    setPassShowHide((prevState) => !prevState);
  };

  const [dropDownObj, setDropDownObj] = useState({
    country: partnerData ? partnerData.country : "",
    state: partnerData ? partnerData.state : "",
    partner: partnerData ? partnerData.partner : "",
    product_type: partnerData ? partnerData.product_type : "",
    currency: partnerData ? partnerData.currency : "",
    countryCurrency: [],
    redemptionMethod: partnerData ? partnerData?.redemptionMethod : "",
  });

  const [imgFiles, setImgFiles] = useState([]);
  const [patnerObj, setPatnerObj] = useState({
    city: partnerData ? partnerData.city : "",
    name: partnerData?.name ? partnerData?.name : "",
    buildingName: partnerData?.buildingName ? partnerData?.buildingName : "",
    streetAddress: partnerData ? partnerData?.streetAddress : "",
    landmark: partnerData ? partnerData?.landmark : "",
    zipcode: partnerData ? partnerData?.zipcode : "",
    companyname: partnerData?.companyname ? partnerData?.companyname : "",
    emailid: partnerData ? partnerData?.emailid : "",
    password: partnerData ? partnerData?.password : "",
    phonenumber: partnerData ? partnerData?.phonenumber : "",
  });

  const [error, setError] = useState({
    name: "",
    country: "",
    state: "",
    city: "",
    buildingName: "",
    streetAddress: "",
    landmark: "",
    zipcode: "",
    emailid: "",
    password: "",
    partner: "",
    currency: "",
  });
  const [tags, setTags] = useState(partnerData?.tags ? partnerData?.tags : []);
  const [switchButton, setSwitchButton] = useState({ floatFlag: false });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchPartnerCategory());
  }, []);

  // handle for phone number
  function handlePhoneNumber(evt) {
    let regex = /^\d{0,15}?$/;
    const value = evt.target.value;
    if (regex.test(evt.target.value)) {
      setPatnerObj({
        ...patnerObj,
        [evt.target.name]: value,
      });
    }
  }

  const handleInputChange = (e) => {
    const target = e.target;
    var value = target.value;
    const name = target.name;
    setPatnerObj({
      ...patnerObj,
      [name]: value,
    });
    if (name === "emailid") {
      setError({ emailid: "" });
    } else if (name === "password") {
      setError({ password: "" });
    }
  };

  const changefloatFlag = () => {
    setSwitchButton({ ...switchButton, floatFlag: !switchButton.floatFlag });
  };

  const handleDropdownPartnerType = (item, type) => {
    setDropDownObj({
      ...dropDownObj,
      [type]: item,
      product_type: "",
      redemptionMethod: "",
    });
  };

  const handleDropdownSelect = (item, type) => {
    // setDropDownObj(dropDownObj.countryCurrency.push(item?.id))
    setDropDownObj({ ...dropDownObj, [type]: item });

    //to generate tags on dropdown change
    if (type.name === "currency") {
      //   let value = item;
      dropdownTags(item);
    }
  };

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const dropdownTags = (item) => {
    //   let tagHead = item;
    if (tags && tags.some((tag) => tag.id === item?.id)) {
      toggleShowToast();
      return;
    } else {
      setTags([...tags, item]);
    }
  };

  const inputTags = (event) => {
    let value = event.target.value;
    const re = /^[A-Za-z]+$/;
    if (value === null) {
      event.target.value = "";
      return false;
    } else if (!re.test(value)) {
      event.target.value = "";
      return false;
    } else {
      if (tags.some((tag) => tag === value.toUpperCase())) {
        toggleShowToast();
        event.target.value = "";
        return;
      } else {
        setTags([...tags, value.toUpperCase()]);
        event.target.value = "";
      }
    }
  };
  // end here
  const validate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var passRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,}$)"
    );
    if (!dropDownObj.partner) {
      setError({ partner: t("Partner type field is required") });
      return false;
    } else if (!patnerObj.name) {
      setError({ name: t("Name field is required") });
      if (inputElementName.current) {
        inputElementName.current.focus();
      }
      return false;
    } else if (!dropDownObj.country) {
      setError({ country: t("Country field is required") });
      if (inputElementCountry.current) {
        inputElementCountry.current.focus();
      }
      return false;
    } else if (!patnerObj.buildingName) {
      setError({ buildingName: t("Building field is required") });
      if (inputElementAddress1.current) {
        inputElementAddress1.current.focus();
      }
      return false;
    } else if (!patnerObj.streetAddress) {
      setError({ streetAddress: t("Street Address field is required") });
      if (inputElementAddress2.current) {
        inputElementAddress2.current.focus();
      }
      return false;

      //  else if (!patnerObj.landmark) {
      //   setError({ landmark: "Landmark field is required" });
      //   return false;
      // }
      // else if (!patnerObj.zipcode) {
      //   setError({ zipcode: "Zipcode field is required" });
      //   return false;
      // }
    } else if (!patnerObj.emailid) {
      setError({ emailid: t("Enter email id") });
      if (inputElementEmail.current) {
        inputElementEmail.current.focus();
      }
      return false;
    } else if (
      !patnerObj.emailid ||
      emailRegex.test(patnerObj.emailid) === false
    ) {
      setError({ emailid: t("Enter valid email") });
      return false;
    } else if (!patnerObj.password) {
      setError({ password: t("Enter password") });
      if (inputElementPassword.current) {
        inputElementPassword.current.focus();
      }
      return false;
    } else if (
      !patnerObj.password ||
      passRegex.test(patnerObj.password) === false
    ) {
      setError({
        password: t(`Minimum 8 characters, at least one uppercase letter,
        one lowercase letter, one number and one special character`),
      });
      return false;
    } else if (!tags.length) {
      setError({ currency: t("Select currency") });
      return false;
    }

    return true;
  };

  const goToCurrencyMapping = () => {
    if (dropDownObj.partner.partner_category === "client") {
      history("/float/currency-mapping/to-client");
    } else {
      history("/float/currency-mapping/to-organization");
    }
  };

  const goToSuccessfull = () => {
    history("/partner-successfull");
  };
  const handleChangeCountries = (e) => {
    setDropDownObj({ ...dropDownObj, country: e, state: "" });
    e?.id && dispatch(fetchAllCountrieDetails(e?.id));
    e ? dropdownTags(e) : setTags([]);
  };

  const handleChangeStates = (e) => {
    setDropDownObj({ ...dropDownObj, state: e });
  };

  const handleChangeRedemptionMethod = (e) => {
    setDropDownObj({ ...dropDownObj, redemptionMethod: e });
  };

  const generatePassword = () => {
    const alpha = "abcdefghijklmnopqrstuvwxyz";
    const calpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const num = "1234567890";
    const specials = ",.!@#$%^&*";
    const options = [
      alpha,
      alpha,
      alpha,
      calpha,
      calpha,
      num,
      num,
      num,
      specials,
      specials,
    ];
    let opt, choose;
    let pass = "";
    for (let i = 0; i < 10; i++) {
      opt = Math.floor(Math.random() * options.length);
      choose = Math.floor(Math.random() * options[opt].length);
      pass = pass + options[opt][choose];
      options.splice(opt, 1);
    }

    setPatnerObj({ ...patnerObj, password: pass });
  };

  const createPartnerHandle = () => {
    let tagArrCurrency = [];
    tags.map((item) => {
      tagArrCurrency.push(item.id);
    });

    let partnerObj = {
      name: patnerObj.name,
      country: dropDownObj.country,
      state: dropDownObj.state,
      city: patnerObj.city,
      address: [
        { id: 1, name: "Building Name", value: patnerObj.buildingName },
        { id: 2, name: "Street Address", value: patnerObj.streetAddress },
        { id: 3, name: "Landmark", value: patnerObj.landmark },
        { id: 4, name: "Zipcode", value: patnerObj.zipcode },
      ],
      buildingName: patnerObj.buildingName,
      streetAddress: patnerObj.streetAddress,
      landmark: patnerObj.landmark,
      zipcode: patnerObj.zipcode,
      imgFiles: imgFiles,
      partner: dropDownObj.partner,
      product_type: dropDownObj.product_type,
      companyname: patnerObj.companyname,
      emailid: patnerObj.emailid.toLowerCase(),
      password: patnerObj.password,
      phonenumber: patnerObj.phonenumber,
      enableFloatFlag: switchButton.floatFlag,
      countryCurrency: tagArrCurrency,
      redemptionMethod: dropDownObj.redemptionMethod,
    };
    let val = validate();

    if (val) {
      if (switchButton.floatFlag) {
        dispatch(putPartner(partnerObj, goToCurrencyMapping));
      } else {
        dispatch(putPartner(partnerObj, goToSuccessfull));
      }
    }
  };

  return (
    <>
      {loader === false ? (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-20 font-bold">
                {t("Name & Description")}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column gap-4 mt-4">
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Creating partner as")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Select partner"} />
              </div>
              {/* Dropdown partner */}
              <div className="btn-group w-100">
                <div
                  className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                  data-bs-toggle="dropdown"
                >
                  {dropDownObj.partner
                    ? dropDownObj.partner?.partner_category
                    : "Select"}
                </div>
                <ul
                  className={`dropdown-menu rounded-card-7 dropdown-menu-start ${
                    props?.lang === "ar" ? "dropdown-menu-ar" : ""
                  }`}
                >
                  {partnerCategory &&
                    partnerCategory.map((item, i) => (
                      <li
                        key={i}
                        onClick={() =>
                          handleDropdownPartnerType(item, "partner")
                        }
                      >
                        <div className="dropdown-item cursor">
                          {item.partner_category}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              {!dropDownObj.partner && error.partner ? (
                <label className="text-error">{error.partner}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Name")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Enter name"} />
              </div>
              <InputField
                inputRef={inputElementName}
                placeholder={t("Enter name")}
                name="name"
                value={patnerObj.name}
                onChange={handleInputChange}
              />
              {!patnerObj.name && error.name ? (
                <label className="text-error">{error.name}</label>
              ) : null}
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Country")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={
                    "Select or enter country (enter atleast 3 character to sort dropdown)"
                  }
                />
              </div>
              {/* Dropdown country*/}
              <div className="d-flex flex-column gap-1">
                <Select
                  ref={inputElementCountry}
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select country")}
                  getOptionLabel={(countries) => countries.name}
                  options={countries}
                  //   isSearchable
                  value={dropDownObj?.country}
                  onChange={handleChangeCountries}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
                {!dropDownObj.country && error.country ? (
                  <label className="text-error">{error.country}</label>
                ) : null}
              </div>
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("State")}</span>
                  <InfoTooltip title={"State"} />
                </div>
                {/* Dropdown state*/}
                <div>
                  <Select
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select state")}
                    getOptionLabel={(states) => states.name}
                    options={states}
                    //   isSearchable
                    value={dropDownObj.state}
                    onChange={handleChangeStates}
                    isClearable
                    styles={CUSTOM_DROPDOWN_STYLE}
                    isDisabled={!dropDownObj?.country}
                  />
                </div>
                {!dropDownObj.state && error.state ? (
                  <label className="text-error">{error.state}</label>
                ) : null}
              </div>
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("City")}</span>
                <InfoTooltip title={"Enter city"} />
              </div>

              <InputField
                placeholder={t("Enter city")}
                name="city"
                value={patnerObj.city}
                onChange={handleInputChange}
              />
              {!dropDownObj.city && error.city ? (
                <label className="text-error">{error.city}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Complete Address")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Enter address"} />
              </div>
              <div className="d-flex flex-row justify-content-between gap-2 py-4 w-100">
                <div className="d-flex flex-column justify-content-between align-items-center h-75 w-100">
                  <div className="d-flex w-100">
                    <InputField
                      inputRef={inputElementAddress1}
                      placeholder={t("Building/Apartment/Floor")}
                      name="buildingName"
                      value={patnerObj.buildingName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-flex w-100">
                    {!patnerObj.buildingName && error.buildingName ? (
                      <label className="text-error">{error.buildingName}</label>
                    ) : null}
                  </div>
                  {/*
              {!patnerObj.buildingName && error.buildingName ? (
            <label className="text-error">{error.buildingName}</label>
          ) : !patnerObj.streetAddress && error.streetAddress ? (
            <label className="text-error">{error.streetAddress}</label>
          ) : null}
            */}
                </div>
                <div className="d-flex flex-column justify-content-between align-items-center h-75 w-100">
                  <div className="d-flex w-100">
                    <InputField
                    inputRef={inputElementAddress2}
                      placeholder={t("Street Address")}
                      name="streetAddress"
                      value={patnerObj.streetAddress}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-flex w-100">
                    {!patnerObj.streetAddress && error.streetAddress ? (
                      <label className="text-error">
                        {error.streetAddress}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
                <InputField
                  placeholder={t("Land Mark")}
                  name="landmark"
                  value={patnerObj.landmark}
                  onChange={handleInputChange}
                />
                <InputField
                  placeholder={"Zip Code"}
                  name="zipcode"
                  type="number"
                  value={patnerObj.zipcode}
                  onChange={handleInputChange}
                />
              </div>
              {/*  {!patnerObj.landmark && error.landmark ? (
            <label className="text-error">{error.landmark}</label>
          ) : !patnerObj.zipcode && error.zipcode ? (
            <label className="text-error">{error.zipcode}</label>
          ) : null}*/}
            </div>

            <div className="d-flex flex-column gap-2 ">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Partner logo")}</span>
                  <InfoTooltip title={"Upload partner logo"} />
                </div>
                <div className="d-flex flex-column">
                  <DragandDrop files={imgFiles} setFiles={setImgFiles} />
                </div>
              </div>
            </div>

            {dropDownObj.partner.partner_category === "supplier" ? (
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Product Type")}</span>
                  <InfoTooltip title={"Select product type"} />
                </div>
                {/* Dropdown partner */}
                <div className="btn-group w-100">
                  <div
                    className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                    data-bs-toggle="dropdown"
                  >
                    {dropDownObj.product_type
                      ? dropDownObj.product_type?.name
                      : "Select"}
                  </div>
                  <ul
                    className={`dropdown-menu rounded-card-7 dropdown-menu-start ${
                      props?.lang === "ar" ? "dropdown-menu-ar" : ""
                    }`}
                  >
                    {PRODUCT_TYPE &&
                      PRODUCT_TYPE.map((item, i) => (
                        <li
                          key={i}
                          onClick={() =>
                            handleDropdownSelect(item, "product_type")
                          }
                        >
                          <div className="dropdown-item cursor">
                            {item.name}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            ) : null}

            {dropDownObj.partner.partner_category === "supplier" &&
            dropDownObj.product_type?.name === "Giftcard" ? (
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Redemption method")}
                  </span>
                  <InfoTooltip title={"Redemption method"} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <Select
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select redemption method")}
                    getOptionLabel={(obj) => obj.name}
                    options={GIFTCARD_REDEMPTION_METHOD}
                    //   isSearchable
                    value={dropDownObj?.redemptionMethod}
                    onChange={handleChangeRedemptionMethod}
                    isClearable
                    styles={CUSTOM_DROPDOWN_STYLE}
                  />
                </div>
              </div>
            ) : null}

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Company name")}</span>
                <InfoTooltip title={"Enter company name"} />
              </div>
              <InputField
                placeholder={t("Enter company name")}
                name="companyname"
                value={patnerObj.companyname}
                onChange={handleInputChange}
              />
            </div>
            {/* {!patnerObj.compName&&error.compName?
          <label className="text-error">{error.compName}</label>:null
        } */}
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Email Id")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Enter partner email"} />
              </div>
              <InputField
              inputRef={inputElementEmail}
                placeholder={t("Enter partner email")}
                name="emailid"
                value={patnerObj.emailid}
                onChange={handleInputChange}
              />
              {error.emailid ? (
                <label className="text-error">{error.emailid}</label>
              ) : null}
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Password")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Enter or generate password"} />
              </div>
              <div className="input-group  position-relative">
                <InputField
                inputRef={inputElementPassword}
                  type={passShowHide ? "text" : "password"}
                  placeholder={t("Example Sam.01@123")}
                  name="password"
                  value={patnerObj.password}
                  onChange={handleInputChange}
                />
                <div
                  className={`input-group-append position-absolute position-absolute top-0 ${
                    props?.lang === "ar" ? "start-0" : "end-0"
                  }`}
                >
                  <button
                    className="btn  bg-tranparent "
                    type="button"
                    onClick={togglePassword}
                  >
                    {passShowHide ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </button>
                </div>
              </div>
              {error.password ? (
                <label className="text-error">{error.password}</label>
              ) : null}
              <span
                className="text-primary cursor"
                onClick={() => generatePassword()}
              >
                {t("Generate password")}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Phone number")}</span>
                <InfoTooltip title={"Enter phone number"} />
              </div>
              <InputGroupTxt
                title={
                  dropDownObj?.country?.phone_code
                    ? "" + dropDownObj?.country?.phone_code
                    : ""
                }
                placeholder={t("Enter phone number")}
                type="number"
                name="phonenumber"
                value={patnerObj.phonenumber}
                onChange={handlePhoneNumber}
                lang={props?.lang}
              />
              {/* {!patnerObj.phNumber&&error.phNumber?
            <label className="text-error">{error.phNumber}</label>:null
          } */}
            </div>
            {dropDownObj.partner.partner_category === "client" ||
            dropDownObj.partner.partner_category === "organization" ? (
              <div className="d-flex flex-row justify-content-between gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Enable float")}?
                  </span>
                  <InfoTooltip title={"Enable float"} />
                </div>
                <ReactSwitchButton
                  onChange={changefloatFlag}
                  value={switchButton.floatFlag}
                />
              </div>
            ) : null}

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Select currencies")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Select currencies"} />
              </div>
              <div className="">
                <Select
                  name={"currency"}
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select currencies")}
                  getOptionLabel={(currencies) =>
                    currencies?.name + " - " + currencies?.currency
                  }
                  options={countries}
                  isSearchable
                  value={dropDownObj.currency}
                  onChange={handleDropdownSelect}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <div className="tags-input border border-2">
                  <ul id="tags">
                    {tags &&
                      tags.map((tag, index) => (
                        <li key={index} className="tag align-items-center">
                          <span className="">
                            {tag.name + "-" + tag.currency}
                          </span>
                          <span
                            className="tag-close-icon"
                            onClick={() => removeTags(index)}
                          >
                            x
                          </span>
                        </li>
                      ))}
                  </ul>
                  <input
                    type="text"
                    name="tags"
                    onKeyUp={(event) =>
                      event.key === "Enter" ? inputTags(event) : null
                    }
                    disabled={true}
                  />
                </div>
              </div>
              {!tags.length && error.currency ? (
                <label className="text-error">{error.currency}</label>
              ) : null}
            </div>

            <div className="d-flex">
              <Button
                onClick={() => createPartnerHandle()}
                text={t("Create")}
                type="primary"
              />
            </div>
          </div>
          <div
            className="position-fixed bottom-0 start-50 mb-4 translate-middle-x w-100 "
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg="Already added."
              imageType={"warning"}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default CreatePatner;
