import {FETCH_CUSTOMER_REQUEST,
    FETCH_CUSTOMER_SUCCESS,
    FETCH_CUSTOMER_FAILURE } from "./customerTypes";

    const initialState = {
        customer: [],
        loading: false,
        error: '',
        errorMsg: '',
    }

    const customerReducer = (state = initialState, action) => {
        switch (action.type) {
            
            case FETCH_CUSTOMER_REQUEST:
                return {
                    ...state,
                    loading: true,
                    customer: [],
                    errorMsg: '',
                }
            case FETCH_CUSTOMER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    customer: action.payload,
                    errorMsg: '',
                }
            case FETCH_CUSTOMER_FAILURE:
                return {
                    ...state,
                    loading: false,
                    errorMsg: action.payload
                }
            
            default: return state
        }
    }
    
    export default customerReducer;