import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as RadishTag } from "../../assests/svg/Tag/radish_tag.svg";
import { ReactComponent as YellowTag } from "../../assests/svg/Tag/yellow_tag.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import InputField from "../../components/InputField/InputField";
import Default_giftcard from "../../assests/img/giftcard-default.png";
import Default_product from "../../assests/img/Product-default.png";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import OrderDetails from "../../components/Order/OrderDetails";
import { useDispatch, useSelector } from "react-redux";
import { getorderDetails } from "../../Redux/Order/orderAction";
import OrderDetailTimeLine from "../../components/Order/OrderDetailTimeLine";
import ModelForGiftCardSummery from "../../components/Models/ModelForGiftCardSummery";
import ModalComp from "../../components/Models/ModalComp";
import { fetchGiftCardSummeryById } from "../../Redux/GiftCard/giftcardAction";
import { getMerchandiseSummeryById } from "../../Redux/Merchandise/merchandiesAction";
import ModelForMerchandiseSummery from "../../components/Models/ModelForMerchandiseSummery";
import ProductSupplierDetails from "../../components/Order/ProductSupplierDetails";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg";
import { Collapse } from "react-bootstrap";
import { momentdateTimeFormater } from "../../utils/constantFunction";
import SmallToast from "../../components/Toast/SmallToast";
import CopyToClipboard from "react-copy-to-clipboard";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import { useTranslation } from "react-i18next";

const OrderManagementGiftCard = () => {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const orderDetailState = useSelector(
    (state) => state?.allOrderList?.orderDetails
  );

  const countryCurrency = useSelector(
    (state) => state?.countries?.countrieDetails
  );
  // set collapse for receiver detail
  const [receiverDetailCollapse, setReceiverDetailCollapse] = useState(false);

  // for model Merchandise
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );
  const handleClose = () => setShowMerchandiseModal(false);
  const [showMerchandiseModal, setShowMerchandiseModal] = useState(false);

  // for model Giftcard
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );
  const handleGiftcardClose = () => setShowGiftcardModal(false);
  const [showGiftcardModal, setShowGiftcardModal] = useState(false);
  const [orderid, setorderid] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.selectedOrder?.id) {
      if (location?.state?.selectedOrder?.id) {
        dispatch(getorderDetails(location?.state?.selectedOrder?.id));
      }
      // if (location?.state?.selectedOrder?.giftcard?.country_id) {
      //   dispatch(
      //     fetchAllCountrieDetails(
      //       location?.state?.selectedOrder?.giftcard?.country_id
      //     )
      //   );
      // }
      // else if (location?.state?.selectedOrder?.merchandise?.country_id) {
      //   dispatch(
      //     fetchAllCountrieDetails(
      //       location?.state?.selectedOrder?.merchandise?.country_id
      //     )
      //   );
      // }
    } else {
      let orderid1 = new URLSearchParams(window.location.search).get("orderid");
      setorderid(orderid1);
      dispatch(getorderDetails(orderid1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // copy to clipboard
  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const showSummaryModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowMerchandiseModal(true);
  };

  const showGiftcardModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowGiftcardModal(true);
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-35 my-2">
            {t("Product order Management")}
          </span>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between gap-2">
        <div className="d-flex flex-column width-65">
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
            <div className="d-flex flex-row  align-items-center gap-2">
              <Tag />
              <span className="font-18 font-bold">
                {t("Order ID")} : {orderDetailState?.result?.id}
              </span>
            </div>

            <div className="d-flex">
              <div className=" d-flex flex-column justify-content-between rounded-3 mb-3 gap-3 w-100">
                <table responsive="xl">
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex flex-row justify-content-start align-items-center gap-2 py-3">
                          <span className=" rounded-3">
                            {orderDetailState?.result?.product_type ===
                            "giftcard" ? (
                              <img
                                src={
                                  orderDetailState.result.giftcard.files &&
                                  orderDetailState.result.giftcard.files[0]
                                    .file_url
                                    ? orderDetailState.result.giftcard.files[0]
                                        .file_url
                                    : Default_giftcard
                                }
                                alt="not found"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  backgroundSize: "cover",
                                }}
                              />
                            ) : (
                              <img
                                src={
                                  orderDetailState?.result?.merchandise
                                    ?.files &&
                                  orderDetailState?.result?.merchandise
                                    ?.files[0]
                                    ? orderDetailState?.result?.merchandise
                                        ?.files[0].file_url
                                    : Default_product
                                }
                                alt="not found"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  backgroundSize: "cover",
                                }}
                              />
                            )}
                          </span>
                          <div className="d-flex flex-column justify-content-start">
                            {orderDetailState?.result?.product_type ===
                            "giftcard" ? (
                              <span
                                className="font-16 font-boldest text-dark cursor"
                                onClick={() =>
                                  showGiftcardModel(
                                    orderDetailState?.result?.product_id
                                  )
                                }
                              >
                                {orderDetailState?.result?.giftcard
                                  ?.default_title
                                  ? orderDetailState?.result?.giftcard
                                      ?.default_title
                                  : orderDetailState?.result?.merchandise
                                      ?.default_title}
                              </span>
                            ) : (
                              <span
                                className="font-16 font-boldest text-dark cursor"
                                onClick={() =>
                                  showSummaryModel(
                                    orderDetailState?.result?.product_id
                                  )
                                }
                              >
                                {orderDetailState?.result?.giftcard
                                  ?.default_title
                                  ? orderDetailState?.result?.giftcard
                                      ?.default_title
                                  : orderDetailState?.result?.merchandise
                                      ?.default_title}
                              </span>
                            )}

                            {/*  {orderDetailState?.result?.product_type ===
                            "merchandise" ? (
                              <span className="font-13 font-bold text-muted">
                                {orderDetailState?.result?.merchandise?.variant?.variant_values.join(
                                  "|"
                                )}
                              </span>
                            ) : null}*/}
                            {/* {orderDetailState?.result?.product_type ===
                            "giftcard" ? (
                              <span className="font-13 font-bold text-dark">
                                {location?.state?.selectedOrder?.giftcard
                                  ?.giftcard_value_type === "denominations"
                                  ? "denominations"
                                  : "Range" &&
                                    location?.state?.selectedOrder?.giftcard
                                      ?.giftcard_value_type === "denominations"
                                  ? "denominations"
                                  : "Range"}
                                <span> Denomination</span>
                              </span>
                            ) : null} */}

                            {orderDetailState?.result?.product_type ===
                            "giftcard" ? (
                              <span className="font-13 font-bold text-dark">
                                {orderDetailState?.result?.inventory_type_id &&
                                orderDetailState?.result?.inventory_type_id ===
                                  "powered_by_ehadaya"
                                  ? "Powered by eHadaya"
                                  : orderDetailState?.result
                                      ?.inventory_type_id === "manual_inventory"
                                  ? "Manual Inventory"
                                  : orderDetailState?.result
                                      ?.inventory_type_id ===
                                    "3rd_party_suppliers"
                                  ? "3rd Party Suppliers"
                                  : null}
                              </span>
                            ) : null}
                            <span className="font-13 font-bold text-muted">
                              <span
                                className="font-13 font-bold text-muted"
                                title={orderDetailState?.result?.product_id}
                              >
                                {orderDetailState?.result?.product_id}
                                <span>...</span>
                              </span>
                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Copy to clipboard"
                                className="cursor "
                                value={orderDetailState?.result?.product_id}
                                onClick={() => copyToClipboard(orderDetailState?.result?.product_id)}
                              >
                                <CopyToClipboard text={orderDetailState?.result?.product_id}>
                                  <CopyButton />
                                </CopyToClipboard>
                              </span>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        {/*  <span className="font-15 font-bold text-success bg-04 px-1 py-1 rounded-3">
                           {item.Status} 
                          In stock
                        </span>*/}
                      </td>

                      <td>
                        <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 py-1">
                          {orderDetailState?.result?.product_type}
                        </span>
                      </td>
                    </tr>
                    {/* ))} */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex flex-row  align-items-center gap-2">
              <RadishTag />
              <span className="font-18 font-bold">Buyer section</span>
            </div>

            <div className="d-flex flex-column gap-3 mt-4">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">Buyer Name</span>
                <InfoTooltip title={"Buyer Name"} />
              </div>
              <InputField
                value={
                  orderDetailState?.result?.client?.partner_name
                    ? orderDetailState?.result?.client?.partner_name
                    : orderDetailState?.result?.organization?.partner_name
                }
                disabled
              />
            </div>

            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">Buyer Type</span>
                <InfoTooltip title={"Buyer Type"} />
              </div>
              <InputField
                value={
                  orderDetailState?.result?.order_by === "client"
                    ? "Client"
                    : "Organization"
                }
                disabled
              />
            </div>
            <div className="d-flex flex-column gap-4 mt-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">Created on</span>
                  <InfoTooltip title={"Created on"} />
                </div>
                <InputField
                  value={momentdateTimeFormater(
                    orderDetailState?.result?.created_at
                  )}
                  disabled
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    Total Amount (Before discount)
                  </span>
                </div>
                <InputField
                  value={orderDetailState?.result?.total_price}
                  disabled
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    Total Amount (After discount)
                  </span>
                </div>
                <InputField
                  value={orderDetailState?.result?.order_price}
                  disabled
                />
              </div>
              <div
                className="d-flex flex-row justify-content-between align-items-center"
                onClick={() =>
                  setReceiverDetailCollapse(!receiverDetailCollapse)
                }
              >
                <div className="d-flex py-3 gap-3">
                  <YellowTag />
                  <span className="font-20 font-bold">
                    Receiver section(Contact Details)
                  </span>
                </div>
                <div
                  className={`cursor p-0 ${
                    receiverDetailCollapse ? "rotate-180" : ""
                  }`}
                  aria-controls="example-collapse-text"
                  aria-expanded={receiverDetailCollapse}
                >
                  <DropDownIcon
                    className={`cursor p-0 ${
                      receiverDetailCollapse ? "rotate-180" : ""
                    }`}
                  />
                </div>
              </div>
              <Collapse in={receiverDetailCollapse}>
                <div>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column gap-1">
                      {orderDetailState?.result?.email_id ? (
                        <span className="font-bolder font-14 text-dark">
                          Email :-
                          <span className="font-bolder font-16 text-muted">
                            {orderDetailState?.result?.email_id}
                          </span>
                        </span>
                      ) : null}

                      {orderDetailState?.result?.phone_number > 0 ? (
                        <div className="d-flex flex-row align-items-center gap-2">
                          <span className="font-bolder font-14 text-dark">
                            Phone Number :-
                            <span className="font-bolder font-16 text-muted">
                              {orderDetailState?.result?.phone_number &&
                                orderDetailState?.result?.phone_number}
                            </span>
                          </span>
                        </div>
                      ) : null}

                      {orderDetailState?.result?.delivery_type ===
                      "physical" ? (
                        <div className="d-flex flex-column gap-1">
                          {orderDetailState?.result?.delivery_address_line_1 ? (
                            <span className="font-bolder font-14 text-dark">
                              Address1 :-
                              <span className="font-bolder font-14 text-muted">
                                {" "}
                                {orderDetailState?.result
                                  ?.delivery_address_line_1 &&
                                  orderDetailState?.result
                                    ?.delivery_address_line_1}
                              </span>
                            </span>
                          ) : null}

                          {orderDetailState?.result?.delivery_address_line_2 ? (
                            <span className="font-bolder font-14 text-dark">
                              Address2 :-
                              <span className="font-bolder font-14 text-muted">
                                {" "}
                                {orderDetailState?.result
                                  ?.delivery_address_line_2 &&
                                  orderDetailState?.result
                                    ?.delivery_address_line_2}
                              </span>
                            </span>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-3 mt-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">Currency</span>
                      <InfoTooltip title={"Currency"} />
                    </div>
                    <div className="d-flex flex-column">
                      <InputField
                        placeholder={" "}
                        value={
                          countryCurrency?.currency +
                          " - " +
                          countryCurrency?.currency_symbol
                        }
                        disabled
                      />
                    </div>

                    {orderDetailState?.result?.product_type === "giftcard" &&
                    location?.state?.selectedOrder?.giftcard
                      ?.giftcard_value_type === "denominations" ? (
                      <div className="d-flex flex-column gap-2">
                        <div className="d-flex flex-row align-items-center gap-2">
                          <span className="font-bold font-14">
                            {/* <span className="font-13 font-bold text-dark"> */}
                            {location?.state?.selectedOrder?.giftcard
                              ?.giftcard_value_type === "denominations"
                              ? "denominations"
                              : "Range" &&
                                location?.state?.selectedOrder?.giftcard
                                  ?.giftcard_value_type === "denominations"
                              ? "denominations"
                              : "Range"}{" "}
                            Denomination
                          </span>
                        </div>
                        <InputField
                          // {location?.state?.selectedOrder?.giftcard?.denomination.denomination_value ==="denominations"? "denominations":"Reanged"}
                          value={
                            location?.state?.selectedOrder?.giftcard
                              ?.denomination.denomination_value &&
                            location?.state?.selectedOrder?.giftcard
                              ?.denomination.denomination_value
                          }
                          disabled
                        />
                      </div>
                    ) : null}

                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">Quantity</span>
                      </div>
                      <InputField
                        value={orderDetailState?.result?.quantity}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column width-35 h-100">
          <OrderDetails />
          <ProductSupplierDetails />
          <OrderDetailTimeLine
            id={orderid === "" ? location?.state?.selectedOrder?.id : orderid}
          />
        </div>
      </div>

      <ModalComp
        size="lg"
        showModal={showGiftcardModal}
        // handleClose={handleClose}
        component={
          <ModelForGiftCardSummery
            handleClose={handleGiftcardClose}
            giftcardSummery={giftcardSummery}
          />
        }
      />

      <ModalComp
        size="lg"
        showModal={showMerchandiseModal}
        // handleClose={handleClose}
        component={
          <ModelForMerchandiseSummery
            handleClose={handleClose}
            merchandiseSummery={merchandiseSummery}
          />
        }
      />
      <div>
        <SmallToast data={id ? id : ""} message={"Copied"} />
      </div>
    </div>
  );
};

export default OrderManagementGiftCard;
