import React from 'react';
import { ReactComponent as Gross } from "../../assests/svg/GrossIncome.svg";
import { ReactComponent as Merchant } from "../../assests/svg/Merchant.svg";
import { ReactComponent as Profit } from "../../assests/svg/ProfitIcon.svg";
import InfoTooltip from '../Tooltip/InfoTooltip';

const GrossIncome = () => {
  return (
  <div className="d-flex flex-row bg-white shadow-sm rounded-3 py-3 px-4 w-100 ">
      <div className="d-flex flex-row justify-content-between gap-4">
      <div className="d-flex flex-column gap-2 px-5">
          <div className="">
            <Gross />
          </div>
          <div className="my-3 gap-3">
          <div className="d-flex flex-start align-items-center gap-2">
            <span className="font-15 font-bolder">Gross income</span>
            <InfoTooltip title={"Gross income"} />
            </div>
            <span className="fw-bold font-32">No data</span>
          </div>
        </div>
        <div className="border-start border-1 my-3"></div>

        <div className="d-flex flex-column gap-2 px-5">
          <div className="">
            <Merchant />
          </div>
          <div className="my-3 gap-3">
          <div className="d-flex flex-start align-items-center gap-2">
            <span className="font-15 font-bolder">Merchant fee</span>
            <InfoTooltip title={"Merchant fee"} />
            </div>
            <span className="fw-bold font-32">No data</span>
          </div>
        </div>
        <div className="border-start border-1 my-3"></div>

        <div className="d-flex flex-column gap-3 px-5">
          <div className="">
            <Profit />
          </div>
          <div className="my-3 gap-3">
          <div className="d-flex flex-start align-items-center gap-2">
            <span className="font-15 font-bolder">Profit</span>
            <InfoTooltip title={"Profit"} />
            </div>
            <span className="fw-bold font-32">No data</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GrossIncome;
