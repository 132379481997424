import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../assests/svg/Success.svg";
import Button from "../../components/Button/Button";
import { getAllUserRole } from "../../Redux/UserManagement/userAction";
import { useTranslation } from "react-i18next";

const UpdateUserRoleSuccess = () => {
  const history = useNavigate();
  const dispatch=useDispatch();
  const { t } = useTranslation();
  const rolesUpdateState = useSelector((state) => state.users.updateUserRoleResponse);
  
  useEffect(() => {
    if (rolesUpdateState === null) {
      history("/users-management/roles");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesUpdateState]);

  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesUpdateState]);

  const goToDashboard = () => {
    history("/users-management/roles");
    dispatch(getAllUserRole());
  };

  return (
    <div className="d-flex flex-column  align-item-center bg-light w-100 py-5">
      <div className="d-flex flex-row w-100 justify-content-center">
        <SuccessIcon />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-dark font-bold font-40">
          {t("Role permissions updated successfully")}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-muted font-bold font-15">
          {t("Role ID")}: {rolesUpdateState?.id}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button
          type={"primary"}
          text={t("View Dashboard")}
          onClick={goToDashboard}
        />
      </div>
    </div>
  );
};
export default UpdateUserRoleSuccess;
