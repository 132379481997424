import React from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as BlueTag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as RadishTag } from "../../assests/svg/Tag/radish_tag.svg";
import { ReactComponent as PinkTag } from "../../assests/svg/Tag/pink_tag.svg";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/dark_green.svg";
import { useSelector } from "react-redux";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { useTranslation } from "react-i18next";

const GiftCardSummaryDetails = (props) => {
  const { t } = useTranslation();
  const { floatBalance } = props;
  const giftcardOrderData = useSelector((state) => state.createOrder);

  const orderamount =
    giftcardOrderData?.createorderData?.quantity *
    (giftcardOrderData?.createorderData?.product?.giftcard_value_type ===
    "denominations"
      ? giftcardOrderData?.createorderData?.denomination?.denomination_value
      : giftcardOrderData?.createorderData?.rangeDenomination);

  let discountedamount;
  if (
    giftcardOrderData?.createorderData?.selectedProduct?.discount === undefined
  ) {
    discountedamount = (orderamount * 0) / 100;
  } else {
    discountedamount =
      (orderamount *
        giftcardOrderData?.createorderData?.selectedProduct?.discount) /
      100;
  }

  let remainingBal =
    floatBalance?.balance.toFixed(2) -
    (orderamount.toFixed(2) - discountedamount.toFixed(2));

  return (
    <div className="d-flex flex-column justify-content-between bg-white shadow-sm rounded-3 p-4 w-100">
      <div className="d-flex gap-4  pb-3">
        <span className="font-bold font-20 ">{t("Details")}</span>
      </div>
      <div className=" d-flex flex-column gap-3">
        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <BlueTag />
          <span className="font-20 font-bold">
            {t("Denomination")} :{" "}
            <span className="font-20 font-bold text-light-blue ">
              {giftcardOrderData?.createorderData?.product?.country &&
                giftcardOrderData?.createorderData?.product?.country
                  .currency_symbol}
              {giftcardOrderData?.createorderData?.product
                ?.giftcard_value_type === "denominations"
                ? giftcardOrderData?.createorderData?.denomination
                    ?.denomination_value
                : giftcardOrderData?.createorderData?.rangeDenomination}
            </span>
          </span>
        </div>

        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <RadishTag />
          <span className="font-20 font-bold">
            {t("Quantity")} :{" "}
            <span className="font-20 font-bold text-light-blue ">
              {giftcardOrderData?.createorderData?.quantity}
            </span>
          </span>
        </div>
        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <PinkTag />
          <span className="font-20 font-bold">
            {t("Client")} :{" "}
            <span className="font-22 font-boldest text-light-blue ">
              <u>
                {
                  giftcardOrderData?.createorderData?.selectedClient
                    ?.partner_name
                }
              </u>
            </span>
          </span>
        </div>
        {giftcardOrderData?.createorderData?.selectedProduct?.discount && (
          <div className="d-flex gap-3 my-2 mx-2 align-items-center">
            <BlueTag />
            <span className="font-20 font-bold">
              {t("Client discount")}:{" "}
              <span className="font-22 font-boldest text-light-blue ">
                {giftcardOrderData?.createorderData?.selectedProduct?.discount}

                <span className="font-22 font-boldest text-light-blue ">%</span>
              </span>
            </span>
          </div>
        )}
        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <RadishTag />
          <span className="font-20 font-bold">
            {t("Order amount")} :
            <span className="font-20 font-bold text-light-blue mx-1">
              <span>
                {
                  giftcardOrderData?.createorderData?.product?.country
                    .currency_symbol
                }
              </span>
              {giftcardOrderData?.createorderData?.quantity *
                (giftcardOrderData?.createorderData?.product
                  ?.giftcard_value_type === "denominations"
                  ? giftcardOrderData?.createorderData?.denomination
                      ?.denomination_value
                  : giftcardOrderData?.createorderData?.rangeDenomination)}
              <span className="font-15 font-bold text-muted">
                {t("(Before Discount)")}
              </span>
            </span>
          </span>
        </div>
        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <BlueTag />
          <span className="font-20 font-bold">
            {t("Order amount")} :
            <span className="font-20 font-bold text-light-blue mx-1">
              <span>
                {
                  giftcardOrderData?.createorderData?.product?.country
                    .currency_symbol
                }
              </span>
              {orderamount - discountedamount}
              <span className="font-15 font-bold text-muted">
                {t("(After Discount)")}
              </span>
            </span>
          </span>
        </div>
        {giftcardOrderData?.createorderData?.deliveryType?.name ===
        "Physical" ? (
          <div className="d-flex gap-3 my-2 mx-2 align-items-center">
            <GreenTag />
            <span className="font-20 font-bold">
              {t("Delivery address")} :
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {giftcardOrderData?.createorderData?.deliveryAddressline1}{" "}
              </span>
              {giftcardOrderData?.createorderData?.deliveryAddressline1 && ","}
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {giftcardOrderData?.createorderData?.deliveryAddressline2}{" "}
              </span>
              {giftcardOrderData?.createorderData?.deliveryAddressline2 && ","}
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {giftcardOrderData?.createorderData?.pincode}{" "}
              </span>
              {giftcardOrderData?.createorderData?.pincode && ","}
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {giftcardOrderData?.createorderData?.cityName}{" "}
              </span>
            </span>
          </div>
        ) : (
          <div className="d-flex gap-3 my-2 mx-2 align-items-center">
            <RadishTag />
            <span className="font-20 font-bold">
              {t("Delivery type")} :
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {giftcardOrderData?.createorderData?.deliveryType?.name}{" "}
              </span>
            </span>
          </div>
        )}
        {giftcardOrderData?.createorderData?.emailid ? (
          <div className="d-flex gap-3 my-2 mx-2 align-items-center">
            <GreenTag />
            <span className="font-20 font-bold">
              {t("Email Id")} :
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {giftcardOrderData?.createorderData?.emailid}{" "}
              </span>
            </span>
          </div>
        ) : null}

        {giftcardOrderData?.createorderData?.selectedClient?.float_flag ===
          true && (
          <div className="d-flex flex-row gap-4">
            <div className="d-flex gap-3 my-4 mx-2 align-items-center">
              <Tag />
              <span className="font-20 font-bold">
                {t("Remaining Float Balance")}:
                <span className="font-20 font-bold text-light-blue ">
                  {" "}
                  {floatBalance?.country?.currency_symbol}
                  {floatBalance?.balance
                    ? parseFloat(remainingBal.toFixed(2))
                    : "00"}
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GiftCardSummaryDetails;
