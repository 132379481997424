/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../Toasts/Toasts";
import {
  DataSortingHandler,
  momentdateFormater,
} from "../../utils/constantFunction";
import Filter from "../Filter/Filter";
import {
  PRODUCT_STATUS_FILTERS_ACTIVE_DEACTIVE,
  SORT_TYPE,
} from "../../utils/ConstantData"; import PulseLoader from "../Loader/PulseLoader";
import {
  clearOrganizationList,
  getOrganizationList,
  setOrganizationFilterData,
} from "../../Redux/Organizations/organizationAction";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";
import RefreshButton from "../Button/RefreshButton";

const AllOrganizationList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();

  const loading = useSelector((state) => state?.organizations?.loading);
  const organizationFilter = useSelector(
    (state) => state?.organizations?.organizationFilter
  );
  const organizationState = useSelector(
    (state) => state?.organizations?.organizationList
  );

  // infinite scroll
  // const hasmore = useSelector(
  //   (state) => state?.suppliers?.next_iteration
  // );
  // const loading = useSelector((state) => state?.suppliers?.loading);
  // const [skip, setskip] = useState(organizationState.length === 0 ? -10 : organizationState.length - 10);
  // const [limit, setlimit] = useState(10);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (organizationState.length === 0){
  //     fetchdata(skip)
  //   }
  // }, []);
  //  console.log(organizationState)

  // const fetchdata = () => {
  //   setTimeout(async () => {
  //     dispatch(getAllOrganizationList(skip + limit, limit, organizationState));
  //     setskip(skip + limit)
  //   }, 100);
  // }

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);
  const [products, setProducts] = useState();
  const [filterCardType, setFilterCardType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => { }, [order]);

  useEffect(() => {
    setProducts(organizationFilter[0]?.filterProduct);
  }, [organizationFilter]);

  useEffect(() => {
    organizationFilter && setFilterCardType(organizationFilter[1]?.filterType);
  }, [organizationFilter[1]?.filterType]);

  useEffect(() => {
    if (organizationFilter.length === 0) {
      setProducts(organizationState);
    }
  }, [organizationState]);

  const filterCardTypeHandler = (e) => {
    setFilterCardType(e);
    let arr = [];
    organizationState &&
      organizationState.map((item, i) => {
        if (e?.value === item.active_flag) {
          arr.push(item);
        } else if (e?.value === null) {
          arr.push(item);
        }
      });
    dispatch(
      setOrganizationFilterData([{ filterProduct: arr }, { filterType: e }])
    );
  };

  const goTonextStep = (item) => {
    history("/organization-details", {
      state: {
        selectedOrganization: item,
      },
    });
  };

  const refreshData = () => {
    dispatch(clearOrganizationList());
    // setskip(0);
    // setlimit(10);
    dispatch(getOrganizationList());
  };

  return loading === true && products?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column gap-3 mt-3">
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-2 w-100">
        <div className="d-flex flex-column  bg-white">
          <div className="card-body d-flex  flex-row justify-content-between align-item-center">
            <div className="d-flex flex-row justify-content-start align-items-center gap-2">
              <div className="p-2 flex-grow-1">
                <div className="d-flex gap-3 my-2 mx-3 align-items-center">
                  <Tag />
                  <span className="font-20 font-bold">
                    {t("All Organization")}
                  </span>
                </div>
              </div>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search by name")}
                onChange={searchHandler}
              />
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center gap-2">
              <div>
                <Filter
                  data={PRODUCT_STATUS_FILTERS_ACTIVE_DEACTIVE}
                  onChange={filterCardTypeHandler}
                  title={filterCardType?.name}
                  lang={props?.lang}
                />
              </div>
              <div>
                <RefreshButton onClick={refreshData} />
              </div>
            </div>
          </div>

          <div className="row px-5 py-2">
            <table responsive="xl">
              <tbody>
                <tr className="card-body gap-3 border-bottom py-5">
                  <td
                    className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "partner_name"
                      ? "ascending"
                      : "descending"
                      }`}
                    onClick={() =>
                      sortingHandler(
                        "partner_name",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Name")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "id"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "id",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("id")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "email"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "email",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Email")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "created_at"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "created_at",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Created at")}
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    {t("Status")}
                  </td>
                </tr>
                {products &&
                  products
                    ?.filter((item) => {
                      if (searchTerm === "") {
                        return item;
                      } else if (
                        item?.partner_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <tr
                        className="card-body gap-3 border-bottom cursor hover-row"
                        key={item.id}
                        onClick={() => goTonextStep(item)}
                      >
                        <td className="card-body gap-1">
                          <div className="d-flex flex-column justify-content-start ">
                            <span
                              className="d-inline-block text-truncate font-15 font-boldest text-dark"
                              title={item?.partner_name}
                              style={{ maxWidth: "160px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                            >
                              {item?.partner_name}
                            </span>
                            <span className="font-12 font-bolder text-muted">
                              {item?.company_name}
                            </span>
                          </div>
                        </td>

                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-between  gap-3">
                            <span
                              className="fs-light  text-muted d-inline-block text-truncate"
                              style={{ maxWidth: "100px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={item?.id}
                            >
                              {item?.id}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-between  gap-3">
                            <span
                              className="fs-light  text-muted d-inline-block text-truncate"
                              style={{ maxWidth: "150px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={item.email}
                            >
                              {item.email}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-contents-between align-items-center">
                            <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                              {momentdateFormater(item?.created_at)}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-contents-between align-items-center">
                            <span>
                              {item?.active_flag === true ? (
                                <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                  {t("Active")}
                                </span>
                              ) : (
                                <span className="font-15 font-bold text-danger bg-04 bg-opacity-25 rounded-3 px-2">
                                  {t("Deactive")}
                                </span>
                              )}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            {!products?.length && (
              <div className="d-flex flex-row justify-content-center py-3">
                <span className="font-20 font-bold text-error">
                  {t("No organization found")}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default AllOrganizationList;
