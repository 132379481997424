import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import Button from "../Button/Button";
import PulseLoader from "../Loader/PulseLoader";
import InputField from "../InputField/InputField";
import InfoTooltip from "../Tooltip/InfoTooltip";
import ReactSwitchButton from "../Button/ReactSwitchButton";
import DragandDrop from "../DragandDrop/DragandDrop";
import { useDispatch, useSelector } from "react-redux";
import { getEmailTemplate } from "../../Redux/CustomEmail/customeEmailAction";
import ModalComp from "../../components/Models/ModalComp";
import EmailPreviewModel from "../Models/EmailPreviewModel";
import { useTranslation } from "react-i18next";

const CustomEmail = (props) => {
  const { t } = useTranslation();
  const { selectedClient } = props;
  const dispatch = useDispatch();
  //  const emailTemplateState = useSelector((state) => state?.emailTemplate);
  const emailTemplateState = useSelector(
    (state) => state?.emailTemplate?.emailTemplate
  );
  //for model to preview email template
  const handleClose = () => setShowEmailModal(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const [brandImg, setBrandImg] = useState([]);

  const [switchButtons, setSwitchButtons] = useState({
    customEmailFlag: false,
    barcodeFlag: true,
    qrCodeFlag: false,
    brandLogoFlag: false,
    broughtYouBuyFlag: true,
    contactInfoFlag: true,
  });
  const [sucessTitle, setSucessTitle] = useState("");
  const [successHeading, setHeadingTxt] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [email, setEmail] = useState("");
  const [webAddress, setWebAddress] = useState("");
  const [contact_one, setContact_one] = useState("");
  const [contact_two, setContact_two] = useState("");
  const [emailData, setEmailData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getEmailTemplate(selectedClient?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (emailTemplateState) {
      setSucessTitle(emailTemplateState.success_title)
      setHeadingTxt(emailTemplateState.success_heading);
      setSuccessMessage(emailTemplateState.success_message);
      setSwitchButtons({
        ...switchButtons,
        barcodeFlag: emailTemplateState.bar_code_flag,
        qrCodeFlag: emailTemplateState.qr_code_flag,
      });
      setEmail(emailTemplateState.contact_info[0]);
      setContact_one(emailTemplateState?.contact_info[2]);
      setContact_two(emailTemplateState.contact_info[3]);
      setWebAddress(emailTemplateState.contact_info[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateState]);

  const customeEmailHandler = () => {
    setSwitchButtons({
      ...switchButtons,
      customEmailFlag: !switchButtons.customEmailFlag,
    });
  };

  const brandLogoFlagHandler = () => {
    setSwitchButtons({
      ...switchButtons,
      brandLogoFlag: !switchButtons.brandLogoFlag,
    });
  };
  const contactInfoFlagHandler = () => {
    setSwitchButtons({
      ...switchButtons,
      contactInfoFlag: !switchButtons.contactInfoFlag,
    });
  };
  const broughtYouBuyFlagHandler = () => {
    setSwitchButtons({
      ...switchButtons,
      broughtYouBuyFlag: !switchButtons.broughtYouBuyFlag,
    });
  };

  // const barcodeHandler = () => {
  //   setSwitchButtons({
  //     ...switchButtons,
  //     barcodeFlag: !switchButtons.barcodeFlag,
  //     qrCodeFlag: !switchButtons.qrCodeFlag,
  //   });
  // };

  const showEmailModel = () => {
    let contactInfo = [email, webAddress, contact_one, contact_two]
    setEmailData({
      partner_id: selectedClient?.id,
      sucessTitle: sucessTitle,
      successHeading: successHeading,
      successMessage: successMessage,
      barcodeFlag: switchButtons.barcodeFlag,
      qrCodeFlag: switchButtons.qrCodeFlag,
      brandLogoFlag: switchButtons.brandLogoFlag,
      brandLogo: brandImg,
      contactInfo: contactInfo,
      customEmailFlag: switchButtons.customEmailFlag,
      broughtYouBuyFlag: switchButtons.broughtYouBuyFlag,
      contactInfoFlag: switchButtons.contactInfoFlag,
    });
    setShowEmailModal(true);
  };

  return (
    <>
      {/* {allClientFloatLoading === false ? ( */}
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 width-65">
        <div className="d-flex flex-column  bg-white">
          <div className="d-flex flex-row w-100  align-items-center">
            <div className="p-2 flex-grow-1">
              <div className="d-flex gap-3 my-2 mx-3 align-items-center">
                <Tag />
                <span className="font-20 font-bold">{t("Email Template")}</span>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column gap-4 mt-4 mx-3">
            <div className="d-flex flex-row justify-content-between gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Custom Email")}</span>
                <InfoTooltip
                  title={"Enable or disable custom email"}
                />
              </div>
              <ReactSwitchButton
                onChange={customeEmailHandler}
                value={switchButtons.customEmailFlag}
              />
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Congratulations Title")}
                </span>
                <InfoTooltip
                  title={"Congratulations Title"}
                />
              </div>
              <InputField
                placeholder={t("Enter Heading")}
                name="sucessTitle"
                value={sucessTitle}
                onChange={(e) => setSucessTitle(e.target.value)}
                disabled={!switchButtons.customEmailFlag}
              />
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Congratulations Heading")}
                </span>
                <InfoTooltip
                  title={"Congratulations Heading"}
                />
              </div>
              <InputField
                placeholder={t("Enter Heading")}
                name="successHeading"
                value={successHeading}
                onChange={(e) => setHeadingTxt(e.target.value)}
                disabled={!switchButtons.customEmailFlag}
              />
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Congratulations Massage")}
                </span>
                <InfoTooltip
                  title={"Please enter product title and product transaction id"}
                />
              </div>
              <InputField
                placeholder={t("Enter Massage")}
                name="successMessage"
                value={successMessage}
                onChange={(e) => setSuccessMessage(e.target.value)}
                disabled={!switchButtons.customEmailFlag}
              />
            </div>

            {/* <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">Scanner Code Type</span>
                <InfoTooltip
                  title={"Select QR code or Bar code"}
                />
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center gap-2 w-100">
                <div className="col-6 d-flex flex-row align-items-center gap-2">
                  <input
                    className="form-check-input cursor"
                    type="checkbox"
                    id="checkboxNoLabel"
                    checked={switchButtons.barcodeFlag}
                    onChange={barcodeHandler}
                    disabled={!switchButtons.customEmailFlag}
                  />
                  <span className="font-14 text-muted pt-1">Barcode</span>
                </div>
                <div className="col-6 d-flex flex-row align-items-center gap-2">
                  <input
                    className="form-check-input cursor"
                    type="checkbox"
                    id="checkboxNoLabel"
                    checked={switchButtons.qrCodeFlag}
                    onChange={barcodeHandler}
                    disabled={!switchButtons.customEmailFlag}
                  />
                  <span className="font-14 text-muted pt-1">QR code</span>
                </div>
              </div>
            </div> */}

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Contact Information")}<span className="text-danger">*</span>{" "}
                  <InfoTooltip
                    title={"Contact Information"}
                  />
                </span>

                <span>
                  <ReactSwitchButton
                    onChange={contactInfoFlagHandler}
                    value={switchButtons.contactInfoFlag}
                    disabled={!switchButtons.customEmailFlag}
                  />
                </span>
              </div>
              {switchButtons.contactInfoFlag === true ? (
                <div className=" ">
                  <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100 mb-2">
                    <InputField
                      placeholder={t("Contact Email")}
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={!switchButtons.customEmailFlag}
                    />
                    <InputField
                      placeholder={t("Contact Number")}
                      name="webAddress"
                      value={webAddress}
                      onChange={(e) => setWebAddress(e.target.value)}
                      disabled={!switchButtons.customEmailFlag}
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
                    <InputField
                      placeholder={t("Contact Number")}
                      name="contact_one"
                      value={contact_one}
                      onChange={(e) => setContact_one(e.target.value)}
                      disabled={!switchButtons.customEmailFlag}
                    />
                    <InputField
                      placeholder={t("Contact Number")}
                      name="contact_two"
                      value={contact_two}
                      onChange={(e) => setContact_two(e.target.value)}
                      disabled={!switchButtons.customEmailFlag}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="d-flex flex-row justify-content-between gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Enable/Disable brought to you buy section")}
                </span>
                <InfoTooltip
                  title={"Enable or disable brought to you buy section"}
                />
              </div>
              <ReactSwitchButton
                onChange={broughtYouBuyFlagHandler}
                value={switchButtons.broughtYouBuyFlag}
                disabled={!switchButtons.customEmailFlag}
              />
            </div>
            <div className="d-flex flex-row justify-content-between gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Brand Logo")}</span>
                <InfoTooltip
                  title={"Upload Brand Logo"}
                />
              </div>
              <ReactSwitchButton
                onChange={brandLogoFlagHandler}
                value={switchButtons.brandLogoFlag}
                disabled={!switchButtons.customEmailFlag}
              />
            </div>

            {switchButtons.brandLogoFlag && (
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Logo")}<span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <DragandDrop files={brandImg} setFiles={setBrandImg} />
              </div>
            )}

            <div className="d-flex flex-start gap-3 mb-1 align-items-center">
              <Button
                type={"primary"}
                text={t("Preview")}
                className="rounded-6 my-4 mt-6 fw-bold"
                onClick={() => showEmailModel()}
              />
            </div>
          </div>
        </div>

        <ModalComp
          size="lg"
          showModal={showEmailModal}
          // handleClose={handleClose}
          component={
            <EmailPreviewModel
              handleClose={handleClose}
              emailData={emailData}
            />
          }
        />
      </div>
      {/* ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )} */}
    </>
  );
};

export default CustomEmail;
