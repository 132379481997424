import React, { useEffect } from "react";
import { useNavigate, } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../../assests/svg/Success.svg";
import Button from "../../../components/Button/Button";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MerchandiseOrderSuccess = () => {
  const history = useNavigate()
  const { t } = useTranslation();

  const clientMerRes = useSelector((state) => state?.createOrder?.distMerRespose)


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToHome = () => {
    history('/merchandise-distribution')
  }

  const goToOrderDetail = () => {
    history(`/order-management/gift-card?orderid=${clientMerRes?.id}`)
  }

  return (
    <div className="d-flex flex-column  align-item-center bg-light w-100 py-5">
      <div className="d-flex flex-row w-100 justify-content-center">
        <SuccessIcon />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-dark font-bold font-40">
          {t("Order placed successfully")}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-muted font-bold font-15">
          {t("Order ID")}: {clientMerRes?.id}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"primary"} text={t("Go to Home")} onClick={() => goToHome()} />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"rounded"} text={t("Order details")} onClick={goToOrderDetail} />
      </div>
    </div>
  );
};
export default MerchandiseOrderSuccess;
