//TO POST DATA INTO SERVER

export const REMOVE_CREATE_ORDER_DATA = "REMOVE_CREATE_ORDER_DATA"
export const PUT_CREATEORDER_REQUEST = "PUT_CREATEORDER_REQUEST"
export const PUT_CREATEORDER_SUCCESS = "PUT_CREATEORDER_SUCCESS"
export const PUT_CREATEORDER_FAILURE = "PUT_CREATEORDER_FAILURE"
// TO STORE GIFTCARD  DATA INTO REDUX
export const SET_CREATEORDER_DATA = "SET_CREATEORDER_DATA"
// TO MERCHANDISE DISTRIBUTION  DATA INTO REDUX
export const SET_DISTRIBUTION_OF_MERCHANDISE = "SET_DISTRIBUTION_OF_MERCHANDISE"
//TO POST MERCHANDISE DISTRIBUTION  DATA INTO SERVER
export const PUT_DISTRIBUTION_MERCHANDISE_REQUEST = "PUT_DISTRIBUTION_MERCHANDISE_REQUEST";
export const PUT_DISTRIBUTION_MERCHANDISE_SUCCESS = "PUT_DISTRIBUTION_MERCHANDISE_SUCCESS";
export const PUT_DISTRIBUTION_MERCHANDISE_FAILURE = "PUT_DISTRIBUTION_MERCHANDISE_FAILURE";


export const FETCH_CLIENT_MERCHANDISE_DATA_REQUEST = "FETCH_CLIENT_MERCHANDISE_DATA_REQUEST";
export const FETCH_CLIENT_MERCHANDISE_DATA_SUCCESS = "FETCH_CLIENT_MERCHANDISE_DATA_SUCCESS";
export const FETCH_CLIENT_MERCHANDISE_DATA_FAILURE = "FETCH_CLIENT_MERCHANDISE_DATA_FAILURE";

// export const SET_CLIENT_MERCHANDISE_RESPONSE = "SET_CLIENT_MERCHANDISE_RESPONSE"