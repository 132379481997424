import {
  GET_SAMPLE_BULK_UPLOAD_MER_REQUEST,
  GET_SAMPLE_BULK_UPLOAD_MER_SUCCESS,
  GET_SAMPLE_BULK_UPLOAD_MER_FAILURE,
  POST_MERCHANDISE_BULK_UPLOAD_REQUEST,
  POST_MERCHANDISE_BULK_UPLOAD_SUCCESS,
  POST_MERCHANDISE_BULK_UPLOAD_FAILURE,
  POST_MERCHANDISE_BULK_UPLOAD_ERROR,
  CLEAR_MERCHANDISE_BULK_UPLOAD_ERROR,
  GET_SAMPLE_BULK_UPLOAD_GIFTCARD_REQUEST,
  GET_SAMPLE_BULK_UPLOAD_GIFTCARD_SUCCESS,
  GET_SAMPLE_BULK_UPLOAD_GIFTCARD_FAILURE,
  POST_GIFTCARD_BULK_UPLOAD_REQUEST,
  POST_GIFTCARD_BULK_UPLOAD_SUCCESS,
  POST_GIFTCARD_BULK_UPLOAD_FAILURE,
  CLEAR_GIFTCARD_BULK_UPLOAD_ERROR,
  POST_GIFTCARD_BULK_UPLOAD_ERROR,
} from "./bulkUploadType";

const initialManualInventoryState = {
  loading: false,
  bulkUploadMerchandise: [],
  bulkUploadMerchandiseResponse: null,
  bulkUploadMerchandiseErros: [],
  bulkuploadGiftcard: [],
  bulkuploadGiftcardResponse: null,
  bulkuploadGiftcardErrors: [],
  error: "",
};

const bulkUploadReducer = (state = initialManualInventoryState, action) => {
  switch (action.type) {
    case GET_SAMPLE_BULK_UPLOAD_MER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SAMPLE_BULK_UPLOAD_MER_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkUploadMerchandise: action.payload,
        error: "",
      };
    case GET_SAMPLE_BULK_UPLOAD_MER_FAILURE:
      return {
        ...state,
        loading: false,
        bulkUploadMerchandise: [],
        error: action.payload,
      };
    case GET_SAMPLE_BULK_UPLOAD_GIFTCARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SAMPLE_BULK_UPLOAD_GIFTCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkuploadGiftcard: action.payload,
        error: "",
      };
    case GET_SAMPLE_BULK_UPLOAD_GIFTCARD_FAILURE:
      return {
        ...state,
        loading: false,
        bulkuploadGiftcard: [],
        error: action.payload,
      };

    case POST_MERCHANDISE_BULK_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case POST_MERCHANDISE_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkUploadMerchandiseResponse: action.payload,
        error: "",
      };

    case POST_MERCHANDISE_BULK_UPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        bulkUploadMerchandiseResponse: [],
        error: action.payload,
      };

    case POST_MERCHANDISE_BULK_UPLOAD_ERROR:
      return {
        ...state,
        loading: false,
        bulkUploadMerchandiseResponse: [],
        bulkUploadMerchandiseErros: action.payload,
        error: "",
      };

    case CLEAR_MERCHANDISE_BULK_UPLOAD_ERROR:
      return {
        ...state,
        loading: false,
        bulkUploadMerchandiseResponse: [],
        bulkUploadMerchandiseErros: [],
        error: "",
      };

    case POST_GIFTCARD_BULK_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case POST_GIFTCARD_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkuploadGiftcard: action.payload,
        error: "",
      };

      case POST_GIFTCARD_BULK_UPLOAD_FAILURE:
        return {
          ...state,
          loading: false,
          bulkuploadGiftcard: [],
          error: action.payload,
        };

        case POST_GIFTCARD_BULK_UPLOAD_ERROR:
          return {
            ...state,
            loading: false,
            bulkUploadMerchandiseResponse: [],
            bulkUploadMerchandiseErros: [],
            bulkuploadGiftcardResponse: [],
            bulkuploadGiftcardErrors: action.payload,
            error: "",
          };

          case CLEAR_GIFTCARD_BULK_UPLOAD_ERROR:
            return {
              ...state,
              loading: false,
              bulkuploadGiftcardResponse: [],
              bulkuploadGiftcardErrors: [],
              error: "",
            };

    default:
      return state;
  }
};

export default bulkUploadReducer;
