export const FETCH_SUPPLIER_MAPPING_REQUEST= "FETCH_SUPPLIER_MAPPING_REQUEST";
export const FETCH_SUPPLIER_MAPPING_SUCCESS= "FETCH_SUPPLIER_MAPPING_SUCCESS";
export const FETCH_SUPPLIER_MAPPING_FAILURE= "FETCH_SUPPLIER_MAPPING_FAILURE";

// to put supplier mapping data 
export const PUT_SUPPLIER_MAPPING_REQUEST= "PUT_SUPPLIER_MAPPING_REQUEST";
export const PUT_SUPPLIER_MAPPING_SUCCESS= "PUT_SUPPLIER_MAPPING_SUCCESS";
export const PUT_SUPPLIER_MAPPING_FAILURE= "PUT_SUPPLIER_MAPPING_FAILURE";

// to get merchandise vaiants by merchandise id 
export const GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID= "GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID";
// to get giftcard mapping with supplier 
export const GET_GIFTCARD_SUPPLIER_MAPPING_REQUEST= "GET_GIFTCARD_SUPPLIER_MAPPING_REQUEST";
export const GET_GIFTCARD_SUPPLIER_MAPPING_SUCCESS= "GET_GIFTCARD_SUPPLIER_MAPPING_SUCCESS";
export const GET_GIFTCARD_SUPPLIER_MAPPING_FAILURE= "GET_GIFTCARD_SUPPLIER_MAPPING_FAILURE";
// to put giftcard mapping with supplier data 
export const PUT_GIFTCARD_SUPPLIER_MAPPING_REQUEST= "PUT_GIFTCARD_SUPPLIER_MAPPING_REQUEST";
export const PUT_GIFTCARD_SUPPLIER_MAPPING_SUCCESS= "PUT_GIFTCARD_SUPPLIER_MAPPING_SUCCESS";
export const PUT_GIFTCARD_SUPPLIER_MAPPING_FAILURE= "GET_GIFTCARD_SUPPLIER_MAPPING_FAILURE";