import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import Button from "../Button/Button";
import InfoTooltip from "../Tooltip/InfoTooltip";
import BackButton from "../Button/BackButton";
import Select from "react-select";
import TagsInput from "../../components/TagsInput/TagsInput";
import { useDispatch, useSelector } from "react-redux";
import { setCategoryAttributes } from "../../Redux/GiftCard/giftcardAction";
import { fetchAllCountrieDetails } from "../../Redux/Countries/countryAction";
import ReactDatePicker from "../DatePicker/ReactDatePicker";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import ReactSwitchButton from "../Button/ReactSwitchButton";
import {
  GIFTCARD_REDEMPTION_AMOUNT,
  PREFIX_TEXT_LIMIT,
  VALIDITY_UNITS,
} from "../../utils/ConstantData";
import { nextYearRange } from "../../utils/constantFunction";
import { fetchBrandCategoryByID } from "../../Redux/BrandCategories/brandCategoryAction";
import { useTranslation } from "react-i18next";

const ProductCategory = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  const inputElementGiftcardType = useRef(null);
  const inputElementCountry = useRef(null);
  const inputElementBrandName = useRef(null);
  const inputElementRedemAmount = useRef(null);

  const nameDescState = useSelector((state) => state?.giftcard?.cardNameDesc);
  const giftcardtype = useSelector((state) => state.giftcardtype?.giftcardtype);
  const countries = useSelector((state) => state?.countries?.countries);
  const countrieDetails = useSelector(
    (state) => state?.countries?.countrieDetails
  );
  const brands = useSelector((state) => state?.brands?.brands);
  const giftCardNumberLength = useSelector(
    (state) => state?.giftcardlengths?.giftcardlength
  );
  const redemptionMethod = useSelector(
    (state) => state?.redemptionmethods?.redemptionMethods
  );
  const activations = useSelector(
    (state) => state?.activationrequired?.activations
  );
  const catAttributeState = useSelector(
    (state) => state?.giftcard?.cardCatAttribute
  );
  const brandCategories = useSelector(
    (state) => state?.brandCategories?.brandCategoriesbyID
  );
  const giftcardPrefix = useSelector(
    (state) => state?.giftcardprefix?.giftcardprefix
  );
  const states = useSelector(
    (state) => state?.countries?.countrieDetails?.states
  );

  // tags input code
  const [tags, setTags] = useState(
    catAttributeState?.tags ? catAttributeState?.tags : []
  );

  const [productCatObj, setProductCatObj] = useState({
    giftcardtype: catAttributeState?.giftcardtype
      ? catAttributeState?.giftcardtype
      : "",
    partner: catAttributeState?.partner ? catAttributeState?.partner : [],
    organization: catAttributeState?.organization
      ? catAttributeState?.organization
      : "",
    region: catAttributeState?.region ? catAttributeState?.region : "",
    state: catAttributeState?.state ? catAttributeState?.state : "",
    countryCurrency: catAttributeState?.countryCurrency
      ? catAttributeState?.countryCurrency
      : "",
    brand: catAttributeState?.brand ? catAttributeState?.brand : "",
    brandCategory: catAttributeState?.brandCategory
      ? catAttributeState?.brandCategory
      : "",
    startDate: catAttributeState?.startDate
      ? catAttributeState?.startDate
      : new Date(),
    expireDate: catAttributeState?.expireDate
      ? catAttributeState?.expireDate
      : nextYearRange(),
    giftCardLength: catAttributeState?.giftCardLength
      ? catAttributeState?.giftCardLength
      : "",
    binNumber: catAttributeState?.binNumber ? catAttributeState?.binNumber : "",
    prefixGiftCard: catAttributeState?.prefixGiftCard
      ? catAttributeState?.prefixGiftCard
      : "",
    prefixValue: catAttributeState?.prefixValue
      ? catAttributeState?.prefixValue
      : "",
    validity: catAttributeState?.validity ? catAttributeState?.validity : "",
    validityValue: catAttributeState?.validityValue
      ? catAttributeState?.validityValue
      : "",
    redemptionMethod: catAttributeState?.redemptionMethod
      ? catAttributeState?.redemptionMethod
      : "",
    activation: catAttributeState?.activation
      ? catAttributeState?.activation
      : "",
    redemptionAmount: catAttributeState?.redemptionAmount
      ? catAttributeState?.redemptionAmount
      : "",
  });

  const [isFeaturedFlag, setIsFeaturedFlag] = useState(
    catAttributeState?.isFeaturedFlag
      ? catAttributeState?.isFeaturedFlag
      : false
  );

  const [activeFlag, setActiveFlag] = useState(
    catAttributeState?.activeFlag ? catAttributeState?.activeFlag : true
  );

  const [tillLifetime, setTillLifetime] = useState(true);

  const [error, setError] = useState({
    countryCurrency: "",
    giftcardtype: "",
    brand: "",
    redemptionAmount: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!nameDescState) {
      history("/product-creation/name-description");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameDescState]);

  const handleChangeValue = (item, type) => {
    setProductCatObj({ ...productCatObj, [type]: item });
    if (type === "countryCurrency") {
      dispatch(fetchAllCountrieDetails(item.iso2));
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let regex = /^\d{0,4}(\.\d{0,4})?$/;
    if (e.target.name === "prefixValue") {
      setProductCatObj({ ...productCatObj, [name]: value.toUpperCase() });
    } else {
      if (regex.test(e.target.value)) {
        setProductCatObj({ ...productCatObj, [name]: value });
      }
    }
  };

  const validate = () => {
    if (!productCatObj.giftcardtype) {
      setError({ giftcardtype: t("Giftcard type field is required") });
      if (inputElementGiftcardType.current) {
        inputElementGiftcardType.current.focus();
      }
      return false;
    } else if (!productCatObj.countryCurrency) {
      setError({ countryCurrency: t("Country field is required") });
      if (inputElementCountry.current) {
        inputElementCountry.current.focus();
      }
      return false;
    } else if (!productCatObj.brand) {
      setError({ brand: t("Brand field is required") });
      if (inputElementBrandName.current) {
        inputElementBrandName.current.focus();
      }
      return false;
    } else if (!productCatObj.redemptionAmount) {
      setError({ redemptionAmount: t("Redemption amount field is required") });
      if (inputElementRedemAmount.current) {
        inputElementRedemAmount.current.focus();
      }
      return false;
    }
    return true;
  };

  const submit = () => {
    let val = validate();
    if (val) {
      let obj = {
        giftcardtype: productCatObj.giftcardtype,
        organization: productCatObj.organization,
        region: countrieDetails?.region,
        state: productCatObj.state,
        countryCurrency: productCatObj.countryCurrency,
        brand: productCatObj.brand,
        brandCategory: productCatObj.brandCategory,
        startDate: productCatObj.startDate,
        expireDate: productCatObj.expireDate,
        giftCardLength: productCatObj.giftCardLength,
        binNumber: productCatObj.binNumber,
        prefixGiftCard: productCatObj.prefixGiftCard,
        prefixValue: productCatObj.prefixValue,
        validity: productCatObj.validity,
        validityValue: productCatObj.validityValue,
        redemptionMethod: productCatObj.redemptionMethod,
        activation: productCatObj.activation,
        tags: tags,
        isFeaturedFlag: isFeaturedFlag,
        activeFlag: activeFlag,
        redemptionAmount: productCatObj?.redemptionAmount,
      };
      dispatch(setCategoryAttributes(obj));
      history("/product-creation/pricing");
    }
  };

  const handleChangeGiftcardtype = (e) => {
    setProductCatObj({ ...productCatObj, giftcardtype: e });
  };

  // const handleChangePartner = (selectedList, selectedItem) => {
  //   setSupplier(selectedList);
  // };

  // const handleRemovePartner = (selectedList, removedItem) => {
  //   setSupplier(selectedList);
  // };

  const handleChangeCountries = (e) => {
    setProductCatObj({ ...productCatObj, countryCurrency: e, state: "" });
    e && dispatch(fetchAllCountrieDetails(e.id));
  };

  const handleChangeStates = (e) => {
    setProductCatObj({ ...productCatObj, state: e });
  };

  const handleChangeBrands = (e) => {
    setProductCatObj({ ...productCatObj, brand: e, brandCategory: "" });
    e && dispatch(fetchBrandCategoryByID(e.id));
  };

  const handleChangeBrandCategories = (e) => {
    setProductCatObj({ ...productCatObj, brandCategory: e });
  };

  const handleChangeGiftCardLength = (e) => {
    setProductCatObj({ ...productCatObj, giftCardLength: e });
  };

  const handleChangeRedemptionMethod = (e) => {
    setProductCatObj({ ...productCatObj, redemptionMethod: e });
  };

  const handleChangeRedemptionAmount = (e) => {
    setProductCatObj({ ...productCatObj, redemptionAmount: e });
  };

  const handleChangeActivations = (e) => {
    setProductCatObj({ ...productCatObj, activation: e });
  };

  const isFeaturedHandle = () => {
    setIsFeaturedFlag(!isFeaturedFlag);
  };

  const activeHandle = () => {
    setActiveFlag(!activeFlag);
  };

  const tillLifetimeHandle = () => {
    setTillLifetime(!tillLifetime);
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">
            {t("Category & Attributes")}
          </span>
        </div>
        <div>
          <BackButton onClick={() => history(-1)} />
        </div>
      </div>

      <div className="d-flex flex-column gap-4 mt-4">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              {t("Giftcard Type")}
              <span className="text-danger">*</span>
            </span>
            <InfoTooltip title={"Select Giftcard Type"} />
          </div>
          {/* Dropdown supplier*/}
          <div>
            <Select
              ref={inputElementGiftcardType}
              noOptionsMessage={() => "Not found"}
              placeholder={t("Select giftcard type")}
              getOptionLabel={(giftcardtype) => giftcardtype?.type_name}
              options={giftcardtype}
              //   isSearchable
              isClearable
              styles={CUSTOM_DROPDOWN_STYLE}
              value={productCatObj.giftcardtype}
              onChange={handleChangeGiftcardtype}
            />
            {!productCatObj.giftcardtype && error.giftcardtype ? (
              <label className="text-error">{error.giftcardtype}</label>
            ) : null}
          </div>
        </div>

        {/*  <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">Supplier</span>
            <InfoTooltip
              title={"Enter your Preferred language or select from list"}
            />
          </div>*/}
        {/* Dropdown supplier Multiselect*/}
        {/*   <div>
            <Multiselect
              placeholder="Select suppliers"
              options={suppliers}
              showCheckbox={true}
              selectedValues={supplier}
              onSelect={handleChangePartner}
              onRemove={handleRemovePartner}
              displayValue="partner_name"
            />
          </div>
        </div>*/}
        {/* <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">Organization</span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
           <div className="btn-group w-100">
            <div
              className="btn border border-2 rounded-card-7 d-flex justify-content-between align-items-center py-2"
              data-bs-toggle="dropdown"
            >
              {productCatObj?.partner?.company_name
                ? productCatObj?.partner?.company_name
                : "Select"}
            </div>
          </div>
        </div> */}
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              {t("Country & Currency")}
              <span className="text-danger">*</span>
            </span>
            <InfoTooltip title={"Select Country & Currency"} />
          </div>
          {/* Dropdown countryCurrency*/}
          <div className="d-flex flex-column gap-1">
            <Select
              ref={inputElementCountry}
              noOptionsMessage={() => "Not found"}
              placeholder={t("Select country & currency")}
              getOptionLabel={(countries) =>
                countries.name + " -" + countries.currency
              }
              options={countries}
              //   isSearchable
              value={productCatObj?.countryCurrency}
              onChange={handleChangeCountries}
              isClearable
              styles={CUSTOM_DROPDOWN_STYLE}
            />
            {!productCatObj.countryCurrency && error.countryCurrency ? (
              <label className="text-error">{error.countryCurrency}</label>
            ) : null}
          </div>
        </div>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              {t("Business Unit/Region")}
            </span>
            <InfoTooltip title={"Business Unit/Region"} />
          </div>

          {/* Dropdown region*/}
          <div className="btn-group w-100">
            <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
              {countrieDetails?.region ? countrieDetails?.region : t("Select")}
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">{t("State")}</span>
            <InfoTooltip title={"State"} />
          </div>
          {/* Dropdown state*/}
          <div>
            <Select
              noOptionsMessage={() => "Not found"}
              placeholder={t("Select state")}
              getOptionLabel={(states) => states.name}
              options={states}
              //   isSearchable
              value={productCatObj.state}
              onChange={handleChangeStates}
              isClearable
              styles={CUSTOM_DROPDOWN_STYLE}
              isDisabled={!productCatObj?.countryCurrency}
            />
          </div>
        </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row justify-content-between align-items-center gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Brand")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip title={"Select Brand name"} />
            </div>
            <span
              className="font-bold font-14 link-text-color"
              onClick={() =>
                history("/brand-creation", { state: { productCreation: true } })
              }
            >
              {t("Add Brand")}
            </span>
          </div>
          {/* Dropdown brand*/}
          <div className="d-flex flex-column gap-1">
            <Select
              ref={inputElementBrandName}
              noOptionsMessage={() => "Not found"}
              placeholder={t("Select brand")}
              getOptionLabel={(brands) => brands.default_name}
              options={brands}
              //   isSearchable
              value={productCatObj.brand}
              onChange={handleChangeBrands}
              isClearable
              styles={CUSTOM_DROPDOWN_STYLE}
            />
            {!productCatObj.brand && error.brand ? (
              <label className="text-error">{error.brand}</label>
            ) : null}
          </div>
        </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row justify-content-between align-items-center gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Brand category")}</span>
              <InfoTooltip title={"Select Brand name"} />
            </div>
            <span
              className="font-bold font-14 link-text-color"
              onClick={() =>
                history("/category-creation", {
                  state: { productCreation: true },
                })
              }
            >
              {t("Add Brand Category")}
            </span>
          </div>
          {/* Dropdown brandCategory*/}
          <div>
            <Select
              noOptionsMessage={() => "Not found"}
              placeholder={t("Select brand categories")}
              getOptionLabel={(brandCategories) =>
                brandCategories?.default_category_name
              }
              options={brandCategories}
              //   isSearchable
              value={productCatObj.brandCategory}
              onChange={handleChangeBrandCategories}
              isClearable
              styles={CUSTOM_DROPDOWN_STYLE}
              isDisabled={!productCatObj?.brand}
            />
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              {t("Product remains active for lifetime")}
            </span>
            <InfoTooltip title={"Active/Inactive"} />
          </div>
          <ReactSwitchButton
            onChange={() => tillLifetimeHandle()}
            value={tillLifetime}
          />
        </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row justify-content-start align-items-center gap-2 w-100">
            <div className="col d-flex flex-column gap-2">
              <span className="fs-lighter font-14 font-bold">
                {t("Select start date")}
              </span>
              <ReactDatePicker
                onChange={handleChangeValue}
                selected={productCatObj.startDate}
                minDate={new Date()}
                maxDate={productCatObj.expireDate}
                name={"startDate"}
                lang={props?.lang}
                className="datepicker-input-style-without-border"
              />
            </div>
            {tillLifetime === false ? (
              <div className="col d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {" "}
                    {t("Select end date")}
                  </span>
                  <InfoTooltip
                    title={
                      "Keep blank, if you want to create a gift card with out end date"
                    }
                  />
                </div>

                <ReactDatePicker
                  onChange={handleChangeValue}
                  selected={productCatObj.expireDate}
                  minDate={productCatObj.startDate}
                  name={"expireDate"}
                  lang={props?.lang}
                  className="datepicker-input-style-without-border"
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">{t("Validity")}</span>
            <InfoTooltip
              title={"Keep blank for product to stay active forever"}
            />
          </div>
          {/* Dropdown prefixGiftCard*/}
          <div className="input-group rounded-card-7">
            <button
              className={`btn btn-outline-secondary dropdown-toggle border border-2 ${
                props?.lang === "ar"
                  ? "prefix-dropdown-right-corner-ar"
                  : "prefix-dropdown-left-corner"
              }`}
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {productCatObj.validity
                ? productCatObj.validity?.name
                : t("Select")}
            </button>
            <ul
              className={` ${
                props?.lang === "ar"
                  ? "dropdown-menu dropdown-menu-ar"
                  : "dropdown-menu"
              }`}
            >
              {VALIDITY_UNITS &&
                VALIDITY_UNITS.map((item, i) => (
                  <li
                    key={i}
                    onClick={() => handleChangeValue(item, "validity")}
                  >
                    <div className="dropdown-item cursor">{item.name}</div>
                  </li>
                ))}
            </ul>
            <input
              type={"number"}
              name="validityValue"
              placeholder={t("Validity of gift card")}
              className={`form-control border border-2 ${
                props?.lang === "ar"
                  ? "prefix-dropdown-left-corner-ar"
                  : "prefix-dropdown-right-corner"
              }`}
              value={productCatObj.validityValue}
              disabled={!productCatObj.validity?.name}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              {t("Gift card number length")}
            </span>
            <InfoTooltip title={"Select Gift card number length"} />
          </div>
          {/* Dropdown giftCardLength*/}
          <div>
            <Select
              noOptionsMessage={() => "Not found"}
              placeholder={t("Select giftcard number length")}
              getOptionLabel={(giftCardNumberLength) =>
                giftCardNumberLength.gift_card_length
              }
              options={giftCardNumberLength}
              //   isSearchable
              value={productCatObj.giftCardLength}
              onChange={handleChangeGiftCardLength}
              isClearable
              styles={CUSTOM_DROPDOWN_STYLE}
            />
          </div>
        </div>
        {/*  <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">BIN number</span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <div className="d-flex flex-column gap-1">
            <InputField
              onChange={handleInputChange}
              name={"binNumber"}
              placeholder={"Enter BIN number"}
              type="text"
              value={productCatObj.binNumber}
            />
          </div>
        </div>*/}
        {productCatObj.giftcardtype?.type_name === "Powered by eHadaya" ? (
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Prefix of gift card")}
              </span>
              <InfoTooltip title={"Prefix of gift card"} />
            </div>
            {/* Dropdown prefixGiftCard*/}
            <div className="input-group rounded-card-7">
              <button
                className={`btn btn-outline-secondary dropdown-toggle border border-2 ${
                  props?.lang === "ar"
                    ? "prefix-dropdown-right-corner-ar"
                    : "prefix-dropdown-left-corner"
                }`}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {productCatObj.prefixGiftCard
                  ? productCatObj.prefixGiftCard?.giftcard_prefix
                  : "Select"}
              </button>
              <ul
                className={` ${
                  props?.lang === "ar"
                    ? "dropdown-menu dropdown-menu-ar"
                    : "dropdown-menu"
                }`}
              >
                {giftcardPrefix &&
                  giftcardPrefix.map((item, i) => (
                    <li
                      key={i}
                      onClick={() => handleChangeValue(item, "prefixGiftCard")}
                    >
                      <div className="dropdown-item cursor">
                        {item.giftcard_prefix}
                      </div>
                    </li>
                  ))}
              </ul>
              <input
                type={
                  productCatObj?.prefixGiftCard?.giftcard_prefix === "Numeric"
                    ? "number"
                    : "text"
                }
                name="prefixValue"
                placeholder={"Prefix of gift card"}
                className={`form-control border border-2 ${
                  props?.lang === "ar"
                    ? "prefix-dropdown-left-corner-ar"
                    : "prefix-dropdown-right-corner"
                }`}
                maxLength={PREFIX_TEXT_LIMIT}
                value={productCatObj.prefixValue}
                disabled={!productCatObj.prefixGiftCard?.giftcard_prefix}
                onChange={handleInputChange}
              />
            </div>
          </div>
        ) : null}

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">{t("Redemption method")}</span>
            <InfoTooltip title={"Select Redemption method"} />
          </div>
          {/* Dropdown redemptionMethod*/}
          <div>
            <Select
              noOptionsMessage={() => "Not found"}
              placeholder={t("Select redemption method")}
              getOptionLabel={(redemptionMethod) =>
                redemptionMethod.redemption_method
              }
              options={redemptionMethod}
              //   isSearchable
              value={productCatObj.redemptionMethod}
              onChange={handleChangeRedemptionMethod}
              isClearable
              styles={CUSTOM_DROPDOWN_STYLE}
            />
          </div>
        </div>

        {productCatObj?.giftcardtype?.id !== "3rd_party_suppliers" ? (
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Redemption Amount")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip title={"Select Redemption amount"} />
            </div>
            {/* Dropdown redemptionMethod*/}
            <div>
              <Select
                ref={inputElementRedemAmount}
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select redemption amount")}
                getOptionLabel={(e) => e.name}
                options={GIFTCARD_REDEMPTION_AMOUNT}
                //   isSearchable
                value={productCatObj.redemptionAmount}
                onChange={handleChangeRedemptionAmount}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
              />
              {!productCatObj.redemptionAmount && error.redemptionAmount ? (
                <label className="text-error">{error.redemptionAmount}</label>
              ) : null}
            </div>
          </div>
        ) : null}

        {productCatObj?.giftcardtype?.type_name !== "Manual Inventory" ? (
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Activation Required")}
              </span>
              <InfoTooltip title={"Select Activation Required"} />
            </div>
            {/* Dropdown activation*/}
            <div>
              <Select
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select activation")}
                getOptionLabel={(activations) =>
                  activations.activation_required
                }
                options={activations}
                // isSearchable
                value={productCatObj.activation}
                onChange={handleChangeActivations}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
              />
            </div>
          </div>
        ) : null}
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">{t("Tags")}</span>
            <InfoTooltip title={"Enter tags(Attributes of products)"} />
          </div>
          <div className="d-flex flex-row align-items-center gap-2">
            <TagsInput
              tags={tags}
              setTags={setTags}
              placeholder={t("Enter tags to describe your item")}
            />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">{t("Is featured")}</span>
            <InfoTooltip title={"Is featured"} />
          </div>
          <ReactSwitchButton
            onChange={() => isFeaturedHandle()}
            value={isFeaturedFlag}
          />
        </div>
        <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">{t("Active")}</span>
            <InfoTooltip title={"Active/Inactive"} />
          </div>
          <ReactSwitchButton
            onChange={() => activeHandle()}
            value={activeFlag}
          />
        </div>

        <div className="d-flex my-2">
          <Button
            onClick={() => submit()}
            text={t("Save & Next")}
            type="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCategory;
