import React from "react";
import { ReactComponent as BackIcon } from "../../assests/svg/Products/left.svg"
import { useTranslation } from "react-i18next";

const BackButton = (props) => {
    const { t } = useTranslation();
    const { onClick } = props
    return (
        <>
            <div className="d-flex flex-row align-items-center btn border border-2 rounded-card-7 gap-2" onClick={() => onClick()}>
                <BackIcon />
                <span>{t("Back")}</span>
            </div>
        </>
    );
};

export default BackButton;
