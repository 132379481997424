import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import applePhones from "../../assests/img/apple_colored.png";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import InputGroupTxt from "../../components/InputField/InputGroupTxt";
import Toasts from "../../components/Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../../components/Button/BackButton";
import Dropdown from "react-bootstrap/Dropdown";
import { putSupplierGiftcardMappingProducts } from "../../Redux/SupplierMapping/SupplierMappingAction";

const MappingGiftcardWithSupplier = () => {
  const location = useLocation();

  const history = useNavigate();
  const dispatch = useDispatch()
  const merchandiseVariants = useSelector(
    (state) => state?.SupplierMappingData?.giftcards
  );
  const merchandiseName = useSelector(
    (state) =>
      state?.SupplierMappingData?.giftcards?.product_title[0]
        .title
  );
  const [products, setProducts] = useState([merchandiseVariants]);
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
    let arr = [...products]
    // eslint-disable-next-line array-callback-return
    location.state.selectedSupplier.map((supplierItem) => {
      // eslint-disable-next-line array-callback-return
      arr.map((item, i) => {
        item.supplier = location.state.selectedSupplier[0];
        item.supplierSKU = "";
        item.supplierPrice = "";

      });
    })

    setProducts(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  // Selecting Supplier Object
  const handleChangeValue = (supObj, index) => {
    let arr = [...products];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === index) {
        arr[i].supplier = supObj;
      }
    });
    setProducts(arr);

  };
  // Add new row
  const addRow = (item, index) => {
    let arr = [...products];

    arr.map((item, i) => {
      if (index === i) {
        item.isChecked = false
      }
    })
    arr.unshift(item);
    setProducts(arr);
  };
  // delete row
  const deleteRow = (index) => {
    let arr = [...products]
    if (index > -1) {
      arr.splice(index, 1)
    }
    setProducts(arr)
  }

  const goBack = () => {
    history("/supplier-mapping");
  };

  const goToNextStep = () => {
    let arr = [...products];
    let selectedProducts = [];
    // eslint-disable-next-line array-callback-return
    arr.map((item) => {
      if (item.isChecked === true) {
        selectedProducts.push(item);
      }
    });
    if (selectedProducts.length > 0) {
      dispatch(putSupplierGiftcardMappingProducts(selectedProducts))
      history("/supplier-map-successfull");
    } else {
      toggleShowToast();
    }
  };

  // Getting Supplier SKU Value
  const inputChangeHandler = (e) => {
    let skuValue = (e.target.value = e.target.value.toUpperCase());
    let index = e.target.name;
    let arr = [...products];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === parseInt(index)) {
        arr[i].supplierSKU = skuValue;
      }
    });
    setProducts(arr);
  };

  const priceInputeHandler = (e) => {
    let supplierPriceValue = e.target.value;
    let index = e.target.name;
    let arr = [...products];

    let regex = /^\d{0,10}(\.\d{0,2})?$/

    if (regex.test(e.target.value)) {
      // eslint-disable-next-line array-callback-return
      arr.map((item, i) => {
        if (i === parseInt(index)) {
          arr[i].supplierPrice = supplierPriceValue;
        }
      });
      setProducts(arr);
    }
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...products];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === parseInt(name)) {
        item.isChecked = checked;
      }
    });
    setProducts(arr);
  };


  const allCheckedHandle = (e) => {
    const { checked } = e.target
    let allSelect = [...products];
    // eslint-disable-next-line array-callback-return
    allSelect && allSelect.map((item, i) => {
      allSelect[i].isChecked = checked
    })
    setProducts(allSelect)
  }
  // custom three dots
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a to="#" ref={ref} onClick={e => { e.preventDefault(); onClick(e); }}>
      {children}
      <span className="threedots" />
    </a>

  ));

  return (
    products && (
      <div className="d-flex flex-column justify-content-between gap-3 py-4 pb-3 px-4 bg-light">
        <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
          <div className="d-flex flex-row align-items-center pb-2 gap-3">
            <LeftIcon onClick={() => goBack()} className="cursor" />
            <span className="font-bold font-32 my-2">Supplier mapping</span>
          </div>
        </div>

        <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100 gap-3">
          <div className="card-body d-flex justify-content-between flex-row">
            <div className="d-flex gap-3 my-2">
              <Tag />
              <span className="font-20 font-bold">Mapping giftcard</span>
            </div>
            <div>
              <BackButton onClick={() => history(-1)} />
            </div>
          </div>
          <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
            <table responsive="xl">
              <tbody>
                <tr className="col">
                  <td className="col-4">
                    <div className="d-flex flex-row justify-content-start text-dark gap-3">
                      <span>
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="checkboxNoLabel"
                          checked={products.filter(product => product?.isChecked !== true).length < 1}
                          onChange={allCheckedHandle}
                        />
                      </span>
                      <span className="mx-2"></span>
                      <span className="font-16 font-boldest text-dark">
                        Name
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div>
                      <span className="font-16 font-boldest text-dark">
                        Supplier
                      </span>
                    </div>
                  </td>
                  <td className="col-4">
                    <div>
                      <span className="font-16 font-boldest text-dark">
                        Suppliers SKU
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div>
                      <span className="font-16 font-boldest text-dark">
                        Supplier Price
                      </span>
                    </div>
                  </td>
                </tr>
                {/* {products && products.map((item, index) => ( */}
                {products &&
                  products?.map((item, index) => (
                    <tr key={index} className="gap-2">
                      <td className="col-4">
                        <div className="d-flex flex-row justify-content-start align-items-center py-3 gap-3">
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="checkboxNoLabel"
                            checked={item.isChecked}
                            name={index}
                            onChange={handleCheck}
                          />
                          <img
                            src={applePhones}
                            alt="not found"
                            className="img-fluid"
                          />
                          <div className="d-flex flex-column align-items-start">
                            <span className="font-bold text-dark font-15">
                              {merchandiseName}
                            </span>
                            <span className="font-12 text-muted">
                              {item?.id}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="col-2">
                        <div className="d-flex">
                          <div className="btn-group">
                            <div
                              className="btn  border border-2 rounded-card-7 dropdown-toggle text-muted"
                              data-bs-toggle="dropdown"
                            >
                              {item?.supplier
                                ? item?.supplier?.partner_name
                                : "Select"}
                              {/* {supplierMappingObj.map((item,i) => item.supplier ? item.supplier.partner_name : "")} */}
                            </div>
                            <ul className="dropdown-menu rounded-card-7 dropdown-menu-end ">
                              {location?.state?.selectedSupplier.map(
                                (supplierItem, i) => (
                                  <li
                                    key={i}
                                    onClick={() =>
                                      handleChangeValue(supplierItem, index)
                                    }
                                  >
                                    <div className="dropdown-item">
                                      {supplierItem?.partner_name}
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td className="col-4">
                        <div className="width-80">
                          <InputField
                            name={index}
                            placeholder="Supplier product SKU"
                            type="text"
                            maxLength={20}
                            onChange={inputChangeHandler}
                          />
                        </div>
                      </td>
                      <td className="col-2">
                        <div>
                          <InputGroupTxt
                            name={index}
                            title={"$"}
                            placeholder={"Price"}
                            type="text"
                            maxLength={6}
                            value={item.supplierPrice}
                            onChange={priceInputeHandler}
                          />
                        </div>
                      </td>

                      <td className="col-2">
                        <div className="d-flex flex-row px-2 cursor gap-2">
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title="">
                              {/* <Dropdown.Item className="cursor">Edit</Dropdown.Item>*/}
                              <Dropdown.Item className="cursor" onClick={() => deleteRow(index)}>Delete</Dropdown.Item>
                              <Dropdown.Item onClick={() => addRow(item, index)}>Add new row</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex">
          <Button
            type="primary"
            text="Save and Exit"
            onClick={() => goToNextStep()}
          />
        </div>
        <div
          className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
          id="liveToast"
        >
          <Toasts
            position={"bottom-center"}
            showToast={showToast}
            toggleShowToast={toggleShowToast}
            warningMsg="Please select giftcards/products to map."
            imageType={"warning"}
          />
        </div>
      </div>
    )
  );

}

export default MappingGiftcardWithSupplier