import {
  GET_PARTNER_FLOAT_FAILURE,
  GET_PARTNER_FLOAT_REQUEST,
  GET_PARTNER_FLOAT_SUCCESS,
  GET_CLIENT_ALLFLOAT_TRANS_REQUEST,
  GET_CLIENT_ALLFLOAT_TRANS_SUCCESS,
  GET_CLIENT_ALLFLOAT_TRANS_FAILURE,
  POST_FLOAT_TRANSACTION_FAILURE,
  POST_FLOAT_TRANSACTION_SUCCESS,
  POST_FLOAT_TRANSACTION_REQUEST,
  GET_ALL_CLIENT_TRANS_REQUEST,
  GET_ALL_CLIENT_TRANS_SUCCESS,
  GET_ALL_CLIENT_TRANS_FAILURE,
  FLOAT_FILTER_DATA,
  CLEAR_FLOAT_TRANSACTION_LIST
} from "./floatType";

const initialState = {
  loading: false,
  floats: [],
  clientAllFloatTransactions: [],
  clientAllTransactions: [],
  floatTransactionResponce: null,
  error: "",
  next_iteration: true,
  floatTransactionFilter: []
};

const floatReducer = (state = initialState, action) => {
  switch (action.type) {
    case FLOAT_FILTER_DATA:
      return {
        ...state,
        floatTransactionFilter: action.payload,

      };
    case GET_PARTNER_FLOAT_REQUEST:
      return {
        ...state,
        loading: true,
        floats: [],
        error: "",
      };
    case GET_PARTNER_FLOAT_SUCCESS:
      return {
        ...state,
        loading: false,
        floats: action.payload,
        error: "",
      };
    case GET_PARTNER_FLOAT_FAILURE:
      return {
        ...state,
        loading: false,
        floats: [],
        error: action.payload,
      };
    // to get client all float transactions
    case GET_CLIENT_ALLFLOAT_TRANS_REQUEST:
      return {
        ...state,
        loading: true,
        clientAllFloatTransactions: [],
        error: "",
      };
    case GET_CLIENT_ALLFLOAT_TRANS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientAllFloatTransactions: action.payload,
        error: "",
      };
    case GET_CLIENT_ALLFLOAT_TRANS_FAILURE:
      return {
        ...state,
        loading: false,
        clientAllFloatTransactions: [],
        error: action.payload,
      };
    case POST_FLOAT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
        floatTransactionResponce: null,
        error: "",
      };
    case POST_FLOAT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        floatTransactionResponce: action.payload,
        error: "",
      };
    case POST_FLOAT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        floatTransactionResponce: null,
        error: action.payload,
      };
    case GET_ALL_CLIENT_TRANS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CLIENT_TRANS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientAllTransactions: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case GET_ALL_CLIENT_TRANS_FAILURE:
      return {
        ...state,
        loading: false,
        clientAllTransactions: [],
        error: "",
      };
    case CLEAR_FLOAT_TRANSACTION_LIST:
      return {
        ...state,
        loading: false,
        clientAllTransactions: [],
        next_iteration: true,
        error: "",
      };
    default:
      return state;
  }
};

export default floatReducer;
