import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import OrderSummaryProduct from "../../../components/Order/OrderSummaryProduct";
import OrderSummaryDetail from "../../../components/Order/OrderSummaryDetail";
import GoogleIncCompo from "../Clients/GoogleIncCompo";
import { useDispatch, useSelector } from "react-redux";
import { postClientMerchandise } from "../../../Redux/CreateOrder/CreateOrderAction";
import { getPratnerFloats } from "../../../Redux/Float/floatAction";
import ModalComp from "../../../components/Models/ModalComp";
import DistGiftcardModel from "../../../components/Models/DistGiftcardModel";
import { useTranslation } from "react-i18next";

const MerchantOrderSummary = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const merchandiseDistData = useSelector(
    (state) => state?.createOrder?.distributionOfMerchandise
  );

  const [orderAmount, setOrderAmount] = useState(0);
  const [remainingBal, setRemainingBal] = useState(0);
  const [floatBalance, setFloatBalance] = useState();
  const partnerFloatbalance = useSelector((state) => state.partnerFloat.floats);
  const SelectedClient = useSelector(
    (state) => state.clientMappedMerchandise.distMerSelectedClientData
  );

  const selectedVariantPrice = useSelector(
    (state) => state.merchadiseAllProduct.merchandiseVariantPrice
  );

  const [showModal, setShowModal] = useState(false);
  const [modelText, setModelText] = useState("");
  const [modelPath, SetModelPath] = useState(
    "/float/currency-mapping/to-client"
  );

  const handleClose = () => setShowModal(false);

  const getFloatbalance = () => {
    partnerFloatbalance &&
      partnerFloatbalance.map((item) => {
        if (
          item?.country_id === merchandiseDistData.selectedProduct.country_id
        ) {
          setFloatCurrencyNotMapped(false);
          setFloatBalance(item);
          return;
        }
      });
  };

  const [floatCurrencyNotMapped, setFloatCurrencyNotMapped] = useState(false);

  useEffect(() => {
    if (
      SelectedClient?.float_flag === true &&
      partnerFloatbalance.length === 0
    ) {
      setFloatCurrencyNotMapped(true);
    }
  }, [partnerFloatbalance]);

  useEffect(() => {
    const remainingBalance =
      orderAmount > 0
        ? floatBalance?.balance - orderAmount
        : floatBalance?.balance;
    setRemainingBal(remainingBalance);
  }, [floatBalance?.balance]);

  // Calling float function
  useEffect(() => {
    getFloatbalance();
  }, [partnerFloatbalance]);

  useEffect(() => {
    if (floatBalance === undefined) {
      setFloatBalance(0);
    }
  }, [floatBalance]);

  // Fetching Client currency List
  useEffect(() => {
    if (SelectedClient?.float_flag === true) {
      dispatch(getPratnerFloats(merchandiseDistData?.selectedClient?.id));
    }
  }, [merchandiseDistData]);

  // OrderAmount Calculating
  useEffect(() => {
    let orderAmount = selectedVariantPrice?.selling_price
      ? selectedVariantPrice?.selling_price * merchandiseDistData?.quantity
      : merchandiseDistData?.product?.selling_price *
        merchandiseDistData?.quantity;
    setOrderAmount(orderAmount);
  }, [merchandiseDistData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!merchandiseDistData?.selectedClient) {
      history("/merchandise-distribution");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchandiseDistData?.selectedClient]);

  const goTonextStep = () => {
    let createOrderObj = {
      orderBy: "client",
      buyerId: merchandiseDistData?.selectedClient?.id,
      productType: "merchandise",
      productId: merchandiseDistData?.product?.merchandise?.id,
      productVariantId: merchandiseDistData.varinats.variant
        ? merchandiseDistData.varinats.variant.id
        : "",
      inventorTypeId: "",
      product: merchandiseDistData?.product,
      quantity: merchandiseDistData?.quantity,
      orderPrice: orderAmount,
      discount: 0,
      totalPrice: orderAmount,
      deliveryType: merchandiseDistData.deliveryType,
      countryCurrency: merchandiseDistData?.product?.country?.currency,
      deliveryType: "physical",
      deliveryAddressline1: merchandiseDistData?.deliveryAddressline1
        ? merchandiseDistData?.deliveryAddressline1
        : "",
      deliveryAddressline2: merchandiseDistData?.deliveryAddressline2
        ? merchandiseDistData?.deliveryAddressline2
        : "",
      cityName: merchandiseDistData?.cityName,
      emailid: merchandiseDistData?.emailid,
      phoneNumber: merchandiseDistData?.phoneNumber,
      selectedClient: merchandiseDistData?.selectedClient,
    };

    if (SelectedClient?.float_flag === true) {
      if (orderAmount > floatBalance?.balance) {
        SetModelPath("/float/addfloat-details");
        setModelText(
          "Your balance is low for purchasing this product, Please add some floats "
        );
        setShowModal(true);
        return;
      }
    }

    dispatch(postClientMerchandise(createOrderObj, goToSuccessfull));
  };

  const goToSuccessfull = () => {
    history("/merchandise/order-success");
  };

  return (
    <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Order Summary")}</span>
        </div>
      </div>
      <div className=" d-flex mb-4">
        <span className="font-bolder font-14 text-muted ">
          {t(
            "Gift card will be sent to the email ID as a password protected Excel Sheets"
          )}
        </span>
      </div>

      <div className="d-flex ">
        <GoogleIncCompo
          prepaidClient={SelectedClient?.float_flag}
          float={floatBalance}
          floatFlag={SelectedClient?.float_flag}
          floatCurrencyNotMapped={floatCurrencyNotMapped}
        />
      </div>

      <div className="d-flex ">
        <OrderSummaryProduct />
      </div>

      <div className="d-flex ">
        <OrderSummaryDetail
          variant={selectedVariantPrice?.selling_price}
          float={floatBalance}
          orderAmount={orderAmount}
          remainingBal={remainingBal}
          productCurrency={merchandiseDistData?.countryCurrency}
          floatFlag={SelectedClient?.float_flag}
        />
      </div>

      <div className="d-flex my-3">
        <Button
          type="primary"
          text={t("Confirm & Send")}
          className="rounded-6 my-4 mt-6 fw-bold"
          onClick={goTonextStep}
        />
      </div>
      <ModalComp
        size="md"
        showModal={showModal}
        component={
          <DistGiftcardModel
            handleClose={handleClose}
            heading={modelText}
            path={modelPath}
          />
        }
      />
    </div>
  );
};

export default MerchantOrderSummary;
