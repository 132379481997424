import {GET_VAT_REQUEST,
    GET_VAT_SUCCESS,
    GET_VAT_FAILURE} from './vatType'
    

    import { baseUrl, getToken } from "../../utils/prodURL";
    import axios from "axios";
    
    export const getVatRequest = () => {
      return {
        type: GET_VAT_REQUEST,
      };
    };
    export const getVatSuccess = (vat) => {
      return {
        type: GET_VAT_SUCCESS,
        payload: vat,
      };
    };
    export const getVatFailure = (error) => {
      return {
        type: GET_VAT_FAILURE,
        payload: error,
      };
    };
    
    export const getVat = () => {
      return (dispatch) => {
        dispatch(getVatRequest());
        let config = {
          method: "get",
          url: `${baseUrl}/vat`,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
        axios(config)
          .then((response) => {
           // console.log("response" + JSON.stringify(response.data));
             dispatch(getVatSuccess(response.data))
          })
          .catch((error) => {
            const errorMsg = error;
            dispatch(getVatFailure("Something went wrong!! Please try again!!"));
          });
      };
    };
    