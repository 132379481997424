import React, { useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

const TopSuppliers = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">{t("Top Suppliers (by sale)")}</span>
        </div>
        <div>
          <ReactDatePicker
            selected={startDate}
            onChange={setStartDate}
            minDate={new Date()}
            className="datepicker-input-style"
          />
        </div>
      </div>
      <div className="d-flex "></div>
    </div>
  );
};

export default TopSuppliers;
