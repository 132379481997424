import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../src/services/i18n';
import Loader from './components/Loader/Loader'
import axios from 'axios';


const handleLogout = () => {
  localStorage.clear();
};

axios.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 401) {
  // console.log("Interceptor got the error")
  handleLogout()
  window.location.reload(false)
 }
 return error;
});



ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader loading={true} />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
