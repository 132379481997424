/* eslint-disable array-callback-return */
import {
  SET_CATEGORY_ATTRIBUTES,
  SET_NAME_DESCRIPTION,
  SET_OTHER_FILES,
  SET_PRICE,
  SET_TERM_CONDITION,
  FETCH_GIFTCARD_NUMBER_LENGTH_REQUEST,
  FETCH_GIFTCARD_NUMBER_LENGTH_SUCCESS,
  FETCH_GIFTCARD_NUMBER_LENGTH_FAILURE,
  FETCH_GIFTCARD_TYPE_REQUEST,
  FETCH_GIFTCARD_TYPE_SUCCESS,
  FETCH_GIFTCARD_TYPE_FAILURE,
  PUT_GIFTCARD_FAILURE,
  PUT_GIFTCARD_SUCCESS,
  PUT_GIFTCARD_REQUEST,
  FETCH_GIFTCARD_PREFIX_REQUEST,
  FETCH_GIFTCARD_PREFIX_SUCCESS,
  FETCH_GIFTCARD_PREFIX_FAILURE,
  FETCH_GIFTCARD_REQUEST,
  FETCH_GIFTCARD_SUCCESS,
  FETCH_GIFTCARD_FAILURE,
  PUT_DENOMINATION_REQUEST,
  PUT_DENOMINATION_SUCCESS,
  PUT_DENOMINATION_FAILURE,
  PUT_GIFTCARD_IMAGE_SUCCESS,
  PUT_GIFTCARD_IMAGE_REQUEST,
  PUT_GIFTCARD_IMAGE_FAILURE,
  FETCH_GIFTCARD_DETAILS_FAILURE,
  FETCH_GIFTCARD_DETAILS_SUCCESS,
  FETCH_GIFTCARD_DETAILS_REQUEST,
  FETCH_GIFTCARD_DENOMINATION_BYID_FAILURE,
  FETCH_GIFTCARD_DENOMINATION_BYID_SUCCESS,
  FETCH_GIFTCARD_DENOMINATION_BYID_REQUEST,
  FETCH_GIFTCARD_FILES_BYID_FAILURE,
  FETCH_GIFTCARD_FILES_BYID_SUCCESS,
  FETCH_GIFTCARD_FILES_BYID_REQUEST,
  FETCH_GIFTCARD_SUMMERY_BYID_REQUEST,
  FETCH_GIFTCARD_SUMMERY_BYID_SUCCESS,
  FETCH_GIFTCARD_SUMMERY_BYID_FAILURE,
  EDIT_GIFTCARD_REQUEST,
  EDIT_GIFTCARD_SUCCES,
  EDIT_GIFTCARD_FAILURE,
  GIFTCARD_FILTER_DATA,
  CLEAR_GIFTCARD_LIST
} from "./giftcardTypes";
import axios from "axios";
import { baseUrl, getToken, getUser } from "../../utils/prodURL";
import {
  dateFormatConverter,
  diffTwoDatesInDays,
} from "../../utils/constantFunction";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

export const fetchGiftCardLengthRequest = () => {
  return {
    type: FETCH_GIFTCARD_NUMBER_LENGTH_REQUEST,
  };
};

export const fetchGiftCardLengthSuccess = (giftcardlength) => {
  return {
    type: FETCH_GIFTCARD_NUMBER_LENGTH_SUCCESS,
    payload: giftcardlength,
  };
};

export const fetchGiftCardLengthFailure = () => {
  return {
    type: FETCH_GIFTCARD_NUMBER_LENGTH_FAILURE,
  };
};

export const fetchAllGiftCardLengths = () => {
  return (dispatch) => {
    dispatch(fetchGiftCardLengthRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/gift_card_length`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(fetchGiftCardLengthSuccess(response.data));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          fetchGiftCardLengthFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

//get all gistcars list

export const fetchProductsRequist = (data) => {
  return {
    type: FETCH_GIFTCARD_REQUEST,
    payload: data,
  };
};
export const fetchProductsSuccess = (data) => {
  return {
    type: FETCH_GIFTCARD_SUCCESS,
    payload: data,
  };
};
export const fetchProductsFailure = (data) => {
  return {
    type: FETCH_GIFTCARD_FAILURE,
    payload: data,
  };
};

export const setGiftcardFilterData = (data) => {
  return {
    type: GIFTCARD_FILTER_DATA,
    payload: data
  }
}

export const fetchAllGiftCardList = (skip, limit, products) => {
  if (!products) products = []
  return (dispatch) => {
    dispatch(fetchProductsRequist());
    let url = skip !== undefined && limit !== undefined ?
      `${baseUrl}/v1/giftcards/denomination/list?supplier_info=true&skip=${skip}&limit=${limit}` :
      `${baseUrl}/v1/giftcards/denomination/list?supplier_info=true`
    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      // data:data
    };
    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          let data = {
            data: [...products, ...response.data.result],
            moredata: response.data.next_iteration
            //moredata: false
          }
          dispatch(fetchProductsSuccess(data));
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          fetchProductsFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const fetchAllGiftCardListByCountryIds = (data) => {
  return (dispatch) => {
    dispatch(fetchProductsRequist());
    let ids = [];
    // eslint-disable-next-line array-callback-return
    data &&
      data.map((item) => {
        let obj = `country_ids=${item}&`;
        ids.push(obj);
      });

    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/denomination/list?${ids.join(
        ""
      )}supplier_info=true&brand_info=false`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      // data:data
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          // console.log("response ", response)
          let data = {
            data: response.data.result
          }
          dispatch(fetchProductsSuccess(data));
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          fetchProductsFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const setNameDescription = (data) => {
  return {
    type: SET_NAME_DESCRIPTION,
    payload: data,
  };
};

export const setCategoryAttributes = (data) => {
  return {
    type: SET_CATEGORY_ATTRIBUTES,
    payload: data,
  };
};

export const setPrice = (data) => {
  return {
    type: SET_PRICE,
    payload: data,
  };
};

export const setOtherFiles = (data) => {
  return {
    type: SET_OTHER_FILES,
    payload: data,
  };
};

export const setTermCondition = (data) => {
  return {
    type: SET_TERM_CONDITION,
    payload: data,
  };
};

// gift Card Type actions

export const fetchGiftCardTypeRequest = () => {
  return {
    type: FETCH_GIFTCARD_TYPE_REQUEST,
  };
};
export const fetchGiftCardTypeSuccess = (giftcardtype) => {
  return {
    type: FETCH_GIFTCARD_TYPE_SUCCESS,
    payload: giftcardtype,
  };
};
export const fetchGiftCardTypeFailure = () => {
  return {
    type: FETCH_GIFTCARD_TYPE_FAILURE,
  };
};

export const fetchAllGiftCardType = () => {
  return (dispatch) => {
    dispatch(fetchGiftCardTypeRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/inventory_type`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.code) {
          dispatch(fetchGiftCardTypeSuccess(response.data.result));
        } else {
          fetchGiftCardTypeFailure("Something went wrong!! Please try again!!");
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          fetchGiftCardTypeFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// gift card prefix action
export const fetchGiftCardPrefixRequest = () => {
  return {
    type: FETCH_GIFTCARD_PREFIX_REQUEST,
  };
};
export const fetchGiftCardPrefixSuccess = (giftcardprefix) => {
  return {
    type: FETCH_GIFTCARD_PREFIX_SUCCESS,
    payload: giftcardprefix,
  };
};
export const fetchGiftCardPrefixFailure = () => {
  return {
    type: FETCH_GIFTCARD_PREFIX_FAILURE,
  };
};

export const fetchAllGiftCardPrefix = () => {
  return (dispatch) => {
    dispatch(fetchGiftCardPrefixRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/giftcard_prefix/`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(fetchGiftCardPrefixSuccess(response.data));
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          fetchGiftCardPrefixFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

//Gift card put

export const putGiftCardRequest = () => {
  return {
    type: PUT_GIFTCARD_REQUEST,
  };
};

export const putGiftCardSuccess = (obj) => {
  return {
    type: PUT_GIFTCARD_SUCCESS,
    payload: obj,
  };
};

export const putGiftCardFailure = (obj) => {
  return {
    type: PUT_GIFTCARD_FAILURE,
    payload: obj,
  };
};

export const putGiftCard = (stateObj, goToproductSuccessfull) => {
  return (dispatch) => {
    dispatch(putGiftCardRequest());

    let intAmount = parseInt(stateObj?.cardPrice?.amount);
    let intMaxAmt = parseInt(stateObj?.cardPrice?.maxamount);
    let intMinAmt = parseInt(stateObj?.cardPrice?.minamount);
    let denomnation = stateObj?.cardPrice?.fixeddenomnation.length;
    let validity = diffTwoDatesInDays(
      stateObj?.cardCatAttribute?.startDate,
      stateObj?.cardCatAttribute?.expireDate
    );
    let user = getUser();

    let prodTitleArr = [];

    // eslint-disable-next-line array-callback-return
    stateObj?.cardNameDesc?.prodTitles?.map((item) => {
      prodTitleArr.push({ [item.lang.language_code]: item.title });
    });
    let titleObject = Object.assign({}, ...prodTitleArr);

    // keybenefits

    // description
    let descriptionArr = [];
    // eslint-disable-next-line array-callback-return
    stateObj?.cardNameDesc?.description.map((item) => {
      let obj = {
        description_text: draftToHtml(
          convertToRaw(item.value.getCurrentContent())
        ),
        //JSON.stringify(item),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      descriptionArr.push(obj);
    });

    // termCondition
    let termConditionArr = [];
    // eslint-disable-next-line array-callback-return
    stateObj?.cardTermCondition?.termCondition.map((item) => {
      let obj = {
        terms_condition_text: draftToHtml(
          convertToRaw(item.value.getCurrentContent())
        ),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      termConditionArr.push(obj);
    });

    const keyBenifits = [];
    stateObj?.cardNameDesc?.keybenefits?.one &&
      keyBenifits.push(stateObj?.cardNameDesc?.keybenefits?.one?.name);
    stateObj?.cardNameDesc?.keybenefits?.two &&
      keyBenifits.push(stateObj?.cardNameDesc?.keybenefits?.two?.name);
    stateObj?.cardNameDesc?.keybenefits?.three &&
      keyBenifits.push(stateObj?.cardNameDesc?.keybenefits?.three?.name);
    stateObj?.cardNameDesc?.keybenefits?.four &&
      keyBenifits.push(stateObj?.cardNameDesc?.keybenefits?.four?.name);

    let payload = {
      language_id: stateObj?.cardNameDesc?.preferredLanguage?.language_code,
      product_title: titleObject,
      default_title: stateObj?.cardNameDesc?.prodTitles[0]?.title,
      about_the_product: descriptionArr,
      key_benefits: keyBenifits,
      registered_by_id: stateObj?.cardCatAttribute?.giftcardtype?.id,
      country_id: stateObj?.cardCatAttribute?.countryCurrency?.id,
      business_region: stateObj?.cardCatAttribute?.region,
      state_id: stateObj.cardCatAttribute.state.id,
      brand_id: stateObj?.cardCatAttribute?.brand.id,
      brand_category_id: stateObj?.cardCatAttribute?.brandCategory?.id,
      start_date: stateObj?.cardCatAttribute?.startDate
        ? dateFormatConverter(stateObj?.cardCatAttribute?.startDate)
        : "2022-04-22T09:56:29.626167",
      end_date: stateObj?.cardCatAttribute?.expireDate
        ? dateFormatConverter(stateObj?.cardCatAttribute?.expireDate)
        : "2022-05-06T15:39:13.685244",
      giftcard_num_length: stateObj?.cardCatAttribute?.giftCardLength
        ?.gift_card_length
        ? stateObj?.cardCatAttribute?.giftCardLength?.gift_card_length
        : 12,
      prefix_type: stateObj.cardCatAttribute?.prefixGiftCard
        ? stateObj.cardCatAttribute.prefixGiftCard?.giftcard_prefix.toLowerCase()
        : "numeric",
      prefix: stateObj?.cardCatAttribute?.prefixValue,
      redemption_method_id: stateObj?.cardCatAttribute?.redemptionMethod.id,
      activation_required_flag: true,
      tags: stateObj?.cardCatAttribute.tags,
      giftcard_value_type: stateObj?.cardPrice?.giftcard_value_type,
      min_amount: intMinAmt ? intMinAmt : 0,
      max_amount: intMaxAmt ? intMaxAmt : 0,
      amount: intAmount ? intAmount : 0,
      denominations:
        denomnation > 0 ? stateObj?.cardPrice?.fixeddenomnation : [0],
      reloadable_giftcard_flag: stateObj?.cardPrice?.reloadableGiftCardFlag,
      redemption_type: stateObj?.cardCatAttribute?.redemptionAmount?.value,
      terms_condition: termConditionArr,
      validity: validity,
      active_flag: stateObj?.cardCatAttribute?.activeFlag,
      feature_flag: stateObj?.cardCatAttribute?.isFeaturedFlag,
      promotion_flag: false,
      version: 0,
      user_id: user.id,
      giftcard_key: "string",
      giftcard_source: "string",
      giftcard_status: "string",
      value_source: "string",
      giftcard_number: "string",
      giftcard_pin: "string",
      partner_id: "string",
      giftcard_attribute_id: "string",
      original_value: 0,
      remaining_value: 0,
      number_revealed_flag: true,
      multipack_flag: true,
      ehadaya_card_flag: true,
      custom_giftcard_flag: true,
      giftcard_link: "string",
      giftcard_token: "string",
      third_party_giftcard_url: "string",
      giftcard_access_key: "string",
      pkpass_link: "string",
      paper_voucher_flag: true,
      giftcard_request_id: "string",
      availibility: "in_stock",
    };


    let configGiftcard = {
      method: "post",
      url: `${baseUrl}/v1/giftcards`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    const GiftDescription = axios(configGiftcard);

    Promise.all([GiftDescription])
      .then(([giftDescription]) => {
        //giftDescription
        if (giftDescription.status === 200) {
          let giftCardObj = {
            id: giftDescription.data.result.id,
            giftcard_title: giftDescription.data.result.default_title,
          };
          if (stateObj.cardPrice.denomnationFlag === true) {
            dispatch(putGiftCardsDenominations(giftCardObj, stateObj, goToproductSuccessfull));
          }
          else {
            dispatch(
              putGiftCardsFilesUpload(
                stateObj,
                giftCardObj,
                goToproductSuccessfull
              )
            );
          }
          dispatch(putGiftCardSuccess(giftDescription.data.result));

        } else {
          dispatch(putGiftCardFailure("failed"));
        }
      })
      .catch((error) => {
        //     const errorMsg = error

        dispatch(putGiftCardFailure("failed"));
      });
  };
};

export const putGiftcardDenominationRequest = () => {
  return {
    type: PUT_DENOMINATION_REQUEST
  }
}

export const putGiftcardDenominationSuccess = (data) => {
  return {
    type: PUT_DENOMINATION_SUCCESS,
    payload: data
  }
}

export const putGiftcardDenominationFailure = (error) => {
  return {
    type: PUT_DENOMINATION_FAILURE,
    payload: error
  }
}

// POST GIFTCARD DENOMINATION IF DENOMINATION FLAG IS TRUE
export const putGiftCardsDenominations = (guftcardId, giftcardObj, goToproductSuccessfull) => {
  return (dispatch) => {
    dispatch(putGiftcardDenominationRequest())
    let payload = [];
    giftcardObj?.cardPrice?.fixeddenomnation &&
      giftcardObj?.cardPrice?.fixeddenomnation.map((item) => {
        let obj = {
          giftcard_id: guftcardId?.id,
          version: 0,
          currency: giftcardObj?.cardCatAttribute?.countryCurrency?.currency,
          denomination_value: parseInt(item),
          active_flag: true,
        };
        payload.push(obj);
      });

    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcards/denomination`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            putGiftCardsFilesUpload(
              giftcardObj,
              guftcardId,
              goToproductSuccessfull
            )
          );
        }
        else {
          console.log("Something wrong in Denomination API ")
        }
        // dispatch(putClientMerchandiseSuccess(response.data));
        // dispatch(putGiftcardDenominationSuccess())
      })
      .catch((error) => {
        dispatch(putGiftcardDenominationFailure())
      });
  };
};

export const putGiftCardImageRequest = () => {
  return {
    type: PUT_GIFTCARD_IMAGE_REQUEST,
  };
};

export const putGiftCardImageSuccess = (obj) => {
  return {
    type: PUT_GIFTCARD_IMAGE_SUCCESS,
    payload: obj,
  };
};

export const putGiftCardImageFailure = (obj) => {
  return {
    type: PUT_GIFTCARD_IMAGE_FAILURE,
    payload: obj,
  };
};

export const putGiftCardsFilesUpload = (
  stateObj,
  giftCardObj,
  goToproductSuccessfull
) => {
  return (dispatch) => {
    dispatch(putGiftCardImageRequest());
    const formData = new FormData();

    formData.append("giftcard_id", giftCardObj.id);
    formData.append("giftcard_title", giftCardObj.giftcard_title);
    formData.append("card_image", stateObj?.cardPrice?.giftCardImg[0]);
    stateObj?.cardOtherFiles?.documentation[0] &&
      formData.append(
        "card_documentation",
        stateObj?.cardOtherFiles?.documentation[0]
      );
    stateObj?.cardOtherFiles?.giftCardThumb[0] &&
      formData.append(
        "card_thumbnail",
        stateObj?.cardOtherFiles?.giftCardThumb[0]
      );

    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(`${baseUrl}/v1/giftcards/files`, formData, config)
      .then((response) => {
        if (response.status === 200) {
          goToproductSuccessfull();
          dispatch(fetchAllGiftCardList());
          dispatch(putGiftCardImageSuccess(response.data));
        }
        else {
          console.log("Something wrong in Giftcard IMAGE API ")
        }
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          putGiftCardImageFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const EditGiftCardsFilesUpload = (
  stateObj,
  giftCardObj,
  goToproductSuccessfull
) => {
  return (dispatch) => {
    dispatch(putGiftCardImageRequest());

    const formData = new FormData();

    formData.append("giftcard_id", giftCardObj.id);
    formData.append("giftcard_title", giftCardObj.giftcard_title);
    formData.append("card_image", stateObj?.cardImage[0]);
    stateObj?.cardDocumentation[0] &&
      formData.append("card_documentation", stateObj?.cardDocumentation[0]);
    stateObj?.cardThumbnail[0] &&
      formData.append("card_thumbnail", stateObj?.cardThumbnail[0]);

    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(`${baseUrl}/v1/giftcards/files`, formData, config)
      .then((response) => {
        if (response.status === 200) {
          goToproductSuccessfull();
          dispatch(putGiftCardImageSuccess(response.data));
        }
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          putGiftCardImageFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// Giftcard EDIT
export const editGiftCardRequest = () => {
  return {
    type: EDIT_GIFTCARD_REQUEST,
  };
};

export const editGiftCardSuccess = (data) => {
  return {
    type: EDIT_GIFTCARD_SUCCES,
    payload: data,
  };
};

export const editGiftCardFailure = (error) => {
  return {
    type: EDIT_GIFTCARD_FAILURE,
    payload: error,
  };
};

export const editGiftCard = (stateObj, goToproductSuccessfull) => {
  return (dispatch) => {
    dispatch(editGiftCardRequest());

    let prodTitleArr = [];

    // eslint-disable-next-line array-callback-return
    stateObj?.product_title?.map((item) => {
      prodTitleArr.push({ [item.lang.language_code]: item.title });
    });
    let titleObject = Object.assign({}, ...prodTitleArr);

    // description
    let descriptionArr = [];
    // eslint-disable-next-line array-callback-return
    stateObj?.about_the_product.map((item) => {
      let obj = {
        description_text: draftToHtml(
          convertToRaw(item.value.getCurrentContent())
        ),
        //JSON.stringify(item),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      descriptionArr.push(obj);
    });

    // termCondition
    let termConditionArr = [];
    // eslint-disable-next-line array-callback-return
    stateObj?.terms_condition?.map((item) => {
      let obj = {
        terms_condition_text: draftToHtml(
          convertToRaw(item.value.getCurrentContent())
        ),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      termConditionArr.push(obj);
    });

    const keyBenifits = [];
    stateObj?.key_benefits &&
      keyBenifits.push(stateObj?.key_benefits?.one?.name);
    stateObj?.key_benefits &&
      keyBenifits.push(stateObj?.key_benefits?.two?.name);
    stateObj?.key_benefits &&
      keyBenifits.push(stateObj?.key_benefits?.three?.name);
    stateObj?.key_benefits &&
      keyBenifits.push(stateObj?.key_benefits?.four?.name);

    let payload = {
      language_id: stateObj?.language_id,
      product_title: titleObject,
      default_title: stateObj?.default_title,
      about_the_product: descriptionArr,
      key_benefits: keyBenifits,
      registered_by_id: stateObj?.registered_by_id,
      country_id: stateObj?.country_id,
      business_region: stateObj?.business_region,
      state_id: stateObj?.state_id,
      brand_id: stateObj?.brand_id,
      brand_category_id: stateObj?.brand_category_id,
      start_date: "2022-07-21T09:18:12.313036",
      end_date: "2022-07-21T09:18:12.313101",
      giftcard_num_length: stateObj?.giftcard_num_length,
      prefix_type: stateObj?.prefix_type,
      prefix: stateObj?.prefix,
      redemption_method_id: stateObj?.redemption_method_id,
      activation_required_flag: stateObj?.activation_required_flag,
      tags: stateObj?.tags,
      giftcard_value_type: stateObj?.giftcard_value_type,
      min_amount: stateObj?.min_amount ? stateObj?.min_amount : 0,
      max_amount: stateObj?.max_amount ? stateObj?.max_amount : 0,
      amount: stateObj?.amount ? stateObj?.amount : 0,
      reloadable_giftcard_flag: stateObj?.reloadable_giftcard_flag,
      redemption_type: stateObj?.redemptionAmount?.value,
      terms_condition: termConditionArr,
      validity: stateObj?.validity,
      feature_flag: stateObj?.feature_flag,
      promotion_flag: true,
      version: 0,
      user_id: stateObj?.user_id,
      giftcard_key: stateObj?.giftcard_key,
      // giftcard_source: "string",
      // giftcard_status: "string",
      // value_source: "string",
      // giftcard_number: "string",
      // giftcard_pin: "string",
      // store_id: null,
      // partner_id: "string",
      // giftcard_attribute_id: "string",
      // original_value: 0,
      // remaining_value: 0,
      // number_revealed_flag: true,
      // multipack_flag: true,
      // ehadaya_card_flag: true,
      // custom_giftcard_flag: true,
      // giftcard_link: "string",
      // giftcard_token: "string",
      // third_party_giftcard_url: "string",
      // giftcard_access_key: "string",
      // pkpass_link: "string",
      // paper_voucher_flag: true,
      // giftcard_request_id: "string",
      // show_as_points: true,
    };

    let configGiftcard = {
      method: "patch",
      url: `${baseUrl}/v1/giftcards/${stateObj?.giftcartId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    const GiftDescription = axios(configGiftcard);

    Promise.all([GiftDescription])
      .then(([giftDescription]) => {
        //giftDescription
        if (giftDescription.status === 200) {
          let giftCardObj = {
            id: stateObj?.giftcartId,
            giftcard_title: giftDescription.data.result.default_title,
          };
          if (
            stateObj?.denominationFlag === true &&
            stateObj?.denominations.length > 0
          ) {

            dispatch(editGiftcardDenominations(giftCardObj, stateObj));
          }
          goToproductSuccessfull();
          dispatch(fetchAllGiftCardList());
          dispatch(editGiftCardSuccess(giftDescription.data.result));

          if (
            stateObj?.cardImage.length > 0 ||
            stateObj?.cardDocumentation.length > 0 ||
            stateObj?.cardThumbnail.length > 0
          ) {
            dispatch(
              EditGiftCardsFilesUpload(
                stateObj,
                giftCardObj,
                goToproductSuccessfull
              )
            );
          }
        } else {
          dispatch(editGiftCardFailure("failed"));
        }
      })
      .catch((error) => {
        //     const errorMsg = error

        dispatch(editGiftCardFailure("failed"));
      });
  };
};

export const editGiftcardDenominations = (giftCardObj, stateObj) => {
  return (dispatch) => {

    let payload = [];
    stateObj?.denominations &&
      stateObj?.denominations.map((item) => {
        let obj = {
          giftcard_id: giftCardObj?.id,
          version: 0,
          currency: stateObj?.country?.currency,
          denomination_value: parseInt(item),
          active_flag: true,
        };
        payload.push(obj);
      });

    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcards/denomination`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        // console.log("response Denomination ", response);
        // dispatch(putClientMerchandiseSuccess(response.data));
      })
      .catch((error) => {
        console.log("err" + JSON.stringify(error));
      });


  }
}

// giftcard details all by id
export const fetchGiftCardDetailsIdRequest = () => {
  return {
    type: FETCH_GIFTCARD_DETAILS_REQUEST,
  };
};
export const fetchGiftCardDetailsIdSuccess = (giftcardprefix) => {
  return {
    type: FETCH_GIFTCARD_DETAILS_SUCCESS,
    payload: giftcardprefix,
  };
};
export const fetchGiftCardDetailsIdFailure = () => {
  return {
    type: FETCH_GIFTCARD_DETAILS_FAILURE,
  };
};

export const fetchGiftCardDetailsId = (id) => {
  return (dispatch) => {
    dispatch(fetchGiftCardDetailsIdRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/${id}/view`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(fetchGiftCardDetailsIdSuccess(response.data.result));
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          fetchGiftCardDetailsIdFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// giftcard denomination by giftcard id
export const fetchGiftCardDenominationByIdRequest = () => {
  return {
    type: FETCH_GIFTCARD_DENOMINATION_BYID_REQUEST,
  };
};
export const fetchGiftCardDenominationByIdSuccess = (obj) => {
  return {
    type: FETCH_GIFTCARD_DENOMINATION_BYID_SUCCESS,
    payload: obj,
  };
};
export const fetchGiftCardDenominationByIdFailure = () => {
  return {
    type: FETCH_GIFTCARD_DENOMINATION_BYID_FAILURE,
  };
};

export const fetchGiftCardDenominationById = (id) => {
  return (dispatch) => {
    dispatch(fetchGiftCardDenominationByIdRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/${id}/denomination`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(fetchGiftCardDenominationByIdSuccess(response.data.result));
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          fetchGiftCardDenominationByIdFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// giftcard files by giftcard id
export const fetchGiftCardFilesByIdRequest = () => {
  return {
    type: FETCH_GIFTCARD_FILES_BYID_REQUEST,
  };
};
export const fetchGiftCardFilesByIdSuccess = (obj) => {
  return {
    type: FETCH_GIFTCARD_FILES_BYID_SUCCESS,
    payload: obj,
  };
};
export const fetchGiftCardFilesByIdFailure = () => {
  return {
    type: FETCH_GIFTCARD_FILES_BYID_FAILURE,
  };
};

export const fetchGiftCardFilesById = (id) => {
  return (dispatch) => {
    dispatch(fetchGiftCardFilesByIdRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/${id}/files`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(fetchGiftCardFilesByIdSuccess(response.data.result));
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          fetchGiftCardFilesByIdFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// to get the summery of giftcard
export const fetchGiftCardSummeryByIdRequest = () => {
  return {
    type: FETCH_GIFTCARD_SUMMERY_BYID_REQUEST,
  };
};
export const fetchGiftCardSummeryByIdSuccess = (obj) => {
  return {
    type: FETCH_GIFTCARD_SUMMERY_BYID_SUCCESS,
    payload: obj,
  };
};
export const fetchGiftCardSummeryByIdFailure = () => {
  return {
    type: FETCH_GIFTCARD_SUMMERY_BYID_FAILURE,
  };
};

export const fetchGiftCardSummeryById = (id) => {
  return (dispatch) => {
    dispatch(fetchGiftCardSummeryByIdRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/denomination/list?giftcard_ids=${id}&supplier_info=true&brand_info=false`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(fetchGiftCardSummeryByIdSuccess(response.data.result));
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          fetchGiftCardSummeryByIdFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

export const clearGiftcardList = () => {
  return {
    type: CLEAR_GIFTCARD_LIST,
  };
};