import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import { ReactComponent as GreenUpArrow } from "../../../assests/svg/Product/Green_UpArrow.svg";
import { ReactComponent as BlueIcon } from "../../../assests/svg/Product/Blue_Icon.svg";
import MyntraImg from "../../../assests/img/ImageGift.png";
import Button from "../../../components/Button/Button";
import Toasts from "../../../components/Toasts/Toasts";
import { ReactComponent as Invoice } from "../../../assests/svg/Invoice.svg";

const StoreTransaction = () => {
  const history = useNavigate();

  const [visible, setVisible] = useState(3);

  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Name = () => {
    return (
      <div>
        <div className="font-15 font-boldest text-dark ">iTunes Gift card</div>
        <div className="font-12 font-bolder text-muted">
          Transaction ID: UI8-8934AS
        </div>
      </div>
    );
  };

  const products = [{
    id: 0, Status: "In stock", Price: "$10", Sales: "$3,200", SalePercent: "55.8%", Totalsold: "48k", Type: 'Manual'
  }];

  const customerDetails = [
    { id: 0, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$88.20" },
    { id: 1, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$21.00" },
    { id: 2, Date: "14 Oct 2021", Type: "Recharge", Name: Name(), Price: "$98.00", Amount: "-$9.80" },
    { id: 3, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$54.00" },
    { id: 4, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$98.00" },
    { id: 5, Date: "14 Oct 2021", Type: "Recharge", Name: Name(), Price: "$98.00", Amount: "-$9.80" },
    { id: 6, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$54.00" },
    { id: 7, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$98.00" },
    { id: 8, Date: "14 Oct 2021", Type: "Recharge", Name: Name(), Price: "$98.00", Amount: "-$9.80" },
  ]

  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 10);
  };

  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-32 my-2">Reports</span>
        </div>
      </div>
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
        <div className="d-flex flex-column  bg-white">
          <div className="d-flex flex-row align-items-center pb-2 gap-3">
            <span className="font-20 font-bold my-2  px-3">Transaction History</span>
          </div>
          <div className="card-body d-flex flex-column justify-content-between  p-2 mb-2 gap-3">
            <table responsive="xl">
              <tbody>
                <tr className="border-bottom ">
                  <td className="text-muted">
                    <div className="d-flex flex-row justify-content-start">
                      <input
                        className="cursor align-items-center"
                        type="checkbox"
                      // name={item?.id}
                      />
                      <span className="mx-3">Product</span>
                    </div>
                  </td>
                  <td className="text-muted"> Status</td>
                  <td className="text-muted"> Price</td>
                  <td className="text-muted"> Sales</td>
                  <td className="text-muted"> Total sold</td>
                  <td className="text-muted"> Type</td>
                </tr>
                {products.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="d-flex flex-row justify-content-start align-items-center py-3">
                        <input
                          className="cursor align-items-center"
                          type="checkbox"
                        // name={item?.id}
                        />
                        <span className="mx-3 rounded-3">
                          <img
                            src={MyntraImg}
                            alt="not found"
                            className="img-fluid width-75"
                          />
                        </span>
                        <div className="d-flex flex-column justify-content-start ">
                          <span className="font-16 font-boldest text-dark ">
                            Myntra Gift card
                          </span>
                          <span className="font-13 font-bold text-muted">
                            by Myntra Fashion
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="font-15 font-bold text-success bg-04 px-1 py-1 rounded-3">
                        {item.Status}
                      </span>
                    </td>
                    <td>
                      <span className="font-16 font-bold text-dark">
                        {item.Price}
                      </span>
                    </td>
                    <td>
                      <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 py-1">
                        {item.Sales}
                      </span>
                      <span className="font-13 font-bold text-success bg-opacity-25 rounded-3">
                        <span>
                          <GreenUpArrow className="m-1" />
                        </span>
                        {item.SalePercent}
                      </span>
                    </td>
                    <td>
                      <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 gap-4 px-1 py-1">
                        {item.Totalsold}
                      </span>
                      <span className="mx-1">
                        <BlueIcon />
                      </span>
                    </td>
                    <td>
                      <span className="font-15 font-bold text-dark bg-danger bg-opacity-25 rounded-3 px-1 py-1">
                        {item.Type}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex flex-row w-100  align-items-center justify-content-between gap-3">
            <div className="p-2 flex-grow-1 gap-3">
              <div className="d-flex gap-3 align-items-center ">
                <div className="d-flex gap-3 my-2 mx-3 align-items-center">
                  <Tag />
                  <span className="font-20 font-bold">Transactions</span>
                </div>

                <div className="d-flex px-5">
                  <span className="font-20 font-bold">
                    Total Sold:
                    <span className="font-20 font-bold text-light-blue ">
                      {" "}
                      531
                    </span>
                  </span>
                </div>
              </div>

            </div>

            <div className="gap-1">
              <div className="dropdown">
                <button
                  className="btn border border-2 btn-lg  dropdown-toggle font-14 font-bold text-muted"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Last 30 days
                </button>
                <ul
                  className="dropdown-menu "
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>Last 10 days</li>
                  <li>Last 15 days</li>
                  <li>Last 30 days</li>
                </ul>
              </div>
            </div>
            <div className="mx-4">
              <Button
                type="primary"
                text="Download CSV"
                className="rounded-6 my-3 mt-6 fw-bolder"
                onClick={() => toggleShowToast()}
              />
            </div>
          </div>

          <div className="row px-5 py-3">
            <table responsive="xl">
              <tbody>
                <tr className="card-body">
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    Date
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    Type
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    Detail
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    Spent
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    Balance
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    Download Invoice
                  </td>
                </tr>

                {customerDetails.slice(0, visible).map((item) => (
                  <tr className="card-body" key={item.id}>
                    <td className="card-body gap-1">
                      <span className="font-15 font-bold text-dark ">
                        {item.Date}
                      </span>
                    </td>
                    <td className="card-body gap-1">
                      <div className="d-flex flex-row justify-content-between  gap-3">
                        <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 py-1 px-2">
                          {item.Type}
                        </span>
                      </div>
                    </td>
                    <td className="card-body gap-1">
                      <span className="">{item.Name}</span>
                    </td>
                    <td className="card-body gap-1">
                      <span className="font-15 font-bold text-orange ">
                        {item.Price}
                      </span>
                    </td>
                    <td className="card-body gap-1">
                      <span className="font-15 font-bold text-dark ">
                        {item.Amount}
                      </span>
                    </td>
                    <td className="d-flex justify-content-center">
                      <Invoice className="mt-3 cursor" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex flex-row  justify-content-center align-item-center gap-3">
            <div className="d-flex">
              <Button
                type="rounded"
                text="Load more"
                className="rounded-6 my-4 mt-6 "
                onClick={() => loadMore()}
              />
            </div>
          </div>
        </div>
        <div
          className="position-fixed bottom-0 start-50 translate-middle-x mb-2 w-100 "
          id="liveToast"
        >
          <Toasts
            position={"bottom-center"}
            showToast={showToast}
            toggleShowToast={toggleShowToast}
            warningMsg="Your file is being downloaded"
            imageType={"success"}
          />
        </div>
      </div>
    </div>
  );
};

export default StoreTransaction;
