import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ReactComponent as SuccessIcon } from "../../assests/svg/Success.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MerchandiseSuccess = () => {
  const history = useNavigate();
  const { t } = useTranslation();

  const merchandiseResposeData = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseResposeData)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const goToViewProduct = () => {
    history("/merchandise-dashboard");
  };

  const goToClientMap = () => {
    history("/clients-mapping-dashboard");
  };

  const goToSupplierMap = () => {
    history("/supplier-mapping-dashboard");
  };

  return (
    <div className="d-flex flex-column  align-item-center bg-light w-100 py-5 gap-2">
      <div className="d-flex flex-row w-100 justify-content-center">
        <SuccessIcon />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-dark font-bold font-40">
          {t("Product created successfully")}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-muted font-bold font-15">
          {t("Product ID")}: {merchandiseResposeData?.id}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"primary"} text={t("View product")} onClick={() => goToViewProduct()} />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"rounded"} text={t("Map supplier to product")} onClick={() => goToSupplierMap()} />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"rounded"} text={t("Map client to product")} onClick={() => goToClientMap()} />
      </div>
    </div>
  );
};

export default MerchandiseSuccess;
