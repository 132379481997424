import React, { useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import Button from "../../components/Button/Button";
import Toasts from "../../components/Toasts/Toasts";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";


const DownloadSampleExcel = (props) => {
  const {getSampleFileApiFunction, description, secondTitle} = props
  const dispatch = useDispatch();
  const { t } = useTranslation(); 
  const [showToast, setShowToast] = useState(false);


  const toggleShowToast = () => setShowToast(!showToast);

  const getDownloadApiRes = (response) => {
    if(response === 200){
      toggleShowToast();
    }
  }

  const downloadHandler = () => {
    getSampleFileApiFunction && dispatch(getSampleFileApiFunction(getDownloadApiRes));
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">{t("Excel sheet example")}</span>
        </div>
      </div>

      <div className="d-flex flex-column gap-4 mt-4">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">{secondTitle}</span>
          </div>
          <div className="font-bolder font-13 text-muted">
            <span>
              {description}
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex mt-4 mb-2" id="downloadBtn">
        <Button
          text={t("Download Excel")}
          onClick={() => downloadHandler()}
          type="primary"
        />
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default DownloadSampleExcel;
