import React, { useEffect, useState } from "react";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as Tagpurple } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Righttick } from "../../assests/svg/Righttick.svg";
import giftcard_default from "../../assests/img/giftcard-default.png";
import { useSelector } from "react-redux";
import PulseLoader from "../Loader/PulseLoader";
import { useTranslation } from "react-i18next";

const ModelForGiftCardSummery = (props) => {
  const { t } = useTranslation();
  const { giftcardSummery } = props;

  const loading = useSelector((state) => state?.giftcard?.loading);
  // const mappedSupplier =useSelector((state)=>state?.giftcard?.giftcard);

  const [permissions, setpermissions] = useState();
  const [usertype, setusertype] = useState();

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    setusertype(JSON.parse(localStorage.getItem("user")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions")]);

  return loading === true ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white p-4 w-100 mb-3">
      <div className="d-flex flex-row justify-content-end align-items-center">
        <div className="d-flex justify-content-end">
          <span
            onClick={props.handleClose}
            className="rounded-pill  bg-light p-2"
          >
            <span className="font-20 rounded-circle cursor p-2">X</span>
          </span>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex">
          <div className="d-flex flex-column">
            <div className="font-32 font-bold align-item-center m-4">
              {giftcardSummery && giftcardSummery[0]?.default_title}
            </div>
          </div>
        </div>

        {/* <div className="d-flex col-2">
            <Button text="Edit Product" type={"border"}  />
          </div>*/}
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-column mx-4">
          <img
            src={
              giftcardSummery &&
              giftcardSummery[0]?.images &&
              giftcardSummery[0]?.images[0]?.file_url
                ? giftcardSummery && giftcardSummery[0]?.images[0]?.file_url
                : giftcardSummery && giftcard_default
            }
            key={giftcard_default}
            alt="not found"
            id="product-preview"
          />
        </div>
      </div>
      <div className="d-flex flex-row p-4">
        <div className="d-flex flex-wrap flex-column gap-3 col-8">
          <div className="d-flex gap-3">
            <GreenTag />
            <span className="font-20 font-bold neutral-grey-07">
              {t("Details")}
            </span>
          </div>

          <div className="d-flex flex-column gap-2 mt-4">
            <span className="font-17 font-bolder text-muted">
              {t("Product id")} -
              <span className="font-17 font-bolder text-dark">
                {giftcardSummery && giftcardSummery[0]?.id}
              </span>
            </span>
            {giftcardSummery && giftcardSummery ? (
              <span className="font-17 font-bolder text-muted">
                {t("Country currency")} -
                <span className="font-15 font-bolder text-dark">
                  {giftcardSummery[0]?.country?.name +
                    " - " +
                    giftcardSummery[0]?.country?.currency_symbol}
                </span>
              </span>
            ) : null}

            <span className="font-17 font-bolder text-muted">
              {t("Denominations type")} -
              <span className="font-15 font-bolder text-dark">
                {giftcardSummery &&
                giftcardSummery[0]?.giftcard_value_type === "denominations" ? (
                  <span>{t("Fixed denomination")}</span>
                ) : (
                  <span>{t("Range")}</span>
                )}
              </span>
            </span>

            {giftcardSummery &&
            giftcardSummery[0]?.giftcard_value_type === "denominations" ? (
              <span className="font-17 font-bolder text-muted">
                {t("Denomination")} -
                <span className="font-15 font-bolder text-dark ">
                  <span className="font-17 font-bolder">
                    {giftcardSummery &&
                    giftcardSummery[0]?.giftcard_value_type === "denominations"
                      ? giftcardSummery[0]?.denominations?.map(
                          (deno) => deno.denomination_value + ","
                        )
                      : null}
                  </span>
                </span>
              </span>
            ) : (
              <span className="font-17 font-bolder text-muted">
                {t("Range")} :
                <span className="font-15 font-bolder text-dark ">
                  <span className="font-17 font-bolder">
                    {giftcardSummery &&
                    giftcardSummery[0]?.giftcard_value_type === "range"
                      ? giftcardSummery[0]?.min_amount +
                        "-" +
                        giftcardSummery[0]?.max_amount
                      : null}
                  </span>
                </span>
              </span>
            )}
            <span className="font-17 font-bolder text-muted">
              {t("Business region")} -
              <span className="font-15 font-bolder text-dark ">
                {giftcardSummery && giftcardSummery[0]?.business_region}
              </span>
            </span>

            {giftcardSummery && giftcardSummery[0]?.redemption_type ? (
              <span className="font-17 font-bolder text-muted">
                {t("Redemption Type")} -
                <span className="font-17 font-bolder text-dark">
                  {giftcardSummery[0]?.redemption_type === "redeem_full_value"
                    ? "Redeem Full Value"
                    : "Partial Redeem Value"}
                </span>
              </span>
            ) : null}

            {giftcardSummery && giftcardSummery[0]?.validity ? (
              <span className="font-17 font-bolder text-muted">
                {t("Validity")} -
                <span className="font-17 font-bolder text-dark">
                  {giftcardSummery[0]?.validity}
                </span>
              </span>
            ) : null}

            <span className="font-17 font-bolder text-muted">
              {t("Giftcard type")} -
              <span className="font-15 font-bolder text-dark">
                {giftcardSummery &&
                giftcardSummery[0]?.registered_by_id ===
                  "powered_by_ehadaya" ? (
                  <span className="font-15 font-bold text-dark ">
                    {t("Powered by ehadaya")}
                  </span>
                ) : giftcardSummery &&
                  giftcardSummery[0]?.registered_by_id ===
                    "3rd_party_suppliers" ? (
                  <span className="font-15 font-bold text-dark  ">
                    {t("3rd party suppliers")}
                  </span>
                ) : giftcardSummery &&
                  giftcardSummery[0]?.registered_by_id ===
                    "manual_inventory" ? (
                  <span className="font-15 font-bold text-dark  ">
                    {t("Manual inventory")}
                  </span>
                ) : (
                  <span className="font-13 font-bold text-dark  ">
                    {t("No data")}
                  </span>
                )}
              </span>
            </span>

            {giftcardSummery && giftcardSummery[0]?.brand_id ? (
              <span className="font-17 font-bolder text-muted">
                {t("Brand Id")} -
                <span className="font-17 font-bolder text-dark">
                  {giftcardSummery[0]?.brand_id}
                </span>
              </span>
            ) : null}

            {giftcardSummery && giftcardSummery[0]?.brand_category_id ? (
              <span className="font-17 font-bolder text-muted">
                {t("Brand Category Id")} -
                <span className="font-17 font-bolder text-dark">
                  {giftcardSummery[0]?.brand_category_id}
                </span>
              </span>
            ) : null}
          </div>
        </div>

        <div className="col-4">
          <div className="p-2 flex-fill bd-highlight">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex gap-3">
                <Tagpurple />
                <span className="font-20 font-bold neutral-grey-07">
                  {t("Features")}
                </span>
              </div>
            </div>

            <div className="d-flex flex-column mt-3 w-100  neutral-grey-05 font-15">
              <ul className="list-group list-group-flush ">
                <li className="list-group-item d-flex flex-column">
                  {giftcardSummery &&
                    giftcardSummery[0]?.key_benefits?.map((item, index) => {
                      if (item) {
                        return (
                          <span className="m-2" key={index}>
                            {" "}
                            <Righttick /> {item}
                          </span>
                        );
                      } else return null;
                    })}
                </li>
                <li className="list-group-item d-flex flex-column">
                  {giftcardSummery &&
                    !giftcardSummery[0]?.key_benefits?.length && (
                      <span className="font-15 font-bold text-error">
                        {t("No Key features found")}
                      </span>
                    )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {usertype?.user_type === "internal" ? (
        <div className="d-flex flex-column p-4 w-100 mb-3">
          <div className="d-flex flex-row gap-3">
            <GreenTag />
            <span className="font-20 font-bold neutral-grey-07">
              {t("Mapped supplier")}
            </span>
          </div>

          {giftcardSummery && giftcardSummery[0]?.suppliers.length > 0 ? (
            <div className="d-flex flex-column  shadow-sm rounded-3 py-3 mt-3 w-100">
              <div className="d-flex flex-column  bg-white">
                <div className="row px-5 py-2">
                  <table responsive="table-responsive">
                    <tbody>
                      <tr className="card-body gap-2 border-bottom py-3">
                        <td className="card-body gap-1 font-13 font-bold text-muted">
                          {t("Supplier Id")}
                        </td>
                        <td className="card-body gap-1 font-13 font-bold text-muted">
                          {t("Supplier name")}
                        </td>
                      </tr>
                      {
                        giftcardSummery &&
                          // giftcardSummery[0]?.suppliers.length > 0 ? (
                          giftcardSummery[0]?.suppliers.map((item) => (
                            <tr
                              className="card-body gap-2 border-bottom hover-row"
                              key={item?.supplier_id}
                            >
                              <td className="card-body gap-1">
                                <div className="d-flex flex-column justify-content-start ">
                                  <span className="font-15 font-boldest text-dark ">
                                    {item?.supplier_id}
                                  </span>
                                </div>
                              </td>
                              <td className="card-body gap-1">
                                <div className="d-flex flex-row justify-content-between  gap-3">
                                  <span className="font-15 font-boldest text-dark ">
                                    {item?.name}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))
                        // ) : (
                        //   <div className="d-flex flex-row justify-content-end align-items-center py-3">
                        //     <span className="font-20 font-bold text-error" aria-colspan={2}>
                        //       No Supplier found
                        //     </span>
                        //   </div>
                        // )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-center align-items-center pt-4">
              <span className="font-20 font-bold text-error" aria-colspan={2}>
                {t("No Supplier found")}
              </span>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ModelForGiftCardSummery;
