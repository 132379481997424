// TO GET ALL CLIENT LIST 
export const GET_CLIENT_LIST_REQUEST = "GET_CLIENT_LIST_REQUEST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_FAILURE = "GET_CLIENT_LIST_FAILURE";
// GET GIFT CARD LIST TO MAP WITH CLIENT 
export const GET_CLIENT_GC_LIST_REQUEST = "GET_CLIENT_GC_LIST_REQUEST";
export const GET_CLIENT_GC_LIST_SUCCESS = "GET_CLIENT_GC_LIST_SUCCESS";
export const GET_CLIENT_GC_LIST_FAILURE = "GET_CLIENT_GC_LIST_FAILURE";
// TO PUT CLIENT GIFTCARD MAPPING DATA

export const PUT_GIFTCARD_CLIENT_MAPPING_REQUEST= "PUT_GIFTCARD_CLIENT_MAPPING_REQUEST";
export const PUT_GIFTCARD_CLIENT_MAPPING_SUCCESS= "PUT_GIFTCARD_CLIENT_MAPPING_SUCCESS";
export const PUT_GIFTCARD_CLIENT_MAPPING_FAILURE= "PUT_GIFTCARD_CLIENT_MAPPING_FAILURE";
