import React, { useEffect, useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as EarningIcon } from "../../assests/svg/Product/Earning_Icon.svg";
import { ReactComponent as CustomerIcon } from "../../assests/svg/Product/Customer_Icon.svg";
import { ReactComponent as PayoutIcon } from "../../assests/svg/Product/Payout_Icon.svg";
import { ReactComponent as VectorGreen } from "../../assests/svg/Product/Vector_Green.svg";
import { ReactComponent as VectorBlue } from "../../assests/svg/Product/Vector_Blue.svg";
import { ReactComponent as VectorPurple } from "../../assests/svg/Product/Vector_Purple.svg";
import { ReactComponent as SubtractIcon } from "../../assests/svg/Product/Subtract_Icon.svg";
import { ReactComponent as RedDownArrow } from "../../assests/svg/Product/Red_DownArrow.svg";
import { ReactComponent as GreenUpArrow } from "../../assests/svg/Product/Green_UpArrow.svg";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";


const ProductOverview = () => {
  const { t } = useTranslation();
  const history = useNavigate();

  const [permissions, setpermissions] = useState();
  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    // console.log(permissions);
  }, [permissions]);
  
  const goToAddProduct = () => {
    history("/product-creation/name-description");
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">{t("Overview")}</span>
        </div>
        {permissions?.giftcard?.create ? (
          <div>
            <Button
              type="primary"
              text={t("+Add Giftcard")}
              onClick={() => goToAddProduct()}
            />
          </div>
        ) : null}
      </div>
      <div className="d-flex flex-row justify-content-between gap-2 py-2 px-1 mt-4 w-100">
        <div className="d-flex  flex-column justify-content-between shadow-sm bg-04 rounded-card px-3">
          <div className="d-flex  flex-row justify-content-between align-item-center px-3 py-4">
            <div className="d-flex gap-6 flex-column justify-content-center ">
              <div className="mx-2 my-3">
                <EarningIcon />
              </div>
              <div className="d-flex flex-row justify-content-between w-100 mx-2">
                <div className=" d-flex flex-column ">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row gap-1">
                      {t("Earnings")}
                      <span className="">
                        <SubtractIcon />
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-around gap-5">
                      {/* <div className="d-flex flex-column  fw-bold font-32">
                        128k
                      </div>*/}
                      <div className="d-flex flex-column  fw-bold font-30">
                        No data
                      </div>
                      <div className="d-flex flex-column  ml-2 mb-4">
                        <VectorGreen />
                      </div>
                    </div>
                    <div className="d-flex flex-row rounded-3 bg-white fit-content">
                      {/*  <span className=" text-success font-13 bg-opacity-25 rounded-3 px-1">
                        <GreenUpArrow /> 37.8%
                        <span className=" text-dark fs-light"> this week</span>
                      </span>*/}
                      <span className=" text-success font-13 bg-opacity-25 rounded-3 px-1">
                        <GreenUpArrow /> No data
                        <span className=" text-dark fs-light"> {t("this week")}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex  flex-column justify-content-between shadow-sm bg-05 rounded-card px-3">
          <div className="d-flex  flex-row justify-content-between align-item-center px-3 py-4">
            <div className="d-flex gap-6 flex-column justify-content-center ">
              <div className="mx-2 my-3">
                <CustomerIcon />
              </div>
              <div className="d-flex flex-row justify-content-between w-100 mx-2">
                <div className=" d-flex flex-column ">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row gap-1">
                      {t("Customers")}
                      <span className="">
                        <SubtractIcon />
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-around gap-5">
                      {/* <div className="d-flex flex-column fw-bold font-32">
                        512k
                      </div>*/}
                      <div className="d-flex flex-column fw-bold font-30">
                        No data
                      </div>
                      <div className="d-flex flex-column  ml-2 mb-4">
                        <VectorBlue />
                      </div>
                    </div>
                    <div className="d-flex flex-row rounded-3 bg-white fit-content">
                      {/*  <span className=" text-danger font-13 bg-opacity-25 rounded-3 px-1">
                        <RedDownArrow /> 37.8%
                        <span className=" text-dark"> this week</span>
                      </span>*/}
                      <span className=" text-danger font-13 bg-opacity-25 rounded-3 px-1">
                        <RedDownArrow /> No data
                        <span className=" text-dark"> {t("this week")}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex  flex-column justify-content-between shadow-sm bg-02 rounded-card px-3">
          <div className="d-flex  flex-row justify-content-between align-item-center px-3 py-4">
            <div className="d-flex gap-6 flex-column justify-content-center ">
              <div className="mx-2 my-3">
                <PayoutIcon />
              </div>
              <div className="d-flex flex-row justify-content-between w-100 mx-2">
                <div className=" d-flex flex-column ">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row gap-1">
                      {t("Payouts")}
                      <span className="">
                        <SubtractIcon />
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between gap-5">
                      {/*  <div className="d-flex flex-column fw-bold font-32">
                        64k
                      </div>*/}
                      <div className="d-flex flex-column fw-bold font-30">
                        No data
                      </div>
                      <div className="d-flex flex-column mb-4 ">
                        <VectorPurple />
                      </div>
                    </div>
                    <div className="d-flex flex-row rounded-3 bg-white fit-content">
                      {/* <span className=" text-success font-13 bg-opacity-25 rounded-3 px-1">
                        <GreenUpArrow /> 37.8%
                        <span className=" text-dark"> this week</span>
                      </span>*/}

                      <span className=" text-success font-13 bg-opacity-25 rounded-3 px-1">
                        <GreenUpArrow /> No data
                        <span className=" text-dark"> {t("this week")}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductOverview;
