import { FETCH_AUTH_TOKEN_FAILURE, FETCH_AUTH_TOKEN_REQUEST, FETCH_AUTH_TOKEN_SUCCESS, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, VALID_TOKEN } from "./authTypes"

const initialState = {
    authToken: "",
    massage: "",
    loading: false,
    error: "",
    valid: false
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                massage: "",
                error: '',
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                massage: action.payload,
                error: '',
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                massage: "",
                error: action.payload
            }
        case FETCH_AUTH_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
                valid: false
            }
        case FETCH_AUTH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                authToken: action.payload
            }
        case FETCH_AUTH_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
                valid: false,
                error: action.payload
            }
        case VALID_TOKEN:
            return {
                ...state,
                loading: false,
                valid: true,
                authToken: action.payload.authtoken
            }
        default: return state
    }
}

export default authReducer;