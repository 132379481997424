/* eslint-disable array-callback-return */
import axios from "axios";
import { dateFormatConverter } from "../../utils/constantFunction";
import { baseUrl, getToken } from "../../utils/prodURL";
import {
  SET_CATEGORY_ATTRIBUTE_DATA,
  SET_NAME_DESCRIPTION_DATA,
  SET_MERCHANDISE_MAPPING_DATA,
  SET_MERCHANDISE_SORT_VARIANTS_DATA,
  SET_MERCHANDISE_TERM_CONDITION,
  PUT_MERCHANDISE_SUCCESS,
  PUT_MERCHANDISE_FAILURE,
  PUT_MERCHANDISE_REQUEST,
  GET_MERCHANDISE_SUCCESS,
  GET_MERCHANDISE_FAILURE,
  GET_MERCHANDISE_REQUEST,
  PUT_MERCHANDISE_UPLOAD_IMG_REQUEST,
  PUT_MERCHANDISE_UPLOAD_IMG_SUCCESS,
  PUT_MERCHANDISE_UPLOAD_IMG_FAILURE,
  GET_MERCHANDISE_BY_ID_SUCCESS,
  GET_MERCHANDISE_BY_ID_FAILURE,
  GET_MERCHANDISE_BY_ID_REQUEST,
  GET_MERCHANDISE_SUMMERY_BY_ID_SUCCESS,
  GET_MERCHANDISE_SUMMERY_BY_ID_FAILURE,
  GET_MERCHANDISE_SUMMERY_BY_ID_REQUEST,
  SET_FILTER_MERCHANDISE_DATA,
  EDIT_MERCHANDISE_REQUEST,
  EDIT_MERCHANDISE_SUCCESS,
  EDIT_MERCHANDISE_FAILURE,
  GET_MERCHANDISE_IMAGE_SUCCESS
} from "./merchandiseTypes";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {
  getMerchandiseVariantList
} from "../MerchadiseAllProduct/MerchadiseAllProductAction";


export const setMerchandiseSortVariants = (data) => {
  return {
    type: SET_MERCHANDISE_SORT_VARIANTS_DATA,
    payload: data,
  };
};

// set merchandise mapping data fields into redux
export const setMerchandiseMappingData = (data) => {
  return {
    type: SET_MERCHANDISE_MAPPING_DATA,
    payload: data,
  };
};

export const setMerchandiseCategoryAttribute = (data) => {
  return {
    type: SET_CATEGORY_ATTRIBUTE_DATA,
    payload: data,
  };
};
export const setMerchandiseNameDescription = (data) => {
  return {
    type: SET_NAME_DESCRIPTION_DATA,
    payload: data,
  };
};
export const setMerchandiseTermCondition = (data) => {
  return {
    type: SET_MERCHANDISE_TERM_CONDITION,
    payload: data,
  };
};

// Merchandise put action types
export const putMerchandiseRequest = () => {
  return {
    type: PUT_MERCHANDISE_REQUEST,
  };
};
export const putMerchandiseSuccess = (data) => {
  return {
    type: PUT_MERCHANDISE_SUCCESS,
    payload: data,
  };
};
export const putMerchandiseFailure = (error) => {
  return {
    type: PUT_MERCHANDISE_FAILURE,
    payload: error,
  };
};

// put merchandise async action method
export const putMerchandise = (stateObj, goToMerchandiseSuccessScr) => {

  return (dispatch) => {
    // dispatch(putMerchandiseRequest());
    let shippingCost = parseInt(stateObj?.merchandiseCatAtt?.shippingCostInput);
    let salesTax = parseInt(stateObj?.merchandiseCatAtt?.saleTaxInput);
    let vat = parseInt(stateObj?.merchandiseCatAtt?.vatInput);
    let warrantyInput = parseInt(stateObj?.merchandiseCatAtt?.warrantyInput);
    // let tags = stateObj?.cardCatAttribute?.tags.length;
    // let validity = diffTwoDatesInDays(stateObj?.cardCatAttribute?.startDate, stateObj?.cardCatAttribute?.expireDate)
    // let user = getUser();

    // key_benefits
    let keyBenefitsArr = [];
    const one = stateObj?.merchandiseNameDes?.keyBenefits?.one?.name ? stateObj?.merchandiseNameDes?.keyBenefits?.one?.name : ""
    const two = stateObj?.merchandiseNameDes?.keyBenefits?.two?.name ? stateObj?.merchandiseNameDes?.keyBenefits?.two?.name : ""
    const three = stateObj?.merchandiseNameDes?.keyBenefits?.three?.name ? stateObj?.merchandiseNameDes?.keyBenefits?.three?.name : ""
    const four = stateObj?.merchandiseNameDes?.keyBenefits?.four?.name ? stateObj?.merchandiseNameDes?.keyBenefits?.four?.name : ""

    keyBenefitsArr.push(one, two, three, four)

    let prodTitleArr = [];
    stateObj?.merchandiseNameDes?.prodTitles?.map((item) => {
      prodTitleArr.push({ [item.lang.language_code]: item.title });
    });
    let titleObject = Object.assign({}, ...prodTitleArr);


    // description
    let descriptionArr = [];
    stateObj?.merchandiseNameDes?.description.map((item) => {
      let obj = {
        description_text: draftToHtml(convertToRaw(item.value.getCurrentContent())),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      descriptionArr.push(obj);
    });

    // termCondition
    let termConditionArr = [];
    stateObj?.merchandiseTandC?.termCondition.map((item) => {
      let obj = {
        terms_condition_text: draftToHtml(convertToRaw(item.value.getCurrentContent())),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      termConditionArr.push(obj);
    });

    let payload = {
      language_id: stateObj?.merchandiseNameDes?.preferredLanguage?.language_code,
      product_title: titleObject,
      default_title: stateObj?.merchandiseNameDes?.prodTitles[0]?.title,
      about_the_product: descriptionArr,
      key_benefits: keyBenefitsArr.length > 0 ? keyBenefitsArr : [],
      country_id: stateObj?.merchandiseCatAtt?.countryCurrency?.id,
      state_id: stateObj?.merchandiseCatAtt?.state?.id,
      brand_id: stateObj?.merchandiseCatAtt?.selectBrand?.id,
      brand_category_id: stateObj?.merchandiseCatAtt?.brandCategory?.id,
      weight_unit: stateObj?.merchandiseCatAtt?.weight?.weight?.toLowerCase(),
      weight: stateObj?.merchandiseCatAtt?.weightInput,
      shipping_cost: shippingCost,
      sales_tax_type: "amount",
      sales_tax: salesTax,
      vat_type: "amount",
      vat: vat,
      warranty_type: stateObj?.merchandiseCatAtt?.warranty?.name?.toLowerCase(),
      warranty: warrantyInput,
      promotion_flag: stateObj?.merchandiseCatAtt?.promotionItemFlag,
      featured_flag: false,
      product_variants_flag: stateObj?.merchandiseCatAtt?.hasVariantFlag,
      start_date: stateObj?.merchandiseCatAtt?.startDate
        ? dateFormatConverter(stateObj?.merchandiseCatAtt?.startDate)
        : "2022-04-22T09:56:29.626167",
      end_date: stateObj?.merchandiseCatAtt?.expireDate
        ? dateFormatConverter(stateObj?.merchandiseCatAtt?.expireDate)
        : "2022-04-22T09:56:29.626167",
      tags: stateObj?.merchandiseCatAtt?.brandTags,
      terms_condition: termConditionArr,
      active_flag: stateObj?.merchandiseCatAtt?.activationFlag,
    };

    const formData = new FormData();
    formData.append("request_items", JSON.stringify(payload))
    stateObj?.merchandiseTandC?.productImage && stateObj?.merchandiseTandC?.productImage.map((item) => {
      formData.append("images", item)
    })
    stateObj?.merchandiseTandC && formData.append("video_url", stateObj?.merchandiseTandC?.videoUrl)

    let configMerchandise = {
      method: "post",
      url: `${baseUrl}/v1/merchandise`,
      data: formData,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(configMerchandise).then((res) => {

      if (res.data.code === 200) {
        goToMerchandiseSuccessScr();
        dispatch(getMerchandiseVariantList());
        dispatch(putMerchandiseSuccess(res.data.result));
        // dispatch(putGiftCardsFilesUpload(stateObj, giftCardObj, goToproductSuccessfull));
      }
      else {
        dispatch(putMerchandiseFailure("failed"));
      }
    }).catch((error) => {
      console.log("error   " + JSON.stringify(error));

      dispatch(putMerchandiseFailure("failed"));
    });
  };
};
// To call get API to Merchandise data

export const getMerchandiseRequest = () => {
  return {
    type: GET_MERCHANDISE_REQUEST,
  };
};
export const getMerchandiseSuccess = (data) => {
  return {
    type: GET_MERCHANDISE_SUCCESS,
    payload: data,
  };
};
export const getMerchandiseFailure = (error) => {
  return {
    type: GET_MERCHANDISE_FAILURE,
    payload: error,
  };
};

export const setMerhchandiseFilterData = (data) => {
  return {
    type: SET_FILTER_MERCHANDISE_DATA,
    payload: data
  }
}

export const getMerchandise = () => {
  return (dispatch) => {
    dispatch(getMerchandiseRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {

        if (response?.status === 200) {
          dispatch(getMerchandiseSuccess(response.data.result));
          dispatch(getMerchandiseVariantList());
        }
        else {
          dispatch(getMerchandiseFailure("faild"));
        }
      })
      .catch((error) => {
        dispatch(getMerchandiseFailure(error));
      });
  };
};

export const putMerchandiseVarientsProduct = (stateObj, varientState, varientImg, goToMerchandiseSuccessScr) => {
  return (dispatch) => {

    dispatch(putMerchandiseRequest());
    let shippingCost = parseInt(stateObj?.merchandiseCatAtt?.shippingCostInput);
    let salesTax = parseInt(stateObj?.merchandiseCatAtt?.saleTaxInput);
    let vat = parseInt(stateObj?.merchandiseCatAtt?.vatInput);
    let warrantyInput = parseInt(stateObj?.merchandiseCatAtt?.warrantyInput);
    let hasVariantFlag = stateObj?.merchandiseCatAtt?.hasVariantFlag ? true : false;


    // key_benefits
    let keyBenefitsArr = [];
    const one = stateObj?.merchandiseNameDes?.keyBenefits?.one?.name ? stateObj?.merchandiseNameDes?.keyBenefits?.one?.name : ""
    const two = stateObj?.merchandiseNameDes?.keyBenefits?.two?.name ? stateObj?.merchandiseNameDes?.keyBenefits?.two?.name : ""
    const three = stateObj?.merchandiseNameDes?.keyBenefits?.three?.name ? stateObj?.merchandiseNameDes?.keyBenefits?.three?.name : ""
    const four = stateObj?.merchandiseNameDes?.keyBenefits?.four?.name ? stateObj?.merchandiseNameDes?.keyBenefits?.four?.name : ""

    keyBenefitsArr.push(one, two, three, four)

    let prodTitleArr = [];
    // eslint-disable-next-line array-callback-return
    stateObj?.merchandiseNameDes?.prodTitles && stateObj?.merchandiseNameDes?.prodTitles?.map((item) => {
      prodTitleArr.push({ [item.lang.language_code]: item.title });
    });
    let titleObject = Object.assign({}, ...prodTitleArr);


    // description
    let descriptionArr = [];
    // eslint-disable-next-line array-callback-return
    stateObj?.merchandiseNameDes?.description && stateObj?.merchandiseNameDes?.description.map((item) => {
      let obj = {
        description_text: draftToHtml(convertToRaw(item.value.getCurrentContent())),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      descriptionArr.push(obj);
    });

    // termCondition
    let termConditionArr = [];
    // eslint-disable-next-line array-callback-return
    varientState.merchandiseVarientTermImg.termAndconditions && varientState.merchandiseVarientTermImg.termAndconditions.map((item) => {
      let obj = {
        terms_condition_text: draftToHtml(convertToRaw(item.value.getCurrentContent())),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      termConditionArr.push(obj);
    });

    // variant product Term 
    let termConditionArrVarient = [];
    // eslint-disable-next-line array-callback-return
    stateObj?.merchandiseTandC?.termCondition && stateObj?.merchandiseTandC?.termCondition.map((item) => {
      let obj = {
        terms_condition_text: draftToHtml(convertToRaw(item.value.getCurrentContent())),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      termConditionArrVarient.push(obj);
    });

    let payload = {
      language_id: stateObj?.merchandiseNameDes?.preferredLanguage?.language_code,
      product_title: titleObject,
      default_title: stateObj?.merchandiseNameDes?.prodTitles[0]?.title,
      about_the_product: descriptionArr,
      key_benefits: keyBenefitsArr.length > 0 ? keyBenefitsArr : [],
      country_id: stateObj?.merchandiseCatAtt?.countryCurrency?.id,
      state_id: stateObj?.merchandiseCatAtt?.state?.id,
      brand_id: stateObj?.merchandiseCatAtt?.selectBrand?.id,
      brand_category_id: stateObj?.merchandiseCatAtt?.brandCategory?.id,
      weight_unit: stateObj?.merchandiseCatAtt?.weight?.weight?.toLowerCase(),
      weight: stateObj?.merchandiseCatAtt?.weightInput,
      shipping_cost: shippingCost,
      sales_tax_type: "amount",
      sales_tax: salesTax,
      vat_type: "amount",
      vat: vat,
      warranty_type: stateObj?.merchandiseCatAtt?.warranty?.name?.toLowerCase(),
      warranty: warrantyInput,
      promotion_flag: stateObj?.merchandiseCatAtt?.promotionItemFlag,
      featured_flag: false,
      product_variants_flag: stateObj?.merchandiseCatAtt?.hasVariantFlag,
      start_date: stateObj?.merchandiseCatAtt?.startDate
        ? dateFormatConverter(stateObj?.merchandiseCatAtt?.startDate)
        : "2022-04-22T09:56:29.626167",
      end_date: stateObj?.merchandiseCatAtt?.expireDate
        ? dateFormatConverter(stateObj?.merchandiseCatAtt?.expireDate)
        : "2022-04-22T09:56:29.626167",
      tags: stateObj?.merchandiseCatAtt?.brandTags,
      terms_condition: termConditionArr,
      active_flag: stateObj?.merchandiseCatAtt?.activationFlag,
      show_as_points: true
    };



    const formData = new FormData();
    formData.append("request_items", JSON.stringify(payload))

    let configMerchandise = {
      method: "post",
      url: `${baseUrl}/v1/merchandise`,
      data: formData,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(configMerchandise).then((res) => {
      if (res.data.code === 200) {
        let prodObj = {
          id: res.data.result.id,
          product_title: res.data.result.default_title,
        };
        dispatch(putMerchandiseSuccess(res.data.result));
        dispatch(putMerchandiseVariation(prodObj, stateObj, varientState, varientImg, goToMerchandiseSuccessScr));
        dispatch(putMerchandiseVarients(prodObj, stateObj, varientState, varientImg, goToMerchandiseSuccessScr));
      }
      else {
        dispatch(putMerchandiseFailure("failed"));
      }
    }).catch((error) => {
      console.log("error   " + JSON.stringify(error));

      dispatch(putMerchandiseFailure("failed"));
    });
  };
};



export const putMerchandiseVariation = (prodObj, stateObj, varientState, varientImg, goToMerchandiseSuccessScr) => {
  return (dispatch) => {
    dispatch(putMerchandiseRequest);

    let arr = varientState?.merchandiseVarient;
    let arr2 = varientState?.merchandiseVarientObj;

    let variationValuesList = [];
    let variationNames = [];
    arr2.map((item) =>
      variationValuesList.push(item.tags)
    )
    arr.map((item) =>
      variationNames = (Object.keys(item))
    )
    let variationData = {
      "merchandise_id": prodObj.id,
      "variation_names": variationNames,
      "variation_values_list": variationValuesList,
    }

    let configVariation = {
      method: "post",
      url: `${baseUrl}/v1/merchandise/variation/values`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: variationData,
    };

    axios(configVariation)
      .then((res) => {
        if (res.status === 200) {

        }
        else {
          // dispatch(putMerchandiseFailure("failed"));
        }
      }).catch((error) => {
        console.log("error   " + JSON.stringify(error));

        //dispatch(putMerchandiseFailure("failed"));
      });
  };
};


// Merchandise put action types
export const putMerchandiseUploadImgRequest = () => {
  return {
    type: PUT_MERCHANDISE_UPLOAD_IMG_REQUEST,
  };
};
export const putMerchandiseUploadImgSuccess = (data) => {
  return {
    type: PUT_MERCHANDISE_UPLOAD_IMG_SUCCESS,
    payload: data,
  };
};
export const putMerchandiseUploadImgFailure = (error) => {
  return {
    type: PUT_MERCHANDISE_UPLOAD_IMG_FAILURE,
    payload: error,
  };
};

// export const putMerchandiseVarientsImages = (resVariants, varientImg) => {
export const putMerchandiseVarientsImages = (varientImg) => {

  return (dispatch) => {
    dispatch(putMerchandiseUploadImgRequest);

    let imagesResponseObj = [];

    varientImg && varientImg.map((imgArr) => {

      const formData = new FormData();

      formData.append("merchandise_id", "")
      formData.append("variant_id", "")
      formData.append("product_variants_flag", true)
      imgArr.images && imgArr.images.map((img) => {
        formData.append("images", img)
      })
      formData.append("active_flag", true)
      formData.append("image_processing", "")


      let config = {
        method: 'post',
        url: `${baseUrl}/v1/merchandise/files`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: formData
      };

      axios(config).then((res) => {
        if (res.status === 200) {

          let idsArr = [];
          res?.data?.result && res?.data?.result.map((item) => {
            idsArr.push(item.id);
          })

          let obj = {
            variantKey: imgArr?.color,
            ids: idsArr
          }
          imagesResponseObj.push(obj);

          dispatch(putMerchandiseUploadImgSuccess());
        }
        else {
          dispatch(putMerchandiseUploadImgFailure("failed"));
        }
      }).catch((error) => {
        console.log("error   " + JSON.stringify(error));

        //dispatch(putMerchandiseUploadImgFailure("failed"));
      });
    })


  };
};


export const putMerchandiseVarients = (prodObj, stateObj, varientState, varientImg, goToMerchandiseSuccessScr) => {
  return async (dispatch) => {


    let imagesResponseObj = {};


    const imageUpload = async () => {
      return new Promise((resolve, reject) => {

        varientImg && varientImg.map((imgArr, index) => {

          const formData = new FormData();

          formData.append("merchandise_id", "")
          formData.append("variant_id", "")
          formData.append("product_variants_flag", true)
          imgArr.images && imgArr.images.map((img) => {
            formData.append("images", img)
          })
          formData.append("active_flag", true)
          formData.append("image_processing", "")


          let config = {
            method: 'post',
            url: `${baseUrl}/v1/merchandise/files`,
            headers: {
              Authorization: `Bearer ${getToken()}`,
              "Content-Type": "application/x-www-form-urlencoded"
            },
            data: formData
          };

          axios(config).then(async (res) => {
            if (res.status === 200) {

              let idsArr = [];
              res?.data?.result && res?.data?.result.map((item) => {
                idsArr.push(item.id);
              })

              imagesResponseObj = await {
                ...imagesResponseObj,
                [imgArr?.color]: {
                  variantKey: imgArr?.color,
                  ids: idsArr
                }
              };

              if (Object.keys(imagesResponseObj).length === varientImg.length) resolve(imagesResponseObj);
              dispatch(putMerchandiseUploadImgSuccess());
            }
            else {
              dispatch(putMerchandiseUploadImgFailure("failed"));
            }
          }).catch((error) => {
            console.log("error   " + JSON.stringify(error));

            //dispatch(putMerchandiseUploadImgFailure("failed"));
          });
        })
      })
    }

    const variantsUpload = async (images) => {
      dispatch(putMerchandiseRequest);

      let arr = varientState?.merchandiseVarient;
      let payloadObj = [];

      await arr.map(async (item, i) => {
        // let variantArr = []

        let variant_key = Object.keys(item).map(function (k) { return item[k] })

        let variationData = {
          "merchandise_id": prodObj.id,
          "variant_keys": Object.keys(item),
          "variant_values": variant_key,
          "variants_images_id_list": images?.[variant_key[0]]?.ids
        }
        // variantArr.push(Object.keys(item).map(function (k) { return item[k] }).join(","));
        // variantArr.push(Object.keys(item).map(function (k) { return item[k] }));
        payloadObj.push(variationData);
      })

      let config = {
        method: "post",
        url: `${baseUrl}/v1/merchandise/variants`,
        data: payloadObj,
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      };

      await axios(config)
        .then((res) => {
          if (res.status === 200) {

            //merchandise variants images upload
            let arrPayload = [];

            res.data.result && res.data.result.map((item) => {
              let imagesObj = [];

              varientImg && varientImg.map((img) => {
                if (img.color === item.variant_values[0]) {
                  imagesObj.push(img.images);
                }
              })
              let obj = {
                "merchandise_id": item.merchandise_id,
                "variant_id": item.id,
                "product_variants_flag": true,
                "images": imagesObj,
                "active_flag": true,
                "image_processing": "",
              }
              arrPayload.push(obj);
            });

            arrPayload && arrPayload.map((item) => {
              const formData = new FormData();

              formData.append("merchandise_id", item.merchandise_id)
              formData.append("variant_id", item.variant_id)
              formData.append("product_variants_flag", true)
              item.images && item.images[0].map((img) => {
                formData.append("images", img)
              })
              formData.append("active_flag", true)
              formData.append("image_processing", "")


              //  dispatch(putMerchandiseVarientsImages(formData));

            })

            goToMerchandiseSuccessScr();
            dispatch(getMerchandiseVariantList());
          }
          else {
            // dispatch(putMerchandiseFailure("failed"));
          }
        }).catch((error) => {
          console.log("error   " + JSON.stringify(error));

          //dispatch(putMerchandiseFailure("failed"));
        });
    }

    let images = await imageUpload();
    await variantsUpload(images);


  };

}

export const getMerchandiseImageSuccess = (data) => {
  return {
    type: GET_MERCHANDISE_IMAGE_SUCCESS,
    payload: data
  }
}

export const getMerchandiseImage = (merchandiseId) => {
  return (dispatch) => {
    let configMerchandise = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/${merchandiseId}/files`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(configMerchandise).then((response) => {
      dispatch(getMerchandiseImageSuccess(response?.data?.result))
    })

  }
}

export const editMerchandiseRequest = () => {
  return {
    type: EDIT_MERCHANDISE_REQUEST,

  }
}

export const editMerchandiseSuccess = (data) => {
  return {
    type: EDIT_MERCHANDISE_SUCCESS,
    payload: data

  }
}

export const editMerchandiseFailure = (error) => {
  return {
    type: EDIT_MERCHANDISE_FAILURE,
    payload: error
  }
}

export const editMerchandise = (obj, merchandiseSuccess) => {
  return (dispatch) => {
    dispatch(editMerchandiseRequest())

    let prodTitleArr = [];

    // eslint-disable-next-line array-callback-return
    obj?.product_title?.map((item) => {
      prodTitleArr.push({ [item.lang.language_code]: item.title });
    });
    let titleObject = Object.assign({}, ...prodTitleArr);

    // description
    let descriptionArr = [];
    obj?.about_the_product.map((item) => {
      let desObj = {
        description_text: draftToHtml(
          convertToRaw(item.value.getCurrentContent())
        ),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      descriptionArr.push(desObj);
    });

    // Term and Condition
    let termConditionArr = [];
    obj?.terms_condition.map((item) => {
      let desObj = {
        terms_condition_text: draftToHtml(
          convertToRaw(item.value.getCurrentContent())
        ),
        language_id: item.lang.language_code,
        active_flag: true,
      };
      termConditionArr.push(desObj);
    })

    const keyBenifits = [];
    obj?.key_benefits &&
      keyBenifits.push(obj?.key_benefits?.one?.name);
    obj?.key_benefits &&
      keyBenifits.push(obj?.key_benefits?.two?.name);
    obj?.key_benefits &&
      keyBenifits.push(obj?.key_benefits?.three?.name);
    obj?.key_benefits &&
      keyBenifits.push(obj?.key_benefits?.four?.name);

    const payload = {
      "language_id": obj?.language_id,
      "product_title": titleObject,
      "default_title": obj?.default_title,
      "about_the_product": descriptionArr,
      "key_benefits": keyBenifits,
      "brand_id": obj?.brand_id,
      "brand_category_id": obj?.brand_category_id,
      "weight_unit": obj?.weight_unit,
      "weight": obj?.weight,
      "shipping_cost": obj?.shipping_cost === "" ? 0 : obj?.shipping_cost,
      "sales_tax_type": obj?.sales_tax_type === "%" ? "percentage" : "amount",
      "sales_tax": obj?.sales_tax === "" ? 0 : obj?.sales_tax,
      "vat_type": obj?.vat_type === "%" ? "percentage" : "amount",
      "vat": obj?.vat === "" ? 0 : obj?.vat,
      "warranty_type": obj?.warranty_type?.toLowerCase(),
      "warranty": obj?.warranty === "" ? 0 : obj?.warranty,
      "promotion_flag": obj?.promotion_flag,
      "featured_flag": obj?.featured_flag,
      "product_variants_flag": obj?.product_variants_flag,
      "start_date": dateFormatConverter(obj?.start_date),
      "end_date": dateFormatConverter(obj?.end_date),
      "tags": obj?.tags,
      "terms_condition": termConditionArr,
      "active_flag": obj?.active_flag,
      "deleted_flag": obj?.deleted_flag,
      "show_as_points": obj?.show_as_points,
      "availability": obj?.availability,
      "updated_at": dateFormatConverter(obj?.updated_at),
    }


    let configMerchandise = {
      method: "patch",
      url: `${baseUrl}/v1/merchandise/${obj?.merchandiseId}`,
      data: payload,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    };

    axios(configMerchandise).then((response) => {
      if (response.status === 200) {
        dispatch(editMerchandiseSuccess(response?.data?.result))
        dispatch(getMerchandise())
        merchandiseSuccess()
      }
    }).catch((error) => {
      dispatch(editMerchandiseFailure(error))
    })

  }
}

// To GET merchandise by id for edit

export const getMerchandiseByIdRequest = () => {
  return {
    type: GET_MERCHANDISE_BY_ID_REQUEST,
  };
};
export const getMerchandiseByIdSuccess = (data) => {
  return {
    type: GET_MERCHANDISE_BY_ID_SUCCESS,
    payload: data,
  };
};
export const getMerchandiseByIdFailure = (error) => {
  return {
    type: GET_MERCHANDISE_BY_ID_FAILURE,
    payload: error,
  };
};

export const getMerchandiseById = (id) => {
  return (dispatch) => {
    dispatch(getMerchandiseByIdRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {

        if (response?.status === 200) {
          dispatch(getMerchandiseByIdSuccess(response.data.result));
        }
        else {
          dispatch(getMerchandiseByIdFailure("faild"));
        }
      })
      .catch((error) => {
        dispatch(getMerchandiseFailure(error));
      });
  };
};

// get merchandise summery by id

export const getMerchandiseSummeryByIdRequest = () => {
  return {
    type: GET_MERCHANDISE_SUMMERY_BY_ID_REQUEST,
  };
};
export const getMerchandiseSummeryByIdSuccess = (data) => {
  return {
    type: GET_MERCHANDISE_SUMMERY_BY_ID_SUCCESS,
    payload: data,
  };
};
export const getMerchandiseSummeryByIdFailure = (error) => {
  return {
    type: GET_MERCHANDISE_SUMMERY_BY_ID_FAILURE,
    payload: error,
  };
};

export const getMerchandiseSummeryById = (id) => {
  return (dispatch) => {
    dispatch(getMerchandiseSummeryByIdRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/variants/list?merchandise_ids=${id}&supplier_info=true&brand_info=false`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(getMerchandiseSummeryByIdSuccess(response.data.result));
        }
        else {
          dispatch(getMerchandiseSummeryByIdFailure("faild"));
        }
      })
      .catch((error) => {
        dispatch(getMerchandiseSummeryByIdFailure(error));
      });
  };
};