import {GET_TRANSACTION_CRITARIA_REQUEST,
    GET_TRANSACTION_CRITARIA_SUCCESS,
    GET_TRANSACTION_CRITARIA_FAILURE} from '../TransactionCritaria/transactionType'

  const initialTransactionCritariaState = {
    loading: false,
    transactionCritaria: [],
    error: "",
  };
  
  const transactionCritariaReducer = (state = initialTransactionCritariaState, action) => {
    switch (action.type) {
      case GET_TRANSACTION_CRITARIA_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_TRANSACTION_CRITARIA_SUCCESS:
        return {
          ...state,
          loading: false,
          transactionCritaria: action.payload,
          error: "",
        };
      case GET_TRANSACTION_CRITARIA_FAILURE:
        return {
          ...state,
          loading: false,
          transactionCritaria: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  export default transactionCritariaReducer;
  