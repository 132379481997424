import React from "react";
import { useNavigate } from "react-router-dom";
import CardPreview from "../../components/Products/CardPreview";
import Header from "../../components/Products/Header";
import ProductTermCondition from "../../components/Products/ProductTermCondition";

const AddProductTermCondition = (props) => {
    const history = useNavigate();

    const goToNextScreen = () => {
        history("/product-creation/category-attibutes")
    }

    return (
        <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
            <Header steps={5} title={"Terms & Condition"} />
            <div className="d-flex flex-row mt-2 gap-2">
                <div className="width-65" >
                    <ProductTermCondition goToNextScreen={goToNextScreen} lang={props?.lang} />
                </div>
                <div className="width-35" >
                    <CardPreview />

                </div>
            </div>

        </div>
    );
};

export default AddProductTermCondition;
