import {
  GET_WEIGHT_REQUEST,
  GET_WEIGHT_SUCCESS,
  GET_WEIGHT_FAILURE,
} from "./weightType";

import { baseUrl, getToken } from "../../utils/prodURL";
import axios from "axios";

export const getWeightRequest = () => {
  return {
    type: GET_WEIGHT_REQUEST,
  };
};
export const getWeightSuccess = (weight) => {
  return {
    type: GET_WEIGHT_SUCCESS,
    payload: weight,
  };
};
export const getWeightFailure = (error) => {
  return {
    type: GET_WEIGHT_FAILURE,
    payload: error,
  };
};

export const getWeight = () => {
  return (dispatch) => {
    dispatch(getWeightRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/weight`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
       // console.log("response" + JSON.stringify(response.data));
       dispatch(getWeightSuccess(response.data))
      })
      .catch((error) => {
        const errorMsg = error;
        dispatch(getWeightFailure("Something went wrong!! Please try again!!"));
      });
  };
};
