import {
  GET_CLIENT_MER_LIST_REQUEST,
  GET_CLIENT_MER_LIST_SUCCESS,
  GET_CLIENT_MER_LIST_FAILURE,
  SET_SELECTED_CLIENT,
  PUT_CLIENT_MER_LIST_REQUEST,
  PUT_CLIENT_MER_LIST_SUCCESS,
  PUT_CLIENT_MER_LIST_FAILURE,
} from "./ClientMerchandiseType";

import { baseUrl, getToken } from "../../../../utils/prodURL";
import axios from "axios";

export const getClientMerchandiseListByIDRequest = () => {
  return {
    type: GET_CLIENT_MER_LIST_REQUEST,
  };
};
export const getClientMerchandiseListByIDSuccess = (clientMerList) => {
  return {
    type: GET_CLIENT_MER_LIST_SUCCESS,
    payload: clientMerList,
  };
};
export const getClientMerchandiseListByIDFailure = (error) => {
  return {
    type: GET_CLIENT_MER_LIST_FAILURE,
    payload: error,
  };
};
// SET SELECTED CLIENT SET_SELECTED_CLIENT
export const setSelectedClient = (obj) => {
  return {
    type: SET_SELECTED_CLIENT,
    payload: obj,
  };
};
export const getClientMerchandiseListByID = () => {
  return (dispatch) => {
    dispatch(getClientMerchandiseListByIDRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        //  console.log("client response" + JSON.stringify(response.data));
        dispatch(getClientMerchandiseListByIDSuccess(response.data));
      })
      .catch((error) => {
        const errorMsg = error;
        dispatch(
          getClientMerchandiseListByIDFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};
// TO POST CLIENT MERCHANDISE MAPPED DATA
export const putClientMerchandiseRequest = () => {
  return {
    type: PUT_CLIENT_MER_LIST_REQUEST,
  };
};

export const putClientMerchandiseSuccess = (data) => {
  return {
    type: PUT_CLIENT_MER_LIST_SUCCESS,
    payload: data,
  };
};

export const putClientMerchandiseFailure = (error) => {
  return {
    type: PUT_CLIENT_MER_LIST_FAILURE,
    payload: error,
  };
};

export const putClientMerchandiseMappingProd = (selectedProducts, successPage) => {

  return (dispatch) => {
    dispatch(putClientMerchandiseRequest());
    let payload = [];

    // eslint-disable-next-line array-callback-return
    selectedProducts.map((item) => {
      // console.log("item ", item)
      let obj = {
        "mapped_for": item?.product?.product?.product_variants_flag ? "merchandise_variant" : "merchandise",
        "merchandise_id": item?.product?.product?.id,
        "variant_id": item?.product?.variantId,
        "client_list": [
          {
            "client_id": item?.client.id,
            "selling_price": item?.sellingPrice,
            "margin": item?.margin,
            supplier_id: item?.supplier?.supplier_id
          }
        ],
        "active_flag": true

      };
      payload.push(obj);
    });
    // console.log("payload ", payload);

    let config = {
      method: "post",
      url: `${baseUrl}/v1/merchandise/client/mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };
    axios(config)
      .then((response) => {
         console.log("client response", response);
         if(response.status === 200){
           dispatch(putClientMerchandiseSuccess(response.data));
           successPage()
         }
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(putClientMerchandiseFailure("Something went wrong!! Please try again!!"));
      });
  };
};
