import React from "react";
import { IconContext } from "react-icons";
import { HiOutlineRefresh } from "react-icons/hi";


const RefreshButton = (props) => {
    const { onClick } = props
    return (
        <>
            <div className="d-flex justify-content-center cursor border shadow-sm rounded-3"
                onClick={() => onClick()} >
                <IconContext.Provider
                    value={{
                        color: "#6F767E",
                        size: "35px",
                        className: "global-class-name",
                    }}
                >
                    <HiOutlineRefresh width="50" height="50" />
                </IconContext.Provider>
            </div>
        </>
    );
};

export default RefreshButton;
