import {
  FETCH_PARTNER_CATEGORY_FAILURE,
  FETCH_PARTNER_CATEGORY_SUCCESS,
  FETCH_PARTNER_CATEGORY_REQUEST,
  SET_CREATE_PARTNER_DATA,
  PUT_PARTNER_REQUEST,
  PUT_PARTNER_SUCCESS,
  PUT_PARTNER_FAILURE,
  FETCH_PARTNER_FAILURE,
  FETCH_PARTNER_SUCCESS,
  FETCH_PARTNER_REQUEST,
  POST_PARTNER_CATEGORY_REQUEST,
  POST_PARTNER_CATEGORY_SUCCESS,
  POST_PARTNER_CATEGORY_FAILURE,
  PATCH_PATRNER_CATEGORY_REQUEST,
  PATCH_PATRNER_CATEGORY_SUCCESS,
  PATCH_PATRNER_CATEGORY_FAILURE,
  CLEAR_PARTNER_CAT_LIST,
} from "../Partner/partnerType";

const initialState = {
  partner: [],
  partnerCategory: [],
  createpartnerData: null,
  createPartnerResponese: null,
  partnerCategoryResponse: null,
  partnerList: [],
  loading: false,
  error: "",
};

function partnerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CREATE_PARTNER_DATA:
      return {
        ...state,
        createpartnerData: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_PARTNER_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_PARTNER_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        partnerCategory: action.payload,
        error: "",
      };
    case FETCH_PARTNER_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        partnerCategory: [],
        error: action.payload,
      };
    case CLEAR_PARTNER_CAT_LIST:
      return {
        ...state,
        loading: false,
        partnerCategory: [],
        error: "",
      };
    case PUT_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        createPartnerResponese: null,
      };
    case PUT_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
        createPartnerResponese: action.payload,
        createpartnerData: "",
        error: "",
      };
    case PUT_PARTNER_FAILURE:
      return {
        ...state,
        loading: false,
        createPartnerResponese: null,
        error: action.payload,
      };

    case FETCH_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        partnerList: [],
      };
    case FETCH_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
        partnerList: action.payload,
        error: "",
      };
    case FETCH_PARTNER_FAILURE:
      return {
        ...state,
        loading: false,
        partnerList: [],
        error: action.payload,
      };
    case POST_PARTNER_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case POST_PARTNER_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        partnerList: [],
        createPartnerResponese: null,
        createpartnerData: "",
        partnerCategoryResponse: action.payload,
        error: "",
      };
    case POST_PARTNER_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        partnerCategoryResponse: null,
        error: action.payload,
      };
    case PATCH_PATRNER_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case PATCH_PATRNER_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        partnerList: [],
        createPartnerResponese: null,
        createpartnerData: "",
        partnerCategoryResponse: action.payload,
        error: "",
      };
    case PATCH_PATRNER_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        partnerCategoryResponse: null,
        error: action.payload,
      };

    default:
      return state;
  }
}

export default partnerReducer;
