/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupplierMapMerchandise } from "../../Redux/Supplier/supplierAction";
import Default_product from "../../assests/img/Product-default.png";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg";
import { getMerchandiseSummeryById } from "../../Redux/Merchandise/merchandiesAction";
import ModalComp from "../Models/ModalComp";
import ModelForMerchandiseSummery from "../Models/ModelForMerchandiseSummery";
import SmallToast from "../Toast/SmallToast";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoaders from "react-spinners/PulseLoader";
import PulseLoader from "../Loader/PulseLoader";
import { useTranslation } from "react-i18next";
import { DataSortingHandler } from "../../utils/constantFunction";
import { SORT_TYPE } from "../../utils/ConstantData";

const PartnerDetailMerchandiseTable = (props) => {
  const { t } = useTranslation();
  const {
    partnerId,
    searchValue,
    merchandiseTitle,
    merchandiseId,
    countryCurrency,
    sellingPrice,
  } = props;
  const dispatch = useDispatch();

  const supplierMappedMerchandise = useSelector(
    (state) => state?.suppliers?.supplierMappedMerchandise
  );

  const loading = useSelector((state) => state?.suppliers?.loading);
  // for model Merchandise
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );

  const handleClose = () => setShowMerchandiseModal(false);
  const [showMerchandiseModal, setShowMerchandiseModal] = useState(false);
  const [supplierMapMerchandise, setSupplierMapMerchandise] = useState([]);
  const [id, setId] = useState("");
  const hasmore = useSelector((state) => state?.suppliers?.next_iteration);
  // set infinite loader
  const [skip, setskip] = useState(
    supplierMapMerchandise.length === 0
      ? -10
      : supplierMapMerchandise.length - 10
  );
  const [limit, setlimit] = useState(10);

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {}, [order]);

  useEffect(() => {
    let arr = [...supplierMappedMerchandise];
    let prodArr = [];

    arr &&
      arr?.map((item, i) => {
        let obj = {
          ...item,
          collapseFlag: false,
        };
        prodArr.push(obj);
      });
    setSupplierMapMerchandise(prodArr);
  }, [supplierMappedMerchandise]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (supplierMapMerchandise.length === 0) {
      fetchdata(skip);
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(
        fetchSupplierMapMerchandise(
          partnerId,
          skip + limit,
          limit,
          supplierMapMerchandise
        )
      );
      setskip(skip + limit);
    }, 100);
  };

  const showSummaryModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowMerchandiseModal(true);
  };

  // useEffect(() => {
  //   partnerId && dispatch(fetchSupplierMapMerchandise(partnerId));
  // }, [partnerId]);

  const dropdownHandler = (index) => {
    let arr = [...supplierMappedMerchandise];
    arr &&
      arr?.map((item, i) => {
        if (index === i) {
          arr[i].collapseFlag = !item.collapseFlag;
        }
      });
    setSupplierMapMerchandise(arr);
  };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 5);
    return val;
  };

  // copy to clipboard
  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };


  return loading === true && supplierMappedMerchandise?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <InfiniteScroll
      dataLength={supplierMappedMerchandise?.length || 0}
      next={fetchdata}
      hasMore={hasmore}
      loader={
        <div className="d-flex justify-content-center">
          {" "}
          <PulseLoaders color={"#594190"} loading={loading} size={10} />{" "}
        </div>
      }
    >
      <div className="card-body d-flex flex-column justify-content-between align-item-center gap-5 px-2">
        <table responsive="xl">
          <tbody>
            <tr className="d-flex align-items-center text-muted font-14 font-bolder">
              <td className="col-4  px-4">
                <div className="d-flex flex-row justify-content-start">
                  <span
                    className={`mx-3 cursor ${
                      tableHeaderName === "default_title"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "default_title",
                        setTableHeaderName,
                        supplierMapMerchandise,
                        order,
                        setOrder
                      )
                    }
                  >
                    {merchandiseTitle
                      ? merchandiseTitle
                      : t("Merchandise title")}
                  </span>
                </div>
              </td>
              <td
                className={`col-3 px-1 cursor ${
                  tableHeaderName === "id" ? "ascending" : "descending"
                }`}
                onClick={() =>
                  sortingHandler(
                    "id",
                    setTableHeaderName,
                    supplierMapMerchandise,
                    order,
                    setOrder
                  )
                }
              >
                {merchandiseId ? merchandiseId : t("Merchandise id")}
              </td>
              <td
                className={`col-3 ${
                  tableHeaderName === "currency" ? "ascending" : "descending"
                }`}
                onClick={() =>
                  sortingHandler(
                    "currency",
                    setTableHeaderName,
                    supplierMapMerchandise,
                    order,
                    setOrder
                  )
                }
              >
                {countryCurrency ? countryCurrency : t("Country & currency")}
              </td>
              <td
                className={`col-2 cursor ${
                  tableHeaderName === "supplier_price" ? "ascending" : "descending"
                }`}
                onClick={() =>
                  sortingHandler(
                    "supplier_price",
                    setTableHeaderName,
                    supplierMapMerchandise,
                    order,
                    setOrder
                  )
                }
              >
                {sellingPrice ? sellingPrice : t("Selling price")}
              </td>
            </tr>
            {supplierMapMerchandise &&
              supplierMapMerchandise
                .filter((item) => {
                  if (searchValue === "") {
                    return item;
                  } else if (
                    item?.merchandise?.default_title
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item, index) => (
                  <tr
                    key={index}
                    className="d-flex flex-column justify-content-start px-4"
                  >
                    <div className="d-flex flex-row justify-content-start align-items-center ">
                      <td className="col-4 pt-3">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                          <span className="p-2">
                            <img
                              src={
                                item?.merchandise?.images &&
                                  item?.merchandise?.images
                                  ? item?.merchandise?.images[0]?.file_url
                                  : Default_product
                              }
                              alt="not found"
                              id="product-thumbnail"
                            />
                          </span>
                          <div className="d-flex flex-column justify-content-start ">
                            <span
                              className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                              title={item?.merchandise?.default_title}
                              style={{ maxWidth: "150px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              onClick={() =>
                                showSummaryModel(item?.merchandise?.id)
                              }
                            >
                              {item?.merchandise?.default_title}
                            </span>

                            <span className="font-10 font-bold">
                              {item?.product_class === "merchandise_variant"
                                ? item?.merchandise_variant &&
                                item?.merchandise_variant.length + " variants"
                                : "No variants"}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="col-3 pt-3">
                        <div className="d-flex justify-content-start align-items-center">
                          <span
                            className="font-13 font-bold text-muted d-inline-block text-truncate cursor"
                            title={item?.merchandise?.id}
                            style={{ maxWidth: "160px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {trimIdHandler(item?.merchandise?.id)}
                            <span>...</span>
                          </span>

                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Copy to clipboard"
                            className="cursor "
                            value={item?.merchandise?.id}
                            onClick={() =>
                              copyToClipboard(item?.merchandise?.id)
                            }
                          >
                            <CopyToClipboard text={item?.merchandise?.id}>
                              <CopyButton />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </td>

                      <td className="col-3 pt-3">
                        <div className="d-flex flex-column justify-content-start mx-4">
                          <span className="font-15 font-bolder text-dark bg-04 rounded-3 px-1 fit-content">
                            {`${item?.merchandise?.country?.currency}-(${item?.merchandise?.country?.currency_symbol})`}
                          </span>
                        </div>
                      </td>

                      <td className="col-2 pt-3">
                        <div className="d-flex justify-content-center  px-3">
                          <span className="d-flex font-12 font-boldest text-dark">
                            {item?.supplier_price ? (
                              item?.supplier_price
                            ) : (
                              <span>
                                {item?.product_class ===
                                  "merchandise_variant" ? (
                                  <div
                                    className="d-flex justify-content-center"
                                    onClick={() => dropdownHandler(index)}
                                  >
                                    <DropDownIcon
                                      className={`cursor p-0 ${item?.collapseFlag ? "rotate-180" : ""
                                        }`}
                                    />
                                  </div>
                                ) : null}
                              </span>
                            )}
                          </span>
                        </div>
                      </td>
                    </div>
                    {item?.collapseFlag && (
                      <div className="d-flex bg-white shadow rounded-3 ">
                        <table
                          className="table table-borderless"
                          responsive="xl"
                        >
                          <tbody>
                            <tr className="">
                              <td className="col-9">
                                <span className="font-14 font-bolder ">
                                  {t("Variants List")}
                                </span>
                              </td>
                              <td className="col-3">
                                <span className="font-14 font-bolder ">
                                  {t("Price")}
                                </span>
                              </td>
                            </tr>
                            {item?.product_class === "merchandise_variant"
                              ? item?.merchandise_variant &&
                              item?.merchandise_variant.map((vari, i) => (
                                <tr key={i}>
                                  <td className="col-9">
                                    <div className="d-flex align-items-center gap-2">
                                      <img
                                        src={
                                          vari?.images && vari?.images
                                            ? vari?.images[0]?.file_url
                                            : Default_product
                                        }
                                        alt="not found"
                                        className="img-fluid"
                                        width="50px"
                                        height="50px"
                                      />
                                      <span>
                                        {vari?.variant?.variant_values.join(
                                          "|"
                                        )}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="col-3">
                                    <div className="">
                                      <span>{vari?.supplier_price}</span>
                                    </div>
                                  </td>
                                </tr>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </tr>
                ))}
          </tbody>
        </table>
        {!supplierMapMerchandise.length && (
          <div className="d-flex flex-row justify-content-center py-3">
            <span className="font-20 font-bold text-error">
              {t("Products Not Found")}
            </span>
          </div>
        )}

        <ModalComp
          size="lg"
          showModal={showMerchandiseModal}
          // handleClose={handleClose}
          component={
            <ModelForMerchandiseSummery
              handleClose={handleClose}
              merchandiseSummery={merchandiseSummery}
            />
          }
        />

        <div>
          <SmallToast data={id ? id : ""} message={"Copied"} />
        </div>
      </div>
    </InfiniteScroll>
  );
};

export default PartnerDetailMerchandiseTable;
