import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";
import { fetchClient } from "../Client/clientAction";
import { getOrganizationList } from "../Organizations/organizationAction";
import {
  FETCH_PARTNER_CATEGORY_REQUEST,
  FETCH_PARTNER_CATEGORY_SUCCESS,
  FETCH_PARTNER_CATEGORY_FAILURE,
  SET_CREATE_PARTNER_DATA,
  PUT_PARTNER_FAILURE,
  PUT_PARTNER_SUCCESS,
  PUT_PARTNER_REQUEST,
  FETCH_PARTNER_FAILURE,
  FETCH_PARTNER_SUCCESS,
  FETCH_PARTNER_REQUEST,
  POST_PARTNER_CATEGORY_REQUEST,
  POST_PARTNER_CATEGORY_SUCCESS,
  POST_PARTNER_CATEGORY_FAILURE,
  PATCH_PATRNER_CATEGORY_REQUEST,
  PATCH_PATRNER_CATEGORY_SUCCESS,
  CLEAR_PARTNER_CAT_LIST,
} from "../Partner/partnerType";
import { fetchSupplierList } from "../Supplier/supplierAction";

// SET CREATE PARTNER DATA INTO REDUX
export const setCreatePartnerData = (partnerData) => {
  return {
    type: SET_CREATE_PARTNER_DATA,
    payload: partnerData,
  };
};

export const fetchPartnerCategoryRequest = () => {
  return {
    type: FETCH_PARTNER_CATEGORY_REQUEST,
  };
};

export const fetchPartnerCategorySuccess = (partner) => {
  return {
    type: FETCH_PARTNER_CATEGORY_SUCCESS,
    payload: partner,
  };
};
export const fetchPartnerCategoryFailure = () => {
  return {
    type: FETCH_PARTNER_CATEGORY_FAILURE,
  };
};

export const fetchPartnerCategory = (payload) => {
  return (dispatch) => {
    dispatch(fetchPartnerCategoryRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/partner/partner_attributes/partner_category`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchPartnerCategorySuccess(response.data.result));
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          fetchPartnerCategoryFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// put Partner
export const putPartnerRequest = () => {
  return {
    type: PUT_PARTNER_REQUEST,
  };
};

export const putPartnerSuccess = (obj) => {
  return {
    type: PUT_PARTNER_SUCCESS,
    payload: obj,
  };
};

export const putPartnerFailure = (obj) => {
  return {
    type: PUT_PARTNER_FAILURE,
    payload: obj,
  };
};

export const putPartner = (obj, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(putPartnerRequest());
    // address
    const address = obj?.address.map((item) => item.value);
    let add = address.toString();

    let partnerObj = {
      product_type: obj.product_type
        ? obj.product_type?.name.toLowerCase()
        : null,
      redemption_type: obj?.redemptionMethod
        ? obj?.redemptionMethod?.value
        : null,
      version: 0,
      active: 0,
      birthday: "2022-04-25T16:58:47.084271",
      contact_no: obj?.phonenumber,
      created_at: "2022-04-25T16:58:47.084298",
      customer_id: "",
      default_currency_id: "5f85f36d6dfecacc68428a46",
      email: obj?.emailid,
      encrypted_password: obj?.password,
      first_name: "",
      gender: "",
      last_name: "",
      modified_by: "",
      partner_id: "",
      partner_category: obj?.partner.partner_category,
      partner_info_verification: "",
      partner_name: obj?.name,
      partner_notes: "",
      partner_priority: 0,
      partner_referred_by: "",
      partner_same_rate_priority: 0,
      partner_status: "",
      country_name: obj?.country.name,
      country_id: obj?.country?.id,
      state_name: obj?.state.name,
      state_id: obj?.state.id,
      city_name: obj?.city,
      city_id: "",
      address: add,
      zip_code: 0,
      company_name: obj?.companyname,
      tag_id: "",
      float_flag: obj?.enableFloatFlag,
      password_reset_required_flag: true,
      payment_hold_days: 0,
      payment_threshold_amount: 0,
      point_balance: "5f85f36d6dfecacc68428a46",
      show_empty_wallets_flag: true,
      show_zero_balance_giftcards_flag: true,
      sync_birthday_flag: true,
      sync_first_name_flag: true,
      sync_gender_flag: true,
      sync_last_name_flag: true,
      updated_at: "2022-04-25T16:58:47.084851",
      invitation_token: "",
      service_account_flag: true,
      invitation_created_at: "2022-04-25T16:58:47.084892",
      invitation_accepted_at: "2022-04-25T16:58:47.084907",
      reset_password_sent_at: "2022-04-25T16:58:47.084921",
      remember_created_at: "2022-04-25T16:58:47.084936",
      partner_group_id: "5f85f36d6dfecacc68428a46",
      currencies_id: obj.countryCurrency,
    };
    let config = {
      method: "post",
      url: `${baseUrl}/partner`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: partnerObj,
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(putPartnerSuccess(response.data.result));
          goToSuccessfull();
          dispatch(getOrganizationList());
          dispatch(fetchClient());
          dispatch(fetchSupplierList());
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          putPartnerFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const patchPartner = (obj, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(putPartnerRequest());
    // address
    const address = obj?.address.map((item) => item.value);
    let add = address.toString();
    let partnerObj = {
      //  "product_type":obj.product_type ? obj.product_type.toLowerCase() : null,
      redemption_type: obj?.redemptionMethod
        ? obj?.redemptionMethod?.value
        : null,
      active_flag: obj?.active_flag,
      contact_no: obj?.phonenumber,
      created_at: "2022-04-25T16:58:47.084298",
      email: obj?.emailid,
      encrypted_password: obj?.password,
      partner_name: obj?.name,
      // "country_name": obj?.country.name,
      // "country_id": obj?.country?.id,
      // "state_name": obj?.state.name,
      // "state_id": obj?.state.id,
      city_name: obj?.city,
      // "city_id": "",
      address: add,
      zip_code: 0,
      company_name: obj?.companyname,
      float_flag: obj?.enableFloatFlag,
      currencies_id: obj.countryCurrency,
    };
    let config = {
      method: "patch",
      url: `${baseUrl}/partner/${obj.id}`,

      headers: {
        Authorization: `Bearer ${getToken()}`,
        //   'Content-Type': 'application/json'
      },
      data: partnerObj,
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(putPartnerSuccess(response.data.result));
          goToSuccessfull();
          dispatch(fetchClient());
          dispatch(fetchSupplierList());
          dispatch(getOrganizationList());
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          putPartnerFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const fetchPartnerByCategoryRequest = () => {
  return {
    type: FETCH_PARTNER_REQUEST,
  };
};

export const fetchPartnerByCategorySuccess = (partner) => {
  return {
    type: FETCH_PARTNER_SUCCESS,
    payload: partner,
  };
};
export const fetchPartnerByCategoryFailure = () => {
  return {
    type: FETCH_PARTNER_FAILURE,
  };
};

export const fetchPartnerByCategory = (partnerType) => {
  return (dispatch) => {
    dispatch(fetchPartnerByCategoryRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/partner_category/${partnerType}/partner`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchPartnerByCategorySuccess(response.data.result));
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          fetchPartnerByCategoryFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

export const postPartnerCategoryRequest = () => {
  return {
    type: POST_PARTNER_CATEGORY_REQUEST,
  };
};

export const postPartnerCategorySuccess = (data) => {
  return {
    type: POST_PARTNER_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const postPartnerCategoryFailure = (error) => {
  return {
    type: POST_PARTNER_CATEGORY_FAILURE,
    payload: error,
  };
};

export const postPartnerCategory = (obj, partnerSuccessfull) => {
  return (dispatch) => {
    dispatch(postPartnerCategoryRequest());

    const payload = {
      active_flag: obj?.active_flag,
      partner_category: obj?.partner_category,
      partner_sub_category: obj?.partner_sub_category,
      partner_category_name: obj?.partner_category_name,
    };

    const config = {
      method: "post",
      url: `${baseUrl}/v1/partner/partner_attributes/partner_category`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(postPartnerCategorySuccess(response.data.result));
          partnerSuccessfull();
          dispatch(fetchPartnerCategory());
        }
      })
      .catch((error) => {
        dispatch(
          postPartnerCategoryFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// Patch Partner Category
export const PatchPartnerCategoryRequest = () => {
  return {
    type: PATCH_PATRNER_CATEGORY_REQUEST,
  };
};

export const PatchPartnerCategorySuccess = (data) => {
  return {
    type: PATCH_PATRNER_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const PatchPartnerCategoryFailure = (error) => {
  return {
    type: PATCH_PATRNER_CATEGORY_SUCCESS,
    payload: error,
  };
};

export const patchPartnerCategory = (obj, partnerSuccessfull) => {
  return (dispatch) => {
    dispatch(PatchPartnerCategoryRequest());

    const payload = {
      active_flag: obj?.active_flag,
      partner_category: obj?.partner_category_name,
      partner_sub_category: obj?.partner_sub_category,
      partner_category_name: obj?.partner_category_name,
    };

    const config = {
      method: "patch",
      url: `${baseUrl}/v1/partner/partner_attributes/partner_category/${obj?.partner_category}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(PatchPartnerCategorySuccess(response.data.result));
          partnerSuccessfull();
          dispatch(fetchPartnerCategory());
        }
      })
      .catch((error) => {
        dispatch(
          PatchPartnerCategoryFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

export const clearPartnerCategoryList = () => {
  return {
    type: CLEAR_PARTNER_CAT_LIST,
  };
};