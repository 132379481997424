/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import { getAllTransactionCritaria } from "../../Redux/TransactionCritaria/transactionAction";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import { fetchClientFloatOnyShow } from "../../Redux/Client/clientAction";
import {
  getPratnerFloats,
  postFloatCurrencyClient,
} from "../../Redux/Float/floatAction";
import { TRANSACTION_CRITARIA_STATUS } from "../../utils/ConstantData";
import Toasts from "../../components/Toasts/Toasts";
import { useTranslation } from "react-i18next";

const CurrencyToClient = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const partner = useSelector(
    (state) => state?.partners?.createPartnerResponese
  );

  const countries = useSelector((state) => state?.countries?.countries);
  const clientsState = useSelector((state) => state?.clients?.client);
  const floatCurrency = useSelector((state) => state?.partnerFloat?.floats);

  let variantsObj = {
    currency: null,
    transactionCritaria: null,
  };

  const [fields, setFields] = useState({
    client: partner ? partner : "",
  });

  const [error, setError] = useState({
    clientErorr: "",
    currencyError: "",
    transactionCritariaError: "",
  });

  const [mapCurrencyClientToClient, setMapCurrencyClientToClient] = useState([
    variantsObj,
  ]);

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const [clientCurrencies, setClientCurrencies] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchClientFloatOnyShow());
    dispatch(getAllCountriesList());
    dispatch(getAllTransactionCritaria());
  }, []);

  useEffect(() => {
    if (fields?.client?.id) {
      dispatch(getPratnerFloats(fields?.client?.id));
    }
  }, [fields?.client])

  useEffect(() => {
    let arr = [];
    let allCurrencyArr = [];

    if (fields?.client) {
      countries &&
        fields?.client?.currencies_id.map((id) => {
          countries.map((item) => {
            if (item?.id === id) {
              let variantsObj = {
                currency: item,
                transactionCritaria: null,
              };
              arr.push(variantsObj);
              allCurrencyArr.push(item);
            }
          });
        });

      setMapCurrencyClientToClient(arr);
      setClientCurrencies(allCurrencyArr);
    }
  }, [countries, fields?.client]);



  const handleChangeClient = (e) => {
    setFields({ ...fields, client: e });
    setMapCurrencyClientToClient([variantsObj]);
    if (e?.float_flag) {
      dispatch(getPratnerFloats(e?.id));
    }
  };

  const handleAddClick = (e) => {
    setMapCurrencyClientToClient([
      ...mapCurrencyClientToClient,
      { currency: null, transactionCritaria: null },
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...mapCurrencyClientToClient];
    list.splice(index, 1);
    setMapCurrencyClientToClient(list);
  };

  const handleChangeCurrency = (e, { name }) => {
    let id = name;
    let arr = [...mapCurrencyClientToClient];

    arr && arr.map((item, i) => {
      if (i === id) {
        if (
          floatCurrency &&
          floatCurrency.some((c) => c.currency === e.currency)
        ) {
          toggleShowToast();
          return false;
        } else {
          arr[i].currency = e;
        }
      }
    });
    setMapCurrencyClientToClient(arr);
  };

  const handleChangeTransactionCritaria = (e, { name }) => {
    let id = name;
    let arr = [...mapCurrencyClientToClient];
    arr && arr.map((item, i) => {
      if (i === id) {
        arr[i].transactionCritaria = e;
      }
    });
    setMapCurrencyClientToClient(arr);
  };

  const validate = () => {
    if (!fields.client) {
      setError({ clientErorr: t("Please select client") });
      return false;
    } else if (mapCurrencyClientToClient.length > 0) {
      //  let valSKU = checkArr && checkArr.every(v => v.supplierSKU);
      let valCurrency =
        mapCurrencyClientToClient &&
        mapCurrencyClientToClient.every((v) => v.currency);
      let valTransactionCritaria =
        mapCurrencyClientToClient &&
        mapCurrencyClientToClient.every((v) => v.transactionCritaria);

      valCurrency && valTransactionCritaria
        ? setError({
          clientErorr: "",
          currencyError: "",
          transactionCritariaError: "",
        })
        : setError({
          clientErorr: "",
          currencyError: t("Please select currency"),
          transactionCritariaError: t("Please select transaction critaria"),
        });

      return valCurrency && valTransactionCritaria ? true : false;
    }
    return true;
  };

  const getAPIResponse = (booleanValue) => {
    if (booleanValue === true) {
      toggleShowToast()
    }
  }

  const submit = () => {
    let val = validate();

    if (val) {
      let obj = {
        selectedClient: fields.client,
        currencyObj: mapCurrencyClientToClient,
      };
      dispatch(postFloatCurrencyClient(obj, goToSuccessfull, getAPIResponse));
    }
  };


  const goToSuccessfull = () => {
    history("/float/addfloat-details");
  };

  return (
    <div className="d-flex flex-column justify-content-start gap-3 px-4 bg-light py-4">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-35 font-bold mb-2 py-2">
            {t("Map Float Currency")}
          </span>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center gap-3">
          <Button
            type="square"
            text={t("Do it later")}
            onClick={() => history("/float/addfloat-details")}
          />
          <Button
            type="primary"
            text={t("Allocate float")}
            onClick={() => submit()}
          />
        </div>
      </div>

      <div className="d-flex width-65 gap-1 flex-column ">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-4 px-4 gap-3 w-100">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-20 font-bold">{t("Client details")}</span>
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Client")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>

              <div>
                <Select
                  placeholder={t("Select client")}
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(clientsState) => clientsState.partner_name}
                  options={clientsState}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.client}
                  onChange={handleChangeClient}
                  isClearable
                />
                {!fields.client && error.clientErorr ? (
                  <label className="text-error">{error.clientErorr}</label>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {mapCurrencyClientToClient &&
        mapCurrencyClientToClient.map((item, index) => (
          <div className="d-flex width-65 gap-1 flex-column" key={index}>
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-4 px-4  w-100 ">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex gap-3">
                  <Tag />
                  <span className="font-20 font-bold">
                    {t("Currency & Criteria")} #{index + 1}
                  </span>
                </div>
                <div>
                  <div className="d-flex flex-row  gap-3">
                    {index !== 0 ? (
                      <Button
                        type="square"
                        text="Delete"
                        className="rounded-6 my-4 mt-6 font-bolder"
                        onClick={() => handleRemoveClick(index)}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center py-3 mt-3 gap-2">
                <span className="font-bold font-14">{t("Currency")}</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className=" ">
                <Select
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select country & currency")}
                  getOptionLabel={(clientCurrencies) =>
                    clientCurrencies.name + " -" + clientCurrencies.currency
                  }
                  options={clientCurrencies}
                  //   isSearchable
                  value={item?.currency}
                  onChange={handleChangeCurrency}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                  name={index}
                />
                {!item?.currency && error?.currencyError ? (
                  <label className="text-error">{error?.currencyError}</label>
                ) : null}
              </div>
              <div className="d-flex flex-column py-4 gap-3">
                {item?.currency?.id ? (
                  <span className="text-muted font-bolder font-13">
                    {t("Currency ID")}: {item?.currency?.id}
                  </span>
                ) : null}
              </div>

              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Order Transaction Criteria")}
                  </span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <div className=" ">
                  <Select
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select country & currency")}
                    getOptionLabel={(transactionCritaria) =>
                      transactionCritaria?.name
                    }
                    options={TRANSACTION_CRITARIA_STATUS}
                    //   isSearchable
                    value={item?.transactionCritaria}
                    onChange={handleChangeTransactionCritaria}
                    isClearable
                    styles={CUSTOM_DROPDOWN_STYLE}
                    name={index}
                    isDisabled={!item?.currency}
                  />
                  {!item?.transactionCritaria &&
                    error?.transactionCritariaError ? (
                    <label className="text-error">
                      {error?.transactionCritariaError}
                    </label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className="d-flex flex-row justify-content-start gap-4 width-65">
        <Button
          onClick={() => handleAddClick()}
          text={t("+ Add new option")}
          type="square"
        />
        <Button type="primary" text={t("Allocate float")} onClick={() => submit()} />
      </div>
      <div
        className="position-fixed bottom-0 start-50 mb-4 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Already allocated currency."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default CurrencyToClient;
