/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import OrganizationInfo from "../../components/Organization/OrganizationInfo";
import StoreList from "../../components/Organization/StoreList";
// import AllTransaction from "../../components/Supplier/AllTransaction";
// import MappedGiftCards from "../../components/Supplier/MappedGiftCards";
import OrganizationTransaction from "../../components/Organization/OrganizationTransaction";
//  import { getStoreListMapToOrganization } from "../../Redux/CreateStore/createStoreAction";
import OrganizationMappedGiftcard from "../../components/Organization/OrganizationMappedGiftcard";
import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";

const OrganizationDashboardDetails = () => {
  const history = useNavigate();

  const [tabs, setTabs] = useState("stores");

  const [permissions, setpermissions] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState("")

  const partner_object = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (partner_object?.type_id) {
      let config = {
        method: "get",
        url: `${baseUrl}/partner/${partner_object?.type_id}`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      };
      axios(config)
        .then((response) => {
          setSelectedOrganization(response?.data?.result)
        })
        .catch((error) => {
          // const errorMsg = error;
        });
    }

  }, [])

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {

  }, [permissions]);

  const handleChangeTab = (tab) => {
    if (tab === "orders") {
      setTabs(tab);
    } else if (tab === "stores") {
      setTabs(tab);
    } else if (tab === "allProducts") {
      setTabs(tab);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getStoreListMapToOrganization(location.state.selectedOrganization.id));
  }, []);
  // go to store creation
  const creatNewstore = () => {
    history("/store-creation");
  };

  return (
    <div className="d-flex flex-column justify-content-between gap-3 py-3 px-4 bg-light">
      {permissions?.all_organization?.create ? (
        <div className="d-flex flex-row justify-content-end align-items-center w-100">
          <div>
            <Button
              type="primary"
              text="+Create Store"
              onClick={() => creatNewstore()}
            />
          </div>
        </div>
      ) : null}

      <OrganizationInfo
        selectedOrganization={selectedOrganization}
      />

      <div className="d-flex flex-row w-100  align-items-center">
        <div className="d-flex flex-grow-1">
          <div className="d-flex flex-start gap-3 mb-1 align-items-center">
            {/* <div className="">
              <Button
                type={tabs==="allTransactions" ? "primary" : "rounded"}
                text="All transactions"
                className="rounded-5 my-4 mt-6 fw-bolder"
                onClick={() => setTabs("allTransactions")}
              />
            </div> */}

            <div className="">
              <Button
                type={tabs === "stores" ? "primary" : "rounded"}
                text="Stores"
                className="rounded-6 my-4 mt-6 fw-bold"
                onClick={() => handleChangeTab("stores")}
              />
            </div>
            <div className="">
              <Button
                type={tabs === "orders" ? "primary" : "rounded"}
                text="Orders"
                className="rounded-6 my-4 mt-6 fw-bold"
                onClick={() => handleChangeTab("orders")}
              />
            </div>
            <div className="">
              <Button
                type={tabs === "allProducts" ? "primary" : "rounded"}
                text="All products"
                className="rounded-6 my-4 mt-6 fw-bold"
                onClick={() => handleChangeTab("allProducts")}
              />
            </div>
          </div>
        </div>
      </div>
      {/*  <MappedGiftCards />*/}
      {/* {tabs === "stores" ? (
        <StoreList selectedOrganization={location.state.selectedOrganization}/>
       
      )  : (
      
      <OrganizationTransaction selectedClient={location.state.selectedOrganization}/>
      )}*/}

      {tabs === "stores" ? (
        <StoreList selectedOrganization={selectedOrganization} />
      ) : tabs === "orders" ? (
        <OrganizationTransaction
          selectedClient={selectedOrganization}
        />
      ) : tabs === "allProducts" ? (
        <OrganizationMappedGiftcard
          selectedOrganization={selectedOrganization}
        />
      ) : null}
    </div>
  );
};

export default OrganizationDashboardDetails;
