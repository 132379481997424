import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../../assests/svg/Tag/tag.svg";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ReactSwitchButton from "../../../components/Button/ReactSwitchButton";
import { getAllLanguage } from "../../../Redux/Language/languageAction";
import { ReactComponent as CloseIcone } from "../../../assests/svg/close.svg";
import Toasts from "../../../components/Toasts/Toasts";
import { patchPostOrganization } from "../../../Redux/Organizations/organizationAction";
import PulseLoader from "../../../components/Loader/PulseLoader";

const EditOrganization = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);
  const organizationItem = location.state.organizationsItem;

  const loader = useSelector((state) => state?.organizations?.loading);
  const Preferredlanguage = useSelector((state) => state?.languages?.languages);
  const [prodTitles, setProdTitles] = useState([]);
  const [activeFlag, setActiveFlag] = useState(true);

  const [error, setError] = useState({
    organization_title: "",
    brandType: "",
  });

  const setEditObject = () => {
    let arr = [];
    const res = Object.entries(organizationItem?.organization_title).map(
      (e) => ({ [e[0]]: e[1] })
    );

    res &&
      res.map((item) => {
        let name = Object.values(item).join("");
        let langCode = Object.keys(item).join("");

        Preferredlanguage &&
          Preferredlanguage.map((item) => {
            if (item.language_code === langCode) {
              let obj = {
                organization_title: name,
                language_id: item,
              };
              arr.push(obj);
            }
          });
        setProdTitles(arr);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllLanguage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEditObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationItem]);

  useEffect(() => {

  }, [prodTitles]);

  // Remove Selected Language title input field
  const handleRemoveClickProdTitle = (index) => {
    const list = [...prodTitles];
    list.splice(index, 1);
    setProdTitles(list);
  };

  // Getting Brand Category title value from input field
  const handleInputChangeProdTitle = (e) => {
    const { name, value } = e.target;
    let arr = [...prodTitles];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (name === item.language_id.language_code) {
        arr[i].organization_title = value;
      }
    });
    setProdTitles(arr);
  };

  // Brand category title dropdown
  const handleAddClickProdTitle = (e) => {
    if (
      prodTitles.some(
        (item) => item.language_id.language_code === e.language_code
      )
    ) {
      toggleShowToast();
      return;
    } else {
      setProdTitles([
        ...prodTitles,
        { organization_title: "", language_id: e },
      ]);
    }
  };

  const validate = () => {
    if (!prodTitles[0].organization_title) {
      setError({ organization_title: "Please enter organization" });
      return false;
    }

    return true;
  };

  const activeHandle = () => {
    setActiveFlag(!activeFlag);
  };

  const submit = () => {
    let obj = {
      id: organizationItem.id,
      organization: prodTitles,
      activateFlag: activeFlag,
    };

    let val = validate();
    if (val) {
      dispatch(patchPostOrganization(obj, goToSuccessfull));
    }
  };

  const goToSuccessfull = () => {
    history("/organization-create-success");
  };

  return (
    <>
      {loader === false ? (
        <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row align-items-center pb-2 gap-3">
              <LeftIcon onClick={() => history(-1)} className="cursor" />
              <span className="font-bold font-32 my-2">Edit Organization</span>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between gap-2">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex gap-3">
                  <Tag />
                  <span className="font-20 font-bold">Organization</span>
                </div>
              </div>

              <div className="d-flex flex-column gap-4 mt-4 ">
                <div className="d-flex flex-column gap-3">
                  {prodTitles && prodTitles.map((item, index) => (
                    <div className="d-flex flex-column gap-3" key={index}>
                      <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                        <div className="d-flex flex-row align-items-center gap-2">
                          <span className="font-bold font-14">
                            Organization name #{index + 1}{" "}
                            {item.language_id.language}
                            <span className="text-danger">*</span>
                          </span>
                          <InfoTooltip
                            title={
                              "Maximum 100 characters. No HTML or emoji allowed"
                            }
                          />
                        </div>
                        {prodTitles.length !== 1 && (
                          <div
                            className="cursor"
                            onClick={() => handleRemoveClickProdTitle(index)}
                          >
                            {index !== 0 && <CloseIcone />}
                          </div>
                        )}
                      </div>

                      <div className="d-flex flex-column gap-1">
                        <InputField
                          onChange={handleInputChangeProdTitle}
                          name={item.language_id.language_code}
                          placeholder={`Organization name ${item.language_id.language.toLocaleLowerCase()}`}
                          type="text"
                          value={item.organization_title}
                        />
                        {!item.organization_title &&
                          error.organization_title ? (
                          <label className="text-error">
                            {error.organization_title}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="d-flex ">
                  <div className="btn-group w-100">
                    <div
                      className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                      data-bs-toggle="dropdown"
                    >
                      <span>Add other language for organization title</span>
                    </div>
                    <ul className="dropdown-menu rounded-card-7 dropdown-menu-start">
                      {Preferredlanguage &&
                        Preferredlanguage.map((item, i) => (
                          <li
                            key={i}
                            onClick={() => handleAddClickProdTitle(item)}
                          >
                            <div className="dropdown-item cursor">
                              {item.language}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">Active?</span>
                    <InfoTooltip title={"Active/Inactive"} />
                  </div>
                  <ReactSwitchButton
                    onChange={() => activeHandle()}
                    value={activeFlag}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <Button onClick={() => submit()} text="Create" type="primary" />
          </div>
          <div
            className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg="Allready Added."
              imageType={"warning"}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default EditOrganization;
