import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as DownArrow } from "../../assests/svg/arrow-down-circle.svg";
import Button from "../Button/Button";
import Toasts from "../Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import { getClientAllFloatTrans } from "../../Redux/Float/floatAction";
import {
  DataSortingHandler,
  DateTimeFormatter,
  downloadTransactionReciept,
} from "../../utils/constantFunction";
import PulseLoader from "../Loader/PulseLoader";
import { convertNumberInUSAFormat } from "../../utils/constantFunction";
import PdfIcon from "../../assests/img/pdf-icon-download.jpg";
import InfoTooltip from "../Tooltip/InfoTooltip";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SORT_TYPE } from "../../utils/ConstantData";

const ClientFloatTransaction = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedClient } = props;
  const location = useLocation();
  const allClientFloat = useSelector(
    (state) => state?.partnerFloat?.clientAllFloatTransactions
  );
  const allClientFloatLoading = useSelector(
    (state) => state?.partnerFloat?.loading
  );

  const [showToast, setShowToast] = useState(false);
  const [visible, setVisible] = useState(10);
  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 10);
  };

  const toggleShowToast = () => setShowToast(!showToast);

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {}, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (selectedClient?.float_flag && selectedClient?.id) {
      dispatch(getClientAllFloatTrans(selectedClient?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient?.id]);

  return (
    <>
      {allClientFloatLoading === false ? (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
          <div className="d-flex flex-column  bg-white">
            <div className="d-flex flex-row w-100  align-items-center">
              <div className="p-2 flex-grow-1">
                <div className="d-flex gap-3 my-2 mx-3 align-items-center">
                  <Tag />
                  <span className="font-20 font-bold">
                    {t("Float Transactions")}
                  </span>
                </div>
              </div>

              {/* <div className=" d-flex gap-2">
                <div className="btn-group cursor ">
                  <div
                    className="btn border border-2 btn-lg  dropdown-toggle font-14 font-bold text-muted"
                    data-bs-toggle="dropdown"
                  >
                    Last 30 days
                  </div>
                  <ul className="dropdown-menu rounded-card-7 dropdown-menu-end">
                    <li>
                      <div className="dropdown-item">Last 10 days</div>
                    </li>
                    <li>
                      <div className="dropdown-item">Last 15 days</div>
                    </li>
                    <li>
                      <div className="dropdown-item">Last 30 days</div>
                    </li>
                  </ul>
                </div>
              </div>*/}
              <div className="px-2">
                {/*  <Button
                  type="primary"
                  text="Download CSV"
                  className="rounded-6 my- mt-6 fw-bolder"
                  onClick={() => toggleShowToast()}
                />*/}
              </div>
            </div>
            <div className="row px-5 py-3">
              <table responsive="xl">
                <tbody>
                  <tr className="card-body">
                    <td
                      className={`card-body gap-1 font-13 font-bold text-muted`}
                    >
                      {t("Client Name & ID")}
                    </td>
                    <td
                      className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                        tableHeaderName === "created_at"
                          ? "ascending"
                          : "descending"
                      }`}
                      onClick={() =>
                        sortingHandler(
                          "created_at",
                          setTableHeaderName,
                          allClientFloat,
                          order,
                          setOrder
                        )
                      }
                    >
                      {t("Transaction date")}
                    </td>
                    <td className={`card-body gap-1 font-13 font-bold text-muted cursor`}>
                      {t("Transaction Details")}
                    </td>
                    {/* <td className="card-body gap-1 font-13 font-bold text-muted">
                      Currency
                    </td> */}
                    <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                        tableHeaderName === "amount"
                          ? "ascending"
                          : "descending"
                      }`}
                      onClick={() =>
                        sortingHandler(
                          "amount ",
                          setTableHeaderName,
                          allClientFloat,
                          order,
                          setOrder
                        )
                      }
                    >
                      {t("Amount")}{" "}
                      <InfoTooltip title="cr stands for credit and dr stands for debit" />
                    </td>
                    <td className="card-body gap-1 font-13 font-bold text-muted">
                      {t("Download receipt")}
                    </td>
                  </tr>

                  {allClientFloat &&
                    allClientFloat.slice(0, visible).map((item) => (
                      <tr className="card-body  border-bottom" key={item.id}>
                        <td className="card-body gap-1">
                          <span
                            className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                            title={
                              location?.state?.selectedClient?.partner_name
                            }
                            style={{ maxWidth: "150px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {location?.state?.selectedClient?.partner_name}
                          </span>
                          <br />
                          <span
                            className="font-13 font-bold text-muted d-inline-block text-truncate cursor"
                            title={location?.state?.selectedClient?.id}
                            style={{ maxWidth: "100px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {location?.state?.selectedClient?.id}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          <span className="font-15 font-bold text-dark ">
                            {DateTimeFormatter(item?.created_at)}
                          </span>
                        </td>
                        <td className=" gap-1">
                          {item?.transaction_type === "credit" ? (
                            <div className=" align-item-cener mt-4">
                              <span className="font-12 font-boldest text-dark ">
                                {t("Payment received on")}
                                <br />
                                <span
                                  className="font-12 font-bolder text-muted
                            d-inline-block text-truncate cursor"
                                  title={DateTimeFormatter(
                                    item?.payment_recieved_on
                                  )}
                                  style={{ maxWidth: "150px" }}
                                >
                                  {DateTimeFormatter(item?.payment_recieved_on)}
                                </span>
                              </span>
                              <br />
                              <span
                                className="font-12 font-bolder text-muted
                          d-inline-block text-truncate cursor"
                                title={item?.transactor_id}
                                style={{ maxWidth: "150px" }}
                              >
                                {t("Transactor ID")}: {item?.transactor_id}
                              </span>
                            </div>
                          ) : (
                            <span
                              className="font-12 font-bolder text-muted
                        d-inline-block text-truncate cursor"
                              title={item?.id}
                              style={{ maxWidth: "150px" }}
                            >
                              {t("Transaction ID")}: {item?.id}
                            </span>
                          )}
                          <br />
                          <span></span>
                        </td>
                        {/* <td className="card-body gap-1">
                          <span className="font-15 font-bold text-dark mx-3">
                            -
                          </span>
                        </td> */}
                        <td className="card-body gap-1">
                          <span className="font-15 font-bold text-dark text-light-orange">
                            {item?.transaction_type === "credit" ? (
                              <div className="d-flex flex-column">
                                <span className="font-15 font-bold text-dark ">
                                  {convertNumberInUSAFormat(item?.amount)}
                                  cr
                                </span>
                                <span
                                  className="font-12 font-bolder text-muted
                              d-inline-block text-truncate cursor"
                                  title={item?.id}
                                  style={{ maxWidth: "130px" }}
                                >
                                  {t("Transaction id")}:{item?.id}
                                </span>
                              </div>
                            ) : (
                              <span>
                                {convertNumberInUSAFormat(item?.amount)}dr
                              </span>
                            )}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          {item?.transaction_receipt ? (
                            <span className="d-flex flex-row justify-content-center">
                              {/*  <DownArrow className="cursor" />*/}
                              <img
                                src={PdfIcon}
                                alt="not found"
                                className="img-fluid cursor"
                                style={{ width: "30px", height: "30px" }}
                                onClick={() => downloadTransactionReciept(item)}
                              />
                            </span>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {!allClientFloat.length && (
                <div className="d-flex flex-row justify-content-center py-4">
                  <span className="font-20 font-bold text-error">
                    {t("Transactions Not Found")}
                  </span>
                </div>
              )}
            </div>

            {/* <div className="d-flex flex-row  justify-content-center align-item-center gap-3">
              <div className="d-flex">
                {allClientFloat && visible < allClientFloat.length && (
                  <Button
                    type="rounded"
                    text="Load more"
                    className="rounded-6 my-4 mt-6 "
                    onClick={() => loadMore()}
                  />
                )}
              </div>
            </div> */}
          </div>
          <div
            className="position-fixed bottom-0 start-50 translate-middle-x w-100 mb-2"
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg="Your file is being downloaded"
              imageType={"success"}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default ClientFloatTransaction;
