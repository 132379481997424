import React from "react";
import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg"
import { ReactComponent as CalanderIconBg } from "../../assests/svg/calender.svg"

const Datepicker = (props) => {
    const { maxDate, minDate, onChange, selected, disabled, format, locale, type, name, className, lang } = props;

    const handleChange = (e) => {
        onChange(e, name);
    }

    return (
        <>
            {type === "dropdown" ?
                < ReactDatePicker
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={(selected) => handleChange(selected)}
                    selected={selected}
                    format={format}
                    locale={locale}
                    disabled={disabled}
                    calendarIcon={<DropDownIcon />}
                    clearIcon={null}
                    className={className}
                    dateFormat='dd/MM/yyyy'
                /> :
                <div className="d-flex bord" >
                    <div className="date-picker-lable-right " >
                        <CalanderIconBg className={lang === "ar" ? "d-flex border-radius-right-ar" : "d-flex border-radius-right"} />
                    </div>
                    <div className={lang === "ar" ? "date-picker-main border border-radius-left-ar" : "date-picker-main border border-radius-left"}>
                        <ReactDatePicker
                            maxDate={maxDate}
                            minDate={minDate}
                            onChange={(selected) => handleChange(selected)}
                            selected={selected}
                            format={format}
                            locale={locale}
                            disabled={disabled}
                            calendarIcon={null}
                            clearIcon={null}
                            className={className}
                            dateFormat='dd/MM/yyyy'
                        />
                    </div>
                </div >}
        </>
    );
};

export default Datepicker;
