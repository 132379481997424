import {GET_ACTIVATION_REQUIRED_REQUEST,
    GET_ACTIVATION_REQUIRED_SUCCESS,
    GET_ACTIVATION_REQUIRED_FAILURE} from './activationType'

    const initialActivationState={
        loading: false,
        activations: [],
        error: ''
    }

const activationReducer=(state = initialActivationState, action)=>{
    switch (action.type){
        case GET_ACTIVATION_REQUIRED_REQUEST:
            return{
                ...state,
                loading:true
            }
            case GET_ACTIVATION_REQUIRED_SUCCESS:
                return{
                    ...state,
                    loading: false,
                    activations: action.payload,
                    error: ''
                }
                case GET_ACTIVATION_REQUIRED_FAILURE:
                    return{
                        ...state,
                        loading: false,
                        activations: [],
                        error: action.payload
                    }
                    default: return state
    }
}    
export default activationReducer