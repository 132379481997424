/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Info from "../../../components/EPOS/Info";
import Button from "../../../components/Button/Button";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import Select from "react-select";
import { getStoreListMapToOrganization } from "../../../Redux/CreateStore/createStoreAction";
import { useDispatch, useSelector } from "react-redux";
import { getAllRedemptionMethod } from "../../../Redux/Redemption/redemptionAction";
import { getClientDetailById } from "../../../Redux/Client/clientAction";
import {
  getOrganizationList,
  getOrganizationMappedGiftcardDetailsList,
} from "../../../Redux/Organizations/organizationAction";
import { getDenomination } from "../../../Redux/Denominations/denominationAction";
import { setSellCardData } from "../../../Redux/Epos/eposAction";
import { SELL_GIFTCARD_REDEMPTION_METHOD } from "../../../utils/ConstantData";
import PulseLoader from "../../../components/Loader/PulseLoader";
import { useTranslation } from "react-i18next";

const SellCard = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputElementDeliType = useRef(null);
  const inputElementOrg = useRef(null);
  const inputElementStore = useRef(null);
  const inputElementGiftcard = useRef(null);
  const inputElementDenomi = useRef(null);

  const organizationState = useSelector(
    (state) => state?.organizations?.organizationList
  );

  const loading = useSelector((state) => state?.organizations?.loading);

  const storeListByOrgnazation = useSelector(
    (state) => state.createStore?.storeListByOrgnazationId
  );

  const organizationmappedGiftcardList = useSelector(
    (state) => state?.organizations?.organizationMappedProdList
  );
  const denominationState = useSelector(
    (state) => state?.denominations?.denomination
  );

  const [fields, setFields] = useState({
    organization: "",
    store: "",
    delivaryMode: "",
    product: "",
    denomination: "",
  });

  const [onlyManaulInventoryGiftcard, setOnlyManaulInventoryGiftcard] =
    useState([]);

  const [usertype, setusertype] = useState();

  const [error, setError] = useState({
    store: "",
    delivaryMode: "",
    product: "",
    denomination: "",
  });

  useEffect(() => {
    setusertype(JSON.parse(localStorage.getItem("user")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      usertype?.user_type === "organization" ||
      usertype?.user_type === "organization_store"
    ) {
      // usertype?.type_id && dispatch(getClientDetailById(usertype?.type_id));
      usertype?.type_id &&
        dispatch(getStoreListMapToOrganization(usertype?.type_id));
    } else {
      dispatch(getOrganizationList());
    }
  }, [usertype]);

  useEffect(() => {
    fields?.organization?.id &&
      dispatch(
        getOrganizationMappedGiftcardDetailsList(fields?.organization?.id)
      );
  }, [fields?.organization?.id]);

  useEffect(() => {
    if (
      usertype?.user_type === "organization_store" ||
      usertype?.user_type === "organization"
    ) {
      organizationState &&
        organizationState.map((item) => {
          if (usertype?.type_id === item?.id) {
            setFields({ ...fields, organization: item });
          }
        });
    }
  }, [organizationState]);

  useEffect(() => {
    if (usertype?.user_type === "organization_store") {
      storeListByOrgnazation &&
        storeListByOrgnazation.map((item) => {
          if (usertype?.sub_type_id === item?.id) {
            setFields({ ...fields, store: item });
          }
        });
    }
  }, [storeListByOrgnazation]);

  useEffect(() => {
    dispatch(getAllRedemptionMethod());
  }, []);

  // filter product according to manual inventory giftcard type
  useEffect(() => {
    let arr = [];
    organizationmappedGiftcardList?.map((item) => {
      if (item?.giftcard?.registered_by_id === "manual_inventory") {
        arr.push(item);
      }
    });
    setOnlyManaulInventoryGiftcard(arr);
  }, [organizationmappedGiftcardList]);

  const handleChangeOrganization = (e) => {
    setFields({ ...fields, organization: e, store: "" });
    e && dispatch(getStoreListMapToOrganization(e?.id));
  };

  const handleChangeStore = (e) => {
    setFields({ ...fields, store: e });
  };

  const handleChangeProduct = (e) => {
    setFields({ ...fields, product: e });
    dispatch(getDenomination(e?.giftcard?.id));
  };

  const handleChangeDelivaryMode = (e) => {
    setFields({ ...fields, delivaryMode: e });
  };

  const handleDenomination = (e) => {
    setFields({ ...fields, denomination: e });
  };

  const validate = () => {

    if (!fields.delivaryMode) {
      setError({ delivaryMode: t("Please select delivary mode") });
      if(inputElementDeliType.current){
        inputElementDeliType.current.focus()
      }
      return false;
    } else if (!fields.organization) {
      setError({ organization: t("Please select organization") });
      if(inputElementOrg.current){
        inputElementOrg.current.focus()
      }
      return false;
    } else if (!fields.store) {
      setError({ store: t("Please select store") });
      if(inputElementStore.current){
        inputElementStore.current.focus()
      }
      return false;
    } else if (!fields.product) {
      setError({ product: t("Please select product") });
      if(inputElementGiftcard.current){
        inputElementGiftcard.current.focus()
      }
      return false;
    } else if (!fields.denomination) {
      setError({ denomination: t("Please enter denomination") });
      if(inputElementDenomi.current){
        inputElementDenomi.current.focus()
      }
      return false;
    }

    return true;
  };

  const Submit = () => {
    let val = validate();
    if (val) {
      const boj = {
        selectedOrganization: fields.organization,
        selectedStore: fields.store,
        selectedProduct: fields.product,
        selectedDenomination: fields.denomination,
        selectedDeliveryType: fields.delivaryMode,
      };
      dispatch(setSellCardData(boj));
      history("/epos/buyer-seller-info");
    }
  };

  const goToHome = () => {
    history("/epos/ehadaya-interface");
  };

  return loading === true && organizationState?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goToHome()} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Sell Card")}</span>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between gap-4">
        <div className="d-flex flex-row justify-content-between gap-2">
          <div className="d-flex width-65">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 gap-3 w-100">
              <div className="d-flex flex-column justify-content-between gap-2">
                <div className="d-flex flex-column gap-4  ">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Delivary mode")}<span className="text-danger">*</span>
                      </span>
                      <InfoTooltip
                        title={
                          "Maximum 100 characters. No HTML or emoji allowed"
                        }
                      />
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <Select
                      ref={inputElementDeliType}
                        noOptionsMessage={() => "Not found"}
                        placeholder={t("Select delivary mode")}
                        getOptionLabel={(SELL_GIFTCARD_REDEMPTION_METHOD) =>
                          SELL_GIFTCARD_REDEMPTION_METHOD.name
                        }
                        options={SELL_GIFTCARD_REDEMPTION_METHOD}
                        styles={CUSTOM_DROPDOWN_STYLE}
                        value={fields.delivaryMode}
                        onChange={handleChangeDelivaryMode}
                        isClearable
                      />
                      {!fields.delivaryMode && error.delivaryMode ? (
                        <label className="text-error">
                          {error.delivaryMode}
                        </label>
                      ) : null}
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Organization")}
                        <span className="text-danger">*</span>
                      </span>
                      <InfoTooltip
                        title={
                          "Maximum 100 characters. No HTML or emoji allowed"
                        }
                      />
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <Select
                      ref={inputElementOrg}
                        noOptionsMessage={() => "Not found"}
                        placeholder={t("Select organization")}
                        getOptionLabel={(e) => e.partner_name}
                        options={organizationState}
                        styles={CUSTOM_DROPDOWN_STYLE}
                        value={fields.organization}
                        onChange={handleChangeOrganization}
                        isClearable
                        isDisabled={
                          usertype?.user_type === "organization_store" ||
                          usertype?.user_type === "organization"
                            ? true
                            : false
                        }
                      />
                      {!fields.organization && error.organization ? (
                        <label className="text-error">
                          {error.organization}
                        </label>
                      ) : null}
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Store")}<span className="text-danger">*</span>
                      </span>
                      <InfoTooltip
                        title={
                          "Maximum 100 characters. No HTML or emoji allowed"
                        }
                      />
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <Select
                      ref={inputElementStore}
                        noOptionsMessage={() => "Not found"}
                        placeholder={t("Store name #1 (ID: 13546)")}
                        getOptionLabel={(e) => e.store_name}
                        options={storeListByOrgnazation}
                        styles={CUSTOM_DROPDOWN_STYLE}
                        value={fields.store}
                        onChange={handleChangeStore}
                        isClearable
                        isDisabled={
                          usertype?.user_type === "organization_store"
                            ? true
                            : false
                        }
                      />
                      {!fields.store && error.store ? (
                        <label className="text-error">{error.store}</label>
                      ) : null}
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Giftcard")}<span className="text-danger">*</span>
                      </span>
                      <InfoTooltip
                        title={
                          "Maximum 100 characters. No HTML or emoji allowed"
                        }
                      />
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <Select
                      ref={inputElementGiftcard}
                        noOptionsMessage={() => "Not found"}
                        placeholder={t("Select Giftcard")}
                        getOptionLabel={(onlyManaulInventoryGiftcard) =>
                          onlyManaulInventoryGiftcard?.giftcard?.default_title
                        }
                        options={onlyManaulInventoryGiftcard}
                        styles={CUSTOM_DROPDOWN_STYLE}
                        value={fields.product}
                        onChange={handleChangeProduct}
                        isClearable
                      />
                      {!fields.product && error.product ? (
                        <label className="text-error">{error.product}</label>
                      ) : null}
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Denomination")}<span className="text-danger">*</span>
                      </span>
                      <InfoTooltip
                        title={
                          "Maximum 100 characters. No HTML or emoji allowed"
                        }
                      />
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <Select
                      ref={inputElementDenomi}
                        noOptionsMessage={() => "Not found"}
                        placeholder={t("Select Denomination")}
                        getOptionLabel={(denominationState) =>
                          denominationState?.denomination_value
                        }
                        options={denominationState}
                        styles={CUSTOM_DROPDOWN_STYLE}
                        value={fields.denomination}
                        onChange={handleDenomination}
                        isClearable
                      />
                      {!fields.denomination && error.denomination ? (
                        <label className="text-error">
                          {error.denomination}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex width-35">
            <Info selectedGiftcard={fields.product} />
          </div>
        </div>
        {/* <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Select Style<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <div className="d-flex gap-3">
          <img src={SellcardImg} alt="Logo Not Found" />
          <div className="d-flex align-items-center">
          <span className="font-20 font-bold text-light-blue cursor" 
          onClick={() => changeStyle()}>
            <u>Change Style</u>
          </span>
          </div>
          </div>
        </div> */}

        <div className="d-flex gap-2">
          <Button onClick={Submit} text={t("Continue")} type="primary" />
        </div>
      </div>
    </div>
  );
};

export default SellCard;
