/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import GiftCardOrderSummaryProd from "../../../components/Order/GiftCardOrderSummaryProd";
import GiftCardSummaryDetails from "../../../components/Order/GiftCardSummaryDetails";
import GoogleIncClient from "../../../components/Order/GoogleIncClient";
import { useDispatch, useSelector } from "react-redux";
import { putCreateOrderForGiftcard } from "../../../Redux/CreateOrder/CreateOrderAction";
import DistributionStoreDetails from "../../../components/Distribution/DistributionStoreDetails";
import StoreProductDetails from "../../../components/Distribution/StoreProductDetails";
import StoreOrderDetails from "../../../components/Order/StoreOrderDetails";
import { useState } from "react";
import DistGiftcardModel from "../../../components/Models/DistGiftcardModel";
import ModalComp from "../../../components/Models/ModalComp";
import { getPratnerFloats } from "../../../Redux/Float/floatAction";
import { useTranslation } from "react-i18next";

const OrderSummary = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const giftcardOrderData = useSelector((state) => state.createOrder);
  const [totalAmount, setTotalAmount] = useState();
  const [floatBalance, setFloatBalance] = useState();
  const [addFloatPath, setAddFloatPath] = useState();

  const partnerFloatbalance = useSelector((state) => state.partnerFloat.floats);

  const [modelText, setModelText] = useState("");

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    if (
      giftcardOrderData?.createorderData?.selectedOrganization?.float_flag ===
      true
    ) {
      dispatch(
        getPratnerFloats(
          giftcardOrderData?.createorderData?.selectedOrganization?.id
        )
      );
    }
  }, [giftcardOrderData?.createorderData?.selectedOrganization]);

  const getFloatbalance = () => {
    partnerFloatbalance &&
      partnerFloatbalance.map((item) => {
        if (
          giftcardOrderData?.createorderData?.product?.country_id ===
          item.country_id
        ) {
          // SetIsCurrencymatched(true);
          setShowModal(false);
          setFloatBalance(item);
        }
      });
  };
  useEffect(() => {
    const orderAmount =
      giftcardOrderData?.createorderData?.quantity *
      (giftcardOrderData?.createorderData?.product?.giftcard_value_type ===
        "denominations"
        ? giftcardOrderData?.createorderData?.denomination?.denomination_value
        : giftcardOrderData?.createorderData?.rangeDenomination);
    const discountedamount =
      (orderAmount *
        giftcardOrderData?.createorderData?.selectedProduct?.discount) /
      100;
    setTotalAmount(orderAmount - discountedamount);
  }, []);

  useEffect(() => {
    getFloatbalance();
  }, [partnerFloatbalance]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   const orderamount =
  //     giftcardOrderData?.createorderData?.quantity *
  //     (giftcardOrderData?.createorderData?.product?.giftcard_value_type ===
  //     "denominations"
  //       ? giftcardOrderData?.createorderData?.denomination?.denomination_value
  //       : giftcardOrderData?.createorderData?.rangeDenomination);
  //       console.log("orderamount ", orderamount)
  //   const discountedamount = giftcardOrderData?.createorderData?.selectedProduct?.discount
  //     ? (orderamount *
  //         giftcardOrderData?.createorderData?.quantity *
  //         giftcardOrderData?.createorderData?.selectedProduct?.discount) /
  //       100
  //     : (orderamount *
  //         giftcardOrderData?.createorderData?.quantity *
  //         giftcardOrderData?.createorderData?.selectedProduct?.discount) /
  //       100;
  //   setTotalAmount(orderamount - discountedamount);
  //   console.log("discountedamount ",orderamount - discountedamount)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (!giftcardOrderData?.createorderData) {
      history("/gift-card-distribution");
    }
  }, [giftcardOrderData?.createorderData]);

  useEffect(() => {
    if (
      giftcardOrderData?.createorderData?.selectedClient?.float_flag === true
    ) {
      setAddFloatPath("/float/addfloat-details");
    } else {
      setAddFloatPath("/float/addfloat-org-details");
    }
  }, [
    giftcardOrderData?.createorderData?.selectedClient?.float_flag,
    giftcardOrderData?.createorderData?.selectedOrganization?.float_flag,
  ]);

  const goTonextStep = () => {
    let createOrderObj = {
      product_value:
        giftcardOrderData?.createorderData?.product?.giftcard_value_type ===
          "denominations"
          ? giftcardOrderData?.createorderData?.denomination?.denomination_value
          : giftcardOrderData?.createorderData?.rangeDenomination,
      product: giftcardOrderData.createorderData.product,
      quantity: giftcardOrderData.createorderData?.quantity,
      denomination: giftcardOrderData?.createorderData?.denomination,
      countryCurrency:
        giftcardOrderData?.createorderData?.product?.country?.name,
      brand: "",
      deliveryType: giftcardOrderData?.createorderData?.deliveryType?.name,
      deliveryAddressline1:
        giftcardOrderData.createorderData?.deliveryAddressline1,
      deliveryAddressline2:
        giftcardOrderData.createorderData.deliveryAddressline2,
      deliveryOption: giftcardOrderData.createorderData?.deliveryOptions?.name,
      cityName: giftcardOrderData.createorderData.product.cityName,
      emailid: giftcardOrderData?.createorderData?.emailid,

      phoneNumber: giftcardOrderData.createorderData?.phoneNumber,

      selectedProduct: giftcardOrderData.createorderData?.selectedProduct,
      selectedQuantity: giftcardOrderData.createorderData?.selectedQuantity,
      selectedDenomination:
        giftcardOrderData?.createorderData?.selectedDenomination
          ?.denomination_value,
      selectedClient: giftcardOrderData.createorderData?.selectedClient,
      selectedStore: giftcardOrderData.createorderData?.selectedStore,
      selectedOrganization:
        giftcardOrderData?.createorderData?.selectedOrganization,
      store: giftcardOrderData.createorderData.store
        ? giftcardOrderData.createorderData.store
        : giftcardOrderData.createorderData.clientType,
      discount:
        giftcardOrderData?.createorderData?.selectedProduct?.discount &&
        giftcardOrderData?.createorderData?.selectedProduct?.discount,
      orderAmount:
        giftcardOrderData?.createorderData?.quantity *
        (giftcardOrderData?.createorderData?.product?.giftcard_value_type ===
          "denominations"
          ? giftcardOrderData?.createorderData?.denomination?.denomination_value
          : giftcardOrderData?.createorderData?.rangeDenomination),
      total_price: totalAmount ? totalAmount : 0,
    };

    if (
      giftcardOrderData?.createorderData?.selectedClient?.float_flag === true ||
      giftcardOrderData?.createorderData?.selectedOrganization?.float_flag ===
      true
    ) {
      if (totalAmount > floatBalance?.balance) {
        setModelText(
          "Your available float balance is low, Please add some float."
        );
        setShowModal(true);
        return;
      }
    }

    dispatch(putCreateOrderForGiftcard(createOrderObj, goToSuccessScreem));
  };

  const goToSuccessScreem = () => {
    history("/giftcard-order-success");
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-4 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Order Summary")}</span>
        </div>
        <div className="d-flex ">
          <Button
            onClick={() => goTonextStep()}
            text={t("Confirm & Send")}
            type="primary"
          />
        </div>
      </div>
      <div className=" d-flex mb-4">
        <span className="font-bolder font-14 text-muted ">
          {t("Gift card will be sent to the email ID as a password protected Excel Sheets")}
        </span>
      </div>

      {giftcardOrderData?.createorderData?.selectedClient ? (
        <div className="d-flex ">
          <GoogleIncClient
            floatBalance={floatBalance}
            prepaidClient={
              giftcardOrderData?.createorderData?.selectedClient?.float_flag
            }
          />
        </div>
      ) : (
        <DistributionStoreDetails floatBalance={floatBalance} />
      )}
      {giftcardOrderData?.createorderData?.selectedClient ? (
        <div className="d-flex ">
          <GiftCardOrderSummaryProd />
        </div>
      ) : (
        <StoreProductDetails />
      )}
      {giftcardOrderData?.createorderData?.selectedClient ? (
        <div className="d-flex ">
          <GiftCardSummaryDetails floatBalance={floatBalance} />
        </div>
      ) : (
        <StoreOrderDetails />
      )}
      <div>
        <ModalComp
          size="md"
          showModal={showModal}
          component={
            <DistGiftcardModel
              handleClose={handleClose}
              heading={modelText}
              path={addFloatPath}
            />
          }
        />
      </div>
    </div>
  );
};

export default OrderSummary;
