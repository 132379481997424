import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Tag } from "../../../assests/svg/Tag/tag.svg";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import TagsInput from "../../../components/TagsInput/TagsInput";
import InputField from "../../../components/InputField/InputField";
import { useDispatch, useSelector } from "react-redux";

import {
  patchGetBrandCategory,
  patchPostBrand,
} from "../../../Redux/Brands/brandAction";
import { getAllLanguage } from "../../../Redux/Language/languageAction";
import { ReactComponent as CloseIcone } from "../../../assests/svg/close.svg";
import Toasts from "../../../components/Toasts/Toasts";
import ReactSwitchButton from "../../../components/Button/ReactSwitchButton";
import Multiselect from "multiselect-react-dropdown";
import { getAllBrandCategoryList } from "../../../Redux/BrandCategories/brandCategoryAction";
import PulseLoader from "../../../components/Loader/PulseLoader";
import { useTranslation } from "react-i18next";

const EditBrand = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const inputElement = useRef(null)

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const Preferredlanguage = useSelector((state) => state?.languages?.languages);
  const loader = useSelector((state) => state?.brands?.loading);
  const brandItem = location.state.brandItem;
  const [brandCategory, setBrandCategory] = useState([]);

  const [prodTitles, setProdTitles] = useState([]);
  const [error, setError] = useState({
    brand_name: "",
    brandType: "",
    brandCategory: "",
  });
  const categoriesState = useSelector(
    (state) => state.brandCategories.brandCategories
  );

  const [activeFlag, setActiveFlag] = useState(brandItem?.active_flag);
  // tags input code
  const [brandTags, setBrandTags] = useState(brandItem?.tags);

  // Getting Brand Category
  const getBrandCatMap = (res) => {
    setBrandCategory(res);
  };

  // Getting Brand Map BrandCategory
  useEffect(() => {
    dispatch(patchGetBrandCategory(brandItem.id, getBrandCatMap));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllLanguage());
    dispatch(getAllBrandCategoryList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    setEditObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Preferredlanguage]);

  const setEditObject = () => {
    let arr = [];
    const res = Object.entries(brandItem.brand_name).map((e) => ({
      [e[0]]: e[1],
    }));

    // eslint-disable-next-line array-callback-return
    res && res.map((item) => {
      let name = Object.values(item).join("");
      let langCode = Object.keys(item).join("");

      // eslint-disable-next-line array-callback-return
      Preferredlanguage &&
        Preferredlanguage.map((item) => {
          if (item.language_code === langCode) {
            let obj = {
              brand_name: name,
              language_id: item,
            };
            arr.push(obj);
          }
        });
      setProdTitles(arr);
    });
  };

  const handleAddClickProdTitle = (e) => {
    if (
      prodTitles.some(
        (item) => item.language_id.language_code === e.language_code
      )
    ) {
      toggleShowToast();
      return;
    } else {
      setProdTitles([...prodTitles, { brand_name: "", language_id: e }]);
    }
  };

  // Remove Selected Language title input field
  const handleRemoveClickProdTitle = (index) => {
    const list = [...prodTitles];
    list.splice(index, 1);
    setProdTitles(list);
  };

  // Getting Brand title value from input field
  const handleInputChangeProdTitle = (e) => {
    const { name, value } = e.target;
    setError({brand_name: ""})
    let arr = [...prodTitles];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (name === item.language_id.language_code) {
        arr[i].brand_name = value;
      }
    });
    setProdTitles(arr);
  };

  const validate = () => {
    if (!prodTitles[0].brand_name) {
      setError({ brand_name: t("Please enter brand name") });
      if(inputElement.current){
        inputElement.current.focus()
      }
      return false;
    }

    return true;
  };
  const goToSuccessfull = () => {
    history("/brand-edit-success");
  };

  const handleBrandCategory = (selectedList, selectedItem) => {
    setBrandCategory(selectedList);
  };

  const handleRemoveBrandCategory = (selectedList, removedItem) => {
    setBrandCategory(selectedList);
  };

  const submit = () => {
    let obj = {
      id: brandItem.id,
      brandName: prodTitles,
      tags: brandTags,
      activeFlag: activeFlag,
      brandCategory: brandCategory[0]?.default_category_name,
    };
    let val = validate();
    if (val) {
      dispatch(patchPostBrand(obj, brandCategory, goToSuccessfull));
    }
  };

  const activeHandle = () => {
    setActiveFlag(!activeFlag);
  };

  return (
    <>
      {loader === false ? (
        <div className="d-flex flex-column justify-content-start py-4 gap-3 px-4 bg-light">
          <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
            <div className="d-flex flex-row align-items-center pb-2 gap-3">
              <LeftIcon onClick={() => history(-1)} className="cursor" />
              <span className="font-bold font-32 my-2">{t("Edit Brand")}</span>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between gap-2">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex gap-3">
                  <Tag />
                  <span className="font-20 font-bold">{t("Edit Brand")}</span>
                </div>
              </div>

              <div className="d-flex flex-column gap-4 mt-4 ">
                {prodTitles.map((item, index) => (
                  <div className="d-flex flex-column gap-3" key={index}>
                    <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">
                          {t("Brand name")} #{index + 1} {item.language_id.language}
                          <span className="text-danger">*</span>
                        </span>
                        <InfoTooltip
                          title={
                            "Enter brand name"
                          }
                        />
                      </div>
                      {prodTitles.length !== 1 && (
                        <div
                          className="cursor"
                          onClick={() => handleRemoveClickProdTitle(index)}
                        >
                          {index !== 0 && <CloseIcone />}
                        </div>
                      )}
                    </div>

                    <div className="d-flex flex-column gap-1">
                      <InputField
                      inputRef={inputElement}
                        onChange={handleInputChangeProdTitle}
                        name={item.language_id.language_code}
                        placeholder={`${t("Brand name")} ${item.language_id.language.toLocaleLowerCase()}`}
                        type="text"
                        value={item?.brand_name}
                      />
                      {!prodTitles.default_name && error.brand_name ? (
                        <label className="text-error">{error.brand_name}</label>
                      ) : null}
                    </div>
                  </div>
                ))}

                <div className="d-flex ">
                  <div className="btn-group w-100">
                    <div
                      className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                      data-bs-toggle="dropdown"
                    >
                      <span>{t("Add other language for brand title")}</span>
                    </div>
                    <ul className={`dropdown-menu rounded-card-7 dropdown-menu-start ${props?.lang === 'ar' ? 'dropdown-menu-ar' : ''}`}>
                      {Preferredlanguage &&
                        Preferredlanguage.map((item, i) => (
                          <li
                            key={i}
                            onClick={() => handleAddClickProdTitle(item)}
                          >
                            <div className="dropdown-item cursor">
                              {item.language}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("Brand category")}</span>
                    <InfoTooltip
                      title={"Select Brand category"}
                    />
                  </div>
                  {/* Multi Select Dropdown */}
                  <Multiselect
                    placeholder={t("Select brand category")}
                    options={categoriesState}
                    showCheckbox={true}
                    selectedValues={brandCategory}
                    onSelect={handleBrandCategory}
                    onRemove={handleRemoveBrandCategory}
                    displayValue="default_category_name"
                    className={`${props?.lang === 'ar' ? 'multiSelectContainer-ar' : ''}`}
                  />
                </div>

                <div className="d-flex flex-column mt-2 gap-3">
                  <span className="font-bold font-14">{t("Tags")}</span>
                  <div className="d-flex flex-row align-items-center gap-2">
                    <TagsInput
                      tags={brandTags}
                      setTags={setBrandTags}
                      placeholder={t("Enter tags to describe your brands")}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("Active?")}</span>
                    <InfoTooltip title={"Active/Inactive"} />
                  </div>
                  <ReactSwitchButton
                    onChange={() => activeHandle()}
                    value={activeFlag}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <Button onClick={() => submit()} text={t("Update")} type="primary" />
          </div>

          <div
            className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg="Allready Added."
              imageType={"warning"}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default EditBrand;
