import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import Button from "../Button/Button";
import { ReactComponent as EditButton } from "../../assests/svg/edit.svg";
import SmallToast from "../Toast/SmallToast";
import CopyToClipboard from "react-copy-to-clipboard";
import Filter from "../Filter/Filter";
import { PRODUCT_STATUS_FILTERS } from "../../utils/ConstantData";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoaders from "react-spinners/PulseLoader";
import { getAllOrganizationList } from "../../Redux/Organizations/organizationAction";
import PulseLoader from "../Loader/PulseLoader";

const Organizations = (props) => {
  const {organizationState}=props
  const dispatch=useDispatch()
  const [id, setId] = useState("");
  const history = useNavigate();
  
  const [searchTerm, setSearchTerm] = useState('');
  // infinite scroll
  const hasmore = useSelector(
    (state) => state?.organizations?.next_iteration
  );
  const loading = useSelector((state) => state?.organizations?.loading)
  // console.log(hasmore,loading)
  const [skip, setskip] = useState(organizationState.length === 0 ? -10 : organizationState.length - 10);
  const [limit, setlimit] = useState(10);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (organizationState.length === 0){
      fetchdata(skip)
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(getAllOrganizationList(skip + limit, limit, organizationState));
      setskip(skip + limit)
    }, 100);
  }

  const searchHandler = (e) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const loadMore = () => {
  //   setVisible((prevVisible) => prevVisible + 10);
  // };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 5);
    return val;
  };

  const orgEditHandler = (item) => {
    history("/organization-edit", { state: { organizationsItem: item } });
  };

  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  return loading === true && organizationState.length===0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) :(
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="card-body d-flex  flex-row justify-content-between align-item-center">
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div className="d-flex gap-3 my-2">
              <Tag />
              <span className="font-20 font-bold">Organizations</span>
            </div>
            <div className="Inputwithicon">
              <div id="search">
                <Searchicon />
              </div>
              <input
                type="text"
                className="input-custom font-bold text-muted w-95"
                name="search"
                placeholder="Search by name"
                onChange={searchHandler}
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <Button
              type="primary"
              text="Download CSV"
              //  className="rounded-6 my-4 mt-6 fw-bolder"
              //  onClick={() => toggleShowToast()}
            />
            <div>
              <Filter data={PRODUCT_STATUS_FILTERS} />
            </div>
          </div>
        </div>
        <InfiniteScroll
        dataLength={organizationState?.length || 0}
        next={fetchdata}
        hasMore={hasmore}
        loader={ <div className="d-flex justify-content-center"> <PulseLoaders color={"#594190"} loading={loading} size={10} /> </div> }
      >
        <div className="row px-5 py-2 justify-content-between gap-3">
          <table responsive="xl">
            <tbody>
              <tr className="col-3 card-body gap-3 border-bottom py-4 justify-content-between">
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Name
                </td>
                <td className="col-2 card-body gap-1 font-13 font-bold text-muted">
                  Organization Id
                </td>
                <td className="col-2 card-body gap-1 font-13 font-bold text-muted">
                  Status
                </td>
                <td className="col-2 card-body gap-1 font-13 font-bold text-muted">
                  Created at
                </td>
                <td className="col-2 card-body gap-1 font-13 font-bold text-muted">
                  Updated at
                </td>
              </tr>

              {organizationState &&
                organizationState.filter((item) => {
                  if(searchTerm == ""){
                    return item
                  } else if(item?.default_title.toLowerCase().includes(searchTerm.toLowerCase())){
                    return item
                  }
                }).map((item) => (
                  <tr className="card-body gap-3 border-bottom" key={item.id}>
                    <td className="card-body gap-1">
                      <div className="d-flex flex-column justify-content-start ">
                        <span
                          className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                          title={item?.default_title}
                          style={{ maxWidth: "130px" }}
                          data-toggle="tooltip"
                          data-placement="top"
                        >
                          {item?.default_title}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex justify-content-start align-items-center">
                        <span
                          className="font-14 font-boldest text-dark visible mt-4"
                          title={item.id}
                        >
                          {trimIdHandler(item.id)}
                          <span>...</span>
                        </span>

                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Copy to clipboard"
                          className="cursor "
                          value={item.id}
                          onClick={() => copyToClipboard(item.id)}
                        >
                          <CopyToClipboard text={item.id}>
                            <CopyButton />
                          </CopyToClipboard>
                        </span>
                      </div>
                    </td>
                    <td className="card-body gap-1">
                      <span>
                        {item.active_flag === true ? (
                          <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                            Active
                          </span>
                        ) : (
                          <span className="font-15 font-bold text-danger bg-04 bg-opacity-25 rounded-3 px-2">
                            Deactive
                          </span>
                        )}
                      </span>
                    </td>
                    <td className="card-body gap-1">
                      <div className="d-flex flex-row justify-contents-between align-items-center">
                        <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                          {new Date(item.created_at).toLocaleDateString(
                            "en-us",
                            { year: "numeric", month: "short", day: "numeric" }
                          )}
                        </span>
                        {/* <span className="font-13 font-bolder text-danger bg-opacity-25 rounded-3 px-1">
                        {item.Percent}
                      </span> */}
                      </div>
                    </td>
                    <td className="card-body gap-1">
                      <div className="d-flex flex-row justify-contents-between align-items-center">
                        <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                          {new Date(item.updated_at).toLocaleDateString(
                            "en-us",
                            { year: "numeric", month: "short", day: "numeric" }
                          )}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex flex-row justify-content-end">
                        <span
                          className="cursor"
                          onClick={() => orgEditHandler(item)}
                        >
                          <EditButton />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!organizationState.length && (
            <div className="d-flex flex-row justify-content-center py-3">
              <span className="font-20 font-bold text-error">
                Giftcards not found
              </span>
            </div>
          )}
        </div>
        </InfiniteScroll>
     {/*   <div className="d-flex flex-row  justify-content-center align-item-center gap-3">
          <div className="d-flex">
            {visible < organizationState.length && (
              <Button
                type="rounded"
                text="Load more"
                className="rounded-6 my-4 mt-6 "
                onClick={() => loadMore()}
              />
            )}
          </div>
        </div>
      */}
      </div>
      <div>
        <SmallToast data={id ? id : ""} message={"Copied"} />
      </div>
    </div>
  );
};

export default Organizations;
