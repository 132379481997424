import {
  FETCH_SUPPLIER_MAPPING_REQUEST,
  FETCH_SUPPLIER_MAPPING_SUCCESS,
  FETCH_SUPPLIER_MAPPING_FAILURE,
  PUT_SUPPLIER_MAPPING_REQUEST,
  PUT_SUPPLIER_MAPPING_SUCCESS,
  PUT_SUPPLIER_MAPPING_FAILURE,
  GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID,
  GET_GIFTCARD_SUPPLIER_MAPPING_REQUEST,
  GET_GIFTCARD_SUPPLIER_MAPPING_SUCCESS,
  GET_GIFTCARD_SUPPLIER_MAPPING_FAILURE,
  PUT_GIFTCARD_SUPPLIER_MAPPING_REQUEST,
  PUT_GIFTCARD_SUPPLIER_MAPPING_SUCCESS,
  PUT_GIFTCARD_SUPPLIER_MAPPING_FAILURE,
} from "./SupplierMappingType";

import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";

export const fetchSupplierMappingRequest = () => {
  return {
    type: FETCH_SUPPLIER_MAPPING_REQUEST,
  };
};

export const fetchSupplierMappingSuccess = (supplier) => {
  return {
    type: FETCH_SUPPLIER_MAPPING_SUCCESS,
    payload: supplier,
  };
};
export const fetchSupplierMappingFailure = () => {
  return {
    type: FETCH_SUPPLIER_MAPPING_FAILURE,
  };
};
// get variants by merchandise id
export const getMerchandiseVariants = (data) => {
  return {
    type: GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID,
    payload: data,
  };
};

export const fetchSupplierMappingList = (id) => {
  return (dispatch) => {
    dispatch(fetchSupplierMappingRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        // console.log("mapping response" + JSON.stringify(response.data));
        if (response.data.result.product_variants_flag === true) {
          dispatch(getvariants(id));
        }
        dispatch(fetchSupplierMappingSuccess(response.data));
      })
      .catch((error) => {
        const errorMsg = error;
        dispatch(
          fetchSupplierMappingFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};
// to mapped giftcard with supplier
export const getGiftcardSupplierMappingRequest = () => {
  return {
    type: GET_GIFTCARD_SUPPLIER_MAPPING_REQUEST,
  };
};

export const getGiftcardSupplierMappingSuccess = (giftcard) => {
  return {
    type: GET_GIFTCARD_SUPPLIER_MAPPING_SUCCESS,
    payload: giftcard,
  };
};
export const getGiftcardSupplierMappingFailure = () => {
  return {
    type: GET_GIFTCARD_SUPPLIER_MAPPING_FAILURE,
  };
};
export const getGiftcardSupplierMappingList = (id) => {
  return (dispatch) => {
    dispatch(getGiftcardSupplierMappingRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        // console.log("giftcard response" + JSON.stringify(response.data.result));
        dispatch(getGiftcardSupplierMappingSuccess(response.data.result));
      })
      .catch((error) => {
        const errorMsg = error;
        dispatch(
          getGiftcardSupplierMappingFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// to get merchandise variants by merchandise id
const getvariants = (id) => {
  return (dispatch) => {
    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/${id}/variants`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        // console.log("response" + JSON.stringify(response.data.result));
        dispatch(getMerchandiseVariants(response.data.result));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// to put supplier merchandise mapping data
export const putSupplierMerchandiseRequest = () => {
  return {
    type: PUT_SUPPLIER_MAPPING_REQUEST,
  };
};

export const putSupplierMerchandiseSuccess = (data) => {
  return {
    type: PUT_SUPPLIER_MAPPING_SUCCESS,
    payload: data,
  };
};

export const putSupplierMerchandiseFailure = (error) => {
  return {
    type: PUT_SUPPLIER_MAPPING_FAILURE,
    payload: error,
  };
};

export const putSupplierMerchandiseProducts = (suppMerchandisObj) => {
  return (dispatch) => {
    dispatch(putSupplierMerchandiseRequest);

    let supplist = [];
    suppMerchandisObj.map((item) => {
      let obj = {
        supplier_id: item.supplier.id,
        supplier_sku: item.supplierSKU,
        supplier_price: item.supplierPrice,
        };
        supplist.push(obj)
    })
   

    let varId = [];
    suppMerchandisObj.map((item) => {
      let varObj = {
        mapped_for: "product",
        merchandise_id: suppMerchandisObj[0].merchandise_id,
        variant_id: item.id,
        supplier_list: supplist,
        active_flag: true,
      }
      varId.push(varObj)
    })

    let config = {
      method: "post",
      url: `${baseUrl}/v1/merchandise/supplier/mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data:varId
    };

    axios(config)
      .then((response) => {
        console.log("response" + JSON.stringify(response.data.result));
        //  dispatch(putSupplierMerchandiseSuccess(response.data));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          putSupplierMerchandiseFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// to put supplier giftcard mapping data
export const putSupplierGiftcardRequest = () => {
  return {
    type: PUT_GIFTCARD_SUPPLIER_MAPPING_REQUEST,
  };
};

export const putSupplierGiftcardSuccess = (data) => {
  return {
    type: PUT_GIFTCARD_SUPPLIER_MAPPING_SUCCESS,
    payload: data,
  };
};

export const putSupplierGiftcardFailure = (error) => {
  return {
    type: PUT_GIFTCARD_SUPPLIER_MAPPING_FAILURE,
    payload: error,
  };
};

export const putSupplierGiftcardMappingProducts = (suppGiftcardObj) => {
  return (dispatch) => {
    dispatch(putSupplierMerchandiseRequest);

    let supplist = [];
    suppGiftcardObj.map((item) => {
      let obj = {
        supplier_id: item.supplier.id,
        supplier_sku: item.supplierSKU,
        supplier_price: item.supplierPrice,
      };
      supplist.push(obj);
    });

    const payload = {
      giftcard_id: suppGiftcardObj[0].id,
      supplier_list: supplist,
      active_flag: true,
      deleted_flag: false,
      user_access_logs: "string",
    };

    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcards/supplier_mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        console.log("response" + JSON.stringify(response.data.result));
        //  dispatch(putSupplierMerchandiseSuccess(response.data));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          putSupplierMerchandiseFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};
