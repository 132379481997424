/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import GoogleIncIcon from "../../assests/img/GoogleInc.png";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as RadishTag } from "../../assests/svg/Tag/radish_tag.svg";
import { getStoreDetailsById } from "../../Redux/CreateStore/createStoreAction";
import PulseLoader from "../../components/Loader/PulseLoader";

const StoreDashboard = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.createStore?.loading);
  const storeDetails = useSelector((state) => state.createStore?.storeDetails);

  const [usertype, setusertype] = useState();


  useEffect(() => {
    setusertype(JSON.parse(localStorage.getItem("user")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    if (!storeDetails) {
      usertype?.sub_type_id && dispatch(getStoreDetailsById(usertype?.sub_type_id));
    }
  }, [usertype]);

  useEffect(() => {

  }, [storeDetails]);



  return (
    <>
      {loading === false ? (
        <div className="d-flex flex-column  justify-content-between px-4 py-4 w-100 bg-light">
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 w-100 py-3">
            <div className="d-flex px-4 gap-3 pt-2 align-items-center">
              <RadishTag />
              <span className="font-22 font-boldest text-dark">Store Info</span>
            </div>
            <div className="d-flex flex-row gap-4 py-4 justify-content-between align-items-center">
              <div className="d-flex flex-row gap-3 py-  align-items-center">
                <div className="d-flex flex-column gap-3 mb-  py-  px-3 align-items-center">
                  <div className="d-flex flex-row mx-2 gap-4 justify-content-between">
                    <img
                      src={GoogleIncIcon}
                      alt="not found"
                      className="rounded-3 "
                      width="80px"
                      height="80px"
                    />
                    <div className=" d-flex flex-column gap-1">

                      <span className="font-20 font-bold text-dark">
                        Store Name :{" "}
                        <span className="font-20 font-boldest text-dark ">
                          {storeDetails?.store_name}
                        </span>

                      </span>

                      <span className="font-13 font-bold text-dark">
                        Store Id :{" "}
                        <span
                          className="font-13 font-bold text-muted"
                          title={storeDetails?.id}
                        >
                          {storeDetails?.id}
                        </span>
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Copy to clipboard"
                          className="cursor "
                          value={storeDetails?.id}
                        ></span>
                      </span>
                      <span className="font-13 font-bold text-dark">
                        Organization Name :{" "}
                        <span className="font-13 font-bold text-muted" >
                          {/* {storeDetails?.id} */}
                        </span>
                      </span>
                      <span className="font-13 font-bold text-dark">
                        Address :{" "}
                        <span className="font-13 font-bold text-muted" >
                          {storeDetails?.store_address + " " + storeDetails?.zip_code}
                        </span>
                      </span>
                      <span className="font-13 font-bold text-dark">
                        Area :{" "}
                        <span className="font-13 font-bold text-muted" >
                          {storeDetails?.area}
                        </span>
                      </span>
                      <span className="font-13 font-bold text-dark">
                        State :{" "}
                        <span className="font-13 font-bold text-muted" >
                          {/* {storeDetails?.id} */}
                        </span>
                      </span>
                      <span className="font-13 font-bold text-dark">
                        Country :{" "}
                        <span className="font-13 font-bold text-muted" >
                          {/* {storeDetails?.id} */}
                        </span>
                      </span>
                      <span className="font-13 font-bold text-dark">
                        Email :{" "}
                        <span className="font-13 font-bold text-muted" >
                          {storeDetails?.email_id}
                        </span>
                      </span>
                      <span className="font-13 font-bold text-dark">
                        Contact Number :{" "}
                        <span className="font-13 font-bold text-muted" >
                          {storeDetails?.store_phone_no}
                        </span>
                      </span>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )
      }
    </>
  );
};

export default StoreDashboard;
