import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../assests/svg/Tag/blue_tag.svg";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import Button from "../../../components/Button/Button";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Toasts from "../../../components/Toasts/Toasts";
import { useDispatch } from "react-redux";
import { postManualInventory } from "../../../Redux/ManualInventory/manualInventoryAction";
import DragandDropDocs from "../../../components/DragandDrop/DragandDropDocs";
import BulkDownloadSample from "../../../components/Distribution/BulkDownloadSample";
import { postBulkDistribution } from "../../../Redux/Distributions/BulkDistribution/BulkDistributionAction";
import { useTranslation } from "react-i18next";

const AddBulkDistribution = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const [showToast, setShowToast] = useState(false);
  const [fileUpload, setFileUpload] = useState([]);


  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useNavigate();

  const goToSuccessfull = () => {
    history('/manual-inventory-success')
  }

  const submitHandler = () => {

    if(fileUpload.length > 0){
      dispatch(postBulkDistribution(fileUpload, goToSuccessfull));
    }
    toggleShowToast();   
  };

  return (
    <div className="d-flex flex-column py-4 gap-4 position-relative px-4 bg-light">
      <div className="d-flex flex-row align-items-center gap-3">
        <LeftIcon className="cursor" onClick={() => history(-1)}/>
        <span className="font-bold font-32">{t("Bulk Distribution")}</span>
      </div>

      <div className="d-flex flex-row justify-content-between gap-2">
        <div className="d-flex width-65">
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <Tag />
                <span className="font-20 font-bold">{t("Upload Excel of Clients")}</span>
              </div>
            </div>

            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex flex-column gap-3 mt-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("Upload excel")}</span>
                    <InfoTooltip
                      title={"Upload excel for product bulk distribution."}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <DragandDropDocs
                      files={fileUpload}
                      setFiles={setFileUpload}
                    />
                    {/* {!fileUpload.length > 0 && error.fileUpload ? (
                                <label className="text-error">{error.fileUpload}</label>
                            ) : null} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex mt-4 mb-2">
              <Button
                onClick={() => submitHandler()}
                text={t("Upload & Continue")}
                type="primary"
              />
            </div>
          </div>
        </div>
        <div className="d-flex width-35 h-100">
          <BulkDownloadSample />
        </div>
      </div>

      <div
        className="position-fixed bottom-0 start-50 translate-middle-x mb-3 w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg={fileUpload.length === 0 ? "Please upload a file" :`${"Bulk uploaded successfully."}`}
        //    ManualInventoryErrors?.errors ? manualInventoryPostError.map((item) => item) : `${"Bulk uploaded successfully."}`}
        imageType={fileUpload.length === 0 ? "warning" : "success"}
        //   imageType={ManualInventoryErrors?.errors ? "warning" : fileUpload.length === 0 ? "warning" : "success"}
        />
      </div>
    </div>
  );
};

export default AddBulkDistribution;

