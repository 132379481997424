import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import SigninLeftPanel from "../../components/SigninLeftPanel/SigninLeftPanel";
import { ReactComponent as Mail } from "../../assests/svg/Sidebar/mail.svg";
import { ReactComponent as Lock } from "../../assests/svg/lock.svg";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { USER_AUTHORIZATION } from "../../utils/prodURL";
import axios from "axios";
import SignInToast from "../../components/Toasts/SignInToast";
import PulseLoader from "../../components/Loader/PulseLoader";

const Forgotpass = () => {
  const location = useLocation();
  const history = useNavigate();
  const { t } = useTranslation();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [otpScr, setOtpScr] = useState(false);
  const [error, setError] = useState({ password: "", username: "", otp: "" });

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
    setUserName(location?.state?.username);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.username]);

  const [passShowHide, setPassShowHide] = useState(false);
  const togglePassword = () => {
    setPassShowHide((prevState) => !prevState);
  };

  const userNameHandler = (e) => {
    const target = e.target;
    var value = target.value;
    setUserName(value)
    // setError({ ...error, username: "" });
  }

  const passwordHandler = (e) => {
    const target = e.target;
    var value = target.value;
    setPassword(value);
    setError({ ...error, password: "" });
  };

  const handleChangeOTP = (e) => {
    setOtp(e);
    setError({ ...error, otp: "" });
  };

  const changeUserName = () => {
    setOtpScr(false);
    setUserName("");
    setOtp("");
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   setMessage(response?.data?.message);
  //   toggleShowToast();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [3000]);


  const userNameValidate = () => {
    if (username === "" || username === null) {
      setError({ ...error, username: "Please enter email or username" });
      return false;
    }

    return true;
  };

  const sendVerificationCode = () => {
    setLoading(true);
    let val = userNameValidate();
    if (val) {
      let config = {
        method: "POST",
        url: `${USER_AUTHORIZATION}/v1/user/password/otp?user_identifier=${username}`,
        headers: {},
      };
      axios(config)
        .then((response) => {
          if (response?.data?.status === "success") {
            // toggleShowToast();
            // setMessage("Password updated successfuly.");
            setOtpScr(true);
          } else if (response?.data?.status === "error") {
            // setMessage(response?.data?.errors[0]);4
            if (response?.data?.code === 429) {
              setMessage("OTP already sent!");
              toggleShowToast();
            } else if (response?.data?.code === 404) {
              // setMessage("User not found");
              setMessage("If you are a registered user in our system, you will receive your password reset information on your registered email");
              toggleShowToast();
            } else if (response?.data?.code === 200) {
              // setMessage("If you are a registered user in our system, you will receive your password reset information on your registered email");
              setMessage("Password updated successfuly.");
              // setMessage(response?.data?.message);
              toggleShowToast();
            }
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }
    setLoading(false);
  };

  const goToSignInScr = (message) => {
    history("/", {
      state: {
        // username: auth.username,
        // password: auth.password,
        message: ("Password updated successfuly.")
      },
    });
  };


  const passwordValidate = () => {
    var passRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,}$)"
    );

    if (password === "" || passRegex.test(password) === false) {
      setError({
        ...error,
        password: `Minimum 8 characters, at least one uppercase letter,
      one lowercase letter, one number and one special character`,
        username: "",
      });
      return false;
    } else if (otp.length !== 6) {
      setError({ ...error, otp: "Please enter OTP" });
      return false;
    }

    return true;
  };

// history("/",
            // // toggleShowToast()
            // {
            //   state:{   
                  
            //      message:response?.data?.message
                 
                
            //   },
            // }
            //  );
  const changePassword = () => {
    setLoading(true);

    let val = passwordValidate();
    if (val) {
      let config = {
        method: "POST",
        url: `${USER_AUTHORIZATION}/v1/user/password?user_identifier=${username}&password=${password}&otp=${otp}`,
        headers: {},
      };
      axios(config)
        .then((response) => {
          console.log(response)
          if (response?.data?.code === 406) {
            setMessage("Invalid OTP");
            toggleShowToast();
          } else if (response?.data?.code === 200) {
            setMessage(response?.data?.message);
            toggleShowToast();
            
            goToSignInScr(message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setLoading(false);
  };

  return loading ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex row align-items-center bg-white">
      <SigninLeftPanel />
      {!otpScr ? (
        <div className="col">
          <div className="d-flex justify-content-center align-items-start ">
            <div className="d-flex flex-column justify-content-start gap-3">
              <h1 className="heading-text">{t("Forgot password")}</h1>
              <span className="heading-caption-text font-13 font-bold">
                {t("Enter your email or username to get started")}
              </span>
              <div className="d-flex flex-column justify-content-start gap-1">
                <div className="Inputwithicon">
                  <input
                    className="input-custom w-100   border-2"
                    type="text"
                    placeholder={t("Your email or Username")}
                    name="username"
                    value={username}
                    autoFocus={username ? true : false}
                    onChange={userNameHandler}
                  />
                  <div id="txt-icon">
                    <Mail />
                  </div>
                </div>
                {error.username && !username ? (
                  <label className="text-error">{error.username}</label>
                ) : null}
              </div>
              <div className="">
                <Button
                  text={"Next"}
                  type="primary"
                  onClick={() => sendVerificationCode()}
                />
              </div>
              <div className="">
                <span
                  onClick={() => history("/")}
                  className="text-light-blue font-14 font-bold cursor text-light-blue"
                >
                  Login
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col">
          <div className="d-flex justify-content-center align-items-start ">
            <div className="d-flex flex-column justify-content-start gap-3">
              <h1 className="heading-text">{t("Forgot password")}</h1>
              <span className="heading-caption-text mb-3 font-13 font-bold">
                {t("We just sent you a varify code. Check your")} <br />
                {t("inbox to get them.")}
              </span>
              <div className="d-flex flex-column justify-content-start gap-1">
                <div className="Inputwithicon">
                  <input
                    className="input-custom w-100   border-2"
                    type={passShowHide ? "text" : "password"}
                    placeholder={t("Enter new password")}
                    name="password"
                    autoFocus={username ? true : false}
                    value={password}
                    onChange={passwordHandler}
                  />
                  <div id="txt-icon">
                    <Lock />
                  </div>
                  <div className="input-group-append position-absolute position-absolute top-0 end-0">
                    <button
                      className="btn  bg-tranparent "
                      type="button"
                      onClick={() => togglePassword()}
                    >
                      {passShowHide ? (
                        <AiFillEye className="text-muted" />
                      ) : (
                        <AiFillEyeInvisible className="text-muted" />
                      )}
                    </button>
                  </div>
                </div>
                {error.password ? (
                  <div>
                    <label className="text-error" style={{ maxWidth: "368.212px" }}>{error.password}</label>
                  </div>
                ) : null}
                <div className="col-sm-12 d-flex flex-row justify-content-center mt-3">
                  <OtpInput
                    value={otp}
                    onChange={handleChangeOTP}
                    numInputs={6}
                    separator={<span className="mx-2"></span>}
                    isInputNum={true}
                    // shouldAutoFocus={true}
                    inputStyle={"otp-input-style"}
                  // containerStyle={"px-4 py-4"}
                  />
                </div>
                {error.otp ? (
                  <label className="text-error">{error.otp}</label>
                ) : null}
                <div className="mt-3">
                  <Button
                    text={"Submit"}
                    type="primary"
                    onClick={() => changePassword()}
                  />
                </div>
                <div className="">
                  <span
                    onClick={() => changeUserName()}
                    className="text-light-blue font-14 font-bold cursor"
                  >
                    Change username
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      )}
      <div className="position-fixed bottom-0 start-50 pb-3 translate-middle-x w-100 " id="liveToast" >
        <SignInToast
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg={message}
          //  successMsg={message}
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default Forgotpass;
