import {
    GET_ALL_REDEMPTION_METHOD_REQUEST,
    GET_ALL_REDEMPTION_METHOD_SUCCESS,
    GET_ALL_REDEMPTION_METHOD_FAILURE
} from './redemptionType'


const initialRedemptionMethodState = {
    loading: false,
    redemptionMethods: [],
    error: ''
}


export const redemptionMethodReducer = (state = initialRedemptionMethodState, action) => {

    switch (action.type) {
        case GET_ALL_REDEMPTION_METHOD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_REDEMPTION_METHOD_SUCCESS:
            return {
                ...state,
                loading: false,
                redemptionMethods: action.payload,
                error: ''

            }
        case GET_ALL_REDEMPTION_METHOD_FAILURE:
            return {
                ...state,
                loading: false,
                redemptionMethods: [],
                error: action.payload
            }
        default: return state
    }
}
export default redemptionMethodReducer