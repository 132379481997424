import React from "react";
import { ReactComponent as Basket } from "../../assests/svg/TotalClients.svg";
import { ReactComponent as Line } from "../../assests/svg/Dashboard/line.svg";
import { ReactComponent as OrangeTag } from "../../assests/svg/Tag/orange_lite_tag.svg";
import { ReactComponent as RedDownArrow } from "../../assests/svg/Product/Red_DownArrow.svg";
import { ReactComponent as GreenUpArrow } from "../../assests/svg/Product/Green_UpArrow.svg";
import { ReactComponent as FilterIcon } from "../../assests/svg/Filter_Icon.svg";
import { ReactComponent as ChartIcon } from "../../assests/svg/Chart.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ReportsOverview = () => {

  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 gap-2 px-4 w-100">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <OrangeTag />
          <span className="font-20 font-bold">Overview</span>
        </div>
        <div>
          <div className="btn-group">
            <div
              className="btn border border-2 rounded-card-7 dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              {t("All time")}
            </div>
            <ul className="dropdown-menu rounded-card-7 dropdown-menu-end">
              <li>
                <div className="dropdown-item">{t("Last 7 days")}</div>
              </li>
              <li>
                <div className="dropdown-item">{t("Last 30 days")}</div>
              </li>
              <li>
                <div className="dropdown-item">{t("All time")}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row w-100  align-items-center">
      <div className="p-2">
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle text-muted"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client
              </button>
              <ul
                className="dropdown-menu rounded-card-7"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <Link className="dropdown-item" to={""}>
                  Client #1
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={""}>
                  Client #2
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={""}>
                  Client #3
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="p-2">
            <span className="my-2">
              <FilterIcon  />
            </span>
          </div>
        </div>

      <div className="d-flex bg-light rounded-card py-2 px-2 mt-4 w-100">
        <div className="d-flex  flex-row justify-content-between shadow-sm bg-white rounded-card px-2 gap-3">
          <div className="mx-2 my-3">
            <Basket />
          </div>
          <div className="my-3 gap-3">
            <div className="">
            <span className="font-15 font-bolder">{t("Total Clients")}</span>
            </div>
            <h1 className="fw-bold font-15 px-4">No data</h1>
          </div>
          <div className="flex-row mr-4 my-2 rounded-3 pl-2">
            <span className=" fw-light font-14 bg-danger text-danger bg-opacity-25 rounded-3 px-1">
              <RedDownArrow className=" mb-1" /> 0%
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between flex-row gap-3 px-4 my-3">
          <div>
            <Line />
          </div>
          <div className="d-flex flex-column gap-1">
            <span className="font-15 font-bolder">{t("Customers")}</span>
            <h1 className="fw-bold font-15">No data</h1>
          </div>

          <div className=" rounded-3 pl-2">
            <span className=" fw-light font-14 bg-white text-success rounded-3 px-1">
              <GreenUpArrow className=" mb-1" /> 0%
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center ">
        <ChartIcon />
      </div>
    </div>
  );
};

export default ReportsOverview;
