import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import Dropdown from "../../components/Dropdown/Dropdown";
import InputField from "../../components/InputField/InputField";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import BackButton from "../../components/Button/BackButton";
import FileUpload from "../../components/FileUpload/FileUpload";
import Button from "../Button/Button";


const CreateClient = () => {
  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submit = () => {
    history("/patner-successfull");
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">Name & Description</span>
        </div>
        <div>
          <BackButton onClick={() => history(-1)} />
        </div>
      </div>

      <div className="d-flex flex-column gap-4 mt-4 width-65 ">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Product title<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <InputField placeholder={"Enter Product title"} />
        </div>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Product title (Arabic)<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <InputField placeholder={"Enter Product title"} />
        </div>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Country<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <Dropdown />
        </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              City<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <Dropdown />
        </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Complete Address<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
            <InputField placeholder={"Address Line #1"} />
            <InputField placeholder={"Address Line #1"} />
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
            <InputField placeholder={"Land Mark"} />
            <InputField placeholder={"Zip Code"} />
          </div>
        </div>

        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">Logo/ Client Image</span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <FileUpload />
          </div>
        </div>
      </div>
      <div className="d-flex my-3">
        <Button onClick={() => submit()} text="Create" type="primary" />
      </div>
    </div>
  );
};

export default CreateClient;
