import React, { useState, useEffect } from "react";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import ModalComp from "../Models/ModalComp";
import RedeemModel from "../Models/RedeemModel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SelectStore = (props) => {
  const { t } = useTranslation();
  const { details, giftcardDetails } = props

  const [fields, setFields] = useState({
    redemptionAmount: "",
  });



  const [error, setError] = useState({
    redemptionAmount: "",
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeHandler = (e) => {
    const value = e.target.value;
    let regex = /^\d{0,5}(\.\d{0,2})?$/;

    if (giftcardDetails?.balance_obj?.balance_amount >= value) {
      setError({ redemptionAmount: "" });
    }
    else {
      setError({ redemptionAmount: t("Please enter redemption amount less than balance amount") });
    }
    if (regex.test(e.target.value)) {
      setFields({ ...fields, [e.target.name]: value });
    } else {
      setError({ redemptionAmount: t("Please enter only in number") });
    }
  };



  const validate = () => {
    if (!fields.redemptionAmount) {
      setError({ redemptionAmount: t("Please enter redemption amount") });
      return false;
    } else if (error.redemptionAmount) {
      return false
    }

    return true;
  };

  const handleShow = () => {
    let val = validate();
    if (val) {
      setShowModal(true);
    }
    else {
      setShowModal(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 gap-3 w-100">
      <div className="d-flex flex-column justify-content-between gap-2">

        <div className="d-flex flex-column gap-3 mt-4 ">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Organization")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Selected organization displayed"}
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="giftCardNumber"
                value={details?.organization?.partner_name}
                disabled={true}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Store")}
              </span>
              <InfoTooltip
                title={"Selected store displayed"}
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="giftCardNumber"
                value={details?.selectedStore?.store_name}
                disabled={true}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Giftcard number")}
              </span>
              <InfoTooltip
                title={"Selected giftcard number"}
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="giftCardNumber"
                onChange={(e) => changeHandler(e)}
                value={details?.giftcardNumber}
                disabled={true}
              />
              {!fields.giftCardNumber && error.giftCardNumber ? (
                <label className="text-error">{error.giftCardNumber}</label>
              ) : null}
            </div>
          </div>

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Redemption amount")}<span className="text-danger">*</span>
              </span>
              <InfoTooltip title={"Note: This gift card currency is: USD"} />
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="redemptionAmount"
                onChange={(e) => changeHandler(e)}
                value={fields.redemptionAmount}
              />
              {error.redemptionAmount ? (
                <label className="text-error">{error.redemptionAmount}</label>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex gap-2">
        <Button onClick={handleShow} text={t("Submit")} type="primary" />
      </div>
      {/* onClick={submit} */}
      <ModalComp
        size="md"
        showModal={showModal}
        handleClose={handleClose}
        component={
          <RedeemModel
            amount={fields.redemptionAmount}
            handleClose={handleClose}
          />
        }
      />
    </div>
  );
};

export default SelectStore;
