import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import { ReactComponent as FilterIcon } from "../../assests/svg/Filter_Icon.svg";
import { ReactComponent as RedDownArrow } from "../../assests/svg/Product/Red_DownArrow.svg";
import Button from "../../components/Button/Button";
import InfoTooltip from "../Tooltip/InfoTooltip";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupplierList } from "../../Redux/Supplier/supplierAction";
import Toasts from "../Toasts/Toasts";
import { fetchAllGiftCardLengths } from "../../Redux/GiftCard/giftcardAction";
import { fetchProductTypeList } from "../../Redux/ProductType/prodtypeAction";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import { fetchSupplierMappingList, getGiftcardSupplierMappingList } from "../../Redux/SupplierMapping/SupplierMappingAction";

const SelectSupplier = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const productType = [{ name: "giftcard" }, { name: "merchandise" }];

  const productsState = useSelector((state) => state?.productTypes?.products);
  const suppliersState = useSelector((state) => state?.suppliers?.supplier);
  const suppliersMappingDataState = useSelector((state) => state?.SupplierMappingData?.supplierMappingData?.result);


  const [suppliers, setSuppliers] = useState([]);
  const [visible, setVisible] = useState(2);
  const [fields, setFields] = useState({
    productType:"",
    giftcard: "",
  });
  const [error, setError] = useState({
    giftcard: "",
  });
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSupplierList());
    dispatch(fetchAllGiftCardLengths());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 10);
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...suppliersState];

    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (item.id === name) {
        arr[i].isChecked = checked;
      }
    });
    setSuppliers(arr);
  };
const validate=()=>{
  if(!fields.giftcard){
    setError({giftcard:"Please select giftcard/product to map"})
    return false
  }
  return true
}
  const goToMappingVariants = () => {
    let val=validate();
    if(val){
    let arr = [...suppliers];
    let selectedSupplier = [];
    // eslint-disable-next-line array-callback-return
    arr.map((item) => {
      if (item.isChecked === true) {
        selectedSupplier.push(item);
      }
    });
    // console.log(JSON.stringify(selectedSupplier))
    // if (selectedSupplier.length > 0) {
      
    //   if (fields.productType.name === "Giftcards") {
    //     toggleShowToast();
    //     return;
    //   } else {
    //     history("/supplier-mapping-variants", {
    //       state: {
    //         selectedSupplier: selectedSupplier,
    //         productDetails: suppliersMappingDataState
    //       },
    //     });
    //   }
    // }
    if(fields.productType.name==="merchandise"){
      if(selectedSupplier.length > 0){
        history("/supplier-mapping-variants", {
                 state: {
                   selectedSupplier: selectedSupplier,
                   productDetails: suppliersMappingDataState
                 },
               });
      }else{
        toggleShowToast();
             return;
      }
    }else{
      if(selectedSupplier.length > 0){
        history("/supplier-mapping-to-giftcard", {
                 state: {
                   selectedSupplier: selectedSupplier,
                   productDetails: suppliersMappingDataState
                 },
               });
      }else{
        toggleShowToast();
             return;
      }
    }

  }
  };

  const handleChangeProductCategory = (e) => {
    setFields({ ...fields, productType: e });
    dispatch(fetchProductTypeList(e.name));
  };

  const handleChangeProduct = (e) => {
    setFields({ ...fields, giftcard: e });
   
    if(fields.productType.name==="merchandise"){
      dispatch(fetchSupplierMappingList(e.id))
    }else{
      dispatch(getGiftcardSupplierMappingList(e.id))
    }
   
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-4 px-4 width-65">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">Product</span>
          </div>
        </div>

        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">Product Category</span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <div>
              <Select
                placeholder="Select product category"
                noOptionsMessage={() => "Not found"}
                getOptionLabel={(productType) => productType.name}
                options={productType}
                styles={CUSTOM_DROPDOWN_STYLE}
                value={fields.productType}
                onChange={handleChangeProductCategory}
                isClearable
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">Mapping for Product</span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            
              <div>
                <Select
                  placeholder="Select mapping for product"
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(productsState) =>
                    productsState.product_title[0].title
                  }
                  options={productsState}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.giftcard}
                  onChange={handleChangeProduct}
                  isClearable
                />
              </div>
          
            {!fields.giftcard && error.giftcard ? (
              <label className="text-error">{error.giftcard}</label>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-2 w-100">
        <div className="d-flex flex-column  bg-white">
          <div className="d-flex flex-row w-100  align-items-center">
            <div className="p-2 flex-grow-1">
              <div className="d-flex gap-3 my-2 mx-3 align-items-center">
                <Tag />
                <span className="font-20 font-bold">Select Supplier</span>
                <span className="font-20 font-bold">
                  <div className="Inputwithicon">
                    <div id="search">
                      <Searchicon />
                    </div>
                    <input
                      type="text"
                      className="input-custom w-100"
                      name="search"
                      placeholder="Search by name,email or client"
                    />
                  </div>
                </span>
              </div>
            </div>
            <div className="p-2">
              <Button type="secondary" text="All" />
            </div>
            <div className="p-2">
              <div className="dropdown">
                <button
                  className="btn  dropdown-toggle font-14 font-bold text-muted"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Client
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <ul className="dropdown-item" to={""}>
                      Client 1
                    </ul>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={""}>
                      Client 2
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={""}>
                      Client 3
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="p-2">
              <span className="my-2">
                <FilterIcon className="cursor" />
              </span>
            </div>
          </div>
          <div className="row px-5 py-2">
            <table responsive="xl">
              <tbody>
                <tr className="card-body gap-3 border-bottom py-5">
                  <td>
               
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    Name
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    Email
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    Sold cards
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    Total sale
                  </td>
                  <td className="card-body font-13 font-bold text-muted">
                    Total products
                  </td>
                </tr>
                {suppliersState &&
                  suppliersState.slice(0, visible).map((item) => (
                    <tr
                      className="card-body gap-3 border-bottom "
                      key={item.id}
                    >
                      {/*onClick={() => goToMappingVariants()}*/}
                      <td>
                        <input
                        className="form-check-input cursor"
                          type="checkbox"
                          name={item.id}
                          id="checkboxNoLabel"
                          onChange={handleCheck}
                        />
                      </td>
                      <td className="card-body gap-1">
                        <div className="d-flex flex-column justify-content-start ">
                          <span className="font-15 font-boldest text-dark ">
                            {item.partner_name}
                          </span>
                          <span className="font-12 font-bolder text-muted">
                            {item.company_name}
                          </span>
                        </div>
                      </td>
                      <td className="card-body gap-1">
                        <div className="d-flex flex-row justify-content-between  gap-3">
                          <span className="fs-light  text-muted ">
                            {item.email}
                          </span>
                        </div>
                      </td>
                      <td className="card-body gap-1">
                        <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                          10
                        </span>
                      </td>
                      <td className="card-body gap-1">
                        <div className="d-flex flex-row justify-contents-between align-items-center">
                          <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                            400
                          </span>
                          <span className="font-13 font-bolder text-danger bg-opacity-25 rounded-3 px-1">
                            <span>
                              <RedDownArrow className="m-1" />
                            </span>
                            15
                          </span>
                        </div>
                      </td>
                      <td className="card-body gap-1">
                        <span className="font-15 font-bold text-dark px-1">
                          25
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex flex-row  justify-content-center align-item-center gap-3">
            <div className="d-flex">
              {visible < suppliersState.length && (
                <Button
                  type="rounded"
                  text="Load more"
                  className="rounded-6 my-4 mt-6 "
                  onClick={() => loadMore()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex mb-5">
        <Button
          type="primary"
          text={
            fields?.productType?.name === "Giftcards"
              ? "Save & Exit"
              : "Continue"
          }
          className="rounded-6 "
          onClick={() => goToMappingVariants()}
        />
      </div>
      {/* <div>{JSON.stringify(fields.productType.name)}</div> */}
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x mb-3 w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Please select supplier to map."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default SelectSupplier;
