import React from 'react';
import MyntraImg from "../../assests/img/ImageGift.png";
import { ReactComponent as GreenUpArrow } from "../../assests/svg/Product/Green_UpArrow.svg";
import { ReactComponent as BlueIcon } from "../../assests/svg/Product/Blue_Icon.svg";

const  ProdTable =()=> {

    const products=[{
        id:0,Status:"In stock",Price:"$10",Sales:"$3,200",SalePercent:"55.8%",Totalsold:"48k",Type:'Manual'
      }];

  return (
    <div className="card-body d-flex flex-column justify-content-between bg-white shadow-sm rounded-3 p-2 mb-2 gap-3">

    <table responsive="xl">
      <tbody>
        {products.map((item) => (
          <tr key={item.id}>
            <td>
              <div className="d-flex flex-row justify-content-start align-items-center py-3">
                <span className="mx-3 rounded-3">
                  <img
                    src={MyntraImg}
                    alt="not found"
                    className="img-fluid width-75"
                  />
                </span>
                <div className="d-flex flex-column justify-content-start ">
                  <span className="font-16 font-boldest text-dark ">
                    Myntra Gift card
                  </span>
                  <span className="font-13 font-bold text-muted">
                    by Myntra Fashion
                  </span>
                </div>
              </div>
            </td>
            <td>
              <span className="font-15 font-bold text-success bg-04 px-1 py-1 rounded-3">
                {item.Status}
              </span>
            </td>
            <td>
              <span className="font-16 font-bold text-dark">
                {item.Price}
              </span>
            </td>
            <td>
              <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 py-1">
                {item.Sales}
              </span>
              <span className="font-13 font-bold text-success bg-opacity-25 rounded-3">
                <span>
                  <GreenUpArrow className="m-1" />
                </span>
                {item.SalePercent}
              </span>
            </td>
            <td>
              <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 gap-4 px-1 py-1">
                {item.Totalsold}
              </span>
              <span className="mx-1">
                <BlueIcon />
              </span>
            </td>
            <td>
              <span className="font-15 font-bold text-dark bg-danger bg-opacity-25 rounded-3 px-1 py-1">
                {item.Type}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>

  );
}

export default ProdTable;
