import React, { useState } from "react";
import { PRODUCT_TYPE } from "../../utils/ConstantData";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";

const PartnerDetailProductTableHeader = (props) => {
  const { t } = useTranslation();
  const {
    getSearchValue,
    productFilterValueGethandler,
    disabled,
    goToEditMappingScreen,
  } = props;
  const [dropdownObj, setDropdownObj] = useState({
    filter: { id: 1, name: "Giftcard" },
  });
  // const [searchTerm, setSearchTerm] = useState("");
  const searchHandler = (e) => {
    // setSearchTerm(e.target.value);
    getSearchValue(e.target.value);
  };

  const productFilterHandle = (item, type) => {
    setDropdownObj({ ...dropdownObj, [type]: item });
    productFilterValueGethandler(item);
    // if (item?.name === "GiftCard") {
    //   dispatch(fetchSupplierMapProducts(selectedSupplier?.id));
    // } else {
    //   dispatch(fetchSupplierMapMerchandise(selectedSupplier?.id));
    // }
  };

  return (
    <div className="d-flex flex-row justify-content-between py-2 align-items-center">
      <div className="d-flex flex-row ">
        <div className="d-flex gap-3 align-items-center">
          <Tag />
          <span className="font-20 font-bold">{t("Mapped products")}</span>
          <SearcBar
            type="text"
            name="search"
            lang={props?.lang}
            placeholder={t("Search Product")}
            onChange={searchHandler}
          />
        </div>
      </div>

      <div className="d-flex flex-row gap-2 mx-2">
        <div className="btn-group w-10">
          <div
            className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center"
            data-bs-toggle="dropdown"
            disabled={disabled}
          >
            <span className="text-muted ">
              {dropdownObj.filter ? dropdownObj.filter.name : "Filter product"}
            </span>
          </div>
          <ul className={`dropdown-menu rounded-card-7 dropdown-menu-end ${props?.lang === 'ar' ? 'dropdown-menu-ar' : ''}`}>
            {PRODUCT_TYPE &&
              PRODUCT_TYPE.map((item, i) => (
                <li key={i} onClick={() => productFilterHandle(item, "filter")}>
                  <div className="dropdown-item cursor">{item.name}</div>
                </li>
              ))}
          </ul>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            type="primary"
            text={t("Edit mapping")}
            onClick={() => goToEditMappingScreen()}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerDetailProductTableHeader;
