import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";
import {
    PUT_CREATESTORE_REQUEST,
    PUT_CREATESTORE_SUCCESS,
    PUT_CREATESTORE_FAILURE,
    GET_STORELIST_REQUEST,
    GET_STORELIST_SUCCESS,
    GET_STORELIST_FAILURE,
    PATCH_STORELIST_REQUEST,
    PATCH_STORELIST_SUCCESS,
    PATCH_STORELIST_FAILURE,
    EPOS_STORE_DATA,
    GET_STORELIST_MAP_TO_ORG_REQUEST,
    GET_STORELIST_MAP_TO_ORG_SUCCESS,
    GET_STORELIST_MAP_TO_ORG_FAILURE,
    SET_SELETED_STORE_OR_ORG_DATA,
    GET_STORELIST_MAP_TO_ORG_DELETE,
    GET_STORE_DETAILS_FAILURE,
    GET_STORE_DETAILS_SUCCESS,
    GET_STORE_DETAILS_REQUEST
} from "./createStoreType";


export const setSelectedStoreOrOrgData = (data) => {
    // console.log("data ", data);
    return {
        type: SET_SELETED_STORE_OR_ORG_DATA,
        payload: data
    }
}

export const getStoreListRequest = () => {
    return {
        type: GET_STORELIST_REQUEST,
    }
}

export const getStoreListSuccess = (data) => {
    return {
        type: GET_STORELIST_SUCCESS,
        payload: data

    }
}
export const getStoreListFailure = () => {
    return {
        type: GET_STORELIST_FAILURE,
    }
}

export const getStoreList = (skip, limit, createStore) => {
    return (dispatch) => {
        dispatch(getStoreListRequest());
        let config = {
            method: 'get',
            url: `${baseUrl}/v1/stores`,
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        };
        axios(config)
            .then(response => {
                let data = {
                    data: [...createStore, ...response.data.result],
                    moredata: response.data.next_iteration
                }
                dispatch(getStoreListSuccess(data))
            })
            .catch(error => {
                //  const errorMsg = error
                dispatch(getStoreListFailure("Something went wrong!! Please try again!!"))
            })
    }
}

// Get store list mapped to organization 
export const getStoreListMapToOrgRequest = () => {
    return {
        type: GET_STORELIST_MAP_TO_ORG_REQUEST,
    }
}

export const getStoreListMapToOrgSuccess = (data) => {
    return {
        type: GET_STORELIST_MAP_TO_ORG_SUCCESS,
        payload: data
    }
}

export const getStoreListMapToOrgFailure = (error) => {
    return {
        type: GET_STORELIST_MAP_TO_ORG_FAILURE,
        payload: error
    }
}

export const getStoreListMapToOrgDelete = () => {
    return {
        type: GET_STORELIST_MAP_TO_ORG_DELETE,
        payload: []
    }
}

// Get store list mapped to organization 
export const getStoreListMapToOrganization = (orgId) => {
    return (dispatch) => {
        dispatch(getStoreListMapToOrgRequest())
        let config = {
            method: 'get',
            url: `${baseUrl}/v1/stores/organization/${orgId}/stores`,
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        };
        axios(config)
            .then(response => {
                dispatch(getStoreListMapToOrgSuccess(response.data.result))
            })
            .catch(error => {
                //  const errorMsg = error
                dispatch(getStoreListMapToOrgFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const eposStoreDataSave = (data) => {
    // console.log("action ", data)
    return {
        type: EPOS_STORE_DATA,
        payload: data
    }
}

// put CreateStore
export const putCreateStoreRequest = () => {
    return {
        type: PUT_CREATESTORE_REQUEST,
    };
};

export const putCreateStoreSuccess = (obj) => {
    return {
        type: PUT_CREATESTORE_SUCCESS,
        payload: obj
    };
};

export const putCreateStoreFailure = (obj) => {
    return {
        type: PUT_CREATESTORE_FAILURE,
        payload: obj
    };
};

export const putCreateStore = (obj, goToSuccessfull) => {

    return (dispatch) => {
        dispatch(putCreateStoreRequest());


        let tType = obj?.transactionType.redemption_method;
        let createStoreObj = {
            store_name: obj?.storeName,
            store_id: obj?.storeId,
            organization_id: obj?.organization,
            transaction_type: tType.toLowerCase(),
            country_id: obj.country.id,
            state_id: obj.state.id,
            // state_name:obj?.state.name,
            area: obj?.area,
            store_address: obj?.storeFullAdd,
            zip_code: obj?.zipcode,
            email_id: obj.emailid,
            store_phone_code: obj.phonecode,
            store_phone_no: obj.phonenumber,
            notify_flag: true,
            active_flag: true
        }
        let config = {
            method: 'post',
            url: `${baseUrl}/v1/stores`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
                'Content-Type': 'application/json'

            },
            data: createStoreObj
        };
        axios(config)
            .then(response => {
                if (response.status === 200) {
                    dispatch(putCreateStoreSuccess(response.data.result));
                    goToSuccessfull();
                    dispatch(getStoreList());
                }
            })
            .catch(error => {
                //  const errorMsg = error
                dispatch(putCreateStoreFailure("Something went wrong!! Please try again!!"))
            })
    }
}

// PATCH STORE 
export const patchCreateStoreRequest = () => {
    return {
        type: PATCH_STORELIST_REQUEST,
    };
};

export const patchCreateStoreSuccess = (obj) => {
    return {
        type: PATCH_STORELIST_SUCCESS,
        payload: obj
    };
};

export const patchCreateStoreFailure = (obj) => {
    return {
        type: PATCH_STORELIST_FAILURE,
        payload: obj
    };
};

export const patchCreateStore = (obj, goToSuccessfull) => {

    return (dispatch) => {
        dispatch(patchCreateStoreRequest());

        let payload = {
            "store_name": obj.storeName,
            "store_id": obj.storeId,
            // "organisation_id": obj.,
            "transaction_type": obj?.transactionType?.redemption_method.toLowerCase(),
            "country_id": obj.country.id,
            "city_name": obj.city,
            "store_address": obj.storeFullAdd,
            "zip_code": obj.zipcode,
            "email_id": obj.emailid,
            "store_phone_no": obj.phonenumber,
            "notify_flag": obj.notificationFlag,
            "active_flag": obj.active_flag,
            "deleted_flag": false,
            "id": obj.id
        }
        // console.log(JSON.stringify(payload))
        let config = {
            method: 'patch',
            url: `${baseUrl}/v1/stores/${obj?.id}`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
                'Content-Type': 'application/json'

            },
            data: payload
        };
        axios(config)
            .then(response => {
                if (response.status === 200) {
                    dispatch(patchCreateStoreSuccess(response.data));
                    dispatch(getStoreList());
                    // goToSuccessfull();
                }
            })
            .catch(error => {
                //  const errorMsg = error
                dispatch(patchCreateStoreFailure("Something went wrong!! Please try again!!"))
            })
    }
}


export const getStoreDetailsRequest = (data) => {
    return {
        type: GET_STORE_DETAILS_REQUEST,
        payload: data
    }
}

export const getStoreDetailsSuccess = (error) => {
    return {
        type: GET_STORE_DETAILS_SUCCESS,
        payload: error
    }
}

export const getStoreDetailsFailure = () => {
    return {
        type: GET_STORE_DETAILS_FAILURE,
        payload: []
    }
}

export const getStoreDetailsById = (id) => {
    return (dispatch) => {
        dispatch(getStoreDetailsRequest())
        let config = {
            method: 'get',
            url: `${baseUrl}/v1/stores/${id}`,
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        };
        axios(config)
            .then(response => {
                dispatch(getStoreDetailsSuccess(response.data.result))
            })
            .catch(error => {
                //  const errorMsg = error
                dispatch(getStoreDetailsFailure("Something went wrong!! Please try again!!"))
            })
    }
}