/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as Tagpurple } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Righttick } from "../../assests/svg/Righttick.svg";
import product_default from "../../assests/img/Product-default.png";
import { useSelector } from "react-redux";
import PulseLoader from "../Loader/PulseLoader";
import { useTranslation } from "react-i18next";
import ProductImageSlider from "../ImageSlider/ProductImageSlider";
import VarientProductImageSlider from "../ImageSlider/VarientProductImageSlider";
import SliderLeftAndRightButtons from "../ImageSlider/SliderLeftAndRightButtons";

const ModelForMerchandiseSummery = (props) => {
  const { t } = useTranslation();
  const { merchandiseSummery } = props;

  const loading = useSelector(
    (state) => state?.merchandiseCatAttriData?.loading
  );
  const [tabs, setTabs] = useState(0);
  const [selectedVarienImageArray, setSelectedVarienImageArray] = useState([]);
  // const [permissions, setpermissions] = useState();
  const [usertype, setusertype] = useState();

  useEffect(() => {
    //  setpermissions(JSON.parse(localStorage.getItem("permissions")));
    setusertype(JSON.parse(localStorage.getItem("user")));
  }, [localStorage.getItem("permissions")]);

  // default varient image array set
  useEffect(() => {
    merchandiseSummery[0] &&
      merchandiseSummery[0]?.variants?.length > 0 &&
      merchandiseSummery[0]?.variants !== undefined &&
      merchandiseSummery[0]?.variants[0]?.images &&
      merchandiseSummery[0]?.variants[0]?.images &&
      setSelectedVarienImageArray(merchandiseSummery[0]?.variants[0]?.images);
  }, [merchandiseSummery]);

  // on varient button click that varient image array set
  const varientHandler = (varientName, index) => {
    setTabs(index);
    merchandiseSummery[0]?.product_variants_flag === true &&
      merchandiseSummery[0]?.variants &&
      merchandiseSummery[0]?.variants.map((item) => {
        if (varientName.join() === item?.variant_values.join()) {
          setSelectedVarienImageArray(item?.images);
        }
      });
  };

  return loading === true ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white p-4 w-100 mb-3">
      <div className="d-flex flex-row justify-content-end align-items-center">
        <div className="d-flex justify-content-end">
          <span
            onClick={props.handleClose}
            className="rounded-pill  bg-light p-2"
          >
            <span className="font-20 rounded-circle cursor p-2">X</span>
          </span>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex">
          <div className="d-flex flex-column">
            <div className="font-32 font-bold align-item-center m-4">
              {merchandiseSummery && merchandiseSummery[0]?.default_title}
            </div>
          </div>
        </div>
      </div>
      <div className="merchandiseModelImgContainer d-flex flex-row justify-content-between align-items-center">
        {merchandiseSummery &&
        merchandiseSummery[0]?.product_variants_flag === true ? (
          <>
            {merchandiseSummery[0]?.variants &&
            merchandiseSummery[0]?.variants[0]?.images &&
            merchandiseSummery[0]?.variants[0]?.images.length > 1 ? (
              <>
                <VarientProductImageSlider
                  varientProductActiveImage={
                    merchandiseSummery[0] &&
                    merchandiseSummery[0]?.variants?.length > 0 &&
                    merchandiseSummery[0]?.variants !== undefined &&
                    merchandiseSummery[0]?.variants[0]?.images &&
                    merchandiseSummery[0]?.variants[0].images[0]?.file_url &&
                    merchandiseSummery[0]?.variants[0].images[0]?.file_url
                  }
                  selectedVarienImageArray={
                    selectedVarienImageArray && selectedVarienImageArray
                  }
                  defualtProduct={product_default}
                />
                <SliderLeftAndRightButtons />
              </>
            ) : (
              <div className={"carousel-item active"}>
                <img
                  src={
                    merchandiseSummery[0] &&
                    merchandiseSummery[0]?.variants?.length > 0 &&
                    merchandiseSummery[0]?.variants !== undefined &&
                    merchandiseSummery[0]?.variants[0]?.images &&
                    merchandiseSummery[0]?.variants[0].images[0]?.file_url
                      ? merchandiseSummery[0]?.variants[0].images[0]?.file_url
                      : product_default
                  }
                  //   key={product_default}
                  alt="not found"
                  id="product-preview"
                />
              </div>
            )}
          </>
        ) : (
          <>
            {merchandiseSummery[0]?.images.length > 1 ? (
              <>
                <ProductImageSlider
                  productActiveImage={
                    merchandiseSummery &&
                    merchandiseSummery[0]?.images &&
                    merchandiseSummery[0]?.images[0]?.file_url &&
                    merchandiseSummery[0]?.images[0]?.file_url
                  }
                  productImagesArray={
                    merchandiseSummery &&
                    merchandiseSummery[0]?.images &&
                    merchandiseSummery[0]?.images
                  }
                  defualtProduct={product_default}
                />
                <SliderLeftAndRightButtons />
              </>
            ) : (
              <div className={"carousel-item active"}>
                <img
                  src={
                    merchandiseSummery &&
                    merchandiseSummery[0]?.images &&
                    merchandiseSummery[0]?.images[0]?.file_url
                      ? merchandiseSummery[0]?.images[0]?.file_url
                      : product_default
                  }
                  //   key={product_default}
                  alt="not found"
                  id="product-preview"
                />
              </div>
            )}
          </>
        )}

        {/* product image slider */}
      </div>

      {merchandiseSummery && merchandiseSummery[0]?.product_variants_flag && (
        <div className="d-flex p-4">
          <div className="col">
            <div className="d-flex  flex-column w-100 my-2">
              <div>
                <div className="font-20 font-bold neutral-grey-07">
                  {merchandiseSummery &&
                    merchandiseSummery[0]?.variants &&
                    merchandiseSummery[0]?.variants[0]?.variant_keys.join("|")}
                </div>
                <div className="d-flex flex-wrap flex-row my-2 gap-2 mx-2 cursor">
                  {merchandiseSummery[0]?.product_variants_flag === true &&
                    merchandiseSummery[0]?.variants &&
                    merchandiseSummery[0]?.variants.map((item, index) => (
                      <div className="d-flex gap-3">
                        <span
                          className={`p-2 border rounded ${
                            tabs !== index
                              ? "text-muted"
                              : "bg-primary text-white"
                          }`}
                          name={item?.variant_values}
                          onClick={() => {
                            varientHandler(item?.variant_values, index);
                          }}
                        >
                          {item?.variant_values.join("|")}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="d-flex flex-row p-4">
        <div className="d-flex flex-wrap flex-column gap-2 col-8">
          <div className="d-flex gap-3">
            <GreenTag />
            <span className="font-20 font-bold neutral-grey-07">
              {t("Details")}
            </span>
          </div>

          <div className="d-flex flex-column gap-2 mt-4">
            <span className="font-17 font-bolder text-muted">
              {t("Product id")} -
              <span className="font-15 font-bolder text-dark mx-2">
                <span className="font-17 font-bolder">
                  {merchandiseSummery && merchandiseSummery[0]?.id}
                </span>
              </span>
            </span>
            <span className="font-17 font-bolder text-muted">
              {t("Country currency")} -
              <span className="font-15 font-bolder text-dark bg-04 rounded-3 px-1 fit-content m-4 p-2">
                {merchandiseSummery[0]?.country?.currency +
                  " - " +
                  merchandiseSummery[0]?.country?.currency_symbol}
              </span>
            </span>
            {merchandiseSummery && merchandiseSummery[0]?.availability ? (
              <span className="font-17 font-bolder text-muted">
                {t("Availibility")} -
                <span className="font-15 font-bolder text-dark mx-2">
                  {merchandiseSummery &&
                  merchandiseSummery[0].availability === "in_stock"
                    ? "In Stock"
                    : "Out Of Stock"}
                </span>
              </span>
            ) : null}

            {merchandiseSummery && merchandiseSummery[0]?.sales_tax ? (
              <span className="font-17 font-bolder text-muted">
                {t("Sale tax")} -
                <span className="font-15 font-bolder text-dark mx-2">
                  <span className="font-17 font-bolder">
                    {merchandiseSummery && merchandiseSummery[0]?.sales_tax}
                  </span>
                </span>
              </span>
            ) : null}
            {merchandiseSummery && merchandiseSummery[0]?.shipping_cost ? (
              <span className="font-17 font-bolder text-muted">
                {t("Shipping cost")} -
                <span className="font-15 font-bolder text-dark ">
                  {merchandiseSummery && merchandiseSummery[0]?.shipping_cost}
                </span>
              </span>
            ) : null}

            {console.log("merchandiseSummery ", merchandiseSummery)}
            {merchandiseSummery && merchandiseSummery[0]?.warranty ? (
              <span className="font-17 font-bolder text-muted">
                {t("Warranty")} -
                <span className="font-15 font-bolder text-dark mx-2">
                  {merchandiseSummery[0]?.warranty +
                    " " +
                    merchandiseSummery[0]?.warranty_type}
                </span>
              </span>
            ) : null}

            {merchandiseSummery && merchandiseSummery[0]?.brand_id ? (
              <span className="font-17 font-bolder text-muted">
                {t("Brand Id")} -
                <span className="font-15 font-bolder text-dark mx-2">
                  {merchandiseSummery[0]?.brand_id}
                </span>
              </span>
            ) : null}

            {merchandiseSummery && merchandiseSummery[0]?.brand_category_id ? (
              <span className="font-17 font-bolder text-muted">
                {t("Brand Category Id")} -
                <span className="font-15 font-bolder text-dark mx-2">
                  {merchandiseSummery[0]?.brand_category_id}
                </span>
              </span>
            ) : null}

            {merchandiseSummery && merchandiseSummery[0]?.vat ? (
              <span className="font-17 font-bolder text-muted">
                {t("Vat")} -
                <span className="font-15 font-bolder text-dark mx-2">
                {merchandiseSummery[0]?.vat +
                    " " +
                    merchandiseSummery[0]?.vat_type}
                </span>
              </span>
            ) : null}

            {merchandiseSummery && merchandiseSummery[0]?.weight ? (
              <span className="font-17 font-bolder text-muted">
                {t("Weight")} -
                <span className="font-15 font-bolder text-dark mx-2">
                {merchandiseSummery[0]?.weight +
                    " " +
                    merchandiseSummery[0]?.weight_unit}
                </span>
              </span>
            ) : null}
          </div>
        </div>

        <div className="col-4">
          <div className="p-2 flex-fill bd-highlight">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex gap-3">
                <Tagpurple />
                <span className="font-20 font-bold neutral-grey-07">
                  {t("Features")}
                </span>
              </div>
            </div>

            <div className="d-flex flex-column mt-3 w-100  neutral-grey-05 font-15">
              <ul className="list-group list-group-flush ">
                <li className="list-group-item d-flex flex-column">
                  {merchandiseSummery &&
                    merchandiseSummery[0]?.key_benefits?.map((item, index) => {
                      if (item) {
                        return (
                          <span className="m-2" key={index}>
                            {" "}
                            <Righttick /> {item}
                          </span>
                        );
                      } else return null;
                    })}
                </li>
                <li className="list-group-item d-flex flex-column">
                  {!merchandiseSummery[0]?.key_benefits?.length && (
                    <span className="font-15 font-bold text-error">
                      {t("No Key features found")}
                    </span>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {usertype?.user_type === "internal" ? (
        <div className="d-flex flex-column p-4 w-100 mb-3">
          <div className="d-flex flex-row gap-3">
            <GreenTag />
            <span className="font-20 font-bold neutral-grey-07">
              {t("Mapped supplier")}
            </span>
          </div>

          {merchandiseSummery &&
          merchandiseSummery[0]?.suppliers &&
          merchandiseSummery[0]?.suppliers.length > 0 ? (
            <div className="d-flex flex-column  shadow-sm rounded-3 py-3 mt-3 w-100">
              <div className="d-flex flex-column  bg-white">
                <div className="row px-5 py-2">
                  <table responsive="table-responsive">
                    <tbody>
                      <tr className="card-body gap-2 border-bottom py-3">
                        <td className="card-body gap-1 font-13 font-bold text-muted">
                          {t("Supplier Id")}
                        </td>
                        <td className="card-body gap-1 font-13 font-bold text-muted">
                          {t("Supplier name")}
                        </td>
                      </tr>
                      {merchandiseSummery &&
                        merchandiseSummery[0]?.suppliers &&
                        merchandiseSummery[0]?.suppliers.map((item) => (
                          <tr
                            className="card-body gap-2 border-bottom hover-row"
                            key={item?.supplier_id}
                          >
                            <td className="card-body gap-1">
                              <div className="d-flex flex-column justify-content-start ">
                                <span className="font-15 font-boldest text-dark ">
                                  {item?.supplier_id}
                                </span>
                              </div>
                            </td>
                            <td className="card-body gap-1">
                              <div className="d-flex flex-row justify-content-between  gap-3">
                                <span className="font-15 font-boldest text-dark ">
                                  {item?.name}
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-center align-items-center pt-4">
              <span className="font-20 font-bold text-error" aria-colspan={2}>
                {t("No Supplier found")}
              </span>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ModelForMerchandiseSummery;
