import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import InputField from "../InputField/InputField";
import InputGroupTxt from "../InputField/InputGroupTxt";
import { useTranslation } from "react-i18next";

const GiftCardDetails = (props) => {
  const { t } = useTranslation();
  const giftcardDetails = props?.giftcardDetails;


  const [fields, setFields] = useState({
    giftCardNumber: "",
    redemptionAmount: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftcardDetails]);

  const changeHandler = (e) => {
    const value = e.target.value;
    setFields({ ...fields, [e.target.name]: value });
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">{t("Gift Card details")}</span>
        </div>
        {/* <div>
                    <Expand className="cursor" onClick={handleShow} />
                </div> */}
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center gap-3"></div>
      <div className="d-flex flex-column gap-4 mt-4 ">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">{t("Gift card issued to")}</span>
          </div>
          <div className="d-flex flex-column gap-1">
            <InputField
              name="giftCardNumber"
              // onChange={(e) => changeHandler(e)}
              // value={eposStoreData?.selectedStore?.email_id}
              disabled={true}
            />
          </div>
        </div>

        <div className="d-flex flex-column gap-4  ">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Original Amount")}</span>
            </div>
            <div className="d-flex flex-column gap-1">
              <InputGroupTxt
                title={
                  giftcardDetails?.giftcard?.country?.currency_symbol
                }
                name="redemptionAmount"
                lang={props?.lang}
                //  onChange={(e) => changeHandler(e)}
                value={giftcardDetails?.balance_obj?.original_amount}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-4  ">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Balance Amount")}</span>
            </div>
            <div className="d-flex flex-column gap-1">
              <InputGroupTxt
                title={
                  giftcardDetails?.giftcard?.country?.currency_symbol
                }
                name="redemptionAmount"
                lang={props?.lang}
                value={giftcardDetails?.balance_obj?.balance_amount}
                disabled={true}
              // onChange={(e) => changeHandler(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCardDetails;
