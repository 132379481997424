export const GET_ALL_LANGUAGE_REQUEST = "GET_ALL_LANGUAGE_REQUEST"
export const GET_ALL_LANGUAGE_SUCCESS = "GET_ALL_LANGUAGE_SUCCESS"
export const GET_ALL_LANGUAGE_FAILURE = "GET_ALL_LANGUAGE_FAILURE"

export const POST_LANGUAGE_REQUEST = "POST_LANGUAGE_REQUEST"
export const POST_LANGUAGE_SUCCESS = "POST_LANGUAGE_SUCCESS"
export const POST_LANGUAGE_FAILURE = "POST_LANGUAGE_FAILURE"

export const PATCH_LANGUAGE_REQUEST = "PATCH_LANGUAGE_REQUEST"
export const PATCH_LANGUAGE_SUCCESS = "PATCH_LANGUAGE_SUCCESS"
export const PATCH_LANGUAGE_FAILURE = "PATCH_LANGUAGE_FAILURE"

export const CLEAR_LANGUAGE_LIST = "CLEAR_LANGUAGE_LIST"