import React from "react";

const Filter = (props) => {
    const { onChange, title, data, lang } = props;


    return (
        <div>
            <div className="btn-group">
                <div className="btn border border-2 rounded-card-7 dropdown-toggle" data-bs-toggle="dropdown">
                    {title ? title : "Select"}
                </div>
                <ul className={`dropdown-menu rounded-card-7 dropdown-menu-end ${lang === 'ar' ? 'dropdown-menu-ar' : ''}`}>
                    {data && data.map((item, i) => (
                        <li key={i} onClick={() => onChange(item)}>
                            <div className="dropdown-item">
                                <span className="font-15">{item?.name}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Filter;
