/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoader from "../../Loader/PulseLoader";
import PulseLoaders from "react-spinners/PulseLoader";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  DataSortingHandler,
  strFirstLetterCap,
} from "../../../utils/constantFunction";
import { fetchGiftCardSummeryById } from "../../../Redux/GiftCard/giftcardAction";
import Default_giftcard from "../../../assests/img/giftcard-default.png";
import { ReactComponent as CopyButton } from "../../../assests/svg/copyLink.svg";
import { useEffect } from "react";
import ModalComp from "../../Models/ModalComp";
import ModelForGiftCardSummery from "../../Models/ModelForGiftCardSummery";
import SmallToast from "../../Toast/SmallToast";
import { getClientGcDetailList } from "../../../Redux/Distributions/ClientGiftcardDistributions/ClientGiftcardDistributionAction";
import { useTranslation } from "react-i18next";
import { SORT_TYPE } from "../../../utils/ConstantData";

const ClientDetailGiftcardTable = (props) => {
  const { t } = useTranslation();
  const {
    giftcardTitle,
    giftcardId,
    countryCurrency,
    inventoryType,
    discount,
    partnerId,
    searchValue,
  } = props;
  const dispatch = useDispatch();
  const clientmappedGiftcardList = useSelector(
    (state) => state?.clientMapGcList?.clientMappedGcList
  );

  const loading = useSelector((state) => state?.clientMapGcList?.loading);

  // for model Giftcard
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );

  const handleGiftcardClose = () => setShowGiftcardModal(false);
  const [showGiftcardModal, setShowGiftcardModal] = useState(false);
  const [id, setId] = useState("");
  const hasmore = useSelector(
    (state) => state?.clientMapGcList?.next_iteration
  );
  // set infinite loader
  const [skip, setskip] = useState(
    clientmappedGiftcardList.length === 0
      ? -10
      : clientmappedGiftcardList.length - 10
  );
  const [limit, setlimit] = useState(10);

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {}, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (clientmappedGiftcardList.length === 0) {
      fetchdata(skip);
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(
        getClientGcDetailList(
          partnerId,
          skip + limit,
          limit,
          clientmappedGiftcardList
        )
      );
      setskip(skip + limit);
    }, 100);
  };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 5);
    return val;
  };

  // copy to clipboard
  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const showGiftcardModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowGiftcardModal(true);
  };

  return loading === true && clientmappedGiftcardList?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <InfiniteScroll
      dataLength={clientmappedGiftcardList?.length || 0}
      next={fetchdata}
      hasMore={hasmore}
      loader={
        <div className="d-flex justify-content-center">
          {" "}
          <PulseLoaders color={"#594190"} loading={loading} size={10} />{" "}
        </div>
      }
    >
      <div className="card-body d-flex flex-column justify-content-between align-item-center gap-5 px-2">
        <table responsive="xl">
          <tbody>
            <tr className="border-bottom">
              <td className="text-muted" colSpan={3}>
                <div className="d-flex flex-row justify-content-start">
                  <span
                    className={`mx-3 cursor ${
                      tableHeaderName === "default_title"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "default_title",
                        setTableHeaderName,
                        clientmappedGiftcardList,
                        order,
                        setOrder
                      )
                    }
                  >
                    {giftcardTitle}
                  </span>
                </div>
              </td>
              <td
                className={`text-muted cursor ${
                  tableHeaderName === "id" ? "ascending" : "descending"
                }`}
                onClick={() =>
                  sortingHandler(
                    "id",
                    setTableHeaderName,
                    clientmappedGiftcardList,
                    order,
                    setOrder
                  )
                }
              >
                {giftcardId}
              </td>
              <td
                className={`text-muted cursor ${
                  tableHeaderName === "currency" ? "ascending" : "descending"
                }`}
                onClick={() =>
                  sortingHandler(
                    "currency",
                    setTableHeaderName,
                    clientmappedGiftcardList,
                    order,
                    setOrder
                  )
                }
              >
                {countryCurrency}
              </td>
              <td
                className={`text-muted cursor ${
                  tableHeaderName === "inventory_type_name"
                    ? "ascending"
                    : "descending"
                }`}
                onClick={() =>
                  sortingHandler(
                    "inventory_type_name",
                    setTableHeaderName,
                    clientmappedGiftcardList,
                    order,
                    setOrder
                  )
                }
              >
                {inventoryType}
              </td>
              <td
                className={`text-muted cursor ${
                  tableHeaderName === "client_discount"
                    ? "ascending"
                    : "descending"
                }`}
                onClick={() =>
                  sortingHandler(
                    "client_discount",
                    setTableHeaderName,
                    clientmappedGiftcardList,
                    order,
                    setOrder
                  )
                }
              >
                {discount}
              </td>
              {/* <td className="text-muted"> Edit</td> */}
            </tr>
            {clientmappedGiftcardList &&
              clientmappedGiftcardList
                .filter((item) => {
                  if (searchValue === "") {
                    return item;
                  } else if (
                    item?.giftcard?.default_title
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item, index) => (
                  <tr className="card-body gap-3 border-bottom " key={index}>
                    <td colSpan={3}>
                      <div className="d-flex flex-row justify-content-start align-items-center py-2">
                        <div className="rounded-3 px-1">
                          <img
                            src={
                              item?.giftcard?.images &&
                              item?.giftcard?.images[0]
                                ? item?.giftcard?.images[0]?.file_url
                                : Default_giftcard
                            }
                            alt="not found"
                            // className="img-fluid"
                            // width="50px"
                            // height="50px"
                            id="product-thumbnail"
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-start mx-2">
                          <span
                            className="font-13 font-boldest text-dark d-inline-block text-truncate cursor"
                            title={item?.giftcard?.default_title}
                            style={{ maxWidth: "150px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                            onClick={() =>
                              showGiftcardModel(item?.giftcard?.id)
                            }
                          >
                            {item?.giftcard?.default_title}
                          </span>
                          <span className="font-10 font-boldest text-muted">
                            {strFirstLetterCap(
                              item?.giftcard?.giftcard_value_type
                            )}{" "}
                          </span>
                          <span
                            className="font-10 font-bolder text-dark bg-04 rounded-3 px-1 d-inline-block text-truncate cursor"
                            style={{
                              width: "fit-Content",
                              maxWidth: "120px",
                            }}
                            title={
                              item?.giftcard?.giftcard_value_type ===
                              "denominations"
                                ? item?.giftcard?.denominations &&
                                  item?.giftcard?.denominations.map(
                                    (deno) => deno.denomination_value
                                  )
                                : item?.giftcard?.min_amount +
                                  "-" +
                                  item?.giftcard?.max_amount
                            }
                          >
                            {item?.giftcard?.giftcard_value_type ===
                            "denominations"
                              ? item?.giftcard?.denominations &&
                                item?.giftcard?.denominations.map(
                                  (deno) => deno.denomination_value + ","
                                )
                              : item?.giftcard?.min_amount +
                                "-" +
                                item?.giftcard?.max_amount}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex justify-content-start align-items-center">
                        <span
                          className="font-14 font-boldest text-dark visible mt-4"
                          title={item?.giftcard?.id}
                        >
                          {trimIdHandler(item?.giftcard?.id)}
                          <span>...</span>
                        </span>

                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Copy to clipboard"
                          className="cursor "
                          value={item?.giftcard?.id}
                          onClick={() => copyToClipboard(item?.giftcard?.id)}
                        >
                          <CopyToClipboard text={item?.giftcard?.id}>
                            <CopyButton />
                          </CopyToClipboard>
                        </span>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex flex-row justify-content-center">
                        <span className="font-12 font-bold text-success bg-04 px-1 rounded-3">
                          {`${item?.giftcard?.country?.currency}-(${item?.giftcard?.country?.currency_symbol})`}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span className="font-12 font-bold">
                        {item?.giftcard?.inventory_type_name}
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-row justify-content-center">
                        <span className="font-12 font-boldest text-dark">
                          {item?.client_discount}%
                        </span>
                      </div>
                    </td>
                    {/* <td>
              <span className=" px-1  ">
                <EditIcon/>
              </span>
            </td> */}
                  </tr>
                ))}
          </tbody>
        </table>
        {!clientmappedGiftcardList.length && (
          <div className="d-flex flex-row justify-content-center py-3">
            <span className="font-20 font-bold text-error">
              {t("Products Not Found")}
            </span>
          </div>
        )}

        <ModalComp
          size="lg"
          showModal={showGiftcardModal}
          // handleClose={handleClose}
          component={
            <ModelForGiftCardSummery
              handleClose={handleGiftcardClose}
              giftcardSummery={giftcardSummery}
            />
          }
        />
        <div>
          <SmallToast data={id ? id : ""} message={t("Copied")} />
        </div>
      </div>
    </InfiniteScroll>
  );
};

export default ClientDetailGiftcardTable;
