import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";
import {
  FETCH_ALL_SUPPLIER_REQUEST,
  FETCH_ALL_SUPPLIER_SUCCESS,
  FETCH_ALL_SUPPLIER_FAILURE,
  FETCH_SUPPLIER_MAPPED_GC_REQUEST,
  FETCH_SUPPLIER_MAPPED_GC_SUCCESS,
  FETCH_SUPPLIER_MAPPED_GC_FAILURE,
  FETCH_SUPPLIER_MAPPED_MER_REQUEST,
  FETCH_SUPPLIER_MAPPED_MER_SUCCESS,
  FETCH_SUPPLIER_MAPPED_MER_FAILURE,
  SUPPLIER_FILTER_DATA,
  GET_SEL_SUP_ALL_TRANS_REQUEST,
  GET_SEL_SUP_ALL_TRANS_SUCCESS,
  GET_SEL_SUP_ALL_TRANS_FAILURE,
  GET_SEL_SUP_ALL_TRANS_DELETE,
  CLEAR_SUPPLIER_LIST,
  CLEAR_SUPPLIER_MAPP_PRODUCTS_LIST
} from "./supplierTypes";

export const fetchAllSupplierRequest = () => {
  return {
    type: FETCH_ALL_SUPPLIER_REQUEST,
  };
};

export const fetchAllSupplierSuccess = (data) => {
  return {
    type: FETCH_ALL_SUPPLIER_SUCCESS,
    payload: data,
  };
};
export const fetchAllSupplierFailure = () => {
  return {
    type: FETCH_ALL_SUPPLIER_FAILURE,
  };
};

export const fetchSupplierList = (skip, limit, suppliersState) => {
  if (!suppliersState) suppliersState = [];
  return (dispatch) => {
    dispatch(fetchAllSupplierRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/partner_category/supplier/partner`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        let data = {
          data: [...suppliersState, ...response.data.result],
          // moredata: response.data.next_iteration
          moredata: false,
        };
        dispatch(fetchAllSupplierSuccess(data));
      })
      .catch((error) => {
        //   const errorMsg = error
        dispatch(
          fetchAllSupplierFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const setSupplierFilterData = (data) => {
  return {
    type: SUPPLIER_FILTER_DATA,
    payload: data,
  };
};

// fetch supplier mapped products
export const fetchSupplierMapProdRequest = () => {
  return {
    type: FETCH_SUPPLIER_MAPPED_GC_REQUEST,
  };
};

export const fetchSupplierMapProdSuccess = (data) => {
  return {
    type: FETCH_SUPPLIER_MAPPED_GC_SUCCESS,
    payload: data,
  };
};
export const fetchSupplierMapProdFailure = (error) => {
  return {
    type: FETCH_SUPPLIER_MAPPED_GC_FAILURE,
    payload: error,
  };
};

export const fetchSupplierMapProducts = (
  id,
  skip,
  limit,
  supplierMappedGiftCard
) => {
  if (!supplierMappedGiftCard) supplierMappedGiftCard = [];
  return (dispatch) => {
    dispatch(fetchSupplierMapProdRequest());
    let url =
      skip !== undefined && limit !== undefined
        ? `${baseUrl}/v1/giftcards/supplier/${id}/mappedProducts?is_detailed=true&skip=${skip}&limit=${limit}`
        : `${baseUrl}/v1/giftcards/supplier/${id}/mappedProducts?is_detailed=true`;

    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        let data = {
          data: [...supplierMappedGiftCard, ...response.data.result],
          moredata: response.data.next_iteration,
          // moredata: false
        };
        dispatch(fetchSupplierMapProdSuccess(data));
      })
      .catch((error) => {
        //   const errorMsg = error
        dispatch(
          fetchSupplierMapProdFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};
//   fetch supplier mapped merchandise

export const fetchSupplierMapMerchandiseRequest = () => {
  return {
    type: FETCH_SUPPLIER_MAPPED_MER_REQUEST,
  };
};

export const fetchSupplierMapMerchandiseSuccess = (data) => {
  return {
    type: FETCH_SUPPLIER_MAPPED_MER_SUCCESS,
    payload: data,
  };
};
export const fetchSupplierMapMerchandiseFailure = (error) => {
  return {
    type: FETCH_SUPPLIER_MAPPED_MER_FAILURE,
    payload: error,
  };
};
export const fetchSupplierMapMerchandise = (
  id,
  skip,
  limit,
  supplierMapMerchandise
) => {
  return (dispatch) => {
    dispatch(fetchSupplierMapMerchandiseRequest());
    let url =
      skip !== undefined && limit !== undefined
        ? `${baseUrl}/v1/merchandise/supplier/${id}/mappedProducts?is_detailed=true&skip=${skip}&limit=${limit}`
        : `${baseUrl}/v1/merchandise/supplier/${id}/mappedProducts?is_detailed=true&skip=0&limit=1000000`;
    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        let data = {
          data: [...supplierMapMerchandise, ...response.data.result],
          moredata: response.data.next_iteration,
          // moredata: false
        };
        dispatch(fetchSupplierMapMerchandiseSuccess(data));
      })
      .catch((error) => {
        //   const errorMsg = error
        dispatch(
          fetchSupplierMapMerchandiseFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};
// Get All Transaction Of selected Supplier
export const getAllTransationSelSupRequest = () => {
  return {
    type: GET_SEL_SUP_ALL_TRANS_REQUEST,
  };
};

export const getAllTransationSelSupSuccess = (data) => {
  return {
    type: GET_SEL_SUP_ALL_TRANS_SUCCESS,
    payload: data,
  };
};
export const getAllTransationSelSupFailure = (error) => {
  return {
    type: GET_SEL_SUP_ALL_TRANS_FAILURE,
    payload: error,
  };
};

export const getAllTransationSelSupDelete = () => {
  return {
    type: GET_SEL_SUP_ALL_TRANS_DELETE,
    payload: [],
  };
};

export const getAllTransationSelSup = (
  skip,
  limit,
  selSupplierTransaction,
  supplier_id
) => {
  if (!selSupplierTransaction) selSupplierTransaction = [];
  return (dispatch) => {
    dispatch(getAllTransationSelSupRequest());
    let url =
      skip !== undefined && limit !== undefined
        ? `${baseUrl}/v1/product_transaction?skip=${skip}&limit=${limit}&supplier_id=${supplier_id}`
        : `${baseUrl}/v1/product_transaction?supplier_id=${supplier_id}`;
    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        console.log("response ", response.data)
        let data = {
          data: [...selSupplierTransaction, ...response.data.result],
          moredata: response.data.next_iteration,
          // moredata: false
        };
        dispatch(getAllTransationSelSupSuccess(data));
      })
      .catch((error) => {
        //   const errorMsg = error
        dispatch(
          getAllTransationSelSupFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

export const clearSupplierList = () => {
  return {
    type: CLEAR_SUPPLIER_LIST,
  };
};

export const clearSupplierMappProductsList = () => {
  return {
    type: CLEAR_SUPPLIER_MAPP_PRODUCTS_LIST,
  };
};