import React, { useEffect, useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import applePhones from "../../assests/img/apple_colored.png";

const ClientMappingVariants = () => {

  

  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState({
    ch1: false,
    ch2: false,
    ch3: false
  });
  /* #### TOGGLES checK STATE BASED ON inputName #### */
  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };

  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4  w-100 gap-3">
        <div className="card-body d-flex justify-content-between flex-row">
          <div className="d-flex gap-3 my-2">
            <Tag />
            <span className="font-20 font-bold">Mapping variants</span>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between  align-items-center font-bold">
          <div>
            <span className="mx-3">
              <input type="checkbox" onChange={(event) => selectAll(event.target.checked)}
                checked={checkedAll} />
            </span>
            <span className="font-20 font-bold">Name</span>
          </div>
          <div className="d-flex px-5"></div>
          <div className="font-20 font-bold px-5">Hadaya product ID</div>
          <div className="font-20 font-bold px-5">Supplier</div>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center bg-light py-2">
          <div className="d-flex flex-row align-items-center mx-20">
            <span className="mx-3">
              <input type="checkbox" name="ch1"
                onChange={() => toggleCheck("ch1")}
                checked={checked["ch1"]} />
            </span>
            <span>
              {/* <ApplePhones /> */}
              <img
              src={applePhones}
              alt="not found"
              className="img-fluid "
            />
            </span>
            <div className="d-flex flex-column align-items-start  mx-3">
              <div className="font-bold font-15">I phone 13</div>
              <div className="font-12 text-muted">Red | 128 GB</div>
            </div>
          </div>
          <div className="text-muted">ehid_iphonesexc#$35325</div>
          <div className="d-flex justify-content-start align-items-center">
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle text-muted"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Apple Fahey
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <span className="dropdown-item"  >
                    Apple 13
                  </span>
                </li>
                <li>
                  <span className="dropdown-item" >
                    Apple 13
                  </span>
                </li>
                <li>
                  <span className="dropdown-item">
                    Apple 13
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center bg-light py-2">
          <div className="d-flex flex-row align-items-center mx-20">
            <span className="mx-3">
              <input type="checkbox" name="ch2"
                onChange={() => toggleCheck("ch2")}
                checked={checked["ch2"]} />
            </span>
            <span>
              {/* <ApplePhones /> */}
              <img
              src={applePhones}
              alt="not found"
              className="img-fluid"
            />
            </span>
            <div className="d-flex flex-column align-items-start  mx-3">
              {" "}
              <div className="font-bold font-15">I phone 12</div>
              <div className="font-12 text-muted">Red | 256 GB</div>
            </div>
          </div>
          <div className="text-muted">ehid_iphonesexc#$35325</div>
          <div className="d-flex justify-content-start align-items-center">
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle text-muted"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Apple Fahey
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <span className="dropdown-item" >
                    Apple 13
                  </span>
                </li>
                <li>
                  <span className="dropdown-item" >
                    Apple 13
                  </span>
                </li>
                <li>
                  <span className="dropdown-item"  >
                    Apple 13
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center bg-light py-2">
          <div className="d-flex flex-row align-items-center mx-20">
            <span className="mx-3">
              <input type="checkbox" name="ch3"
                onChange={() => toggleCheck("ch3")}
                checked={checked["ch3"]} />
            </span>
            <span>
              {/* <ApplePhones /> */}
              <img
              src={applePhones}
              alt="not found"
              className="img-fluid"
            />
            </span>
            <div className="d-flex flex-column align-items-start  mx-3">
              {" "}
              <div className="font-bold font-15">I phone 11</div>
              <div className="font-12 text-muted">Red | 64 GB</div>
            </div>
          </div>
          <div className="text-muted">ehid_iphonesexc#$35325</div>
          <div className="d-flex justify-content-start align-items-center">
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle text-muted"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Apple Fahey
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <span className="dropdown-item"  >
                    Apple 13
                  </span>
                </li>
                <li>
                  <span className="dropdown-item" >
                    Apple 13
                  </span>
                </li>
                <li>
                  <span className="dropdown-item" >
                    Apple 13
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientMappingVariants;
