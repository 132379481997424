/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import CardDetails from "../../../components/EPOS/CardDetails";
import Transactions from "../../../components/EPOS/Transactions";
import { getGiftcardDetails } from "../../../Redux/Epos/eposAction";
import { useTranslation } from "react-i18next";

const GiftCardBalance = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const giftCardNumber = location?.state?.giftCardNumber
  // const giftcardDetails = useSelector((state) => state.eposState?.giftcardDetails);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getGiftcardDetails(giftCardNumber));

  }, [giftCardNumber]);




  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Gift card Balance")}</span>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between gap-2">
        <div className="d-flex">
          <CardDetails />
        </div>

        <div className="d-flex">
          <Transactions lang={props?.lang} giftCardNumber={giftCardNumber} />
        </div>
      </div>
    </div>
  );
};

export default GiftCardBalance;
