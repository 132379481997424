import React, { useEffect } from "react";
import "./ProductImageSlider.css";
import { MdOutlineExpandLess } from "react-icons/md";
import { IconContext } from "react-icons";
import SliderLeftAndRightButtons from "./SliderLeftAndRightButtons";

const ProductImageSlider = (props) => {
  const { productImagesArray, productActiveImage, defualtProduct } = props;

  // console.log("productImagesArray ", productImagesArray);
  useEffect(() => {}, [productImagesArray]);

  useEffect(() => {}, [productActiveImage]);

  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        {productImagesArray && productImagesArray.length > 1 ? (
          productImagesArray.map((item, index) => {
            return (
              <>
                <div
                  className={
                    index === 0 ? "carousel-item active" : "carousel-item"
                  }
                >
                  <img
                    src={item?.file_url}
                    key={index}
                    alt="not found"
                    id="product-preview"
                  /> 
                </div>
              </>
            );
          })
        ) : (
          <div className={"carousel-item active"}>
            <img
              src={productActiveImage ? productActiveImage : defualtProduct}
              //   key={product_default}
              alt="not found"
              id="product-preview"
            />
          </div>
        )}
      </div>
      {/* <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <IconContext.Provider
                    value={{ color: "black", size: "50px" }}
                  >
                    <div>
                      <MdOutlineExpandLess
                        style={{ transform: "rotate(-90deg)" }}
                      />
                    </div>
                  </IconContext.Provider>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <IconContext.Provider
                    value={{ color: "black", size: "50px" }}
                  >
                    <div>
                      <MdOutlineExpandLess
                        style={{ transform: "rotate(90deg)" }}
                      />
                    </div>
                  </IconContext.Provider>
                  <span className="visually-hidden">Next</span>
                </button> */}
    </div>
  );
};

export default ProductImageSlider;
