/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import Default_giftcard from "../../assests/img/giftcard-default.png";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { strFirstLetterCap } from "../../utils/constantFunction";
import PulseLoader from "../Loader/PulseLoader";
import ModalComp from "../../components/Models/ModalComp";
import ModelForGiftCardSummery from "../../components/Models/ModelForGiftCardSummery";
import { fetchGiftCardSummeryById } from "../../Redux/GiftCard/giftcardAction";
import { getOrganizationMappedGiftcardDetailsList } from "../../Redux/Organizations/organizationAction";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import SmallToast from "../Toast/SmallToast";
import PulseLoaders from "react-spinners/PulseLoader";
import InfiniteScroll from "react-infinite-scroll-component";

const OrganizationMappedGiftcard = (props) => {
  const { selectedOrganization } = props;
  const dispatch = useDispatch();

  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );
  const organizationmappedGiftcardList = useSelector(
    (state) => state?.organizations?.organizationMappedProdList
  );
  const Loading = useSelector(
    (state) => state?.organizations?.loading
  );
    // set infinite loader
    const [skip, setskip] = useState(
      organizationmappedGiftcardList?.length === 0 ? -2 : organizationmappedGiftcardList?.length - 2
    );
    const [limit, setlimit] = useState(2);
  const hasmore = useSelector((state) => state?.organizations?.next_iteration);

  const [id, setId] = useState("");
  const [showGiftcardModal, setShowGiftcardModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [button, setButton] = useState("GiftCard");
  const [searchTerm, setSearchTerm] = useState("");


  const handleGiftcardClose = () => setShowGiftcardModal(false);
  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleChangeProductType = (type) => {
    if (type === "Merchandise") {
      setButton("Merchandise");
      // dispatch(fetchClientMappedMerchandiseDetails());
    } else if (type === "GiftCard") {
      setButton("GiftCard");
      //  dispatch(getClientGcDetailList());
      dispatch(getOrganizationMappedGiftcardDetailsList(selectedOrganization?.id));
    }
  };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 5);
    return val;
  };

  // copy to clipboard
  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const toggleShowToast = () => setShowToast(!showToast);

  const showGiftcardModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowGiftcardModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (organizationmappedGiftcardList?.length === 0) {
      fetchdata(skip);
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(getOrganizationMappedGiftcardDetailsList(selectedOrganization?.id,skip + limit, limit, organizationmappedGiftcardList));
      setskip(skip + limit);
    }, 100);
  };


  // useEffect(() => {
  //   dispatch(getOrganizationMappedGiftcardDetailsList(selectedOrganization?.id));
  // }, [selectedOrganization?.id]);

  return Loading === true && organizationmappedGiftcardList?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
        <div className="d-flex flex-row justify-content-between  align-items-center">
          <div className="d-flex flex-row ">
            <div className="d-flex gap-3 align-items-center">
              <Tag />
              <span className="font-20 font-bold">Mapped Giftcards</span>
              <div className="Inputwithicon">
                <div id="search">
                  <Searchicon />
                </div>
                <input
                  type="text"
                  className="input-custom w-100"
                  name="search"
                  placeholder="Search Product"
                  onChange={searchHandler}
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row py-2 align-items-center">


            <div className="d-flex flex-row gap-1 align-items-center">
              {/* <div className="px-1">
                <Button
                  type={button === "GiftCard" ? "primary" : "rounded"}
                  text="Giftcards"
                  onClick={() => handleChangeProductType("GiftCard")}
                />
              </div> */}
              {/* <Button
            type={button === "Merchandise" ? "primary" : "rounded"}
            text="Merchandise"
            onClick={() => handleChangeProductType("Merchandise")}
          />*/}
            </div>

            {/* <div className="p-1">
            <Button
              type="primary"
              text="Download CSV"
              className="rounded-6 my-4 mt-6 fw-bolder"
              onClick={() => toggleShowToast()}
            />
          </div> */}
          </div>
        </div>

        {/* {organizationmappedGiftcardList && button === "GiftCard" ? ( */}
        <InfiniteScroll
          dataLength={organizationmappedGiftcardList?.length || 0}
          next={fetchdata}
          hasMore={hasmore}
          loader={
            <div className="d-flex justify-content-center">
              {" "}
              <PulseLoaders
                color={"#594190"}
                loading={Loading}
                size={10}
              />{" "}
            </div>
          }
        >
          <div className="card-body d-flex flex-column justify-content-between align-item-center gap-5 px-2">
            <table responsive="xl">
              <tbody>
                <tr className="border-bottom">
                  <td className="text-muted" colSpan={3}>
                    <div className="d-flex flex-row justify-content-start">
                      <span className="mx-3">Giftcard image/title</span>
                    </div>
                  </td>
                  <td className="text-muted">Giftcard id</td>
                  <td className="text-muted">Country & currency</td>
                  <td className="text-muted">Inventory type</td>
                  <td className="text-muted">Organization discount</td>
                  {/* <td className="text-muted"> Edit</td> */}
                </tr>
                {organizationmappedGiftcardList &&
                  organizationmappedGiftcardList
                    ?.filter((item) => {
                      if (searchTerm === "") {
                        return item;
                      } else if (
                        item?.giftcard?.default_title
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <tr className="card-body gap-3 border-bottom" key={index}>
                        <td colSpan={3}>
                          <div className="d-flex flex-row justify-content-start align-items-center py-2">
                            <div className="rounded-3 px-1">
                              <img
                                src={
                                  item?.giftcard?.images &&
                                    item?.giftcard?.images[0]
                                    ? item?.giftcard?.images[0]?.file_url
                                    : Default_giftcard
                                }
                                alt="not found"
                                className="img-fluid"
                                width="50px"
                                height="50px"
                              />
                            </div>
                            <div className="d-flex flex-column justify-content-start mx-2">
                              <span
                                className="font-12 font-boldest text-dark d-inline-block text-truncate cursor"
                                title={item?.giftcard?.default_title}
                                style={{ maxWidth: "150px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                onClick={() =>
                                  showGiftcardModel(item?.giftcard?.id)
                                }
                              >
                                {item?.giftcard?.default_title}
                              </span>
                              <span className="font-10 font-boldest text-muted">
                                {strFirstLetterCap(
                                  item?.giftcard?.giftcard_value_type
                                )}{" "}
                              </span>
                              <span
                                className="font-10 font-bolder text-dark bg-04 rounded-3 px-1 d-inline-block text-truncate cursor"
                                style={{
                                  width: "fit-Content",
                                  maxWidth: "70px",
                                }}
                                title={
                                  item?.giftcard?.giftcard_value_type ===
                                    "denominations"
                                    ? item?.giftcard?.denominations &&
                                    item?.giftcard?.denominations.map(
                                      (deno) => deno.denomination_value
                                    )
                                    : item?.giftcard?.min_amount +
                                    "-" +
                                    item?.giftcard?.max_amount
                                }
                              >
                                {item?.giftcard?.giftcard_value_type ===
                                  "denominations"
                                  ? item?.giftcard?.denominations &&
                                  item?.giftcard?.denominations.map(
                                    (deno) => deno.denomination_value + ","
                                  )
                                  : item?.giftcard?.min_amount +
                                  "-" +
                                  item?.giftcard?.max_amount}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                            <div className="d-flex justify-content-start align-items-center">
                              <span
                                className="font-14 font-boldest text-dark visible mt-4"
                                title={item?.giftcard?.id}
                              >
                                {trimIdHandler(item?.giftcard?.id)}
                                <span>...</span>
                              </span>

                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Copy to clipboard"
                                className="cursor "
                                value={item?.giftcard?.id}
                                onClick={() =>
                                  copyToClipboard(item?.giftcard?.id)
                                }
                              >
                                <CopyToClipboard text={item?.giftcard?.id}>
                                  <CopyButton />
                                </CopyToClipboard>
                              </span>
                            </div>
                          </td>

                        <td>
                          <div className="d-flex flex-row justify-content-center">
                            <span className="font-12 font-bold text-success bg-04 px-1 rounded-3">
                              {`${item?.giftcard?.country?.currency}-(${item?.giftcard?.country?.currency_symbol})`}
                            </span>
                          </div>
                        </td>
                        <td>
                          <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1">
                            {item?.giftcard?.registered_by_id &&
                              item?.giftcard?.registered_by_id ===
                              "powered_by_ehadaya"
                              ? "Powered by eHadaya"
                              : item?.giftcard?.registered_by_id ===
                                "manual_inventory"
                                ? "Manual Inventory"
                                : item?.giftcard?.registered_by_id ===
                                  "3rd_party_suppliers"
                                  ? "3rd party suppliers"
                                  : item?.giftcard?.registered_by_id}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex flex-row justify-content-center">
                            <span className="font-12 font-boldest text-dark">
                              {item?.discount}%
                            </span>
                          </div>
                        </td>
                        {/* <td>
              <span className=" px-1  ">
                <EditIcon/>
              </span>
            </td> */}
                      </tr>
                    ))}
              </tbody>
            </table>
            {!organizationmappedGiftcardList?.length && (
              <div className="d-flex flex-row justify-content-center py-3">
                <span className="font-20 font-bold text-error">
                  Products Not Found
                </span>
              </div>
            )}
          </div>
          </InfiniteScroll>
        {/* ) : null} */}

        <ModalComp
          size="lg"
          showModal={showGiftcardModal}
          // handleClose={handleClose}
          component={
            <ModelForGiftCardSummery
              handleClose={handleGiftcardClose}
              giftcardSummery={giftcardSummery}
            />
          }
        />
      <div>
        <SmallToast data={id ? id : ""} message={"Copied"} />
      </div>
      </div>
  );
};

export default OrganizationMappedGiftcard;
