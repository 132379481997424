import React from "react";
import { ReactComponent as Orangelite } from "../../assests/svg/Tag/orange_lite_tag.svg";
import { ReactComponent as BlueTag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import EmailImg from "../../assests/img/Email_design .png";
import { ReactComponent as RightTick } from "../../assests/svg/Righttick.svg";
import EhadayaLogo from "../../assests/img/ehadaya_logo.png";
import HroundLogo from "../../assests/img/H_logo.png";
import Barcode from "../../assests/img/lines-barcode.jpg";
import QRCode from "../../assests/img/qrcode.png";
import Button from "../Button/Button";
import giftcard_default from "../../assests/img/giftcard-default.png";
import { useDispatch } from "react-redux";
import { postEmailTemplate } from "../../Redux/CustomEmail/customeEmailAction";
import { useTranslation } from "react-i18next";

const EmailPreviewModel = (props) => {
  const { handleClose, emailData } = props;
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const submitHandleEmailData = () => {
    let contactInfo = [];
    emailData.contactInfo.map((item) => {
      contactInfo.push(item)
    })
    let obj = {
      success_title: emailData?.sucessTitle,
      success_heading: emailData?.successHeading,
      success_message: emailData?.successMessage,
      scanner_code_type: emailData?.qrCodeFlag ? emailData?.qrCodeFlag : emailData?.barcodeFlag,
      contact_info: emailData?.contactInfoFlag === false ? "" : emailData?.contactInfo,
      ehadaya_logo_flag: emailData?.broughtYouBuyFlag,
      partner_id: emailData?.partner_id,
      partner_logo: emailData?.brandLogoFlag === false ? "" : emailData?.brandLogo[0]?.preview
    }
    dispatch(postEmailTemplate(obj));
    handleClose()
  }
  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-3 px-2 bg-light callout">
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 gap-3 py-4 px-4 callout">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-column justify-content-end">
            <span onClick={handleClose} className="rounded-pill  bg-light p-2">
              <span className="font-20 rounded-circle cursor p-2">X</span>
            </span>
          </div>
          <div className="d-flex  gap-3 mb-1 align-items-center">
            <Button
              type={"primary"}
              text={t("Send email")}
              className="rounded-6 my-4 mt-6 fw-bold"
              onClick={() => submitHandleEmailData()}
            />
          </div>
        </div>
        <div className="email-container p-4 mt-4 mb-4">
          <div>
            {emailData && emailData.brandLogoFlag === true ? (
              <div className="d-flex flex-row justify-content-center mb-3">
                <img
                  // src={emailData && emailData.brandLogo[0].preview?emailData.brandLogo[0].preview:""}
                  src={emailData.brandLogo[0].preview}
                  alt="logo not found"
                  className="img-fluid"
                  width={'500px'}
                />
              </div>
            ) : null}

            <div className="d-flex flex-row align-item-center justify-content-start">
              <div className="flex-column mx-2">
                <img
                  src="https://i.postimg.cc/1zMVkTM5/blue-tag.png"
                  alt="logo not found"
                // className="img-fluid"
                // width={'500px'}
                />
              </div>
              <div className="flex-column">
                <h3 className="heading">{emailData.sucessTitle}</h3>
              </div>
            </div>
            <div className="">
              <div className="col-cong">
                <div className="mt-4 heading">{emailData.successHeading}</div>
                <div className="typo">
                  <p>{emailData?.successMessage}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="col-barcode"></div>

            <div className="">
              <div
                className="col-cong-2 d-flex align-items-center"
                style={{ marginTop: "40px" }}
              >
                <div className="d-flex flex-column align-items-center">
                  <div className="col-cong-2">
                    <div className="d-flex flex-row">
                      <img
                        src={giftcard_default}
                        alt="image not found"
                        className="responsive"
                        id="giftcardImage"
                      />
                    </div>
                  </div>
                  <div className="mt-4 heading">
                    <p>{t("card_currency")}</p>
                    <p>{t("card_denomination")}</p>
                  </div>
                </div>
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ marginLeft: "100px" }}
                >
                  <div>
                    <img
                      src={QRCode}
                      alt="image not found"
                      width="200px"
                      height="50px"
                      className="responsive"
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <h2 className="space" id="myData">
                      <div className="d-flex flex-column justify-content-center align-items-center gap-3 pt-1 callout">
                        <span className="text-dark font-15 align-items-center font-boldest">
                          C A R D N O 1 4 5 7 5 7 g W S F G
                        </span>
                      </div>
                    </h2>
                  </div>
                  <div className="expire-text">
                    <span className="mr-2"> {t("Expiry Date")}:{t("expiry date")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div className="row-terms-and-features">
            <div className="col-terms-and-features">
              <div className="mt-4">
                <div className="mx-2">
                  <div className="d-flex flex-row align-item-center">
                    <img
                      src="https://i.postimg.cc/9MJPB0sb/orange-lite-tag.png"
                      alt="logo not found"
                    />
                    <h3 className="mx-2 mt-2 heading">{t("Terms and conditions")}</h3>
                  </div>
                </div>

                <div className="mt-2">
                  <p className="typo ml-4">{t("terms_and_condition")}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="row-logo-contact">
              {emailData && emailData?.broughtYouBuyFlag === true ? (
                <div>
                  <span className="heading">{t("Brought to you by")}</span>
                  <div>
                    <img src="https://i.postimg.cc/3wQH3cym/ehadayalogo.png" />
                    <img src="https://i.postimg.cc/150dp34n/Group-2.png" />
                  </div>
                  <h4 className="copyRight">
                    {t("CopyRight eHadaya 2022")} - {t("All Rights Reserved")}
                  </h4>
                </div>
              ) : null}
              <div>
                <span className="heading">{t("Need help?")}</span>
                {emailData && emailData.contactInfoFlag === true ? (
                  <div
                    className={
                      emailData?.broughtYouBuyFlag === false
                        ? "d-flex flex-column justify-content-end  align-items-right gap-2"
                        : "typo"
                    }
                  >
                    <span>{emailData.contactInfo[0]}</span>
                    {emailData?.broughtYouBuyFlag === true && <br />}
                    <span>{emailData.contactInfo[1]}</span>
                    {emailData?.broughtYouBuyFlag === true && <br />}
                    <span>{emailData.contactInfo[2]}</span>
                    {emailData?.broughtYouBuyFlag === true && <br />}
                    <span>{emailData.contactInfo[3]}</span>
                  </div>
                ) : (
                  <div className="d-flex flex-row justify-content-center  align-items-center">
                    <span className="text-danger">
                      {t("No Contact Information found")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailPreviewModel;
