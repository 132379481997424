import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redeemGiftcard } from "../../Redux/Epos/eposAction";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";

function RedeemModel(props) {
  const { amount, handleClose } = props
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const giftcardDetails = useSelector((state) => state.eposState);

  const submit = () => {

    let payload = {
      "giftcard_number": giftcardDetails?.storeDetails?.giftcardNumber,
      "transaction_type": "sale",
      "transaction_amount": amount ? amount : 0,
      "partner_id": giftcardDetails?.storeDetails?.organization?.id,
      "partner_sub_type_id": giftcardDetails?.storeDetails?.selectedStore?.id,
    }
    dispatch(redeemGiftcard(payload, goToSuccess))
  }

  const goToSuccess = () => {
    history("/epos/redeem-success");
  };

  return (
    <div className="d-flex flex-column position-relative bg-white shadow-sm rounded-3 py-3 ">
      <div
        className="d-flex fixed-top position-absolute p-2 justify-content-end"
        aria-live="assertive"
        aria-atomic="true"
      >
        <button
          type="button"
          className="btn-close "
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </div>
      <div className="d-flex flex-column px-5 gap-3">
        <div className="d-flex flex-row  ">
          <span className="font-32 font-bold">
            {t("Are you sure you want to redeem this gift card?")}
          </span>
        </div>
        <div className="d-flex ">
          <span className="font-15 font-bold text-sm">
            {t("Amount")}:
            <span className="font-15 text-light-blue font-bold mx-2">{giftcardDetails?.giftcardDetails?.giftcard?.country?.currency_symbol}{amount}</span>
          </span>
        </div>
        <div className="d-flex">
          <Button type="primary" text={t("Redeem now")} onClick={() => submit()} />
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <span className="font-10 font-bold text-muted ">
            {t("Please note that once redeemed, the process cannot be reverted")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default RedeemModel;
