import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { convertToRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';

// import { EditorState, convertToRaw } from 'draft-js';

const TextEditor = (props) => {
  const { getDescription, code, editorState, lang, inputRef } = props;

  const onEditorStateChange = (e) => {
    getDescription(e, code);
  };

  return (
    <>
      <div className="border rounded-card-7 ">
        <Editor
          ref={inputRef ? inputRef : null}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName="wrapper-class"
          editorClassName="px-2 "
          toolbarClassName="toolbar-class"
          toolbar={{
            options: ["inline", "fontSize", "list", "link", "textAlign"],
          }}
          textAlignment={lang === "ar" ? "right" : ""}
        />
      </div>

      {/* <textarea
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            /> */}

      {/* <Editor
                    readOnly={true}
                    toolbar={{
                        options: [],
                    }}
                    editorState={EditorState.createWithContent(editorState.getCurrentContent())}
                /> */}
    </>
  );
};

export default TextEditor;
