import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { ReactComponent as Tag } from "../../assests/svg/Tag/orange_lite_tag.svg";
import { ReactComponent as OrangeTag } from "../../assests/svg/Tag/orange_tag.svg";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as Tagpurple } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Righttick } from "../../assests/svg/Righttick.svg";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import { putGiftCard } from "../../Redux/GiftCard/giftcardAction";
import Loader from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";


const GiftCardModel = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const giftcard = useSelector((state) => state?.giftcard);
  const giftcardDescription = useSelector((state) => state?.giftcard?.cardNameDesc?.description);
  const priceState = useSelector((state) => state.giftcard?.cardPrice);
  const [langView, setLangView] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    let lang = giftcardDescription[0]?.lang?.language;
    setLangView({ ...langView, lang });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToProductEditScreen = () => {
    history("/product-creation/name-description")
  }

  const goToproductSuccessfull = () => {
    history('/product-successfull');
  }

  const publish = () => {
    dispatch(putGiftCard(giftcard, goToproductSuccessfull));
  }

  const handleChangeValue = (lang) => {
    setLangView({ ...langView, lang });
    // setLangViewTerm({ ...langViewTerm, lang });
  };

  const descriptionFun = () => {
    const description = giftcard?.cardNameDesc?.description;

    return (
      <div>
        {description && description.map((item, i) =>
          <div key={i} >
            {langView?.lang === item.lang.language ?
              <div className="border-bottom pt-4">
                {item.value ?
                  <span className="font-15 font-bold text-muted">{t("Terms & Conditions in")} {item.lang.language}</span> : null}
                <Editor
                  readOnly={true}
                  toolbar={{
                    options: [],
                  }}
                  editorState={EditorState.createWithContent(item.value.getCurrentContent())}
                />
              </div> :
              <div >
                {langView?.lang === item.lang.language ?
                  <span className="font-15  font-bold text-muted pt-4">
                    {t("Terms & Conditions not avilable in")} {langView?.lang}
                  </span> : null}
              </div>}
          </div>
        )}
      </div>
    )
  }

  const termConditionFun = () => {
    const termCondition = giftcard?.cardTermCondition?.termCondition;
    return (
      <div>
        {termCondition && termCondition.map((item, i) =>
          <div key={i} >
            {/* {langView?.lang + item?.lang?.language} */}
            {langView?.lang === item?.lang?.language ?
              <div className="border-bottom pt-4">
                {item.value ?
                  <span className="font-15 font-bold text-muted">{t("Terms & Conditions in")} {item?.lang?.language}</span> : null}
                <Editor
                  readOnly={true}
                  toolbar={{
                    options: [],
                  }}
                  editorState={EditorState.createWithContent(item.value.getCurrentContent())}
                />
              </div>
              :
              <div >
                {/* {langView?.lang !== item?.lang?.language ?
                  <span className="font-15  font-bold text-muted pt-4">
                    Terms & Conditions not avilable in {langView?.lang}
                  </span> : null} */}
              </div>}
          </div>
        )}
      </div>
    )
  }

  return (
    giftcard.loading ?
      <div className="position-fixed top-50 start-50">
        <Loader loading={giftcard.loading} />
      </div>
      :
      <div className="d-flex flex-column bg-white py-3 px-4 w-100 mb-3">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex">
            <span>
              <Button text={t("Close")} type={"secondary"} onClick={props.handleClose} />
            </span>
          </div>
          <div className="d-flex">
            <div className="btn-group">
              <div
                className="btn border border-2 model-drop-width dropdown-toggle d-flex justify-content-between align-items-center py-2"
                data-bs-toggle="dropdown"
              >
                {langView?.lang ? langView?.lang : t("Select")}
              </div>
              <ul className="dropdown-menu rounded-card-7 dropdown-menu-start">
                {giftcardDescription &&
                  giftcardDescription.map((item, i) => (
                    <li key={i} onClick={() => handleChangeValue(item.lang.language)}>
                      <div className="dropdown-item cursor">{item.lang.language}</div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {!giftcard.loading &&
            <div className="d-flex gap-3">
              <span>
                <Button text={`${giftcard?.cardPrice?.amount ? '$' + giftcard?.cardPrice?.amount + ' |' : ''} Confirm & Publish`} type={"primary"} onClick={() => publish()} />
              </span>
            </div>}
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center pt-5">
          <div className="d-flex gap-3 col">
            <div className="d-flex flex-column ">
              <div className="font-32 font-bold">{giftcard?.cardNameDesc?.prodTitles[0].title}</div>
              {/* <div className="neutral-grey-04 font-20">
              Card description example
            </div>*/}
              {priceState?.rangedenomnationFlag && priceState?.minamount ?
                <div>
                  <span className="font-20 font-bolder">{t("Minimum amount")}: </span>
                  <span className="text-muted font-bolder">{priceState?.minamount}</span>
                </div> : null}

              {priceState?.rangedenomnationFlag && priceState?.maxamount ?
                <div>
                  <span className="font-20 font-bolder">{t("Max amount")}: </span>
                  <span className="text-muted font-bolder">{priceState?.maxamount}</span>
                </div> : null}

              {priceState?.denomnationFlag && priceState?.fixeddenomnation.length > 0 ?
                <div className="d-flex flex-wrap align-items-center">
                  <span className="font-20 font-bolder">{t("Denomination")}: </span>
                  <div className="d-flex flex-wrap align-items-center">
                    {priceState?.fixeddenomnation.map((item, i) =>
                      <span className=" font-bolder bg-04 bg-opacity-25 m-1 px-1  rounded-3" key={i}>{item}</span>
                    )}
                  </div>
                </div>
                : null}

              {/* <span className="font-bolder font-20">Expires in {diffTwoDatesInDays(catAttributeState.startDate, catAttributeState.expireDate)} days</span> */}

              <div>
                {/*
              <span className="neutral-grey-04">
                by <b className="neutral-grey-07 font-bold">Chelsie Haley</b>
              </span>{" "}
              <span>
                <Star />
              </span>
              <span className="neutral-grey-04"> 4.8 (87)</span>*/}
              </div>
            </div>
          </div>

          <div className="d-flex col-2">
            <Button text={t("Edit Product")} type={"border"} onClick={() => goToProductEditScreen()} />
          </div>

        </div>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column">
            <img
              src={giftcard?.cardPrice?.giftCardImg[0]?.preview}
              key={giftcard?.cardPrice?.giftCardImg[0]?.preview}
              alt="not found"
              className="img-fluid"
              width={'500px'}
            />
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="d-flex flex-wrap flex-column gap-2 col-8">
            <div className="d-flex gap-3">
              <GreenTag />
              <span className="font-20 font-bold neutral-grey-07">
                {t("Details")}
              </span>
            </div>
            {giftcard?.cardCatAttribute?.giftcardtype?.giftcard_type_name ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Giftcard Type")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.giftcardtype?.giftcard_type_name}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.countryCurrency?.name ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Country")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.countryCurrency?.name}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.countryCurrency?.currency ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Currency")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.countryCurrency?.currency}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.state?.name ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("State")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.state?.name}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.brandCategory?.default_category_name ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Brand Category")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.brandCategory?.default_category_name}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.brand?.default_name ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Brand")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.brand?.default_name}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.validityValue && giftcard?.cardCatAttribute?.validity?.name ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Validity")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.validityValue + " " + giftcard?.cardCatAttribute?.validity?.name}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.giftCardLength?.gift_card_length ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Giftcard number length")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.giftCardLength?.gift_card_length}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.binNumber ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("BIN number")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.binNumber}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.prefixGiftCard?.giftcard_prefix && giftcard?.cardCatAttribute?.prefixValue ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Prefix of giftcard")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.prefixGiftCard?.giftcard_prefix + "/" + giftcard?.cardCatAttribute?.prefixValue}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.redemptionMethod?.redemption_method ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Redemption method")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.redemptionMethod?.redemption_method}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.activation?.activation_required ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Activation required")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardCatAttribute?.activation?.activation_required}</span>
              </div> : null}
            {giftcard?.cardCatAttribute?.tags.length > 0 ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Tags")}: </span>
                {giftcard?.cardCatAttribute?.tags.map((item, i) =>
                  <span key={i} className="font-17 font-bolder bg-04 bg-opacity-25 mx-1 px-1  rounded-3">{item}</span>
                )}
              </div> : null}
            {giftcard?.cardNameDesc?.isFeaturedFlag ?
              <div className="d-flex gap-2">
                <span className="font-17 font-bolder ">{t("Is featured")}: </span>
                <span className="font-17 font-bolder text-muted">{giftcard?.cardNameDesc?.isFeaturedFlag ? "True" : "False"}</span>
              </div> : null}
            {/* <div className="d-flex gap-2">
          <span className="font-17 font-bolder ">Tags: </span>
          <span className="font-17 font-bolder text-muted">{giftcard?.cardNameDesc?.productTitle}</span>
        </div> */}
          </div>
          <div className="col-4">
            {giftcard?.cardNameDesc?.keybenefits?.one?.name ?
              <div className="p-2 flex-fill bd-highlight">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex gap-3">
                    <Tagpurple />
                    <span className="font-20 font-bold neutral-grey-07">
                      {t("Features")}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column mt-3 w-100 gap-3 neutral-grey-05 font-15">
                  <ul className="list-group list-group-flush ">
                    {giftcard?.cardNameDesc?.keybenefits?.one?.name ?
                      <li className="list-group-item">
                        <Righttick />
                        <span className="m-2">
                          {giftcard?.cardNameDesc?.keybenefits?.one?.name}
                        </span>
                      </li> : null}
                    {giftcard?.cardNameDesc?.keybenefits?.two?.name ?
                      <li className="list-group-item">
                        <Righttick />
                        <span className="m-2">
                          {giftcard?.cardNameDesc?.keybenefits?.two?.name}
                        </span>
                      </li> : null}
                    {giftcard?.cardNameDesc?.keybenefits?.three?.name ?
                      <li className="list-group-item">
                        <Righttick />
                        <span className="m-2">
                          {giftcard?.cardNameDesc?.keybenefits?.three?.name}
                        </span>
                      </li> : null}
                    {giftcard?.cardNameDesc?.keybenefits?.four?.name ?
                      <li className="list-group-item">
                        <Righttick />
                        <span className="m-2">
                          {giftcard?.cardNameDesc?.keybenefits?.four?.name}
                        </span>
                      </li> : null}
                  </ul>
                </div>
              </div> : null}
          </div>
        </div>
        <div className="d-flex flex-column gap-4 mt-4">
          <div className="row">
            <div className="p-2 flex-fill bd-highlight">
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex gap-3">
                  <Tag />
                  <span className="font-20 font-bold neutral-grey-07">
                    {t("Description")}
                  </span>
                </div>
                <div></div>
              </div>

              <div>
                {descriptionFun()}
              </div>

            </div>

          </div>

          <div className="row">
            <div className="col-8">
              <div className="p-2 flex-fill bd-highlight">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex gap-3">
                    <OrangeTag />
                    <span className="font-20 font-bold neutral-grey-07">
                      {t("Terms & Conditions")}
                    </span>
                  </div>
                  <div></div>
                </div>

                <div>
                  {termConditionFun()}
                </div>

              </div>
            </div>
            <div className="col-4"></div>
          </div>

        </div>
        <div className="d-flex flex-row justify-content-center mt-4">
          {!giftcard.loading &&
            <span>
              <Button text={`${giftcard?.cardPrice?.amount ? '$' + giftcard?.cardPrice?.amount + ' |' : ''} ${t('Confirm & Publish')}`} type={"primary"} onClick={() => publish()} />
            </span>}
        </div>
      </div >
  );
};

export default GiftCardModel;
