import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

const Loader = (props) => {
    const { loading, type } = props;

    return (
        type === 'data' ?
            <div className="d-flex loader-center justify-content-center align-items-center">
                <div className="">
                    <FadeLoader color={"#33FF91"} loading={loading} size={60} />
                </div>
            </div> :
            <div className="d-flex loader-center justify-content-center align-items-center">
                <div className="d-flex bg-white py-4 px-5 rounded-card-7 ">
                    <FadeLoader color={"#33FF91"} loading={loading} size={60} />
                </div>
            </div>
    );
};

export default Loader;
