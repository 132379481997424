import {
  PUT_GIFTCARD_SUPPLIER_MAPPING_FAILURE,
  PUT_GIFTCARD_SUPPLIER_MAPPING_REQUEST,
  PUT_GIFTCARD_SUPPLIER_MAPPING_SUCCESS,
  SET_ALL_SELECTED_MERTCHANDISE_PROD,
  SET_NO_SUPPLIER_PRODUCT,
  SET_SELECTED_GIFTCARD,
  SET_SELECTED_MERTCHANDISE,
  SET_SELECTED_SUPPLIER,
} from "./giftcardSuppMappingType";

import axios from "axios";
import { baseUrl, getToken } from "../../../../utils/prodURL";

export const setSelectedGiftcardsList = (obj) => {
  return {
    type: SET_SELECTED_GIFTCARD,
    payload: obj,
  };
};

export const setSelectedMerchandiseList = (obj) => {
  return {
    type: SET_SELECTED_MERTCHANDISE,
    payload: obj,
  };
};

export const setAllSelectedMerchandiseProd = (obj) => {
  return {
    type: SET_ALL_SELECTED_MERTCHANDISE_PROD,
    payload: obj,
  };
};

export const setSelectedNoMapSupplierProduct = (obj) => {
  return {
    type: SET_NO_SUPPLIER_PRODUCT,
    payload: obj,
  };
};

export const setSelectedSupplier = (obj) => {
  return {
    type: SET_SELECTED_SUPPLIER,
    payload: obj,
  };
};

export const putSupplierGiftcardRequest = () => {
  return {
    type: PUT_GIFTCARD_SUPPLIER_MAPPING_REQUEST,
  };
};

export const putSupplierGiftcardSuccess = (data) => {
  return {
    type: PUT_GIFTCARD_SUPPLIER_MAPPING_SUCCESS,
    payload: data,
  };
};

export const putSupplierGiftcardFailure = (error) => {
  return {
    type: PUT_GIFTCARD_SUPPLIER_MAPPING_FAILURE,
    payload: error,
  };
};

export const putSupplierGiftcardMappingProd = (
  selectedProducts,
  supplierGiftcarMappingSuccess,
  apiCallFailed
) => {
  return (dispatch) => {
    dispatch(putSupplierGiftcardRequest);

    let payload = [];
    // eslint-disable-next-line array-callback-return
    selectedProducts.map((item) => {
      let obj = {
        giftcard_id: item.product.id,
        supplier_list: [
          {
            supplier_id: item.supplier.id,
            supplier_sku: item.supplierSKU,
            supplier_discount: item.discountToClient,
          },
        ],
        active_flag: true,
        deleted_flag: false,
        user_access_logs: "string",
      };

      payload.push(obj);
    });

    // console.log(JSON.stringify(payload))
    // console.log(JSON.stringify(selectedProducts))

    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcards/supplier_mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        console.log("response ", response);
        if (response?.status === 200) {
          dispatch(putSupplierGiftcardSuccess(response.data));
          supplierGiftcarMappingSuccess();
        }
        else{
          apiCallFailed()
        }
      })
      .catch((error) => {
        // console.log("response" + JSON.stringify(error));

        //  const errorMsg = error;
        dispatch(
          putSupplierGiftcardFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};
