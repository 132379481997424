/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { ReactComponent as Tag } from "../../../../assests/svg/Tag/tag.svg";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../../components/Toasts/Toasts";
import { ReactComponent as LeftIcon } from "../../../../assests/svg/Products/left_lg.svg";
// import BackButton from "../../../../components/Button/BackButton";
import { ReactComponent as DropDownIcon } from "../../../../assests/svg/DropDown.svg";
import product_default from "../../../../assests/img/Product-default.png";
import {
  setSelectedMerchandiseList,
  setSelectedNoMapSupplierProduct,
} from "../../../../Redux/MappingProducts/Supplier/Giftcards/giftcardSuppMappingAction";
import ModalComp from "../../../../components/Models/ModalComp";
import SupplierMappingAlertModel from "../../../../components/Models/SupplierMappingAlertModel";
import { getMerchandiseVariantListByCountryIds } from "../../../../Redux/MerchadiseAllProduct/MerchadiseAllProductAction";
import { fetchClientMappedMerchandise } from "../../../../Redux/Distributions/ClientMerchandiseDistributions/ClientMerchandiseDistributionAction";
import PulseLoader from "../../../../components/Loader/PulseLoader";
import {
  fetchCountrieDetailsByIds,
  getAllCountriesList,
} from "../../../../Redux/Countries/countryAction";
import ModelForMerchandiseSummery from "../../../../components/Models/ModelForMerchandiseSummery";
import { getMerchandiseSummeryById } from "../../../../Redux/Merchandise/merchandiesAction";
import Collapse from "react-bootstrap/Collapse";
import { ReactComponent as Searchicon } from "../../../../assests/svg/search.svg";
import { useTranslation } from "react-i18next";
import SearcBar from "../../../../components/InputField/SearcBar";
import { DataSortingHandler } from "../../../../utils/constantFunction";
import { SORT_TYPE } from "../../../../utils/ConstantData";

const ClientMerchandiseList = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();

  const productsAllState = useSelector(
    (state) => state?.merchadiseAllProduct?.merchandiseList
  );
  const loading = useSelector((state) => state?.merchadiseAllProduct?.loading);
  const selectedClients = useSelector(
    (state) => state?.clients?.selectedClient
  );
  const clientMappedMerchandiseList = useSelector(
    (state) => state.clientMappedMerchandise.clientMappedMerchandise
  );
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );
  const countries = useSelector((state) => state?.countries?.countries);
  const [products, setProducts] = useState([]);
  // const ClientCountriesList = useSelector((state) => state?.countries?.countrieDetailsList);
  // const variantsState = useSelector((state) => state?.clientMappingData?.clientMappingProduct?.result);

  // for model
  // const handleSummeryShow = () => setShowSummeryModal(true);
  const [showSummeryModal, setShowSummeryModal] = useState(false);

  // const [visible, setVisible] = useState(2);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [floatCollapse, setFloatCollapse] = useState(false);
  const [clientCurrencies, setClientCurrencies] = useState([]);

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSummeryClose = () => setShowSummeryModal(false);

  const toggleShowToast = () => setShowToast(!showToast);
  const handleClose = () => setShowModal(false);

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {}, [order]);

  useEffect(() => {
    let arr = [];
    countries &&
      countries.map((conItem) => {
        selectedClients &&
          selectedClients.currencies_id.map((item) => {
            if (conItem?.id === item) {
              arr.push(conItem);
            }
          });
      });
    setClientCurrencies(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClients, countries]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCountriesList());

    if (selectedClients) {
      dispatch(fetchClientMappedMerchandise(selectedClients?.id));
    }
    if (selectedClients?.currencies_id) {
      dispatch(
        getMerchandiseVariantListByCountryIds(selectedClients?.currencies_id)
      );
      dispatch(fetchCountrieDetailsByIds(selectedClients?.currencies_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClients?.currencies_id]);

  useEffect(() => {
    let arr = productsAllState;
    let prodArr = [];

    // eslint-disable-next-line array-callback-return
    clientMappedMerchandiseList &&
      arr &&
      arr.map((item, i) => {
        if (
          clientMappedMerchandiseList.some(
            (mappProd) => item.id === mappProd.merchandise.id
          )
        ) {
          // toggleShowToast();
          return;
        } else {
          let obj = {
            product: item,
            collapseFlag: false,
          };
          prodArr.push(obj);
        }
      });

    setProducts(prodArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAllState, clientMappedMerchandiseList]);

  useEffect(() => {
    if (!selectedClients) {
      history("/clients-mapping-dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClients]);

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...products];

    // eslint-disable-next-line array-callback-return
    arr &&
      arr.map((item, i) => {
        if (i === parseInt(name)) {
          arr[i].isChecked = checked;
          if (item.product.variants && item.product.variants.length > 0) {
            //   arr[i].collapseFlag = !item.collapseFlag;
            let variantsArr = [...item.product.variants];
            variantsArr &&
              // eslint-disable-next-line array-callback-return
              variantsArr.map((val, j) => {
                variantsArr[j].isChecked = checked;
              });
          }
        }
      });
    setProducts(arr);
  };

  const handleChildCheck = (e) => {
    const { name, checked, id } = e.target;
    let vatiadtIndex = parseInt(id);
    let arr = [...products];
    // eslint-disable-next-line array-callback-return
    arr &&
      arr.map((item, i) => {
        if (i === parseInt(name)) {
          arr[i].isChecked = true;
          if (item.product.variants && item.product.variants.length > 0) {
            // arr[i].collapseFlag = !item.collapseFlag
            let variantsArr = [...item.product.variants];

            // eslint-disable-next-line array-callback-return
            variantsArr &&
              variantsArr.map((val, j) => {
                if (j === vatiadtIndex) {
                  variantsArr[j].isChecked = checked;
                }
              });
          }
        }
      });
    setProducts(arr);
  };

  const allCheckedHandle = (e) => {
    const { checked } = e.target;
    let arr = [...products];
    // eslint-disable-next-line array-callback-return
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = checked;
        if (item.product.variants && item.product.variants.length > 0) {
          let variantsArr = [...item.product.variants];
          // eslint-disable-next-line array-callback-return
          variantsArr &&
            variantsArr.map((val, j) => {
              variantsArr[j].isChecked = checked;
            });
        }
      });
    setProducts(arr);
  };

  const goBack = () => {
    history("/clients-mapping-dashboard");
  };

  const setProductCollapse = (index) => {
    let arr = [...products];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (index === i) {
        arr[i].collapseFlag = !item.collapseFlag;
      }
    });
    setProducts(arr);
  };

  const submit = () => {
    let arr = [...products];
    let selectedProducts = [];
    let selectedProductsVariants = [];

    // eslint-disable-next-line array-callback-return
    arr.map((item) => {
      if (item.isChecked === true) {
        if (item.product.variants && item.product.variants.length > 0) {
          let variantsArr = [...item.product.variants];
          // eslint-disable-next-line array-callback-return
          variantsArr &&
            variantsArr.map((val, j) => {
              if (val.isChecked === true) {
                let obj = {
                  product: item.product,
                  productId: item.product.id,
                  product_title: item.product.product_title,
                  variantId: val.id,
                  variantKeys: val.variant_keys,
                  variantValues: val.variant_values,
                  suppliers: val.suppliers,
                  defaultImage: val?.images && val?.images[0]?.file_url,
                };
                selectedProductsVariants.push(obj);
              }
            });
        } else {
          let obj = {
            product: item.product,
            productId: item.product.id,
            product_title: item.product.product_title,
            suppliers: item.product.suppliers,
            // variantId: val.id,
            // variantKeys: val.variant_keys,
            // variantValues: val.variant_values
          };
          selectedProducts.push(obj);
        }
      }
    });
    let arrProducts = [...selectedProducts, ...selectedProductsVariants];
    // let prodIds = []
    // arrProducts && arrProducts.map((item) => {
    //   prodIds.push(item.productId)
    // })
    // dispatch(setAllSelectedMerchandiseProd(prodIds))
    let suppVAl =
      arrProducts &&
      arrProducts.every((v) => v.suppliers && v.suppliers?.length > 0);

    let noSupplierProduct = [];
    if (!suppVAl) {
      setShowModal(true);
      // eslint-disable-next-line array-callback-return
      arrProducts &&
        arrProducts.map((item, i) => {
          if (item.suppliers && !item.suppliers.length > 0) {
            noSupplierProduct.push(item);
          }
        });
      //  setNoSuppProducts(noSupplierProduct);
      dispatch(setSelectedNoMapSupplierProduct(noSupplierProduct));
      return;
    } else if (arrProducts.length > 0) {
      dispatch(setSelectedMerchandiseList(arrProducts));
      history("/clients-mapping-product");
    } else {
      toggleShowToast();
    }
  };
  const showModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowSummeryModal(true);
  };

  return loading === true ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-between gap-3 py-4 pb-3 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Client Mapping")}</span>
        </div>
      </div>

      <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100">
        <div className="card-body d-flex justify-content-between flex-row">
          <div className="d-flex gap-3 my-2">
            <Tag />
            <span className="font-20 font-bold">
              {t("Selected Client")}:{" " + selectedClients?.partner_name}
            </span>
          </div>
          {/* <div>
                  <BackButton onClick={() => history("/supplier-mapping-dashboard")} />
                </div> */}
        </div>

        {/* <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
          <table responsive="xl">
            <tbody>
              <tr className="col gap-2 ">
                <td className="col">
                  <div>
                    <span className="font-16 font-bold">Client Operational Countries</span>
                  </div>
                </td>
                <td className="col">
                  <div>
                    <span className="font-16 font-bold">Currencies</span>
                  </div>
                </td>
              </tr>
              {ClientCountriesList &&
                ClientCountriesList.map((item, i) => (
                  <tr className="col gap-2" key={i}>
                    <td className="col">
                      {item.name + " (" + item?.iso3 + ")"}
                    </td>
                    <td className="col">
                      {item.currency + " - " + item?.currency_symbol}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!ClientCountriesList.length && (
            <div className="d-flex flex-row justify-content-center py-3">
              <span className="font-20 font-bold text-error">
                No country & Currencies found
              </span>
            </div>
          )}
        </div> */}

        <div className="d-flex justifiy-content-center px-4 gap-3">
          <div
            className="d-flex flex-row justify-content-between align-items-center py-2 px-2 gap-3 rounded-3"
            onClick={() => setFloatCollapse(!floatCollapse)}
          >
            <div
              className="d-flex flex-column cursor gap-3"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              <div className="d-flex align-items-center gap-4">
                {/* <ProductIcon /> */}
                <span className="font-18 font-bold">
                  {t("Client Operational Countries")}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div
                className={`cursor p-0 ${floatCollapse ? "rotate-180" : ""}`}
                aria-controls="example-collapse-text"
                aria-expanded={false}
              >
                <DropDownIcon
                  className={`cursor p-0 ${floatCollapse ? "rotate-180" : ""}`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="px-5">
          <Collapse in={floatCollapse}>
            <div
              id="panelsStayOpen-collapseOne"
              className="mt-2"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
                <table responsive="xl">
                  <tbody>
                    <tr className="col gap-3 border-bottom  ">
                      <td className="col">
                        <div>
                          <span className="font-16 font-bold px-2">
                            {t("Countries")}
                          </span>
                        </div>
                      </td>
                      <td className="col">
                        <div className="d-flex flex-end justify-content-end px-4">
                          <span className="font-16 font-bold px-2">
                            {t("Currencies")}
                          </span>
                        </div>
                      </td>
                    </tr>

                    {clientCurrencies &&
                      clientCurrencies.map((item, i) => (
                        <tr
                          className="col gap-3 border-bottom hover-row"
                          key={i}
                        >
                          <td className="col px-2 py-2">
                            {item?.name + " (" + item?.iso3 + ")"}
                          </td>
                          <td className="col">
                            <div className="d-flex flex-end justify-content-end px-4">
                              <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                {item?.currency + " - " + item?.currency_symbol}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {!clientCurrencies.length && (
                  <div className="d-flex flex-row justify-content-center py-3">
                    <span className="font-20 font-bold text-error">
                      {t("No country & Currencies found")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      {/* </div> */}

      <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100 gap-3">
        <div className="d-flex flex-row w-100  align-items-center bg-white">
          {/* <div className="p-2 flex-grow-1"> */}
          <div>
            <div className="d-flex gap-3 my-2 mx-3 align-items-center">
              <Tag />
              <span className="font-20 font-bold">
                {t("Mapping merchandise")}
              </span>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search by name")}
                onChange={searchHandler}
              />
            </div>
          </div>
        </div>
        <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
          <table responsive="xl">
            <tbody>
              <tr className="col border-bottom ">
                <div className="d-flex flex-row">
                  <td className="col-4">
                    <div className="d-flex flex-row justify-content-start text-dark gap-3">
                      <span>
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="checkboxNoLabel"
                          checked={
                            products.filter(
                              (product) => product?.isChecked !== true
                            ).length < 1
                          }
                          onChange={allCheckedHandle}
                        />
                      </span>
                      <span className="mx-2"></span>
                      <span
                        className={`font-16 font-boldest text-muted cursor ${
                          tableHeaderName === "default_title"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "default_title",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Name")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div className="d-flex justify-content-center">
                      <span className="font-16 font-boldest text-muted">
                        {t("Variants")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div>
                      <span className="font-16 font-boldest text-muted">
                        {t("Client name")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div>
                      <span className="font-16 font-boldest text-muted">
                        {t("Currency")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div className="d-flex justify-content-start">
                      <span className="font-16 font-boldest text-muted">
                        {t("Status")}
                      </span>
                    </div>
                  </td>
                  {/* <td></td> */}
                </div>
              </tr>
              {products &&
                products
                  ?.filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.product?.default_title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => (
                    <tr key={index} className="col gap-3 border-bottom px-2">
                      <div className="d-flex flex-row justify-content-start align-items-center ">
                        <td className="col-4 align-top ">
                          <div className="d-flex flex-row justify-content-start align-items-center py-3 gap-3">
                            <input
                              className="form-check-input cursor"
                              type="checkbox"
                              id="checkboxNoLabel"
                              checked={item?.isChecked}
                              name={index}
                              onChange={handleCheck}
                            />
                            {item?.product?.product_variants_flag ? (
                              <img
                                src={
                                  item.product &&
                                  item?.product?.variants?.length > 0 &&
                                  item?.product.variants !== undefined &&
                                  item?.product?.variants[0]?.images &&
                                  item?.product?.variants[0].images[0]?.file_url
                                    ? item?.product?.variants[0].images[0]
                                        ?.file_url
                                    : product_default
                                }
                                alt="not found"
                                id="product-thumbnail"
                              />
                            ) : (
                              <img
                                src={
                                  item?.product?.images &&
                                  item?.product?.images[0]?.file_url
                                    ? item?.product?.images[0]?.file_url
                                    : product_default
                                }
                                alt="not found"
                                id="product-thumbnail"
                              />
                            )}
                            <div className="d-flex flex-column align-items-start ">
                              <span
                                className="font-bold text-dark font-15 d-inline-block text-truncate cursor"
                                style={{
                                  width: "fit-Content",
                                  maxWidth: "120px",
                                }}
                                title={item?.product?.default_title}
                                onClick={() => showModel(item?.product?.id)}
                              >
                                {item?.product?.default_title}
                              </span>
                              <span
                                className="font-12 text-muted d-inline-block text-truncate cursor"
                                style={{
                                  width: "fit-Content",
                                  maxWidth: "100px",
                                }}
                                title={item?.product?.id}
                              >
                                {item?.product?.id}
                              </span>
                            </div>
                          </div>

                          {/* {item?.collapseFlag && (
                            <table className="table mb-0">
                              <tbody>
                                <tr className="d-flex flex-column mx-5 ">
                                  {item.product.variants &&
                                    item?.product?.product_variants_flag &&
                                    item?.product?.variants &&
                                    item?.product?.variants.map((variant, i) => (
                                      <td
                                        key={i}
                                        className="d-flex flex-row justify-content-start align-items-center gap-2 "
                                      >
                                        <input
                                          className="form-check-input cursor"
                                          type="checkbox"
                                          id={i}
                                          name={index}
                                          checked={variant?.isChecked}
                                          onChange={handleChildCheck}
                                        />
                                        <div className="d-flex flex-column gap-2 ">
                                          <span>
                                            {item?.product?.default_title &&
                                              item?.product?.default_title}
                                          </span>
                                          <span>
                                            {variant?.variant_values.join("|")}
                                          </span>
                                        </div>
                                      </td>
                                    ))}
                                </tr>
                              </tbody>
                            </table>
                          )} */}
                        </td>

                        <td className="col-2 align-top pt-4">
                          <div className="d-flex flex-row justify-content-center">
                            <span className="font-12 font-bolder text-dark bg-04 rounded-3 px-1 ">
                              {item.product.variants
                                ? item?.product?.variants.length
                                : t("No variants")}
                            </span>
                          </div>
                        </td>
                        <td className="col-2 align-top  pt-4">
                          <span
                            className="text-dark font-15 mx-3 d-inline-block text-truncate cursor"
                            title={selectedClients?.partner_name}
                            style={{ maxWidth: "100px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {selectedClients?.partner_name}
                          </span>
                        </td>
                        <td className="col-2 align-top  pt-4">
                          <div className="d-flex">
                            <span className="text-dark font-15 mx-2">
                              {item?.product?.country?.currency +
                                " (" +
                                item?.product?.country?.currency_symbol +
                                ")"}
                            </span>
                          </div>
                        </td>
                        <td className="col-2 align-top  pt-4">
                          <div className="d-flex justify-content-between">
                            <span className="text-dark font-15">
                              {item?.product?.active_flag ? (
                                <span className="font-15 font-bolder text-dark bg-04 rounded-3 px-1">
                                  {t("Active")}
                                </span>
                              ) : (
                                <span className="font-15 font-bolder text-dark bg-danger bg-opacity-25 rounded-3 px-1">
                                  {t("Deactive")}
                                </span>
                              )}
                            </span>
                            {/* </td>
                      <td className="col-2 align-top  pt-4"> */}
                            {item.product.variants &&
                              item?.product?.variants.length > 0 &&
                              item?.product?.product_variants_flag && (
                                <div
                                  onClick={() => setProductCollapse(index)}
                                  // className={`cursor p-0 ${
                                  //   item?.collapseFlag ? "rotate-180" : ""
                                  // }`}
                                  aria-controls="example-collapse-text"
                                  aria-expanded={item.collapseFlag}
                                  className="px-3"
                                >
                                  <DropDownIcon
                                    className={`cursor p-0 ${
                                      item.collapseFlag ? "rotate-180" : ""
                                    }`}
                                  />
                                </div>
                              )}
                          </div>
                        </td>
                      </div>

                      {item?.collapseFlag && (
                        <div id="example-collapse-text" className="w-100">
                          <div className="d-flex bg-white shadow w-100 rounded-3">
                            <div className="d-flex flex-column w-100 gap-3">
                              <div className="d-flex flex-row justify-content-start px-5 border-bottom w-100">
                                <div className="d-flex align-items-center gap-2">
                                  <span className="font-14 font-bolder py-2 ">
                                    {t("Variants List")}
                                  </span>
                                </div>
                              </div>

                              <div className="d-flex flex-column justify-content-start px-4  w-100 gap-3">
                                {item?.product?.product_variants_flag &&
                                  item?.product?.variants &&
                                  item?.product?.variants.map((variant, i) => (
                                    <td
                                      key={i}
                                      className="d-flex flex-row justify-content-start align-items-center pb-3 gap-2"
                                    >
                                      <input
                                        className="form-check-input cursor"
                                        type="checkbox"
                                        id={i}
                                        name={index}
                                        checked={variant?.isChecked}
                                        onChange={handleChildCheck}
                                      />
                                      <div>
                                        <img
                                          src={
                                            variant?.images &&
                                            variant?.images[0]?.file_url
                                              ? variant?.images[0]?.file_url
                                              : product_default
                                          }
                                          alt="not found"
                                          // className="img-fluid"
                                          // width="50px"
                                          // height="50px"
                                          id="product-thumbnail"
                                        />
                                      </div>
                                      <div className="d-flex flex-column w-100 gap-2">
                                        {/* <span>
                                        {item?.product_title &&
                                          item?.product_title.default_title}
                                      </span> */}
                                        <span>
                                          {variant?.variant_values.join("|")}
                                        </span>
                                      </div>
                                    </td>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </tr>
                  ))}
            </tbody>
          </table>
          {!products.length && (
            <div className="d-flex flex-row justify-content-center py-3">
              <span className="font-20 font-bold text-error">
                {t("No products found")}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex">
        <Button
          type="primary"
          text={t("Save and Next")}
          onClick={() => submit()}
        />
      </div>
      <ModalComp
        size="md"
        showModal={showModal}
        // handleClose={handleClose}
        component={
          <SupplierMappingAlertModel
            handleClose={handleClose}
            productType="merchandise"
          />
        }
      />
      <ModalComp
        size="lg"
        showModal={showSummeryModal}
        // handleClose={handleClose}
        component={
          <ModelForMerchandiseSummery
            handleClose={handleSummeryClose}
            merchandiseSummery={merchandiseSummery}
          />
        }
      />
      <div
        className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Please select products to map."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default ClientMerchandiseList;
