import {
  FETCH_CLIENT_MAPPED_PRODCUT_REQUEST,
  FETCH_CLIENT_MAPPED_PRODCUT_SUCCESS,
  FETCH_CLIENT_MAPPED_PRODCUT_FAILURE,
  SET_SELECTED_CLIENT_DATA,
} from "./ClientMerchandiseDistributionType";
import { baseUrl, getToken } from "../../../utils/prodURL";
import axios from "axios";

export const fetchClientMappedMerRequest = () => {
  return {
    type: FETCH_CLIENT_MAPPED_PRODCUT_REQUEST,
  };
};

export const fetchClientMappedMerSuccess = (data) => {
  return {
    type: FETCH_CLIENT_MAPPED_PRODCUT_SUCCESS,
    payload: data,
  };
};

export const fetchClientMappedMerFailure = (error) => {
  return {
    type: FETCH_CLIENT_MAPPED_PRODCUT_FAILURE,
    payload: error,
  };
};

export const setSelectedDistMerClient = (clientData) => {
  return {
    type: SET_SELECTED_CLIENT_DATA,
    payload: clientData,
  };
};

export const fetchClientMappedMerchandise = (clientId) => {
  return (dispatch) => {
    dispatch(fetchClientMappedMerRequest());

    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/client/${clientId}/mappingProducts`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchClientMappedMerSuccess(response.data.result));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          fetchClientMappedMerFailure(
            "Something went wrong!! Please try again!"
          )
        );
      });
  };
};

// Client Merchandise Details list
export const fetchClientMappedMerchandiseDetails = (
  clientId,
  skip,
  limit,
  clientMapMerchandiseList
) => {
  return (dispatch) => {
    dispatch(fetchClientMappedMerRequest());
    let url =
      skip !== undefined && limit !== undefined
        ? `${baseUrl}/v1/merchandise/client/${clientId}/mappingProducts?is_detailed=true&skip=${skip}&limit=${limit}`
        : `${baseUrl}/v1/merchandise/client/${clientId}/mappingProducts?is_detailed=true`;
    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          let data = {
            data: [...clientMapMerchandiseList, ...response.data.result],
            moredata: response.data.next_iteration
            // moredata: false
          }
          dispatch(fetchClientMappedMerSuccess(data?.data));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          fetchClientMappedMerFailure(
            "Something went wrong!! Please try again!"
          )
        );
      });
  };
};

export const fetchClientMappedMerchandiseDetailsall = (clientId) => {
  return (dispatch) => {
    dispatch(fetchClientMappedMerRequest());

    let url = `${baseUrl}/v1/merchandise/client/${clientId}/mappingProducts?is_detailed=true`;
    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {

          dispatch(fetchClientMappedMerSuccess(response.data.result));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          fetchClientMappedMerFailure(
            "Something went wrong!! Please try again!"
          )
        );
      });
  };
};
