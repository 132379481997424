import React, { useState } from "react";
import Toasts from "../Toasts/Toasts";

const TagsInput = (props) => {
  const { tags, setTags, placeholder, inputRef } = props;

  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);
  const imgPath = "data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2096%2096%22%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%20%20%20%3Cstyle%20type%3D%22text%2Fcss%22%3E%20%20%20%20%20%20%20%20.st0%7B%20%20%20%20%20%20%20%20%20%20%20%20fill%3A%23fff%3B%20%20%20%20%20%20%20%20%7D%20%3C%2Fstyle%3E%20%20%20%20%3Cg%3E%20%20%20%20%20%20%20%20%3Cpath%20class%3D%22st0%22%20d%3D%22M48%2C0A48%2C48%2C0%2C1%2C0%2C96%2C48%2C48.0512%2C48.0512%2C0%2C0%2C0%2C48%2C0Zm0%2C84A36%2C36%2C0%2C1%2C1%2C84%2C48%2C36.0393%2C36.0393%2C0%2C0%2C1%2C48%2C84Z%22%2F%3E%20%20%20%20%20%20%20%20%3Cpath%20class%3D%22st0%22%20d%3D%22M64.2422%2C31.7578a5.9979%2C5.9979%2C0%2C0%2C0-8.4844%2C0L48%2C39.5156l-7.7578-7.7578a5.9994%2C5.9994%2C0%2C0%2C0-8.4844%2C8.4844L39.5156%2C48l-7.7578%2C7.7578a5.9994%2C5.9994%2C0%2C1%2C0%2C8.4844%2C8.4844L48%2C56.4844l7.7578%2C7.7578a5.9994%2C5.9994%2C0%2C0%2C0%2C8.4844-8.4844L56.4844%2C48l7.7578-7.7578A5.9979%2C5.9979%2C0%2C0%2C0%2C64.2422%2C31.7578Z%22%2F%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E"

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    let value = event.target.value;
    let reg = /^\d{0,10}(\.\d{0,2})?$/;
    if (value !== "" && value > 0 && reg.test(value) === true && !tags.includes(value) ) {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    } else { toggleShowToast() }
    event.target.value = "";
  }

  return (
    <div className="d-flex tags-input border border-2">
      <ul id="tags">
        {tags.map((tag, index) => (
          <li key={index} className="tag align-items-center">
            <span className="">{tag}</span>
            <span className="tag-close-icon" onClick={() => removeTags(index)}>
              <img src={imgPath} alt='not found' />
            </span>
          </li>
        ))}
      </ul>
      <input
      ref={inputRef ? inputRef : null}
        type="text"
        name="tags"
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
        placeholder={placeholder}
        maxLength={15}
      />

      <div className="position-fixed bottom-0 start-50 translate-middle-x w-100 " id="liveToast">
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Enter the valid denomination value"
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default TagsInput;
