/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../../assests/svg/Tag/tag.svg";
import Button from "../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../../components/Toasts/Toasts";
import { ReactComponent as LeftIcon } from "../../../../assests/svg/Products/left_lg.svg";
import Giftcard_default from "../../../../assests/img/giftcard-default.png";
import { setSelectedGiftcardsList } from "../../../../Redux/MappingProducts/Supplier/Giftcards/giftcardSuppMappingAction";
import {
  fetchAllGiftCardListByCountryIds,
  fetchGiftCardSummeryById,
} from "../../../../Redux/GiftCard/giftcardAction";
import PulseLoader from "../../../../components/Loader/PulseLoader";
import {
  fetchCountrieDetailsByIds,
  getAllCountriesList,
} from "../../../../Redux/Countries/countryAction";
import ModelForGiftCardSummery from "../../../../components/Models/ModelForGiftCardSummery";
import ModalComp from "../../../../components/Models/ModalComp";
import Collapse from "react-bootstrap/Collapse";
import { ReactComponent as DropDownIcon } from "../../../../assests/svg/DropDown.svg";
import { ReactComponent as Searchicon } from "../../../../assests/svg/search.svg";
import { fetchSupplierMapProducts } from "../../../../Redux/Supplier/supplierAction";
import { getAllBrandCategoryList } from "../../../../Redux/BrandCategories/brandCategoryAction";
import Multiselect from "multiselect-react-dropdown";
import { useTranslation } from "react-i18next";
import SearcBar from "../../../../components/InputField/SearcBar";
import { DataSortingHandler } from "../../../../utils/constantFunction";
import { SORT_TYPE } from "../../../../utils/ConstantData";

const GiftcardList = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const giftCardList = useSelector((state) => state?.giftcard?.giftcards);
  const loading = useSelector((state) => state?.giftcard?.loading);
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );

  const selectedSupplier = useSelector(
    (state) => state?.giftcardSuppMappingReducer?.selectedSupplier
  );
  const countries = useSelector((state) => state?.countries?.countries);
  const supplierMappedGiftCard = useSelector(
    (state) => state?.suppliers?.supplierMappedGiftCard
  );
  const categoriesState = useSelector(
    (state) => state.brandCategories.brandCategories
  );

  const [products, setProducts] = useState([]);
  const [unmappProducts, setUnmappProducts] = useState([]);

  const [selectedCategorie, setSelectedCategorie] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);
  // for model
  const handleClose = () => setShowModal(false);
  // const handleShow = () => setShowModal(true);
  const [showModal, setShowModal] = useState(false);
  const [floatCollapse, setFloatCollapse] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [supplierCurrencies, setSupplierCurrencies] = useState([]);

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {}, [order]);

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCountriesList());
    dispatch(getAllBrandCategoryList());
  }, []);

  useEffect(() => {
    let arr = [];
    countries &&
      countries.map((conItem) => {
        selectedSupplier &&
          selectedSupplier.currencies_id.map((item) => {
            if (conItem?.id === item) {
              arr.push(conItem);
            }
          });
      });
    setSupplierCurrencies(arr);
  }, [selectedSupplier, countries]);

  useEffect(() => {
    window.scrollTo(0, 0);
    //setProducts(giftCardList);
    filterClientMappedGiftList();
  }, [giftCardList, supplierMappedGiftCard, categoriesState]);

  useEffect(() => {
    if (!selectedCategorie.length) {
      setProducts(unmappProducts);
    }
  }, [products, unmappProducts, selectedCategorie]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (selectedSupplier && selectedSupplier?.currencies_id) {
      dispatch(
        fetchAllGiftCardListByCountryIds(selectedSupplier?.currencies_id)
      );
      dispatch(fetchCountrieDetailsByIds(selectedSupplier?.currencies_id));
      dispatch(fetchSupplierMapProducts(selectedSupplier?.id));
    }

    if (!selectedSupplier) {
      history("/supplier-mapping-dashboard");
    }
  }, [selectedSupplier]);

  const filterClientMappedGiftList = () => {
    let arr = [];
    giftCardList &&
      giftCardList.map((item, i) => {
        if (
          supplierMappedGiftCard.some(
            (mappProd) => item.id === mappProd.giftcard.id
          )
        ) {
          // toggleShowToast();
          return;
        } else {
          arr.push(item);
        }
      });
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = false;
      });
    setProducts(arr);
    setUnmappProducts(arr);
  };

  const handleBrandCategory = (selectedList, removedItem) => {
    setSelectedCategorie(selectedList);
    let arr = [];
    unmappProducts &&
      unmappProducts.map((item, i) => {
        if (
          selectedList &&
          selectedList.some((prod) => item?.brand_category_id === prod?.id)
        ) {
          arr.push(item);
        }
      });

    setProducts(arr);
  };

  const handleRemoveBrandCategory = (selectedList, removedItem) => {
    setSelectedCategorie(selectedList);
    let arr = [];
    if (selectedList.length) {
      unmappProducts &&
        unmappProducts.map((item, i) => {
          if (
            selectedList &&
            selectedList.some((prod) => item?.brand_category_id === prod?.id)
          ) {
            arr.push(item);
          }
        });
      setProducts(arr);
    }
  };

  const allCheckedHandle = (e) => {
    const { checked } = e.target;
    let arr = [...products];
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = checked;
      });
    setProducts(arr);
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...products];
    arr.map((item, i) => {
      if (i === parseInt(name)) {
        item.isChecked = checked;
      }
    });
    setProducts(arr);
  };

  const submit = () => {
    let arr = [...products];
    let selectedProducts = [];
    arr.map((item) => {
      if (item.isChecked === true) {
        selectedProducts.push(item);
      }
    });

    if (selectedProducts.length > 0) {
      dispatch(setSelectedGiftcardsList(selectedProducts));

      history("/supplier-mapping-giftcard-sku");
    } else {
      toggleShowToast();
    }
  };
  const showModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowModal(true);
  };
  return loading === true ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-between gap-3 py-4 pb-3 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon
            onClick={() => history("/supplier-mapping-dashboard")}
            className="cursor"
          />
          <span className="font-bold font-32 my-2">
            {t("Supplier mapping")}
          </span>
        </div>
      </div>

      <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100">
        <div className="card-body d-flex justify-content-between flex-row">
          <div className="d-flex gap-3 my-2">
            <Tag />
            <span className="font-20 font-bold">
              {t("Selected Supplier")}: {selectedSupplier?.partner_name}
            </span>
          </div>
          {/* <div>
                  <BackButton onClick={() => history("/supplier-mapping-dashboard")} />
                </div> */}
        </div>

        {/* <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
              <table responsive="xl">
                <tbody>
                  <tr className="col gap-2 ">
                    <td className="col">
                      <div>
                        <span className="font-16 font-bold">
                        Supplier Operational Countries
                        </span>
                      </div>
                    </td>
                    <td className="col">
                      <div>
                        <span className="font-16 font-bold">
                          Currencies
                        </span>
                      </div>
                    </td>
                  </tr>
                  {ClientCountriesList && ClientCountriesList.map((item, i) => (
                    <tr className="col gap-2" key={i}>
                      <td className="col">{item.name + " (" + item?.iso3 + ")"}</td>
                      <td className="col">{item.currency + " - " + item?.currency_symbol}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div> */}
        <div className="d-flex justifiy-content-center px-4 gap-3">
          <div
            className="d-flex flex-row justify-content-between align-items-center py-2 px-2 gap-3 rounded-3"
            onClick={() => setFloatCollapse(!floatCollapse)}
          >
            <div
              className="d-flex flex-column cursor gap-3"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              <div className="d-flex align-items-center gap-4">
                {/* <ProductIcon /> */}
                <span className="font-18 font-bold">
                  {t("Supplier Operational Countries")}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div
                className={`cursor p-0 ${floatCollapse ? "rotate-180" : ""}`}
                aria-controls="example-collapse-text"
                aria-expanded={false}
              >
                <DropDownIcon
                  className={`cursor p-0 ${floatCollapse ? "rotate-180" : ""}`}
                />
              </div>
            </div>
          </div>
        </div>
        {/* )} */}

        <div className="px-5">
          <Collapse in={floatCollapse}>
            <div
              id="panelsStayOpen-collapseOne"
              className="mt-2"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div className="card-body d-flex flex-column justify-content-between p-2 gap-4">
                <table responsive="xl">
                  <tbody>
                    <tr className="col gap-3 border-bottom ">
                      <td className="col">
                        <div>
                          <span className="font-16 font-bold px-3">
                            {t("Countries")}
                          </span>
                        </div>
                      </td>
                      <td className="col">
                        <div className="d-flex flex-end justify-content-end px-4">
                          <span className="font-16 font-bold ">
                            {t("Currencies")}
                          </span>
                        </div>
                      </td>
                    </tr>

                    {supplierCurrencies &&
                      supplierCurrencies.map((item, i) => (
                        <tr
                          className="col gap-3 border-bottom hover-row"
                          key={i}
                        >
                          <td className="col px-2 py-2">
                            {item?.name + " (" + item?.iso3 + ")"}
                          </td>
                          <td className="col">
                            <div className="d-flex flex-end justify-content-end px-4">
                              <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                {item?.currency + " - " + item?.currency_symbol}
                              </span>
                            </div>
                          </td>
                        </tr>
                        // </tr>
                      ))}
                  </tbody>
                </table>
                {!supplierCurrencies.length && (
                  <div className="d-flex flex-row justify-content-center py-3">
                    <span className="font-20 font-bold text-error">
                      {t("No country & Currencies found")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100 gap-3">
        {/* <div className="card-body d-flex justify-content-between flex-row">
              <div className="d-flex gap-3 my-2">
                <Tag />
                <span className="font-20 font-bold">Mapping giftcard</span>
              </div>
              {/* <div>
            <BackButton onClick={() => history("/supplier-mapping-dashboard")} />
          </div> */}
        {/*</div> */}
        <div className="d-flex gap-3 my-2 mx-3 align-items-center">
          <Tag />
          <span className="font-20 font-bold">{t("Mapping giftcard")}</span>
          <SearcBar
            type="text"
            name="search"
            lang={props?.lang}
            placeholder={t("Search by name")}
            onChange={searchHandler}
          />

          <div className="d-flex width-30">
            <Multiselect
              placeholder={t("Select category")}
              options={categoriesState}
              showCheckbox={true}
              selectedValues={selectedCategorie}
              onSelect={handleBrandCategory}
              onRemove={handleRemoveBrandCategory}
              displayValue="default_category_name"
              className={`${
                props?.lang === "ar" ? "multiSelectContainer-ar" : ""
              }`}
            />
          </div>
        </div>
        <div className="card-body d-flex flex-column justify-content-between px-2 gap-3">
          <table responsive="xl">
            <tbody>
              <tr className="col gap-2 border-bottom cursor">
                <div className="d-flex flex-row justify-content-start align-items-center gap-3">
                  <td className="col-4">
                    <div className="d-flex flex-row justify-content-start text-dark gap-3">
                      <span>
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="checkboxNoLabel"
                          checked={
                            products.filter(
                              (product) => product?.isChecked !== true
                            ).length < 1
                          }
                          onChange={allCheckedHandle}
                        />
                      </span>

                      <span
                        className={`font-16 font-boldest text-muted ${
                          tableHeaderName === "default_title"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "default_title",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Name")}
                      </span>
                    </div>
                  </td>
                  <td className="col-4">
                    <div>
                      <span
                        className={`font-16 font-boldest text-muted ${
                          tableHeaderName === "id"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "id",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Product Id")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div>
                      <span className={`font-16 font-boldest text-muted ${
                          tableHeaderName === "partner_name"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "partner_name",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }>
                        {t("Supplier")}
                      </span>
                    </div>
                  </td>
                  <td className="col">
                    <div>
                      <span className={`font-16 font-boldest text-muted ${
                          tableHeaderName === "currency"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "currency",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }>
                        {t("Currency")}
                      </span>
                    </div>
                  </td>
                </div>
              </tr>
              {products &&
                products
                  ?.filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.default_title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => (
                    <tr key={index} className="gap-1 border-bottom">
                      <div className="d-flex flex-row justify-content-start align-items-center gap-3">
                        <td className="col-4">
                          <div className="d-flex flex-row justify-content-start align-items-center py-2 gap-3">
                            <input
                              className="form-check-input cursor"
                              type="checkbox"
                              id={item?.id}
                              checked={item.isChecked}
                              name={index}
                              onChange={handleCheck}
                            />
                            <img
                              src={
                                item?.images && item?.images[0]?.file_url
                                  ? item?.images && item?.images[0]?.file_url
                                  : Giftcard_default
                              }
                              alt="not found"
                              // className="img-fluid"
                              // width="50px"
                              // height="50px"
                              id="product-thumbnail"
                            />
                            <div className="d-flex flex-column align-items-start">
                              <span
                                className="font-bold text-dark font-15 d-inline-block text-truncate cursor"
                                title={item?.default_title}
                                style={{ maxWidth: "140px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => showModel(item.id)}
                              >
                                {item?.default_title}
                              </span>
                              <span className="font-bold text-muted font-10">
                                {/* {strFirstLetterCap(item?.giftcard_value_type)} */}
                                {item?.giftcard_value_type === "denominations"
                                  ? t("Fixed denominations")
                                  : t("Range denominations")}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="col-4">
                          <div className="d-flex">
                            <span className="text-dark font-15">
                              {item?.id}
                            </span>
                          </div>
                        </td>
                        <td className="col-2">
                          <div className="d-flex">
                            <span className="text-dark font-15">
                              {selectedSupplier?.partner_name}
                            </span>
                          </div>
                        </td>
                        <td className="col">
                          <div className="d-flex">
                            <span className="text-dark font-15 ">
                              {item?.country?.currency +
                                " (" +
                                item?.country?.currency_symbol +
                                ")"}
                            </span>
                          </div>
                        </td>
                      </div>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        {!products.length && (
          <div className="d-flex flex-row justify-content-center py-4">
            <span className="font-20 font-bold text-error">
              {t("Product Not Found")}
            </span>
          </div>
        )}
      </div>
      {products && products.length ? (
        <div className="d-flex">
          <Button
            type="primary"
            text={t("Save and Next")}
            onClick={() => submit()}
          />
        </div>
      ) : null}
      <ModalComp
        size="lg"
        showModal={showModal}
        // handleClose={handleClose}
        component={
          <ModelForGiftCardSummery
            handleClose={handleClose}
            giftcardSummery={giftcardSummery}
          />
        }
      />
      <div
        className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Please select products"
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default GiftcardList;
