import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import AllFloatTransactions from "../../components/Float/AllFloatTransactions";
import { useTranslation } from "react-i18next";

const FloatTransactions = (props) => {

  const history = useNavigate();
  const { t } = useTranslation();


  const goToNext = () => {
    history("/float/addfloat-details");
  };


  return (
    <div className="d-flex flex-column justify-content-between gap-1 py-4 mt- px-4 bg-light gap-3">
      <div className="d-flex flex-row w-100  align-items-center">
        <div className="d-flex flex-grow-1">
          <div className="d-flex flex-start gap-3 mb-1 align-items-center">

          </div>
        </div>
        <div className="">
          <Button
            type="primary"
            text={t("+ Top Up Float")}
            className="rounded-6 my-4 mt-6 fw-bold"
            onClick={() => goToNext()}
          />
        </div>
      </div>
      <AllFloatTransactions lang={props?.lang} />
    </div>
  );
};
export default FloatTransactions;
