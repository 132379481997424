/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as HadayaLogo } from "../../assests/svg/ehadaya_logo.svg";
import { ReactComponent as DashBoardIcon } from "../../assests/svg/Sidebar/DashBoard_icon.svg";
import { ReactComponent as ProductIcon } from "../../assests/svg/Sidebar/Product_Icon.svg";
import { ReactComponent as ManageRoles } from "../../assests/svg/Sidebar/ManageRoies.svg";
import { ReactComponent as CustomerIcon } from "../../assests/svg/Sidebar/Customer_Icon.svg";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg";
import { ReactComponent as ManageClient } from "../../assests/svg/Sidebar/ManageClient.svg";
import { ReactComponent as Settings } from "../../assests/svg/Sidebar/settings.svg";
import { ReactComponent as PlusCircle } from "../../assests/svg/Sidebar/plus_circle.svg";
import { ReactComponent as ReportIcon } from "../../assests/svg/Sidebar/Report_Icon.svg";
import Collapse from "react-bootstrap/Collapse";
import { useTranslation } from "react-i18next";
import "./SideNav.css";
import { useDispatch } from "react-redux";
import { sideNavToggle } from "../../Redux/SidenavToggle/SidenavToggleAction";
import { useSelector } from "react-redux";
import { MdOutlineInventory2 } from "react-icons/md";
import { MdAttachMoney } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { FaClipboardList } from "react-icons/fa";
import { BiShapePolygon } from "react-icons/bi";
import { IconContext } from "react-icons";

const Sidenav = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  // for langaue changer
  const { t } = useTranslation();
  const [productCollapse, setProductCollapse] = useState(false);
  const [distributionCollapse, setDistributionCollapse] = useState(false);
  const [prodAttrCollapse, setProdAttrCollapse] = useState(false);
  const [manageRolesCollapse, setManageRolesCollapse] = useState(false);
  const [customerCollapse, setCustomerCollapse] = useState(false);
  const [settingCollapse, setSettingCollapse] = useState(false);
  const [sideNavCollapse, setSideNavCollapse] = useState(false);
  const [permissions, setpermissions] = useState();
  const [usertype, setusertype] = useState();
  // eslint-disable-next-line no-unused-vars
  const [lang, setLang] = useState()

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    setusertype(JSON.parse(localStorage.getItem("user")));
    setLang(localStorage.getItem("i18nextLng"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions"), localStorage.getItem("i18nextLng")]);

  const toggleValue = useSelector(
    (state) => state.sideNavToggleReducer.sideNavToggle
  );


  const sideNavToggleHandler = () => {
    setSideNavCollapse(!sideNavCollapse);
    dispatch(sideNavToggle());
    if (productCollapse === true) {
      setProductCollapse(!productCollapse);
    }

    if (distributionCollapse === true) {
      setDistributionCollapse(!distributionCollapse);
    }

    if (prodAttrCollapse === true) {
      setProdAttrCollapse(!prodAttrCollapse);
    }

    if (manageRolesCollapse === true) {
      setManageRolesCollapse(!manageRolesCollapse);
    }

    if (customerCollapse === true) {
      setCustomerCollapse(!customerCollapse);
    }

    if (settingCollapse === true) {
      setSettingCollapse(!settingCollapse);
    }
  };

  // Product
  const productCollapseHandler = () => {
    setProductCollapse(!productCollapse);
    if (toggleValue === true) {
      dispatch(sideNavToggle());
    }
  };

  // Distribution
  const distributionCollapseHandler = () => {
    setDistributionCollapse(!distributionCollapse);
    if (toggleValue === true) {
      dispatch(sideNavToggle());
    }
  };

  // Product Attributes
  const ProdAttrCollapseHandler = () => {
    setProdAttrCollapse(!prodAttrCollapse);
    if (toggleValue === true) {
      dispatch(sideNavToggle());
    }
  };

  // User Manage
  const ManageRolesCollapseHandler = () => {
    setManageRolesCollapse(!manageRolesCollapse);
    if (toggleValue === true) {
      dispatch(sideNavToggle());
    }
  };

  // Partner
  const CustomerCollapseHandler = () => {
    setCustomerCollapse(!customerCollapse);
    if (toggleValue === true) {
      dispatch(sideNavToggle());
    }
  };

  // Setting
  const SettingCollapseHandler = () => {
    setSettingCollapse(!settingCollapse);
    if (toggleValue === true) {
      dispatch(sideNavToggle());
    }
  };

  useEffect(() => { }, [toggleValue]);

  let toggleClasses = toggleValue === true ? "sidebar active" : "sidebar";
  let toggleClassesAr = toggleValue === true ? "sidebar-ar active" : "sidebar-ar";

  return (
    <div className={`d-flex mb-5 ${props?.lang === 'ar' ? toggleClassesAr : toggleClasses}`}>
      <div className="w-100">
        <div className={`py-4 px-2 logo_container d-flex ${props?.lang === 'ar' ? 'justify-content-evenly' : 'justify-content-between'} align-items-center gap-3`}>
          <Link to="/" className="p-0 logo">
            <HadayaLogo width={""} />
          </Link>
          <div
            className={`cursor p-0 menu_icon`}
            // onClick={sideNavToggleHandler}
            aria-controls="example-collapse-text"
            aria-expanded={sideNavCollapse}
          >
            <div
              className="cursor menuToggle"
              onClick={sideNavToggleHandler}
            ></div>
          </div>
          {/* <div className='menuToggle' onClick={sideNavToggleHandler}></div> */}
        </div>

        <div className="d-flex flex-column mt-5 mx-2 gap-3">
          {/* Dashboard */}
          <Link className="link-text" to="/">
            <div
              className={`d-flex align-items-center rounded-3 ${location.pathname === "/" ? "bg-body-color" : ""
                }`}
            >
              <div
                className={`d-flex flex-row align-items-center rounded-3 gap-2 py-2 px-2 cursor`}
              >
                <DashBoardIcon />
              </div>
              <div className="without_dropdown_icon">
                <span className="text-muted fw-normal sidenav_title">
                  {t("Dashboard")}
                </span>
              </div>
            </div>
          </Link>

          {/* Product */}
          {permissions?.products_module?.access ? (
            <div>
              <div
                className="d-flex flex-row justify-content-between align-items-center py-2 px-2 rounded-3"
                onClick={productCollapseHandler}
              >
                <div>
                  <div
                    className="d-flex align-items-center cursor gap-2"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    <IconContext.Provider
                      value={{
                        color: "#6F767E",
                        size: "25px",
                        className: "global-class-name",
                      }}
                    >
                      <MdOutlineInventory2 width="50" height="50" />
                    </IconContext.Provider>
                  </div>
                </div>
                <div className="d-flex gap-3 dropdown_icon">
                  <div className="cursor">
                    <span className="text-muted fw-normal sidenav_title">
                      {t("Products")}
                    </span>
                  </div>
                  <div
                    className={`cursor p-0 ${productCollapse ? "rotate-180" : ""
                      }`}
                    aria-controls="example-collapse-text"
                    aria-expanded={productCollapse}
                  >
                    <DropDownIcon
                      className={`cursor p-0 ${productCollapse ? "rotate-180" : ""
                        }`}
                    />
                  </div>
                </div>
              </div>
              <Collapse in={productCollapse}>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="mt-2"
                  aria-labelledby="pane lsStayOpen-headingOne"
                >
                  <div className="d-flex flex-column gap-2 px-4">
                    {/* Giftcards */}
                    {permissions?.giftcard?.read ? (
                      <Link
                        to="/product-dashboard"
                        className={`link-text rounded-3  ${location.pathname === "/product-dashboard"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <span className="text-muted px-2">Giftcards</span>
                          {permissions?.giftcard?.create ? (
                            <span>
                              <Link
                                to="/product-creation/name-description"
                                className="link-text"
                              >
                                <PlusCircle />
                              </Link>
                            </span>
                          ) : null}
                        </div>
                      </Link>
                    ) : null}

                    {/* Merchandise */}
                    {permissions?.merchandise?.read ? (
                      <Link
                        to="/merchandise-dashboard"
                        className={`link-text rounded-3  ${location.pathname === "/merchandise-dashboard"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <span className="text-muted px-2">Merchandise</span>
                          {permissions?.merchandise?.read &&
                            permissions?.merchandise?.create ? (
                            <span>
                              <Link to="/add-merchandise" className="link-text">
                                <PlusCircle />
                              </Link>
                            </span>
                          ) : null}
                        </div>
                      </Link>
                    ) : null}

                    {/* Manual Inventory */}
                    {permissions?.manual_inventory?.read ? (
                      <Link
                        to="/manual-inventory"
                        className={`link-text rounded-3  ${location.pathname === "/manual-inventory"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <span className="text-muted px-2">
                            Manual Inventory
                          </span>
                          {permissions?.manual_inventory?.read &&
                            permissions?.manual_inventory?.create ? (
                            <span>
                              <Link
                                to="/add-manual-inventory"
                                className="link-text"
                              >
                                <PlusCircle />
                              </Link>
                            </span>
                          ) : null}
                        </div>
                      </Link>
                    ) : null}

                    {/* <div className="d-flex justify-content-between flex-row align-items-center">
                    <Link to="/a" className={`link-text rounded-3  ${location.pathname === '/2' ? 'bg-body-color py-2 ' : 'py-2 '}`}>
                      <span className="text-muted px-2">{t("Restock")}</span>
                    </Link>
                    <span className="bg-04 rounded-3 px-2">8</span>
                  </div> */}

                    {/* Bulk Upload */}
                    <Link
                      to="/bulk-upload"
                      className={`link-text rounded-3  ${location.pathname === "/bulk-upload"
                        ? "bg-body-color py-2 " : "py-2 "}`}
                    >
                      <span className="text-muted px-2">Bulk Upload</span>
                    </Link>

                    {/* Add Gift Card */}
                    {permissions?.giftcard?.read &&
                      permissions?.giftcard?.create ? (
                      <Link
                        to="/product-creation/name-description"
                        className={`link-text rounded-3  ${location.pathname ===
                          "/product-creation/name-description"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <span className="text-muted px-2">Add Gift Card</span>
                      </Link>
                    ) : null}

                    {/* Add Merchandise Product */}
                    {permissions?.merchandise?.read &&
                      permissions?.merchandise?.create ? (
                      <Link
                        to="/add-merchandise"
                        className={`link-text rounded-3  ${location.pathname === "/add-merchandise"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <span className="text-muted px-2">
                          Add Merchandise Product
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </Collapse>
            </div>
          ) : null}

          {/* Distribution Model */}
          {permissions?.distribution_module?.access ? (
            <div>
              <div
                className="d-flex flex-row justify-content-between align-items-center py-2 px-2 rounded-3"
                onClick={distributionCollapseHandler}
              >
                <div
                  className="cursor"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne4"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne4"
                >
                  <div className="d-flex align-items-center gap-2">
                    <IconContext.Provider
                      value={{
                        color: "#6F767E",
                        size: "25px",
                        className: "global-class-name",
                      }}
                    >
                      <BiShapePolygon width="50" height="50" />
                    </IconContext.Provider>
                  </div>
                </div>
                <div className="d-flex gap-3 dropdown_icon">
                  <div className="cursor">
                    <span className="text-muted fw-normal sidenav_title">
                      Distribution Module
                    </span>
                  </div>

                  <div
                    className={`cursor p-0 ${distributionCollapse ? "rotate-180" : ""
                      }`}
                    aria-controls="example-collapse-text"
                    aria-expanded={distributionCollapse}
                  >
                    <DropDownIcon
                      className={`cursor p-0 ${distributionCollapse ? "rotate-180" : ""
                        }`}
                    />
                  </div>
                </div>
              </div>
              <Collapse in={distributionCollapse}>
                <div
                  id="panelsStayOpen-collapseOne4"
                  className="mt-2"
                  aria-labelledby="pane lsStayOpen-headingOne4"
                >
                  <div className="d-flex flex-column gap-2 px-4">
                    {permissions?.giftcard_distribution?.read &&
                      permissions?.giftcard_distribution?.create ? (
                      <Link
                        to="/gift-card-distribution"
                        className={`link-text rounded-3  ${location.pathname === "/gift-card-distribution"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <span className="text-muted px-2">
                          {t("Gift Card Distribution")}
                        </span>
                      </Link>
                    ) : null}

                    {permissions?.merchandise_distribution?.read &&
                      permissions?.merchandise_distribution?.create ? (
                      <Link
                        to="/merchandise-distribution"
                        className={`link-text rounded-3  ${location.pathname === "/merchandise-distribution"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <span className="text-muted px-2">
                          {t("Merchandise Distribution")}
                        </span>
                      </Link>
                    ) : null}

                    {/* <Link
                      to="/bulk-distribution"
                      // /bulk-distribution
                      className={`link-text rounded-3  ${location.pathname === "/a"
                        ? "bg-body-color py-2 "
                        : "py-2 "
                        }`}
                    >
                      <span className="text-muted px-2">Bulk Distribution</span>
                    </Link> */}
                  </div>
                </div>
              </Collapse>
            </div>
          ) : null}

          {/* Products Attribute */}
          {permissions?.products_attribute_module?.access ? (
            <div>
              <div
                className="d-flex flex-row justify-content-between align-items-center py-2 px-2 rounded-3"
                onClick={ProdAttrCollapseHandler}
              >
                <div
                  className="cursor"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne5"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne5"
                >
                  <div className="d-flex align-items-center gap-2">
                    <ProductIcon />
                  </div>
                </div>
                <div className="d-flex dropdown_icon">
                  <div className="cursor">
                    <span className="text-muted fw-normal sidenav_title">
                      Products Attribute
                    </span>
                  </div>

                  <div
                    className={`cursor p-0 ${prodAttrCollapse ? "rotate-180" : ""
                      }`}
                    aria-controls="example-collapse-text"
                    aria-expanded={prodAttrCollapse}
                  >
                    <DropDownIcon
                      className={`cursor p-0 ${prodAttrCollapse ? "rotate-180" : ""
                        }`}
                    />
                  </div>
                </div>
              </div>
              <Collapse in={prodAttrCollapse}>
                <div
                  id="panelsStayOpen-collapseOne5"
                  className="mt-2"
                  aria-labelledby="pane lsStayOpen-headingOne5"
                >
                  <div className="d-flex flex-column gap-2 px-4">
                    {permissions?.brand_category?.read ? (
                      <Link
                        to="/category-dashboard"
                        className={`link-text rounded-3  ${location.pathname === "/category-dashboard"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <span className="text-muted px-2">
                            Brand Categories
                          </span>
                          {permissions?.brand_category?.read &&
                            permissions?.brand_category?.create ? (
                            <span>
                              <Link
                                to="/category-creation"
                                className="link-text"
                              >
                                <PlusCircle />
                              </Link>
                            </span>
                          ) : null}
                        </div>
                      </Link>
                    ) : null}

                    {permissions?.brand?.read ? (
                      <Link
                        to="/brand-dashboard"
                        className={`link-text rounded-3  ${location.pathname === "/brand-dashboard"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <span className="text-muted px-2">Brands</span>
                          {permissions?.brand?.read &&
                            permissions?.brand?.create ? (
                            <span>
                              <Link to="/brand-creation" className="link-text">
                                <PlusCircle />
                              </Link>
                            </span>
                          ) : null}
                        </div>
                      </Link>
                    ) : null}

                    {/*   <Link
                    to="/organization-dashboard"
                    className={`link-text rounded-3  ${location.pathname === "/organization-dashboard"
                      ? "bg-body-color py-2 "
                      : "py-2 "
                      }`}
                  >
                    <span className="text-muted px-2">Organization</span>
                  </Link>*/}
                    {/*  <Link
                    to="/store-dashboard"
                    className={`link-text rounded-3  ${location.pathname === "/store-dashboard"
                      ? "bg-body-color py-2 "
                      : "py-2 "
                      }`}
                  >
                    <span className="text-muted px-2">Store</span>
                  </Link>*/}
                  </div>
                </div>
              </Collapse>
            </div>
          ) : null}

          {/* Manage Roles */}
          {permissions?.manage_users_module?.access &&
            usertype?.user_type === "internal" ? (
            <div>
              <div
                className="d-flex flex-row justify-content-between align-items-center py-2 px-2 rounded-3"
                onClick={ManageRolesCollapseHandler}
              >
                <div
                  className="cursor"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne1"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne1"
                >
                  <div className="d-flex align-items-center gap-2">
                    <ManageRoles />
                  </div>
                </div>
                <div className="d-flex dropdown_icon">
                  <div className="cursor">
                    <span className="text-muted fw-normal sidenav_title">
                      Manage Users
                    </span>
                  </div>
                  <div
                    className={`cursor p-0 ${manageRolesCollapse ? "rotate-180" : ""
                      }`}
                    aria-controls="example-collapse-text"
                    aria-expanded={manageRolesCollapse}
                  >
                    <DropDownIcon
                      className={`cursor p-0 ${manageRolesCollapse ? "rotate-180" : ""
                        }`}
                    />
                  </div>
                </div>
              </div>
              <Collapse in={manageRolesCollapse}>
                <div
                  id="panelsStayOpen-collapseOne1"
                  className="mt-2"
                  aria-labelledby="pane lsStayOpen-headingOne"
                >
                  <div className="d-flex flex-column gap-2 px-4">
                    {permissions?.user?.read ? (
                      <Link
                        to="/users-management/users"
                        className={`link-text rounded-3  ${location.pathname === "/users-management/users"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <span className="text-muted px-2">Users</span>
                      </Link>
                    ) : null}

                    {permissions?.user_role?.read ? (
                      <Link
                        to="/users-management/roles"
                        className={`link-text rounded-3  ${location.pathname === "/users-management/roles"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <span className="text-muted px-2">Roles</span>
                      </Link>
                    ) : null}

                    {permissions?.user?.read && permissions?.user?.create ? (
                      <Link
                        to="/roles-management/user-creation"
                        className={`link-text rounded-3  ${location.pathname === "/5"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <span className="text-muted px-2">Add User</span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </Collapse>
            </div>
          ) : null}

          {/* Order Management */}
          {permissions?.order_management_module?.access &&
            usertype?.user_type === "internal" ? (
            <Link className="link-text" to="/order-management/dashboard">
              <div
                className={`d-flex align-items-center rounded-3 ${location.pathname === "/order-management/dashboard"
                  ? "bg-body-color"
                  : ""
                  }`}
              >
                <div
                  className={`d-flex flex-row align-items-center rounded-3 gap-2 py-2 px-2 cursor`}
                >
                  <IconContext.Provider
                    value={{
                      color: "#6F767E",
                      size: "25px",
                      className: "global-class-name",
                    }}
                  >
                    <FaClipboardList width="50" height="50" />
                  </IconContext.Provider>
                </div>
                <div className="without_dropdown_icon">
                  <span className="text-muted fw-normal sidenav_title">
                    Order Management
                  </span>
                </div>
              </div>
            </Link>
          ) : null}

          {/* Float Management */}
          {permissions?.float_management_module?.access &&
            usertype?.user_type === "internal" &&
            permissions?.all_floats?.read &&
            permissions?.all_floats?.create ? (
            <Link className="link-text" to="/float/float-transactions">
              <div
                className={`d-flex align-items-center rounded-3 ${location.pathname === "/float/float-transactions"
                  ? "bg-body-color"
                  : ""
                  }`}
              >
                <div
                  className={`d-flex flex-row align-items-center rounded-3 gap-2 py-2 px-2 cursor `}
                >
                  <IconContext.Provider
                    value={{
                      color: "#6F767E",
                      size: "25px",
                      className: "global-class-name",
                    }}
                  >
                    <MdAttachMoney width="50" height="50" />
                  </IconContext.Provider>
                </div>
                <div className="without_dropdown_icon">
                  <span className="text-muted fw-normal sidenav_title">
                    Float Management
                  </span>
                </div>
              </div>
            </Link>
          ) : null}

          {/* Partner */}
          {permissions?.partners_module?.access &&
            usertype?.user_type === "internal" ? (
            <div>
              <div
                className="d-flex flex-row justify-content-between align-items-center py-2 px-2 rounded-3"
                onClick={CustomerCollapseHandler}
              >
                <div
                  className="cursor"
                  data-bs-toggle="collapse"
                  data-bs-target="#suppliers"
                  aria-expanded="true"
                  aria-controls="suppliers"
                >
                  <div className="d-flex align-items-center gap-2">
                    <ManageClient />
                  </div>
                </div>
                <div className="d-flex dropdown_icon">
                  <div className="cursor">
                    <span className="text-muted fw-normal sidenav_title">
                      Partners
                    </span>
                  </div>
                  <div
                    className={`cursor p-0 ${customerCollapse ? "rotate-180" : ""
                      }`}
                    aria-controls="example-collapse-text"
                    aria-expanded={customerCollapse}
                  >
                    <DropDownIcon
                      className={`cursor p-0 ${customerCollapse ? "rotate-180" : ""
                        }`}
                    />
                  </div>
                </div>
              </div>
              <Collapse in={customerCollapse}>
                <div
                  id="suppliers"
                  className="mt-2"
                  aria-labelledby="suppliers"
                >
                  <div className="d-flex flex-column gap-2 px-4">
                    {permissions?.all_suppliers?.create ||
                      permissions?.all_clients?.create ||
                      permissions?.all_organization?.create ? (
                      <Link
                        to="/partner-creation"
                        className={`link-text rounded-3  ${location.pathname === "/patner-creation"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          <span className="text-muted px-2">
                            Add New Partner
                          </span>
                          <Link to="/partner-creation" className="link-text">
                            <PlusCircle />
                          </Link>
                        </div>
                      </Link>
                    ) : null}

                    {permissions?.all_suppliers?.read ? (
                      <Link
                        to="/supplier-mapping-dashboard"
                        className={`link-text rounded-3  ${location.pathname === "/supplier-mapping-dashboard"
                          ? "bg-body-color py-2 "
                          : "py-2 "
                          }`}
                      >
                        <span className="text-muted px-2">All Suppliers</span>
                      </Link>
                    ) : null}

                    {permissions?.all_clients?.read ? (
                      <Link
                        to="/clients-mapping-dashboard"
                        className={`link-text rounded-3  ${location.pathname === "/clients-mapping-dashboard"
                          ? "bg-body-color py-2"
                          : "py-2"
                          }`}
                      >
                        <span className="text-muted px-2">All Clients</span>
                      </Link>
                    ) : null}

                    {permissions?.all_organization?.read ? (
                      <Link
                        to="/organization-mapping-dashboard"
                        className={`link-text rounded-3  ${location.pathname ===
                          "/organization-mapping-dashboard"
                          ? "bg-body-color py-2"
                          : "py-2"
                          }`}
                      >
                        <span className="text-muted px-2">
                          All Organization
                        </span>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </Collapse>
            </div>
          ) : null}

          {/* Reports */}
          {permissions?.reports_module?.access ? (
            <Link className="link-text" to="/reports/reports-analytics">
              <div
                className={`d-flex align-items-center rounded-3 ${location.pathname === "/reports/reports-analytics"
                  ? "bg-body-color"
                  : ""
                  }`}
              >
                <div
                  className={`d-flex flex-row align-items-center rounded-3 gap-2 py-2 px-2 cursor`}
                >
                  <ReportIcon />
                </div>
                <div className="without_dropdown_icon">
                  <span className="text-muted fw-normal sidenav_title">
                    {t("Reports")}
                  </span>
                </div>
              </div>
            </Link>
          ) : null}

          {/* EPOS Module */}
          {permissions?.epos_module?.access && usertype?.user_type === "internal" || usertype?.user_type === "organization" || usertype?.user_type === "organization_store" ? (
            <Link className="link-text" to="/epos/ehadaya-interface">
              <div
                className={`d-flex align-items-center rounded-3 ${location.pathname === "/epos/ehadaya-interface"
                  ? "bg-body-color"
                  : ""
                  }`}
              >
                <div
                  className={`d-flex flex-row align-items-center rounded-3 gap-2 py-2 px-2 cursor`}
                >
                  <CustomerIcon />
                </div>
                <div className="without_dropdown_icon">
                  <span className="text-muted fw-normal sidenav_title">
                    EPOS Module
                  </span>
                </div>
              </div>
            </Link>
          ) : null}

          {/* Settings */}
          {permissions?.settings_module?.access &&
            usertype?.user_type === "internal" ? (
            <div>
              <div
                className="d-flex flex-row justify-content-between align-items-center py-2 px-2 rounded-3"
                onClick={SettingCollapseHandler}
              >
                <div
                  className="cursor"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne3"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne3"
                >
                  <div className="d-flex align-items-center gap-2">
                    <Settings />
                  </div>
                </div>
                <div className="d-flex dropdown_icon">
                  <div className="cursor">
                    <span className="text-muted fw-normal sidenav_title">
                      {t("Settings")}
                    </span>
                  </div>
                  <div
                    className={`cursor p-0 ${settingCollapse ? "rotate-180" : ""
                      }`}
                    aria-controls="example-collapse-text"
                    aria-expanded={settingCollapse}
                  >
                    <DropDownIcon
                      className={`cursor p-0 ${settingCollapse ? "rotate-180" : ""
                        }`}
                    />
                  </div>
                </div>
              </div>
              <Collapse in={settingCollapse}>
                <div
                  id="panelsStayOpen-collapseOne3"
                  className="mt-2"
                  aria-labelledby="pane lsStayOpen-headingOne"
                >
                  <div className="d-flex flex-column gap-2 px-4">
                    <Link
                      to="/language"
                      className={`link-text rounded-3  ${location.pathname === "/language"
                        ? "bg-body-color py-2"
                        : "py-2"
                        }`}
                    >
                      <span className="text-muted px-2">Language</span>
                    </Link>

                    <Link
                      to="/partner-category-dashboard"
                      className={`link-text rounded-3  ${location.pathname === "/partner-category-dashboard"
                        ? "bg-body-color py-2"
                        : "py-2"
                        }`}
                    >
                      <span className="text-muted px-2">Partner Category</span>
                    </Link>
                  </div>

                  {/* <div className="d-flex flex-column gap-2 px-4">
                    <span
                      className="text-muted px-2 py-2 cursor"
                      onClick={() => i18n.changeLanguage("en")}
                    >
                      {t("English")}
                    </span>
                    <span
                      className="text-muted px-2 py-2 cursor"
                      onClick={() => i18n.changeLanguage("ar")}
                    >
                      {t("Arabic")}
                    </span>
                  </div> */}
                </div>
              </Collapse>
            </div>
          ) : null}

          <div className="d-flex align-items-center rounded-3">
            <div
              className="d-flex flex-row align-items-center rounded-3 gap-2 py-2 px-2 cursor"
              onClick={() => props.logout()}
            >
              <IconContext.Provider
                value={{
                  color: "#6F767E",
                  size: "25px",
                  className: "global-class-name",
                }}
              >
                <FiLogOut width="50" height="50" />
              </IconContext.Provider>
            </div>
            <div className="without_dropdown_icon">
              <span className="text-muted fw-normal sidenav_title cursor" onClick={() => props.logout()}>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidenav;
