import {
    GET_SELECT_COLOR_REQUEST,
    GET_SELECT_COLOR_SUCCESS,
    GET_SELECT_COLOR_FAILURE
  } from "./selcolType";
  
  const initialSelectColorState = {
    loading: false,
    selectcolor: [],
    error: "",
  };
  
  const selectColorReducer = (state = initialSelectColorState, action) => {
    switch (action.type) {
      case GET_SELECT_COLOR_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_SELECT_COLOR_SUCCESS:
        return {
          ...state,
          loading: false,
          selectcolor: action.payload,
          error: "",
        };
      case GET_SELECT_COLOR_FAILURE:
        return {
          ...state,
          loading: false,
          selectcolor: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  export default selectColorReducer;
  