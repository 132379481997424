import React, { useEffect } from "react";
import { ReactComponent as Subtract } from "../../assests/svg/Subtract.svg";
import { ReactComponent as FilledIcon } from "../../assests/svg/filledCS.svg";
import { ReactComponent as SellIcon } from "../../assests/svg/filledIcon.svg";
import { ReactComponent as ActivateIcon } from "../../assests/svg/Activate.svg";
import { ReactComponent as TopUpIcon } from "../../assests/svg/topup.svg";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

const EhadayaInterface = () => {
  const { t } = useTranslation();
  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToNext = () => {
    history("/epos/select-store");
  };

  const goToBalance = () => {
    history("/epos/check-balance");
  };

  const goToSell = () => {
    history("/epos/sell-card");
  };

  const goToReports = () => {
    history("/epos/giftcard-reports");
  }

  const goToActivationGiftcard = () => {
    history("/epos/activation");
  };
  const goToTopUpGiftcard = () => {
    history("/epos/topup-giftcard")
  }

  return (
    <div className="d-flex flex-column justify-content-between pt-5 gap-4 px-4 bg-light ">
      <div className="d-flex flex-column justify-content-between w-100">
        <div className="d-flex flex-row justify-content-between align-items-center ">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-32 my-2">{t("eHadaya Interface")}</span>
          </div>
        </div>
        <div className=" d-flex justify-content-between ">
          <span className="font-bolder font-14 text-muted ">
            {/* Store name #1 (ID: 13546) */}
          </span>
          <span className="font-20 font-bold text-light-blue cursor"
            onClick={() => goToReports()}>
            <u>{t("Reports")}</u>
          </span>
        </div>
      </div>

      <div className=" d-flex flex-column justify-content-center gap-4 ">
        <table responsive="xl">
          <tbody>
            <tr className="">
              <td className="col-3">
                <div className="d-flex flex-column gap-3 py-3 bg-white cursor hover-row-border shadow-sm rounded-3 align-items-center mx-3"
                  onClick={() => goToNext()}>
                  <Subtract />
                  <span className="d-flex font-15 font-bold">
                    {t("Redeem Giftcard")}
                  </span>
                  <span className="font-10 text-muted">{t("Redeem your card")}</span>
                </div>

              </td>
              <td className="col-3">
                <div className="d-flex flex-column gap-3 py-3 bg-white cursor hover-row-border shadow-sm rounded-3 align-items-center  mx-3"
                  onClick={() => goToBalance()}>
                  <FilledIcon />
                  <span className="d-flex font-15 font-bold">
                    {t("Check Balance")}
                  </span>
                  <span className="font-10 text-muted">{t("Details & Status")}</span>
                </div>
              </td>
              <td className="col-3">
                <div className="d-flex flex-column gap-3 py-3 bg-white cursor hover-row-border shadow-sm rounded-3 align-items-center  mx-3"
                  onClick={() => goToSell()}>
                  <SellIcon />
                  <span className="d-flex font-15 font-bold">
                    {t("Sell")}
                  </span>
                  <span className="font-10 text-muted">{t("Sell your card")}</span>
                </div>
              </td>
            </tr>
            <tr className="">
              <td className="col-3">
                <div className="d-flex flex-column gap-3 py-3 bg-white cursor hover-row-border shadow-sm rounded-3 align-items-center  mx-3 mt-4"
                  onClick={() => goToActivationGiftcard()}>
                  <ActivateIcon />
                  <span className="d-flex font-15 font-bold">
                    {t("Activation Giftcard")}
                  </span>
                  <span className="font-10 text-muted">{t("Activate your card")}</span>
                </div>
              </td>
              <td className="col-3">
                <div className="d-flex flex-column gap-3 py-3 bg-white cursor hover-row-border shadow-sm rounded-3 align-items-center  mx-3 mt-4"
                  onClick={() => goToTopUpGiftcard()}>
                  <TopUpIcon />
                  <span className="d-flex font-15 font-bold">
                    {t("Topup Giftcard")}
                  </span>
                  <span className="font-10 text-muted">{t("Topup your card")}</span>
                </div>
              </td>
            </tr>

          </tbody>
        </table>

      </div>
      {/* <div className="d-flex flex-column bg-white shadow-sm rounded-3 justify-content-center border border-primary border-3 p-3 m-4 width-65">
          <div className="d-flex justify-content-start font-20 font-bold text-dark-blue"> Update W.e.f. 1/10/2022</div>
          <p className="text-muted font-15">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum
          libero, minus accusantium reiciendis veniam nostrum recusandae quae
          deleniti dolorem officia voluptas ullam cumque a repellendus
          doloremque consequatur minima adipisci et! Lorem ipsum dolor sit amet,
          consectetur adipisicing elit. Lorem ipsum dolor sit amet,consectetur
          adipisicing elit !
        </p>
      </div> */}
    </div>
  );
};

export default EhadayaInterface;
