import React from "react";

const InputField = (props) => {
    const { value, onChange, name, type, placeholder, title, maxLength, disabled, inputRef } = props;

    return (
        <>
            <input
                name={name}
                type={type}
                value={value}
                onChange={(e) => onChange(e)}
                placeholder={placeholder ? placeholder : "Please Enter"}
                className="border border-2 outline-none rounded-card-7 px-2 py-2 w-100 "
                title={title}
                maxLength={maxLength}
                disabled={disabled}
                ref={inputRef ? inputRef : null}
                
            />
        </>
    );
};

export default InputField;
