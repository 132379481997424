import {GET_CLIENT_PRODUCT_REQUEST,
    GET_CLIENT_PRODUCT_SUCCESS,
    GET_CLIENT_PRODUCT_FAILURE,
    GET_GIFTCARD_CLIENT_MAPPING_DATA_REQUEST,
    GET_GIFTCARD_CLIENT_MAPPING_DATA_SUCCESS,
    GET_GIFTCARD_CLIENT_MAPPING_DATA_FAILURE,
    GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID,
    GET_SUPP_SUPPPRICE_CLIENT_GC_REQUEST,
    GET_SUPP_SUPPPRICE_CLIENT_GC_SUCCESS,
    GET_SUPP_SUPPPRICE_CLIENT_GC_FAILURE,
    GET_CLIENT_MAPPED_GIFTCARD_REQUEST,
    GET_CLIENT_MAPPED_GIFTCARD_SUCCESS,
    GET_CLIENT_MAPPED_GIFTCARD_FAILURE
} from "./ClientMappingType";

    const initialState = {
        clientMappingProduct: [],
        loading: true,
        errorMsg: '',
        merchandiseVariants:[],
        clientGiftcardList:[],
        supplierPrice:[],
        clientMappedGiftcardList: []
    }

    const clientsMappingReducer = (state = initialState, action) => {
        switch (action.type) {
            case GET_CLIENT_PRODUCT_REQUEST:
                return {
                    ...state,
                    loading: true,
                    clientMappingProduct: [],
                    errorMsg: '',
                }
              
            case GET_CLIENT_PRODUCT_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    clientMappingProduct: action.payload,
                    errorMsg: '',
                }
            case GET_CLIENT_PRODUCT_FAILURE:
                return {
                    ...state,
                    loading: false,
                    errorMsg: action.payload
                }
                // get giftcard client mapping data
                case GET_GIFTCARD_CLIENT_MAPPING_DATA_REQUEST:
                    return {
                        ...state,
                        loading: true,
                        clientGiftcardList: [],
                        errorMsg: '',
                    }
                  
                case GET_GIFTCARD_CLIENT_MAPPING_DATA_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        clientGiftcardList: action.payload,
                        errorMsg: '',
                    }
                case GET_GIFTCARD_CLIENT_MAPPING_DATA_FAILURE:
                    return {
                        ...state,
                        loading: false,
                        errorMsg: action.payload
                    }

                // to get merchandise variants
                case GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID:
                    return {
                        ...state,
                        loading: false,
                        merchandiseVariants : action.payload,
                        errorMsg: '',
                    }

                    // get supplier name and price
                    case GET_SUPP_SUPPPRICE_CLIENT_GC_REQUEST:
                        return {
                            ...state,
                            loading: true,
                            supplierPrice: [],
                            errorMsg: '',
                        }
                      
                    case GET_SUPP_SUPPPRICE_CLIENT_GC_SUCCESS:
                        return {
                            ...state,
                            loading: false,
                            supplierPrice: action.payload,
                            errorMsg: '',
                        }
                    case GET_SUPP_SUPPPRICE_CLIENT_GC_FAILURE:
                        return {
                            ...state,
                            loading: false,
                            errorMsg: action.payload
                        }
                    case GET_CLIENT_MAPPED_GIFTCARD_REQUEST:
                        return {
                            ...state,
                            loading: true,
                            clientMappingProduct: [],
                            errorMsg: '',
                        }
                    case GET_CLIENT_MAPPED_GIFTCARD_SUCCESS:
                        return {
                            ...state,
                            loading: false,
                            clientMappedGiftcardList: action.payload,
                            errorMsg: '',
                        }
                    case GET_CLIENT_MAPPED_GIFTCARD_FAILURE: 
                    return {
                        ...state,
                        loading: false,
                        clientMappedGiftcardList: [],
                        errorMsg: action.payload,
                    }
            default: return state
        }
    }
    
    export default clientsMappingReducer;