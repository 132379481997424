import React, { useState, useEffect } from "react";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import Button from "../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import { redeemGiftcard } from "../../../Redux/Epos/eposAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const GiftcardTopUpDetail = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();


  const { details } = props;
  const giftcardDetails = useSelector((state) => state.eposState);

  const [fields, setFields] = useState({
    topupAmount: "",
  });

  const [error, setError] = useState({
    topupAmount: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let regex = /^\d{0,4}(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      setFields({ ...fields, [name]: value });
      if (fields.topupAmount) {
        setError({ topupAmount: "" });
        return false;
      }
    }

  };

  const validate = () => {
    if (!fields.topupAmount) {
      setError({ topupAmount: t("Please enter Topup amount") });
      return false;
    }
    return true;
  };

  const Submit = () => {
    let val = validate();
    let payload = {
      "giftcard_number": giftcardDetails?.giftcardDetails?.giftcard?.id,
      "transaction_type": "recharge",
      "transaction_amount": fields.topupAmount ? fields.topupAmount : 0,
      "partner_id": giftcardDetails?.storeDetails?.organization?.id ? giftcardDetails?.storeDetails?.organization?.id : "",
      "partner_sub_type_id": giftcardDetails?.storeDetails?.selectedStore?.id ? giftcardDetails?.storeDetails?.selectedStore?.id : "",
    }
    console.log(payload)
    if (val) {
      dispatch(redeemGiftcard(payload, goToSuccess))
    }
  };

  const goToSuccess = () => {
    history("/epos/redeem-success");
  };


  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3  px-4 gap-3 w-100 p-3">
      <div className="d-flex flex-column justify-content-between gap-2">
        <div className="d-flex flex-column gap-3 mt-4 ">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Topup amount")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Enter topup amount"}
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                type="text"
                name="topupAmount"
                placeholder={t("Please enter name")}
                value={fields.topupAmount}
                onChange={changeHandler}
              />
              {error.topupAmount && !fields.topupAmount ? (
                <label className="text-error">{error.topupAmount}</label>
              ) : null}
            </div>
          </div>

        </div>
      </div>

      <div className="d-flex gap-2">
        <Button onClick={() => Submit()} text={t("Submit")} type="primary" />
      </div>
    </div>
  )
}

export default GiftcardTopUpDetail