import {
    GET_PRODUCT_TYPE_REQUEST,
    GET_PRODUCT_TYPE_SUCCESS,
    GET_PRODUCT_TYPE_FAILURE
} from './prodtypeType';


const initialProductTypeState = {
    loading: false,
    products: [],
    error: ''
}

const productTypeReducer = (state = initialProductTypeState, action) => {

    switch (action.type) {
        case GET_PRODUCT_TYPE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_PRODUCT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.payload,
                error: ''

            }
        case GET_PRODUCT_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                products: [],
                error: action.payload
            }
        default: return state
    }
}
export default productTypeReducer;