/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import ClientDistributionTable from "../../../components/Distribution/ClientDistributionTable";
import { fetchClient } from "../../../Redux/Client/clientAction";
import { removeCreateOrderData } from "../../../Redux/CreateOrder/CreateOrderAction";
import { removeGiftcardDetails } from "../../../Redux/Distributions/ClientGiftcardDistributions/ClientGiftcardDistributionAction";

const ClientDistributDashBoard = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const clientsState = useSelector((state) => state?.clients?.client);


  useEffect(() => {
    window.scrollTo(0, 0);

    if (clientsState.length === 0) {
      dispatch(fetchClient());
    }
    dispatch(removeCreateOrderData());
    dispatch(removeGiftcardDetails());

  }, []);

  const goBack = () => {
    history("/gift-card-distribution");
  };

  return (
    <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-40 font-bold">Client distribution</span>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-start gap-2">
        <ClientDistributionTable clientsState={clientsState} />
      </div>
    </div>
  );
};

export default ClientDistributDashBoard;
