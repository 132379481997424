
// Merchandise sample file download
export const GET_SAMPLE_BULK_UPLOAD_MER_REQUEST = "GET_SAMPLE_BULK_UPLOAD_MER_REQUEST";
export const GET_SAMPLE_BULK_UPLOAD_MER_SUCCESS = "GET_SAMPLE_BULK_UPLOAD_MER_SUCCESS";
export const GET_SAMPLE_BULK_UPLOAD_MER_FAILURE = "GET_SAMPLE_BULK_UPLOAD_MER_FAILURE";

// Merchandise bulk upload file 
export const POST_MERCHANDISE_BULK_UPLOAD_REQUEST = "POST_MERCHANDISE_BULK_UPLOAD_REQUEST";
export const POST_MERCHANDISE_BULK_UPLOAD_SUCCESS = "POST_MERCHANDISE_BULK_UPLOAD_SUCCESS";
export const POST_MERCHANDISE_BULK_UPLOAD_FAILURE = "POST_MERCHANDISE_BULK_UPLOAD_FAILURE";

export const POST_MERCHANDISE_BULK_UPLOAD_ERROR = "POST_MERCHANDISE_BULK_UPLOAD_ERROR";
export const CLEAR_MERCHANDISE_BULK_UPLOAD_ERROR = "CLEAR_MERCHANDISE_BULK_UPLOAD_ERROR";

// Giftcard sample file download
export const GET_SAMPLE_BULK_UPLOAD_GIFTCARD_REQUEST = "GET_SAMPLE_BULK_UPLOAD_MER_REQUEST";
export const GET_SAMPLE_BULK_UPLOAD_GIFTCARD_SUCCESS = "GET_SAMPLE_BULK_UPLOAD_GIFTCARD_SUCCESS";
export const GET_SAMPLE_BULK_UPLOAD_GIFTCARD_FAILURE = "GET_SAMPLE_BULK_UPLOAD_GIFTCARD_FAILURE";


// Giftcard bulk upload file 
export const POST_GIFTCARD_BULK_UPLOAD_REQUEST = "POST_GIFTCARD_BULK_UPLOAD_REQUEST";
export const POST_GIFTCARD_BULK_UPLOAD_SUCCESS = "POST_GIFTCARD_BULK_UPLOAD_SUCCESS";
export const POST_GIFTCARD_BULK_UPLOAD_FAILURE = "POST_GIFTCARD_BULK_UPLOAD_FAILURE";


export const POST_GIFTCARD_BULK_UPLOAD_ERROR = "POST_GIFTCARD_BULK_UPLOAD_ERROR";
export const CLEAR_GIFTCARD_BULK_UPLOAD_ERROR = "CLEAR_GIFTCARD_BULK_UPLOAD_ERROR";

