import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import ReactSwitchButton from "../../../components/Button/ReactSwitchButton";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAllCountriesList } from "../../../Redux/Countries/countryAction";
import { postSellGiftcard } from "../../../Redux/Epos/eposAction";
import { useTranslation } from "react-i18next";

function BuyerSellerInfo() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputElementName = useRef(null);
  const inputElementEmail = useRef(null);
  const inputElementPhoneNo = useRef(null);

  const sellCardData = useSelector((state) => state.eposState.sellCardData);
  const countries = useSelector((state) => state.countries.countries);

  const [fields, setFields] = useState({
    buyerLastName: "",
    buyerEmail: "",
    buyerPhone: "",
    sellerFirstName: "",
    sellerLastName: "",
    sellerEmail: "",
    sellerPhone: "",
  });

  const [dropDownObj, setDropDownObj] = useState({
    buyerCountry: "",
    sellerCountry: "",
  });

  const [switchButton, setSwitchButton] = useState({ Active: true });

  const [error, setError] = useState({
    buyerLastName: "",
    buyerCountry: "",
    buyerEmail: "",
    buyerPhone: "",
    sellerFirstName: "",
    sellerLastName: "",
    sellerCountry: "",
    sellerEmail: "",
    sellerPhone: "",
  });

  useEffect(() => {
    if(!sellCardData){
      history("/epos/sell-card")
    }
  },[sellCardData])

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCountriesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeBuyerCountry = (e) => {
    setDropDownObj({ ...dropDownObj, buyerCountry: e });
  };

  const handleChangeSellerCountry = (e) => {
    setDropDownObj({ ...dropDownObj, sellerCountry: e });
  };

  const changeHandler = (e) => {
    const value = e.target.value;
    Object.keys(fields).map((item) => {
      if (item === e.target.name) {
        let regex = /^\d{0,15}?$/;
        if (regex.test(e.target.value)) {
          setFields({
            ...fields,
            [e.target.name]: value,
          });
        }
      } else if (e.target.name !== "buyerPhone") {
        setFields({
          ...fields,
          [e.target.name]: value,
        });
      }
    })
    // setFields({ ...fields, [e.target.name]: value });
  };

  const changeSellerHandler = (e) => {
    const value = e.target.value;
    Object.keys(fields).map((item) => {
      if (item === e.target.name) {
        let regex = /^\d{0,15}?$/;
        if (regex.test(e.target.value)) {
          setFields({
            ...fields,
            [e.target.name]: value,
          });
        }
      } else if (e.target.name !== "sellerPhone") {
        setFields({
          ...fields,
          [e.target.name]: value,
        });
      }
    })
    // setFields({ ...fields, [e.target.name]: value });
  };

  const switchHandle = () => {
    setSwitchButton(!switchButton);
  };

  const validate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!fields.buyerLastName) {
      setError({ buyerLastName: t("Please enter last name") });
      if(inputElementName.current){
        inputElementName.current.focus()
      }
      return false;
    } else if (!fields.buyerEmail) {
      setError({ buyerEmail: t("Please enter email") });
      if(inputElementEmail.current){
        inputElementEmail.current.focus()
      }
      return false;
    }  else if (
      !fields.buyerEmail ||
      emailRegex.test(fields.buyerEmail) === false
    ) {
      setError({ buyerEmail: t("Please enter valid email id") });
      return false;
    } else if (!fields.buyerPhone) {
      setError({ buyerPhone: t("Please enter phone number") });
      if(inputElementPhoneNo.current){
        inputElementPhoneNo.current.focus()
      }
      return false;
    } 
    // else if (!fields.sellerFirstName) {
    //   setError({ sellerFirstName: t("Please enter first name") });
    //   return false;
    // } else if (!fields.sellerLastName) {
    //   setError({ sellerLastName: t("Please enter last name") });
    //   return false;
    // } else if (!fields.sellerEmail) {
    //   setError({ sellerEmail: t("Please enter email") });
    //   return false;
    // } else if (
    //   !fields.sellerEmail ||
    //   emailRegex.test(fields.sellerEmail) === false
    // ) {
    //   setError({ sellerEmail: t("Please enter valid email id") });
    //   return false;
    // } 
    return true;
  };

  const sellGiftcardSuccess = () => {
    return history("/epos/card-successfull");
  }

  const submit = () => {
    let val = validate();

    const obj = {
      order_by: sellCardData?.selectedOrganization?.partner_category,
      buyer_id: sellCardData?.selectedOrganization?.id,
      product_type: sellCardData?.selectedProduct?.giftcard?.registered_by_id,
      product_id: sellCardData?.selectedProduct?.giftcard?.id,
      product_variant_id: "string",
      product_value: 0,
      inventory_type_id: "string",
      quantity: 0,
      order_price: 0,
      discount: 0,
      total_price: 0,
      delivery_type: "physical",
      digital_delivery_option: "separate_email_for_each_giftcard",
      delivery_address_line_1: "string",
      delivery_address_line_2: "string",
      email_id: fields?.buyerEmail,
      phone_number: fields?.buyerPhone,
      receiver: {
        receiver_type: "store",
        receiver_id: sellCardData?.selectedStore?.id,
      },
      purchaser: {
        name: fields?.buyerLastName,
        email: fields?.buyerEmail,
        phone_no: fields?.buyerPhone,
      },
      store_user_id: "string",
    };
    
    if (val) {
      dispatch(postSellGiftcard(obj, sellGiftcardSuccess))
    }
  };

  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light width-65">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Sell Card")}</span>
        </div>
      </div>

      <div className="d-flex flex-column bg-white rounded-3 justify-content-start py-3 gap-3 px-4 ">
        <div className="d-flex flex-row justify-content-between gap-3">
          <div className="d-flex flex-column w-100 gap-3">
            <div className="d-flex">
              <span className="font-bold font-20">{t("Purchase info")}</span>
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Name")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
              inputRef={inputElementName}
                placeholder={t("Enter your name")}
                name="buyerLastName"
                value={fields.buyerLastName}
                onChange={(e) => changeHandler(e)}
              />
              {!fields.buyerLastName && error.buyerLastName ? (
                <label className="text-error">{error.buyerLastName}</label>
              ) : null}
            </div>

            {/* <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Country<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <Select
                  noOptionsMessage={() => "Not found"}
                  placeholder="Select country name"
                  getOptionLabel={(countries) => countries.name}
                  options={countries}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={dropDownObj.buyerCountry}
                  onChange={handleChangeBuyerCountry}
                  isClearable
                />
                {!dropDownObj.buyerCountry && error.buyerCountry ? (
                  <label className="text-error">{error.buyerCountry}</label>
                ) : null}
              </div>
            </div> */}

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Email")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
              inputRef={inputElementEmail}
                placeholder={t("Enter email")}
                name="buyerEmail"
                value={fields.buyerEmail}
                onChange={(e) => changeHandler(e)}
              />
              {error.buyerEmail ? (
                <label className="text-error">{error.buyerEmail}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Phone number")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
              inputRef={inputElementPhoneNo}
                placeholder={t("Enter phone number")}
                type="number"
                name="buyerPhone"
                value={fields.buyerPhone}
                maxLength={15}
                onChange={(e) => changeHandler(e)}
              />
              {!fields.buyerPhone && error.buyerPhone ? (
                <label className="text-error">{error.buyerPhone}</label>
              ) : null}
            </div>
          </div>

          {/* ########################Seller############################# */}

            {/* <div className="d-flex flex-column w-100 gap-3">
              <div className="d-flex">
                <span className="font-bold font-20">Receiver info</span>
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    First name<span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <InputField
                  placeholder={"Enter first name"}
                  name="sellerFirstName"
                  value={fields.sellerFirstName}
                  onChange={(e) => changeSellerHandler(e)}
                />
                {!fields.sellerFirstName && error.sellerFirstName ? (
                  <label className="text-error">{error.sellerFirstName}</label>
                ) : null}
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    Last name<span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <InputField
                  placeholder={"Enter last name"}
                  name="sellerLastName"
                  value={fields.sellerLastName}
                  onChange={(e) => changeSellerHandler(e)}
                />
                {!fields.sellerLastName && error.sellerLastName ? (
                  <label className="text-error">{error.sellerLastName}</label>
                ) : null}
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    Email<span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <InputField
                  placeholder={"Enter email"}
                  name="sellerEmail"
                  value={fields.sellerEmail}
                  onChange={(e) => changeSellerHandler(e)}
                />
                {error.sellerEmail ? (
                  <label className="text-error">{error.sellerEmail}</label>
                ) : null}
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    Phone number<span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <InputField
                  placeholder={"Enter phone number"}
                  type="number"
                  name="sellerPhone"
                  value={fields.sellerPhone}
                  maxLength={15}
                  onChange={(e) => changeSellerHandler(e)}
                />
                {!fields.sellerPhone && error.sellerPhone ? (
                  <label className="text-error">{error.sellerPhone}</label>
                ) : null}
              </div>
            </div> */}
        </div>

        <div className="d-flex flex-row justify-content-between w-50 px-1 gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-13">
              {t("Owner same as Buyer")}?<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <ReactSwitchButton
            onChange={() => switchHandle()}
            value={switchButton}
          />
        </div>
        <div className="d-flex py-1 px-4">
          <Button onClick={submit} text={t("Sell")} type="primary" />
        </div>
      </div>
    </div>
  );
}

export default BuyerSellerInfo;
