import {
  GET_ALL_REDEMPTION_METHOD_REQUEST,
  GET_ALL_REDEMPTION_METHOD_SUCCESS,
  GET_ALL_REDEMPTION_METHOD_FAILURE
} from './redemptionType'

import { baseUrl, getToken } from "../../utils/prodURL";
import axios from "axios";


// Redemption Method
export const getAllRedemptionMethodRequest = () => {
  return {
    type: GET_ALL_REDEMPTION_METHOD_REQUEST,
  };
};
export const getAllRedemptionMethodSuccess = (redemptionmethod) => {
  return {
    type: GET_ALL_REDEMPTION_METHOD_SUCCESS,
    payload: redemptionmethod,
  };
};
export const getAllRedemptionMethodFailure = (error) => {
  return {
    type: GET_ALL_REDEMPTION_METHOD_FAILURE,
    payload: error,
  };
};

export const getAllRedemptionMethod = () => {
  return (dispatch) => {
    dispatch(getAllRedemptionMethodRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/redemption_method`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    //  console.log(config);
    axios(config)
      .then((response) => {
        //  console.log("response" + JSON.stringify(response.data));
        dispatch(getAllRedemptionMethodSuccess(response.data))
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getAllRedemptionMethodFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};