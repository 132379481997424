import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import Button from "../Button/Button";
import InfoTooltip from "../Tooltip/InfoTooltip";
import BackButton from "../Button/BackButton";
import DragandDropMultiple from "../DragandDrop/DragandDropMultiple";
import { setMerchandiseVariants } from "../../Redux/MerchandiseVarient/merchandiseVarientActions";
import { useDispatch, useSelector } from "react-redux";

const AddVarientImage = () => {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const merchandiseCatAttriState = useSelector((state) => state?.merchandiseCatAttriData?.merchandiseCatAtt);
  const merchandiseVarientState = useSelector((state) => state?.merchandiseVarient?.merchandiseVarient);


  // const [productImg, setProductImg] = useState([]);
  const [prodObj, setProdObj] = useState(location.state);
  const [images, setImages] = useState([]);


  const goTonextStep = () => {
   // history(-1);
    addImagesInObj();
  };

  const addImagesInObj = () => {

    // let arr = [merchandiseVarientState];
    // merchandiseVarientState && merchandiseVarientState.map((item, i) => {
    //   if (location.state.index === i) {
    //     arr[i] = prodObj;
    //   } else {
    //     arr[i] = item;
    //   }
    // })
   // dispatch(setMerchandiseVariants());
  }

  const goBack = () => {
    history("/merchandise-dashboard"); 
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setProdObj(location.state.prodVariant)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!merchandiseCatAttriState) {
      history("/add-merchandise");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchandiseCatAttriState]);

  const setProductImg = (e) => {
    setProdObj({ ...prodObj, images: e });
  }

  return (
    <div className="d-flex flex-column justify-content-start py-4  px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center py-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">Add variant image</span>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-2 px-4 width-65">
          <div className="d-flex flex-row justify-content-between align-items-center my-2">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-20 font-bold">{prodObj.title + " | " + prodObj.varientName}</span>
            </div>
            <div>
              <BackButton onClick={() => history(-1)} />
            </div>
          </div>

          <div className="d-flex flex-column gap-4 mt-4 ">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Upload images<span className="text-danger">*</span>
                  <span> (size should be 600*200)</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <DragandDropMultiple files={images} setFiles={setImages} />
            </div>
          </div>
          <div className="d-flex my-4">
            <Button
              onClick={() => goTonextStep()}
              text="Save Variant Image"
              type="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVarientImage;
