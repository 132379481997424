import React, { Component } from 'react';
import ErrorModel from '../Models/ErrorModel';

class ErrorBoundary extends Component {
    constructor(props){
        super(props)

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error){
        return {
            hasError: true
        }
    }
    
    render() {
       if(this.state.hasError){
        return <ErrorModel />
       }

       return this.props.children
    }
}

export default ErrorBoundary;