import axios from "axios";
import {GET_DENOMINATION_REQUEST,
    GET_DENOMINATION_SUCCESS,
    GET_DENOMINATION_FAILURE } from "./denominationType";
import { baseUrl, getToken } from '../../utils/prodURL';


    export const getDenominationRequest = () => {
        return {
            type: GET_DENOMINATION_REQUEST,
        }
    }
    
    export const getDenominationSuccess = data => {
        return {
            type: GET_DENOMINATION_SUCCESS,
            payload: data
        }
    }
    
    export const getDenominationFailure = error => {
        return {
            type: GET_DENOMINATION_FAILURE,
            payload: error
        }
    }
    
    export const getDenomination = (id) => {
        return (dispatch) => {
            dispatch(getDenominationRequest);
            let config = {
                method: "get",
                url: `${baseUrl}/v1/giftcards/${id}/denomination`,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            };
            axios(config)
                .then((response) => {
                    // console.log("response" + JSON.stringify(response.data.result));
                    dispatch(getDenominationSuccess(response.data.result))
                })
                .catch((error) => {
                    //  const errorMsg = error;
                    dispatch(getDenominationFailure("Something went wrong!! Please try again!!"));
                });
        }
    }