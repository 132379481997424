import React, { useEffect } from "react";
import Button from "../../components/Button/Button";
import SelectSupplier from "../../components/Supplier/SelectSupplier";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchAllGiftCardList } from "../../Redux/GiftCard/giftcardAction";

const SupplierDashboard = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const goToCreateSupplier = () => {
    history("/patner-creation");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchAllGiftCardList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">Supplier mapping</span>
        </div>
        <div className="mb-3">
          <Button
            type="primary"
            text="+Add Supplier"
            onClick={() => goToCreateSupplier()}
          />
        </div>
      </div>
      <div className="d-flex ">
        <SelectSupplier />
      </div>
    </div>
  );
};

export default SupplierDashboard;
