/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import React, { useState } from "react";
import Button from "../Button/Button";
import DragandDropExcel from "../DragandDrop/DragandDropExcel";
import DownloadSampleExcel from "../Inventory/DownloadSampleExcel";
import InfoTooltip from "../Tooltip/InfoTooltip";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import {
  clearBulkUploadMerchandiseErros,
  getSampleBulkUpload,
  postBulkUploadMerchandise,
} from "../../Redux/BulkUpload/bulkUploadAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import { useEffect } from "react";
import { fetchSupplierList } from "../../Redux/Supplier/supplierAction";
import Toasts from "../Toasts/Toasts";
import ModalComp from "../Models/ModalComp";
import ErrorHandlerCard from "./ErrorHandlerCard";

const MerchandiseBulkUpload = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const suppliersListState = useSelector((state) => state?.suppliers?.supplier);

  //   getting merchandise bulk upload ERRORS
  const MerchandiseBulkUploadErrors = useSelector(
    (state) => state?.merchandiseBulkUpload?.bulkUploadMerchandiseErros
  );

  const [fields, setFields] = useState({
    productType: "",
    supplier: "",
  });

  const [fileUpload, setFileUpload] = useState([]);
  const [showToast, setShowToast] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [merchandiseBulkUploadPostErrors, setMerchandiseBulkUploadPostErrors] =
    useState([]);

  const toggleShowToast = () => setShowToast(!showToast);

  const handleClose = () => {
    setShowModal(false);
    setMerchandiseBulkUploadPostErrors([]);
    dispatch(clearBulkUploadMerchandiseErros());
  };
  
  useEffect(() => {
    console.log("showToast ", showToast)
  }, [showToast])

  // File upload success Toast Display
  // useEffect(() => {
  //   if (MerchandiseBulkUploadErrors.code === 200) {
  //     toggleShowToast();
  //   }
  // }, [MerchandiseBulkUploadErrors]);

  useEffect(() => {
    let errObj = MerchandiseBulkUploadErrors?.errors;
    let errArray = [];
    let errorsKeyValueArrya = [];

    if (MerchandiseBulkUploadErrors.code === 422) {
      for (let errors in errObj) {
        errorsKeyValueArrya.push({ errors: errObj[errors] });

        if (errors) {
          errArray.push(` ${errors}: ${errObj[errors]}. `);
        }
      }
    } else {
      MerchandiseBulkUploadErrors?.errors !== undefined &&
        MerchandiseBulkUploadErrors?.errors !== null &&
        errorsKeyValueArrya.push({ errors: errObj });
    }

    setMerchandiseBulkUploadPostErrors(errorsKeyValueArrya);
  }, [MerchandiseBulkUploadErrors]);

  // Showing ERROR Model
  useEffect(() => {
    if (merchandiseBulkUploadPostErrors.length > 0) {
      setShowModal(true);
    }
  }, [merchandiseBulkUploadPostErrors]);

  useEffect(() => {
    dispatch(fetchSupplierList());
  }, []);

  const goToSuccessfull = () => {
    history("/bulk-upload/merchandise-success");
  };

  //   supplier dropdown handler
  const handleChangeProduct = (e) => {
    setFields({ ...fields, supplier: e });
  };

  const bulkUplodMerchandiseHandler = () => {
    console.log("upload function call")
    if (fileUpload.length > 0) {
      dispatch(postBulkUploadMerchandise(fileUpload, goToSuccessfull));
    }

    if (fileUpload.length === 0) {
      toggleShowToast();    
    }
    
  };

  return (
    <div className="d-flex flex-row justify-content-between gap-3">
      <div className="d-flex width-65">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-3 mb-3">
              <Tag />
              <span className="font-20 font-bold">
                {t("Merchandise Product Upload")}
              </span>
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Select Supplier")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip title={"Select Supplier"} />
            </div>

            <div>
              <Select
                placeholder={t("Select supplier")}
                noOptionsMessage={() => "Not found"}
                getOptionLabel={(suppliersListState) =>
                  suppliersListState.partner_name
                }
                options={suppliersListState}
                styles={CUSTOM_DROPDOWN_STYLE}
                value={fields.supplier}
                onChange={handleChangeProduct}
                isClearable
              />
              {/* {!fields.supplier && error.supplier ? (
                  <label className="text-error">{error.supplier}</label>
                ) : null} */}
            </div>
          </div>

          <div className="d-flex flex-column gap-3 mt-3">
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Upload excel")}</span>
                  <InfoTooltip title={"Upload manual inventory excel file"} />
                </div>
                <div className="d-flex flex-column">
                  <DragandDropExcel
                    files={fileUpload}
                    setFiles={setFileUpload}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex mt-4 mb-2">
            <Button
              onClick={() => bulkUplodMerchandiseHandler()}
              text={"Upload"}
              type="primary"
            />
          </div>
        </div>
      </div>
      <div className="d-flex width-35 h-100">
        <DownloadSampleExcel
          getSampleFileApiFunction={getSampleBulkUpload}
          description={
            "Make sure you follow the same format as shown in the below excel for bulk upload"
          }
          secondTitle={"Download merchandise excel"}
        />
      </div>

      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg={
            fileUpload.length === 0
              ? "Please upload a file"
              : MerchandiseBulkUploadErrors?.errors
              ? merchandiseBulkUploadPostErrors.map((item) => item)
              : `${"Inventory uploaded successfully."}`
          }
          imageType={
            MerchandiseBulkUploadErrors?.errors
              ? "warning"
              : fileUpload.length === 0
              ? "warning"
              : "success"
          }
        />
      </div>
      {merchandiseBulkUploadPostErrors.length > 0 && (
        <ModalComp
          size="lg"
          showModal={showModal}
          // handleClose={handleClose}
          component={
            <ErrorHandlerCard
              handleClose={handleClose}
              errors={
                merchandiseBulkUploadPostErrors &&
                merchandiseBulkUploadPostErrors
              }
            />
          }
        />
      )}
    </div>
  );
};

export default MerchandiseBulkUpload;
