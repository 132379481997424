import "./App.css";
import "./assests/css/CustomeStyle.css";
import Routing from "./routes/index";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import React, { useEffect, useState } from "react";
import store from "./Redux/store";
import OfflineComponent from "./components/Offline/Offline";

function App() {
  const { i18n } = useTranslation();
  const [networkStatus, setNetworkStatus] = useState(true);

  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);

  useEffect(() => {
    window.addEventListener("online", function (e) {
      setNetworkStatus(true)
    });

    window.addEventListener("offline", function (e) {
      setNetworkStatus(false)

    });
  }, []);

  return (
    <>
      {networkStatus ?
        <Provider store={store}>
          <Routing />
        </Provider> :
        <OfflineComponent />}
    </>
  );
}

export default App;
