import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddProduct } from "../../assests/svg/Dashboard/add_product.svg";
import { ReactComponent as RightIcon } from "../../assests/svg/Dashboard/right_arrow.svg";
import { ReactComponent as Phone } from "../../assests/svg/Dashboard/phone.svg";
import { ReactComponent as CameraIcon } from "../../assests/svg/Dashboard/camera_icon.svg";
import { ReactComponent as Pencile } from "../../assests/svg/Dashboard/pencile.svg";
import { ReactComponent as Left } from "../../assests/svg/Dashboard/left.svg";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/green_tag.svg";

const QuickAccess = () => {
  const { t } = useTranslation();

  const [permissions, setpermissions] = useState();
  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    // console.log(permissions);
  }, [permissions]);

  return (
    <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-3 px-4 w-100 gap-4">
      <div className="d-flex gap-3">
        <GreenTag />
        <span className="font-20 font-bold">{t("Quick Access")}</span>
      </div>
      <div className="d-flex">
        <span className="text-muted">
          {t("Quickly access the most relevant tools")}
        </span>
      </div>

      <div className="d-flex">
        <div className="d-flex flex-column col gap-4">
          {permissions?.giftcard?.create ? (
            <div className="d-flex flex-row gap-3">
              <div>
                <AddProduct />
              </div>
              <div className="d-flex flex-column ">
                <Link
                  to="/product-creation/name-description"
                  className="link-text"
                >
                  <span className="font-15 font-bolder">{t("Add a product")}</span>
                </Link>
                <span className="font-12 font-bold text-dark bg-02 bg-opacity-25 rounded-3 px-2">
                  Last added 3d ago
                </span>
              </div>
            </div>
          ) : null}

          <div className="d-flex flex-row gap-3">
            <div>
              <Pencile />
            </div>
            <div className="d-flex flex-column ">
              <span className="font-15 font-bolder">{t("Manage roles")}</span>
              <span> </span>
            </div>
          </div>
          <div className="d-flex flex-row gap-3">
            <div>
              <Phone />
            </div>
            <div className="d-flex flex-column ">
              <span className="font-15 font-bolder">{t("Button Example")}</span>
              <span className="font-12 font-bold text-dark bg-04 rounded-3 px-2 fit-content">
                Popular
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column col gap-4">
          {permissions?.all_suppliers?.create ||
          permissions?.all_clients?.create ||
          permissions?.all_organization?.create ? (
            <div className="d-flex flex-row gap-3">
              <div>
                <Left />
              </div>
              <div className="d-flex flex-column ">
                <Link to="/patner-creation" className="link-text">
                  <span className="font-15 font-bolder">{t("Add a partner")}</span>
                </Link>

                <span className="font-12 font-bold text-dark bg-04 rounded-3 fit-content px-2">
                  Latest client: Amway
                </span>
              </div>
            </div>
          ) : null}

          <div className="d-flex flex-row gap-3">
            <div>
              <CameraIcon />
            </div>
            <div className="d-flex flex-column ">
              <span className="font-15 font-bolder">{t("Learn about Hadaya")}</span>
              <span className="font-12 font-bold text-dark bg-danger bg-opacity-25 rounded-3 px-2 fit-content">
                YouTube
              </span>
            </div>
          </div>
          <div className="d-flex flex-row gap-3 pr-3">
            <div>
              <RightIcon />
            </div>
            <div className="d-flex flex-column ">
              <span className="font-15 font-bolder">{t("Button Example")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickAccess;
