/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import InfoTooltip from "../Tooltip/InfoTooltip";
import InputField from "../InputField/InputField";
import InputGroupTxt from "../InputField/InputGroupTxt";
import TagsInput from "../TagsInput/TagsInput";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import DragandDropPDF from "../DragandDrop/DragandDropPDF";
import ReactDatePicker from "../DatePicker/ReactDatePicker";
import Button from "../Button/Button";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import Select from "react-select";
import ReactSwitchButton from "../Button/ReactSwitchButton";
import { fetchClient } from "../../Redux/Client/clientAction";
import {
  getPratnerFloats,
  postFloatClient,
} from "../../Redux/Float/floatAction";
import PulseLoader from "../Loader/PulseLoader";
import { useTranslation } from "react-i18next";

const FloatDetails = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const inputElementClient = useRef(null)
  const inputElementCurrency = useRef(null)
  const inputElementFloatAmount = useRef(null)

  const partner = useSelector(
    (state) => state?.partners?.createPartnerResponese
  );
  const clientsState = useSelector((state) => state?.clients?.client);
  const floatCurrency = useSelector((state) => state?.partnerFloat?.floats);
  const loading = useSelector((state) => state?.partnerFloat?.loading);

  const [documentation, setDocumentations] = useState([]);
  const [emailList, setEmailList] = useState([]);

  const [fields, setFields] = useState({
    client: partner ? partner : location?.state?.selectedClient,
    currency: "",
    floatAmount: "",
    paymentReceivedOn: new Date(),
    percentageNotificationLimit: "",
    amountNotificationLimit: "",
  });

  const [switchButton, setSwitchButton] = useState({
    notificationsFlag: false,
    percentageNotificationLimitFlag: false,
    amountNotificationLimitFlag: false,
  });

  const [error, setError] = useState({
    client: "",
    currency: "",
    floatAmount: "",
    paymentReceivedOn: "",
    documentation: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchClient());
    dispatch(getAllCountriesList());
    if (partner?.id) {
      dispatch(getPratnerFloats(partner?.id));
    }
    if (partner?.email) {
      setEmailList([partner?.email]);
    }
    if (location?.state?.selectedClient?.email) {
      setEmailList([location?.state?.selectedClient?.email]);
    }
  }, []);

  const handleChangeClient = (e) => {
    setFields({ ...fields, client: e, currency: "" });
    setEmailList([e?.email]);
    dispatch(getPratnerFloats(e?.id));
  };

  const handleChangeCurrency = (e) => {
    setFields({ ...fields, currency: e });
  };

  const handleChangeValue = (item, type) => {
    setFields({ ...fields, [type]: item });
  };

  const inputChangeHandle = (e) => {
    if (e.target.name === "percentageNotificationLimit") {
      let regex = /^\d{0,2}(\.\d{0,2})?$/;
      if (regex.test(e.target.value)) {
        setFields({
          ...fields,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      let regex = /^\d{0,8}(\.\d{0,2})?$/;
      if (regex.test(e.target.value)) {
        setFields({
          ...fields,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleFloatPercentageSwitch = (e) => {
    setSwitchButton({
      ...switchButton,
      percentageNotificationLimitFlag:
        !switchButton.percentageNotificationLimitFlag,
      amountNotificationLimitFlag: false,
    });
  };

  const handleFloatAmountSwitch = (e) => {
    setSwitchButton({
      ...switchButton,
      amountNotificationLimitFlag: !switchButton.amountNotificationLimitFlag,
      percentageNotificationLimitFlag: switchButton.false,
    });
  };

  const validate = () => {
    if (!fields.client) {
      setError({ client: t("Please select client") });
      if(inputElementClient.current){
        inputElementClient.current.focus()
      }
      return false;
    } else if (!fields.currency) {
      setError({ currency: t("Please select currency") });
      if(inputElementCurrency.current){
        inputElementCurrency.current.focus()
      }
      return false;
    } else if (!fields.floatAmount) {
      setError({ floatAmount: t("Please enter amount") });
      if(inputElementFloatAmount.current){
        inputElementFloatAmount.current.focus()
      }
      return false;
    } else if (!fields.paymentReceivedOn) {
      setError({ paymentReceivedOn: t("Please select date") });
      return false;
    } else if (!documentation.length) {
      setError({ documentation: t("Please select receipt") });
      return false;
    }

    return true;
  };

  const submit = () => {
    let val = validate();
    let obj = {
      client: fields.client,
      currency: fields.currency,
      floatAmount: fields.floatAmount,
      paymentReceivedOn: fields.paymentReceivedOn,
      notificationsFlag: switchButton.notificationsFlag,
      notification_limit: switchButton.percentageNotificationLimitFlag
        ? fields.percentageNotificationLimit
        : fields.amountNotificationLimit,
      notification_limit_type: switchButton.percentageNotificationLimitFlag
        ? "percentage"
        : "amount",
      emails: emailList,
      documentation: documentation,
    };
    if (val) {
      dispatch(postFloatClient(obj, goToSuccessfull));
    }
  };

  const goToSuccessfull = () => {
    history("/float-map-success");
  };

  return loading ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div>
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 mb-3">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">{t("Client details")}</span>
          </div>
        </div>

        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Mapping for")}<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Select client"}
              />
            </div>

            <div className=" ">
              {location?.state?.selectedClient?.partner_name === undefined ? (
                <Select
                ref={inputElementClient}
                  placeholder={t("Select client")}
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(clientsState) => clientsState.partner_name}
                  options={clientsState}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.client}
                  onChange={handleChangeClient}
                  isClearable
                />

              ) : (
                <InputField
                  placeholder={t("Select client")}
                  name="currencyId"
                  value={
                    location?.state?.selectedClient
                      ? location?.state?.selectedClient?.partner_name
                      : ""
                  }
                  disabled={true}
                />
              )}

              {location?.state?.selectedClient?.partner_name === undefined && !fields.client && error.client ? (
                <label className="text-error">{error.client}</label>
              ) : null}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Currency")}<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Select currency"}
              />
            </div>
            <div className=" ">
              <Select
              ref={inputElementCurrency}
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select currency")}
                getOptionLabel={(countries) => countries.country.currency}
                options={floatCurrency}
                //   isSearchable
                value={fields.currency}
                onChange={handleChangeCurrency}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
              // isDisabled={location?.state?.selectedClient?.partner_name}
              />
              {!fields.currency && error.currency ? (
                <label className="text-error">{error.currency}</label>
              ) : null}
            </div>
          </div>

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Currency ID")}</span>
              <InfoTooltip
                title={"Selected currency ID"}
              />
            </div>
            <InputField
              placeholder={t("Currency id")}
              name="currencyId"
              value={
                fields?.currency?.country_id ? fields?.currency?.country_id : ""
              }
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 mb-3">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">{t("Float details")}</span>
          </div>
        </div>

        <div className="d-flex flex-column gap-2 mt-4">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              {t("Float amount")}<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Enter float amount"}
            />
          </div>
          <InputGroupTxt
            title={
              fields?.currency?.country?.currency_symbol
                ? fields?.currency?.country?.currency_symbol
                : fields?.currency?.country?.currency
                  ? fields?.currency?.country?.currency
                  : "$"
            }
            type="text"
            maxLength={6}
            name="floatAmount"
            value={fields.floatAmount}
            lang={props?.lang}
            onChange={inputChangeHandle}
            disabled={!fields.currency}
            inputRef={inputElementFloatAmount}
          />
          {!fields.floatAmount && error.floatAmount ? (
            <label className="text-error">{error.floatAmount}</label>
          ) : null}
        </div>
        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Payment received on")}<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Select payment date"}
              />
            </div>
            <ReactDatePicker
              onChange={handleChangeValue}
              selected={fields.paymentReceivedOn}
              maxDate={new Date()}
              name={"paymentReceivedOn"}
              lang={props?.lang}
              className="datepicker-input-style-without-border"
            />
            {!fields.paymentReceivedOn && error.paymentReceivedOn ? (
              <label className="text-error">{error.paymentReceivedOn}</label>
            ) : null}
          </div>

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Upload bank receipt PDF")}<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Upload bank receipt PDF"}
              />
            </div>
            <DragandDropPDF
              files={documentation}
              setFiles={setDocumentations}
            />
            {!documentation.length && error.documentation ? (
              <label className="text-error">{error.documentation}</label>
            ) : null}
          </div>

          <div className="d-flex flex-column  gap-2 py-2">
            <div className="d-flex flex-row align-items-center justify-content-between gap-2">
              <div>
                <span className="font-bold font-14">
                  {t("Send notifications? (Limit exceeded, Reacharge etc)")}{" "}
                </span>
                <InfoTooltip
                  title={"Enable or Disable for notifications"}
                />
              </div>
              <div>
                <ReactSwitchButton
                  onChange={() =>
                    setSwitchButton({
                      ...switchButton,
                      notificationsFlag: !switchButton.notificationsFlag,
                    })
                  }
                  value={switchButton.notificationsFlag}
                //disabled={reloadableGiftCardFlag}
                />
              </div>
            </div>
          </div>
          {switchButton.notificationsFlag && (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column  gap-3 py-2">
                <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                  <div>
                    <span className="font-bold font-14">
                      {t("Define float percentage")}{" "}
                    </span>
                    <InfoTooltip
                      title={"Define float percentage"}
                    />
                  </div>
                  <div>
                    <ReactSwitchButton
                      onChange={() => handleFloatPercentageSwitch()}
                      value={switchButton.percentageNotificationLimitFlag}
                    //disabled={reloadableGiftCardFlag}
                    />
                  </div>
                </div>
              </div>
              {switchButton.percentageNotificationLimitFlag && (
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Notification limit")}
                    </span>
                    <InfoTooltip
                      title={"Percentage Notification Limit"}
                    />
                  </div>
                  <InputGroupTxt
                    title={"%"}
                    name="percentageNotificationLimit"
                    lang={props?.lang}
                    value={fields.percentageNotificationLimit}
                    onChange={inputChangeHandle}
                  />
                </div>
              )}

              <div className="d-flex flex-column gap-3 py-2">
                <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                  <div>
                    <span className="font-bold font-14">
                      {t("Define float amount")}
                    </span>
                    <InfoTooltip
                      title={"Define float amount"}
                    />
                  </div>
                  <div>
                    <ReactSwitchButton
                      onChange={() => handleFloatAmountSwitch()}
                      value={switchButton.amountNotificationLimitFlag}
                    //disabled={reloadableGiftCardFlag}
                    />
                  </div>
                </div>
              </div>
              {switchButton.amountNotificationLimitFlag && (
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Notification limit")}
                    </span>
                    <InfoTooltip
                      title={"Notification limit"}
                    />
                  </div>
                  <InputGroupTxt
                    title={
                      fields?.currency?.country?.currency_symbol
                        ? fields?.currency?.country?.currency_symbol
                        : fields?.currency?.country?.currency
                          ? fields?.currency?.country?.currency
                          : "$"
                    }
                    name="amountNotificationLimit"
                    lang={props?.lang}
                    value={fields.amountNotificationLimit}
                    onChange={inputChangeHandle}
                  />
                </div>
              )}
            </div>
          )}

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Email list")}</span>
              <InfoTooltip
                title={"Add your email"}
              />
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
              <TagsInput
                tags={emailList}
                setTags={setEmailList}
                placeholder={"Enter Email"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <Button text={t("Save & Exit")} type="primary" onClick={() => submit()} />
      </div>
    </div>
  );
};

export default FloatDetails;
