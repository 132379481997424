/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as EditButton } from "../../assests/svg/edit.svg";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import giftcard_default from "../../assests/img/giftcard-default.png";
import Toasts from "../Toasts/Toasts";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  momentdateFormater,
  DataSortingHandler,
} from "../../utils/constantFunction";
import SmallToast from "../Toast/SmallToast";
import CopyToClipboard from "react-copy-to-clipboard";
import Filter from "../Filter/Filter";
import { GIFTCARD_TYPES_FILTERS, SORT_TYPE } from "../../utils/ConstantData";
import ModelForGiftCardSummery from "../../components/Models/ModelForGiftCardSummery";
import ModalComp from "../../components/Models/ModalComp";
import {
  clearGiftcardList,
  fetchAllGiftCardList,
  fetchGiftCardSummeryById,
  setGiftcardFilterData,
} from "../../Redux/GiftCard/giftcardAction";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoaders from "react-spinners/PulseLoader";
import PulseLoader from "../Loader/PulseLoader";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";
import RefreshButton from "../Button/RefreshButton";

const GiftCard = (props) => {

  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector((state) => state?.giftcard?.loading);
  const giftcardsState = useSelector((state) => state?.giftcard?.giftcards);
  // for lazy loading
  const hasmore = useSelector((state) => state?.giftcard?.next_iteration);

  const giftcardFilter = useSelector(
    (state) => state?.giftcard?.giftcardFilter
  );
  // for model Giftcard
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );
  const handleGiftcardClose = () => setShowGiftcardModal(false);
  const [showGiftcardModal, setShowGiftcardModal] = useState(false);

  const [products, setProducts] = useState([]);


  const [filterCardType, setFilterCardType] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [id, setId] = useState("");

  const toggleShowToast = () => setShowToast(!showToast);

  const [permissions, setpermissions] = useState();

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => { }, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (products?.length === 0) {
      fetchdata(skip);
    }
  }, []);

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => { }, [permissions]);

  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setProducts(giftcardFilter[0]?.filterProduct);
  }, [giftcardFilter]);

  useEffect(() => {
    giftcardFilter && setFilterCardType(giftcardFilter[1]?.filterType);
  }, [giftcardFilter[1]?.filterType]);

  useEffect(() => {
    if (giftcardFilter.length === 0) {
      setProducts(giftcardsState);
    }
  }, [giftcardsState]);

  const [skip, setskip] = useState(
    products?.length === 0 ? -30 : products?.length - 30
  );
  const [limit, setlimit] = useState(30);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(fetchAllGiftCardList(skip + limit, limit, products));
      setskip(skip + limit);
    }, 100);
  };

  const filterCardTypeHandler = (e) => {
    setFilterCardType(e);
    let arr = [];

    giftcardsState &&
      giftcardsState.map((item, i) => {
        if (e?.value === item?.giftcard_value_type) {
          arr.push(item);
        } else if (e?.value === null) {
          arr.push(item);
        }
      });
    dispatch(
      setGiftcardFilterData([{ filterProduct: arr }, { filterType: e }])
    );
    // setProducts(arr);
  };


  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 5);
    return val;
  };

  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const modelhandler = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowGiftcardModal(true);
  };

  const refreshData = () => {
    dispatch(clearGiftcardList());
    setskip(0);
    setlimit(30);
    dispatch(fetchAllGiftCardList(0, limit, []));
  }

  return loading === true && products?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
      <div className="d-flex flex-column py-2">
        <div className="card-body d-flex justify-content-between flex-row">
          <div className="d-flex flex-row justify-content-start gap-2">
            <div className="d-flex gap-3 my-2">
              <Tag />
              <span className="font-20 font-bold">{t("GiftCards")}</span>
            </div>
            <SearcBar
              type="text"
              name="search"
              lang={props?.lang}
              placeholder={t("Search product")}
              onChange={searchHandler}
            />
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div>
              <Filter
                data={GIFTCARD_TYPES_FILTERS}
                onChange={filterCardTypeHandler}
                title={filterCardType?.name}
                lang={props?.lang}
              />
            </div>
            <div>
              <RefreshButton onClick={refreshData} />
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={products?.length || 0}
          next={fetchdata}
          hasMore={hasmore}
          loader={
            <div className="d-flex justify-content-center">
              {" "}
              <PulseLoaders
                color={"#594190"}
                loading={loading}
                size={10}
              />{" "}
            </div>
          }
        >
          <div className="card-body d-flex flex-column justify-content-between align-item-center ">
            <table responsive="xl">
              <tbody>
                <tr className="border-bottom">
                  <td className="text-muted cursor">
                    <div className="d-flex flex-row justify-content-start">
                      <span
                        className={`mx-3 ${tableHeaderName === "default_title"
                          ? "ascending"
                          : "descending"
                          }`}
                        onClick={() =>
                          sortingHandler(
                            "default_title",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Giftcard Name")}
                      </span>
                    </div>
                  </td>
                  <td className="text-muted cursor">
                    <span
                      className={`${tableHeaderName === "id" ? "ascending" : "descending"
                        }`}
                      onClick={() =>
                        sortingHandler(
                          "id",
                          setTableHeaderName,
                          products,
                          order,
                          setOrder
                        )
                      }
                    >
                      {t("Product Id")}
                    </span>
                  </td>
                  <td className="text-muted cursor">
                    <div className="d-flex flex-row justify-content-start">
                      <span
                        className={`${tableHeaderName === "giftcard_value_type"
                          ? "ascending"
                          : "descending"
                          }`}
                        onClick={() =>
                          sortingHandler(
                            "giftcard_value_type",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Denomination")}
                      </span>
                    </div>
                  </td>
                  <td className="text-muted mx-2 cursor">
                    <span
                      className={`${tableHeaderName === "created_at"
                        ? "ascending"
                        : "descending"
                        }`}
                      onClick={() =>
                        sortingHandler(
                          "created_at",
                          setTableHeaderName,
                          products,
                          order,
                          setOrder
                        )
                      }
                    >
                      {t("Date")}
                    </span>
                  </td>
                  <td className="text-muted">
                    <span
                      className={``}>
                      {t("Status")}
                    </span>
                  </td>
                  {permissions?.giftcard?.create ? (
                    <td className="text-muted">
                      <div className="d-flex flex-row justify-content-end">
                        {t("Edit")}
                      </div>
                    </td>
                  ) : null}
                </tr>
                {products &&
                  products?.filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.default_title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                    .map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex flex-row justify-content-start align-items-center rounded-3 py-3 gap-2">
                            <div className="rounded-3">
                              <img
                                src={
                                  item.images && item.images[0].file_url
                                    ? item.images[0].file_url
                                    : giftcard_default
                                }
                                alt="not found"
                                id="product-thumbnail"
                              />
                            </div>
                            <div className="d-flex flex-column justify-content-center ">
                              <span
                                className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                                title={item?.default_title}
                                style={{ maxWidth: "120px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => modelhandler(item.id)}
                              >
                                {item?.default_title}
                              </span>
                              <span
                                className="font-10 font-bolder text-dark bg-04 rounded-3 px-1 d-inline-block text-truncate cursor"
                                style={{
                                  width: "fit-Content",
                                  maxWidth: "70px",
                                }}
                                title={
                                  item?.giftcard_value_type === "denominations"
                                    ? item?.denominations &&
                                    item?.denominations.map(
                                      (deno) => deno?.denomination_value
                                    )
                                    : item.min_amount + "-" + item.max_amount
                                }
                              >
                                {item.giftcard_value_type === "denominations"
                                  ? item?.denominations &&
                                  item.denominations.map(
                                    (deno) => deno.denomination_value + ","
                                  )
                                  : item.min_amount + "-" + item.max_amount}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-start align-items-center">
                            <span
                              className="font-14 font-boldest text-dark visible mt-4"
                              title={item.id}
                            >
                              {trimIdHandler(item.id)}
                              <span>...</span>
                            </span>

                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Copy to clipboard"
                              className="cursor "
                              value={item.id}
                              onClick={() => copyToClipboard(item.id)}
                            >
                              <CopyToClipboard text={item.id}>
                                <CopyButton />
                              </CopyToClipboard>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-start">
                            <span className="font-14 font-bolder">
                              {item?.giftcard_value_type === "denominations"
                                ? "Fixed denominations"
                                : "Range denominations"}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-start">
                            <span>{momentdateFormater(item.created_at)}</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-row justify-content-center">
                            {item?.active_flag ? (
                              <span className="font-15 font-bolder text-dark bg-04 rounded-3 px-1">
                                Active
                              </span>
                            ) : (
                              <span className="font-15 font-bolder text-dark bg-danger bg-opacity-25 rounded-3 px-1">
                                Deactive
                              </span>
                            )}
                          </div>
                        </td>
                        {permissions?.giftcard?.create ? (
                          <td>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                              <span
                                className="cursor"
                                onClick={() =>
                                  history("/product-edit", {
                                    state: {
                                      giftcardId: item.id,
                                    },
                                  })
                                }
                              >
                                <EditButton />
                              </span>
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
      </div>
      <div>
        <SmallToast data={id ? id : ""} message={"Copied"} />
      </div>
      <ModalComp
        size="lg"
        showModal={showGiftcardModal}
        // handleClose={handleClose}
        component={
          <ModelForGiftCardSummery
            handleClose={handleGiftcardClose}
            giftcardSummery={giftcardSummery}
          />
        }
      />

      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default GiftCard;
