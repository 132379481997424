import {
  FETCH_ALL_SUPPLIER_REQUEST,
  FETCH_ALL_SUPPLIER_SUCCESS,
  FETCH_ALL_SUPPLIER_FAILURE,
  FETCH_SUPPLIER_MAPPED_GC_REQUEST,
  FETCH_SUPPLIER_MAPPED_GC_SUCCESS,
  FETCH_SUPPLIER_MAPPED_GC_FAILURE,
  FETCH_SUPPLIER_MAPPED_MER_REQUEST,
  FETCH_SUPPLIER_MAPPED_MER_SUCCESS,
  FETCH_SUPPLIER_MAPPED_MER_FAILURE,
  SUPPLIER_FILTER_DATA,
  GET_SEL_SUP_ALL_TRANS_REQUEST,
  GET_SEL_SUP_ALL_TRANS_SUCCESS,
  GET_SEL_SUP_ALL_TRANS_FAILURE,
  GET_SEL_SUP_ALL_TRANS_DELETE,
  CLEAR_SUPPLIER_LIST,
  CLEAR_SUPPLIER_MAPP_PRODUCTS_LIST
} from "./supplierTypes";

const initialState = {
  supplier: [],
  loading: false,
  supplierMappedGiftCard: [],
  supplierMappedMerchandise: [],
  selSupplierTransaction: [],
  error: "",
  next_iteration: true,
  supplierFilter: []
};

function supplierReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SEL_SUP_ALL_TRANS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case GET_SEL_SUP_ALL_TRANS_SUCCESS:
      return {
        ...state,
        loading: false,
        selSupplierTransaction: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case GET_SEL_SUP_ALL_TRANS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SEL_SUP_ALL_TRANS_DELETE:
      return {
        ...state,
        loading: false,
        selSupplierTransaction: [],
        next_iteration: true,
        error: "",
      }
    case FETCH_ALL_SUPPLIER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_ALL_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        supplier: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case FETCH_ALL_SUPPLIER_FAILURE:
      return {
        ...state,
        loading: false,
        supplier: [],
        error: action.payload,
      };

    // get supplier mapped Giftcard
    case FETCH_SUPPLIER_MAPPED_GC_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_SUPPLIER_MAPPED_GC_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierMappedGiftCard: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case FETCH_SUPPLIER_MAPPED_GC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // get supplier mapped merchandise
    case FETCH_SUPPLIER_MAPPED_MER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_SUPPLIER_MAPPED_MER_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierMappedMerchandise: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case FETCH_SUPPLIER_MAPPED_MER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUPPLIER_FILTER_DATA:
      return {
        ...state,
        supplierFilter: action.payload,
      };
    case CLEAR_SUPPLIER_LIST:
      return {
        ...state,
        loading: false,
        supplier: [],
        next_iteration: true,
        error: "",
      };
    case CLEAR_SUPPLIER_MAPP_PRODUCTS_LIST:
      return {
        ...state,
        loading: false,
        supplierMappedMerchandise: [],
        next_iteration: true,
        error: "",
      };
    default:
      return state;
  }
}

export default supplierReducer;
