import { combineReducers } from "redux";
import giftcardReducer, { giftcardLengthReducer, giftcardPrefixReducer, giftcardTypeReducer } from "../Redux/GiftCard/giftcardReducer";
import countryReducer from '../Redux/Countries/countryReducer'
import brandCategoryReducer from '../Redux/BrandCategories/brandCategoryReducer'
import organizationReducer from '../Redux/Organizations/organizationReducer'
import supplierReducer from "./Supplier/supplierReducer";
import brandReducer from "./Brands/brandReducer";
import partnerReducer from "./Partner/partnerReducer";
import languageReducer from "./Language/languageReducer";
import redemptionMethodReducer from "./Redemption/redemptionReducer";
import activationReducer from "./ActivationRequired/activationReducer";
import mappingDataReducer from "./MappingFor/mappingReducer";
import productCategoryReducer from "./ProdCategory/prodCatReducer";
import weightReducer from "./Weight/weightReducer";
import shippingCostReducer from "./ShippingCost/shippingcostReducer";
import salesTaxReducer from "./SalesTax/salestaxReducer";
import selectColorReducer from "../Redux/SelectColor/selcolReducer";
import vatReducer from "./Vat/vatReducer";
import warrantyReducer from "./Warranty/warrantyReducer";
import merchandiseCatAttReducer from "./Merchandise/merchandiesReducer";
import transactionCritariaReducer from "./TransactionCritaria/transactionReducer";
import currenciesReducer from "./Currency/currencyReducer";
import authReducer from "./Auth/authReducer";
import clientsReducer from "./Client/clientReducer";
import productTypeReducer from "./ProductType/prodtypeReducer";
import merchandiseVarientReducer from "./MerchandiseVarient/merchandiseVarientReducer";
import clientsMappingReducer from "./ClientMapping/ClientMappingReducer";
import SupplierMappingDataReducer from "./SupplierMapping/SupplierMappingReducer";
import clientListReducer from "./MappingProducts/Client/Giftcards/ClientGiftcardReducer";
import clientMerchandiseListReducer from "./MappingProducts/Client/Merchandise/ClientMerchandiseReducer";
import supplierMerchandiseListReducer from "./MappingProducts/Supplier/Merchandise/SuppMerchandiseReducer";
import manualInventoryReducer from "./ManualInventory/manualInventoryReducer";
import giftcardSuppMappingReducer from "./MappingProducts/Supplier/Giftcards/giftcardSuppMappingReducer";
import createStoreReducer from "./CreateStore/createStoreReducer";
import merchadiseAllProductReducer from "./MerchadiseAllProduct/MerchadiseAllProductReducer";
import customerReducer from "./Customer/customerReducer";
import denominationReducer from "./Denominations/denominationReducer";
import createOrderReducer from "./CreateOrder/CreateOrderReducer";
import floatReducer from "./Float/floatReducer";
import clientMappedMerchandiseReducer from "./Distributions/ClientMerchandiseDistributions/clientMappedMerchandiseReducer";

import ClientMappedGcListReducer from "./Distributions/ClientGiftcardDistributions/ClientGiftcardDistributionReducer";
import allOrdersReducer from "./Order/orderReducer";
import customeEmailReducer from "./CustomEmail/customeEmailReducer";
import userReducer from "./UserManagement/userReducer";
import bulkDistributionReducer from "./Distributions/BulkDistribution/BulkDistributionReducer";
import eposReducer from "./Epos/eposReducer";
import sideNavToggleReducer from "./SidenavToggle/sideNavToggleReducer"
import bulkUploadReducer from "./BulkUpload/bulkUploadReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    giftcard: giftcardReducer,
    countries: countryReducer,
    brandCategories: brandCategoryReducer,
    organizations: organizationReducer,
    suppliers: supplierReducer,
    brands: brandReducer,
    partners: partnerReducer,
    giftcardlengths: giftcardLengthReducer,
    languages: languageReducer,
    redemptionmethods: redemptionMethodReducer,
    activationrequired: activationReducer,
    mappingdata: mappingDataReducer,
    productcategories: productCategoryReducer,
    weights: weightReducer,
    shippingcost: shippingCostReducer,
    salestax: salesTaxReducer,
    selectcolors: selectColorReducer,
    giftcardtype: giftcardTypeReducer,
    giftcardprefix: giftcardPrefixReducer,
    vat: vatReducer,
    warranty: warrantyReducer,
    merchandiseCatAttriData: merchandiseCatAttReducer,
    transactionCritaria: transactionCritariaReducer,
    currencies: currenciesReducer,
    clients: clientsReducer,
    productTypes: productTypeReducer,
    merchandiseVarient: merchandiseVarientReducer,
    clientMappingData: clientsMappingReducer,
    SupplierMappingData: SupplierMappingDataReducer,
    clientList: clientListReducer,
    clientMerchandiseList: clientMerchandiseListReducer,
    giftcardSuppMappingReducer: giftcardSuppMappingReducer,
    supplierMerchandiseList: supplierMerchandiseListReducer,
    manualInventory: manualInventoryReducer,
    createStore: createStoreReducer,
    merchadiseAllProduct: merchadiseAllProductReducer,
    customers: customerReducer,
    denominations: denominationReducer,
    // createOrder: createOrderReducer
    createOrder: createOrderReducer,
    partnerFloat: floatReducer,
    clientMappedMerchandise: clientMappedMerchandiseReducer,
    clientMapGcList: ClientMappedGcListReducer,
    allOrderList: allOrdersReducer,
    emailTemplate: customeEmailReducer,
    users: userReducer,
    bulkDistribution: bulkDistributionReducer,
    eposState: eposReducer,
    languageState: languageReducer,
    sideNavToggleReducer: sideNavToggleReducer,
    merchandiseBulkUpload: bulkUploadReducer,
});

export default rootReducer;