import {
  PUT_SUPP_MER_LIST_REQUEST,
  PUT_SUPP_MER_LIST_SUCCESS,
  PUT_SUPP_MER_LIST_FAILURE,
} from "./SuppMerchandiseType";

const initialSupplierMerchandiseState = {
  loading: false,
  supplierMerResponse: null,
  error: "",
};

const supplierMerchandiseListReducer = (state = initialSupplierMerchandiseState, action) => {
  switch (action.type) {

    // TO GET MERCHANDISE LIST TO MAP SUPPLIER
    case PUT_SUPP_MER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_SUPP_MER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierMerList: action.payload,
        error: "",
      };
    case PUT_SUPP_MER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        supplierMerList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default supplierMerchandiseListReducer;
