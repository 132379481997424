export const PUT_CREATESTORE_REQUEST = "PUT_CREATESTORE_REQUEST"
export const PUT_CREATESTORE_SUCCESS = "PUT_CREATESTORE_SUCCESS"
export const PUT_CREATESTORE_FAILURE = "PUT_CREATESTORE_FAILURE"


//get CreateStore

export const GET_STORELIST_REQUEST = "GET_STORELIST_REQUEST"
export const GET_STORELIST_SUCCESS = "GET_STORELIST_SUCCESS"
export const GET_STORELIST_FAILURE = "GET_STORELIST_FAILURE"

// store patch api
export const PATCH_STORELIST_REQUEST = "GET_STORELIST_REQUEST"
export const PATCH_STORELIST_SUCCESS = "GET_STORELIST_SUCCESS"
export const PATCH_STORELIST_FAILURE = "GET_STORELIST_FAILURE"

export const EPOS_STORE_DATA = "EPOS_STORE_DATA"

export const GET_STORELIST_MAP_TO_ORG_REQUEST = "GET_STORELIST_MAP_TO_ORG_REQUEST"
export const GET_STORELIST_MAP_TO_ORG_SUCCESS = "GET_STORELIST_MAP_TO_ORG_SUCCESS"
export const GET_STORELIST_MAP_TO_ORG_FAILURE = "GET_STORELIST_MAP_TO_ORG_FAILURE"

export const GET_STORELIST_MAP_TO_ORG_DELETE = "GET_STORELIST_MAP_TO_ORG_DELETE"

export const SET_SELETED_STORE_OR_ORG_DATA = "SET_SELETED_STORE_OR_ORG_DATA"



export const GET_STORE_DETAILS_REQUEST = "GET_STORE_DETAILS_REQUEST"
export const GET_STORE_DETAILS_SUCCESS = "GET_STORE_DETAILS_SUCCESS"
export const GET_STORE_DETAILS_FAILURE = "GET_STORE_DETAILS_FAILURE"