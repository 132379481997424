import BulkDistributioDashboard from "../../../screens/DistributionModel/BulkDistribution/BulkDistributioDashboard";
import {
  GET_SAMPLE_BULK_DISTRIBUTION_REQUEST,
  GET_SAMPLE_BULK_DISTRIBUTION_SUCCESS,
  GET_SAMPLE_BULK_DISTRIBUTION_FAILURE,
  GET_BULK_DISTRIBUTION_REQUEST,
  GET_BULK_DISTRIBUTION_SUCCESS,
  GET_BULK_DISTRIBUTION_FAILURE,
  POST_BULK_DISTRIBUTION_REQUEST,
  POST_BULK_DISTRIBUTION_SUCCESS,
  POST_BULK_DISTRIBUTION_FAILURE,
} from "./BulkDistributionType";

const initialBulkDistribution = {
  loading: false,
  bulkDistribution: [],
  bulkDistributionResponse: null,
  bulkDistributionList: [],
  bulkDistributionErrors: [],
  error: "",
};

const bulkDistributionReducer = (state = initialBulkDistribution, action) => {
  switch (action.type) {
    case GET_SAMPLE_BULK_DISTRIBUTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SAMPLE_BULK_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkDistribution: action.payload,
        error: "",
      };
    case GET_SAMPLE_BULK_DISTRIBUTION_FAILURE:
      return {
        ...state,
        loading: false,
        bulkDistribution: [],
        error: action.payload,
      };
    case POST_BULK_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkDistribution: [],
        bulkDistributionResponse: action.payload,
        error: "",
      };
    case GET_BULK_DISTRIBUTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BULK_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkDistribution: [],
        bulkDistributionList: action.payload,
        manualInventoryResponse: null,
        error: "",
      };
    case GET_BULK_DISTRIBUTION_FAILURE:
      return {
        ...state,
        loading: false,
        bulkDistribution: [],
        bulkDistributionList: [],
        manualInventoryResponse: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default bulkDistributionReducer;
