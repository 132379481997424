export const FETCH_ALL_SUPPLIER_REQUEST = "FETCH_ALL_SUPPLIER_REQUEST";
export const FETCH_ALL_SUPPLIER_SUCCESS = "FETCH_ALL_SUPPLIER_SUCCESS";
export const FETCH_ALL_SUPPLIER_FAILURE = "FETCH_ALL_SUPPLIER_FAILURE"

// Get supplier mapped product Giftcard
export const FETCH_SUPPLIER_MAPPED_GC_REQUEST = "FETCH_SUPPLIER_MAPPED_GC_REQUEST";
export const FETCH_SUPPLIER_MAPPED_GC_SUCCESS = "FETCH_SUPPLIER_MAPPED_GC_SUCCESS";
export const FETCH_SUPPLIER_MAPPED_GC_FAILURE = "FETCH_SUPPLIER_MAPPED_GC_FAILURE";
// get supplier mapped product merchandise
export const FETCH_SUPPLIER_MAPPED_MER_REQUEST = "FETCH_SUPPLIER_MAPPED_MER_REQUEST";
export const FETCH_SUPPLIER_MAPPED_MER_SUCCESS = "FETCH_SUPPLIER_MAPPED_MER_SUCCESS";
export const FETCH_SUPPLIER_MAPPED_MER_FAILURE = "FETCH_SUPPLIER_MAPPED_MER_FAILURE";

// supplier filer
export const SUPPLIER_FILTER_DATA = "SUPPLIER_FILTER_DATA";

export const CLEAR_SUPPLIER_LIST = "CLEAR_SUPPLIER_LIST";
export const CLEAR_SUPPLIER_MAPP_PRODUCTS_LIST = "CLEAR_SUPPLIER_MAPP_PRODUCTS_LIST";


// Get Selected Supplier All Transactions 
export const GET_SEL_SUP_ALL_TRANS_REQUEST = "GET_SEL_SUP_ALL_TRANS_REQUEST";
export const GET_SEL_SUP_ALL_TRANS_SUCCESS = "GET_SEL_SUP_ALL_TRANS_SUCCESS";
export const GET_SEL_SUP_ALL_TRANS_FAILURE = "GET_SEL_SUP_ALL_TRANS_FAILURE";
export const GET_SEL_SUP_ALL_TRANS_DELETE = "GET_SEL_SUP_ALL_TRANS_DELETE";