/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import GoogleIncIcon from "../../assests/img/GoogleInc.png";
import { ReactComponent as MailIcon } from "../../assests/svg/mail.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RadishTag } from "../../assests/svg/Tag/radish_tag.svg";
import { ReactComponent as EditButton } from "../../assests/svg/edit.svg";
import SmallToast from "../../components/Toast/SmallToast";
import CopyToClipboard from "react-copy-to-clipboard";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import Button from "../Button/Button";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import { useDispatch, useSelector } from "react-redux";
import Collapse from "react-bootstrap/Collapse";
import { getPratnerFloats } from "../../Redux/Float/floatAction";
import { fetchClientMappedMerchandiseDetails } from "../../Redux/Distributions/ClientMerchandiseDistributions/ClientMerchandiseDistributionAction";
import { useTranslation } from "react-i18next";

const OrganizationInfo = (props) => {
  const { selectedOrganization } = props;
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const countries = useSelector((state) => state?.countries?.countries);
  const floatCurrency = useSelector((state) => state?.partnerFloat?.floats);

  const [permissions, setpermissions] = useState();
  const [id, setId] = useState("");

  const [floatCollapse, setFloatCollapse] = useState(false);
  const [currencyCollapse, setCurrencyCollapse] = useState(false);

  const [organizationCurrencies, setOrganizationCurrencies] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCountriesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {}, [permissions, selectedOrganization]);

  useEffect(() => {
    let arr = [];
    countries &&
      countries.map((conItem) => {
        selectedOrganization &&
          selectedOrganization?.currencies_id.map((item) => {
            if (conItem?.id === item) {
              arr.push(conItem);
            }
          });
      });
    setOrganizationCurrencies(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization, countries]);

  // Float currency getting
  useEffect(() => {
    window.scrollTo(0, 0);
    if (selectedOrganization?.float_flag && selectedOrganization?.id) {
      dispatch(getPratnerFloats(selectedOrganization?.id));
    }
    dispatch(fetchClientMappedMerchandiseDetails(selectedOrganization?.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization]);

  const editButtonhandler = (item) => {
    history("/edit-partner", {
      state: {
        partnerItem: selectedOrganization,
      },
    });
  };

  const topUpButtonhandler = (item) => {
    history("/float/addfloat-org-details", {
      state: {
        selectedOrganization,
      },
    });
  };

  const allocateButtonhandler = (item) => {
    history("/float/currency-mapping/to-organization", {
      state: {
        selectedOrganization,
      },
    });
  };

  // copy to clipboard
  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 w-100">
      <div className="d-flex px-4 gap-3 align-items-center">
        <RadishTag />
        <span className="font-22 font-boldest text-dark">
          {t("Organization Info")}
        </span>
      </div>
      <div className="d-flex flex-row gap-4 justify-content-between align-items-center">
        <div className="d-flex flex-row gap-3 py-2  align-items-center">
          {/* <div className="d-flex flex-row"> */}
          <div className="d-flex flex-column gap-3 mb-1  py-3  px-4 align-items-center">
            <div className="d-flex flex-row gap-4 justify-content-between">
              <img
                src={GoogleIncIcon}
                alt="not found"
                className="rounded-3 "
                width="80px"
                height="80px"
              />

              <div>
                <span className="font-22 font-bold text-dark ">{t("Name")} : </span>
                <span className="font-22 font-boldest text-dark ">
                  {selectedOrganization.partner_name}
                </span>
                {permissions?.all_organization?.create ? (
                  <span
                    className="font-22 font-boldest text-dark mx-2 cursor"
                    onClick={() => editButtonhandler()}
                  >
                    <EditButton />
                  </span>
                ) : null}

                <br />
                <span className="font-15 font-bold text-dark ">{t("Id")} : </span>
                <span
                  className="font-15 font-bold text-muted"
                  title={selectedOrganization.id}
                >
                  {selectedOrganization.id}
                  {/* <span>...</span> */}
                </span>
                <span
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Copy to clipboard"
                  className="cursor "
                  value={selectedOrganization.id}
                  onClick={() => copyToClipboard(selectedOrganization.id)}
                >
                  {/* <CopyToClipboard text={selectedOrganization.id}>
                    <CopyButton />
                  </CopyToClipboard> */}
                </span>
                <br />
                <span className="font-15 font-bold text-dark ">{t("Email")} : </span>
                <span className="font-15 font-bold text-muted">
                  {selectedOrganization.email}
                </span>
                <br />
                <span className="font-15 font-bold text-dark ">
                  {t("Billing type")} :{" "}
                </span>
                {selectedOrganization?.float_flag === true ? (
                  <span className="font-15 font-bold text-pink">{t("Float")}</span>
                ) : (
                  <span className="font-15 font-bold text-pink">{t("Postpaid")}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column  align-items-center gap-3 justify-content-between pt-1 px-3">
          {selectedOrganization?.float_flag && (
            <div className="d-flex flex-end px-1 align-items-center">
              <Button
                type="primary"
                text={t("TopUp float")}
                className="rounded-5 my-4 mt-6 fw-bolder"
                onClick={() => topUpButtonhandler()}
              />
            </div>
          )}
          {selectedOrganization?.float_flag && (
            <div className="d-flex flex-end px-1 align-items-center">
              <Button
                type="primary"
                text={t("Allocate float")}
                className="rounded-5 my-4 mt-6 fw-bolder"
                onClick={() => allocateButtonhandler()}
              />
            </div>
          )}
        </div>
      </div>

      <div className="d-flex justifiy-content-center px-4 gap-3">
        <div
          className="d-flex flex-row justify-content-between align-items-center py-2 px-2 gap-3 rounded-3"
          onClick={() => setCurrencyCollapse(!currencyCollapse)}
        >
          <div
            className="d-flex flex-column cursor gap-3"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            <div className="d-flex align-items-center gap-4">
              {/* <ProductIcon /> */}
              <span className="font-18 font-bold">
                {t("Client Operational Countries")}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div
              className={`cursor p-0 ${currencyCollapse ? "rotate-180" : ""}`}
              aria-controls="example-collapse-text"
              aria-expanded={false}
            >
              <DropDownIcon
                className={`cursor p-0 ${currencyCollapse ? "rotate-180" : ""}`}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="px-5">
        <Collapse in={currencyCollapse}>
          <div
            id="panelsStayOpen-collapseOne"
            className="mt-2"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div className="card-body d-flex flex-column justify-content-between p-3 gap-4">
              <table responsive="xl">
                <tbody>
                  <tr className="col gap-3 border-bottom  ">
                    <td className="col">
                      <div>
                        <span className="font-15 text-muted font-bold px-2 py-4">
                          {t("Countries")}
                        </span>
                      </div>
                    </td>
                    <td className="col">
                      <div className="d-flex flex-end justify-content-end px-4">
                        <span className="font-15 font-bold text-muted px-2">
                          {t("Currencies")}
                        </span>
                      </div>
                    </td>
                  </tr>

                  {organizationCurrencies &&
                    organizationCurrencies.map((item, i) => (
                      <tr className="col gap-3 border-bottom hover-row" key={i}>
                        <td className="col px-2 py-2">
                          {item?.name + " (" + item?.iso3 + ")"}
                        </td>
                        <td className="col">
                          <div className="d-flex flex-end justify-content-end px-4">
                            <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                              {item?.currency + " - " + item?.currency_symbol}
                            </span>
                          </div>
                        </td>
                      </tr>
                      // </tr>
                    ))}
                </tbody>
              </table>
              {!organizationCurrencies.length && (
                <div className="d-flex flex-row justify-content-center py-3">
                  <span className="font-20 font-bold text-error">
                    {t("No country & Currencies found")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Collapse>
      </div>

      {selectedOrganization?.float_flag && (
        <div className="d-flex justifiy-content-center px-4 gap-3">
          <div
            className="d-flex flex-row justify-content-between align-items-center py-2 px-2 gap-3 rounded-3"
            onClick={() => setFloatCollapse(!floatCollapse)}
          >
            <div
              className="d-flex flex-column cursor gap-3"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              <div className="d-flex align-items-center gap-4">
                {/* <ProductIcon /> */}
                <span className="font-18 font-bold">{t("Float currency")}</span>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div
                className={`cursor p-0 ${floatCollapse ? "rotate-180" : ""}`}
                aria-controls="example-collapse-text"
                aria-expanded={false}
              >
                <DropDownIcon
                  className={`cursor p-0 ${floatCollapse ? "rotate-180" : ""}`}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="px-5">
        <Collapse in={floatCollapse}>
          <div
            id="panelsStayOpen-collapseOne"
            className="mt-2"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div className="row justify-contents-between  gap-4">
              <table responsive="xl">
                <tbody>
                  <tr className="card-body gap-3 border-bottom  py-5">
                    <td className="card-body gap-1 font-15 font-bold text-muted">
                      {t("Currency")}
                    </td>
                    <td className="card-body gap-1 font-15 font-bold justify-content-end text-muted">
                      <div className="d-flex flex-end justify-content-end px-4">
                        {t("Balance")}
                      </div>
                    </td>
                  </tr>
                  {floatCurrency && floatCurrency.length > 0 ? (
                    floatCurrency.map((item, index) => (
                      <tr
                        className="card-body gap-3 border-bottom hover-row"
                        key={index}
                      >
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-between align-items-center">
                            <span className="font-15 font-bold text-dark px-2">
                              {item?.country?.name +
                                "-" +
                                item?.country?.currency +
                                " (" +
                                item?.country?.currency_symbol +
                                ")"}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-2">
                          <div className="d-flex flex-end justify-content-end px-4">
                            <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                              {item?.balance}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        <div className="d-flex justify-content-center flex-row w-100 text-danger font-15 font-bold">
                          {`${t("Currencies not allocated to this")} ${selectedOrganization?.partner_name} ${t("client")}`}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default OrganizationInfo;
