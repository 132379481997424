import { SIDENAV_TOGGLE } from "./sideNavToggleType";

export const sideNavToggleSuccess = (toggleValue) => {
    return {
        type: SIDENAV_TOGGLE,
        payload: toggleValue
    }
}

export const sideNavToggle = (toggleValue) => {
    return(dispatch) => {
        dispatch(sideNavToggleSuccess())
    }
}