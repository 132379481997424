import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import WarningImg from "../../assests/img/warning.png";
import { fetchSupplierList } from "../../Redux/Supplier/supplierAction";


const SupplierMappingAlertModel = (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToNextMechandise = () => {
        dispatch(fetchSupplierList());
        history("/supplier-mapping-merchandise-product");
    }

    const goToNextGiftcard = () => {
        dispatch(fetchSupplierList());
        history("/supplier-mapping-giftcard-product");
    }

    const submit = () => {
        if (props.productType === "giftcard") {
            goToNextGiftcard();
        } else if (props.productType === "merchandise") {
            goToNextMechandise();
        }
    }

    return (

        <div className="d-flex flex-column bg-white px-5 w-100 my-5">
            <div className="d-flex flex-column justify-content-start align-items-center gap-4">
                <div>
                    <img
                        src={WarningImg}
                        alt=""
                    />
                </div>
                <span className="font-bold text-dark font-17">
                    Few selected products have missing suppliers, please map suppliers before client mapping
                </span>
                <div className="d-flex flex-column gap-4">
                    <Button
                        text="Go to Supplier Mapping"
                        type={"primary"}
                        onClick={() => submit()}
                    />
                    <Button
                        text="Close"
                        type={"secondary"}
                        onClick={props.handleClose}
                    />
                </div>

            </div>
        </div >
    );
};

export default SupplierMappingAlertModel;
