/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as Tag } from "../../../../assests/svg/Tag/tag.svg";
import { ReactComponent as Searchicon } from "../../../../assests/svg/search.svg";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../../components/Toasts/Toasts";
import { ReactComponent as LeftIcon } from "../../../../assests/svg/Products/left_lg.svg";
import ModalComp from "../../../../components/Models/ModalComp";
import SupplierMappingAlertModel from "../../../../components/Models/SupplierMappingAlertModel";
import {
  setSelectedGiftcardsList,
  setSelectedNoMapSupplierProduct,
  setSelectedSupplier,
} from "../../../../Redux/MappingProducts/Supplier/Giftcards/giftcardSuppMappingAction";
import { GetClientMappedGiftcardList } from "../../../../Redux/ClientMapping/ClientMappingAction";
import {
  fetchAllGiftCardListByCountryIds,
  fetchGiftCardSummeryById,
} from "../../../../Redux/GiftCard/giftcardAction";
import PulseLoader from "../../../../components/Loader/PulseLoader";
import {
  fetchCountrieDetailsByIds,
  getAllCountriesList,
} from "../../../../Redux/Countries/countryAction";
import {
  DataSortingHandler,
  strFirstLetterCap,
} from "../../../../utils/constantFunction";
import ModelForGiftCardSummery from "../../../../components/Models/ModelForGiftCardSummery";
import Giftcard_default from "../../../../assests/img/giftcard-default.png";
import Collapse from "react-bootstrap/Collapse";
import { ReactComponent as DropDownIcon } from "../../../../assests/svg/DropDown.svg";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../../../utils/CustomeStyles";
import { fetchSupplierList } from "../../../../Redux/Supplier/supplierAction";
import { useTranslation } from "react-i18next";
import SearcBar from "../../../../components/InputField/SearcBar";
import { SORT_TYPE } from "../../../../utils/ConstantData";

const ClientGiftcardList = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const giftCardListState = useSelector((state) => state?.giftcard?.giftcards);
  const giftcardListLoading = useSelector((state) => state?.giftcard?.loading);

  const clientMappedGiftcardList = useSelector(
    (state) => state.clientMappingData.supplierPrice
  );
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );
  const countries = useSelector((state) => state?.countries?.countries);
  const suppliersListState = useSelector((state) => state?.suppliers?.supplier);

  // for model
  // const handleShow = () => setShowSummeryModal(true);
  const [showSummeryModal, setShowSummeryModal] = useState(false);

  const [visible, setVisible] = useState(10);
  const [giftCardsList, setGiftCardsList] = useState([]);
  console.log("giftCardsList ", giftCardsList);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [floatCollapse, setFloatCollapse] = useState(false);
  const [clientCurrencies, setClientCurrencies] = useState([]);
  const [supplierSort, setSupplierSort] = useState("");
  const [unmappProducts, setUnmappProducts] = useState([]);

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSummeryClose = () => setShowSummeryModal(false);

  const handleClose = () => setShowModal(false);

  const toggleShowToast = () => setShowToast(!showToast);

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {}, [order]);

  useEffect(() => {
    let arr = [];
    countries &&
      countries.map((conItem) => {
        location?.state?.clients &&
          location?.state?.clients.currencies_id.map((item) => {
            if (conItem?.id === item) {
              arr.push(conItem);
            }
          });
      });
    setClientCurrencies(arr);
  }, [location?.state?.clients, countries]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCountriesList());
    dispatch(fetchSupplierList());

    // Client id send for fetching giftcard list
    dispatch(GetClientMappedGiftcardList(location?.state?.clients?.id));

    dispatch(
      fetchAllGiftCardListByCountryIds(location?.state?.clients?.currencies_id)
    );
    dispatch(
      fetchCountrieDetailsByIds(location?.state?.clients?.currencies_id)
    );
  }, []);

  useEffect(() => {
    filterClientMappedGiftList();
  }, [clientMappedGiftcardList, giftCardListState]);

  useEffect(() => {
    if (!supplierSort) {
      filterClientMappedGiftList();
    }
  }, [giftCardListState, supplierSort]);

  const filterClientMappedGiftList = () => {
    let arr = [];
    giftCardListState &&
      giftCardListState?.map((item, i) => {
        if (
          clientMappedGiftcardList.some(
            (mappProd) => item.id === mappProd.giftcard.id
          )
        ) {
          // toggleShowToast();
          return;
        } else {
          arr.push(item);
        }
      });
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = false;
      });
    setGiftCardsList(arr);
    setUnmappProducts(arr);
  };

  const handleSupplierSort = (e) => {
    setSupplierSort(e);

    let arr = [];
    unmappProducts &&
      unmappProducts.map((item, i) => {
        item?.suppliers &&
          item?.suppliers.map((supp, j) => {
            if (supp?.supplier_id === e?.id) {
              arr.push(item);
            }
          });
      });

    setGiftCardsList(arr);
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...giftCardsList];
    arr &&
      arr.map((item, i) => {
        if (item.id === name) {
          arr[i].isChecked = checked;
        }
      });
    setGiftCardsList(arr);
  };

  const allCheckedHandle = (e) => {
    const { checked } = e.target;
    let arr = [...giftCardsList];
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = checked;
      });
    setGiftCardsList(arr);
  };

  const goToClientMappingVariants = () => {
    let arr = [...giftCardsList];
    let selectedGiftCard = [];
    arr &&
      arr.map((item) => {
        if (item.isChecked === true) {
          selectedGiftCard.push(item);
        }
      });
    let suppVAl =
      selectedGiftCard &&
      selectedGiftCard.every((v) => v.suppliers && v.suppliers?.length > 0);
    let noSupplierProduct = [];

    if (!suppVAl) {
      setShowModal(true);
      selectedGiftCard &&
        selectedGiftCard.map((item, i) => {
          if (item.suppliers && !item.suppliers.length > 0) {
            noSupplierProduct.push(item);
          }
        });
      dispatch(setSelectedNoMapSupplierProduct(noSupplierProduct));
      return;
    } else if (selectedGiftCard.length > 0) {
      dispatch(setSelectedSupplier(supplierSort));

      dispatch(setSelectedGiftcardsList(selectedGiftCard));
      history("/clients-mapping-giftcard-with-client");
    } else {
      toggleShowToast();
      return;
    }
  };

  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 10);
  };

  const goBack = () => {
    history("/clients-mapping-dashboard");
  };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 5);
    return val;
  };

  const showModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowSummeryModal(true);
  };

  return (
    <>
      {giftcardListLoading === false ? (
        <div className="d-flex flex-column justify-content-start gap-3 py-4 pb-3 px-4 bg-light">
          <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
            <div className="d-flex flex-row align-items-center pb-2 gap-3">
              <LeftIcon onClick={() => goBack()} className="cursor" />
              <span className="font-bold font-32">
                {t("Client mapping to giftcards")}
              </span>
            </div>
          </div>

          <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100">
            <div className="card-body d-flex justify-content-between flex-row">
              <div className="d-flex gap-3 my-2">
                <Tag />
                <span className="font-20 font-bold">
                  {t("Selected Client")}:
                  {" " + location?.state?.clients?.partner_name}
                </span>
              </div>
              {/* <div>
                  <BackButton onClick={() => history("/supplier-mapping-dashboard")} />
                </div> */}
            </div>

            <div className="d-flex justifiy-content-center px-4 gap-3">
              <div
                className="d-flex flex-row justify-content-between align-items-center py-2 px-2 gap-3 rounded-3"
                onClick={() => setFloatCollapse(!floatCollapse)}
              >
                <div
                  className="d-flex flex-column cursor gap-3"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  <div className="d-flex align-items-center gap-4">
                    {/* <ProductIcon /> */}
                    <span className="font-18 font-bold">
                      {t("Client Operational Countries")}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div
                    className={`cursor p-0 ${
                      floatCollapse ? "rotate-180" : ""
                    }`}
                    aria-controls="example-collapse-text"
                    aria-expanded={false}
                  >
                    <DropDownIcon
                      className={`cursor p-0 ${
                        floatCollapse ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}

            <div className="px-5">
              <Collapse in={floatCollapse}>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="mt-2"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="card-body d-flex flex-column justify-content-between p-3 gap-4">
                    <table responsive="xl">
                      <tbody>
                        <tr className="col gap-3 border-bottom  ">
                          <td className="col">
                            <div>
                              <span className="font-16 font-bold px-2 py-4">
                                {t("Countries")}
                              </span>
                            </div>
                          </td>
                          <td className="col">
                            <div className="d-flex flex-end justify-content-end px-4">
                              <span className="font-16 font-bold px-2">
                                {t("Currencies")}
                              </span>
                            </div>
                          </td>
                        </tr>

                        {clientCurrencies &&
                          clientCurrencies?.map((item, i) => (
                            <tr
                              className="col gap-3 border-bottom hover-row"
                              key={i}
                            >
                              <td className="col px-2 py-2">
                                {item?.name + " (" + item?.iso3 + ")"}
                              </td>
                              <td className="col">
                                <div className="d-flex flex-end justify-content-end px-4">
                                  <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                    {item?.currency +
                                      " - " +
                                      item?.currency_symbol}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            // </tr>
                          ))}
                      </tbody>
                    </table>
                    {!clientCurrencies.length && (
                      <div className="d-flex flex-row justify-content-center py-3">
                        <span className="font-20 font-bold text-error">
                          {t("No country & Currencies found")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </div>
          </div>

          <div className="d-flex flex-column bg-white shadow-sm rounded-3 w-100 py-3 px-4 gap-3 ">
            <div className="d-flex flex-row w-100  align-items-center bg-white">
              <div className="d-flex flex-column p-2">
                <div className="d-flex gap-3 my-2 mx-3 justify-content-between align-items-center">
                  <Tag />
                  <span className="font-20 font-bold">{t("GiftCards")}</span>
                  <SearcBar
                    type="text"
                    name="search"
                    lang={props?.lang}
                    placeholder={t("Search by name")}
                    onChange={searchHandler}
                  />
                  <div className="d-flex width-30">
                    <Select
                      noOptionsMessage={() => "Not found"}
                      placeholder={t("Select supplier")}
                      getOptionLabel={(e) => e?.partner_name}
                      options={suppliersListState}
                      //   isSearchable
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                      value={supplierSort}
                      onChange={handleSupplierSort}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row px-5 py-2">
              <table responsive="xl">
                <tbody>
                  <tr className="card-body gap-3 border-bottom py-5">
                    <td>
                      {" "}
                      <span>
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="checkboxNoLabel"
                          checked={
                            giftCardsList?.filter(
                              (item) => item?.isChecked !== true
                            ).length < 1
                          }
                          onChange={allCheckedHandle}
                        />
                      </span>
                    </td>
                    <td
                      className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                        tableHeaderName === "default_title"
                          ? "ascending"
                          : "descending"
                      }`}
                      onClick={() =>
                        sortingHandler(
                          "default_title",
                          setTableHeaderName,
                          giftCardsList,
                          order,
                          setOrder
                        )
                      }
                    >
                      {t("Name")}
                    </td>
                    <td
                      className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                        tableHeaderName === "id"
                          ? "ascending"
                          : "descending"
                      }`}
                      onClick={() =>
                        sortingHandler(
                          "id", 
                          setTableHeaderName,
                          giftCardsList,
                          order,
                          setOrder
                        )
                      }
                    >
                      {t("Giftcard Id")}
                    </td>
                    <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                        tableHeaderName === "currency"
                          ? "ascending"
                          : "descending"
                      }`}
                      onClick={() =>
                        sortingHandler(
                          "currency", 
                          setTableHeaderName,
                          giftCardsList,
                          order,
                          setOrder
                        )
                      }
                    >
                      {t("Country")} &<br />
                      {t("currency")}
                    </td>
                    <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                        tableHeaderName === "registered_by_id"
                          ? "ascending"
                          : "descending"
                      }`}
                      onClick={() =>
                        sortingHandler(
                          "registered_by_id", 
                          setTableHeaderName,
                          giftCardsList,
                          order,
                          setOrder
                        )
                      }
                    >
                      {t("Registered by")}
                    </td>
                    <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                        tableHeaderName === "giftcard_value_type"
                          ? "ascending"
                          : "descending"
                      }`}
                      onClick={() =>
                        sortingHandler(
                          "giftcard_value_type", 
                          setTableHeaderName,
                          giftCardsList,
                          order,
                          setOrder
                        )
                      }
                    >
                      {t("Giftcard type")}
                    </td>
                    {/*  <td className="card-body font-13 font-bold text-muted">
                      Availability
                    </td>*/}
                  </tr>
                  {giftCardsList &&
                    giftCardsList
                      ?.slice(0, visible)
                      ?.filter((item) => {
                        if (searchTerm === "") {
                          return item;
                        } else if (
                          item?.default_title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => (
                        <tr
                          className="card-body gap-3 border-bottom"
                          key={item?.id}
                        >
                          <td>
                            <div className="d-flex flex-row justify-content-start align-items-center py-2 gap-3">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="checkboxNoLabel"
                                name={item?.id}
                                checked={item?.isChecked}
                                onChange={handleCheck}
                              />
                              <img
                                src={
                                  item?.images && item?.images[0]?.file_url
                                    ? item?.images && item?.images[0]?.file_url
                                    : Giftcard_default
                                }
                                alt="not found"
                                // className="img-fluid"
                                // width="50px"
                                // height="50px"
                                id="product-thumbnail"
                              />
                            </div>
                          </td>
                          <td className="card-body gap-1">
                            <div className="d-flex flex-column justify-content-start ">
                              {/* <span className="font-15 font-boldest text-dark cursor"
                              onClick={() => showModel(item?.id)}
                            > */}
                              <span
                                className="font-bold text-dark font-15 d-inline-block text-truncate cursor"
                                title={item?.default_title}
                                style={{ maxWidth: "140px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => showModel(item?.id)}
                              >
                                {item?.default_title}
                              </span>
                              <span
                                className="font-10 font-bolder text-dark bg-04 rounded-3 px-1 d-inline-block text-truncate cursor"
                                style={{
                                  width: "fit-Content",
                                  maxWidth: "120px",
                                }}
                                title={
                                  item?.giftcard_value_type === "denominations"
                                    ? item?.denominations &&
                                      item?.denominations?.map(
                                        (deno) => deno?.denomination_value
                                      )
                                    : item?.min_amount + "-" + item?.max_amount
                                }
                              >
                                {item?.giftcard_value_type === "denominations"
                                  ? item?.denominations &&
                                    item?.denominations?.map(
                                      (deno) => deno.denomination_value + ","
                                    )
                                  : item?.min_amount + "-" + item?.max_amount}
                              </span>
                            </div>
                          </td>
                          <td className="card-body gap-1">
                            <div
                              className="d-flex flex-row justify-content-between align-items-center  gap-3 visible cursor"
                              title={item?.id}
                            >
                              <span className="fs-light font-15 text-muted ">
                                {trimIdHandler(item?.id)}
                                <span>...</span>
                              </span>
                            </div>
                          </td>
                          <td className="card-body gap-1">
                            <span className="font-13 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                              {item.country
                                ? `${item?.country?.currency} (${item?.country?.currency_symbol})`
                                : t("No data")}
                            </span>
                          </td>
                          <td className="card-body gap-1">
                            <div className="d-flex flex-row justify-contents-between align-items-center">
                              <span>
                                {item?.registered_by_id ===
                                "powered_by_ehadaya" ? (
                                  <span className="font-13 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                                    {t("Powered by ehadaya")}
                                  </span>
                                ) : item?.registered_by_id ===
                                  "3rd_party_suppliers" ? (
                                  <span className="font-13 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                                    {t("3rd party suppliers")}
                                  </span>
                                ) : item?.registered_by_id ===
                                  "manual_inventory" ? (
                                  <span className="font-13 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                                    {t("Manual inventory")}
                                  </span>
                                ) : (
                                  <span className="font-13 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                                    {t("No data")}
                                  </span>
                                )}
                              </span>
                            </div>
                          </td>
                          <td className="card-body gap-1">
                            <span className="font-15 font-bold text-dark px-1">
                              {strFirstLetterCap(item?.giftcard_value_type)}
                            </span>
                          </td>
                          {/*   <td className="card-body ">
                          <span className="font-10 font-bold text-dark px-1">
                            {item.availability === "in_stock" ? (
                              <span className="font-10 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                In stock
                              </span>
                            ) : item.availability === "out_of_stock" ? (
                              <span className="font-10 font-bold text-danger bg-04 bg-opacity-25 rounded-3 px-2">
                                Out of stock
                              </span>
                            ) : (
                              <span className="font-10 font-bold text-danger bg-04 bg-opacity-25 rounded-3 px-2">
                                No stock
                              </span>
                            )}
                          </span>
                        </td>*/}
                        </tr>
                      ))}
                </tbody>
              </table>
              {!giftCardsList?.length && (
                <div className="d-flex flex-row justify-content-center py-3">
                  <span className="font-20 font-bold text-error">
                    {t("Giftcard not found")}
                  </span>
                </div>
              )}
              <div className="d-flex flex-row  justify-content-center align-item-center mb-3 gap-3">
                <div className="d-flex pt-3">
                  {visible < giftCardListState?.length && (
                    <Button
                      type="rounded"
                      text={t("Load more")}
                      className="rounded-6 "
                      onClick={() => loadMore()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-3">
            <Button
              type="primary"
              text={t("Continue")}
              className="rounded-6 "
              onClick={() => goToClientMappingVariants()}
            />
          </div>
          <ModalComp
            size="md"
            showModal={showModal}
            // handleClose={handleClose}
            component={
              <SupplierMappingAlertModel
                handleClose={handleClose}
                productType="giftcard"
              />
            }
          />
          <ModalComp
            size="lg"
            showModal={showSummeryModal}
            // handleClose={handleSummeryClose}
            component={
              <ModelForGiftCardSummery
                handleClose={handleSummeryClose}
                giftcardSummery={giftcardSummery}
              />
            }
          />
          <div
            className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg="Please select giftcard to map."
              imageType={"warning"}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default ClientGiftcardList;
