import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ReactComponent as SuccessIcon } from "../../assests/svg/Success.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PartnerSuccess = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const partnerResponese = useSelector((state) => state?.partners?.createPartnerResponese);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (partnerResponese === null) {
      history("/patner-creation");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToHome = () => {
    history("/partner-creation");
  };

  return (
    <div className="d-flex flex-column  align-item-center bg-light w-100 py-5">
      <div className="d-flex flex-row w-100 justify-content-center">
        <SuccessIcon />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-dark font-bold font-40">
          {t("Partner created successfully")}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-muted font-bold font-15">
          {t("Partner ID")}: {partnerResponese?.id}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"rounded"} text={t("Add Partner")} onClick={() => goToHome()} />
      </div>
    </div>
  );
};
export default PartnerSuccess;
