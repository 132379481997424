/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import InputField from "../InputField/InputField";
import InputGroupTxt from "../InputField/InputGroupTxt";
import Button from "../Button/Button";
import InfoTooltip from "../Tooltip/InfoTooltip";
import { useDispatch, useSelector } from "react-redux";
import DragandDrop from "../DragandDrop/DragandDrop";
import ReactSwitchButton from "../Button/ReactSwitchButton";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import { fetchPartnerCategory, patchPartner, } from "../../Redux/Partner/partnerAction";
import Toasts from "../Toasts/Toasts";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import PulseLoader from "../Loader/PulseLoader";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import Select from "react-select";
import { GIFTCARD_REDEMPTION_METHOD, } from "../../utils/ConstantData";
import { getPratnerFloats } from "../../Redux/Float/floatAction";
import { useTranslation } from "react-i18next";

const UpdatePartner = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const inputElementName = useRef(null);
  const inputElementEmail = useRef(null);
  const inputElementPassword = useRef(null);

  const partnerItem = location.state.partnerItem;
  const addressArr = partnerItem.address && partnerItem.address.split(",");

  const countries = useSelector((state) => state?.countries?.countries);
  const loader = useSelector((state) => state?.partners?.loading);
  const partnerData = useSelector((state) => state.partners?.createpartnerData);
  const partnerCategory = useSelector(
    (state) => state.partners?.partnerCategory
  );
  const floatCurrency = useSelector((state) => state?.partnerFloat?.floats);

  // for toast
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);
  // to hide and show password
  const [passShowHide, setPassShowHide] = useState(false);

  const togglePassword = () => {
    setPassShowHide((prevState) => !prevState);
  };
  const [dropDownObj, setDropDownObj] = useState({
    country: partnerItem ? partnerItem.country_name : "",
    state: partnerItem ? partnerItem.state_name : "",
    partner: partnerItem ? partnerItem.partner_category : "",
    product_type: "",
    currency: partnerData ? partnerData.currency : "",
  });

  const [redemptionMethod, setRedemptionMethod] = useState(
    partnerData ? partnerData.redemptionMethod : ""
  );

  const [imgFiles, setImgFiles] = useState([]);
  const [patnerObj, setPatnerObj] = useState({
    name: partnerItem ? partnerItem?.partner_name : "",
    city: partnerItem ? partnerItem.city_name : "",
    buildingName: addressArr && addressArr.length > 0 ? addressArr[0] : "",
    streetAddress: addressArr && addressArr.length > 0 ? addressArr[1] : "",
    landmark: addressArr && addressArr.length > 0 ? addressArr[2] : "",
    zipcode: addressArr && addressArr.length > 0 ? addressArr[3] : "",
    companyname: partnerItem ? partnerItem?.company_name : "",
    emailid: partnerItem ? partnerItem?.email : "",
    password: partnerItem ? partnerItem?.encrypted_password : "",
    phonenumber: partnerItem ? partnerItem?.contact_no : "",
  });

  const [error, setError] = useState({
    name: "",
    country: "",
    state: "",
    city: "",
    buildingName: "",
    streetAddress: "",
    landmark: "",
    zipcode: "",
    emailid: "",
    password: "",
    partner: "",
  });

  const [tags, setTags] = useState(partnerData?.tags ? partnerData?.tags : []);
  const [notEditableCountry, setNotEditableCountry] = useState([]);
  const [switchButton, setSwitchButton] = useState({
    floatFlag: partnerItem?.float_flag ? true : false,
    active_flag: partnerItem?.active_flag ? true : false,
  });

  const [partnerFloatApiError, setPartnerFloatApiError] = useState("");

  const partnerFloatApiErrorHandler = (value) => {
    setPartnerFloatApiError(value);
  };

  useEffect(() => {
    dispatch(getPratnerFloats(partnerItem?.id, partnerFloatApiErrorHandler));
  }, [partnerItem]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchPartnerCategory());
    dispatch(getAllCountriesList());

  }, []);

  useEffect(() => {
    partnerCategory &&
      partnerCategory.map((item) => {
        if (partnerItem?.partner_category === item.partner_category) {
          setDropDownObj({ ...dropDownObj, partner: item });
        }
      });
  }, [partnerCategory, partnerItem]);

  useEffect(() => {
    partnerItem &&
      GIFTCARD_REDEMPTION_METHOD &&
      GIFTCARD_REDEMPTION_METHOD.map((item) => {
        if (partnerItem?.redemption_type === item?.value) {
          setRedemptionMethod(item);
        }
      });
  }, [GIFTCARD_REDEMPTION_METHOD, partnerItem]);

  useEffect(() => {
    let notEditable = [];
    let allCountriesArr = [];

    // Default country
    countries &&
      countries.map((item) => {
        if (partnerItem?.country_id === item?.id) {
          setDropDownObj({ ...dropDownObj, country: item });
          notEditable.push(item);
        }
      });

    // Float avialbe balance country
    floatCurrency &&
      floatCurrency.map((floatCur) => {
        if (floatCur?.balance !== 0) {
          countries &&
            countries.map((item) => {
              if (item?.id === floatCur?.country_id) {
                notEditable.push(item);
              }
            });
        }
      });

    // Sort unic country in array
    const uniqueArr = [];
    notEditable && notEditable.forEach((item) => {
      //pushes only unique element
      if (!uniqueArr.includes(item)) {
        uniqueArr.push(item);
      }
    })

    setNotEditableCountry(uniqueArr);


    countries &&
      countries.map((item) => {
        if (partnerItem?.currencies_id && partnerItem?.currencies_id) {
          partnerItem?.currencies_id &&
            partnerItem?.currencies_id.map((currency) => {
              if (currency === item.id) {
                allCountriesArr.push(item);
              }
            });
          setTags(getDifference(allCountriesArr, notEditable));
        }
      });

  }, [partnerItem, countries, floatCurrency]);

  const getDifference = (array1, array2) => {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.id === object2.id;
      });
    });
  }

  useEffect(() => {
    let finalArr = []
    tags && tags.map((tagsItem) => {
      if (notEditableCountry.some((floatItem) => floatItem?.id !== tagsItem?.id)) {
        finalArr.push(tagsItem)
      }
    })
  }, [tags])

  // phone number validation handle
  function handlePhoneNumber(evt) {
    // eslint-disable-next-line array-callback-return
    let regex = /^\d{0,15}?$/;
    const value = evt.target.value;
    if (regex.test(evt.target.value)) {
      setPatnerObj({
        ...patnerObj,
        [evt.target.name]: value,
      });
    }
  }

  const handleInputChange = (e) => {
    const target = e.target;
    var value = target.value;
    const name = target.name;
    setPatnerObj({
      ...patnerObj,
      [name]: value,
    });
    if (name === "emailid") {
      setError({ emailid: "" });
    } else if (name === "password") {
      setError({ password: "" });
    }
  };

  const changefloatFlag = () => {
    setSwitchButton({ ...switchButton, floatFlag: !switchButton.floatFlag });
  };

  const changeActiveFlag = () => {
    setSwitchButton({
      ...switchButton,
      active_flag: !switchButton.active_flag,
    });
  };

  const handleDropdownSelect = (item, type) => {
    // setDropDownObj(dropDownObj.countryCurrency.push(item?.id))
    setDropDownObj({ ...dropDownObj, [type]: item });

    //to generate tags on dropdown change
    if (type.name === "currency") {
      //   let value = item;
      dropdownTags(item);
    }
  };

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const dropdownTags = (item) => {
    //   let tagHead = item;
    if (tags && tags.some((tag) => tag?.id === item?.id)) {
      toggleShowToast();
      return;
    } else {
      setTags([...tags, item]);
    }
  };

  const inputTags = (event) => {
    let value = event.target.value;
    const re = /^[A-Za-z]+$/;
    if (value === null) {
      event.target.value = "";
      return false;
    } else if (!re.test(value)) {
      event.target.value = "";
      return false;
    } else {
      if (tags.some((tag) => tag === value.toUpperCase())) {
        toggleShowToast();
        event.target.value = "";
        return;
      } else {
        setTags([...tags, value.toUpperCase()]);
        event.target.value = "";
      }
    }
  };
  // end here

  const validate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var passRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,}$)"
    );
    if (!patnerObj.name) {
      setError({ name: t("Name field is required") });
      if(inputElementName.current){
        inputElementName.current.focus()
      }
      return false;
    } else if (!dropDownObj.country) {
      setError({ country: t("Country field is required") });
      return false;
    } else if (!patnerObj.buildingName) {
      setError({ buildingName: t("Building field is required") });
      return false;
    } else if (!patnerObj.streetAddress) {
      setError({ streetAddress: t("Street Address field is required") });
      return false;
    } else if (!patnerObj.emailid) {
      setError({ emailid: t("Enter email id") });
      if(inputElementEmail.current){
        inputElementEmail.current.focus()
      }
      return false;
    } else if (
      !patnerObj.emailid ||
      emailRegex.test(patnerObj.emailid) === false
    ) {
      setError({ emailid: t("Enter valid email") });
      return false;
    } else if (!patnerObj.password) {
      setError({ password: t("Enter password") });
      if(inputElementPassword.current){
        inputElementPassword.current.focus()
      }
      return false;
    } else if (
      !patnerObj.password ||
      passRegex.test(patnerObj.password) === false
    ) {
      setError({
        password: t(`Minimum 8 characters, at least one uppercase letter,
        one lowercase letter, one number and one special character`),
      });
      return false;
    }

    return true;
  };

  const createPartnerHandle = () => {
    let tagArrCurrency = [];
    let countryArr = [...notEditableCountry, ...tags]

    countryArr && countryArr.map((item) => {
      tagArrCurrency.push(item.id);
    });

    let partnerObj = {
      id: partnerItem.id,
      name: patnerObj.name,
      //  country: dropDownObj.country,
      //  state: dropDownObj.state,
      city: patnerObj.city,
      address: [
        { id: 1, name: "Building Name", value: patnerObj.buildingName },
        { id: 2, name: "Street Address", value: patnerObj.streetAddress },
        { id: 3, name: "Landmark", value: patnerObj.landmark },
        { id: 4, name: "Zipcode", value: patnerObj.zipcode },
      ],
      buildingName: patnerObj.buildingName,
      streetAddress: patnerObj.streetAddress,
      landmark: patnerObj.landmark,
      zipcode: patnerObj.zipcode,
      imgFiles: imgFiles,
      //  partner: dropDownObj.partner,
      companyname: patnerObj.companyname,
      emailid: patnerObj.emailid,
      password: patnerObj.password,
      phonenumber: patnerObj.phonenumber,
      enableFloatFlag: switchButton.floatFlag,
      active_flag: switchButton.active_flag,
      countryCurrency: tagArrCurrency,
      redemptionMethod: redemptionMethod,
    };

    let val = validate();
    if (val) {
      dispatch(patchPartner(partnerObj, goToCurrencyMapping));
    }
  };

  const goToCurrencyMapping = () => {
    if (dropDownObj.partner?.partner_category === "supplier") {
      history("/supplier-mapping-dashboard");
    } else if (dropDownObj.partner?.partner_category === "client") {
      history("/clients-mapping-dashboard");
    } else {
      history("/organization-mapping-dashboard");
    }
  };

  const generatePassword = () => {
    const alpha = "abcdefghijklmnopqrstuvwxyz";
    const calpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const num = "1234567890";
    const specials = ",.!@#$%^&*";
    const options = [
      alpha,
      alpha,
      alpha,
      calpha,
      calpha,
      num,
      num,
      num,
      specials,
      specials,
    ];
    let opt, choose;
    let pass = "";
    for (let i = 0; i < 10; i++) {
      opt = Math.floor(Math.random() * options.length);
      choose = Math.floor(Math.random() * options[opt].length);
      pass = pass + options[opt][choose];
      options.splice(opt, 1);
    }

    setPatnerObj({ ...patnerObj, password: pass });
  };

  return (
    <>
      {loader === false ? (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-20 font-bold">{t("Name & Description")}</span>
            </div>
          </div>
          <div className="d-flex flex-column gap-4 mt-4">
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Creating partner as")}</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              {/* Dropdown partner */}
              <div className="btn-group w-100">
                <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                  {dropDownObj.partner?.partner_category
                    ? dropDownObj.partner?.partner_category
                    : t("Select")}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Name")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
              inputRef={inputElementName}
                placeholder={t("Enter username")}
                name="name"
                value={patnerObj.name}
                onChange={handleInputChange}
              />
              {!patnerObj.name && error.name ? (
                <label className="text-error">{error.name}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Country")}</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="btn-group w-100">
                <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                  {partnerItem?.country_name
                    ? partnerItem?.country_name
                    : t("Select")}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("State")}</span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                {/* Dropdown state*/}

                <div className="btn-group w-100">
                  <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                    {partnerItem?.state_name
                      ? partnerItem?.state_name
                      : t("Select")}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("City")}</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>

              <InputField
                placeholder={t("Enter city")}
                name="city"
                value={patnerObj.city}
                onChange={handleInputChange}
              />
              {!dropDownObj.city && error.city ? (
                <label className="text-error">{error.city}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Complete Address")}</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="d-flex flex-row justify-content-between align-items-center gap-2 py-3 w-100">
                <div className="d-flex flex-column justify-content-between align-items-center h-75 w-100">
                  <div className="d-flex w-100">
                    <InputField
                      placeholder={t("Building/Apartment/Floor")}
                      name="buildingName"
                      value={patnerObj.buildingName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-flex w-100">
                    {!patnerObj.buildingName && error.buildingName ? (
                      <label className="text-error">{error.buildingName}</label>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-between align-items-center h-75 w-100">
                  <div className="d-flex w-100">
                    <InputField
                      placeholder={t("Street Address")}
                      name="streetAddress"
                      value={patnerObj.streetAddress}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-flex w-100">
                    {!patnerObj.streetAddress && error.streetAddress ? (
                      <label className="text-error">
                        {error.streetAddress}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
                <InputField
                  placeholder={t("Land Mark")}
                  name="landmark"
                  value={patnerObj.landmark}
                  onChange={handleInputChange}
                />
                <InputField
                  placeholder={t("Zip Code")}
                  name="zipcode"
                  type="number"
                  value={patnerObj.zipcode}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-2 ">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Logo/ Client Image")}</span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <div className="d-flex flex-column">
                  <DragandDrop files={imgFiles} setFiles={setImgFiles} />
                </div>
              </div>
            </div>

            {dropDownObj.partner.partner_category === "supplier" ? (
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Product Type")}</span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <div className="btn-group w-100">
                  <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                    {partnerItem?.product_type
                      ? partnerItem?.product_type
                      : t("Select")}
                  </div>
                </div>
              </div>
            ) : null}

            {dropDownObj.partner.partner_category === "supplier" &&
              partnerItem?.product_type === "giftcard" ? (
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Redemption method")}</span>
                  <InfoTooltip title={"Redemption method"} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <Select
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select redemption method")}
                    getOptionLabel={(obj) => obj.name}
                    options={GIFTCARD_REDEMPTION_METHOD}
                    //   isSearchable
                    value={redemptionMethod}
                    onChange={setRedemptionMethod}
                    isClearable
                    styles={CUSTOM_DROPDOWN_STYLE}
                  />
                </div>
              </div>
            ) : null}

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Company name")}</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
                placeholder={t("Enter username")}
                name="companyname"
                value={patnerObj.companyname}
                onChange={handleInputChange}
              />
            </div>
            {/* {!patnerObj.compName&&error.compName?
          <label className="text-error">{error.compName}</label>:null
        } */}
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Email Id")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
              inputRef={inputElementEmail}
                placeholder={t("Enter your email")}
                name="emailid"
                value={patnerObj.emailid}
                onChange={handleInputChange}
              />
              {error.emailid ? (
                <label className="text-error">{error.emailid}</label>
              ) : null}
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Password")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="input-group  position-relative">
                <InputField
                inputRef={inputElementPassword}
                  type={passShowHide ? "text" : "password"}
                  placeholder={t("Example Sam.01@123")}
                  name="password"
                  value={patnerObj.password}
                  onChange={handleInputChange}
                />
                <div className={`input-group-append position-absolute position-absolute top-0 ${props?.lang === 'ar' ? 'start-0' : 'end-0'}`}>
                  <button
                    className="btn  bg-tranparent "
                    type="button"
                    onClick={togglePassword}
                  >
                    {passShowHide ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </button>
                </div>
              </div>
              {error.password ? (
                <label className="text-error">{error.password}</label>
              ) : null}
              <span
                className="text-primary cursor"
                onClick={() => generatePassword()}
              >
                {t("Generate password")}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Phone number")}</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputGroupTxt
                title={
                  dropDownObj?.country?.phone_code
                    ? "+" + dropDownObj?.country?.phone_code
                    : ""
                }
                placeholder={t("Enter phone number")}
                type="number"
                name="phonenumber"
                lang={props?.lang}
                value={patnerObj.phonenumber}
                onChange={handlePhoneNumber}
              />
            </div>

            <div className="d-flex flex-row justify-content-between gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Active")}</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <ReactSwitchButton
                onChange={changeActiveFlag}
                value={switchButton.active_flag}
              />
            </div>

            {dropDownObj.partner.partner_category === "client" ||
              dropDownObj.partner.partner_category === "organization" ? (
              <div className="d-flex flex-row justify-content-between gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Enable float?")}</span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <ReactSwitchButton
                  onChange={changefloatFlag}
                  value={switchButton.floatFlag}
                  disabled={!switchButton.active_flag}
                />
              </div>
            ) : null}

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Select currencies")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="">
                <Select
                  name={"currency"}
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select currencies")}
                  getOptionLabel={(currencies) =>
                    currencies?.name + " - " + currencies?.currency
                  }
                  options={countries}
                  isSearchable
                  value={dropDownObj.currency}
                  onChange={handleDropdownSelect}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <div className="tags-input border border-2">
                  <ul id="tags">
                    {tags &&
                      tags.map((tag, index) => (
                        <li key={index} className="tag align-items-center">
                          <span className="">
                            {tag.name + "-" + tag.currency}
                          </span>
                          <span className="tag-close-icon" onClick={() => removeTags(index)} >
                            x
                          </span>
                        </li>
                      ))}
                  </ul>
                  <input
                    type="text"
                    name="tags"
                    onKeyUp={(event) =>
                      event.key === "Enter" ? inputTags(event) : null
                    }
                    disabled={true}
                  />
                </div>
              </div>
              {!tags.length && error.currency ? (
                <label className="text-error">{error.currency}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-3">

              <span className="font-bold font-14">
                {t("Not editable country")}
              </span>
              <div className="d-flex flex-row align-items-center gap-2">
                <div className="tags-input border border-2">
                  <ul id="tags">
                    {notEditableCountry &&
                      notEditableCountry.map((tag, index) => (
                        <li key={index} className="tag align-items-center">
                          <span className="">
                            {tag.name + "-" + tag.currency}
                          </span>
                          {/* <span className="tag-close-icon" onClick={() => removeTags(index)} >
                            x
                          </span> */}
                        </li>
                      ))}
                  </ul>
                  <input
                    type="text"
                    name="tags"
                    onKeyUp={(event) =>
                      event.key === "Enter" ? inputTags(event) : null
                    }
                    disabled={true}
                  />
                </div>
              </div>
              {!tags.length && error.currency ? (
                <label className="text-error">{error.currency}</label>
              ) : null}
            </div>

            <div className="d-flex">
              <Button
                onClick={() => createPartnerHandle()}
                text={t("Update")}
                type="primary"
              />
            </div>
          </div>
          <div
            className="position-fixed bottom-0 start-50 mb-4 translate-middle-x w-100 "
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg="Already added."
              imageType={"warning"}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default UpdatePartner;
