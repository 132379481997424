export const FETCH_AUTH_TOKEN_REQUEST = "FETCH_AUTH_TOKEN_REQUEST";
export const FETCH_AUTH_TOKEN_SUCCESS = "FETCH_AUTH_TOKEN_SUCCESS";
export const FETCH_AUTH_TOKEN_FAILURE = "FETCH_AUTH_TOKEN_FAILURE";

// login 
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";


export const VALID_TOKEN = "VALID_TOKEN"