export const SET_SELECTED_GIFTCARD = "SET_SELECTED_GIFTCARD";

export const SET_SELECTED_SUPPLIER = "SET_SELECTED_SUPPLIER";

export const SET_SELECTED_MERTCHANDISE = "SET_SELECTED_MERTCHANDISE";

export const SET_NO_SUPPLIER_PRODUCT = "SET_NO_SUPPLIER_PRODUCT";

export const SET_ALL_SELECTED_MERTCHANDISE_PROD = "SET_ALL_SELECTED_MERTCHANDISE_PROD";

export const PUT_GIFTCARD_SUPPLIER_MAPPING_REQUEST = "PUT_GIFTCARD_SUPPLIER_MAPPING_REQUEST";
export const PUT_GIFTCARD_SUPPLIER_MAPPING_SUCCESS = "PUT_GIFTCARD_SUPPLIER_MAPPING_SUCCESS";
export const PUT_GIFTCARD_SUPPLIER_MAPPING_FAILURE = "GET_GIFTCARD_SUPPLIER_MAPPING_FAILURE";