import React from "react";
import { ReactComponent as ProdActIcon } from "../../assests/svg/Dashboard/ProdAct_Icon.svg";
import { ReactComponent as RedDownArrow } from "../../assests/svg/Product/Red_DownArrow.svg";
import { ReactComponent as GreenUpArrow } from "../../assests/svg/Product/Green_UpArrow.svg";
import { useTranslation } from "react-i18next";

const ProductActivity = () => {
  const { t } = useTranslation();

  const Activity = [
    // {
    //   Week: "25Sep-1Oct",
    //   Products: "123",
    //   ProdPercent: "37.8%",
    //   Sales: "$24k",
    //   SalePercent: "37.8%",
    //   TotalSold: "423",
    //   TotalPercent: "37.8%",
    //   Recharged: "16",
    //   RechPercent: "37.8%",
    // },
    // {
    //   Week: "25Sep-1Oct",
    //   Products: "123",
    //   ProdPercent: "37.8%",
    //   Sales: "$24k",
    //   SalePercent: "37.8%",
    //   TotalSold: "423",
    //   TotalPercent: "37.8%",
    //   Recharged: "16",
    //   RechPercent: "37.8%",
    // },
    // {
    //   Week: "25Sep-1Oct",
    //   Products: "123",
    //   ProdPercent: "37.8%",
    //   Sales: "$24k",
    //   SalePercent: "37.8%",
    //   TotalSold: "423",
    //   TotalPercent: "37.8%",
    //   Recharged: "16",
    //   RechPercent: "37.8%",
    // },
    // {
    //   Week: "25Sep-1Oct",
    //   Products: "123",
    //   ProdPercent: "37.8%",
    //   Sales: "$24k",
    //   SalePercent: "37.8%",
    //   TotalSold: "423",
    //   TotalPercent: "37.8%",
    //   Recharged: "16",
    //   RechPercent: "37.8%",
    // },
  ];

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 w-100">
      <div className="card-body d-flex justify-content-between flex-row">
        <div className="card-body d-flex gap-4 flex-start justify-content-start">
          <ProdActIcon />
          <span className="font-20 font-bold">{t("Product Activity")}</span>
        </div>
       {/* <div>
          <div className="btn-group  ">
            <div
              className="btn border border-2 rounded-card-7 dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              Last 2 Weeks
            </div>
            <ul className="dropdown-menu rounded-card-7 dropdown-menu-end">
              <li>
                <div className="dropdown-item">Last Week</div>
              </li>
              <li>
                <div className="dropdown-item">Last 2 Weeks</div>
              </li>
              <li>
                <div className="dropdown-item">Last 3 Weeks</div>
              </li>
            </ul>
          </div>
        </div>*/}
      </div>
      <div className="mx-3">
        <table responsive="xl">
          <tbody>
            <tr className="border-bottom mb-2">
              <td className="col-2">
                <div className="font-14 font-bold text-muted">{t("Week")}</div>
              </td>
              <td className="col-2">
                <div className="font-14 font-bold text-muted">{t("Product")}</div>
              </td>
              <td className="col-2">
                <div className="font-14 font-bold text-muted">{t("Sales")}</div>
              </td>
              <td className="col-2">
                <div className="font-14 font-bold text-muted">{t("Total sold")}</div>
              </td>
              <td className="col-2">
                <div className="font-14 font-bold text-muted">{t("Recharged")}</div>
              </td>
            </tr>
            {Activity && Activity.map((item, i) => (
              <tr className="border-bottom mb-2" key={i}>
                <td className="col-2 py-2">
                  <div>
                    <span className="font-13 font-bolder text-dark ">
                      {item.Week}
                    </span>
                  </div>
                </td>
                <td className="col-2 py-2">
                  <div className="d-flex flex-row justify-contents-between align-items-center gap-2">
                    <span className="font-13 font-bolder text-dark bg-04 rounded-3 px-2">
                      {item.Products}
                    </span>
                    <div className="d-flex gap-2">
                      <GreenUpArrow />
                      <span className="font-12 font-bolder text-success bg-opacity-25 rounded-3">
                        {item.ProdPercent}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="col-2 py-2">
                  <div className="d-flex flex-row justify-contents-between align-items-center gap-2">
                    <span className="font-13 font-bolder text-dark bg-02 bg-opacity-25 rounded-3 px-2">
                      {item.Sales}
                    </span>
                    <div className="d-flex gap-2">
                      <GreenUpArrow />
                      <span className="font-12 font-bolder text-success bg-opacity-25 rounded-3">
                        {item.SalePercent}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="col-2 py-2">
                  <div className="d-flex flex-row justify-contents-between align-items-center gap-2">
                    <span className="font-13 font-bolder text-dark  bg-dark bg-opacity-25 rounded-3 px-2">
                      {item.TotalSold}
                    </span>
                    <div className="d-flex gap-2">
                      <RedDownArrow />
                      <span className="font-12 font-bolder text-danger bg-opacity-25 rounded-3 px-1">
                        {item.TotalPercent}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="col-2 py-2">
                  <div className="d-flex flex-row justify-contents-between align-items-center gap-2">
                    <span className="font-13 font-bolder text-dark bg-danger bg-opacity-25 rounded-3 px-2">
                      {item.Recharged}
                    </span>
                    <div className="d-flex gap-2">
                      <RedDownArrow />
                      <span className="font-12 font-bolder text-danger bg-opacity-25 rounded-3 px-1">
                        {item.RechPercent}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!Activity.length && (
          <div className="d-flex flex-row justify-content-center py-3">
            <span className="font-20 font-bold text-error">
              {t("No product activity found")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductActivity;
