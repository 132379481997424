import {
  SET_CATEGORY_ATTRIBUTE_DATA,
  SET_NAME_DESCRIPTION_DATA,
  SET_MERCHANDISE_TERM_CONDITION,
  SET_MERCHANDISE_MAPPING_DATA,
  SET_MERCHANDISE_SORT_VARIANTS_DATA,
  PUT_MERCHANDISE_SUCCESS,
  PUT_MERCHANDISE_FAILURE,
  GET_MERCHANDISE_SUCCESS,
  GET_MERCHANDISE_FAILURE,
  GET_MERCHANDISE_REQUEST,
  PUT_MERCHANDISE_UPLOAD_IMG_FAILURE,
  PUT_MERCHANDISE_UPLOAD_IMG_SUCCESS,
  PUT_MERCHANDISE_UPLOAD_IMG_REQUEST,
  GET_MERCHANDISE_BY_ID_SUCCESS,
  GET_MERCHANDISE_BY_ID_FAILURE,
  GET_MERCHANDISE_BY_ID_REQUEST,
  PUT_MERCHANDISE_REQUEST,
  GET_MERCHANDISE_SUMMERY_BY_ID_SUCCESS,
  GET_MERCHANDISE_SUMMERY_BY_ID_FAILURE,
  GET_MERCHANDISE_SUMMERY_BY_ID_REQUEST,
  SET_FILTER_MERCHANDISE_DATA,
  EDIT_MERCHANDISE_REQUEST,
  EDIT_MERCHANDISE_SUCCESS,
  EDIT_MERCHANDISE_FAILURE,
  GET_MERCHANDISE_IMAGE_SUCCESS,
} from "./merchandiseTypes";

const intialMerchandiseCategoryAttribute = {
  loading: false,
  error: "",
  merchandiseList: "",
  merchandiseNameDes: null,
  merchandiseCatAtt: null,
  merchandiseTermCondition: null,
  merchandiseMappingData: "",
  merchandiseSortVariants: "",
  merchandiseResposeData: null,
  uploadImgResponse: [],
  merchandiseByIdForEdit: null,
  merchandiseTandC: "",
  merchandiseSummery: null,
  merchandiseFilterData: [],
  merchandiseImage: null,
};

const merchandiseCatAttReducer = (
  state = intialMerchandiseCategoryAttribute,
  action
) => {
  switch (action.type) {
    case SET_MERCHANDISE_SORT_VARIANTS_DATA:
      return {
        ...state,
        merchandiseSortVariants: action.payload,
      };
    case SET_CATEGORY_ATTRIBUTE_DATA:
      return {
        ...state,
        merchandiseCatAtt: action.payload,
      };
    case SET_NAME_DESCRIPTION_DATA:
      return {
        ...state,
        merchandiseNameDes: action.payload,
      };
    case SET_MERCHANDISE_TERM_CONDITION:
      return {
        ...state,
        merchandiseTandC: action.payload,
      };
    case SET_MERCHANDISE_MAPPING_DATA:
      return {
        ...state,
        merchandiseMappingData: action.payload,
      };
    // merchandise put
    case PUT_MERCHANDISE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case PUT_MERCHANDISE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        merchandiseNameDes: null,
        merchandiseCatAtt: null,
        merchandiseTermCondition: null,
        merchandiseMappingData: "",
        merchandiseSortVariants: "",
        merchandiseTandC: "",
        merchandiseResposeData: action.payload,
      };
    case PUT_MERCHANDISE_FAILURE:
      return {
        ...state,
        loading: false,
        merchandiseResposeData: null,
        error: action.payload,
      };
    case EDIT_MERCHANDISE_REQUEST:
      return {
        ...state,
        loading: true,
        merchandiseResposeData: null,
        error: "",
      };
    case EDIT_MERCHANDISE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        merchandiseNameDes: null,
        merchandiseCatAtt: null,
        merchandiseTermCondition: null,
        merchandiseMappingData: "",
        merchandiseSortVariants: "",
        merchandiseTandC: "",
        merchandiseResposeData: action.payload,
      };
    case EDIT_MERCHANDISE_FAILURE:
      return {
        ...state,
        loading: false,
        merchandiseResposeData: null,
        error: action.payload,
      };
    // merchandise get
    case GET_MERCHANDISE_REQUEST:
      return {
        ...state,
        loading: true,
        merchandiseList: {},
        errorMsg: "",
      };
    case GET_MERCHANDISE_SUCCESS:
      return {
        ...state,
        loading: false,
        merchandiseList: action.payload,
        errorMsg: "",
      };
    case GET_MERCHANDISE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
      };
    case SET_FILTER_MERCHANDISE_DATA:
      return {
        ...state,
        merchandiseFilterData: action.payload,
      };
    // Get Merchandis Summry
    case GET_MERCHANDISE_SUMMERY_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        merchandiseSummery: {},
        errorMsg: "",
      };
    case GET_MERCHANDISE_SUMMERY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        merchandiseSummery: action.payload,
        errorMsg: "",
      };
    case GET_MERCHANDISE_SUMMERY_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
      };

    // merchandise post images
    case PUT_MERCHANDISE_UPLOAD_IMG_REQUEST:
      return {
        ...state,
        loading: true,
        uploadImgResponse: [],
        errorMsg: "",
      };
    case PUT_MERCHANDISE_UPLOAD_IMG_SUCCESS:
      // let arr = [...intialMerchandiseCategoryAttribute.uploadImgResponse];
      // let imgObj = action.payload;
      // console.log(arr.length)
      // if (arr.length > 0) {
      //   console.log("frist")
      //   arr && arr.map((item, i) => {
      //     if (arr.some((item) => item.prodVariantName === imgObj.prodVariantName)) {
      //       // arr.splice(i, 1);
      //       console.log("added")
      //       // arr.push(imgObj)
      //     } else {
      //       arr.push(imgObj)
      //     }
      //   })
      // } else {
      //   console.log("second")
      //   arr.push(imgObj)
      // }
      // console.log("hh " + JSON.stringify(imgObj))

      return {
        ...state,
        loading: false,
        uploadImgResponse: action.payload,
        errorMsg: "",
      };
    case PUT_MERCHANDISE_UPLOAD_IMG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
        uploadImgResponse: [],
      };
    //Get merchandise by id for edit
    case GET_MERCHANDISE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        merchandiseByIdForEdit: {},
        errorMsg: "",
      };
    case GET_MERCHANDISE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        merchandiseByIdForEdit: action.payload,
        errorMsg: "",
      };
    case GET_MERCHANDISE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
      };
    case GET_MERCHANDISE_IMAGE_SUCCESS:
      return {
        ...state,
        merchandiseImage: action.payload,
        error: ''
      };
    default:
      return state;
  }
};
export default merchandiseCatAttReducer;
