/* eslint-disable array-callback-return */
import axios from "axios";
import {
  FETCH_ALL_BRAND_CATEGORY_REQUEST,
  FETCH_ALL_BRAND_CATEGORY_SUCCESS,
  FETCH_ALL_BRAND_CATEGORY_FAILURE,
  PUT_BRAND_CATEGORY_REQUEST,
  PUT_BRAND_CATEGORY_SUCCESS,
  PUT_BRAND_CATEGORY_FAILURE,
  PUT_BRAND_CATEGORY_ID_SUCCESS,
  PUT_BRAND_CATEGORY_ID_REQUEST,
  PUT_BRAND_CATEGORY_ID_FAILURE,
  PATCH_BRAND_CATEGORY_REQUEST,
  PATCH_BRAND_CATEGORY_SUCCESS,
  PATCH_BRAND_CATEGORY_FAILURE,
  BRAND_CATEGORY_FILTER_DATA,
  CLEAR_BRAND_CATEGORY_LIST

} from "./brandCategoryType";
import { baseUrl, getToken } from "../../utils/prodURL";

export const fetchAllBrandCategoryRequest = () => {
  return {
    type: FETCH_ALL_BRAND_CATEGORY_REQUEST,
  };
};
export const fetchAllBrandCategorySuccess = (data) => {
  return {
    type: FETCH_ALL_BRAND_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const fetchAllBrandCategoryFailure = (error) => {
  return {
    type: FETCH_ALL_BRAND_CATEGORY_FAILURE,
    payload: error,
  };
};
export const setBrandCategoryFilterData = (data) => {
  return {
    type: BRAND_CATEGORY_FILTER_DATA,
    payload: data,
  };
};
export const getAllBrandCategoryList = (skip, limit, categoriesState) => {
  if (!categoriesState) categoriesState = []
  return (dispatch) => {
    dispatch(fetchAllBrandCategoryRequest());
    let url = skip !== undefined && limit !== undefined ? `${baseUrl}/v1/brand_category?skip=${skip}&limit=${limit}` : `${baseUrl}/v1/brand_category`
    let data = { active_flag: true };
    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        let data = {
          data: [...categoriesState, ...response.data.result],
          moredata: response.data.next_iteration || false
        }
        dispatch(fetchAllBrandCategorySuccess(data));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchAllBrandCategoryFailure(errorMsg));
      });
  };
};

// put brand category
export const putBrandCategoryRequest = () => {
  return {
    type: PUT_BRAND_CATEGORY_REQUEST,
  };
};

export const putBrandCategorySuccess = (obj) => {
  return {
    type: PUT_BRAND_CATEGORY_SUCCESS,
    payload: obj,
  };
};

export const putBrandCategoryFailure = (obj) => {
  return {
    type: PUT_BRAND_CATEGORY_FAILURE,
    payload: obj,
  };
};

export const putBrandCategory = (obj, goToSuccessfull) => {

  return (dispatch) => {
    dispatch(putBrandCategoryRequest());
    let brandCatTitleArr = [];

    obj?.categoryName?.map((item) => {
      brandCatTitleArr.push({
        [item.language_id.language_code]: item.category_name,
      });
    });
    let object = Object.assign({}, ...brandCatTitleArr);

    let brandCategoryObj = {
      category_names: object,
      default_category_name: obj?.categoryName[0].category_name,
      active_flag: true,
    };

    const formData = new FormData();
    formData.append("request_items", JSON.stringify(brandCategoryObj));
    obj.categoryImage[0] && formData.append("image", obj.categoryImage[0]);

    let config = {
      method: "post",
      url: `${baseUrl}/v1/brand_category`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: formData,
    };
    axios(config)
      .then((response) => {

        if (response.status === 200) {
          dispatch(putBrandCategorySuccess(response.data));
          goToSuccessfull();
          dispatch(getAllBrandCategoryList());
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          putBrandCategoryFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const fetchBrandCategoryByIDRequest = () => {
  return {
    type: PUT_BRAND_CATEGORY_ID_REQUEST,
  };
};

export const fetchBrandCategoryByIDSuccess = (obj) => {
  return {
    type: PUT_BRAND_CATEGORY_ID_SUCCESS,
    payload: obj,
  };
};

export const fetchBrandCategoryByIDFailure = (obj) => {
  return {
    type: PUT_BRAND_CATEGORY_ID_FAILURE,
    payload: obj,
  };
};

export const fetchBrandCategoryByID = (id) => {
  return (dispatch) => {
    dispatch(fetchBrandCategoryByIDRequest);
    let data = { active_flag: true };
    let config = {
      method: "get",
      url: `${baseUrl}/v1/brand/${id}/brand_category`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: data,
    };
    axios(config)
      .then((response) => {

        if (response.data.code === 200) {
          dispatch(fetchBrandCategoryByIDSuccess(response.data.result));
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          fetchBrandCategoryByIDFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};


// Patch brand category
export const patchBrandCategoryRequest = () => {
  return {
    type: PATCH_BRAND_CATEGORY_REQUEST,
  };
};

export const patchBrandCategorySuccess = (obj) => {
  return {
    type: PATCH_BRAND_CATEGORY_SUCCESS,
    payload: obj,
  };
};

export const patchBrandCategoryFailure = (error) => {
  return {
    type: PATCH_BRAND_CATEGORY_FAILURE,
    payload: error,
  };
};

export const patchBrandCategory = (obj, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(patchBrandCategoryRequest())

    let brandCatTitleArr = [];

    obj?.categoryName?.map((item) => {
      brandCatTitleArr.push({
        [item.language_id.language_code]: item.category_name,
      });
    });
    let object = Object.assign({}, ...brandCatTitleArr);

    let brandCategoryObj = {
      category_names: object,
      default_category_name: obj?.categoryName[0].category_name,
      active_flag: obj.activateFlag,
    };

    const formData = new FormData()
    formData.append("update_items", JSON.stringify(brandCategoryObj));
    obj.categoryImage[0] && formData.append("image", obj.categoryImage[0]);
    let config = {
      method: "patch",
      url: `${baseUrl}/v1/brand_category/${obj.id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: formData,
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(patchBrandCategorySuccess(response.data));
          goToSuccessfull();
          dispatch(getAllBrandCategoryList());
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          putBrandCategoryFailure("Something went wrong!! Please try again!!")
        );
      });
  }
}

export const clearBrandCategoryList = () => {
  return {
    type: CLEAR_BRAND_CATEGORY_LIST,
  };
};