/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Brands from "../../../components/Brand/Brands";
import { useTranslation } from "react-i18next";

const BrandsDashBoard = (props) => {
  const history = useNavigate();
  const { t } = useTranslation();

  const [permissions, setpermissions] = useState();

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));

  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
  }, [permissions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const creatNewBrand = () => {
    history("/brand-creation");
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">{t("Brand Dashboard")}</span>
        </div>
        {permissions?.brand?.create ? (
          <div>
            <Button
              type="primary"
              text={t("+Create Brand")}
              onClick={() => creatNewBrand()}
            />
          </div>
        ) : null}
      </div>
      <div className="d-flex ">
        <Brands lang={props?.lang} />
      </div>
    </div>
  );
};

export default BrandsDashBoard;
