import axios from "axios";
import {FETCH_CUSTOMER_REQUEST,
    FETCH_CUSTOMER_SUCCESS,
    FETCH_CUSTOMER_FAILURE } from "./customerTypes";
import { baseUrl, getToken } from '../../utils/prodURL';


    export const fetchCustomerRequest = () => {
        return {
            type: FETCH_CUSTOMER_REQUEST,
        }
    }
    
    export const fetchCustomerSuccess = data => {
        return {
            type: FETCH_CUSTOMER_SUCCESS,
            payload: data
        }
    }
    
    export const fetchCustomerFailure = error => {
        return {
            type: FETCH_CUSTOMER_FAILURE,
            payload: error
        }
    }
    
    export const fetchCustomer = () => {
        return (dispatch) => {
            dispatch(fetchCustomerRequest());
            let config = {
                method: "get",
                url: `${baseUrl}/partner_category/customer/partner`,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            };
            axios(config)
                .then((response) => {
                    // console.log("response" + JSON.stringify(response.data));
                    dispatch(fetchCustomerSuccess(response.data.result))
                })
                .catch((error) => {
                    //  const errorMsg = error;
                    dispatch(fetchCustomerFailure("Something went wrong!! Please try again!!"));
                });
        }
    }