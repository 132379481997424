import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Overview from "../../components/Dashboard/Overview";
import PopularGiftCards from "../../components/Dashboard/PopularGiftCards";
import QuickAccess from "../../components/Dashboard/QuickAccess";
import TopSuppliers from "../../components/Dashboard/TopSuppliers";
import MainContentArea from "../../components/MainContentArea/MainContentArea";
import { getAllLanguage } from "../../Redux/Language/languageAction";
import { useTranslation } from "react-i18next";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllLanguage());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // <MainContentArea>
    <div className="d-flex flex-column justify-content-start py-4 px-4 bg-light">
      <div className="d-flex">
        <span className="font-40 font-bold mb-2">{t("Dashboard")}</span>
      </div>
      <div className="d-flex flex-row gap-2">
        <div className="width-65">
          <Overview lang={props?.lang}/>
          <div className="mt-2">
            <TopSuppliers />
          </div>
          <div className="mt-2">
            <QuickAccess />
          </div>
        </div>
        <div className="width-35">
          <PopularGiftCards />
        </div>
      </div>
    </div>
    // </MainContentArea>  
  );
};

export default Dashboard;
