import React from "react";
import BulkUploadComp from "../../components/BulkUpload/BulkUploadComp";

const BulkUpload = (props) => {
  return (
    <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
      <div className="d-flex flex-row align-items-center pb-2 gap-3">
        <span className="font-bold font-32 my-2">Bulk upload</span>
      </div>
      <div className="d-flex flex-row mt-2 gap-2">
        <div className="">
          <BulkUploadComp lang={props?.lang} />
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
