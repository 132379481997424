/* eslint-disable array-callback-return */
import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";
import {
  GET_PARTNER_FLOAT_FAILURE,
  GET_PARTNER_FLOAT_REQUEST,
  GET_PARTNER_FLOAT_SUCCESS,
  GET_CLIENT_ALLFLOAT_TRANS_REQUEST,
  GET_CLIENT_ALLFLOAT_TRANS_SUCCESS,
  GET_CLIENT_ALLFLOAT_TRANS_FAILURE,
  POST_FLOAT_TRANSACTION_FAILURE,
  POST_FLOAT_TRANSACTION_SUCCESS,
  POST_FLOAT_TRANSACTION_REQUEST,
  GET_ALL_CLIENT_TRANS_REQUEST,
  GET_ALL_CLIENT_TRANS_SUCCESS,
  GET_ALL_CLIENT_TRANS_FAILURE,
  FLOAT_FILTER_DATA,
  CLEAR_FLOAT_TRANSACTION_LIST
} from "./floatType";
import { dateFormatConverter } from "../../utils/constantFunction";

export const floatTransactionFilterData = (data) => {
  return {
    type: FLOAT_FILTER_DATA,
    payload: data
  };
};

export const getPratnerFloatsRequest = () => {
  return {
    type: GET_PARTNER_FLOAT_REQUEST,
  };
};
export const getPratnerFloatsSuccess = (data) => {
  return {
    type: GET_PARTNER_FLOAT_SUCCESS,
    payload: data,
  };
};
export const getPratnerFloatsFailure = (error) => {
  return {
    type: GET_PARTNER_FLOAT_FAILURE,
    payload: error,
  };
};

export const getPratnerFloats = (id, partnerFloatApiErrorHandler) => {
  return (dispatch) => {
    dispatch(getPratnerFloatsRequest);

    let config = {
      method: "get",
      url: `${baseUrl}/v1/partner/${id}/float`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response?.response?.status === 404) {
          partnerFloatApiErrorHandler(response?.response?.status)
        }
        if (response?.status === 200) {

          dispatch(getPratnerFloatsSuccess(response.data.result));
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getPratnerFloatsFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const postFloatCurrencyClient = (obj, goToSuccessfull, getAPIResponse) => {
  return (dispatch) => {
    //  dispatch(putPartnerRequest);

    let payload = [];
    obj?.currencyObj &&
      obj?.currencyObj.map((item) => {
        let data = {
          partner_id: obj?.selectedClient?.id,
          country_id: item?.currency.id,
          currency: item?.currency.currency,
          currency_symbol: item?.currency.currency_symbol,
          transaction_criteria: item.transactionCritaria?.name.toLowerCase(),
          active_flag: true,
          notification_flag: false,
          notification_limit_type: "percentage",
          notification_limit: 0,
          notification_emails: [],
        };
        payload.push(data);
      });

    let config = {
      method: "post",
      url: `${baseUrl}/v1/partner/float`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };
    axios(config)
      .then((response) => {
        if (response?.data?.code === 200) {
          //  dispatch(putPartnerSuccess(response.data.result));
          getAPIResponse(false)
          goToSuccessfull();
        } else if (response?.data?.code === 409) {
          console.log("Something went wrong");
          getAPIResponse(true)
        }
      })
      .catch((error) => {
        // dispatch(putPartnerFailure("Something went wrong!! Please try again!!"))
      });
  };
};

export const postFloatClient = (obj, goToSuccessfull) => {
  return (dispatch) => {
    //  dispatch(putPartnerRequest);

    let payload = {
      // "partner_id": obj.client.id,
      // "country_id": obj.currency.country_id,
      // "currency": obj.currency.currency,
      // "currency_symbol": obj.currency.currency_symbol,
      // "transaction_criteria": "allocated",
      active_flag: true,
      deleted_flag: false,
      notification_flag: obj.notificationsFlag ? obj.notificationsFlag : false,
      notification_limit_type: obj.notification_limit_type,
      notification_limit: obj.notification_limit ? obj.notification_limit : 0,
      notification_emails: obj.emails,
      created_at: "2022-06-20T05:02:35.590834",
      updated_at: "2022-06-20T05:02:35.590906",
      version: 0,
    };
    let floatConfig = {
      method: "patch",
      url: `${baseUrl}/v1/partnerfloat/${obj?.currency?.id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(floatConfig)
      .then((response) => {
        if (response.status === 200) {
          //  dispatch(putPartnerSuccess(response.data.result));
          dispatch(postFloatClientTransaction(obj, goToSuccessfull));
          dispatch(getAllClientTransactions())
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        // dispatch(putPartnerFailure("Something went wrong!! Please try again!!"))
      });
  };
};

export const postFloatClientTransactionRequest = () => {
  return {
    type: POST_FLOAT_TRANSACTION_REQUEST,
  };
};
export const postFloatClientTransactionSuccess = (data) => {
  return {
    type: POST_FLOAT_TRANSACTION_SUCCESS,
    payload: data,
  };
};
export const postFloatClientTransactionFailure = (error) => {
  return {
    type: POST_FLOAT_TRANSACTION_FAILURE,
    payload: error,
  };
};

export const postFloatClientTransaction = (obj, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(postFloatClientTransactionRequest());

    let transactionObj = {
      partner_id: obj.client.id,
      partner_float_id: obj?.currency?.id,
      transaction_type: "credit",
      amount: obj?.floatAmount,
      payment_recieved_on: dateFormatConverter(obj?.paymentReceivedOn),
    };

    const formData = new FormData();

    formData.append("request_item", JSON.stringify(transactionObj));
    obj.documentation &&
      obj.documentation[0] &&
      formData.append("transaction_receipt", obj.documentation[0]);

    let transConfig = {
      method: "post",
      url: `${baseUrl}/v1/partner/float/transaction`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: formData,
    };
    axios(transConfig)
      .then((response) => {
        if (response.status === 200) {
          dispatch(postFloatClientTransactionSuccess(response.data.result));
          goToSuccessfull();
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(postFloatClientTransactionFailure("Something went wrong!! Please try again!!"))
      });
  };
};

// TO GET CLIENT ALL FLOAT TRANSACTION
export const getClientAllFloatTransRequest = () => {
  return {
    type: GET_CLIENT_ALLFLOAT_TRANS_REQUEST,
  };
};
export const getClientAllFloatTransSuccess = (data) => {
  return {
    type: GET_CLIENT_ALLFLOAT_TRANS_SUCCESS,
    payload: data,
  };
};
export const getClientAllFloatTransFailure = (error) => {
  return {
    type: GET_CLIENT_ALLFLOAT_TRANS_FAILURE,
    payload: error,
  };
};

export const getClientAllFloatTrans = (id) => {
  return (dispatch) => {
    dispatch(getClientAllFloatTransRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/partner/${id}/float/transaction`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getClientAllFloatTransSuccess(response.data.result));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getClientAllFloatTransFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

export const getClientAllTransactionsRequest = () => {
  return {
    type: GET_ALL_CLIENT_TRANS_REQUEST
  }
}

export const getClientAllTransactionsSuccess = (data) => {
  return {
    type: GET_ALL_CLIENT_TRANS_SUCCESS,
    payload: data
  }
}

export const getClientAllTransactionsFailure = (error) => {
  return {
    type: GET_ALL_CLIENT_TRANS_FAILURE,
    payload: error
  }
}

export const getAllClientTransactions = (skip, limit, allclienttransactions) => {
  if (!allclienttransactions) allclienttransactions = []
  return (dispatch) => {
    dispatch(getClientAllTransactionsRequest())

    let url = skip !== undefined && limit !== undefined ? `${baseUrl}/v1/partner/float/transaction?skip=${skip}&limit=${limit}` : `${baseUrl}/v1/partner/float/transaction`

    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        let data = {
          data: [...allclienttransactions, ...response.data.result],
          moredata: response.data.next_iteration
          //moredata: false
        }
        dispatch(getClientAllTransactionsSuccess(data));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getClientAllTransactionsFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  }
}

export const clearClientFloatTransactions = () => {
  return {
    type: CLEAR_FLOAT_TRANSACTION_LIST,
  };
};