import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import { ReactComponent as FilterIcon } from "../../assests/svg/Filter_Icon.svg";
import { ReactComponent as RedDownArrow } from "../../assests/svg/Product/Red_DownArrow.svg";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfoTooltip from "../Tooltip/InfoTooltip";
import {
  fetchClient,
  setSelectedClient,
} from "../../Redux/Client/clientAction";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import { fetchProductTypeList } from "../../Redux/ProductType/prodtypeAction";
import { getClientProducts, getGiftcardClientmappingData, getSupplierPriceClientMap } from "../../Redux/ClientMapping/ClientMappingAction";
import Toasts from "../Toasts/Toasts";

const ClientMapping = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const productType = [{ name: "giftcard" }, { name: "merchandise" }];
  const clientsState = useSelector((state) => state?.clients?.client);
  const productsState = useSelector((state) => state?.productTypes?.products);
  // const variantsState = useSelector(
  //   (state) => state?.clientMappingData?.clientMappingProduct?.result
  // );

  const [visible, setVisible] = useState(2);
  const [clients, setClients] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const [fields, setFields] = useState({
    productType: "",
    giftcard: "",
  });
  const [error, setError] = useState({
    giftcard: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchClient());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    // console.log(e.target);
    let arr = [...clientsState];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (item.id === name) {
        arr[i].isChecked = checked;
      }
    });
    setClients(arr);
  };
  // for validation
  const validate = () => {
    if (!fields.giftcard) {
      setError({ giftcard: "Please Select product/giftcard for mapping" });
      return false;
    }
    return true;
  };
  const goToClientMappingVariants = () => {
    let val = validate();
    if (val) {
      let arr = [...clients];
      let selectedClient = [];
      // eslint-disable-next-line array-callback-return
      arr.map((item) => {
        if (item.isChecked === true) {
          selectedClient.push(item);
        }
      });
      // console.log(fields.productType.name )
      if (fields.productType.name === "merchandise") {
        if (selectedClient.length > 0) {
          dispatch(setSelectedClient(selectedClient));
          history("/clients-mapping-product", { state: selectedClient });
        } else {
          toggleShowToast();
        }
      } else {
        if(selectedClient.length > 0){
          history("/clients-mapping-giftcard", {
                   state: {
                     selectedClient: selectedClient,
                     productDetails: selectedClient
                   },
                 });
        }else{
          toggleShowToast();
               return;
        }
      }
    }
  };

  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 10);
  };

  const handleChangeProductCategory = (e) => {
    setFields({ ...fields, productType: e });
    dispatch(fetchProductTypeList(e.name));
  };

  const handleChangeProduct = (e) => {
    setFields({ ...fields, giftcard: e });
    if (fields.productType.name === "merchandise")
      dispatch(getClientProducts(e.id));
    else {
      dispatch(getGiftcardClientmappingData(e.id));
      dispatch(getSupplierPriceClientMap(e.id))
    }
  };

  // to select all checkbox
  return (
    <div>
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65 mb-3">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">Product</span>
          </div>
        </div>
        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">Product Category</span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            {/* <Dropdown data={data} /> */}
            <div>
              <Select
                placeholder="Select product category"
                noOptionsMessage={() => "Not found"}
                getOptionLabel={(productType) => productType.name}
                options={productType}
                styles={CUSTOM_DROPDOWN_STYLE}
                value={fields.productType}
                onChange={handleChangeProductCategory}
                isClearable
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                Mapping for Product <span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            {/* <Dropdown /> */}

            <div>
              <Select
                placeholder="Select mapping for products"
                noOptionsMessage={() => "Not found"}
                getOptionLabel={(productsState) =>
                  productsState?.product_title[0]?.title
                }
                options={productsState}
                styles={CUSTOM_DROPDOWN_STYLE}
                value={fields.giftcard}
                onChange={handleChangeProduct}
                isClearable
              />
            </div>

            {!fields.giftcard && error.giftcard ? (
              <label className="text-error">{error.giftcard}</label>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column  bg-white">
        <div className="d-flex flex-row w-100  align-items-center">
          <div className="p-2 flex-grow-1">
            <div className="d-flex gap-3 my-2 mx-3 align-items-center">
              <Tag />
              <span className="font-20 font-bold">Clients</span>
              <span className="font-20 font-bold">
                <div className="Inputwithicon">
                  <div id="search">
                    <Searchicon />
                  </div>
                  <input
                    type="text"
                    className="input-custom w-100"
                    name="search"
                    placeholder="Search by name,email or client"
                  />
                </div>
              </span>
            </div>
          </div>
          <div className="p-2">
            <Button type="secondary" text="All" />
          </div>
          <div className="p-2">
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle text-muted"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <Link className="dropdown-item" to={""}>
                    Apple 13
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={""}>
                    Apple 13
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={""}>
                    Apple 13
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="p-2">
            <span className="my-2">
              <FilterIcon />
            </span>
          </div>
        </div>
        <div className="row px-5 py-2">
          <table responsive="xl">
            <tbody>
              <tr className="card-body gap-3 border-bottom py-5">
                <td></td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Name
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Clientmail
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Active
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Lifetime
                </td>
                <td className="card-body font-13 font-bold text-muted">
                  Locality
                </td>
                <td className="card-body font-13 font-bold text-muted">
                  Users
                </td>
              </tr>
              {/* {clientDetails.slice(0, visible).map((item) => ( */}
              {clientsState &&
                clientsState.slice(0, visible).map((item, index) => (
                  <tr
                    className="card-body gap-3 border-bottom hover-row"
                    key={item.id}
                  >
                    <td>
                      <input
                        className="form-check-input cursor"
                        type="checkbox"
                        id="checkboxNoLabel"
                        name={item.id}
                        onChange={handleCheck}
                      />
                    </td>

                    <td className="card-body gap-1">
                      <div className="d-flex flex-column justify-content-start ">
                        <span className="font-15 font-boldest text-dark ">
                          {item.partner_name}
                        </span>
                        <span className="font-12 font-bolder text-muted">
                          {item.company_name}
                        </span>
                      </div>
                    </td>
                    <td className="card-body gap-1">
                      <div className="d-flex flex-row justify-content-between  gap-3">
                        <span className="fs-light  text-muted ">
                          {/* {item.clientMail} */}
                          {item.email}
                        </span>
                      </div>
                    </td>
                    <td className="card-body gap-1">
                      <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                        {/* {item.active} */}
                        10
                      </span>
                    </td>
                    <td className="card-body gap-1">
                      <div className="d-flex flex-row justify-contents-between align-items-center">
                        <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                          {/* {item.lifeTime} */}
                          15
                        </span>
                        <span className="font-13 font-bolder text-danger bg-opacity-25 rounded-3 px-1">
                          <span>
                            {" "}
                            <RedDownArrow className="m-1" />
                          </span>{" "}
                          {/* {item.lifeTimePercent} */}
                          25
                        </span>
                      </div>
                    </td>
                    <td className="card-body gap-1">
                      <span className="font-15 font-bold text-dark px-1">
                        {/* {item.locality} */}
                        34
                      </span>
                    </td>
                    <td className="card-body gap-1">
                      <span className="font-15 font-bold text-dark px-1">
                        {/* {item.users} */}
                        Repletech
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-row  justify-content-center align-item-center mb-3 gap-3">
          <div className="d-flex">
            {visible < clientsState.length && (
              <Button
                type="rounded"
                text="Load more"
                className="rounded-6 "
                onClick={() => loadMore()}
              />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <Button
          type="primary"
          text="Continue"
          className="rounded-6 "
          onClick={() => goToClientMappingVariants()}
        />
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Please select client to map."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default ClientMapping;
