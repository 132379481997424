import {GET_MAPPING_DATA_REQUEST,
    GET_MAPPING_DATA_SUCCESS,
GET_MAPPING_DATA_FAILURE
} from './mappingType'


const initialMappingDataState={
    loading: false,
    mappingdata: [],
    error: ''
}

const mappingDataReducer = (state = initialMappingDataState, action) => {

switch (action.type) {
    case GET_MAPPING_DATA_REQUEST:
        return {
            ...state,
            loading: true
        }
    case GET_MAPPING_DATA_SUCCESS:
         return {
            ...state,
            loading: false,
            mappingdata: action.payload,
            error: ''

        }
    case GET_MAPPING_DATA_FAILURE:
        return {
            ...state,
            loading: false,
            mappingdata: [],
            error: action.payload
        }
    default: return state
}
}
export default mappingDataReducer