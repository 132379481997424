import {
  GET_CLIENT_LIST_REQUEST,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAILURE,
  GET_CLIENT_GC_LIST_REQUEST,
  GET_CLIENT_GC_LIST_SUCCESS,
  GET_CLIENT_GC_LIST_FAILURE,
  PUT_GIFTCARD_CLIENT_MAPPING_REQUEST,
  PUT_GIFTCARD_CLIENT_MAPPING_SUCCESS,
  PUT_GIFTCARD_CLIENT_MAPPING_FAILURE
} from "./ClientGIftcardType";

import { baseUrl, getToken } from "../../../../utils/prodURL";
import axios from "axios";

export const getClientListRequest = () => {
  return {
    type: GET_CLIENT_LIST_REQUEST,
  };
};
export const getClientListSuccess = (clientList) => {
  return {
    type: GET_CLIENT_LIST_SUCCESS,
    payload: clientList,
  };
};
export const getClientListFailure = (error) => {
  return {
    type: GET_CLIENT_LIST_FAILURE,
    payload: error,
  };
};

export const getClientList = () => {
  return (dispatch) => {
    dispatch(getClientListRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        // console.log("client response" + JSON.stringify(response.data));
        dispatch(getClientListSuccess(response.data))
      })
      .catch((error) => {
        const errorMsg = error;
        dispatch(getClientListFailure("Something went wrong!! Please try again!!"));
      });
  };
};
// TO GET GIFTCARD LIST TO MAP CLIENT
export const getClientGcListRequest = () => {
  return {
    type: GET_CLIENT_GC_LIST_REQUEST,
  };
};
export const getClientGcListSuccess = (clientGcList) => {
  return {
    type: GET_CLIENT_GC_LIST_SUCCESS,
    payload: clientGcList,
  };
};
export const getClientGCListFailure = (error) => {
  return {
    type: GET_CLIENT_GC_LIST_FAILURE,
    payload: error,
  };
};

export const getClientGcList = () => {
  return (dispatch) => {
    dispatch(getClientGcListRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        // console.log("client gc response" + JSON.stringify(response.data));
        dispatch(getClientGcListSuccess(response.data))
      })
      .catch((error) => {
        const errorMsg = error;
        dispatch(getClientGCListFailure("Something went wrong!! Please try again!!"));
      });
  };
};
// TO PUT CLIENT GIFTCARD MAPPING DATA 
export const putClientGiftcardRequest = () => {
  return {
    type: PUT_GIFTCARD_CLIENT_MAPPING_REQUEST,
  };
};

export const putClientGiftcardSuccess = (data) => {
  return {
    type: PUT_GIFTCARD_CLIENT_MAPPING_SUCCESS,
    payload: data,
  };
};

export const putClientGiftcardFailure = (error) => {
  return {
    type: PUT_GIFTCARD_CLIENT_MAPPING_FAILURE,
    payload: error,
  };
};

export const putClientGiftcardMappingProd = (selectedProducts,successPage) => {
  return (dispatch) => {
    dispatch(putClientGiftcardRequest);

    let payload = [];
    // eslint-disable-next-line array-callback-return
    selectedProducts.map((item) => {
      let obj = {
        "giftcard_id": item.product.id,
        "client_list": [
          {
            "client_id": item.client.id,
            "client_discount": item.clientDiscount,
            "supplier_id": item?.supplier?.supplier_id,
            "margin": parseFloat(item?.hadayaMargin)
          }
        ],
        "active_flag": true
      }

      payload.push(obj);
    });

    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcards/client/mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        // console.log("response", response);
        if(response.status === 200){
           dispatch(putClientGiftcardSuccess(response.data));
          successPage()
        }
      })
      .catch((error) => {
        // console.log("response" + JSON.stringify(error));

        //  const errorMsg = error;
        dispatch(putClientGiftcardFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};
