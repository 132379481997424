import {
  GET_SHIPPING_COST_REQUEST,
  GET_SHIPPING_COST_SUCCESS,
  GET_SHIPPING_COST_FAILURE,
} from "./shippingcostType";

import { baseUrl, getToken } from "../../utils/prodURL";
import axios from "axios";

export const getShippingCostRequest = () => {
  return {
    type: GET_SHIPPING_COST_REQUEST,
  };
};
export const getShippingCostSuccess = (shippingcost) => {
  return {
    type: GET_SHIPPING_COST_SUCCESS,
    payload: shippingcost,
  };
};
export const getShippingCostFailure = (error) => {
  return {
    type: GET_SHIPPING_COST_FAILURE,
    payload: error,
  };
};

export const getShippingCost = () => {
  return (dispatch) => {
    dispatch(getShippingCostRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/shipping_cost`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
       // console.log("response" + JSON.stringify(response.data));
        dispatch(getShippingCostSuccess(response.data))
      })
      .catch((error) => {
        const errorMsg = error;
        dispatch(
          getShippingCostFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};
