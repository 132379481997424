export const GET_ALL_PROD_REQUEST = "GET_ALL_PROD_REQUEST"
export const GET_ALL_PROD_SUCCESS = "GET_ALL_PROD_SUCCESS"
export const GET_ALL_PROD_FAILURE = "GET_ALL_PROD_FAILURE"


export const GET_PARTNER_FLOAT_REQUEST = "GET_PARTNER_FLOAT_REQUEST"
export const GET_PARTNER_FLOAT_SUCCESS = "GET_PARTNER_FLOAT_SUCCESS"
export const GET_PARTNER_FLOAT_FAILURE = "GET_PARTNER_FLOAT_FAILURE"

// to get all float transaction of client
export const GET_CLIENT_ALLFLOAT_TRANS_REQUEST = "GET_CLIENT_ALLFLOAT_TRANS_REQUEST"
export const GET_CLIENT_ALLFLOAT_TRANS_SUCCESS = "GET_CLIENT_ALLFLOAT_TRANS_SUCCESS"
export const GET_CLIENT_ALLFLOAT_TRANS_FAILURE = "GET_CLIENT_ALLFLOAT_TRANS_FAILURE"


export const POST_FLOAT_TRANSACTION_REQUEST = "POST_FLOAT_TRANSACTION_REQUEST"
export const POST_FLOAT_TRANSACTION_SUCCESS = "POST_FLOAT_TRANSACTION_SUCCESS"
export const POST_FLOAT_TRANSACTION_FAILURE = "POST_FLOAT_TRANSACTION_FAILURE"


export const GET_ALL_CLIENT_TRANS_REQUEST = "GET_ALL_CLIENT_TRANS_REQUEST"
export const GET_ALL_CLIENT_TRANS_SUCCESS = "GET_ALL_CLIENT_TRANS_SUCCESS"
export const GET_ALL_CLIENT_TRANS_FAILURE = "GET_ALL_CLIENT_TRANS_FAILURE"

// to persist sort filter data
export const FLOAT_FILTER_DATA = "FLOAT_FILTER_DATA"
export const CLEAR_FLOAT_TRANSACTION_LIST = "CLEAR_FLOAT_TRANSACTION_LIST"