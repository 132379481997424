/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import GoogleIncIcon from "../../assests/img/GoogleInc.png";
import Collapse from "react-bootstrap/Collapse";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg";
import { getPratnerFloats } from "../../Redux/Float/floatAction";
import { useDispatch, useSelector } from "react-redux";
import AllTransactions from "../../components/Client/AllTransactions";
import ClientFloatTransaction from "../../components/Client/ClientFloatTransaction";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import { fetchClientMappedMerchandiseDetails } from "../../Redux/Distributions/ClientMerchandiseDistributions/ClientMerchandiseDistributionAction";
import CustomEmail from "../../components/Client/CustomEmail";
import { ReactComponent as RadishTag } from "../../assests/svg/Tag/radish_tag.svg";
import { ReactComponent as EditButton } from "../../assests/svg/edit.svg";
import PartnerDetailProductTableHeader from "../../components/PartnerDetailProductTable/PartnerDetailProductTableHeader";
import ClientDetailGiftcardTable from "../../components/PartnerDetailProductTable/ClientDetailProductTable/ClientDetailGiftcardTable";
import ClientDetailMerchandiseTable from "../../components/PartnerDetailProductTable/ClientDetailProductTable/ClientDetailMerchandiseTable";
import { useTranslation } from "react-i18next";

const ClientDetails = (props) => {
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let selectedClient = location?.state?.selectedClient;

  const floatCurrency = useSelector((state) => state?.partnerFloat?.floats);
  const countries = useSelector((state) => state?.countries?.countries);

  const [tabs, setTabs] = useState("orders");

  const [floatCollapse, setFloatCollapse] = useState(false);
  const [currencyCollapse, setCurrencyCollapse] = useState(false);

  const [clientCurrencies, setClientCurrencies] = useState([]);

  const [permissions, setpermissions] = useState();

  const [prductFilterValue, setProductFilterValue] = useState({
    name: "Giftcard",
  });
  const [searchValue, setSearchValue] = useState("");

  const productFilterValueGethandler = (filterValue) => {
    setProductFilterValue(filterValue);
  };

  const getSearchValue = (SearchValue) => {
    setSearchValue(SearchValue);
  };

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => { }, [permissions]);

  useEffect(() => {
    let arr = [];
    countries &&
      countries.map((conItem) => {
        selectedClient &&
          selectedClient?.currencies_id.map((item) => {
            if (conItem?.id === item) {
              arr.push(conItem);
            }
          });
      });
    setClientCurrencies(arr);
  }, [selectedClient, countries]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCountriesList());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (selectedClient?.float_flag && selectedClient?.id) {
      dispatch(getPratnerFloats(selectedClient?.id));
    }
    dispatch(fetchClientMappedMerchandiseDetails(selectedClient?.id));

  }, []);

  const editButtonhandler = (item) => {
    history("/edit-partner", {
      state: {
        partnerItem: location?.state?.selectedSupplier
          ? location?.state?.selectedSupplier
          : selectedClient,
      },
    });
  };

  const topUpButtonhandler = (item) => {
    history("/float/addfloat-details", {
      state: {
        selectedClient,
      },
    });
  };

  const allocateButtonhandler = (item) => {
    history("/float/currency-mapping/to-client", {
      state: {
        selectedClient,
      },
    });
  };
  const goToClientDistributionHandle = (item) => {
    history("/card-distribution", {
      state: {
        selectedClient: item,
        clientType: "client",
      },
    });
  };

  const handleChangeTab = (tab) => {
    if (tab === "orders") {
      setTabs(tab);
    } else if (tab === t("floatTransactions")) {
      setTabs(tab);
    } else if (tab === t("products")) {
      setTabs(tab);
    } else if (tab === t("email")) {
      setTabs(tab);
    }
  };

  const goToEditMappingScreen = () => {
    if (prductFilterValue?.name === "Giftcard") {
      history("/clients-mapping-giftcard-edit", {
        state: {
          partnerId: selectedClient?.id && selectedClient?.id,
          selectedClient: selectedClient
        }
      });
    } else {
      history("/clients-mapping-merchandise-edit", {
        state: {
          partnerId: selectedClient?.id && selectedClient?.id,
          selectedClient: selectedClient
        }
      });
    }
  }

  return (
    <div className="d-flex flex-column  justify-content-between px-4 py-4 w-100 bg-light">
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 w-100 py-3">
        <div className="d-flex px-4 gap-3 pt-2 align-items-center">
          <RadishTag />
          <span className="font-22 font-boldest text-dark">{t("Client Info")}</span>
        </div>
        <div className="d-flex flex-row gap-4 py-4 justify-content-between align-items-center">
          <div className="d-flex flex-row gap-3 py-  align-items-center">
            <div className="d-flex flex-column gap-3 mb-  py-  px-3 align-items-center">
              <div className="d-flex flex-row mx-2 gap-4 justify-content-between">
                <img
                  src={GoogleIncIcon}
                  alt="not found"
                  className="rounded-3 "
                  width="80px"
                  height="80px"
                />
                <div className=" d-flex flex-column gap-1">
                  <span className="font-20 font-bold text-dark">
                    {t("Name")} :{" "}
                    <span className="font-20 font-boldest text-dark ">
                      {selectedClient?.partner_name}
                    </span>
                    {permissions?.all_clients?.create ? (
                      <span
                        className="mx-2 cursor"
                        onClick={() => editButtonhandler()}
                      >
                        <EditButton />
                      </span>
                    ) : null}
                  </span>

                  <span className="font-13 font-bold text-dark">
                    {t("Id")} :{" "}
                    <span
                      className="font-13 font-bold text-muted"
                      title={selectedClient?.id}
                    >
                      {selectedClient?.id}
                      {/* <span>...</span> */}
                    </span>
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Copy to clipboard"
                      className="cursor "
                      value={selectedClient?.id}
                    ></span>
                  </span>

                  <span className="font-13 font-bold text-dark">
                    {t("Email")} :{" "}
                    <span className="font-13 font-bold text-muted">
                      {selectedClient?.email}
                    </span>
                    <br />
                    <span className="font-13 font-bold text-dark">
                      {t("Billing type")} :{" "}
                    </span>
                    {selectedClient?.float_flag === true ? (
                      <span className="font-15 font-bold text-pink">{t("Float")}</span>
                    ) : (
                      <span className="font-15 font-bold text-pink">
                        {t("Postpaid")}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {permissions?.all_clients?.create ? (
            <div className="d-flex flex-column  align-items-center gap-3 justify-content-between pt-1 px-3">
              {selectedClient?.float_flag && (
                <div className="d-flex flex-end px-1 align-items-center">
                  <Button
                    type="primary"
                    text={t("TopUp float")}
                    className="rounded-5 my-4 mt-6 fw-bolder"
                    onClick={() => topUpButtonhandler()}
                  />
                </div>
              )}
              {selectedClient?.float_flag && (
                <div className="d-flex flex-end px-1 align-items-center">
                  <Button
                    type="primary"
                    text={t("Allocate float")}
                    className="rounded-5 my-4 mt-6 fw-bolder"
                    onClick={() => allocateButtonhandler()}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>

        <div className="d-flex justifiy-content-center px-4 gap-3">
          <div
            className="d-flex flex-row justify-content-between align-items-center py-2 px-2 gap-3 rounded-3"
            onClick={() => setCurrencyCollapse(!currencyCollapse)}
          >
            <div
              className="d-flex flex-column cursor gap-3"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              <div className="d-flex align-items-center gap-4">
                {/* <ProductIcon /> */}
                <span className="font-18 font-bold">
                  {t("Client Operational Countries")}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div
                className={`cursor p-0 ${currencyCollapse ? "rotate-180" : ""}`}
                aria-controls="example-collapse-text"
                aria-expanded={false}
              >
                <DropDownIcon
                  className={`cursor p-0 ${currencyCollapse ? "rotate-180" : ""
                    }`}
                />
              </div>
            </div>
          </div>
        </div>
        {/* )} */}

        <div className="px-5">
          <Collapse in={currencyCollapse}>
            <div
              id="panelsStayOpen-collapseOne"
              className="mt-2"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div className="card-body d-flex flex-column justify-content-between p-3 gap-4">
                <table responsive="xl">
                  <tbody>
                    <tr className="col gap-3 border-bottom  ">
                      <td className="col">
                        <div>
                          <span className="font-15 text-muted font-bold px-2 py-4">
                            {t("Countries")}
                          </span>
                        </div>
                      </td>
                      <td className="col">
                        <div className="d-flex flex-end justify-content-end px-4">
                          <span className="font-15 font-bold text-muted px-2">
                            {t("Currencies")}
                          </span>
                        </div>
                      </td>
                    </tr>

                    {clientCurrencies &&
                      clientCurrencies.map((item, i) => (
                        <tr
                          className="col gap-3 border-bottom hover-row"
                          key={i}
                        >
                          <td className="col px-2 py-2">
                            {item?.name + " (" + item?.iso3 + ")"}
                          </td>
                          <td className="col">
                            <div className="d-flex flex-end justify-content-end px-4">
                              <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                {item?.currency + " - " + item?.currency_symbol}
                              </span>
                            </div>
                          </td>
                        </tr>
                        // </tr>
                      ))}
                  </tbody>
                </table>
                {!clientCurrencies.length && (
                  <div className="d-flex flex-row justify-content-center py-3">
                    <span className="font-20 font-bold text-error">
                      {t("No country & Currencies found")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>

        {selectedClient?.float_flag && (
          <div className="d-flex justifiy-content-center px-4 gap-3">
            <div
              className="d-flex flex-row justify-content-between align-items-center py-2 px-2 gap-3 rounded-3"
              onClick={() => setFloatCollapse(!floatCollapse)}
            >
              <div
                className="d-flex flex-column cursor gap-3"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                <div className="d-flex align-items-center gap-4">
                  {/* <ProductIcon /> */}
                  <span className="font-18 font-bold">{t("Float currency")}</span>
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div
                  className={`cursor p-0 ${floatCollapse ? "rotate-180" : ""}`}
                  aria-controls="example-collapse-text"
                  aria-expanded={false}
                >
                  <DropDownIcon
                    className={`cursor p-0 ${floatCollapse ? "rotate-180" : ""
                      }`}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="px-5">
          <Collapse in={floatCollapse}>
            <div
              id="panelsStayOpen-collapseOne"
              className="mt-2"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div className="row justify-contents-between  gap-4">
                <table responsive="xl">
                  <tbody>
                    <tr className="card-body gap-3 border-bottom  py-5">
                      <td className="card-body gap-1 font-15 font-bold text-muted">
                        {t("Currency")}
                      </td>
                      <td className="card-body gap-1 font-15 font-bold justify-content-end text-muted">
                        <div className="d-flex flex-end justify-content-end px-4">
                          {t("Balance")}
                        </div>
                      </td>
                    </tr>
                    {floatCurrency && floatCurrency.length > 0 ? (
                      floatCurrency.map((item, index) => (
                        <tr
                          className="card-body gap-3 border-bottom hover-row"
                          key={index}
                        >
                          <td className="card-body gap-1">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <span className="font-15 font-bold text-dark px-2">
                                {item?.country?.name +
                                  "-" +
                                  item?.country?.currency +
                                  " (" +
                                  item?.country?.currency_symbol +
                                  ")"}
                              </span>
                            </div>
                          </td>
                          <td className="card-body gap-2">
                            <div className="d-flex flex-end justify-content-end px-4">
                              <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                {item?.balance}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={2}>
                          <div className="d-flex justify-content-center flex-row w-100 text-danger font-15 font-bold">
                            {`${t("Currencies not allocated to this")} ${selectedClient?.partner_name} ${t("client")}`}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <div className="d-flex flex-row  align-items-center my-4">
        <div className="d-flex flex-grow-1">
          <div className="d-flex flex-start gap-3 mb-1 align-items-center">
            <div className="">
              <Button
                type={tabs === "orders" ? "primary" : "rounded"}
                text={t("Orders")}
                className="rounded-5 my-4 mt-6 fw-bolder"
                onClick={() => handleChangeTab("orders")}
              />
            </div>
            <div className="">
              <Button
                type={tabs === "products" ? "primary" : "rounded"}
                text={t("Products")}
                className="rounded-6 my-4 mt-6 fw-bold"
                onClick={() => handleChangeTab("products")}
              />
            </div>
            {selectedClient?.float_flag && (
              <div className="">
                <Button
                  type={tabs === "floatTransactions" ? "primary" : "rounded"}
                  text={t("Float Transactions")}
                  className="rounded-6 my-4 mt-6 fw-bold"
                  onClick={() => handleChangeTab("floatTransactions")}
                />
              </div>
            )}
            {permissions?.all_clients?.create ? (
              <div className="">
                <Button
                  type={tabs === "email" ? "primary" : "rounded"}
                  text={t("Email Template")}
                  className="rounded-6 my-4 mt-6 fw-bold"
                  onClick={() => handleChangeTab("email")}
                />
              </div>
            ) : null}
          </div>
        </div>
        {/* <div>
          <SmallToast data={id ? id : ""} message={"Copied"} />
        </div> */}
        {/* <div className="">
          <Button
            type="primary"
            text="+ Top Up Float"
            className="rounded-6 my-4 mt-6 fw-bold"
            // onClick={() => goToNext()}
          />
        </div> */}
      </div>
      {tabs === "orders" ? (
        <AllTransactions lang={props?.lang} selectedClient={selectedClient} />
      ) : tabs === "floatTransactions" ? (
        <ClientFloatTransaction selectedClient={selectedClient} />
      ) : tabs === "products" ? (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
          <PartnerDetailProductTableHeader
            lang={props?.lang}
            productFilterValueGethandler={productFilterValueGethandler}
            getSearchValue={getSearchValue}
            goToEditMappingScreen={goToEditMappingScreen}
          />
          {prductFilterValue?.name === "Giftcard" ? (
            <ClientDetailGiftcardTable
              giftcardTitle={t("Giftcard image/title")}
              giftcardId={t("Giftcard id")}
              countryCurrency={t("Country & currency")}
              inventoryType={t("Inventory type")}
              discount={t("Supplier discount")}
              partnerId={
                selectedClient?.id &&
                selectedClient?.id
              }
              searchValue={searchValue}
            />
          ) : (
            <ClientDetailMerchandiseTable
              merchandiseTitle={t("Merchandise title")}
              merchandiseId={t("Merchandise id")}
              countryCurrency={t("Country & currency")}
              sellingPrice={t("Selling price")}
              partnerId={
                selectedClient?.id &&
                selectedClient?.id
              }
              searchValue={searchValue}
            />
          )}
        </div>
        // <MappedProducts selectedClient={selectedClient} />
      ) : // <MappedProducts selectedClient={selectedClient} />
        tabs === "email" && permissions?.all_clients?.create ? (
          <CustomEmail selectedClient={selectedClient} />
        ) : null}
    </div>
  );
};

export default ClientDetails;
