import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import MyntraImg from "../../assests/img/ImageGift.png";
import ModalComp from "../../components/Models/ModalComp";
import ModelForMerchandiseSummery from "../../components/Models/ModelForMerchandiseSummery";
import { getMerchandiseSummeryById } from "../../Redux/Merchandise/merchandiesAction";
import prodcutDefaultImg from '../../assests/img/Product-default.png'
import { useTranslation } from "react-i18next";

const OrderSummaryProduct = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const merchandiseDistributionData = useSelector(
    (state) => state?.createOrder?.distributionOfMerchandise
  );

  // for model
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );
  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);


  const showModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowModal(true);
  };

  return (
    <div className="card-body d-flex flex-column justify-content-between bg-white shadow-sm rounded-3 p-4 mb-2 gap-3">
      <div className="d-flex gap-4 ">
        <span className="font-bold font-20 ">{t("Product")}</span>
      </div>

      <table responsive="xl">
        <tbody>
          <tr className="d-flex justify-content-between align-items-center px-2">
            <td>
              <div className="d-flex flex-row justify-content-start align-items-center py-3">
                <span className="rounded-3">
                  <img
                    src={
                      merchandiseDistributionData?.selectedProduct?.images
                        ? merchandiseDistributionData?.selectedProduct?.images[0]
                            .file_url
                        : prodcutDefaultImg
                    }
                    alt="not found"
                    id="product-thumbnail"
                    // className="img-fluid "
                    // width="80px"
                    // height="80px"
                  />
                </span>
                <div className="d-flex flex-column justify-content-start gap-2 mx-3">
                  <span className="font-16 font-boldest text-dark cursor"
                  onClick={() => showModel(merchandiseDistributionData?.product?.merchandise?.id)}
                  >
                    {
                      merchandiseDistributionData?.product?.merchandise
                        ?.default_title
                    }
                    {merchandiseDistributionData?.varinats &&  ` (${merchandiseDistributionData?.varinats?.variant_values.join("|")})`}
                  </span>
                  <span className="font-13 font-bold text-muted">
                    {merchandiseDistributionData?.product?.merchandise?.id}
                  </span>
                </div>
              </div>
            </td>
            <td>
              <span className="font-16 font-bold text-dark">
                {/*  {location?.state?.selectedQuantity}*/}
              </span>
            </td>
            <td>
              <span className="font-15 font-bold text-success bg-04 px-1 py-1 rounded-3">
                {
                  merchandiseDistributionData?.selectedProduct?.availability==="in_stock"?
                  "In stock":"Out of stock"
                }
              </span>
            </td>
            <td>
              <span className="font-15 font-bold text-success bg-04 px-1 py-1 rounded-3 text-dark">
                {`${merchandiseDistributionData?.countryCurrency?.currency} - (${merchandiseDistributionData?.countryCurrency?.currency_symbol})`}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      < ModalComp
      size="lg"
      showModal={showModal}
      // handleClose={handleClose}
      component={
        <ModelForMerchandiseSummery
          handleClose={handleClose}
          merchandiseSummery={merchandiseSummery}
        />
      }
    />
    </div>
  );
};

export default OrderSummaryProduct;
