import React from "react";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";

const SearcBar = (props) => {
    const { name, value, onChange, placeholder, type, disabled, lang } = props;

    return (
        <div>
            <div className={lang === "ar" ? "Inputwithicon-ar" : "Inputwithicon"}>
                <div id="search">
                    <Searchicon />
                </div>
                <input
                    type={type ? type : "text"}
                    className="input-custom w-100"
                    name={name ? name : "search"}
                    placeholder={placeholder ? placeholder : "Search something"}
                    disabled={disabled}
                    value={value}
                    onChange={(e) => onChange(e)}
                />
            </div>
        </div>


    );
};

export default SearcBar;
