import moment from "moment";

export const dateFormatConverter = (d) => {
  let dateStr = d.toISOString();
  let date = moment(dateStr, "YYYY-MM-DDTHH:mm:ss.sssZ").format(
    "YYYY-MM-DDTHH:mm:ss.ssssss"
  );
  // ex : 2022-04-23T22:43:09.090909
  return date;
};
export const momentdateTimeFormater = (d) => {
  if (d) {
    let datestr = d;
    let date = moment(datestr).format("L");
    let time = moment(datestr).format("LT");
    return date + "|" + time;
  } else {
    return " ";
  }
};
export const momentdateFormater = (d) => {
  if (d) {
    let datestr = d;
    let date = moment(datestr).format("L");
    return date;
  } else {
    return " ";
  }
};

export const dateFormatConverterforFrontend = (d) => {
  let date = new Date(d);
  date.setUTCDate(date.getDate());
  // Mon Jul 04 2022 14:56:09 GMT+0530 (India Standard Time)
  return date;
};

export const stringConverter = (obj) => {
  return JSON.stringify(obj);
};

export const objConverter = (obj) => {
  return JSON.parse(obj);
};

export const diffTwoDatesInDays = (sdate, edate) => {
  return moment(edate).diff(moment(sdate), "days");
};
export const nextYearRange = () => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

export const strFirstLetterCap = (data) => {
  if (typeof data !== "string") return "";
  return data.charAt(0).toUpperCase() + data.slice(1);
  // const str = data;
  // if(str){
  //     const str2 = str.charAt(0).toUpperCase() + str.slice(1);
  //     return str2;
  // }
};

export const DateTimeFormatter = (d) => {
  let date = new Date(`${d}`);
  return (
    date.getDate() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getFullYear() +
    "|" +
    date.getHours() +
    ":" +
    date.getMinutes()
  );
};

export const getDefaultLanguage = () => {
  const lang = localStorage.getItem("defaultLang");

  return JSON.stringify(lang);
};

// download file from url
export const downloadFileURI = (obj) => {
  const link = document.createElement("a");
  link.download = obj.name;
  link.href = obj.file_url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
// download simple file
export const downloadTransactionReciept = (obj) => {
  const link = document.createElement("a");
  // link.download = obj.name;
  link.href = obj.transaction_receipt;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const convertNumberInUSAFormat = (number) => {
  if (number) {
    let num = number.toFixed(2);
    var commas = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return commas;
  } else {
    return " ";
  }
};

// For table data sorting column wise
export const DataSortingHandler = (
  tableHeaderName,
  setTableHeaderName,
  data,
  order,
  setOrder
) => {
  if (order === "descending") {
    // toLowerCase()
    let sortedData =
      data &&
      data.sort((a, b) => {
        let aSmall = a[tableHeaderName] && a[tableHeaderName]
        let bSmall = b[tableHeaderName] && b[tableHeaderName]
        // return aSmall && aSmall.toLowerCase() > bSmall && bSmall.toLowerCase() ? 1 : -1;
        return aSmall && aSmall > bSmall && bSmall ? 1 : -1;
      });
    setTableHeaderName(tableHeaderName);
    setOrder("ascending");
  }

  if (order === "ascending") {
    let sortedData =
      data &&
      data.sort((a, b) => {
        let aSmall = a[tableHeaderName] && a[tableHeaderName]
        let bSmall = b[tableHeaderName] && b[tableHeaderName]
        // return aSmall && aSmall.toLowerCase() < bSmall && bSmall.toLowerCase() ? 1 : -1;
        return aSmall && aSmall < bSmall && bSmall ? 1 : -1;
      });
    setTableHeaderName("");
    setOrder("descending");
  }
};
