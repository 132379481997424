import React from "react";

const Button = (props) => {
  const { onClick, text, type } = props
  return (
    <div>
      {type === "primary" ?
        <div className="primary-btn w-100 cursor" onClick={onClick}>
          {text}
        </div> :
        type === "secondary" ?
          <div className="secondary-btn w-100 " onClick={onClick}>
            {text}
          </div> :
          type === "rounded" ?
            <div className="rounded-btn w-100 " onClick={onClick}>
              {text}
            </div> :
            type === "square" ?
              <div className="square-btn w-100 " onClick={onClick}>
                {text}
              </div> :
              type === "border" ?
                <div className="border-btn w-100 " onClick={onClick}>
                  {text}
                </div> : null
      }

    </div>
  );
};

export default Button;
