import React from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as YellowTag } from "../../assests/svg/Tag/yellow_tag.svg";
import { ReactComponent as RadishTag } from "../../assests/svg/Tag/radish_tag.svg";
import { ReactComponent as PinkTag } from "../../assests/svg/Tag/pink_tag.svg";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/dark_green.svg";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const OrderSummaryDetail = (props) => {
  const { t } = useTranslation();
  const { productCurrency, remainingBal, orderAmount, variant, floatFlag } =
    props;

  const merchandiseDistributionData = useSelector(
    (state) => state?.createOrder?.distributionOfMerchandise
  );
  // console.log(
  //   "merchandiseDistributionData ",
  //   merchandiseDistributionData
  // );

  return (
    <div className="d-flex flex-column justify-content-between bg-white shadow-sm rounded-3 p-4 w-100">
      <div className="d-flex gap-4  pb-3">
        <span className="font-bold font-20 ">{t("Details")}</span>
      </div>
      <div className=" d-flex flex-column gap-3">
        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <YellowTag />
          <span className="font-20 font-bold">
            {t("Price")} :
            <span className="font-20 font-bold text-light-blue ">
              {variant
                ? `${productCurrency?.currency_symbol}${variant}`
                : `${productCurrency?.currency_symbol}${merchandiseDistributionData?.product?.selling_price}`}
            </span>
          </span>
        </div>

        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <RadishTag />
          <span className="font-20 font-bold">
            {t("Quantity")} :{" "}
            <span className="font-20 font-bold text-light-blue ">
              {merchandiseDistributionData?.quantity}
            </span>
          </span>
        </div>

        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <PinkTag />
          <span className="font-20 font-bold">
            {t("Client")} :{" "}
            <span className="font-22 font-bold text-light-blue ">
              <u>{merchandiseDistributionData?.selectedClient?.partner_name}</u>
            </span>
          </span>
        </div>

        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <GreenTag />
          <span className="font-20 font-bold">
            {t("Order amount")} :
            <span className="font-20 font-bold text-light-blue ">
              {" "}
              {productCurrency?.currency_symbol}
              {orderAmount}
            </span>
          </span>
        </div>

        {merchandiseDistributionData?.deliveryType === "Physical" ? (
          <div className="d-flex gap-3 my-2 mx-2 align-items-center">
            <GreenTag />
            <span className="font-20 font-bold">
              {t("Delivery address")} :
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {merchandiseDistributionData?.deliveryAddressline1}{" "}
              </span>
              {merchandiseDistributionData?.deliveryAddressline2 && ","}
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {merchandiseDistributionData?.deliveryAddressline2}{" "}
              </span>
              {merchandiseDistributionData?.cityName && ","}
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {merchandiseDistributionData?.cityName}{" "}
              </span>
              {merchandiseDistributionData?.pincode && ","}
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {merchandiseDistributionData?.pincode}{" "}
              </span>
            </span>
          </div>
        ) : (
          <div className="d-flex gap-3 my-2 mx-2 align-items-center">
            <GreenTag />
            <span className="font-20 font-bold">
              {t("Delivery type")} :
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {t("Physical")}{" "}
              </span>
            </span>
          </div>
        )}

      {merchandiseDistributionData?.emailid && <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <PinkTag />
          <span className="font-20 font-bold">
            {t("Email")} :{" "}
            <span className="font-22 font-bold text-light-blue ">
              <u>{merchandiseDistributionData?.emailid}</u>
            </span>
          </span>
        </div>}

        {floatFlag === true && (
          <div className="d-flex gap-3 my-4 mx-2 align-items-center">
            <Tag />
            <span className="font-20 font-bold">
              {t("Remaining Float Balance")}:
              <span className="font-20 font-bold text-light-blue ">
                {" "}
                {productCurrency?.currency_symbol}
                {remainingBal}
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSummaryDetail;
