import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import { getGiftcardDetails } from "../../../Redux/Epos/eposAction";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../components/Toasts/Toasts";
import PulseLoader from "../../../components/Loader/PulseLoader";
import { useTranslation } from "react-i18next";

const CheckBalance = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputElementGiftcardNo = useRef(null);

  const loading = useSelector((state) => state.eposState?.loading);

  const [fields, setFields] = useState({
    giftCardNumber: "",
  });

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const [toastMessage, setToastMessage] = useState("");

  const [error, setError] = useState({
    giftCardNumber: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useNavigate();

  const changeHandler = (e) => {
    const value = e.target.value;
    setFields({ ...fields, [e.target.name]: value });
  };

  const validate = () => {
    if (!fields.giftCardNumber) {
      setError({ giftCardNumber: t("Please enter giftcard number") });
      if(inputElementGiftcardNo.current){
        inputElementGiftcardNo.current.focus()
      }
      return false;
    }

    return true;
  };

  const handlerError = (errorCode, successCode) => {
    if (successCode === 200) {
      setError({
        ...error,
        errorCode: "",
        successCode: t("Your giftcard verify"),
      });
      setToastMessage("Your giftcard is verify");
      toggleShowToast();
      afterGiftcardNoVerify();
    } else if (errorCode === 404) {
      setError({ ...error, errorCode: t("Giftcard not found"), successCode: "" });
      setToastMessage("Giftcard not found");
      toggleShowToast();
    }
  };

  const afterGiftcardNoVerify = () => {
    return history("/epos/giftcard-balance", {
      state: { giftCardNumber: fields.giftCardNumber },
    });
  };

  const submit = () => {
    let val = validate();
    if (val) {
      dispatch(getGiftcardDetails(fields.giftCardNumber, handlerError));
      // history("/epos/giftcard-balance", { state: { giftCardNumber: fields.giftCardNumber } });
    }
  };

  return (
    <>
      {loading === false ? (
        <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row align-items-center pb-2 gap-3">
              <LeftIcon onClick={() => history(-1)} className="cursor" />
              <span className="font-bold font-32 my-2">{t("Check Balance")}</span>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between gap-3">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">
              <div className="d-flex flex-column gap-4 mt-4 ">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Giftcard number")}<span className="text-danger">*</span>
                    </span>
                    <InfoTooltip title={"Gift card number is 25 charactors long"} />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <InputField
                    inputRef={inputElementGiftcardNo}
                      name="giftCardNumber"
                      placeholder={t("Enter giftcard number")}
                      type="text"
                      value={fields?.giftCardNumber}
                      onChange={(e) => changeHandler(e)}
                    />
                    {!fields.giftCardNumber && error.giftCardNumber ? (
                      <label className="text-error">{error.giftCardNumber}</label>
                    ) : null}
                  </div>
                </div>

                {/* <div className="d-flex flex-row align-items-center ">
              <ScanIcon />
              <span className="font-15 font-bold text-light-blue cursor"> <u>Scan gift card number</u></span>
            </div> */}
              </div>
            </div>
          </div>

          <div className="d-flex py-1">
            <Button onClick={submit} text={t("Submit")} type="primary" />
          </div>

          {/* <div className="d-flex flex-column bg-white shadow-sm rounded-3 justify-content-center border border-primary border-3 p-3 m-4 width-65">
        <div className="d-flex justify-content-start font-20 font-bold text-dark-blue"> Update W.e.f. 1/10/2022</div>
        <p className="text-muted font-15">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum
          libero, minus accusantium reiciendis veniam nostrum recusandae quae
          deleniti dolorem officia voluptas ullam cumque a repellendus
          doloremque consequatur minima adipisci et! Lorem ipsum dolor sit amet,
          consectetur adipisicing elit. Lorem ipsum dolor sit amet,consectetur
          adipisicing elit !
        </p>
      </div> */}

          <div
            className="position-fixed bottom-0 start-50 translate-middle-x w-100 mb-2"
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg={toastMessage}
              imageType={"warning"}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )
      }
    </>
  );
};

export default CheckBalance;
