/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../Toasts/Toasts";
import {
  DataSortingHandler,
  momentdateFormater,
} from "../../utils/constantFunction";
import Filter from "../Filter/Filter";
import { PRODUCT_STATUS_FILTERS_ACTIVE_DEACTIVE } from "../../utils/ConstantData";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoaders from "react-spinners/PulseLoader";
import {
  clearSupplierList,
  fetchSupplierList,
  setSupplierFilterData,
} from "../../Redux/Supplier/supplierAction";
import PulseLoader from "../Loader/PulseLoader";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";
import { SORT_TYPE } from "../../utils/ConstantData";
import RefreshButton from "../Button/RefreshButton";

const AllSupplierList = (props) => {
  const { suppliersState } = props;
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  // infinite scroll
  const hasmore = useSelector((state) => state?.suppliers?.next_iteration);
  const loading = useSelector((state) => state?.suppliers?.loading);
  const [skip, setskip] = useState(
    suppliersState.length === 0 ? -10 : suppliersState.length - 10
  );
  const [limit, setlimit] = useState(10);
  const supplierFilterState = useSelector(
    (state) => state?.suppliers?.supplierFilter
  );

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => { }, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (suppliersState.length === 0) {
      fetchdata(skip);
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(fetchSupplierList(skip + limit, limit, suppliersState));
      setskip(skip + limit);
    }, 100);
  };
  // const loading = useSelector((state) => state?.partnerFloat?.loading)
  // const [visible, setVisible] = useState(10);
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);
  const [products, setProducts] = useState();
  const [filterCardType, setFilterCardType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    setProducts(suppliersState);
  }, [suppliersState]);

  useEffect(() => {
    setProducts(supplierFilterState[0]?.filterProduct);
  }, [supplierFilterState]);

  useEffect(() => {
    supplierFilterState &&
      setFilterCardType(supplierFilterState[1]?.filterType);
  }, [supplierFilterState[1]?.filterType]);

  useEffect(() => {
    if (supplierFilterState.length === 0) {
      setProducts(suppliersState);
    }
  }, []);

  const filterCardTypeHandler = (e) => {
    setFilterCardType(e);
    let arr = [];
    suppliersState &&
      suppliersState.map((item, i) => {
        if (e?.value === item.active_flag) {
          arr.push(item);
        } else if (e?.value === null) {
          arr.push(item);
        }
      });
    dispatch(
      setSupplierFilterData([{ filterProduct: arr }, { filterType: e }])
    );
    //  setProducts(arr);
  };

  const goTonextStep = (item) => {
    history("/supplier-details", {
      state: {
        selectedSupplier: item,
      },
    });
  };

  const refreshData = () => {
    dispatch(clearSupplierList());
    setskip(0);
    setlimit(10);
    dispatch(fetchSupplierList(0, 10, []));
  };

  return loading === true && products?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column gap-3 mt-3">
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-2 w-100">
        <div className="d-flex flex-column  bg-white">
          <div className="card-body d-flex  flex-row justify-content-between align-item-center">
            <div className="d-flex flex-row justify-content-start align-items-center gap-2">
              <div className="p-2 flex-grow-1">
                <div className="d-flex gap-3 my-2 mx-3 align-items-center">
                  <Tag />
                  <span className="font-20 font-bold">{t("All Supplier")}</span>
                </div>
              </div>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search by name")}
                onChange={searchHandler}
              />
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center gap-2">
              <div>
                <Filter
                  data={PRODUCT_STATUS_FILTERS_ACTIVE_DEACTIVE}
                  onChange={filterCardTypeHandler}
                  title={filterCardType?.name}
                  lang={props?.lang}
                />
              </div>
              <div>
                <RefreshButton onClick={refreshData} />
              </div>
            </div>
          </div>
          <InfiniteScroll
            dataLength={suppliersState?.length || 0}
            next={fetchdata}
            hasMore={hasmore}
            loader={
              <div className="d-flex justify-content-center">
                {" "}
                <PulseLoaders
                  color={"#594190"}
                  loading={loading}
                  size={10}
                />{" "}
              </div>
            }
          >
            <div className="row px-5 py-2">
              <table responsive="xl">
                <tbody>
                  <tr className="card-body gap-3 border-bottom py-5">
                    <td
                      className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "partner_name"
                        ? "ascending"
                        : "descending"
                        }`}
                      onClick={() =>
                        sortingHandler(
                          "partner_name",
                          setTableHeaderName,
                          products,
                          order,
                          setOrder
                        )
                      }
                    >
                      {t("Name")}
                    </td>
                    <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "email"
                      ? "ascending"
                      : "descending"
                      }`}
                      onClick={() =>
                        sortingHandler(
                          "email",
                          setTableHeaderName,
                          products,
                          order,
                          setOrder
                        )
                      }>
                      {t("Email")}
                    </td>
                    <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "created_at"
                      ? "ascending"
                      : "descending"
                      }`}
                      onClick={() =>
                        sortingHandler(
                          "created_at",
                          setTableHeaderName,
                          products,
                          order,
                          setOrder
                        )
                      }>
                      {t("Created at")}
                    </td>
                    <td className={`card-body gap-1 font-13 font-bold text-muted`}>
                      {t("Status")}
                    </td>
                  </tr>
                  {products &&
                    products
                      ?.filter((item) => {
                        if (searchTerm === "") {
                          return item;
                        } else if (
                          item?.partner_name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => (
                        <tr
                          className="card-body gap-3 border-bottom cursor hover-row"
                          key={item?.id}
                          onClick={() => goTonextStep(item)}
                        >
                          {/*onClick={() => goToMappingVariants()}*/}

                          <td className="card-body gap-1">
                            <div className="d-flex flex-column justify-content-start ">
                              <span
                                className="d-inline-block text-truncate font-15 font-boldest text-dark"
                                title={item?.partner_name}
                                style={{ maxWidth: "160px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                {item?.partner_name}
                              </span>
                              <span className="font-12 font-bolder text-muted">
                                {item?.company_name}
                              </span>
                            </div>
                          </td>
                          <td className="card-body gap-1">
                            <div className="d-flex flex-row justify-content-between  gap-3">
                              <span
                                className="fs-light  text-muted d-inline-block text-truncate"
                                style={{ maxWidth: "140px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item?.email}
                              >
                                {item.email}
                              </span>
                            </div>
                          </td>
                          <td className="card-body gap-1">
                            <span className="fs-light  text-muted">
                              {/* {DateTimeFormatter(item?.created_at)}*/}
                              {momentdateFormater(item?.created_at)}
                            </span>
                          </td>
                          <td className="card-body gap-1">
                            <div className="d-flex flex-row justify-contents-between align-items-center">
                              <span>
                                {item?.active_flag === true ? (
                                  <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                    {t("Active")}
                                  </span>
                                ) : (
                                  <span className="font-15 font-bold text-danger bg-04 bg-opacity-25 rounded-3 px-2">
                                    {t("Deactive")}
                                  </span>
                                )}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
              {!products?.length && (
                <div className="d-flex flex-row justify-content-center py-3">
                  <span className="font-20 font-bold text-error">
                    {t("No supplier found")}
                  </span>
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default AllSupplierList;
