import React, { useEffect, useState } from "react";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as Tagpurple } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Righttick } from "../../assests/svg/Righttick.svg";
import product_default from "../../assests/img/Product-default.png";
import { useSelector } from "react-redux";
import PulseLoader from "../Loader/PulseLoader";
import { useTranslation } from "react-i18next";
import ProductImageSlider from "../ImageSlider/ProductImageSlider";
import VarientProductImageSlider from "../ImageSlider/VarientProductImageSlider";
import SliderLeftAndRightButtons from "../ImageSlider/SliderLeftAndRightButtons";

const ErrorHandlerCard = (props) => {
  const { t } = useTranslation();
  const { handleClose, errors } = props;

  const handleRemoveClick = () => {};

  return (
    <div className="d-flex flex-column bg-white p-4 w-100 mb-3">
      <div className="d-flex flex-row justify-content-end align-items-center">
        <div className="d-flex justify-content-end">
          <span onClick={handleClose} className="rounded-pill  bg-light p-2">
            <span className="font-20 rounded-circle cursor p-2">X</span>
          </span>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex">
          <div className="d-flex flex-column">
            <div className="font-32 font-bold align-item-center m-4 text-danger">
              File Fields Error
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row p-1 justify-content-between">
        <div className="d-flex flex-row p-4 col-4">
          <div className="d-flex flex-wrap flex-column gap-2">
            <div className="d-flex gap-3">
              <GreenTag />
              <span className="font-20 font-bold neutral-grey-07">
                {t("File Rows")}
              </span>
            </div>

            <div className="d-flex flex-column mt-3 w-100  neutral-grey-05 font-15">
              <ul className="list-group list-group-flush ">
                <li className="list-group-item d-flex flex-column">
                  {errors &&
                    errors?.map((item, index) => {
                        if(item){
                            return (
                                <span
                                  key={index}
                                  className="m-2 font-bolder text-danger"
                                >
                                 Row {index + 1}
                                </span>
                              );
                        }else {
                            return null
                        }
                    })}
                </li>
              </ul>
            </div>

            {/* <div className="d-flex flex-column gap-2 mt-4">
              {errors &&
                errors?.map((item, index) => {
                  return (
                    <span className="font-15 font-bolder text-danger" key={index}>
                      Row {index + 1}
                    </span>
                  );
                })}
            </div> */}
          </div>
        </div>

        <div className="d-flex flex-row p-4 col-8">
          <div className="d-flex flex-wrap flex-column gap-2">
            <div className="d-flex gap-3">
              <Tagpurple />
              <span className="font-20 font-bold neutral-grey-07">
                {t("Error Message")}
              </span>
            </div>

            <div className="d-flex flex-column mt-3 w-100  neutral-grey-05 font-15">
              <ul className="list-group list-group-flush ">
                <li className="list-group-item d-flex flex-column">
                  {errors &&
                    errors?.map((item, index) => {
                        if(item){
                            return (
                                <span
                                  key={index}
                                  className="m-2 font-bolder text-danger"
                                >
                                  {item.errors}
                                </span>
                              );
                        }else {
                            return null
                        }
                      
                    })}
                </li>
              </ul>
            </div>

            {/* <div className="d-flex flex-column gap-2 mt-4">
              {errors &&
                errors?.map((item, index) => {
                  return (
                    <span className="font-17 font-bolder text-muted">
                      {item.errors}
                    </span>
                  );
                })}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorHandlerCard;
