import React from "react";
import { ReactComponent as ProductView } from "../../assests/svg/Dashboard/Product_views.svg";
import { ReactComponent as BestClient } from "../../assests/svg/Dashboard/BestClient.svg";
import { useTranslation } from "react-i18next";

const BestClients = () => {
  const { t } = useTranslation();
  
  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-4  px-4 h-100 w-100">
   <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3 ">
          <BestClient />
          <span className="font-20 font-bold">{t("Best Clients")}</span>
        </div>
      </div>
      <div className="card-body d-flex justify-content-center text-danger">
       {/* <ProductView />*/}
       No data
      </div>
    </div>
  );
};
export default BestClients;
