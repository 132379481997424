import React, {useEffect} from "react";
import ChelsieHaley from "../../assests/img/ChelsieHaley.png";
import Dropdown from "../../components/Dropdown/Dropdown";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import SwitchButton from "../../components/Button/SwitchButton";



const UserProfile = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-3 px-4 bg-light">
      <div className="d-flex">
        <span className="font-40 font-bold mb-2">User profile</span>
      </div>

      <div className="width-100 ">

   <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
       <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <span>
            <img
              src={ChelsieHaley}
              alt="not found"
              className="img-fluid width-75"
            />
          </span>
          <div>
    <span className="font-15 font-boldest text-dark ">Samsung Haley</span><br/>
    <span className="font-15 font-bolder text-muted">@username</span>
    </div>
        </div>

      </div>

      <div className="d-flex flex-column gap-4 mt-4 width-65 ">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
                Change user name<span className="text-danger">*</span>
                </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <InputField placeholder={"Enter user name"} />
        </div>

        <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Change Email<span className="text-danger">*</span>
                  <span className="font-bold font-14 text-muted">
                    (This will also be used for OTP Authentication)
                  </span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField placeholder={"Enter email ID"} />
            </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">Name
            <span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <InputField placeholder={"Enter user name"} />
        </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Password<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <InputField placeholder={"Example sdf@#@#%$,."} />
        </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
                Role<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <Dropdown />
        </div>

        <div className="d-flex flex-row justify-content-between mb-2 gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-13">
                  Send notifications to user(Email will also be used for OTP
                  authentication)<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <SwitchButton />
            </div>

            <div className="d-flex flex-row gap-3">
          <Button
            onClick={() => alert("Update")}
            text="Update"
            type="primary"
          />
          <Button
            onClick={() => alert("Delete User")}
            text="Delete User"
            type="square"
          />
        </div>
      </div>
    </div>

      </div>
    </div>
  );
};

export default UserProfile;
