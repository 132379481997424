import React, { useEffect } from "react";
import CardPreview from "../../components/Products/CardPreview";
import Header from "../../components/Products/Header";
import ProductPricing from "../../components/Products/ProductPricing";

const AddProductPricing = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
            <Header steps={3} title={"Price"} />
            <div className="d-flex flex-row mt-2 gap-2">
                <div className="width-65" >
                    <ProductPricing title={"Price"} lang={props?.lang} />
                </div>
                <div className="width-35" >
                    <CardPreview />

                </div>
            </div>

        </div>
    );
};

export default AddProductPricing;
