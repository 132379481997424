import {
    GET_SHIPPING_COST_REQUEST,
    GET_SHIPPING_COST_SUCCESS,
    GET_SHIPPING_COST_FAILURE
  } from "./shippingcostType";
  
  const initialShippingCostState = {
    loading: false,
    shippingcost: [],
    error: "",
  };
  
  const shippingCostReducer = (state = initialShippingCostState, action) => {
    switch (action.type) {
      case GET_SHIPPING_COST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_SHIPPING_COST_SUCCESS:
        return {
          ...state,
          loading: false,
          shippingcost: action.payload,
          error: "",
        };
      case GET_SHIPPING_COST_FAILURE:
        return {
          ...state,
          loading: false,
          shippingcost: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  export default shippingCostReducer;
  