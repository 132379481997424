import {GET_DENOMINATION_REQUEST,
    GET_DENOMINATION_SUCCESS,
    GET_DENOMINATION_FAILURE } from "./denominationType";

    const initialState = {
        denomination: [],
        loading: false,
        error: '',
        errorMsg: '',
    }

    const denominationReducer = (state = initialState, action) => {
        switch (action.type) {
            
            case GET_DENOMINATION_REQUEST:
                return {
                    ...state,
                    loading: true,
                    denomination: [],
                    errorMsg: '',
                }
            case GET_DENOMINATION_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    denomination: action.payload,
                    errorMsg: '',
                }
            case GET_DENOMINATION_FAILURE:
                return {
                    ...state,
                    loading: false,
                    denomination:[],
                    errorMsg: action.payload
                }
            
            default: return state
        }
    }
    
    export default denominationReducer;