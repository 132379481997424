import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg"
import InputField from "../InputField/InputField";
import { useTranslation } from "react-i18next";

const CardDetails = () => {
  const { t } = useTranslation();
  const giftcardDetails = useSelector((state) => state.eposState?.giftcardDetails);
 

  const [fields, setFields] = useState({
    giftCardNumber: "",
    redemptionAmount: "",
  });


  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftcardDetails]);


  const changeHandler = (e) => {
    const value = e.target.value;
    setFields({ ...fields, [e.target.name]: value });
  };


  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">{t("Gift Card details")}</span>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center gap-3">

      </div>
      <div className="d-flex flex-column gap-4 mt-4 ">
        <div className="d-flex flex-column gap-3 ">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              {t("Gift card issued to")}</span>
          </div>
          <div className="d-flex flex-column gap-1">
            <InputField
              name="giftCardNumber"
              value={giftcardDetails?.balance_obj?.giftCardNumber}
              disabled={true}
            // onChange={(e) => changeHandler(e)}
            />
          </div>
        </div>

        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Original Amount")}</span>
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="redemptionAmount"
                value={giftcardDetails?.balance_obj?.original_amount}
                disabled={true}
              // onChange={(e) => changeHandler(e)}
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Balance Amount")}</span>
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="redemptionAmount"
                value={giftcardDetails?.balance_obj?.balance_amount}
                disabled={true}
              // onChange={(e) => changeHandler(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
