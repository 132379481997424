import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../assests/svg/Tag/green_tag.svg";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import InputGroupTxt from "../../../components/InputField/InputGroupTxt";
import TagsInput from "../../../components/TagsInput/TagsInput";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountriesList } from "../../../Redux/Countries/countryAction";
import DragandDropPDF from "../../../components/DragandDrop/DragandDropPDF";
import ReactDatePicker from "../../../components/DatePicker/ReactDatePicker";
import Button from "../../../components/Button/Button";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import Select from "react-select";
import ReactSwitchButton from "../../../components/Button/ReactSwitchButton";
import { getPratnerFloats, postFloatClient, } from "../../../Redux/Float/floatAction";
import PulseLoader from "../../../components/Loader/PulseLoader";
import { getOrganizationList } from "../../../Redux/Organizations/organizationAction";


const FloatOrgDetail = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const partner = useSelector((state) => state?.partners?.createPartnerResponese);
  const organizationState = useSelector((state) => state?.organizations?.organizationList);
  const floatCurrency = useSelector((state) => state?.partnerFloat?.floats);
  const loading = useSelector((state) => state?.partnerFloat?.loading);

  const [documentation, setDocumentations] = useState([]);
  const [emailList, setEmailList] = useState([]);

  const [fields, setFields] = useState({
    client: partner ? partner : location?.state?.selectedClient,
    currency: "",
    floatAmount: "",
    paymentReceivedOn: new Date(),
    percentageNotificationLimit: "",
    amountNotificationLimit: "",
  });

  const [switchButton, setSwitchButton] = useState({
    notificationsFlag: false,
    percentageNotificationLimitFlag: false,
    amountNotificationLimitFlag: false,
  });

  const [error, setError] = useState({
    client: "",
    currency: "",
    floatAmount: "",
    paymentReceivedOn: "",
    documentation: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getOrganizationList());
    dispatch(getAllCountriesList());
    if (partner?.id) {
      dispatch(getPratnerFloats(partner?.id));
    }
    if (partner?.email) {
      setEmailList([partner?.email]);
    }
    if (location?.state?.selectedClient?.email) {
      setEmailList([location?.state?.selectedClient?.email]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeClient = (e) => {
    setFields({ ...fields, client: e, currency: "" });
    setEmailList([e?.email]);
    dispatch(getPratnerFloats(e?.id));
  };

  const handleChangeCurrency = (e) => {
    setFields({ ...fields, currency: e });
  };

  const handleChangeValue = (item, type) => {
    setFields({ ...fields, [type]: item });
  };

  const inputChangeHandle = (e) => {
    if (e.target.name === "percentageNotificationLimit") {
      let regex = /^\d{0,2}(\.\d{0,2})?$/;
      if (regex.test(e.target.value)) {
        setFields({
          ...fields,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      let regex = /^\d{0,8}(\.\d{0,2})?$/;
      if (regex.test(e.target.value)) {
        setFields({
          ...fields,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleFloatPercentageSwitch = (e) => {
    setSwitchButton({
      ...switchButton,
      percentageNotificationLimitFlag:
        !switchButton.percentageNotificationLimitFlag,
      amountNotificationLimitFlag: false,
    });
  };

  const handleFloatAmountSwitch = (e) => {
    setSwitchButton({
      ...switchButton,
      amountNotificationLimitFlag: !switchButton.amountNotificationLimitFlag,
      percentageNotificationLimitFlag: switchButton.false,
    });
  };

  const validate = () => {
    if (!fields.client) {
      setError({ client: "Please select client" });
      return false;
    } else if (!fields.currency) {
      setError({ currency: "Please select currency" });
      return false;
    } else if (!fields.floatAmount) {
      setError({ floatAmount: "Please enter amount" });
      return false;
    } else if (!fields.paymentReceivedOn) {
      setError({ paymentReceivedOn: "Please select date" });
      return false;
    } else if (!documentation.length) {
      setError({ documentation: "Please select receipt" });
      return false;
    }

    return true;
  };

  const submit = () => {
    let val = validate();
    let obj = {
      client: fields.client,
      currency: fields.currency,
      floatAmount: fields.floatAmount,
      paymentReceivedOn: fields.paymentReceivedOn,
      notificationsFlag: switchButton.notificationsFlag,
      notification_limit: switchButton.percentageNotificationLimitFlag
        ? fields.percentageNotificationLimit
        : fields.amountNotificationLimit,
      notification_limit_type: switchButton.percentageNotificationLimitFlag
        ? "percentage"
        : "amount",
      emails: emailList,
      documentation: documentation,
    };
    if (val) {
      dispatch(postFloatClient(obj, goToSuccessfull));
    }
  };

  const goToSuccessfull = () => {
    history("/float-map-success");
  };

  return loading ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div>
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 mb-3">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">Organization details</span>
          </div>
          {/* <div>
            <BackButton onClick={() => history(-1)} />
          </div> */}
        </div>

        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                Mapping for<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>

            <div className=" ">
              {location?.state?.selectedClient?.partner_name === undefined ? (
                <Select
                  placeholder="Select organization"
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(e) => e.partner_name}
                  options={organizationState}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.client}
                  onChange={handleChangeClient}
                  isClearable
                />

              ) : (
                <InputField
                  placeholder="Select organization"
                  name="currencyId"
                  value={
                    location?.state?.selectedClient
                      ? location?.state?.selectedClient?.partner_name
                      : ""
                  }
                  disabled={true}
                />
              )}

              {location?.state?.selectedClient?.partner_name === undefined && !fields.client && error.client ? (
                <label className="text-error">{error.client}</label>
              ) : null}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                Currency<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <div className=" ">
              <Select
                noOptionsMessage={() => "Not found"}
                placeholder="Select currency"
                getOptionLabel={(countries) => countries.country.currency}
                options={floatCurrency}
                //   isSearchable
                value={fields.currency}
                onChange={handleChangeCurrency}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
              // isDisabled={location?.state?.selectedClient?.partner_name}
              />
              {!fields.currency && error.currency ? (
                <label className="text-error">{error.currency}</label>
              ) : null}
            </div>
          </div>

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">Currency ID</span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <InputField
              placeholder="Currency id"
              name="currencyId"
              value={
                fields?.currency?.country_id ? fields?.currency?.country_id : ""
              }
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 mb-3">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">Float details</span>
          </div>
        </div>

        <div className="d-flex flex-column gap-2 mt-4">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Float amount<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <InputGroupTxt
            title={
              fields?.currency?.country?.currency_symbol
                ? fields?.currency?.country?.currency_symbol
                : fields?.currency?.country?.currency
                  ? fields?.currency?.country?.currency
                  : "$"
            }
            type="text"
            maxLength={6}
            lang={props?.lang}
            name="floatAmount"
            value={fields.floatAmount}
            onChange={inputChangeHandle}
            disabled={!fields.currency}
          />
          {!fields.floatAmount && error.floatAmount ? (
            <label className="text-error">{error.floatAmount}</label>
          ) : null}
        </div>
        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                Payment received on<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <ReactDatePicker
              onChange={handleChangeValue}
              selected={fields.paymentReceivedOn}
              maxDate={new Date()}
              lang={props?.lang}
              name={"paymentReceivedOn"}
              className="datepicker-input-style-without-border"
            />
            {!fields.paymentReceivedOn && error.paymentReceivedOn ? (
              <label className="text-error">{error.paymentReceivedOn}</label>
            ) : null}
          </div>

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                Upload bank receipt PDF<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <DragandDropPDF
              files={documentation}
              setFiles={setDocumentations}
            />
            {!documentation.length && error.documentation ? (
              <label className="text-error">{error.documentation}</label>
            ) : null}
          </div>

          <div className="d-flex flex-column  gap-2 py-2">
            <div className="d-flex flex-row align-items-center justify-content-between gap-2">
              <div>
                <span className="font-bold font-14">
                  Send notifications? (Limit exceeded, Reacharge etc.){" "}
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div>
                <ReactSwitchButton
                  onChange={() =>
                    setSwitchButton({
                      ...switchButton,
                      notificationsFlag: !switchButton.notificationsFlag,
                    })
                  }
                  value={switchButton.notificationsFlag}
                //disabled={reloadableGiftCardFlag}
                />
              </div>
            </div>
          </div>
          {switchButton.notificationsFlag && (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column  gap-3 py-2">
                <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                  <div>
                    <span className="font-bold font-14">
                      Define float percentage{" "}
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <div>
                    <ReactSwitchButton
                      onChange={() => handleFloatPercentageSwitch()}
                      value={switchButton.percentageNotificationLimitFlag}
                    //disabled={reloadableGiftCardFlag}
                    />
                  </div>
                </div>
              </div>
              {switchButton.percentageNotificationLimitFlag && (
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Notification limit
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <InputGroupTxt
                    title={"%"}
                    name="percentageNotificationLimit"
                    lang={props?.lang}
                    value={fields.percentageNotificationLimit}
                    onChange={inputChangeHandle}
                  />
                </div>
              )}

              <div className="d-flex flex-column gap-3 py-2">
                <div className="d-flex flex-row align-items-center justify-content-between gap-2">
                  <div>
                    <span className="font-bold font-14">
                      Define float amount
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <div>
                    <ReactSwitchButton
                      onChange={() => handleFloatAmountSwitch()}
                      value={switchButton.amountNotificationLimitFlag}
                    //disabled={reloadableGiftCardFlag}
                    />
                  </div>
                </div>
              </div>
              {switchButton.amountNotificationLimitFlag && (
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Notification limit
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <InputGroupTxt
                    title={
                      fields?.currency?.country?.currency_symbol
                        ? fields?.currency?.country?.currency_symbol
                        : fields?.currency?.country?.currency
                          ? fields?.currency?.country?.currency
                          : "$"
                    }
                    name="amountNotificationLimit"
                    lang={props?.lang}
                    value={fields.amountNotificationLimit}
                    onChange={inputChangeHandle}
                  />
                </div>
              )}
            </div>
          )}

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">Email list</span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
              <TagsInput
                tags={emailList}
                setTags={setEmailList}
                placeholder={"Enter Email"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <Button text="Save & Exit" type="primary" onClick={() => submit()} />
      </div>
    </div>
  );
};

export default FloatOrgDetail;
