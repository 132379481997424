import {GET_TRANSACTION_CRITARIA_REQUEST,
    GET_TRANSACTION_CRITARIA_SUCCESS,
    GET_TRANSACTION_CRITARIA_FAILURE} from '../TransactionCritaria/transactionType'

    import { baseUrl, getToken } from '../../utils/prodURL'
import axios from 'axios'

    export const getTransactionCritariaRequest = () => {
        return {
            type: GET_TRANSACTION_CRITARIA_REQUEST,
        }
    }
    
    export const getTransactionCritariaSuccess = data => {
        return {
            type: GET_TRANSACTION_CRITARIA_SUCCESS,
            payload: data
        }
    }
    
    export const getTransactionCritariaFailure = error => {
        return {
            type: GET_TRANSACTION_CRITARIA_FAILURE,
            payload: error
        }
    }
    
    export const getAllTransactionCritaria = () => {
        return (dispatch) => {
            dispatch(getTransactionCritariaRequest);
            let config = {
                method: "get",
                url: `${baseUrl}/transaction_categories`,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            };
            axios(config)
                .then((response) => {
                // console.log("response" + JSON.stringify(response.data));
                  dispatch(getTransactionCritariaSuccess(response.data))
                })
                .catch((error) => {
                    //  const errorMsg = error;
                    dispatch(getTransactionCritariaFailure("Something went wrong!! Please try again!!"));
                });
        }
    }
    