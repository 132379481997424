import { SET_MERCHANDISE_VARIENT, SET_MERCHANDISE_VARIENT_IMG, SET_MERCHANDISE_VARIENT_OBJ, SET_MERCHANDISE_VARIENT_TERM_IMG } from "./merchandiseVarientTypes";

export const setMerchandiseVariants = (data) => {
    return {
        type: SET_MERCHANDISE_VARIENT,
        payload: data,
    };
};

export const setMerchandiseVariantsObj = (data) => {
    return {
        type: SET_MERCHANDISE_VARIENT_OBJ,
        payload: data,
    };
};

export const setMerchandiseVariantTerm = (data) => {
    return {
        type: SET_MERCHANDISE_VARIENT_TERM_IMG,
        payload: data,
    };
};

export const setMerchandiseVariantImages = (data) => {
    return {
        type: SET_MERCHANDISE_VARIENT_IMG,
        payload: data,
    };
};