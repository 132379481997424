import {
  PUT_SUPP_MER_LIST_REQUEST,
  PUT_SUPP_MER_LIST_SUCCESS,
  PUT_SUPP_MER_LIST_FAILURE,
} from "./SuppMerchandiseType";

import { baseUrl, getToken } from "../../../../utils/prodURL";
import axios from "axios";

// TO POST SUPPLIER MERCHANDISE MAPPED DATA
export const putSupplierMerchandiseRequest = () => {
  return {
    type: PUT_SUPP_MER_LIST_REQUEST,
  };
};

export const putSupplierMerchandiseSuccess = (data) => {
  return {
    type: PUT_SUPP_MER_LIST_SUCCESS,
    payload: data,
  };
};

export const putSupplierMerchandiseFailure = (error) => {
  return {
    type: PUT_SUPP_MER_LIST_FAILURE,
    payload: error,
  };
};

export const putSupplierMerchandiseMappingProd = (
  selectedProducts,
  supplierMerchandiseMappingSuccess,
  apiCallFailed
) => {
  return (dispatch) => {
    dispatch(putSupplierMerchandiseRequest);

    let payload = [];
    // eslint-disable-next-line array-callback-return
    selectedProducts.map((item) => {
      let obj = {
        mapped_for: item?.product?.product?.product_variants_flag
          ? "merchandise_variant"
          : "merchandise",
        merchandise_id: item.product.productId,
        variant_id: item.product.variantId,
        supplier_list: [
          {
            supplier_id: item.supplier.id,
            supplier_sku: item.supplierSKU,
            supplier_price: item.supplierPrice,
          },
        ],
        active_flag: true,
      };

      payload.push(obj);
    });
    let config = {
      method: "post",
      url: `${baseUrl}/v1/merchandise/supplier/mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        console.log("res ", response);
        if (response?.status === 200) {
          dispatch(putSupplierMerchandiseSuccess(response.data));
          supplierMerchandiseMappingSuccess();
        } else {
          apiCallFailed()
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          putSupplierMerchandiseFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};
