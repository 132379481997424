import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import applePhones from "../../assests/img/apple_colored.png";
import Button from "../../components/Button/Button";
import BackButton from "../Button/BackButton";

const MappingVariants = () => {
  const history = useNavigate();
  const location = useLocation();

  const [dropdown, setDropdown] = useState({
    supplierName: "",
  });
  const [products, setProducts] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let arr = [{product: location.state.productDetails}]
    location.state.selectedSupplier.map((supplierItem) => {
      arr && arr.map((item, i) => {
        item.product = location.state.productDetails
        item.supplier = supplierItem
      })
    })

    setProducts(arr);
  }, []);

  const handleChangeValue = (item, type) => {

    setDropdown({ ...dropdown, [type]: item });
  };


  const goBack = () => {
    history("/supplier-mapping");
  };

  const goToNextStep = () => {
    history("/supplier-mapping-sku", {
      state: {
        selectedSupplier: location.state.selectedSupplier,
        productDetails: location.state.productDetails
      }
    });
  };


  return (
    <div className="d-flex flex-column justify-content-between gap-3 py-4 pb-3 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">Supplier mapping</span>
        </div>
      </div>

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 gap-3">
        <div className="card-body d-flex justify-content-between flex-row">
          <div className="d-flex gap-3 my-2">
            <Tag />
            <span className="font-20 font-bold">Mapping variants</span>
          </div>
          <div>
          <BackButton onClick={() => history(-1)} />
        </div>
        </div>

        <table responsive="xl">
          <thead>
            <tr>
              <th>
                <div className="font-20 font-bold mx-4">Name</div>
              </th>
              <th>
                <div className="font-20 font-bold">Product ID</div>
              </th>
              <th>
                <div className="font-20 font-bold">Supplier</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {products && products.map((item, index) => (
              <tr className="bg-light" key={index}>
                <td className="py-2">
                  <div className="d-flex flex-row align-items-center ">
                    <span className="mx-3"></span>
                    <img
                      src={applePhones}
                      alt="not found"
                      className="img-fluid "
                    />
                    <div className="d-flex flex-column align-items-start mx-3">
                      <div className="font-bold font-15">{item?.product.product_title[0].title}</div>
                      {/* <div className="font-12 text-muted">Red | 128 GB</div> */}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="text-muted">{item?.product.id}</div>
                </td>
                <td>
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="d-flex">
                      <div className="btn-group">
                        <div
                          className="btn  border border-2 rounded-card-7 dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          {item.supplier ? item?.supplier?.partner_name : "Select"}
                        </div>

                        <ul className="dropdown-menu rounded-card-7 dropdown-menu-end">
                          {location?.state?.selectedSupplier &&
                            location?.state?.selectedSupplier?.map((item, i) => (
                              <li
                                key={i}
                                onClick={() =>
                                  handleChangeValue(item, "supplierName")
                                }
                              >
                                <div className="dropdown-item cursor">
                                  {item.partner_name}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
      <div className="d-flex">
        <Button
          type="primary"
          text="Save and Next"
          onClick={() => goToNextStep()}
        />
      </div>
    </div>
  );
};

export default MappingVariants;
