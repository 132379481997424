import React, { useEffect } from "react";
import MerchandiseCategory from "../../components/Merchandise/MerchandiseCategory";
// import CardPreview2 from "../../components/Merchandise/CardPreview2";

const AddMerchantAttributes = (props) => {


    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="d-flex flex-column justify-content-start py-2 bg-light">
            <div className="d-flex flex-row mt-2 gap-2">
                <div className="width-65" >
                    <MerchandiseCategory lang={props?.lang} />
                </div>
                {/* <div className="width-35" >
                  <CardPreview2/>
                </div> */}

            </div>

        </div>
    );
};

export default AddMerchantAttributes;
