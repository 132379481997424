import React from "react";

const SmallToast = (props) => {
  const { message, data } = props;
  return (
    <div id="toast" className="d-flex flex-row">
      <div className="checkicon">
        <i className="fas fa-check-square"></i>
      </div>
      <div>
        <span className="font-bold">{message}</span> :<span>{data}</span>
      </div>
    </div>
  );
};

export default SmallToast;
