import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getStoreList } from "../../../Redux/CreateStore/createStoreAction";


const StoreReports = () => {
  const history = useNavigate();
  const dispatch = useDispatch()

  const createStore = useSelector((state) => state.createStore?.createStore);


  const [dropDown, setDropDown] = useState({
    reportType:"",
    selectStore: "",
  });

const [error, setError] = useState({
    reportType:"",
    selectStore: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getStoreList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeReportType = (e) => {
    setDropDown({ ...dropDown, reportType: e });
  };

  const handleChangeStoreList = (e) => {
    setDropDown({ ...dropDown, selectStore:e });
    dispatch(getStoreList(e));
  };

  const validate = () => {
    if (!dropDown.reportType) {
      setError({ reportType: "Please select report type" });
      return false;
    }else if (!dropDown.selectStore) {
        setError({ selectStore: "Please select store" });
        return false;
    }
    return true;
  };


  const submit = () => {
    // let obj = {
    //     brandName: fields.brandName,
    //     brandType: brandObj.brandType,
    //     brandCategory:brandObj.brandCategory
    // }

    let val = validate();
    if (val) {
      history("");
    }
  };

  const goToHome =()=>{
    history("/epos/ehadaya-interface");
   }

  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goToHome()} className="cursor" />
          <span className="font-bold font-32 my-2">Reports</span>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between gap-3">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                Select report type<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <Select
                noOptionsMessage={() => "Not found"}
                placeholder="Select report type"
                // getOptionLabel={(giftcardsState) =>
                //   giftcardsState?.product_title[0]?.title
                // }
                // options={fields.giftCardNumber}
                styles={CUSTOM_DROPDOWN_STYLE}
                value={dropDown.reportType}
                onChange={handleChangeReportType}
                isClearable
              />
              {!dropDown.reportType && error.reportType ? (
                <label className="text-error">{error.reportType}</label>
              ) : null}
            </div>
          </div>

          <div className="d-flex flex-column gap-4 mt-4 ">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                Select store
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="d-flex flex-column gap-2">
             <div>
               <Select
                  noOptionsMessage={() => "Not found"}
                  placeholder="Select store from store list"
                //   getOptionLabel={(createStore) =>
                //     createStore?.store_name
                //   }
                //   options={createStore}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={dropDown?.selectStore}
                  onChange={handleChangeStoreList}
                  isClearable
                />
              </div>
               {!dropDown.selectStore && error.selectStore ? (
                <label className="text-error">{error.selectStore}</label>
              ) : null}
              
          </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex py-1">
        <Button onClick={submit} text="Submit" type="primary" />
      </div>
    </div>
  );
};

export default StoreReports;
