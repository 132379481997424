import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import { fetchPartnerCategory } from "../../Redux/Partner/partnerAction";
import CreateClient from "../../components/ReportsAnalytics/CreateClient";

const ClientCreation = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCountriesList());
    dispatch(fetchPartnerCategory());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex align-items-center pb-2 gap-3">
          <span className="font-bold font-32 my-2">Create Client</span>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-start gap-2">
        <CreateClient />
      </div>
    </div>
  );
};

export default ClientCreation;
