export const CUSTOM_DROPDOWN_STYLE = {
  // option: (provided, state) => ({
  //   ...provided,
  //   color: state.isSelected ? "black" : "black",
  //   background:state.isSelected ? 0 : 0,

  // }),
  control: (base, state) => ({
    ...base,
    cursor: 'pointer',
    border: "2px solid #dee2e6!important",
    borderRadius: "0.7rem",
    padding: "0.1rem",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};