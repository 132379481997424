export const FETCH_ALL_BRAND_CATEGORY_REQUEST = "FETCH_ALL_BRAND_CATEGORY_REQUEST"
export const FETCH_ALL_BRAND_CATEGORY_SUCCESS = "FETCH_ALL_BRAND_CATEGORY_SUCCESS"
export const FETCH_ALL_BRAND_CATEGORY_FAILURE = "FETCH_ALL_BRAND_CATEGORY_FAILURE"


export const PUT_BRAND_CATEGORY_REQUEST = "PUT_BRAND_CATEGORY_REQUEST"
export const PUT_BRAND_CATEGORY_SUCCESS = "PUT_BRAND_CATEGORY_SUCCESS"
export const PUT_BRAND_CATEGORY_FAILURE = "PUT_BRAND_CATEGORY_FAILURE"

export const PUT_BRAND_CATEGORY_ID_REQUEST = "PUT_BRAND_CATEGORY_ID_REQUEST"
export const PUT_BRAND_CATEGORY_ID_SUCCESS = "PUT_BRAND_CATEGORY_ID_SUCCESS"
export const PUT_BRAND_CATEGORY_ID_FAILURE = "PUT_BRAND_CATEGORY_ID_FAILURE"

export const PATCH_BRAND_CATEGORY_REQUEST = "PATCH_BRAND_CATEGORY_REQUEST"
export const PATCH_BRAND_CATEGORY_SUCCESS = "PATCH_BRAND_CATEGORY_SUCCESS"
export const PATCH_BRAND_CATEGORY_FAILURE = "PATCH_BRAND_CATEGORY_FAILURE"

export const BRAND_CATEGORY_FILTER_DATA = "BRAND_CATEGORY_FILTER_DATA"
export const CLEAR_BRAND_CATEGORY_LIST = "CLEAR_BRAND_CATEGORY_LIST"