import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import InputField from "../InputField/InputField";

const Info = (props) => {
  const { selectedGiftcard } = props;
  // console.log("selectedGiftcard ", selectedGiftcard);
  const [fields, setFields] = useState({
    giftCardNumber: "",
    redemptionAmount: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("selectedGiftcard INFO ", selectedGiftcard?.giftcard)
  },[selectedGiftcard])

  const changeHandler = (e) => {
    const value = e.target.value;
    setFields({ ...fields, [e.target.name]: value });
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">Info</span>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center gap-3"></div>
      <div className="d-flex flex-column gap-4 mt-4 ">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">Product ID</span>
          </div>
          <div className="d-flex flex-column gap-1">
            <InputField
              name="productID"
              onChange={(e) => changeHandler(e)}
              value={selectedGiftcard && selectedGiftcard?.giftcard.id ? selectedGiftcard?.giftcard.id : ""}
              disabled={true}
              placeholder="Giftcard id"
            />
          </div>
        </div>

        <div className="d-flex flex-column gap-4  ">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">Currency</span>
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="currency"
                onChange={(e) => changeHandler(e)}
                value={selectedGiftcard?.giftcard?.country?.currency ? selectedGiftcard?.giftcard?.country?.currency : ""}
                disabled={true}
                placeholder="Giftcard currency"
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-4  ">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">Card type</span>
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="cardType"
                onChange={(e) => changeHandler(e)}
                value={selectedGiftcard?.giftcard?.registered_by_id ? selectedGiftcard?.giftcard?.registered_by_id : ""}
                disabled={true}
                placeholder="Giftcard card type"
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-4  ">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">Activation required</span>
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="activationRequired"
                onChange={(e) => changeHandler(e)}
                value={selectedGiftcard?.giftcard?.active_flag ? selectedGiftcard?.giftcard?.active_flag : ""}
                disabled={true}
                placeholder="Giftcard active/deactivate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
