import {
  SET_ALL_SELECTED_MERTCHANDISE_PROD,
  SET_NO_SUPPLIER_PRODUCT,
  SET_SELECTED_GIFTCARD, SET_SELECTED_MERTCHANDISE, SET_SELECTED_SUPPLIER
} from "./giftcardSuppMappingType";

const initialSupplierState = {
  loading: false,
  error: "",
  selectedGiftCardList: null,
  selectedSupplier: "",
  selectedMerchandiseList: null,
  noMappSupplierProduct: null,
  marcahndiseSelectClientProduct: null
};

const giftcardSuppMappingReducer = (state = initialSupplierState, action) => {
  switch (action.type) {
    case SET_SELECTED_GIFTCARD:
      return {
        ...state,
        loading: false,
        error: "",
        selectedGiftCardList: action.payload,
      };
    case SET_SELECTED_SUPPLIER:
      return {
        ...state,
        loading: false,
        error: "",
        selectedSupplier: action.payload,
      };
    case SET_SELECTED_MERTCHANDISE:
      return {
        ...state,
        loading: false,
        error: "",
        selectedMerchandiseList: action.payload,
      };
    case SET_NO_SUPPLIER_PRODUCT:
      return {
        ...state,
        loading: false,
        error: "",
        noMappSupplierProduct: action.payload,
      };
    case SET_ALL_SELECTED_MERTCHANDISE_PROD:
      return {
        ...state,
        loading: false,
        error: "",
        marcahndiseSelectClientProduct: action.payload,
      };

    default:
      return state;
  }
};
export default giftcardSuppMappingReducer;
