/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import {
  clearOrderList,
  getOrdersProductByTypes,
} from "../../Redux/Order/orderAction";
import {
  DataSortingHandler,
  momentdateTimeFormater,
} from "../../utils/constantFunction";
import { strFirstLetterCap } from "../../utils/constantFunction";
import { OrderFilter, SORT_TYPE } from "../../utils/ConstantData";
import PulseLoader from "../../components/Loader/PulseLoader";
import { storeOrderFilterType } from "../../Redux/Order/orderAction";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoaders from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import SearcBar from "../../components/InputField/SearcBar";
import RefreshButton from "../../components/Button/RefreshButton";

const OrderManagement = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // redux stores
  const allOrdersList = useSelector(
    (state) => state?.allOrderList?.allOrdersList
  );
  console.log("allOrdersList ", allOrdersList);

  const orderFilterType = useSelector(
    (state) => state?.allOrderList?.orderFilterType
  );
  // infinite scroll
  const hasmore = useSelector((state) => state?.allOrderList?.next_iteration);
  const loading = useSelector((state) => state?.allOrderList?.loading);

  const [skip, setskip] = useState(
    allOrdersList?.length === 0 ? -10 : allOrdersList?.length - 10
  );
  const [limit, setlimit] = useState(10);
  // for search bar
  const [searchTerm, setSearchTerm] = useState("");
  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const [dropdownObj, setDropdownObj] = useState({
    orders: orderFilterType ? orderFilterType : { id: 1, name: "Giftcard" },
  });

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  const handleGetOrderByType = (item, type) => {
    setDropdownObj({ ...dropdownObj, [type]: item });
    dispatch(storeOrderFilterType(item));
    dispatch(getOrdersProductByTypes(0, limit, allOrdersList, item, true));
    setskip(0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (allOrdersList?.length === 0) {
      fetchdata(skip);
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(
        getOrdersProductByTypes(
          skip + limit,
          limit,
          allOrdersList,
          dropdownObj.orders
        )
      );
      setskip(skip + limit);
    }, 100);
  };

  const goToNext = (id, item) => {
    history("/order-management/gift-card", {
      state: {
        selectedOrder: item,
      },
    });
  };

  const refreshData = () => {
    dispatch(clearOrderList());
    setskip(0);
    setlimit(10);
    dispatch(getOrdersProductByTypes(0, 10, [], dropdownObj.orders));
  };

  return loading === true && allOrdersList?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-start py-3 gap-3 px-4 bg-light">
      <div className="d-flex">
        <span className="font-40 font-bold mb-2">{t("Order Management")}</span>
      </div>

      <div className="width-100 ">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
          <div className="card-body d-flex flex-column  bg-white">
            <div className="d-flex flex-row justify-content-between align-item-center">
              <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                <div className="p-2 flex-grow-1">
                  <div className="d-flex gap-3 my-2 mx-3 align-items-center">
                    <Tag />
                    <span className="font-20 font-bold">{t("All Orders")}</span>
                    <SearcBar
                      type="text"
                      name="search"
                      lang={props?.lang}
                      placeholder={t("Search by client and org name")}
                      onChange={searchHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                <div className="d-flex flex-column gap-2 mx-2">
                  <div className="btn-group w-10">
                    <div
                      className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center"
                      data-bs-toggle="dropdown"
                    >
                      <span className="text-muted">
                        {dropdownObj.orders
                          ? dropdownObj.orders.name
                          : "Filter orders"}
                      </span>
                    </div>
                    <ul
                      className={`dropdown-menu rounded-card-7 dropdown-menu-end ${
                        props?.lang === "ar" ? "dropdown-menu-ar" : ""
                      }`}
                    >
                      {OrderFilter &&
                        OrderFilter.map((item, i) => (
                          <li
                            key={i}
                            onClick={() => handleGetOrderByType(item, "orders")}
                          >
                            <div className="dropdown-item cursor">
                              {item.name}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div>
                <RefreshButton onClick={refreshData} />
              </div> */}
            </div>
            <InfiniteScroll
              dataLength={allOrdersList?.length || 0}
              next={fetchdata}
              hasMore={hasmore}
              loader={
                <div className="d-flex justify-content-center">
                  {" "}
                  <PulseLoaders
                    color={"#594190"}
                    loading={loading}
                    size={10}
                  />{" "}
                </div>
              }
            >
              <div className="row px-5 py-3">
                <table responsive="xl">
                  <tbody>
                    <tr className="card-body cursor">
                      <td
                        className={`card-body gap-1 font-13 font-bold text-muted ${
                          tableHeaderName === "created_at"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "created_at",
                            setTableHeaderName,
                            allOrdersList,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Date")}
                      </td>
                      <td
                        className={`card-body gap-1 font-13 font-bold text-muted ${
                          tableHeaderName === "id" ? "ascending" : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "id",
                            setTableHeaderName,
                            allOrdersList,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Order id")}
                      </td>
                      <td
                        className={`card-body gap-1 font-13 font-bold text-muted ${
                          tableHeaderName === "default_title"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "default_title",
                            setTableHeaderName,
                            allOrdersList,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Product")}
                        <br /> {t("details")}
                      </td>
                      <td
                        className={`card-body gap-1 font-13 font-bold text-muted ${
                          tableHeaderName === "partner_name"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "partner_name",
                            setTableHeaderName,
                            allOrdersList,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Client")}/
                        <br />
                        {t("Organization")}
                      </td>
                      <td
                        className={`card-body gap-1 font-13 font-bold text-muted ${
                          tableHeaderName === "product_type"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "product_type",
                            setTableHeaderName,
                            allOrdersList,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Product")}
                        <br /> {t("type")}
                      </td>
                      <td
                        className={`card-body gap-1 font-13 font-bold text-muted ${
                          tableHeaderName === "quantity"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "quantity",
                            setTableHeaderName,
                            allOrdersList,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Quantity")}
                      </td>
                      <td
                        className={`card-body gap-1 font-13 font-bold text-muted ${
                          tableHeaderName === "order_status"
                            ? "ascending"
                            : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "order_status",
                            setTableHeaderName,
                            allOrdersList,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Status")}
                      </td>
                    </tr>

                    {allOrdersList &&
                      allOrdersList
                        .filter((item) => {
                          if (searchTerm === "") {
                            return item;
                          } else if (
                            (item?.product_type === "merchandise"
                              ? item?.merchandise?.default_title
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              : item?.giftcard?.default_title
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())) ||
                            (item?.order_by === "client"
                              ? item?.client?.partner_name
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              : item?.organization.partner_name
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase()))
                          ) {
                            return item;
                          }
                        })
                        .map((item) => (
                          <tr
                            className="card-body hover-row"
                            key={item.id}
                            onClick={() => goToNext(item.id, item)}
                          >
                            <td className="card-body gap-1">
                              <span
                                className="font-15 font-bold text-dark d-inline-block text-truncate cursor"
                                title={momentdateTimeFormater(item?.created_at)}
                                style={{ maxWidth: "93px" }}
                              >
                                {momentdateTimeFormater(item?.created_at)}
                              </span>
                            </td>
                            <td className="card-body gap-1">
                              <div className="d-flex flex-row justify-content-center">
                                <span
                                  className="font-15 font-bold text-dark 
                          d-inline-block text-truncate cursor"
                                  title={item?.id}
                                  style={{ maxWidth: "80px" }}
                                >
                                  {item?.id}
                                </span>
                              </div>
                            </td>
                            <td className="card-body gap-1">
                              <div className="d-flex flex-column">
                                <span
                                  className="font-15 font-bold text-dark d-inline-block text-truncate cursor"
                                  title={
                                    item?.product_type === "merchandise"
                                      ? item?.merchandise?.default_title
                                      : item?.giftcard?.default_title
                                  }
                                  style={{ maxWidth: "80px" }}
                                >
                                  {item?.product_type === "merchandise"
                                    ? item?.merchandise?.default_title
                                    : item?.giftcard?.default_title}
                                </span>
                                <span className="font-12 font-bolder text-muted ">
                                  <span
                                    className="d-inline-block text-truncate cursor"
                                    title={
                                      item?.product_type === "giftcard" &&
                                      item?.giftcard &&
                                      item?.product_variant_id &&
                                      item?.giftcard?.denomination &&
                                      item?.giftcard?.denomination?.currency +
                                        "-" +
                                        item?.giftcard?.denomination
                                          ?.denomination_value
                                    }
                                    style={{ maxWidth: "80px" }}
                                  >
                                    {item?.product_type === "giftcard" &&
                                      item?.giftcard &&
                                      item?.product_variant_id &&
                                      item?.giftcard?.denomination &&
                                      item?.giftcard?.denomination?.currency +
                                        "-" +
                                        item?.giftcard?.denomination
                                          ?.denomination_value}
                                  </span>
                                  <span
                                    className="d-inline-block text-truncate cursor"
                                    title={
                                      item?.product_type === "merchandise" &&
                                      item?.merchandise &&
                                      item?.product_variant_id &&
                                      item?.merchandise
                                        ?.product_variants_flag &&
                                      item?.merchandise?.variant &&
                                      item?.merchandise?.variant
                                        ?.variant_values &&
                                      item?.merchandise?.variant?.variant_values.join(
                                        "|"
                                      )
                                    }
                                    style={{ maxWidth: "80px" }}
                                  >
                                    {item?.product_type === "merchandise" &&
                                      item?.merchandise &&
                                      item?.product_variant_id &&
                                      item?.merchandise
                                        ?.product_variants_flag &&
                                      item?.merchandise?.variant &&
                                      item?.merchandise?.variant
                                        ?.variant_values &&
                                      item?.merchandise?.variant?.variant_values.join(
                                        "|"
                                      )}
                                  </span>
                                </span>
                              </div>
                            </td>
                            <td className="card-body gap-1">
                              <div className="d-flex flex-column">
                                <span
                                  className="font-15 font-bold text-dark d-inline-block text-truncate cursor"
                                  title={
                                    item?.order_by === "client"
                                      ? item?.client?.partner_name
                                      : item?.organization.partner_name
                                  }
                                  style={{ maxWidth: "80px" }}
                                >
                                  {strFirstLetterCap(
                                    item?.order_by === "client"
                                      ? item?.client?.partner_name
                                      : item?.organization.partner_name
                                  )}
                                </span>
                                <span className="font-13 font-bolder text-muted">
                                  {item?.order_by === "client"
                                    ? "Client"
                                    : "Organization"}
                                </span>
                              </div>
                            </td>
                            <td className="card-body gap-1">
                              <span className="font-15 font-bold text-dark ">
                                {strFirstLetterCap(item?.product_type)}
                              </span>
                            </td>
                            <td className="card-body gap-1">
                              <div className="d-flex justify-content-center">
                                <span className="font-15 font-bold text-dark ">
                                  {item?.quantity}
                                </span>
                              </div>
                            </td>
                            <td className="card-body gap-1">
                              <span className="font-15 font-bold text-dark ">
                                {item?.order_status === "unable_to_fulfill" ? (
                                  <span className="font-12 font-bold text-dark ">
                                    {t("Unable to fulfill")}
                                  </span>
                                ) : item?.order_status === "in_progress" ? (
                                  <span className="font-15 font-bold text-dark ">
                                    {t("In progress")}
                                  </span>
                                ) : (
                                  strFirstLetterCap(item?.order_status)
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                {!allOrdersList?.length && (
                  <div className="d-flex flex-row justify-content-center py-4">
                    <span className="font-20 font-bold text-error">
                      {t("Orders Not Found")}
                    </span>
                  </div>
                )}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderManagement;
