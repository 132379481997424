import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import { ReactComponent as GreenUpArrow } from "../../assests/svg/Product/Green_UpArrow.svg";
import { ReactComponent as BlueIcon } from "../../assests/svg/Product/Blue_Icon.svg";
import { ReactComponent as RoundedArrow } from "../../assests/svg/Product/Rounded_Arrow.svg";
import { ReactComponent as BackwordArrow } from "../../assests/svg/Product/Backword_Arrow.svg";
import MyntraImg from "../../assests/img/ImageGift.png";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import Toasts from "../Toasts/Toasts";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";

const Products = (props) => {
  const history = useNavigate();
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToGiftCardDetails = () => {
    history("/reports/giftcard-details");
  };

  const products = [
    // {
    //   id: 0,
    //   Status: "In stock",
    //   Price: "$10",
    //   Sales: "$3,200",
    //   SalePercent: "55.8%",
    //   Totalsold: "48k",
    //   Type: "Reloadable",
    // },
    // {
    //   id: 1,
    //   Status: "In stock",
    //   Price: "$10",
    //   Sales: "$3,200",
    //   SalePercent: "55.8%",
    //   Totalsold: "48k",
    //   Type: "Reloadable",
    // },
    // {
    //   id: 2,
    //   Status: "In stock",
    //   Price: "$10",
    //   Sales: "$3,200",
    //   SalePercent: "55.8%",
    //   Totalsold: "48k",
    //   Type: "Fixed",
    // },
    // {
    //   id: 3,
    //   Status: "In stock",
    //   Price: "$10",
    //   Sales: "$3,200",
    //   SalePercent: "55.8%",
    //   Totalsold: "48k",
    //   Type: "Reloadable",
    // },
    // {
    //   id: 4,
    //   Status: "In stock",
    //   Price: "$10",
    //   Sales: "$3,200",
    //   SalePercent: "55.8%",
    //   Totalsold: "48k",
    //   Type: "Reloadable",
    // },
    // {
    //   id: 5,
    //   Status: "In stock",
    //   Price: "$10",
    //   Sales: "$3,200",
    //   SalePercent: "55.8%",
    //   Totalsold: "48k",
    //   Type: "Reloadable",
    // },
  ];

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
      <div className="d-flex flex-row justify-content-between py-2 align-items-center">
        <div className="d-flex flex-row ">
          <div className="d-flex gap-3 my-2 mx-3 align-items-center">
            <Tag />
            <span className="font-20 font-bold">Products</span>
            <div className="d-flex flex-row gap-1 align-items-center">
              <div className="p-2">
                <Button type="secondary" text="Gift cards" />
              </div>
              <span className="align font-bold text-muted">Merchandise</span>
            </div>
          </div>
        </div>
        <SearcBar
          type="text"
          name="search"
          lang={props?.lang}
          placeholder={t("Search by name")}
        />

        {/*  <div className="p-2">
          <Button
            type="primary"
            text="Download CSV"
            className="rounded-6 my-4 mt-6 fw-bolder"
            onClick={() => toggleShowToast()}
          />
        </div>*/}
      </div>

      <div className="card-body d-flex flex-column justify-content-between align-item-center gap-5">
        <table responsive="xl">
          <tbody>
            <tr className="border-bottom">
              <td className="text-muted" colSpan={3}>
                <div className="d-flex flex-row justify-content-start">
                  <span className="mx-3">Product</span>
                </div>
              </td>
              <td className="text-muted"> Status</td>
              <td className="text-muted"> Price</td>
              <td className="text-muted" colSpan={2}>
                {" "}
                Sales
              </td>
              <td className="text-muted"> Total sold</td>
              <td className="text-muted"> Type</td>
            </tr>
            {products &&
              products.map((item) => (
                <tr
                  className="card-body gap-3 border-bottom hover-row"
                  key={item.id}
                  onClick={() => goToGiftCardDetails()}
                >
                  <td colSpan={3}>
                    <div className="d-flex flex-row justify-content-start align-items-center py-2">
                      <div className="rounded-3">
                        <img
                          src={MyntraImg}
                          alt="not found"
                          className="img-fluid width-75"
                        />
                      </div>
                      <div className="d-flex flex-column justify-content-start ">
                        <span className="font-15 font-boldest text-dark ">
                          Myntra Gift card
                        </span>
                        <span className="font-13 font-bold text-muted">
                          by Myntra Fashion
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="font-15 font-bold text-success bg-04 px-1 rounded-3">
                      {item.Status}
                    </span>
                  </td>
                  <td>
                    <span className="font-15 font-boldest text-dark">
                      {item.Price}
                    </span>
                  </td>
                  <td colSpan={2}>
                    <div className="d-flex flex-row justify-content-start align-items-center gap-1">
                      <span className="font-15 font-bolder text-dark bg-dark bg-opacity-25 rounded-3 px-1">
                        {item.Sales}
                      </span>
                      <div className="d-flex flex-row justify-content-start align-items-center gap-1">
                        <GreenUpArrow />
                        <span className="font-13 font-bolder text-success bg-opacity-25 rounded-3">
                          {item.SalePercent}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="font-15 font-bolder text-dark bg-dark bg-opacity-25 rounded-3 gap-4 px-1  ">
                      {item.Totalsold}
                    </span>
                    <span className="mx-1">
                      <BlueIcon />
                    </span>
                  </td>
                  <td>
                    <span className="font-15 font-bolder text-dark bg-dark bg-opacity-25 rounded-3 px-1  ">
                      {item.Type}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {!products.length && (
          <div className="d-flex flex-row justify-content-center py-3">
            <span className="font-20 font-bold text-error">
              No product details found
            </span>
          </div>
        )}
      </div>

      <div className="d-flex flex-row  justify-content-center align-item-center gap-3">
        <div className="d-flex flex-row  justify-content-center py-2">
          <BackwordArrow />
        </div>
        <RoundedArrow />
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default Products;
