/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Toasts from "../Toasts/Toasts";
import MerchandiseBulkUpload from "./MerchandiseBulkUpload";
import GiftcardBulkUpload from "./GiftcardBulkUpload";

const BulkUploadComp = (props) => {
  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-column gap-3 position-relative bg-light">
      <GiftcardBulkUpload />

      <MerchandiseBulkUpload />


      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
        // warningMsg={
        //   fileUpload.length === 0
        //     ? "Please upload a file"
        //     : ManualInventoryErrors?.errors
        //     ? manualInventoryPostError.map((item) => item)
        //     : `${"Inventory uploaded successfully."}`
        // }
        // imageType={
        //   ManualInventoryErrors?.errors
        //     ? "warning"
        //     : fileUpload.length === 0
        //     ? "warning"
        //     : "success"
        // }
        />
      </div>
    </div>
  );
};

export default BulkUploadComp;
