import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import { ReactComponent as UpArrow } from "../../assests/svg/Frame 87.svg";
import applePhones from "../../assests/img/apple_colored.png";
import Button from "../../components/Button/Button";
import InputGroupTxt from "../../components/InputField/InputGroupTxt";

const MappingMerchandiseWithPartner = () => {
  const history = useNavigate();

  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState({
    ch1: false,
    ch2: false,
    ch3: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goBack = () => {
    history("/merchandise-dashboard");
  };

  /* #### CHECKS OR UNCHECKS ALL FROM SELECT ALL CLICK #### */

  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);

  const products = [
    { id: 0, Client: "Client#1", Percent: "12.8%" },
    { id: 0, Client: "Client#2", Percent: "12.8%" },
    { id: 0, Client: "Client#3", Percent: "12.8%" },
    { id: 0, Client: "Client#4", Percent: "12.8%" },
    { id: 0, Client: "Client#5", Percent: "12.8%" },
    { id: 0, Client: "Client#6", Percent: "12.8%" },
  ];

  return (
    <div className="d-flex flex-column justify-content-start gap-3 py-4 pb-3 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32">Iphone 13</span>
        </div>
      </div>

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 w-100 py-3 px-4 gap-3 ">
        <div className="card-body d-flex justify-content-between flex-row ">
          {/*Card Header*/}
          <div className="d-flex gap-3 my-2">
            <Tag />
            <span className="font-20 font-bold">Iphone 13</span>
          </div>
          {/*Card Body*/}
        </div>

        <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
          <table responsive="xl">
            <tbody>
              <tr className="col">
                <td className="col-4">
                  <div className="d-flex flex-row justify-content-start text-dark gap-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkboxNoLabel"
                      value=""
                      aria-label="..."
                      onChange={(event) => selectAll(event.target.checked)}
                    // checked={checkedAll}
                    />
                    <span className="font-16 font-boldest text-dark">
                      Name
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-16 font-boldest text-dark">Client</span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-16 font-boldest text-dark">Suppliers</span>
                  </div>
                </td>
                <td className="col-4">
                  <div>
                    <span className="font-16 font-boldest text-dark">Selling Price</span>
                  </div>
                </td>
              </tr>
              {products.map((item) => (
                <tr key={item.id} className=" gap-2">
                  <td className="col-4">
                    <div className="d-flex flex-row justify-content-start align-items-center py-3 gap-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        aria-label="..."
                        onChange={(event) => selectAll(event.target.checked)}
                        checked={checkedAll}
                      />
                      <img
                        src={applePhones}
                        alt="not found"
                        className="img-fluid"
                      />
                      <div className="d-flex flex-column align-items-start">
                        <span className="font-bold text-dark font-15">I phone 13</span>
                        <span className="font-12 text-muted">Red | 128 GB</span>
                        <span className="font-12 text-muted">
                          ehid_iphonesexc#$35325
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="col-2">
                    <div className="d-flex">
                      <div className="btn-group">
                        <div className="btn  border border-2 rounded-card-7 dropdown-toggle" data-bs-toggle="dropdown">
                          Client 1
                        </div>
                        <ul className="dropdown-menu rounded-card-7 dropdown-menu-end">
                          <li><div className="dropdown-item">Client 1</div></li>
                          <li><div className="dropdown-item">Client 2</div></li>
                          <li><div className="dropdown-item">Client 3</div></li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td className="col-2">
                    <span className="font-14 font-bold text-muted">Supplier name</span>
                  </td>

                  <td className="col-4">
                    <div className="d-flex align-item-center justify-content-center gap-3">
                      <div className="d-flex justify-content-start">
                        <InputGroupTxt title={"$"} placeholder={"Enter price"} type="number" />
                      </div>

                      <div className="d-flex flex-column align-item-center">
                        <div className="d-flex flex-row align-item-center gap-1">
                          <UpArrow className="" />
                          <span className="font-13 font-bold brand-color-05">
                            {item.Percent}
                          </span>
                        </div>
                        <div className="font-13 font-bold text-muted">
                          +$100
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex">
        <Button
          type="primary"
          text="Save and Update"
          onClick={() => console.log()}
        />
      </div>
    </div>
  );
};

export default MappingMerchandiseWithPartner;
