import {
  GET_SAMPLE_MANAUL_INVENTORY_REQUEST,
  GET_SAMPLE_MANAUL_INVENTORY_SUCCESS,
  GET_SAMPLE_MANAUL_INVENTORY_FAILURE,
  POST_MANAUL_INVENTORY_SUCCESS,
  GET_MANAUL_INVENTORY_REQUEST,
  GET_MANAUL_INVENTORY_SUCCESS,
  GET_MANAUL_INVENTORY_FAILURE,
  POST_MANAUL_INVENTORY_SUCCESS_ERROR,
  CLEAR_MANAUL_INVENTORY_SUCCESS_ERROR
} from "./manualInventoryType";

const initialManualInventoryState = {
  loading: false,
  manualInventory: [],
  manualInventoryResponse: null,
  manualInventoryList: [],
  manualInventoryErrors: [],
  error: "",
};

const manualInventoryReducer = (
  state = initialManualInventoryState,
  action
) => {
  switch (action.type) {
    case GET_SAMPLE_MANAUL_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SAMPLE_MANAUL_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        manualInventory: action.payload,
        error: "",
      };
    case GET_SAMPLE_MANAUL_INVENTORY_FAILURE:
      return {
        ...state,
        loading: false,
        manualInventory: [],
        error: action.payload,
      };
    case POST_MANAUL_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        manualInventory: [],
        manualInventoryResponse: action.payload,
        error: ''
      };
    case GET_MANAUL_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MANAUL_INVENTORY_SUCCESS: 
    return {
      ...state,
      loading: false,
      manualInventory: [],
      manualInventoryList: action.payload,
      manualInventoryResponse: null,
      error: ''
    }
    case GET_MANAUL_INVENTORY_FAILURE:
      return {
        ...state,
        loading: false,
        manualInventory: [],
        manualInventoryList: [],
        manualInventoryResponse: null,
        error: action.payload
      }
    case POST_MANAUL_INVENTORY_SUCCESS_ERROR:
      return {
        ...state,
        loading: false,
        manualInventory: [],
        manualInventoryList: [],
        manualInventoryResponse: null,
        manualInventoryErrors: action.payload,
        error: ''
      }
      case CLEAR_MANAUL_INVENTORY_SUCCESS_ERROR:
        return {
          ...state,
          loading: false,
          manualInventory: [],
          manualInventoryList: [],
          manualInventoryResponse: null,
          manualInventoryErrors: [],
          error: ''
        }

      default: return state
  }
};

export default manualInventoryReducer
