import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import GoogleIncIcon from "../../../assests/img/GoogleInc.png";
import { ReactComponent as Tag } from "../../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as LinkIcon } from "../../../assests/svg/link-icon.svg";
import { ReactComponent as MailIcon } from "../../../assests/svg/mail.svg";
import { ReactComponent as Twitter } from "../../../assests/svg/twitter.svg";
import { ReactComponent as Instagram } from "../../../assests/svg/instagram.svg";
import { ReactComponent as Printerest } from "../../../assests/svg/printerest.svg";
import { ReactComponent as Facebook } from "../../../assests/svg/facebook.svg";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import { ReactComponent as GreenTag } from "../../../assests/svg/Tag/dark_green.svg";



const ClientGoogleInc = () => {
  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goBack = () => {
    history("");
  };

  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-3 px-4 bg-light">
      <div className="d-flex flex-column my-3">
        <div className="d-flex flex-row align-items-center gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-32 ">Client</span>
        </div>
        <span className="font-bolder font-14 text-muted ">
          Gift card will be send to the email ID as a password protected Excel
          Sheet.
        </span>
      </div>

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 pb-3 pt-1 w-100">
        <div className="d-flex flex-row gap-3 py-2  align-items-center">
          <div className="d-flex flex-grow-1">
            <div className="d-flex flex-column gap-3 mb-1  py-3  px-2 align-items-center">
              <div className="d-flex flex-row mx-2 gap-4 justify-content-between">
                <img
                  src={GoogleIncIcon}
                  alt="not found"
                  className="img-fluid rounded-3 "
                />

                <div>
                  <span className="font-22 font-boldest text-dark ">
                    Google Inc.
                  </span>
                  <br />
                  <span className="font-13 font-bold text-muted">
                    Client ID #54123
                  </span>
                </div>
                <div className="d-flex flex-row justify-content-between px-5 mx-2 py-2 gap-4">
                  <span className="">
                    <Twitter />
                  </span>
                  <span className="">
                    <Instagram />
                  </span>
                  <span className="">
                    <Printerest />
                  </span>
                  <span className="">
                    <Facebook />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-start gap-3 mb-1  mx-2 align-items-center">
            <div className="d-flex">
              <Button
                type="rounded"
                text="Edit Account +"
                className="rounded-5 my-4 mt-6 fw-bolder"
              />
            </div>
            <div className="">
              <Button
                type="primary"
                text="Delete"
                className="rounded-6 my-4 mt-6 fw-bold"
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between gap-3 py-3 px-3">
          <div className="d-flex ">
            <span className="font-14 font-bold text-muted">
              <LinkIcon /> Website:
            </span>
            <span className="font-14 font-bold">Repletech.com</span>
          </div>
          <span className="font-14 font-bold">
            <MailIcon /> fahey.designer@hadaya.net
          </span>
        </div>

        <div className="d-flex flex-row gap-4">
        <div className="d-flex gap-3 my-4 mx-2 align-items-center">
          <Tag />
          <span className="font-20 font-bold">
            FloatBalance:
            <span className="font-20 font-bold text-light-blue "> $512</span>
          </span>
        </div>

        <div className="d-flex gap-3 my-4 mx-2 align-items-center">
          <GreenTag />
          <span className="font-20 font-bold">
            FloatBalance:
            <span className="font-20 font-bold text-light-blue "> $500</span>
          </span>
        </div>
        </div>

      </div>

      <div className="d-flex">
        <Button
          type="primary"
          text="CheckOut"
          className="rounded-6 my-4 mt-6 fw-bold"
        />
      </div>
    </div>
  );
};

export default ClientGoogleInc;
