

import {GET_VAT_REQUEST,
    GET_VAT_SUCCESS,
    GET_VAT_FAILURE} from './vatType'

const initialVatState = {
    loading: false,
    vat: [],
    error: "",
  };
  
  const vatReducer = (state = initialVatState, action) => {
    switch (action.type) {
      case GET_VAT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_VAT_SUCCESS:
        return {
          ...state,
          loading: false,
          vat: action.payload,
          error: "",
        };
      case GET_VAT_FAILURE:
        return {
          ...state,
          loading: false,
          vat: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  export default vatReducer;
  