import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import { ReactComponent as FilterIcon } from "../../assests/svg/Filter_Icon.svg";
import UserDefaultPlaceHolder from "../../assests/img/default-user.png";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import DistGiftcardModel from "../Models/DistGiftcardModel";
import ModalComp from "../Models/ModalComp";

const MerchantUserSelect = (props) => {
  const {customerState}=props
  const history = useNavigate();
  const location = useLocation();
  // const customerState = useSelector((state) => state?.customers?.customer);

  const [visible, setVisible] = useState(10);

  const clientmappedGiftcardList = useSelector(
    (state) => state?.clientMapGcList?.clientMappedGcList
  );

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [searchTerm, setSearchTerm] = useState('');

  const searchHandler = (e) => {
    setSearchTerm(e.target.value)
  }
  // console.log(searchTerm)


  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goTonextStep = () => {
    if (!clientmappedGiftcardList?.length) {
      setShowModal(true);
      return;
    }

    history("/card-distribution", {
      state: {
        selectedClient: location.state.selectedClient,
        clientType: location.state.type,
      },
    });
  };

  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 10);
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="d-flex flex-row w-100  align-items-center">
          <div className="p-2 flex-grow-1">
            <div className="d-flex gap-3 my-2 mx-3 align-items-center">
              <Tag />
              <span className="font-20 font-bold">Customer</span>
              <span className="font-20 font-bold">
                <div className="Inputwithicon">
                  <div id="search">
                    <Searchicon />
                  </div>
                  <input
                    type="text"
                    className="input-custom w-100"
                    name="search"
                    placeholder="Search by name"
                    onChange={searchHandler}
                  />
                </div>
              </span>
            </div>
          </div>
          <div className="p-2">
            <Button type="secondary" text="All" />
          </div>
          <div className="p-2">
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle font-14 font-bold text-muted"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <Link className="dropdown-item" to={""}>
                    Client 1
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={""}>
                    Client 2
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={""}>
                    Client 3
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="p-2">
            <span className="my-2">
              <FilterIcon />
            </span>
          </div>
        </div>
        <div className="row px-5 py-2">
          <table responsive="xl">
            <tbody>
              <tr className="card-body gap-3 border-bottom py-5">
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Name
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Email
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Country
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  State
                </td>
              {/*  <td className="card-body gap-1 font-13 font-bold text-muted">
                  Client
                </td>
                <td className="card-body font-13 font-bold text-muted">
                  Total
                </td>*/}
              </tr>
              {customerState &&
                customerState?.slice(0, visible).filter((item) => {
                  if(searchTerm == ""){
                    return item
                  } else if(item?.partner_name.toLowerCase().includes(searchTerm.toLowerCase())){
                    return item
                  }
                }).map((item, index) => (
                  <tr
                    className="card-body gap-3 border-bottom hover-row"
                    key={item.id}
                    onClick={() => goTonextStep(item)}
                  >
                    <td className="card-body gap-1">
                      <div className="d-flex flex-row justify-content-start  gap-3">
                        <span className="d-flex">
                          <img
                            src={UserDefaultPlaceHolder}
                            alt="not found"
                            className="img-fluid"
                            // width={50}
                            // height={50}
                            style={{width:"50px",height:"50px",backgroundSize:"cover"}}
                          />
                        </span>
                        <span className="font-15 font-boldest text-dark ">
                          <span
                            className="font-15 font-boldest text-dark d-inline-block text-truncate"
                            style={{ maxWidth: "150px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={item.partner_name}
                          >
                            {/* Samsung Haley */}
                            {item.partner_name}
                          </span>
                          <br />
                          <span className="font-12 font-bolder text-muted">
                            {/* @username */}
                            {item.company_name}
                          </span>
                        </span>
                      </div>
                    </td>
                    <td className="card-body gap-1">
                      <div className="d-flex flex-row justify-content-between  gap-3">
                        <span
                          className="fs-light  text-muted "
                        >
                          {item.email}
                        </span>
                      </div>
                    </td>
                    <td className="card-body gap-1">
                      <span className="font-15 font-bold text-dark px-1">
                       {item.country_name?item.country_name:"No country"}
                      </span>
                    </td>
                  {/*  <td className="card-body gap-1">
                      <div className="d-flex flex-row justify-contents-between align-items-center">
                        <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                          {/* {item.Lifetime}
                          $384
                        </span>
                        <span className="font-13 font-bolder text-danger bg-opacity-25 rounded-3 ">
                          <span>
                            <RedDownArrow className="m-1" />
                          </span>
                          {/* {item.Percent}
                          12.8%
                        </span>
                      </div>
                    </td>*/}
                    <td className="card-body gap-1">
                      <span className="font-15 font-bold text-dark px-1">
                         {item.state_name?item.state_name:"No state"} 
                      
                      </span>
                    </td>
                  {/*  <td className="card-body gap-1">
                      <span className="font-15 font-bold text-dark px-1">
                        {/* {item.Total} 
                        16
                      </span>
                    </td>*/}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-row  justify-content-center align-item-center gap-3">
          <div className="d-flex">
            {visible < customerState.length && (
              <Button
                type="rounded"
                text="Load more"
                className="rounded-6 my-4 mt-6 "
                onClick={() => loadMore()}
              />
            )}
          </div>
        </div>
        <ModalComp
          size="md"
          showModal={showModal}
          // handleClose={handleClose}
          component={
            <DistGiftcardModel
              handleClose={handleClose}
              heading="No products mapped with this client, Do you want to map products."
              path='/clients-mapping-dashboard'
            />
          }
        />
      </div>
    </div>
  );
};

export default MerchantUserSelect;
