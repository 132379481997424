import { t } from "i18next";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CardiTunes } from "../../assests/svg/Dashboard/Card _design.svg"
import { ReactComponent as CardApple } from "../../assests/svg/Dashboard/Card_design_apple.svg"
import { ReactComponent as BlueTag } from "../../assests/svg/Tag/blue_tag.svg"
import Button from "../Button/Button";

const PopularGiftCards = () => {
    const history = useNavigate()

    const GiftCard = [
        // {
        //     name: "Gift Card",
        //     icon: CardApple
        // },
        // {
        //     name: "Gift Card 1",
        //     icon: CardiTunes
        // },
        // {
        //     name: "Gift Card 2",
        //     icon: CardApple
        // },
        // {
        //     name: "Gift Card 3",
        //     icon: CardiTunes
        // },
    ]
    return (
        <div>
            <div className="d-flex flex-column bg-white rounded-3 py-4 px-4 shadow-sm">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex gap-3">
                        <BlueTag />
                        <span className="font-20 font-bold">{t("Popular Gift Cards")}</span>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center my-4">
                    <span className="text-muted">{t("Products")}</span>
                    <span className="text-muted">{t("Total sale")}</span>
                </div>
                {GiftCard.map((data, i) =>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-4" key={i}>
                        <div className="d-flex flex-row align-items-center gap-2">
                            <div>
                                <data.icon />
                            </div>
                            <div className="d-flex flex-column ">
                                <span className="font-15 font-bold">{data.name}</span>
                                <span className="font-15 font-bold">Example</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column ">
                            <span className="font-15 font-bold">$2,455.82</span>
                            <span className="font-12 font-bold text-success bg-04 rounded-3 fit-content px-2 flex-end">Active</span>
                        </div>
                    </div>
                )}
                {!GiftCard.length && (
                    <div className="d-flex flex-row justify-content-center py-3">
                      <span className="font-20 font-bold text-error">
                        {t("Giftcards not found")}
                      </span>
                    </div>
                  )}
                <div>
                    <Button onClick={() => console.log()} text={t("All products")} type="rounded" />
                </div>
            </div>
            <div className="d-flex flex-column mt-2 gap-2">
                <div>
                    <Button onClick={() => history("/epos/select-store")} text={t("Redeem gift card")} type="square" />
                </div>
                <div>
                    <Button onClick={() => history("/epos/sell-card")} text={t("Sell gift card")} type="square" />
                </div>
                <div>
                    <Button onClick={() => history("/epos/check-balance")} text={t("Check balance")} type="square" />
                </div>


            </div>
        </div>
    );
};

export default PopularGiftCards;
