/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as BlueTag } from "../../assests/svg/Tag/blue_tag.svg";
import InfoTooltip from "../Tooltip/InfoTooltip";
import InputField from "../InputField/InputField";
import TextEditor from "../InputField/TextEditor";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import ReactDatePicker from "../DatePicker/ReactDatePicker";
import InputGroupTxt from "../InputField/InputGroupTxt";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import { ReactComponent as CloseIcone } from "../../assests/svg/close.svg";
import Select from "react-select";
import { fetchBrandCategoryByID } from "../../Redux/BrandCategories/brandCategoryAction";
import {
  GIFTCARD_REDEMPTION_AMOUNT,
  KEYBENEFITS_TEXT_LIMIT,
  PREFIX_TEXT_LIMIT,
  VALIDITY_UNITS,
} from "../../utils/ConstantData";
import TagsInput from "../../components/TagsInput/TagsInput";
import ReactSwitchButton from "../Button/ReactSwitchButton";
import PulseLoader from "../../components/Loader/PulseLoader";
import {
  dateFormatConverterforFrontend,
  downloadFileURI,
} from "../../utils/constantFunction";
import {
  editGiftCard,
  fetchGiftCardDenominationById,
  fetchGiftCardFilesById,
} from "../../Redux/GiftCard/giftcardAction";
import DragandDrop from "../DragandDrop/DragandDrop";
import DragandDropDocs from "../DragandDrop/DragandDropDocs";
import { fetchAllCountrieDetails } from "../../Redux/Countries/countryAction";
import Button from "../Button/Button";
import TagsInputFoDenominations from "../TagsInput/TagsInputFoDenominations";
import { useTranslation } from "react-i18next";

const EditProduct = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const inputTitleElement = useRef(null);
  const inputDescElement = useRef(null);
  const inputElementBrand = useRef(null);
  const inputElementRangMin = useRef(null);
  const inputElementRangMax = useRef(null);

  const giftcardsState = useSelector(
    (state) => state?.giftcard?.selectedGidftcardDetails
  );
  const Preferredlanguage = useSelector((state) => state?.languages?.languages);
  const countries = useSelector((state) => state?.countries?.countries);
  const stateList = useSelector(
    (state) => state?.countries?.countrieDetails?.states
  );
  const brands = useSelector((state) => state?.brands?.brands);
  const brandCategories = useSelector(
    (state) => state?.brandCategories?.brandCategoriesbyID
  );
  const giftCardNumberLength = useSelector(
    (state) => state?.giftcardlengths?.giftcardlength
  );
  const redemptionMethod = useSelector(
    (state) => state?.redemptionmethods?.redemptionMethods
  );
  const activations = useSelector(
    (state) => state?.activationrequired?.activations
  );
  const giftcardtype = useSelector((state) => state.giftcardtype?.giftcardtype);
  const denominationState = useSelector(
    (state) => state?.giftcard?.selectedGidftcardDenomination
  );
  const giftcarFilesState = useSelector(
    (state) => state?.giftcard?.giftcardFiles
  );
  const countrieDetails = useSelector(
    (state) => state?.countries?.countrieDetails
  );
  //  const states = useSelector((state) => state?.countries?.countrieDetails[0]?.states);
  const giftcardPrefix = useSelector(
    (state) => state?.giftcardprefix?.giftcardprefix
  );

  let defaultLang = localStorage.getItem("defaultLang");

  // Text editor
  const edittorTxt = {
    value: EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML("<p></p>"))
    ),
    lang: JSON.parse(defaultLang),
  };

  const edittorTxtTerm = {
    value: EditorState.createEmpty(),
    lang: JSON.parse(defaultLang),
  };

  const constProdTitle = {
    title: "",
    lang: JSON.parse(defaultLang),
  };

  const [prodTitles, setProdTitles] = useState([constProdTitle]);
  const [editorState, setEditorState] = useState([edittorTxt]);

  const [termConditionTxt, setTermConditionTxt] = useState([edittorTxtTerm]);

  const [gitcardTags, setGitcardTags] = useState([]);
  const [fixeddenomnationTags, setDenomnationTags] = useState([]);

  const [error, setError] = useState({
    language: "",
    title: "",
    editorState: "",
    termConditionTxt: "",
    keyBenefits1: "",
    keyBenefits2: "",
    keyBenefits3: "",
    keyBenefits4: "",
  });

  const [languageDropDown, setLanguageDropDown] = useState("");
  const [giftcardTypeDropDown, setGiftcardTypeDropDown] = useState("");
  const [countryCurrencyDropDown, setCountryCurrencyDropDown] = useState("");
  const [stateDropDown, setStateDropDown] = useState("");
  const [brandDropDown, setBrandDropDown] = useState("");
  const [brandCategoryDropDown, setBrandCategoryDropDown] = useState("");
  const [validityDropDown, setValidityDropDown] = useState({ name: "Days" });
  const [giftCardLengthDropDown, setGiftCardLengthDropDown] = useState("");
  const [prefixGiftCardDropDown, setPrefixGiftCardDropDown] = useState("");
  const [redemptionMethodDropDown, setRedemptionMethodDropDown] = useState("");
  const [redemptionAmountDropDown, setRedemptionAmountDropDown] = useState("");
  const [activationDropDown, setActivationDropDown] = useState("");
  const [denominations, setDenominations] = useState([]);

  const [docUrl, setDocUrl] = useState("");
  const [cardImgUrl, setCardImgUrl] = useState("");
  const [tbImgUrl, setTbImgUrl] = useState("");

  const [cardImage, setCardImage] = useState([]);
  const [cardDocumentation, setCardDocumentation] = useState([]);
  const [cardThumbnail, setCardThumbnail] = useState([]);

  const [inputFields, setInputFields] = useState({
    keyBenefits1: "",
    keyBenefits2: "",
    keyBenefits3: "",
    keyBenefits4: "",
    binNumber: "",
    prefixValue: "",
    validityValue: "",
    minamount: "",
    maxamount: "",
  });

  const [keybenefits, setKeyBenefits] = useState("");

  const [switchButtons, setSwitchButtons] = useState({
    isFeaturedFlag: false,
    activeFlag: true,
    denomnationFlag: false,
    rangedenomnationFlag: false,
  });

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (giftcardsState) {
      setTitleObject();
      setInputData();
      setFlagsData();
      setTagsData();
      dispatch(fetchGiftCardFilesById(giftcardsState.id));

      if (giftcardsState?.giftcard_value_type === "denominations") {
        dispatch(fetchGiftCardDenominationById(giftcardsState.id));
      }
    }
  }, [giftcardsState]);

  useEffect(() => {
    giftcardsState &&
      Preferredlanguage &&
      Preferredlanguage.map((item) => {
        if (giftcardsState?.language_id === item?.language_code) {
          setLanguageDropDown(item);
        }
      });
  }, [giftcardsState, Preferredlanguage]);

  useEffect(() => {
    let one = giftcardsState?.key_benefits
      ? giftcardsState?.key_benefits[0]
      : "";
    let two = giftcardsState?.key_benefits
      ? giftcardsState?.key_benefits[1]
      : "";
    let three = giftcardsState?.key_benefits
      ? giftcardsState?.key_benefits[2]
      : "";
    let four = giftcardsState?.key_benefits
      ? giftcardsState?.key_benefits[3]
      : "";

    let oneObj =
      one && one.length > 0
        ? { id: "one", name: one }
        : { id: "one", name: "" };
    let twoObj =
      two && two.length > 0
        ? { id: "two", name: two }
        : { id: "two", name: "" };
    let threeObj =
      three && three.length > 0
        ? { id: "three", name: three }
        : { id: "three", name: "" };
    let fourObj =
      four && four.length > 0
        ? { id: "four", name: four }
        : { id: "four", name: "" };

    let key_benifits = {
      one: oneObj,
      two: twoObj,
      three: threeObj,
      four: fourObj,
    };

    setKeyBenefits(key_benifits);
  }, [giftcardsState]);

  useEffect(() => {
    giftcardsState &&
      giftcardtype &&
      giftcardtype.map((item) => {
        if (giftcardsState?.registered_by_id === item?.id) {
          setGiftcardTypeDropDown(item);
        }
      });
  }, [giftcardsState, giftcardtype]);

  useEffect(() => {
    giftcardsState &&
      countries &&
      countries.map((item) => {
        if (giftcardsState?.country_id === item?.id) {
          setCountryCurrencyDropDown(item);
          item?.id && dispatch(fetchAllCountrieDetails(item?.id));
        }
      });
  }, [giftcardsState, countries]);

  useEffect(() => {
    giftcardsState &&
      countries &&
      stateList &&
      stateList.map((item) => {
        if (giftcardsState?.state_id === item?.id) {
          setStateDropDown(item);
        }
      });
  }, [countries, stateList]);

  useEffect(() => {
    giftcardsState &&
      brands &&
      brands.map((item) => {
        if (giftcardsState?.brand_id === item.id) {
          setBrandDropDown(item);
          item.id && dispatch(fetchBrandCategoryByID(item.id));
        }
      });
  }, [giftcardsState, brands]);

  useEffect(() => {
    brandDropDown &&
      brandCategories &&
      brandCategories.map((item) => {
        if (giftcardsState?.brand_category_id === item.id) {
          setBrandCategoryDropDown(item);
        }
      });
  }, [giftcardsState, brandDropDown, brandCategories]);

  useEffect(() => {
    giftcardsState &&
      giftCardNumberLength &&
      giftCardNumberLength.map((item) => {
        if (giftcardsState?.giftcard_num_length === item.gift_card_length) {
          setGiftCardLengthDropDown(item);
        }
      });
  }, [giftcardsState, giftCardNumberLength]);

  useEffect(() => {
    giftcardsState &&
      redemptionMethod &&
      redemptionMethod.map((item) => {
        if (giftcardsState?.redemption_method_id === item.id) {
          setRedemptionMethodDropDown(item);
        }
      });
  }, [giftcardsState, redemptionMethod]);

  useEffect(() => {
    giftcardsState &&
      GIFTCARD_REDEMPTION_AMOUNT &&
      GIFTCARD_REDEMPTION_AMOUNT.map((item) => {
        if (giftcardsState?.redemption_type === item.value) {
          setRedemptionAmountDropDown(item);
        }
      });
  }, [giftcardsState, GIFTCARD_REDEMPTION_AMOUNT]);

  useEffect(() => {
    giftcardsState &&
      activations &&
      activations.map((item) => {
        if (giftcardsState?.activation_required_flag === true) {
          setActivationDropDown(item);
        }
      });
  }, [giftcardsState, activations]);

  useEffect(() => {
    giftcardsState && denominationState && setDenominations(denominationState);
  }, [giftcardsState, denominationState]);

  useEffect(() => {
    giftcardsState &&
      giftcardPrefix &&
      giftcardPrefix.map((item) => {
        if (
          item.giftcard_prefix.toLocaleLowerCase() ===
          giftcardsState.prefix_type
        ) {
          setPrefixGiftCardDropDown(item);
        }
      });
  }, [giftcardsState, giftcardPrefix]);

  useEffect(() => {
    giftcarFilesState &&
      giftcarFilesState.map((item) => {
        if (item.file === "card_image") {
          setCardImgUrl(item);
        } else if (item.file === "card_documentation") {
          setDocUrl(item);
        } else if (item.file === "card_thumbnail") {
          setTbImgUrl(item);
        }
      });
  }, [giftcarFilesState]);

  // title
  const setTitleObject = () => {
    let arr = [];
    const res = Object.entries(giftcardsState.product_title).map((e) => ({
      [e[0]]: e[1],
    }));

    res &&
      res.map((item) => {
        let name = Object.values(item).join("");
        let langCode = Object.keys(item).join("");

        Preferredlanguage &&
          Preferredlanguage.map((item) => {
            if (item.language_code === langCode) {
              let obj = {
                title: name,
                lang: item,
              };
              arr.push(obj);
            }
          });
        setProdTitles(arr);
      });
  };

  const setInputData = () => {
    setInputFields({
      prefixValue: giftcardsState?.prefix ? giftcardsState?.prefix : "",
      validityValue: giftcardsState?.validity ? giftcardsState?.validity : "",
      minamount: giftcardsState?.min_amount ? giftcardsState?.min_amount : "",
      maxamount: giftcardsState?.max_amount ? giftcardsState?.max_amount : "",
      //  binNumber: giftcardsState?.binNumber ? giftcardsState?.binNumber : "",
    });

    setDate({
      startDate: giftcardsState?.start_date
        ? dateFormatConverterforFrontend(giftcardsState?.start_date)
        : new Date(),
      endDate: giftcardsState?.end_date
        ? dateFormatConverterforFrontend(giftcardsState?.end_date)
        : new Date(),
    });

    // Description

    let arrDescription = [];

    giftcardsState.about_the_product &&
      giftcardsState.about_the_product.map((item, i) => {
        Preferredlanguage &&
          Preferredlanguage.map((langs) => {
            if (langs.language_code === item.language_id) {
              const obj = {
                value: EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(item?.description_text)
                  )
                ),
                lang: langs,
              };
              arrDescription.push(obj);
            }
          });
      });

    setEditorState(arrDescription);

    // TermCondition

    let arrTermCondition = [];

    giftcardsState.terms_condition &&
      giftcardsState.terms_condition.map((item, i) => {
        Preferredlanguage &&
          Preferredlanguage.map((langs) => {
            if (langs.language_code === item.language_id) {
              const obj = {
                value: EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(item?.terms_condition_text)
                  )
                ),
                lang: langs,
              };
              arrTermCondition.push(obj);
            }
          });
      });

    setTermConditionTxt(arrTermCondition);
  };

  const setFlagsData = () => {
    setSwitchButtons({
      isFeaturedFlag: giftcardsState?.feature_flag ? true : false,
      activeFlag: giftcardsState?.active_flag ? true : false,
      denomnationFlag:
        giftcardsState?.giftcard_value_type === "denominations" ? true : false,
      rangedenomnationFlag:
        giftcardsState?.giftcard_value_type === "range" ? true : false,
    });
  };

  const setTagsData = () => {
    if (giftcardsState?.tags) {
      setGitcardTags(giftcardsState?.tags);
    } else {
      setGitcardTags([]);
    }
    // setDenomnationTags(giftcardsState?.tags ? giftcardsState?.tags : [])
  };

  // Removed product title field handler
  const handleRemoveClickProdTitle = (index) => {
    const list = [...prodTitles];
    list.splice(index, 1);
    setProdTitles(list);
  };

  // product input value getting handler
  const handleInputChangeProdTitle = (e) => {
    const { name, value } = e.target;
    let arr = [...prodTitles];
    arr.map((item, i) => {
      if (name === item.lang.language_code) {
        arr[i].title = value;
      }
    });
    setProdTitles(arr);
  };

  // Language select handler
  const handleAddClickProdTitle = (e) => {
    if (
      prodTitles.some((item) => item.lang.language_code === e.language_code)
    ) {
      // alert("Already added");
      toggleShowToast();
      return;
    } else {
      setProdTitles([...prodTitles, { title: "", lang: e }]);
    }
  };

  // Remove Editor handler
  const handleRemoveClickDiscription = (index) => {
    const list = [...editorState];
    list.splice(index, 1);
    setEditorState(list);
  };

  const handleRemoveClickTermCondition = (index) => {
    const list = [...termConditionTxt];
    list.splice(index, 1);
    setTermConditionTxt(list);
  };

  // Getting Editor avlue handler
  const getDescriptionDiscription = (txt, code) => {
    let arr = [...editorState];
    setError({ termConditionTxt: "" });
    arr.map((item, i) => {
      if (code === item.lang.language_code) {
        arr[i].value = txt;
      }
    });
    setEditorState(arr);
  };

  const getDescriptionTermCondition = (txt, code) => {
    let arr = [...termConditionTxt];
    setError({ termConditionTxt: "" });
    arr.map((item, i) => {
      if (code === item.lang.language_code) {
        arr[i].value = txt;
      }
    });
    setTermConditionTxt(arr);
  };

  // Editor select language handler
  const handleDropdownOnClickDiscription = (obj) => {
    handleAddClickDiscription(obj);
  };

  const handleDropdownOnClickTermCondition = (obj) => {
    handleAddClickTermCondition(obj);
  };

  // Editor select language handler
  const handleAddClickDiscription = (e) => {
    if (
      editorState.some((item) => item.lang.language_code === e.language_code)
    ) {
      toggleShowToast();
      return;
    } else {
      setEditorState([
        ...editorState,
        { value: EditorState.createEmpty(), lang: e },
      ]);
    }
  };

  const handleAddClickTermCondition = (e) => {
    if (
      termConditionTxt.some(
        (item) => item.lang.language_code === e.language_code
      )
    ) {
      toggleShowToast();
      return;
    } else {
      setTermConditionTxt([
        ...termConditionTxt,
        { value: EditorState.createEmpty(), lang: e },
      ]);
    }
  };

  const handleChangeGiftCardLength = (e) => {
    //  setProductCatObj({ ...productCatObj, giftCardLength: e });
    setGiftCardLengthDropDown(e);
  };

  //Input handler

  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (e.target.name === "prefixValue") {
      setInputFields({ ...inputFields, [name]: value.toUpperCase() });
    } else {
      setInputFields({ ...inputFields, [name]: value });
    }
  };

  const handleSetKeybenefits = (e) => {
    let data = {
      id: e.target.name,
      name: e.target.value,
    };
    setKeyBenefits({ ...keybenefits, [e.target.name]: data });
  };

  // Switch button handler

  const activeHandle = () => {
    setSwitchButtons({
      ...switchButtons,
      activeFlag: !switchButtons.activeFlag,
    });
  };

  const isFeaturedHandle = () => {
    setSwitchButtons({
      ...switchButtons,
      isFeaturedFlag: !switchButtons.isFeaturedFlag,
    });
  };

  const fixeddenomnationHandle = () => {
    setSwitchButtons({
      ...switchButtons,
      denomnationFlag: !switchButtons.denomnationFlag,
      rangedenomnationFlag: false,
    });
  };

  const rangedenomnationHandle = () => {
    setSwitchButtons({
      ...switchButtons,
      rangedenomnationFlag: !switchButtons.rangedenomnationFlag,
      denomnationFlag: false,
    });
  };

  // dropdown handler

  const handleChangeLanguage = (e) => {
    setLanguageDropDown(e);
  };

  const handleChangeBrands = (e) => {
    setBrandDropDown(e);
    setError({brand: ''})
    setBrandCategoryDropDown("");
    dispatch(fetchBrandCategoryByID(e.id));
  };

  const handleChangeBrandCategories = (e) => {
    setBrandCategoryDropDown(e);
  };

  const handleChangeValue = (e) => {
    // setDropDownFields({ ...dropDownFields, [type]: item });
    // if (type === "countryCurrency") {
    //   dispatch(fetchAllCountrieDetails(item.iso2));
    // }
  };

  const handleChangeRedemptionMethod = (e) => {
    setRedemptionMethodDropDown(e);
  };

  const handleChangeRedemptionAmount = (e) => {
    setRedemptionAmountDropDown(e);
  };

  const handleChangeActivations = (e) => {
    setActivationDropDown(e);
  };

  const handleDenominationRemoveClick = (index) => {
    const list = [...denominations];
    list.splice(index, 1);
    setDenominations(list);
  };

  const goToproductSuccessfull = () => {
    history("/product-edit-successfull");
  };

  const validate = () => {
    let value = convertToRaw(editorState[0].value.getCurrentContent());
    if (!prodTitles[0].title) {
      setError({ title: "Please enter product title" });
      if (inputTitleElement.current) {
        inputTitleElement.current.focus();
      }
      return false;
    } else if (!value.blocks[0].text) {
      setError({ editorState: "Please provides description" });
      if (inputDescElement.current) {
        inputDescElement.current.focus();
      }
      return false;
    } else if (!brandDropDown) {
      setError({ brand: t("Brand field is required") });
      if (inputElementBrand.current) {
        inputElementBrand.current.focus();
      }
      return false;
    } else if (switchButtons.rangedenomnationFlag && !inputFields.minamount) {
      setError({ minamount: t("Please enter minimum amount") });
      if(inputElementRangMin.current){
        inputElementRangMin.current.focus()
      }
      return false;
    } else if (switchButtons.rangedenomnationFlag && !inputFields.maxamount) {
      setError({ maxamount: t("Please enter maximum amount") });
      if(inputElementRangMax.current){
        inputElementRangMax.current.focus()
      }
      return false;
    } 

    return true;
  };

  console.log("brandDropDown ", brandDropDown)
  console.log("error ", error)

  const submit = () => {
    let denominationType =
      switchButtons?.rangedenomnationFlag === true ? "range" : "denominations";
    let val = validate();
    let obj = {
      language_id: languageDropDown?.language_code,
      product_title: prodTitles,
      default_title: prodTitles[0].title,
      about_the_product: editorState,
      key_benefits: keybenefits,
      registered_by_id: giftcardTypeDropDown?.id,
      country_id: giftcardsState?.country_id,
      business_region: countrieDetails?.region,
      state_id: giftcardsState?.state_id,
      brand_id: brandDropDown?.id,
      brand_category_id: brandCategoryDropDown?.id,
      // "start_date": "2022-07-21T06:57:48.890238",
      // "end_date": "2022-07-21T06:57:48.890301",
      giftcard_num_length: giftCardLengthDropDown?.gift_card_length,
      prefix_type: prefixGiftCardDropDown?.giftcard_prefix.toLowerCase(),
      prefix: inputFields?.prefixValue,
      post_prefix: null,
      redemption_method_id: redemptionMethodDropDown?.id,
      activation_required_flag: true,
      tags: gitcardTags,
      giftcard_value_type: denominationType,
      min_amount: inputFields?.minamount ? inputFields?.minamount : 0,
      max_amount: inputFields?.maxamount ? inputFields?.maxamount : 0,
      amount: 0,
      reloadable_giftcard_flag: true,
      redemptionAmount: redemptionAmountDropDown,
      terms_condition: termConditionTxt,
      validity: parseInt(inputFields?.validityValue),
      feature_flag: true,
      promotion_flag: true,
      user_id: giftcardsState?.user_id,
      giftcard_key: giftcardsState?.giftcard_key,
      cardImage: cardImage,
      cardDocumentation: cardDocumentation,
      cardThumbnail: cardThumbnail,
      giftcartId: giftcardsState?.id,
      denominations: fixeddenomnationTags,
      denominationFlag: switchButtons?.denomnationFlag,
      country: giftcardsState?.country,
    };

    if (val) {
      dispatch(editGiftCard(obj, goToproductSuccessfull));
    }
  };

  return !giftcardsState ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column ">
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-2 gap-4">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">{t("Name & Description")}</span>
          </div>
        </div>

        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">{t("Preferred language")}</span>
            <InfoTooltip
              title={"Enter your Preferred language or select from list"}
            />
          </div>

          <div>
            <Select
              placeholder={t("Select preferred language")}
              noOptionsMessage={() => "Not found"}
              getOptionLabel={(Preferredlanguage) => Preferredlanguage.language}
              options={Preferredlanguage}
              styles={CUSTOM_DROPDOWN_STYLE}
              value={languageDropDown}
              onChange={handleChangeLanguage}
              isClearable
            />
          </div>

          {prodTitles.map((item, index) => (
            <div className="d-flex flex-column gap-3" key={index}>
              <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Product title")} #{index + 1} {item.lang.language}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip title={"Enter product title"} />
                </div>
                {prodTitles.length !== 1 && (
                  <div
                    className="cursor"
                    onClick={() => handleRemoveClickProdTitle(index)}
                  >
                    {index !== 0 && <CloseIcone />}
                  </div>
                )}
              </div>

              <div className="d-flex flex-column gap-1">
                <InputField
                  inputRef={inputTitleElement}
                  onChange={handleInputChangeProdTitle}
                  name={item.lang.language_code}
                  placeholder={`${t(
                    "Product title"
                  )} ${item.lang.language.toLocaleLowerCase()}`}
                  type="text"
                  value={item.title}
                />
                {!item.title && error.title ? (
                  <label className="text-error">{error.title}</label>
                ) : null}
              </div>
            </div>
          ))}
          <div className="d-flex ">
            <div className="btn-group w-100">
              <div
                className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                data-bs-toggle="dropdown"
              >
                <span>{t("Add other language for product title")}</span>
              </div>
              <ul
                className={`dropdown-menu rounded-card-7 dropdown-menu-start ${
                  props?.lang === "ar" ? "dropdown-menu-ar" : ""
                }`}
              >
                {Preferredlanguage &&
                  Preferredlanguage.map((item, i) => (
                    <li key={i} onClick={() => handleAddClickProdTitle(item)}>
                      <div className="dropdown-item cursor">
                        {item.language}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          {editorState &&
            editorState.map((item, i) => (
              <div className="d-flex flex-column gap-3" key={i}>
                <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Description")} #{i + 1} {item?.lang?.language}
                      <span className="text-danger">{i === 0 ? "*" : ""}</span>
                    </span>
                    <InfoTooltip title={"Enter product description"} />
                  </div>
                  {editorState.length !== 1 && (
                    <div
                      className="cursor"
                      onClick={() => handleRemoveClickDiscription(i)}
                    >
                      {i !== 0 && <CloseIcone />}
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column gap-1">
                  <TextEditor
                    code={item?.lang?.language_code}
                    editorState={item.value}
                    getDescription={getDescriptionDiscription}
                    lang={props?.lang}
                  />
                  {error.editorState && i === 0 ? (
                    <label className="text-error">{error.editorState}</label>
                  ) : null}
                </div>
              </div>
            ))}

          <div className="d-flex ">
            <div className="btn-group w-100">
              <div
                className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                data-bs-toggle="dropdown"
              >
                <span>{t("Add other language description")} </span>
              </div>
              <ul
                className={`dropdown-menu rounded-card-7 dropdown-menu-start ${
                  props?.lang === "ar" ? "dropdown-menu-ar" : ""
                }`}
              >
                {Preferredlanguage &&
                  Preferredlanguage.map((item, i) => (
                    <li
                      key={i}
                      onClick={() => handleDropdownOnClickDiscription(item)}
                    >
                      <div className="dropdown-item cursor">
                        {item.language}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Key benefits")}</span>
              <InfoTooltip title={"Enter key benefits"} />
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
              <InputField
                onChange={handleSetKeybenefits}
                name="one"
                placeholder={t("benefit 1")}
                type="text"
                value={keybenefits?.one?.name}
                maxLength={KEYBENEFITS_TEXT_LIMIT}
              />
              <InputField
                onChange={handleSetKeybenefits}
                name="two"
                placeholder={t("benefit 2")}
                type="text"
                value={keybenefits?.two?.name}
                maxLength={KEYBENEFITS_TEXT_LIMIT}
              />
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
              <InputField
                onChange={handleSetKeybenefits}
                name="three"
                placeholder={t("benefit 3")}
                type="text"
                value={keybenefits?.three?.name}
                maxLength={KEYBENEFITS_TEXT_LIMIT}
              />
              <InputField
                onChange={handleSetKeybenefits}
                name="four"
                placeholder={t("benefit 4")}
                type="text"
                value={keybenefits?.four?.name}
                maxLength={KEYBENEFITS_TEXT_LIMIT}
              />{" "}
            </div>
          </div>
        </div>
      </div>

      {/* Category & attribute */}

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-2">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">
              {t("Category & Attribute")}
            </span>
          </div>
        </div>

        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Giftcard Type")}</span>
              <InfoTooltip title={"Select Giftcard Type"} />
            </div>

            {/* Dropdown region*/}
            <div className="btn-group w-100">
              <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                {giftcardTypeDropDown?.type_name
                  ? giftcardTypeDropDown?.type_name
                  : ""}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Country & Currency")}
              </span>
              <InfoTooltip title={"Select Country & Currency"} />
            </div>

            {/* Dropdown region*/}
            <div className="btn-group w-100">
              <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                {countryCurrencyDropDown?.name +
                  " - " +
                  countryCurrencyDropDown?.currency}
              </div>
            </div>
          </div>

          {/* <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                Country & Currency<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
             <div className="d-flex flex-column gap-1">
              <Select
                noOptionsMessage={() => "Not found"}
                placeholder="Select country & currency"
                getOptionLabel={(countries) =>
                  countries.name + " -" + countries.currency
                }
                options={countries}
                //   isSearchable
                value={dropDownFields?.countryCurrency}
                onChange={handleChangeCountries}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
                isDisabled={true}
              />
              {!dropDownFields.countryCurrency && error.countryCurrency ? (
                <label className="text-error">{error.countryCurrency}</label>
              ) : null}
            </div>
          </div> */}

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Business Unit/Region")}
              </span>
              <InfoTooltip title={"Business Unit/Region"} />
            </div>

            {/* Dropdown region*/}
            <div className="btn-group w-100">
              <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                {countrieDetails?.region
                  ? countrieDetails?.region
                  : t("Not avilable")}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("State")}</span>
              <InfoTooltip title={"State"} />
            </div>

            {/* Dropdown region*/}
            <div className="btn-group w-100">
              <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                {stateDropDown?.name ? stateDropDown?.name : t("Not avilable")}
              </div>
            </div>
          </div>

          {/* <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">State</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div>
                <Select
                  noOptionsMessage={() => "Not found"}
                  placeholder="Select state"
                  getOptionLabel={(states) => states.name}
                  options={states}
                  //   isSearchable
                  value={stateDropDown}
                  onChange={handleChangeStates}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                  isDisabled={!countryCurrencyDropDown}
                />
              </div>
            </div> */}

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Brand")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip title={"Select Brand name"} />
            </div>
            {/* Dropdown brand*/}
            <div className="d-flex flex-column gap-1">
              <Select
                ref={inputElementBrand}
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select brand")}
                getOptionLabel={(brands) => brands.default_name}
                options={brands}
                //   isSearchable
                value={brandDropDown}
                onChange={handleChangeBrands}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
              />
              {error.brand ? (
                <label className="text-error">{error.brand}</label>
              ) : null}
            </div>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Brand category")}</span>
              <InfoTooltip title={"Select brand category"} />
            </div>

            {/* Dropdown brandCategory*/}
            <div>
              <Select
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select brand categories")}
                getOptionLabel={(brandCategories) =>
                  brandCategories?.default_category_name
                }
                options={brandCategories}
                //   isSearchable
                value={brandCategoryDropDown}
                onChange={handleChangeBrandCategories}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
                isDisabled={!brandDropDown}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row justify-content-start align-items-center gap-2 w-100">
              <div className="col d-flex flex-column gap-2">
                <span className="fs-lighter font-14 font-bold">
                  {t("Select start date")}
                </span>
                <ReactDatePicker
                  onChange={handleChangeValue}
                  value={date.startDate}
                  selected={date.startDate}
                  minDate={new Date()}
                  lang={props?.lang}
                  name={"startDate"}
                  className="datepicker-input-style-without-border"
                />
              </div>

              <div className="col d-flex flex-column gap-2">
                <span className="fs-lighter font-14 font-bold">
                  {t("Select end date")}
                </span>
                <ReactDatePicker
                  onChange={handleChangeValue}
                  value={date.endDate}
                  selected={date.endDate}
                  minDate={date.startDate}
                  lang={props?.lang}
                  name={"endDate"}
                  className="datepicker-input-style-without-border"
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Validity")}</span>
              <InfoTooltip
                title={"Keep blank for product to stay active forever"}
              />
            </div>
            {/* Dropdown prefixGiftCard*/}
            <div className="input-group rounded-card-7">
              <button
                className={`btn btn-outline-secondary dropdown-toggle border border-2 ${
                  props?.lang === "ar"
                    ? "prefix-dropdown-right-corner-ar"
                    : "prefix-dropdown-left-corner"
                }`}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {validityDropDown ? validityDropDown?.name : "Select"}
              </button>
              <ul
                className={` ${
                  props?.lang === "ar"
                    ? "dropdown-menu dropdown-menu-ar"
                    : "dropdown-menu"
                }`}
              >
                {VALIDITY_UNITS &&
                  VALIDITY_UNITS.map((item, i) => (
                    <li key={i} onClick={() => setValidityDropDown(item)}>
                      <div className="dropdown-item cursor">{item.name}</div>
                    </li>
                  ))}
              </ul>
              <input
                type={"number"}
                name="validityValue"
                placeholder={t("Validity of gift card")}
                className={`form-control border border-2 ${
                  props?.lang === "ar"
                    ? "prefix-dropdown-left-corner-ar"
                    : "prefix-dropdown-right-corner"
                }`}
                maxLength={PREFIX_TEXT_LIMIT}
                value={inputFields.validityValue}
                disabled={!validityDropDown?.name}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Gift Card length */}
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Gift card number length")}
              </span>
              <InfoTooltip title={"Select Gift card number length"} />
            </div>
            {/* Dropdown giftCardLength*/}
            <div>
              <Select
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select giftcard number length")}
                getOptionLabel={(giftCardNumberLength) =>
                  giftCardNumberLength.gift_card_length
                }
                options={giftCardNumberLength}
                //   isSearchable
                value={giftCardLengthDropDown}
                onChange={handleChangeGiftCardLength}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
              />
            </div>
          </div>

          {/* Bin Number */}
          {/* <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">BIN number</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <InputField
                  onChange={handleInputChange}
                  name={"binNumber"}
                  placeholder={"Enter BIN number"}
                  type="text"
                //   value={productCatObj.binNumber}
                />
              </div>
            </div> */}

          {/* Prefix  */}
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Prefix of gift card")}
              </span>
              <InfoTooltip title={"Prefix of gift card"} />
            </div>
            {/* Dropdown prefixGiftCard*/}
            <div className="input-group rounded-card-7">
              <button
                className={`btn btn-outline-secondary dropdown-toggle border border-2 ${
                  props?.lang === "ar"
                    ? "prefix-dropdown-right-corner-ar"
                    : "prefix-dropdown-left-corner"
                }`}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {prefixGiftCardDropDown
                  ? prefixGiftCardDropDown?.giftcard_prefix
                  : t("Select")}
              </button>
              <ul
                className={` ${
                  props?.lang === "ar"
                    ? "dropdown-menu dropdown-menu-ar"
                    : "dropdown-menu"
                }`}
              >
                {giftcardPrefix &&
                  giftcardPrefix.map((item, i) => (
                    <li key={i} onClick={() => setPrefixGiftCardDropDown(item)}>
                      <div className="dropdown-item cursor">
                        {item.giftcard_prefix}
                      </div>
                    </li>
                  ))}
              </ul>
              <input
                type={"text"}
                name="prefixValue"
                placeholder={t("Prefix of gift card")}
                className={`form-control border border-2 ${
                  props?.lang === "ar"
                    ? "prefix-dropdown-left-corner-ar"
                    : "prefix-dropdown-right-corner"
                }`}
                maxLength={PREFIX_TEXT_LIMIT}
                value={inputFields.prefixValue}
                disabled={!prefixGiftCardDropDown?.giftcard_prefix}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Redemption method")}
              </span>
              <InfoTooltip title={"Select Redemption method"} />
            </div>
            {/* Dropdown redemptionMethod*/}
            <div>
              <Select
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select redemption method")}
                getOptionLabel={(redemptionMethod) =>
                  redemptionMethod.redemption_method
                }
                options={redemptionMethod}
                //   isSearchable
                value={redemptionMethodDropDown}
                onChange={handleChangeRedemptionMethod}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
              />
            </div>
          </div>

          {giftcardTypeDropDown?.id !== "3rd_party_suppliers" ? (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Redemption Amount")}
                </span>
                <InfoTooltip title={"Select Redemption amount"} />
              </div>
              <div>
                <Select
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select redemption amount")}
                  getOptionLabel={(e) => e.name}
                  options={GIFTCARD_REDEMPTION_AMOUNT}
                  isSearchable
                  value={redemptionAmountDropDown}
                  onChange={handleChangeRedemptionAmount}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
              </div>
            </div>
          ) : null}

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Activation Required")}
              </span>
              <InfoTooltip title={"Select Activation Required"} />
            </div>
            {/* Dropdown activation*/}
            <div>
              <Select
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select activation")}
                getOptionLabel={(activations) =>
                  activations.activation_required
                }
                options={activations}
                // isSearchable
                value={activationDropDown}
                onChange={handleChangeActivations}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Tags")}</span>
              <InfoTooltip title={"Enter tags"} />
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
              <TagsInput
                tags={gitcardTags}
                setTags={setGitcardTags}
                placeholder={"Enter tags to describe your item"}
              />
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Is featured")}</span>
              <InfoTooltip title={"Is featured"} />
            </div>
            <ReactSwitchButton
              onChange={() => isFeaturedHandle()}
              value={switchButtons.isFeaturedFlag}
            />
          </div>

          <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("Active")}</span>
              <InfoTooltip title={"Active/Inactive"} />
            </div>
            <ReactSwitchButton
              onChange={() => activeHandle()}
              value={switchButtons.activeFlag}
            />
          </div>
        </div>
      </div>

      {/* Price Modal*/}

      <div className="d-flex flex-column">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">{t("Price")}</span>
          </div>
          <div className="d-flex flex-column gap-4 mt-4">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Fixed denomination")}
                  </span>
                  <InfoTooltip
                    title={"Enable switch to enter Fixed denomination"}
                  />
                </div>
                {/*  <SwitchButton
                onChange={() =>fixeddenomnationHandle() }
                value={denomnationFlag}
                disabled={reloadableGiftCardFlag}
              />*/}
                <ReactSwitchButton
                  onChange={() => fixeddenomnationHandle()}
                  value={switchButtons.denomnationFlag}
                  disabled={true}
                />
              </div>
              {switchButtons.denomnationFlag ? (
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column gap-3">
                      <div>
                        <span className="font-bold font-14">
                          {t("Denomination List")}
                        </span>
                      </div>
                      <div className="d-flex flex-column gap-3">
                        <table responsive="xl">
                          <tbody>
                            <tr className="">
                              <td className="col-6">
                                <div className="d-flex flex-row justify-content-start">
                                  <span className="font-bolder  font-14">
                                    {t("Id")}
                                  </span>
                                </div>
                              </td>
                              <td className="col-4">
                                <div className="d-flex flex-row justify-content-start">
                                  <span className="font-bolder font-14">
                                    {t("Denomination")}
                                  </span>
                                </div>
                              </td>
                              <td className="col-2">
                                <div className="d-flex flex-row justify-content-start">
                                  <span className="font-bolder font-14">
                                    {t("Remove")}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            {denominations &&
                              denominations.map((item, i) => (
                                <tr className="" key={i}>
                                  <td className="col-6">
                                    <div className="d-flex flex-row justify-content-start">
                                      <span className="text-muted font-14">
                                        {item.id}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="col-4">
                                    <div className="d-flex flex-row justify-content-start">
                                      <span className="text-muted font-14">
                                        {item.currency +
                                          " " +
                                          item.denomination_value}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="col-2">
                                    <div className="d-flex flex-row justify-content-start">
                                      <div
                                        className="cursor"
                                        onClick={() =>
                                          handleDenominationRemoveClick(i)
                                        }
                                      >
                                        <CloseIcone />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Add Denomination")}
                      </span>
                      <InfoTooltip title={"Enter fixed denomination"} />
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-column">
                        <TagsInputFoDenominations
                          existingTags={denominations}
                          tags={fixeddenomnationTags}
                          setTags={setDenomnationTags}
                          placeholder={t("Enter fixed denomination")}
                        />
                        {!fixeddenomnationTags.length > 0 &&
                        error.fixeddenomnationTags ? (
                          <label className="text-error">
                            {error.fixeddenomnationTags}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {switchButtons.rangedenomnationFlag ? (
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
                  <div className="d-flex flex-row justify-content-between gap-2">
                    <div className="col d-flex flex-column gap-2">
                      <span className="fs-lighter font-14 font-bold">
                        {t("Minimum amount")}
                        <span className="text-danger">*</span>
                      </span>
                      <div className=" d-flex flex-column gap-1">
                        <InputGroupTxt
                          // title={
                          //   catAttributeState?.countryCurrency?.currency_symbol
                          // }
                          inputRef={inputElementRangMin}
                          placeholder={t("Enter amount")}
                          type="number"
                          name="minamount"
                          lang={props?.lang}
                          value={inputFields.minamount}
                          onChange={handleInputChange}
                        />
                        {!inputFields.minamount && error.minamount ? (
                          <label className="text-error">
                            {error.minamount}
                          </label>
                        ) : null}
                      </div>
                    </div>
                    <div className="col d-flex flex-column gap-2">
                      <span className="fs-lighter font-14 font-bold">
                        {t("Max amount")}
                        <span className="text-danger">*</span>
                      </span>
                      <div className="d-flex flex-column gap-1">
                        <InputGroupTxt
                          // title={
                          //   catAttributeState?.countryCurrency?.currency_symbol
                          // }
                          inputRef={inputElementRangMax}
                          placeholder={t("Enter amount")}
                          type="number"
                          name="maxamount"
                          lang={props?.lang}
                          value={inputFields.maxamount}
                          onChange={handleInputChange}
                        />
                        {!inputFields.maxamount && error.maxamount ? (
                          <label className="text-error">
                            {error.maxamount}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Range denomination")}
                </span>
                <InfoTooltip
                  title={"Enable switch to enter Range denomination"}
                />
              </div>
              <ReactSwitchButton
                onChange={() => rangedenomnationHandle()}
                value={switchButtons.rangedenomnationFlag}
                disabled={true}
              />
            </div>
          </div>
        </div>

        {/* Other files */}

        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mt-2 mb-2">
          <div className="d-flex gap-3">
            <BlueTag />
            <span className="font-20 font-bold">{t("Card Image")}</span>
          </div>

          <div className="d-flex flex-column gap-4 mt-4">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row  justify-content-between align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Card image")}
                    <span className="text-danger">*</span>
                    <span> {t("(size should be 600*200)")}</span>
                  </span>
                  <InfoTooltip title={"Upload giftcard image"} />
                </div>

                {cardImgUrl && (
                  <span
                    className="text-light-blue font-bold font-14 cursor"
                    onClick={() => downloadFileURI(cardImgUrl)}
                  >
                    {t("Download")}
                  </span>
                )}
              </div>
              <DragandDrop files={cardImage} setFiles={setCardImage} />
            </div>
          </div>

          <div className="d-flex flex-column gap-4 mt-4">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row  justify-content-between align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Documentations")}
                  </span>
                  <InfoTooltip title={"Upload documentations"} />
                </div>
                {docUrl && (
                  <span
                    className="text-light-blue font-bold font-14 cursor"
                    onClick={() => downloadFileURI(docUrl)}
                  >
                    {t("Download")}
                  </span>
                )}
              </div>
              <DragandDropDocs
                files={cardDocumentation}
                setFiles={setCardDocumentation}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-4 mt-4">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row  justify-content-between align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Gift card Thumbnail")}
                  </span>
                  <InfoTooltip title={"Upload gift card thumbnail"} />
                </div>
                {tbImgUrl && (
                  <span
                    className="text-light-blue font-bold font-14 cursor"
                    onClick={() => downloadFileURI(tbImgUrl)}
                  >
                    {t("Download")}
                  </span>
                )}
              </div>
              <DragandDrop files={cardThumbnail} setFiles={setCardThumbnail} />
            </div>
          </div>
        </div>
      </div>

      {/* Terms & Condition */}

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">{t("Terms & Condition")}</span>
        </div>

        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-3">
            {termConditionTxt &&
              termConditionTxt.map((item, i) => (
                <div className="d-flex flex-column gap-3" key={i}>
                  <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Terms & Condition")} #{i + 1} {item?.lang?.language}
                        <span className="text-danger">
                          {i === 0 ? "*" : ""}
                        </span>
                      </span>
                      {/* <InfoTooltip title={" Enter Terms & Condition"} /> */}
                    </div>
                    {termConditionTxt.length !== 1 && (
                      <div
                        className="cursor"
                        onClick={() => handleRemoveClickTermCondition(i)}
                      >
                        {i !== 0 && <CloseIcone />}
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <TextEditor
                      code={item?.lang?.language_code}
                      editorState={item.value}
                      getDescription={getDescriptionTermCondition}
                      lang={props?.lang}
                    />
                    {error.termConditionTxt && i === 0 ? (
                      <label className="text-error">
                        {error.termConditionTxt}
                      </label>
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
          <div className="d-flex">
            <div className="btn-group w-100">
              <div
                className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                data-bs-toggle="dropdown"
              >
                <span>{t("Add other language Terms & Condition")}</span>
              </div>
              <ul
                className={`dropdown-menu rounded-card-7 dropdown-menu-start ${
                  props?.lang === "ar" ? "dropdown-menu-ar" : ""
                }`}
              >
                {Preferredlanguage &&
                  Preferredlanguage.map((item, i) => (
                    <li
                      key={i}
                      onClick={() => handleDropdownOnClickTermCondition(item)}
                    >
                      <div className="dropdown-item cursor">
                        {item.language}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="d-flex mt-4 mb-2">
          <Button onClick={() => submit()} text={t("Update")} type="primary" />
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
