/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../../assests/svg/Tag/tag.svg";
import Button from "../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../../components/Toasts/Toasts";
import { ReactComponent as LeftIcon } from "../../../../assests/svg/Products/left_lg.svg";
import InputField from "../../../../components/InputField/InputField";
import InputGroupTxt from "../../../../components/InputField/InputGroupTxt";
import product_default from "../../../../assests/img/Product-default.png";
import Dropdown from "react-bootstrap/Dropdown";
import { putSupplierMerchandiseMappingProd } from "../../../../Redux/MappingProducts/Supplier/Merchandise/SuppMerchandiseAction";
import InfoTooltip from "../../../../components/Tooltip/InfoTooltip";
import ModalComp from "../../../../components/Models/ModalComp";
import ModelForMerchandiseSummery from "../../../../components/Models/ModelForMerchandiseSummery";
import { getMerchandiseSummeryById } from "../../../../Redux/Merchandise/merchandiesAction";
import { fetchSupplierMapMerchandise } from "../../../../Redux/Supplier/supplierAction";
import PulseLoader from "../../../../components/Loader/PulseLoader";

const MerchandiseSKUEditMapping = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const loading = useSelector(
    (state) => state?.suppliers?.loading
  );
  const supplierMappedMerchandise = useSelector(
    (state) => state?.suppliers?.supplierMappedMerchandise
  );

  const selectedSupplier = useSelector(
    (state) => state?.giftcardSuppMappingReducer?.selectedSupplier
  );

  // for model
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );
  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const [products, setProducts] = useState([]);

  const [showHideCheckBox, setShowHideCheckBox] = useState(true);

  const [error, setError] = useState({
    supplierSKU: "",
    supplierPrice: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    let arr = supplierMappedMerchandise;
    let selectedProducts = [];
    let selectedProductsVariants = [];

    arr && arr.map((item) => {
      if (item.merchandise.product_variants_flag && item.merchandise_variant && item.merchandise_variant.length > 0) {
        let variantsArr = [...item.merchandise_variant];
        variantsArr && variantsArr.map((val, j) => {

          let obj = {
            product: item.merchandise,
            productId: item.merchandise.id,
            product_title: item.merchandise.product_title,
            variantId: val.id,
            variantKeys: val?.variant?.variant_keys,
            variantValues: val?.variant?.variant_values,
            defaultImage: val?.images && val?.images[0]?.file_url,
            supplier_price: val?.supplier_price
          };

          selectedProductsVariants.push(obj);
        });

      } else {

        let obj = {
          product: item.merchandise,
          productId: item.merchandise.id,
          product_title: item.merchandise.product_title,
          supplier_price: item?.supplier_price
          // variantId: val.id,
          // variantKeys: val.variant_keys,
          // variantValues: val.variant_values
        };
        selectedProducts.push(obj);
      }
    });
    let arrProducts = [...selectedProducts, ...selectedProductsVariants];


    let prodArr = [];
    let supplier = {
      id: location.state?.partnerId,
      supplierName: location.state?.selectedSupplier?.partner_name,
    };

    arrProducts &&
      arrProducts.map((item, i) => {

        let obj = {
          product: item,
          supplier: supplier,
          supplierSKU: "",
          discountToClient: "",
          supplierPrice: item?.supplier_price ? item?.supplier_price : "",
          hadayaPrice: "",
        };
        prodArr.push(obj);
      });

    setProducts(prodArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierMappedMerchandise]);

  const allCheckedHandle = (e) => {
    const { checked } = e.target;
    let arr = [...products];
    // eslint-disable-next-line array-callback-return
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = checked;
      });
    setProducts(arr);
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...products];

    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === parseInt(name)) {
        arr[i].isChecked = checked;
      }
    });
    setProducts(arr);
  };

  const inputChangeHandler = (e) => {
    let skuValue = (e.target.value = e.target.value.toUpperCase());
    let index = e.target.name;
    let arr = [...products];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === parseInt(index)) {
        arr[i].supplierSKU = skuValue;
      }
    });
    setProducts(arr);
  };

  // Discount Input handler
  // const discountInputHandler = (e) => {
  //   let discountValue = e.target.value;
  //   let index = e.target.name;
  //   setDiscountIndex(parseInt(index));
  //   let arr = [...products];
  //   let regex = /^\d{0,2}(\.\d{0,2})?$/;
  //   if (regex.test(e.target.value)) {
  //     arr.map((item, i) => {
  //       if (i === parseInt(index)) {
  //         arr[i].discountToClient = discountValue;
  //       }
  //     });
  //     setProducts(arr);
  //   }
  // };

  const priceInputeHandler = (e) => {
    let supplierPriceValue = e.target.value;
    let index = e.target.name;

    let arr = [...products];
    let regex = /^\d{0,6}(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      // eslint-disable-next-line array-callback-return
      arr.map((item, i) => {
        // let discountPrice = parseFloat(item.discountToClient);
        // let organizationPrice = parseFloat(supplierPriceValue);
        // let percentageValue = organizationPrice ? ((discountPrice / 100) * organizationPrice).toFixed(
        //   2
        // ) : 0;
        // let hadayaPrice = organizationPrice - percentageValue;

        if (i === parseInt(index)) {
          arr[i].supplierPrice = supplierPriceValue;
          // arr[i].hadayaPrice = hadayaPrice.toFixed(2);
        }
      });
      setProducts(arr);
    }
  };

  // const [hadayaPrice, setHadayaPrice] = useState(0)
  // Discount calculation
  // useEffect(() => {
  //   let arr;
  //   let identifier = setTimeout(() => {
  //     arr = [...products];

  //     arr.map((item, i) => {
  //       let discountPrice = item.discountToClient;
  //       let supplierPrice = item.supplierPrice;
  //       let percentageValue = ((discountPrice / 100) * supplierPrice).toFixed(
  //         2
  //       );
  //       let hadayaPrice = supplierPrice - percentageValue;
  //       setHadayaPrice(hadayaPrice)
  //       let regex = /^\d{0,3}(\.\d{0,2})?$/;
  //       if (i === discountIndex && i === supplierIndex) {
  //         arr[i].hadayaPrice = hadayaPrice;
  //       }
  //     });
  //   }, 500);

  //   return () => {
  //     clearTimeout(identifier);
  //   };
  // }, [discountIndex, supplierIndex, products]);

  // useEffect(() => {

  // }, [hadayaPrice])

  useEffect(() => {
    dispatch(fetchSupplierMapMerchandise(location.state?.partnerId));
  }, [location.state?.partnerId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!location.state?.partnerId) {
      history("/supplier-mapping-dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplier]);

  const goBack = () => {
    history("/supplier-mapping-dashboard");
  };

  // const addRow = (item, index) => {
  //   let arr = [...products];

  //   let obj = {
  //     ...item,
  //     supplier: "",
  //     supplierSKU: "",
  //     discountToClient: "",
  //     supplierPrice: "",
  //     isChecked: false,
  //   };

  //   arr.unshift(obj);
  //   setProducts(arr);
  // };

  const deleteRow = (index) => {
    let arr = [...products];
    if (index > -1) {
      arr.splice(index, 1);
    }
    setProducts(arr);
    if (arr.length === 0) {
      setShowHideCheckBox(false);
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </a>
  ));

  const validate = () => {
    let checkArr = [];

    // eslint-disable-next-line array-callback-return
    products &&
      products.map((item) => {
        if (item.isChecked) {
          checkArr.push(item);
        }
      });
    if (checkArr.length > 0) {
      //  let valSKU = checkArr && checkArr.every(v => v.supplierSKU);
      let valPrice = checkArr && checkArr.every((v) => v.supplierPrice);

      valPrice
        ? setError({ supplierPrice: "" })
        : setError({ supplierPrice: "Enter supplier price" });

      return valPrice ? true : false;
    } else {
      toggleShowToast();
      return false;
    }
  };

  const supplierMerchandiseMappingSuccess = () => {
    return history("/supplier-map-successfull");
  };

  const apiCallFailed = () => {
    console.log("Something Went Wrong");
  };

  const submit = () => {
    let value = validate();

    if (value) {
      let selectedProducts = [];
      // eslint-disable-next-line array-callback-return
      products &&
        products.map((item) => {
          if (item.isChecked === true) {
            selectedProducts.push(item);
          }
        });
      if (selectedProducts.length > 0) {
        dispatch(
          putSupplierMerchandiseMappingProd(
            selectedProducts,
            supplierMerchandiseMappingSuccess,
            apiCallFailed
          )
        );
      }
    }
  };
  const trimIdHandler = (item) => {
    let id = item;
    let val = id?.slice(0, 12);
    return val;
  };

  const showModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowModal(true);
  };

  return (
    loading === true ? (
      <div className="d-flex flex-column justify-content-center align-item-center">
        <h5 className="text-center">
          <PulseLoader type={"data"} />
        </h5>
      </div>
    ) : (
      <div className="d-flex flex-column justify-content-between gap-3 py-4 pb-3 px-4 bg-light">
        <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
          <div className="d-flex flex-row align-items-center pb-2 gap-3">
            <LeftIcon onClick={() => goBack()} className="cursor" />
            <span className="font-bold font-32 my-2">Supplier Mapping</span>
          </div>
        </div>

        <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100 gap-3">
          <div className="card-body d-flex justify-content-between flex-row">
            <div className="d-flex gap-3 my-2">
              <Tag />
              <span className="font-20 font-bold">Mapping merchandise</span>
            </div>
            {/* <div>
            <BackButton onClick={() => history(-1)} />
          </div> */}
          </div>
          <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
            <table responsive="xl" cellPadding={4} cellSpacing={0}>
              <tbody>
                <tr className="border-bottom">
                  <td className="col-2">
                    <div className="d-flex flex-row justify-content-start text-dark gap-3">
                      {showHideCheckBox === true ? (
                        <span>
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="checkboxNoLabel"
                            checked={
                              products.filter(
                                (product) => product?.isChecked !== true
                              ).length < 1
                            }
                            onChange={allCheckedHandle}
                          />
                        </span>
                      ) : null}

                      <span className="font-12 font-boldest text-muted">
                        Name
                      </span>
                    </div>
                  </td>
                  <td className="col-2 px-4">
                    <div>
                      <span className="font-12 font-boldest text-muted">
                        Supplier name
                      </span>
                    </div>
                  </td>
                  <td className="col-3">
                    <div className="d-flex justify-content-center">
                      <span className="font-12 font-boldest text-muted">
                        Supplier SKU{" "}
                        <InfoTooltip
                          title={"Enter the product ID from the supplier"}
                        />
                      </span>
                    </div>
                  </td>
                  {/* <td className="col-2">
                  <div>
                    <span className="font-12 font-boldest text-muted">
                      Supplier
                      <br />
                      discount
                    </span>
                  </div>
                </td> */}
                  <td className="col-3">
                    <div className="d-flex justify-content-center">
                      <span className="font-12 font-boldest text-muted">
                        Supplier price
                      </span>
                    </div>
                  </td>
                  {/* <td className="col-2">
                  <div>
                    <span className="font-12 text-success font-boldest">
                      Hadaya
                      <br /> price
                    </span>
                  </div>
                </td> */}
                </tr>
                {/* {products && products.map((item, index) => ( */}
                {products &&
                  products?.map((item, index) => (
                    <tr key={index} className="gap-2 border-bottom">
                      <td className="col-4">
                        <div className="d-flex flex-row justify-content-start align-items-center py-3 gap-2 ">
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="checkboxNoLabel"
                            checked={item.isChecked}
                            name={index}
                            onChange={handleCheck}
                          />
                          {item?.product?.product?.product_variants_flag ===
                            true ? (
                            <img
                              src={
                                item?.product?.defaultImage
                                  ? item?.product?.defaultImage
                                  : product_default
                              }
                              alt="not found"
                              id="product-thumbnail"
                            />
                          ) : (
                            <img
                              src={
                                item?.product?.product?.images &&
                                  item?.product?.product?.images[0]?.file_url
                                  ? item?.product?.product?.images[0]?.file_url
                                  : product_default
                              }
                              alt="not found"
                              id="product-thumbnail"
                            />
                          )}
                          <div className="d-flex flex-column mt-2">
                            <span
                              className="font-bold text-dark font-15 d-inline-block text-truncate cursor"
                              title={item?.product?.product?.default_title}
                              style={{ maxWidth: "140px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              onClick={() =>
                                showModel(item?.product?.product?.id)
                              }
                            >
                              {item?.product?.product?.default_title}
                            </span>
                            {item?.product?.variantValues && (
                              <span
                                className="font-12 text-muted visible cursor"
                                title={
                                  item?.product?.variantValues &&
                                  item?.product?.variantValues.join("|")
                                }
                              >
                                {trimIdHandler(
                                  item?.product?.variantValues &&
                                  item?.product?.variantValues.join("|")
                                )}
                                <span>...</span>
                              </span>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="col-2 px-4">
                        <div className="d-flex align-items-center font-12">
                          <span
                            className="font-bold text-dark mb-3 d-inline-block text-truncate cursor"
                            title={item?.supplier?.supplierName}
                            style={{ maxWidth: "100px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {item?.supplier?.supplierName}
                          </span>
                        </div>
                      </td>
                      <td className="col-2 p-2">
                        <div className="d-flex ">
                          <InputField
                            name={index}
                            placeholder="SKU"
                            type="text"
                            maxLength={25}
                            value={item.supplierSKU}
                            onChange={inputChangeHandler}
                          />
                        </div>
                      </td>
                      {/* <td className="col-2 p-2">
                      <div className="d-flex w-45">
                        <InputGroupTxt
                          name={index}
                          placeholder={"Discount"}
                          type="text"
                          title={"%"}
                          lang={props?.lang}
                          maxLength={6}
                          value={item.discountToClient}
                          onChange={discountInputHandler}
                        />
                      </div>
                    </td> */}
                      <td className="col-2 p-2">
                        <div className="d-flex">
                          <InputGroupTxt
                            name={index}
                            title={
                              item?.product?.country?.currency_symbol
                            }
                            placeholder={"Price"}
                            type="text"
                            lang={props?.lang}
                            maxLength={6}
                            value={item.supplierPrice}
                            onChange={priceInputeHandler}
                          />
                        </div>
                        <div className="d-flex">
                          {!item.supplierPrice && item.isChecked ? (
                            <label className="text-error-client-supplier">
                              {error.supplierPrice}
                            </label>
                          ) : null}
                        </div>
                      </td>

                      <td className="col-3 p-2">
                        <div className="d-flex text-success justify-content-between align-items-center">
                          {/* <div>
                          {item.hadayaPrice > 0 && item.hadayaPrice}
                        </div> */}
                          <div className="d-flex flex-row px-2 cursor gap-2">
                            <Dropdown>
                              <Dropdown.Toggle as={CustomToggle} />
                              <Dropdown.Menu size="sm" title="">
                                <Dropdown.Item
                                  className="cursor"
                                  onClick={() => deleteRow(index)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex">
          <Button type="primary" text="Update" onClick={() => submit()} />
        </div>

        <ModalComp
          size="lg"
          showModal={showModal}
          // handleClose={handleClose}
          component={
            <ModelForMerchandiseSummery
              handleClose={handleClose}
              merchandiseSummery={merchandiseSummery}
            />
          }
        />
        <div
          className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
          id="liveToast"
        >
          <Toasts
            position={"bottom-center"}
            showToast={showToast}
            toggleShowToast={toggleShowToast}
            warningMsg="Please select giftcards/products to map."
            imageType={"warning"}
          />
        </div>
      </div>
    )
  );
};

export default MerchandiseSKUEditMapping;
