 
// DEV
export const baseUrl = "https://dev-api.ehadaya.io"
export const USER_AUTHORIZATION = "https://authdev-api.ehadaya.io"
// export const baseUrl = "http://15.185.134.17:8000"
// export const USER_AUTHORIZATION = "http://15.185.134.17:8020"


// UAT
// export const baseUrl = "http://15.185.134.17:7000"
// export const USER_AUTHORIZATION = "http://15.185.134.17:7020"
 
export const getToken = () => {
    let token = localStorage.getItem("token");
    const removed = token.replace(/"/g, '');
    return removed;
}
export const getUser = () => {
    let user = localStorage.getItem("user");
    return JSON.parse(user);
}
//export const TOKEN = getToken();

export const BASE_URL = "https://dev-api.ehadaya.io";
export const AUTH_URL = "https://authdev-api.ehadaya.io";


//Giftcard
export const GET_GIFTCARD = BASE_URL + "/v1/giftcards";
export const POST_GIFTCARD = BASE_URL + "/v1/giftcards";
export const GET_GIFTCARD_VIEW = BASE_URL + "/v1/giftcards/{id}/view";
export const GET_GIFTCARD_BY_ID = BASE_URL + "/v1/giftcards/{id}";
export const PATCH_GIFTCARD_BY_ID = BASE_URL + "/v1/giftcards/{id}";
export const POST_GIFTCARD_FILES = BASE_URL + "/v1/giftcards/files";
export const GET_GIFTCARD_FILES = BASE_URL + "/v1/giftcards/{giftcard_id}/files";
export const GET_GIFTCARD_DENOMINATIONS_DETAILED_LIST = BASE_URL + "/v1/giftcards/denomination/list";

// GiftcardsSupplierMapping
export const POST_GIFTCARD_SUPPLIER_MAPPING = BASE_URL + "/v1/giftcards/supplier_mapping";
export const GET_GIFTCARD_SUPPLIER_MAPPING = BASE_URL + "/v1/giftcards/{giftcard_id}/supplier_mapping";
export const GET_SUPPLIER_GIFTCARD_MAPPING = BASE_URL + "/v1/giftcards/supplier_mapping/{supplier_id}";


// GiftcardClientMapping
export const POST_GIFTCARD_CLIENT_MAPPING = BASE_URL + "/v1/giftcards/client/mapping";
export const GET_GIFTCARD_CLIENT_MAPPING = BASE_URL + "/v1/giftcards/{giftcard_id}/client/mapping";
export const GET_CLIENT_GIFTCARD_MAPPING = BASE_URL + "/v1/giftcards/client/{client_id}/mapping";

// ClientMappedGiftcards
export const GET_CLIENT_MAPPED_GIFTCARDS = BASE_URL + "/v1/giftcards/client/{client_id}/mappedProducts";


// gift_card_length
export const GET_GIFTCARD_LENGTH = BASE_URL + "/gift_card_length";

// giftcard_prefix
export const GET_GIFTCARD_PREFIX = BASE_URL + "/giftcard_prefix";

// warranty
export const GET_WARRANTY = BASE_URL + "/warranty";

// vat
export const GET_VAT = BASE_URL + "/vat";

// weight
export const GET_WEIGHT = BASE_URL + "/weight";


// Merchandise
export const GET_MERCHANDISE = BASE_URL + "/v1/merchandise";
export const POST_MERCHANDISE = BASE_URL + "/v1/merchandise";
export const GET_MERCHANDISE_BY_ID = BASE_URL + "/v1/merchandise/{id}";
export const PATCH_MERCHANDISE_BY_ID = BASE_URL + "/v1/merchandise/{id}";
export const GET_MERCHANDISE_VARIANTS_DETAILED_LIST = BASE_URL + "/v1/merchandise/variants/list";


// MerchandiseVariants
export const GET_MERCHANDISE_VARIATION_VALUES = BASE_URL + "/v1/merchandise/variation/values";
export const POST_MERCHANDISE_VARIATION_VALUES = BASE_URL + "/v1/merchandise/variation/values";
export const POST_MERCHANDISE_VARIANTS = BASE_URL + "/v1/merchandise/variants";
export const GET_MERCHANDISE_VARIANTS = BASE_URL + "/v1/merchandise/{merchandise_id}/variants";
export const GET_MERCHANDISE_VARIANTS_BY_ID = BASE_URL + "/v1/merchandise/variants/{id}";

// MerchandiseImages
export const POST_MERCHANDISE_FILES = BASE_URL + "/v1/merchandise/files";
export const GET_MERCHANDISE_FILES = BASE_URL + "/v1/merchandise/{merchandise_id}/files";

// MerchandiseSupplierMapping
export const POST_MERCHANDISE_SUPPLIER_MAPPING = BASE_URL + "/v1/merchandise/supplier/mapping";
export const GET_MERCHANDISE_SUPPLIER_MAPPING = BASE_URL + "/v1/merchandise/{merchandise_id}/supplier/mapping";
export const GET_SUPPLIER_MERCHANDISE = BASE_URL + "/v1/merchandise/{merchandise_id}/supplier";
export const GET_SUPPLIER_MERCHANDISE_MAPPING = BASE_URL + "/v1/merchandise/supplier/{supplier_id}/mapping";


// MerchandiseClientMapping
export const POST_MERCHANDISE_CLIENT_MAPPING = BASE_URL + "/v1/merchandise/client/mapping";
export const GET_MERCHANDISE_CLIENT_MAPPING = BASE_URL + "/v1/merchandise/{merchandise_id}/client/mapping";
export const GET_CLIENT_MERCHANDISE_MAPPING = BASE_URL + "/v1/merchandise/client/{client_id}/mapping";

// ClientMappedMerchandise
export const GET_CLIENT_MAPPED_MERCHANDISE_VARIANTS = BASE_URL + "/v1/merchandise/client/{client_id}/mappingProducts";

// BrandMapping
export const POST_BRAND_BRAND_CATEGORY_MAPPING = BASE_URL + "/v1/brand/brand_category/mapping";
export const GET_BRAND_BRAND_CATEGORY_MAPPING_BY_BRAND_ID = BASE_URL + "/v1/brand/{brand_id}/brand_category/mapping";
export const GET_BRAND_BRAND_CATEGORY_MAPPING_BY_BRAND_CATEGORY_ID = BASE_URL + "/v1/brand/brand_category/{brand_category_id}/mapping";
export const GET_BRAND_BRAND_CATEGORY_FOR_BRAND = BASE_URL + "/v1/brand/{brand_id}/brand_category";


// Brand
export const GET_BRANDS = BASE_URL + "/v1/brand";
export const POST_BRANDS = BASE_URL + "/v1/brand";
export const PATCH_BRANDS_BY_ID = BASE_URL + "/v1/brand/{id}";


// BrandCategory
export const GET_BRAND_CATEGORIES = BASE_URL + "/v1/brand_category";
export const POST_BRAND_CATEGORIES = BASE_URL + "/v1/brand_category";
export const PATCH_BRAND_CATEGORIES_BY_ID = BASE_URL + "/v1/brand_category/{id}";

//@@@@@@@@@@//NotInUseAPIs@@@@@@@@@@@
// ClientProductSupplierMapping
// Download Sample Xlx &  ManualInventory 1st GET are Same
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


// GiftCardDenominations
export const POST_GIFTCARD_DENOMINATION = BASE_URL + "v1/giftcards/denomination";
export const GET_GIFTCARD_DENOMINATION_ALL = BASE_URL + "/v1/giftcards/denomination/test";
export const GET_GIFTCARD_DENOMINATION_BY_GIFTCARD_ID = BASE_URL + "/v1/giftcards/{giftcard_id}/denomination";
export const GET_GIFTCARD_DENOMINATION_BY_DENOMINATION_ID = BASE_URL + "/v1/giftcards/denomination/{denomination_id}";

// ManualInventory
export const GET_MANUAL_INVENTORY_SAMPLE_FILE = BASE_URL + "/v1/giftcard/manual_inventory/sample_file";
export const GET_MANUAL_INVENTORY = BASE_URL + "/v1/giftcard/manual_inventory";
export const POST_MANUAL_INVENTORY_FILE = BASE_URL + "/v1/giftcard/manual_inventory";

// Download Sample Xlx
// export const DOWNLOAD_MANUAL_INVENTORY_SAMPLE_FILE = BASE_URL + "/v1/giftcard/manual_inventory/sample_file";

// Stores
export const GET_STORES = BASE_URL + "/v1/stores";
export const POST_STORE = BASE_URL + "/v1/stores";
export const GET_STORE_BY_ID = BASE_URL + "/v1/stores/{id}";
export const PATCH_STORE_BY_ID = BASE_URL + "/v1/stores/{id}";


// ProductTransaction
export const GET_PRODUCT_TRANSACTIONS = BASE_URL + "/v1/product_transaction";
export const POST_PRODUCT_TRANSACTION = BASE_URL + "/v1/product_transaction";
export const GET_PRODUCT_TRANSACTION_BY_ID = BASE_URL + "/v1/product_transaction/{id}";

// Organization
export const GET_ORGANIZATION = BASE_URL + "/v1/organization";
export const POST_ORGANIZATION = BASE_URL + "/v1/organization";
// export const GET_ORGANIZATION_BY_ID = BASE_URL + "/v1/organization/{id}";
export const PATCH_ORGANIZATION_BY_ID = BASE_URL + "/v1/organization/{id}";

// InventoryType
export const GET_INVENTORY_TYPES = BASE_URL + "/v1/inventory_type";
// export const POST_INVENTORY_TYPE = BASE_URL + "/v1/inventory_type";


// Partner
export const POST_PARTNER = BASE_URL + "/partner";
export const GET_PARTNER_BY_ID = BASE_URL + "/partner/{id}";
export const PATCH_PARTNER_BY_ID = BASE_URL + "/partner/{id}";


// PartnerFloats
export const POST_CLIENT_FLOAT = BASE_URL + "/v1/partner/float";
export const GET_CLIENT_FLOATS = BASE_URL + "/v1/partner/{partner_id}/float";
export const PATCH_CLIENT_FLOAT_BY_ID = BASE_URL + "/v1/partnerfloat/{id}";


// PartnerFloatTransactions
export const POST_CLIENT_FLOAT_TRANSACTION = BASE_URL + "/v1/partner/float/transaction";
export const GET_CLIENT_FLOAT_TRANSACTIONS_FOR_A_CURRENCY = BASE_URL + "/v1/partnerfloat/{partner_float_id}/transaction";
export const GET_CLIENT_FLOAT_TRANSACTIONS_FOR_ALL_CURRENCIES = BASE_URL + "/v1/partner{partner_id}/float/transaction";


// Fetch Country
export const GET_ALL_COUNTRIES = BASE_URL + "/fetchcountry/";
export const GET_COUNTRIES = BASE_URL + "/fetchcountry/{countrycode}";


// tags
export const GET_TAGS = BASE_URL + "/tags/";

