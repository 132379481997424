import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import { useSelector, useDispatch } from "react-redux";
import Toasts from "../../../components/Toasts/Toasts";
// import { getStoreList } from "../../../Redux/CreateStore/createStoreAction";
import { fetchAllGiftCardList } from "../../../Redux/GiftCard/giftcardAction";
import { removeCreateOrderData } from "../../../Redux/CreateOrder/CreateOrderAction";
import {
  getAllOrganizationList,
  getOrganizationList,
  deleteOrganizationList,
} from "../../../Redux/Organizations/organizationAction";
import {
  getStoreListMapToOrganization,
  getStoreListMapToOrgDelete,
  setSelectedStoreOrOrgData,
} from "../../../Redux/CreateStore/createStoreAction";
import { useTranslation } from "react-i18next";

const GiftCardDistribution = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputElementDist = useRef(null);
  const inputElementOrg = useRef(null);
  const inputElementStore = useRef(null);

  const organizationState = useSelector(
    (state) => state?.organizations?.organizationList
  );

  const storeListByOrgnazationId = useSelector(
    (state) => state.createStore?.storeListByOrgnazationId
  );

  const [fields, setFields] = useState({
    distributeTo: { name: "Organization" },
    organizationList: "",
    storeList: "",
  });

  const [error, setError] = useState({
    giftcard: "",
    distributeType: "",
    organization: "",
    store: "",
  });

  const distributeTo = [{ name: "Organization" }, { name: "Client" }];
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(removeCreateOrderData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fields?.distributeTo?.name === "Organization") {
      dispatch(getOrganizationList());
    }
  }, [fields?.distributeTo]);

  useEffect(() => {
    if (!fields?.organizationList) {
      dispatch(getStoreListMapToOrgDelete());
      setFields({ ...fields, storeList: "" });
    }
  }, [fields?.organizationList, fields?.distributeTo]);

  const goToCardDist = () => {
    if (fields?.distributeTo?.name === "Organization") {
      if (!fields?.organizationList) {
        setError({
          organizationList: "Please select organization from organization list",
        });
        if (inputElementOrg.current) {
          inputElementOrg.current.focus();
        }
        return false;
      } else if (!fields?.storeList) {
        if (!storeListByOrgnazationId.length) {
          toggleShowToast();
        }
        setError({
          storeList: "Please select store from store list",
        });
        if (inputElementStore.current) {
          inputElementStore.current.focus();
        }
        return false;
      } else {
        dispatch(fetchAllGiftCardList());
        dispatch(
          setSelectedStoreOrOrgData({
            selectedOrganization: fields?.organizationList,
            seletedStore: fields?.storeList,
            type: "organization",
          })
        );

        history("/card-distribution-store", {
          state: {
            selectedOrganization: fields?.organizationList,
            seletedStore: fields?.storeList,
            type: "organization",
          },
        });
      }
    } else if (fields?.distributeTo?.name === "Client") {
      history("/client-distribution-dashboard");
      return;
    } else {
      setError({
        distributeType: "Please select distribute to",
      });
      if (inputElementDist.current) {
        inputElementDist.current.focus();
      }
      return false;
    }
  };

  const goTonextStep = () => {
    history("/patner-creation");
  };

  const handleChangeFields = (e) => {
    setFields({
      ...fields,
      distributeTo: e,
      organizationList: "",
      storeList: "",
    });
  };

  const handleChangeOrganizationList = (e) => {
    if (e) {
      dispatch(getStoreListMapToOrganization(e?.id));
    }

    setFields({ ...fields, organizationList: e, storeList: "" });
    setError({
      storeList: "",
    });
  };

  const handleChangeStoreList = (e) => {
    setFields({ ...fields, storeList: e });
  };

  return (
    <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">{t("Card distribution")}</span>
        </div>
        <div className="d-flex flex-row">
          <Button
            type="primary"
            text={t("Create new organization")}
            onClick={goTonextStep}
          />
        </div>
      </div>

      <div className="d-flex flex-column width-65">
        <div className="bg-white shadow-sm rounded-3 py-3 px-4">
          <div className="d-flex flex-column gap-4 mt-4 mb-3 w-50">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Distribute to")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Select organization or client"} />
              </div>
              <div>
                <Select
                  ref={inputElementDist}
                  placeholder={t("Select distribute to")}
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(distributeTo) => distributeTo?.name}
                  options={distributeTo}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.distributeTo}
                  onChange={handleChangeFields}
                  isClearable
                />
              </div>
              {!fields?.distributeTo ? (
                <label className="text-error">{error?.distributeType}</label>
              ) : null}
            </div>

            {fields?.distributeTo?.name === "Organization" ? (
              <div className="d-flex flex-column gap-2">
                <div>
                  <Select
                    ref={inputElementOrg}
                    noOptionsMessage={() => "Not found"}
                    placeholder={t(
                      "Select organization from organization list"
                    )}
                    getOptionLabel={(organizationState) =>
                      organizationState?.partner_name
                    }
                    options={organizationState}
                    styles={CUSTOM_DROPDOWN_STYLE}
                    value={fields?.organizationList}
                    onChange={handleChangeOrganizationList}
                    isClearable
                  />
                </div>
                {!fields?.organizationList && error?.organizationList ? (
                  <label className="text-error">
                    {error?.organizationList}
                  </label>
                ) : null}
              </div>
            ) : null}

            {fields?.distributeTo?.name === "Organization" &&
            fields?.organizationList !== "" &&
            storeListByOrgnazationId?.length > 0 ? (
              <div className="d-flex flex-column gap-2">
                <div>
                  <Select
                    ref={inputElementStore}
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select store from store list")}
                    getOptionLabel={(storeListByOrgnazationId) =>
                      storeListByOrgnazationId?.store_name
                    }
                    options={storeListByOrgnazationId}
                    styles={CUSTOM_DROPDOWN_STYLE}
                    value={fields?.storeList}
                    onChange={handleChangeStoreList}
                    isClearable
                  />
                </div>
                {!fields?.storeList && error?.storeList ? (
                  <label className="text-error">{error?.storeList}</label>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex py-3">
        <Button type="primary" text={t("Continue")} onClick={goToCardDist} />
      </div>

      <div
        className="position-fixed bottom-0 start-50 translate-middle-x mb-2 w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="You have no store mapped to selected organization."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default GiftCardDistribution;
