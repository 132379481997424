import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as CloseIcone } from "../../assests/svg/close.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import InputField from "../../components/InputField/InputField";
import TextEditor from "../../components/InputField/TextEditor";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import { fetchBrand } from "../../Redux/Brands/brandAction";
import { getAllLanguage } from "../../Redux/Language/languageAction";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import { EditorState, convertToRaw } from "draft-js";
import { useDispatch, useSelector } from "react-redux";
import { setMerchandiseNameDescription } from "../../Redux/Merchandise/merchandiesAction";
import Toasts from "../../components/Toasts/Toasts";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import { useTranslation } from "react-i18next";

const MerchandiseDescription = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  const inputElementTitle = useRef(null)
  const inputElementDesc = useRef(null)

  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchBrand());
    dispatch(getAllLanguage());
    dispatch(getAllCountriesList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Preferredlanguage = useSelector((state) => state?.languages?.languages);
  const mercandiseNameDesc = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseNameDes
  );

  let defaultLang = localStorage.getItem('defaultLang')
  const edittorTxt = {
    value: EditorState.createEmpty(),
    lang: JSON.parse(defaultLang),
  };

  const constProdTitle = {
    title: "",
    lang: JSON.parse(defaultLang)
  };

  const [prodTitles, setProdTitles] = useState(mercandiseNameDesc?.prodTitles
    ? mercandiseNameDesc?.prodTitles
    : [constProdTitle]);

  const [editorState, setEditorState] = useState(
    mercandiseNameDesc?.description
      ? mercandiseNameDesc?.description
      : [edittorTxt]
  );

  const [prodDescription, setProdDescription] = useState({
    language: mercandiseNameDesc?.preferredLanguage
      ? mercandiseNameDesc?.preferredLanguage
      : JSON.parse(defaultLang),
    prodTitle: mercandiseNameDesc?.productTitle
      ? mercandiseNameDesc?.productTitle
      : "",
    keyBenefits1: "",
    keyBenefits2: "",
    keyBenefits3: "",
    keyBenefits4: "",
  });
  const [keybenefits, setKeyBenefits] = useState([]);
  const [error, setError] = useState({
    language: "",
    prodTitle: "",
    title: "",
    editorState: "",
    keyBenefits1: "",
    keyBenefits2: "",
    keyBenefits3: "",
    keyBenefits4: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSetKeybenefits = (e) => {
    let data = {
      id: e.target.name,
      name: e.target.value,
    };
    setKeyBenefits({ ...keybenefits, [e.target.name]: data });
  };

  const handleInputChangeState = (e) => {
    const { name, value } = e.target;
    setProdDescription({
      ...prodDescription,
      [name]: value,
    });
  };

  const getDescription = (txt, code) => {
    let arr = [...editorState];
    setError({ editorState: "" });
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (code === item?.lang?.language_code) {
        arr[i].value = txt;
      }
    });
    setEditorState(arr);
  };

  const handleInputChangeProdTitle = (e) => {
    const { name, value } = e.target;
    let arr = [...prodTitles];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (name === item?.lang?.language_code) {
        arr[i].title = value;
      }
    });
    setProdTitles(arr);
  }

  const handleAddClick = (e) => {
    if (
      editorState.some((item) => item?.lang?.language_code === e.language_code)
    ) {
      // alert("Already added");
      toggleShowToast();
      return;
    } else {
      setEditorState([
        ...editorState,
        { value: EditorState.createEmpty(), lang: e },
      ]);
    }
  };

  const handleAddClickProdTitle = (e) => {
    if (
      prodTitles.some((item) => item?.lang?.language_code === e.language_code)
    ) {
      // alert("Already added");
      toggleShowToast();
      return;
    } else {
      setProdTitles([
        ...prodTitles,
        { title: "", lang: e },
      ]);
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...editorState];
    list.splice(index, 1);
    setEditorState(list);
  };

  const handleRemoveClickProdTitle = (index) => {
    const list = [...prodTitles];
    list.splice(index, 1);
    setProdTitles(list);
  };

  const handleDropdownOnClick = (obj) => {
    handleAddClick(obj);
  };

  const handleChangeValue = (e) => {
    setProdDescription({ ...prodDescription, language: e });
    e && handleAddClickProdTitle(e);
    e && handleDropdownOnClick(e);
  };

  const validate = () => {
    let value = convertToRaw(editorState[0].value.getCurrentContent());
    // if (!prodDescription.prodTitle) {
    //   setError({ prodTitle: t("Please enter product title") });
    //   return false;
    // }
    if (!prodTitles[0].title) {
      setError({ title: t("Please enter product title") });
      if(inputElementTitle.current){
        inputElementTitle.current.focus()
      }
      return false;
    } else if (!value.blocks[0].text) {
      setError({ editorState: t("Please provides description") });
      if(inputElementDesc.current){
        inputElementDesc.current.focus()
      }
      return false;
    }
    return true;
  };

  const submit = () => {
    let value = validate();

    if (value) {
      let obj = {
        preferredLanguage: prodDescription?.language,
        productTitle: prodDescription?.prodTitle,
        prodTitles: prodTitles,
        keyBenefits: keybenefits,
        description: editorState,
      };
      dispatch(setMerchandiseNameDescription(obj));
      history("/merchandise-attribute");
    }
  };

  const goBack = () => {
    history("/merchandise-dashboard");
  };


  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Add merchandise")}</span>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between gap-2">
        <div className="d-flex w-100">
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <Tag />
                <span className="font-20 font-bold">{t("Name & Description")}</span>
              </div>
            </div>

            <div className="d-flex flex-column gap-4 mt-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Preferred language")}</span>
                  <InfoTooltip
                    title={"Select Preferred language"}
                  />
                </div>

                <div>
                  <Select
                    placeholder={t("Select preferred language")}
                    noOptionsMessage={() => "Not found"}
                    getOptionLabel={(Preferredlanguage) =>
                      Preferredlanguage.language
                    }
                    options={Preferredlanguage}
                    styles={CUSTOM_DROPDOWN_STYLE}
                    value={prodDescription?.language}
                    onChange={handleChangeValue}
                    isClearable
                  />
                </div>

              </div>
              {prodTitles.map((item, index) => (
                <div className="d-flex flex-column gap-3" key={index}>
                  <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Product title")}  #{index + 1} {item?.lang?.language}<span className="text-danger">*</span>
                      </span>
                      <InfoTooltip
                        title={"Enter Product title"}
                      />
                    </div>
                    {prodTitles.length !== 1 && (
                      <div
                        className="cursor"
                        onClick={() => handleRemoveClickProdTitle(index)}
                      >
                        {index !== 0 && <CloseIcone />}
                      </div>
                    )}
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <InputField
                    inputRef={inputElementTitle}
                      onChange={handleInputChangeProdTitle}
                      name={item?.lang?.language_code}
                      placeholder={`${t("Product title")} ${(item?.lang?.language.toLocaleLowerCase())}`}
                      type="text"
                      value={item.title}
                    />
                    {!item.title && error.title ? (
                      <label className="text-error">{error.title}</label>
                    ) : null}
                  </div>
                </div>
              ))}
              <div className="d-flex ">
                <div className="btn-group w-100">
                  <div
                    className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                    data-bs-toggle="dropdown"
                  >
                    <span>{t("Add other language for product title")}</span>
                  </div>
                  <ul className={`dropdown-menu rounded-card-7 dropdown-menu-start ${props?.lang === 'ar' ? 'dropdown-menu-ar' : ''}`}>
                    {Preferredlanguage &&
                      Preferredlanguage.map((item, i) => (
                        <li key={i} onClick={() => handleAddClickProdTitle(item)}>
                          <div className="dropdown-item cursor">
                            {item.language}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              {/* <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    Product title<span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <div className="d-flex flex-column gap-1">
                  <InputField
                    onChange={handleInputChangeState}
                    name="prodTitle"
                    placeholder={"Product title"}
                    type="text"
                    value={prodDescription.prodTitle}
                  />
                  {!prodDescription.prodTitle && error.prodTitle ? (
                    <label className="text-error">{error.prodTitle}</label>
                  ) : null}
                </div>
              </div> */}

              {editorState.map((item, i) => (
                <div className="d-flex flex-column gap-3" key={i}>
                  <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Description")} #{i + 1} {item?.lang?.language}
                        <span className="text-danger">
                          {i === 0 ? "*" : ""}
                        </span>
                      </span>
                      <InfoTooltip
                        title={
                          "Enter Description"
                        }
                      />
                    </div>
                    {editorState?.length !== 1 && (
                      <div
                        className="cursor"
                        onClick={() => handleRemoveClick(i)}
                      >
                        {i !== 0 && <CloseIcone />}
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <TextEditor
                    inputRef={inputElementDesc}
                      code={item?.lang?.language_code}
                      editorState={item.value}
                      getDescription={getDescription}
                      lang={props?.lang}
                    />
                    {error.editorState && i === 0 ? (
                      <label className="text-error">{error.editorState}</label>
                    ) : null}
                  </div>
                </div>
              ))
              }

              <div className="d-flex ">
                <div className="btn-group w-100">
                  <div
                    className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                    data-bs-toggle="dropdown"
                  >
                    <span>{t("Add other language description")}</span>
                  </div>
                  <ul className={`dropdown-menu rounded-card-7 dropdown-menu-start ${props?.lang === 'ar' ? 'dropdown-menu-ar' : ''}`}>
                    {Preferredlanguage &&
                      Preferredlanguage.map((item, i) => (
                        <li key={i} onClick={() => handleDropdownOnClick(item)}>
                          <div className="dropdown-item cursor">
                            {item.language}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Key benefits")}
                  </span>
                  <InfoTooltip
                    title={"Enter Key benefits"}
                  />
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
                  <InputField
                    onChange={handleSetKeybenefits}
                    name="one"
                    placeholder={t("benefit 1")}
                    type="text"
                  />
                  <InputField
                    onChange={handleSetKeybenefits}
                    name="two"
                    placeholder={("benefit 2")}
                    type="text"
                  />
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
                  <InputField
                    onChange={handleSetKeybenefits}
                    name="three"
                    placeholder={("benefit 3")}
                    type="text"
                  />
                  <InputField
                    onChange={handleSetKeybenefits}
                    name="four"
                    placeholder={("benefit 4")}
                    type="text"
                  />
                </div>
              </div>

              <div className="d-flex">
                <Button
                  onClick={() => submit()}
                  text={t("Save & Next")}
                  type="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Allready Added."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default MerchandiseDescription;
