import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BlueTag } from "../../assests/svg/Tag/blue_tag.svg"
import SwitchButton from "../Button/SwitchButton";
import Button from "../Button/Button";
import InfoTooltip from "../Tooltip/InfoTooltip";
import BackButton from "../Button/BackButton";
import Datepicker from "../DatePicker/Datepicker";
import DragandDrop from "../DragandDrop/DragandDrop";
import { useDispatch, useSelector } from "react-redux";
import { setOtherFiles } from "../../Redux/GiftCard/giftcardAction";
import DragandDropDocs from "../DragandDrop/DragandDropDocs";
import { useTranslation } from "react-i18next";

const ProductOtherFiles = (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const priceState = useSelector((state) => state.giftcard?.cardPrice);
    const otherFilesState = useSelector((state) => state.giftcard?.cardOtherFiles);

    // const [cardExpire, setCardExpire] = useState(false);
    const [documentation, setDocumentations] = useState(otherFilesState?.documentation ? otherFilesState?.documentation : []);
    const [giftCardThumb, setGiftCardThumb] = useState(otherFilesState?.giftCardThumb ? otherFilesState?.giftCardThumb : []);
    // const [date, setDate] = useState({
    //     startDate: otherFilesState?.date?.startDate ? otherFilesState?.date?.startDate : new Date(),
    //     expireDate: otherFilesState?.date?.expireDate ? otherFilesState?.date?.expireDate : new Date(),
    // });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!priceState) {
            history("/product-creation/name-description");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceState]);

    // const handleChangeCardExpire = () => {
    //     setCardExpire(!cardExpire);
    // };

    // const handleChangeValue = (item, type) => {
    //     setDate({ ...date, [type]: item });
    // };

    const submit = () => {
        let obj = {
            documentation: documentation,
            giftCardThumb: giftCardThumb,
            // date: {
            //     startDate: date.startDate,
            //     expireDate: date.expireDate
            // }
        }
        dispatch(setOtherFiles(obj));
        history("/product-creation/term-condition")
    }

    return (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex gap-3">
                    <BlueTag />
                    <span className="font-20 font-bold">{t("Upload Other Files")}</span>
                </div>
                <div>
                    <BackButton onClick={() => history(-1)} />
                </div>
            </div>

            <div className="d-flex flex-column gap-4 mt-4">
                <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">{t("Documentations")}</span>
                        <InfoTooltip title={"Upload document file"} />
                    </div>
                    <div className="d-flex flex-column">
                        <DragandDropDocs files={documentation} setFiles={setDocumentations} />
                        {/* <lable className="text-error">Please upload card images</lable> */}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column gap-4 mt-4">
                <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">{t("Gift card Thumbnail (Leave empty to just use the gift card)")}</span>
                        <InfoTooltip title={"Upload giftcard Thumbnail"} />
                    </div>
                    <div className="d-flex flex-column">
                        <DragandDrop files={giftCardThumb} setFiles={setGiftCardThumb} />
                        {/* <lable className="text-error">Please upload card images</lable> */}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column gap-3 mt-4">
                {/* <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">Card set to expire?</span>
                        <InfoTooltip title={"Maximum 100 characters. No HTML or emoji allowed"} />
                    </div>
                    <SwitchButton onChange={handleChangeCardExpire} value={cardExpire} />
                </div> */}

                {/* {cardExpire ?
                    <div className="d-flex flex-column gap-3">
                        <div className="d-flex flex-row justify-content-start align-items-center gap-2 w-100">
                            <div className="col d-flex flex-column gap-2">
                                <span className="fs-lighter font-14 font-bold">Select start date</span>
                                <Datepicker
                                    onChange={handleChangeValue}
                                    value={date.startDate}
                                    minDate={new Date()}
                                    name={"startDate"} />
                            </div>
                            <div className="col d-flex flex-column gap-2">
                                <span className="fs-lighter font-14 font-bold">Select expire date</span>
                                <Datepicker
                                    onChange={handleChangeValue}
                                    value={date.expireDate}
                                    minDate={date.startDate}
                                    name={"expireDate"} />
                            </div>
                        </div>
                    </div> : null} */}
                <div className="d-flex my-2 mt-2">
                    <Button onClick={() => submit()} text={t("Save & Next")} type="primary" />
                </div>
            </div>
        </div>
    );
};

export default ProductOtherFiles;
