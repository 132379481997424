/* eslint-disable array-callback-return */
import {
  GET_ALL_LANGUAGE_REQUEST,
  GET_ALL_LANGUAGE_SUCCESS,
  GET_ALL_LANGUAGE_FAILURE,
  POST_LANGUAGE_REQUEST,
  POST_LANGUAGE_SUCCESS,
  POST_LANGUAGE_FAILURE,
  PATCH_LANGUAGE_REQUEST,
  PATCH_LANGUAGE_SUCCESS,
  CLEAR_LANGUAGE_LIST,
} from "./languageType";
import { baseUrl, getToken } from "../../utils/prodURL";
import axios from "axios";
import { dateFormatConverter } from "../../utils/constantFunction";

export const getAllLanguageRequest = () => {
  return {
    type: GET_ALL_LANGUAGE_REQUEST,
  };
};
export const getAllLanguageSuccess = (languages) => {
  return {
    type: GET_ALL_LANGUAGE_SUCCESS,
    payload: languages,
  };
};
export const getAllLanguageFailure = (error) => {
  return {
    type: GET_ALL_LANGUAGE_FAILURE,
    payload: error,
  };
};

export const getAllLanguage = () => {
  return (dispatch) => {
    dispatch(getAllLanguageRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/attributes/language`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllLanguageSuccess(response.data.result));
          response.data.result.map((item) => {
            if (item?.language_code.toLowerCase() === "en") {
              localStorage.setItem("defaultLang", JSON.stringify(item));
            }
          });
        }
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          getAllLanguageFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// Post Language
export const postLanguageRequest = () => {
  return {
    type: POST_LANGUAGE_REQUEST,
  };
};

export const postLanguageSuccess = (data) => {
  return {
    type: POST_LANGUAGE_SUCCESS,
    payload: data,
  };
};

export const postLanguageFailure = (error) => {
  return {
    type: POST_LANGUAGE_FAILURE,
    payload: error,
  };
};

export const postLanguage = (obj, languageSuccessfull) => {
  return (dispatch) => {
    dispatch(postLanguageRequest())
    let payload = {
      language_code: obj?.language_code,
      language: obj?.language,
      active_flag: true,
      created_at: dateFormatConverter(obj?.created_at),
      updated_at: dateFormatConverter(obj?.updated_at),
    };

    const config = {
      method: "post",
      url: `${baseUrl}/v1/attributes/language`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload
    };

    axios(config).then((response) => {
      if (response?.status === 200) {
        dispatch(postLanguageSuccess(response?.data?.result))
        dispatch(getAllLanguage());
        languageSuccessfull()
      }
    }).catch((error) => {
      dispatch(postLanguageFailure("Something went wrong!! Please try again!!"))
    })

  };
};


// Patch Language
export const patchLanguageRequest = () => {
  return {
    type: PATCH_LANGUAGE_REQUEST,
  };
};

export const patchLanguageSuccess = (data) => {
  return {
    type: PATCH_LANGUAGE_SUCCESS,
    payload: data
  };
};

export const patchLanguageFailure = (error) => {
  return {
    type: PATCH_LANGUAGE_SUCCESS,
    payload: error
  };
};

export const patchLanguage = (obj, languageSuccessfull) => {
  return (dispatch) => {
    dispatch(patchLanguageRequest())
    let payload = {
      // language_code: obj?.language_code, 
      language: obj?.language,
      active_flag: obj?.active_flag,
      created_at: obj?.created_at,
      updated_at: obj?.updated_at,
    };

    const config = {
      method: "patch",
      url: `${baseUrl}/v1/attributes/language/${obj?.language_code}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload
    };

    axios(config).then((response) => {
      if (response?.status === 200) {
        dispatch(patchLanguageSuccess(response?.data?.result))
        dispatch(getAllLanguage());
        languageSuccessfull()
      }
    }).catch((error) => {
      dispatch(patchLanguageFailure("Something went wrong!! Please try again!!"))
    })

  };
};

export const clearAllLanguageList = () => {
  return {
    type: CLEAR_LANGUAGE_LIST,
  };
};