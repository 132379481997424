/* eslint-disable array-callback-return */
import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";
import {
  CLEAR_MERCHANDISE_LIST,
  GET_MERCHANDISE_PRODLIST_FAILURE,
  GET_MERCHANDISE_PRODLIST_REQUEST,
  GET_MERCHANDISE_PRODLIST_SUCCESS,
  GET_MERCHANDISE_VARIANT_PRICE_REQUEST,
  GET_MERCHANDISE_VARIANT_PRICE_SUCCESS
} from "./MerchadiseAllProductType";

// Merchandise put action types
export const getMerchandiseRequest = () => {
  return {
    type: GET_MERCHANDISE_PRODLIST_REQUEST,
  };
};
export const getMerchandiseSuccess = (data) => {
  return {
    type: GET_MERCHANDISE_PRODLIST_SUCCESS,
    payload: data,
  };
};
export const getMerchandiseFailure = (error) => {
  return {
    type: GET_MERCHANDISE_PRODLIST_FAILURE,
    payload: error,
  };
};

export const getMerchandiseVariantList = (skip, limit, products) => {
  if (!products) products = []
  return (dispatch) => {
    dispatch(getMerchandiseRequest());
    let url = skip !== undefined && limit !== undefined ? `${baseUrl}/v1/merchandise/variants/list?supplier_info=true&skip=${skip}&limit=${limit}` : `${baseUrl}/v1/merchandise/variants/list?supplier_info=true`
    let config = {
      method: "get",
      url: url,
      //url: MOCKY_PRODUCT,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        if (response?.data.code === 200) {
          let data = {
            data: [...products, ...response.data.result],
            moredata: response.data.next_iteration
            //moredata: false
          }
          dispatch(getMerchandiseSuccess(data));
          //  dispatch(getMerchandiseSuccess(response.data));
        } else {
          dispatch(getMerchandiseFailure("faild"));
        }
      })
      .catch((error) => {
        dispatch(getMerchandiseFailure(error));
      });
  };
};

export const getMerchandiseVariantListByCountryIds = (data) => {
  return (dispatch) => {
    dispatch(getMerchandiseRequest());
    let ids = [];
    data && data.map((item) => {
      let obj = `country_ids=${item}&`
      ids.push(obj)
    });
    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/variants/list?${ids.join("")}supplier_info=true&brand_info=false&skip=0&limit=10`,
      //url: MOCKY_PRODUCT,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        if (response?.status === 200) {
          let data = {
            data: response.data.result
          }

          dispatch(getMerchandiseSuccess(data));
          //  dispatch(getMerchandiseSuccess(response.data));
        } else {
          dispatch(getMerchandiseFailure("faild"));
        }
      })
      .catch((error) => {
        dispatch(getMerchandiseFailure(error));
      });
  };
};

// Merchandise variant price
export const getMerchandiseVariantPriceRequest = () => {
  return {
    type: GET_MERCHANDISE_VARIANT_PRICE_REQUEST,
  };
};

export const getMerchandiseVariantPriceSuccess = (data) => {
  return {
    type: GET_MERCHANDISE_VARIANT_PRICE_SUCCESS,
    payload: data
  };
};

export const getMerchandiseVariantPriceFailure = (error) => {
  return {
    type: GET_MERCHANDISE_VARIANT_PRICE_SUCCESS,
    payload: error
  };
};

export const getMerchandiseVariantPrice = (merId, VarId, clientid, getVarianPriceObj) => {
  return (dispatch) => {
    dispatch(getMerchandiseVariantPriceRequest())

    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/${merId}/client/${clientid}/mapping/?variant_id=${VarId}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    };

    axios(config).then((response) => {
      let obj = {};
      if (response.data.result === null) {
        obj = {
          noVariant: 'variant_not_available'
        }
        getVarianPriceObj(obj)
      }
      dispatch(getMerchandiseVariantPriceSuccess(response.data.result === null ? obj : response.data.result))
    }).catch((err) => {
      dispatch(getMerchandiseVariantPriceFailure('something wen wrong.'))
    })
  }
}

export const clearMerchandiseList = () => {
  return {
    type: CLEAR_MERCHANDISE_LIST,
  };
};