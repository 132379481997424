import {
    FETCH_ALL_COUNTRY_REQUEST,
    FETCH_ALL_COUNTRY_SUCCESS,
    FETCH_ALL_COUNTRY_FAILURE,
    FETCH_COUNTRY_DETAILS_FAILURE,
    FETCH_COUNTRY_DETAILS_SUCCESS,
    FETCH_COUNTRY_DETAILS_REQUEST,
    FETCH_COUNTRY_DETAILS_ID_REQUEST,
    FETCH_COUNTRY_DETAILS_ID_SUCCESS,
    FETCH_COUNTRY_DETAILS_ID_FAILURE
}
    from './countryType'

const initialCountryState = {
    loading: false,
    countries: [],
    countrieDetailsList: [],
    countrieDetails: '',
    error: ''
}

const countryReducer = (state = initialCountryState, action) => {

    switch (action.type) {
        case FETCH_ALL_COUNTRY_REQUEST:
            return {
                ...state,
                loading: true,
                countries: [],
                error: ""
            }
        case FETCH_ALL_COUNTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                countries: action.payload,
                error: ""
            }
        case FETCH_ALL_COUNTRY_FAILURE:
            return {
                ...state,
                loading: false,
                countries: [],
                error: action.payload
            }
        case FETCH_COUNTRY_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                countrieDetails: "",
                error: ""
            }
        case FETCH_COUNTRY_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                countrieDetails: action.payload,
                error: ""
            }
        case FETCH_COUNTRY_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                countrieDetails: "",
                error: action.payload
            }

        case FETCH_COUNTRY_DETAILS_ID_REQUEST:
            return {
                ...state,
                loading: true,
                countrieDetailsList: [],
                error: ""
            }
        case FETCH_COUNTRY_DETAILS_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                countrieDetailsList: action.payload,
                error: ""
            }
        case FETCH_COUNTRY_DETAILS_ID_FAILURE:
            return {
                ...state,
                loading: false,
                countrieDetailsList: [],
                error: action.payload
            }
        default: return state
    }
}
export default countryReducer