import axios from "axios";
import { dateFormatConverter } from "../../utils/constantFunction";
import { baseUrl, getToken } from "../../utils/prodURL";
import { fetchAllCountrieDetails } from "../Countries/countryAction";
import {
  GET_ALL_ORDERS_REQUEST,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAILURE,
  GET_ALL_CLIENT_ORDERSLIST_REQUEST,
  GET_ALL_CLIENT_ORDERSLIST_SUCCESS,
  GET_ALL_CLIENT_ORDERSLIST_FAILURE,
  GET_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  ORDER_FILTER_TYPES_STORE,
  UPDATE_ORDER_STATUS_REQUEST,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_FAILURE,
  HISTORY_ORDER_STATUS_REQUEST,
  HISTORY_ORDER_STATUS_SUCCESS,
  HISTORY_ORDER_STATUS_FAILURE,
  CLEAR_ORDER_LIST
} from "./orderType";

export const getAllOrdersListRequest = () => {
  return {
    type: GET_ALL_ORDERS_REQUEST,
  };
};

export const getAllOrdersListSuccess = (data) => {
  return {
    type: GET_ALL_ORDERS_SUCCESS,
    payload: data,
  };
};
export const getAllOrdersListFailure = () => {
  return {
    type: GET_ALL_ORDERS_FAILURE,
  };
};

export const getAllOrdersList = () => {
  return (dispatch) => {
    dispatch(getAllOrdersListRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/product_transaction`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getAllOrdersListSuccess(response.data.result));
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          getAllOrdersListFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const storeOrderFilterType = (filterTypes) => {
  return {
    type: ORDER_FILTER_TYPES_STORE,
    payload: filterTypes,
  };
};

export const getOrdersProductByTypes = (skip, limit, allOrdersList, types, change) => {
  if (!allOrdersList) allOrdersList = []
  if (change) allOrdersList = []
  let all =
    types?.name === "Giftcard"
      ? `&product_type=${types?.name.toLowerCase()}`
      : types?.name === "Merchandise"
        ? `&product_type=${types?.name.toLowerCase()}`
        : types?.name === "All"
          ? ""
          : "";

  let url = skip !== undefined && limit !== undefined ? `${baseUrl}/v1/product_transaction?skip=${skip}&limit=${limit}${all}` : `${baseUrl}/v1/product_transaction?skip=0${all}`


  return (dispatch) => {
    dispatch(getAllOrdersListRequest());
    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        let data = {
          data: [...allOrdersList, ...response.data.result],
          moredata: response.data.next_iteration
          //moredata: false
        }
        dispatch(storeOrderFilterType(types));
        dispatch(getAllOrdersListSuccess(data));
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          getAllOrdersListFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// get client all orders list

export const getClientAllOrdersRequest = () => {
  return {
    type: GET_ALL_CLIENT_ORDERSLIST_REQUEST,
  };
};

export const getClientAllOrdersSuccess = (data) => {
  return {
    type: GET_ALL_CLIENT_ORDERSLIST_SUCCESS,
    payload: data,
  };
};
export const getClientAllOrdersFailure = () => {
  return {
    type: GET_ALL_CLIENT_ORDERSLIST_FAILURE,
  };
};

export const getClientAllOrders = (clientID, orderBy, skip, limit, clientAllOrdersList) => {
  if (!clientAllOrdersList) clientAllOrdersList = []
  return (dispatch) => {
    dispatch(getClientAllOrdersRequest());
    let url = skip !== undefined && limit !== undefined ? `${baseUrl}/v1/product_transaction?skip=${skip}&limit=${limit}&buyer_id=${clientID}&order_by=${orderBy}`
      : `${baseUrl}/v1/product_transaction?buyer_id=${clientID}&order_by=${orderBy}`
    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        let data = {
          data: [...clientAllOrdersList, ...response.data.result],
          moredata: response.data.next_iteration
          // moredata: false
        }
        dispatch(getClientAllOrdersSuccess(data));
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          getClientAllOrdersFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const getSuppllierAllOrders = (clientID, orderBy) => {
  return (dispatch) => {
    dispatch(getClientAllOrdersRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/product_transaction?buyer_id=${clientID}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getClientAllOrdersSuccess(response.data.result));
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          getClientAllOrdersFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// get orderDetails

export const orderDetailsRequest = () => {
  return {
    type: GET_ORDER_DETAILS_REQUEST,
  };
};

export const orderDetailsSuccess = (data) => {
  return {
    type: GET_ORDER_DETAILS_SUCCESS,
    payload: data,
  };
};
export const orderDetailsFailure = (error) => {
  return {
    type: GET_ORDER_DETAILS_FAILURE,
    payload: error,
  };
};

export const getorderDetails = (id) => {
  return (dispatch) => {
    dispatch(orderDetailsRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/v1/product_transaction/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        dispatch(orderDetailsSuccess(response.data));
        if (response.data.result.giftcard !== null) dispatch(fetchAllCountrieDetails(response.data.result.giftcard.country_id))
        else dispatch(fetchAllCountrieDetails(response.data.result.merchandise.country_id))


      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          orderDetailsFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// update order status
export const updateOrderStatusRequest = () => {
  return {
    type: UPDATE_ORDER_STATUS_REQUEST,
  };
};

export const updateOrderStatusSuccess = (obj) => {
  return {
    type: UPDATE_ORDER_STATUS_SUCCESS,
    payload: obj,
  };
};

export const updateOrderStatusFailure = (obj) => {
  return {
    type: UPDATE_ORDER_STATUS_FAILURE,
    payload: obj,
  };
};

export const updateOrderStatus = (orderObj, getStatus, getResponseCode) => {
  return (dispatch) => {
    dispatch(updateOrderStatusRequest);

    let ids = orderObj?.id;

    let status = orderObj?.orderStatus?.value;
    let updateOrderStatusObj = {
      order_status: status,
      reason: orderObj?.reason ? orderObj?.reason : "",
      shipped_date: orderObj?.date ? dateFormatConverter(orderObj?.date) : dateFormatConverter(new Date()),
      tracking_id: orderObj?.trackingId ? orderObj?.trackingId : "",
      tracking_url: orderObj?.trackingUrl ? orderObj?.trackingUrl : "",
      notes: orderObj?.notes ? orderObj?.notes : "",
    };
    let config = {
      method: "patch",
      url: `${baseUrl}/v1/product_transaction/${ids}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: updateOrderStatusObj,
    };

    axios(config)
      .then((response) => {

        if (response.status === 200) {
          dispatch(updateOrderStatusSuccess(response.data.result));
          getStatus(response.data.result);
          getResponseCode(response.status)
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          updateOrderStatusFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};
// to get order history 
export const getOrdersHistoryRequest = () => {
  return {
    type: HISTORY_ORDER_STATUS_REQUEST,
  };
};

export const getOrdersHistorySuccess = (history) => {
  return {
    type: HISTORY_ORDER_STATUS_SUCCESS,
    payload: history,
  };
};
export const getOrdersHistoryFailure = (error) => {
  return {
    type: HISTORY_ORDER_STATUS_FAILURE,
    payload: error
  };
};

export const getOrderHistory = (id) => {
  return (dispatch) => {
    dispatch(getOrdersHistoryRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/product_transaction/${id}/order_history`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getOrdersHistorySuccess(response.data.result));
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          getOrdersHistoryFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};


export const clearOrderList = () => {
  return {
    type: CLEAR_ORDER_LIST,
  };
};