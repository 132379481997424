import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import ReactSwitchButton from "../../../components/Button/ReactSwitchButton";
import InputGroupTxt from "../../../components/InputField/InputGroupTxt";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { putCreateStore } from "../../../Redux/CreateStore/createStoreAction";
import { getAllRedemptionMethod } from "../../../Redux/Redemption/redemptionAction";
import {
  fetchAllCountrieDetails,
  getAllCountriesList,
} from "../../../Redux/Countries/countryAction";
import {
  getAllOrganizationList,
  getOrganizationList,
} from "../../../Redux/Organizations/organizationAction";
import PulseLoader from "../../../components/Loader/PulseLoader";

const CreateStore = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const countries = useSelector((state) => state.countries.countries);
  const states = useSelector((state) => state?.countries?.countrieDetails?.states);
  const redemptionMethod = useSelector((state) => state?.redemptionmethods?.redemptionMethods);
  const createStore = useSelector((state) => state.createStore?.createStore);
  const loader = useSelector((state) => state?.createStore?.loading);
  const organizationState = useSelector((state) => state?.organizations?.organizationList);

  const [fields, setFields] = useState({
    storeName: "",
    storeId: "",
    country: "",
    state: "",
    organization: "",
    transactionType: "",
    area: "",
    storeFullAdd: "",
    zipcode: "",
    emailid: "",
    storePhoneNum: "",
  });

  const [switchButton, setSwitchButton] = useState({ Active: true });
  const [error, setError] = useState({
    storeName: "",
    storeId: "",
    transactionType: "",
    organization: "",
    country: "",
    state: "",
    area: "",
    storeFullAdd: "",
    emailid: "",
    storePhoneNum: "",
  });

  function handlePhoneNumber(evt) {
    // eslint-disable-next-line array-callback-return
    Object.keys(fields).map((item) => {
      if (item === evt.target.name) {
        let regex = /^\d{0,15}?$/;
        const value = evt.target.value;
        if (regex.test(evt.target.value)) {
          setFields({
            ...fields,
            [evt.target.name]: value,
          });
        }
      }
    });
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setFields({
      ...fields,
      [evt.target.name]: value,
    });
  }
  //for drop down

  const handleChangeCountries = (e) => {
    setFields({ ...fields, country: e });
    e?.id && dispatch(fetchAllCountrieDetails(e?.id));
  };
  const handleChangeStates = (e) => {
    setFields({ ...fields, state: e });
  };

  const handleChangeTransactionType = (e) => {
    setFields({ ...fields, transactionType: e });
  };

  const handleChangeOrganisation = (e) => {
    setFields({ ...fields, organization: e });
    // dispatch(getAllOrganizationList(e.id))
  };

  const switchHandle = () => {
    setSwitchButton(!switchButton);
  };

  //for validations
  const validate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!fields.organization) {
      setError({ organization: "Please select organization" });
      return false;
    } else if (!fields.storeName) {
      setError({ storeName: "Please enter store name" });
      return false;
    } else if (!fields.storeId) {
      setError({ storeId: "Please enter store id" });
      return false;
    } else if (!fields.transactionType) {
      setError({ transactionType: "Please select transaction type" });
      return false;
    } else if (!fields.country) {
      setError({ country: "Please select country" });
      return false;
    } else if (!fields.state) {
      setError({ state: "Please select state" });
      return false;
    } else if (!fields.area) {
      setError({ area: "Please enter area name" });
      return false;
    } else if (!fields.storeFullAdd) {
      setError({ storeFullAdd: "Please provides full address" });
      return false;
    } else if (!fields.emailid) {
      setError({ emailid: "Please enter email id" });
      return false;
    } else if (!fields.emailid || emailRegex.test(fields.emailid) === false) {
      setError({ emailid: "Please enter valid email" });
      return false;
    } else if (!fields.storePhoneNum) {
      setError({ storePhoneNum: "Please provides phone number" });
      return false;
    }

    return true;
  };

  const goToSelectStore = () => {
    history("/store-creation-success");
  };

  const submit = () => {
    let val = validate();
    if (val) {
      let createStoreObj = {
        storeName: fields.storeName,
        storeId: fields.storeId,
        transactionType: fields?.transactionType,
        country: fields?.country,
        state: fields?.state,
        organization: fields?.organization.id,
        area: fields?.area,
        storeFullAdd: fields?.storeFullAdd,
        zipcode: fields?.zipcode,
        emailid: fields?.emailid,
        phonenumber: fields?.storePhoneNum,
        phonecode:fields?.country?.phone_code
      };
      dispatch(putCreateStore(createStoreObj, goToSelectStore));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllRedemptionMethod());
    dispatch(getAllCountriesList());
    dispatch(getAllOrganizationList());
    dispatch(getOrganizationList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loader === false ? (
        <div className="d-flex flex-column justify-content-start py-3 gap-3 px-4 bg-light">
          <div className="d-flex flex-row align-items-center gap-3">
            <LeftIcon onClick={() => history(-1)} className="cursor" />
            <span className="font-bold font-32 my-2">Create store</span>
            <br />
          </div>

          <div className="width-100 ">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
              <div className="d-flex flex-column gap-4 mt-4 width-65 ">
                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Organization<span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      noOptionsMessage={() => "Not found"}
                      placeholder="Select organization"
                      getOptionLabel={(organizationState) =>
                        organizationState.partner_name
                      }
                      options={organizationState}
                      value={fields.organization}
                      onChange={handleChangeOrganisation}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                    />
                  </div>
                  {!fields.organization && error.organization ? (
                    <label className="text-error">{error.organization}</label>
                  ) : null}
                </div>

                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Store name<span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <InputField
                    placeholder={"Enter store name"}
                    name="storeName"
                    value={fields.storeName}
                    onChange={handleChange}
                  />
                  {!fields.storeName && error.storeName ? (
                    <label className="text-error">{error.storeName}</label>
                  ) : null}
                </div>

                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Store ID<span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <InputField
                    placeholder={"Enter store ID"}
                    name="storeId"
                    value={fields.storeId}
                    onChange={handleChange}
                  />
                  {!fields.storeId && error.storeId ? (
                    <label className="text-error">{error.storeId}</label>
                  ) : null}
                </div>

                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Transaction type<span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      noOptionsMessage={() => "Not found"}
                      placeholder="Select transaction type"
                      getOptionLabel={(redemptionMethod) =>
                        redemptionMethod.redemption_method
                      }
                      options={redemptionMethod}
                      value={fields?.transactionType}
                      onChange={handleChangeTransactionType}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                    />
                    {!fields.transactionType && error.transactionType ? (
                      <label className="text-error">
                        {error.transactionType}
                      </label>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Country<span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      noOptionsMessage={() => "Not found"}
                      placeholder="Select country & currency"
                      getOptionLabel={(countries) => countries.name}
                      options={countries}
                      value={fields?.country}
                      onChange={handleChangeCountries}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                    />
                    {!fields.country && error.country ? (
                      <label className="text-error">{error.country}</label>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">State<span className="text-danger">*</span></span>
                      <InfoTooltip title={"State"} />
                    </div>
                    {/* Dropdown state*/}
                    <div>
                      <Select
                        noOptionsMessage={() => "Not found"}
                        placeholder="Select state"
                        getOptionLabel={(states) => states.name}
                        options={states}
                        //   isSearchable
                        value={fields?.state}
                        onChange={handleChangeStates}
                        isClearable
                        styles={CUSTOM_DROPDOWN_STYLE}
                        isDisabled={!fields?.country}
                      />
                    </div>
                    {!fields.state && error.state ? (
                      <label className="text-error">{error.state}</label>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Area<span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <InputField
                    placeholder={"Enter area name"}
                    name="area"
                    value={fields.area}
                    onChange={handleChange}
                  />
                  {!fields.area && error.area ? (
                    <label className="text-error">{error.area}</label>
                  ) : null}
                </div>

                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Store Full Address<span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <InputField
                    placeholder={"Enter store address"}
                    name="storeFullAdd"
                    value={fields.storeFullAdd}
                    onChange={handleChange}
                  />
                  {!fields.storeFullAdd && error.storeFullAdd ? (
                    <label className="text-error">{error.storeFullAdd}</label>
                  ) : null}
                </div>

                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">Zip code</span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <InputField
                    placeholder={"Enter Zip code"}
                    type="number"
                    name="zipcode"
                    value={fields.zipcode}
                    onChange={handleChange}
                  />
                </div>

                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Email ID
                      <span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <InputField
                    placeholder={"Enter email ID"}
                    type="email"
                    name="emailid"
                    value={fields.emailid}
                    onChange={handleChange}
                  />
                  {error.emailid ? (
                    <label className="text-error">{error.emailid}</label>
                  ) : null}
                </div>

                <div className="d-flex flex-column gap-1">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Store phone number<span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <InputGroupTxt
                      title={
                        fields?.country?.phone_code
                          ? "+" + fields?.country?.phone_code
                          : ""
                      }
                      placeholder={"Enter store phone number"}
                      type="number"
                      name="storePhoneNum"
                      lang={props?.lang}
                      value={fields.storePhoneNum}
                      onChange={handlePhoneNumber}
                    />
                    {!fields.storePhoneNum && error.storePhoneNum ? (
                      <label className="text-error">
                        {error.storePhoneNum}
                      </label>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between mb-2 gap-3">
                  <div className="d-flex flex-row gap-2">
                    <span className="font-bold font-13">
                      Send notifications to user(Email will also be used for OTP
                      authentication)<span className="text-danger">*</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  {/* <SwitchButton /> */}
                  <ReactSwitchButton
                    onChange={() => switchHandle()}
                    value={switchButton}
                  />
                </div>

                <div className="d-flex py-1 px-3">
                  <Button onClick={submit} text="Create" type="primary" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default CreateStore;
