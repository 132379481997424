/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import CardPreview from "../../components/Products/CardPreview";
import Header from "../../components/Products/Header";
import ProductCategory from "../../components/Products/ProductCategory";
import { getAllActivations } from "../../Redux/ActivationRequired/activationAction";
import { fetchBrand } from "../../Redux/Brands/brandAction";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import { fetchAllGiftCardLengths, fetchAllGiftCardPrefix, fetchAllGiftCardType } from "../../Redux/GiftCard/giftcardAction";
import { getAllRedemptionMethod } from "../../Redux/Redemption/redemptionAction";

const AddCategoryAttibutes = (props) => {

    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchBrand());
        dispatch(fetchAllGiftCardLengths());
        dispatch(getAllRedemptionMethod());
        dispatch(getAllActivations());
        dispatch(fetchAllGiftCardPrefix());
        dispatch(getAllCountriesList());
        dispatch(fetchAllGiftCardType());

    }, []);

    return (
        <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
            <Header steps={2} title={"Category & Attributes"} />
            <div className="d-flex flex-row mt-2 gap-2">
                <div className="width-65" >
                    <ProductCategory lang={props?.lang} />
                </div>
                <div className="width-35" >
                    <CardPreview />
                </div>
            </div>
        </div>
    );
};

export default AddCategoryAttibutes;
