import {
    FETCH_ALL_CLIENT_REQUEST,
    FETCH_ALL_CLIENT_SUCCESS,
    FETCH_ALL_CLIENT_FAILURE,
    SET_SELECTED_CLIENT,
    GET_CLIENT_BYID_REQUEST,
    GET_CLIENT_BYID_SUCCESS,
    GET_CLIENT_BYID_FAILURE,
    CLIENT_FILTER_DATA,
    CLEAR_CLIENT_LIST
} from "./clientTypes";

const initialState = {
    client: [],
    loading: false,
    error: '',
    errorMsg: '',
    selectedClient: null,
    getClientDetailById: null,
    next_iteration: true,
    clientFilter: []
}

const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
        // get client detail by id
        case GET_CLIENT_BYID_REQUEST:
            return {
                ...state,
                loading: true,
                errorMsg: '',
                getClientDetailById: null
            }

        case GET_CLIENT_BYID_SUCCESS:
            return {
                ...state,
                loading: false,
                getClientDetailById: action.payload,
                errorMsg: '',
            }
        case GET_CLIENT_BYID_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload
            }

        case CLEAR_CLIENT_LIST:
            return {
                ...state,
                loading: false,
                client: [],
                next_iteration: true,
                errorMsg: '',
            }

        case FETCH_ALL_CLIENT_REQUEST:
            return {
                ...state,
                loading: true,
                errorMsg: '',
            }

        case FETCH_ALL_CLIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                client: action.payload.data,
                next_iteration: action.payload.moredata,
                errorMsg: '',
            }
        case FETCH_ALL_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload
            }
        // set selected client in redux when we select client in client mapping 
        case SET_SELECTED_CLIENT:
            return {
                ...state,
                loading: false,
                selectedClient: action.payload,
                errorMsg: '',
            }
        case CLIENT_FILTER_DATA:
            return {
                ...state,
                clientFilter: action.payload
            }
        default: return state
    }
}

export default clientsReducer;