import React from "react";
import { ReactComponent as InfoIcon } from "../../assests/svg/info_light.svg"

const InfoTooltip = (props) => {
    const { title, direction } = props;

    return (
        <>
            <InfoIcon
                className="cursor"
                data-bs-toggle="tooltip"
                data-bs-placement={direction ? direction : "top"}
                title={title}
            />
        </>
    );
};

export default InfoTooltip;
