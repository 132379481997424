import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardPreview from "../../components/Products/CardPreview";
import Header from "../../components/Products/Header";
import ProductDescription from "../../components/Products/ProductDescription";
import { getAllLanguage } from "../../Redux/Language/languageAction";
import { useTranslation } from "react-i18next";

const AddProductNameDes = (props) => { 

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const nameDescState = useSelector((state) => state?.giftcard?.cardNameDesc);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getAllLanguage());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
            <Header steps={1} title={t("Name & Description")} />
            <div className="d-flex flex-row mt-2 gap-2">
                <div className="width-65" >
                    <ProductDescription lang={props?.lang} />
                </div>
                <div className="width-35" >
                    {nameDescState && nameDescState?.productTitle ?
                        <CardPreview /> : null}
                </div>
            </div>

        </div>
    );
};

export default AddProductNameDes;
