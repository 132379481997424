import axios from "axios";
import {
    FETCH_ALL_CLIENT_REQUEST,
    FETCH_ALL_CLIENT_SUCCESS,
    FETCH_ALL_CLIENT_FAILURE,
    SET_SELECTED_CLIENT,
    GET_CLIENT_BYID_REQUEST,
    GET_CLIENT_BYID_SUCCESS,
    GET_CLIENT_BYID_FAILURE,
    CLIENT_FILTER_DATA,
    CLEAR_CLIENT_LIST
} from "./clientTypes";
import { baseUrl, getToken } from '../../utils/prodURL';


export const fetchClientRequest = () => {
    return {
        type: FETCH_ALL_CLIENT_REQUEST,
    }
}

export const fetchClientSuccess = (data) => {
    return {
        type: FETCH_ALL_CLIENT_SUCCESS,
        payload: data
    }
}

export const fetchClientFailure = error => {
    return {
        type: FETCH_ALL_CLIENT_FAILURE,
        payload: error
    }
}
// Set selected client from client mapping into redux 
export const setSelectedClient = data => {
    return {
        type: SET_SELECTED_CLIENT,
        payload: data
    }
}

export const setClientFilterData = (data) => {
    return {
        type: CLIENT_FILTER_DATA,
        payload: data
    }
}

export const fetchClient = (skip, limit, clientsState) => {
    if (!clientsState) clientsState = []
    return (dispatch) => {
        dispatch(fetchClientRequest());
        let config = {
            method: "get",
            url: `${baseUrl}/partner_category/client/partner`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        };
        axios(config)
            .then((response) => {
                let data = {
                    data: [...clientsState, ...response.data.result],
                    moredata: false
                }
                dispatch(fetchClientSuccess(data))
            })
            .catch((error) => {
                //  const errorMsg = error;
                dispatch(fetchClientFailure("Something went wrong!! Please try again!!"));
            });
    }
}

export const fetchClientFloatOnyShow = () => {
    return (dispatch) => {
        dispatch(fetchClientRequest());
        let config = {
            method: "get",
            url: `${baseUrl}/partner_category/client/partner?float_flag=true`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        };
        axios(config)
            .then((response) => {
                let data = {
                    data: response.data.result,
                    moredata: false,
                }
                dispatch(fetchClientSuccess(data))
            })
            .catch((error) => {
                //  const errorMsg = error;
                dispatch(fetchClientFailure("Something went wrong!! Please try again!!"));
            });
    }
}

// to get client detail by id
export const getClientDetailByIdRequest = () => {
    return {
        type: GET_CLIENT_BYID_REQUEST,
    }
}

export const getClientDetailByIdSuccess = data => {
    return {
        type: GET_CLIENT_BYID_SUCCESS,
        payload: data
    }
}

export const getClientDetailByIdFailure = error => {
    return {
        type: GET_CLIENT_BYID_FAILURE,
        payload: error
    }
}

export const getClientDetailById = (id) => {
    return (dispatch) => {
        dispatch(getClientDetailByIdRequest());
        let config = {
            method: "get",
            url: `${baseUrl}/partner/${id}`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        };
        axios(config)
            .then((response) => {
                dispatch(getClientDetailByIdSuccess(response.data.result))
            })
            .catch((error) => {
                //  const errorMsg = error;
                dispatch(getClientDetailByIdFailure("Something went wrong!! Please try again!!"));
            });
    }
}

export const clearClientList = () => {
    return {
        type: CLEAR_CLIENT_LIST,
    };
};