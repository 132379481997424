/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as EditButton } from "../../assests/svg/edit.svg";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import Toasts from "../Toasts/Toasts";
import { useSelector, useDispatch } from "react-redux";
import {
  DataSortingHandler,
  momentdateFormater,
} from "../../utils/constantFunction";
import product_default from "../../assests/img/Product-default.png";
import SmallToast from "../Toast/SmallToast";
import CopyToClipboard from "react-copy-to-clipboard";
import Filter from "../Filter/Filter";
import { MERCHANDISE_TYPES_FILTERS, SORT_TYPE } from "../../utils/ConstantData";
import ModalComp from "../../components/Models/ModalComp";
import ModelForMerchandiseSummery from "../../components/Models/ModelForMerchandiseSummery";
import {
  getMerchandiseSummeryById,
  setMerhchandiseFilterData,
} from "../../Redux/Merchandise/merchandiesAction";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoaders from "react-spinners/PulseLoader";
import { clearMerchandiseList, getMerchandiseVariantList } from "../../Redux/MerchadiseAllProduct/MerchadiseAllProductAction";
import PulseLoader from "../Loader/PulseLoader";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";
import RefreshButton from "../Button/RefreshButton";


const MerchandiseProducts = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const merchandiseFilterData = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseFilterData
  );
  const loading = useSelector((state) => state?.merchadiseAllProduct?.loading);
  const productsAllState = useSelector(
    (state) => state?.merchadiseAllProduct?.merchandiseList
  );
  // for lazy loading
  const hasmore = useSelector(
    (state) => state?.merchadiseAllProduct?.next_iteration
  );
  // for model
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );
  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [id, setId] = useState("");

  const toggleShowToast = () => setShowToast(!showToast);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterCardType, setFilterCardType] = useState("");

  const [products, setProducts] = useState([]);
  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");
  // lazy loading
  const [skip, setskip] = useState(
    products?.length === 0 ? -30 : products?.length - 30
  );
  const [limit, setlimit] = useState(30);

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const [permissions, setpermissions] = useState();
  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => { }, [permissions]);

  useEffect(() => {
    merchandiseFilterData &&
      setProducts(merchandiseFilterData[0]?.filterProduct);
  }, [merchandiseFilterData]);

  useEffect(() => {
    merchandiseFilterData &&
      setFilterCardType(merchandiseFilterData[1]?.filterType);
  }, [merchandiseFilterData[1]?.filterType]);

  useEffect(() => {
    if (merchandiseFilterData.length === 0) {
      setProducts(productsAllState);
    }
  }, [productsAllState]);

  useEffect(() => {
    if (products?.length === 0) {
      fetchdata(skip);
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(getMerchandiseVariantList(skip + limit, limit, products));
      setskip(skip + limit);
    }, 100);
  };


  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 6);
    return val;
  };

  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const showModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowModal(true);
  };

  const filterCardTypeHandler = (e) => {
    setFilterCardType(e);
    let arr = [];
    productsAllState &&
      productsAllState.map((item, i) => {
        if (e?.value === item?.product_variants_flag) {
          arr.push(item);
        } else if (e?.value === null) {
          arr.push(item);
        }
      });
    dispatch(
      setMerhchandiseFilterData([{ filterProduct: arr }, { filterType: e }])
    );
  };

  const refreshData = () => {
    dispatch(clearMerchandiseList());
    setskip(0);
    setlimit(30);
    dispatch(getMerchandiseVariantList(0, limit, []));
  }



  return loading === true && products?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="card-body d-flex  flex-row justify-content-between align-item-center">
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div className="d-flex gap-3 my-2">
              <Tag />
              <span className="font-18 font-bold">
                {t("Merchandise products")}
              </span>
            </div>
            <SearcBar
              type="text"
              name="search"
              lang={props?.lang}
              placeholder={t("Search by name")}
              onChange={searchHandler}
            />
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div>
              <Filter
                onChange={filterCardTypeHandler}
                title={filterCardType?.name}
                data={MERCHANDISE_TYPES_FILTERS}
                lang={props?.lang}
              />
            </div>
            <div>
              <RefreshButton onClick={refreshData} />
            </div>
          </div>

        </div>
        <InfiniteScroll
          dataLength={products?.length || 0}
          next={fetchdata}
          hasMore={hasmore}
          loader={
            <div className="d-flex justify-content-center">
              {" "}
              <PulseLoaders
                color={"#594190"}
                loading={loading}
                size={10}
              />{" "}
            </div>
          }
        >
          <div className="card-body d-flex flex-column justify-content-between align-item-center gap-2">
            <table responsive="xl">
              <tbody>
                <tr className="border-bottom">
                  <td className="text-muted cursor">
                    <div className="d-flex justify-content-start mx-2">
                      <span
                        className={`px-2 ${tableHeaderName === "default_title"
                          ? "ascending"
                          : "descending"
                          }`}
                        onClick={() =>
                          sortingHandler(
                            "default_title",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Product Name")}
                      </span>
                    </div>
                  </td>
                  <td className="text-muted cursor">
                    <div className="d-flex justify-content-start ">
                      <span
                        className={`${tableHeaderName === "id" ? "ascending" : "descending"
                          }`}
                        onClick={() =>
                          sortingHandler(
                            "id",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Product Id")}
                      </span>
                    </div>
                  </td>
                  {/* <td className="text-muted">
                  <div className="d-flex justify-content-center">Supplier</div>
                </td> */}
                  <td className="text-muted cursor">
                    <div className="d-flex justify-content-center">
                      <span
                        className={`${tableHeaderName === "created_at"
                          ? "ascending"
                          : "descending"
                          }`}
                        onClick={() =>
                          sortingHandler(
                            "created_at",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Date")}
                      </span>
                    </div>
                  </td>
                  <td className="text-muted">
                    <div className="d-flex justify-content-center">
                      <span
                        className={``}>
                        {t("Status")}
                      </span>
                    </div>
                  </td>
                  {permissions?.merchandise?.create ? (
                    <td className="text-muted">
                      <div className="d-flex justify-content-center">
                        {t("Edit")}
                      </div>
                    </td>
                  ) : null}
                </tr>
                {products &&
                  products
                    ?.filter((item) => {
                      if (searchTerm === "") {
                        return item;
                      } else if (
                        item?.default_title
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <tr
                        className="card-body gap-3 border-bottom "
                        key={index}
                      >
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-start align-items-center rounded-3 gap-2">
                            <div className="rounded-3">
                              {item?.product_variants_flag ? (
                                <img
                                  src={
                                    item &&
                                      item?.variants?.length > 0 &&
                                      item?.variants !== undefined &&
                                      item?.variants[0]?.images &&
                                      item?.variants[0].images[0]?.file_url
                                      ? item?.variants[0].images[0]?.file_url
                                      : product_default
                                  }
                                  alt="not found"
                                  id="product-thumbnail"
                                />
                              ) : (
                                <img
                                  src={
                                    item.images && item.images[0].file_url
                                      ? item.images[0].file_url
                                      : product_default
                                  }
                                  alt="not found"
                                  id="product-thumbnail"
                                />
                              )}
                            </div>
                            <div className="d-flex flex-column justify-content-center ">
                              <span
                                className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                                title={item?.default_title}
                                style={{ maxWidth: "170px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => showModel(item?.id)}
                              >
                                {item?.default_title}
                              </span>
                              <span className="font-12 font-bolder text-muted">
                                {item?.variants &&
                                  item?.variants.length + " variants"}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            className="d-flex justify-content-start align-items-center visible cursor"
                            title={item?.id}
                          >
                            <span className="font-14 font-boldest text-dark">
                              {trimIdHandler(item?.id)}
                              <span>...</span>
                            </span>
                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Copy to clipboard"
                              className="cursor "
                              value={item.id}
                              onClick={() => copyToClipboard(item?.id)}
                            >
                              <CopyToClipboard text={item?.id}>
                                <CopyButton />
                              </CopyToClipboard>
                            </span>
                          </div>
                        </td>

                        <td className="card-body gap-1">
                          <div className="d-flex justify-content-center">
                            <span>{momentdateFormater(item.created_at)}</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-row justify-content-center">
                            {item.active_flag ? (
                              <span className="font-15 font-bolder text-dark bg-04 rounded-3 px-1">
                                {t("Active")}
                              </span>
                            ) : (
                              <span className="font-15 font-bolder text-dark bg-danger bg-opacity-25 rounded-3 px-1">
                                {t("Deactive")}
                              </span>
                            )}
                          </div>
                        </td>
                        {permissions?.merchandise?.create ? (
                          <td className="card-body gap-1">
                            <div className="d-flex flex-row justify-content-center align-items-center">
                              <span className="font-15 cursor font-bold text-dark px-1 align-item-center">
                                <EditButton
                                  onClick={() =>
                                    history("/merchandise-edit", {
                                      state: {
                                        merchandiseEditDetails: item,
                                      },
                                    })
                                  }
                                />
                              </span>
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
      </div>
      <div>
        <SmallToast data={id ? id : ""} message={"Copied"} />
      </div>
      <ModalComp
        size="lg"
        showModal={showModal}
        // handleClose={handleClose}
        component={
          <ModelForMerchandiseSummery
            handleClose={handleClose}
            merchandiseSummery={merchandiseSummery}
          />
        }
      />
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default MerchandiseProducts;
