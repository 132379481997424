import {
    GET_CLIENT_LIST_REQUEST,
    GET_CLIENT_LIST_SUCCESS,
    GET_CLIENT_LIST_FAILURE,
    GET_CLIENT_GC_LIST_REQUEST,
    GET_CLIENT_GC_LIST_SUCCESS,
    GET_CLIENT_GC_LIST_FAILURE
  } from "./ClientGIftcardType";
  
  const initialClientState = {
    loading: false,
    clientList: [],
    clientGiftcardList:[],
    error: "",
  };
  
  const clientListReducer = (state = initialClientState, action) => {
    switch (action.type) {
      case GET_CLIENT_LIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_CLIENT_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          clientList: action.payload,
          error: "",
        };
      case GET_CLIENT_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          clientList: [],
          error: action.payload,
        };
        // TO GET GIFTCARD LIST MAP TO CLIENT
        case GET_CLIENT_GC_LIST_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case GET_CLIENT_GC_LIST_SUCCESS:
          return {
            ...state,
            loading: false,
            clientGiftcardList: action.payload,
            error: "",
          };
        case GET_CLIENT_GC_LIST_FAILURE:
          return {
            ...state,
            loading: false,
            clientGiftcardList: [],
            error: action.payload,
          };
      default:
        return state;
    }
  };
  export default clientListReducer;
  