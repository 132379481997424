import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { EditorState, convertToRaw } from "draft-js";
import { setNameDescription } from "../../Redux/GiftCard/giftcardAction";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as CloseIcone } from "../../assests/svg/close.svg";
import InputField from "../InputField/InputField";
import TextEditor from "../InputField/TextEditor";
import Button from "../Button/Button";
import InfoTooltip from "../Tooltip/InfoTooltip";
import { KEYBENEFITS_TEXT_LIMIT } from "../../utils/ConstantData";
import Select from "react-select";
import Toasts from "../../components/Toasts/Toasts";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import { useTranslation } from "react-i18next";

const ProductDescription = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const inputTitleElement = useRef(null);
  const inputDescElement = useRef(null);

  const toggleShowToast = () => setShowToast(!showToast);
  const Preferredlanguage = useSelector((state) => state?.languages?.languages);
  const nameDescState = useSelector((state) => state?.giftcard?.cardNameDesc);

  let defaultLang = localStorage.getItem("defaultLang");

  const edittorTxt = {
    value: EditorState.createEmpty(),
    lang: JSON.parse(defaultLang),
  };

  const [editorState, setEditorState] = useState(
    nameDescState?.description ? nameDescState?.description : [edittorTxt]
  );

  const constProdTitle = {
    title: "",
    lang: JSON.parse(defaultLang),
  };

  const [prodTitles, setProdTitles] = useState(
    nameDescState?.prodTitles ? nameDescState?.prodTitles : [constProdTitle]
  );

  const [prodDescription, setProdDescription] = useState({
    language: nameDescState?.preferredLanguage
      ? nameDescState?.preferredLanguage
      : JSON.parse(defaultLang),
    prodTitle: nameDescState?.productTitle ? nameDescState?.productTitle : "",
    keyBenefits1: "",
    keyBenefits2: "",
    keyBenefits3: "",
    keyBenefits4: "",
  });
  const [keybenefits, setKeyBenefits] = useState(
    nameDescState?.keybenefits ? nameDescState?.keybenefits : []
  );

  const [error, setError] = useState({
    language: "",
    title: "",
    editorState: "",
    keyBenefits1: "",
    keyBenefits2: "",
    keyBenefits3: "",
    keyBenefits4: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSetKeybenefits = (e) => {
    let data = {
      id: e.target.name,
      name: e.target.value,
    };
    setKeyBenefits({ ...keybenefits, [e.target.name]: data });
  };

  const handleInputChangeState = (e) => {
    const { name, value } = e.target;
    setProdDescription({
      ...prodDescription,
      [name]: value,
    });
  };

  const getDescription = (txt, code) => {
    let arr = [...editorState];
    setError({ editorState: "" });
    // eslint-disable-next-line array-callback-return
    arr &&
      arr.map((item, i) => {
        if (code === item.lang.language_code) {
          arr[i].value = txt;
        }
      });
    setEditorState(arr);
  };

  const handleAddClick = (e) => {
    if (
      editorState.some((item) => item.lang.language_code === e.language_code)
    ) {
      toggleShowToast();
      return;
    } else {
      setEditorState([
        ...editorState,
        { value: EditorState.createEmpty(), lang: e },
      ]);
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...editorState];
    list.splice(index, 1);
    setEditorState(list);
  };

  const handleDropdownOnClick = (obj) => {
    handleAddClick(obj);
  };

  const validate = () => {
    let value = convertToRaw(editorState[0].value.getCurrentContent());
    if (!prodTitles[0].title) {
      setError({ title: "Please enter product title" });
      if (inputTitleElement.current) {
        inputTitleElement.current.focus();
      }
      return false;
    } else if (!value.blocks[0].text) {
      setError({ editorState: "Please provides description" });
      if (inputDescElement.current) {
        inputDescElement.current.focus();
      }
      return false;
    }

    return true;
  };

  const submit = () => {
    let value = validate();
    if (value) {
      // eslint-disable-next-line react-hooks/rules-of-hooks

      let obj = {
        preferredLanguage: prodDescription.language,
        // productTitle: prodDescription.prodTitle,
        prodTitles: prodTitles,
        keybenefits: keybenefits,
        description: editorState,
      };
      dispatch(setNameDescription(obj));
      history("/product-creation/category-attibutes");
    }
  };

  // usePrompt
  // const isBlocking = () => {
  //   let value = convertToRaw(editorState[0].value.getCurrentContent());

  //   let fristField = !prodDescription.prodTitle ? true : false
  //   let lastField = !value.blocks[0].text ? true : false

  //   return fristField && lastField ? true : false
  //   // return prodDescription.prodTitle.length > 0
  // };
  // usePrompt("Are you sure you want to leave?", isBlocking());

  const handleChangeLanguage = (e) => {
    setProdDescription({ ...prodDescription, language: e });
    e && handleAddClickProdTitle(e);
    e && handleDropdownOnClick(e);
  };

  const handleRemoveClickProdTitle = (index) => {
    const list = [...prodTitles];
    list.splice(index, 1);
    setProdTitles(list);
  };

  const handleInputChangeProdTitle = (e) => {
    const { name, value } = e.target;
    let arr = [...prodTitles];
    // eslint-disable-next-line array-callback-return
    arr &&
      arr.map((item, i) => {
        if (name === item.lang.language_code) {
          arr[i].title = value;
        }
      });
    setProdTitles(arr);
  };

  const handleAddClickProdTitle = (e) => {
    if (
      prodTitles.some((item) => item.lang.language_code === e.language_code)
    ) {
      // alert("Already added");
      toggleShowToast();
      return;
    } else {
      setProdTitles([...prodTitles, { title: "", lang: e }]);
    }
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">{t("Name & Description")}</span>
        </div>
      </div>

      <div className="d-flex flex-column gap-4 mt-4">
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="font-bold font-14">{t("Preferred language")}</span>
          <InfoTooltip
            title={"Enter your Preferred language or select from list"}
          />
        </div>

        <div>
          <Select
            placeholder={t("Select preferred language")}
            noOptionsMessage={() => "Not found"}
            getOptionLabel={(Preferredlanguage) => Preferredlanguage.language}
            options={Preferredlanguage}
            styles={CUSTOM_DROPDOWN_STYLE}
            value={prodDescription.language}
            onChange={handleChangeLanguage}
            isClearable
          />
        </div>
        {prodTitles &&
          prodTitles.map((item, index) => (
            <div className="d-flex flex-column gap-3" key={index}>
              <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Product title  #")}
                    {index + 1} {item?.lang?.language}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip title={"Enter product title"} />
                </div>
                {prodTitles.length !== 1 && (
                  <div
                    className="cursor"
                    onClick={() => handleRemoveClickProdTitle(index)}
                  >
                    {index !== 0 && <CloseIcone />}
                  </div>
                )}
              </div>

              <div className="d-flex flex-column gap-1">
                <InputField
                  inputRef={inputTitleElement}
                  onChange={handleInputChangeProdTitle}
                  name={item?.lang?.language_code}
                  placeholder={`${t(
                    "Product title"
                  )} ${item?.lang?.language.toLocaleLowerCase()}`}
                  type="text"
                  value={item?.title}
                />
                {!item.title && error.title ? (
                  <label className="text-error">{error.title}</label>
                ) : null}
              </div>
            </div>
          ))}
        <div className="d-flex ">
          <div className="btn-group w-100">
            <div
              className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
              data-bs-toggle="dropdown"
            >
              <span>{t("Add other language for product title")}</span>
            </div>

            <ul
              className={`dropdown-menu rounded-card-7 dropdown-menu-start ${
                props?.lang === "ar" ? "dropdown-menu-ar" : ""
              }`}
            >
              {Preferredlanguage &&
                Preferredlanguage.map((item, i) => (
                  <li key={i} onClick={() => handleAddClickProdTitle(item)}>
                    <div className="dropdown-item cursor">{item.language}</div>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {/* <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Product title<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <div className="d-flex flex-column gap-1">
            <InputField
              onChange={handleInputChangeState}
              name="prodTitle"
              placeholder={"Product title"}
              type="text"
              maxLength={TITLE_TEXT_LIMIT}
              value={prodDescription.prodTitle}
            />
            {!prodDescription.prodTitle && error.prodTitle ? (
              <label className="text-error">{error.prodTitle}</label>
            ) : null}
          </div>
        </div> */}

        {editorState &&
          editorState.map((item, i) => (
            <div className="d-flex flex-column gap-3" key={i}>
              <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Description #")}
                    {i + 1} {item?.lang?.language}
                    <span className="text-danger">{i === 0 ? "*" : ""}</span>
                  </span>
                  <InfoTooltip title={"Enter product description"} />
                </div>
                {editorState.length !== 1 && (
                  <div className="cursor" onClick={() => handleRemoveClick(i)}>
                    {i !== 0 && <CloseIcone />}
                  </div>
                )}
              </div>
              <div className="d-flex flex-column gap-1">
                <TextEditor
                  inputRef={inputDescElement}
                  code={item?.lang?.language_code}
                  editorState={item?.value}
                  getDescription={getDescription}
                  lang={props?.lang}
                />
                {error.editorState && i === 0 ? (
                  <label className="text-error">{error.editorState}</label>
                ) : null}
              </div>
            </div>
          ))}

        <div className="d-flex ">
          <div className="btn-group w-100">
            <div
              className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
              data-bs-toggle="dropdown"
            >
              <span>{t("Add other language description")} </span>
            </div>
            <ul
              className={`dropdown-menu rounded-card-7 dropdown-menu-start ${
                props?.lang === "ar" ? "dropdown-menu-ar" : ""
              }`}
            >
              {Preferredlanguage &&
                Preferredlanguage.map((item, i) => (
                  <li key={i} onClick={() => handleDropdownOnClick(item)}>
                    <div className="dropdown-item cursor">{item?.language}</div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">{t("Key benefits")}</span>
            <InfoTooltip title={"Enter key benefits"} />
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
            <InputField
              onChange={handleSetKeybenefits}
              name="one"
              placeholder={t("benefit 1")}
              type="text"
              value={keybenefits?.one?.name}
              maxLength={KEYBENEFITS_TEXT_LIMIT}
            />
            <InputField
              onChange={handleSetKeybenefits}
              name="two"
              placeholder={t("benefit 2")}
              type="text"
              value={keybenefits?.two?.name}
              maxLength={KEYBENEFITS_TEXT_LIMIT}
            />
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
            <InputField
              onChange={handleSetKeybenefits}
              name="three"
              placeholder={t("benefit 3")}
              type="text"
              value={keybenefits?.three?.name}
              maxLength={KEYBENEFITS_TEXT_LIMIT}
            />
            <InputField
              onChange={handleSetKeybenefits}
              name="four"
              placeholder={t("benefit 4")}
              type="text"
              value={keybenefits?.four?.name}
              maxLength={KEYBENEFITS_TEXT_LIMIT}
            />
          </div>
        </div>

        <div className="d-flex">
          <Button
            onClick={() => submit()}
            text={t("Save & Next")}
            type="primary"
          />
        </div>
      </div>

      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Allready Added."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default ProductDescription;
