// merchandise module set data into redux
export const SET_CATEGORY_ATTRIBUTE_DATA = "SET_CATEGORY_ATTRIBUTE_DATA";
export const SET_NAME_DESCRIPTION_DATA = "SET_NAME_DESCRIPTION_DATA";
// Setting  merchandise Mapping in Redux
export const SET_MERCHANDISE_MAPPING_DATA="SET_MERCHANDISE_MAPPING_DATA"
// setting merchandise sort variant data in redux
export const SET_MERCHANDISE_SORT_VARIANTS_DATA="SET_MERCHANDISE_SORT_VARIANTS_DATA"
//seeting merchandise term and conditions data
export const SET_MERCHANDISE_TERM_CONDITION = "SET_MERCHANDISE_TERM_CONDITION";

// Merchandise Data put in server 
export const PUT_MERCHANDISE_SUCCESS = "PUT_MERCHANDISE_SUCCESS";
export const PUT_MERCHANDISE_FAILURE = "PUT_MERCHANDISE_FAILURE";
export const PUT_MERCHANDISE_REQUEST = "PUT_MERCHANDISE_REQUEST";

// Merchandise Images put in server 
export const PUT_MERCHANDISE_UPLOAD_IMG_SUCCESS = "PUT_MERCHANDISE_UPLOAD_IMG_SUCCESS";
export const PUT_MERCHANDISE_UPLOAD_IMG_FAILURE = "PUT_MERCHANDISE_UPLOAD_IMG_FAILURE";
export const PUT_MERCHANDISE_UPLOAD_IMG_REQUEST = "PUT_MERCHANDISE_UPLOAD_IMG_REQUEST";

// Merchandise Data Get from server 
export const GET_MERCHANDISE_SUCCESS = "GET_MERCHANDISE_SUCCESS";
export const GET_MERCHANDISE_FAILURE = "GET_MERCHANDISE_FAILURE";
export const GET_MERCHANDISE_REQUEST = "GET_MERCHANDISE_REQUEST";

// Merchandise edit 
export const GET_MERCHANDISE_BY_ID_SUCCESS = "GET_MERCHANDISE_BY_ID_SUCCESS";
export const GET_MERCHANDISE_BY_ID_FAILURE = "GET_MERCHANDISE_BY_ID_FAILURE";
export const GET_MERCHANDISE_BY_ID_REQUEST = "GET_MERCHANDISE_BY_ID_REQUEST";
// get merchandise summery by id
export const GET_MERCHANDISE_SUMMERY_BY_ID_SUCCESS = "GET_MERCHANDISE_SUMMERY_BY_ID_SUCCESS";
export const GET_MERCHANDISE_SUMMERY_BY_ID_FAILURE = "GET_MERCHANDISE_SUMMERY_BY_ID_FAILURE";
export const GET_MERCHANDISE_SUMMERY_BY_ID_REQUEST = "GET_MERCHANDISE_SUMMERY_BY_ID_REQUEST";

export const SET_FILTER_MERCHANDISE_DATA = "SET_FILTER_MERCHANDISE_DATA"

export const EDIT_MERCHANDISE_REQUEST = "EDIT_MERCHANDISE_REQUEST";
export const EDIT_MERCHANDISE_SUCCESS = "EDIT_MERCHANDISE_SUCCESS";
export const EDIT_MERCHANDISE_FAILURE = "EDIT_MERCHANDISE_FAILURE";

export const GET_MERCHANDISE_IMAGE_SUCCESS = "GET_MERCHANDISE_IMAGE_SUCCESS"

