import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import MerchantUserSelect from "../../../components/Distribution/MerchantUserSelect";

import { useDispatch, useSelector } from "react-redux";
import { fetchCustomer } from "../../../Redux/Customer/customerAction";
import PulseLoader from "../../../components/Loader/PulseLoader";

const SelectUser = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const customerState = useSelector((state) => state?.customers?.customer);
  const loading = useSelector((state) => state?.customers?.loading);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(customerState.length===0){
      dispatch(fetchCustomer());
    }
  }, []);

  const addCustomer = () => {
    history("/patner-creation");
  };

  return (
    <div className="d-flex flex-column justify-content-start pt-2 px-4 bg-light gap-3 w-100">
      <div className="d-flex flex-column justify-content-start  w-100">
        <div className="d-flex flex-row justify-content-between align-items-center mt-3 w-100">
          <div className="d-flex flex-row align-items-center gap-3">
            <LeftIcon onClick={() => history(-1)} className="cursor" />
            <span className="font-bold font-32 my-2">Select Recipient</span>
          </div>
          <div>
            <Button
              type="primary"
              text="+ ADD A CUSTOMER"
              onClick={() => addCustomer()}
            />
          </div>
        </div>
        <div className=" d-flex mb-2">
          <span className="font-bolder font-14 text-muted ">
            Gift card will be send to the email ID as a password protected Excel
            Sheet.
          </span>
        </div>
      </div>

      {loading === false ? (
        <div className="d-flex ">
          <MerchantUserSelect customerState={customerState}/>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center"><PulseLoader type={"data"}/></h5>
        </div>
      )}
    </div>
  );
};

export default SelectUser;
