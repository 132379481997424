/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import { setSelectedSupplier } from "../../../Redux/MappingProducts/Supplier/Giftcards/giftcardSuppMappingAction";
import { PRODUCT_TYPE } from "../../../utils/ConstantData";
import AllSupplierList from "../../../components/Supplier/AllSupplierList";
import {
  fetchSupplierList,
  getAllTransationSelSupDelete,
} from "../../../Redux/Supplier/supplierAction";
import { useTranslation } from "react-i18next";

const SupplierMappingDashboard = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputElementSupplier = useRef(null);
  const inputElementCategory = useRef(null);

  const suppliersListState = useSelector((state) => state?.suppliers?.supplier);

  const [fields, setFields] = useState({
    productType: "",
    supplier: "",
  });

  const [error, setError] = useState({
    supplier: "",
    productType: "",
  });

  const [permissions, setpermissions] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSupplierList());
    dispatch(getAllTransationSelSupDelete());
  }, []);

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {}, [permissions]);
  useEffect(() => {}, [suppliersListState]);

  const handleChangeProductCategory = (e) => {
    setFields({ ...fields, productType: e });
  };

  const handleChangeProduct = (e) => {
    setFields({ ...fields, supplier: e });
  };

  const validate = () => {
    if (!fields.supplier) {
      setError({ supplier: t("Please select supplier to map") });
      if (inputElementSupplier.current) {
        inputElementSupplier.current.focus();
      }
      return false;
    } else if (!fields.productType) {
      setError({ productType: t("Please select product type") });
      if (inputElementCategory.current) {
        inputElementCategory.current.focus();
      }
      return false;
    }
    return true;
  };

  const goToCreateSupplier = () => {
    let val = validate();
    if (val) {
      dispatch(setSelectedSupplier(fields.supplier));

      if (fields.productType.name === "Giftcard") {
        history("/supplier-mapping-giftcard", {
          state: {
            productType: fields.productType,
          },
        });
      } else {
        history("/supplier-mapping-merchandise", {
          state: {
            selectedSupplier: fields.supplier,
            productType: fields.productType,
          },
        });
      }
    }
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">{t("Supplier mapping")}</span>
        </div>
      </div>
      {permissions?.all_suppliers?.create ? (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-4 px-4 width-65">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Select Supplier")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Select Supplier"} />
              </div>

              <div>
                <Select
                  ref={inputElementSupplier}
                  placeholder={t("Select supplier")}
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(suppliersListState) =>
                    suppliersListState.partner_name
                  }
                  options={suppliersListState}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.supplier}
                  onChange={handleChangeProduct}
                  isClearable
                />
                {!fields.supplier && error.supplier ? (
                  <label className="text-error">{error.supplier}</label>
                ) : null}
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Product Category")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Select Product Category"} />
              </div>
              <div>
                <Select
                  ref={inputElementCategory}
                  placeholder={t("Select product category")}
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(productType) => productType.name}
                  options={PRODUCT_TYPE}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.productType}
                  onChange={handleChangeProductCategory}
                  isClearable
                />
                {!fields.productType && error.productType ? (
                  <label className="text-error">{error.productType}</label>
                ) : null}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row gap-3 mt-3">
            <Button
              type="primary"
              text={t("Continue")}
              onClick={() => goToCreateSupplier()}
            />
          </div>
        </div>
      ) : null}

      <div>
        <AllSupplierList
          lang={props?.lang}
          suppliersState={suppliersListState}
        />
      </div>
    </div>
  );
};

export default SupplierMappingDashboard;
