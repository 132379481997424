import React, { useEffect } from "react";
import Button from "../../components/Button/Button";
import { ReactComponent as SuccessIcon } from "../../assests/svg/Success.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ClientMapSuccess = () => {
  const history = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const goToclientDashboard = () => {
    history("/clients-mapping-dashboard")
  }
  return (
    <div className="d-flex flex-column  align-item-center bg-light w-100 py-5">
      <div className="d-flex flex-row w-100 justify-content-center">
        <SuccessIcon />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-dark font-bold font-40">
          {t("Client mapped successfully")}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center my-4">
        <Button type={"primary"} text={t("Client Dashboard")} onClick={goToclientDashboard} />
      </div>
      {/* <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"rounded"} text={"Map client to product"} />
      </div> */}
    </div>
  );
};
export default ClientMapSuccess;
