import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import FloatOrgDetail from "./FloatOrgDetail";

const AddFloatOrg = (props) => {
  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center width-98">
        <div className="d-flex flex-row align-items-center gap-3">
          <LeftIcon className="cursor" onClick={() => history(-1)} />
          <span className="font-35 font-bold mb-2 py-2">
            Add Float to Organization
          </span>
        </div>
      </div>

      <div className="d-flex width-65 gap-1 flex-column">
        <FloatOrgDetail lang={props?.lang} />
      </div>

    </div>
  );
};

export default AddFloatOrg;
