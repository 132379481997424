import {
  GET_CLIENT_MAPPED_GCLIST_REQUEST,
  GET_CLIENT_MAPPED_GCLIST_SUCCESS,
  GET_CLIENT_MAPPED_GCLIST_FAILURE,
  GET_CLIENT_MAPPED_GCLIST_DELETE,
  GET_CLIENT_MAP_GCLIST_DETAILS_REQUEST,
  GET_CLIENT_MAP_GCLIST_DETAILS_SUCCESS,
  GET_CLIENT_MAP_GCLIST_DETAILS_FAILURE,
  GET_CLIENT_MAP_GC_FLOAT_REQUEST,
  GET_CLIENT_MAP_GC_FLOAT_SUCCESS,
  GET_CLIENT_MAP_GC_FLOAT_FAILURE,
  DELETE_GIFTCARD_DETAIL,
  POST_GIFTACRD_MANAUL_IN_COUNT_REQUEST,
  POST_GIFTACRD_MANAUL_IN_COUNT_SUCCESS,
  POST_GIFTACRD_MANAUL_IN_COUNT_FAILURE,
} from "./ClientGiftcardDistributionType";

const initialLanguageState = {
  loading: false,
  clientMappedGcList: [],
  selectedMapGcListDetails: [],
  manaulInventoryGcCount: null,
  error: "",
};

const ClientMappedGcListReducer = (state = initialLanguageState, action) => {
  switch (action.type) {
    // to get mapped gift card list with client
    case GET_CLIENT_MAPPED_GCLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_MAPPED_GCLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clientMappedGcList: action.payload,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case GET_CLIENT_MAPPED_GCLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case GET_CLIENT_MAPPED_GCLIST_DELETE:
        return {
          ...state,
          loading: false,
          clientMappedGcList: [],
          next_iteration: action.payload.moredata,
          error: "",
        };
    // to get selected mapped giftcard list with client details
    case GET_CLIENT_MAP_GCLIST_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_MAP_GCLIST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedMapGcListDetails: action.payload,
        error: "",
      };
    case DELETE_GIFTCARD_DETAIL: 
     return {
      ...state,
      loading: false,
      selectedMapGcListDetails: [],
      error: "",
     }
    case GET_CLIENT_MAP_GCLIST_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        selectedMapGcListDetails: [],
        error: action.payload,
      };
      case POST_GIFTACRD_MANAUL_IN_COUNT_REQUEST: 
      return {
        ...state,
        loading: true,
      };
      case POST_GIFTACRD_MANAUL_IN_COUNT_SUCCESS: 
      return {
        ...state,
        loading: false,
        error: "",
        manaulInventoryGcCount: action.payload
      }
      case POST_GIFTACRD_MANAUL_IN_COUNT_FAILURE: 
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state;
  }
};
export default ClientMappedGcListReducer;
