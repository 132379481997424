import {
  GET_SAMPLE_MANAUL_INVENTORY_REQUEST,
  GET_SAMPLE_MANAUL_INVENTORY_SUCCESS,
  GET_SAMPLE_MANAUL_INVENTORY_FAILURE,
  POST_MANAUL_INVENTORY_SUCCESS,
  GET_MANAUL_INVENTORY_REQUEST,
  GET_MANAUL_INVENTORY_SUCCESS,
  GET_MANAUL_INVENTORY_FAILURE,
  POST_MANAUL_INVENTORY_SUCCESS_ERROR,
  CLEAR_MANAUL_INVENTORY_SUCCESS_ERROR,
} from "./manualInventoryType";
import { baseUrl, getToken } from "../../utils/prodURL";
import axios from "axios";
import fileDownload from "js-file-download";

export const getSampleManualInventoryRequest = () => {
  return {
    type: GET_SAMPLE_MANAUL_INVENTORY_REQUEST,
  };
};

export const getSampleManualInventorySuccess = (manualInventory) => {
  return {
    type: GET_SAMPLE_MANAUL_INVENTORY_SUCCESS,
    payload: manualInventory,
  };
};

export const getSampleManualInventoryFailure = (error) => {
  return {
    type: GET_SAMPLE_MANAUL_INVENTORY_FAILURE,
    payload: error,
  };
};

export const getSampleManualInventory = () => {
  return (dispatch) => {
    dispatch(getSampleManualInventoryRequest());

    axios
      .get(`${baseUrl}/v1/giftcard/manual_inventory/sample_file`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        // console.log(res);
        fileDownload(res.data, "Manual_Inventory_Template.xlsx");
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          getSampleManualInventoryFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// Getting excel data list
export const getManualInventoryExcelDataRequest = () => {
  return {
    type: GET_MANAUL_INVENTORY_REQUEST,
  };
};

export const getManualInventoryExcelDataSuccess = (manualInventoryData) => {
  return {
    type: GET_MANAUL_INVENTORY_SUCCESS,
    payload: manualInventoryData,
  };
};

export const getManualInventoryExcelDataFailure = (error) => {
  return {
    type: GET_MANAUL_INVENTORY_FAILURE,
    payload: error,
  };
};

export const getManualInventoryExcelData = () => {
  return (dispatch) => {
    dispatch(getManualInventoryExcelDataRequest());

    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcard/manual_inventory`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.data);
        dispatch(getManualInventoryExcelDataSuccess(response.data));
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          getManualInventoryExcelDataFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

export const postManualInventorySuccess = (manualInventoryRes) => {
  return {
    type: POST_MANAUL_INVENTORY_SUCCESS,
    payload: manualInventoryRes,
  };
};

export const postManualInventorySuccessErrors = (postErrors) => {
  return {
    type: POST_MANAUL_INVENTORY_SUCCESS_ERROR,
    payload: postErrors,
  };
};

export const clearManualInventorySuccessErrors = () => {
  return {
    type: CLEAR_MANAUL_INVENTORY_SUCCESS_ERROR,
    payload: [],
  };
};

export const postManualInventory = (fileArr, goToSuccessfull) => {
  return (dispatch) => {
    // console.log(fileArr[0]);
    // console.log("In ActionFile ");

    const formData = new FormData();
    formData.append("file", fileArr[0]);
    // console.log(formData);

    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcard/manual_inventory`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          dispatch(postManualInventorySuccess(response.data));
          dispatch(clearManualInventorySuccessErrors());
          goToSuccessfull();
        }
        dispatch(postManualInventorySuccessErrors(response.data));
      })
      .catch((error) => {
        console.log("error ", error);
        console.log("Something went wrong");
      });
  };
};
