import React, { useEffect, useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import GiftcardDefault from "../../assests/img/giftcard-default.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CardPreviewEdit = () => {
  const { t } = useTranslation();
  const giftcardsState = useSelector((state) => state?.giftcard?.selectedGidftcardDetails);
  const giftcarFilesState = useSelector((state) => state?.giftcard?.giftcardFiles);

  const [cardImg, setCardImg] = useState("");


  useEffect(() => {
    giftcardsState && giftcarFilesState && giftcarFilesState.map((item, i) => {
      if (item.file === "card_image") {
        setCardImg(item)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftcarFilesState]);


  return (
    giftcardsState &&
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">{t("Preview")}</span>
        </div>
        {/* <div>
          <Expand className="cursor" onClick={handleShow} />
        </div> */}
      </div>
      <div className="my-4">
        {!cardImg.file_url ?
          <img
            src={GiftcardDefault}
            alt="not found"
            className="img-fluid"
          /> :
          <img
            src={cardImg.file_url}
            // key={giftcard?.cardPrice?.giftCardImg[0]?.preview}
            alt="not found"
            className="img-fluid"
          />}
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center gap-3">
        <span className="font-20 font-bold">{giftcardsState?.default_title}</span>
        {/* <span className=" text-dark bg-success bg-opacity-25 font-bold rounded-3 px-1 ">
          {giftcard?.cardPrice?.amount ? '$' + giftcard?.cardPrice?.amount : ''}
        </span> */}
      </div>
      <div>
        <span className="font-bolder text-muted">{t("by")} </span>
        <span className="font-bold">{t("Hadaya")}</span>
      </div>

    </div>
  );
};

export default CardPreviewEdit;
