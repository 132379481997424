import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import GoogleInc from "../../components/Float/GoogleInc";
import CardBalance from "../../components/ReportsAnalytics/CardBalance";

const BalanceDashBoard = () => {

  const history = useNavigate ();

  const goToNext = () => {
    history("");
  };

  return (
    <div className="d-flex flex-column justify-content-between gap-1 py-4 mt- px-4 bg-light">
      <GoogleInc/>
      <div className="d-flex flex-row w-100  align-items-center">
        <div className="d-flex flex-grow-1">
          <div className="d-flex flex-start gap-3 mb-1 align-items-center">
            <div className="">
              <Button
                type="rounded"
                text="All transactions"
                className="rounded-5 my-4 mt-6 fw-bolder"
              />
            </div>
            <div className="">
              <Button
                type="primary"
                text="Float transactions"
                className="rounded-6 my-4 mt-6 fw-bold"
              />
            </div>
          </div>
        </div>
        <div className="">
          <Button
            type="primary"
            text="+ Top Up Float"
            className="rounded-6 my-4 mt-6 fw-bold"
            onClick={() => goToNext()}
          />
        </div>
      </div>

      <CardBalance />
    </div>
  );
};
export default BalanceDashBoard;
