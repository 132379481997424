import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import { ReactComponent as CloseIcone } from "../../assests/svg/close.svg"
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import BackButton from "../../components/Button/BackButton";
import { useDispatch, useSelector } from "react-redux";
//import { getSelectColor } from "../../Redux/SelectColor/selcolAction";
import { TITLE_TEXT_LIMIT, Variants } from "../../utils/ConstantData";
import ReactSwitchButton from "../../components/Button/ReactSwitchButton";
import InputField from "../../components/InputField/InputField";
import Toasts from "../../components/Toasts/Toasts";
import { setMerchandiseVariantImages, setMerchandiseVariants, setMerchandiseVariantsObj } from "../../Redux/MerchandiseVarient/merchandiseVarientActions";

const CreateVarient = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const mercandiseNameDescState = useSelector((state) => state?.merchandiseCatAttriData?.merchandiseNameDes);
  const merchandiseCatAttriState = useSelector((state) => state?.merchandiseCatAttriData?.merchandiseCatAtt);

  let variantsObj = {
    title: mercandiseNameDescState?.prodTitles[0]?.title,
    optionTxt: "Color",
    option: null,
    requireImages: true,
    value: null,
    tags: []
  }

  const merchandiseVarientObjState = useSelector((state) => state?.merchandiseVarient?.merchandiseVarientObj);

  const [showToast, setShowToast] = useState(false);
  const [prodVariants, setProdVariants] = useState(merchandiseVarientObjState ? merchandiseVarientObjState : [variantsObj]);

  const [error, setError] = useState({
    option: "",
    optionTxt: "",
    requireImages: "",
    tags: ""
  });


  useEffect(() => {
    window.scrollTo(0, 0);
    //dispatch(getSelectColor());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!merchandiseCatAttriState) {
      history("/add-merchandise");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchandiseCatAttriState]);

  const toggleShowToast = () => setShowToast(!showToast);

  const handleOptionValue = (obj, index) => {
    let arr = [...prodVariants];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === index) {
        arr[i].option = obj;
      }
    })
    setProdVariants(arr);
  };


  const handleSwitchButton = (index) => {
    let arr = [...prodVariants];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === index) {
        arr[i].requireImages = !item.requireImages;
      }
    })
    setProdVariants(arr);
  };

  const removeTags = (indexToRemove, index) => {
    let arr = [...prodVariants];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === index) {
        let tagsArr = arr[i].tags ? arr[i].tags : [];
        tagsArr.filter((_, j) => j !== indexToRemove);
        tagsArr.splice(indexToRemove, 1);
      }
    })
    setProdVariants(arr);
  };

  const addTags = (event, index) => {
    let arr = [...prodVariants];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === index) {
        if (event.target.value !== "") {
          let tagsArr = arr[i].tags ? arr[i].tags : [];
          tagsArr.push(event.target.value)
          event.target.value = "";
        }
      }
    })
    setProdVariants(arr);
  };

  const handleAddNewOption = (e) => {
    if (prodVariants.length < 3) {
      setProdVariants([...prodVariants, { optionTxt: "", option: null, requireImages: false, value: null, tags: [] }]);
    } else {
      setShowToast(true);
    }

  };

  const handleRemoveClick = index => {
    const list = [...prodVariants];
    list.splice(index, 1);
    setProdVariants(list);
  };

  const handleInputChangeState = (e) => {
    const { name, value } = e.target;
    let arr = [...prodVariants];

    // eslint-disable-next-line array-callback-return
    prodVariants && prodVariants.map((item, i) => {
      // eslint-disable-next-line  
      if (name == i) {
        arr[i].optionTxt = value;
      }
    })
    setProdVariants(arr);
  };

  const validate = () => {
    if (!prodVariants[0].optionTxt) {
      setError({ optionTxt: "Please enter option" });
      return false;
    } else if (!prodVariants[0].tags.length > 0) {
      setError({ tags: "Please enter variants tags" });
      return false;
    }
    return true;
  }
  const goBack = () => {
    history("/merchandise-dashboard");
  };

  const goTonextStep = () => {
    let value = validate();
    if (value) {
      createVarients();
      history("/add-variant/merchandise-mapping");
    }
  };

  const createVarients = () => {

    let attributes = {}

    // eslint-disable-next-line array-callback-return
    attributes && prodVariants && prodVariants.map((item, index) => {
      if (index === 0) {
        attributes[item.optionTxt] = item.tags ? item.tags : []
      } else if (index === 1) {
        attributes[item.optionTxt] = item.tags ? item.tags : []
      } else if (index === 2) {
        attributes[item.optionTxt] = item.tags ? item.tags : []
      }
    });

    let attrs = [];

    for (const [attr, values] of Object.entries(attributes))
      attrs.push(values.map(v => ({ [attr]: v })));

    attrs = attrs.reduce((a, b) => a.flatMap(d => b.map(e => ({ ...d, ...e }))));

    let arr = []

    // eslint-disable-next-line array-callback-return
    prodVariants[0].tags.map((item, i) => {
      arr[i] = {
        color: item,
        images: []
      };
    })

    dispatch(setMerchandiseVariantImages(arr));
    dispatch(setMerchandiseVariants(attrs));
    dispatch(setMerchandiseVariantsObj(prodVariants));
  }

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-5 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">Create Variants</span>
          <br />
        </div>
      </div>
      <div className=" d-flex mb-4">
        <span className="font-bolder font-14 text-muted ">
          Add variants if this product comes in multiple versions, sizes, colors
          etc.
        </span>
      </div>

      {prodVariants && prodVariants.map((item, index) =>
        <div className="d-flex flex-row justify-content-between gap-2 mb-3" key={index}>
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">
            <div className="d-flex flex-column gap-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">Option {index + 1}</span>
                    <InfoTooltip
                      title={"option for variants"}
                    />
                  </div>
                  {index === 0 ?
                    <div className="py-2">
                      <BackButton onClick={() => history('/merchandise-attribute')} />
                    </div> :

                    <div className="cursor py-2" onClick={() => handleRemoveClick(index)}>
                      <CloseIcone />
                    </div>}
                </div>
                <div className="d-flex flex-column gap-1">
                  <InputField
                    onChange={handleInputChangeState}
                    name={index}
                    placeholder={"Enter variants(versions, sizes, colors etc.)"}
                    type="text"
                    maxLength={TITLE_TEXT_LIMIT}
                    value={item.optionTxt}
                  />
                  {!item.optionTxt && error.optionTxt ? (
                    <label className="text-error">{error.optionTxt}</label>
                  ) : null}
                </div>
                {/* Dropdown */}
                {/* <div className="d-flex flex-column gap-1">
                  <div className="btn-group w-100">
                    <div
                      className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                      data-bs-toggle="dropdown"
                    >
                      {item.option ? item.option?.name : "Select"}
                    </div>
                    <ul className="dropdown-menu rounded-card-7 dropdown-menu-start">
                      {Variants &&
                        Variants.map((item, i) => (
                          <li key={i} onClick={() => handleOptionValue(item, index)} >
                            <div className="dropdown-item cursor">{item.name}</div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {!item.option && error.option ? (
                    <label className="text-error">{error.option}</label>
                  ) : null}
                </div> */}
              </div>


              {/* <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">Select colors</span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <div className="btn-group w-100">
                  <div
                    className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                    data-bs-toggle="dropdown"
                  >
                    {item.value ? item.value?.select_colors : "Select"}
                  </div>
                  <ul className="dropdown-menu rounded-card-7 dropdown-menu-start">
                    {selectColor &&
                      selectColor.map((item, i) => (
                        <li key={i} onClick={() => handleChangeValue(item, index)} >
                          <div className="dropdown-item cursor">{item.select_colors}</div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div> */}

              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column gap-1">
                  {/* <TagsInput
                    tags={tags}
                    setTags={setTags}
                    placeholder={"Enter tags to describe your item"}
                  /> */}
                  <div className="tags-input border border-2">
                    <ul id="tags">
                      {item.tags.map((tag, i) => (
                        <li key={i} className="tag align-items-center">
                          <span className=''>{tag}</span>
                          <span className='tag-close-icon'
                            onClick={() => removeTags(i, index)}>
                            x
                          </span>
                        </li>
                      ))}
                    </ul>
                    <input
                      type="text"
                      name="tags"
                      onKeyUp={event => event.key === "Enter" ? addTags(event, index) : null}
                      placeholder={"Enter tags"}
                    />
                  </div>
                  {!item.tags.length > 0 && error.tags ? (
                    <label className="text-error">{error.tags}</label>
                  ) : null}
                </div>
              </div>
              {index === 0 ?
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">Require images?</span>
                    <InfoTooltip
                      title={"Disable If your product has no variant images"}
                    />
                  </div>
                  <ReactSwitchButton
                    onChange={() => handleSwitchButton(index)}
                    value={item.requireImages}
                  />
                </div> : null}

            </div>
          </div>
        </div>
      )}

      <div className="d-flex flex-row justify-content-start gap-4 width-65 my-2">
        <Button
          onClick={() => handleAddNewOption()}
          text="+ Add new option"
          type="square"
        />
        <Button
          onClick={() => goTonextStep()}
          text="Save & Next"
          type="primary"
        />
      </div>
      <div className="position-fixed bottom-0 start-50 translate-middle-x w-100 " id="liveToast"      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Maximum three varient add!"
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default CreateVarient;
