export const FETCH_ALL_CLIENT_REQUEST = "FETCH_ALL_CLIENT_REQUEST"
export const FETCH_ALL_CLIENT_SUCCESS = "FETCH_ALL_CLIENT_SUCCESS"
export const FETCH_ALL_CLIENT_FAILURE = "FETCH_ALL_CLIENT_FAILURE"

// set selected client in redux when we select client in client mapping 
export const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT"

// get client detail by client id 
export const GET_CLIENT_BYID_REQUEST = "GET_CLIENT_BYID_REQUEST"
export const GET_CLIENT_BYID_SUCCESS = "GET_CLIENT_BYID_SUCCESS"
export const GET_CLIENT_BYID_FAILURE = "GET_CLIENT_BYID_FAILURE"

// client filer
export const CLIENT_FILTER_DATA = "CLIENT_FILTER_DATA"
export const CLEAR_CLIENT_LIST = "CLEAR_CLIENT_LIST"