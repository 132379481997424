import React from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as BlueTag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as RadishTag } from "../../assests/svg/Tag/radish_tag.svg";
import { ReactComponent as PinkTag } from "../../assests/svg/Tag/pink_tag.svg";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/dark_green.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function StoreOrderDetails() {
  const { t } = useTranslation();
  const giftcardOrderData = useSelector((state) => state.createOrder);

  const orderamount =
    giftcardOrderData?.createorderData?.quantity *
    (giftcardOrderData?.createorderData?.product?.giftcard_value_type ===
    "denominations"
      ? giftcardOrderData?.createorderData?.denomination?.denomination_value
      : giftcardOrderData?.createorderData?.rangeDenomination);

  let discountedamount;
  if(giftcardOrderData?.createorderData?.selectedProduct?.discount === undefined){
    discountedamount =
    (orderamount * 0 ) / 100;
  }
  else{
    discountedamount = (orderamount *
      giftcardOrderData?.createorderData?.selectedProduct?.discount) /
    100;
  }

  return (
    <div className="d-flex flex-column justify-content-between bg-white shadow-sm rounded-3 p-4 w-100">
      <div className="d-flex gap-4  pb-3">
        <span className="font-bold font-20 ">{t("Details")}</span>
      </div>
      <div className=" d-flex flex-column gap-3">
        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <BlueTag />
          <span className="font-20 font-bold">
            {t("Denomination")} :
            <span className="font-20 font-bold text-light-blue mx-1">
              {
                giftcardOrderData?.createorderData?.product?.country
                  ?.currency_symbol
              }
              {giftcardOrderData?.createorderData?.product
                ?.giftcard_value_type === "denominations"
                ? giftcardOrderData?.createorderData?.denomination
                    ?.denomination_value
                : giftcardOrderData?.createorderData?.rangeDenomination}
            </span>
          </span>
        </div>

        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <RadishTag />
          <span className="font-20 font-bold">
            {t("Quantity")} :
            <span className="font-20 font-bold text-light-blue mx-1">
              {giftcardOrderData?.createorderData?.selectedQuantity}
            </span>
          </span>
        </div>

        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <PinkTag />
          <span className="font-20 font-bold">
            {t("Store name")} :
            <span className="font-22 font-bold text-light-blue mx-1">
              <u>
                {giftcardOrderData?.createorderData?.selectedStore?.store_name}
              </u>
            </span>
          </span>
        </div>

        {giftcardOrderData?.createorderData?.selectedProduct?.discount  && <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <BlueTag />
          <span className="font-20 font-bold">
            {t("Client discount")}:{" "}
            <span className="font-22 font-boldest text-light-blue ">
              {
                giftcardOrderData?.createorderData?.selectedProduct?.discount
              }

              <span className="font-22 font-boldest text-light-blue ">%</span>
            </span>
          </span>
        </div>}

        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <BlueTag />
          <span className="font-20 font-bold">
            {t("Order amount")} :
            <span className="font-20 font-bold text-light-blue mx-1">
              <span>
                {
                  giftcardOrderData?.createorderData?.product?.country
                    .currency_symbol
                }
              </span>
              {giftcardOrderData?.createorderData?.quantity *
                (giftcardOrderData?.createorderData?.product
                  ?.giftcard_value_type === "denominations"
                  ? giftcardOrderData?.createorderData?.denomination
                      ?.denomination_value
                  : giftcardOrderData?.createorderData?.rangeDenomination)}
            </span>
            <span className="font-15 font-bold text-muted">
                {t("(Before Discount)")}
              </span>
          </span>
        </div>

        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <BlueTag />
          <span className="font-20 font-bold">
            {t("Order amount")} :
            <span className="font-20 font-bold text-light-blue mx-1">
              <span>
                {
                  giftcardOrderData?.createorderData?.product?.country
                    .currency_symbol
                }
              </span>
              {orderamount - discountedamount}
              <span className="font-15 font-bold text-muted">
                {t("(After Discount)")}
              </span>
            </span>
          </span>
        </div>

        {giftcardOrderData?.createorderData?.deliveryType?.name ===
        "Physical" ? (
          <div className="d-flex gap-3 my-2 mx-2 align-items-center ">
            <GreenTag />
            <span className="font-20 font-bold">
              {t("Delivery address")} :
              <span className="font-20 font-bold text-light-blue mx-1">
                {giftcardOrderData?.createorderData?.deliveryAddressline1}
              </span>
              ,
              {giftcardOrderData?.createorderData?.deliveryAddressline2 && (
                <span className="font-20 font-bold text-light-blue mx-1">
                  {giftcardOrderData?.createorderData?.deliveryAddressline2}
                </span>
              )}
              {giftcardOrderData?.createorderData?.deliveryAddressline2 && ","}
              {giftcardOrderData?.createorderData?.pincode && (
                <span className="font-20 font-bold text-light-blue mx-1">
                  {giftcardOrderData?.createorderData?.pincode}
                </span>
              )}
              {giftcardOrderData?.createorderData?.pincode && ","}
              <span className="font-20 font-bold text-light-blue mx-1">
                {giftcardOrderData?.createorderData?.cityName}
              </span>
            </span>
          </div>
        ) : (
          <div className="d-flex gap-3 my-2 mx-2 align-items-center">
            <GreenTag />
            <span className="font-20 font-bold">
              {t("Delivery type")} :
              <span className="font-20 font-bold text-light-blue mx-1">
                {giftcardOrderData?.createorderData?.deliveryType?.name}
              </span>
            </span>
          </div>
        )}

        <div className="d-flex gap-3 my-2 mx-2 align-items-center">
          <RadishTag />
          <span className="font-20 font-bold">
            {t("Email Id")} :
            <span className="font-20 font-bold text-light-blue mx-1">
              {giftcardOrderData?.createorderData?.emailid}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default StoreOrderDetails;
