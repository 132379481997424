import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import ReactSwitchButton from "../../../components/Button/ReactSwitchButton";
import InputGroupTxt from "../../../components/InputField/InputGroupTxt";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { patchCreateStore } from "../../../Redux/CreateStore/createStoreAction";
import { getAllRedemptionMethod } from "../../../Redux/Redemption/redemptionAction";
import { getAllCountriesList } from "../../../Redux/Countries/countryAction";

const EditStore = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()

  const storeItem = location.state.storeItem
  const countries = useSelector((state) => state.countries.countries);
  const redemptionMethod = useSelector((state) => state?.redemptionmethods?.redemptionMethods);


  const [country, setCountry] = useState("");
  const [transactionType, setTransactionType] = useState("");
  
  const organizationState = useSelector(
    (state) => state?.organizations?.organizations
  );


  
  const [inputFields, setFields] = useState({
    storeName: "",
    storeId: "",
    city: "",
    storeFullAdd: "",
    organization: "",
    emailid: "",
    storePhoneNum: "",
    zipCode: ""
  });

  const [switchButton, setSwitchButton] = useState({ activeFlag: true, notificationFlag: false });

  const [error, setError] = useState({
    storeName: "",
    storeId: "",
    transactionType: "",
    country: "",
    organization: "",
    city: "",
    storeFullAdd: "",
    emailid: "",
    storePhoneNum: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllRedemptionMethod());
    dispatch(getAllCountriesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (storeItem) {
      setFields({
        ...inputFields,
        storeName: storeItem?.store_name,
        storeId: storeItem?.store_id,
        organization: storeItem?.organization_id,
        city: storeItem?.city_name,
        storeFullAdd: storeItem?.store_address,
        emailid: storeItem?.email_id,
        storePhoneNum: storeItem?.store_phone_no,
        zipCode: storeItem?.zip_code
      });
      setSwitchButton({ ...switchButton, activeFlag: storeItem.active_flag, notificationFlag: storeItem.notify_flag });
    }
  }, [storeItem]);

  useEffect(() => {
    if (storeItem) {
      storeItem && countries && countries.map((item) => {
        if (storeItem?.country_id === item?.id) {
          setCountry(item);
          // item?.id && dispatch(fetchAllCountrieDetails(item?.id));
        }
      })
    }
  }, [storeItem, country]);

  useEffect(() => {
    if (storeItem) {
      storeItem && redemptionMethod && redemptionMethod.map((item) => {
        if (storeItem?.transaction_type.toLowerCase() === item.redemption_method.toLowerCase()) {
          setTransactionType(item);
        }
      })
    }
  }, [storeItem, redemptionMethod]);



  function handleChange(evt) {
    const value = evt.target.value;
    setFields({ ...inputFields, [evt.target.name]: value, });
  }
  //for drop down

  const handleChangeCountries = (e) => {
    setCountry(e)
  };


  const handleChangeOrganisation = (e) => {
    setFields({ ...inputFields, organization: e });
    // dispatch(getAllOrganizationList(e.id))
  };

  const handleChangeTransactionType = (e) => {
    setTransactionType(e)
  };

  const switchHandle = () => {
    setSwitchButton({ ...switchButton, activeFlag: !switchButton.activeFlag });
  };

  //for validations
  const validate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!inputFields.storeName) {
      setError({ storeName: "Please enter store name" });
      return false;
    } else if (!inputFields.storeId) {
      setError({ storeId: "Please enter store id" });
      return false;
    } else if (!transactionType) {
      setError({ transactionType: "Please select transaction type" });
      return false;
    } else if (!country) {
      setError({ country: "Please select country" });
      return false;
    } else if (!inputFields.city) {
      setError({ city: "Please enter city name" });
      return false;
    } else if (!inputFields.storeFullAdd) {
      setError({ storeFullAdd: "Please provides full address" });
      return false;
    } else if (!inputFields.emailid) {
      setError({ emailid: "Please enter email id" });
      return false;
    } else if (!inputFields.emailid || emailRegex.test(inputFields.emailid) === false) {
      setError({ emailid: "Please enter valid email" });
      return false;
    }
    return true;
  };



  const submit = () => {
    let val = validate();
    if (val) {
      let obj = {
        id: storeItem.id,
        storeName: inputFields.storeName,
        storeId: inputFields.storeId,
        transactionType: transactionType,
        country: country,
        city: inputFields.city,
        storeFullAdd: inputFields.storeFullAdd,
        zipcode: inputFields.zipCode,
        emailid: inputFields.emailid,
        phonenumber: inputFields.storePhoneNum,
        active_flag: switchButton?.activeFlag,
        notificationFlag: switchButton.notificationFlag
      };

      dispatch(patchCreateStore(obj, goToSelectStore));

    }
  };

  const goToSelectStore = () => {
    history("/gift-card-distribution");
  };


  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-3 px-4 bg-light">
      <div className="d-flex flex-row align-items-center gap-3">
        <LeftIcon onClick={() => history(-1)} className="cursor" />
        <span className="font-bold font-32 my-2">Edit store</span>
        <br />
      </div>

      <div className="width-100 ">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
          <div className="d-flex flex-column gap-4 mt-4 width-65 ">
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Store name<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
                placeholder={"Enter store name"}
                name="storeName"
                value={inputFields.storeName}
                onChange={handleChange}
              />
              {!inputFields.storeName && error.storeName ? (
                <label className="text-error">{error.storeName}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Store ID<span className="text-danger">*</span>
                  {/* <span className="font-bold font-14 text-muted">
                    ( Store ID is lorem ipsum dolor sit)
                  </span> */}
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
                placeholder={"Enter store ID"}
                name="storeId"
                value={inputFields.storeId}
                onChange={handleChange}
              />
              {!inputFields.storeId && error.storeId ? (
                <label className="text-error">{error.storeId}</label>
              ) : null}
            </div>

           {/* <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">Organisation</span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      noOptionsMessage={() => "Not found"}
                      placeholder="Select organization"
                      getOptionLabel={(organizationState) =>
                        organizationState.default_title
                      }
                      options={organizationState}
                      value={inputFields?.organization}
                      onChange={handleChangeOrganisation}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                    />
                  </div>
                </div>*/}
                <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    Organisation
                  </span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <InputField
                  placeholder={"Enter city name"}
                  name="city"
                  value={inputFields?.organization}
                 // onChange={handleChange}
                 disabled
                 
                />
              </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Transaction type<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <Select
                  noOptionsMessage={() => "Not found"}
                  placeholder="Select transaction type"
                  getOptionLabel={(redemptionMethod) =>
                    redemptionMethod.redemption_method
                  }
                  options={redemptionMethod}
                  value={transactionType}
                  onChange={handleChangeTransactionType}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
                {!transactionType && error.transactionType ? (
                  <label className="text-error">{error.transactionType}</label>
                ) : null}
              </div>
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Country<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <Select
                  noOptionsMessage={() => "Not found"}
                  placeholder="Select country & currency"
                  getOptionLabel={(countries) => countries.name}
                  options={countries}
                  value={country}
                  onChange={handleChangeCountries}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                  isDisabled={country ? true : false}
                />
                {!country && error.country ? (
                  <label className="text-error">{error.country}</label>
                ) : null}
              </div>
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  City<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
                placeholder={"Enter city name"}
                name="city"
                value={inputFields.city}
                onChange={handleChange}
              />
              {!inputFields.city && error.city ? (
                <label className="text-error">{error.city}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-1">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Store Full Address<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
                placeholder={"Enter store address"}
                name="storeFullAdd"
                value={inputFields.storeFullAdd}
                onChange={handleChange}
              />
              {!inputFields.storeFullAdd && error.storeFullAdd ? (
                <label className="text-error">{error.storeFullAdd}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">Zip code</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField placeholder={"Enter Zip code"} type="number" value={inputFields.zipCode} />
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Email ID
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
                placeholder={"Enter email ID"}
                type="email"
                name="emailid"
                value={inputFields.emailid}
                onChange={handleChange}
              />
              {error.emailid ? (
                <label className="text-error">{error.emailid}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Store phone number
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <InputGroupTxt
                  title={country?.phone_code ? "+" + country?.phone_code : ""}
                  placeholder={"Enter store phone number"}
                  type="number"
                  name="storePhoneNum"
                  lang={props?.lang}
                  value={inputFields.storePhoneNum}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between mb-2 gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-13">
                  Active
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <ReactSwitchButton
                onChange={() => setSwitchButton({ ...switchButton, activeFlag: !switchButton.activeFlag })}
                value={switchButton.activeFlag}
              />
            </div>

            <div className="d-flex flex-row justify-content-between mb-2 gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-13">
                  Send notifications to user(Email will also be used for OTP
                  authentication)<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <ReactSwitchButton
                onChange={() => setSwitchButton({ ...switchButton, notificationFlag: !switchButton.notificationFlag })}
                value={switchButton.notificationFlag}
              />
            </div>

            <div className="d-flex py-1 px-3">
              <Button onClick={() => submit()} text="Update" type="primary" />
            </div>
          </div>
        </div>
      </div>
      {/* <div>{JSON.stringify(fields)}</div>
      <div>{JSON.stringify(switchButton)}</div> */}
    </div>
  );
};

export default EditStore;