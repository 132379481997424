import React, { useEffect } from "react";
import "./PreviewCardVarientSlider.css";
import { MdOutlineExpandLess } from "react-icons/md";
import { IconContext } from "react-icons";
import PrevSliderLeftAndRightButtons from "./PrevSliderLeftAndRightButtons";

const PreviewCardVarientSlider = (props) => {
  const {
    varientProductActiveImage,
    selectedVarienImageArray,
    defualtProduct,
  } = props;

  useEffect(() => {
    // console.log("***Slider Re-Render***")
  }, [selectedVarienImageArray]);

  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner text-center">
        {selectedVarienImageArray &&
        selectedVarienImageArray.length > 1 ? (
          selectedVarienImageArray.map((item, index) => {
            return (
              <>
                <div
                  className={
                    index === 0 ? "carousel-item active" : "carousel-item"
                  }
                >
                  <img
                    src={item?.file_url}
                    key={index}
                    alt="not found"
                    id="product-preview"
                  />
                </div>
                <PrevSliderLeftAndRightButtons />
              </>
            );
          })
        ) : (
          <div className={"carousel-item active"}>
            <img
              src={
                varientProductActiveImage
                  ? varientProductActiveImage
                  : defualtProduct
              }
              //   key={product_default}
              alt="not found"
              id="product-preview"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewCardVarientSlider;
