import { baseUrl, getToken, USER_AUTHORIZATION } from "../../utils/prodURL";
import axios from "axios";
import {
  GET_ALL_USER_ROLE_FAILURE,
  GET_ALL_USER_ROLE_REQUEST,
  GET_ALL_USER_ROLE_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_ROLE_FAILURE,
  GET_USER_ROLE_REQUEST,
  GET_USER_ROLE_SUCCESS,
  GET_USER_SUCCESS,
  SET_USER_FAILURE,
  SET_USER_REQUEST,
  SET_USER_ROLE_FAILURE,
  SET_USER_ROLE_REQUEST,
  SET_USER_ROLE_SUCCESS,
  SET_USER_SUCCESS,
  GET_USER_ROLE_DATA_BYID_REQUEST,
  GET_USER_ROLE_DATA_BYID_SUCCESS,
  GET_USER_ROLE_DATA_BYID_FAILURE,
  UPDATE_USER_ROLE_DATA_REQUEST,
  UPDATE_USER_ROLE_DATA_SUCCESS,
  UPDATE_USER_ROLE_DATA_FAILURE,
  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
  CLEAR_USER_LIST,
  CLEAR_USER_ROLES_LIST
} from "./userType";

// get user list
export const getUserRequest = () => {
  return {
    type: GET_USER_REQUEST,
  };
};
export const getUserSuccess = (obj) => {
  return {
    type: GET_USER_SUCCESS,
    payload: obj,
  };
};
export const getUserFailure = (error) => {
  return {
    type: GET_USER_FAILURE,
    payload: error,
  };
};

export const getUserList = () => {
  return (dispatch) => {
    dispatch(getUserRequest());
    let config = {
      method: "get",
      url: `${USER_AUTHORIZATION}/v1/user`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getUserSuccess(response.data.result));
        }
      })
      .catch((error) => {
        dispatch(getUserFailure("Something went wrong!! Please try again!!"));
      });
  };
};

// set user role

export const setUserRequest = () => {
  return {
    type: SET_USER_REQUEST,
  };
};
export const setUserSuccess = (obj) => {
  return {
    type: SET_USER_SUCCESS,
    payload: obj,
  };
};
export const setUserFailure = (error) => {
  return {
    type: SET_USER_FAILURE,
    payload: error,
  };
};

export const setUser = (obj, gotoSucess, showMassage) => {
  return (dispatch) => {
    dispatch(setUserRequest());

    let payload = {
      user_type: obj.user_type.value,
      type_id: obj.selectedPartner?.id ? obj.selectedPartner?.id : "", //partner
      sub_type_id: obj.selectStore?.id, //store id
      user_role_id: obj.selectRole?.id, //role id
      email: obj.email,
      password: obj.password,
      username: obj.userName,
      firstname: obj.firstName,
      lastname: obj.lastName,
      contact_no: obj.contact_no,
    };
    let config = {
      method: "post",
      url: `${USER_AUTHORIZATION}/v1/user/register`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          dispatch(setUserSuccess(response.data.result));
          gotoSucess();
        } else if (response.data.status === "error") {
          showMassage(response?.data?.errors[0]);
        }
      })
      .catch((error) => {
        dispatch(setUserFailure("Something went wrong!! Please try again!!"));
      });
  };
};

// Update user info

export const updateUserRequest = () => {
  return {
    type: UPDATE_USER_DATA_REQUEST,
  };
};
export const updateUserSuccess = (obj) => {
  return {
    type: UPDATE_USER_DATA_SUCCESS,
    payload: obj,
  };
};
export const updateUserFailure = (error) => {
  return {
    type: UPDATE_USER_DATA_FAILURE,
    payload: error,
  };
};

export const updateUser = (obj, gotoSucess, showMassage) => {
  return (dispatch) => {
    dispatch(updateUserRequest());
    let user_id = obj?.user_id
    let payload = {
      user_role_id: obj.selectRole?.id, //role id
      firstname: obj.firstName,
      lastname: obj.lastName,
      contact_no: obj.contact_no,
      //  user_type: obj.user_type.value,
      //  type_id: obj.selectedPartner?.id ? obj.selectedPartner?.id : "", //partner
      //  sub_type_id: obj.selectStore?.id, //store id
      //  email: obj.email,
      //  password: obj.password,
      //  username: obj.userName,
    };
    let config = {
      method: "post",
      url: `${USER_AUTHORIZATION}/v1/user/${user_id}/update`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          dispatch(updateUserSuccess(response.data.result));
          gotoSucess();
        } else if (response.data.status === "error") {
          showMassage(response?.data?.errors[0]);
        }
      })
      .catch((error) => {
        dispatch(updateUserFailure("Something went wrong!! Please try again!!"));
      });
  };
};


// get user role
export const getUserRoleRequest = () => {
  return {
    type: GET_USER_ROLE_REQUEST,
  };
};
export const getUserRoleSuccess = (obj) => {
  return {
    type: GET_USER_ROLE_SUCCESS,
    payload: obj,
  };
};
export const getUserRoleFailure = (error) => {
  return {
    type: GET_USER_ROLE_FAILURE,
    payload: error,
  };
};

export const getUserRole = (role) => {
  return (dispatch) => {
    dispatch(getUserRoleRequest());
    let config = {
      method: "get",
      url: `${USER_AUTHORIZATION}/v1/user/role?user_type=${role}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getUserRoleSuccess(response.data.result));
        }
      })
      .catch((error) => {
        dispatch(
          getUserRoleFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// get user all role
export const getAllUserRoleRequest = () => {
  return {
    type: GET_ALL_USER_ROLE_REQUEST,
  };
};
export const getAllUserRoleSuccess = (obj) => {
  return {
    type: GET_ALL_USER_ROLE_SUCCESS,
    payload: obj,
  };
};
export const getAllUserRoleFailure = (error) => {
  return {
    type: GET_ALL_USER_ROLE_FAILURE,
    payload: error,
  };
};

export const getAllUserRole = () => {
  return (dispatch) => {
    dispatch(getAllUserRoleRequest());
    let config = {
      method: "get",
      url: `${USER_AUTHORIZATION}/v1/user/role`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          dispatch(getAllUserRoleSuccess(response.data.result));
        }
      })
      .catch((error) => {
        dispatch(
          getAllUserRoleFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// set user role

export const setUserRoleRequest = () => {
  return {
    type: SET_USER_ROLE_REQUEST,
  };
};
export const setUserRoleSuccess = (obj) => {
  return {
    type: SET_USER_ROLE_SUCCESS,
    payload: obj,
  };
};
export const setUserRoleFailure = (error) => {
  return {
    type: SET_USER_ROLE_FAILURE,
    payload: error,
  };
};

export const setUserRole = (obj, gotoSucess, showToastMessage) => {
  return (dispatch) => {
    dispatch(setUserRoleRequest());

    let payload = {
      user_type: obj.user_type.value,
      role_name: obj.roleName,
      description: obj.roleDescription,
      permissions: obj.permissions,
    };
    let config = {
      method: "post",
      url: `${USER_AUTHORIZATION}/v1/user/role`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        if (response?.data?.code === 200) {
          dispatch(setUserRoleSuccess(response?.data?.result));
          gotoSucess();
        } else {
          showToastMessage("Role already exist");
          dispatch(
            setUserRoleFailure("Something went wrong!! Please try again!!")
          );
        }
      })
      .catch((error) => {
        dispatch(
          setUserRoleFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};


// update user role permissions

export const updateUserRoleRequest = () => {
  return {
    type: UPDATE_USER_ROLE_DATA_REQUEST,
  };
};
export const updateUserRoleSuccess = (obj) => {
  return {
    type: UPDATE_USER_ROLE_DATA_SUCCESS,
    payload: obj,
  };
};
export const updateUserRoleFailure = (error) => {
  return {
    type: UPDATE_USER_ROLE_DATA_FAILURE,
    payload: error,
  };
};

export const updateUserRole = (obj, gotoSucess, showToastMessage) => {
  return (dispatch) => {
    dispatch(updateUserRoleRequest());
    let role_id = obj?.roleId
    let payload = {
      user_type: obj.user_type.value,
      // role_name: obj.roleName,
      description: obj.roleDescription,
      permissions: obj.permissions,
    };
    let config = {
      method: "PATCH",
      url: `${USER_AUTHORIZATION}/v1/user/role/${role_id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        if (response?.data?.code === 200) {
          dispatch(updateUserRoleSuccess(response?.data?.result));
          gotoSucess();
        } else {
          // showToastMessage("Role already exist");
          dispatch(
            updateUserRoleFailure("Something went wrong!! Please try again!!")
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateUserRoleFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// get user role data by id 
export const getUserRoleDataByIDRequest = () => {
  return {
    type: GET_USER_ROLE_DATA_BYID_REQUEST,
  };
};
export const getUserRoleDataByIDSuccess = (obj) => {
  return {
    type: GET_USER_ROLE_DATA_BYID_SUCCESS,
    payload: obj,
  };
};
export const getUserRoleDataByIDFailure = (error) => {
  return {
    type: GET_USER_ROLE_DATA_BYID_FAILURE,
    payload: error,
  };
};

export const getUserRoleDataByID = (userID) => {
  return (dispatch) => {
    dispatch(getUserRoleDataByIDRequest());
    let config = {
      method: "get",
      url: `${USER_AUTHORIZATION}/v1/user/role/${userID}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getUserRoleDataByIDSuccess(response.data.result));
        }
      })
      .catch((error) => {
        dispatch(getUserRoleDataByIDFailure("Something went wrong!! Please try again!!"));
      });
  };
};

export const clearUserList = () => {
  return {
    type: CLEAR_USER_LIST,
  };
};

export const clearUserRolesList = () => {
  return {
    type: CLEAR_USER_ROLES_LIST,
  };
};