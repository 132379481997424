import React from 'react'
import { ReactComponent as Orangelite } from "../../assests/svg/Tag/orange_lite_tag.svg";
import { ReactComponent as BlueTag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import EmailImg from "../../assests/img/Email_design .png";
import { ReactComponent as RightTick } from "../../assests/svg/Righttick.svg";
import EhadayaLogo from "../../assests/img/ehadaya_logo.png";
import HroundLogo from "../../assests/img/H_logo.png";
 import Barcode from "../../assests/img/lines-barcode.jpg";

const Email = () => {
  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-3 px-2 bg-light callout">
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 gap-3 py-4 px-4 callout">
      <div className="d-flex gap-3 px-5 pt-5 callout">
              <BlueTag />
              <span className="font-22 font-bold callout">Congratulations!</span>
            </div>
            <div className='d-flex gap-3 px-5 callout'>
            <span className="font-32 font-boldest text-responsive">You have received a Gift Card</span>
            </div>
            <div className='d-flex flex-row gap-2 px-5 justify-content-between text-responsive'>
            <div className='w-50 pt-3'>
                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facilis, iste. Unde corporis ducimus possimus mollitia
                    praesentium error ab porro ipsum eveniet. 
                    Atque eum beatae voluptatibus fugiat repellendus numquam et, consectetur nobis tempora.
                    </span>
                    <div className='d-flex flex-row gap-3 py-3'>
                    <span>
                    <img
                       src={Barcode?Barcode:EmailImg}
                      alt="not found"
                      className="img-fluid"
                    //   style={{ width: "50px", height: "50px" }}
                    />
                  </span>
                    </div>
                    <div className='d-flex flex-row gap-4 pt-3 callout'>
                    <div className=''>
                        <span className='text-dark font-15 font-boldest'>
                            C A R D N O 1 4 5 7 5 7 g W S F G
                        </span>
                    </div>
                    <div className='bg-success bg-opacity-25 rounded-3 callout gap-3'>
                        <span className='font-14 font-bold text-light-blue responsive px-2 cursor'>Copy</span>
                    </div>
                    </div>
            </div>
            <div className=''>
            <span>
                    <img
                      src={EmailImg}
                      alt="not found"
                      className="img-fluid"
                    //   style={{ width: "50px", height: "50px" }}
                    />
                  </span>
            </div>
            </div>

            <div className='d-flex flex-row gap-3 pt-4 px-5 justify-content-between'>

            <div className='width-65'>
            <div className="d-flex gap-3">
              <Orangelite />
              <span className="font-22 font-bold">Description</span>
            </div>
            <div className='d-flex flex-column pt-4'>
                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, iste. Unde corporis ducimus possimus mollitia praesentium error ab porro ipsum eveniet. Atque eum beatae voluptatibus fugiat repellendus numquam et, consectetur nobis tempora.</span>
                <br/>
                <br/>
                <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, iste. Unde corporis ducimus possimus mollitia praesentium error ab porro ipsum eveniet. Atque eum beatae voluptatibus fugiat repellendus numquam et, consectetur nobis tempora.</span>
            </div>
            </div>
            <div className="d-flex flex-column gap-4">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-22 font-bold">Features</span>
            </div>
            <div className='d-flex flex-column pt-4 gap-4 '>
            <span className='d-flex flex-row align-items-center border-bottom gap-3'><RightTick/>Usable Offline</span>
            <span className='d-flex flex-row align-items-center border-bottom gap-3'><RightTick/>Instant access</span>
            <span className='d-flex flex-row align-items-center border-bottom gap-3'><RightTick/>Globally usable</span>
            <span className='d-flex flex-row align-items-center border-bottom gap-3'><RightTick/>Reloadable</span>
            </div>
            </div>
            </div>

            <div className='d-flex flex-row px-5 justify-content-between py-3'>
            <div className='d-flex flex-column py-3 pt-3'>
                <span>Brought to you buy </span>
                <div className='d-flex flex-row align-items-center justify-content-between'>
                <div className='d-flex flex-row align-items-center'>
                <span className='align-items-center'>
                    <img
                      src={EhadayaLogo}
                      alt="not found"
                      className="img-fluid"
                    //   style={{ width: "50px", height: "50px" }}
                    />
                  </span>
                  <span className=''>
                    <img
                      src={HroundLogo}
                      alt="not found"
                      className="img-fluid"
                    //   style={{ width: "100px", height: "100px" }}
                    />
                  </span>
                  </div>
                  
                  </div>
                  
                  <span className='text-muted font-13 font-bold'>CopyRight eHadaya 2022 - All Rights Reserved </span>
            </div>
            <div className=' d-flex px-4 align-items-center'>
            <span className="font-15 font-bold text-light-blue cursor" >
            <u>Need help?</u>
          </span>
            </div>
            </div>
      </div>
      
    </div>
  )
}

export default Email;
