import React, { useEffect, useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import Button from "../Button/Button";
import InputField from "../InputField/InputField";
import ReactDatePicker from "../DatePicker/ReactDatePicker";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import {
  GIFTCARD_ORDER_STATUS,
  MERCHANDISE_ORDER_STATUS,
} from "../../utils/ConstantData";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderHistory,
  updateOrderStatus,
} from "../../Redux/Order/orderAction";
import { strFirstLetterCap } from "../../utils/constantFunction";
import Toasts from "../../components/Toasts/Toasts";

const OrderDetails = () => {
  const dispatch = useDispatch();

  const orderDetailState = useSelector(
    (state) => state?.allOrderList?.orderDetails
  );

  const [startDate, setStartDate] = useState(new Date());
  const [status, setStatus] = useState("");
  const [statusDropdownData, setStatusDropdownData] = useState([]);
  const [merStatusDropdownData, setMerStatusDropdownData] = useState([]);
  const [showToast, setShowToast] = useState(false);

  const [fields, setFields] = useState({
    orderStatus: "",
    date: "",
    trackingId: "",
    trackingUrl: "",
    notes: "",
    reason: "",
  });

  const [error, setError] = useState({
    orderStatus: "",
    trackingId: "",
    trackingUrl: "",
    reason: "",
  });

  const toggleShowToast = () => setShowToast(!showToast);

  const [permissions, setpermissions] = useState();
  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    // console.log(permissions);
  }, [permissions]);

  const handleChangeStatus = (e) => {
    setFields({
      ...fields,
      orderStatus: e,
      date: "",
      trackingId: "",
      trackingUrl: "",
      notes: "",
      reason: "",
    });
  };

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFields({ ...fields, [name]: value });
  };

  const validate = () => {
    if (!fields.orderStatus && orderDetailState?.result?.delivery_type !== "digital") {
      setError({ orderStatus: "Please select order status" });
      return false;
    } else if (!fields?.trackingId && fields.orderStatus?.name === "Shipped") {
      setError({ trackingId: "Please enter tracking id" });
      return false;
    } else if (!fields?.trackingUrl && fields.orderStatus?.name === "Shipped") {
      setError({ trackingUrl: "Please enter tracking url" });
      return false;
    } else if (
      !fields?.reason &&
      fields.orderStatus?.name === "Cancelled" &&
      fields.orderStatus?.name === "Cancelled"
    ) {
      setError({ reason: "Please enter reason" });
      return false;
    }
    return true;
  };
  const getStatus = (data) => {
    setStatus(data?.order_status);
    if (data) {
      dispatch(getOrderHistory(orderDetailState?.result?.id));
    }
  };

  useEffect(() => {
    setStatus(orderDetailState?.result?.order_status);
  }, [orderDetailState?.result?.order_status]);

  useEffect(() => {
    const newArrGif = GIFTCARD_ORDER_STATUS.filter((e) => e.value !== status);
    setStatusDropdownData(newArrGif);
    const newArrMer = MERCHANDISE_ORDER_STATUS.filter(
      (e) => e.value !== status
    );
    setMerStatusDropdownData(newArrMer);
  }, [status]);

  const getResponseCode = (code) => {
    if (code === 200) {
      toggleShowToast();
    }
    setFields({
      ...fields,
      orderStatus: "",
      date: "",
      trackingId: "",
      trackingUrl: "",
      notes: "",
      reason: "",
    });
  };

  //handle order update  MERCHANDISE_ORDER_STATUS
  const handleOrderUpdates = () => {
    let val = validate();
    if (val) {
      let obj = {
        id: orderDetailState?.result?.id,
        orderStatus: fields.orderStatus,
        trackingId: fields?.trackingId ? fields?.trackingId : "",
        trackingUrl: fields.trackingUrl ? fields.trackingUrl : "",
        notes: fields?.notes ? fields?.notes : "",
        reason: fields?.reason ? fields?.reason : "",
        date: fields.orderStatus === "Shipped" ? startDate : "",
      };
      dispatch(updateOrderStatus(obj, getStatus, getResponseCode));
      //history("/order-management/dashboard");
    }
  };
  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex py-3 gap-3">
          <Tag />
          <span className="font-20 font-bold">Order Details</span>
        </div>
      </div>

      <div className="d-flex flex-column pt-4 justify-content-between gap-3">
        <span className="font-15 font-bold">
          Order ID:
          <span className="font-13 text-muted mx-1">
            {orderDetailState?.result?.id}
          </span>
        </span>
      </div>
      <div className="d-flex gap-2">
        <span className="font-15 font-bolder">Status:</span>
        <span className="font-13 font-bold  text-muted">
          {status === "unable_to_fulfill" ? (
            <span>Unable to fulfill</span>
          ) : status === "in_progress" ? (
            <span>In Progress</span>
          ) : (
            strFirstLetterCap(status)
          )}
        </span>
      </div>
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="font-bold font-14">Delivary type:</span>
          <span className="font-13 font-bold  text-muted">
            {orderDetailState?.result?.delivery_type === "digital"
              ? "Digital"
              : "Physical"}
          </span>
        </div>
      </div>

      <div className="d-flex flex-column pt-4 mb-3 gap-2">
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="font-bold font-15">
            Update order status<span className="text-danger">*</span>
          </span>
          <InfoTooltip title={"Please select Action type(this field only enable when delivery type is physical)"} />
        </div>

        {orderDetailState?.result?.product_type === "giftcard" ? (
          <div>
            <Select
              placeholder="Select status"
              noOptionsMessage={() => "Not found"}
              getOptionLabel={(status) => status.name}
              options={statusDropdownData}
              styles={CUSTOM_DROPDOWN_STYLE}
              value={fields.orderStatus}
              onChange={handleChangeStatus}
              isClearable
              isDisabled={orderDetailState?.result?.delivery_type === "digital"}
            />
          </div>
        ) : (
          <div>
            <Select
              placeholder="Select status"
              noOptionsMessage={() => "Not found"}
              getOptionLabel={(status) => status.name}
              options={merStatusDropdownData}
              styles={CUSTOM_DROPDOWN_STYLE}
              value={fields.orderStatus}
              onChange={handleChangeStatus}
              isClearable
            />
          </div>
        )}
        {!fields.orderStatus && error.orderStatus ? (
          <label className="text-error">{error.orderStatus}</label>
        ) : null}
      </div>

      {fields?.orderStatus?.name === "Cancelled" ? (
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Cancellation Reason <span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <InputField
            value={fields?.reason}
            name="reason"
            placeholder={"Enter reason"}
            type="text"
            onChange={handleInputChange}
          />
          {!fields.reason && error.reason ? (
            <label className="text-error">{error.reason}</label>
          ) : null}
        </div>
      ) : fields?.orderStatus?.name === "Unable to fulfill" ? (
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Reason <span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <InputField
            value={fields?.reason}
            name="reason"
            placeholder={"Type something"}
            type="text"
            onChange={handleInputChange}
          />
          {!fields.reason && error.reason ? (
            <label className="text-error">{error.reason}</label>
          ) : null}
        </div>
      ) : fields?.orderStatus?.name === "Shipped" ? (
        <div className="d-flex flex-column mb- gap-3">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row justify-content-start align-items-center gap-2 w-100">
              <div className="col d-flex flex-column gap-2">
                <span className="fs-lighter font-14 font-bold">
                  Shipped date
                  <span>
                    {" "}
                    <InfoTooltip />
                  </span>
                </span>
                <ReactDatePicker
                  selected={startDate}
                  onChange={setStartDate}
                  minDate={new Date()}
                  className="datepicker-style-without-border"
                />
                {/* {!productCatObj.startDate && error.startDate ? (
            <label className="text-error">{error.startDate}</label>
          ) : null}*/}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2 mb-2">
              <span className="font-bold font-14 ">
                Tracking ID<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <InputField
              value={fields.trackingId}
              name="trackingId"
              placeholder={"Enter Tracking ID"}
              onChange={handleInputChange}
            />
            {!fields.trackingId && error.trackingId ? (
              <label className="text-error">{error.trackingId}</label>
            ) : null}
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2 mb-2">
              <span className="font-bold font-14">
                Tracking URL<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <InputField
              value={fields.trackingUrl}
              name="trackingUrl"
              placeholder={"Enter tracking url"}
              type="text"
              onChange={handleInputChange}
            />
            {!fields.trackingUrl && error.trackingUrl ? (
              <label className="text-error">{error.trackingUrl}</label>
            ) : null}
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">Notes</span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <InputField
              name="notes"
              value={fields.notes}
              placeholder={"Type something"}
              type="text"
              onChange={handleInputChange}
            />
          </div>
        </div>
      ) : null}

      {permissions?.all_orders?.create ? (
        <div className="d-flex flex-row justify-content-between align-items-center mt-3 gap-2">
          <div className="d-flex">
            <Button text="Update" type="primary" onClick={handleOrderUpdates} 
            />
          </div>
        </div>
      ) : null}

      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your order status has been changed successfully"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default OrderDetails;
