import {GET_WARRANTY_REQUEST,
    GET_WARRANTY_SUCCESS,
    GET_WARRANTY_FAILURE} from './warrantyType'
    

    import { baseUrl, getToken } from "../../utils/prodURL";
    import axios from "axios";
    
    export const getWarrantyRequest = () => {
      return {
        type: GET_WARRANTY_REQUEST,
      };
    };
    export const getWarrantySuccess = (warranty) => {
      return {
        type: GET_WARRANTY_SUCCESS,
        payload: warranty,
      };
    };
    export const getWarrantyFailure = (error) => {
      return {
        type: GET_WARRANTY_FAILURE,
        payload: error,
      };
    };
    
    export const getWarranty = () => {
      return (dispatch) => {
        dispatch(getWarrantyRequest());
        let config = {
          method: "get",
          url: `${baseUrl}/warranty`,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
        axios(config)
          .then((response) => {
           // console.log("response" + JSON.stringify(response.data));
             dispatch(getWarrantySuccess(response.data))
          })
          .catch((error) => {
            const errorMsg = error;
            dispatch(getWarrantyFailure("Something went wrong!! Please try again!!"));
          });
      };
    };
    