import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "../Dropdown/Dropdown";
import InfoTooltip from "../Tooltip/InfoTooltip";
import ReactDatePicker from "../DatePicker/ReactDatePicker";

const DetailsReport = () => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex flex-column bg-white shadow-sm card-rounded-4 py-3 px-4 w-100 ">
      <div className="d-flex flex-column gap-2 ">
        <div className="d-flex flex-column pt-4 gap-2">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">Genrate report for</span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <Dropdown />
        </div>

        <div className="d-flex flex-column py-2  gap-2">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">Select Transaction type</span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <Dropdown />
        </div>

        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-row align-items-center">
            <span className="font-bold font-14">From : To</span>
          </div>
          <div className="d-flex w-100">
            <ReactDatePicker
              selected={startDate}
              onChange={setStartDate}
              minDate={new Date()}
              className="datepicker-style-without-border"
            />
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default DetailsReport;
