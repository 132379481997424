import {GET_PRODUCT_CATEGORY_REQUEST,
    GET_PRODUCT_CATEGORY_SUCCESS,
    GET_PRODUCT_CATEGORY_FAILURE
} from './prodCatType'


const initialProdCategoryState={
    loading: false,
    prodcategories: [],
    error: ''
}

const productCategoryReducer = (state = initialProdCategoryState, action) => {

switch (action.type) {
    case GET_PRODUCT_CATEGORY_REQUEST:
        return {
            ...state,
            loading: true
        }
    case GET_PRODUCT_CATEGORY_SUCCESS:
         return {
            ...state,
            loading: false,
            prodcategories: action.payload,
            error: ''

        }
    case GET_PRODUCT_CATEGORY_FAILURE:
        return {
            ...state,
            loading: false,
            prodcategories: [],
            error: action.payload
        }
    default: return state
}
}
export default productCategoryReducer