import {
  PUT_CREATESTORE_REQUEST,
  PUT_CREATESTORE_SUCCESS,
  PUT_CREATESTORE_FAILURE,
  GET_STORELIST_REQUEST,
  GET_STORELIST_SUCCESS,
  GET_STORELIST_FAILURE,
  EPOS_STORE_DATA,
  GET_STORELIST_MAP_TO_ORG_REQUEST,
  GET_STORELIST_MAP_TO_ORG_SUCCESS,
  GET_STORELIST_MAP_TO_ORG_FAILURE,
  SET_SELETED_STORE_OR_ORG_DATA,
  GET_STORELIST_MAP_TO_ORG_DELETE,
  GET_STORE_DETAILS_REQUEST,
  GET_STORE_DETAILS_SUCCESS,
  GET_STORE_DETAILS_FAILURE
} from "./createStoreType";

const initialState = {
  storeDetails: null,
  createStore: [],
  createstoreData: null,
  createStoreResponese: null,
  storeListByOrgnazationId: [],
  selectedStoreOrOrg: null,
  eposStoreData: null,
  loading: false,
  error: "",
  next_iteration: true
};

function createStoreReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STORELIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case GET_STORELIST_SUCCESS:
      return {
        ...state,
        loading: false,
        createStore: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case GET_STORELIST_FAILURE:
      return {
        ...state,
        loading: false,
        createStore: [],
        error: action.payload,
      };
    case PUT_CREATESTORE_REQUEST:
      return {
        ...state,
        loading: true,
        createStoreResponese: null,
        error: "",
      };
    case PUT_CREATESTORE_SUCCESS:
      return {
        ...state,
        loading: false,
        createStoreResponese: action.payload,
        createstoreData: "",
        error: "",
      };
    case PUT_CREATESTORE_FAILURE:
      return {
        ...state,
        loading: false,
        createStoreResponese: null,
        error: action.payload,
      };
    case EPOS_STORE_DATA:
      return {
        ...state,
        loading: false,
        eposStoreData: action.payload,
      };
    case GET_STORELIST_MAP_TO_ORG_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case GET_STORELIST_MAP_TO_ORG_SUCCESS:
      return {
        ...state,
        loading: false,
        storeListByOrgnazationId: action.payload,
        error: "",
      };
    case GET_STORELIST_MAP_TO_ORG_FAILURE:
      return {
        ...state,
        loading: false,
        storeListByOrgnazationId: null,
        error: action.payload,
      };
    case GET_STORE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        storeDetails: null,
      };
    case GET_STORE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        storeDetails: action.payload,
        error: "",
      };
    case GET_STORE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        storeDetails: null,
        error: action.payload,
      };
    case GET_STORELIST_MAP_TO_ORG_DELETE:
      return {
        ...state,
        storeListByOrgnazationId: action.payload
      }
    case SET_SELETED_STORE_OR_ORG_DATA:
      return {
        ...state,
        selectedStoreOrOrg: action.payload,
        error: "",
      }

    default:
      return state;
  }
}

export default createStoreReducer;
