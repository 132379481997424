/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as OrangeTag } from "../../assests/svg/Tag/orange_tag.svg";
import { ReactComponent as CloseIcone } from "../../assests/svg/close.svg";
import { ReactComponent as DeleteIconWhite } from "../../assests/svg/delete_black.svg";
import DragHandle from "../../assests/img/drag-handle.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import BackButton from "../../components/Button/BackButton";
import ModalComp from "../../components/Models/ModalComp";
import MerchandiseModelPreview from "../../components/Models/MerchandiseModelPreview";
import { useDispatch, useSelector } from "react-redux";
import applePhones from "../../assests/img/Product-default.png";
import DragandDropMultiple from "../../components/DragandDrop/DragandDropMultiple";
import { EditorState, convertToRaw } from "draft-js";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/green_tag.svg"
import TextEditor from "../../components/InputField/TextEditor";
import Toasts from "../../components/Toasts/Toasts";
import { setMerchandiseVariantTerm } from "../../Redux/MerchandiseVarient/merchandiseVarientActions";
import Loader from "../../components/Loader/Loader";


const MerchandiseMapping = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const merchandiseVarientState = useSelector((state) => state?.merchandiseVarient?.merchandiseVarient);
  const merchandiseVarientObjState = useSelector((state) => state?.merchandiseVarient?.merchandiseVarientObj);
  const merchandiseVarientImg = useSelector((state) => state?.merchandiseVarient?.merchandiseVarientImg);
  const Preferredlanguage = useSelector((state) => state?.languages?.languages);
  const merchandiseVarientTermState = useSelector((state) => state?.merchandiseVarient?.merchandiseVarientTermImg);
  const merchandiseData = useSelector((state) => state?.merchandiseCatAttriData);
  const merchandiseNameDes = useSelector((state) => state?.merchandiseCatAttriData?.merchandiseNameDes);

  let defaultLang = localStorage.getItem('defaultLang')
  const edittorTxt = {
    value: EditorState.createEmpty(),
    lang: JSON.parse(defaultLang),
  };
  const [editorState, setEditorState] = useState(merchandiseVarientTermState?.termAndconditions ? merchandiseVarientTermState?.termAndconditions : [edittorTxt]);
  const [showToast, setShowToast] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [productImg, setProductImg] = useState(merchandiseVarientTermState?.productImg ? merchandiseVarientTermState?.productImg : []);
  const toggleShowToast = () => setShowToast(!showToast);

  const [error, setError] = useState({
    editorState: "",
    productImg: "",
  });


  const handleClose = () => setShowModal(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    merchandiseNameDes?.preferredLanguage && merchandiseNameDes?.preferredLanguage?.language_code !== "EN" && handleDropdownOnClick(merchandiseNameDes?.preferredLanguage);
  }, [merchandiseNameDes?.preferredLanguage]);

  useEffect(() => {
    if (!merchandiseNameDes) {
      history("/add-merchandise");
    }
  }, [merchandiseNameDes]);

  const getDescription = (txt, code) => {
    let arr = [...editorState];
    setError({ editorState: "" });
    arr.map((item, i) => {
      if (code === item.lang.language_code) {
        arr[i].value = txt;
      }
    });
    setEditorState(arr);
  };

  const handleAddClick = (e) => {
    if (
      editorState.some((item) => item.lang.language_code === e.language_code)
    ) {
      // alert("Already added");
      toggleShowToast();
      return;
    } else {
      setEditorState([
        ...editorState,
        { value: EditorState.createEmpty(), lang: e },
      ]);
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...editorState];
    list.splice(index, 1);
    setEditorState(list);
  };

  const handleDropdownOnClick = (obj) => {
    handleAddClick(obj);
  };

  const uploadImages = (item, index) => {
    history("/add-variant/add-variant-image", {
      state: {
        prodVariant: item,
        index: index,
      }
    });
  };

  const editImages = (item, index, prodVariantName) => {
    history("/sort-variant-images/sort-variant", {
      state: {
        prodVariant: item,
        prodVariantName: prodVariantName,
        index: index,
      }
    });
  };

  const validate = () => {
    let value = convertToRaw(editorState[0].value.getCurrentContent());

    if (merchandiseVarientObjState[0]?.requireImages) {
      let val = merchandiseVarientObjState[0]?.requireImages && merchandiseVarientImg.every(v => v.images.length > 0);
      merchandiseVarientObjState[0]?.requireImages ? setError({ productImg: "Upload images" }) : setError({ productImg: "" })
      return merchandiseVarientObjState[0]?.requireImages ? val : true
    } else if (!value.blocks[0].text) {
      setError({ editorState: "Please provide Terms & Condition" });
      return false;
    }
    return true;
  };

  // const imgValidate = () => {

  //   let val = merchandiseVarientObjState[0]?.requireImages && merchandiseVarientImg.every(v => v.images.length > 0);
  //   merchandiseVarientObjState[0]?.requireImages ? setError({ productImg: "Upload images" }) : setError({ productImg: "" })
  //   return merchandiseVarientObjState[0]?.requireImages ? val : true
  // }

  const saveAndPreview = () => {
    let value = validate();

    if (value) {
      let obj = {
        productImg: productImg,
        termAndconditions: editorState,
      };
      dispatch(setMerchandiseVariantTerm(obj));
      if (merchandiseVarientObjState[0]?.requireImages) {
        // dispatch(putMerchandiseVarientsImages(merchandiseData, merchandiseVarientImg));
        //  dispatch(putMerchandiseVarientsImages());

      }
      setShowModal(true);
    }
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(productImg);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);

    setProductImg(tempData);
  };

  const setMultipleImg = (e) => {
    let arr = [...productImg];
    if (arr.length > 0) {
      let imgArr = [...arr, ...e]
      setProductImg(imgArr);
    } else {
      setProductImg(e);
    }
  }

  const removeProductImg = (e) => {
    let array = [...productImg];
    if (e !== -1) {
      array.splice(e, 1);
      setProductImg(array);
    }
  }

  return (
    merchandiseData.loading ?
      <div className="position-fixed top-50 start-50">
        <Loader loading={merchandiseData.loading} />
      </div>
      :
      <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light ">
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <div className="d-flex flex-row align-items-center gap-3">
            <LeftIcon className="cursor" />
            <span className="font-bold font-32 my-2">Merchandise mapping</span>
            <br />
          </div>
        </div>
        <div className=" d-flex mb-4">
          <span className="font-bolder font-14 text-muted ">
            Add variants if this product comes in multiple versions. sizes, colors
            etc.
          </span>
        </div>

        <div className="d-flex flex-row justify-content-between gap-2">
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65 mb-2">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <Tag />
                <span className="font-20 font-bold">Product</span>
              </div>
              <div>
                <BackButton onClick={() => history('/add-variant')} />
              </div>
            </div>

            <div className="d-flex flex-column gap-4 mt-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">Mapping for</span>
                  <InfoTooltip
                    title={"Product for mapping"}
                  />
                </div>

                <div className="btn-group w-100">
                  <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                    {merchandiseVarientObjState && merchandiseVarientObjState[0]?.title}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">Option</span>
                  <InfoTooltip
                    title={"Option"}
                  />
                </div>
                <div className="btn-group w-100">
                  <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                    {merchandiseVarientObjState && merchandiseVarientObjState[0].optionTxt}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {merchandiseVarientObjState && !merchandiseVarientObjState[0].requireImages ?
          <div className="d-flex flex-row justify-content-between gap-2">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65 mb-2">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex gap-3">
                  <Tag />
                  <span className="font-20 font-bold">Product Images</span>
                </div>
              </div>

              <div className="d-flex flex-column gap-4 mt-4">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">Product images</span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>

                  <div className="btn-group w-100">
                    <DragandDropMultiple files={productImg} setFiles={setMultipleImg} />

                  </div>
                </div>

              </div>
              {productImg.length > 0 &&
                <div className="d-flex flex-column gap-4 mt-4">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      Sort images<span className="text-danger">*</span>
                      <span> (size should be 600*200)</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <div className="d-flex w-100">
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <table responsive="xl" className="w-100">
                        <Droppable droppableId="droppable-1">
                          {(provider) => (
                            <tbody
                              className="text-capitalize"
                              ref={provider.innerRef}
                              {...provider.droppableProps}
                            >
                              {productImg?.map((user, index) => (
                                <Draggable
                                  key={user.path}
                                  draggableId={user.path}
                                  index={index}
                                >
                                  {(provider) => (
                                    <tr
                                      key={index}
                                      id="tr-sort"
                                      className="d-flex flex-row align-items-center justify-content-between  gap-3 p-2"
                                      {...provider.draggableProps}
                                      ref={provider.innerRef}
                                    >

                                      <td>
                                        <div className="d-flex flex-row align-items-center gap-3">
                                          <span>
                                            <img
                                              src={user.preview}
                                              width={52}
                                              height={52}
                                              alt="Logo Not Found"
                                            />
                                          </span>
                                          <span>
                                            <div className="font-16 font-bolder">
                                              {user.path}
                                            </div>
                                            {/* <div className="text-muted">
                                      {user.size}
                                    </div> */}
                                          </span>
                                        </div>

                                      </td>
                                      <td><div className="d-flex flex-row justify-content-between align-items-center gap-3">
                                        <div onClick={() => removeProductImg(index)}
                                          data-bs-toggle="tooltip" data-bs-placement="bottom" title="Remove image">
                                          <DeleteIconWhite className="cursor" />
                                        </div>
                                        <div {...provider.dragHandleProps}>
                                          <span className="cursor" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Sort image(move up/down)">
                                            <img
                                              src={DragHandle}
                                              width={40}
                                              height={40}
                                              alt="Logo Not Found"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provider.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>
                  </div>
                </div>}
            </div>
          </div> : null}

        <div className="d-flex flex-row justify-content-between gap-2">
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65 mb-2">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <OrangeTag />
                <span className="font-20 font-bold">Product Variants</span>
              </div>
            </div>

            <div className="d-flex flex-column gap-4 mt-4">

              <div className="card-body d-flex flex-column justify-content-between align-item-center gap-3 ">
                {/* Product color */}
                {merchandiseVarientObjState && merchandiseVarientObjState[0].requireImages ?
                  <div className="d-flex flex-column justify-content-between align-item-center gap-3 ">
                    <span className="font-15 font-boldest text-dark ">Product Images</span>
                    <table responsive="xl">
                      <tbody>
                        {merchandiseVarientImg && merchandiseVarientImg.map((item, index) => (
                          <tr className="card-body gap-3" key={index}>
                            <td colSpan={4}>
                              <div className="d-flex flex-row gap-4 align-items-center py-2">
                                <span className="mx-3 rounded-3">
                                  {merchandiseVarientObjState && !merchandiseVarientObjState[0].requireImages ?
                                    <img
                                      src={productImg[0]?.preview ? productImg[0]?.preview : applePhones}
                                      alt="not found"
                                      className="img-fluid"
                                      width={'45px'}
                                    /> :
                                    <img
                                      src={item?.images[0]?.preview ? item?.images[0]?.preview : applePhones}
                                      alt="not found"
                                      className="img-fluid"
                                      width={'45px'}
                                    />}
                                </span>
                                <div className="d-flex flex-column justify-content-start ">
                                  <span className="font-15 font-boldest text-dark ">
                                    {merchandiseVarientObjState && merchandiseVarientObjState[0]?.title}
                                  </span>
                                  <span className="font-13 font-bold text-muted">
                                    {item.color}
                                  </span>
                                </div>

                              </div>
                              <tr>

                              </tr>
                            </td>

                            {merchandiseVarientObjState && merchandiseVarientObjState[0]?.requireImages &&
                              <td onClick={() => editImages(item, index, item.color)}>
                                <div className="d-flex justify-content-end justify-content-end  ">
                                  <div className="d-flex flex-column justify-content-end ">
                                    <span className="font-15 font-bold text-light-blue cursor ">
                                      {item.images.length > 0 ? "Edit images" : "Upload images"}
                                    </span>
                                    {!item.images.length > 0 ? (
                                      <label className="text-error">{error.productImg}</label>
                                    ) : null}
                                  </div>
                                </div>

                              </td>}


                            {/* {item.imageFlag && item.images.length > 0 && <td onClick={() => editImages(item, index)}>
                        <div className="d-flex justify-content-end px-2">
                          <span className="font-15 font-bold text-light-blue  cursor">
                            Edit images
                          </span>
                        </div>
                      </td>} */}

                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div> : null}

                {/* Product Varient  */}
                <span className="font-15 font-boldest text-dark ">Product Variants</span>

                <table responsive="xl">
                  <tbody>
                    {merchandiseVarientState && merchandiseVarientState.map((item, index) => (
                      <tr className="card-body gap-3" key={index}>
                        <td colSpan={4}>
                          <div className="d-flex flex-row gap-4 align-items-center py-2">
                            <span className="mx-3 rounded-3">
                              <img
                                src={productImg[0]?.preview ? productImg[0]?.preview : applePhones}
                                alt="not found"
                                className="img-fluid"
                                width={'45px'}
                              />
                            </span>
                            <div className="d-flex flex-column justify-content-start ">
                              <span className="font-15 font-boldest text-dark ">
                                {merchandiseVarientObjState[0]?.title}
                              </span>
                              <span className="font-13 font-bold text-muted">
                                {Object.keys(item).map(function (k) { return item[k] }).join("|")}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>

        <div className="d-flex flex-column bg-white shadow-sm  rounded-3 py-3 px-4  gap-4 width-65">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <GreenTag />
              <span className="font-20 font-bold">Terms & Condition</span>
            </div>
          </div>

          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                {/*  <span className="font-bold font-14">Message to reviewer</span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />*/}
              </div>
              {editorState.map((item, i) => (
                <div className="d-flex flex-column gap-3" key={i}>
                  <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        Terms & Condition #{i + 1} {item.lang.language}
                        <span className="text-danger">{i === 0 ? "*" : ""}</span>
                      </span>
                      {/* <InfoTooltip title={"Maximum 100 characters. No HTML or emoji allowed"} /> */}
                    </div>
                    {editorState.length !== 1 && (
                      <div
                        className="cursor"
                        onClick={() => handleRemoveClick(i)}
                      >
                        {i !== 0 && <CloseIcone />}
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <TextEditor
                      code={item.lang.language_code}
                      editorState={item.value}
                      getDescription={getDescription}
                      lang={props?.lang}
                    />
                    {error.editorState && i === 0 ? (
                      <label className="text-error">{error.editorState}</label>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="d-flex">
            <div className="btn-group w-100">
              <div
                className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                data-bs-toggle="dropdown"
              >
                <span>Add other language Terms & Condition</span>
              </div>
              <ul className={`dropdown-menu rounded-card-7 dropdown-menu-start ${props?.lang === 'ar' ? 'dropdown-menu-ar' : ''}`}>
                {Preferredlanguage &&
                  Preferredlanguage.map((item, i) => (
                    <li key={i} onClick={() => handleDropdownOnClick(item)}>
                      <div className="dropdown-item cursor">{item.language}</div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="d-flex">
            {/* <Button onClick={() => submit()} text="Save & Preview" type="primary" /> */}
          </div>
        </div>
        {/* <div className=" d-flex width-65 gap-2 ">
        <ProductImages products={products} applePhones={applePhones} />
      </div> */}


        <div className="d-flex my-4">
          <Button
            onClick={() => saveAndPreview()}
            text="Preview"
            type="primary"
          />
        </div>
        <ModalComp
          size="lg"
          showModal={showModal}
          //handleClose={handleClose}
          component={<MerchandiseModelPreview handleClose={handleClose} />}
        />
        <div className="position-fixed bottom-0 start-50 translate-middle-x w-100 " id="liveToast">
          <Toasts
            position={"bottom-center"}
            showToast={showToast}
            toggleShowToast={toggleShowToast}
            warningMsg="Allready Added."
            imageType={"warning"}
          />
        </div>
      </div>

  );
};

export default MerchandiseMapping;
