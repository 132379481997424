import {
  GET_MERCHANDISE_PRODLIST_FAILURE,
  GET_MERCHANDISE_PRODLIST_REQUEST,
  GET_MERCHANDISE_PRODLIST_SUCCESS,
  GET_MERCHANDISE_VARIANT_PRICE_REQUEST,
  GET_MERCHANDISE_VARIANT_PRICE_SUCCESS,
  GET_MERCHANDISE_VARIANT_PRICE_FAILURE,
  CLEAR_MERCHANDISE_LIST,
} from "./MerchadiseAllProductType";

const intialMerchandiseCategoryAttribute = {
  loading: false,
  error: "",
  merchandiseList: [],
  merchandiseVariantPrice: null,
  next_iteration: true
};

const merchadiseAllProductReducer = (
  state = intialMerchandiseCategoryAttribute,
  action
) => {
  switch (action.type) {
    case CLEAR_MERCHANDISE_LIST:
      return {
        ...state,
        loading: false,
        merchandiseList: [],
        next_iteration: true,
        errorMsg: "",
      };
    case GET_MERCHANDISE_PRODLIST_REQUEST:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case GET_MERCHANDISE_PRODLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        merchandiseList: action.payload.data,
        next_iteration: action.payload.moredata,
        errorMsg: "",
      };
    case GET_MERCHANDISE_PRODLIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
      };
    case GET_MERCHANDISE_VARIANT_PRICE_REQUEST:
      return {
        loading: true,
      };
    case GET_MERCHANDISE_VARIANT_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        merchandiseVariantPrice: action.payload,
        errorMsg: "",
      };
    case GET_MERCHANDISE_VARIANT_PRICE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
      };

    default:
      return state;
  }
};
export default merchadiseAllProductReducer;
