import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import Button from "../../components/Button/Button";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import DownloadSampleExcel from "../../components/Inventory/DownloadSampleExcel";
import Toasts from "../../components/Toasts/Toasts";
import DragandDrop from "../../components/DragandDrop/DragandDrop";
import { useDispatch, useSelector } from "react-redux";
import {
  clearManualInventorySuccessErrors,
  getSampleManualInventory,
  postManualInventory,
} from "../../Redux/ManualInventory/manualInventoryAction";
import DragandDropDocs from "../../components/DragandDrop/DragandDropDocs";
import DragandDropExcel from "../../components/DragandDrop/DragandDropExcel";
import { t } from "i18next";
import ErrorHandlerCard from "../../components/BulkUpload/ErrorHandlerCard";
import ModalComp from "../../components/Models/ModalComp";

const AddManualInventory = () => {
  const dispatch = useDispatch();
  const [fileUpload, setFileUpload] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const ManualInventoryErrors = useSelector(
    (state) => state?.manualInventory?.manualInventoryErrors
  );
  const [manualInventoryPostError, setManualInventoryPostError] = useState([]);

  const handleClose = () => {
    setShowModal(false);
    setManualInventoryPostError([]);
    dispatch(clearManualInventorySuccessErrors());
  };

  // Modifying and storing ERRORS in array of object in state
  useEffect(() => {
    let errObj = ManualInventoryErrors?.errors;
    let errArray = [];
    let errorsKeyValueArrya = [];

    if (ManualInventoryErrors.code === 422) {
      for (let errors in errObj) {
        errorsKeyValueArrya.push({ errors: errObj[errors] });
      }
    } else {
      ManualInventoryErrors?.errors !== undefined &&
        errorsKeyValueArrya.push({ errors: errObj });
    }

    setManualInventoryPostError(errorsKeyValueArrya);
  }, [ManualInventoryErrors]);


    // Showing ERROR Model
    useEffect(() => {
      if (manualInventoryPostError.length > 0) {
        setShowModal(true);
      }
    }, [manualInventoryPostError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useNavigate();

  const goToSuccessfull = () => {
    history("/manual-inventory-success");
  };

  const submitHandler = () => {
    if (fileUpload.length > 0) {
      dispatch(postManualInventory(fileUpload, goToSuccessfull));
    }
    
    if(fileUpload.length === 0){
      toggleShowToast()
    }
  };

  return (
    <div className="d-flex flex-column py-4 gap-4 position-relative px-4 bg-light">
      <div className="d-flex flex-row align-items-center gap-3">
        <LeftIcon className="cursor" onClick={() => history(-1)} />
        <span className="font-bold font-32">{t("Add Manual Inventory")}</span>
      </div>

      <div className="d-flex flex-row justify-content-between gap-2">
        <div className="d-flex width-65">
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <Tag />
                <span className="font-20 font-bold">{t("Upload Excel")}</span>
              </div>
            </div>

            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex flex-column gap-3 mt-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Upload excel")}
                    </span>
                    <InfoTooltip title={"Upload manual inventory excel file"} />
                  </div>
                  <div className="d-flex flex-column">
                    <DragandDropExcel
                      files={fileUpload}
                      setFiles={setFileUpload}
                    />
                    {/* {!fileUpload.length > 0 && error.fileUpload ? (
                                <label className="text-error">{error.fileUpload}</label>
                            ) : null} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex mt-4 mb-2">
              <Button
                onClick={() => submitHandler()}
                text={t("Upload & Continue")}
                type="primary"
              />
            </div>
          </div>
        </div>
        <div className="d-flex width-35 h-100">
          <DownloadSampleExcel
            getSampleFileApiFunction={getSampleManualInventory}
            description={
              "Make sure you follow the same format as shown in the below excel for manual inventory"
            }
          />
        </div>
      </div>

      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg={
            fileUpload.length === 0
              ? "Please upload a file"
              : ManualInventoryErrors?.errors
              ? manualInventoryPostError.map((item) => item)
              : `${"Inventory uploaded successfully."}`
          }
          imageType={
            ManualInventoryErrors?.errors
              ? "warning"
              : fileUpload.length === 0
              ? "warning"
              : "success"
          }
        />

        {manualInventoryPostError.length > 0 && (
          <ModalComp
            size="lg"
            showModal={showModal}
            // handleClose={handleClose}
            component={
              <ErrorHandlerCard
                handleClose={handleClose}
                errors={
                  manualInventoryPostError &&
                  manualInventoryPostError
                }
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default AddManualInventory;
