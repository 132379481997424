/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Categories from "../../../components/Category/Categories";
import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";

const CategoryDashboard = (props) => {
  const history = useNavigate();
  const { t } = useTranslation();
  const categoriesState = useSelector(
    (state) => state.brandCategories.brandCategories
  );

  const [permissions, setpermissions] = useState();

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {

  }, [permissions]);

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  const creatNewBrandCategory = () => {
    history("/category-creation");
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">{t("Brand Category Dashboard")}</span>
        </div>
        {permissions?.brand_category?.create ? (
          <div>
            <Button
              type="primary"
              text={t("+Create Brand Category")}
              onClick={() => creatNewBrandCategory()}
            />
          </div>
        ) : null}
      </div>
      <div className="d-flex ">
        <ErrorBoundary>
          <Categories lang={props?.lang} categoriesState={categoriesState} />
        </ErrorBoundary>
      </div>
    </div>

  );
};

export default CategoryDashboard;
