import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";
import { DELETE_GIFTCARD_DETAIL } from "../Distributions/ClientGiftcardDistributions/ClientGiftcardDistributionType";
import { getOrdersProductByTypes, storeOrderFilterType } from "../Order/orderAction";
import {
  PUT_CREATEORDER_REQUEST,
  PUT_CREATEORDER_SUCCESS,
  PUT_CREATEORDER_FAILURE,
  SET_CREATEORDER_DATA,
  SET_DISTRIBUTION_OF_MERCHANDISE,
  PUT_DISTRIBUTION_MERCHANDISE_REQUEST,
  PUT_DISTRIBUTION_MERCHANDISE_SUCCESS,
  PUT_DISTRIBUTION_MERCHANDISE_FAILURE,
  FETCH_CLIENT_MERCHANDISE_DATA_REQUEST,
  FETCH_CLIENT_MERCHANDISE_DATA_SUCCESS,
  FETCH_CLIENT_MERCHANDISE_DATA_FAILURE,
  REMOVE_CREATE_ORDER_DATA,
} from "./CreateOrderType";



export const removeCreateOrderData = () => {
  return {
    type: REMOVE_CREATE_ORDER_DATA
  };
};

// Set Order data for giftcard into redux
export const SetCreateOrderData = (data) => {
  return {
    type: SET_CREATEORDER_DATA,
    payload: data,
  };
};

export const DeleteGiftcardDetails = () => {
  return {
    type: DELETE_GIFTCARD_DETAIL,
    payload: [],
  };
};

// Set Order data for merchandise into redux
export const SetDistributionOfMerchandiseData = (data) => {
  return {
    type: SET_DISTRIBUTION_OF_MERCHANDISE,
    payload: data,
  };
};

// put Order
export const putCreateOrderRequest = () => {
  return {
    type: PUT_CREATEORDER_REQUEST,
  };
};

export const putCreateOrderSuccess = (obj) => {
  return {
    type: PUT_CREATEORDER_SUCCESS,
    payload: obj,
  };
};

export const putCreateOrderFailure = (obj) => {
  return {
    type: PUT_CREATEORDER_FAILURE,
    payload: obj,
  };
};

export const fetchClientMerchandiseRequest = () => {
  return {
    type: FETCH_CLIENT_MERCHANDISE_DATA_REQUEST,
  };
};

export const fetchClientMerchandiseSuccess = (data) => {
  return {
    type: FETCH_CLIENT_MERCHANDISE_DATA_SUCCESS,
    payload: data,
  };
};

export const fetchClientMerchandiseFailure = (error) => {
  return {
    type: FETCH_CLIENT_MERCHANDISE_DATA_FAILURE,
    payload: error,
  };
};

// Getting Client Merchandise data on Mer Id 
export const fetchClientMerchandiseData = (id, getMerchandiseProductByIdData) => {
  return (dispatch) => {
    dispatch(fetchClientMerchandiseRequest())
    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/variants/list?merchandise_ids=${id}&supplier_info=false&brand_info=true`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config).then((response) => {
      if (response.status === 200) {
        dispatch(fetchClientMerchandiseSuccess(response.data.result))
        getMerchandiseProductByIdData(response.data.result)
      }
    }).catch((error) => {
      dispatch(fetchClientMerchandiseFailure("Something went wrong!! Please try again!"))
    })

  };
};

export const putCreateOrderForGiftcard = (obj, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(putCreateOrderRequest);
    // console.log("Redux ", obj)
    let delivaryOption = obj.deliveryOption === "Separate email for each giftcard" ?
      "separate_email_for_each_giftcard" : obj.deliveryOption === "One email with giftcard PDF attachment" ?
        "one_email_with_all_giftcard_pdf_attachment" : obj.deliveryOption === "One email with excel attachment of giftcard URLs" ?
          "one_email_with_excel_attachment_of_giftcard_URLs" : obj.deliveryOption === "One email with giftcard URLs" ?
            "one_email_with_giftcard_URLs" : null
    let deliveryType = obj?.deliveryType;
    let createOrderObj = {
      product_value: obj.product_value ? Number(obj?.product_value) : "",
      order_by: obj.store ? obj.store : "",
      buyer_id: obj.selectedOrganization
        ? obj?.selectedOrganization?.id
        : obj?.selectedClient?.id,
      // order_status: obj?.order_status,
      product_type: "giftcard",
      product_id: obj?.product.id,
      product_variant_id: obj?.denomination?.id ? obj?.denomination?.id : "",
      inventory_type_id: obj?.product?.registered_by_id,
      quantity: obj?.quantity,
      delivery_type: deliveryType.toLowerCase(),
      delivery_address_line_1: obj?.deliveryAddressline1
        ? obj?.deliveryAddressline1
        : "",
      delivery_address_line_2: obj?.deliveryAddressline2
        ? obj?.deliveryAddressline2
        : "",
      email_id: obj?.emailid,
      phone_number: obj?.phoneNumber,
      receiver: {
        receiver_type: "store",
        receiver_id: obj?.selectedStore ? obj?.selectedStore?.id : ""
      },
      order_price: obj?.orderAmount ? obj?.orderAmount : 0,
      discount: obj?.discount ? obj?.discount : 0,
      total_price: obj?.total_price ? obj?.total_price : 0,
      digital_delivery_option: delivaryOption

    };
    // console.log("createOrderObj in action", createOrderObj);     
    // console.log("createOrderObj in action", JSON.stringify(createOrderObj));                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           

    let config = {
      method: "post",
      url: `${baseUrl}/v1/product_transaction`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: createOrderObj,
    };
    axios(config)
      .then((response) => {

        if (response.status === 200) {
          // console.log("response ", response);
          dispatch(putCreateOrderSuccess(response.data.result));
          dispatch(DeleteGiftcardDetails())
          goToSuccessfull();
          dispatch(storeOrderFilterType())
          dispatch(getOrdersProductByTypes());
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          putCreateOrderFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};
// POST DISTRIBUTION OF MERCHANDISE PRODUCT
// put Order
export const putDistributionOfMerchandiseRequest = () => {
  return {
    type: PUT_DISTRIBUTION_MERCHANDISE_REQUEST,
  };
};

export const putDistributionOfMerchandiseSuccess = (obj) => {
  return {
    type: PUT_DISTRIBUTION_MERCHANDISE_SUCCESS,
    payload: obj,
  };
};

export const putDistributionOfMerchandiseFailure = (obj) => {
  return {
    type: PUT_DISTRIBUTION_MERCHANDISE_FAILURE,
    payload: obj,
  };
};

export const postClientMerchandise = (obj, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(putDistributionOfMerchandiseRequest);

    let deliveryType = obj?.deliveryType;
    let createOrderObj = {
      order_by: obj.orderBy,
      buyer_id: obj.buyerId,
      // order_status: obj?.order_status,
      product_type: obj.productType,
      product_id: obj?.productId,
      product_variant_id: obj.productVariantId,
      inventory_type_id: obj.inventorTypeId,
      quantity: obj?.quantity,
      order_price: obj.orderPrice,
      discount: obj.discount,
      total_price: obj.totalPrice,
      delivery_type: obj.deliveryType.toLowerCase(),
      delivery_address_line_1: obj?.deliveryAddressline1
        ? obj?.deliveryAddressline1
        : "",
      delivery_address_line_2: obj?.deliveryAddressline2
        ? obj?.deliveryAddressline2
        : "",
      email_id: obj?.emailid,
      phone_number: obj?.phoneNumber,
    };

    console.log("createOrderObj ", JSON.stringify(createOrderObj));
    let config = {
      method: "post",
      url: `${baseUrl}/v1/product_transaction`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: createOrderObj,
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          dispatch(putDistributionOfMerchandiseSuccess(response.data.result));
          goToSuccessfull();
        }
      })
      .catch((error) => {
        //  const errorMsg = error
        dispatch(
          putDistributionOfMerchandiseFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};
