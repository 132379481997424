import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import WarningImg from "../../assests/img/warning.png";

const ErrorModel = (props) => {
    // const history = useNavigate();
    // const dispatch = useDispatch();

    const tryAgainHandler = () => {
        window.location.reload(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


  return (

        <div className="d-flex flex-column bg-white px-5 w-100 my-5 p-5">
            <div className="d-flex flex-column justify-content-start align-items-center gap-4">
                <div>
                    <img
                        src={WarningImg}
                        alt=""
                    />
                </div>
                <span className="font-bold text-dark font-17">
                    Somthing went wrong. Please try again.
                </span>
                <div className="d-flex flex-column gap-4">
                    <Button
                        text="Try again"
                        type={"primary"}
                        onClick={() => tryAgainHandler()}
                    />
                    {/* <Button
                        text="Close"
                        type={"secondary"}
                        onClick={props.handleClose}
                    /> */}
                </div>

            </div>
        </div >
    );
};

export default ErrorModel;
 