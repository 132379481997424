/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { useSelector } from "react-redux";
import giftcard_default from "../../assests/img/giftcard-default.png";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import ProductImageSlider from "../ImageSlider/ProductImageSlider";
// import VarientProductImageSlider from "../ImageSlider/VarientProductImageSlider";
import PreviewCardVarientSlider from "../ImageSlider/PreviewCardVarientSlider";
import PrevCardProductSlider from "../ImageSlider/PrevCardProductSlider";
// import SliderLeftAndRightButtons from "../ImageSlider/SliderLeftAndRightButtons";
// import PrevSliderLeftAndRightButtons from "../ImageSlider/PrevSliderLeftAndRightButtons";

const CardPreview = () => {
  const { t } = useTranslation();

  // const giftcard = useSelector((state) => state.giftcard);
  const merchandiseByIdForEdit = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseByIdForEdit
  );
  const merchandiseImage = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseImage
  );
  // for preview
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );
  const [tabs, setTabs] = useState(0);
  const [selectedVarienImageArray, setSelectedVarienImageArray] = useState([]);

  useEffect(() => { }, [selectedVarienImageArray, merchandiseImage]);

  // default varient image array set
  useEffect(() => {
    merchandiseSummery &&
      merchandiseSummery[0] &&
      merchandiseSummery[0]?.variants?.length > 0 &&
      merchandiseSummery[0]?.variants !== undefined &&
      merchandiseSummery[0]?.variants[0]?.images &&
      merchandiseSummery[0]?.variants[0]?.images &&
      setSelectedVarienImageArray(merchandiseSummery[0]?.variants[0]?.images);
  }, [merchandiseSummery]);

  // on varient button click that varient image array set
  const varientHandler = (varientName, index) => {
    setTabs(index);
    merchandiseSummery &&
      merchandiseSummery[0]?.product_variants_flag === true &&
      merchandiseSummery[0]?.variants &&
      merchandiseSummery[0]?.variants.map((item) => {
        if (varientName.join() === item?.variant_values.join()) {
          setSelectedVarienImageArray(item?.images);
        }
      });
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">{t("Preview")}</span>
        </div>
        {/* <div>
          {giftcard?.cardPrice ?
            <Expand className="cursor" onClick={handleShow} /> : null}
        </div> */}
      </div>
      {merchandiseSummery && merchandiseSummery[0]?.product_variants_flag && (
        // <div className="d-flex">
        //   <div className="col">
        //     {/* {merchandiseSummery[0]?.product_variants_flag === true && merchandiseSummery[0]?.variants && merchandiseSummery[0]?.variants.map((item, index) => ( */}
        //     <div className="d-flex  flex-column w-100 my-2">
        //       <div>
        //         <div className="font-20 font-bold neutral-grey-07">
        //           {merchandiseSummery &&
        //             merchandiseSummery[0]?.variants &&
        //             merchandiseSummery[0]?.variants[0]?.variant_keys.join("|")}
        //         </div>
        //         <div className="d-flex flex-wrap flex-row my-2 gap-2 mx-2 cursor">
        //           {merchandiseSummery[0]?.product_variants_flag === true &&
        //             merchandiseSummery[0]?.variants &&
        //             merchandiseSummery[0]?.variants.map((item, index) => (
        //               <div className="d-flex gap-3">
        //                 <span
        //                   className={`p-2 border rounded ${tabs !== index
        //                     ? "text-muted"
        //                     : "bg-primary text-white"
        //                     }`}
        //                   name={item?.variant_values}
        //                   onClick={() => {
        //                     varientHandler(item?.variant_values, index);
        //                   }}
        //                 >
        //                   {item?.variant_values.join("|")}
        //                 </span>
        //               </div>
        //             ))}
        //         </div>
        //       </div>
        //     </div>
        //     {/* ))} */}
        //   </div>
        // </div>
        <div></div>
      )}
      {/* <div className="">
        {!merchandiseImage ?
          <img
            src={giftcard_default}
            alt="not found"
            className="img-fluid"
          /> :
          <img
            src={merchandiseImage && merchandiseImage[0]?.file_url}
            key={merchandiseImage && merchandiseImage[0]?.file_url}
            alt="not found"
            className="img-fluid"
          />}
      </div> */}
      {merchandiseSummery &&
        merchandiseSummery[0]?.product_variants_flag === true ? (
        <>
          {merchandiseSummery[0]?.variants && merchandiseSummery[0]?.variants[0]?.images && merchandiseSummery[0]?.variants[0]?.images.length > 1 ? (
            <>
              <PreviewCardVarientSlider
                varientProductActiveImage={
                  merchandiseSummery[0] &&
                  merchandiseSummery[0]?.variants?.length > 0 &&
                  merchandiseSummery[0]?.variants !== undefined &&
                  merchandiseSummery[0]?.variants[0]?.images &&
                  merchandiseSummery[0]?.variants[0].images[0]?.file_url &&
                  merchandiseSummery[0]?.variants[0].images[0]?.file_url
                }
                selectedVarienImageArray={
                  selectedVarienImageArray && selectedVarienImageArray
                }
                defualtProduct={giftcard_default}
              />
              {/* <PrevSliderLeftAndRightButtons /> */}
            </>
          ) : (
            <div className={"carousel-item active"}>
              <img
                src={
                  merchandiseSummery[0] &&
                    merchandiseSummery[0]?.variants?.length > 0 &&
                    merchandiseSummery[0]?.variants !== undefined &&
                    merchandiseSummery[0]?.variants[0]?.images &&
                    merchandiseSummery[0]?.variants[0].images[0]?.file_url
                    ? merchandiseSummery[0]?.variants[0].images[0]?.file_url
                    : giftcard_default
                }
                //   key={product_default}
                alt="not found"
                id="product-preview"
              />
            </div>
          )}
        </>
      ) : (
        <>
          <PrevCardProductSlider
            productActiveImage={
              merchandiseSummery &&
              merchandiseSummery[0]?.images &&
              merchandiseSummery[0]?.images[0]?.file_url &&
              merchandiseSummery[0]?.images[0]?.file_url
            }
            productImagesArray={
              merchandiseSummery &&
              merchandiseSummery[0]?.images &&
              merchandiseSummery[0]?.images
            }
            defualtProduct={giftcard_default}
          />
          {/* <PrevSliderLeftAndRightButtons /> */}
        </>
      )}

      <div className="d-flex flex-row justify-content-between align-items-center gap-3">
        <span className="font-20 font-bold">
          {merchandiseByIdForEdit?.default_title}
        </span>
      </div>
      <div>
        <span className="font-bolder text-muted">{t("by")} </span>{" "}
        <span className="font-bold">{t("Hadaya")}</span>
      </div>

    </div>
  );
};

export default CardPreview;
