// TO GET ALL TRANSACTION ORDER DETAILS FROM PRODUCTTRANSACTION GET API
export const GET_ALL_ORDERS_REQUEST = "GET_ALL_ORDERS_REQUEST";
export const GET_ALL_ORDERS_SUCCESS = "GET_ALL_ORDERS_SUCCESS";
export const GET_ALL_ORDERS_FAILURE = "GET_ALL_ORDERS_FAILURE";

// TO GET ALL CLIENT  ORDERS TRANSACTIONS
export const GET_ALL_CLIENT_ORDERSLIST_REQUEST = "GET_ALL_CLIENT_ORDERSLIST_REQUEST";
export const GET_ALL_CLIENT_ORDERSLIST_SUCCESS = "GET_ALL_CLIENT_ORDERSLIST_SUCCESS";
export const GET_ALL_CLIENT_ORDERSLIST_FAILURE = "GET_ALL_CLIENT_ORDERSLIST_FAILURE";

// TO GET ORDER DETAILS
export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAILURE = "GET_ORDER_DETAILS_FAILURE";

export const ORDER_FILTER_TYPES_STORE = "ORDER_FILTER_TYPES_STORE"

// To update order status 
export const UPDATE_ORDER_STATUS_REQUEST = "UPDATE_ORDER_STATUS_REQUEST"
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS"
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE"

// TO GET ORDER STATUS HISTORY FOR TIMELINE 
export const HISTORY_ORDER_STATUS_REQUEST = "HISTORY_ORDER_STATUS_REQUEST"
export const HISTORY_ORDER_STATUS_SUCCESS = "HISTORY_ORDER_STATUS_SUCCESS"
export const HISTORY_ORDER_STATUS_FAILURE = "HISTORY_ORDER_STATUS_FAILURE"

export const CLEAR_ORDER_LIST = "CLEAR_ORDER_LIST"
