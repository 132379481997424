import React, {useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import { ReactComponent as FilterIcon } from "../../assests/svg/Filter_Icon.svg";
import FilomenaFahey from "../../assests/img/FilomenaFahey.png";
import Button from "../../components/Button/Button";


const UserRole = () => {
  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userRole = () => {
    history("/user-profile");
  };

  const customerDetails=[
    {id:0,userEmail:' chelsie@hadaya.net',CreatedOn:"Finance Manager",Total:'6'},
    {id:1,userEmail:' fahey.designer@hadaya.net',CreatedOn:"User Manager",Total:'5'},
    {id:2,userEmail:' orrion88@yahoo.com',CreatedOn:"Product Manager",Total:'4'},
    {id:3,userEmail:' brown.be@gmail.com',CreatedOn:"Repletech Manager",Total:'3'},
    {id:4,userEmail:' bess@hadaya.net',CreatedOn:"Hadaya Manager",Total:'2'},
    {id:5,userEmail:' elly1509@gmail.com',CreatedOn:"Super Admin",Total:'1'}
  ]



  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="d-flex flex-row w-100  align-items-center">
          <div className="p-2 flex-grow-1">
            <div className="d-flex gap-3 my-2 mx-3 align-items-center">
              <Tag />
              <span className="font-20 font-bold">Admin roles</span>
              <span className="font-20 font-bold">
                <div className="Inputwithicon">
                  <div id="search">
                    <Searchicon onClick={() => alert("Searching Users Role")} />
                  </div>
                  <input
                    type="text"
                    className="input-custom w-100"
                    name="search"
                    placeholder="Search by name,email or client"
                  />
                </div>
              </span>
            </div>
          </div>
          <div className="p-2">
            <Button type="secondary" text="All" />
          </div>
          <div className="p-2">
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle font-14 font-bold text-muted"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Client
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <Link className="dropdown-item" to={""}>
                  Client 1
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={""}>
                  Client 2
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={""}>
                  Client 3
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="p-2">
            <span className="my-2">
              <FilterIcon onClick={() => alert("Filter Icon")} />
            </span>
          </div>
        </div>
        <div className="row px-5 py-2">
          <table responsive="xl">
            <tbody>
              <tr className="card-body gap-3 border-bottom py-5">
                <td className="card-body gap-1 font-13 font-bold text-muted">
                Name
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                Email
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                Created on
                </td>
                
                <td className="card-body font-13 font-bold text-muted">
                User ID
                </td>
              </tr>
            {customerDetails.map((item)=>(
              <tr className="card-body gap-3 border-bottom hover-row" key={item.id} onClick={userRole}>
                <td className="card-body gap-1">
                  <div className="d-flex flex-row justify-content-start  gap-3">
                    {/* <Image/> */}
                    <span>
                    <img
              src={FilomenaFahey}
              alt="not found"
              className="img-fluid width-75"
            />
                    </span>
                    <span className="font-15 font-boldest text-dark ">
                     {/* {item.customerNameText} */}
                     <span className="font-15 font-boldest text-dark ">Chelsie Haley</span>
                     <span className="font-12 font-bolder text-muted">@username</span>
                    </span>
                  </div>
                </td>
                <td className="card-body gap-1">
                  <div className="d-flex flex-row justify-content-between  gap-3">
                    <span className="fs-light  text-muted ">
                      {item.userEmail}
                    </span>
                  </div>
                </td>
                <td className="card-body gap-1">
                  <span className="font-15 font-bold text-dark px-1">{item.CreatedOn}</span>
                </td>
                <td className="card-body gap-1">
                  <span className="font-15 font-bold text-dark px-1">{item.Total}</span>
                </td>
              </tr>
              )) }
            </tbody>
          </table>
        </div>
        
      </div>
     
    </div>
  );
};

export default UserRole;
