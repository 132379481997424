export const GET_SAMPLE_MANAUL_INVENTORY_REQUEST = "GET_SAMPLE_MANAUL_INVENTORY_REQUEST";
export const GET_SAMPLE_MANAUL_INVENTORY_SUCCESS = "GET_SAMPLE_MANAUL_INVENTORY_SUCCESS";
export const GET_SAMPLE_MANAUL_INVENTORY_FAILURE = "GET_SAMPLE_MANAUL_INVENTORY_FAILURE";

export const GET_MANAUL_INVENTORY_REQUEST = "GET_MANAUL_INVENTORY_REQUEST";
export const GET_MANAUL_INVENTORY_SUCCESS = "GET_MANAUL_INVENTORY_SUCCESS";
export const GET_MANAUL_INVENTORY_FAILURE = "GET_MANAUL_INVENTORY_FAILURE";

export const POST_MANAUL_INVENTORY_REQUEST = "POST_MANAUL_INVENTORY_REQUEST";
export const POST_MANAUL_INVENTORY_SUCCESS = "POST_MANAUL_INVENTORY_SUCCESS";
export const POST_MANAUL_INVENTORY_FAILURE = "POST_MANAUL_INVENTORY_FAILURE";

// Post error handler this type
export const POST_MANAUL_INVENTORY_SUCCESS_ERROR = "POST_MANAUL_INVENTORY_SUCCESS_ERROR"
export const CLEAR_MANAUL_INVENTORY_SUCCESS_ERROR = "CLEAR_MANAUL_INVENTORY_SUCCESS_ERROR"