import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../assests/svg/Success.svg";
import Button from "../../components/Button/Button";
import { useTranslation } from "react-i18next";

const PatchLanguageSuccess = () => {
    const history = useNavigate();
    const { t } = useTranslation();
    const languageResponse = useSelector((state) => state?.languages?.languageResponse);

    useEffect(() => {
      window.scrollTo(0, 0);
      if (languageResponse === null) {
        history("/language");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const goToDashboard = () => {
      history("/language")
    }
  
    return (
      <div className="d-flex flex-column  align-item-center bg-light w-100 py-5">
        <div className="d-flex flex-row w-100 justify-content-center">
          <SuccessIcon />
        </div>
        <div className="d-flex flex-row w-100 justify-content-center mt-2">
          <span className="text-dark font-bold font-40">
            {t("Language updated successfully")}
          </span>
        </div>
        {/* <div className="d-flex flex-row w-100 justify-content-center mt-2">
          <span className="text-muted font-bold font-15">
          Language ID: {languageResponse.id}
          </span>
        </div>     */}
        <div className="d-flex flex-row w-100 justify-content-center mt-2">
          <Button type={"primary"} text={t("View Dashboard")} onClick={goToDashboard} />
        </div>
      </div>
    );
};

export default PatchLanguageSuccess;