import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as Tag } from "../../../assests/svg/Tag/tag.svg";
import Button from "../../../components/Button/Button";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllLanguage,
  postLanguage,
} from "../../../Redux/Language/languageAction";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import InputField from "../../InputField/InputField";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import { patchPartnerCategory } from "../../../Redux/Partner/partnerAction";
import ReactSwitchButton from "../../Button/ReactSwitchButton";
import { useTranslation } from "react-i18next";

const AddPartnerCategory = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const inputElementPartCatName = useRef(null);

  const [partnerCategoryState, setPartnerCategoryState] = useState({
    categoryName: "",
  });

  const [activeFlag, setActiveFlag] = useState(true);

  const [error, setError] = useState({
    categoryName: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPartnerCategoryState({
      ...partnerCategoryState,
      categoryName: location?.state?.categoryItem?.partner_category_name,
    });
  }, [location?.state?.categoryItem]);

  useEffect(() => {
    location?.state?.categoryItem?.active_flag && setActiveFlag(location?.state?.categoryItem?.active_flag)
  },[location?.state?.categoryItem])

  const categoryHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.name === "categoryName") {
      setError({ ...error, categoryName: "" });
      setPartnerCategoryState({ ...partnerCategoryState, [name]: value });
    }
  };

  const activeHandle = () => {
    setActiveFlag(!activeFlag);
  };

  const validation = () => {
    if (!partnerCategoryState?.categoryName) {
      setError({ ...error, categoryName: t("Please add partner category name") });
      if(inputElementPartCatName.current){
        inputElementPartCatName.current.focus()
      }
      return false;
    }
    return true;
  };

  const partnerSuccessfull = () => {
    history("/partnercategory-patch-success");
  };

  const submit = () => {
    let val = validation();
    if (val) {
      let obj = {
        "active_flag": true,
        "partner_category": location?.state?.categoryItem?.partner_category,
        "partner_sub_category": "string",
        "partner_category_name": partnerCategoryState?.categoryName
      }

      dispatch(patchPartnerCategory(obj, partnerSuccessfull))
    } else {
    }
  };

  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-32 my-2">
            {t("Update Partner Category")}
          </span>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between gap-2">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-20 font-bold">{t("Partner Category")}</span>
            </div>
          </div>
          <div className="d-flex flex-column gap-4 mt-4">
            <div className="d-flex flex-column gap-1">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Partner category name")}<span className="text-danger">*</span></span>
                <InfoTooltip title={"Enter Partner category name"} />
              </div>
              <InputField
              inputRef={inputElementPartCatName}
                onChange={categoryHandler}
                name="categoryName"
                placeholder={t("Enter Partner category name")}
                type="text"
                value={partnerCategoryState.categoryName}
              />
              {error.categoryName ? (
                <label className="text-error">{error.categoryName}</label>
              ) : null}
            </div>

            <div className="d-flex flex-row justify-content-between mb-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Active?")}</span>
                <InfoTooltip title={"Active/Inactive"} />
              </div>
              <ReactSwitchButton
                onChange={() => activeHandle()}
                value={activeFlag}
              />
            </div>

            <div className="d-flex">
              <Button onClick={() => submit()} text={t("Update")} type="primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPartnerCategory;
