import React, { useEffect } from 'react';
import './PrevCardProductSlider.css'
import PrevSliderLeftAndRightButtons from './PrevSliderLeftAndRightButtons';

const PrevCardProductSlider = (props) => {
    const { productImagesArray, productActiveImage, defualtProduct } = props;
    useEffect(() => {}, [productImagesArray]);
  
    useEffect(() => {}, [productActiveImage]);
  
    return (
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner text-center">
            {productImagesArray && productImagesArray.length > 1 ? (
              productImagesArray.map((item, index) => {
                return (
                  <>
                    <div
                      className={
                        index === 0 ? "carousel-item active" : "carousel-item"
                      }
                    >
                      <img
                        src={item?.file_url}
                        key={index}
                        alt="not found"
                        id="product-preview"
                      /> 
                    </div>
                    <PrevSliderLeftAndRightButtons />
                  </>
                );
              })
            ) : (
              <div className={"carousel-item active"}>
                <img
                  src={productActiveImage ? productActiveImage : defualtProduct}
                  //   key={product_default}
                  alt="not found"
                  id="product-preview"
                />
              </div>
            )}
          </div>
        </div>
      );
};

export default PrevCardProductSlider;