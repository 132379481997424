/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../../assests/svg/Tag/tag.svg";
import Button from "../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../../components/Toasts/Toasts";
import { ReactComponent as LeftIcon } from "../../../../assests/svg/Products/left_lg.svg";
// import BackButton from "../../../../components/Button/BackButton";
import Giftcard_default from "../../../../assests/img/giftcard-default.png";
import Dropdown from "react-bootstrap/Dropdown";
import InputField from "../../../../components/InputField/InputField";
import InputGroupTxt from "../../../../components/InputField/InputGroupTxt";
import { putSupplierGiftcardMappingProd } from "../../../../Redux/MappingProducts/Supplier/Giftcards/giftcardSuppMappingAction";
import InfoTooltip from "../../../../components/Tooltip/InfoTooltip";
import ModelForGiftCardSummery from "../../../../components/Models/ModelForGiftCardSummery";
import ModalComp from "../../../../components/Models/ModalComp";
import { fetchGiftCardSummeryById } from "../../../../Redux/GiftCard/giftcardAction";
import { fetchSupplierMapProducts } from "../../../../Redux/Supplier/supplierAction";
import { ReactComponent as Searchicon } from "../../../../assests/svg/search.svg";
import { useTranslation } from "react-i18next";
import SearcBar from "../../../../components/InputField/SearcBar";

const GiftCardSKUEditMapping = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const supplierMappedGiftCard = useSelector(
    (state) => state?.suppliers?.supplierMappedGiftCard
  );
  const selectedSupplier = useSelector(
    (state) => state?.giftcardSuppMappingReducer?.selectedSupplier
  );
  // for model
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );
  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);
  const [showHideCheckBox, setShowHideCheckBox] = useState(true);

  const [products, setProducts] = useState([]);
  const [isDiscount, setIsDiscount] = useState(false);
  const [discount, setDiscount] = useState("");

  const [error, setError] = useState({
    supplierSKU: "",
    // supplierPrice: "",
    discountToClient: "",
  });

  const [toastMessge, setToastMessage] = useState("")
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const [searchTerm, setSearchTerm] = useState("");
  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let arr = supplierMappedGiftCard;
    let prodArr = [];
    let supplier = {
      id: location?.state?.selectedSupplier?.id,
      supplierName: location?.state?.selectedSupplier?.partner_name,
    };
    arr &&
      arr.map((item, i) => {
        let obj = {
          product: item?.giftcard,
          supplier: supplier,
          supplierSKU: item?.supplier_sku ? item?.supplier_sku : "",
          discountToClient: item?.supplier_discount ? item?.supplier_discount : "",
          // supplierPrice: "",
          hadayaPrice: "",
        };
        prodArr.push(obj);
      });

    setProducts(prodArr);
  }, [supplierMappedGiftCard, isDiscount]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSupplierMapProducts(location?.state?.partnerId))
  }, [location?.state?.partnerId]);

  useEffect(() => {
    if (!location?.state?.partnerId) {
      history("/supplier-mapping-dashboard");
    }
  }, [location?.state?.partnerId]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </a>
  ));

  const isDiscountHandle = (e) => {
    setIsDiscount(e?.target?.checked)
    let arr = [...products];
    setDiscount("")
    arr && arr.map((item, i) => {
      arr[i].discountToClient = "";
    });
    setProducts(arr);
  };

  const allCheckedHandle = (e) => {
    const { checked } = e.target;
    let arr = [...products];
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = checked;
      });
    setProducts(arr);
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...products];
    arr.map((item, i) => {
      if (i === parseInt(name)) {
        item.isChecked = checked;
      }
    });
    setProducts(arr);
  };

  // SKU Handler
  const inputChangeHandler = (e) => {
    let skuValue = (e.target.value = e.target.value.toUpperCase());
    let index = e.target.name;

    let arr = [...products];
    arr.map((item, i) => {
      if (i === parseInt(index)) {
        arr[i].supplierSKU = skuValue;
      }
    });
    setProducts(arr);
  };

  // Discount Input handler
  const discountInputHandler = (e) => {
    let discountValue = e.target.value;
    let index = e.target.name;

    let arr = [...products];
    let regex = /^\d{0,2}(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      arr &&
        arr.map((item, i) => {
          if (i === parseInt(index)) {
            arr[i].discountToClient = discountValue;
          }
        });
      setProducts(arr);
    }
  };

  const addRow = (item) => {
    let arr = [...products];

    let obj = {
      ...item,
      supplierSKU: "",
      discountToClient: "",
      // supplierPrice: "",
      isChecked: false,
    };

    arr.unshift(obj);
    setProducts(arr);
  };

  const deleteRow = (index) => {
    let arr = [...products];
    if (index > -1) {
      arr.splice(index, 1);
    }
    setProducts(arr);
    if (arr.length === 0) {
      setShowHideCheckBox(false);
    }
  };

  const setAllGiftcardDiscount = (e) => {
    let discountValue = e?.target.value;
    // let index = e.target.name;

    let arr = [...products];
    let regex = /^\d{0,2}(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      setDiscount(e?.target.value)

      arr &&
        arr.map((item, i) => {
          // if (i === parseInt(index)) {
          arr[i].discountToClient = discountValue;
          // }
        });
      setProducts(arr);
    }
  };

  const validate = () => {
    let checkArr = [];

    products &&
      products.map((item) => {
        if (item.isChecked) {
          checkArr.push(item);
        }
      });
    if (checkArr.length > 0) {
      //  let valSKU = checkArr && checkArr.every(v => v.supplierSKU);
      let valPrice = checkArr && checkArr.every((v) => v.discountToClient);

      valPrice
        ? setError({ supplierPrice: "" })
        : setError({ supplierPrice: t("Enter discount amount") });

      return valPrice ? true : false;
    } else {
      setToastMessage("Please Select Product.")
      toggleShowToast();
      return false;
    }
  };

  const supplierGiftcarMappingSuccess = () => {
    return history("/supplier-map-successfull");
  };

  const apiCallFailed = () => {
    setToastMessage("You cannot map same product.")
    toggleShowToast()
  }

  const submit = () => {
    let value = validate();

    if (value) {
      let selectedProducts = [];
      products &&
        products.map((item) => {
          if (item.isChecked === true) {
            selectedProducts.push(item);
          }
        });
      if (selectedProducts.length > 0) {
        dispatch(
          putSupplierGiftcardMappingProd(
            selectedProducts,
            supplierGiftcarMappingSuccess,
            apiCallFailed
          )
        );
      }
    }
  };

  const showModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowModal(true);
  };

  return (
    products && (
      <div className="d-flex flex-column justify-content-between gap-3 py-4 pb-3 px-4 bg-light">
        <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
          <div className="d-flex flex-row align-items-center pb-2 gap-3">
            <LeftIcon
              onClick={() => history("/supplier-mapping-dashboard")}
              className="cursor"
            />
            <span className="font-bold font-32 my-2">{t("Supplier mapping edit")}</span>
          </div>
        </div>

        <div className="d-flex flex-column bg-white shadow-sm rounded-3 w-100 py-3 px-4 gap-3 ">
          <div className=" d-flex flex-column justify-content-start ">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-20 font-bold">{t("Edit Mapping giftcard")}</span>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search Product")}
                onChange={searchHandler}
              />
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center gap-4 mx-2">
              <div className="d-flex flex-row justify-content-start align-items-center gap-2 my-3">
                <input
                  className="form-check-input cursor"
                  type="checkbox"
                  id="applyAll"
                  checked={isDiscount}
                  onChange={isDiscountHandle}
                />
                <span className="font-15 font-bold my-3">{t("Discount for all entries")}</span>
              </div>
              <div className="d-flex width-20 ">
                {isDiscount &&
                  <InputGroupTxt
                    //  name={index}
                    placeholder={t("Discount")}
                    type="text"
                    title="%"
                    lang={props?.lang}
                    value={discount}
                    onChange={setAllGiftcardDiscount}
                  />}
              </div>
            </div>
          </div>

          <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
            <table responsive="xl" cellPadding={4} cellSpacing={0}>
              <tbody>
                <tr className="col border-bottom">
                  <td className="col-2">
                    <div className="d-flex flex-row justify-content-start text-dark gap-3">
                      {showHideCheckBox === true ? (
                        <span>
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="checkboxNoLabel"
                            checked={
                              products.filter(
                                (product) => product?.isChecked !== true
                              ).length < 1
                            }
                            onChange={allCheckedHandle}
                          />
                        </span>
                      ) : null}

                      <span className="font-12 font-boldest text-muted">
                        {t("Name")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2 px-4">
                    <div className="d-flex justify-content-start">
                      <span className="font-12 font-boldest text-muted">
                        <div>{t("Supplier name")}</div>
                      </span>
                    </div>
                  </td>
                  <td className="col-3">
                    <div className="d-flex justify-content-center">
                      <span className="font-12 font-boldest text-muted">
                        <div>
                          {t("Supplier SKU")}{" "}
                          <InfoTooltip
                            title={"Enter the product ID from the supplier"}
                          />
                        </div>
                      </span>
                    </div>
                  </td>
                  <td className="col-3">
                    <div className="d-flex justify-content-center">
                      <span className="font-12 font-boldest text-muted">
                        <div>{t("Supplier discount")}</div>
                      </span>
                    </div>
                  </td>
                </tr>
                {products &&
                  products?.filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.product?.default_title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  }).map((item, index) => (
                    <tr key={index} className="gap-3 my-2 border-bottom">
                      <td className="col-4">
                        <div className="d-flex flex-row justify-content-start align-items-center py-1 gap-2">
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="checkboxNoLabel"
                            checked={item.isChecked}
                            name={index}
                            onChange={handleCheck}
                          />
                          <img
                            src={
                              item?.product.images &&
                                item?.product?.images[0]?.file_url
                                ? item?.product?.images[0]?.file_url
                                : Giftcard_default
                            }
                            alt="not found"
                            // className="img-fluid"
                            // width="50px"
                            // height="50px"
                            id="product-thumbnail"
                          />
                          <div className="d-flex flex-column justify-content-center align-items-start">
                            <span
                              className="font-bold text-dark font-15 mt-2  d-inline-block text-truncate cursor"
                              title={
                                item?.product?.default_title &&
                                item?.product?.default_title
                              }
                              style={{ maxWidth: "150px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              onClick={() => showModel(item?.product?.id)}
                            >
                              {item?.product?.default_title &&
                                item?.product?.default_title}
                            </span>
                            <span className="font-bold text-muted font-10">
                              {/* {strFirstLetterCap(
                                item?.product?.giftcard_value_type
                              )} */}
                              {item?.product?.giftcard_value_type ===
                                "denominations"
                                ? t("Fixed denominations")
                                : t("Range denominations")}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="col-2 px-4">
                        <div className="d-flex">
                          <span className="text-dark font-15">
                            {item?.supplier?.supplierName}
                          </span>
                        </div>
                      </td>
                      <td className="col-2 p-2">
                        <div className="d-flex flex-column align-items-start">
                          <div className="d-flex ">
                            <InputField
                              name={index}
                              placeholder={t("SKU")}
                              type="text"
                              maxLength={20}
                              value={item.supplierSKU}
                              onChange={inputChangeHandler}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="col-2 p-2">
                        <div className="d-flex flex-column align-items-start">
                          <div className="d-flex">
                            <InputGroupTxt
                              name={index}
                              placeholder={t("Discount")}
                              type="text"
                              title="%"
                              lang={props?.lang}
                              maxLength={20}
                              value={item.discountToClient}
                              onChange={discountInputHandler}
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          {!item.supplierPrice && item.isChecked ? (
                            <label className="text-error-client-supplier">
                              {error.supplierPrice}
                            </label>
                          ) : null}
                        </div>
                      </td>

                      <td className="col-1">
                        <div className="d-flex flex-row px-2 cursor gap-2">
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title="">
                              {/* <Dropdown.Item className="cursor">Edit</Dropdown.Item>*/}
                              <Dropdown.Item
                                className="cursor"
                                onClick={() => deleteRow(index)}
                              >
                                {t("Delete")}
                              </Dropdown.Item>
                              {/* <Dropdown.Item onClick={() => addRow(item)}>
                                Add new row
                              </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex">
          <Button
            type="primary"
            text={t("Update")}
            onClick={() => submit()}
          />
        </div>
        <ModalComp
          size="lg"
          showModal={showModal}
          // handleClose={handleClose}
          component={
            <ModelForGiftCardSummery
              handleClose={handleClose}
              giftcardSummery={giftcardSummery}
            />
          }
        />
        <div
          className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
          id="liveToast"
        >
          <Toasts
            position={"bottom-center"}
            showToast={showToast}
            toggleShowToast={toggleShowToast}
            warningMsg={toastMessge}
            imageType={"warning"}
          />
        </div>
      </div>
    )
  );
};

export default GiftCardSKUEditMapping;
