/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import SmallToast from "../Toast/SmallToast";
import CopyToClipboard from "react-copy-to-clipboard";
import { ReactComponent as EditButton } from "../../assests/svg/edit.svg";
import { clearUserList, getUserList } from "../../Redux/UserManagement/userAction";
import { useDispatch, useSelector } from "react-redux";
import PulseLoader from "../Loader/PulseLoader";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";
import { DataSortingHandler } from "../../utils/constantFunction";
import { SORT_TYPE } from "../../utils/ConstantData";
import RefreshButton from "../Button/RefreshButton";
import DeleteIcon from '@mui/icons-material/Delete';

const AdminRole = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userListState = useSelector((state) => state.users.userList);
  const loading = useSelector((state) => state.users.loading);

  const [id, setId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const [users, setUsers] = useState();

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUserList());
  }, []);

  useEffect(() => {
    setUsers(userListState);
  }, [userListState]);

  useEffect(() => { }, [users]);


  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 20);
    return val;
  };

  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const refreshData = () => {
    dispatch(clearUserList());
    // setskip(0);
    // setlimit(10);
    dispatch(getUserList());
  }


  return loading === true ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="card-body d-flex  flex-row justify-content-between align-item-center">
          <div className="p-2 flex-grow-1">
            <div className="d-flex gap-3 my-2 mx-3 align-items-center">
              <Tag />
              <span className="font-20 font-bold">{t("Users")}</span>
              <span className="font-20 font-bold">
                <SearcBar
                  type="text"
                  name="search"
                  lang={props?.lang}
                  placeholder={t("Search by name")}
                  onChange={searchHandler}
                />
              </span>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div>
              <RefreshButton onClick={refreshData} />
            </div>
          </div>
        </div>
        <div className="row px-5 py-2">
          <table responsive="xl">
            <tbody>
              <tr className="card-body gap-2 border-bottom py-3 cursor">
                <td
                  className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "username"
                    ? "ascending"
                    : "descending"
                    }`}
                  onClick={() =>
                    sortingHandler(
                      "username",
                      setTableHeaderName,
                      users,
                      order,
                      setOrder
                    )
                  }
                >
                  {t("User Name")}
                </td>
                <td className={`card-body gap-1 font-13 font-bold text-muted  ${tableHeaderName === "id"
                  ? "ascending"
                  : "descending"
                  }`}
                  onClick={() =>
                    sortingHandler(
                      "id",
                      setTableHeaderName,
                      users,
                      order,
                      setOrder
                    )
                  }>
                  {t("User ID")}
                </td>
                <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "firstname"
                  ? "ascending"
                  : "descending"
                  }`}
                  onClick={() =>
                    sortingHandler(
                      "firstname",
                      setTableHeaderName,
                      users,
                      order,
                      setOrder
                    )
                  }>
                  {t("Name")}
                </td>
                <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "email"
                  ? "ascending"
                  : "descending"
                  }`}
                  onClick={() =>
                    sortingHandler(
                      "email",
                      setTableHeaderName,
                      users,
                      order,
                      setOrder
                    )
                  }>
                  {t("Email")}
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  <div className="d-flex flex-row justify-content-end">
                    {t("Edit")}
                  </div>
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  <div className="d-flex flex-row justify-content-end">
                    {t("Delete")}
                  </div>
                </td>
              </tr>

              {users &&
                users
                  .filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.username
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, i) => (
                    <tr className="card-body gap-2 border-bottom" key={i}>
                      <td className="card-body gap-1">
                        <div className="d-flex flex-column justify-content-start ">
                          <span
                            className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                            title={item?.username}
                            style={{ maxWidth: "150px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {item?.username}
                          </span>
                        </div>
                      </td>
                      <td className="card-body col-4 gap-1">
                        <div
                          className="d-flex justify-content-start align-items-center visible cursor"
                          title={item?.id}
                        >
                          <span className="font-14 font-boldest text-dark">
                            {trimIdHandler(item?.id)}
                            <span>...</span>
                          </span>
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Copy to clipboard"
                            className="cursor "
                            value={item.id}
                            onClick={() => copyToClipboard(item?.id)}
                          >
                            <CopyToClipboard text={item?.id}>
                              <CopyButton />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </td>

                      <td className="card-body gap-1">
                        <div className="d-flex flex-column">
                          <span className="font-14 font-bold text-dark px-1">
                            {item?.firstname + " " + item?.lastname}
                          </span>
                        </div>
                      </td>

                      <td className="card-body gap-1">
                        <div className="d-flex flex-row justify-content-between cursor gap-3">
                          <span
                            className="fs-light  text-muted d-inline-block text-truncate"
                            style={{ maxWidth: "150px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={item?.email}
                          >
                            {item.email}
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <span
                            className="cursor"
                            onClick={() =>
                              history("/users-management/users-edit", {
                                state: {
                                  user: item,
                                },
                              })
                            }
                          >
                            <EditButton />
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <span
                            className="cursor"
                          >
                            <DeleteIcon />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {users && !users.length && (
            <div className="d-flex flex-row justify-content-center py-3">
              <span className="font-20 font-bold text-error">
                {t("No User found")}
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        <SmallToast data={id ? id : ""} message={"Copied"} />
      </div>
    </div>
  );
};

export default AdminRole;
