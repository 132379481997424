export const FETCH_GIFTCARD_REQUEST = "FETCH_GIFTCARD_REQUEST";
export const FETCH_GIFTCARD_SUCCESS = "FETCH_GIFTCARD_SUCCESS";
export const FETCH_GIFTCARD_FAILURE = "FETCH_GIFTCARD_FAILURE";



export const SET_GIFT_CARD = "SET_GIFT_CARD";
export const SET_NAME_DESCRIPTION = "SET_NAME_DESCRIPTION";
export const SET_CATEGORY_ATTRIBUTES = "SET_CATEGORY_ATTRIBUTES";
export const SET_PRICE = "SET_PRICE";
export const SET_OTHER_FILES = "SET_OTHER_FILES";
export const SET_TERM_CONDITION = "SET_TERM_CONDITION";
// gift card length 

export const FETCH_GIFTCARD_NUMBER_LENGTH_REQUEST = "FETCH_GIFTCARD_LENGTH_REQUEST";
export const FETCH_GIFTCARD_NUMBER_LENGTH_SUCCESS = "FETCH_GIFTCARD_LENGTH_SUCCESS";
export const FETCH_GIFTCARD_NUMBER_LENGTH_FAILURE = "FETCH_GIFTCARD_LENGTH_FAILURE";

// gift card type

export const FETCH_GIFTCARD_TYPE_REQUEST = "FETCH_GIFTCARD_TYPE_REQUEST";
export const FETCH_GIFTCARD_TYPE_SUCCESS = "FETCH_GIFTCARD_TYPE_SUCCESS";
export const FETCH_GIFTCARD_TYPE_FAILURE = "FETCH_GIFTCARD_TYPE_FAILURE";

//put gift card varieties
export const PUT_GIFTCARD_REQUEST = "PUT_GIFTCARD_REQUEST";
export const PUT_GIFTCARD_SUCCESS = "PUT_GIFTCARD_SUCCESS";
export const PUT_GIFTCARD_FAILURE = "PUT_GIFTCARD_FAILURE";

export const PUT_DENOMINATION_REQUEST = "PUT_DENOMINATION_REQUEST";
export const PUT_DENOMINATION_SUCCESS = "PUT_DENOMINATION_SUCCESS";
export const PUT_DENOMINATION_FAILURE = "PUT_DENOMINATION_FAILURE";

//put giftcard image
export const PUT_GIFTCARD_IMAGE_REQUEST = "PUT_GIFTCARD_IMAGE_REQUEST";
export const PUT_GIFTCARD_IMAGE_SUCCESS = "PUT_GIFTCARD_IMAGE_SUCCESS";
export const PUT_GIFTCARD_IMAGE_FAILURE = "PUT_GIFTCARD_IMAGE_FAILURE";

// Prefix of Gift Card
export const FETCH_GIFTCARD_PREFIX_REQUEST = "FETCH_GIFTCARD_PREFIX_REQUEST";
export const FETCH_GIFTCARD_PREFIX_SUCCESS = "FETCH_GIFTCARD_PREFIX_SUCCESS";
export const FETCH_GIFTCARD_PREFIX_FAILURE = "FETCH_GIFTCARD_PREFIX_FAILURE";


// giftcard details
export const FETCH_GIFTCARD_DETAILS_REQUEST = "FETCH_GIFTCARD_DETAILS_REQUEST";
export const FETCH_GIFTCARD_DETAILS_SUCCESS = "FETCH_GIFTCARD_DETAILS_SUCCESS";
export const FETCH_GIFTCARD_DETAILS_FAILURE = "FETCH_GIFTCARD_DETAILS_FAILURE";

// giftcard denominations
export const FETCH_GIFTCARD_DENOMINATION_BYID_REQUEST = "FETCH_GIFTCARD_DENOMINATION_BYID_REQUEST";
export const FETCH_GIFTCARD_DENOMINATION_BYID_SUCCESS = "FETCH_GIFTCARD_DENOMINATION_BYID_SUCCESS";
export const FETCH_GIFTCARD_DENOMINATION_BYID_FAILURE = "FETCH_GIFTCARD_DENOMINATION_BYID_FAILURE";

// giftcard files
export const FETCH_GIFTCARD_FILES_BYID_REQUEST = "FETCH_GIFTCARD_FILES_BYID_REQUEST";
export const FETCH_GIFTCARD_FILES_BYID_SUCCESS = "FETCH_GIFTCARD_FILES_BYID_SUCCESS";
export const FETCH_GIFTCARD_FILES_BYID_FAILURE = "FETCH_GIFTCARD_FILES_BYID_FAILURE";

// get giftcard summery by giftcard id
export const FETCH_GIFTCARD_SUMMERY_BYID_REQUEST = "FETCH_GIFTCARD_SUMMERY_BYID_REQUEST";
export const FETCH_GIFTCARD_SUMMERY_BYID_SUCCESS = "FETCH_GIFTCARD_SUMMERY_BYID_SUCCESS";
export const FETCH_GIFTCARD_SUMMERY_BYID_FAILURE = "FETCH_GIFTCARD_SUMMERY_BYID_FAILURE";

export const EDIT_GIFTCARD_REQUEST = "EDIT_GIFTCARD_REQUEST";
export const EDIT_GIFTCARD_SUCCES = "EDIT_GIFTCARD_SUCCES";
export const EDIT_GIFTCARD_FAILURE = "EDIT_GIFTCARD_FAILURE";

export const GIFTCARD_FILTER_DATA = "GIFTCARD_FILTER_DATA"
export const CLEAR_GIFTCARD_LIST = "CLEAR_GIFTCARD_LIST"

