import {
  SET_CATEGORY_ATTRIBUTES,
  SET_NAME_DESCRIPTION,
  SET_OTHER_FILES,
  SET_PRICE,
  SET_TERM_CONDITION,
  FETCH_GIFTCARD_NUMBER_LENGTH_REQUEST,
  FETCH_GIFTCARD_NUMBER_LENGTH_SUCCESS,
  FETCH_GIFTCARD_NUMBER_LENGTH_FAILURE,
  FETCH_GIFTCARD_TYPE_REQUEST,
  FETCH_GIFTCARD_TYPE_SUCCESS,
  FETCH_GIFTCARD_TYPE_FAILURE,
  FETCH_GIFTCARD_PREFIX_REQUEST,
  FETCH_GIFTCARD_PREFIX_SUCCESS,
  FETCH_GIFTCARD_PREFIX_FAILURE,
  FETCH_GIFTCARD_FAILURE,
  FETCH_GIFTCARD_SUCCESS,
  FETCH_GIFTCARD_REQUEST,
  PUT_GIFTCARD_SUCCESS,
  PUT_GIFTCARD_REQUEST,
  PUT_GIFTCARD_FAILURE,
  PUT_DENOMINATION_REQUEST,
  PUT_DENOMINATION_SUCCESS,
  PUT_DENOMINATION_FAILURE,
  PUT_GIFTCARD_IMAGE_REQUEST,
  PUT_GIFTCARD_IMAGE_SUCCESS,
  PUT_GIFTCARD_IMAGE_FAILURE,
  FETCH_GIFTCARD_DETAILS_FAILURE,
  FETCH_GIFTCARD_DETAILS_SUCCESS,
  FETCH_GIFTCARD_DETAILS_REQUEST,
  FETCH_GIFTCARD_DENOMINATION_BYID_FAILURE,
  FETCH_GIFTCARD_DENOMINATION_BYID_SUCCESS,
  FETCH_GIFTCARD_DENOMINATION_BYID_REQUEST,
  FETCH_GIFTCARD_FILES_BYID_FAILURE,
  FETCH_GIFTCARD_FILES_BYID_SUCCESS,
  FETCH_GIFTCARD_FILES_BYID_REQUEST,
  FETCH_GIFTCARD_SUMMERY_BYID_REQUEST,
  FETCH_GIFTCARD_SUMMERY_BYID_SUCCESS,
  FETCH_GIFTCARD_SUMMERY_BYID_FAILURE,
  EDIT_GIFTCARD_REQUEST,
  EDIT_GIFTCARD_SUCCES,
  EDIT_GIFTCARD_FAILURE,
  GIFTCARD_FILTER_DATA,
  CLEAR_GIFTCARD_LIST
} from "./giftcardTypes";

const initialState = {
  loading: false,
  giftcards: [],
  error: "",
  cardNameDesc: "",
  cardCatAttribute: "",
  cardPrice: "",
  cardOtherFiles: "",
  cardTermCondition: "",
  createGiftCardResponese: null,
  createDenominationResponse: null,
  giftcardImageReponse: null,
  selectedGidftcardDetails: "",
  selectedGidftcardDenomination: null,
  giftcardFiles: null,
  giftcardSummery: null,
  giftcardFilter: [],
  next_iteration: true,
};
//next_iteration: action.payload.moredata,
const initialGiftCardLengthState = {
  giftcardlength: [],
  loading: false,
  error: "",
};

const initialGiftCardTypeState = {
  giftcardtype: [],
  loading: false,
  error: "",
};

const initialGiftCardPrefixState = {
  giftcardprefix: [],
  loading: false,
  error: "",
};

export function giftcardLengthReducer(
  state = initialGiftCardLengthState,
  action
) {
  switch (action.type) {
    case FETCH_GIFTCARD_NUMBER_LENGTH_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_GIFTCARD_NUMBER_LENGTH_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcardlength: action.payload,
        error: "",
      };
    case FETCH_GIFTCARD_NUMBER_LENGTH_FAILURE:
      return {
        ...state,
        loading: false,
        giftcardlength: [],
        error: action.payload,
      };
    default:
      return state;
  }
}

//   gift card type reducer
export function giftcardTypeReducer(state = initialGiftCardTypeState, action) {
  switch (action.type) {
    case FETCH_GIFTCARD_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_GIFTCARD_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcardtype: action.payload,
        error: "",
      };
    case FETCH_GIFTCARD_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        giftcardtype: [],
        error: action.payload,
      };
    default:
      return state;
  }
}

// gift card prefix reducer
export function giftcardPrefixReducer(
  state = initialGiftCardPrefixState,
  action
) {
  switch (action.type) {
    case FETCH_GIFTCARD_PREFIX_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_GIFTCARD_PREFIX_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcardprefix: action.payload,
        error: "",
        cardNameDesc: "",
        cardCatAttribute: "",
        cardPrice: "",
        cardOtherFiles: "",
        cardTermCondition: "",
      };
    case FETCH_GIFTCARD_PREFIX_FAILURE:
      return {
        ...state,
        loading: false,
        giftcardprefix: [],
        error: action.payload,
      };
    default:
      return state;
  }
}
function giftcardReducer(state = initialState, action) {
  switch (action.type) {
    //Field data
    case SET_NAME_DESCRIPTION:
      return {
        ...state,
        cardNameDesc: action.payload,
      };
    case SET_CATEGORY_ATTRIBUTES:
      return {
        ...state,
        cardCatAttribute: action.payload,
      };
    case SET_PRICE:
      return {
        ...state,
        cardPrice: action.payload,
      };
    case SET_OTHER_FILES:
      return {
        ...state,
        cardOtherFiles: action.payload,
      };
    case SET_TERM_CONDITION:
      return {
        ...state,
        cardTermCondition: action.payload,
      };
    //put giftcard
    case PUT_GIFTCARD_REQUEST:
      return {
        ...state,
        loading: true,
        createGiftCardResponese: null,
        error: "",
      };
    case PUT_GIFTCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        createGiftCardResponese: action.payload,
        error: "",
      };
    case PUT_GIFTCARD_FAILURE:
      return {
        ...state,
        loading: false,
        createGiftCardResponese: null,
        error: action.payload,
      };
    case PUT_DENOMINATION_REQUEST:
      return {
        ...state,
        loading: true,
        createDenominationResponse: null,
        error: "",
      };
    case PUT_DENOMINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        createDenominationResponse: action.payload,
      };
    case PUT_DENOMINATION_FAILURE:
      return {
        ...state,
        loading: false,
        createDenominationResponse: null,
        error: action.payload,
      };
    case PUT_GIFTCARD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        createDenominationResponse: null,
        error: "",
      };
    case PUT_GIFTCARD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcardImageReponse: action.payload,
        error: "",
        cardNameDesc: "",
        cardCatAttribute: "",
        cardPrice: "",
        cardOtherFiles: "",
        cardTermCondition: "",
      };
    case PUT_GIFTCARD_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        createDenominationResponse: null,
        giftcardImageReponse: null,
        error: action.payload,
      };
    case EDIT_GIFTCARD_REQUEST:
      return {
        ...state,
        loading: true,
        createGiftCardResponese: null,
        error: "",
      };
    case EDIT_GIFTCARD_SUCCES:
      return {
        ...state,
        loading: false,
        createGiftCardResponese: action.payload,
        error: "",
        cardNameDesc: "",
        cardCatAttribute: "",
        cardPrice: "",
        cardOtherFiles: "",
        cardTermCondition: "",
      };
    case EDIT_GIFTCARD_FAILURE:
      return {
        ...state,
        loading: false,
        createGiftCardResponese: null,
        error: action.payload,
      };

    case CLEAR_GIFTCARD_LIST:
      return {
        ...state,
        loading: false,
        giftcards: [],
        next_iteration: true,
        error: "",
      };

    // get gitcarlist
    case FETCH_GIFTCARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        // giftcards: [],
        giftcardFiles: null,
        selectedGidftcardDenomination: null,
        selectedGidftcardDetails: "",
      };
    case FETCH_GIFTCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcards: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case FETCH_GIFTCARD_FAILURE:
      return {
        ...state,
        loading: false,
        giftcards: [],
        error: action.payload,
      };
    case GIFTCARD_FILTER_DATA:
      return {
        ...state,
        giftcardFilter: action.payload
      }
    // get giftcard summery

    case FETCH_GIFTCARD_SUMMERY_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_GIFTCARD_SUMMERY_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcardSummery: action.payload,
        error: "",
      };
    case FETCH_GIFTCARD_SUMMERY_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        giftcardSummery: [],
        error: action.payload,
      };

    // giftcard details
    case FETCH_GIFTCARD_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        selectedGidftcardDetails: "",
        giftcardFiles: null,
        selectedGidftcardDenomination: null,
        error: "",
      };
    case FETCH_GIFTCARD_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedGidftcardDetails: action.payload,
        error: "",
      };
    case FETCH_GIFTCARD_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        selectedGidftcardDetails: "",
        error: action.payload,
      };
    case FETCH_GIFTCARD_DENOMINATION_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        selectedGidftcardDenomination: null,
        error: "",
      };
    case FETCH_GIFTCARD_DENOMINATION_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedGidftcardDenomination: action.payload,
        error: "",
      };
    case FETCH_GIFTCARD_DENOMINATION_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        selectedGidftcardDenomination: null,
        error: action.payload,
      };
    case FETCH_GIFTCARD_FILES_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        giftcardFiles: null,
        error: "",
      };
    case FETCH_GIFTCARD_FILES_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcardFiles: action.payload,
        error: "",
      };
    case FETCH_GIFTCARD_FILES_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        giftcardFiles: null,
        error: action.payload,
      };

    default:
      return state;
  }
}

export default giftcardReducer;
