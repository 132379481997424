import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import PartnerDetailGiftcardTable from "../../components/PartnerDetailProductTable/PartnerDetailGiftcardTable";
import PartnerDetailMerchandiseTable from "../../components/PartnerDetailProductTable/PartnerDetailMerchandiseTable";
import PartnerDetailProductTableHeader from "../../components/PartnerDetailProductTable/PartnerDetailProductTableHeader";
import AllTransaction from "../../components/Supplier/AllTransaction";
import SupplierInfo from "../../components/Supplier/SupplierInfo";
import { useTranslation } from "react-i18next";

const SupplierDetails = (props) => {
  const location = useLocation();
  const history = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {

  }, []);

  const [tabs, setTabs] = useState("allTransactions");
  const [prductFilterValue, setProductFilterValue] = useState({
    name: "Giftcard",
  });
  const [searchValue, setSearchValue] = useState("");

  const productFilterValueGethandler = (filterValue) => {

    setProductFilterValue(filterValue);
  };

  const getSearchValue = (SearchValue) => {
    setSearchValue(SearchValue);
  };

  const goToEditMappingScreen = () => {
    if (prductFilterValue?.name === "Giftcard") {
      history("/supplier-mapping-giftcard-edit", {
        state: {
          partnerId: location?.state?.selectedSupplier?.id,
          selectedSupplier: location?.state?.selectedSupplier,
        },
      });
    }
    else {
      history("/supplier-mapping-merchandise-edit", {
        state: {
          partnerId: location?.state?.selectedSupplier?.id,
          selectedSupplier: location?.state?.selectedSupplier,
        },
      });
    }
  };

  return (
    <div className="d-flex flex-column justify-content-between gap-3 py-3 px-4 bg-light">
      <SupplierInfo selectedSupplier={location?.state?.selectedSupplier} />

      <div className="d-flex flex-row w-100  align-items-center">
        <div className="d-flex flex-grow-1">
          <div className="d-flex flex-start gap-3 mb-1 align-items-center">
            <div className="">
              <Button
                type={tabs === "allTransactions" ? "primary" : "rounded"}
                text={t("All transactions")}
                className="rounded-5 my-4 mt-6 fw-bolder"
                onClick={() => setTabs("allTransactions")}
              />
            </div>
            <div className="">
              <Button
                type={tabs === "allProducts" ? "primary" : "rounded"}
                text={t("All products")}
                className="rounded-6 my-4 mt-6 fw-bold"
                onClick={() => setTabs("allProducts")}
              />
            </div>
          </div>
        </div>
      </div>

      {tabs === "allTransactions" ? (
        <AllTransaction lang={props?.lang} selectedSupplier={location?.state?.selectedSupplier} />
      ) : (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
          <PartnerDetailProductTableHeader
            lang={props?.lang}
            productFilterValueGethandler={productFilterValueGethandler}
            getSearchValue={getSearchValue}
            goToEditMappingScreen={goToEditMappingScreen}
          />
          {prductFilterValue?.name === "Giftcard" ? (
            <PartnerDetailGiftcardTable
              giftcardTitle={t("Giftcard image/title")}
              giftcardId={t("Giftcard id")}
              countryCurrency={t("Country & currency")}
              inventoryType={t("Inventory type")}
              discount={t("Supplier discount")}
              partnerId={
                location?.state?.selectedSupplier?.id &&
                location?.state?.selectedSupplier?.id
              }
              searchValue={searchValue}
            />
          ) : (
            <PartnerDetailMerchandiseTable
              merchandiseTitle={t("Merchandise title")}
              merchandiseId={t("Merchandise id")}
              countryCurrency={t("Country & currency")}
              sellingPrice={t("Selling price")}
              partnerId={
                location?.state?.selectedSupplier?.id &&
                location?.state?.selectedSupplier?.id
              }
              searchValue={searchValue}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default SupplierDetails;
