/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import { ReactComponent as EditButton } from "../../assests/svg/edit.svg";
import SmallToast from "../Toast/SmallToast";
import CopyToClipboard from "react-copy-to-clipboard";
import PulseLoader from "../Loader/PulseLoader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";
import { SORT_TYPE } from "../../utils/ConstantData";
import { DataSortingHandler } from "../../utils/constantFunction";
import { clearUserRolesList, getAllUserRole } from "../../Redux/UserManagement/userAction";
import RefreshButton from "../Button/RefreshButton";
import DeleteIcon from '@mui/icons-material/Delete';

const RolesCompo = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loading = useSelector((state) => state.users.loading);
  const rolesState = useSelector((state) => state.users.allUserRole);

  const [id, setId] = useState("");
  const history = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const [products, setProducts] = useState();

  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllUserRole());
  }, []);

  useEffect(() => {
    setProducts(rolesState);
  }, [rolesState]);

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 22);
    return val;
  };

  const editRoleButtonhandler = (item) => {
    history("/users-management/edit-role", { state: { roleItem: item } });
  };

  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const refreshData = () => {
    dispatch(clearUserRolesList());
    // setskip(0);
    // setlimit(10);
    dispatch(getAllUserRole());
  }


  return loading === true ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="card-body d-flex  flex-row justify-content-between align-item-center">
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div className="d-flex align-items-center gap-3 px-3 my-2">
              <Tag />
              <span className="font-20 font-bold">{t("Roles")}</span>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search by name")}
                onChange={searchHandler}
              />
            </div>
          </div>

          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div>
              <RefreshButton onClick={refreshData} />
            </div>
          </div>
        </div>

        <div className="row card-body px-5 py-2 justify-content-between gap-3">
          <table responsive="xl">
            <tbody>
              <tr className="card-body gap-3 border-bottom py-4 justify-content-between cursor">
                <td
                  className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "role_name" ? "ascending" : "descending"
                    }`}
                  onClick={() =>
                    sortingHandler(
                      "role_name",
                      setTableHeaderName,
                      products,
                      order,
                      setOrder
                    )
                  }
                >
                  {t("Role Name")}
                </td>
                <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "id" ? "ascending" : "descending"
                  }`}
                  onClick={() =>
                    sortingHandler(
                      "id",
                      setTableHeaderName,
                      products,
                      order,
                      setOrder
                    )
                  }>
                  {t("User Id")}
                </td>
                <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "user_type" ? "ascending" : "descending"
                  }`}
                  onClick={() =>
                    sortingHandler(
                      "user_type",
                      setTableHeaderName,
                      products,
                      order,
                      setOrder
                    )
                  }>
                  {t("User Type")}
                </td>
                <td className="card-body font-13 font-bold text-muted">
                  {t("Edit")}
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  <div className="d-flex flex-row justify-content-end">
                    {t("Delete")}
                  </div>
                </td>
              </tr>

              {products &&
                products
                  ?.filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.role_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <tr
                      className="card-body gap-3 border-bottom"
                      key={item?.id}
                    >
                      <td className="card-body gap-1">
                        <div className="d-flex flex-column justify-content-start ">
                          <span
                            className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                            title={item?.role_name}
                            style={{ maxWidth: "150px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {item?.role_name}
                          </span>
                        </div>
                      </td>
                      <td className="card-body col-4 gap-1">
                        <div
                          className="d-flex justify-content-start align-items-center visible cursor"
                          title={item?.id}
                        >
                          <span className="font-14 font-boldest text-dark">
                            {trimIdHandler(item?.id)}
                            <span>...</span>
                          </span>
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Copy to clipboard"
                            className="cursor "
                            value={item.id}
                            onClick={() => copyToClipboard(item?.id)}
                          >
                            <CopyToClipboard text={item?.id}>
                              <CopyButton />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </td>
                      <td className="card-body gap-1">
                        <div className="d-flex flex-row justify-contents-between align-items-center">
                          <span className="font-15 font-bold text-dark px-1 ">
                            {item?.user_type}
                            {/* {item?.user_type==="internal"?"Internal":null} */}
                          </span>
                        </div>
                      </td>
                      <td className="card-body ">
                        <div className="d-flex flex-row justify-contents-center align-items-center">
                          <span
                            className="cursor"
                            onClick={() => editRoleButtonhandler(item)}
                          >
                            <EditButton />
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <span
                            className="cursor"
                          >
                            <DeleteIcon />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {!products?.length && (
            <div className="d-flex flex-row justify-content-center py-3">
              <span className="font-20 font-bold text-error">
                {t("Roles not found")}
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        <SmallToast data={id ? id : ""} message={"Copied"} />
      </div>
    </div>
  );
};

export default RolesCompo;
