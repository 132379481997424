export const FETCH_ALL_COUNTRY_REQUEST="FETCH_ALL_COUNTRY_REQUEST"
export const FETCH_ALL_COUNTRY_SUCCESS="FETCH_ALL_COUNTRY_SUCCESS"
export const FETCH_ALL_COUNTRY_FAILURE="FETCH_ALL_COUNTRY_FAILURE"

export const FETCH_COUNTRY_DETAILS_REQUEST="FETCH_COUNTRY_DETAILS_REQUEST"
export const FETCH_COUNTRY_DETAILS_SUCCESS="FETCH_COUNTRY_DETAILS_SUCCESS"
export const FETCH_COUNTRY_DETAILS_FAILURE="FETCH_COUNTRY_DETAILS_FAILURE"

export const FETCH_COUNTRY_DETAILS_ID_REQUEST="FETCH_COUNTRY_DETAILS_ID_REQUEST"
export const FETCH_COUNTRY_DETAILS_ID_SUCCESS="FETCH_COUNTRY_DETAILS_ID_SUCCESS"
export const FETCH_COUNTRY_DETAILS_ID_FAILURE="FETCH_COUNTRY_DETAILS_ID_FAILURE"