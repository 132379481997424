/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/Button/Button";
import AllTransaction from "../../components/Supplier/AllTransaction";
import MappedGiftCards from "../../components/Supplier/MappedGiftCards";
import SupplierInfo from "../../components/Supplier/SupplierInfo";
import { fetchSupplierMapMerchandise, fetchSupplierMapProducts } from "../../Redux/Supplier/supplierAction";
import { baseUrl, getToken } from "../../utils/prodURL";


const SupplierDashboardDetails = (props) => {
  const dispatch = useDispatch();
  const [selectedSupplier, setSelectedSupplier] = useState("")
  const partner_object = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (partner_object?.type_id) {
      let config = {
        method: "get",
        url: `${baseUrl}/partner/${partner_object?.type_id}`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      };
      axios(config)
        .then((response) => {
          setSelectedSupplier(response?.data?.result)
        })
        .catch((error) => {
          // const errorMsg = error;
        });
    }

  }, [])

  useEffect(() => {
    dispatch(fetchSupplierMapProducts(selectedSupplier?.id))
    dispatch(fetchSupplierMapMerchandise(selectedSupplier?.id));
  }, [selectedSupplier])

  const [tabs, setTabs] = useState("allTransactions");


  return (
    <div className="d-flex flex-column justify-content-between gap-3 py-3 px-4 bg-light">
      <SupplierInfo selectedSupplier={selectedSupplier} />

      <div className="d-flex flex-row w-100  align-items-center">
        <div className="d-flex flex-grow-1">
          <div className="d-flex flex-start gap-3 mb-1 align-items-center">
            <div className="">
              <Button
                type={tabs === "allTransactions" ? "primary" : "rounded"}
                text="All transactions"
                className="rounded-5 my-4 mt-6 fw-bolder"
                onClick={() => setTabs("allTransactions")}
              />
            </div>
            <div className="">
              <Button
                type={tabs === "allProducts" ? "primary" : "rounded"}
                text="All products"
                className="rounded-6 my-4 mt-6 fw-bold"
                onClick={() => setTabs("allProducts")}
              />
            </div>
          </div>
        </div>
      </div>

      {tabs === "allTransactions" ? (
        <AllTransaction selectedSupplier={selectedSupplier} />
      ) : (
        <MappedGiftCards selectedSupplier={selectedSupplier} lang={props?.lang} />
      )}
    </div>
  );
};
export default SupplierDashboardDetails;
