import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import MyntraImg from "../../assests/img/ImageGift.png";
import { strFirstLetterCap } from "../../utils/constantFunction";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import ModelForGiftCardSummery from "../../components/Models/ModelForGiftCardSummery";
import ModalComp from "../../components/Models/ModalComp";
import {  fetchGiftCardSummeryById } from "../../Redux/GiftCard/giftcardAction";
import { useTranslation } from "react-i18next";


const GiftCardOrderSummaryProd = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const giftcardOrderData = useSelector((state) => state.createOrder);

      // for model
const giftcardSummery = useSelector((state) => state?.giftcard?.giftcardSummery)
const handleClose = () => setShowModal(false);
const [showModal, setShowModal] = useState(false);

  const showModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id))
    setShowModal(true)
  };

  return (
    <div className="card-body d-flex flex-column justify-content-between bg-white shadow-sm rounded-3 p-4 mb-2 gap-3">
      <div className="d-flex gap-4 ">
        <Tag />
        <span className="font-bold font-20 ">{t("Product")}</span>
      </div>

      <table responsive="xl">
        <tbody>
          <tr className="d-flex justify-content-between align-items-center px-2">
            <td>
              <div className="d-flex flex-row justify-content-start align-items-center  gap-1">
                <span className=" mx-1 rounded-3">
                  <img
                    src={
                      giftcardOrderData?.createorderData?.product?.images &&
                      giftcardOrderData?.createorderData?.product?.images[0]
                        ? giftcardOrderData?.createorderData?.product?.images[0]
                            ?.file_url
                        : MyntraImg
                    }
                    alt="not found"
                    className="img-fluid"
                    width={"80px"}
                    height={"50px"}
                  />
                </span>
                <div className="d-flex flex-column justify-content-start mx-3">
                  <span className="font-16 font-boldest text-dark cursor"
                   onClick={() => showModel(giftcardOrderData?.createorderData?.product?.id)}
                  >
                    {giftcardOrderData?.createorderData?.product?.default_title}
                  </span>
                  <span className="font-13 font-bold text-dark">
                    {strFirstLetterCap(
                      giftcardOrderData?.createorderData?.product
                        ?.giftcard_value_type
                    )}
                    {/* <span> denomination</span> */}
                  </span>
                  <span className="font-13 font-bold text-muted">
                    {giftcardOrderData?.createorderData?.product?.id}
                  </span>
                </div>
              </div>
            </td>
            <td>
              <span className="font-15 text-dark font-bold text-success bg-04 px-1 py-1 rounded-3">
                {`${giftcardOrderData?.createorderData?.product?.country?.currency} - (${giftcardOrderData?.createorderData?.product?.country?.currency_symbol})`}
              </span>
            </td>
            {/* <td>
              <span className="font-15 font-bold text-success bg-04 px-1 py-1 rounded-3">
                {giftcardOrderData?.createorderData?.selectedDenomination?.availability &&
                giftcardOrderData?.createorderData?.selectedDenomination?.availability ===
                  "in_stock"
                  ? "In stock"
                  : giftcardOrderData?.createorderData?.selectedDenomination
                      ?.availability === "out_of_stock"
                  ? "Out of stock"
                  : ""}
              </span>
            </td> */}
            <td>
              <span className="font-15 font-bold text-dark bg-danger bg-opacity-25 rounded-3 px-1 py-1">
                {giftcardOrderData?.createorderData?.product
                  ?.registered_by_id &&
                giftcardOrderData?.createorderData?.product
                  ?.registered_by_id === "powered_by_ehadaya"
                  ? "Powered by ehadaya"
                  : giftcardOrderData?.createorderData?.product
                      ?.registered_by_id === "manual_inventory"
                  ? "Manual inventory"
                  : giftcardOrderData?.createorderData?.product
                      ?.registered_by_id === "3rd_party_suppliers"
                  ? "3rd party suppliers"
                  : null}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <ModalComp
        size="lg"
        showModal={showModal}
        // handleClose={handleClose}
        component={<ModelForGiftCardSummery handleClose={handleClose} giftcardSummery={giftcardSummery} />}
      />
    </div>
  );
};

export default GiftCardOrderSummaryProd;
