/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import Toasts from "../Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import { DataSortingHandler, momentdateFormater, } from "../../utils/constantFunction";
import PulseLoader from "../Loader/PulseLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoaders from "react-spinners/PulseLoader";
import { clearClientFloatTransactions, floatTransactionFilterData, getAllClientTransactions } from "../../Redux/Float/floatAction";
import Filter from "../Filter/Filter";
import { PRODUCT_TRANSACTION_DEBIT_CREDIT_FILTER, SORT_TYPE } from "../../utils/ConstantData";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";
import RefreshButton from "../Button/RefreshButton";

const AllFloatTransactions = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allClientTransaction = useSelector(
    (state) => state?.partnerFloat?.clientAllTransactions
  );

  const hasmore = useSelector(
    (state) => state?.partnerFloat?.next_iteration
  );
  const loading = useSelector((state) => state?.partnerFloat?.loading)
  const floatTransactionFilter = useSelector((state) => state?.partnerFloat?.floatTransactionFilter)
  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);


  const [skip, setskip] = useState(allClientTransaction.length === 0 ? -10 : allClientTransaction.length - 10);
  const [limit, setlimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const [products, setProducts] = useState();

  const [filterCardType, setFilterCardType] = useState("");

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => { }, [order]);

  useEffect(() => {
    if (allClientTransaction.length === 0) {
      dispatch(getAllClientTransactions())
    }
  }, [])

  useEffect(() => {
    setProducts(allClientTransaction);
  }, [allClientTransaction]);

  useEffect(() => {
    setProducts(floatTransactionFilter[0]?.filterProduct)
  }, [floatTransactionFilter])

  useEffect(() => {
    floatTransactionFilter &&
      setFilterCardType(floatTransactionFilter[1]?.filterType);
  }, [floatTransactionFilter[1]?.filterType])

  useEffect(() => {
    if (floatTransactionFilter.length === 0) {
      setProducts(allClientTransaction);
    }
  }, [allClientTransaction]);

  const filterCardTypeHandler = (e) => {
    setFilterCardType(e);
    let arr = [];
    allClientTransaction &&
      allClientTransaction.map((item, i) => {
        if (e?.value === item?.transaction_type) {
          arr.push(item);
        }
        else if (e?.value === null) {
          arr.push(item)
        }
      });
    dispatch(floatTransactionFilterData([{ filterProduct: arr }, { filterType: e }]))
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (allClientTransaction.length === 0) {
      fetchdata(skip)
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(getAllClientTransactions(skip + limit, limit, allClientTransaction));
      setskip(skip + limit)
    }, 100);
  }

  const refreshData = () => {
    dispatch(clearClientFloatTransactions());
    setskip(0);
    setlimit(10);
    setFilterCardType("");
    dispatch(getAllClientTransactions(0, 10, []));
  }

  return loading === true && products?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="card-body d-flex  flex-row justify-content-between align-item-center">
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div className="d-flex align-items-center gap-3 my-2">
              <Tag />
              <span className="font-20 font-bold">{t("All Transactions")}</span>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search by brand name")}
                onChange={searchHandler}
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div  >
              <Filter
                data={PRODUCT_TRANSACTION_DEBIT_CREDIT_FILTER}
                onChange={filterCardTypeHandler}
                title={filterCardType?.name}
                lang={props?.lang}
              />
            </div>
            <div>
              <RefreshButton onClick={refreshData} />
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={allClientTransaction?.length || 0}
          next={fetchdata}
          hasMore={hasmore}
          loader={<div className="d-flex justify-content-center"> <PulseLoaders color={"#594190"} loading={loading} size={10} /> </div>}
        >
          <div className="row px-5 py-3">

            <table responsive="xl">
              <tbody>
                <tr className="card-body cursor">
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "partner_name"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "partner_name",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Client Name")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "name"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "name",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }>
                    {t("Country & Currency")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "amount"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "amount",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }>
                    {t("Amount")}{" "}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "transaction_type"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "transaction_type",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }>
                    {t("Transaction")}<br /> {t("Type")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "created_at"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "created_at",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }>
                    {t("Transaction Date")}
                  </td>
                </tr>
                {products &&
                  products?.filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.partner?.partner_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  }).map((item, index) => (
                    <tr className="card-body " key={item?.id}>
                      <td className="card-body gap-1">
                        <span className="d-inline-block text-truncate font-15 font-boldest text-dark cursor" title={item?.partner?.partner_name}
                          style={{ maxWidth: "150px" }} data-toggle="tooltip" data-placement="top">
                          {item?.partner?.partner_name}
                        </span>
                      </td>
                      <td className="card-body gap-1">
                        <span
                          className="font-13 font-bold text-muted d-inline-block text-truncate cursor"
                          title={
                            item?.country?.name + " | " + item?.country?.currency
                          }
                          style={{ maxWidth: "125px" }}
                          data-toggle="tooltip"
                          data-placement="top"
                        >
                          {item?.country?.name} | {item?.country?.currency}
                        </span>
                      </td>


                      <td className="card-body gap-1">
                        <span className="font-15 font-bold text-dark ">
                          {item?.country?.currency_symbol}
                          {item?.amount}
                        </span>
                      </td>
                      <td className="card-body gap-1">
                        <span className="d-flex justify-content-start align-items-center font-15 font-bold text-dark ">
                          {item?.transaction_type}
                        </span>
                      </td>
                      <td className="card-body gap-1">
                        <div className="d-flex flex-row justify-content-between  gap-3">
                          <span className="font-15 font-bold text-dark ">
                            {momentdateFormater(item?.created_at)}
                          </span>
                        </div>
                      </td>

                    </tr>
                  ))}
              </tbody>
            </table>
            {!products?.length && (
              <div className="d-flex flex-row justify-content-center py-3">
                <span className="font-20 font-bold text-error">
                  {t("No transactions details found")}
                </span>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default AllFloatTransactions;
