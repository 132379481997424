
// GET PARTNER CATEGORY API
export const FETCH_PARTNER_CATEGORY_REQUEST = "FETCH_PARTNER_CATEGORY_REQUEST"
export const FETCH_PARTNER_CATEGORY_SUCCESS = "FETCH_PARTNER_CATEGORY_SUCCESS"
export const FETCH_PARTNER_CATEGORY_FAILURE = "FETCH_PARTNER_CATEGORY_FAILURE"


// To set Partner in redux 
export const SET_CREATE_PARTNER_DATA = "SET_CREATE_PARTNER_DATA"
export const CLEAR_PARTNER_CAT_LIST = "CLEAR_PARTNER_CAT_LIST"


export const PUT_PARTNER_REQUEST = "PUT_PARTNER_REQUEST"
export const PUT_PARTNER_SUCCESS = "PUT_PARTNER_SUCCESS"
export const PUT_PARTNER_FAILURE = "PUT_PARTNER_FAILURE"


export const FETCH_PARTNER_REQUEST = "FETCH_PARTNER_REQUEST"
export const FETCH_PARTNER_SUCCESS = "FETCH_PARTNER_SUCCESS"
export const FETCH_PARTNER_FAILURE = "FETCH_PARTNER_FAILURE"

export const POST_PARTNER_CATEGORY_REQUEST = "POST_PARTNER_CATEGORY_REQUEST"
export const POST_PARTNER_CATEGORY_SUCCESS = "POST_PARTNER_CATEGORY_SUCCESS"
export const POST_PARTNER_CATEGORY_FAILURE = "POST_PARTNER_CATEGORY_FAILURE"

export const PATCH_PATRNER_CATEGORY_REQUEST = "PATCH_PATRNER_CATEGORY_REQUEST"
export const PATCH_PATRNER_CATEGORY_SUCCESS = "PATCH_PATRNER_CATEGORY_SUCCESS"
export const PATCH_PATRNER_CATEGORY_FAILURE = "PATCH_PATRNER_CATEGORY_FAILURE"