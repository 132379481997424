import React from 'react'
import { ReactComponent as Offlineicon } from '../../assests/svg/offline.svg';

const Offline = () => {
    return (
        <div className='offline-container'>
            <div className='offline-vertical-center'>
                <span>
                    <Offlineicon style={{width:"100px"}}/>
                </span>
                <span className='p-4'><h1>You are offline!</h1></span>
            </div>
        </div>
    )
}

export default Offline