/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { useDispatch, useSelector } from "react-redux";
import UserDefaultPlaceHolder from "../../assests/img/default-user.png";
import { clearClientList, fetchClient } from "../../Redux/Client/clientAction";
import { getPratnerFloats } from "../../Redux/Float/floatAction";
import { fetchAllGiftCardList } from "../../Redux/GiftCard/giftcardAction";
import DistGiftcardModel from "../Models/DistGiftcardModel";
import ModalComp from "../Models/ModalComp";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoaders from "react-spinners/PulseLoader";
import PulseLoader from "../Loader/PulseLoader";
import SearcBar from "../InputField/SearcBar";
import { useTranslation } from "react-i18next";
import { DataSortingHandler } from "../../utils/constantFunction";
import { SORT_TYPE } from "../../utils/ConstantData";
import RefreshButton from "../Button/RefreshButton";


const ClientDistributionTable = (props) => {
  const { clientsState } = props;
  const history = useNavigate();
  const dispatch = useDispatch(); 
  const { t } = useTranslation();

  // infinite scroll
  const hasmore = useSelector((state) => state?.clients?.next_iteration);
  const loading = useSelector((state) => state?.clients?.loading);
  const [skip, setskip] = useState(
    clientsState.length === 0 ? -10 : clientsState.length - 10
  );
  const [limit, setlimit] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {}, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (clientsState.length === 0) {
      fetchdata(skip);
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(fetchClient(skip + limit, limit, clientsState));
      setskip(skip + limit);
    }, 100);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchAllGiftCardList());
  }, []);

  const goTonextStep = (item) => {
    if (item.float_flag === true) {
      dispatch(getPratnerFloats(item?.id));
    }
    history("/card-distribution", {
      state: {
        selectedClient: item,
        clientType: "client",
      },
    });
  };

  const refreshData = () => {
    dispatch(clearClientList());
    setskip(0);
    setlimit(10);
    dispatch(fetchAllGiftCardList());
    dispatch(fetchClient(0, 10, []));
  };

  return loading === true && clientsState.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div>
      <div className="d-flex flex-column  bg-white">
        <div className="card-body d-flex  flex-row justify-content-between align-item-center">
          <div className="p-2 flex-grow-1">
            <div className="d-flex gap-3 my-2 mx-3 align-items-center">
              <Tag />
              <span className="font-20 font-bold">{t("Select Client")}</span>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search by name")}
                onChange={searchHandler}
              />
            </div>
          </div>
          <div>
            <RefreshButton onClick={refreshData} />
          </div>
        </div>
        <InfiniteScroll
          dataLength={clientsState?.length || 0}
          next={fetchdata}
          hasMore={hasmore}
          loader={
            <div className="d-flex justify-content-center">
              {" "}
              <PulseLoaders
                color={"#594190"}
                loading={loading}
                size={10}
              />{" "}
            </div>
          }
        >
          <div className="row px-5 py-2">
            <table responsive="xl">
              <tbody>
                <tr className="card-body gap-3 border-bottom py-5">
                  <td
                    className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "partner_name"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "partner_name",
                        setTableHeaderName,
                        clientsState,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Name")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "email"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "email",
                        setTableHeaderName,
                        clientsState,
                        order,
                        setOrder
                      )
                    }>
                    {t("Client email")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "country_name"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "country_name",
                        setTableHeaderName,
                        clientsState,
                        order,
                        setOrder
                      )
                    }>
                    {t("Country")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "state_name"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "state_name",
                        setTableHeaderName,
                        clientsState,
                        order,
                        setOrder
                      )
                    }>
                    {t("State")}
                  </td>
                </tr>
                {clientsState &&
                  clientsState
                    ?.filter((item) => {
                      if (searchTerm === "") {
                        return item;
                      } else if (
                        item?.partner_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <tr
                        className="card-body gap-1 border-bottom hover-row"
                        key={item.id}
                        onClick={() => goTonextStep(item)}
                      >
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-start  gap-1">
                            <img
                              src={UserDefaultPlaceHolder}
                              width={50}
                              height={50}
                              alt="Logo Not Found"
                            />
                            <div className="d-flex flex-column justify-content-start ">
                              <span
                                className="d-inline-block text-truncate font-15 font-boldest text-dark"
                                title={item.partner_name}
                                style={{ maxWidth: "200px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                {item.partner_name}
                              </span>
                              <span className="font-12 font-bold text-muted">
                                {item.company_name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-between  gap-1">
                            <span
                              className="fs-light  text-muted d-inline-block text-truncate"
                              style={{ maxWidth: "150px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={item.email}
                            >
                              {item.email}
                            </span>
                          </div>
                        </td>

                        <td className="card-body gap-1">
                          <span
                            className="font-15 font-bold text-dark px-1 d-inline-block text-truncate"
                            style={{ maxWidth: "150px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={
                              item.country_name
                                ? item.country_name
                                : "No Country"
                            }
                          >
                            {item.country_name
                              ? item.country_name
                              : "No Country"}
                          </span>
                        </td>

                        <td className="card-body gap-1">
                          <span
                            className="font-15 font-bold text-dark px-1 d-inline-block text-truncate"
                            style={{ maxWidth: "150px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={
                              item.state_name ? item.state_name : "No State"
                            }
                          >
                            {item.state_name ? item.state_name : "No State"}
                          </span>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>

        <ModalComp
          size="md"
          showModal={showModal}
          // handleClose={handleClose}
          component={
            <DistGiftcardModel
              handleClose={handleClose}
              heading="Client have no float balance, Do you want to add float balance?"
              path="/float/currency-mapping/to-client"
            />
          }
        />
      </div>
    </div>
  );
};

export default ClientDistributionTable;
