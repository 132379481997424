import {
  GET_WEIGHT_REQUEST,
  GET_WEIGHT_SUCCESS,
  GET_WEIGHT_FAILURE,
} from "./weightType";

const initialWeightState = {
  loading: false,
  weight: [],
  error: "",
};

const weightReducer = (state = initialWeightState, action) => {
  switch (action.type) {
    case GET_WEIGHT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WEIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
        weight: action.payload,
        error: "",
      };
    case GET_WEIGHT_FAILURE:
      return {
        ...state,
        loading: false,
        weight: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default weightReducer;
