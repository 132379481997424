import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import UserRole from "../../components/UserRole/UserRole";

const UserRoleDashboard = () => {
  const history = useNavigate();

  const [permissions, setpermissions] = useState();
  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
  }, [permissions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userRole = () => {
    history("");
  };

  return (
    <div className="d-flex flex-column justify-content-start pt-2 px-4 bg-light gap-3 w-100">
      <div className="d-flex flex-column justify-content-start ">
        <div className="d-flex flex-row justify-content-between align-items-center mt-3 ">
          <div className="d-flex flex-row align-items-center gap-3">
            <span className="font-bold font-32 my-2">User roles</span>
          </div>
          {permissions?.user_role?.create ? (
            <div>
              <Button
                type="primary"
                text="+ Add new user role"
                onClick={() => userRole()}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="d-flex mb-3 pb-4 ">
        <UserRole />
      </div>
    </div>
  );
};

export default UserRoleDashboard;
