/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import Button from "../../../components/Button/Button";
import ModalComp from "../../../components/Models/ModalComp";
// import RedeemModel from "../Models/RedeemModel";
import RedeemModel from "../../../components/Models/RedeemModel";
import { useDispatch } from "react-redux";
import { activationGiftcardPost } from "../../../Redux/Epos/eposAction";
import { useTranslation } from "react-i18next";

const CustomerDetails = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { details, giftcardDetails } = props;

  const [fields, setFields] = useState({
    customerName: "",
    emailid: "",
    mobileNumber: "",
  });

  const [error, setError] = useState({
    customerName: "",
    emailid: "",
    mobileNumber: "",
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let regexMobileNo = /^\d{0,15}?$/;

    if (name === "customerName") {
      setError({ ...error, customerName: "" })
    } else if (name === "emailid") {
      setError({ ...error, emailid: "" })
    }
    else if (name === "mobileNumber") {
      setError({ ...error, mobileNumber: "" })
    }
    setFields({ ...fields, [e.target.name]: value });
  };

  const validate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regexMobileNo = /^\d{0,15}?$/;
    if (!fields.customerName) {
      setError({ customerName: t("Please enter customer") });
      return false;
    } else if (!fields.emailid || emailRegex.test(fields.emailid) === false) {
      setError({ emailid: t("Enter valid email") });
      return false;
    } else if (!fields.mobileNumber || regexMobileNo.test(fields.mobileNumber) === false) {
      setError({ mobileNumber: t("please enter valid phone number") });
      return false;
    }
    return true;
  };

  const submit = () => {
    let val = validate();
    console.log(details)
    if (val) {
      // setShowModal(true);
      let data = {
        cardNo: details,
        giftcardDetails: giftcardDetails,
        customerDetails: fields
      }
      dispatch(activationGiftcardPost(data));

    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 gap-3 w-100">
      <div className="d-flex flex-column justify-content-between gap-2">
        <div className="d-flex flex-column gap-3 mt-4 ">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Customer name")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="customerName"
                placeholder={t("Please enter name")}
                onChange={changeHandler}
              />
              {error.customerName ? (
                <label className="text-error">{error.customerName}</label>
              ) : null}
            </div>
          </div>


          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Email")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                name="emailid"
                placeholder={t("Please enter email")}
                onChange={changeHandler}
              />
              {error.emailid ? (
                <label className="text-error">{error.emailid}</label>
              ) : null}
            </div>
          </div>


          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Mobile no")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
                placeholder={t("Phone number")}
                type="text"
                name="mobileNumber"
                value={fields.phoneNumber}
                maxLength={15}
                onChange={changeHandler}
              />
              {error.mobileNumber ? (
                <label className="text-error">{error.mobileNumber}</label>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex gap-2">
        <Button onClick={() => submit()} text={t("Submit")} type="primary" />
      </div>

      <ModalComp
        size="md"
        showModal={showModal}
        handleClose={handleClose}
        component={
          <RedeemModel
            amount={fields.redemptionAmount}
            handleClose={handleClose}
          />
        }
      />
    </div>
  );
};

export default CustomerDetails;
