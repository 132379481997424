import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import WarningImg from "../../assests/img/warning.png";
import { fetchSupplierList } from "../../Redux/Supplier/supplierAction";

const ClientMapProdAlertModel = (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToNextMechandise = () => {
        dispatch(fetchSupplierList());
        history("/supplier-mapping-merchandise-product");
    }

    const goToNextGiftcard = () => {
        dispatch(fetchSupplierList());
        history("/supplier-mapping-giftcard-product");
    }

    const yesHandler = () => {
      history('/clients-mapping-dashboard')
    }

    return (

        <div className="d-flex flex-column bg-white px-5 w-100 my-5">
            <div className="d-flex flex-column justify-content-start align-items-center gap-4">
                <div>
                    <img
                        src={WarningImg}
                        alt=""
                    />
                </div>
                <span className="font-bold text-dark font-17">
                    No products mapped with this client, Do you want to map products.
                </span>
                <div className="d-flex flex-row gap-4">
                    <Button
                        text="Yes"
                        type={"primary"}
                        onClick={() => yesHandler()}
                    />
                    <Button
                        text="No"
                        type={"secondary"}
                        onClick={props.handleClose}
                    />
                </div>

            </div>
        </div >
    );
};

export default ClientMapProdAlertModel;

