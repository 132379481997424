import axios from "axios";
import {
  GET_CLIENT_PRODUCT_REQUEST,
  GET_CLIENT_PRODUCT_SUCCESS,
  GET_CLIENT_PRODUCT_FAILURE,
  PUT_CLIENT_PRODUCT_REQUEST,
  PUT_CLIENT_PRODUCT_SUCCESS,
  PUT_CLIENT_PRODUCT_FAILURE,
  GET_GIFTCARD_CLIENT_MAPPING_DATA_REQUEST,
  GET_GIFTCARD_CLIENT_MAPPING_DATA_SUCCESS,
  GET_GIFTCARD_CLIENT_MAPPING_DATA_FAILURE,
  GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID,
  PUT_GIFTCARD_CLIENT_MAPPING_REQUEST,
  PUT_GIFTCARD_CLIENT_MAPPING_SUCCESS,
  PUT_GIFTCARD_CLIENT_MAPPING_FAILURE,
  GET_SUPP_SUPPPRICE_CLIENT_GC_REQUEST,
  GET_SUPP_SUPPPRICE_CLIENT_GC_SUCCESS,
  GET_SUPP_SUPPPRICE_CLIENT_GC_FAILURE,
  GET_CLIENT_MAPPED_GIFTCARD_REQUEST,
  GET_CLIENT_MAPPED_GIFTCARD_SUCCESS,
  GET_CLIENT_MAPPED_GIFTCARD_FAILURE
} from "./ClientMappingType";
import { baseUrl, getToken } from "../../utils/prodURL";

export const getClientProductRequest = () => {
  return {
    type: GET_CLIENT_PRODUCT_REQUEST,
  };
};

export const getClientProductSuccess = (data) => {
  return {
    type: GET_CLIENT_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const getClientProductFailure = (error) => {
  return {
    type: GET_CLIENT_PRODUCT_FAILURE,
    payload: error,
  };
};
// to get merchandise variants list
export const getMerchandiseVariants = (data) => {
  return {
    type: GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID,
    payload: data,
  };
};
export const getClientProducts = (id) => {
  return (dispatch) => {
    dispatch(getClientProductRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.result.product_variants_flag === true) {
          dispatch(getMerchandisevariantsList(id));
        }

        dispatch(getClientProductSuccess(response.data));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getClientProductFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};
// to get variants based on merchandise id
const getMerchandisevariantsList = (id) => {
  return (dispatch) => {
    let config = {
      method: "get",
      url: `${baseUrl}/v1/merchandise/${id}/variants`,

      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getMerchandiseVariants(response.data.result));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
// get giftcard client mapping data

export const getGiftcardClientProductRequest = () => {
  return {
    type: GET_GIFTCARD_CLIENT_MAPPING_DATA_REQUEST,
  };
};

export const getGiftcardClientProductSuccess = (data) => {
  return {
    type: GET_GIFTCARD_CLIENT_MAPPING_DATA_SUCCESS,
    payload: data,
  };
};

export const getGiftcardClientProductFailure = (error) => {
  return {
    type: GET_GIFTCARD_CLIENT_MAPPING_DATA_FAILURE,
    payload: error,
  };
};

export const getGiftcardClientmappingData = (id) => {
  return (dispatch) => {
    dispatch(getGiftcardClientProductRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getGiftcardClientProductSuccess(response.data));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getGiftcardClientProductFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// to put client mapping data
export const putClientProductRequest = () => {
  return {
    type: PUT_CLIENT_PRODUCT_REQUEST,
  };
};

export const putClientProductSuccess = (data) => {
  return {
    type: PUT_CLIENT_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const putClientProductFailure = (error) => {
  return {
    type: PUT_CLIENT_PRODUCT_FAILURE,
    payload: error,
  };
};

export const putClientProducts = (type, clientObj) => {
  return (dispatch) => {
    dispatch(putClientProductRequest);


    const config = {
      method: "post",
      url: `${baseUrl}/v1/merchandise/client/mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        dispatch(putClientProductSuccess(response.data));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          putClientProductFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// to put Client giftcard mapping data
export const putClientGiftcardRequest = () => {
  return {
    type: PUT_GIFTCARD_CLIENT_MAPPING_REQUEST,
  };
};

export const putClientGiftcardSuccess = (data) => {
  return {
    type: PUT_GIFTCARD_CLIENT_MAPPING_SUCCESS,
    payload: data,
  };
};

export const putClientGiftcardFailure = (error) => {
  return {
    type: PUT_GIFTCARD_CLIENT_MAPPING_FAILURE,
    payload: error,
  };
};

export const putClientGiftcardMappingProducts = (clientGiftcardObj) => {
  return (dispatch) => {
    dispatch(putClientGiftcardRequest);

    let clientlist = [];
    clientGiftcardObj.map((item) => {
      let obj = {
        client_id: item.client.id,
        selling_price: item.sellingPrice,
        margin: 0,
      };
      clientlist.push(obj);
    });

    const payload = {
      giftcard_id: clientGiftcardObj[0].result.id,
      client_list: clientlist,
      active_flag: true,
      deleted_flag: false,
    };

    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcards/client/mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        //  dispatch(putClientGiftcardSuccess(response.data));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          putClientGiftcardFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};
// to get supplier and supplier price based on giftcard id and merchandise id

export const getSupplierPriceRequest = () => {
  return {
    type: GET_SUPP_SUPPPRICE_CLIENT_GC_REQUEST,
  };
};

export const getSupplierPriceSuccess = (data) => {
  return {
    type: GET_SUPP_SUPPPRICE_CLIENT_GC_SUCCESS,
    payload: data,
  };
};

export const getSupplierPriceFailure = (error) => {
  return {
    type: GET_SUPP_SUPPPRICE_CLIENT_GC_FAILURE,
    payload: error,
  };
};

export const getSupplierPriceClientMap = (id) => {
  return (dispatch) => {
    dispatch(getSupplierPriceRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/${id}/supplier_mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getSupplierPriceSuccess(response.data.result));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getSupplierPriceFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

export const clientMappedGiftcardListRequest = () => {
  return {
    type: GET_CLIENT_MAPPED_GIFTCARD_REQUEST
  }
}

export const clientMappedGiftcardListSuccess = (obj) => {
  return {
    type: GET_CLIENT_MAPPED_GIFTCARD_SUCCESS,
    payload: obj
  }
}

export const clientMappedGiftcardListFailure = (error) => {
  return {
    type: GET_CLIENT_MAPPED_GIFTCARD_FAILURE,
    payload: error
  }
}

export const GetClientMappedGiftcardList = (clientId) => {
  return (dispatch) => {
    dispatch(clientMappedGiftcardListRequest);


    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/client/${clientId}/mappedProducts`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        dispatch(getSupplierPriceSuccess(response.data.result));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getSupplierPriceFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });


  }
}