import { baseUrl, getToken } from "../../utils/prodURL";
import axios from "axios";
import {
  GET_EMAIL_TEMPLATE_FAILURE,
  GET_EMAIL_TEMPLATE_REQUEST,
  GET_EMAIL_TEMPLATE_SUCCESS,
  POST_EMAIL_TEMPLATE_REQUEST,
  POST_EMAIL_TEMPLATE_SUCCESS,
  POST_EMAIL_TEMPLATE_FAILURE
} from "./customeEmailType";

export const getEmailTemplateRequest = () => {
  return {
    type: GET_EMAIL_TEMPLATE_REQUEST,
  };
};

export const getEmailTemplateSuccess = (currencies) => {
  return {
    type: GET_EMAIL_TEMPLATE_SUCCESS,
    payload: currencies,
  };
};

export const getEmailTemplateFailure = (error) => {
  return {
    type: GET_EMAIL_TEMPLATE_FAILURE,
    payload: error,
  };
};

export const getEmailTemplate = (id) => {
  return (dispatch) => {
    dispatch(getEmailTemplateRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/attributes/email_template/${id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          // console.log("response" + JSON.stringify(response.data.result));

          dispatch(getEmailTemplateSuccess(response.data.result));
        }
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          getEmailTemplateFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};
// Post Email template Data
export const postEmailTemplateRequest = () => {
  return {
    type: POST_EMAIL_TEMPLATE_REQUEST,
  };
};

export const postEmailTemplateSuccess = (currencies) => {
  return {
    type: POST_EMAIL_TEMPLATE_SUCCESS,
    payload: currencies,
  };
};

export const postEmailTemplateFailure = (error) => {
  return {
    type: POST_EMAIL_TEMPLATE_FAILURE,
    payload: error,
  };
};
export const postEmailTemplate = (obj) => {
  
  return (dispatch) => {
    dispatch(postEmailTemplateRequest());
    let emialObj={
      success_title: obj?.success_title,
      success_heading: obj?.success_heading,
      success_message: obj?.success_message,
      scanner_code_type: "barcode",
      contact_info: obj?.contact_info,
      ehadaya_logo_flag: obj?.ehadaya_logo_flag,
      partner_id: obj?.partner_id,
      partner_logo: obj?.partner_logo
    }
    //  console.log(emialObj)
    let config = {
      method: "post",
      url: `${baseUrl}/v1/attributes/email_template/custom`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data:emialObj
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          console.log("response" + JSON.stringify(response.data.result));
          dispatch(postEmailTemplateSuccess(response.data.result));
        }
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          postEmailTemplateFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};