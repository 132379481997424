import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { ReactComponent as SuccessIcon } from "../../../assests/svg/Success.svg";
import { useTranslation } from "react-i18next";

const CardSuccessfull = () => {
  const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const history = useNavigate();

    const goToHome = () => {
      history("/epos/ehadaya-interface");
    };
  return (
    <div className="d-flex flex-column  align-item-center bg-light w-100 py-5">
      <div className="d-flex flex-row w-100 justify-content-center">
        <SuccessIcon />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-dark font-bold font-40">
          {t("Card sold successfuly")}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
      <span className="text-dark font-bold font-15 ">
              {t("Invoice ID")}: 23542385
          </span>
          </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"primary"} text={t("Download Invoice & Print")} />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"rounded"} text={t("Back to home")} onClick={() => goToHome()} />
      </div>
    </div>
  );
};
export default CardSuccessfull;
