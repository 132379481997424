import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../../assests/svg/Tag/tag.svg";
import { ReactComponent as DropDownIcon } from "../../../../assests/svg/DropDown.svg";

import Button from "../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../../components/Toasts/Toasts";
import { ReactComponent as LeftIcon } from "../../../../assests/svg/Products/left_lg.svg";
import applePhones from "../../../../assests/img/apple_colored.png";
import product_default from "../../../../assests/img/Product-default.png";
import { setSelectedGiftcardsList } from "../../../../Redux/MappingProducts/Supplier/Giftcards/giftcardSuppMappingAction";
import { getMerchandiseVariantList, getMerchandiseVariantListByCountryIds } from "../../../../Redux/MerchadiseAllProduct/MerchadiseAllProductAction";
import PulseLoader from "../../../../components/Loader/PulseLoader";
import { fetchCountrieDetailsByIds } from "../../../../Redux/Countries/countryAction";
import { ReactComponent as Searchicon } from "../../../../assests/svg/search.svg";


const OrganizationMerchandiseList = () => {

    const history = useNavigate();
    const dispatch = useDispatch();
  
    //const productsState = useSelector((state) => state?.productTypes?.products);
    const productsAllState = useSelector(
      (state) => state?.merchadiseAllProduct?.merchandiseList
    );

    const loading = useSelector((state) => state?.merchadiseAllProduct?.loading);
  
    const selectedSupplier = useSelector(
      (state) => state?.giftcardSuppMappingReducer?.selectedSupplier
    );
    const selectedOrganization = useSelector(
        (state) => state?.organizations?.selectedOrganization
      );


    // const ClientCountriesList = useSelector((state) => state?.countries?.countrieDetailsList);
  
    //const suppliersMappingDataState = useSelector(    (state) => state?.SupplierMappingData?.supplierMappingData?.result  );
  
    const [visible, setVisible] = useState(10);
  
    const [showToast, setShowToast] = useState(false);
    const toggleShowToast = () => setShowToast(!showToast);
  
    const [products, setProducts] = useState([]);
    // for search bar
    const [searchTerm, setSearchTerm] = useState("");
    const searchHandler = (e) => {
      setSearchTerm(e.target.value);
    };

    useEffect(() => {
      dispatch(getMerchandiseVariantList());
    }, [])
  
    useEffect(() => {
      window.scrollTo(0, 0);
  
      // if (!selectedOrganization) {
      //   history("/organization-mapping-dashboard");
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganization]);
  
    useEffect(() => {
      window.scrollTo(0, 0);
      let arr = productsAllState;
  
      let prodArr = [];
  
      arr &&
        arr.map((item, i) => {
          let obj = {
            product: item,
            collapseFlag: false,
          };
          prodArr.push(obj);
        });
  
      setProducts(prodArr);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsAllState]);
  
    const loadMore = () => {
      setVisible((prevVisible) => prevVisible + 10);
    };
  
    const allCheckedHandle = (e) => {
      const { checked } = e.target;
      let arr = [...products];
      // eslint-disable-next-line array-callback-return
      arr &&
        arr.map((item, i) => {
          arr[i].isChecked = checked;
          if (item.product.variants && item.product.variants.length > 0) {
            let variantsArr = [...item.product.variants];
            // eslint-disable-next-line array-callback-return
            variantsArr &&
              variantsArr.map((val, j) => {
                variantsArr[j].isChecked = checked;
              });
          }
        });
      setProducts(arr);
    };
  
    const handleCheck = (e) => {
      const { name, checked } = e.target;
      let arr = [...products];
  
      // eslint-disable-next-line array-callback-return
      arr &&
        arr.map((item, i) => {
          if (i === parseInt(name)) {
            arr[i].isChecked = checked;
            if (item.product.variants && item.product.variants.length > 0) {
              //  arr[i].collapseFlag = !item.collapseFlag;
              let variantsArr = [...item.product.variants];
              // eslint-disable-next-line array-callback-return
              variantsArr &&
                variantsArr.map((val, j) => {
                  variantsArr[j].isChecked = checked;
                });
            }
          }
        });
      setProducts(arr);
    };
  
    const handleChildCheck = (e) => {
      const { name, checked, id } = e.target;
      let vatiadtIndex = parseInt(id);
      let arr = [...products];
      // eslint-disable-next-line array-callback-return
      arr &&
        arr.map((item, i) => {
          if (i === parseInt(name)) {
            arr[i].isChecked = true;
            if (item.product.variants && item.product.variants.length > 0) {
              // arr[i].collapseFlag = !item.collapseFlag
              let variantsArr = [...item.product.variants];
  
              // eslint-disable-next-line array-callback-return
              variantsArr &&
                variantsArr.map((val, j) => {
                  if (j === vatiadtIndex) {
                    variantsArr[j].isChecked = checked;
                  }
                });
            }
          }
        });
      setProducts(arr);
    };
  
    const goBack = () => {
      history("/organization-mapping-dashboard");
    };
  
    const setProductCollapse = (index) => {
      let arr = [...products];
  
      // eslint-disable-next-line array-callback-return
      arr.map((item, i) => {
        if (index === i) {
          arr[i].collapseFlag = !item.collapseFlag;
        }
      });
      setProducts(arr);
    };
  
    const submit = () => {
      let arr = [...products];
      let selectedProducts = [];
      let selectedProductsVariants = [];
  
      // eslint-disable-next-line array-callback-return
      arr.map((item) => {
        if (item.isChecked === true) {
          if (item.product.variants && item.product.variants.length > 0) {
            let variantsArr = [...item.product.variants];
            // eslint-disable-next-line array-callback-return
            variantsArr &&
              variantsArr.map((val, j) => {
                if (val.isChecked === true) {
                  let obj = {
                    product: item.product,
                    productId: item.product.id,
                    product_title: item.product.product_title,
                    variantId: val.id,
                    variantKeys: val.variant_keys,
                    variantValues: val.variant_values,
                  };
                  selectedProductsVariants.push(obj);
                }
              });
          } else {
            let obj = {
              product: item.product,
              productId: item.product.id,
              product_title: item.product.product_title,
              // variantId: val.id,
              // variantKeys: val.variant_keys,
              // variantValues: val.variant_values
            };
            selectedProducts.push(obj);
          }
        }
      });
      let arrProducts = [...selectedProducts, ...selectedProductsVariants];

      if (arrProducts.length > 0) {
        dispatch(setSelectedGiftcardsList(arrProducts));
        history("/organization-mapping-merchandise-sku");
      } else {
        toggleShowToast();
      }
    };


    return loading === true ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-between gap-3 py-4 pb-3 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">Organization Mapping</span>
        </div>
      </div>

      {/* <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100  ">
        <div className="card-body d-flex justify-content-between flex-row">
          <div className="d-flex gap-3 my-2">
            <Tag />
            <span className="font-20 font-bold">
              Selected Supplier: {selectedSupplier?.partner_name}
            </span>
          </div>
          <div>
                  <BackButton onClick={() => history("/supplier-mapping-dashboard")} />
                </div>
        </div>

        <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
          <table responsive="xl">
            <tbody>
              <tr className="col gap-2 ">
                <td className="col">
                  <div>
                    <span className="font-16 font-bold">Country</span>
                  </div>
                </td>
                <td className="col">
                  <div>
                    <span className="font-16 font-bold">Currencies</span>
                  </div>
                </td>
              </tr>
              {ClientCountriesList && ClientCountriesList.map((item, i) => (
                <tr className="col gap-2" key={i}>
                  <td className="col">{item.name + " (" + item?.iso3 + ")"}</td>
                  <td className="col">{item.currency + " - " + item?.currency_symbol}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}

      <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100 gap-3">
        <div className="card-body d-flex justify-content-between flex-row">
          <div className="d-flex gap-3 my-2">
            <Tag />
            <span className="font-20 font-bold">Mapping merchandise</span>
          </div>
          <div className="Inputwithicon">
          <div id="search">
            <Searchicon />
          </div>
          <input
            type="text"
            className="input-custom font-bold text-muted w-95"
            name="search"
            placeholder="Search by name"
            onChange={searchHandler}
          />
        </div>
          {/* <div>
            <BackButton onClick={() => history(-1)} />
          </div> */}
        </div>
        <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
          <table responsive="xl">
            <tbody>
              <tr className="col border-bottom">
                <td className="col-3">
                  <div className="d-flex flex-row justify-content-start text-dark gap-3">
                    <span>
                      <input
                        className="form-check-input cursor"
                        type="checkbox"
                        id="checkboxNoLabel"
                        checked={
                          products.filter(
                            (product) => product?.isChecked !== true
                          ).length < 1
                        }
                        onChange={allCheckedHandle}
                      />
                    </span>
                    <span className="font-16 font-boldest text-muted">
                      Name
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-16 font-boldest text-muted">
                      Variants
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-16 font-boldest text-muted">
                    Organization name
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-16 font-boldest text-muted">
                      Currency
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-16 font-boldest text-muted">
                      Status
                    </span>
                  </div>
                </td>
              </tr>
              {/* {products && products.map((item, index) => ( */}
              {products &&
                products?.filter((item) => {
                  if (searchTerm == "") {
                    return item;
                  } else if (
                    item?.product?.default_title
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return item;
                  }
                }).map((item, index) => (
                  <tr key={index} className="gap-2 border-bottom">
                    <td className="col-4 align-top">
                      <div className="d-flex flex-row justify-content-start align-items-center py-3 gap-3">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="checkboxNoLabel"
                          checked={item?.isChecked}
                          name={index}
                          onChange={handleCheck}
                        />
                        <img
                          src={
                            item?.product?.images &&
                              item?.product?.images[0]?.file_url
                              ? item?.product?.images[0]?.file_url
                              : product_default
                          }
                          alt="not found"
                          className="img-fluid"
                          width="50px"
                          height="50px"
                        />
                        <div className="d-flex flex-column align-items-start ">
                          <span
                            className="font-bold text-dark font-15 d-inline-block text-truncate cursor"
                            title={item?.product?.default_title}
                            style={{ maxWidth: "100px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {item?.product?.default_title}
                          </span>
                          <span
                            className="font-12 text-muted d-inline-block text-truncate cursor"
                            title={item?.product?.id}
                            style={{ maxWidth: "100px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {item?.product?.id}
                          </span>
                        </div>
                      </div>
                     
                      {item?.collapseFlag && (
                          <div id="example-collapse-text">
                            <div className="d-flex bg-white shadow rounded-3">
                              <div className="d-flex flex-column w-100 gap-3">
                                <div className="d-flex flex-row justify-content-center border-bottom">
                                  <div className="d-flex align-items-center gap-2">
                                    <span className="font-14 font-bolder py-2 ">
                                      Variants List
                                    </span>
                                  </div>
                                </div>

                                <div className="d-flex flex-column justify-content-center align-items-center w-100 gap-3">
                               
                                    {item?.product?.product_variants_flag &&
                                item?.product?.variants &&
                                item?.product?.variants.map((variant, i) => (
                                  <td
                                    key={i}
                                    className="d-flex flex-row justify-content-start align-items-center pb-3 gap-2"
                                  >
                                    <input
                                      className="form-check-input cursor"
                                      type="checkbox"
                                      id={i}
                                      name={index}
                                      checked={variant?.isChecked}
                                      onChange={handleChildCheck}
                                    />
                                    <div className="d-flex flex-column w-100 gap-2">
                                      <span>
                                        {item?.product_title &&
                                          item?.product_title.default_title}
                                      </span>
                                      <span>
                                        {variant?.variant_values.join("|")}
                                      </span>
                                    </div>
                                  </td>
                                ))}
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        )}
                    </td>
                    <td className="col-2 align-top pt-4">
                      <div className="d-flex flex-row">
                        <span className="font-13 font-bolder text-dark bg-04 rounded-3 px-1 ">
                          {item?.product?.variants
                            ? item?.product?.variants.length
                            : "No variants"}
                        </span>
                      </div>
                    </td>
                    <td className="col-2 align-top  pt-4 ">
                      <span
                        className="text-dark font-15 mx-4 d-inline-block text-truncate cursor"
                        title={selectedOrganization?.default_title}
                        style={{ maxWidth: "100px" }}
                        data-toggle="tooltip"
                        data-placement="top"
                      >
                        {selectedOrganization?.default_title}
                      </span>
                    </td>
                    <td className="col-2 align-top  pt-4">
                      <div className="d-flex">
                        <span className="text-dark font-15 mx-2">
                          {`${item?.product?.country?.currency} (${item?.product?.country?.currency_symbol})`}
                        </span>
                      </div>
                    </td>
                    <td className="col-2 align-top  pt-4">
                      <span className="text-dark font-15">
                        {item?.product?.active_flag ? (
                          <span className="font-15 font-bolder text-dark bg-04 rounded-3 px-1">
                            Active
                          </span>
                        ) : (
                          <span className="font-15 font-bolder text-dark bg-danger bg-opacity-25 rounded-3 px-1">
                            Deactive
                          </span>
                        )}
                      </span>
                    </td>

                    <td className="col-2 align-top  pt-4">
                      {item?.product?.variants &&
                        item?.product?.product_variants_flag && (
                          <div
                            onClick={() => setProductCollapse(index)}
                            className={`cursor p-0 ${item?.collapseFlag ? "rotate-180" : ""
                              }`}
                            aria-controls="example-collapse-text"
                            aria-expanded={item.collapseFlag}
                          >
                            <DropDownIcon
                              className={`cursor p-0 ${item.collapseFlag ? "rotate-180" : ""
                                }`}
                            />
                          </div>
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex">
        <Button type="primary" text="Save and Next" onClick={() => submit()} />
      </div>
      <div
        className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Please select giftcards/products to map."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default OrganizationMerchandiseList;