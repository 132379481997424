import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import MainContentArea from "../components/MainContentArea/MainContentArea";
import Sidenav from "../components/SideNav/Sidenav";

const Layout = (props) => {
  const toggleValue = useSelector(
    (state) => state.sideNavToggleReducer.sideNavToggle
  );

  let outLetClass = toggleValue ? "outletDiv active" : "outletDiv"
  let outLetClassAR = toggleValue ? "outletDiv-ar active" : "outletDiv-ar"

  return (
    <div className="mainContainer">
      <div className="row m-0">
        <div className="col-3 p-0">
          <Sidenav lang={props?.lang} logout={props.logout} />
        </div>
        <div className="col-9 p-0 bg-light">
          <div className="d-flex flex-column bg-white">
            <Header lang={props?.lang}/>
            <div className={`${props?.lang === "ar" ? outLetClassAR : outLetClass} d-flex flex-column`}>
              {/* <MainContentArea lang={props?.lang}> */}
                <Outlet />
              {/* </MainContentArea> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
