import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { ReactComponent as Tag } from "../../assests/svg/Tag/orange_lite_tag.svg";
import { ReactComponent as OrangeTag } from "../../assests/svg/Tag/orange_tag.svg";
import { ReactComponent as Tagpurple } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Righttick } from "../../assests/svg/Righttick.svg";
import { ReactComponent as DarkGreen } from "../../assests/svg/Tag/dark_green.svg";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { putMerchandise, putMerchandiseVarientsProduct } from "../../Redux/Merchandise/merchandiesAction";
import Loader from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";


const MerchandiseModelPreview = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();

  const merchandiseData = useSelector((state) => state?.merchandiseCatAttriData);
  const merchandiseVarientState = useSelector((state) => state?.merchandiseVarient);
  const merchandiseDescription = useSelector((state) => state?.merchandiseCatAttriData?.merchandiseNameDes?.description);
  const merchandiseVarientImg = useSelector((state) => state?.merchandiseVarient?.merchandiseVarientImg);
  const merchandiseVarientObjState = useSelector((state) => state?.merchandiseVarient?.merchandiseVarientObj);

  const [langView, setLangView] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    let lang = merchandiseDescription && merchandiseDescription[0]?.lang?.language;
    setLangView({ ...langView, lang });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToProductEditScreen = () => {
    history("/add-merchandise");
  };
  const goToMerchandiseSuccessScr = () => {
    history("/merchandise-successfull");
  };

  const handleChangeValue = (lang) => {
    setLangView({ ...langView, lang });
  };

  const publishMerchandise = () => {
    merchandiseData?.merchandiseCatAtt?.hasVariantFlag === false ?
      dispatch(putMerchandise(merchandiseData, goToMerchandiseSuccessScr)) :
      dispatch(putMerchandiseVarientsProduct(merchandiseData, merchandiseVarientState, merchandiseVarientImg, goToMerchandiseSuccessScr))
  };

  const descriptionFun = () => {
    const description = merchandiseData?.merchandiseNameDes?.description;

    return (
      <div>
        {description &&
          description.map((item, i) => (
            <div key={i}>
              {langView?.lang === item.lang.language ? (
                <div className="border-bottom pt-4">
                  {item.value ? (
                    <span className="font-15 font-bold text-muted">
                      {t("Terms & Conditions in")} {item.lang.language}
                    </span>
                  ) : null}
                  <Editor
                    readOnly={true}
                    toolbar={{
                      options: [],
                    }}
                    editorState={EditorState.createWithContent(
                      item.value.getCurrentContent()
                    )}
                  />
                </div>
              ) : (
                <div>
                  {langView?.lang === item.lang.language ? (
                    <span className="font-15  font-bold text-muted pt-4">
                      {t("Terms & Conditions not avilable in")} {langView?.lang}
                    </span>
                  ) : null}
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };

  const termConditionFun = () => {
    let termCondition = null
    merchandiseData?.merchandiseCatAtt?.hasVariantFlag === false ?
      termCondition = merchandiseData?.merchandiseTandC?.termCondition :
      termCondition = merchandiseVarientState.merchandiseVarientTermImg.termAndconditions;

    return (
      <div>
        {termCondition &&
          termCondition?.map((item, i) => (
            <div key={i}>
              {/* {langView?.lang + item?.lang?.language} */}
              {langView?.lang === item?.lang?.language ? (
                <div className="border-bottom pt-4">
                  {item.value ? (
                    <span className="font-15 font-bold text-muted">
                      {t("Terms & Conditions in")} {item?.lang?.language}
                    </span>
                  ) : null}
                  <Editor
                    readOnly={true}
                    toolbar={{
                      options: [],
                    }}
                    editorState={EditorState.createWithContent(
                      item.value.getCurrentContent()
                    )}
                  />
                </div>
              ) : (
                <div>
                  {/* {langView?.lang !== item?.lang?.language ?
                  <span className="font-15  font-bold text-muted pt-4">
                    Terms & Conditions not avilable in {langView?.lang}
                  </span> : null} */}
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };



  return (
    merchandiseData.loading ?
      <div className="position-fixed top-50 start-50">
        <Loader loading={merchandiseData.loading} />
      </div>
      :
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-3">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <span>
              <Button
                text={t("Close")}
                type={"secondary"}
                onClick={props.handleClose}
              />
            </span>
          </div>
          <div className="d-flex gap-3">
            <div className="btn-group">
              <div
                className="btn border border-2 model-drop-width dropdown-toggle d-flex justify-content-between align-items-center py-2"
                data-bs-toggle="dropdown"
              >
                {langView?.lang ? langView?.lang : "Select"}
                <ul className={`dropdown-menu rounded-card-7 dropdown-menu-start ${props?.lang === 'ar' ? 'dropdown-menu-ar' : ''}`}>
                  {merchandiseDescription &&
                    merchandiseDescription.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => handleChangeValue(item.lang.language)}
                      >
                        <div className="dropdown-item cursor">
                          {item.lang.language}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            {!merchandiseData.loading &&
              <span className="d-flex gap-3 ">
                <Button
                  text={t("Confirm & Publish")}
                  type={"primary"}
                  onClick={() => publishMerchandise()}
                />
              </span>}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center gap-3  pt-5">
          <div className="d-flex gap-4">
            <div className="d-flex flex-column gap-1">
              <div className="font-32 font-bold">
                {merchandiseData?.merchandiseNameDes?.prodTitles[0]?.title}
              </div>
              {/*  <div className="neutral-grey-04 font-20">
              Card description example
            </div>*/}
              {/* <div className="neutral-grey-04 font-20">Expires in 14 days</div>*/}
            </div>
          </div>

          <div className="d-flex ">
            <Button
              text={t("Edit Product")}
              type={"border"}
              onClick={() => goToProductEditScreen()}
            />
          </div>
        </div>
        <div className="d-flex flex-row py-4 gap-3">
          <div className="d-flex flex-column width-80">
            <span>
              {(merchandiseData?.merchandiseTandC?.productImage && !merchandiseData?.merchandiseCatAtt?.hasVariantFlag) ?
                <img
                  src={merchandiseData?.merchandiseTandC?.productImage[0]?.preview}
                  // key={merchandiseData?.merchandiseTandC?.productImage[0]?.preview}
                  alt="not found"
                  className="img-fluid"
                  width={'500px'}
                />
                :
                (!merchandiseVarientObjState[0]?.requireImages && merchandiseData?.merchandiseCatAtt?.hasVariantFlag && merchandiseVarientState?.merchandiseVarientTermImg?.productImg.length > 0) ?
                  < img
                    src={merchandiseVarientState?.merchandiseVarientTermImg?.productImg[0].preview}
                    //   key={merchandiseVarientImg[0]?.images[0]?.preview}
                    alt="not found"
                    className="img-fluid"
                    width={'500px'}
                  />
                  :
                  (merchandiseVarientObjState[0]?.requireImages && merchandiseData?.merchandiseCatAtt?.hasVariantFlag && merchandiseVarientImg[0]?.images.length > 0) ?
                    <img
                      src={merchandiseVarientImg[0]?.images[0]?.preview}
                      //   key={merchandiseVarientImg[0]?.images[0]?.preview}
                      alt="not found"
                      className="img-fluid"
                      width={'500px'}
                    />
                    :
                    <img
                      src={merchandiseVarientImg[0]?.images[0]?.preview}
                      //   key={merchandiseVarientImg[0]?.images[0]?.preview}
                      alt="not found"
                      className="img-fluid"
                      width={'500px'}
                    />}
            </span>
          </div>

          {(merchandiseData?.merchandiseTandC?.productImage && !merchandiseData?.merchandiseCatAtt?.hasVariantFlag) ?
            <div>
              {merchandiseData?.merchandiseTandC?.productImage &&
                merchandiseData?.merchandiseTandC?.productImage.map((item, i) => (
                  <div className="d-flex flex-row " key={i}>
                    <span>
                      <img
                        src={item.preview}
                        key={i}
                        alt="not found"
                        className="img-fluid"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </span>
                  </div>
                ))}
            </div>
            :
            (!merchandiseVarientObjState[0]?.requireImages && merchandiseData?.merchandiseCatAtt?.hasVariantFlag && merchandiseVarientState?.merchandiseVarientTermImg?.productImg.length > 0) ?
              <div>
                {merchandiseVarientState?.merchandiseVarientTermImg?.productImg &&
                  merchandiseVarientState?.merchandiseVarientTermImg?.productImg.map((item, i) => (
                    <div className="d-flex flex-row " key={i}>
                      <span>
                        <img
                          src={item.preview}
                          key={i}
                          alt="not found"
                          className="img-fluid"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </span>
                    </div>
                  ))}
              </div>
              :
              (merchandiseVarientObjState[0]?.requireImages && merchandiseData?.merchandiseCatAtt?.hasVariantFlag && merchandiseVarientImg[0]?.images.length > 0) ?
                <div>
                  {merchandiseVarientImg[0]?.images &&
                    merchandiseVarientImg[0]?.images.map((item, i) => (
                      <div className="d-flex flex-row " key={i}>
                        <span>
                          <img
                            src={item.preview}
                            key={i}
                            alt="not found"
                            className="img-fluid"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </span>
                      </div>
                    ))}
                </div>
                :
                <img
                  src={merchandiseVarientImg[0]?.images[0]?.preview}
                  //   key={merchandiseVarientImg[0]?.images[0]?.preview}
                  alt="not found"
                  className="img-fluid"
                  width={'500px'}
                />}

        </div>

        <div className="d-flex flex-column gap-4 ">
          <div className="row">
            {merchandiseData?.merchandiseCatAtt?.hasVariantFlag === true ?
              <div>
                <div className="col">
                  {merchandiseVarientObjState && merchandiseVarientObjState.map((item, index) => (
                    <div className="d-flex  flex-column w-100 my-2" key={index}>
                      <div >
                        <div className="d-flex font-bolder" >{item.optionTxt}</div>
                        <div className="d-flex flex-wrap flex-row my-2 gap-2 mx-2">
                          {item.tags.map((tag, i) =>
                            <div className="d-flex gap-3" key={i}>
                              <Button text={tag} type={"rounded"} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                  ))}


                </div>
              </div> : null}

            <div className="d-flex flex-column width-30">
              {merchandiseData?.merchandiseNameDes?.keyBenefits?.one?.name ? (
                <div className="p-2 flex-fill bd-highlight">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex gap-3">
                      <Tagpurple />
                      <span className="font-20 font-bold neutral-grey-07">
                        {t("Features")}
                      </span>
                    </div>
                    <div></div>
                  </div>
                  <div className="d-flex flex-column mt-3 w-100 gap-3 neutral-grey-05 font-15 w-100">
                    <ul className="list-group list-group-flush ">
                      {merchandiseData?.merchandiseNameDes?.keyBenefits?.one
                        ?.name ? (
                        <li className="list-group-item font-bolder">
                          <Righttick />
                          <span className="m-2">
                            {
                              merchandiseData?.merchandiseNameDes?.keyBenefits
                                ?.one?.name
                            }
                          </span>
                        </li>
                      ) : null}
                      {merchandiseData?.merchandiseNameDes?.keyBenefits?.two
                        ?.name ? (
                        <li className="list-group-item font-bolder">
                          <Righttick />
                          <span className="m-2">
                            {
                              merchandiseData?.merchandiseNameDes?.keyBenefits
                                ?.two?.name
                            }
                          </span>
                        </li>
                      ) : null}
                      {merchandiseData?.merchandiseNameDes?.keyBenefits?.three
                        ?.name ? (
                        <li className="list-group-item font-bolder">
                          <Righttick />
                          <span className="m-2">
                            {
                              merchandiseData?.merchandiseNameDes?.keyBenefits
                                ?.three?.name
                            }
                          </span>
                        </li>
                      ) : null}
                      {merchandiseData?.merchandiseNameDes?.keyBenefits?.four
                        ?.name ? (
                        <li className="list-group-item font-bolder">
                          <Righttick />
                          <span className="m-2">
                            {
                              merchandiseData?.merchandiseNameDes?.keyBenefits
                                ?.four?.name
                            }
                          </span>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="border-bottom pb-10"></div>

          <div className="row ">
            <div className="col">
              <div className="p-3 flex-fill bd-highlight width-100 ">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex gap-3">
                    <DarkGreen />
                    <span className="font-20 font-bold neutral-grey-07">
                      {t("Product details")}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-row mt-3 w-100 gap-2 ">
                  <div className="d-flex flex-column w-100 gap-2 flex-wrap ">
                    {merchandiseData?.merchandiseCatAtt?.countryCurrency.name ? (
                      <div>
                        <span className="font-17 font-bolder">Country: </span>
                        <span className="font-17 font-bolder text-muted">
                          {
                            merchandiseData?.merchandiseCatAtt?.countryCurrency
                              .name
                          }
                        </span>
                      </div>
                    ) : null}

                    {merchandiseData?.merchandiseCatAtt?.countryCurrency.name ? (
                      <div>
                        <span className="font-17 font-bolder">Currency: </span>
                        <span className="font-17 font-bolder text-muted">
                          {
                            merchandiseData?.merchandiseCatAtt?.countryCurrency
                              .currency
                          }
                        </span>
                      </div>
                    ) : null}
                    {merchandiseData?.merchandiseCatAtt?.state?.name ? (
                      <div>
                        <span className="font-17 font-bolder">State: </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.state?.name}
                        </span>
                      </div>
                    ) : null}

                    {merchandiseData?.merchandiseCatAtt?.selectBrand
                      ?.default_name ? (
                      <div>
                        <span className="font-17 font-bolder">Brand: </span>
                        <span className="font-17 font-bolder text-muted">
                          {
                            merchandiseData?.merchandiseCatAtt?.selectBrand
                              ?.default_name
                          }
                        </span>
                      </div>
                    ) : null}
                    {merchandiseData?.merchandiseCatAtt?.brandCategory?.default_category_name ? (
                      <div>
                        <span className="font-17 font-bolder">
                          {t("Brand category")}:{" "}
                        </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.brandCategory?.default_category_name}
                        </span>
                      </div>
                    ) : null}

                    {merchandiseData?.merchandiseCatAtt?.weightInput ? (
                      <div>
                        <span className="font-17 font-bolder">Weight: </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.weightInput}
                        </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.weight?.weight}
                        </span>
                      </div>
                    ) : null}

                    {merchandiseData?.merchandiseCatAtt?.shippingCostInput ? (
                      <div>
                        <span className="font-17 font-bolder">
                          {t("Shipping cost")}:{" "}
                        </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.shippingCost}
                        </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.shippingCostInput}
                        </span>
                      </div>
                    ) : null}
                    {merchandiseData?.merchandiseCatAtt?.saleTax?.name ? (
                      <div>
                        <span className="font-17 font-bolder">Sales tax: </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.saleTax?.name}
                        </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.saleTaxInput}
                        </span>
                      </div>
                    ) : null}

                    {merchandiseData?.merchandiseCatAtt?.vat?.name ? (
                      <div>
                        <span className="font-17 font-bolder">{t("VAT")}: </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.vat?.name}
                        </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.vatInput}
                        </span>
                      </div>
                    ) : null}
                    {merchandiseData?.merchandiseCatAtt?.warranty?.name ? (
                      <div>
                        <span className="font-17 font-bolder">{t("Warranty")}: </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.warrantyInput}
                        </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt?.warranty?.name}
                        </span>
                      </div>
                    ) : null}

                    {merchandiseData?.merchandiseCatAtt?.promotionItemFlag ? (
                      <div>
                        <span className="font-17 font-bolder">
                          {t("Promotional item")}:{" "}
                        </span>
                        <span className="font-17 font-bolder text-muted">
                          {merchandiseData?.merchandiseCatAtt
                            ?.promotionItemFlag === true
                            ? "true"
                            : "false"}
                        </span>
                      </div>
                    ) : null}

                    {merchandiseData?.merchandiseCatAtt?.brandTags.length > 0 ? (
                      <div className="d-flex gap-2">
                        <span className="font-17 font-bolder ">{t("Brand tags")}: </span>
                        {merchandiseData?.merchandiseCatAtt?.brandTags.map((item, i) => (
                          <span key={i} className="font-17 font-bolder bg-04 bg-opacity-25 mx-1 px-1  rounded-3" >
                            {item}
                          </span>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="p-2 flex-fill bd-highlight width-70">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex gap-3">
                    <Tag />
                    <span className="font-20 font-bold neutral-grey-07">
                      {t("Description")}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-row mt-3 w-100">
                  {descriptionFun()}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="p-2 flex-fill bd-highlight width-70">
                <div className="d-flex flex-row justify-content-between ">
                  <div className="d-flex gap-3">
                    <OrangeTag />
                    <span className="font-20 font-bold neutral-grey-07">
                      {t("Terms & Conditions")}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-row mt-3 w-100 neutral-grey-05 font-15">
                  {termConditionFun()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center mt-4">
          {!merchandiseData.loading &&
            <span className="d-flex gap-3 ">
              <Button
                text={t("Confirm & Publish")}
                type={"primary"}
                onClick={() => publishMerchandise()}
              />
            </span>}
        </div>
      </div>
  );
};

export default MerchandiseModelPreview;
