import {
  FETCH_BRAND_FAILURE,
  FETCH_BRAND_REQUEST,
  FETCH_BRAND_SUCCESS,
  GET_BRAND_TYPE_REQUEST,
  GET_BRAND_TYPE_SUCCESS,
  GET_BRAND_TYPE_FAILURE,
  PUT_CREATE_BRAND_REQUEST,
  PUT_CREATE_BRAND_SUCCESS,
  PUT_CREATE_BRAND_FAILURE,
  PATCH_BRAND_REQUEST,
  PATCH_BRAND_SUCCESS,
  PATCH_BRAND_FAILURE,
  BRAND_FILTER_DATA,
  CLEAR_BRAND_LIST
} from "./brandType";

const initialState = {
  brands: [],
  loading: false,
  error: "",
  errorMsg: "",
  prodNotFound: false,
  brandType: [],
  crateBrandResponse: null,
  next_iteration: true,
  brandFilter: []
};

const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    // set brand filter data
    case BRAND_FILTER_DATA:
      return {
        ...state,
        brandFilter: action.payload,
      };
    // get brands
    case GET_BRAND_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        prodNotFound: false,
        brandType: [],
        errorMsg: "",
      };
    case GET_BRAND_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        prodNotFound: false,
        brandType: action.payload,
        errorMsg: "",
      };
    case GET_BRAND_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        prodNotFound: false,
        brandType: [],
        errorMsg: action.payload,
      };

    case CLEAR_BRAND_LIST:
      return {
        ...state,
        loading: false,
        prodNotFound: false,
        brands: [],
        next_iteration: true,
        errorMsg: "",
      };

    // fetch brands
    case FETCH_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
        prodNotFound: false,
        errorMsg: "",
      };
    case FETCH_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        brands: action.payload.data,
        next_iteration: action.payload.moredata,
        prodNotFound: false,
        errorMsg: "",
      };
    case FETCH_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        prodNotFound: true,
        errorMsg: action.payload,
      };

    //put brands responese
    case PUT_CREATE_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
        crateBrandResponse: null,
        error: "",
      };
    case PUT_CREATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        crateBrandResponse: action.payload,
      };
    case PUT_CREATE_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        crateBrandResponse: null,
        error: action.payload,
      };

    case PATCH_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
        crateBrandResponse: null
      };
    case PATCH_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        crateBrandResponse: action.payload

      };
    case PATCH_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return state;
  }
};

export default brandReducer;
