export const FETCH_BRAND_REQUEST = "FETCH_BRAND_REQUEST";
export const FETCH_BRAND_SUCCESS = "FETCH_BRAND_SUCCESS";
export const FETCH_BRAND_FAILURE = "FETCH_BRAND_FAILURE";

export const GET_BRAND_REQUEST = "GET_BRAND_REQUEST";
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_BRAND_FAILURE = "GET_BRAND_FAILURE";

// TO BIND BRAND TYPE DD FOR ADD BRAND PAGE
export const GET_BRAND_TYPE_REQUEST = "GET_BRAND_TYPE_REQUEST";
export const GET_BRAND_TYPE_SUCCESS = "GET_BRAND_TYPE_SUCCESS";
export const GET_BRAND_TYPE_FAILURE = "GET_BRAND_TYPE_FAILURE";
// TO PUT DATA INTO SERVER FOR ADD BRAND PAGE
export const PUT_CREATE_BRAND_REQUEST = "PUT_CREATE_BRAND_REQUEST";
export const PUT_CREATE_BRAND_SUCCESS = "PUT_CREATE_BRAND_SUCCESS";
export const PUT_CREATE_BRAND_FAILURE = "PUT_CREATE_BRAND_FAILURE";

export const PATCH_BRAND_REQUEST = "PATCH_BRAND_REQUEST";
export const PATCH_BRAND_SUCCESS = "PATCH_BRAND_SUCCESS";
export const PATCH_BRAND_FAILURE = "PATCH_BRAND_FAILURE";
// FOR SORT FILTER
export const BRAND_FILTER_DATA = "BRAND_FILTER_DATA"
export const CLEAR_BRAND_LIST = "CLEAR_BRAND_LIST"
