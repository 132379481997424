import {
    GET_SALES_TAX_REQUEST,
    GET_SALES_TAX_SUCCESS,
    GET_SALES_TAX_FAILURE
  } from "./salestaxType";
  
  import { baseUrl, getToken } from "../../utils/prodURL";
  import axios from "axios";
  
  export const getSalesTaxRequest = () => {
    return {
      type: GET_SALES_TAX_REQUEST,
    };
  };
  export const getSalesTaxSuccess = (saletax) => {
    return {
      type: GET_SALES_TAX_SUCCESS,
      payload: saletax,
    };
  };
  export const getSalesTaxFailure = (error) => {
    return {
      type: GET_SALES_TAX_FAILURE,
      payload: error,
    };
  };
  
  export const getSalestax = () => {
    return (dispatch) => {
      dispatch(getSalesTaxRequest());
      let config = {
        method: "get",
        url: `${baseUrl}/sales`,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      };
      axios(config)
        .then((response) => {
         // console.log("response" + JSON.stringify(response.data));
           dispatch(getSalesTaxSuccess(response.data))
        })
        .catch((error) => {
          const errorMsg = error;
          dispatch(getSalesTaxFailure("Something went wrong!! Please try again!!"));
        });
    };
  };
  