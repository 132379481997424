/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import MyntraImg from "../../../assests/img/ImageGift.png";
import Button from "../../../components/Button/Button";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrand } from "../../../Redux/Brands/brandAction";
import { fetchAllGiftCardType } from "../../../Redux/GiftCard/giftcardAction";
import { fetchClient } from "../../../Redux/Client/clientAction";
import { SetCreateOrderData } from "../../../Redux/CreateOrder/CreateOrderAction";
import { strFirstLetterCap } from "../../../utils/constantFunction";
import { deliveryOption } from "../../../utils/ConstantData";
import { getClientGcListByType, getClientGcListDetails, postGiftCardManualInvCount, } from "../../../Redux/Distributions/ClientGiftcardDistributions/ClientGiftcardDistributionAction";
import Toasts from "../../../components/Toasts/Toasts";
import PulseLoader from "../../../components/Loader/PulseLoader";
import ModelForGiftCardSummery from "../../../components/Models/ModelForGiftCardSummery";
import ModalComp from "../../../components/Models/ModalComp";
import { fetchGiftCardSummeryById } from "../../../Redux/GiftCard/giftcardAction";
import { useTranslation } from "react-i18next";

const CardDistributionProduct = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const inputElementProdType = useRef(null);
  const inputElementProdName = useRef(null);
  const inputElementDenomi = useRef(null);
  const inputElementRang = useRef(null);
  const inputElementQuantity = useRef(null);
  const inputElementCountry = useRef(null);
  const inputElementDeliType = useRef(null);
  const inputElementDeliOptPhyAddress = useRef(null);
  const inputElementDeliOptDigital = useRef(null);
  const inputElementEmail = useRef(null);

  // reload data when user back to this page
  const giftcardOrderData = useSelector((state) => state.createOrder);

  const clientmappedGiftcardList = useSelector(
    (state) => state?.clientMapGcList?.clientMappedGcList
  );
  const selectedMapGiftcard = useSelector(
    (state) => state?.clientMapGcList?.selectedMapGcListDetails
  );

  const selectedMapGiftcardLoading = useSelector(
    (state) => state?.clientMapGcList?.loading
  );

  const selectedGcDenomination = useSelector(
    (state) =>
      state?.clientMapGcList?.selectedMapGcListDetails[0]?.denominations
  );

  const giftcardtype = useSelector((state) => state.giftcardtype?.giftcardtype);


  // for model
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );
  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = () => setShowToast(!showToast);

  const [selectedGiftcard, setSelectedGiftcard] = useState([]);

  const getSelecedGiftcard = (data) => {
    setSelectedGiftcard(data);
  };
  const [giftcartManualInvcount, setGiftcartManualInvcount] = useState(0);
  const [productType, setProductType] = useState("");

  useEffect(() => {
    setProductType(giftcardtype[0]);
  }, [giftcardtype]);

  useEffect(() => {
    // dispatch(getClientGcList(location?.state?.selectedClient?.id));
    window.scrollTo(0, 0);
    dispatch(fetchBrand());
    dispatch(fetchClient());
    dispatch(fetchAllGiftCardType());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getClientGcListByType(
        location?.state?.selectedClient?.id,
        giftcardtype && giftcardtype[0]?.id
      )
    );
  }, [giftcardtype])

  useEffect(() => {
    setFields({
      phoneNumber: location.state.selectedStore?.store_phone_no
        ? location.state.selectedStore?.store_phone_no
        : location.state.selectedClient.contact_no
          ? location.state.selectedClient.contact_no
          : fields?.phoneNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [error, setError] = useState({
    productType: "",
    product: "",
    quantity: "",
    denomination: "",
    rangeDenomination: "",
    countryCurrency: "",
    brand: "",
    organization: "",
    deliveryType: "",
    deliveryAddressline1: "",
    deliveryAddressline2: "",
    cityName: "",
    emailid: "",
    phoneNumber: "",
    pincode: "",
    deliveryOptions: "",
  });

  useEffect(() => {
    setFields({
      ...fields,
      emailid: location?.state?.selectedClient?.email,
      phoneNumber: location?.state?.selectedClient?.contact_no,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.selectedClient]);

  useEffect(() => {
    giftcardOrderData?.createorderData?.selectedProduct &&
      clientmappedGiftcardList &&
      clientmappedGiftcardList.map((item) => {
        if (
          item?.giftcard?.id ===
          giftcardOrderData?.createorderData?.selectedProduct?.giftcard?.id
        ) {
          setFields({ ...fields, product: item });
          dispatch(
            getClientGcListDetails(item?.giftcard?.id, getSelecedGiftcard)
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientmappedGiftcardList, giftcardOrderData?.createorderData]);

  useEffect(() => {
    giftcardOrderData?.createorderData?.denomination &&
      selectedGcDenomination &&
      selectedGcDenomination.map((item) => {
        if (item?.id === giftcardOrderData?.createorderData?.denomination?.id) {
          setFields({ ...fields, denomination: item });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGcDenomination, giftcardOrderData?.createorderData]);

  useEffect(() => {
    if (giftcardOrderData?.createorderData?.qauntity) {
      setFields({
        ...fields,
        qauntity: giftcardOrderData?.createorderData?.qauntity,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGcDenomination, giftcardOrderData?.createorderData]);

  const [fields, setFields] = useState({
    product: giftcardOrderData?.createorderData?.product
      ? giftcardOrderData?.createorderData?.product
      : "",
    quantity: giftcardOrderData?.createorderData?.quantity
      ? giftcardOrderData?.createorderData?.quantity
      : "",
    denomination: giftcardOrderData?.createorderData?.denomination
      ? giftcardOrderData?.createorderData?.denomination
      : "",
    rangeDenomination: giftcardOrderData?.createorderData?.rangeDenomination
      ? giftcardOrderData?.createorderData?.rangeDenomination
      : "",
    countryCurrency: giftcardOrderData?.createorderData?.countryCurrency
      ? giftcardOrderData?.createorderData?.countryCurrency
      : "",
    brand: giftcardOrderData?.createorderData?.brand
      ? giftcardOrderData?.createorderData?.brand
      : "",
    organization: "",
    deliveryType: giftcardOrderData?.createorderData?.deliveryType
      ? giftcardOrderData?.createorderData?.deliveryType
      : "",
    deliveryAddressline1: "",
    deliveryAddressline2: giftcardOrderData?.createorderData
      ?.deliveryAddressline2
      ? giftcardOrderData?.createorderData?.deliveryAddressline1
      : "",
    cityName: "",
    emailid: giftcardOrderData?.createorderData?.emailid
      ? giftcardOrderData?.createorderData?.emailid
      : "",
    phoneNumber: giftcardOrderData?.createorderData?.phoneNumber
      ? giftcardOrderData?.createorderData?.phoneNumber
      : "",
    pincode: giftcardOrderData?.createorderData?.pincode
      ? giftcardOrderData?.createorderData?.pincode
      : "",
    deliveryOptions: giftcardOrderData?.createorderData?.deliveryOptions
      ? giftcardOrderData?.createorderData?.deliveryOptions
      : "",
  });

  const deliveryType = [{ name: "Physical" }, { name: "Digital" }];

  // useEffect(() => {
  //   if (fields?.deliveryType?.name === "Physical") {
  //     setFields({
  //       ...fields,
  //       deliveryAddressline1:
  //         location?.state?.selectedClient &&
  //         location?.state?.selectedClient?.address,
  //     });
  //   }
  // }, [fields?.deliveryType?.name]);

  function handleRangeDenoChange(evt) {
    const value = evt.target.value;
    if (
      parseInt(value) > 0 &&
      parseInt(value) <= selectedMapGiftcard[0]?.max_amount
    ) {
      setFields({
        ...fields,
        [evt.target.name]: value,
      });
      setError({ rangeDenomination: "" });
    } else {
      setError({ rangeDenomination: "Enter the denomination value between Min & Max Rang value." });
      setFields({
        ...fields,
        [evt.target.name]: value,
      });
    }

    if (parseInt(value) > 0 && parseInt(value) < selectedMapGiftcard[0]?.min_amount) {
      setError({ rangeDenomination: "Enter the denomination value between Min & Max Rang value." });
    }
  }
  function handleChange(evt) {
    const value = evt.target.value;
    Object.keys(fields).map((item) => {
      if (item === evt.target.name) {
        let regex = /^\d{0,15}?$/;
        if (regex.test(evt.target.value)) {
          setFields({
            ...fields,
            [evt.target.name]: value,
          });
        }
      } else if (evt.target.name !== "phoneNumber") {
        setFields({
          ...fields,
          [evt.target.name]: value,
        });
      }
    });
  }

  function handlePinNumberChange(evt) {
    const value = evt.target.value;
    let regex = /^\d{0,10}(\.\d{0,2})?$/;
    if (regex.test(evt.target.value)) {
      setFields({
        ...fields,
        [evt.target.name]: value,
      });
    }
  }

  function handleEmailIDChange(evt) {
    const target = evt.target;
    // const target = e.target;
    let value = target.value;
    const emailid = target.name;
    setFields({
      ...fields,
      [emailid]: value,
    });

    setError({ ...error, emailid: "" });

  }
  function handleQuantityChange(evt) {
    const value = evt.target.value;
    let regex = /^\d{0,2}?$/;

    let qauntity = parseFloat(value);
    let manualInventoryCount = parseFloat(giftcartManualInvcount?.count);

    if (selectedMapGiftcard[0]?.registered_by_id === "manual_inventory" && qauntity) {
      if (manualInventoryCount >= qauntity) {
        if (regex.test(evt.target.value)) {
          setFields({
            ...fields,
            [evt.target.name]: value,
          });
        }
      } else {
        setFields({
          ...fields,
          [evt.target.name]: "",
        });
        setToastMessage(
          "You cannot enter a quantity more than your gift card quantity."
        );
        toggleShowToast();
      }
    } else if (selectedMapGiftcard[0]?.registered_by_id !== "manual_inventory") {
      if (regex.test(evt.target.value)) {
        setFields({
          ...fields,
          [evt.target.name]: value,
        });
      }
    }
  }
  //for drop down

  const handleChangeProduct = (e) => {
    setFields({
      ...fields,
      product: e,
      denomination: "",
      quantity: "",
      rangeDenomination: "",
    });
    setError({ rangeDenomination: "" });
    e && dispatch(getClientGcListDetails(e?.giftcard?.id, getSelecedGiftcard));
  };

  const productTypeHandler = (e) => {
    setFields({
      ...fields,
      product: "",
      denomination: "",
      quantity: "",
      rangeDenomination: "",
    });
    setProductType(e);
    e?.id &&
      dispatch(
        getClientGcListByType(location?.state?.selectedClient?.id, e?.id)
      );
  };

  // Getting giftcart manual inventory count
  const getGiftCardManualInvCount = (count) => {
    setGiftcartManualInvcount(count);
    if (count.count === 0) {
      setToastMessage(
        "Selected denomination quantity you not have please add this denomination first."
      );
      toggleShowToast();
    }
  };

  const handleChangeDenomination = (e) => {
    setFields({ ...fields, denomination: e, quantity: "" });
    if (selectedMapGiftcard[0]?.registered_by_id === "manual_inventory") {
      const giftcardInfo = {
        giftcardId: selectedMapGiftcard[0]?.id,
        denominationId: e?.id,
        card_status: "availabel",
      };
      dispatch(
        postGiftCardManualInvCount(giftcardInfo, getGiftCardManualInvCount)
      );
    }
  };

  const handleChangeDelivaryType = (e) => {
    if (e?.name === "Physical") {
      setFields({
        ...fields,
        deliveryType: e,
        deliveryAddressline1:
          location?.state?.selectedClient &&
          location?.state?.selectedClient?.address,
        cityName:
          location?.state?.selectedClient &&
          location?.state?.selectedClient.city_name,
        // pincode:
        //   location?.state?.selectedClient &&
        //   location?.state?.selectedClient.city_name,
      });
    } else if (e?.name !== "Physical") {
      setFields({ ...fields, deliveryType: e, deliveryOptions: "" });
    }
  };
  const handleChangeDelivaryOptions = (e) => {
    setFields({ ...fields, deliveryOptions: e });
  };

  //for validations
  const validate = () => {
    let rangeMinAmt = parseInt(fields.rangeDenomination);
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!productType) {
      setError({ productType: t("Please select product type") });
      if(inputElementProdType.current){
        inputElementProdType.current.focus()
      }
      return false
    } else if (!fields.product) {
      setError({ product: t("Please select product") });
      if(inputElementProdName.current){
        inputElementProdName.current.focus()
      }
      return false;
    } else if (
      fields?.product &&
      !fields.denomination &&
      selectedMapGiftcard &&
      selectedMapGiftcard[0]?.giftcard_value_type === "denominations"
    ) {
      setError({ denomination: t("Please select denomination") });
      if(inputElementDenomi.current){
        inputElementDenomi.current.focus()
      }
      return false;
    } else if (
      !fields.rangeDenomination &&
      selectedMapGiftcard[0]?.giftcard_value_type === "range"
    ) {
      setError({ rangeDenomination: t("Please enter denomination") });
      if(inputElementRang.current){
        inputElementRang.current.focus()
      }
      return false;
      // } else if (
      //   fields.rangeDenomination &&
      //   selectedMapGiftcard &&
      //   selectedMapGiftcard[0]?.giftcard_value_type === "range" &&
      //   selectedMapGiftcard[0]?.min_amount > rangeMinAmt
      // ) {
      //   setError({ rangeDenomination: "Please enter valid range denominations" });
      //   return false;
    } else if (error?.rangeDenomination) {
      return false
    } else if (
      fields.rangeDenomination &&
      selectedMapGiftcard &&
      selectedMapGiftcard[0]?.giftcard_value_type === "range" &&
      selectedMapGiftcard[0]?.max_amount < rangeMinAmt
    ) {
      setError({ rangeDenomination: t("Please enter valid range denominations") });
      if(inputElementRang.current){
        inputElementRang.current.focus()
      }
      return false;
    } else if (!fields.quantity) {
      setError({ quantity: t("Please select quantity") });
      if(inputElementQuantity.current){
        inputElementQuantity.current.focus()
      }
      return false;
    } else if (!fields.deliveryType) {
      setError({ deliveryType: t("Please select deliveryType") });
      if(inputElementDeliType.current){
        inputElementDeliType.current.focus()
      }
      return false;
    } else if (
      !fields?.deliveryAddressline1 &&
      fields?.deliveryType?.name !== "Digital"
    ) {
      setError({ deliveryAddressline1: t("Please enter address") });
      if(inputElementDeliOptPhyAddress.current){
        inputElementDeliOptPhyAddress.current.focus()
      }
      return false;
      // } else if (
      //   !fields?.deliveryAddressline2 &&
      //   fields?.deliveryType?.name !== "Digital"
      // ) {
      //   setError({ deliveryAddressline2: "Please enter address" });
      //   return false;
    } else if (
      !fields.deliveryOptions &&
      fields?.deliveryType?.name === "Digital"
    ) {
      setError({ deliveryOptions: t("Please select delivery option") });
      if(inputElementDeliOptDigital.current){
        inputElementDeliOptDigital.current.focus()
      }
      return false;
    } else if (!fields.emailid) {
      setError({ emailid: t("Please enter email") });
      if(inputElementEmail.current){
        inputElementEmail.current.focus()
      }
      return false;
    } else if (!fields.emailid || emailRegex.test(fields.emailid) === false) {
      setError({ emailid: t("Enter valid email") });
      return false;
    }
    return true;
  };

  const submit = () => {
    let data = fields.product;
    let selectProductObj = {
      discount: fields.product?.client_discount,
      giftcard: fields.product?.giftcard,
    }
    let quantity = fields.quantity;
    let denomination = fields.denomination;
    let val = validate();
    if (val) {
      let createOrderObj = {
        product: selectedMapGiftcard[0],
        quantity: fields.quantity,
        denomination: fields.denomination,
        countryCurrency:
          selectedMapGiftcard[0]?.country?.name +
            "-" +
            selectedMapGiftcard[0]?.country?.currency
            ? selectedMapGiftcard[0]?.country?.name +
            " - " +
            selectedMapGiftcard[0]?.country?.currency
            : "",
        brand: selectedMapGiftcard[0]?.brand?.default_name
          ? selectedMapGiftcard[0]?.brand?.default_name
          : "",
        deliveryType: fields.deliveryType,
        deliveryAddressline1: fields.deliveryAddressline1,
        deliveryAddressline2: fields.deliveryAddressline2,
        pincode: fields.pincode,
        cityName: fields.cityName,
        emailid: fields?.emailid,
        phoneNumber: fields?.phoneNumber,
        selectedProduct: selectProductObj,
        selectedQuantity: quantity,
        selectedDenomination: denomination,
        rangeDenomination: fields.rangeDenomination,
        selectedClient: location.state.selectedClient,
        selectedStore: location.state.selectedStore,
        store: location.state.type,
        clientType: location.state.clientType,
        deliveryOptions: fields.deliveryOptions,
      }

      // if (selectedGiftcard[0]?.availability === "out_of_stock") {
      //   toggleShowToast();
      // } else {
      dispatch(SetCreateOrderData(createOrderObj));
      history("/order-summary");
      // }
    }
    else {
      setToastMessage('Mandatory fields are required')
      toggleShowToast()
    }
  };

  const showModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowModal(true);
  };
  const redirecthandle=()=>{
    if(location?.state?.redirect==="clientDetail"){
      history("/clients-details")
    }else{
      history("/gift-card-distribution")
    }
  }
  
  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-3 px-4 bg-light">
      <div className="d-flex flex-column my-3">
        <div className="d-flex flex-row align-items-center gap-3">
          <LeftIcon
            onClick={() =>history(-1)}
            className="cursor"
          />
          <span className="font-bold font-32 ">{t("Card distribution")}</span>
        </div>
        <span className="font-bolder font-14 text-muted ">
          {t("Select Product & Attributes")}
        </span>
      </div>

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
        <div className="d-flex flex-column gap-4 mt-4 width-65 ">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Select Product Type")}<span className="text-danger">*</span>
              </span>
              <InfoTooltip title={"Select Product Type"} />
            </div>
            <div className="d-flex flex-column gap-1">
              <Select
              ref={inputElementProdType}
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select product type")}
                getOptionLabel={(giftcardtype) => giftcardtype?.type_name}
                options={giftcardtype}
                styles={CUSTOM_DROPDOWN_STYLE}
                value={productType}
                onChange={productTypeHandler}
                isClearable
              />
              {!productType && error.productType ? (
                <label className="text-error">{error.productType}</label>
              ) : null}
            </div>
          </div>

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Select Product")}<span className="text-danger">*</span>
              </span>
              <InfoTooltip title={"Select Product name"} />
            </div>
            <div className="d-flex flex-column gap-1">
              <Select
              ref={inputElementProdName}
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select product name")}
                getOptionLabel={(clientmappedGiftcardList) =>
                  clientmappedGiftcardList?.giftcard?.default_title
                }
                options={clientmappedGiftcardList}
                styles={CUSTOM_DROPDOWN_STYLE}
                value={fields.product}
                onChange={handleChangeProduct}
                isClearable
              />
              {!fields.product && error.product ? (
                <label className="text-error">{error.product}</label>
              ) : null}
            </div>
          </div>

          {fields.product ? (
            <div className="d-flex">
              <div className=" d-flex flex-column justify-content-between rounded-3  w-100">
                <table responsive="xl">
                  <tbody>
                    <tr className="hover-row ">
                      <td>
                        <div className="d-flex flex-row justify-content-start align-items-center gap-3 py-1">
                          <span className="mx-1 rounded-3">
                            <img
                              src={
                                selectedMapGiftcard &&
                                  selectedMapGiftcard[0]?.images[0]?.file_url ? (
                                  selectedMapGiftcard[0]?.images[0]?.file_url
                                ) : (
                                  <MyntraImg />
                                )
                              }
                              width={60}
                              height={60}
                              alt="not found"
                              className="img-fluid "
                            />
                          </span>
                          <div className="d-flex flex-column justify-content-center">
                            <span
                              className="font-16 font-boldest text-dark "
                              onClick={() =>
                                showModel(selectedMapGiftcard[0]?.id)
                              }
                            >
                              {selectedMapGiftcard[0]?.default_title &&
                                selectedMapGiftcard[0]?.default_title}
                            </span>
                            <span className="font-13 font-bold text-dark">
                              {strFirstLetterCap(
                                selectedMapGiftcard[0]?.giftcard_value_type &&
                                selectedMapGiftcard[0]?.giftcard_value_type
                              )}
                              {/* <span> denomination</span> */}
                            </span>
                            <span className="font-13 font-bold text-dark">
                              {selectedMapGiftcard[0]?.registered_by_id &&
                                selectedMapGiftcard[0]?.registered_by_id ===
                                "powered_by_ehadaya"
                                ? t("Powered by eHadaya")
                                : selectedMapGiftcard[0]?.registered_by_id ===
                                  "manual_inventory"
                                  ? t("Manual Inventory")
                                  : selectedMapGiftcard[0]?.registered_by_id ===
                                    "3rd_party_suppliers"
                                    ? t("3rd Party Supplier")
                                    : null}
                            </span>
                            <span className="font-13 font-bold text-muted">
                              {selectedMapGiftcard[0]?.id &&
                                selectedMapGiftcard[0]?.id}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-start ">
                          <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 p-1 ">
                            {selectedMapGiftcard[0]?.country?.currency +
                              " - " +
                              selectedMapGiftcard[0]?.country?.currency_symbol}
                          </span>
                        </div>
                      </td>
                      {/* <td>
                        <div className="d-flex justify-content-center ">
                          <span className="font-15 font-bold text-success bg-04 px-1 py-1 gap-2 rounded-3">
                            {selectedMapGiftcard[0]?.availability
                              ? selectedMapGiftcard[0]?.availability
                              : null}
                          </span>
                        </div>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
          {fields?.product &&
            selectedMapGiftcard[0]?.giftcard_value_type &&
            selectedMapGiftcard[0]?.giftcard_value_type === "denominations" ? (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Denomination")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <Select
                ref={inputElementDenomi}
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select or type product Denomination")}
                  getOptionLabel={(selectedGcDenomination) =>
                    selectedGcDenomination?.denomination_value
                  }
                  options={selectedGcDenomination}
                  value={fields.denomination}
                  onChange={handleChangeDenomination}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
                {!fields.denomination && error.denomination ? (
                  <label className="text-error">{error.denomination}</label>
                ) : null}
                {selectedMapGiftcard[0]?.registered_by_id ===
                  "manual_inventory" &&
                  fields.denomination &&
                  giftcartManualInvcount ? (
                  <label
                    className={
                      giftcartManualInvcount.count > 0
                        ? `text-success`
                        : `text-error`
                    }
                  >
                    {giftcartManualInvcount.count > 0
                      ? `${t("Available quantity")} : ${giftcartManualInvcount?.count}`
                      : `${t("Quantity not available")}`}
                  </label>
                ) : null}
              </div>
            </div>
          ) : fields?.product &&
            selectedMapGiftcard[0]?.giftcard_value_type &&
            selectedMapGiftcard[0]?.giftcard_value_type === "range" ? (
            <div className="d-flex flex-column  ">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Range denomination")}<span className="text-danger">*</span>
                  </span>
                  <span className="font-bold font-14">
                    {`(${t("Min")}:${selectedMapGiftcard[0]?.min_amount}-${t("Max")}:${selectedMapGiftcard[0]?.max_amount})`}{" "}
                  </span>
                  <InfoTooltip title={"Range denomination giftcard"} />
                </div>
                <InputField
                inputRef={inputElementRang}
                  placeholder={t("Enter denomination range")}
                  name="rangeDenomination"
                  value={fields?.rangeDenomination}
                  onChange={handleRangeDenoChange}
                />
              </div>
              {error.rangeDenomination ? (
                <label className="text-error">{error.rangeDenomination}</label>
              ) : null}
            </div>
          ) : null}
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Quantity")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Enter Quantity"}
                />
              </div>
              <InputField
              inputRef={inputElementQuantity}
                placeholder={t("Enter quantity")}
                type="text"
                name="quantity"
                value={fields.quantity}
                onChange={handleQuantityChange}
              />
            </div>
            {!fields.quantity && error.quantity ? (
              <label className="text-error">{error.quantity}</label>
            ) : null}
          </div>

          <div className="d-flex flex-column gap-4">
            {selectedMapGiftcard[0]?.country?.name &&
              selectedMapGiftcard[0]?.country?.currency ? (
              <div className="d-flex flex-column gap-4  ">
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Country & Currency")}
                    </span>
                    <InfoTooltip
                      title={"Enter country name"}
                    />
                  </div>
                  <InputField
                  inputRef={inputElementCountry}
                    placeholder={t("Enter country")}
                    name="CountryName"
                    value={
                      selectedMapGiftcard[0]?.country?.name +
                      " - " +
                      selectedMapGiftcard[0]?.country?.currency
                    }
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>
            ) : null}
            {selectedMapGiftcard[0]?.brand && selectedMapGiftcard[0]?.brand ? (
              <div className="d-flex flex-column gap-4  ">
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("Brand")}</span>
                    <InfoTooltip
                      title={"Enter brand name"}
                    />
                  </div>
                  <InputField
                    placeholder={t("Enter brand")}
                    name="brand"
                    value={selectedMapGiftcard[0]?.brand?.default_name}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>
            ) : null}

            {!location.state.selectedClient ? (
              <div className="d-flex flex-column gap-4  ">
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("Organization")}</span>
                    <InfoTooltip
                      title={"Enter organization name"}
                    />
                  </div>
                  <InputField
                    placeholder={t("Enter organization")}
                    name="Organization"
                    value={
                      location.state.selectedStore?.organisation_id
                        ? location.state.selectedStore?.organisation_id
                        : location.state.selectedClient.organisation
                    }
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>
            ) : null}
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Delivery type")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Select Delivery type"}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <Select
                ref={inputElementDeliType}
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select delivery type")}
                  getOptionLabel={(deliveryType) => deliveryType.name}
                  options={deliveryType}
                  value={fields.deliveryType}
                  onChange={handleChangeDelivaryType}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
                {!fields.deliveryType && error.deliveryType ? (
                  <label className="text-error">{error.deliveryType}</label>
                ) : null}
              </div>
            </div>
          </div>

          {fields?.deliveryType?.name === "Physical" ? (
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Delivery Address line 1")}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Enter physical address"}
                  />
                </div>
                <InputField
                inputRef={inputElementDeliOptPhyAddress}
                  placeholder={t("Enter delivery address line 1")}
                  name="deliveryAddressline1"
                  value={fields.deliveryAddressline1}
                  onChange={handleChange}
                />
                {!fields.deliveryAddressline1 && error.deliveryAddressline1 ? (
                  <label className="text-error">
                    {error.deliveryAddressline1}
                  </label>
                ) : null}
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Delivery Address line 2")}
                    {/* <span className="text-danger">*</span> */}
                  </span>
                  <InfoTooltip
                    title={"Enter physical address"}
                  />
                </div>
                <InputField
                  placeholder={t("Enter delivery address line 2")}
                  name="deliveryAddressline2"
                  value={fields.deliveryAddressline2}
                  onChange={handleChange}
                />
                {!fields.deliveryAddressline2 && error.deliveryAddressline2 ? (
                  <label className="text-error">
                    {error.deliveryAddressline2}
                  </label>
                ) : null}
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Pin code")}</span>
                  <InfoTooltip
                    title={"Enter pin code"}
                  />
                </div>
                <InputField
                  type="text"
                  placeholder={t("Enter pin code")}
                  name="pincode"
                  value={fields.pincode}
                  onChange={handlePinNumberChange}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("City name")}</span>
                  <InfoTooltip
                    title={"Enter city name"}
                  />
                </div>
                <InputField
                  placeholder={t("Enter city name")}
                  type="text"
                  name="cityName"
                  value={fields?.cityName}
                  onChange={handleChange}
                />
              </div>
            </div>
          ) : null}
          {fields?.deliveryType && fields?.deliveryType?.name === "Digital" ? (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Delivery options")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Choose giftcard delivery options"}
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <Select
                ref={inputElementDeliOptDigital}
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select delivery type")}
                  getOptionLabel={(deliveryOption) => deliveryOption.name}
                  options={deliveryOption}
                  value={fields.deliveryOptions}
                  onChange={handleChangeDelivaryOptions}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
                {!fields?.deliveryOptions && error?.deliveryOptions ? (
                  <label className="text-error">{error?.deliveryOptions}</label>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column gap-3  ">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Email ID")}<span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Enter Email ID"}
                  />
                </div>
                <InputField
                inputRef={inputElementEmail}
                  placeholder={t("Enter email id")}
                  type="email"
                  name="emailid"
                  value={fields.emailid}
                  onChange={handleEmailIDChange}
                />
                {error.emailid ? (
                  <label className="text-error">{error.emailid}</label>
                ) : null}
              </div>
              <div className="d-flex flex-row align-items-center gap-1">
                <span className="font-bold font-14">{t("Phone number")} </span>
                <InfoTooltip
                  title={"Enter phone number"}
                />
              </div>
              <div className="d-flex flex-row gap-2 align-items-center">
                {/* <InputGroupTxt
                  title={
                    fields?.countryCurrency?.phone_code
                      ? "+" + fields?.countryCurrency?.phone_code
                      : ""
                  } */}
                <InputField
                  placeholder={t("Phone number")}
                  type="number"
                  name="phoneNumber"
                  value={fields.phoneNumber}
                  maxLength={15}
                  onChange={handleChange}
                />
                <div>
                  <div className="d-flex flex-row justify-content-start text-dark gap-1 align-items-center">
                    <span>
                      <input
                        className="form-check-input cursor"
                        type="checkbox"
                        id="checkboxNoLabel"
                      // checked={products.filter(product => product?.isChecked !== true).length < 1}
                      // onChange={allCheckedHandle}
                      />
                    </span>

                    <span className="font-16 font-boldest text-muted">
                      {t("WhatsApp")}
                    </span>

                    <span>
                      <input
                        className="form-check-input cursor"
                        type="checkbox"
                        id="checkboxNoLabel"
                      // checked={products.filter(product => product?.isChecked !== true).length < 1}
                      // onChange={allCheckedHandle}
                      />
                    </span>

                    <span className="font-16 font-boldest text-muted">{t("SMS")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex">
        <Button onClick={submit} text={t("Create Order")} type="primary" />
      </div>
      <ModalComp
        size="lg"
        showModal={showModal}
        // handleClose={handleClose}
        component={
          <ModelForGiftCardSummery
            handleClose={handleClose}
            giftcardSummery={giftcardSummery}
          />
        }
      />
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg={toastMessage}
          imageType={"warning"}
        />
      </div>
      {selectedMapGiftcardLoading === true && (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </div>
  );
};

export default CardDistributionProduct;
