/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import Default_giftcard from "../../assests/img/giftcard-default.png";
import Default_product from "../../assests/img/Product-default.png";
import Button from "../Button/Button";
import Toasts from "../Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import { getClientGcDetailList } from "../../Redux/Distributions/ClientGiftcardDistributions/ClientGiftcardDistributionAction";
import {
  fetchClientMappedMerchandise,
  fetchClientMappedMerchandiseDetails,
  setSelectedDistMerClient,
} from "../../Redux/Distributions/ClientMerchandiseDistributions/ClientMerchandiseDistributionAction";
import { strFirstLetterCap } from "../../utils/constantFunction";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg";
import PulseLoader from "../Loader/PulseLoader";
import ModalComp from "../../components/Models/ModalComp";
import ModelForGiftCardSummery from "../../components/Models/ModelForGiftCardSummery";
import ModelForMerchandiseSummery from "../../components/Models/ModelForMerchandiseSummery";
import { getMerchandiseSummeryById } from "../../Redux/Merchandise/merchandiesAction";
import { fetchGiftCardSummeryById } from "../../Redux/GiftCard/giftcardAction";
import { useNavigate } from "react-router-dom";
import Filter from "../Filter/Filter";
import { PRODUCT_TYPE } from "../../utils/ConstantData";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import SmallToast from "../Toast/SmallToast";

const MappedProducts = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const selectedClient = props.selectedClient;
  const clientmappedGiftcardList = useSelector(
    (state) => state?.clientMapGcList?.clientMappedGcList
  );

  const GiftcardListLoading = useSelector(
    (state) => state?.clientMapGcList?.loading
  );

  const clientMapMerchandiseList = useSelector(
    (state) => state.clientMappedMerchandise.clientMappedMerchandise
  );
  const MerchandiseListLoading = useSelector(
    (state) => state.clientMappedMerchandise.loading
  );

  // for model Merchandise
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );
  const handleClose = () => setShowMerchandiseModal(false);
  const [showMerchandiseModal, setShowMerchandiseModal] = useState(false);

  // for model Giftcard
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );
  const handleGiftcardClose = () => setShowGiftcardModal(false);
  const [showGiftcardModal, setShowGiftcardModal] = useState(false);

  const [button, setButton] = useState({ name: "Giftcard" });
  const [clientMapMerchandise, setClientMapMerchandise] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [permissions, setpermissions] = useState();
  const [usertype, setusertype] = useState();
  const [id, setId] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    setusertype(JSON.parse(localStorage.getItem("user")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getClientGcDetailList(selectedClient?.id));
    dispatch(fetchClientMappedMerchandiseDetails(selectedClient?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeProductType = (type) => {
    if (type?.name === "Merchandise") {
      setButton(type);
      dispatch(fetchClientMappedMerchandiseDetails(selectedClient?.id));
    } else if (type?.name === "Giftcard") {
      setButton(type);
      dispatch(getClientGcDetailList(selectedClient?.id));
    }
  };

  useEffect(() => {
    let arr = [...clientMapMerchandiseList];
    let prodArr = [];

    arr &&
      // eslint-disable-next-line array-callback-return
      arr?.map((item, i) => {
        let obj = {
          ...item,
          collapseFlag: false,
        };
        prodArr.push(obj);
      });
    setClientMapMerchandise(prodArr);
  }, [clientMapMerchandiseList]);

  useEffect(() => {}, [clientmappedGiftcardList, clientMapMerchandise]);

  const dropdownHandler = (index) => {
    let arr = [...clientMapMerchandiseList];
    // setOpenCollapse(!openCollapse);
    arr &&
      arr?.map((item, i) => {
        if (index === i) {
          arr[i].collapseFlag = !item.collapseFlag;
        }
      });
    setClientMapMerchandise(arr);
  };

  const showSummaryModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowMerchandiseModal(true);
  };

  const showGiftcardModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowGiftcardModal(true);
  };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 5);
    return val;
  };

  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const handleGoToDistribution = () => {
    if (button?.name === "Giftcard") {
      if (clientmappedGiftcardList.length > 0) {
        history("/card-distribution", {
          state: {
            selectedClient: selectedClient,
            clientType: "client",
            redirect: "clientDetail",
          },
        });
      } else {
        setToastMessage("You have no giftcard mapped");
        toggleShowToast();
      }
    } else if (button?.name === "Merchandise") {
      if (clientMapMerchandise.length > 0) {
        dispatch(setSelectedDistMerClient(selectedClient));
        dispatch(fetchClientMappedMerchandise(selectedClient?.id));
        history("/merchandise-order", {
          state: {
            selectedClient: selectedClient,
            redirect: "clientDetail",
          },
        });
      } else {
        setToastMessage("You have no merchandise product mapped");
        toggleShowToast();
      }
    }
  };
  // console.log("clientMapMerchandise ", clientMapMerchandise);

  return (
    <>
      {GiftcardListLoading === false && MerchandiseListLoading === false ? (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
          <div className="d-flex flex-row justify-content-between  align-items-center">
            <div className="d-flex flex-row ">
              <div className="d-flex gap-3 align-items-center">
                <Tag />
                <span className="font-20 font-bold">Mapped Products</span>
                <div className="Inputwithicon">
                  <div id="search">
                    <Searchicon />
                  </div>
                  <input
                    type="text"
                    className="input-custom w-100"
                    name="search"
                    placeholder="Search Product"
                    onChange={searchHandler}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row py-2 align-items-center">
              <div className="d-flex flex-row gap-1 align-items-center">
                {/* <div className="px-1">
                  <Button
                    type={button === "GiftCard" ? "primary" : "rounded"}
                    text="Giftcards"
                    onClick={() => handleChangeProductType("GiftCard")}
                  />
                </div>
                <Button
                  type={button === "Merchandise" ? "primary" : "rounded"}
                  text="Merchandise"
                  onClick={() => handleChangeProductType("Merchandise")}
                /> */}
                <Filter
                  data={PRODUCT_TYPE}
                  onChange={handleChangeProductType}
                  title={button?.name}
                />
                {(selectedClient?.float_flag &&
                  permissions?.giftcard_client_distribution?.create) ||
                permissions?.merchandise_client_distribution?.create ? (
                  <Button
                    type={"primary"}
                    text={"Distribution"}
                    onClick={() => handleGoToDistribution()}
                  />
                ) : null}
              </div>

              {/*  <div className="p-1">
                <Button
                  type="primary"
                  text="Download CSV"
                  className="rounded-6 my-4 mt-6 fw-bolder"
                  onClick={() => toggleShowToast()}
                />
              </div>*/}
            </div>
          </div>

          {clientmappedGiftcardList && button?.name === "Giftcard" ? (
            <div className="card-body d-flex flex-column justify-content-between align-item-center gap-5 px-2">
              <table responsive="xl">
                <tbody>
                  <tr className="border-bottom">
                    <td className="text-muted" colSpan={3}>
                      <div className="d-flex flex-row justify-content-start">
                        <span className="mx-3">Giftcard image/title</span>
                      </div>
                    </td>
                    <td className="text-muted">Giftcard id</td>
                    <td className="text-muted">Country & currency</td>
                    <td className="text-muted">Inventory type</td>
                    <td className="text-muted">Client discount</td>
                    {/* <td className="text-muted"> Edit</td> */}
                  </tr>
                  {clientmappedGiftcardList &&
                    clientmappedGiftcardList
                      ?.filter((item) => {
                        if (searchTerm == "") {
                          return item;
                        } else if (
                          item?.giftcard?.default_title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => (
                        <tr
                          className="card-body gap-3 border-bottom"
                          key={index}
                        >
                          <td colSpan={3}>
                            <div className="d-flex flex-row justify-content-start align-items-center py-2">
                              <div className="rounded-3 px-1">
                                <img
                                  src={
                                    item?.giftcard?.images &&
                                    item?.giftcard?.images[0]
                                      ? item?.giftcard?.images[0]?.file_url
                                      : Default_giftcard
                                  }
                                  alt="not found"
                                  className="img-fluid"
                                  width="50px"
                                  height="50px"
                                />
                              </div>
                              <div className="d-flex flex-column justify-content-start mx-2">
                                <span
                                  className="font-12 font-boldest text-dark d-inline-block text-truncate cursor"
                                  title={item?.giftcard?.default_title}
                                  style={{ maxWidth: "150px" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  onClick={() =>
                                    showGiftcardModel(item?.giftcard?.id)
                                  }
                                >
                                  {item?.giftcard?.default_title}
                                </span>
                                <span className="font-10 font-boldest text-muted">
                                  {strFirstLetterCap(
                                    item?.giftcard?.giftcard_value_type
                                  )}{" "}
                                </span>
                                <span
                                  className="font-10 font-bolder text-dark bg-04 rounded-3 px-1 d-inline-block text-truncate cursor"
                                  style={{
                                    width: "fit-Content",
                                    maxWidth: "70px",
                                  }}
                                  title={
                                    item?.giftcard?.giftcard_value_type ===
                                    "denominations"
                                      ? item?.giftcard?.denominations &&
                                        item?.giftcard?.denominations.map(
                                          (deno) => deno.denomination_value
                                        )
                                      : item?.giftcard?.min_amount +
                                        "-" +
                                        item?.giftcard?.max_amount
                                  }
                                >
                                  {item?.giftcard?.giftcard_value_type ===
                                  "denominations"
                                    ? item?.giftcard?.denominations &&
                                      item?.giftcard?.denominations.map(
                                        (deno) => deno.denomination_value + ","
                                      )
                                    : item?.giftcard?.min_amount +
                                      "-" +
                                      item?.giftcard?.max_amount}
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className="d-flex justify-content-start align-items-center">
                              <span
                                className="font-14 font-boldest text-dark visible mt-4"
                                title={item?.giftcard?.id}
                              >
                                {trimIdHandler(item?.giftcard?.id)}
                                <span>...</span>
                              </span>

                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Copy to clipboard"
                                className="cursor "
                                value={item?.giftcard?.id}
                                onClick={() =>
                                  copyToClipboard(item?.giftcard?.id)
                                }
                              >
                                <CopyToClipboard text={item?.giftcard?.id}>
                                  <CopyButton />
                                </CopyToClipboard>
                              </span>
                            </div>
                          </td>

                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <span className="font-12 font-bold text-success bg-04 px-1 rounded-3">
                                {`${item?.giftcard?.country?.currency}-(${item?.giftcard?.country?.currency_symbol})`}
                              </span>
                            </div>
                          </td>
                          <td>
                            <span className="font-12 font-bold">
                              {item?.giftcard?.inventory_type_name}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <span className="font-12 font-boldest text-dark">
                                {item?.client_discount}%
                              </span>
                            </div>
                          </td>
                          {/* <td>
                    <span className=" px-1  ">
                      <EditIcon/>
                    </span>
                  </td> */}
                        </tr>
                      ))}
                </tbody>
              </table>
              {!clientmappedGiftcardList?.length && (
                <div className="d-flex flex-row justify-content-center py-3">
                  <span className="font-20 font-bold text-error">
                    Products Not Found
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex flex-column gap-4 mt-4">
              <table className="table table-borderless" responsive="xl">
                <tbody>
                  <tr className="d-flex align-items-center text-muted font-14 px-4 font-bolder">
                    <td className="col-3">Merchandise title</td>
                    <td className="col-3">Merchandise id</td>
                    <td className="col-3">Country & currency</td>
                    <td className="col-3">Selling price</td>
                  </tr>
                  {clientMapMerchandise &&
                    clientMapMerchandise
                      ?.filter((item) => {
                        if (searchTerm == "") {
                          return item;
                        } else if (
                          item?.merchandise?.default_title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => (
                        <tr
                          key={index}
                          className="d-flex flex-column justify-content-start px-4"
                        >
                          <div className="d-flex flex-row justify-content-start align-items-center ">
                            <td className="col-3 pt-3">
                              <div className="d-flex flex-row justify-content-start align-items-center">
                                <span className="p-2">
                                  <img
                                    src={
                                      item?.merchandise?.images &&
                                      item?.merchandise?.images
                                        ? item?.merchandise?.images[0]?.file_url
                                        : Default_product
                                    }
                                    alt="not found"
                                    className="img-fluid"
                                    width="50px"
                                    height="50px"
                                  />
                                </span>
                                <div className="d-flex flex-column justify-content-start ">
                                  <span
                                    className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                                    title={item?.merchandise?.default_title}
                                    style={{ maxWidth: "100px" }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    onClick={() =>
                                      showSummaryModel(item?.merchandise?.id)
                                    }
                                  >
                                    {item?.merchandise?.default_title}
                                  </span>

                                  <span className="font-10 font-bold">
                                    {item?.product_class ===
                                    "merchandise_variant"
                                      ? item?.merchandise_variant &&
                                        item?.merchandise_variant?.length +
                                          " variants"
                                      : "No variants"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="col-3 pt-3">
                              <div className="d-flex flex-column justify-content-start ">
                              <span
                                className="font-14 font-boldest text-dark visible mt-4"
                                title={item?.merchandise?.id}
                              >
                                {trimIdHandler(item?.merchandise?.id)}
                                <span>...</span>
                              </span>
                              </div>
                            </td>

                            <td className="col-3 pt-3">
                              <div className="d-flex flex-column justify-content-start mx-4">
                                <span className="font-15 font-bolder text-dark bg-04 rounded-3 px-1 fit-content">
                                  {`${item?.merchandise?.country?.currency}-(${item?.merchandise?.country?.currency_symbol})`}
                                </span>
                              </div>
                            </td>

                            <td className="col-3 pt-3">
                              <div className="d-flex flex-column justify-content-start fit-content mx-4">
                                <span className="font-12 font-boldest text-dark">
                                  {item?.selling_price ? (
                                    item?.selling_price
                                  ) : (
                                    <span>
                                      {item?.product_class ===
                                      "merchandise_variant" ? (
                                        <div
                                          onClick={() => dropdownHandler(index)}
                                        >
                                          <DropDownIcon
                                            className={`cursor p-0 ${
                                              item?.collapseFlag
                                                ? "rotate-180"
                                                : ""
                                            }`}
                                          />
                                        </div>
                                      ) : null}
                                    </span>
                                  )}
                                </span>
                              </div>
                            </td>
                          </div>
                          {item?.collapseFlag && (
                            // <div id="example-collapse-text">
                            //   <div className="d-flex bg-white shadow rounded-3 ">
                            //     <div className="d-flex flex-column  w-50 gap-3 mx-3 my-3 ">
                            //       <div className="d-flex flex-row justify-content-center border-bottom">
                            //         <div className="d-flex align-items-center gap-2">
                            //           <span className="font-14 font-bolder ">
                            //             Variants List
                            //           </span>
                            //         </div>
                            //       </div>

                            //       <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                            //         {item?.product_class ===
                            //         "merchandise_variant"
                            //           ? item?.merchandise_variant &&
                            //             item?.merchandise_variant.map(
                            //               (vari, i) => (
                            //                 <div
                            //                   className="d-flex flex-column justify-content-center align-items-center"
                            //                   key={i}
                            //                 >
                            //                   {vari?.variant?.variant_values.join(
                            //                     "|"
                            //                   )}
                            //                 </div>
                            //               )
                            //             )
                            //           : null}
                            //       </div>
                            //     </div>
                            //     <div className="border-start border-1 my-3"></div>
                            //     <div className="d-flex flex-column  w-50 gap-3 mx-3 my-3 ">
                            //       <div className="d-flex flex-row justify-content-center border-bottom">
                            //         <div className="d-flex align-items-center gap-2">
                            //           <span className="font-14 font-bolder ">
                            //             Variants price
                            //           </span>
                            //         </div>
                            //       </div>

                            //       <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                            //         {item?.product_class ===
                            //         "merchandise_variant"
                            //           ? item?.merchandise_variant &&
                            //             item?.merchandise_variant.map(
                            //               (vari) => (
                            //                 <div className="d-flex flex-column align-items-center">
                            //                   {vari?.selling_price}
                            //                 </div>
                            //               )
                            //             )
                            //           : null}
                            //       </div>
                            //     </div>
                            //   </div>
                            // </div>
                            <div className="d-flex bg-white shadow rounded-3 ">
                              <table
                                className="table table-borderless"
                                responsive="xl"
                              >
                                <tbody>
                                  <tr className="">
                                    <td className="col-9">
                                      <span className="font-14 font-bolder ">
                                        Variants List
                                      </span>
                                    </td>
                                    <td className="col-3">
                                      <span className="font-14 font-bolder ">
                                        Price
                                      </span>
                                    </td>
                                  </tr>

                                  {item?.product_class === "merchandise_variant"
                                    ? item?.merchandise_variant &&
                                      item?.merchandise_variant.map(
                                        (vari, i) => (
                                          <tr key={i}>
                                            <td className="col-9">
                                              <div className="d-flex align-items-center gap-2">
                                                <img
                                                  src={
                                                    vari?.images && vari?.images
                                                      ? vari?.images[0]
                                                          ?.file_url
                                                      : Default_product
                                                  }
                                                  alt="not found"
                                                  className="img-fluid"
                                                  width="50px"
                                                  height="50px"
                                                />
                                                <span>
                                                  {vari?.variant?.variant_values.join(
                                                    "|"
                                                  )}
                                                </span>
                                              </div>
                                            </td>
                                            <td className="col-3">
                                              <div className="">
                                                <span>
                                                  {vari?.selling_price}
                                                </span>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          )}

          {/* {clientmappedGiftcardList.length &&
        <div className="d-flex justify-content-center align-item-center">
          <Button
            type="rounded"
            text="Load more"
            className="rounded-6 my-4 mt-6 "
          />
        </div>}

      {clientMapMerchandiseList.length &&
        <div className="d-flex justify-content-center align-item-center">
          <Button
            type="rounded"
            text="Load more"
            className="rounded-6 my-4 mt-6 "
          />
        </div>} */}

          <ModalComp
            size="lg"
            showModal={showGiftcardModal}
            // handleClose={handleClose}
            component={
              <ModelForGiftCardSummery
                handleClose={handleGiftcardClose}
                giftcardSummery={giftcardSummery}
              />
            }
          />

          <ModalComp
            size="lg"
            showModal={showMerchandiseModal}
            // handleClose={handleClose}
            component={
              <ModelForMerchandiseSummery
                handleClose={handleClose}
                merchandiseSummery={merchandiseSummery}
              />
            }
          />

          <div
            className="position-fixed bottom-0 start-50 translate-middle-x w-100 mb-2"
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg={toastMessage}
              imageType={"warning"}
            />
          </div>
          <div>
            <SmallToast data={id ? id : ""} message={"Copied"} />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default MappedProducts;
