/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as CloseIcone } from "../../assests/svg/close.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import InputField from "../../components/InputField/InputField";
import TextEditor from "../../components/InputField/TextEditor";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import { fetchBrand } from "../../Redux/Brands/brandAction";
import { getAllLanguage } from "../../Redux/Language/languageAction";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { useDispatch, useSelector } from "react-redux";
import {
  editMerchandise,
  getMerchandiseById,
  getMerchandiseImage,
  getMerchandiseSummeryById,
} from "../../Redux/Merchandise/merchandiesAction";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
// merchandise-attribute
import TagsInput from "../../components/TagsInput/TagsInput";
import { getProdCategory } from "../../Redux/ProdCategory/prodCatAction";
import { getWeight } from "../../Redux/Weight/weightAction";
import { getShippingCost } from "../../Redux/ShippingCost/shippingcostAction";
import { getSalestax } from "../../Redux/SalesTax/salestaxAction";
import { getVat } from "../../Redux/Vat/vatAction";
import { getWarranty } from "../../Redux/Warranty/warrantyAction";
import ReactDatePicker from "../../components/DatePicker/ReactDatePicker";
import { fetchAllCountrieDetails } from "../../Redux/Countries/countryAction";
import InputGroupTxt from "../../components/InputField/InputGroupTxt";
import ReactSwitchButton from "../../components/Button/ReactSwitchButton";
import { VALIDITY_UNITS } from "../../utils/ConstantData";
import { downloadFileURI } from "../../utils/constantFunction";
import { fetchBrandCategoryByID } from "../../Redux/BrandCategories/brandCategoryAction";

// Merchandise/Term-Condition

import { ReactComponent as BlueTag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as DeleteIconWhite } from "../../assests/svg/delete_black.svg";
import DragandDropMultiple from "../../components/DragandDrop/DragandDropMultiple";
import DragandDropVideo from "../../components/DragandDrop/DragandDropVideo";
import DragHandle from "../../assests/img/drag-handle.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CardPreview from "../../components/Products/CardPreview";
import { useTranslation } from "react-i18next";

const MerchandiseEditScreen = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  // get merchandise detail in location

  const inputElementTitle = useRef(null)
  const inputElementDesc = useRef(null)
  const inputElementBrand = useRef(null)

  const Preferredlanguage = useSelector((state) => state?.languages?.languages);
  const countries = useSelector((state) => state?.countries?.countries);
  const stateList = useSelector(
    (state) => state?.countries?.countrieDetails?.states
  );
  const brands = useSelector((state) => state?.brands?.brands);
  const brandCategories = useSelector(
    (state) => state?.brandCategories?.brandCategoriesbyID
  );
  const weights = useSelector((state) => state?.weights?.weight);

  //   merchandise-attribute

  const merchandiseByIdForEdit = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseByIdForEdit
  );

  const merchandiseImage = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseImage
  );
  const merchandiseState = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseTandC
  );
  const mercandiseNameDesc = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseNameDes
  );


  let defaultLang = localStorage.getItem("defaultLang");

  const constProdTitle = {
    title: "",
    lang: JSON.parse(defaultLang),
  };

  const edittorTxt = {
    value: EditorState.createEmpty(),
    lang: JSON.parse(defaultLang),
  };

  const edittorTxtTerm = {
    value: EditorState.createEmpty(),
    lang: JSON.parse(defaultLang),
  };

  const [prodTitles, setProdTitles] = useState([constProdTitle]);

  const [languageDropDown, setLanguageDropDown] = useState("");
  const [countryCurrencyDropDown, setCountryCurrencyDropDown] = useState("");
  const [stateDropDown, setStateDropDown] = useState("");
  const [brandDropDown, setBrandDropDown] = useState("");
  const [brandCategoryDropDown, setBrandCategoryDropDown] = useState("");
  const [weightDropDown, setWeightDropDown] = useState("");
  const [saleTaxDropDown, setSaleTaxDropDown] = useState("");
  const [vatDropDown, setVatDropDown] = useState("");
  const [warrantyDropDown, setWarrantyDropDown] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const [inputFields, setInputFields] = useState({
    weight: "",
    shippingCost: "",
    saleTax: "",
    vat: "",
    warranty: "",
  });

  const [docUrl, setDocUrl] = useState("");
  const [cardImgUrl, setCardImgUrl] = useState("");
  const [sortImg, setSortImg] = useState("");


  const [showToast, setShowToast] = useState(false);

  // tags input code
  const [brandTags, setBrandTags] = useState([]);

  const [switchButton, setSwitchButton] = useState({
    activationFlag: merchandiseByIdForEdit?.active_flag
      ? merchandiseByIdForEdit?.active_flag
      : true,
    promotionItemFlag: false,
    hasVariantFlag: merchandiseByIdForEdit?.product_variants_flag
      ? merchandiseByIdForEdit?.product_variants_flag
      : false,
  });

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [videoFileFlag, setVideoFileFlag] = useState(
    merchandiseState?.videoFileFlag ? merchandiseState?.videoFileFlag : false
  );
  const [termConditionTxt, setTermConditionTxt] = useState([edittorTxtTerm]);

  const TAX_UNIT = [
    {
      name: countryCurrencyDropDown?.currency_symbol
        ? countryCurrencyDropDown?.currency_symbol
        : "$",
    },
    { name: "%" },
  ];

  const [productImg, setProductImg] = useState([]);
  const [productVideo, setProductVideo] = useState([]);

  // useEffect(() => {
  //   merchandiseSummery &&
  //     merchandiseSummery[0]?.variants[0]?.images?.map((item) => {
  //       let myFile = new File([item?.file_url], "image/jpeg", {
  //         type: "image/jpeg",
  //       });
  //       console.log("***myFile*** ", myFile);
  //     });

  // }, [productImg]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchBrand());
    dispatch(getProdCategory());
    dispatch(getWeight());
    dispatch(getAllCountriesList());
    dispatch(getShippingCost());
    dispatch(getSalestax());
    dispatch(getVat());
    dispatch(getWarranty());
    dispatch(getAllLanguage());

  }, []);

  useEffect(() => {
    dispatch(getMerchandiseById(location?.state?.merchandiseEditDetails?.id));
    dispatch(
      getMerchandiseSummeryById(location?.state?.merchandiseEditDetails?.id)
    );
  }, [location?.state?.merchandiseEditDetails?.id]);

  useEffect(() => {
    if (merchandiseByIdForEdit) {
      setTitleObject();
    }
  }, [merchandiseByIdForEdit, Preferredlanguage]);

  useEffect(() => {
    dispatch(getMerchandiseImage(merchandiseByIdForEdit?.id));
  }, [merchandiseByIdForEdit]);

  useEffect(() => {
    merchandiseByIdForEdit &&
      Preferredlanguage &&
      Preferredlanguage.map((item) => {
        if (merchandiseByIdForEdit?.language_id === item?.language_code) {
          setLanguageDropDown(item);
        }
      });
  }, [merchandiseByIdForEdit, Preferredlanguage]);

  useEffect(() => {
    merchandiseByIdForEdit &&
      countries &&
      countries.map((item) => {
        if (merchandiseByIdForEdit?.country_id === item?.id) {
          setCountryCurrencyDropDown(item);
          item?.id && dispatch(fetchAllCountrieDetails(item?.id));
        }
      });
  }, [merchandiseByIdForEdit, countries]);

  useEffect(() => {
    merchandiseByIdForEdit &&
      countries &&
      stateList &&
      stateList.map((item) => {
        if (merchandiseByIdForEdit?.state_id === item?.id) {
          setStateDropDown(item);
        }
      });
  }, [countries, stateList]);

  useEffect(() => {
    merchandiseByIdForEdit &&
      brands &&
      brands.map((item) => {
        if (merchandiseByIdForEdit?.brand_id === item.id) {
          setBrandDropDown(item);
          item.id && dispatch(fetchBrandCategoryByID(item.id));
        }
      });
  }, [merchandiseByIdForEdit, brands]);

  useEffect(() => {
    brandDropDown &&
      brandCategories &&
      brandCategories.map((item) => {
        if (merchandiseByIdForEdit?.brand_category_id === item.id) {
          setBrandCategoryDropDown(item);
        }
      });
  }, [merchandiseByIdForEdit, brandDropDown, brandCategories]);

  useEffect(() => {
    merchandiseByIdForEdit &&
      brands &&
      brands.map((item) => {
        if (merchandiseByIdForEdit?.brand_id === item.id) {
          setBrandDropDown(item);
        }
      });
  }, [merchandiseByIdForEdit]);

  // Weights Field
  useEffect(() => {
    weights &&
      weights.map((item) => {
        if (item?.weight === merchandiseByIdForEdit?.weight_unit) {
          setWeightDropDown(item);
          setInputFields({
            ...inputFields,
            weight: merchandiseByIdForEdit?.weight,
          });
        }
      });
  }, [merchandiseByIdForEdit, weights]);

  useEffect(() => {
    setInputFields({
      ...inputFields,
      shippingCost: merchandiseByIdForEdit?.shipping_cost
        ? merchandiseByIdForEdit?.shipping_cost
        : "",
      saleTax: merchandiseByIdForEdit?.sales_tax
        ? merchandiseByIdForEdit?.sales_tax
        : "",
      vat: merchandiseByIdForEdit?.vat ? merchandiseByIdForEdit?.vat : "",
      warranty: merchandiseByIdForEdit?.warranty
        ? merchandiseByIdForEdit?.warranty
        : "",
    });
  }, [merchandiseByIdForEdit]);

  useEffect(() => {
    merchandiseByIdForEdit?.warranty_type &&
      VALIDITY_UNITS.map((item) => {
        if (
          item?.name.toUpperCase() ===
          merchandiseByIdForEdit?.warranty_type.toUpperCase()
        ) {
          setWarrantyDropDown(item);
        }
      });
  }, [VALIDITY_UNITS, merchandiseByIdForEdit]);

  useEffect(() => {
    // merchandiseByIdForEdit &&
    //   TAX_UNIT.map((item) => {
    //     if (countryCurrencyDropDown?.currency_symbol === item.name) {
    //       setSaleTaxDropDown(item);
    //       setVatDropDown(item);
    //     }
    //   });

    if (merchandiseByIdForEdit?.sales_tax_type === "percentage") {
      setSaleTaxDropDown({ name: "%" });
    } else {
      setSaleTaxDropDown({ name: countryCurrencyDropDown?.currency_symbol });
    }

    if (merchandiseByIdForEdit?.vat_type === "percentage") {
      setVatDropDown({ name: "%" });
    } else {
      setVatDropDown({ name: countryCurrencyDropDown?.currency_symbol });
    }
  }, [merchandiseByIdForEdit, countryCurrencyDropDown]);

  useEffect(() => {
    let imgArr = [];
    merchandiseImage &&
      merchandiseImage.map((item) => {
        if (item?.file === "image" && !item?.product_variants_flag) {
          let obj = {
            "path": item?.id,
            "preview": item?.file_url,

            "id": item?.id,
            "merchandise_id": item?.merchandise_id,
            "product_variants_flag": item?.product_variants_flag,
            "variant_id": item?.variant_id,
            "file": item?.file,
            "file_url": item?.file_url,
            "file_type": item?.file_type,
            "folder": item?.folder,
            "key": item?.key,
            "active_flag": item?.active_flag,
            "image_processing": item?.image_processing,
            "default_image_flag": item?.default_image_flag,
            "sort_order": item?.sort_order,
            "created_at": item?.created_at,
            "updated_at": item?.updated_at
          }
          setCardImgUrl(item);
          imgArr.push(obj);
        }
      });
    setSortImg(imgArr);
    setProductImg(imgArr);

  }, [merchandiseImage]);

  const toggleShowToast = () => setShowToast(!showToast);

  const setInputData = () => {
    let arrDescription = [];

    merchandiseByIdForEdit?.about_the_product &&
      merchandiseByIdForEdit?.about_the_product.map((item) => {
        Preferredlanguage &&
          Preferredlanguage.map((langs) => {
            if (langs.language_code === item.language_id) {
              const obj = {
                value: EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(item?.description_text)
                  )
                ),
                lang: langs,
              };
              arrDescription.push(obj);
            }
          });
      });
    setEditorState(arrDescription);

    let arrTermCondition = [];
    // Term and Condition
    merchandiseByIdForEdit?.terms_condition &&
      merchandiseByIdForEdit?.terms_condition.map((item) => {
        Preferredlanguage &&
          Preferredlanguage.map((lang) => {
            if (lang.language_code === item.language_id) {
              const obj = {
                value: EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(item?.terms_condition_text)
                  )
                ),
                lang: lang,
              };
              arrTermCondition.push(obj);
            }
          });
      });
    setTermConditionTxt(arrTermCondition);
  };

  useEffect(() => {
    setInputData();
  }, [merchandiseByIdForEdit, Preferredlanguage]);

  // Key Benefits
  useEffect(() => {
    let one =
      merchandiseByIdForEdit &&
      merchandiseByIdForEdit?.key_benefits &&
      merchandiseByIdForEdit?.key_benefits[0];
    let two =
      merchandiseByIdForEdit &&
      merchandiseByIdForEdit?.key_benefits &&
      merchandiseByIdForEdit?.key_benefits[1];
    let three =
      merchandiseByIdForEdit &&
      merchandiseByIdForEdit?.key_benefits &&
      merchandiseByIdForEdit?.key_benefits[2];
    let four =
      merchandiseByIdForEdit &&
      merchandiseByIdForEdit?.key_benefits &&
      merchandiseByIdForEdit?.key_benefits[3];

    let oneObj =
      one && one?.length > 0
        ? { id: "one", name: one }
        : { id: "one", name: "" };
    let twoObj =
      two && two?.length > 0
        ? { id: "two", name: two }
        : { id: "two", name: "" };
    let threeObj =
      three && three?.length > 0
        ? { id: "three", name: three }
        : { id: "three", name: "" };
    let fourObj =
      four && four?.length > 0
        ? { id: "four", name: four }
        : { id: "four", name: "" };

    let key_benifits = {
      one: oneObj,
      two: twoObj,
      three: threeObj,
      four: fourObj,
    };

    setKeyBenefits(key_benifits);
  }, [merchandiseByIdForEdit]);

  useEffect(() => {
    if (merchandiseByIdForEdit?.tags) {
      setBrandTags(merchandiseByIdForEdit?.tags);
    }
  }, [merchandiseByIdForEdit]);

  useEffect(() => {
    merchandiseByIdForEdit?.product_variants_flag &&
      setSwitchButton({
        ...switchButton,
        hasVariantFlag: merchandiseByIdForEdit?.product_variants_flag,
      });
  }, [merchandiseByIdForEdit?.product_variants_flag]);

  // Set Merchandise Images
  // useEffect(() => {
  //   // eslint-disable-next-line array-callback-return
  //   merchandiseByIdForEdit &&
  //   merchandiseByIdForEdit.map((item) => {
  //       if (item.file === "card_image") {
  //         setCardImgUrl(item);
  //       } else if (item.file === "card_documentation") {
  //         setDocUrl(item);
  //       } else if (item.file === "card_thumbnail") {
  //         setTbImgUrl(item);
  //       }
  //     });
  //   //  
  // }, [merchandiseByIdForEdit]);

  const changeActivate = () => {
    setSwitchButton({
      ...switchButton,
      activationFlag: !switchButton.activationFlag,
    });
  };

  const changePromotionItem = () => {
    setSwitchButton({
      ...switchButton,
      promotionItemFlag: !switchButton.promotionItemFlag,
    });
  };

  const changeHasVariant = () => {
    setSwitchButton({
      ...switchButton,
      hasVariantFlag: !switchButton.hasVariantFlag,
    });
  };

  const videoFileHandler = () => {
    setVideoFileFlag((prevState) => !prevState);
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(productImg);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);

    setProductImg(tempData);
  };

  const setMultipleImg = (e) => {
    let arr = [...productImg];
    if (arr.length > 0) {
      let imgArr = [...arr, ...e];
      setProductImg(imgArr);
    } else {
      setProductImg(e);
    }
  };

  const removeProductImg = (e) => {
    let array = [...productImg];
    if (e !== -1) {
      array.splice(e, 1);
      setProductImg(array);
    }
  };

  const [editorState, setEditorState] = useState(
    mercandiseNameDesc?.description
      ? mercandiseNameDesc?.description
      : [edittorTxt]
  );

  const [prodDescription, setProdDescription] = useState({
    prodTitle: merchandiseByIdForEdit?.default_title
      ? merchandiseByIdForEdit?.default_title
      : "",
    keyBenefits1: "",
    keyBenefits2: "",
    keyBenefits3: "",
    keyBenefits4: "",
  });

  const [keybenefits, setKeyBenefits] = useState([]);

  const [error, setError] = useState({
    language: "",
    prodTitle: "",
    title: "",
    countryCurrency: "",
    brand: "",
    editorState: "",
    termConditionTxt: "",
    keyBenefits1: "",
    keyBenefits2: "",
    keyBenefits3: "",
    keyBenefits4: "",
  });

  const handleSetKeybenefits = (e) => {
    let data = {
      id: e.target.name,
      name: e.target.value,
    };

    setKeyBenefits({ ...keybenefits, [e.target.name]: data });
  };

  const handleInputChangeState = (e) => {
    const { name, value } = e.target;
    setProdDescription({
      ...prodDescription,
      [name]: value,
    });
  };

  const getDescription = (txt, code) => {
    let arr = [...editorState];
    setError({ editorState: "" });
    arr.map((item, i) => {
      if (code === item.lang.language_code) {
        arr[i].value = txt;
      }
    });
    setEditorState(arr);
  };

  const getTermAndCondition = (txt, code) => {
    let arr = [...termConditionTxt];
    setError({ termConditionTxt: "" });
    arr.map((item, i) => {
      if (code === item.lang.language_code) {
        arr[i].value = txt;
      }
    });
    setTermConditionTxt(arr);
  };

  const handleAddClick = (e) => {
    if (
      editorState.some((item) => item.lang.language_code === e.language_code)
    ) {
      // alert("Already added");
      toggleShowToast();
      return;
    } else {
      setEditorState([
        ...editorState,
        { value: EditorState.createEmpty(), lang: e },
      ]);
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...editorState];
    list.splice(index, 1);
    setEditorState(list);
  };

  const handleDropdownOnClick = (obj) => {
    handleAddClick(obj);
  };

  const validate = () => {
    let value = convertToRaw(editorState[0].value.getCurrentContent());
    // if (!prodDescription.prodTitle) {
    //   setError({ prodTitle: "Please enter product title" });
    //   return false;
    // }
    if (!prodTitles[0].title) {
      setError({ title: t("Please enter product title") });
      if(inputElementTitle.current){
        inputElementTitle.current.focus()
      }
      return false;
    } else if (!value.blocks[0].text) {
      setError({ editorState: t("Please provides description") });
      if(inputElementDesc.current){
        inputElementDesc.current.focus()
      }
      return false;
    } else if (!brandDropDown) {
      setError({ brand: t("Please select brand") });
      if(inputElementBrand.current){
        inputElementBrand.current.focus()
      }
      return false;
    }
    return true;
  };

  const setTitleObject = () => {
    let arr = [];
    const res = Object.entries(merchandiseByIdForEdit.product_title).map(
      (e) => ({ [e[0]]: e[1] })
    );

    res &&
      res.map((item) => {
        let name = Object.values(item).join("");
        let langCode = Object.keys(item).join("");

        Preferredlanguage &&
          Preferredlanguage.map((item) => {
            if (item.language_code === langCode) {
              let obj = {
                title: name,
                lang: item,
              };
              arr.push(obj);
            }
          });
        setProdTitles(arr);
      });
  };

  // Removed product title field handler
  const handleRemoveClickProdTitle = (index) => {
    const list = [...prodTitles];
    list.splice(index, 1);
    setProdTitles(list);
  };

  // product input value getting handler
  const handleInputChangeProdTitle = (e) => {
    const { name, value } = e.target;
    let arr = [...prodTitles];
    arr.map((item, i) => {
      if (name === item.lang.language_code) {
        arr[i].title = value;
      }
    });
    setProdTitles(arr);
  };

  // Language select handler
  const handleAddClickProdTitle = (e) => {
    if (
      prodTitles.some((item) => item.lang.language_code === e.language_code)
    ) {
      toggleShowToast();
      return;
    } else {
      setProdTitles([...prodTitles, { title: "", lang: e }]);
    }
  };

  const goBack = () => {
    history("/merchandise-dashboard");
  };

  const variantsEditScr = () => {
    history("/merchandise-variants-edit", { state: { merchandiseState: merchandiseByIdForEdit } });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setInputFields({ ...inputFields, [name]: value });
  };

  const handleChangeLanguage = (e) => {
    setLanguageDropDown(e);
  };

  const handleChangeBrands = (e) => {
    setBrandDropDown(e);
    setBrandCategoryDropDown("");
    dispatch(fetchBrandCategoryByID(e.id));
  };

  const handleChangeBrandCategories = (e) => {
    setBrandCategoryDropDown(e);
  };

  const merchandiseSuccess = () => {
    history("/merchandise-edit-successfull");
  };

  const submit = () => {
    let value = validate();

    if (value) {
      let obj = {
        merchandiseId: merchandiseByIdForEdit?.id,
        language_id: languageDropDown?.language_code,
        product_title: prodTitles,
        default_title: prodTitles[0].title,
        about_the_product: editorState,
        key_benefits: keybenefits,
        brand_id: brandDropDown?.id,
        brand_category_id: brandCategoryDropDown?.id,
        weight_unit: weightDropDown?.weight,
        weight: inputFields?.weight,
        shipping_cost: inputFields?.shippingCost,
        sales_tax_type: saleTaxDropDown?.name,
        sales_tax: inputFields?.saleTax,
        vat_type: vatDropDown?.name,
        vat: inputFields?.vat,
        warranty_type: warrantyDropDown?.name,
        warranty: inputFields?.warranty,
        promotion_flag: switchButton?.promotionItemFlag,
        featured_flag: switchButton?.activationFlag,
        product_variants_flag: switchButton?.hasVariantFlag,
        start_date: date?.startDate,
        end_date: date?.endDate,
        tags: brandTags,
        terms_condition: termConditionTxt,
        active_flag: switchButton?.activationFlag,
        deleted_flag: false,
        show_as_points: true,
        availability: merchandiseByIdForEdit?.availability,
        updated_at: date?.startDate,
      };
      dispatch(editMerchandise(obj, merchandiseSuccess));
    }
  };

  return (
    <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
      <div className="d-flex flex-row mt-2 gap-2">
        <div className="width-65">
          <div className="d-flex flex-column justify-content-start bg-light">
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <div className="d-flex flex-row align-items-center pb-2 gap-3">
                <LeftIcon onClick={() => goBack()} className="cursor" />
                <span className="font-bold font-32 my-2">
                  {t("Full Description (Edit)")}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between gap-2">
              <div className="d-flex w-100">
                <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex gap-3">
                      <Tag />
                      <span className="font-20 font-bold">
                        {t("Name & Description")}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-4 mt-4">
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">
                          {t("Preferred language")}
                        </span>
                        <InfoTooltip title={"Select Preferred language"} />
                      </div>

                      <div>
                        <Select
                          placeholder={t("Select preferred language")}
                          noOptionsMessage={() => "Not found"}
                          getOptionLabel={(Preferredlanguage) =>
                            Preferredlanguage.language
                          }
                          options={Preferredlanguage}
                          styles={CUSTOM_DROPDOWN_STYLE}
                          value={languageDropDown}
                          onChange={handleChangeLanguage}
                          isClearable
                        />
                      </div>
                    </div>
                    {prodTitles &&
                      prodTitles.map((item, index) => (
                        <div className="d-flex flex-column gap-3" key={index}>
                          <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                            <div className="d-flex flex-row align-items-center gap-2">
                              <span className="font-bold font-14">
                                {t("Product title")} #{index + 1}{" "}
                                {item.lang.language}
                                <span className="text-danger">*</span>
                              </span>
                              <InfoTooltip title={"Enter Product title"} />
                            </div>
                            {prodTitles.length !== 1 && (
                              <div
                                className="cursor"
                                onClick={() =>
                                  handleRemoveClickProdTitle(index)
                                }
                              >
                                {index !== 0 && <CloseIcone />}
                              </div>
                            )}
                          </div>

                          <div className="d-flex flex-column gap-1">
                            <InputField
                            inputRef={inputElementTitle}
                              onChange={handleInputChangeProdTitle}
                              name={item.lang.language_code}
                              placeholder={`${t(
                                "Product title"
                              )} ${item.lang.language.toLocaleLowerCase()}`}
                              type="text"
                              value={item.title}
                            />
                            {!item.title && error.title ? (
                              <label className="text-error">
                                {error.title}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      ))}
                    <div className="d-flex ">
                      <div className="btn-group w-100">
                        <div
                          className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                          data-bs-toggle="dropdown"
                        >
                          <span>
                            {t("Add other language for product title")}
                          </span>
                        </div>
                        <ul
                          className={`dropdown-menu rounded-card-7 dropdown-menu-start ${props?.lang === "ar" ? "dropdown-menu-ar" : ""
                            }`}
                        >
                          {Preferredlanguage &&
                            Preferredlanguage.map((item, i) => (
                              <li
                                key={i}
                                onClick={() => handleAddClickProdTitle(item)}
                              >
                                <div className="dropdown-item cursor">
                                  {item.language}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>

                    {editorState.map((item, i) => (
                      <div className="d-flex flex-column gap-3" key={i}>
                        <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                          <div className="d-flex flex-row align-items-center gap-2">
                            <span className="font-bold font-14">
                              {t("About the product")} #{i + 1}{" "}
                              {item.lang.language}
                              <span className="text-danger">
                                {i === 0 ? "*" : ""}
                              </span>
                            </span>
                            <InfoTooltip
                              title={"Enter the product description"}
                            />
                          </div>
                          {editorState.length !== 1 && (
                            <div
                              className="cursor"
                              onClick={() => handleRemoveClick(i)}
                            >
                              {i !== 0 && <CloseIcone />}
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-column gap-1">
                          <TextEditor
                          inputRef={inputElementDesc}
                            code={item.lang.language_code}
                            editorState={item.value}
                            getDescription={getDescription}
                            lang={props?.lang}
                          />
                          {error.editorState && i === 0 ? (
                            <label className="text-error">
                              {error.editorState}
                            </label>
                          ) : null}
                        </div>
                      </div>
                    ))}

                    <div className="d-flex ">
                      <div className="btn-group w-100">
                        <div
                          className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                          data-bs-toggle="dropdown"
                        >
                          <span>{t("Add other language description")}</span>
                        </div>
                        <ul
                          className={`dropdown-menu rounded-card-7 dropdown-menu-start ${props?.lang === "ar" ? "dropdown-menu-ar" : ""
                            }`}
                        >
                          {Preferredlanguage &&
                            Preferredlanguage.map((item, i) => (
                              <li
                                key={i}
                                onClick={() => handleDropdownOnClick(item)}
                              >
                                <div className="dropdown-item cursor">
                                  {item.language}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">
                          {t("Key benefits")}
                        </span>
                        <InfoTooltip title={"Enter Key benefits"} />
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
                        <InputField
                          onChange={handleSetKeybenefits}
                          name="one"
                          placeholder={t("benefit 1")}
                          type="text"
                          value={keybenefits?.one?.name}
                        />
                        <InputField
                          onChange={handleSetKeybenefits}
                          name="two"
                          placeholder={t("benefit 2")}
                          type="text"
                          value={keybenefits?.two?.name}
                        />
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
                        <InputField
                          onChange={handleSetKeybenefits}
                          name="three"
                          placeholder={t("benefit 3")}
                          type="text"
                          value={keybenefits?.three?.name}
                        />
                        <InputField
                          onChange={handleSetKeybenefits}
                          name="four"
                          placeholder={t("benefit 4")}
                          type="text"
                          value={keybenefits?.four?.name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Merchandise Attribute */}
          <div className="d-flex flex-row justify-content-between gap-2 mt-2">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex gap-3">
                  <Tag />
                  <span className="font-20 font-bold">
                    {t("Category & Attributes")}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column gap-3 mt-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Country & Currency")}
                  </span>
                  <InfoTooltip
                    title={
                      "Enter country & currency(type atleast 3 charachters to see dropdown effect)"
                    }
                  />
                </div>

                {/* Dropdown region*/}
                <div className="btn-group w-100">
                  <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                    {countryCurrencyDropDown?.name +
                      " - " +
                      countryCurrencyDropDown?.currency}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column gap-3 mt-4">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("State")}</span>
                  <InfoTooltip title={"State"} />
                </div>
                <div className="btn-group w-100">
                  <div className="btn border border-2 rounded-card-7 default-cursor bg-light text-muted d-flex align-items-center py-2 remove-cursor">
                    {stateDropDown?.name ? stateDropDown?.name : "Not avilable"}
                  </div>
                </div>
              </div>
              {/* <div className="d-flex flex-column gap-3 mt-4">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">State</span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                 <div>
                  <Select
                    noOptionsMessage={() => "Not found"}
                    placeholder="Select state"
                    getOptionLabel={(states) => states.name}
                    options={stateList}
                    //   isSearchable
                    value={fields.state}
                    onChange={handleChangeStates}
                    isClearable
                    styles={CUSTOM_DROPDOWN_STYLE}
                    isDisabled={!fields?.countryCurrency}
                  />
                </div>
              </div> */}
              <div className="d-flex flex-column gap-4 mt-4 ">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Brand")}
                      <span className="text-danger">*</span>
                    </span>
                    <InfoTooltip title={"Select Brand name"} />
                  </div>
                  {/* Dropdown brand*/}
                  <div className="d-flex flex-column gap-1">
                    <Select
                    ref={inputElementBrand}
                      noOptionsMessage={() => "Not found"}
                      placeholder={t("Select brand")}
                      getOptionLabel={(brands) => brands.default_name}
                      options={brands}
                      //   isSearchable
                      value={brandDropDown}
                      onChange={handleChangeBrands}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                    />
                    {!brandDropDown && error.brand ? (
                      <label className="text-error">{error.brand}</label>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Brand category")}
                    </span>
                    <InfoTooltip title={"Select brand category"} />
                  </div>

                  {/* Dropdown brandCategory*/}
                  <div>
                    <Select
                      noOptionsMessage={() => "Not found"}
                      placeholder={t("Select brand categories")}
                      getOptionLabel={(brandCategories) =>
                        brandCategories?.default_category_name
                      }
                      options={brandCategories}
                      //   isSearchable
                      value={brandCategoryDropDown}
                      onChange={handleChangeBrandCategories}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                      isDisabled={!brandDropDown}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("Weight")}</span>
                    <InfoTooltip title={"Enter Weight"} />
                  </div>
                  {/* Dropdown input for Weight*/}
                  <div className="input-group rounded-card-7">
                    <button
                      className={`btn btn-outline-secondary dropdown-toggle border border-2 ${props?.lang === "ar"
                        ? "prefix-dropdown-right-corner-ar"
                        : "prefix-dropdown-left-corner"
                        }`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {weightDropDown.weight
                        ? weightDropDown.weight
                        : t("Select")}
                    </button>
                    <ul
                      className={` ${props?.lang === "ar"
                        ? "dropdown-menu dropdown-menu-ar"
                        : "dropdown-menu"
                        }`}
                    >
                      {weights &&
                        weights.map((item, i) => (
                          <li key={i} onClick={() => setWeightDropDown(item)}>
                            <div className="dropdown-item cursor">
                              {item.weight}
                            </div>
                          </li>
                        ))}
                    </ul>
                    <input
                      type="text"
                      name="weight"
                      placeholder={t("enter weight")}
                      className={`form-control border border-2 ${props?.lang === "ar"
                        ? "prefix-dropdown-left-corner-ar"
                        : "prefix-dropdown-right-corner"
                        }`}
                      aria-label="weight"
                      value={inputFields.weight}
                      //  disabled={fields.weight ? false : true}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Shipping cost")}
                    </span>
                    <InfoTooltip title={"Enter Shipping cost"} />
                  </div>
                  <div className=" d-flex flex-column gap-1">
                    <InputGroupTxt
                      title={
                        countryCurrencyDropDown?.currency_symbol
                          ? countryCurrencyDropDown?.currency_symbol
                          : "$"
                      }
                      placeholder={t("enter shipping cost")}
                      type="text"
                      name="shippingCost"
                      lang={props?.lang}
                      value={inputFields.shippingCost}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("Sales tax")}</span>
                    <InfoTooltip title={"Enter Sales tax"} />
                  </div>
                  {/* Dropdown input for sales tax*/}
                  <div className="input-group rounded-card-7">
                    <button
                      className={`btn btn-outline-secondary dropdown-toggle border border-2  ${props?.lang === "ar"
                        ? "prefix-dropdown-right-corner-ar"
                        : "prefix-dropdown-left-corner"
                        }`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {saleTaxDropDown ? saleTaxDropDown?.name : t("Select")}
                    </button>
                    <ul
                      className={` ${props?.lang === "ar"
                        ? "dropdown-menu dropdown-menu-ar"
                        : "dropdown-menu"
                        }`}
                    >
                      {TAX_UNIT &&
                        TAX_UNIT.map((item, i) => (
                          <li key={i} onClick={() => setSaleTaxDropDown(item)}>
                            <div className="dropdown-item cursor">
                              {item.name}
                            </div>
                          </li>
                        ))}
                    </ul>
                    <input
                      type="text"
                      name="saleTax"
                      placeholder={t("enter sales tax")}
                      className={`form-control border border-2 ${props?.lang === "ar"
                        ? "prefix-dropdown-left-corner-ar"
                        : "prefix-dropdown-right-corner"
                        }`}
                      aria-label="saleTax"
                      value={inputFields.saleTax}
                      // disabled={fields.saleTax ? false : true}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("VAT")}</span>
                    <InfoTooltip title={"Enter VAT"} />
                  </div>
                  {/* Dropdown input for vat*/}
                  <div className="input-group rounded-card-7">
                    <button
                      className={`btn btn-outline-secondary dropdown-toggle border border-2 ${props?.lang === "ar"
                        ? "prefix-dropdown-right-corner-ar"
                        : "prefix-dropdown-left-corner"
                        }`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {vatDropDown ? vatDropDown?.name : t("Select")}
                    </button>
                    <ul
                      className={` ${props?.lang === "ar"
                        ? "dropdown-menu dropdown-menu-ar"
                        : "dropdown-menu"
                        }`}
                    >
                      {TAX_UNIT &&
                        TAX_UNIT.map((item, i) => (
                          <li key={i} onClick={() => setVatDropDown(item)}>
                            <div className="dropdown-item cursor">
                              {item.name}
                            </div>
                          </li>
                        ))}
                    </ul>
                    <input
                      type="text"
                      name="vat"
                      placeholder={t("enter vat")}
                      className={`form-control border border-2 ${props?.lang === "ar"
                        ? "prefix-dropdown-left-corner-ar"
                        : "prefix-dropdown-right-corner"
                        }`}
                      aria-label="vat"
                      value={inputFields.vat}
                      //   disabled={fields.vat ? false : true}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("Warranty")}</span>
                    <InfoTooltip title={"Enter Warranty"} />
                  </div>
                  {/* Dropdown input for warranty*/}
                  <div className="input-group rounded-card-7">
                    <button
                      className={`btn btn-outline-secondary dropdown-toggle border border-2 ${props?.lang === "ar"
                        ? "prefix-dropdown-right-corner-ar"
                        : "prefix-dropdown-left-corner"
                        }`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {warrantyDropDown ? warrantyDropDown?.name : t("Select")}
                    </button>
                    <ul
                      className={` ${props?.lang === "ar"
                        ? "dropdown-menu dropdown-menu-ar"
                        : "dropdown-menu"
                        }`}
                    >
                      {VALIDITY_UNITS &&
                        VALIDITY_UNITS.map((item, i) => (
                          <li key={i} onClick={() => setWarrantyDropDown(item)}>
                            <div className="dropdown-item cursor">
                              {item.name}
                            </div>
                          </li>
                        ))}
                    </ul>
                    <input
                      type="text"
                      name="warranty"
                      placeholder={t("enter warranty")}
                      className={`form-control border border-2 ${props?.lang === "ar"
                        ? "prefix-dropdown-left-corner-ar"
                        : "prefix-dropdown-right-corner"
                        }`}
                      aria-label="warranty"
                      value={inputFields.warranty}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Promotion Item")}
                    </span>
                    <InfoTooltip
                      title={"Enable switch to make product Promotional Item"}
                    />
                  </div>
                  <ReactSwitchButton
                    onChange={changePromotionItem}
                    value={switchButton.promotionItemFlag}
                  />
                </div>

                <div className="d-flex flex-row justify-content-between gap-3 ">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("Active")}</span>
                    <InfoTooltip title={"Active/Deactive product"} />
                  </div>
                  <ReactSwitchButton
                    onChange={changeActivate}
                    value={switchButton.activationFlag}
                  />
                </div>
                {merchandiseByIdForEdit?.product_variants_flag === true && (
                  < div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-row justify-content-between gap-3">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">
                          {t("Does your product has variants?")}
                        </span>
                        <InfoTooltip
                          title={"Enable if your product has variants"}
                        />
                      </div>
                      <ReactSwitchButton
                        onChange={changeHasVariant}
                        value={switchButton.hasVariantFlag}
                        disabled={merchandiseByIdForEdit?.product_variants_flag}
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-end">
                      <span
                        className="text-light-blue font-bold font-14 cursor"
                        onClick={() => variantsEditScr()}
                      >
                        Edit variants
                      </span>
                    </div>
                  </div>
                )}

                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row justify-content-start align-items-center gap-2 w-100">
                    <div className="col d-flex flex-column gap-2">
                      <span className="text-muted font-12 font-bold">
                        {t("Select start date")}
                      </span>
                      <ReactDatePicker
                        // onChange={handleChangeDate}
                        selected={date.startDate}
                        minDate={new Date()}
                        name={"startDate"}
                        lang={props?.lang}
                        className="datepicker-input-style-without-border"
                      />
                    </div>
                    <div className="col d-flex flex-column gap-2">
                      <span className="text-muted font-12 font-bold">
                        {t("Select end date")}
                      </span>
                      <ReactDatePicker
                        // onChange={handleChangeEndDate}
                        selected={date.endDate}
                        minDate={date.startDate}
                        name={"endDate"}
                        lang={props?.lang}
                        className="datepicker-input-style-without-border"
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row justify-content-between gap-3 ">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">{t("Tags")}</span>
                      <InfoTooltip
                        title={"Enter tags that describe your product"}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-2">
                    <TagsInput
                      tags={brandTags}
                      setTags={setBrandTags}
                      placeholder={t("Enter tags to describe your brands")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Merchandise Term-Condition */}
          <div className="d-flex flex-row justify-content-between gap-2 mt-2 ">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex gap-3">
                  <BlueTag />
                  <span className="font-20 font-bold">
                    {t("Product Images")}
                  </span>
                </div>
              </div>

              <div className="d-flex flex-column gap-4 mt-4">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row  justify-content-between align-items-center gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Product images")}
                        <span className="text-danger">*</span>
                        <span> {t("(size should be 600*200)")}</span>
                      </span>
                      <InfoTooltip title={"Upload product image"} />
                    </div>

                    <span
                      className="text-light-blue font-bold font-14 cursor"
                      onClick={() => downloadFileURI(cardImgUrl)}
                    >
                      {t("Download")}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <DragandDropMultiple
                      files={productImg}
                      setFiles={setMultipleImg}
                    />
                    {!productImg.length > 0 && error.productImg ? (
                      <label className="text-error">{error.productImg}</label>
                    ) : null}
                  </div>
                </div>
                <div>
                  {productImg && productImg.length > 0 && (
                    <div className="d-flex flex-column gap-4 mt-4">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">
                          {t("Sort images")}
                          <span className="text-danger">*</span>
                          <span> {t("(size should be 600*200)")}</span>
                        </span>
                        <InfoTooltip title={"Upload sort images"} />
                      </div>
                      <div className="d-flex w-100">
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <table responsive="xl" className="w-100">
                            <Droppable droppableId="droppable-1">
                              {(provider) => (
                                <tbody
                                  className="text-capitalize"
                                  ref={provider.innerRef}
                                  {...provider.droppableProps}
                                >
                                  {productImg?.map((user, index) => (
                                    <Draggable
                                      key={user.path}
                                      draggableId={user.path}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <tr
                                          key={index}
                                          id="tr-sort"
                                          className="d-flex flex-row align-items-center justify-content-between  gap-3 p-2"
                                          {...provider.draggableProps}
                                          ref={provider.innerRef}
                                        >
                                          <td>
                                            <div className="d-flex flex-row align-items-center gap-3">
                                              <span>
                                                <img
                                                  src={user.preview}
                                                  width={52}
                                                  height={52}
                                                  alt="Logo Not Found"
                                                />
                                              </span>
                                              <span>
                                                <div className="font-16 font-bolder">
                                                  {user.path}
                                                </div>
                                                {/* <div className="text-muted">
                                      {user.size}
                                    </div> */}
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="d-flex flex-row justify-content-between align-items-center gap-3">
                                              <div
                                                onClick={() =>
                                                  removeProductImg(index)
                                                }
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="Remove image"
                                              >
                                                <DeleteIconWhite className="cursor" />
                                              </div>
                                              <div
                                                {...provider.dragHandleProps}
                                              >
                                                <span
                                                  className="cursor"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  title="Sort image(move up/down)"
                                                >
                                                  <img
                                                    src={DragHandle}
                                                    width={40}
                                                    height={40}
                                                    alt="Logo Not Found"
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provider.placeholder}
                                </tbody>
                              )}
                            </Droppable>
                          </table>
                        </DragDropContext>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* PRODUCT VIDEO */}
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mt-2 px-4 w-100">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <Tag />
                <span className="font-20 font-bold">{t("Product Video")}</span>
              </div>
            </div>

            <div className="d-flex flex-column gap-4 mt-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row justify-content-between align-items-centergap-2">
                  <span className="font-bold font-14">
                    {t("Choose Product Video URL/Video file")}
                  </span>
                  <ReactSwitchButton
                    onChange={() => videoFileHandler()}
                    value={videoFileFlag}
                  // disabled={videoFileFlag}
                  />
                </div>
                {!videoFileFlag && (
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Product video url")}
                      </span>
                      <InfoTooltip title={"Paste product video url"} />
                    </div>

                    <InputField
                      name="videoUrl"
                      placeholder={t("Product video url")}
                      type="text"
                      // maxLength={TITLE_TEXT_LIMIT}
                      value={videoUrl}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}
                {videoFileFlag && (
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Product video")}
                        <span> {t("(size should be 100MB)")}</span>
                      </span>
                      <InfoTooltip title={"Upload product video"} />
                    </div>
                    <DragandDropVideo
                      files={productVideo}
                      setFiles={setProductVideo}
                      fileType=".mp4"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column bg-white shadow-sm position-relative rounded-3 py-4 px-4 w-100 gap-4 mt-2">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <GreenTag />
                <span className="font-20 font-bold">
                  {t("Terms & Condition")}
                </span>
              </div>
            </div>

            <div className="d-flex flex-column gap-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row align-items-center gap-2">
                  {/*  <span className="font-bold font-14">Message to reviewer</span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />*/}
                </div>
                {termConditionTxt.map((item, i) => (
                  <div className="d-flex flex-column gap-3" key={i}>
                    <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">
                          {t("Terms & Condition")} #{i + 1} {item.lang.language}
                          <span className="text-danger">
                            {i === 0 ? "*" : ""}
                          </span>
                        </span>
                        {/* <InfoTooltip title={"Maximum 100 characters. No HTML or emoji allowed"} /> */}
                      </div>
                      {termConditionTxt.length !== 1 && (
                        <div
                          className="cursor"
                          onClick={() => handleRemoveClick(i)}
                        >
                          {i !== 0 && <CloseIcone />}
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <TextEditor
                        code={item.lang.language_code}
                        editorState={item.value}
                        getDescription={getTermAndCondition}
                        lang={props?.lang}
                      />
                      {error.editorState && i === 0 ? (
                        <label className="text-error">
                          {error.editorState}
                        </label>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex">
              <div className="btn-group w-100">
                <div
                  className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                  data-bs-toggle="dropdown"
                >
                  <span>{t("Add other language Terms & Condition")}</span>
                </div>
                <ul
                  className={`dropdown-menu rounded-card-7 dropdown-menu-start ${props?.lang === "ar" ? "dropdown-menu-ar" : ""
                    }`}
                >
                  {Preferredlanguage &&
                    Preferredlanguage.map((item, i) => (
                      <li key={i} onClick={() => handleDropdownOnClick(item)}>
                        <div className="dropdown-item cursor">
                          {item.language}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="d-flex">
              <Button
                onClick={() => submit()}
                text={t("Update")}
                type="primary"
              />
            </div>
          </div>
        </div>
        <div className="width-35 position-relative my-5 pt-4">
          <CardPreview />
        </div>
      </div>
    </div>
  );
};

export default MerchandiseEditScreen;
