/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import GoogleIncIcon from "../../assests/img/GoogleInc.png";
import { ReactComponent as MailIcon } from "../../assests/svg/mail.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import Collapse from "react-bootstrap/Collapse";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg";
import { ReactComponent as RadishTag } from "../../assests/svg/Tag/radish_tag.svg";
import { ReactComponent as EditButton } from "../../assests/svg/edit.svg";
import SmallToast from "../../components/Toast/SmallToast";
import CopyToClipboard from "react-copy-to-clipboard";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import { useTranslation } from "react-i18next";

const SupplierInfo = (props) => {
  const { selectedSupplier } = props
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const countries = useSelector((state) => state?.countries?.countries);

  const [permissions, setpermissions] = useState();
  const [id, setId] = useState("");


  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    // console.log(permissions);
  }, [permissions]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCountriesList());
  }, []);

  const [supplierCurrencies, setSupplierCurrencies] = useState([]);
  const [supplierCollapse, setSupplierCollapse] = useState(false);

  useEffect(() => {
    let arr = [];
    countries &&
      countries.map((conItem) => {
        selectedSupplier &&
          selectedSupplier?.currencies_id.map((item) => {
            if (conItem?.id === item) {
              arr.push(conItem);
            }
          });
      });
    setSupplierCurrencies(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplier, countries]);

  const editButtonhandler = (item) => {
    history("/edit-partner", {
      state: {
        partnerItem: selectedSupplier,
        partnerType: "Supplier",
      },
    });
  };

  // copy to clipboard
  // const copyToClipboard = async (id) => {
  //   /* Copy the id and show copied id in toast */
  //   if (id !== null && id !== "undefined") {
  //     setId(id);
  //     var x = document.getElementById("toast");
  //     x.className = "show";
  //     setTimeout(function () {
  //       x.className = x.className.replace("show", "");
  //     }, 3000);
  //   }
  // };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 w-100">
      <div className="d-flex px-4 gap-3 pt-2 align-items-center">
        <RadishTag />
        <span className="font-22 font-boldest text-dark">{t("Supplier Info")}</span>
      </div>
      <div className="d-flex flex-row gap-4 justify-content-between align-items-center">
        <div className="d-flex flex-row gap-3 py-2  align-items-center">
          {/* <div className="d-flex flex-row"> */}
          <div className="d-flex flex-column gap-3 mb-1  py-3  px-3 align-items-center">
            <div className="d-flex flex-row mx-2 gap-4 justify-content-between">
              <img
                src={GoogleIncIcon}
                alt="not found"
                className="img-fluid rounded-3 "
              />

              <div>
                <span className="font-22 font-bold text-dark">
                  {t("Name")} :{" "}
                  <span className="font-22 font-boldest text-dark ">
                    {selectedSupplier?.partner_name}
                  </span>
                  {permissions?.all_suppliers?.create ?
                    <span className="font-22 font-boldest text-dark mx-2 cursor" onClick={() => editButtonhandler()}>
                      <EditButton />
                    </span>
                    : null}
                </span>
                <br />
                <span className="font-15 font-bold text-dark">
                  {t("Id")} :{" "}
                  <span className="font-15 font-bold text-muted"
                    title={selectedSupplier?.id}>
                    {selectedSupplier?.id}
                    {/* <span>...</span> */}
                  </span>
                  <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("Copy to clipboard")}
                    className="cursor "
                    value={selectedSupplier?.id}
                    // onClick={() => copyToClipboard(selectedSupplier?.id)}
                  >
                    {/* <CopyToClipboard text={selectedSupplier?.id}>
                      <CopyButton />
                    </CopyToClipboard> */}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-start">
          <span className="font-15 font-boldest px-2">
            <MailIcon /> {selectedSupplier?.email}
          </span>
        </div>
        {/*   {permissions?.all_suppliers?.create ? (
          <div className="d-flex flex-end px-3 align-items-center">
            <Button
              type="rounded"
              text="+ Edit Account"
              className="rounded-5 my-4 mt-6 fw-bolder"
              onClick={() => editButtonhandler()}
            />
          </div>
        ) : null}*/}
      </div>

      <div className="d-flex justifiy-content-center px-4 gap-3">
        <div
          className="d-flex flex-row justify-content-between align-items-center py-2 px-1 gap-3 rounded-3"
          onClick={() => setSupplierCollapse(!supplierCollapse)}
        >
          <div
            className="d-flex flex-column cursor gap-3"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            <div className="d-flex align-items-center gap-4">
              {/* <ProductIcon /> */}
              <span className="font-18 font-bold">
                {t("Supplier Operational Countries")}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div
              className={`cursor p-0 ${supplierCollapse ? "rotate-180" : ""}`}
              aria-controls="example-collapse-text"
              aria-expanded={false}
            >
              <DropDownIcon
                className={`cursor p-0 ${supplierCollapse ? "rotate-180" : ""}`}
              />
            </div>
          </div>
        </div>
      </div>
      {/* )} */}

      <div className="px-5">
        <Collapse in={supplierCollapse}>
          <div
            id="panelsStayOpen-collapseOne"
            className="mt-2"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div className="card-body d-flex flex-column justify-content-between p-2 gap-4">
              <table responsive="xl">
                <tbody>
                  <tr className="col gap-3 border-bottom ">
                    <td className="col">
                      <div>
                        <span className="font-16 font-bold px-3">
                          {t("Countries")}
                        </span>
                      </div>
                    </td>
                    <td className="col">
                      <div className="d-flex flex-end justify-content-end px-4">
                        <span className="font-16 font-bold ">{t("Currencies")}</span>
                      </div>
                    </td>
                  </tr>

                  {supplierCurrencies &&
                    supplierCurrencies.map((item, i) => (
                      <tr className="col gap-3 border-bottom hover-row" key={i}>
                        <td className="col px-2 py-2">
                          {item?.name + " (" + item?.iso3 + ")"}
                        </td>
                        <td className="col">
                          <div className="d-flex flex-end justify-content-end px-4">
                            <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                              {item?.currency + " - " + item?.currency_symbol}
                            </span>
                          </div>
                        </td>
                      </tr>
                      // </tr>
                    ))}
                </tbody>
              </table>
              {!supplierCurrencies.length && (
                <div className="d-flex flex-row justify-content-center py-3">
                  <span className="font-20 font-bold text-error">
                    {t("No country & Currencies found")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Collapse>
      </div>
      {/* <div>
        <SmallToast data={id ? id : ""} message={"Copied"} />
      </div> */}
    </div>
  );
};
export default SupplierInfo;
