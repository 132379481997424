/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import giftcard_default from "../../assests/img/giftcard-default.png";
import Button from "../Button/Button";
import InputGroupTxt from "../InputField/InputGroupTxt";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../Toasts/Toasts";
//import BackButton from "../../components/Button/BackButton";
import Dropdown from "react-bootstrap/Dropdown";
import { putClientGiftcardMappingProd } from "../../Redux/MappingProducts/Client/Giftcards/ClientGiftcardAction";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import Select from "react-select";
import ModelForGiftCardSummery from "../Models/ModelForGiftCardSummery";
import ModalComp from "../Models/ModalComp";
import { fetchGiftCardSummeryById } from "../../Redux/GiftCard/giftcardAction";
import Multiselect from "multiselect-react-dropdown";
import { getAllBrandCategoryList } from "../../Redux/BrandCategories/brandCategoryAction";
import { getClientGcDetailList } from "../../Redux/Distributions/ClientGiftcardDistributions/ClientGiftcardDistributionAction";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";

const EditMappingGiftcardWithClient = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  //  const suppPriceForGc = useSelector((state) => state?.clientMappingData?.supplierPrice);


  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );
  const selectedClients = useSelector(
    (state) => state?.clients?.selectedClient
  );
  const categoriesState = useSelector(
    (state) => state.brandCategories.brandCategories
  );
  const selectedSupplier = useSelector(
    (state) => state?.giftcardSuppMappingReducer?.selectedSupplier
  );
  const clientmappedGiftcardList = useSelector(
    (state) => state?.clientMapGcList?.clientMappedGcList
  );
  // for model
  const [showModal, setShowModal] = useState(false);

  const [products, setProducts] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showHideCheckBox, setShowHideCheckBox] = useState(true);
  const [isDiscount, setIsDiscount] = useState(false);
  const [discount, setDiscount] = useState("");
  const [unmappProducts, setUnmappProducts] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState([]);

  const toggleShowToast = () => setShowToast(!showToast);
  const handleClose = () => setShowModal(false);
  const [error, setError] = useState({
    sellingPrice: "",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    dispatch(getAllBrandCategoryList());
    dispatch(getClientGcDetailList(location?.state?.partnerId));
  }, [location?.state?.partnerId]);

  useEffect(() => {
    if (!selectedCategorie.length) {
      setProducts(unmappProducts);
    }
  }, [selectedCategorie]);

  useEffect(() => {
    if (!location?.state?.partnerId) {
      history("/clients-mapping-dashboard");
    }
  }, [location?.state?.partnerId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let productsList = clientmappedGiftcardList ? clientmappedGiftcardList : [];
    let arr = [...productsList];
    let prodArr = [];

    arr &&
      arr.map((item, i) => {
        let obj = {
          product: item?.giftcard,
          client: location.state?.selectedClient && location.state?.selectedClient,
          supplier:
            item.suppliers && item.suppliers.length === 1
              ? item.suppliers[0]
              : "",
          supplierDiscount: "",
          clientDiscount: item?.client_discount ? item?.client_discount : "",
          profitDiscount: "",
          hadayaMargin: 0,
        };
        prodArr.push(obj);
      });
    setProducts(prodArr);
    setUnmappProducts(prodArr);
  }, [clientmappedGiftcardList]);

  useEffect(() => {
    if (selectedSupplier) {
      let productsList = clientmappedGiftcardList
        ? clientmappedGiftcardList
        : [];
      let arr = [...productsList];
      let prodArr = [];
      let supp = "";
      arr &&
        arr.map((item, i) => {
          item?.suppliers &&
            item?.suppliers.map((item) => {
              if (item?.supplier_id === selectedSupplier?.id) {
                supp = item;
              }
            });

          let obj = {
            product: item?.giftcard,
            client: selectedClients,
            supplier: supp ? supp : "",
            supplierDiscount: "",
            clientDiscount: item?.client_discount ? item?.client_discount : "",
            profitDiscount: "",
            hadayaMargin: 0,
          };
          prodArr.push(obj);
        });

      setProducts(prodArr);
      setUnmappProducts(prodArr);
    }
  }, [clientmappedGiftcardList, selectedSupplier]);

  const isDiscountHandle = (e) => {
    setIsDiscount(e?.target?.checked);
    let arr = [...products];
    setDiscount("");
    arr &&
      arr.map((item, i) => {
        arr[i].clientDiscount = "";
        arr[i].hadayaMargin = 0;
      });
    setProducts(arr);
  };

  const setAllGiftcardDiscount = (e) => {
    let clinetDiscount = e.target.value;
    // let index = e.target.name;

    let arr = [...products];
    let regex = /^\d{0,2}(\.\d{0,2})?$/;

    if (regex.test(clinetDiscount)) {
      setDiscount(clinetDiscount);

      arr &&
        arr.map((item, i) => {
          let clientPrice = parseFloat(clinetDiscount);
          let supplierPrice = parseFloat(item?.supplier?.supplier_discount);

          let profitDiscount = supplierPrice - clientPrice;

          if (supplierPrice >= clientPrice) {
            arr[i].clientDiscount = clinetDiscount;
            arr[i].hadayaMargin = profitDiscount.toFixed(2);
          } else {
            arr[i].clientDiscount = "";
            arr[i].hadayaMargin = 0;
          }
        });
    }
    setProducts(arr);
  };

  const handleBrandCategory = (selectedList, removedItem) => {
    setSelectedCategorie(selectedList);
    let arr = [];
    unmappProducts &&
      unmappProducts.map((item, i) => {
        if (
          selectedList &&
          selectedList.some((b) => item?.product?.brand_category_id === b?.id)
        ) {
          arr.push(item);
        }
      });
    setProducts(arr);
  };

  const handleRemoveBrandCategory = (selectedList, removedItem) => {
    setSelectedCategorie(selectedList);
    let arr = [];
    unmappProducts &&
      unmappProducts.map((item, i) => {
        if (
          selectedList &&
          selectedList.some((b) => item?.product?.brand_category_id === b?.id)
        ) {
          arr.push(item);
        }
      });
    setProducts(arr);
  };

  const allCheckedHandle = (e) => {
    const { checked } = e.target;
    let arr = [...products];
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = checked;
      });
    setProducts(arr);
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...products];
    arr &&
      arr.map((item, i) => {
        if (i === parseInt(name)) {
          item.isChecked = checked;
        }
      });
    setProducts(arr);
  };

  const handleChangeSupplier = (e, { name }) => {
    let id = name;
    let arr = [...products];
    arr &&
      arr.map((item, i) => {
        if (i === id) {
          arr[i].supplier = e;
          arr[i].clientDiscount = "";
          arr[i].hadayaMargin = 0;
        }
      });
    setProducts(arr);
  };

  // Client discount handler
  const clientDiscountHandler = (e) => {
    let clinetDiscount = e.target.value;
    let index = e.target.name;

    let arr = [...products];
    let regex = /^\d{0,2}(\.\d{0,2})?$/;

    if (regex.test(e.target.value)) {
      arr &&
        arr.map((item, i) => {
          let clientPrice = parseFloat(clinetDiscount);
          let supplierPrice = parseFloat(item?.supplier?.supplier_discount);

          let profitDiscount = supplierPrice - clientPrice;

          if (i === parseInt(index)) {
            if (supplierPrice >= clientPrice) {
              arr[i].clientDiscount = clinetDiscount;
              arr[i].hadayaMargin = profitDiscount.toFixed(2);
            } else {
              arr[i].clientDiscount = "";
              arr[i].hadayaMargin = "";
            }
          }
        });
    }
    setProducts(arr);
  };

  const sellingInputHandler = (e, index) => {
    let id = e.target.name;
    let arr = [...products];
    arr &&
      arr.map((item, i) => {
        if (i === id) {
          arr[i].clientDiscount = e;
        }
      });
    setProducts(arr);
  };

  const addRow = (item) => {
    let arr = [...products];

    let obj = {
      ...item,
      client: selectedClients,
      supplier: "",
      supplierDiscount: "",
      clientDiscount: "",
      isChecked: false,
    };

    arr && arr.unshift(obj);
    setProducts(arr);
  };

  const deleteRow = (index) => {
    let arr = [...products];
    if (index > -1) {
      arr.splice(index, 1);
    }
    setProducts(arr);
    if (arr.length === 0) {
      setShowHideCheckBox(false);
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </a>
  ));

  const validate = () => {
    let checkArr = [];

    products &&
      products.map((item) => {
        if (item.isChecked) {
          checkArr.push(item);
        }
      });
    if (checkArr.length > 0) {
      //  let valSKU = checkArr && checkArr.every(v => v.supplierSKU);
      let valSupplier = checkArr && checkArr.every((v) => v.supplier);
      //let valSellingPrice = checkArr && checkArr.every((v) => v.sellingPrice);

      valSupplier
        ? setError({ supplier: "" })
        : setError({ supplier: t("Select supplier") });

      return valSupplier ? true : false;
    } else {
      toggleShowToast();
      return false;
    }
  };

  const successPage = () => {
    return history("/clients-map-successfull");
  };

  const submit = () => {
    let value = validate();

    if (value) {
      let arr = [...products];
      let selectedProducts = [];

      arr &&
        arr.map((item) => {
          if (item.isChecked === true) {
            selectedProducts.push(item);
          }
        });
      if (selectedProducts.length > 0) {
        dispatch(putClientGiftcardMappingProd(selectedProducts, successPage));
        // history("/clients-map-successfull");
      }
    }
  };

  const showModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowModal(true);
  };

  return (
    <div className="d-flex flex-column justify-content-start gap-3 py-4 pb-3 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon className="cursor" onClick={() => history(-1)} />
          <span className="font-bold font-32">{t("Client mapping")}</span>
        </div>
      </div>

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 w-100 py-3 px-4 gap-3 ">
        <div className="card-body d-flex flex-column justify-content-between ">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">{t("Mapping giftcard")}</span>
            <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search Product")}
                onChange={searchHandler}
              />
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center gap-4 mx-2">
            <div className="d-flex flex-row justify-content-start align-items-center gap-2 my-3">
              <input
                className="form-check-input cursor"
                type="checkbox"
                id="applyAll"
                checked={isDiscount}
                onChange={isDiscountHandle}
              />
              <span className="font-15 font-bold my-3">
                {t("Discount for all entries")}
              </span>
            </div>
            <div className="d-flex width-20 ">
              {isDiscount && (
                <InputGroupTxt
                  //  name={index}
                  placeholder={t("Discount")}
                  type="text"
                  title="%"
                  lang={props?.lang}
                  value={discount}
                  onChange={setAllGiftcardDiscount}
                />
              )}
            </div>
            <div className="d-flex width-30">
              <Multiselect
                placeholder={t("Select category")}
                options={categoriesState}
                showCheckbox={true}
                selectedValues={selectedCategorie}
                onSelect={handleBrandCategory}
                onRemove={handleRemoveBrandCategory}
                displayValue="default_category_name"
                className={`${props?.lang === 'ar' ? 'multiSelectContainer-ar' : ''}`}
              />
            </div>
          </div>
        </div>
        <div className="card-body d-flex flex-column px-2 gap-3">
          <table responsive="xl" cellPadding={3} cellSpacing={0}>
            <tbody>
              <tr className="border-bottom">
                <td className="col-2">
                  <div className="d-flex flex-row justify-content-start text-dark gap-3">
                    {showHideCheckBox === true ? (
                      <span>
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="checkboxNoLabel"
                          checked={
                            products.filter(
                              (product) => product?.isChecked !== true
                            ).length < 1
                          }
                          onChange={allCheckedHandle}
                        />
                      </span>
                    ) : null}

                    <span className="font-12 font-boldest text-muted">
                      {t("Name")}
                    </span>
                  </div>
                </td>
                <td className="col-2 ">
                  <div>
                    <span className="font-12 font-boldest text-muted">
                      <div>{t("Client")}</div>
                      <div>{t("name")}</div>
                    </span>
                  </div>
                </td>
                <td className="col-2 px-2">
                  <div>
                    <span className="font-12 font-boldest text-muted">
                      <div>{t("Supllier")}</div>
                      <div>{t("name")}</div>
                    </span>
                  </div>
                </td>
                <td className="col-2 px-2">
                  <div>
                    <span className="font-12 font-boldest text-muted">
                      <div>{t("Supllier")}</div>
                      <div>{t("discount")}</div>
                    </span>
                  </div>
                </td>
                <td className="col-2 px-2">
                  <div>
                    <span className="font-12 font-boldest text-muted">
                      <div>{t("Discount to")}</div>
                      <div>{t("client")}</div>
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-12 text-success font-boldest">
                      <div>{t("Hadaya")}</div>
                      <div>{t("margin")}</div>
                    </span>
                  </div>
                </td>
              </tr>
              {/* {products.map((item, index) => (*/}
              {products &&
                products
                  ?.filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.product?.default_title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => (
                    <tr key={index} className="border-bottom">
                      <td className="col-4">
                        <div className="d-flex flex-row justify-content-start align-items-center py-1 gap-1">
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="checkboxNoLabel"
                            checked={item.isChecked}
                            name={index}
                            onChange={handleCheck}
                          />
                          <img
                            src={
                              item?.product?.images &&
                                item?.product?.images[0].file_url
                                ? item?.product?.images[0].file_url
                                : giftcard_default
                            }
                            alt="not found"
                            // className="img-fluid mx-2"
                            // width="50px"
                            // height="50px"
                            id="product-thumbnail"
                          />
                          <div className="d-flex flex-column justify-content-center align-items-start">
                            <span
                              className="font-bold text-dark font-12 pt-2 d-inline-block text-truncate cursor"
                              style={{
                                width: "fit-Content",
                                maxWidth: "100px",
                              }}
                              title={item?.product?.default_title}
                              onClick={() => showModel(item?.product?.id)}
                            >
                              {item?.product?.default_title}
                            </span>
                            <span
                              className="font-12 text-muted d-inline-block text-truncate cursor"
                              style={{
                                width: "fit-Content",
                                maxWidth: "100px",
                              }}
                              title={item?.product?.id}
                            >
                              {item?.product?.id}
                            </span>
                          </div>
                        </div>
                      </td>
                      {/*client dropdown*/}
                      <td className="col-1">
                        <div className="d-flex flex-column align-items-start mb-2">
                          <span
                            className="font-bold text-dark font-15 d-inline-block text-truncate cursor"
                            style={{ width: "fit-Content", maxWidth: "100px" }}
                            title={item?.client?.partner_name}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {item?.client?.partner_name}
                          </span>
                        </div>
                      </td>
                      {/*Supplier dropdown*/}
                      <td className="col-2 p-2">
                        <div className="d-flex flex-column">
                          <Select
                            placeholder="Select"
                            noOptionsMessage={() => "Not found"}
                            getOptionLabel={(suppliersListState) =>
                              suppliersListState.name
                            }
                            options={item.product.suppliers}
                            styles={CUSTOM_DROPDOWN_STYLE}
                            value={item.supplier}
                            onChange={handleChangeSupplier}
                            isClearable
                            name={index}
                          />
                          {!item.supplier && item.isChecked ? (
                            <label className="text-error">
                              {error.supplier}
                            </label>
                          ) : null}
                        </div>
                      </td>
                      <td className="col-2 p-2">
                        <div className="d-flex flex-column align-item-center justify-content-center gap-2">
                          <div className="d-flex justify-content-start align-items-center ">
                            <InputGroupTxt
                              id="abc"
                              title={"%"}
                              type="text"
                              lang={props?.lang}
                              // onChange={suplierDiscountHandler}
                              name={index}
                              value={
                                item?.supplier?.supplier_discount
                                  ? item?.supplier?.supplier_discount
                                  : 0
                              }
                              disabled={true}
                            // onKeyDown={onKeyHandle}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="col-2 p-2">
                        <div className="d-flex flex-column align-item-center justify-content-center gap-1">
                          <div className="d-flex justify-content-center ">
                            <InputGroupTxt
                              title={"%"}
                              placeholder={"discount"}
                              type="text"
                              lang={props?.lang}
                              value={item.clientDiscount}
                              onChange={clientDiscountHandler}
                              name={index}
                              maxLength="10"
                              disabled={!item?.supplier?.supplier_discount}
                            />
                          </div>
                          <div className="d-flex">
                            {!item.sellingPrice && item.isChecked ? (
                              <label className="text-error">
                                {error.sellingPrice}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      </td>
                      {/* <td className="col-2 p-2">
                      <div className="d-flex flex-column align-item-center">
                        {item.sellingPrice > item.supplierPrice ? (
                          <div className="d-flex flex-row align-item-center gap-1">
                            <UpArrow className="" />
                            <span className="font-13 font-bold brand-color-05">
                              {item.profitPercentage}
                            </span>
                          </div>
                        ) : (
                          <div className="d-flex flex-row align-item-center gap-1">
                            <RedDownArrow />
                            <span className="font-13 font-bold brand-color-03">
                              {item.profitPercentage}
                            </span>
                          </div>
                        )}
                      </div>
                    </td> */}
                      <td className="col-1 p-1">
                        <div className="font-15 font-bold text-muted text-center">
                          {/* {item.margin > 0 && `$+${item.margin}`}
                        {item.margin < 0 && `$${item.margin}`} */}
                          {` ${item.hadayaMargin}%`}
                        </div>
                      </td>
                      <td className="col-1 p-2">
                        <div className="d-flex flex-row gap-2">
                          <Dropdown className="cursor">
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title="">
                              <Dropdown.Item
                                className="cursor"
                                onClick={() => deleteRow(index)}
                              >
                                {t("Delete")}
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => addRow(item)}>
                                {t("Add new row")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex">
        <Button type="primary" text={t("Update")} onClick={() => submit()} />
      </div>

      <ModalComp
        size="lg"
        showModal={showModal}
        // handleClose={handleClose}
        component={
          <ModelForGiftCardSummery
            handleClose={handleClose}
            giftcardSummery={giftcardSummery}
          />
        }
      />
      <div
        className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Please select giftcards to map."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default EditMappingGiftcardWithClient;
