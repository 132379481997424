import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import SigninLeftPanel from "../../components/SigninLeftPanel/SigninLeftPanel";
import { ReactComponent as Mail } from "../../assests/svg/Sidebar/mail.svg";
import { ReactComponent as Lock } from "../../assests/svg/lock.svg";
import { useTranslation } from "react-i18next";
import { UseOnKeyDown } from "../../components/hooks/UseOnKeyDown";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import axios from "axios";
import { USER_AUTHORIZATION } from "../../utils/prodURL";
import SignInToast from "../../components/Toasts/SignInToast";
import PulseLoader from "../../components/Loader/PulseLoader";

const SigninScr = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const location =useLocation();
console.log(location?.state);
  const [auth, setAuth] = useState({ username: "", password: "" });
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);

  const [error, setError] = useState({
    email: "",
    password: "",
    message: "",
  });

  // to hide and show password
  const [passShowHide, setPassShowHide] = useState(false);

  const togglePassword = () => {
    setPassShowHide((prevState) => !prevState);
  };

  const goToForgotPass = () => {
    history("/forget-pass", {
      state: {
        username: auth.username,
        message:message
      },
    });
  };

  const handleChangeValue = (e, type) => {
    setAuth({ ...auth, [type]: e.target.value });
    setError({ ...error, [type]: "" });
  };

  const signInValidate = () => {
    if (auth.username === "" || auth.username === null) {
      setError({ ...error, email: "Please enter email or username" });
      return false;
    }
    if (auth.password === "" || auth.password === null) {
      setError({ ...error, password: "Please enter password" });
      return false;
    }
    return true;
  };

  const signIn = () => {
    setLoading(true);
    let value = signInValidate();

    if (value) {
      let config = {
        method: "POST",
        url: `${USER_AUTHORIZATION}/v1/user/login/otp?user_identifier=${auth.username}&password=${auth.password}`,
        headers: {},
      };
      axios(config)
        .then((response) => {
          if (response?.data?.status === "success" || response?.data?.code === 429) {
            if (response?.data?.code == 429) {
              goToOTPScr("Otp has been already sent. Please use the that OTP.");
            }
            else {
              goToOTPScr(response?.data?.message);
            }
          } else if (response?.data?.status === "error") {
            // setMessage(response?.data?.errors[0])
            setMessage("login credential is incorrect")
            toggleShowToast()
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("location?.state?.message ", location?.state)
    if(location?.state){
      setMessage(location?.state?.message);
      toggleShowToast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.message]);


  const goToOTPScr = (message) => {
    history("/otp", {
      state: {
        username: auth.username,
        password: auth.password,
        message: message
      },
    });
  };

  //following function is used to detect enter key hit
  UseOnKeyDown(signIn, "Enter");

  return loading ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex row align-items-center bg-white">
      <SigninLeftPanel />
      <div className="col">
        <div className="d-flex justify-content-center align-items-start ">
          <div className="d-flex flex-column justify-content-start gap-3">
            <h1 className="heading-text">{t("Sign in")}</h1>
            <span className="font-14 font-bold">
              {t("Sign in using the credential provided to you.")}
            </span>
            <div className="d-flex flex-column justify-content-start gap-1">
              <div className="Inputwithicon">
                <input
                  className="input-custom w-100   border-2"
                  type="text"
                  placeholder={t("Your email or Username")}
                  name="username"
                  value={auth.username}
                  autoFocus={auth.username ? true : false}
                  onChange={(e) => handleChangeValue(e, "username")}
                />
                <div id="txt-icon">
                  <Mail />
                </div>
              </div>
              {error.email && !auth.username ? (
                <label className="text-error">{error.email}</label>
              ) : null}
            </div>
            <div className="d-flex flex-column justify-content-start gap-1 position-relative">
              <div className="Inputwithicon">
                <input
                  className="input-custom w-100   border-2"
                  type={passShowHide ? "text" : "password"}
                  placeholder={t("Password")}
                  name="password"
                  value={auth.password}
                  onChange={(e) => handleChangeValue(e, "password")}
                />
                <div id="txt-icon">
                  <Lock />
                </div>
                <div className="input-group-append position-absolute position-absolute top-0 end-0">
                  <button
                    className="btn  bg-tranparent "
                    type="button"
                    onClick={() => togglePassword()}
                  >
                    {" "}
                    <div
                      className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
                      id="liveToast"
                    >
                      {/* <Toasts
                      position={"bottom-center"}
                      showToast={showToast}
                      toggleShowToast={toggleShowToast}
                      warningMsg={message}
                      imageType={"warning"}
                    /> */}
                    </div>
                    {passShowHide ? (
                      <AiFillEye className="text-muted" />
                    ) : (
                      <AiFillEyeInvisible className="text-muted" />
                    )}
                  </button>
                </div>
              </div>
              {error.password && !auth.password ? (
                <label className="text-error">{error.password}</label>
              ) : null}
            </div>
            <div className="">
              <Button
                text={t("Sign In")}
                type="primary"
                onClick={() => {
                  !loading ? signIn() : console.log("please wait");
                }}
              />
            </div>
            <div className="">
              <span
                onClick={() => goToForgotPass()}
                className="font-14 font-bold cursor text-light-blue"
              >
                {t("Forgot password?")}
              </span>
              {/* <span>
                  <span className="font-14 shades-grey-dark-00">
                    {t("Don't have an account?")}
                  </span>
                  <span className="text-light-blue font-14 font-bold cursor">
                    {" "}
                    {t("Request here")}
                  </span>
                </span> */}
            </div>
          </div>
        </div>
        <div
          className="position-fixed bottom-0 start-50 pb-3 translate-middle-x w-100 "
          id="liveToast"
        >
          <SignInToast
            position={"bottom-center"}
            showToast={showToast}
            toggleShowToast={toggleShowToast}
            warningMsg={message}
            successMsg={message}
            imageType={"warning"}
          />
        </div>
      </div>
    </div>
  );
};

export default SigninScr;
