/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import Default_giftcard from "../../assests/img/giftcard-default.png";
import Default_product from "../../assests/img/Product-default.png";
import Button from "../Button/Button";
import Toasts from "../Toasts/Toasts";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSupplierMapMerchandise,
  fetchSupplierMapProducts,
} from "../../Redux/Supplier/supplierAction";
import { strFirstLetterCap } from "../../utils/constantFunction";
import PulseLoader from "../Loader/PulseLoader";
import ModalComp from "../../components/Models/ModalComp";
import ModelForGiftCardSummery from "../../components/Models/ModelForGiftCardSummery";
import ModelForMerchandiseSummery from "../../components/Models/ModelForMerchandiseSummery";
import { getMerchandiseSummeryById } from "../../Redux/Merchandise/merchandiesAction";
import { fetchGiftCardSummeryById } from "../../Redux/GiftCard/giftcardAction";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import SmallToast from "../Toast/SmallToast";
import { OrderFilter, PRODUCT_TYPE } from "../../utils/ConstantData";
import PulseLoaders from "react-spinners/PulseLoader";
import InfiniteScroll from "react-infinite-scroll-component";



const MappedGiftCards = (props) => {
  const { selectedSupplier } = props;
  const dispatch = useDispatch();

  const supplierMappedGiftCard = useSelector(
    (state) => state?.suppliers?.supplierMappedGiftCard
  );
  const supplierMappedMerchandise = useSelector(
    (state) => state?.suppliers?.supplierMappedMerchandise
  );
  const supplierMappedGiftCardLoading = useSelector(
    (state) => state?.suppliers?.loading
  );
  const supplierMappedMerchandiseLoading = useSelector(
    (state) => state?.suppliers?.loading
  );
  const hasmore = useSelector(
    (state) => state?.suppliers?.next_iteration);

  // set infinite loader For Giftcard
  const [giftcardSkip, setgiftcardSkip] = useState(
    supplierMappedGiftCard?.length === 0 ? -10 : supplierMappedGiftCard?.length - 10
  );
  const [giftcardlimit, setgiftcardlimit] = useState(10);

  // set infinite loader For Merchandise
  const [merchandiseSkip, setmerchandiseSkip] = useState(
    supplierMappedMerchandise?.length === 0 ? -10 : supplierMappedMerchandise?.length - 10
  );
  const [merchandiselimit, setmerchandiselimit] = useState(10);

  // for model Merchandise
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );
  const handleClose = () => setShowMerchandiseModal(false);
  const [showMerchandiseModal, setShowMerchandiseModal] = useState(false);
  const [id, setId] = useState("");
  // for search filter
  const [searchTerm, setSearchTerm] = useState("");
  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };
  // for model Giftcard
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );
  const handleGiftcardClose = () => setShowGiftcardModal(false);
  const [showGiftcardModal, setShowGiftcardModal] = useState(false);

  const [button, setButton] = useState("GiftCard");

  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);
  const [supplierMapMerchandise, setSupplierMapMerchandise] = useState([]);

  const [dropdownObj, setDropdownObj] = useState({
    filter: { id: 1, name: "Giftcard" },
  });

  useEffect(() => {
    console.log(supplierMappedGiftCard?.length)
    console.log(dropdownObj?.filter?.name)
    window.scrollTo(0, 0);
    if (dropdownObj?.filter?.name === "Giftcard") {
      if (supplierMappedGiftCard?.length === 0) {
        console.log("first")
        fetchGiftcardData(giftcardSkip);
      }
    }
    else {
      if (supplierMappedMerchandise?.length === 0) {
        fetchMerchandiseData(giftcardSkip);
      }
    }
  }, []);

  useEffect(() => {
    console.log("check", supplierMappedGiftCard, supplierMappedGiftCardLoading,
      supplierMappedMerchandiseLoading, supplierMappedGiftCard.length)
  }, [supplierMappedGiftCard])

  const fetchGiftcardData = () => {
    setTimeout(async () => {
      dispatch(fetchSupplierMapProducts(selectedSupplier?.id, giftcardSkip + giftcardlimit, giftcardlimit, supplierMappedGiftCard));
      setgiftcardSkip(giftcardSkip + giftcardlimit);
    }, 100);
  };

  const fetchMerchandiseData = () => {
    setTimeout(async () => {
      dispatch(fetchSupplierMapMerchandise(selectedSupplier?.id, merchandiseSkip + merchandiselimit, merchandiselimit, supplierMappedMerchandise));
      setgiftcardSkip(merchandiseSkip + merchandiselimit);
    }, 100);
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (dropdownObj?.filter?.name === "GiftCard") {
  //     dispatch(fetchSupplierMapProducts(selectedSupplier?.id));
  //   } else {
  //     dispatch(fetchSupplierMapMerchandise(selectedSupplier?.id));
  //   }
  // }, [selectedSupplier]);

  useEffect(() => {
    let arr = [...supplierMappedMerchandise];
    let prodArr = [];

    arr &&
      // eslint-disable-next-line array-callback-return
      arr?.map((item, i) => {
        let obj = {
          ...item,
          collapseFlag: false,
        };
        prodArr.push(obj);
      });
    setSupplierMapMerchandise(prodArr);
  }, [supplierMappedMerchandise]);

  const dropdownHandler = (index) => {
    let arr = [...supplierMappedMerchandise];
    // setOpenCollapse(!openCollapse);
    arr &&
      arr?.map((item, i) => {
        // console.log(index, i);
        if (index === i) {
          arr[i].collapseFlag = !item.collapseFlag;
        }
      });
    setSupplierMapMerchandise(arr);
  };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 5);
    return val;
  };

  // copy to clipboard
  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const productFilterHandle = (item, type) => {
    setDropdownObj({ ...dropdownObj, [type]: item });
    if (item?.name === "GiftCard") {
      dispatch(fetchSupplierMapProducts(selectedSupplier?.id));
    } else {
      dispatch(fetchSupplierMapMerchandise(selectedSupplier?.id));
    }
  }

  const showSummaryModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowMerchandiseModal(true);
  };

  const showGiftcardModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowGiftcardModal(true);
  };

  return (
    <>
      {supplierMappedGiftCardLoading === false &&
        supplierMappedMerchandiseLoading === false && supplierMappedGiftCard.length !== 0 ? (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
          <div className="d-flex flex-row justify-content-between py-2 align-items-center">
            <div className="d-flex flex-row ">
              <div className="d-flex gap-3 align-items-center">
                <Tag />
                <span className="font-20 font-bold">Mapped products</span>
                <div className="Inputwithicon">
                  <div id="search">
                    <Searchicon />
                  </div>
                  <input
                    type="text"
                    className="input-custom w-100"
                    name="search"
                    placeholder="Search Product"
                    onChange={searchHandler}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column gap-2 mx-2">
              <div className="btn-group w-10">
                <div
                  className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center"
                  data-bs-toggle="dropdown"
                >
                  <span className="text-muted">
                    {dropdownObj.filter
                      ? dropdownObj.filter.name
                      : "Filter product"}
                  </span>
                </div>
                <ul className={`dropdown-menu rounded-card-7 dropdown-menu-start ${props?.lang === 'ar' ? 'dropdown-menu-ar' : ''}`}>
                  {PRODUCT_TYPE &&
                    PRODUCT_TYPE.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => productFilterHandle(item, "filter")}
                      >
                        <div className="dropdown-item cursor">{item.name}</div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          {supplierMappedGiftCard && dropdownObj.filter.name === "Giftcard" ? (
            <InfiniteScroll
              dataLength={supplierMappedGiftCard?.length || 0}
              next={fetchGiftcardData}
              hasMore={hasmore}
              loader={
                <div className="d-flex justify-content-center">
                  {" "}
                  <PulseLoaders
                    color={"#594190"}
                    loading={hasmore ? true : false}
                    size={10}
                  />{" "}
                </div>
              }
            >
              <div className="card-body d-flex flex-column justify-content-between align-item-center gap-5 px-2">
                <table responsive="xl">
                  <tbody>
                    <tr className="border-bottom">
                      <td className="text-muted" colSpan={3}>
                        <div className="d-flex flex-row justify-content-start">
                          <span className="mx-3">Giftcard image/title</span>
                        </div>
                      </td>
                      <td className="text-muted">Giftcard id</td>
                      <td className="text-muted">Country & currency</td>
                      <td className="text-muted">Inventory type</td>
                      <td className="text-muted">Supplier discount</td>
                      {/* <td className="text-muted"> Edit</td> */}
                    </tr>
                    {supplierMappedGiftCard &&
                      supplierMappedGiftCard
                        .filter((item) => {
                          if (searchTerm === "") {
                            return item;
                          } else if (
                            item?.giftcard?.default_title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => (
                          <tr
                            className="card-body gap-3 border-bottom "
                            key={index}
                          >
                            <td colSpan={3}>
                              <div className="d-flex flex-row justify-content-start align-items-center py-2">
                                <div className="rounded-3 px-1">
                                  <img
                                    src={
                                      item?.giftcard?.images &&
                                        item?.giftcard?.images[0]
                                        ? item?.giftcard?.images[0]?.file_url
                                        : Default_giftcard
                                    }
                                    alt="not found"
                                    // className="img-fluid"
                                    // width="50px"
                                    // height="50px"
                                    id="product-thumbnail"
                                  />
                                </div>
                                <div className="d-flex flex-column justify-content-start mx-2">
                                  <span
                                    className="font-13 font-boldest text-dark d-inline-block text-truncate cursor"
                                    title={item?.giftcard?.default_title}
                                    style={{ maxWidth: "150px" }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    onClick={() =>
                                      showGiftcardModel(item?.giftcard?.id)
                                    }
                                  >
                                    {item?.giftcard?.default_title}
                                  </span>
                                  <span className="font-10 font-boldest text-muted">
                                    {strFirstLetterCap(
                                      item?.giftcard?.giftcard_value_type
                                    )}{" "}
                                  </span>
                                  <span
                                    className="font-10 font-bolder text-dark bg-04 rounded-3 px-1 d-inline-block text-truncate cursor"
                                    style={{
                                      width: "fit-Content",
                                      maxWidth: "120px",
                                    }}
                                    title={
                                      item?.giftcard?.giftcard_value_type ===
                                        "denominations"
                                        ? item?.giftcard?.denominations &&
                                        item?.giftcard?.denominations.map(
                                          (deno) => deno.denomination_value
                                        )
                                        : item?.giftcard?.min_amount +
                                        "-" +
                                        item?.giftcard?.max_amount
                                    }
                                  >
                                    {item?.giftcard?.giftcard_value_type ===
                                      "denominations"
                                      ? item?.giftcard?.denominations &&
                                      item?.giftcard?.denominations.map(
                                        (deno) => deno.denomination_value + ","
                                      )
                                      : item?.giftcard?.min_amount +
                                      "-" +
                                      item?.giftcard?.max_amount}
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex justify-content-start align-items-center">
                                <span
                                  className="font-14 font-boldest text-dark visible mt-4"
                                  title={item?.giftcard?.id}
                                >
                                  {trimIdHandler(item?.giftcard?.id)}
                                  <span>...</span>
                                </span>

                                <span
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Copy to clipboard"
                                  className="cursor "
                                  value={item?.giftcard?.id}
                                  onClick={() =>
                                    copyToClipboard(item?.giftcard?.id)
                                  }
                                >
                                  <CopyToClipboard text={item?.giftcard?.id}>
                                    <CopyButton />
                                  </CopyToClipboard>
                                </span>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex flex-row justify-content-center">
                                <span className="font-12 font-bold text-success bg-04 px-1 rounded-3">
                                  {`${item?.giftcard?.country?.currency}-(${item?.giftcard?.country?.currency_symbol})`}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="font-12 font-bold">
                                {item?.giftcard?.inventory_type_name}
                              </span>
                            </td>
                            <td>
                              <div className="d-flex flex-row justify-content-center">
                                <span className="font-12 font-boldest text-dark">
                                  {item?.supplier_discount}%
                                </span>
                              </div>
                            </td>
                            {/* <td>
              <span className=" px-1  ">
                <EditIcon/>
              </span>
            </td> */}
                          </tr>
                        ))}
                  </tbody>
                </table>
                {!supplierMappedGiftCard.length && (
                  <div className="d-flex flex-row justify-content-center py-3">
                    <span className="font-20 font-bold text-error">
                      Products Not Found
                    </span>
                  </div>
                )}
              </div>
            </InfiniteScroll>
          ) : (
            <div className="card-body d-flex flex-column justify-content-between align-item-center gap-5 px-2">
              <table responsive="xl">
                <tbody>
                  {/* <tr className="d-flex align-items-center text-muted font-14 px-4 font-bolder">
                    <td className="col-3">Merchandise title</td> */}
                  <tr className="d-flex align-items-center text-muted font-14 font-bolder">
                    <td className="col-4  px-4">
                      {/* Merchandise title */}
                      <div className="d-flex flex-row justify-content-start">
                        <span className="mx-3">Merchandise title</span>
                      </div>
                    </td>
                    <td className="col-3 px-1">Merchandise id</td>
                    <td className="col-3">Country & currency</td>
                    <td className="col-2">Selling price</td>
                  </tr>
                  {supplierMapMerchandise &&
                    supplierMapMerchandise
                      .filter((item) => {
                        if (searchTerm === "") {
                          return item;
                        } else if (
                          item?.merchandise?.default_title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => (
                        <tr
                          key={index}
                          className="d-flex flex-column justify-content-start px-4"
                        >
                          <div className="d-flex flex-row justify-content-start align-items-center ">
                            <td className="col-4 pt-3">
                              <div className="d-flex flex-row justify-content-start align-items-center">
                                <span className="p-2">
                                  <img
                                    src={
                                      item?.merchandise?.images &&
                                        item?.merchandise?.images
                                        ? item?.merchandise?.images[0]?.file_url
                                        : Default_product
                                    }
                                    alt="not found"
                                    // className="img-fluid"
                                    // width="50px"
                                    // height="50px"
                                    id="product-thumbnail"
                                  />
                                </span>
                                <div className="d-flex flex-column justify-content-start ">
                                  <span
                                    className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                                    title={item?.merchandise?.default_title}
                                    style={{ maxWidth: "150px" }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    onClick={() =>
                                      showSummaryModel(item?.merchandise?.id)
                                    }
                                  >
                                    {item?.merchandise?.default_title}
                                  </span>

                                  <span className="font-10 font-bold">
                                    {item?.product_class ===
                                      "merchandise_variant"
                                      ? item?.merchandise_variant &&
                                      item?.merchandise_variant.length +
                                      " variants"
                                      : "No variants"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="col-3 pt-3">
                              <div className="d-flex flex-column justify-content-start ">
                                <span
                                  className="font-13 font-bold text-muted d-inline-block text-truncate cursor"
                                  title={item?.merchandise?.id}
                                  style={{ maxWidth: "160px" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                >
                                  {item?.merchandise?.id}
                                </span>
                              </div>
                            </td>

                            <td className="col-3 pt-3">
                              <div className="d-flex flex-column justify-content-start mx-4">
                                <span className="font-15 font-bolder text-dark bg-04 rounded-3 px-1 fit-content">
                                  {`${item?.merchandise?.country?.currency}-(${item?.merchandise?.country?.currency_symbol})`}
                                </span>
                              </div>
                            </td>

                            <td className="col-2 pt-3">
                              <div className="d-flex justify-content-center  px-3">
                                <span className="d-flex font-12 font-boldest text-dark">
                                  {item?.supplier_price ? (
                                    item?.supplier_price
                                  ) : (
                                    <span>
                                      {item?.product_class ===
                                        "merchandise_variant" ? (
                                        <div
                                          className="d-flex justify-content-center"
                                          onClick={() => dropdownHandler(index)}
                                        >
                                          <DropDownIcon
                                            className={`cursor p-0 ${item?.collapseFlag
                                                ? "rotate-180"
                                                : ""
                                              }`}
                                          />
                                        </div>
                                      ) : null}
                                    </span>
                                  )}
                                </span>
                              </div>
                            </td>
                          </div>
                          {item?.collapseFlag && (
                            // <div id="example-collapse-text">
                            //   <div className="d-flex bg-white shadow rounded-3 ">
                            //     <div className="d-flex flex-column  w-50 gap-3 mx-3 my-3 ">
                            //       <div className="d-flex flex-row justify-content-center border-bottom">
                            //         <div className="d-flex align-items-center gap-2">
                            //           <span className="font-14 font-bolder ">
                            //             Variants List
                            //           </span>
                            //         </div>
                            //       </div>

                            //       <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                            //         {item?.product_class === "merchandise_variant"
                            //           ? item?.merchandise_variant &&
                            //             item?.merchandise_variant.map((vari, i) => (
                            //               <div className="d-flex flex-column justify-content-center align-items-center" key={i}>
                            //                 {vari?.variant?.variant_values.join(
                            //                   "|"
                            //                 )}
                            //               </div>
                            //             ))
                            //           : null}
                            //       </div>
                            //     </div>
                            //     <div className="border-start border-1 my-3"></div>
                            //     <div className="d-flex flex-column  w-50 gap-3 mx-3 my-3 ">
                            //       <div className="d-flex flex-row justify-content-center border-bottom">
                            //         <div className="d-flex align-items-center gap-2">
                            //           <span className="font-14 font-bolder ">
                            //             Variants price
                            //           </span>
                            //         </div>
                            //       </div>

                            //       <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                            //         {item?.product_class === "merchandise_variant"
                            //           ? item?.merchandise_variant &&
                            //             item?.merchandise_variant.map((vari,i) => (
                            //               <div className="d-flex flex-column align-items-center" key={i}>
                            //                 {vari?.supplier_price}
                            //               </div>
                            //             ))
                            //           : null}
                            //       </div>
                            //     </div>
                            //   </div>
                            // </div>
                            <div className="d-flex bg-white shadow rounded-3 ">
                              <table
                                className="table table-borderless"
                                responsive="xl"
                              >
                                <tbody>
                                  <tr className="">
                                    <td className="col-9">
                                      <span className="font-14 font-bolder ">
                                        Variants List
                                      </span>
                                    </td>
                                    <td className="col-3">
                                      <span className="font-14 font-bolder ">
                                        Price
                                      </span>
                                    </td>
                                  </tr>

                                  {item?.product_class === "merchandise_variant"
                                    ? item?.merchandise_variant &&
                                    item?.merchandise_variant.map(
                                      (vari, i) => (
                                        <tr key={i}>
                                          <td className="col-9">
                                            <div className="d-flex align-items-center gap-2">
                                              <img
                                                src={
                                                  vari?.images && vari?.images
                                                    ? vari?.images[0]
                                                      ?.file_url
                                                    : Default_product
                                                }
                                                alt="not found"
                                                className="img-fluid"
                                                width="50px"
                                                height="50px"
                                              />
                                              <span>
                                                {vari?.variant?.variant_values.join(
                                                  "|"
                                                )}
                                              </span>
                                            </div>
                                          </td>
                                          <td className="col-3">
                                            <div className="">
                                              <span>
                                                {vari?.supplier_price}
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </tr>
                      ))}
                </tbody>
              </table>
              {!supplierMapMerchandise.length && (
                <div className="d-flex flex-row justify-content-center py-3">
                  <span className="font-20 font-bold text-error">
                    Products Not Found
                  </span>
                </div>
              )}
            </div>
          )}

          <ModalComp
            size="lg"
            showModal={showGiftcardModal}
            // handleClose={handleClose}
            component={
              <ModelForGiftCardSummery
                handleClose={handleGiftcardClose}
                giftcardSummery={giftcardSummery}
              />
            }
          />

          <ModalComp
            size="lg"
            showModal={showMerchandiseModal}
            // handleClose={handleClose}
            component={
              <ModelForMerchandiseSummery
                handleClose={handleClose}
                merchandiseSummery={merchandiseSummery}
              />
            }
          />

          <div
            className="position-fixed bottom-0 start-50 translate-middle-x w-100 mb-2"
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg="Your file is being downloaded"
              imageType={"success"}
            />
          </div>
          <div>
            <SmallToast data={id ? id : ""} message={"Copied"} />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default MappedGiftCards;
