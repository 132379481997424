import React from "react";
import { ReactComponent as Basket } from "../../assests/svg/Dashboard/basket.svg";
import { ReactComponent as Line } from "../../assests/svg/Dashboard/line.svg";
import { ReactComponent as OrangeTag } from "../../assests/svg/Tag/orange_lite_tag.svg";
import { ReactComponent as RedDownArrow } from "../../assests/svg/Product/Red_DownArrow.svg";
import { useTranslation } from "react-i18next";

const Overview = (props) => {
    const {lang} = props
const {t}=useTranslation();
    return (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex gap-3">
                    <OrangeTag />
                    <span className="font-20 font-bold">Overview</span>
                </div>
                <div>
                    <div className="btn-group">
                        <div className="btn border border-2 rounded-card-7 dropdown-toggle" data-bs-toggle="dropdown">
                            {t("All time")}
                        </div>
                        <ul className={`dropdown-menu rounded-card-7 dropdown-menu-end ${lang === 'ar' ? 'dropdown-menu-ar' : ''}`}>
                            <li><div className="dropdown-item">{t("Last 7 days")}</div></li>
                            <li><div className="dropdown-item">{t("Last 30 days")}</div></li>
                            <li><div className="dropdown-item">{t("All time")}</div></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="d-flex bg-light rounded-card py-2 px-2 mt-4 w-100">
                <div className="d-flex  flex-row justify-content-between shadow-sm bg-white rounded-card px-3">
                    <div className="mx-2 my-3">
                        <Basket />
                    </div>
                    <div className="my-3 gap-3">
                        <div>
                            <span>{t("Total Sale")}</span>
                        </div>
                       {/* <h2 className="fw-bold font-32">$432.4k</h2>*/}
                        <h2 className="fw-bold font-32">No data</h2>

                    </div>
                    <div className="flex-row mr-4 my-2 rounded-3 pl-2">

                       {/* <span className=" fw-light font-14 bg-danger text-danger bg-opacity-25 rounded-3 px-1"><RedDownArrow className=" mb-1"/> 37.8%</span>*/}
                        <span className=" fw-light font-14 bg-danger text-danger bg-opacity-25 rounded-3 px-1"><RedDownArrow className=" mb-1"/>No data</span>

                    </div>
                </div>
                <div className="d-flex  flex-row gap-3 px-4 my-3">
                    <div>
                        <Line />
                    </div>
                    <div className="d-flex flex-column">
                        <span>{t("Total Gift Card")}</span>
                        {/*<h1 className="fw-bold font-40">340</h1>*/}
                        <h1 className="fw-bold font-35">No Data</h1>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default Overview;
