import {
  PUT_CREATEORDER_REQUEST,
  PUT_CREATEORDER_SUCCESS,
  PUT_CREATEORDER_FAILURE,
  SET_CREATEORDER_DATA,
  SET_DISTRIBUTION_OF_MERCHANDISE,
  PUT_DISTRIBUTION_MERCHANDISE_SUCCESS,
  SET_CLIENT_MERCHANDISE_DATA,
  FETCH_CLIENT_MERCHANDISE_DATA_REQUEST,
  FETCH_CLIENT_MERCHANDISE_DATA_SUCCESS,
  FETCH_CLIENT_MERCHANDISE_DATA_FAILURE,
  REMOVE_CREATE_ORDER_DATA,
  // SET_CLIENT_MERCHANDISE_RESPONSE
} from "./CreateOrderType";

const initialState = {
  createorderData: null,
  distributionOfMerchandise: null,
  clientMerchandiseData: null,
  clientMerchandiseResponse: null,
  distMerRespose: null,
  createOrderResponse: null,
  loading: false,
  error: "",
};

function createOrderReducer(state = initialState, action) {
  switch (action.type) {
    // SETTING DISTRIBUTION OF MERCHANDISE
    case SET_DISTRIBUTION_OF_MERCHANDISE:
      return {
        ...state,
        loading: false,
        distributionOfMerchandise: action.payload,
        error: "",
      };
    case FETCH_CLIENT_MERCHANDISE_DATA_REQUEST:
      return {
        loading: true,
      };
    case FETCH_CLIENT_MERCHANDISE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        clientMerchandiseData: action.payload,
        error: "",
      };
    case FETCH_CLIENT_MERCHANDISE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        clientMerchandiseData: [],
        error: action.payload,
      };
    case PUT_DISTRIBUTION_MERCHANDISE_SUCCESS:
      return {
        ...state,
        loading: false,
        distMerRespose: action.payload,
        error: "",
      };
    // SETTING DISTRIBUTION OF GIFTCARDS
    case SET_CREATEORDER_DATA:
      return {
        ...state,
        loading: false,
        createorderData: action.payload,
        error: "",
      };
    case PUT_CREATEORDER_REQUEST:
      return {
        ...state,
        loading: true,
        createOrderResponse: [],
        error: "",
      };
    case PUT_CREATEORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        createOrderResponse: action.payload,
        createorderData: [],
        error: "",
      };
    case PUT_CREATEORDER_FAILURE:
      return {
        ...state,
        loading: false,
        createOrderResponse: [],
        error: action.payload,
      };
    case REMOVE_CREATE_ORDER_DATA:
      return {
        ...state,
        loading: false,
        createOrderResponse: [],
        createorderData: null,
      };

    default:
      return state;
  }
}

export default createOrderReducer;
