import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import SuccessImg from "../../assests/img/success.png";
import WarningImg from "../../assests/img/warning.png";
import ErrorImg from "../../assests/img/error.png";

const Toasts = (props) => {
  const {
    showToast,
    toggleShowToast,
    position,
    type,
    successMsg,
    warningMsg,
    errorMsg,
    imageType,
  } = props;

  return (
    <>
      <ToastContainer className="p-1" position={position}>
        <Toast
          show={showToast}
          onClose={() => toggleShowToast()}
          delay={3000}
          autohide
          type={type}

        >
        {/*following section is used for cross icon to close toast forcefully*/}
         {/* <div
            className="align-items-center fixed-top p-2 d-flex justify-content-end"
            aria-live="assertive"
            aria-atomic="true"
          >
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>*/}
          <div className="d-flex flex-row align-items-center mx-4 my-3 gap-3 ">
            <div className="notification-image">
              <img
                src={
                 imageType==="success"?SuccessImg:imageType==="warning"?WarningImg:imageType==="error"?ErrorImg:""
                }
                alt=""
              />
            </div>
            <div className="d-flex flex-column ">
              <span className="font-14 font-bold">
                {successMsg
                  ? successMsg
                  : warningMsg
                  ? warningMsg
                  : errorMsg
                  ? errorMsg
                  : ""}
              </span>
            </div>
          </div>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default Toasts;
