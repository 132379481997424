
export const GET_SAMPLE_BULK_DISTRIBUTION_REQUEST = "GET_SAMPLE_BULK_DISTRIBUTION_REQUEST";
export const GET_SAMPLE_BULK_DISTRIBUTION_SUCCESS = "GET_SAMPLE_BULK_DISTRIBUTION_SUCCESS";
export const GET_SAMPLE_BULK_DISTRIBUTION_FAILURE = "GET_SAMPLE_BULK_DISTRIBUTION_FAILURE";

export const GET_BULK_DISTRIBUTION_REQUEST = "GET_BULK_DISTRIBUTION_REQUEST";
export const GET_BULK_DISTRIBUTION_SUCCESS= "GET_BULK_DISTRIBUTION_SUCCESS";
export const GET_BULK_DISTRIBUTION_FAILURE = "GET_BULK_DISTRIBUTION_FAILURE";

export const POST_BULK_DISTRIBUTION_REQUEST = "POST_BULK_DISTRIBUTION_REQUEST";
export const POST_BULK_DISTRIBUTION_SUCCESS = "POST_BULK_DISTRIBUTION_SUCCESS";
export const POST_BULK_DISTRIBUTION_FAILURE = "POST_BULK_DISTRIBUTION_FAILURE";

export const POST_BULK_DISTRIBUTION_ERROR = "POST_BULK_DISTRIBUTION_ERROR";