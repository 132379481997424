import React, { useEffect, useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import HadayaLogo from "../../assests/svg/Group10.svg";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg";
import Collapse from "react-bootstrap/Collapse";
import { useDispatch, useSelector } from "react-redux";
import { getOrderHistory } from "../../Redux/Order/orderAction";
import {
  DateTimeFormatter,
  strFirstLetterCap,
} from "../../utils/constantFunction";

const ProductSupplierDetails = (props) => {
  const dispatch = useDispatch();
  const { id } = props;
  const [orderTrackCollapse, setOrderTrackCollapse] = useState(false);
  const orderHistory = useSelector(
    (state) => state?.allOrderList?.orderHistory
  );
  const orderDetailState = useSelector(
    (state) => state?.allOrderList?.orderDetails
  );
  console.log("orderDetailState ", orderDetailState?.result)
  useEffect(() => {
    if (id) {
      dispatch(getOrderHistory(id));
    }
  }, []);

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mt-2">
      <div
        className="d-flex flex-row justify-content-between align-items-center"
        onClick={() => setOrderTrackCollapse(!orderTrackCollapse)}
      >
        <div className="d-flex py-3 gap-3">
          <Tag />
          <span className="font-20 font-bold">Suppliers detail</span>
        </div>
        <div
          className={`cursor p-0 ${orderTrackCollapse ? "rotate-180" : ""}`}
          aria-controls="example-collapse-text"
          aria-expanded={orderTrackCollapse}
        >
          <DropDownIcon
            className={`cursor p-0 ${orderTrackCollapse ? "rotate-180" : ""}`}
          />
        </div>
      </div>
      <Collapse in={orderTrackCollapse}>
        <div className="bg-light p-2">
         <span className="font-bold font-15">Name: </span>
         <span className="font-15">{orderDetailState?.result?.supplier?.supplier_name}</span>
         <div>
         <span className="font-15 font-bold">Id: </span>
         <span className="font-15">{orderDetailState?.result?.supplier?.supplier_id}</span>
         </div>
        </div>  
        
      </Collapse>
    </div>
  );
};
export default ProductSupplierDetails;
