// User
export const GET_USER_REQUEST = "GET_USER_REQUEST"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILURE = "GET_USER_FAILURE"

export const SET_USER_REQUEST = "SET_USER_REQUEST"
export const SET_USER_SUCCESS = "SET_USER_SUCCESS"
export const SET_USER_FAILURE = "SET_USER_FAILURE"


// User roles
export const GET_USER_ROLE_REQUEST = "GET_USER_ROLE_REQUEST"
export const GET_USER_ROLE_SUCCESS = "GET_USER_ROLE_SUCCESS"
export const GET_USER_ROLE_FAILURE = "GET_USER_ROLE_FAILURE"


// User all roles
export const GET_ALL_USER_ROLE_REQUEST = "GET_ALL_USER_ROLE_REQUEST"
export const GET_ALL_USER_ROLE_SUCCESS = "GET_ALL_USER_ROLE_SUCCESS"
export const GET_ALL_USER_ROLE_FAILURE = "GET_ALL_USER_ROLE_FAILURE"


export const SET_USER_ROLE_REQUEST = "SET_USER_ROLE_REQUEST"
export const SET_USER_ROLE_SUCCESS = "SET_USER_ROLE_SUCCESS"
export const SET_USER_ROLE_FAILURE = "SET_USER_ROLE_FAILURE"

// get user role data by id
export const GET_USER_ROLE_DATA_BYID_REQUEST = "GET_USER_ROLE_DATA_BYID_REQUEST"
export const GET_USER_ROLE_DATA_BYID_SUCCESS = "GET_USER_ROLE_DATA_BYID_SUCCESS"
export const GET_USER_ROLE_DATA_BYID_FAILURE = "GET_USER_ROLE_DATA_BYID_FAILURE"
// Update user role based on id 
export const UPDATE_USER_ROLE_DATA_REQUEST = "UPDATE_USER_ROLE_DATA_REQUEST"
export const UPDATE_USER_ROLE_DATA_SUCCESS = "UPDATE_USER_ROLE_DATA_SUCCESS"
export const UPDATE_USER_ROLE_DATA_FAILURE = "UPDATE_USER_ROLE_DATA_FAILURE"
// update user 
export const UPDATE_USER_DATA_REQUEST = "UPDATE_USER_DATA_REQUEST"
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS"
export const UPDATE_USER_DATA_FAILURE = "UPDATE_USER_DATA_FAILURE"

export const CLEAR_USER_LIST = "CLEAR_USER_LIST"
export const CLEAR_USER_ROLES_LIST = "CLEAR_USER_ROLES_LIST"



