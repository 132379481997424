/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import GiftCardDetails from "../../../components/EPOS/GiftCardDetails";
import MyntraImg from "../../../assests/img/ImageGift.png";
import ModalComp from "../../../components/Models/ModalComp";
import ModelForGiftCardSummery from "../../../components/Models/ModelForGiftCardSummery";
import PulseLoader from "../../../components/Loader/PulseLoader";
import { fetchGiftCardSummeryById } from "../../../Redux/GiftCard/giftcardAction";
import GiftcardTopUpDetail from "./GiftcardTopUpDetail";
import { useTranslation } from "react-i18next";

const GiftcardTopUpActivation = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();


  const giftcardDetails = useSelector(
    (state) => state.eposState?.giftcardDetails
  );
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );

  const loading = useSelector(
    (state) => state?.giftcard?.loading
  );

  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!giftcardDetails) {
      history("/epos/ehadaya-interface");
    }
  }, [giftcardDetails]);

  const showModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowModal(true);
  };

  return (loading === true ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) :
    <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Topup Giftcard")}</span>
        </div>
      </div>

      {giftcardDetails ? <div className="d-flex flex-row justify-content-between gap-2">
        <div className="d-flex w-100">
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 gap-3 w-100">
            <div className="d-flex">
              <div className=" d-flex flex-column justify-content-between rounded-3  w-100">
                <table responsive="xl">
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex flex-row justify-content-start align-items-center gap-3 py-1">
                          <span className="mx-1 rounded-3">
                            <img
                              src={giftcardDetails &&
                                giftcardDetails?.giftcard?.images ? (
                                giftcardDetails?.giftcard?.images[0].file_url
                              ) : (
                                <MyntraImg />
                              )
                              }
                              width={60}
                              height={60}
                              alt="sign in pic not found"
                              className="img-fluid "
                            />
                          </span>

                          <div className="d-flex flex-column justify-content-center">
                            <span
                              className="font-16 font-boldest text-dark cursor"
                              onClick={() => showModel(giftcardDetails?.giftcard?.id)}
                            >
                              {giftcardDetails?.giftcard?.default_title}
                            </span>
                            <span className="font-13 font-bold text-muted">
                              {giftcardDetails?.giftcard?.id}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-start align-items-center">
                          <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                            {giftcardDetails?.giftcard?.registered_by_id ===
                              "powered_by_ehadaya"
                              ? t("Powered by ehadaya")
                              : giftcardDetails?.giftcard?.registered_by_id ===
                                "manual_inventory"
                                ? t("Manual inventory")
                                : giftcardDetails?.giftcard?.registered_by_id ===
                                  "3rd_party_suppliers"
                                  ? t("3rd party suppliers")
                                  : null}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-start ">
                          <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 p-1 ">
                            {giftcardDetails?.giftcard?.country?.currency +
                              " - " +
                              giftcardDetails?.giftcard?.country?.currency_symbol}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ModalComp
          size="lg"
          showModal={showModal}
          // handleClose={handleClose}
          component={
            <ModelForGiftCardSummery
              handleClose={handleClose}
              giftcardSummery={giftcardSummery}
            />
          }
        />
      </div> : null}


      <div className="d-flex flex-row justify-content-between gap-2">
        <div className="d-flex  width-65" style={{ height: "fit-content" }}>
          <GiftcardTopUpDetail
            details={location.state?.store}
            giftcardDetails={giftcardDetails}
          />
        </div>
        <div className="d-flex width-35">
          <GiftCardDetails
            details={location.state?.store}
            giftcardDetails={giftcardDetails}
            lang={props?.lang}
          />
        </div>
      </div>
    </div>
  );
}

export default GiftcardTopUpActivation