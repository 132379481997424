import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GoogleIncIcon from "../../assests/img/GoogleInc.png";
import { ReactComponent as LinkIcon } from "../../assests/svg/link-icon.svg";
import { ReactComponent as MailIcon } from "../../assests/svg/mail.svg";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/dark_green.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DistributionStoreDetails = (props) => {
  const { t } = useTranslation();
  const { floatBalance } = props;
  const giftcardOrderData = useSelector((state) => state.createOrder);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="card-body d-flex flex-column justify-content-between bg-white shadow-sm rounded-3 p-4 mb-2 gap-3">
      <div className="d-flex flex-column  rounded-3 pb-3  w-100">
        <div className="d-flex gap-4 ">
          <GreenTag />
          <span className="font-bold font-20 ">{t("Store details")}</span>
        </div>
        <div className="d-flex flex-row gap-3 py-2  align-items-center">
          <div className="d-flex flex-grow-1">
            <div className="d-flex flex-column gap-3 mb-1  py-3  px-2 align-items-center">
              <div className="d-flex flex-row mx-2 gap-4 justify-content-between">
                <img
                  src={GoogleIncIcon}
                  alt="not found"
                  className="img-fluid rounded-3 "
                />

                <div>
                  <span className="font-22 font-boldest text-dark ">
                    {
                      giftcardOrderData?.createorderData?.selectedStore
                        ?.store_name
                    }
                  </span>
                  <br />
                  <span className="font-13 font-bold text-muted">
                    {t("Store ID")}:
                    {giftcardOrderData?.createorderData?.selectedStore?.store_id
                      ? giftcardOrderData?.createorderData?.selectedStore
                          ?.store_id
                      : giftcardOrderData?.createorderData?.selectedStore?.id}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-between  px-3">
            <div className="d-flex ">
              <span className="font-14 font-bold text-muted">
                <LinkIcon /> {t("Store address")}:
              </span>
            </div>
            <div>
              <span className="font-14 font-bold">
                {
                  giftcardOrderData?.createorderData?.selectedStore
                    ?.store_address
                }
              </span>
              ,
              <span className="font-14 font-bold">
                {giftcardOrderData?.createorderData?.selectedStore?.city_name}
              </span>
              ,
              <span className="font-14 font-bold">
                {giftcardOrderData?.createorderData?.selectedStore?.zip_code}
              </span>
              <br />
              {giftcardOrderData?.createorderData?.selectedStore
                ?.store_phone_no && (
                <span className="font-14 font-bold text-muted">
                  {t("Store phone")}:
                </span>
              )}
              {giftcardOrderData?.createorderData?.selectedStore
                ?.store_phone_no && (
                <span className="font-14 font-bold">
                  {
                    giftcardOrderData?.createorderData?.selectedStore
                      ?.store_phone_no
                  }
                </span>
              )}
            </div>
          </div>
        </div>

        <span className="font-14 px-4 font-bold">
          <MailIcon />
          <span className="mx-1 ">
            {giftcardOrderData?.createorderData?.selectedStore?.email_id}
          </span>
        </span>
      </div>
      {giftcardOrderData?.createorderData?.selectedOrganization?.float_flag ===
        true && (
        <>
          <div className="d-flex gap-3 my-4 px-4 align-items-center">
            <GreenTag />
            <span className="font-20 font-bold">
              {t("Available Float Balance")}:
              <span className="font-20 font-bold text-pink ">
                {" "}
                {floatBalance?.country?.currency_symbol}
                {floatBalance?.balance ? floatBalance?.balance : "00"}
              </span>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default DistributionStoreDetails;
