/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../components/Toasts/Toasts";
import {
  DataSortingHandler,
  momentdateFormater,
} from "../../utils/constantFunction";
import Filter from "../Filter/Filter";
import {
  PRODUCT_STATUS_FILTERS_ACTIVE_DEACTIVE,
  SORT_TYPE,
} from "../../utils/ConstantData";
import InfiniteScroll from "react-infinite-scroll-component";
import PulseLoaders from "react-spinners/PulseLoader";
import {
  clearClientList,
  fetchClient,
  setClientFilterData,
} from "../../Redux/Client/clientAction";
import PulseLoader from "../Loader/PulseLoader";
import { t } from "i18next";
import SearcBar from "../InputField/SearcBar";
import RefreshButton from "../Button/RefreshButton";

const AllClientList = (props) => {
  const { clientsState } = props;
  const dispatch = useDispatch();
  const history = useNavigate();

  // infinite scrollbar
  const hasmore = useSelector((state) => state?.clients?.next_iteration);
  const loading = useSelector((state) => state?.clients?.loading);
  const clientsFilterState = useSelector(
    (state) => state?.clients?.clientFilter
  );

  const [skip, setskip] = useState(
    clientsState.length === 0 ? -10 : clientsState.length - 10
  );
  const [limit, setlimit] = useState(10);

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => { }, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (clientsState.length === 0) {
      fetchdata(skip);
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(fetchClient(skip + limit, limit, clientsState));
      setskip(skip + limit);
    }, 100);
  };

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState();
  const [filterCardType, setFilterCardType] = useState("");

  useEffect(() => {
    setProducts(clientsState);
  }, [clientsState]);

  useEffect(() => {
    setProducts(clientsFilterState[0]?.filterProduct);
  }, [clientsFilterState]);

  useEffect(() => {
    clientsFilterState && setFilterCardType(clientsFilterState[1]?.filterType);
  }, [clientsFilterState[1]?.filterType]);

  useEffect(() => {
    if (clientsFilterState.length === 0) {
      setProducts(clientsState);
    }
  }, []);

  const filterCardTypeHandler = (e) => {
    setFilterCardType(e);
    let arr = [];
    clientsState &&
      clientsState.map((item, i) => {
        if (e?.value === item.active_flag) {
          arr.push(item);
        } else if (e?.value === null) {
          arr.push(item);
        }
      });
    dispatch(setClientFilterData([{ filterProduct: arr }, { filterType: e }]));
  };

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const goTonextStep = (item) => {
    history("/clients-details", {
      state: {
        selectedClient: item,
      },
    });
  };

  const refreshData = () => {
    dispatch(clearClientList());
    setskip(0);
    setlimit(10);
    dispatch(fetchClient(0, 10, []));
  };

  return loading === true && products?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mt-3">
      <div className="d-flex flex-column  bg-white">
        <div className="card-body d-flex  flex-row justify-content-between align-item-center">
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div className="p-2 flex-grow-1">
              <div className="d-flex gap-3 my-2  align-items-center">
                <Tag />
                <span className="font-20 font-bold">{t("All Clients")}</span>
              </div>
            </div>
            <SearcBar
              type="text"
              name="search"
              lang={props?.lang}
              placeholder={t("Search by name")}
              onChange={searchHandler}
            />
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div>
              <Filter
                data={PRODUCT_STATUS_FILTERS_ACTIVE_DEACTIVE}
                onChange={filterCardTypeHandler}
                title={filterCardType?.name}
                lang={props?.lang}
              />
            </div>
            <div>
              <RefreshButton onClick={refreshData} />
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={clientsState?.length || 0}
          next={fetchdata}
          hasMore={hasmore}
          loader={
            <div className="d-flex justify-content-center">
              {" "}
              <PulseLoaders
                color={"#594190"}
                loading={loading}
                size={10}
              />{" "}
            </div>
          }
        >
          <div className="row  py-2">
            <table responsive="xl">
              <tbody>
                <tr className="card-body gap-3 border-bottom py-5">
                  {/* <td></td>*/}
                  <td
                    className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "partner_name"
                      ? "ascending"
                      : "descending"
                      }`}
                    onClick={() =>
                      sortingHandler(
                        "partner_name",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Name")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "email"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "email",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }>
                    {t("Client email")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "created_at"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "created_at",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }>
                    {t("Created at")}
                  </td>

                  <td className={`card-body gap-1 font-13 font-bold text-muted`}>
                    {t("Status")}
                  </td>
                </tr>
                {products &&
                  products
                    ?.filter((item) => {
                      if (searchTerm === "") {
                        return item;
                      } else if (
                        item?.partner_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <tr
                        className="card-body gap-3 border-bottom hover-row"
                        key={item.id}
                        onClick={() => goTonextStep(item)}
                      >
                        <td className="card-body gap-1 cursor">
                          <div className="d-flex flex-column justify-content-start ">
                            <span
                              className="d-inline-block text-truncate font-15 font-boldest text-dark"
                              title={item?.partner_name}
                              style={{ maxWidth: "160px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                            >
                              {item?.partner_name}
                            </span>
                            <span className="font-12 font-bolder text-muted">
                              {item?.company_name}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1  cursor">
                          <div className="d-flex flex-row justify-content-between  gap-3">
                            <span
                              className="fs-light  text-muted d-inline-block text-truncate"
                              style={{ maxWidth: "140px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={item?.email}
                            >
                              {item?.email}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1 cursor">
                          <span className="fs-light  text-muted">
                            {momentdateFormater(item?.created_at)}
                          </span>
                        </td>

                        <td className="card-body gap-1 cursor">
                          <span>
                            {item?.active_flag === true ? (
                              <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                {t("Active")}
                              </span>
                            ) : (
                              <span className="font-15 font-bold text-danger bg-04 bg-opacity-25 rounded-3 px-2">
                                {t("Deactive")}
                              </span>
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            {!products?.length && (
              <div className="d-flex flex-row justify-content-center py-3">
                <span className="font-20 font-bold text-error">
                  {t("No client found")}
                </span>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>

      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded."
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default AllClientList;
