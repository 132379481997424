/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../../assests/svg/Tag/tag.svg";
import { ReactComponent as DropDownIcon } from "../../../../assests/svg/DropDown.svg";

import Button from "../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../../components/Toasts/Toasts";
import { ReactComponent as LeftIcon } from "../../../../assests/svg/Products/left_lg.svg";
import product_default from "../../../../assests/img/Product-default.png";
import { setSelectedGiftcardsList } from "../../../../Redux/MappingProducts/Supplier/Giftcards/giftcardSuppMappingAction";
import { clearMerchandiseList, getMerchandiseVariantListByCountryIds } from "../../../../Redux/MerchadiseAllProduct/MerchadiseAllProductAction";
import PulseLoader from "../../../../components/Loader/PulseLoader";
import {
  fetchCountrieDetailsByIds,
  getAllCountriesList,
} from "../../../../Redux/Countries/countryAction";
import ModalComp from "../../../../components/Models/ModalComp";
import ModelForMerchandiseSummery from "../../../../components/Models/ModelForMerchandiseSummery";
import { getMerchandiseSummeryById } from "../../../../Redux/Merchandise/merchandiesAction";
import Collapse from "react-bootstrap/Collapse";
import { clearSupplierMappProductsList, fetchSupplierMapMerchandise } from "../../../../Redux/Supplier/supplierAction";
import { useTranslation } from "react-i18next";
import SearcBar from "../../../../components/InputField/SearcBar";
import { DataSortingHandler } from "../../../../utils/constantFunction";
import { SORT_TYPE } from "../../../../utils/ConstantData";

const MerchandiseList = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //const productsState = useSelector((state) => state?.productTypes?.products);
  const productsAllState = useSelector(
    (state) => state?.merchadiseAllProduct?.merchandiseList
  );
  const loading = useSelector((state) => state?.merchadiseAllProduct?.loading);

  const selectedSupplier = useSelector(
    (state) => state?.giftcardSuppMappingReducer?.selectedSupplier
  );

  const supplierMappedMerchandise = useSelector(
    (state) => state?.suppliers?.supplierMappedMerchandise
  );
  const countries = useSelector((state) => state?.countries?.countries);

  // for model
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );
  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);


  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const [products, setProducts] = useState([]);
  const [floatCollapse, setFloatCollapse] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => { }, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCountriesList());
    // dispatch(clearSupplierMappProductsList());
    // dispatch(clearMerchandiseList());


  }, []);

  const [supplierCurrencies, setSupplierCurrencies] = useState([]);
  useEffect(() => {
    let arr = [];
    countries &&
      countries.map((conItem) => {
        selectedSupplier &&
          selectedSupplier.currencies_id.map((item) => {
            if (conItem?.id === item) {
              arr.push(conItem);
            }
          });
      });
    setSupplierCurrencies(arr);
  }, [selectedSupplier, countries]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (selectedSupplier && selectedSupplier?.currencies_id) {
      dispatch(
        getMerchandiseVariantListByCountryIds(selectedSupplier?.currencies_id)
      );
      dispatch(fetchCountrieDetailsByIds(selectedSupplier?.currencies_id));
      dispatch(fetchSupplierMapMerchandise(selectedSupplier?.id));
    }

    if (!selectedSupplier) {
      history("/supplier-mapping-dashboard");
    }
  }, [selectedSupplier]);


  useEffect(() => {
    let arr = productsAllState;
    let prodArr = [];
    supplierMappedMerchandise &&
      arr &&
      arr.map((item, i) => {
        if (
          supplierMappedMerchandise.some(
            (mappProd) => item.id === mappProd.merchandise.id
          )
        ) {
          // toggleShowToast();
          return;
        } else {
          let obj = {
            product: item,
            collapseFlag: false,
          };
          prodArr.push(obj);
        }
      });

    setProducts(prodArr);
  }, [productsAllState, supplierMappedMerchandise]);



  const allCheckedHandle = (e) => {
    const { checked } = e.target;
    let arr = [...products];
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = checked;
        if (item.product.variants && item.product.variants.length > 0) {
          let variantsArr = [...item.product.variants];
          variantsArr &&
            variantsArr.map((val, j) => {
              variantsArr[j].isChecked = checked;
            });
        }
      });
    setProducts(arr);
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...products];

    arr &&
      arr.map((item, i) => {
        if (i === parseInt(name)) {
          arr[i].isChecked = checked;
          if (item.product.variants && item.product.variants.length > 0) {
            //  arr[i].collapseFlag = !item.collapseFlag;
            let variantsArr = [...item.product.variants];
            variantsArr &&
              variantsArr.map((val, j) => {
                variantsArr[j].isChecked = checked;
              });
          }
        }
      });
    setProducts(arr);
  };

  const handleChildCheck = (e) => {
    const { name, checked, id } = e.target;
    let vatiadtIndex = parseInt(id);
    let arr = [...products];
    arr &&
      arr.map((item, i) => {
        if (i === parseInt(name)) {
          arr[i].isChecked = true;
          if (item.product.variants && item.product.variants.length > 0) {
            // arr[i].collapseFlag = !item.collapseFlag
            let variantsArr = [...item.product.variants];

            variantsArr &&
              variantsArr.map((val, j) => {
                if (j === vatiadtIndex) {
                  variantsArr[j].isChecked = checked;
                }
              });
          }
        }
      });
    setProducts(arr);
  };

  const goBack = () => {
    history("/supplier-mapping-dashboard");
  };

  const setProductCollapse = (index) => {
    let arr = [...products];

    arr &&
      arr.map((item, i) => {
        if (index === i) {
          arr[i].collapseFlag = !item.collapseFlag;
        }
      });
    setProducts(arr);
  };

  const submit = () => {
    let arr = [...products];
    let selectedProducts = [];
    let selectedProductsVariants = [];

    arr &&
      arr.map((item) => {
        if (item.isChecked === true) {
          if (item.product.variants && item.product.variants.length > 0) {
            let variantsArr = [...item.product.variants];
            variantsArr &&
              variantsArr.map((val, j) => {
                if (val.isChecked === true) {
                  let obj = {
                    product: item.product,
                    productId: item.product.id,
                    product_title: item.product.product_title,
                    variantId: val.id,
                    variantKeys: val.variant_keys,
                    variantValues: val.variant_values,
                    defaultImage: val?.images && val?.images[0]?.file_url,
                  };
                  selectedProductsVariants.push(obj);
                }
              });
          } else {
            let obj = {
              product: item.product,
              productId: item.product.id,
              product_title: item.product.product_title,
              // variantId: val.id,
              // variantKeys: val.variant_keys,
              // variantValues: val.variant_values
            };
            selectedProducts.push(obj);
          }
        }
      });
    let arrProducts = [...selectedProducts, ...selectedProductsVariants];

    if (arrProducts.length > 0) {
      dispatch(setSelectedGiftcardsList(arrProducts));
      history("/supplier-mapping-merchandise-sku");
    } else {
      toggleShowToast();
    }
  };

  const showModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowModal(true);
  };

  return loading === true ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-between gap-3 py-4 pb-3 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">
            {t("Supplier Mapping")}
          </span>
        </div>
      </div>

      <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100  ">
        <div className="card-body d-flex justify-content-between flex-row">
          <div className="d-flex gap-3 my-2">
            <Tag />
            <span className="font-20 font-bold">
              {t("Selected Supplier")}: {selectedSupplier?.partner_name}
            </span>
          </div>
        </div>


        <div className="d-flex justifiy-content-center px-4 gap-3">
          <div
            className="d-flex flex-row justify-content-between align-items-center py-2 px-2 gap-3 rounded-3"
            onClick={() => setFloatCollapse(!floatCollapse)}
          >
            <div
              className="d-flex flex-column cursor gap-3"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              <div className="d-flex align-items-center gap-4">
                {/* <ProductIcon /> */}
                <span className="font-18 font-bold">
                  {t("Supplier Operational Countries")}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div
                className={`cursor p-0 ${floatCollapse ? "rotate-180" : ""}`}
                aria-controls="example-collapse-text"
                aria-expanded={false}
              >
                <DropDownIcon
                  className={`cursor p-0 ${floatCollapse ? "rotate-180" : ""}`}
                />
              </div>
            </div>
          </div>
        </div>
        {/* )} */}

        <div className="px-5">
          <Collapse in={floatCollapse}>
            <div
              id="panelsStayOpen-collapseOne"
              className="mt-2"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div className="card-body d-flex flex-column justify-content-between p-3 gap-2">
                <table responsive="xl">
                  <tbody>
                    <tr className="col gap-3 border-bottom ">
                      <td className="col">
                        <div>
                          <span className="font-16 font-bold px-2 py-3">
                            {t("Countries")}
                          </span>
                        </div>
                      </td>
                      <td className="col">
                        <div className="d-flex flex-end justify-content-end px-4">
                          <span className="font-16 font-bold px-2 py-3">
                            {t("Currencies")}
                          </span>
                        </div>
                      </td>
                    </tr>

                    {supplierCurrencies &&
                      supplierCurrencies.map((item, i) => (
                        <tr
                          className="col gap-3 border-bottom hover-row"
                          key={i}
                        >
                          <td className="col px-2 py-2">
                            {item?.name + " (" + item?.iso3 + ")"}
                          </td>
                          <td className="col">
                            <div className="d-flex flex-end justify-content-end px-4">
                              <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                {item?.currency + " - " + item?.currency_symbol}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {!supplierCurrencies.length && (
                  <div className="d-flex flex-row justify-content-center py-3">
                    <span className="font-20 font-bold text-error">
                      {t("No country & Currencies found")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100 gap-3">

        <div className="d-flex gap-3 my-2 mx-3 align-items-center">
          <Tag />
          <span className="font-20 font-bold">{t("Mapping merchandise")}</span>
          <SearcBar
            type="text"
            name="search"
            lang={props?.lang}
            placeholder={t("Search by name")}
            onChange={searchHandler}
          />
        </div>
        <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
          <table responsive="xl">
            <tbody>
              <tr className="col border-bottom cursor">
                <div className="d-flex">
                  <td className="col-4">
                    <div className="d-flex flex-row justify-content-start text-dark gap-3">
                      <span>
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="checkboxNoLabel"
                          checked={
                            products.filter(
                              (product) => product?.isChecked !== true
                            ).length < 1
                          }
                          onChange={allCheckedHandle}
                        />
                      </span>
                      <span
                        className={`font-16 font-boldest text-muted ${tableHeaderName === "default_title"
                          ? "ascending"
                          : "descending"
                          }`}
                        onClick={() =>
                          sortingHandler(
                            "default_title",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Name")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div className="d-flex justify-content-center">
                      <span className="font-16 font-boldest text-muted">
                        {t("Variants")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div>
                      <span className="font-16 font-boldest text-muted">
                        {t("Supplier name")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div>
                      <span
                        className={`font-16 font-boldest text-muted ${tableHeaderName === "currency"
                          ? "ascending"
                          : "descending"
                          }`}
                        onClick={() =>
                          sortingHandler(
                            "currency",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }
                      >
                        {t("Currency")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div className="d-flex justify-content-start">
                      <span className={`font-16 font-boldest text-muted ${tableHeaderName === "active_flag"
                        ? "ascending"
                        : "descending"
                        }`}
                        onClick={() =>
                          sortingHandler(
                            "active_flag",
                            setTableHeaderName,
                            products,
                            order,
                            setOrder
                          )
                        }>
                        {t("Status")}
                      </span>
                    </div>
                  </td>
                </div>
              </tr>
              {products &&
                products
                  ?.filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.product?.default_title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => (
                    <tr key={index} className="gap-2 border-bottom">
                      <div className="d-flex flex-row justify-content-start align-items-center ">
                        <td className="col-4 align-top">
                          <div className="d-flex flex-row justify-content-start align-items-center py-3 gap-3">
                            <input
                              className="form-check-input cursor"
                              type="checkbox"
                              id="checkboxNoLabel"
                              checked={item?.isChecked}
                              name={index}
                              onChange={handleCheck}
                            />
                            {item?.product?.product_variants_flag ? (
                              <img
                                src={
                                  item.product &&
                                    item?.product?.variants?.length > 0 &&
                                    item?.product.variants !== undefined &&
                                    item?.product?.variants[0]?.images &&
                                    item?.product?.variants[0].images[0]?.file_url
                                    ? item?.product?.variants[0].images[0]
                                      ?.file_url
                                    : product_default
                                }
                                alt="not found"
                                id="product-thumbnail"
                              />
                            ) : (
                              <img
                                src={
                                  item?.product?.images &&
                                    item?.product?.images[0]?.file_url
                                    ? item?.product?.images[0]?.file_url
                                    : product_default
                                }
                                alt="not found"
                                id="product-thumbnail"
                              />
                            )}
                            <div className="d-flex flex-column align-items-start ">
                              <span
                                className="font-bold text-dark font-15 d-inline-block text-truncate cursor"
                                title={item?.product?.default_title}
                                style={{ maxWidth: "150px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => showModel(item?.product?.id)}
                              >
                                {item?.product?.default_title}
                              </span>
                              <span
                                className="font-12 text-muted d-inline-block text-truncate cursor"
                                title={item?.product?.id}
                                style={{ maxWidth: "140px" }}
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                {item?.product?.id}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="col-2 align-top pt-4">
                          <div className="d-flex flex-row justify-content-center">
                            <span className="font-13 font-bolder text-dark bg-04 rounded-3 px-1 ">
                              {item?.product?.variants
                                ? item?.product?.variants.length
                                : t("No variants")}
                            </span>
                          </div>
                        </td>
                        <td className="col-2 align-top  pt-4 ">
                          <span
                            className="text-dark font-15 mx-4 d-inline-block text-truncate cursor"
                            title={selectedSupplier?.partner_name}
                            style={{ maxWidth: "100px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {selectedSupplier?.partner_name}
                          </span>
                        </td>
                        <td className="col-2 align-top  pt-4">
                          <div className="d-flex">
                            <span className="text-dark font-15 mx-2">
                              {`${item?.product?.country?.currency} (${item?.product?.country?.currency_symbol})`}
                            </span>
                          </div>
                        </td>
                        <td className="col-2 align-top  pt-4">
                          {/* <div className="px-2"> */}
                          <div className="d-flex justify-content-between">
                            <span className="text-dark font-15">
                              {item?.product?.active_flag ? (
                                <span className="font-15 font-bolder text-dark bg-04 rounded-3 px-1">
                                  {t("Active")}
                                </span>
                              ) : (
                                <span className="font-15 font-bolder text-dark bg-danger bg-opacity-25 rounded-3 px-1">
                                  {t("Deactive")}
                                </span>
                              )}
                            </span>

                            {item?.product?.variants &&
                              item?.product?.product_variants_flag && (
                                <div
                                  onClick={() => setProductCollapse(index)}
                                  className="px-3"
                                  aria-controls="example-collapse-text"
                                  aria-expanded={item.collapseFlag}
                                >
                                  <DropDownIcon
                                    className={`cursor p-0 ${item.collapseFlag ? "rotate-180" : ""
                                      }`}
                                  />
                                </div>
                              )}
                          </div>
                        </td>
                      </div>

                      {item?.collapseFlag && (
                        <div id="example-collapse-text">
                          <div className="d-flex bg-white shadow rounded-3">
                            <div className="d-flex flex-column w-100 gap-3">
                              <div className="d-flex flex-row justify-content-start px-5 border-bottom">
                                <div className="d-flex align-items-center gap-2">
                                  <span className="font-14 font-bolder py-2 ">
                                    {t("Variants List")}
                                  </span>
                                </div>
                              </div>

                              <div className="d-flex flex-column justify-content-start px-4 w-100 gap-3">
                                {item?.product?.product_variants_flag &&
                                  item?.product?.variants &&
                                  item?.product?.variants.map((variant, i) => (
                                    <td
                                      key={i}
                                      className="d-flex flex-row justify-content-start align-items-center pb-3 gap-2"
                                    >
                                      <input
                                        className="form-check-input cursor"
                                        type="checkbox"
                                        id={i}
                                        name={index}
                                        checked={variant?.isChecked}
                                        onChange={handleChildCheck}
                                      />
                                      <div>
                                        <img
                                          src={
                                            variant?.images &&
                                              variant?.images[0]?.file_url
                                              ? variant?.images[0]?.file_url
                                              : product_default
                                          }
                                          alt="not found"
                                          id="product-thumbnail"
                                        />
                                      </div>

                                      <div className="d-flex flex-column w-100 gap-2">
                                        <span>
                                          {variant?.variant_values.join("|")}
                                        </span>
                                      </div>
                                    </td>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </tr>
                  ))}
            </tbody>
          </table>
          {!products.length && (
            <div className="d-flex flex-row justify-content-center py-3">
              <span className="font-20 font-bold text-error">
                {t("No product details found")}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex">
        <Button
          type="primary"
          text={t("Save and Next")}
          onClick={() => submit()}
        />
      </div>
      <ModalComp
        size="lg"
        showModal={showModal}
        // handleClose={handleClose}
        component={
          <ModelForMerchandiseSummery
            handleClose={handleClose}
            merchandiseSummery={merchandiseSummery}
          />
        }
      />
      <div
        className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Please select giftcards/products to map."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default MerchandiseList;
