import {
  GET_ALL_ORDERS_REQUEST,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAILURE,
  GET_ALL_CLIENT_ORDERSLIST_REQUEST,
  GET_ALL_CLIENT_ORDERSLIST_SUCCESS,
  GET_ALL_CLIENT_ORDERSLIST_FAILURE,
  GET_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  ORDER_FILTER_TYPES_STORE,
  UPDATE_ORDER_STATUS_REQUEST,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_FAILURE,
  HISTORY_ORDER_STATUS_REQUEST,
  HISTORY_ORDER_STATUS_SUCCESS,
  HISTORY_ORDER_STATUS_FAILURE,
  CLEAR_ORDER_LIST
} from "./orderType";

const initialAllOrdersState = {
  loading: false,
  allOrdersList: [],
  orderDetails: [],
  clientAllOrderList: [],
  orderFilterType: "",
  orderStatusResponse: [],
  orderHistory: [],
  error: "",
  next_iteration: true
};

const allOrdersReducer = (state = initialAllOrdersState, action) => {
  switch (action.type) {
    case CLEAR_ORDER_LIST:
      return {
        ...state,
        loading: false,
        allOrdersList: [],
        next_iteration: true,
        error: "",
      };
    case GET_ALL_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allOrdersList: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case GET_ALL_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        allOrdersList: [],
        error: action.payload,
      };
    // update order status
    case UPDATE_ORDER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderStatusResponse: action.payload,
        error: "",
      };
    case UPDATE_ORDER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        orderStatusResponse: [],
        error: action.payload,
      };
    // get client all orders list
    case GET_ALL_CLIENT_ORDERSLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CLIENT_ORDERSLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clientAllOrderList: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case GET_ALL_CLIENT_ORDERSLIST_FAILURE:
      return {
        ...state,
        loading: false,
        clientAllOrderList: [],
        error: action.payload,
      };

    // get order details
    case GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderDetails: action.payload,
        error: "",
      };
    case GET_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        orderDetails: [],
        error: action.payload,
      };
    case ORDER_FILTER_TYPES_STORE:
      return {
        ...state,
        orderFilterType: action.payload,
      };
    // order history
    case HISTORY_ORDER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        orderHistory: []
      };
    case HISTORY_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderHistory: action.payload,
        error: "",
      };
    case HISTORY_ORDER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        orderHistory: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
export default allOrdersReducer;
