import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import Button from "../Button/Button";
import Toasts from "../Toasts/Toasts";

const Transactions = () => {
  const [visible, setVisible] = useState(3);

  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Name = () => {
    return (
      <div>
        <div className="font-15 font-boldest text-dark ">iTunes Gift card</div>
        <div className="font-12 font-bolder text-muted">
          Transaction ID: UI8-8934AS
        </div>
      </div>
    );
  };
  const customerDetails = [
    // { id: 0, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$88.20" },
    // { id: 1, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$21.00" },
    // { id: 2, Date: "14 Oct 2021", Type: "Recharge", Name: Name(), Price: "$98.00", Amount: "-$9.80" },
    // { id: 3, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$54.00" },
    // { id: 4, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$98.00" },
    // { id: 5, Date: "14 Oct 2021", Type: "Recharge", Name: Name(), Price: "$98.00", Amount: "-$9.80" },
    // { id: 6, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$54.00" },
    // { id: 7, Date: "14 Oct 2021", Type: "Sale", Name: Name(), Price: "$98.00", Amount: "$98.00" },
    // { id: 8, Date: "14 Oct 2021", Type: "Recharge", Name: Name(), Price: "$98.00", Amount: "-$9.80" },
  ];

  const loadMore = () => {
    setVisible((prevVisible) => prevVisible + 10);
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="d-flex flex-row w-100  align-items-center">
          <div className="p-2 flex-grow-1">
            <div className="d-flex gap-3 my-2 mx-3 align-items-center">
              <Tag />
              <span className="font-20 font-bold">Transactions</span>
            </div>
          </div>
          {/* <div className="gap-1">
            <div className="dropdown">
              <button
                className="btn border border-2 btn-lg  dropdown-toggle font-14 font-bold text-muted"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Last 30 days
              </button>
              <ul
                className="dropdown-menu "
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  Last 10 days
                </li>
                <li>
                  Last 15 days
                </li>
                <li>
                  Last 30 days
                </li>
              </ul>
            </div>
          </div>*/}
          {/*  <div className="mx-4">
            <Button
              type="primary"
              text="Download CSV"
              className="rounded-6 my-3 mt-6 fw-bolder"
              onClick={() => toggleShowToast()}
            />
          </div>*/}
        </div>

        <div className="row px-5 py-3">
          <table responsive="xl">
            <tbody>
              <tr className="card-body">
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Date
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Type
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Detail
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Price
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  Amount
                </td>
              </tr>

              {customerDetails.slice(0, visible).map((item) => (
                <tr className="card-body hover-row" key={item.id}>
                  <td className="card-body gap-1">
                    <span className="font-15 font-bold text-dark ">
                      {item.Date}
                    </span>
                  </td>
                  <td className="card-body gap-1">
                    <div className="d-flex flex-row justify-content-between  gap-3">
                      <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 py-1 px-2">
                        {item.Type}
                      </span>
                    </div>
                  </td>
                  <td className="card-body gap-1">
                    <span className="">{item.Name}</span>
                  </td>
                  <td className="card-body gap-1">
                    <span className="font-15 font-bold text-dark ">
                      {item.Price}
                    </span>
                  </td>
                  <td className="card-body gap-1">
                    <span className="font-15 font-bold text-dark ">
                      {item.Amount}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {customerDetails.length === 0 ? (
            <div className="d-flex flex-row justify-content-center text-danger">
              No transactions data found
            </div>
          ) : null}
        </div>
        {/*  <div className="d-flex flex-row  justify-content-center align-item-center gap-3">
          <div className="d-flex">
            <Button
              type="rounded"
              text="Load more"
              className="rounded-6 my-4 mt-6 "
              onClick={() => loadMore()}
            />
          </div>
        </div>*/}
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default Transactions;
