import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../../assests/svg/Success.svg";
import Button from "../../../components/Button/Button";
import { useTranslation } from "react-i18next";

const GiftCardOrderSuccess = () => {
  const history = useNavigate()
  const { t } = useTranslation();

  const createOrder = useSelector((state) => state.createOrder?.createOrderResponse);

  const [permissions, setpermissions] = useState();
  const [usertype, setusertype] = useState();


  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    setusertype(JSON.parse(localStorage.getItem("user")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions")]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToHome = () => {
    history('/gift-card-distribution')
  }

  const goToOrderDetail = () => {
    history(`/order-management/gift-card?orderid=${createOrder?.id}`)
  }

  return (
    <div className="d-flex flex-column  align-item-center bg-light w-100 py-5">
      <div className="d-flex flex-row w-100 justify-content-center">
        <SuccessIcon />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-dark font-bold font-40">
          {t("Order placed successfully")}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-muted font-bold font-15">
          {t("Order ID")}: {createOrder?.id}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"primary"} text={t("Go to Home")} onClick={goToHome} />
      </div>
      {permissions?.order_management_module?.access && usertype?.user_type === "internal" ?
        <div className="d-flex flex-row w-100 justify-content-center mt-2">
          <Button type={"rounded"} text={t("Order details")} onClick={goToOrderDetail} />
        </div> : null}
    </div>
  );
};
export default GiftCardOrderSuccess;
