import React from "react";

const InputGroupTxt = (props) => {
  const {
    name,
    value,
    onChange,
    placeholder,
    title,
    lable,
    type,
    disabled,
    onKeyPress,
    lang,
    inputRef,
  } = props;

  return (
    <>
      <div className="input-group  ">
        <div
          className={
            lang === "ar"
              ? "d-flex flex-column input-group-text px-3 border-radius-right-ar"
              : "d-flex flex-column input-group-text px-3 border-radius-right "
          }
        >
          <span className="font-bold font-14 py-1">{title}</span>
          {lable ? <span className="font-12 text-muted">{lable}</span> : null}
        </div>
        <input
          name={name}
          value={value}
          onChange={(e) => onChange(e)}
          type={type}
          className={
            lang === "ar"
              ? "form-control border border-2 px-2 h-auto border-radius-left-ar"
              : "form-control border border-2 px-2 h-auto border-radius-left"
          }
          placeholder={placeholder}
          ref={inputRef ? inputRef : null}
          disabled={disabled}
          onKeyPress={onKeyPress}
          min="0"
        />
      </div>
    </>
  );
};

export default InputGroupTxt;
