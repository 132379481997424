/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import Toasts from "../Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import { getGiftcardDetails, getGiftcardTransactions } from "../../Redux/Epos/eposAction";
import SmallToast from "../Toast/SmallToast";
import CopyToClipboard from "react-copy-to-clipboard";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";

const Transactions = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const giftcardTransactions = useSelector(
    (state) => state.eposState?.giftcardTransactions
  );
  const giftcardDetails = useSelector(
    (state) => state.eposState?.giftcardDetails
  );

  const [id, setId] = useState("");
  const [transactions, setTransactions] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getGiftcardTransactions());
    dispatch(getGiftcardDetails(props?.giftCardNumber));
  }, []);

  useEffect(() => {
    setTransactions(giftcardTransactions);
  }, [giftcardTransactions]);

  useEffect(() => {
    if (!giftcardDetails) {
      //  history("/epos/ehadaya-interface");
    }
  }, [giftcardDetails]);



  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 8);
    return val;
  };

  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">

        <div className="d-flex flex-row w-100  align-items-center justify-content-between gap-3">
          <div className="p-2 flex-grow-1 gap-3">
            <div className="d-flex gap-3 align-items-center ">
              <div className="d-flex gap-3 my-2 mx-3 align-items-center">
                <Tag />
                <span className="font-20 font-bold">{t("Transactions")}</span>
                <SearcBar
                  type="text"
                  name="search"
                  lang={props?.lang}
                  placeholder={t("Search by id")}
                  onChange={searchHandler}
                />
              </div>
            </div>
          </div>

        </div>

        <div className="row px-5 py-3">
          <table responsive="xl">
            <tbody>
              <tr className="card-body">
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  {t("GiftCard Id")}
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  {t("Transactions")}
                  <br /> {t("type")}
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  {t("GiftCard")}
                  <br /> {t("Reference number")}
                </td>
                <td className="card-body gap-1 font-13 font-bold text-muted">
                  {t("Transactions")}
                  <br />
                  {t("Amount")}
                </td>
              </tr>

              {transactions &&
                transactions
                  ?.filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.id.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <tr className="card-body" key={item?.id}>
                      <td className="card-body gap-1">
                        <span className="font-15 font-bold">{item?.id}</span>
                      </td>
                      <td className="card-body gap-1">
                        <div className="d-flex flex-row justify-content-between  gap-3">
                          <span className="font-15 font-bold">
                            {item?.transaction_type}
                          </span>
                        </div>
                      </td>
                      <td className="card-body gap-1">
                        <span
                          className="font-14 font-boldest text-dark visible mt-4"
                          title={item?.giftcard_ref_number}
                        >
                          {trimIdHandler(item?.giftcard_ref_number)}
                          <span>...</span>
                        </span>
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Copy to clipboard"
                          className="cursor "
                          value={item?.giftcard_ref_number}
                          onClick={() =>
                            copyToClipboard(item?.giftcard_ref_number)
                          }
                        >
                          <CopyToClipboard text={item?.giftcard_ref_number}>
                            <CopyButton />
                          </CopyToClipboard>
                        </span>
                      </td>
                      <td className="card-body gap-1">
                        <span className="font-15 font-bold text-orange ">
                          {item.transaction_amount}
                        </span>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x mb-2 w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
      <div>
        <SmallToast data={id ? id : ""} message={"Copied"} />
      </div>
    </div>
  );
};

export default Transactions;
