import React from "react";

const Dropdown = (props) => {
    const { onClick, title, data } = props;
    const list = [
        { name: "data 1", code: "en" },
        { name: "data 2", code: "ar" }
    ];
    return (
        <div className="btn-group w-100">
            <div className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2" data-bs-toggle="dropdown">
                {data && title ? title : "Select"}
                {/* {title && title} */}
            </div>
            <ul className="dropdown-menu rounded-card-7 dropdown-menu-start">
                {data ?
                    data && data.map((item, i) =>
                        <li key={i} onClick={() => onClick(item)}><div className="dropdown-item cursor">{item.name}</div></li>
                    ) :
                    list.map((item, i) =>
                        <li key={i} onClick={() => onClick(item)}><div className="dropdown-item cursor">{item.name}</div></li>
                    )}
            </ul>
        </div>


    );
};

export default Dropdown;
