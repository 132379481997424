import {
  GET_SAMPLE_BULK_DISTRIBUTION_REQUEST,
  GET_SAMPLE_BULK_DISTRIBUTION_SUCCESS,
  GET_SAMPLE_BULK_DISTRIBUTION_FAILURE,
  GET_BULK_DISTRIBUTION_REQUEST,
  GET_BULK_DISTRIBUTION_SUCCESS,
  GET_BULK_DISTRIBUTION_FAILURE,
  POST_BULK_DISTRIBUTION_REQUEST,
  POST_BULK_DISTRIBUTION_SUCCESS,
  POST_BULK_DISTRIBUTION_FAILURE,
  POST_BULK_DISTRIBUTION_ERROR,
} from "./BulkDistributionType";

import { baseUrl, getToken } from "../../../utils/prodURL";
import axios from "axios";
import fileDownload from "js-file-download";

export const getSampleBulkDistributionRequest = () => {
  return {
    type: GET_SAMPLE_BULK_DISTRIBUTION_REQUEST,
  };
};

export const getSampleBulkDistributionSuccess = (bulkdistribution) => {
  return {
    type: GET_SAMPLE_BULK_DISTRIBUTION_SUCCESS,
    payload: bulkdistribution,
  };
};

export const getSampleBulkDistributionFailure = (error) => {
  return {
    type: GET_SAMPLE_BULK_DISTRIBUTION_FAILURE,
    payload: error,
  };
};

export const getBulkDistribution = () => {
  return (dispatch) => {
    dispatch(getSampleBulkDistributionRequest());

    axios
      .get(`${baseUrl}/v1/giftcard/manual_inventory/sample_file`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        // console.log(res);
        fileDownload(res.data, "Manual_Inventory_Template.xlsx");
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          getSampleBulkDistributionFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// Getting excel data list
export const getBulkDistributionExcelDataRequest = () => {
  return {
    type: GET_BULK_DISTRIBUTION_REQUEST,
  };
};

export const getBulkDistributionExcelDataSuccess = (data) => {
  return {
    type: GET_BULK_DISTRIBUTION_SUCCESS,
    payload: data,
  };
};

export const getBulkDistributionExcelDataFailure = (error) => {
  return {
    type: GET_BULK_DISTRIBUTION_FAILURE,
    payload: error,
  };
};

export const getManualInventoryExcelData = () => {
  return (dispatch) => {
    dispatch(getBulkDistributionExcelDataRequest());

    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcard/manual_inventory`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.data);
        dispatch(getBulkDistributionExcelDataSuccess(response.data));
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          getBulkDistributionExcelDataFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// POST BULK DISTRIBUTION
export const postBulkDistributionSuccess = (manualInventoryRes) => {
  return {
    type: POST_BULK_DISTRIBUTION_SUCCESS,
    payload: manualInventoryRes,
  };
};

export const postBulkDistributionSuccessErrors = (postErrors) => {
  return {
    type: POST_BULK_DISTRIBUTION_ERROR,
    payload: postErrors,
  };
};

export const postBulkDistribution = (fileArr, goToSuccessfull) => {
  return (dispatch) => {
    // console.log(fileArr[0]);
    // console.log("In ActionFile ");

    const formData = new FormData();
    formData.append("file", fileArr[0]);
    // console.log(formData);

    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcard/manual_inventory`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          dispatch(postBulkDistributionSuccess(response.data));
          goToSuccessfull();
        }
        dispatch(postBulkDistributionSuccessErrors(response.data));
      })
      .catch((error) => {
        console.log("error ", error);
        console.log("Something went wrong");
      });
  };
};
