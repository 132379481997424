import {
  GET_GIFTCARD_DETAILS_FAILURE,
  GET_GIFTCARD_DETAILS_REQUEST,
  GET_GIFTCARD_DETAILS_SUCCESS,
  GET_GIFTCARD_TRANSACTIONS_FAILURE,
  GET_GIFTCARD_TRANSACTIONS_REQUEST,
  GET_GIFTCARD_TRANSACTIONS_SUCCESS,
  SET_STORE_DETAILS,
  REDDEM_GIFTCARD_REQUEST,
  REDDEM_GIFTCARD_SUCCESS,
  REDDEM_GIFTCARD_FAILURE,
  ACTIVATION_GIFTCARD_FAILURE,
  ACTIVATION_GIFTCARD_SUCCESS,
  ACTIVATION_GIFTCARD_REQUEST,
  SET_SELL_CARD_DATA,
  POST_SELL_GIFTCARD_REQUEST,
  POST_SELL_GIFTCARD_SUCCESS,
  POST_SELL_GIFTCARD_FAILURE,
} from "./eposType";

const initialState = {
  loading: false,
  storeDetails: "",
  giftcardDetails: "",
  giftcardTransactions: "",
  reedemGiftcardResponse: null,
  activationGiftcardResponse: "",
  sellGiftcardResponse: null,
  sellCardData: null,
  error: "",
};

const eposReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE_DETAILS:
      return {
        ...state,
        storeDetails: action.payload,
      };
    case GET_GIFTCARD_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        giftcardDetails: "",
        error: "",
      };
    case GET_GIFTCARD_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcardDetails: action.payload,
        error: "",
      };
    case GET_GIFTCARD_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        giftcardDetails: "",
        error: action.payload,
      };
    case GET_GIFTCARD_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        giftcardTransactions: "",
        error: "",
      };
    case GET_GIFTCARD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        giftcardTransactions: action.payload,
        error: "",
      };
    case GET_GIFTCARD_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        giftcardTransactions: "",
        error: action.payload,
      };
    case REDDEM_GIFTCARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case REDDEM_GIFTCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        reedemGiftcardResponse: action.payload,
        error: "",
      };
    case REDDEM_GIFTCARD_FAILURE:
      return {
        ...state,
        loading: false,
        reedemGiftcardResponse: "",
        error: action.payload,
      };
    case ACTIVATION_GIFTCARD_REQUEST:
      return {
        ...state,
        loading: true,
        reedemGiftcardResponse: "",
        error: "",
      };
    case ACTIVATION_GIFTCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        reedemGiftcardResponse: action.payload,
        error: action.payload,
      };
    case ACTIVATION_GIFTCARD_FAILURE:
      return {
        ...state,
        loading: false,
        reedemGiftcardResponse: "",
        error: action.payload,
      };
    case SET_SELL_CARD_DATA:
      return {
        ...state,
        loading: false,
        sellCardData: action.payload,
        error: "",
      };
    case POST_SELL_GIFTCARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case POST_SELL_GIFTCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        sellGiftcardResponse: action.payload,
        error: "",
      };
    case POST_SELL_GIFTCARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default eposReducer;
