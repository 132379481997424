/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import { ReactComponent as UpArrow } from "../../assests/svg/Frame 87.svg";
import { ReactComponent as RedDownArrow } from "../../assests/svg/Product/Red_DownArrow.svg";
import product_default from "../../assests/img/Product-default.png";
import Button from "../Button/Button";
import InputGroupTxt from "../InputField/InputGroupTxt";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../Toasts/Toasts";
import Dropdown from "react-bootstrap/Dropdown";
import { putClientMerchandiseMappingProd } from "../../Redux/MappingProducts/Client/Merchandise/ClientMerchandiseAction";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import ModalComp from "../Models/ModalComp";
import ModelForMerchandiseSummery from "../Models/ModelForMerchandiseSummery";
import { getMerchandiseSummeryById } from "../../Redux/Merchandise/merchandiesAction";
import { fetchClientMappedMerchandiseDetailsall } from "../../Redux/Distributions/ClientMerchandiseDistributions/ClientMerchandiseDistributionAction";
import PulseLoader from "../Loader/PulseLoader";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";

const EditMappingProdWithClient = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const loading = useSelector((state) => state.clientMappedMerchandise.loading);
  const clientMapMerchandiseList = useSelector((state) => state.clientMappedMerchandise.clientMappedMerchandise);

  // for model
  const merchandiseSummery = useSelector((state) => state?.merchandiseCatAttriData?.merchandiseSummery);

  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [products, setProducts] = useState([]);
  const [showHideCheckBox, setShowHideCheckBox] = useState(true)
  const [error, setError] = useState({
    supplier: "",
    sellingPrice: "",
  });

  const handleClose = () => setShowModal(false);

  const toggleShowToast = () => setShowToast(!showToast);

  const [searchTerm, setSearchTerm] = useState("");
  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };


  useEffect(() => {
    window.scrollTo(0, 0);

    let arr = clientMapMerchandiseList;
    let prodArr = [];
    let selectedProducts = [];
    let selectedProductsVariants = [];

    let client = {
      id: location.state?.id,
      selectedClient: location.state?.selectedClient,
    };


    arr && arr.map((item) => {
      if (item.merchandise.product_variants_flag && item.merchandise_variant && item.merchandise_variant.length > 0) {
        let variantsArr = [...item.merchandise_variant];
        variantsArr && variantsArr.map((val, j) => {

          let obj = {
            product: item.merchandise,
            productId: item.merchandise.id,
            product_title: item.merchandise.product_title,
            variantId: val.id,
            variantKeys: val?.variant?.variant_keys,
            variantValues: val?.variant?.variant_values,
            defaultImage: val?.images && val?.images[0]?.file_url,
            selling_price: val?.selling_price
          };

          selectedProductsVariants.push(obj);
        });

      } else {

        let obj = {
          product: item.merchandise,
          productId: item.merchandise.id,
          product_title: item.merchandise.product_title,
          selling_price: item?.selling_price
          // variantId: val.id,
          // variantKeys: val.variant_keys,
          // variantValues: val.variant_values
        };
        selectedProducts.push(obj);
      }
    });
    let arrProducts = [...selectedProducts, ...selectedProductsVariants];


    arrProducts && arrProducts.map((item, i) => {
      let obj = {
        product: item,
        client: client,
        supplier: item.suppliers && item.suppliers.length === 1 ? item.suppliers[0] : "",
        sellingPrice: item?.selling_price ? item?.selling_price : "",
        supplierPrice: "",
        margin: 0,
        profitPercentage: 0,
      };
      prodArr.push(obj);
    });
    setProducts(prodArr);
  }, [clientMapMerchandiseList]);


  useEffect(() => {
    if (!location.state?.partnerId) {
      history("/clients-mapping-dashboard");
    }
    dispatch(fetchClientMappedMerchandiseDetailsall(location.state?.partnerId));

  }, [location.state?.partnerId]);

  const handleChangeProduct = (e, { name }) => {
    let id = name;
    let arr = [...products];

    arr.map((item, i) => {
      if (i === id) {
        arr[i].supplier = e;
        arr[i].sellingPrice = "";
        arr[i].margin = 0;
        arr[i].profitPercentage = 0;

      }
    });
    setProducts(arr);
  };
  // SupplierPrice handler
  const priceInputHandler = (e) => {
    let supPrice = e.target.value;
    let index = e.target.name;
    let arr = [...products];
    arr.map((item, i) => {
      if (i === parseInt(index)) {
        arr[i].supplierPrice = supPrice;
      }
    });
    setProducts(arr);
  };

  // Selling Price Handler
  const sellingInputHandler = (e) => {
    let regex = /^\d{0,6}(\.\d{0,2})?$/;
    let sellingPrice = e.target.value;
    let index = e.target.name;
    let arr = [...products];
    if (regex.test(e.target.value)) {
      arr.map((item, i) => {
        if (i === parseInt(index)) {
          arr[i].sellingPrice = sellingPrice;
          getMargin(item.supplier.selling_price, item.sellingPrice, index);
        }
      });
      setProducts(arr);
    }
  };


  /* #### CHECKS OR UNCHECKS ALL FROM SELECT ALL CLICK #### */

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...products];

    arr.map((item, i) => {
      if (i === parseInt(name)) {
        arr[i].isChecked = checked;
      }
    });
    setProducts(arr);
  };

  const getMargin = (supplierPrice, sellingPrice, index) => {
    let val = sellingPrice - supplierPrice;
    let per = ((val / supplierPrice) * 100).toFixed(2);

    let arr = [...products];

    arr.map((item, i) => {
      if (i === parseInt(index)) {
        arr[i].margin = val;
        arr[i].profitPercentage = per;
      }
    });
    setProducts(arr);

    // return val
  };

  // all checked select
  const allCheckedHandle = (e) => {
    const { checked } = e.target;
    let allSelect = [...products];
    allSelect && allSelect.map((item, i) => {
      allSelect[i].isChecked = checked;
    });
    setProducts(allSelect);
  };

  // custom three dots
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </a>
  ));

  // Add new row
  // const addRow = (item) => {
  //   let arr = [...products];
  //   let obj = {
  //     ...item,
  //     supplier: "",
  //     sellingPrice: "",
  //     supplierPrice: "",
  //     margin: 0,
  //     profitPercentage: 0,
  //   };

  //   arr.unshift(obj);
  //   setProducts(arr);
  // };

  // delete row
  const deleteRow = (index) => {
    let arr = [...products];
    if (index > -1) {
      arr.splice(index, 1);
    }
    setProducts(arr);
    if (arr.length === 0) {
      setShowHideCheckBox(false)
    }
  };

  const validate = () => {
    let checkArr = [];

    products && products.map((item) => {
      if (item.isChecked) {
        checkArr.push(item);
      }
    });
    if (checkArr.length > 0) {
      //  let valSKU = checkArr && checkArr.every(v => v.supplierSKU);
      let valSupplier = checkArr && checkArr.every((v) => v.supplier);
      let valSellingPrice = checkArr && checkArr.every((v) => v.sellingPrice);

      valSupplier && valSellingPrice ? setError({ supplier: "", sellingPrice: "" }) : setError({ supplier: "Select supplier", sellingPrice: "Enter price" });

      return valSupplier && valSellingPrice ? true : false;
    } else {
      toggleShowToast();
      return false;
    }
  };

  const successPage = () => {
    return history("/clients-map-successfull")
  }

  const submit = () => {
    let value = validate();

    if (value) {
      let arr = [...products];
      let selectedProducts = [];

      arr.map((item) => {
        if (item.isChecked === true) {
          selectedProducts.push(item);
        }
      });
      if (selectedProducts.length > 0) {
        dispatch(putClientMerchandiseMappingProd(selectedProducts, successPage));
        // history("/clients-map-successfull");
      }
    }
  };

  const showModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowModal(true);
  };

  return (
    loading === true ? (
      <div className="d-flex flex-column justify-content-center align-item-center">
        <h5 className="text-center">
          <PulseLoader type={"data"} />
        </h5>
      </div>
    ) : (
      <div className="d-flex flex-column justify-content-start gap-3 py-4 pb-3 px-4 bg-light">
        <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
          <div className="d-flex flex-row align-items-center pb-2 gap-3">
            <LeftIcon onClick={() => history(-1)} className="cursor" />
            <span className="font-bold font-32">{t("Client mapping")}</span>
          </div>
        </div>

        <div className="d-flex flex-column bg-white shadow-sm rounded-3 w-100 py-3 px-4 gap-3 ">
          <div className="card-body d-flex justify-content-between flex-row ">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-20 font-bold">{t("Mapping variants")}</span>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={"Search Product"}
                onChange={searchHandler}
              />
            </div>
          </div>
          <div className="card-body d-flex flex-column px-2 gap-3">
            <table responsive="xl" cellPadding={3} cellSpacing={0}>
              <tbody>
                <tr className="border-bottom">
                  <td className="col-2">
                    <div className="d-flex flex-row justify-content-start text-dark gap-3">
                      {showHideCheckBox === true ?
                        <span>
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="checkboxNoLabel"
                            checked={
                              products.filter(
                                (product) => product?.isChecked !== true
                              ).length < 1
                            }
                            onChange={allCheckedHandle}
                          />
                        </span>
                        : null}
                      <div className="d-flex flex-row justify-content-start text-dark">
                        <span className="font-12 font-boldest text-muted">
                          {t("Name")}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td className="col-2">
                    <div className="d-flex ">
                      <span className="font-12 font-boldest text-muted">
                        {t("Client")}
                        <br />
                        {t("name")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2 px-2">
                    <div className="d-flex justify-content-start">
                      <span className="font-12 font-boldest text-muted">
                        {t("Supplier")}
                        <br />
                        {t("name")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2 px-2">
                    <div className="d-flex justify-content-start">
                      <span className="font-12 font-boldest text-muted">
                        {t("Supplier")}
                        <br />
                        {t("price")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2 px-2">
                    <div className="d-flex justify-content-start">
                      <span className="font-12 font-boldest text-muted">
                        {t("Selling")} <br /> {t("Price")}
                      </span>
                    </div>
                  </td>
                  <td className="col-2">
                    <div className="d-flex flex-column">
                      <span className="font-12 text-success font-boldest">
                        {t("Hadaya")}
                        <br />
                        {t("margin")}
                      </span>
                    </div>
                  </td>
                </tr>
                {products &&
                  products?.filter((item) => {
                    if (searchTerm === "") {
                      return item;
                    } else if (
                      item?.product?.product?.default_title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                    .map((item, index) => (
                      <tr key={index} className="border-bottom">
                        <td className="col-4">
                          <div className="d-flex flex-row justify-content-start align-items-center py-1 gap-2">
                            <input
                              className="cursor form-check-input"
                              type="checkbox"
                              id="checkboxNoLabel"
                              checked={item?.isChecked}
                              name={index}
                              onChange={handleCheck}
                            />
                            {item?.product?.product?.product_variants_flag === true ?
                              <img
                                src={item?.product?.defaultImage ? item?.product?.defaultImage : product_default}
                                alt="not found"
                                id="product-thumbnail"
                              /> :
                              <img
                                src={item?.product?.product?.images && item?.product?.product?.images[0]?.file_url ? item?.product?.product?.images[0]?.file_url : product_default}
                                alt="not found"
                                id="product-thumbnail"
                              />}
                            <div className="d-flex flex-column justify-content-center align-items-start">
                              <span className="font-bold text-dark font-15 cursor"
                                onClick={() => showModel(item?.product?.product?.id)}
                              >
                                {item?.product?.product?.default_title}

                              </span>
                              <span className="font-12 text-muted">
                                {item?.product?.variantValues && item?.product?.variantValues.join("|")}
                              </span>
                            </div>

                          </div>
                        </td>
                        {/*client dropdown*/}
                        <td className="col">
                          <div className="d-flex">
                            <span>{item?.client?.selectedClient?.partner_name}</span>
                          </div>
                        </td>
                        {/*Supplier dropdown*/}
                        <td className="col-2 p-2">
                          <div className="d-flex flex-column">
                            <Select
                              placeholder="Select"
                              noOptionsMessage={() => "Not found"}
                              getOptionLabel={(suppliersListState) =>
                                suppliersListState.name
                              }
                              options={item.product.suppliers}
                              styles={CUSTOM_DROPDOWN_STYLE}
                              value={item.supplier}
                              onChange={handleChangeProduct}
                              isClearable
                              name={index}
                            />
                            {!item.supplier && item.isChecked ? (
                              <label className="text-error-client-supplier">
                                {error.supplier}
                              </label>
                            ) : null}
                          </div>
                        </td>
                        <td className="col p-2">
                          <div className="d-flex justify-content-start align-items-center">
                            <InputGroupTxt
                              title={item?.product?.product?.country?.currency_symbol ?
                                item?.product?.product?.country?.currency_symbol : "$"}
                              placeholder={"0"}
                              type="number"
                              lang={props?.lang}
                              value={
                                item.supplier === ""
                                  ? 0
                                  : item?.supplier?.selling_price
                              }
                              onChange={priceInputHandler}
                              name={index}
                              disabled={true}
                            />
                          </div>
                        </td>
                        <td className="col-2 p-2">
                          <div className="d-flex align-item-center justify-content-center gap-2">
                            <div className="d-flex flex-column justify-content-start">
                              <InputGroupTxt
                                title={item?.product?.product?.country?.currency_symbol ?
                                  item?.product?.product?.country?.currency_symbol : "$"}
                                placeholder={t("Price")}
                                type="text"
                                lang={props?.lang}
                                value={item.sellingPrice}
                                onChange={sellingInputHandler}
                                name={index}
                              />
                              {!item.sellingPrice && item.isChecked ? (
                                <label className="text-error-client-supplier">
                                  {error.sellingPrice}
                                </label>)
                                //  :
                                //   item.sellingPrice && item.isChecked && item?.supplier?.selling_price > item.sellingPrice ? (
                                //     <label className="text-error-client-supplier">
                                //       {item.error}
                                //     </label>
                                //   )
                                : null}


                            </div>
                          </div>
                        </td>
                        <td className="col-2">
                          <div className="d-flex flex-column align-item-center justify-content-center gap-1">
                            {item.sellingPrice && item.supplier && (
                              <div className="d-flex flex-column align-item-center">
                                {item.sellingPrice > item.supplierPrice ? (
                                  <div className="d-flex flex-row align-item-center gap-1">
                                    <UpArrow className="" />
                                    <span className="font-13 font-bold brand-color-05">
                                      {item.profitPercentage}
                                    </span>
                                  </div>
                                ) : item.sellingPrice < item.supplierPrice ? (
                                  <div className="d-flex flex-row align-item-center gap-1">
                                    <RedDownArrow />
                                    <span className="font-13 font-bold brand-color-03">
                                      {item.profitPercentage}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="d-flex flex-row align-item-center gap-1">
                                    {/* <RedDownArrow />
                                  <span className="font-13 font-bold brand-color-03">
                                    {item.profitPercentage}
                                  </span> */}
                                  </div>
                                )}

                              </div>
                            )}
                            <div className="font-13 font-bold text-muted">
                              {item.sellingPrice && item.margin > 0 && item.margin.toFixed(2)}
                              {item.sellingPrice && item.margin < 0 && item.margin.toFixed(2)}
                            </div>
                          </div>
                        </td>
                        <td className="col-1">
                          <div className="d-flex flex-row gap-2">
                            <Dropdown>
                              <Dropdown.Toggle as={CustomToggle} />
                              <Dropdown.Menu size="sm" title="">
                                {/* <Dropdown.Header>Options</Dropdown.Header>*/}
                                {/* <Dropdown.Item className="cursor">Edit</Dropdown.Item>*/}

                                <Dropdown.Item
                                  className="cursor"
                                  onClick={() => deleteRow(index)}
                                >
                                  {t("Delete")}
                                </Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex">
          <Button type="primary" text={t("Update")} onClick={() => submit()} />
        </div>

        < ModalComp
          size="lg"
          showModal={showModal}
          // handleClose={handleClose}
          component={
            <ModelForMerchandiseSummery
              handleClose={handleClose}
              merchandiseSummery={merchandiseSummery}
            />
          }
        />

        <div
          className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
          id="liveToast"
        >
          <Toasts
            position={"bottom-center"}
            showToast={showToast}
            toggleShowToast={toggleShowToast}
            warningMsg="Please select giftcards/products to map."
            imageType={"warning"}
          />
        </div>
      </div>
    )
  );
};

export default EditMappingProdWithClient;
