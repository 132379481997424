/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MerchanDistributionTable from "../../../components/Distribution/MerchanDistributionTable";
import { DeleteGiftcardDetails } from "../../../Redux/CreateOrder/CreateOrderAction";
import { useTranslation } from "react-i18next";

const MerchandiseDistribution = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const clientsState = useSelector((state) => state?.clients?.client);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(DeleteGiftcardDetails())
  }, []);

  return (
    <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">{t("Merchandise distribution")}</span>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-start gap-2">
        <MerchanDistributionTable lang={props?.lang} clientsState={clientsState} />
      </div>
    </div>
  );
};

export default MerchandiseDistribution;
