import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import TagsInput from "../../components/TagsInput/TagsInput";
import BackButton from "../../components/Button/BackButton";
import { fetchBrand } from "../../Redux/Brands/brandAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProdCategory } from "../../Redux/ProdCategory/prodCatAction";
import { getWeight } from "../../Redux/Weight/weightAction";
import { setMerchandiseCategoryAttribute } from "../../Redux/Merchandise/merchandiesAction";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import { getShippingCost } from "../../Redux/ShippingCost/shippingcostAction";
import { getSalestax } from "../../Redux/SalesTax/salestaxAction";
import { getVat } from "../../Redux/Vat/vatAction";
import { getWarranty } from "../../Redux/Warranty/warrantyAction";
import ReactDatePicker from "../../components/DatePicker/ReactDatePicker";
import { fetchAllCountrieDetails } from "../../Redux/Countries/countryAction";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import InputGroupTxt from "../../components/InputField/InputGroupTxt";
import ReactSwitchButton from "../../components/Button/ReactSwitchButton";
import Select from "react-select";
import Toasts from "../../components/Toasts/Toasts";
import { VALIDITY_UNITS } from "../../utils/ConstantData";
import { nextYearRange } from "../../utils/constantFunction";
import { fetchBrandCategoryByID } from "../../Redux/BrandCategories/brandCategoryAction";
import { useTranslation } from "react-i18next";

const MerchandiseCategory = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);

  const inputElementCountry = useRef(null)
  const inputElementBrand = useRef(null)
  

  const brands = useSelector((state) => state?.brands?.brands);
  const countries = useSelector((state) => state?.countries?.countries);
  const mercandiseNameDescState = useSelector((state) => state?.merchandiseCatAttriData?.merchandiseNameDes);
  // const shippingCost = useSelector((state) => state?.shippingcost?.shippingcost);

  //const salesTax = useSelector((state) => state?.salestax?.salestax);
  //const vat = useSelector((state) => state?.vat?.vat);
  // const warranty = useSelector((state) => state?.warranty?.warranty);

  const brandCategories = useSelector(
    (state) => state?.brandCategories?.brandCategoriesbyID
  );

  const weights = useSelector((state) => state?.weights?.weight);
  const merchandiseCatAttriData = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseCatAtt
  );

  const [fields, setFields] = useState({
    brand: merchandiseCatAttriData?.selectBrand
      ? merchandiseCatAttriData?.selectBrand
      : "",
    countryCurrency: merchandiseCatAttriData?.countryCurrency
      ? merchandiseCatAttriData?.countryCurrency
      : "",
    state: merchandiseCatAttriData?.state ? merchandiseCatAttriData?.state : "",

    brandCategory: merchandiseCatAttriData?.brandCategory
      ? merchandiseCatAttriData?.brandCategory
      : "",
    weight: merchandiseCatAttriData?.weight
      ? merchandiseCatAttriData?.weight
      : "",
    saleTax: merchandiseCatAttriData?.saleTax
      ? merchandiseCatAttriData?.saleTax
      : "",
    shippingCost: merchandiseCatAttriData?.shippingCost
      ? merchandiseCatAttriData?.shippingCost
      : "",
    vat: merchandiseCatAttriData?.vat ? merchandiseCatAttriData?.vat : "",
    warranty: merchandiseCatAttriData?.warranty
      ? merchandiseCatAttriData?.warranty
      : "",
    startDate: merchandiseCatAttriData?.startDate
      ? merchandiseCatAttriData?.startDate
      : new Date(),
    expireDate: merchandiseCatAttriData?.expireDate
      ? merchandiseCatAttriData?.expireDate
      // Function next year form present date 
      : nextYearRange(),
    weightInput: merchandiseCatAttriData?.weightInput
      ? merchandiseCatAttriData?.weightInput
      : "",
    shippingCostInput: merchandiseCatAttriData?.shippingCostInput
      ? merchandiseCatAttriData?.shippingCostInput
      : "",
    saleTaxInput: merchandiseCatAttriData?.saleTaxInput
      ? merchandiseCatAttriData?.saleTaxInput
      : "",
    vatInput: merchandiseCatAttriData?.vatInput
      ? merchandiseCatAttriData?.vatInput
      : "",
    warrantyInput: merchandiseCatAttriData?.warrantyInput
      ? merchandiseCatAttriData?.warrantyInput
      : "",
  });
  // tags input code
  const [brandTags, setBrandTags] = useState(
    merchandiseCatAttriData?.brandTags ? merchandiseCatAttriData?.brandTags : []
  );

  const [switchButton, setSwitchButton] = useState({
    activationFlag: merchandiseCatAttriData?.activationFlag
      ? merchandiseCatAttriData?.activationFlag
      : true,
    promotionItemFlag: merchandiseCatAttriData?.promotionItemFlag
      ? merchandiseCatAttriData?.promotionItemFlag
      : false,
    hasVariantFlag: merchandiseCatAttriData?.hasVariantFlag
      ? merchandiseCatAttriData?.hasVariantFlag
      : false,
  });
  const taxUnit = [
    {
      name: fields?.countryCurrency?.currency_symbol
        ? fields?.countryCurrency?.currency_symbol
        : "$",
    },
    { name: "%" },
  ];

  const [error, setError] = useState({
    countryCurrency: "",
    brand: ""
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchBrand());
    dispatch(getProdCategory());
    dispatch(getWeight());
    dispatch(getAllCountriesList());
    dispatch(getShippingCost());
    dispatch(getSalestax());
    dispatch(getVat());
    dispatch(getWarranty());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    if (!mercandiseNameDescState) {
      history("/add-merchandise");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mercandiseNameDescState]);

  const goBack = () => {
    history("/add-merchandise");
  };

  const states = useSelector(
    (state) => state?.countries?.countrieDetails?.states
  );

  // const handleChangeValue = (item, type) => {
  //   setFields({ ...fields, [type]: item });
  // };

  const handleChangeValue = (e) => {
    setFields({ ...fields, countryCurrency: e, state: "", saleTax: "", vat: "" });
    e && dispatch(fetchAllCountrieDetails(e?.id));
  };

  const handleChangeWeight = (e) => {
    setFields({ ...fields, weight: e });
  };
  const handleChangeBrand = (e) => {
    setFields({ ...fields, brand: e, brandCategory: "" });
    dispatch(fetchBrandCategoryByID(e.id))
  };
  const handleChangeProductCategories = (e) => {
    setFields({ ...fields, brandCategory: e });
  };
  const handleChangeStates = (e) => {
    setFields({ ...fields, state: e });
  };
  const handleChangeSalesTax = (e) => {
    setFields({ ...fields, saleTax: e });
  };
  const handleChangeWarranty = (e) => {
    setFields({ ...fields, warranty: e });
  };
  const handleChangeVat = (e) => {
    setFields({ ...fields, vat: e });
  };
  const handleChangeDate = (e) => {
    setFields({ ...fields, startDate: e });
  };
  const handleChangeEndDate = (e) => {
    setFields({ ...fields, expireDate: e });
  };

  //const toggleShowToast = () => setShowToast(!showToast);

  const handleInputChange = (e) => {


    let reg = /^\d{0,4}(\.\d{0,4})?$/;
    var value = e.target.value;
    const name = e.target.name;
    if (reg.test(e.target.value)) {
      setFields({
        ...fields,
        [name]: value,
      });
      value = "";
    } else {
      value = "";
    }
  };
  const changeActivate = () => {
    setSwitchButton({
      ...switchButton,
      activationFlag: !switchButton.activationFlag,
    });
  };

  const changePromotionItem = () => {
    setSwitchButton({
      ...switchButton,
      promotionItemFlag: !switchButton.promotionItemFlag,
    });
  };

  const changeHasVariant = () => {
    setSwitchButton({
      ...switchButton,
      hasVariantFlag: !switchButton.hasVariantFlag,
    });
  };

  const validate = () => {
    if (!fields.countryCurrency) {
      setError({ countryCurrency: "Giftcard type field is required" });
      if(inputElementCountry.current){
        inputElementCountry.current.focus()
      }
      return false;
    } else if (!fields.brand) {
      setError({ brand: "Brand field is required" });
      if(inputElementBrand.current){
        inputElementBrand.current.focus()
      }
      return false;
    }
    return true;
  };

  const saveAndNext = () => {
    let val = validate();
    if (val) {
      let Obj = {
        selectBrand: fields?.brand,
        countryCurrency: fields?.countryCurrency,
        state: fields?.state,
        brandCategory: fields.brandCategory,
        weight: fields.weight,
        shippingCost: fields.shippingCost,
        saleTax: fields.saleTax,
        vat: fields.vat,
        warranty: fields.warranty,
        promotionItemFlag: switchButton.promotionItemFlag,
        activationFlag: switchButton.activationFlag,
        hasVariantFlag: switchButton.hasVariantFlag,
        startDate: fields.startDate,
        expireDate: fields.expireDate,
        brandTags: brandTags,
        weightInput: fields.weightInput,
        shippingCostInput: fields.shippingCostInput,
        saleTaxInput: fields.saleTaxInput,
        vatInput: fields.vatInput,
        warrantyInput: fields.warrantyInput,
      };

      if (switchButton.hasVariantFlag === true) {
        dispatch(setMerchandiseCategoryAttribute(Obj));
        history("/add-variant");
      } else {
        toggleShowToast();
        dispatch(setMerchandiseCategoryAttribute(Obj));
        history("/merchandise/term-condition");
      }
    }
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 px-4 bg-light gap-3">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Add merchandise")}</span>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between gap-2">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-20 font-bold">{t("Category & Attributes")}</span>
            </div>
            <div>
              <BackButton onClick={() => history(-1)} />
            </div>
          </div>
          <div className="d-flex flex-column gap-3 mt-4">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Country & Currency")}<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Enter country & currency(type atleast 3 charachters to see dropdown effect)"}
              />
            </div>
            <div>
              <Select
              ref={inputElementCountry}
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select country & currency")}
                getOptionLabel={(countries) =>
                  countries.name + " -" + countries.currency
                }
                options={countries}
                //   isSearchable
                value={fields?.countryCurrency}
                onChange={handleChangeValue}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
              />
              {!fields.countryCurrency && error.countryCurrency ? (
                <label className="text-error">{error.countryCurrency}</label>
              ) : null}
            </div>
          </div>
          <div className="d-flex flex-column gap-3 mt-4">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">{t("State")}</span>
              <InfoTooltip
                title={"State"}
              />
            </div>
            {/* Dropdown state*/}
            <div>
              <Select
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select state")}
                getOptionLabel={(states) => states.name}
                options={states}
                //   isSearchable
                value={fields.state}
                onChange={handleChangeStates}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
                isDisabled={!fields?.countryCurrency}
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-4 mt-4 ">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Brand")}<span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Select Brand name"}
                  />
                </div>
                <span className="font-bold font-14 link-text-color" onClick={() => history("/brand-creation", { state: { productCreation: true } })}>
                  {t("Add Brand")}
                </span>
              </div>
              {/* Dropdown brand*/}
              <div className="">
                <Select
                ref={inputElementBrand}
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select brand")}
                  getOptionLabel={(states) => states?.default_name}
                  options={brands}
                  //   isSearchable
                  value={fields.brand}
                  onChange={handleChangeBrand}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
                {!fields.brand && error.brand ? (
                  <label className="text-error">{error.brand}</label>
                ) : null}
              </div>
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Brand category")}
                  </span>
                  <InfoTooltip
                    title={"Select Brand name"}
                  />
                </div>
                <span className="font-bold font-14 link-text-color" onClick={() => history("/category-creation", { state: { productCreation: true } })}>
                  {t("Add Brand Category")}
                </span>
              </div>
              {/* Dropdown product category*/}
              <div>
                <Select
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select product category")}
                  getOptionLabel={(brandCategories) => brandCategories?.default_category_name}
                  options={brandCategories}
                  //   isSearchable
                  value={fields.brandCategory}
                  onChange={handleChangeProductCategories}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                  isDisabled={!fields?.brand}
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Weight")}</span>
                <InfoTooltip
                  title={"Enter Weight"}
                />
              </div>
              {/* Dropdown input for Weight*/}
              <div className="input-group rounded-card-7">
                <button
                  className={`btn btn-outline-secondary dropdown-toggle border border-2 ${props?.lang === 'ar' ? 'prefix-dropdown-right-corner-ar' : 'prefix-dropdown-left-corner'}`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {fields.weight ? fields.weight?.weight : "Select"}
                </button>
                <ul className={` ${props?.lang === 'ar' ? 'dropdown-menu dropdown-menu-ar' : 'dropdown-menu'}`}  >
                  {weights &&
                    weights.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => handleChangeWeight(item, "weight")}
                      >
                        <div className="dropdown-item cursor">
                          {item.weight}
                        </div>
                      </li>
                    ))}
                </ul>
                <input
                  type="text"
                  name="weightInput"
                  placeholder={t("Enter weight")}
                  className={`form-control border border-2 ${props?.lang === 'ar' ? 'prefix-dropdown-left-corner-ar' : 'prefix-dropdown-right-corner'}`}
                  aria-label="Prefix"
                  value={fields.weightInput}
                  disabled={fields.weight ? false : true}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Shipping cost")}</span>
                <InfoTooltip
                  title={"Enter Shipping cost"}
                />
              </div>
              <div className=" d-flex flex-column gap-1">
                <InputGroupTxt
                  title={
                    fields?.countryCurrency?.currency_symbol
                      ? fields?.countryCurrency?.currency_symbol
                      : "$"
                  }
                  placeholder={t("Enter shipping cost")}
                  type="text"
                  name="shippingCostInput"
                  lang={props?.lang}
                  value={fields.shippingCostInput}
                  onChange={handleInputChange}
                />
              </div>

            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Sales tax")}</span>
                <InfoTooltip
                  title={"Enter Sales tax"}
                />
              </div>
              {/* Dropdown input for sales tax*/}
              <div className="input-group rounded-card-7">
                <button
                  className={`btn btn-outline-secondary dropdown-toggle border border-2  ${props?.lang === 'ar' ? 'prefix-dropdown-right-corner-ar' : 'prefix-dropdown-left-corner'}`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {fields.saleTax ? fields.saleTax?.name : "Select"}
                </button>
                <ul className={` ${props?.lang === 'ar' ? 'dropdown-menu dropdown-menu-ar' : 'dropdown-menu'}`}  >
                  {taxUnit &&
                    taxUnit.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => handleChangeSalesTax(item, "saleTax")}
                      >
                        <div className="dropdown-item cursor">{item.name}</div>
                      </li>
                    ))}
                </ul>
                <input
                  type="text"
                  name="saleTaxInput"
                  placeholder={t("Enter sales tax")}
                  className={`form-control border border-2 ${props?.lang === 'ar' ? 'prefix-dropdown-left-corner-ar' : 'prefix-dropdown-right-corner'}`}
                  aria-label="Prefix"
                  value={fields.saleTaxInput}
                  disabled={fields.saleTax ? false : true}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              {/* Dropdown sales tax
              <div>
                <Select
                  noOptionsMessage={() => "Not found"}
                  placeholder="Select sales tax"
                  getOptionLabel={(states) => states.sales}
                  options={salesTax}
                  //   isSearchable
                  value={fields.saleTax}
                  onChange={handleChangeSalesTax}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
              </div>*/}
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("VAT")}</span>
                <InfoTooltip
                  title={"Enter VAT"}
                />
              </div>
              {/* Dropdown input for vat*/}
              <div className="input-group rounded-card-7">
                <button
                  className={`btn btn-outline-secondary dropdown-toggle border border-2  ${props?.lang === 'ar' ? 'prefix-dropdown-right-corner-ar' : 'prefix-dropdown-left-corner'}`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {fields.vat ? fields.vat?.name : t("Select")}
                </button>
                <ul className={` ${props?.lang === 'ar' ? 'dropdown-menu dropdown-menu-ar' : 'dropdown-menu'}`}  >
                  {taxUnit &&
                    taxUnit.map((item, i) => (
                      <li key={i} onClick={() => handleChangeVat(item, "vat")}>
                        <div className="dropdown-item cursor">{item.name}</div>
                      </li>
                    ))}
                </ul>
                <input
                  type="text"
                  name="vatInput"
                  placeholder={t("Enter vat")}
                  className={`form-control border border-2 ${props?.lang === 'ar' ? 'prefix-dropdown-left-corner-ar' : 'prefix-dropdown-right-corner'}`}
                  aria-label="Prefix"
                  value={fields.vatInput}
                  disabled={fields.vat ? false : true}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>

            {/*  <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">VAT</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="btn-group w-100">
                <div
                  className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                  data-bs-toggle="dropdown"
                >
                  {fields.vat ? fields.vat?.vat : "Select"}
                </div>
                <ul className="dropdown-menu rounded-card-7 dropdown-menu-start">
                  {vat &&
                    vat.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => handleChangeValue(item, "vat")}
                      >
                        <div className="dropdown-item cursor">{item.vat}</div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>*/}

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Warranty")}</span>
                <InfoTooltip
                  title={"Enter Warranty"}
                />
              </div>
              {/* Dropdown input for warranty*/}
              <div className="input-group rounded-card-7">
                <button
                  className={`btn btn-outline-secondary dropdown-toggle border border-2  ${props?.lang === 'ar' ? 'prefix-dropdown-right-corner-ar' : 'prefix-dropdown-left-corner'}`}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {fields.warranty ? fields.warranty?.name : t("Select")}
                </button>
                <ul className={` ${props?.lang === 'ar' ? 'dropdown-menu dropdown-menu-ar' : 'dropdown-menu'}`}  >
                  {VALIDITY_UNITS &&
                    VALIDITY_UNITS.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => handleChangeWarranty(item, "warranty")}
                      >
                        <div className="dropdown-item cursor">{item.name}</div>
                      </li>
                    ))}
                </ul>
                <input
                  type="text"
                  name="warrantyInput"
                  placeholder={t("Enter warranty")}
                  className={`form-control border border-2 ${props?.lang === 'ar' ? 'prefix-dropdown-left-corner-ar' : 'prefix-dropdown-right-corner'}`}
                  aria-label="Prefix"
                  value={fields.warrantyInput}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              {/* Dropdown Warranty
              <div>
                <Select
                  noOptionsMessage={() => "Not found"}
                  placeholder="Select warranty"
                  getOptionLabel={(states) => states.warranty}
                  options={warranty}
                  //   isSearchable
                  value={fields.warranty}
                  onChange={handleChangeWarranty}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
              </div>*/}
            </div>

            <div className="d-flex flex-row justify-content-between gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Promotion Item")}</span>
                <InfoTooltip
                  title={"Enable switch to make product Promotional Item"}
                />
              </div>
              <ReactSwitchButton
                onChange={changePromotionItem}
                value={switchButton.promotionItemFlag}
              />
            </div>

            <div className="d-flex flex-row justify-content-between gap-3 ">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Active")}</span>
                <InfoTooltip title={"Active/Deactive product"} />
              </div>
              <ReactSwitchButton
                onChange={changeActivate}
                value={switchButton.activationFlag}
              />
            </div>
            <div className="d-flex flex-row justify-content-between gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Does your product has variants?")}
                </span>
                <InfoTooltip
                  title={"Enable if your product has variants"}
                />
              </div>
              <ReactSwitchButton
                onChange={changeHasVariant}
                value={switchButton.hasVariantFlag}
              />
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row justify-content-start align-items-center gap-2 w-100">
                <div className="col d-flex flex-column gap-2">
                  <span className="text-muted font-12 font-bold">
                    {t("Select start date")}
                  </span>
                  <ReactDatePicker
                    onChange={handleChangeDate}
                    selected={fields.startDate}
                    minDate={new Date()}
                    name={"startDate"}
                    lang={props?.lang}
                    className="datepicker-input-style-without-border"
                  />
                </div>
                <div className="col d-flex flex-column gap-2">
                  <span className="text-muted font-12 font-bold">
                    {t("Select end date")}
                  </span>
                  <ReactDatePicker
                    onChange={handleChangeEndDate}
                    selected={fields.expireDate}
                    minDate={fields.startDate}
                    name={"expireDate"}
                    lang={props?.lang}
                    className="datepicker-input-style-without-border"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row justify-content-between gap-3 ">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Tags")}</span>
                  <InfoTooltip
                    title={"Enter tags that describe your product"}
                  />
                </div>
                <span className="font-bolder font-12">
                  {t("1")}
                  <span className="font-12 font-bolder text-muted">
                    /{t("12 tags")}
                  </span>
                </span>
              </div>
              <div className="d-flex flex-row align-items-center gap-2">
                <TagsInput
                  tags={brandTags}
                  setTags={setBrandTags}
                  placeholder={"Enter tags to describe your brands"}
                />
              </div>
            </div>
          </div>
          <div className="d-flex my-2 mt-3">
            <Button
              onClick={() => saveAndNext()}
              text={t("Save & Next")}
              type="primary"
            />
          </div>
        </div>
      </div>

      <div className="position-fixed bottom-0 start-50 translate-middle-x w-100 " id="liveToast">
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Product create without variants"
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default MerchandiseCategory;
