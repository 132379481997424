/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import { ReactComponent as CloseIcone } from "../../assests/svg/close.svg"
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import { useDispatch, useSelector } from "react-redux";
import { TITLE_TEXT_LIMIT, } from "../../utils/ConstantData";
import ReactSwitchButton from "../../components/Button/ReactSwitchButton";
import InputField from "../../components/InputField/InputField";
import Toasts from "../../components/Toasts/Toasts";

const MerchandiseVariantsEditScreen = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const mercandiseNameDescState = useSelector((state) => state?.merchandiseCatAttriData?.merchandiseNameDes);

  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );


  let variantsObj = {
    title: mercandiseNameDescState?.prodTitles[0]?.title,
    optionTxt: "Color",
    option: null,
    requireImages: true,
    value: null,
    tags: []
  }

  const merchandiseVarientObjState = useSelector((state) => state?.merchandiseVarient?.merchandiseVarientObj);

  const [showToast, setShowToast] = useState(false);
  const [prod, setProd] = useState("");

  const [prodVariants, setProdVariants] = useState([]);

  const [error, setError] = useState({
    option: "",
    optionTxt: "",
    requireImages: "",
    tags: ""
  });


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (merchandiseSummery && merchandiseSummery[0]?.product_variants_flag) {
      setProd(merchandiseSummery);
      let varArr = []

      merchandiseSummery && merchandiseSummery[0]?.variants && merchandiseSummery[0]?.variants.map((item) => {
        let variantsObj = {
          id: item?.id,
          merchandise_id: item?.merchandise_id,
          title: item?.default_title,
          optionTxt: "Color",
          option: null,
          requireImages: true,
          value: null,
          tags: [],
          prod_Obj: item
        }
        varArr.push(variantsObj)
      })

      setProdVariants(varArr)
    }

    if (!merchandiseSummery) {
      history("/merchandise-dashboard");
    }

  }, [merchandiseSummery]);

  console.log("merchandiseSummery  ", prodVariants?.length);


  const toggleShowToast = () => setShowToast(!showToast);



  const handleSwitchButton = (index) => {
    let arr = [...prodVariants];
    arr && arr.map((item, i) => {
      if (i === index) {
        arr[i].requireImages = !item.requireImages;
      }
    })
    setProdVariants(arr);
  };

  const removeTags = (indexToRemove, index) => {
    let arr = [...prodVariants];
    arr && arr.map((item, i) => {
      if (i === index) {
        let tagsArr = arr[i].tags ? arr[i].tags : [];
        tagsArr.filter((_, j) => j !== indexToRemove);
        tagsArr.splice(indexToRemove, 1);
      }
    })
    setProdVariants(arr);
  };

  const addTags = (event, index) => {
    let arr = [...prodVariants];
    arr && arr.map((item, i) => {
      if (i === index) {
        if (event.target.value !== "") {
          let tagsArr = arr[i].tags ? arr[i].tags : [];
          tagsArr.push(event.target.value)
          event.target.value = "";
        }
      }
    })
    setProdVariants(arr);
  };

  const handleAddNewOption = (e) => {
    if (prodVariants.length < 3) {
      setProdVariants([...prodVariants, { optionTxt: "", option: null, requireImages: false, value: null, tags: [] }]);
    } else {
      setShowToast(true);
    }

  };

  const handleRemoveClick = index => {
    const list = [...prodVariants];
    list.splice(index, 1);
    setProdVariants(list);
  };

  const handleInputChangeState = (e) => {
    const { name, value } = e.target;
    let arr = [...prodVariants];

    arr && prodVariants && prodVariants.map((item, i) => {
      if (name === i) {
        arr[i].optionTxt = value;
      }
    })
    setProdVariants(arr);
  };

  const validate = () => {
    if (!prodVariants[0].optionTxt) {
      setError({ optionTxt: "Please enter option" });
      return false;
    } else if (!prodVariants[0].tags.length > 0) {
      setError({ tags: "Please enter variants tags" });
      return false;
    }
    return true;
  }
  const goBack = () => {
    history(-1);
  };

  const goTonextStep = () => {
    let value = validate();
    if (value) {
      createVarients();
      //  history("/add-variant/merchandise-mapping");
    }
  };

  const createVarients = () => {

    let attributes = {}

    // eslint-disable-next-line array-callback-return
    attributes && prodVariants && prodVariants.map((item, index) => {
      if (index === 0) {
        attributes[item.optionTxt] = item.tags ? item.tags : []
      } else if (index === 1) {
        attributes[item.optionTxt] = item.tags ? item.tags : []
      } else if (index === 2) {
        attributes[item.optionTxt] = item.tags ? item.tags : []
      }
    });

    let attrs = [];

    for (const [attr, values] of Object.entries(attributes))
      attrs.push(values.map(v => ({ [attr]: v })));

    attrs = attrs.reduce((a, b) => a.flatMap(d => b.map(e => ({ ...d, ...e }))));

    let arr = []

    prodVariants && prodVariants[0].tags.map((item, i) => {
      arr[i] = {
        color: item,
        images: []
      };
    })

    // dispatch(setMerchandiseVariantImages(arr));
    // dispatch(setMerchandiseVariants(attrs));
    // dispatch(setMerchandiseVariantsObj(prodVariants));
  }

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-5 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">Edit Variants</span>
          <br />
        </div>
      </div>
      <div className=" d-flex mb-4">
        {/* <span className="font-bolder font-14 text-muted ">
          Add/Edit variants if this product comes in multiple versions, sizes, colors
          etc.
        </span> */}
      </div>

      {prodVariants && prodVariants.map((item, index) =>
        <div className="d-flex flex-row justify-content-between gap-2 mb-3" key={index}>
          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">
            <div className="d-flex flex-column gap-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">Variant {index + 1}</span>
                    <InfoTooltip
                      title={"option for variants"}
                    />
                  </div>
                  <div className="cursor py-2" onClick={() => handleRemoveClick(index)}>
                    <CloseIcone />
                  </div>
                </div>
                <div className="d-flex flex-column gap-1">
                  <span className="font-14">Variants Id: {item?.id}</span>
                </div>

                <div className="d-flex flex-column gap-1">
                  <span className="font-14">{merchandiseSummery &&
                    merchandiseSummery[0]?.variants &&
                    merchandiseSummery[0]?.variants[0]?.variant_keys.join("|")}</span>
                </div>
                <div className="d-flex flex-column gap-1">
                  <span className="font-14">{item?.prod_Obj?.variant_values?.join("|")}</span>
                </div>

              </div>


            </div>
          </div>
        </div>
      )}

      <div className="d-flex flex-row justify-content-start gap-4 width-65 my-2">
        {/* <Button
          onClick={() => handleAddNewOption()}
          text="+ Add new option"
          type="square"
        /> */}
        <Button
          onClick={() => goTonextStep()}
          text="Update"
          type="primary"
        />
      </div>
      <div className="position-fixed bottom-0 start-50 translate-middle-x w-100 " id="liveToast"      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Maximum three varient add!"
          imageType={"warning"}
        />
      </div>
    </div>
  );
};


export default MerchandiseVariantsEditScreen;
