import React, { useEffect, useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/voilet_tag.svg";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import HadayaLogo from "../../assests/svg/Group10.svg";
import { ReactComponent as DropDownIcon } from "../../assests/svg/DropDown.svg";
import Collapse from "react-bootstrap/Collapse";
import { useDispatch, useSelector } from "react-redux";
import { getOrderHistory } from "../../Redux/Order/orderAction";
import {
  DateTimeFormatter,
  strFirstLetterCap,
} from "../../utils/constantFunction";

const OrderDetailTimeLine = (props) => {
  const dispatch = useDispatch();
  const { id } = props;
  const [orderTrackCollapse, setOrderTrackCollapse] = useState(false);
  const orderHistory = useSelector(
    (state) => state?.allOrderList?.orderHistory
  );

  useEffect(() => {
    if (id) {
      dispatch(getOrderHistory(id));
    }
  }, [id]);

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mt-2">
      <div
        className="d-flex flex-row justify-content-between align-items-center"
        onClick={() => setOrderTrackCollapse(!orderTrackCollapse)}
      >
        <div className="d-flex py-3 gap-3">
          <Tag />
          <span className="font-20 font-bold">Track order details</span>
        </div>
        <div
          className={`cursor p-0 ${orderTrackCollapse ? "rotate-180" : ""}`}
          aria-controls="example-collapse-text"
          aria-expanded={orderTrackCollapse}
        >
          <DropDownIcon
            className={`cursor p-0 ${orderTrackCollapse ? "rotate-180" : ""}`}
          />
        </div>
      </div>
      <Collapse in={orderTrackCollapse}>
        <div className="bg-light">
          <VerticalTimeline layout={"1-column-left"}>
            {orderHistory &&
              orderHistory.map((item, index) => {
                return (
                  <VerticalTimelineElement
                    key={index}
                    date={DateTimeFormatter(item?.created_at)}
                    dateClassName="date"
                    icon={
                      <img
                        src={HadayaLogo}
                        alt="logo not found"
                        className="img-fluid rounded-circle"
                      />
                    }
                  >
                    <span className="font-bold font-18 verticle-timeline-element-title">
                      {item.order_status === "successful" ? (
                        <span className="text-success">
                          {strFirstLetterCap(item.order_status)}
                        </span>
                      ) : item.order_status === "created" ? (
                        <span className="text-primary">
                          {strFirstLetterCap(item.order_status)}
                        </span>
                      ) : item.order_status === "cancelled" ? (
                        <span className="text-danger">
                          {strFirstLetterCap(item.order_status)}
                        </span>
                      ) : item.order_status === "error" ? (
                        <span className="text-danger">
                          {strFirstLetterCap(item.order_status)}
                        </span>
                      ) : item.order_status === "failed" ? (
                        <span className="text-danger">
                          {strFirstLetterCap(item.order_status)}
                        </span>
                      ) : item.order_status === "unable to fulfill" ? (
                        <span className="text-danger">
                          {strFirstLetterCap(item.order_status)}
                        </span>
                      ) : item.order_status === "shipped" ? (
                        <span className="text-info">
                          {strFirstLetterCap(item.order_status)}
                        </span>
                      ) : item.order_status === "delivered" ? (
                        <span className="text-success">
                          {strFirstLetterCap(item.order_status)}
                        </span>
                      ) : item.order_status === "pending" ? (
                        <span className="text-orange">
                          {strFirstLetterCap(item.order_status)}
                        </span>
                      ) : item.order_status === "in_progress" ? (
                        <span className="text-pink">
                        In progress
                        </span>
                      ) : item.order_status === "unable_to_fulfill" ? (
                        <span className="text-danger">
                         Unable to fulfill
                        </span>
                      ) :item.order_status}
                    </span>
                    <p className="text-muted font-14 verticle-timeline-element-subtitle">
                      {item.reason ? item.reason : null}
                    </p>
                    {item.order_status === "shipped" ? (
                      <div>
                        <span className="font-14 font-bold">Shipped Date:</span>
                        <br/><p className="text-muted">{DateTimeFormatter(item?.shipped_date)}</p>
                        <span className="font-14 font-bold">Tracking Id:</span>
                        <br/><p className="text-muted">{item?.tracking_id}</p>
                        <span className="font-14 font-bold">Tracking url:</span>
                        <br/><p className="text-primary cursor">{item?.tracking_url}</p>
                        <span className="font-14 font-bold">Note:</span>
                        <br/><p className="text-muted">{item?.notes}</p>
                      </div>
                    ) : null}
                  </VerticalTimelineElement>
                );
              })}
          </VerticalTimeline>
        </div>
      </Collapse>
    </div>
  );
};
export default OrderDetailTimeLine;
