/* eslint-disable array-callback-return */
import axios from "axios";
import {
  FETCH_BRAND_FAILURE,
  FETCH_BRAND_REQUEST,
  FETCH_BRAND_SUCCESS,
  GET_BRAND_FAILURE,
  GET_BRAND_REQUEST,
  GET_BRAND_SUCCESS,
  GET_BRAND_TYPE_REQUEST,
  GET_BRAND_TYPE_SUCCESS,
  GET_BRAND_TYPE_FAILURE,
  PUT_CREATE_BRAND_REQUEST,
  PUT_CREATE_BRAND_SUCCESS,
  PUT_CREATE_BRAND_FAILURE,
  PATCH_BRAND_REQUEST,
  PATCH_BRAND_SUCCESS,
  PATCH_BRAND_FAILURE,
  BRAND_FILTER_DATA,
  CLEAR_BRAND_LIST
} from "./brandType";
import { baseUrl, getToken } from "../../utils/prodURL";
import { stringConverter } from "../../utils/constantFunction";

export const fetchBrandRequest = () => {
  return {
    type: FETCH_BRAND_REQUEST,
  };
};

export const fetchBrandSuccess = (data) => {
  return {
    type: FETCH_BRAND_SUCCESS,
    payload: data,
  };
};

export const fetchBrandFailure = (error) => {
  return {
    type: FETCH_BRAND_FAILURE,
    payload: error,
  };
};
export const setBrandFilterData = (data) => {
  return {
    type: BRAND_FILTER_DATA,
    payload: data,
  };
};

export const fetchBrand = (skip, limit, brandsState) => {
  if (!brandsState) brandsState = []
  return (dispatch) => {
    dispatch(fetchBrandRequest());
    let url = skip !== undefined && limit !== undefined ? `${baseUrl}/v1/brand?skip=${skip}&limit=${limit}` : `${baseUrl}/v1/brand`
    let data = { active_flag: true };
    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        let data = {
          data: [...brandsState, ...response.data.result],
          moredata: response.data.next_iteration
          // moredata: false
        }
        if (response.data.code === 200) {
          dispatch(fetchBrandSuccess(data));
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          fetchBrandFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const getBrandRequest = () => {
  return {
    type: GET_BRAND_REQUEST,
  };
};

export const getBrandSuccess = (data) => {
  return {
    type: GET_BRAND_SUCCESS,
    payload: data,
  };
};

export const getBrandFailure = (error) => {
  return {
    type: GET_BRAND_FAILURE,
    payload: error,
  };
};

// GET BIND BRAD TYPE DD
export const getBrandTypeRequest = () => {
  return {
    type: GET_BRAND_TYPE_REQUEST,
  };
};

export const getBrandTypeSuccess = (data) => {
  return {
    type: GET_BRAND_TYPE_SUCCESS,
    payload: data,
  };
};

export const getBrandTypeFailure = (error) => {
  return {
    type: GET_BRAND_TYPE_FAILURE,
    payload: error,
  };
};

export const getBrandTypeData = () => {
  return (dispatch) => {
    dispatch(getBrandTypeRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/brand_type`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getBrandTypeSuccess(response.data));
      })
      .catch((error) => {
        // const errorMsg = error
        dispatch(
          getBrandTypeFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// PUT DATA OF ADD BRAND PAGE
export const putCreateBrandRequest = () => {
  return {
    type: PUT_CREATE_BRAND_REQUEST,
  };
};

export const putCreateBrandSuccess = (data) => {
  return {
    type: PUT_CREATE_BRAND_SUCCESS,
    payload: data,
  };
};

export const putCreateBrandFailure = (error) => {
  return {
    type: PUT_CREATE_BRAND_FAILURE,
    payload: error,
  };
};

export const putBrandData = (obj, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(putCreateBrandRequest);

    let strTags = stringConverter(obj.tags);

    let tags = {
      active: 1,
      tags: strTags,
      tags_id: "string",
      tags_category: "string",
      created_at: "2022-04-27T11:02:20.301923",
      updated_at: "2022-04-27T11:02:20.301939",
      User_access_logs: "string",
    };

    let configDescription = {
      method: "put",
      url: `${baseUrl}/tags/`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: tags,
    };

    const TagsObj = axios(configDescription);

    Promise.all([TagsObj])
      .then(([tagsObj]) => {
        if (tagsObj.status === 200) {
          let tagId = tagsObj?.data?.id;
          dispatch(putCreateBrandFinal(obj, tagId, goToSuccessfull));
        } else {
          dispatch(putCreateBrandFailure("failed"));
        }
      })
      .catch((error) => {
        //     const errorMsg = error
        dispatch(putCreateBrandFailure("failed"));
      });
  };
};

export const postBrandCategoryMapping = (boj) => {
  return (dispatch) => {
    let config = {
      method: "post",
      url: `${baseUrl}/v1/brand/brand_category/mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: boj,
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const putCreateBrandFinal = (obj, brandCategory, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(putCreateBrandRequest());

    let brandTitleArr = [];

    obj?.brandName?.map((item) => {
      brandTitleArr.push({ [item.language_id.language_code]: item.brand_name });
    });
    let object = Object.assign({}, ...brandTitleArr);

    let brandsObj = {
      brand_name: object,
      default_name: obj.brandName[0].brand_name,
      tags: obj.tags,
      active_flag: obj.activeFlag,
      featured_flag: true,
    };
    let config = {
      method: "post",
      url: `${baseUrl}/v1/brand`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: brandsObj,
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          const brandId = response.data.result.id;

          let arrIds = [];
          brandCategory.map((item) => {
            arrIds.push(item.id);
          });

          let brandCatObj = {
            brand_id: brandId,
            brand_category_ids: arrIds,
            active_flag: true,
          };

          dispatch(postBrandCategoryMapping(brandCatObj));

          dispatch(putCreateBrandSuccess(response.data));
          goToSuccessfull();
          dispatch(fetchBrand());
        }
      })
      .catch((error) => {
        // const errorMsg = error
        dispatch(
          putCreateBrandFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// Get Brand Category Map
export const patchGetBrandCategory = (id, getBrandCatMap) => {
  return (dispatch) => {
    let config = {
      method: "get",
      url: `${baseUrl}/v1/brand/${id}/brand_category`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          getBrandCatMap(response.data.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const patchBrandRequest = () => {
  return {
    type: PATCH_BRAND_REQUEST,
  };
};

export const patchBrandSuccess = (obj) => {
  return {
    type: PATCH_BRAND_SUCCESS,
    payload: obj,
  };
};

export const patchBrandFailure = (error) => {
  return {
    type: PATCH_BRAND_FAILURE,
    payload: error,
  };
};

export const patchPostBrand = (obj, brandCategory, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(patchBrandRequest());
    let brandTitleArr = [];

    obj?.brandName?.map((item) => {
      brandTitleArr.push({ [item.language_id.language_code]: item.brand_name });
    });
    let object = Object.assign({}, ...brandTitleArr);

    let brandsObj = {
      brand_name: object,
      default_name: obj.brandName[0].brand_name,
      tags: obj.tags,
      active_flag: obj.activeFlag,
      featured_flag: true,
    };

    let config = {
      method: "patch",
      url: `${baseUrl}/v1/brand/${obj.id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: brandsObj,
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          const brandId = response.data.result.id;

          let arrIds = [];
          brandCategory.map((item) => {
            arrIds.push(item.id);
          });

          let brandCatObj = {
            brand_id: brandId,
            brand_category_ids: arrIds,
            active_flag: true,
          };

          dispatch(postBrandCategoryMapping(brandCatObj));

          dispatch(patchBrandSuccess(response.data));
          goToSuccessfull();
          dispatch(fetchBrand());
        }
      })
      .catch((error) => {
        // const errorMsg = error
        dispatch(
          patchBrandFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const clearBrandList = () => {
  return {
    type: CLEAR_BRAND_LIST,
  };
};