import {
  GET_PRODUCT_CATEGORY_REQUEST,
  GET_PRODUCT_CATEGORY_SUCCESS,
  GET_PRODUCT_CATEGORY_FAILURE,
} from "./prodCatType";
import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";

export const getProdCategoryRequest = () => {
  return {
    type: GET_PRODUCT_CATEGORY_REQUEST,
  };
};
export const getProdCategorySuccess = (prodcategory) => {
  return {
    type: GET_PRODUCT_CATEGORY_SUCCESS,
    payload: prodcategory,
  };
};
export const getProdCategoryFailure = (error) => {
  return {
    type: GET_PRODUCT_CATEGORY_FAILURE,
    payload: error,
  };
};

export const getProdCategory = () => {
  return (dispatch) => {
    dispatch(getProdCategoryRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/product_category`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        //console.log("response" + JSON.stringify(response.data));
         dispatch(getProdCategorySuccess(response.data))
      })
      .catch((error) => {
        const errorMsg = error;
        dispatch(
          getProdCategoryFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};
