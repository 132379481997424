import { SIDENAV_TOGGLE } from "./sideNavToggleType";

const initialState = {
  sideNavToggle: false,
};

function sideNavToggleReducer(state = initialState, action) {
  switch (action.type) {
    case SIDENAV_TOGGLE:
      return {
        sideNavToggle: !state.sideNavToggle,
      };
    default:
      return state;
  }
}

export default sideNavToggleReducer
