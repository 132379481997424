import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllLanguage, patchLanguage } from "../../Redux/Language/languageAction";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import InputField from "../InputField/InputField";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import ReactSwitchButton from "../Button/ReactSwitchButton";
import { useTranslation } from "react-i18next";

const EditLanguage = () => {
  const history = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const inputElementlangCode = useRef(null);
  const inputElementlangName = useRef(null);

  const dispatch = useDispatch();

  let defaultLang = localStorage.getItem("defaultLang");

  const [LanguageState, setLanguageState] = useState({
    languageCode: "",
    languageName: "",
  });

  const [activeFlag, setActiveFlag] = useState(true);

  const activeHandle = () => {
    setActiveFlag(!activeFlag);
  };

  const [error, setError] = useState({
    languageCode: "",
    languageName: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    location?.state?.languageItem && setLanguageState({
      ...LanguageState,
      languageCode: location?.state?.languageItem?.language_code,
      languageName: location?.state?.languageItem?.language,
    });
  }, [location?.state?.languageItem]);

  useEffect(() => {
    location?.state?.languageItem?.active_flag && setActiveFlag(location?.state?.languageItem?.active_flag)
  },[location?.state?.languageItem])

  const languageHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "languageCode") {
      setError({ ...error, languageCode: "" });
      setLanguageState({ ...LanguageState, [name]: value.toUpperCase() });
    } else if (name === "languageName") {
      setError({ ...error, languageName: "" });
      const firstLetter = value.charAt(0)
      const firstLetterCap = firstLetter.toUpperCase()
      const remainingLetters = value.slice(1)
      const capitalizedWord = firstLetterCap + remainingLetters
      setLanguageState({ ...LanguageState, [name]: capitalizedWord });
    }
  };

  const validation = () => {
    if (!LanguageState?.languageCode) {
      setError({ ...error, languageCode: t("Please add language code") });
      if(inputElementlangCode.current){
        inputElementlangCode.current.focus()
      }
      return false;
    } else if (!LanguageState?.languageName) {
      setError({ ...error, languageName: t("Please add language name") });
      if(inputElementlangName.current){
        inputElementlangName.current.focus()
      }
      return false;
    }
    return true;
  };

  const languageSuccessfull = () => {
    history('/language-patch-success')
  }

  const submit = () => {
    let val = validation();
    if (val) {
      const obj = {
        "language_code": LanguageState?.languageCode,
        "language": LanguageState?.languageName,
        "active_flag": activeFlag,
        "created_at": location?.state?.languageItem && location?.state?.languageItem?.created_at,
        "updated_at": location?.state?.languageItem && location?.state?.languageItem?.updated_at,
      }
      dispatch(patchLanguage(obj, languageSuccessfull))
    } else {

    }
  };

  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Edit Language")}</span>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between gap-2">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-20 font-bold">{t("Language")}</span>
            </div>
          </div>
          <div className="d-flex flex-column gap-4 mt-4">
            <div className="d-flex flex-column gap-1">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Language Code")}<span className="text-danger">*</span></span>
                <InfoTooltip title={"Enter Language Code"} />
              </div>
              <InputField
              inputRef={inputElementlangCode}
                onChange={languageHandler}
                name="languageCode"
                placeholder={t("Enter language code")}
                type="text"
                value={LanguageState?.languageCode}
              />
              {error.languageCode ? (
                <label className="text-error">{error.languageCode}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-1">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Language Name")}<span className="text-danger">*</span></span>
                <InfoTooltip title={"Enter Language Name"} />
              </div>
              <InputField
              inputRef={inputElementlangName}
                onChange={languageHandler}
                name="languageName"
                placeholder={t("Enter language name")}
                type="text"
                value={LanguageState?.languageName}
              />
              {error.languageName ? (
                <label className="text-error">{error.languageName}</label>
              ) : null}
            </div>

            <div className="d-flex flex-row justify-content-between mb-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Active?")}</span>
                <InfoTooltip title={"Active/Inactive"} />
              </div>
              <ReactSwitchButton
                onChange={() => activeHandle()}
                value={activeFlag}
              />
            </div>

            <div className="d-flex">
              <Button onClick={() => submit()} text={t("Update")} type="primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLanguage;
