/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Languages from "./Languages";
import { useTranslation } from "react-i18next";

const LanguageDashboard = (props) => {
  const history = useNavigate();
  const { t } = useTranslation();

  const [permissions, setpermissions] = useState();

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
  }, [permissions]);

  const addLanguageHandler = () => {
    history("/add-language");
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">{t("Language Dashboard")}</span>
        </div>
        {permissions?.brand?.create ? (
          <div>
            <Button
              type="primary"
              text={t("+Add Language")}
              onClick={() => addLanguageHandler()}
            />
          </div>
        ) : null}
      </div>
      <div className="d-flex ">
        <Languages lang={props?.lang} />
      </div>
    </div>
  );
};

export default LanguageDashboard;