import {
  FETCH_ALL_ORGANIZATION_REQUEST,
  FETCH_ALL_ORGANIZATION_SUCCESS,
  FETCH_ALL_ORGANIZATION_FAILURE,
  POST_ORGANIZATION_RESPONSE,
  PATCH_ORGANIZATION_REQUEST,
  PATCH_ORGANIZATION_SUCCESS,
  PATCH_ORGANIZATION_FAILURE,
  POST_ALL_ORGANIZATION_REQUEST,
  POST_ALL_ORGANIZATION_SUCCESS,
  POST_ALL_ORGANIZATION_FAILURE,
  SET_SELECTED_ORGANIZATION,
  POST_ORGANIZATION_MAPPING_FRESPONSE,
  POST_ORGANIZATION_MER_MAPPING_REQUEST,
  POST_ORGANIZATION_MER_MAPPING_SUCCESS,
  POST_ORGANIZATION_MER_MAPPING_FAILURE,
  GET_ALL_ORGANIZATION_REQUEST,
  GET_ALL_ORGANIZATION_SUCCESS,
  GET_ALL_ORGANIZATION_FAILURE,
  DELETE_ORGANIZARION_LIST,
  GET_ORGANIZARION_MAPPED_GIFTCARD_FAILURE,
  GET_ORGANIZARION_MAPPED_GIFTCARD_SUCCESS,
  GET_ORGANIZARION_MAPPED_GIFTCARD_REQUEST,
  GET_ORGANIZARION_MAPPED_GIFTCARD_DELETE,
  ORGANIZARION_FILTER_DATA,
  CLEAR_ORGANIZATION_LIST,
} from "../../Redux/Organizations/organizationType";

const initialOrganizationState = {
  loading: false,
  organizations: [],
  organizationsResponse: null,
  selectedOrganization: null,
  organizationList: [],
  error: "",
  next_iteration: true,
  organizationMappedProdList: [],
  organizationFilter: [],
};

const organizationReducer = (state = initialOrganizationState, action) => {
  switch (action.type) {
    case ORGANIZARION_FILTER_DATA:
      return {
        ...state,
        organizationFilter: action.payload,
      };
    case SET_SELECTED_ORGANIZATION:
      return {
        ...state,
        loading: false,
        error: "",
        selectedOrganization: action.payload,
      };
    case POST_ORGANIZATION_MAPPING_FRESPONSE:
      return {
        ...state,
        loading: false,
        error: "",
        organizationsResponse: action.payload,
      };
    case CLEAR_ORGANIZATION_LIST:
      return {
        ...state,
        loading: false,
        organizations: [],
        next_iteration: true,
        error: "",
      };
    case FETCH_ALL_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_ALL_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case FETCH_ALL_ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: false,
        organizations: [],
        error: action.payload,
      };
    // Post Organization
    case POST_ALL_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        organizationsResponse: null,
      };
    case POST_ALL_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationsResponse: action.payload,
        error: "",
      };
    case POST_ALL_ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: false,
        organizationsResponse: null,
        error: action.payload,
      };

    case POST_ORGANIZATION_RESPONSE:
      return {
        ...state,
        loading: false,
        organizations: [],
        organizationsResponse: action.payload,
        error: "",
      };
    // patch organization
    case PATCH_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        organizations: [],
        organizationsResponse: null,
        error: "",
      };
    case PATCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: [],
        organizationsResponse: action.payload,
        error: "",
      };
    case PATCH_ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: false,
        organizations: [],
        error: action.payload,
        organizationsResponse: null,
      };
    case POST_ORGANIZATION_MER_MAPPING_REQUEST:
      return {
        loading: true,
      };
    case POST_ORGANIZATION_MER_MAPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: [],
        organizationsResponse: action.payload,
        error: "",
      };
    case POST_ORGANIZATION_MER_MAPPING_FAILURE:
      return {
        ...state,
        loading: false,
        organizations: [],
        error: action.payload,
        organizationsResponse: null,
      };
    // get organization detail or list as partner from partner category
    case GET_ALL_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case GET_ALL_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationList: action.payload,
        error: "",
      };
    case GET_ALL_ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: false,
        organizationList: [],
        error: action.payload,
      };
    case DELETE_ORGANIZARION_LIST:
      return {
        ...state,
        organizationList: action.payload,
      };
    //    giftcard mappde list
    case GET_ORGANIZARION_MAPPED_GIFTCARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case GET_ORGANIZARION_MAPPED_GIFTCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationMappedProdList: action.payload.data,
        next_iteration: action.payload.moredata,
        error: "",
      };
    case GET_ORGANIZARION_MAPPED_GIFTCARD_FAILURE:
      return {
        ...state,
        loading: false,
        organizationMappedProdList: null,
        error: action.payload,
      };
    case GET_ORGANIZARION_MAPPED_GIFTCARD_DELETE:
      return {
        ...state,
        loading: false,
        organizationMappedProdList: [],
        next_iteration: action.payload.moredata,
        error: "",
      };
    default:
      return state;
  }
};
export default organizationReducer;
