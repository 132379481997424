import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as BlueTag } from "../../assests/svg/Tag/blue_tag.svg";
import InputGroupTxt from "../InputField/InputGroupTxt";
import Button from "../Button/Button";
import InfoTooltip from "../Tooltip/InfoTooltip";
import BackButton from "../Button/BackButton";
import TagsInputForNumber from "../TagsInput/TagsInputForNumber";
import DragandDrop from "../DragandDrop/DragandDrop";
import { useDispatch, useSelector } from "react-redux";
import { setPrice } from "../../Redux/GiftCard/giftcardAction";
import ReactSwitchButton from "../../components/Button/ReactSwitchButton";
import { useTranslation } from "react-i18next";

const ProductPricing = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  const inputElementDenomi = useRef(null);
  const inputElementRangMin = useRef(null);
  const inputElementRangMax = useRef(null);

  const priceState = useSelector((state) => state.giftcard?.cardPrice);
  const catAttributeState = useSelector(
    (state) => state.giftcard?.cardCatAttribute
  );
  const [fixeddenomnation, setDenomnation] = useState(
    priceState?.fixeddenomnation ? priceState?.fixeddenomnation : []
  );
  const [cardImg, setCardImg] = useState(
    priceState?.giftCardImg ? priceState?.giftCardImg : []
  );
  const [reloadableGiftCardFlag, setReloadableGiftCardFlag] = useState(
    priceState?.reloadableGiftCardFlag
      ? priceState?.reloadableGiftCardFlag
      : false
  );
  const [denomnationFlag, setDenomnationFlag] = useState(
    priceState?.denomnationFlag ? priceState?.denomnationFlag : false
  );
  const [rangedenomnationFlag, setRangeDenomnationFlag] = useState(
    priceState?.rangedenomnationFlag ? priceState?.rangedenomnationFlag : false
  );

  const [priceObj, setPriceObj] = useState({
    minamount: priceState?.minamount ? priceState?.minamount : "",
    maxamount: priceState?.maxamount ? priceState?.maxamount : "",
    cardImg: cardImg,
  });

  const [error, setError] = useState({
    switch: "",
    minamount: "",
    maxamount: "",
    fixeddenomnation: "",
    rangedenomnation: "",
    cardImg: "",
  });

  const handleInputChange = (e) => {
    const target = e.target;
    var value = target.value;
    const name = target.name;
    setPriceObj({
      ...priceObj,
      [name]: value,
    });
  };

  const validate = () => {
    if (!denomnationFlag && !rangedenomnationFlag) {
      setError({ switch: t("Select the above option") });
      return false;
    } else if (denomnationFlag && !fixeddenomnation.length > 0) {
      setError({ fixeddenomnation: t("Please enter denominations") });
      if(inputElementDenomi.current){
        inputElementDenomi.current.focus()
      }
      return false;
    } else if (rangedenomnationFlag && !priceObj.minamount) {
      setError({ minamount: t("Please enter minimum amount") });
      if(inputElementRangMin.current){
        inputElementRangMin.current.focus()
      }
      return false;
    } else if (rangedenomnationFlag && !priceObj.maxamount) {
      setError({ maxamount: t("Please enter maximum amount") });
      if(inputElementRangMax.current){
        inputElementRangMax.current.focus()
      }
      return false;
    } else if (!cardImg.length > 0) {
      setError({ cardImg: t("Please upload image") });
      return false;
    }

    return true;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!catAttributeState) {
      history("/product-creation/name-description");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catAttributeState]);

  const submit = () => {
    let obj = {
      minamount: priceObj.minamount ? priceObj.minamount : "",
      maxamount: priceObj.maxamount ? priceObj.maxamount : "",
      reloadableGiftCardFlag: reloadableGiftCardFlag,
      denomnationFlag: denomnationFlag,
      rangedenomnationFlag: rangedenomnationFlag,
      fixeddenomnation: fixeddenomnation,
      giftCardImg: cardImg,
      giftcard_value_type: rangedenomnationFlag
        ? "range"
        : fixeddenomnation
        ? "denominations"
        : "denominations",
      // denominations, range, fixed
    };

    let val = validate();
    if (val) {
      dispatch(setPrice(obj));
      history("/product-creation/other-files");
    }
  };

  const fixeddenomnationHandle = () => {
    setDenomnationFlag(!denomnationFlag);
    setRangeDenomnationFlag(false);
  };

  const rangedenomnationHandle = () => {
    setRangeDenomnationFlag(!rangedenomnationFlag);
    setDenomnationFlag(false);
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">{t("Price")}</span>
          </div>
          <div>
            <BackButton onClick={() => history(-1)} />
          </div>
        </div>
        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Fixed denomination")}
                </span>
                <InfoTooltip
                  title={"Enable switch to enter Fixed denomination"}
                />
              </div>
              {/*  <SwitchButton
                onChange={() =>fixeddenomnationHandle() }
                value={denomnationFlag}
                disabled={reloadableGiftCardFlag}
              />*/}
              <ReactSwitchButton
                onChange={() => fixeddenomnationHandle()}
                value={denomnationFlag}
                //disabled={reloadableGiftCardFlag}
              />
            </div>
            {denomnationFlag ? (
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Denomination")}
                      <span className="text-danger">*</span>
                    </span>
                    <InfoTooltip title={"Enter fixed denomination"} />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                      <TagsInputForNumber
                        inputRef={inputElementDenomi}
                        tags={fixeddenomnation}
                        setTags={setDenomnation}
                        placeholder={t("Enter fixed denomination")}
                      />
                      {!fixeddenomnation.length > 0 &&
                      error.fixeddenomnation ? (
                        <label className="text-error">
                          {error.fixeddenomnation}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {catAttributeState?.giftcardtype?.type_name !== "Manual Inventory" ? (
            <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Range denomination")}
                </span>
                <InfoTooltip
                  title={"Enable switch to enter Range denomination"}
                />
              </div>
              {/**  <SwitchButton
                onChange={() =>rangedenomnationHandle()}
                value={rangedenomnationFlag}
                disabled={reloadableGiftCardFlag}
                      />*/}
              <ReactSwitchButton
                onChange={() => rangedenomnationHandle()}
                value={rangedenomnationFlag}
              />
            </div>
          ) : null}
          {rangedenomnationFlag ? (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100">
                <div className="d-flex flex-row justify-content-between gap-2">
                  <div className="col d-flex flex-column gap-2">
                    <span className="fs-lighter font-14 font-bold">
                      {t("Minimum amount")}
                      <span className="text-danger">*</span>
                    </span>
                    <div className=" d-flex flex-column gap-1">
                      <InputGroupTxt
                        inputRef={inputElementRangMin}
                        title={
                          catAttributeState?.countryCurrency?.currency_symbol
                        }
                        placeholder={t("Enter amount")}
                        type="number"
                        name="minamount"
                        lang={props?.lang}
                        value={priceObj.minamount}
                        onChange={handleInputChange}
                      />
                      {!priceObj.minamount && error.minamount ? (
                        <label className="text-error">{error.minamount}</label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col d-flex flex-column gap-2">
                    <span className="fs-lighter font-14 font-bold">
                      {t("Max amount")}
                      <span className="text-danger">*</span>
                    </span>
                    <div className="d-flex flex-column gap-1">
                      <InputGroupTxt
                      inputRef={inputElementRangMax}
                        title={
                          catAttributeState?.countryCurrency?.currency_symbol
                        }
                        placeholder={t("Enter amount")}
                        type="number"
                        name="maxamount"
                        lang={props?.lang}
                        value={priceObj.maxamount}
                        onChange={handleInputChange}
                      />
                      {!priceObj.maxamount && error.maxamount ? (
                        <label className="text-error">{error.maxamount}</label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {catAttributeState?.giftcardtype?.type_name !== "Manual Inventory" ? (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row justify-content-between  align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t(
                      "Reloadable giftcard (In this case, customer can recharge the card)"
                    )}
                  </span>
                  <InfoTooltip title={"Reloadable giftcard"} />
                </div>

                <ReactSwitchButton
                  onChange={() =>
                    setReloadableGiftCardFlag(!reloadableGiftCardFlag)
                  }
                  value={reloadableGiftCardFlag}
                  disabled={!denomnationFlag && !rangedenomnationFlag}
                />
              </div>
            </div>
          ) : null}
          {!denomnationFlag && !rangedenomnationFlag ? (
            <label className="text-error">{error.switch}</label>
          ) : null}
        </div>
      </div>

      {/* Card Image */}
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mt-2 mb-5">
        <div className="d-flex gap-3">
          <BlueTag />
          <span className="font-20 font-bold">{t("Card Image")}</span>
        </div>

        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Card images")}
                <span className="text-danger">*</span>
                <span> {t("(size should be 600*200)")}</span>
              </span>
              <InfoTooltip title={"Upload card image"} />
            </div>
            <div className="d-flex flex-column">
              <DragandDrop files={cardImg} setFiles={setCardImg} />
              {!cardImg.length > 0 && error.cardImg ? (
                <label className="text-error">{error.cardImg}</label>
              ) : null}
            </div>
          </div>
        </div>

        <div className="d-flex my-2 mt-4">
          <Button
            onClick={() => submit()}
            text={t("Save & Next")}
            type="primary"
          />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ProductPricing;
