import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";
import {
  FETCH_ALL_COUNTRY_REQUEST,
  FETCH_ALL_COUNTRY_SUCCESS,
  FETCH_ALL_COUNTRY_FAILURE,
  FETCH_COUNTRY_DETAILS_SUCCESS,
  FETCH_COUNTRY_DETAILS_REQUEST,
  FETCH_COUNTRY_DETAILS_FAILURE,
  FETCH_COUNTRY_DETAILS_ID_REQUEST,
  FETCH_COUNTRY_DETAILS_ID_SUCCESS,
  FETCH_COUNTRY_DETAILS_ID_FAILURE,
} from "./countryType";

export const fetchAllCountriesRequest = () => {
  return {
    type: FETCH_ALL_COUNTRY_REQUEST,
  };
};

export const fetchAllCountriesSuccess = (countries) => {
  return {
    type: FETCH_ALL_COUNTRY_SUCCESS,
    payload: countries,
  };
};

export const fetchAllCountriesFailure = (error) => {
  return {
    type: FETCH_ALL_COUNTRY_FAILURE,
    payload: error,
  };
};

export const getAllCountriesList = () => {
  return (dispatch) => {
    dispatch(fetchAllCountriesRequest);
    let config = {
      method: "get",
      url: `${baseUrl}/v1/attributes/country`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
         if (response.status === 200) {
          dispatch(fetchAllCountriesSuccess(response.data.result))
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchAllCountriesFailure(errorMsg));
      });
  };
};

export const fetchAllCountrieDetailsRequest = () => {
  return {
    type: FETCH_COUNTRY_DETAILS_REQUEST,
  };
};

export const fetchAllCountrieDetailsSuccess = (countries) => {
  return {
    type: FETCH_COUNTRY_DETAILS_SUCCESS,
    payload: countries,
  };
};

export const fetchAllCountrieDetailsFailure = (error) => {
  return {
    type: FETCH_COUNTRY_DETAILS_FAILURE,
    payload: error,
  };
};

export const fetchAllCountrieDetails = (id) => {

  return (dispatch) => {
    dispatch(fetchAllCountrieDetailsRequest);
    let config = {
      method: 'get',
      url: `${baseUrl}/v1/attributes/country/${id}`,
      headers: {
        'Authorization': `Bearer ${getToken()}`
      }
    };
    axios(config)
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchAllCountrieDetailsSuccess(response.data.result))
        }
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(fetchAllCountrieDetailsFailure(errorMsg))
      })

  }
}

export const fetchCountrieDetailsByIdsRequest = () => {
  return {
    type: FETCH_COUNTRY_DETAILS_ID_REQUEST,
  };
};

export const fetchCountrieDetailsByIdsSuccess = (countries) => {
  return {
    type: FETCH_COUNTRY_DETAILS_ID_SUCCESS,
    payload: countries,
  };
};

export const fetchCountrieDetailsByIdsFailure = (error) => {
  return {
    type: FETCH_COUNTRY_DETAILS_ID_FAILURE,
    payload: error,
  };
};

export const fetchCountrieDetailsByIds = (data) => {

  return (dispatch) => {
    dispatch(fetchCountrieDetailsByIdsRequest);

    let ids = [];
    data && data.map((item) => {
      let obj = `ids=${item}&`
      ids.push(obj)
    });

    let config = {
      method: 'get',
      url: `${baseUrl}/v1/attributes/country?${ids}`,
      headers: {
        'Authorization': `Bearer ${getToken()}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchCountrieDetailsByIdsSuccess(response.data.result));
        }
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(fetchCountrieDetailsByIdsFailure(errorMsg))
      })

  }
}


