/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import Button from "../Button/Button";
import Toasts from "../Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import { getClientAllOrders } from "../../Redux/Order/orderAction";
import {
  DataSortingHandler,
  momentdateTimeFormater,
} from "../../utils/constantFunction";
import { strFirstLetterCap } from "../../utils/constantFunction";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import PulseLoader from "../Loader/PulseLoader";
import PulseLoaders from "react-spinners/PulseLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";
import { SORT_TYPE } from "../../utils/ConstantData";

const OrganizationTransaction = (props) => {
  const { t } = useTranslation();
  const { selectedClient } = props;

  const clientAllOrdersList = useSelector(
    (state) => state?.allOrderList?.clientAllOrderList
  );
  const loading = useSelector((state) => state?.allOrderList?.loading);
  const hasmore = useSelector((state) => state?.allOrderList?.next_iteration);

  // set infinite loader
  const [skip, setskip] = useState(
    clientAllOrdersList?.length === 0 ? -10 : clientAllOrdersList?.length - 10
  );
  const [limit, setlimit] = useState(10);

  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [dropdownObj, setDropdownObj] = useState({
    orders: "",
  });

  const handleGetOrderByType = (item, type) => {
    setDropdownObj({ ...dropdownObj, [type]: item });
  };

  const toggleShowToast = () => setShowToast(!showToast);

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {}, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (clientAllOrdersList?.length === 0) {
      fetchdata(skip);
    }
  }, []);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(
        getClientAllOrders(
          selectedClient?.id,
          selectedClient?.partner_category,
          skip + limit,
          limit,
          clientAllOrdersList
        )
      );
      setskip(skip + limit);
    }, 100);
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   selectedClient &&
  //     dispatch(getClientAllOrders(selectedClient?.id, selectedClient?.partner_category));
  // }, [selectedClient]);

  return loading === true && clientAllOrdersList?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="d-flex flex-row w-100  align-items-center">
          <div className="p-2 flex-grow-1">
            <div className="d-flex gap-3 my-2 mx-3 align-items-center">
              <Tag />
              <span className="font-20 font-bold">{t("Orders")}</span>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search by name")}
                onChange={searchHandler}
              />
            </div>
          </div>
          {/*   <div className="d-flex flex-column gap-2 mx-2 ">
                <div className="btn-group ">
                  <div
                    className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center"
                    data-bs-toggle="dropdown"
                  >
                    <span className="text-muted">
                      {dropdownObj.orders
                        ? dropdownObj.orders.name
                        : "Filter orders"}
                    </span>
                  </div>
                  <ul className="dropdown-menu rounded-card-7 dropdown-menu-start">
                    {OrderFilter &&
                      OrderFilter.map((item, i) => (
                        <li
                          key={i}
                          onClick={() => handleGetOrderByType(item, "orders")}
                        >
                          <div className="dropdown-item cursor ">
                            {item.name}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>*/}

          {/* <div className="btn-group cursor ">
                <div
                 className="btn border border-2 btn-lg rounded-card-7 dropdown-toggle font-14 font-bold text-muted"
                  data-bs-toggle="dropdown"
                >
                  Last 30 days
                </div>
                <ul className="dropdown-menu rounded-card-7 dropdown-menu-end">
                  <li>
                    <div className="dropdown-item">Last 10 days</div>
                  </li>
                  <li>
                    <div className="dropdown-item">Last 15 days</div>
                  </li>
                  <li>
                    <div className="dropdown-item">Last 30 days</div>
                  </li>
                </ul>
              </div> */}
          {/* <div className="mx-4">
                <Button
                  type="primary"
                  text="Download CSV"
                  className="rounded-6 my-3 mt-6 fw-bolder"
                  onClick={() => toggleShowToast()}
                />
              </div> */}
        </div>
        <InfiniteScroll
          dataLength={clientAllOrdersList?.length || 0}
          next={fetchdata}
          hasMore={hasmore}
          loader={
            <div className="d-flex justify-content-center">
              {" "}
              <PulseLoaders
                color={"#594190"}
                loading={loading}
                size={10}
              />{" "}
            </div>
          }
        >
          <div className="row px-5 py-3">
            <table responsive="xl">
              <tbody>
                <tr className="card-body">
                  <td
                    className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "created_at"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "created_at",
                        setTableHeaderName,
                        clientAllOrdersList,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Order date")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "id"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "id",
                        setTableHeaderName,
                        clientAllOrdersList,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Order id")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "default_title"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "default_title",
                        setTableHeaderName,
                        clientAllOrdersList,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Product Name")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "quantity"
                        ? "ascending"
                        : "descending"
                    }`} onClick={() =>
                      sortingHandler(
                        "quantity",
                        setTableHeaderName,
                        clientAllOrdersList,
                        order,
                        setOrder
                      )
                    }>
                    {t("Quantity")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "total_price"
                        ? "ascending"
                        : "descending"
                    }`} onClick={() =>
                      sortingHandler(
                        "total_price",
                        setTableHeaderName,
                        clientAllOrdersList,
                        order,
                        setOrder
                      )
                    }>
                    {t("Amount")} <br />
                    {t("(Before discount)")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "product_value"
                        ? "ascending"
                        : "descending"
                    }`} onClick={() =>
                      sortingHandler(
                        "product_value",
                        setTableHeaderName,
                        clientAllOrdersList,
                        order,
                        setOrder
                      )
                    }>
                    {t("Amount")}
                    <br />
                    {t("(After discount)")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "order_status"
                        ? "ascending"
                        : "descending"
                    }`} onClick={() =>
                      sortingHandler(
                        "order_status",
                        setTableHeaderName,
                        clientAllOrdersList,
                        order,
                        setOrder
                      )
                    }>
                    {t("Order status")}
                  </td>
                </tr>

                {clientAllOrdersList &&
                  clientAllOrdersList
                    ?.filter((item) => {
                      if (searchTerm === "") {
                        return item;
                      } else if (
                        item?.product_type === "merchandise"
                          ? item?.merchandise?.default_title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          : item?.giftcard?.default_title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <tr className="card-body border-bottom" key={index}>
                        <td className="card-body gap-1">
                          <span
                            className="font-15 font-bold text-dark d-inline-block text-truncate cursor"
                            //  title={DateTimeFormatter(item?.created_at)}
                            title={momentdateTimeFormater(item?.created_at)}
                            style={{ maxWidth: "80px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {/*{DateTimeFormatter(item?.created_at)}*/}
                            {momentdateTimeFormater(item?.created_at)}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          <span
                            className="font-15 font-bold text-dark d-inline-block text-truncate cursor"
                            title={item?.id}
                            style={{ maxWidth: "70px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {item?.id}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          <span
                            className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                            title={
                              item?.product_type === "merchandise"
                                ? item?.merchandise?.default_title
                                : item?.giftcard?.default_title
                            }
                            style={{ maxWidth: "80px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {strFirstLetterCap(
                              item?.product_type === "merchandise"
                                ? item?.merchandise?.default_title
                                : item?.giftcard?.default_title
                            )}
                          </span>
                          <br />
                          <div className="d-flex flex-column">
                            <span className="font-12 font-bolder text-dark">
                              {strFirstLetterCap(item?.product_type)}
                            </span>
                            <span className="font-12 font-bolder text-muted">
                              {item?.product_type === "giftcard" &&
                                item?.giftcard &&
                                item?.product_variant_id &&
                                item?.giftcard?.denomination &&
                                item?.giftcard?.denomination?.currency +
                                  "-" +
                                  item?.giftcard?.denomination
                                    ?.denomination_value}
                              {item?.product_type === "merchandise" &&
                                item?.merchandise &&
                                item?.product_variant_id &&
                                item?.merchandise?.product_variants_flag &&
                                item?.merchandise?.variant &&
                                item?.merchandise?.variant?.variant_values &&
                                item?.merchandise?.variant?.variant_values.join(
                                  "|"
                                )}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <span className="font-15 font-bold text-dark ">
                            {item?.quantity}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          <span className="font-15 font-bold text-dark text-light-orange">
                            {item?.total_price}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          <span className="font-15 font-bold text-dark text-light-orange">
                            {item?.order_price}
                          </span>
                        </td>

                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-between  gap-3">
                            <span className="font-15 font-bold text-dark">
                              {strFirstLetterCap(item?.order_status)}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            {clientAllOrdersList && !clientAllOrdersList?.length && (
              <div className="d-flex flex-row justify-content-center py-4">
                <span className="font-20 font-bold text-error">
                  {t("Orders Not Found")}
                </span>
              </div>
            )}
          </div>
        </InfiniteScroll>
        {/* <div className="d-flex flex-row  justify-content-center align-item-center gap-3">
              <div className="d-flex">
                {clientAllOrdersList && visible < clientAllOrdersList.length && (
                  <Button
                    type="rounded"
                    text="Load more"
                    className="rounded-6 my-4 mt-6 "
                    onClick={() => loadMore()}
                  />
                )}
              </div>
            </div> */}
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 mb-2"
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default OrganizationTransaction;
