import React from "react";
import PulseLoaders from "react-spinners/PulseLoader";
import HadayaLogo from "../../assests/svg/Group10.svg";

const PulseLoader = (props) => {
  const { loading, type } = props;
  return type === "data" ? (
    <div className="d-flex flex-column Pulse-loader-center justify-content-center align-items-center ">
      <div>
        <img
          src={HadayaLogo}
          alt="eHadaya logo not found"
          className="img-fluid rounded-circle "
          width="200px"
          height="200px"
        />
      </div>
      <div className="position-absolute top-50 start-30">
        <PulseLoaders color={"#594190"} loading={loading} size={10} />
      </div>
    </div>
  ) : (
    <div className="d-flex Pulse-loader-center justify-content-center align-items-center">
      <div>
        <img
          src={HadayaLogo}
          alt="eHadaya logo not found"
          className="img-fluid rounded-circle "
          width="200px"
          height="200px"
        />
      </div>
      <div className="position-absolute top-50 start-30">
        <PulseLoaders color={"#594190"} loading={loading} size={10} />
      </div>
    </div>
  );
};

export default PulseLoader;
