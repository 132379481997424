import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import MerchandiseTermCondition from "../../components/Merchandise/MerchandiseTermCondition";
import { useTranslation } from "react-i18next";

const AddMerchantTermCondition = (props) => {
  const history = useNavigate();
  const { t } = useTranslation();

  const goBack = () => {
    history("/merchandise-dashboard");
  };


  return (
    <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center py-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2 m">{t("Add merchandise")}</span>
        </div>
      </div>
      <div className="width-65">
        <MerchandiseTermCondition lang={props?.lang} />

      </div>
    </div>
  );
};

export default AddMerchantTermCondition;
