/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import Toasts from "../Toasts/Toasts";
import Filter from "../Filter/Filter";
import { PRODUCT_STATUS_FILTERS, SORT_TYPE } from "../../utils/ConstantData";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import { useDispatch, useSelector } from "react-redux";
import { getStoreListMapToOrganization } from "../../Redux/CreateStore/createStoreAction";
import {
  DataSortingHandler,
  momentdateFormater,
} from "../../utils/constantFunction";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";

const StoreList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedOrganization } = props;

  const storeListByOrgnazationId = useSelector(
    (state) => state.createStore?.storeListByOrgnazationId
  );

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {}, [order]);

  useEffect(() => {
    dispatch(getStoreListMapToOrganization(selectedOrganization?.id));
  }, [selectedOrganization]);

  // const loadMore = () => {
  //   setVisible((prevVisible) => prevVisible + 10);
  // };

  // const goTonextStep = (item) => {
  //   history("/organization-details", {
  //     state: {
  //       selectedOrganization: item
  //     },
  //   });
  // };

  return (
    <div className="d-flex flex-column gap-3 mt-3">
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-2 w-100">
        <div className="d-flex flex-column  bg-white">
          <div className="card-body d-flex  flex-row justify-content-between align-item-center">
            <div className="d-flex flex-row justify-content-start align-items-center gap-2">
              <div className="p-2 flex-grow-1">
                <div className="d-flex gap-3 my-2 mx-3 align-items-center">
                  <Tag />
                  <span className="font-20 font-bold">{t("All Stores")}</span>
                </div>
              </div>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search by name")}
                onChange={searchHandler}
              />
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center gap-2">
              {/* <Button
                type="primary"
                text="Download CSV"
                 className="rounded-6 my-4 mt-6 fw-bolder"
                 onClick={() => toggleShowToast()}
              /> */}
              <div>
                <Filter data={PRODUCT_STATUS_FILTERS} lang={props?.lang} />
              </div>
            </div>
          </div>

          <div className="row px-5 py-2">
            <table responsive="xl">
              <tbody>
                <tr className="card-body gap-3 border-bottom py-5">
                  <td
                    className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "store_name"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "store_name",
                        setTableHeaderName,
                        storeListByOrgnazationId,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Name")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "id"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "id",
                        setTableHeaderName,
                        storeListByOrgnazationId,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("id")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "email"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "email",
                        setTableHeaderName,
                        storeListByOrgnazationId,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Email")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "created_at"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "created_at",
                        setTableHeaderName,
                        storeListByOrgnazationId,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Created at")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted cursor ${
                      tableHeaderName === "transaction_type"
                        ? "ascending"
                        : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "transaction_type",
                        setTableHeaderName,
                        storeListByOrgnazationId,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Transaction")}
                    <br />
                    {t("type")}
                  </td>
                  <td className="card-body gap-1 font-13 font-bold text-muted">
                    {t("Status")}
                  </td>
                </tr>
                {storeListByOrgnazationId &&
                  storeListByOrgnazationId
                    ?.filter((item) => {
                      if (searchTerm == "") {
                        return item;
                      } else if (
                        item?.store_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <tr
                        className="card-body gap-3 border-bottom cursor"
                        key={item?.id}
                      >
                        <td className="card-body gap-1">
                          <div className="d-flex flex-column justify-content-start ">
                            <span
                              className="d-inline-block text-truncate font-15 font-boldest text-dark"
                              title={item.store_name}
                              style={{ maxWidth: "160px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                            >
                              {item?.store_name}
                            </span>
                            <span className="font-12 font-bolder text-muted"></span>
                          </div>
                        </td>

                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-between  gap-3">
                            <span
                              className="fs-light  text-muted d-inline-block text-truncate"
                              style={{ maxWidth: "100px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                            >
                              {item?.id}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-between  gap-3">
                            <span
                              className="fs-light  text-muted d-inline-block text-truncate"
                              style={{ maxWidth: "150px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                            >
                              {item?.email_id}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-contents-between align-items-center">
                            <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                              {/* {new Date(item?.created_at).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}*/}
                              {momentdateFormater(item?.created_at)}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-contents-between align-items-center">
                            <span>{item?.transaction_type}</span>
                          </div>
                        </td>
                        <td>
                          <span>
                            {item.active_flag === true ? (
                              <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                {t("Active")}
                              </span>
                            ) : (
                              <span className="font-15 font-bold text-danger bg-04 bg-opacity-25 rounded-3 px-2">
                                {t("Deactive")}
                              </span>
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            {!storeListByOrgnazationId?.length && (
              <div className="d-flex flex-row justify-content-center py-3">
                <span className="font-20 font-bold text-error">
                  {t("No Stores found")}
                </span>
              </div>
            )}
          </div>

          {/*  <div className="d-flex flex-row  justify-content-center align-item-center gap-3">
            <div className="d-flex">
              {visible < suppliersState?.length && (
                <Button
                  type="rounded"
                  text="Load more"
                  className="rounded-6 my-4 mt-6 "
                  onClick={() => loadMore()}
                />
              )}
            </div>
          </div>*/}
        </div>
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default StoreList;
