import {
  ACTIVATION_GIFTCARD_FAILURE,
  ACTIVATION_GIFTCARD_REQUEST,
  ACTIVATION_GIFTCARD_SUCCESS,
  GET_GIFTCARD_DETAILS_FAILURE,
  GET_GIFTCARD_DETAILS_REQUEST,
  GET_GIFTCARD_DETAILS_SUCCESS,
  GET_GIFTCARD_TRANSACTIONS_FAILURE,
  GET_GIFTCARD_TRANSACTIONS_REQUEST,
  GET_GIFTCARD_TRANSACTIONS_SUCCESS,
  REDDEM_GIFTCARD_FAILURE,
  REDDEM_GIFTCARD_REQUEST,
  REDDEM_GIFTCARD_SUCCESS,
  SET_STORE_DETAILS,
  SET_SELL_CARD_DATA,
  POST_SELL_GIFTCARD_REQUEST,
  POST_SELL_GIFTCARD_SUCCESS,
  POST_SELL_GIFTCARD_FAILURE
} from "./eposType";
import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";

export const setStoreDetails = (data) => {
  return {
    type: SET_STORE_DETAILS,
    payload: data,
  };
};

export const getGiftcardDetailsRequest = () => {
  return {
    type: GET_GIFTCARD_DETAILS_REQUEST,
  };
};
export const getGiftcardDetailsSuccess = (data) => {
  return {
    type: GET_GIFTCARD_DETAILS_SUCCESS,
    payload: data,
  };
};
export const getGiftcardDetailsFailure = (error) => {
  return {
    type: GET_GIFTCARD_DETAILS_FAILURE,
    payload: error,
  };
};

export const getGiftcardDetails = (giftcardNumber, handlerError) => {
  return (dispatch) => {
    dispatch(getGiftcardDetailsRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcard/pos/detail/balance?giftcard_number=${giftcardNumber}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(getGiftcardDetailsSuccess(response.data.result));
        } else if (response?.response?.status) {
          dispatch(
            getGiftcardDetailsFailure(
              "Something went wrong!! Please try again!!"
            )
          );
        } else {
          dispatch(
            getGiftcardDetailsFailure(
              "Something went wrong!! Please try again!!"
            )
          );
        }
        handlerError(response?.response?.status, response?.status);
      })
      .catch((error) => {
        dispatch(
          getGiftcardDetailsFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const getGiftcardTransactionsRequest = () => {
  return {
    type: GET_GIFTCARD_TRANSACTIONS_REQUEST,
  };
};
export const getGiftcardTransactionsSuccess = (data) => {
  return {
    type: GET_GIFTCARD_TRANSACTIONS_SUCCESS,
    payload: data,
  };
};
export const getGiftcardTransactionsFailure = (error) => {
  return {
    type: GET_GIFTCARD_TRANSACTIONS_FAILURE,
    payload: error,
  };
};

export const getGiftcardTransactions = () => {
  return (dispatch) => {
    dispatch(getGiftcardTransactionsRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcard/pos`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        dispatch(getGiftcardTransactionsSuccess(response.data.result));
      })
      .catch((error) => {
        dispatch(
          getGiftcardTransactionsFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

export const redeemGiftcardRequest = () => {
  return {
    type: REDDEM_GIFTCARD_REQUEST,
  };
};
export const redeemGiftcardSuccess = (data) => {
  return {
    type: REDDEM_GIFTCARD_SUCCESS,
    payload: data,
  };
};
export const redeemGiftcardFailure = (error) => {
  return {
    type: REDDEM_GIFTCARD_FAILURE,
    payload: error,
  };
};

export const redeemGiftcard = (payload, goToSuccess) => {
  return (dispatch) => {
    dispatch(redeemGiftcardRequest());
    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcard/pos`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    };
    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          dispatch(redeemGiftcardSuccess(response.data.result));
          goToSuccess();
        }
      })
      .catch((error) => {
        dispatch(
          redeemGiftcardFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const activationGiftcardRequest = () => {
  return {
    type: ACTIVATION_GIFTCARD_REQUEST,
  };
};

export const activationGiftcardSuccess = (data) => {
  return {
    type: ACTIVATION_GIFTCARD_SUCCESS,
    payload: data,
  };
};

export const activationGiftcardFailure = (error) => {
  return {
    type: ACTIVATION_GIFTCARD_FAILURE,
    payload: error,
  };
};

export const activationGiftcardPost = (data, goToSuccess) => {
  return (dispatch) => {
    dispatch(activationGiftcardRequest());
    let payload = {
      "giftcard_number": data?.cardNo,
      "card_status": "activated",
      "store_id": "",
      "activated_by": {
        "name": data?.customerDetails?.customerName,
        "email": data?.customerDetails?.emailid,
        "phone_no": data?.customerDetails?.mobileNumber,
      }
    };
    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcard/pos/activation`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: payload,
    };
    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          dispatch(activationGiftcardSuccess(response.data.result));
          goToSuccess();
        }
      })
      .catch((error) => {
        dispatch(
          activationGiftcardFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const setSellCardData = (data) => {
  return {
    type: SET_SELL_CARD_DATA,
    payload: data
  }
}

export const postSellGiftcardRequest = () => {
  return {
    type: POST_SELL_GIFTCARD_REQUEST
  }
}

export const postSellGiftcardSuccess = (data) => {
  return {
    type: POST_SELL_GIFTCARD_SUCCESS,
    payload: data
  }
}

export const postSellGiftcardFailure = (error) => {
  return {
    type: POST_SELL_GIFTCARD_FAILURE,
    payload: error
  }
}

export const postSellGiftcard = (obj, sellGiftcardSuccess) => {
  return (dispatch) => {
    dispatch(postSellGiftcardRequest())

    const payload = {
      order_by: obj?.order_by,
      buyer_id: obj?.buyer_id,
      product_type: "giftcard",
      product_id: obj?.product_id,
      product_variant_id: "string",
      product_value: 0,
      inventory_type_id: "string",
      quantity: 0,
      order_price: 0,
      discount: 0,
      total_price: 0,
      delivery_type: "physical",
      digital_delivery_option: "separate_email_for_each_giftcard",
      delivery_address_line_1: "string",
      delivery_address_line_2: "string",
      email_id: obj?.email_id,
      phone_number: obj?.phone_number,
      receiver: {
        receiver_type: "store",
        receiver_id: obj?.receiver_id,
      },
      purchaser: {
        name: obj?.purchaser?.name,
        email: obj?.purchaser?.email,
        phone_no: obj?.purchaser?.phone_no,
      },
      store_user_id: "string",
    };


    const config = {
      method: 'post',
      url: `${baseUrl}/v1/giftcard/pos/sell/digital`,
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      data: payload,
    }

    axios(config).then((response) => {
      if (response.status === 200) {
        dispatch(postSellGiftcardSuccess(response.data.result))
        sellGiftcardSuccess()
      }
    }).catch((error) => {
      dispatch(postSellGiftcardFailure("Something went wrong!! Please try again!!"))
    })

  }
}