import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../assests/svg/Tag/blue_tag.svg";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import Button from "../../../components/Button/Button";
import { ReactComponent as DropDownIcon } from "../../../assests/svg/DropDown.svg";
import TagsInput from "../../../components/TagsInput/TagsInput";
import Toasts from "../../../components/Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import { getManualInventoryExcelData } from "../../../Redux/ManualInventory/manualInventoryAction";
import { fetchAllGiftCardList } from "../../../Redux/GiftCard/giftcardAction";
import ReactSwitchButton from "../../../components/Button/ReactSwitchButton";
import {
  DateTimeFormatter,
  momentdateFormater,
  momentdateTimeFormater,
} from "../../../utils/constantFunction";
import PulseLoader from "../../../components/Loader/PulseLoader";
import Filter from "../../../components/Filter/Filter";
import { DATE_FILTERS, PRODUCT_STATUS_FILTERS } from "../../../utils/ConstantData";
import CopyToClipboard from "react-copy-to-clipboard";
import { ReactComponent as CopyButton } from "../../../assests/svg/copyLink.svg";
import SmallToast from "../../../components/Toast/SmallToast";
import { ReactComponent as Searchicon } from "../../../assests/svg/search.svg";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SearcBar from "../../../components/InputField/SearcBar";

const BulkDistributioDashboard = (props) => {
  const history = useNavigate();
  const { t } = useTranslation();

  const manualInventoryState = useSelector(
    (state) => state.manualInventory.manualInventoryList
  );

  const giftcardLoading = useSelector((state) => state?.giftcard?.loading);

  const [manualInventoryGiftCartList, setManualInventoryGiftCartList] =
  useState([]);

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const [activeFlag, setActiveFlag] = useState(false);
  // for search bar
  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const [showSmallToast, setShowSmallToast] = useState(false);
  const [id, setId] = useState("");

  const [permissions, setpermissions] = useState();

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    // console.log(permissions);
  }, [permissions]);

  const addBulkDistribution = () => {
    history("/add-bulk-distribution");
  };

    const dropdownHandler = (index) => {
      let arr = [...manualInventoryGiftCartList];
      // setOpenCollapse(!openCollapse);
      arr &&
        arr.map((item, i) => {
          if (index === i) {
            arr[i].collapseFlag = !item.collapseFlag;
          }
        });

      setManualInventoryGiftCartList(arr);
    };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 13);
    return val;
  };

  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const activeHandle = () => {
    setActiveFlag(!activeFlag);
  };

  return (
    <div className="d-flex flex-column py-4 gap-4 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center gap-3">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row align-items-center gap-3">
            <span className="font-bold font-32">{t("Bulk Distribution")} </span>
          </div>
          <span className="font-13 text-muted">
            {t("This is where all the bulk distribution items are displayed")}
          </span>
        </div>
        {/* {permissions?.manual_inventory?.create ? ( */}
        <div>
          <Button
            onClick={() => addBulkDistribution()}
            text={t("+Add bulk distribution")}
            type="primary"
          />
        </div>
        {/* ) : null} */}
      </div>

      {giftcardLoading === false ? (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-3">
              <Tag />
              <span className="font-20 font-bold">{t("Inventory Products")}</span>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search product by name")}
                onChange={searchHandler}
              />
            </div>

            <div className="d-flex align-items-center gap-2">
              {/*   <div>
                <Button
                  text="Download CSV"
                  type="primary"
                  onClick={() => toggleShowToast()}
                />
              </div>*/}
              {/* <div>
                <Filter data={PRODUCT_STATUS_FILTERS} />
              </div>
              <div>
                <Filter data={DATE_FILTERS} />
              </div>*/}
            </div>
          </div>

          <div className="d-flex flex-column gap-4 mt-4">
            <table className="table table-borderless" responsive="xl">
              <tbody>
                <tr className="d-flex align-items-center text-muted font-14 px-4 font-bolder">
                  <td className="col-3">{t("Uploaded at")}</td>
                  <td className="col-3">{t("Name")}</td>
                  <td className="col-3">{t("Product id")}</td>
                  <td className="col-3">{t("Currency")}</td>
                </tr>
                {manualInventoryGiftCartList &&
                  manualInventoryGiftCartList
                    .filter((item) => {
                      if (searchTerm == "") {
                        return item;
                      } else if (
                        item.default_title
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <tr
                        key={index}
                        className="d-flex flex-column justify-content-start px-4"
                      >
                        <div className="d-flex flex-row justify-content-start align-items-center ">
                          <td className="col-3 pt-3">
                            <div className="d-flex flex-column justify-content-start ">
                              <span className="font-15 font-boldest text-dark ">
                                {/* {DateTimeFormatter(item.created_at)}*/}
                                {momentdateFormater(item.created_at)}
                              </span>
                            </div>
                          </td>
                          <td className="col-3 pt-3">
                            <div className="d-flex flex-column justify-content-start ">
                              <span className="font-bold text-dark font-15">
                                {item.default_title
                                  ? item.default_title
                                  : "No title avaible"}
                              </span>
                            </div>
                          </td>

                          <td className="col-3 pt-3">
                            {/* <div className="d-flex flex-column justify-content-start ">
                          <span className="font-14">
                          {item.id}
                          </span>
                        </div> */}
                            <div
                              className="d-flex justify-content-start align-items-center visible cursor"
                              title={item?.id}
                            >
                              <span className="font-14 font-boldest text-dark">
                                {trimIdHandler(item?.id)}
                                <span>...</span>
                              </span>
                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Copy to clipboard"
                                className="cursor "
                                value={item.id}
                                onClick={() => copyToClipboard(item?.id)}
                              >
                                <CopyToClipboard text={item?.id}>
                                  <CopyButton />
                                </CopyToClipboard>
                              </span>
                            </div>
                          </td>

                          <td className="col-3 pt-3">
                            <div className="d-flex flex-column justify-content-start">
                              <span className="font-bold text-dark font-15">
                                {item?.country?.currency
                                  ? `${item?.country?.currency} (${item?.country?.currency_symbol})`
                                  : "No currency avaible"}
                              </span>
                            </div>
                          </td>

                          <td className="col-1 align-top pt-3">
                            <div onClick={() => dropdownHandler(index)}>
                              <DropDownIcon
                                className={`cursor p-0 ${
                                  item?.collapseFlag ? "rotate-180" : ""
                                }`}
                              />
                            </div>
                          </td>
                        </div>
                        {item?.collapseFlag && (
                          <div id="example-collapse-text">
                            <div className="d-flex bg-white shadow rounded-3 ">
                              {/*@@@@@@TODO(This part is hidden for temporary)@@@@@@ */}

                              {/* <div className="d-flex flex-column w-50 gap-3 mx-3 my-3">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div className="d-flex align-items-center gap-2">
                                <span className="font-14 font-bolder">
                                  Do you want to enable limit notifications?
                                </span>
                                <InfoTooltip title={"Make sure"} />
                              </div>
                              <ReactSwitchButton
                                onChange={() => activeHandle()}
                                value={activeFlag}
                              />
                            </div>
                            <div className="d-flex flex-row justify-content-between ">
                              <div className="d-flex align-items-center gap-2">
                                <span className="font-14 font-bolder">
                                  Email List
                                </span>
                                <InfoTooltip
                                  title={"Enter email in proper formate"}
                                />
                              </div>
                            </div>

                            <div className="d-flex flex-column gap-3">
                              <div className="d-flex flex-row align-items-center gap-2">
                                <TagsInput
                                  tags={tags}
                                  setTags={setTags}
                                  placeholder={"Enter email"}
                                />
                              </div>
                            </div>
                          </div> */}
                              {/* <div className="border-start border-1 my-3"></div> */}
                              {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}

                              <div className="d-flex flex-column w-100 gap-3 mx-3 my-3">
                                {/* <div className="d-flex flex-column gap-4 mx-3 my-3 "> */}
                                <div className="d-flex flex-column justify-content-between gap-3">
                                  <table
                                    className="table table-borderless"
                                    responsive="xl"
                                  >
                                    <tbody>
                                      <tr
                                        key={index}
                                        className="card-body d-flex justify-content-between align-items-center font-14 font-bolder gap-3 px-2"
                                      >
                                        <td className="card-body">
                                          {t("Denominations")}
                                        </td>
                                        <td className="card-body d-flex justify-content-start">
                                          {t("Denomination Id")}
                                        </td>
                                        <td className="card-body">
                                          <div className="d-flex justify-content-center align-items-center">
                                            {t( )}
                                          </div>
                                        </td>
                                      </tr>
                                      {manualInventoryState &&
                                        manualInventoryState?.map(
                                          (denoItem, i) => (
                                            <tr
                                              key={i}
                                              className="d-flex justify-content-between align-items-center gap-3 px-2"
                                            >
                                              {item.id ===
                                                denoItem?._id?.giftcard_id && (
                                                <td className="card-body">
                                                  <div className="font-14 fit-content font-bolder button-bg-color cursor px-2 rounded-3">
                                                    {
                                                      denoItem?._id
                                                        ?.denomination_value
                                                    }
                                                  </div>
                                                  {/* </div> */}
                                                </td>
                                              )}
                                              {item.id ===
                                                denoItem?._id?.giftcard_id && (
                                                <td className="card-body">
                                                  <div
                                                    className="d-flex justify-content-start align-items-center visible cursor"
                                                    title={
                                                      denoItem?._id
                                                        ?.denomination_id
                                                    }
                                                  >
                                                    <span className="font-14 font-boldest text-dark ">
                                                      {/* {trimIdHandler(denoItem?._id
                                                    ?.denomination_id)}*/}
                                                      {
                                                        denoItem?._id
                                                          ?.denomination_id
                                                      }
                                                      <span> </span>
                                                    </span>
                                                    <span
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Copy to clipboard"
                                                      className="cursor justify-content-center"
                                                      value={
                                                        denoItem?._id
                                                          ?.denomination_id
                                                      }
                                                      onClick={() =>
                                                        copyToClipboard(
                                                          denoItem?._id
                                                            ?.denomination_id
                                                        )
                                                      }
                                                    >
                                                      {" "}
                                                      <CopyToClipboard
                                                        text={
                                                          denoItem?._id
                                                            ?.denomination_id
                                                        }
                                                      >
                                                        <CopyButton />
                                                      </CopyToClipboard>
                                                    </span>
                                                  </div>
                                                </td>
                                              )}
                                              {item.id ===
                                                denoItem?._id?.giftcard_id && (
                                                <td className="card-body">
                                                  <div className="d-flex justify-content-center align-items-center">
                                                    {denoItem?.count}
                                                  </div>
                                                </td>
                                              )}
                                            </tr>
                                          )
                                        )}
                                      {/* {item.id !== denoItem?._id?.giftcard_id &&  <tr
                                    className="d-flex justify-content-start align-items-center"
                                  >
                                    <td className="col">
                                      <div className="font-14 fit-content font-bolder button-bg-color cursor px-2 rounded-3">
                                        No denominations
                                      </div>
                                    </td>
                                    <td className="col">No quantity</td>
                                  </tr>} */}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-center font-14 font-bolder">
                                  <span>{t("Product ID")}</span>
                                  <span className="text-muted">{item.id}</span>
                                </div>
                                {/* <div className="d-flex flex-row justify-content-between align-items-center font-14 font-bolder">
                            <span>Currency</span>
                            <span className="text-muted">Rupee - INR</span>
                          </div> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}

      <SmallToast data={id ? id : ""} message={"Copied"} />
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default BulkDistributioDashboard;
