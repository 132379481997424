import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import { ReactComponent as DeleteIconWhite } from "../../assests/svg/delete_black.svg";
import DragHandle from "../../assests/img/drag-handle.png";
import Button from "../Button/Button";
import InfoTooltip from "../Tooltip/InfoTooltip";
import BackButton from "../Button/BackButton";
import { useDispatch, useSelector } from "react-redux";
import DragandDropMultiple from "../DragandDrop/DragandDropMultiple";
import { setMerchandiseVariantImages } from "../../Redux/MerchandiseVarient/merchandiseVarientActions";
//import { putMerchandiseVarientsImages } from "../../Redux/Merchandise/merchandiesAction";

const SortVariants = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const merchandiseCatAttriState = useSelector((state) => state?.merchandiseCatAttriData?.merchandiseCatAtt);
  const merchandiseVarientImg = useSelector((state) => state?.merchandiseVarient?.merchandiseVarientImg);
  // const merchandiseData = useSelector((state) => state?.merchandiseCatAttriData);

  const [prodObj, setProdObj] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    let arr = [...merchandiseVarientImg];
    // eslint-disable-next-line array-callback-return
    arr && arr.map((item, i) => {
      if (location.state.index === i) {
        setProdObj(item)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!merchandiseCatAttriState) {
      history("/add-merchandise");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchandiseCatAttriState]);


  const goBack = () => {
    history("/merchandise-dashboard");
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(prodObj.images);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);

    setProdObj({
      ...prodObj,
      images: tempData,
    });

  };

  const goTonextStep = () => {
    addImagesInObj();
    history("/add-variant/merchandise-mapping");
  };

  const addImagesInObj = () => {
    let arr = [...merchandiseVarientImg];

    // eslint-disable-next-line array-callback-return
    arr && arr.map((item, i) => {
      if (location.state.index === i) {
        arr[i] = prodObj;
      } else {
        arr[i] = item;
      }
    })

    dispatch(setMerchandiseVariantImages(arr));

  }
  const setProductImg = (e) => {
    let arr = [...prodObj?.images];
    if (arr.length > 0) {
      let imgArr = [...arr, ...e]
      setProdObj({ ...prodObj, images: imgArr });
    } else {
      setProdObj({ ...prodObj, images: e });
    }
  }

  const removeProductImg = (e) => {
    let array = [...prodObj?.images];
    if (e !== -1) {
      array.splice(e, 1);
      setProdObj({ ...prodObj, images: array });
    }
  }

  return (
    prodObj &&
    <div className="d-flex flex-column justify-content-start py-4 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">Sort variant images</span>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between gap-2">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65 gap-2 mb-2">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-3 py-3">
              <Tag />
              <span className="font-20 font-bold">Product images</span>
            </div>
            <div>
              <BackButton onClick={() => history('/add-variant/merchandise-mapping')} />
            </div>
          </div>

          <div className="d-flex flex-column gap-4 mt-4 ">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Upload images<span className="text-danger">*</span>
                  <span> (size should be 600*200)</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <DragandDropMultiple files={prodObj.images} setFiles={setProductImg} />

            </div>
          </div>

          {prodObj.images.length > 0 &&
            <div className="d-flex flex-column gap-4 mt-4">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Sort images<span className="text-danger">*</span>
                  <span> (size should be 600*200)</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="d-flex w-100">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <table responsive="xl" className="w-100">
                    <Droppable droppableId="droppable-1">
                      {(provider) => (
                        <tbody
                          className="text-capitalize"
                          ref={provider.innerRef}
                          {...provider.droppableProps}
                        >
                          {prodObj.images?.map((user, index) => (
                            <Draggable
                              key={user.path}
                              draggableId={user.path}
                              index={index}
                            >
                              {(provider) => (
                                <tr
                                  key={index}
                                  id="tr-sort"
                                  className="d-flex flex-row align-items-center justify-content-between  gap-3 p-2"
                                  {...provider.draggableProps}
                                  ref={provider.innerRef}
                                >

                                  <td>
                                    <div className="d-flex flex-row align-items-center gap-3">
                                      <span>
                                        <img
                                          src={user.preview}
                                          width={52}
                                          height={52}
                                          alt="Logo Not Found"
                                        />
                                      </span>
                                      <span>
                                        <div className="font-16 font-bolder">
                                          {user.path}
                                        </div>
                                        {/* <div className="text-muted">
                                      {user.size}
                                    </div> */}
                                      </span>
                                    </div>

                                  </td>
                                  <td><div className="d-flex flex-row justify-content-between align-items-center gap-3">
                                    <div onClick={() => removeProductImg(index)}
                                      data-bs-toggle="tooltip" data-bs-placement="bottom" title="Remove image">
                                      <DeleteIconWhite className="cursor" />
                                    </div>
                                    <div {...provider.dragHandleProps}>
                                      <span className="cursor" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Sort image(move up/down)">
                                        <img
                                          src={DragHandle}
                                          width={40}
                                          height={40}
                                          alt="Logo Not Found"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provider.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </table>
                </DragDropContext>
              </div>
            </div>}
        </div>
      </div>
      <div className="d-flex my-3">
        <Button
          onClick={() => goTonextStep()}
          text="Save Variant Image"
          type="primary"
        />
      </div>
    </div>
  );
};

export default SortVariants;
