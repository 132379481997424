import React, { useRef } from "react";
import { ReactComponent as UploadIcon } from "../../assests/svg/upload.svg"

const FileUpload = (props) => {
    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };

    return (
        <div className="file bg-light rounded-3 py-5 w-100 ">
            <div className="d-flex flex-row align-items-center bg-white shadow-sm rounded-3 my-3 py-2 px-3 cursor" onClick={handleClick}>
                <UploadIcon />
                <span >Click or drop image</span>
            </div>
            <input
                type='file'
                ref={hiddenFileInput}
                onClick={handleChange}
            />

        </div>
    );
};

export default FileUpload;
