import React, { useEffect } from "react";
import BestClients from "../../components/Products/BestClients";
import GiftCard from "../../components/Products/GiftCard";
import ProductActivity from "../../components/Products/ProductActivity";
import ProductOverview from "../../components/Products/ProductOverview";
import { useTranslation } from "react-i18next";


const ProductDashbord = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-column justify-content-start py-4 px-4 bg-light">
      <div className="d-flex">
        <span className="font-40 font-bold mb-2">{t("Giftcards dashboard")}</span>
      </div>
      <div className="d-flex flex-column justify-content-start gap-2">
        <div className="d-flex">
          <ProductOverview />
        </div>
        <div className="d-flex flex-row gap-2">
          <div className="width-65">
            <ProductActivity />
          </div>
          <div className="width-35">
            <BestClients />
          </div>
        </div>

        <div className="d-flex">
          <GiftCard lang={props?.lang} />
        </div>

      </div>
    </div>
  );
};

export default ProductDashbord;
