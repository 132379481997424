/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/orange_tag.svg";
import { ReactComponent as CloseIcone } from "../../assests/svg/close.svg";
import TextEditor from "../InputField/TextEditor";
import Button from "../Button/Button";
import BackButton from "../Button/BackButton";
import ModalComp from "../Models/ModalComp";
import GiftCardModel from "../Models/GiftCardModel";
import { useDispatch, useSelector } from "react-redux";
import { setTermCondition } from "../../Redux/GiftCard/giftcardAction";
import { EditorState, convertToRaw } from "draft-js";
import Toasts from "../Toasts/Toasts";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";

const ProductTermCondition = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const priceState = useSelector((state) => state?.giftcard?.cardPrice);
  const termConditionState = useSelector((state) => state?.giftcard?.cardTermCondition);
  const Preferredlanguage = useSelector((state) => state?.languages?.languages);
  const giftcardDescription = useSelector((state) => state?.giftcard?.cardNameDesc);

  let defaultLang = localStorage.getItem("defaultLang");
  const edittorTxt = {
    value: EditorState.createEmpty(),
    lang: JSON.parse(defaultLang),
  };
  const [showModal, setShowModal] = useState(false);

  const [editorState, setEditorState] = useState(
    termConditionState?.termCondition
      ? termConditionState?.termCondition
      : [edittorTxt]
  );

  const [error, setError] = useState({
    editorState: "",
  });
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    giftcardDescription?.preferredLanguage && giftcardDescription?.preferredLanguage?.language_code !== "EN" && handleDropdownOnClick(giftcardDescription?.preferredLanguage);
  }, [giftcardDescription?.preferredLanguage]);

  useEffect(() => {
    if (!priceState) {
      history("/product-creation/name-description");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceState]);

  const toggleShowToast = () => setShowToast(!showToast);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const getDescription = (txt, code) => {
    let arr = [...editorState];
    setError({ editorState: "" });
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (code === item.lang.language_code) {
        arr[i].value = txt;
      }
    });
    setEditorState(arr);
  };

  const handleAddClick = (e) => {
    if (
      editorState.some((item) => item.lang.language_code === e.language_code)
    ) {
      // alert("Already added");
      toggleShowToast();
      return;
    } else {
      setEditorState([
        ...editorState,
        { value: EditorState.createEmpty(), lang: e },
      ]);
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...editorState];
    list.splice(index, 1);
    setEditorState(list);
  };

  const handleDropdownOnClick = (obj) => {
    handleAddClick(obj);
  };
  const validate = () => {
    let value = convertToRaw(editorState[0].value.getCurrentContent());
    if (!value.blocks[0].text) {
      setError({ editorState: t("Please provide Terms & Condition") });
      return false;
    }
    return true;
  };

  const submit = () => {
    let value = validate();

    if (value) {
      let obj = {
        termCondition: editorState,
      };
      dispatch(setTermCondition(obj));
      handleShow();
    }
  };

  return (
    <div className="d-flex flex-column bg-white shadow-sm position-relative rounded-3 py-3 px-4 w-100 gap-4">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">{t("Terms & Condition")}</span>
        </div>
        <div>
          <BackButton onClick={() => history(-1)} />
        </div>
      </div>

      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-column gap-3">
          {editorState.map((item, i) => (
            <div className="d-flex flex-column gap-3" key={i}>
              <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Terms & Condition")} #{i + 1} {item.lang.language}
                    <span className="text-danger">{i === 0 ? "*" : ""}</span>
                  </span>
                  {/* <InfoTooltip title={"Maximum 100 characters. No HTML or emoji allowed"} /> */}
                </div>
                {editorState.length !== 1 && (
                  <div className="cursor" onClick={() => handleRemoveClick(i)}>
                    {i !== 0 && <CloseIcone />}
                  </div>
                )}
              </div>
              <div className="d-flex flex-column gap-1">
                <TextEditor
                  code={item.lang.language_code}
                  editorState={item.value}
                  getDescription={getDescription}
                  lang={props?.lang}
                />
                {error.editorState && i === 0 ? (
                  <label className="text-error">{error.editorState}</label>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="d-flex">
        <div className="btn-group w-100">
          <div
            className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
            data-bs-toggle="dropdown"
          >
            <span>{t("Add other language Terms & Condition")}</span>
          </div>
          <ul className={`dropdown-menu rounded-card-7 dropdown-menu-start ${props?.lang === 'ar' ? 'dropdown-menu-ar' : ''}`}>
            {Preferredlanguage &&
              Preferredlanguage.map((item, i) => (
                <li key={i} onClick={() => handleDropdownOnClick(item)}>
                  <div className="dropdown-item cursor">{item.language}</div>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <div className="d-flex">
        <Button onClick={() => submit()} text={t("Save & Preview")} type="primary" />
      </div>

      <ErrorBoundary>
        <ModalComp
          size="lg"
          showModal={showModal}
          // handleClose={handleClose}
          component={<GiftCardModel handleClose={handleClose} />}
        />
      </ErrorBoundary>

      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Allready Added."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default ProductTermCondition;
