

import {GET_WARRANTY_REQUEST,
    GET_WARRANTY_SUCCESS,
    GET_WARRANTY_FAILURE} from './warrantyType'

const initialWarrantyState = {
    loading: false,
    warranty: [],
    error: "",
  };
  
  const warrantyReducer = (state = initialWarrantyState, action) => {
    switch (action.type) {
      case GET_WARRANTY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_WARRANTY_SUCCESS:
        return {
          ...state,
          loading: false,
          warranty: action.payload,
          error: "",
        };
      case GET_WARRANTY_FAILURE:
        return {
          ...state,
          loading: false,
          warranty: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  export default warrantyReducer;
  