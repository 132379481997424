export const GET_MERCHANDISE_PRODLIST_REQUEST = "GET_MERCHANDISE_PRODLIST_REQUEST";
export const GET_MERCHANDISE_PRODLIST_SUCCESS = "GET_MERCHANDISE_PRODLIST_SUCCESS";
export const GET_MERCHANDISE_PRODLIST_FAILURE = "GET_MERCHANDISE_PRODLIST_FAILURE";


export const GET_MERCHANDISE_VARIANT_PRICE_REQUEST = "GET_MERCHANDISE_VARIANT_PRICE_REQUEST";
export const GET_MERCHANDISE_VARIANT_PRICE_SUCCESS = "GET_MERCHANDISE_VARIANT_PRICE_SUCCESS";
export const GET_MERCHANDISE_VARIANT_PRICE_FAILURE = "GET_MERCHANDISE_VARIANT_PRICE_FAILURE"

export const CLEAR_MERCHANDISE_LIST = "CLEAR_MERCHANDISE_LIST"
