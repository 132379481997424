/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import logo from "../../assests/img/default-user.png";
import { ReactComponent as Mail } from "../../assests/svg/Sidebar/mail.svg";
import { ReactComponent as Notification } from "../../assests/svg/Sidebar/notification.svg";
import SearcBar from "../InputField/SearcBar";
import "./Header.css";
import { useSelector } from "react-redux";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { MODULE_DATA_SEARCH } from "../../utils/ConstantData";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const history = useNavigate();

  const [userData, setUserData] = useState();
  const toggleValue = useSelector(
    (state) => state.sideNavToggleReducer.sideNavToggle
  );

  let headerClass = toggleValue ? "topbar-container active" : "topbar-container"
  let headerClassAR = toggleValue ? "topbar-container-ar active" : "topbar-container-ar"

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, [localStorage.getItem("user")]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }



  const handleOnSelect = (item) => {
    // the item selected
    history(item?.url);
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }

  return (
    <div
      className={`${props?.lang === "ar" ? headerClassAR : headerClass
        } d-flex flex-row justify-content-between align-items-center px-4 py-4`}
    >
      {/* <SearcBar type="text" name="search" lang={props?.lang} /> */}
      <div style={{ width: 400 }}>
        <ReactSearchAutocomplete
          items={MODULE_DATA_SEARCH}
          onSearch={handleOnSearch}
          // onHover={handleOnHover}
          onSelect={handleOnSelect}
          // onFocus={handleOnFocus}
          autoFocus
          formatResult={formatResult}
          styling={{
            height: "45px",
            // border: "1px solid #f4f4f4",
            borderRadius: "3px",
            backgroundColor: "#f4f4f4",
            boxShadow: "none",
            hoverBackgroundColor: "#fff",
            color: "#212529",
            fontSize: "16px",
            fontFamily: "sans-serif",
            // iconColor: "green",
            lineColor: "#212529",
            placeholderColor: "#212529",
            clearIconMargin: "3px 8px 0 0",
            zIndex: 2,
          }}
        />
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center gap-5">
        <div>
          <Mail />
        </div>
        <div>
          <Notification />
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center gap-3">
          <img src={logo} width={52} height={52} alt="Logo Not Found" />
          <div className="d-flex flex-column">
            <span className="fw-bold text-uppercase">
              {userData?.user_type === "organization_store"
                ? "STORE"
                : userData?.user_type}
            </span>
            <span className="text-muted">{userData?.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
