import {
    FETCH_ALL_BRAND_CATEGORY_REQUEST,
    FETCH_ALL_BRAND_CATEGORY_SUCCESS,
    FETCH_ALL_BRAND_CATEGORY_FAILURE,
    PUT_BRAND_CATEGORY_REQUEST,
    PUT_BRAND_CATEGORY_SUCCESS,
    PUT_BRAND_CATEGORY_FAILURE,
    PUT_BRAND_CATEGORY_ID_REQUEST,
    PUT_BRAND_CATEGORY_ID_SUCCESS,
    PUT_BRAND_CATEGORY_ID_FAILURE,
    PATCH_BRAND_CATEGORY_REQUEST,
    PATCH_BRAND_CATEGORY_SUCCESS,
    PATCH_BRAND_CATEGORY_FAILURE,
    BRAND_CATEGORY_FILTER_DATA,
    CLEAR_BRAND_CATEGORY_LIST

} from "./brandCategoryType";

const initialBrandCategoryState = {
    loading: false,
    brandCategories: [],
    brandCategoriesbyID: [],
    brandcatResponse: null,
    error: '',
    next_iteration: true,
    brandCategoryFilter: []
}

const brandCategory = (state = initialBrandCategoryState, action) => {

    switch (action.type) {
        // set filter data 
        case BRAND_CATEGORY_FILTER_DATA:
            return {
                ...state,
                brandCategoryFilter: action.payload
            }
        case CLEAR_BRAND_CATEGORY_LIST:
            return {
                ...state,
                loading: false,
                brandCategories: [],
                next_iteration: true,
                error: ''
            }
        case FETCH_ALL_BRAND_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_ALL_BRAND_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                brandCategories: action.payload.data,
                next_iteration: action.payload.moredata,
                error: ''

            }
        case FETCH_ALL_BRAND_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                brandCategories: [],
                error: action.payload
            }
        case PUT_BRAND_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                brandcatResponse: null
            }
        case PUT_BRAND_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                brandcatResponse: action.payload,
                error: ''
            }
        case PUT_BRAND_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                brandcatResponse: null,
                error: action.payload
            }
        case PUT_BRAND_CATEGORY_ID_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PUT_BRAND_CATEGORY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                brandCategoriesbyID: action.payload,
                error: ''
            }
        case PUT_BRAND_CATEGORY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                brandcatResponse: null,
                error: action.payload
            }
        case PATCH_BRAND_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                brandcatResponse: null
            }
        case PATCH_BRAND_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                brandcatResponse: action.payload,
                error: ''

            }
        case PATCH_BRAND_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                brandcatResponse: null,
                error: action.payload
            }

        default: return state
    }
}
export default brandCategory