/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import CardPreviewEdit from "../../components/Products/CardPreviewEdit";
import EditProduct from "../../components/Products/EditProduct";
import { getAllActivations } from "../../Redux/ActivationRequired/activationAction";
import { fetchBrand } from "../../Redux/Brands/brandAction";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import { fetchAllGiftCardLengths, fetchAllGiftCardPrefix, fetchAllGiftCardType, fetchGiftCardDetailsId } from "../../Redux/GiftCard/giftcardAction";
import { getAllLanguage } from "../../Redux/Language/languageAction";
import { getAllRedemptionMethod } from "../../Redux/Redemption/redemptionAction";
import { fetchSupplierList } from "../../Redux/Supplier/supplierAction";
import { useTranslation } from "react-i18next";

const EditProductScreen = (props) => {
    const location = useLocation()
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchGiftCardDetailsId(location.state.giftcardId));
        dispatch(getAllLanguage());
        dispatch(fetchBrand());
        dispatch(fetchSupplierList());
        dispatch(getAllCountriesList());
        dispatch(fetchAllGiftCardLengths());
        dispatch(fetchAllGiftCardPrefix());
        dispatch(getAllRedemptionMethod());
        dispatch(getAllActivations());
        dispatch(fetchAllGiftCardType());

    }, []);

    return (
        <div className="d-flex flex-column justify-content-start py-4 px-4 bg-light">
            <div className="d-flex flex-row justify-content-between">
                <span className="font-40 font-bold mb-2">{t("Full description (Edit)")}</span>
            </div>
            <div className="d-flex flex-row mt-2 gap-2">
                <div className="width-65" >
                    <EditProduct lang={props?.lang}/>
                </div>
                <div className="width-35" >
                    <CardPreviewEdit />
                </div>
            </div>

        </div>
    );
};

export default EditProductScreen;
