/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import Toasts from "../Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTransationSelSup,
} from "../../Redux/Supplier/supplierAction";
import {
  DataSortingHandler,
  momentdateTimeFormater,
  strFirstLetterCap,
} from "../../utils/constantFunction";
import PulseLoader from "../../components/Loader/PulseLoader";
import PulseLoaders from "react-spinners/PulseLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";
import { SORT_TYPE } from "../../utils/ConstantData";

const AllTransaction = (props) => {
  const { selectedSupplier } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selSupplierTransaction = useSelector(
    (state) => state?.suppliers?.selSupplierTransaction
  );

  const loading = useSelector((state) => state?.suppliers?.loading);
  const hasmore = useSelector((state) => state?.suppliers?.next_iteration);
  const [showToast, setShowToast] = useState(false);
  // const [products, setProducts] = useState();

  // set infinite loader
  const [skip, setskip] = useState(
    selSupplierTransaction?.length === 0
      ? -10
      : selSupplierTransaction?.length - 10
  );
  const [limit, setlimit] = useState(10);

  // search filter
  const [searchTerm, setSearchTerm] = useState("");
  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleShowToast = () => setShowToast(!showToast);

  // for table data sorting
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => { }, [order]);

  useEffect(() => {
    if (selSupplierTransaction?.length === 0) {
      fetchdata(skip);
    }
  }, [selSupplierTransaction]);

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(
        getAllTransationSelSup(
          skip + limit,
          limit,
          selSupplierTransaction,
          selectedSupplier?.id
        )
      );
      setskip(skip + limit);
    }, 100);
  };

  return loading === true && selSupplierTransaction?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="d-flex flex-row w-100  align-items-center">
          <div className="p-2 flex-grow-1">
            <div className="d-flex gap-3 my-2 mx-3 align-items-center">
              <Tag />
              <span className="font-20 font-bold">{t("All Transactions")}</span>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search order by product name")}
                onChange={searchHandler}
              />
            </div>
          </div>


        </div>
        <InfiniteScroll
          dataLength={selSupplierTransaction?.length || 0}
          next={fetchdata}
          hasMore={hasmore}
          loader={
            <div className="d-flex justify-content-center">
              {" "}
              <PulseLoaders
                color={"#594190"}
                loading={loading}
                size={10}
              />{" "}
            </div>
          }
        >
          <div className="row px-5 py-3">
            <table responsive="xl">
              <tbody>
                <tr className="card-body cursor">
                  <td
                    className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "created_at"
                      ? "ascending"
                      : "descending"
                      }`}
                    onClick={() =>
                      sortingHandler(
                        "created_at",
                        setTableHeaderName,
                        selSupplierTransaction,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Order date")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "id"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "id",
                        setTableHeaderName,
                        selSupplierTransaction,
                        order,
                        setOrder
                      )
                    }>
                    {t("Order id")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "default_title"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "default_title",
                        setTableHeaderName,
                        selSupplierTransaction,
                        order,
                        setOrder
                      )
                    }>
                    {t("Product Name")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "quantity"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "quantity",
                        setTableHeaderName,
                        selSupplierTransaction,
                        order,
                        setOrder
                      )
                    }>
                    {t("Quantity")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "total_price"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "total_price",
                        setTableHeaderName,
                        selSupplierTransaction,
                        order,
                        setOrder
                      )
                    }>
                    {t("Amount")} <br />
                    {t("(Before discount)")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "product_value"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "product_value",
                        setTableHeaderName,
                        selSupplierTransaction,
                        order,
                        setOrder
                      )
                    }>
                    {t("Amount")}
                    <br />
                    {t("(After discount)")}
                  </td>
                  <td className={`card-body gap-1 font-13 font-bold text-muted ${tableHeaderName === "order_status"
                    ? "ascending"
                    : "descending"
                    }`}
                    onClick={() =>
                      sortingHandler(
                        "order_status",
                        setTableHeaderName,
                        selSupplierTransaction,
                        order,
                        setOrder
                      )
                    }>
                    {t("Order status")}
                  </td>
                </tr>

                {selSupplierTransaction &&
                  selSupplierTransaction
                    ?.filter((item) => {
                      if (searchTerm === "") {
                        return item;
                      } else if (
                        item?.product_type === "merchandise"
                          ? item?.merchandise?.default_title
                          : item?.giftcard?.default_title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item) => (
                      <tr className="card-body border-bottom" key={item?.id}>
                        <td className="card-body gap-1">
                          <span
                            className="font-15 font-bold text-dark d-inline-block text-truncate cursor"
                            title={momentdateTimeFormater(item?.created_at)}
                            style={{ maxWidth: "80px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {momentdateTimeFormater(item?.created_at)}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-between  gap-3">
                            <span
                              className="font-15 font-bold text-dark d-inline-block text-truncate cursor"
                              title={item?.id}
                              style={{ maxWidth: "70px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                            >
                              {item?.id}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <span
                            className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                            title={
                              item?.product_type === "merchandise"
                                ? item?.merchandise?.default_title
                                : item?.giftcard?.default_title
                            }
                            style={{ maxWidth: "100px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {strFirstLetterCap(
                              item?.product_type === "merchandise"
                                ? item?.merchandise?.default_title
                                : item?.giftcard?.default_title
                            )}
                          </span>
                          <br />
                          <div className="d-flex flex-column">
                            <span className="font-12 font-bolder text-dark">
                              {strFirstLetterCap(item?.product_type)}
                            </span>
                            <span className="font-12 font-bolder text-muted">
                              {item?.product_type === "giftcard" &&
                                item?.giftcard &&
                                item?.product_variant_id &&
                                item?.giftcard?.denomination &&
                                item?.giftcard?.denomination?.currency +
                                "-" +
                                item?.giftcard?.denomination
                                  ?.denomination_value}
                              {item?.product_type === "merchandise" &&
                                item?.merchandise &&
                                item?.product_variant_id &&
                                item?.merchandise?.product_variants_flag &&
                                item?.merchandise?.variant &&
                                item?.merchandise?.variant?.variant_values &&
                                item?.merchandise?.variant?.variant_values.join(
                                  "|"
                                )}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <span className="font-15 font-bold text-dark ">
                            {item?.quantity}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          <span className="font-15 font-bold text-dark text-light-orange">
                            {item?.total_price}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          <span className="font-15 font-bold text-dark text-light-orange">
                            {item?.order_price}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-content-between  gap-3">
                            <span className="font-15 font-bold text-dark">
                              {strFirstLetterCap(item?.order_status)}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            {!selSupplierTransaction?.length && (
              <div className="d-flex flex-row justify-content-center py-3">
                <span className="font-20 font-bold text-error">
                  {t("No transactions details found")}
                </span>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 mb-2"
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Your file is being downloaded"
          imageType={"success"}
        />
      </div>
    </div>
  );
};

export default AllTransaction;
