/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import SigninLeftPanel from "../../components/SigninLeftPanel/SigninLeftPanel";
import { useTranslation } from "react-i18next";
import { UseOnKeyDown } from "../../components/hooks/UseOnKeyDown";
import OtpInput from "react-otp-input";
import axios from "axios";
import { USER_AUTHORIZATION } from "../../utils/prodURL";
import PulseLoader from "../../components/Loader/PulseLoader";
import SignInToast from "../../components/Toasts/SignInToast";

const OtpScr = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");

  const [error, setError] = useState({ otp: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [resentBtnHide, setResendBtnHide] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);
  const handleChangeOTP = (e) => {
    setOtp(e);
    setError({ ...error, otp: "" });
  };


  useEffect(() => {
    setMessage(location?.state?.message);
    location?.state?.message && toggleShowToast();
  }, []);

  useEffect(() => {
    location?.state?.username && setMinutes(1)
    location?.state?.username && setSeconds(59)
  }, [])

  useEffect(() => {
    if (!location?.state?.username && !location?.state?.password) {
      history("/");
    }
  }, [location?.state]);

  // OTP Expire Time
  let expireTimer;
  useEffect(() => {
    expireTimer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(expireTimer);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(expireTimer);
  });

  const validate = () => {
    if (otp.length !== 6) {
      setError({ ...error, otp: "Please enter OTP" });
      return false;
    }
    return true;
  };

  const submitOTP = () => {
    setLoading(true);

    let val = validate();
    if (val) {
      let config = {
        method: "POST",
        url: `${USER_AUTHORIZATION}/v1/user/login?user_identifier=${location?.state?.username}&password=${location?.state?.password}&otp=${otp}`,
        headers: {},
      };

      axios(config)
        .then((response) => {
          if (response?.data?.status === "success") {
            setLoading(false);
            localStorage.setItem(
              "token",
              JSON.stringify(response.data.result.token)
            );
            localStorage.setItem(
              "permissions",
              JSON.stringify(response.data.result.role_permissions.permissions)
            );
            localStorage.setItem("user", JSON.stringify(response.data.result));
            localStorage.setItem("loggedIn", true);
            history("/");
            window.location.reload();
          } else if (response?.data?.status === "error") {
            setMessage(response?.data?.errors[0]);
            toggleShowToast();
          }
        })
        .catch((error) => {
          console.log("error ", error);
        });
    }
    setLoading(false);
  };

  const resendOtp = () => {
    setLoading(true);

    if (location?.state?.username) {
      let config = {
        method: "POST",
        url: `${USER_AUTHORIZATION}/v1/user/login/otp?user_identifier=${location?.state?.username}&password=${location?.state?.password}`,
        headers: {},
      };
      axios(config)
        .then((response) => {
          if (response?.data?.status === "success") {
            setMessage("OTP sent");
            toggleShowToast();
            setMinutes(1);
            setSeconds(59);
            setResendBtnHide(true);
          } else if (response?.data?.status === "error") {
            setMessage(response?.data?.errors[0]);
            toggleShowToast();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setLoading(false);
  };

  //following function is used to detect enter key hit
  UseOnKeyDown(submitOTP, "Enter");

  return loading ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex row align-items-center bg-white">
      <SigninLeftPanel />

      <div className="col">
        <div className="d-flex justify-content-center align-items-start ">
          <div className="d-flex flex-column justify-content-start gap-3">
            <h1 className="heading-text">{t("Sign In")}</h1>
            <span className="font-14 font-bolder ">
              We just sent you to the verification code,
              <br /> please check your email
            </span>
            <div className="d-flex flex-column gap-2">
              <div className="col-sm-12">
                <OtpInput
                  value={otp}
                  onChange={handleChangeOTP}
                  numInputs={6}
                  separator={<span className="mx-2"></span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={"otp-input-style"}
                //containerStyle={"px-4 py-4"}
                />
              </div>
              {error.otp ? (
                <label className="text-error">{error.otp}</label>
              ) : null}
              <div className="mt-3">
                <Button
                  text={t("Continue")}
                  type="primary"
                  onClick={() => submitOTP()}
                />
              </div>
              {/* {resentBtnHide === false ? ( */}
              <div className="d-flex justify-between">
                {minutes === 0 && seconds === 0 ? (
                  <span
                    onClick={() => resendOtp()}
                    className="text-light-blue font-14 font-bold cursor"
                  >
                    Resend OTP
                  </span>
                ) : null}

                {minutes === 0 && seconds === 0 ? null : (
                  <span className="text-light-blue font-14 font-bold cursor">
                    {minutes < 10 ? "0" + minutes : minutes}:{" "}
                    {seconds < 10 ? "0" + seconds : seconds}
                  </span>
                )}
              </div>
              {/* ) : null} */}

              <div className="">
                <span
                  onClick={() => history("/")}
                  className="text-light-blue font-14 font-bold cursor"
                >
                  Change username or password?
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
          id="liveToast"
        >
          <SignInToast
            position={"bottom-center"}
            showToast={showToast}
            toggleShowToast={toggleShowToast}
            warningMsg={message}
            successMsg={message}
            imageType={"warning"}
          />
        </div>
      </div>
    </div>
  );
};

export default OtpScr;
