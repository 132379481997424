/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import { useNavigate } from "react-router-dom";
import UpdatePartner from "../../components/Partner/UpdatePartner";
import { useDispatch } from "react-redux";
import { getAllCountriesList } from "../../Redux/Countries/countryAction";
import { fetchPartnerCategory } from "../../Redux/Partner/partnerAction";
import { useTranslation } from "react-i18next";

const EditPartner = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCountriesList());
    dispatch(fetchPartnerCategory());
  }, []);


  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon className="cursor" onClick={() => history(-1)} />
          <span className="font-bold font-32 my-2">{t("Edit partner")}</span>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-start gap-2 width-65">
        <UpdatePartner lang={props?.lang} />
      </div>
    </div>
  );
};

export default EditPartner;