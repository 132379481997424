import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ClientMapping from "../../components/Client/ClientMapping";
import Button from "../../components/Button/Button";

const ClientsDashboard = () => {
  const history = useNavigate();

  const goToCreateClient = () => {
    history("/patner-creation");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">Client mapping</span>
        </div>
        <div className="mb-3">
          <Button
            type="primary"
            text="+ ADD A CLIENT"
            onClick={() => goToCreateClient()}
          />
        </div>
      </div>
      <div className="d-flex flex-column justify-content-start gap-2">
        <ClientMapping />
      </div>
    </div>
  );
};

export default ClientsDashboard;
