import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as Tag } from "../../../assests/svg/Tag/tag.svg";
import { ReactComponent as BlueTag } from "../../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import Button from "../../../components/Button/Button";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import DragandDrop from "../../../components/DragandDrop/DragandDrop";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { putBrandCategory } from "../../../Redux/BrandCategories/brandCategoryAction";
import ReactSwitchButton from "../../../components/Button/ReactSwitchButton";
import { getAllLanguage } from "../../../Redux/Language/languageAction";
import { ReactComponent as CloseIcone } from "../../../assests/svg/close.svg";
import Toasts from "../../../components/Toasts/Toasts";
import PulseLoader from "../../../components/Loader/PulseLoader";
import { useTranslation } from "react-i18next";

const AddCategory = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const inputElementCatname = useRef(null);

  const productCreation = location.state?.productCreation;

  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);

  const Preferredlanguage = useSelector((state) => state?.languages?.languages);
  const loader = useSelector((state) => state?.brandCategories?.loading);

  let defaultLang = localStorage.getItem("defaultLang");

  const constProdTitle = {
    category_name: "",
    language_id: JSON.parse(defaultLang),
  };

  const [prodTitles, setProdTitles] = useState([constProdTitle]);

  const [cardImg, setCardImg] = useState([]);
  const [activeFlag, setActiveFlag] = useState(true);

  const [error, setError] = useState({
    category_name: "",
    brandType: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllLanguage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Remove Selected Language title input field
  const handleRemoveClickProdTitle = (index) => {
    const list = [...prodTitles];
    list.splice(index, 1);
    setProdTitles(list);
  };

  // Getting Brand Category title value from input field
  const handleInputChangeProdTitle = (e) => {
    const { name, value } = e.target;
    let arr = [...prodTitles];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (name === item.language_id.language_code) {
        arr[i].category_name = value;
      }
    });
    setProdTitles(arr);
  };

  // Brand category title dropdown
  const handleAddClickProdTitle = (e) => {
    if (
      prodTitles.some(
        (item) => item.language_id.language_code === e.language_code
      )
    ) {
      // alert("Already added");
      toggleShowToast();
      return;
    } else {
      setProdTitles([...prodTitles, { category_name: "", language_id: e }]);
    }
  };

  const validate = () => {
    if (!prodTitles[0].category_name) {
      setError({ category_name: "Please enter categoryname" });
      if (inputElementCatname.current) {
        inputElementCatname.current.focus();
      }
      return false;
    }

    return true;
  };

  const activeHandle = () => {
    setActiveFlag(!activeFlag);
  };

  const submit = () => {
    let obj = {
      categoryName: prodTitles,
      activateFlag: activeFlag,
      categoryImage: cardImg,
    };
    let val = validate();
    if (val) {
      dispatch(putBrandCategory(obj, goToSuccessfull));
    }
  };

  const goToSuccessfull = () => {
    productCreation ? history(-1) : history("/category-create-success");
  };

  return (
    <>
      {loader === false ? (
        <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row align-items-center pb-2 gap-3">
              <LeftIcon onClick={() => history(-1)} className="cursor" />
              <span className="font-bold font-32 my-2">
                {t("Add Brand Category")}
              </span>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between gap-2">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex gap-3">
                  <Tag />
                  <span className="font-20 font-bold">
                    {t("Brand Category")}
                  </span>
                </div>
              </div>

              <div className="d-flex flex-column gap-4 mt-4 ">
                <div className="d-flex flex-column gap-3">
                  {prodTitles.map((item, index) => (
                    <div className="d-flex flex-column gap-3" key={index}>
                      <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                        <div className="d-flex flex-row align-items-center gap-2">
                          <span className="font-bold font-14">
                            {t("Brand category name")} #{index + 1}{" "}
                            {item.language_id.language}
                            <span className="text-danger">*</span>
                          </span>
                          <InfoTooltip title={"Enter Brand category name"} />
                        </div>
                        {prodTitles.length !== 1 && (
                          <div
                            className="cursor"
                            onClick={() => handleRemoveClickProdTitle(index)}
                          >
                            {index !== 0 && <CloseIcone />}
                          </div>
                        )}
                      </div>

                      <div className="d-flex flex-column gap-1">
                        <InputField
                          inputRef={inputElementCatname}
                          onChange={handleInputChangeProdTitle}
                          name={item.language_id.language_code}
                          placeholder={`${t(
                            "Brand category name"
                          )} ${item.language_id.language.toLocaleLowerCase()}`}
                          type="text"
                          value={item.category_name}
                        />
                        {!item.category_name && error.category_name ? (
                          <label className="text-error">
                            {error.category_name}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="d-flex ">
                  <div className="btn-group w-100">
                    <div
                      className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
                      data-bs-toggle="dropdown"
                    >
                      <span>
                        {t("Add other language for brand category title")}
                      </span>
                    </div>
                    <ul
                      className={`dropdown-menu rounded-card-7 dropdown-menu-start ${
                        props?.lang === "ar" ? "dropdown-menu-ar" : ""
                      }`}
                    >
                      {Preferredlanguage &&
                        Preferredlanguage.map((item, i) => (
                          <li
                            key={i}
                            onClick={() => handleAddClickProdTitle(item)}
                          >
                            <div className="dropdown-item cursor">
                              {item.language}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">{t("Active")}?</span>
                    <InfoTooltip title={"Active/Inactive"} />
                  </div>
                  <ReactSwitchButton
                    onChange={() => activeHandle()}
                    value={activeFlag}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-between gap-2">
            <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 gap-4 mb-1 px-4 width-65 ">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex gap-3">
                  <BlueTag />
                  <span className="font-20 font-bold">
                    {t("Category image")}
                  </span>
                </div>
              </div>

              <div className="d-flex flex-column gap-3 mt-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Upload images")}
                    </span>
                    <InfoTooltip title={"Upload image for Category"} />
                  </div>
                  <div className="d-flex flex-column">
                    <DragandDrop files={cardImg} setFiles={setCardImg} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <Button
              onClick={() => submit()}
              text={t("Create")}
              type="primary"
            />
          </div>
          <div
            className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg="Allready Added."
              imageType={"warning"}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default AddCategory;
