import React, { useEffect } from "react";
import DetailsReport from "../../components/ReportsAnalytics/DetailsReport";
import GrossIncome from "../../components/ReportsAnalytics/GrossIncome";
import Transactions from "../../components/ReportsAnalytics/Transactions";

const ReportDetails = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="d-flex flex-column justify-content-start py-4 px-4 bg-light">
        <div className="d-flex align-items-center gap-3 py-2">
          <span className="font-40 font-bold mb-2">Report details</span>
        </div>

        <div className="d-flex flex-column gap-2">

        <div className="d-flex">
        <DetailsReport />
      </div>
      <div className="d-flex">
        <GrossIncome />
      </div>

      <div className="d-flex">
        <Transactions />
      </div>
      </div>

    </div>
  );
};

export default ReportDetails;
