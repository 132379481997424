import React,{ useState } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/blue_tag.svg";
import Dropdown from "../Dropdown/Dropdown";
import InfoTooltip from "../Tooltip/InfoTooltip";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import ReactDatePicker from "../DatePicker/ReactDatePicker";

const GeneraterReports = () => {

  const history = useNavigate();
  const [startDate, setStartDate] = useState(new Date());

  const goToReportDetails= () => {
    history("/reports/report-details");
  };
 
  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 gap-2 w-100">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex py-3 gap-3">
          <Tag />
          <span className="font-20 font-bold">Generate Reports</span>
        </div>
      </div>


      <div className="d-flex flex-column pt-4 mb-3 gap-2">
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="font-bold font-14">
            Genrate report for<span className="text-danger">*</span>
          </span>
          <InfoTooltip
            title={"Maximum 100 characters. No HTML or emoji allowed"}
          />
        </div>
        <Dropdown />
      </div>

      <div className="d-flex flex-column gap-2">
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="font-bold font-14">
            Select Transaction type<span className="text-danger">*</span>
          </span>
          <InfoTooltip
            title={"Maximum 100 characters. No HTML or emoji allowed"}
          />
        </div>
        <Dropdown />
      </div>

      <div className="d-flex flex-column pt-4 mb-3  gap-2 ">
                    <div className="d-flex flex-row align-items-center">
                        <span className="font-bold font-14">From : To</span>
                    </div>
    
              <ReactDatePicker
            selected={startDate}
            onChange={setStartDate}
            minDate={new Date()}
            className="datepicker-style-without-border"
          />
              <div>

              </div>
                    </div>

      <div className="d-flex flex-row justify-content-between align-items-center mt-2 gap-2">
        <div className="d-flex">
          <Button
            onClick={() => goToReportDetails()}
            text="Submit"
            type="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default GeneraterReports;
