/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import Button from "../../../components/Button/Button";
import { fetchBrand } from "../../../Redux/Brands/brandAction";
import { getAllCountriesList } from "../../../Redux/Countries/countryAction";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import InputGroupTxt from "../../../components/InputField/InputGroupTxt";
import { SetDistributionOfMerchandiseData } from "../../../Redux/CreateOrder/CreateOrderAction";
import {
  getMerchandiseVariantList,
  getMerchandiseVariantPrice,
} from "../../../Redux/MerchadiseAllProduct/MerchadiseAllProductAction";
import { fetchClientMerchandiseData } from "../../../Redux/CreateOrder/CreateOrderAction";
import ModalComp from "../../../components/Models/ModalComp";
import Toasts from "../../../components/Toasts/Toasts";
import ModelForMerchandiseSummery from "../../../components/Models/ModelForMerchandiseSummery";
import { getMerchandiseSummeryById } from "../../../Redux/Merchandise/merchandiesAction";
import prodcutDefaultImg from "../../../assests/img/Product-default.png";
import { useTranslation } from "react-i18next";

const MerchandiseOrder = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const inputElementProduct = useRef(null);
  const inputElementVarienProduct = useRef(null);
  const inputElementQuantity = useRef(null);
  const inputElementAddress1 = useRef(null);
  const inputElementEmail = useRef(null);

  // MErchandise data get on merchandise id
  const merchandiseDistData = useSelector(
    (state) => state?.createOrder?.distributionOfMerchandise
  );

  // data reload after user come back on this page

  const clientMapMerchandiseList = useSelector(
    (state) => state.clientMappedMerchandise.clientMappedMerchandise
  );

  const SelectedClient = useSelector(
    (state) => state.clientMappedMerchandise.distMerSelectedClientData
  );

  // for model
  const merchandiseSummery = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseSummery
  );
  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState([]);

  const [variants, setVariants] = useState([]);

  const [fields, setFields] = useState({
    selProduct: "",
    quantity: "",
    organization: "",
    deliveryType: "",
    deliveryMode: "",
    deliveryAdd: "",
    emailid: location?.state?.selectedClient
      ? location?.state?.selectedClient?.email
      : "",
    phoneNumber: location?.state?.selectedClient
      ? location?.state?.selectedClient?.contact_no
      : "",
    cityName: "",
    pincode: "",
    varinats: "",
    deliveryAddressline1: location?.state?.selectedClient
      ? location?.state?.selectedClient?.address
      : "",
    deliveryAddressline2: "",
  });

  const [error, setError] = useState({
    selProduct: "",
    quantity: "",
    countryCurrency: "",
    brand: "",
    organization: "",
    deliveryType: "",
    deliveryAddressline1: "",
    deliveryAddressline2: "",
    deliveryMode: "",
    deliveryAdd: "",
    emailid: "",
    phoneNumber: "",
    pincode: "",
    varinats: "",
    deliveryOptions: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = () => setShowToast(!showToast);

  function handleChange(e) {
    const value = e.target.value;

    Object.keys(fields).map((item) => {
      if (e.target.name === item) {
        let regex = /^\d{0,15}?$/;
        if (regex.test(e.target.value)) {
          setFields({ ...fields, [e.target.name]: value });
        }
      } else if (e.target.name !== "phoneNumber") {
        setFields({
          ...fields,
          [e.target.name]: value,
        });
      }
    });
  }

  const emailHandler = (e) => {
    const value = e.target.value;
    setFields({ ...fields, [e.target.name]: value });
    setError({ ...error, emailid: "" });
  };

  //for drop down
  const handleChangeQuantity = (e) => {
    const value = e.target.value;
    let regex = /^\d{0,2}(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      setFields({ ...fields, [e.target.name]: value });
    }
  };
  const getMerchandiseProductByIdData = (data) => {
    setSelectedProduct(data);
  };

  // product title trim
  const trimIdHandler = (item) => {
    let id = item;

    if(id.length > 20){
      let val = id.slice(0, 20);
      return `${val}...`;
    }
    else{
      return id;
    }

  };

  useEffect(() => {
    merchandiseDistData &&
      setFields({ ...fields, quantity: merchandiseDistData?.quantity });
  }, [merchandiseDistData]);

  useEffect(() => {
    if (selectedProduct) {
      setVariants(selectedProduct[0]?.variants);
      setNoVarianPrice({});
    }
  }, [selectedProduct]);

  // Setting currency or brand in object
  useEffect(() => {
    setFields({
      ...fields,
      emailid: location.state.selectedClient.email,
      phoneNumber: location.state.selectedClient.contact_no,
      deliveryAddressline1: location.state.selectedClient.address,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state.selectedClient]);

  useEffect(() => {
    merchandiseDistData?.product &&
      clientMapMerchandiseList &&
      clientMapMerchandiseList.map((item) => {
        if (
          item?.merchandise?.id ===
          merchandiseDistData?.product?.merchandise?.id
        ) {
          setFields({ ...fields, selProduct: item });
          if (item?.merchandise?.id !== "undefined") {
            dispatch(
              fetchClientMerchandiseData(
                item?.merchandise?.id,
                getMerchandiseProductByIdData
              )
            );
          }
        }
      });
  }, [merchandiseDistData, clientMapMerchandiseList]);

  // {product handler}
  const handleChangeProduct = (e) => {
    setFields({ ...fields, selProduct: e, varinats: "" });

    if (e?.merchandise?.id !== "undefined") {
      dispatch(
        fetchClientMerchandiseData(
          e?.merchandise?.id,
          getMerchandiseProductByIdData
        )
      );
    }

    // let arr = [fields];
    // if (e && e.product_class === "merchandise_variant") {
    //   clientMapMerchandiseList.map((item, i) => {
    //     if (item.product_class === "merchandise_variant") {
    //       let obj = {};
    //       setVariants(item.merchandise_variant);
    //     }
    //   });
    // }
  };

  const [noVarianPrice, setNoVarianPrice] = useState({});

  const getVarianPriceObj = (data) => {
    setNoVarianPrice(data);
  };

  // Merchandise Variant price
  const handleChangeProductVariants = (e) => {
    setFields({ ...fields, varinats: e });
    setNoVarianPrice({});
    e &&
      dispatch(
        getMerchandiseVariantPrice(
          fields?.selProduct?.merchandise?.id,
          e?.id,
          SelectedClient?.id,
          getVarianPriceObj
        )
      );
  };

  //for validations
  const validate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!fields.selProduct) {
      setError({ selProduct: "Please select product" });
      if(inputElementProduct.current){
        inputElementProduct.current.focus()
      }
      return false;
    } else if (
      fields?.selProduct?.merchandise &&
      !fields.varinats &&
      fields?.selProduct?.product_class === "merchandise_variant"
    ) {
      setError({ varinats: "Please select product" });
      if(inputElementVarienProduct.current){
        inputElementVarienProduct.current.focus()
      }
      return false;
    } else if (!fields.quantity) {
      setError({ quantity: "Please select quantity" });
      if(inputElementQuantity.current){
        inputElementQuantity.current.focus()
      }
      return false;
    } else if (!fields.deliveryAddressline1) {
      setError({ deliveryAddressline1: "Please enter address" });
      if(inputElementAddress1.current){
        inputElementAddress1.current.focus()
      }
      return;
    } else if (!fields.emailid) {
      setError({ emailid: "Please enter email" });
      if(inputElementEmail.current){
        inputElementEmail.current.focus()
      }
      return;
    } else if (emailRegex.test(fields.emailid) === false) {
      setError({ emailid: "Enter valid email" });
      return false;
    }

    return true;
  };

  const submit = () => {
    let val = validate();

    if (val) {
      let merchandiseCreateOrderObj = {
        product: fields.selProduct,
        varinats: fields.varinats ? fields.varinats : "",
        quantity: fields.quantity,
        countryCurrency: selectedProduct[0]?.country
          ? selectedProduct[0]?.country
          : "",
        brand: selectedProduct[0]?.brand ? selectedProduct[0]?.brand : "",
        deliveryType: fields.deliveryType.name
          ? fields.deliveryType.name
          : "Physical",
        deliveryAddressline1: fields.deliveryAddressline1,
        deliveryAddressline2: fields.deliveryAddressline2,
        pincode: fields.pincode,
        cityName: fields.cityName,
        emailid: fields.emailid,
        phoneNumber: fields.phoneNumber,
        selectedClient: location.state.selectedClient,
        selectedProduct: selectedProduct[0],
      };

      if (selectedProduct && selectedProduct[0]?.availability === null) {
        setToastMessage(" Selected product is out of stock");
        toggleShowToast();
        return false;
      }

      if (noVarianPrice?.noVariant === "variant_not_available") {
        setToastMessage(
          "The product variant selects is not mapped to the client, please map this product before placing the order!"
        );
        toggleShowToast();
      } else {
        dispatch(SetDistributionOfMerchandiseData(merchandiseCreateOrderObj));
        history("/merchandise/order-summary");
      }
    } else {
      setToastMessage("Mandatory fields are required.");
      toggleShowToast();
    }
  };
  // If product has variants then this function will execute
  const VariantsList = () => {
    let variant = [];

    clientMapMerchandiseList.map((item) => {
      if (item?.merchandise?.product_variants_flag === true) {
        // item.variants.map((vari) => {
        //   let obj = {
        //     id: vari.id,
        //     variantValues: vari.variant_values.join("|"),
        //   };
        //   variant.push(obj);
        // });
      }
    });
    setVariants(variant);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchBrand());
    dispatch(getAllCountriesList());
    dispatch(getMerchandiseVariantList());
    // dispatch(getAllOrganizationList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!clientMapMerchandiseList) {
      history("/merchandise-distribution");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientMapMerchandiseList]);

  const showModel = (id) => {
    dispatch(getMerchandiseSummeryById(id));
    setShowModal(true);
  };

  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-3 px-4 bg-light">
      <div className="d-flex flex-row align-items-center gap-3">
        <LeftIcon onClick={() => history(-1)} className="cursor" />
        <span className="font-bold font-32 my-2">{t("Merchandise Order")}</span>
      </div>

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
        <div className="d-flex flex-column gap-4 mt-4 width-65 ">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Select product")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip title={"Select product"} />
            </div>
            <div className="">
              <Select
                ref={inputElementProduct}
                noOptionsMessage={() => "Not found"}
                placeholder={t("Select or type product name")}
                getOptionLabel={(clientMapMerchandiseList) =>
                  clientMapMerchandiseList?.merchandise?.default_title
                }
                options={clientMapMerchandiseList}
                isSearchable
                value={fields.selProduct}
                onChange={handleChangeProduct}
                isClearable
                styles={CUSTOM_DROPDOWN_STYLE}
              />
              {!fields.selProduct && error.selProduct ? (
                <label className="text-error">{error.selProduct}</label>
              ) : null}
            </div>
          </div>

          {fields?.selProduct && fields?.selProduct ? (
            <div className="d-flex">
              <div className=" d-flex flex-column justify-content-between rounded-3 mb-3 gap-3 w-100">
                <table responsive="xl">
                  <tbody>
                    <tr className="hover-row ">
                      <td>
                        <div className="d-flex flex-row justify-content-start align-items-center gap-2 py-2">
                          <span className="mx-2 rounded-3">
                            <img
                              src={
                                selectedProduct[0]?.images &&
                                selectedProduct[0]?.images[0]
                                  ? selectedProduct[0]?.images[0].file_url
                                  : prodcutDefaultImg
                              }
                              alt="not found"
                              id="product-thumbnail"
                              // className="img-fluid "
                              // width="60px"
                              // height="50px"
                            />
                          </span>
                          <div className="d-flex flex-column justify-content-center gap-2 ">
                            <span
                              className="font-16 font-boldest text-dark "
                              title={fields?.selProduct?.merchandise && fields?.selProduct?.merchandise?.default_title}
                              onClick={() =>
                                showModel(fields?.selProduct?.merchandise?.id)
                              }
                            >
                              {fields?.selProduct?.merchandise &&
                                trimIdHandler(
                                  fields?.selProduct?.merchandise?.default_title
                                )}
                                
                            </span>
                            <span className="font-13 font-bold text-muted">
                              {/* by Myntra Fashion */}
                              {fields?.selProduct?.merchandise &&
                                fields?.selProduct?.merchandise?.id}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center ">
                          <span className="font-15 font-bold text-success bg-04 px-1 py-1 gap-2 rounded-3">
                            {selectedProduct[0]?.country.currency &&
                              `${selectedProduct[0]?.country.currency} - (${selectedProduct[0]?.country?.currency_symbol})`}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center ">
                          {selectedProduct[0]?.availability === "in_stock" ? (
                            <span className="font-15 font-bold text-success bg-04 px-1 py-1 gap-2 rounded-3">
                              {t("In stock")}
                            </span>
                          ) : (
                            <span className="font-15 font-bold text-white bg-04 bg-danger px-1 py-1 gap-2 rounded-3">
                              {t("Out of stock")}
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}

          {fields?.selProduct?.merchandise &&
          fields?.selProduct?.product_class === "merchandise_variant" ? (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Select product variant")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <div className="">
                <Select
                ref={inputElementVarienProduct}
                  noOptionsMessage={() => "Not found"}
                  placeholder={t("Select variant")}
                  getOptionLabel={(variants) => variants?.variant_values}
                  options={variants}
                  isSearchable
                  value={fields.varinats}
                  onChange={handleChangeProductVariants}
                  isClearable
                  styles={CUSTOM_DROPDOWN_STYLE}
                />
                {!fields.varinats && error.varinats ? (
                  <label className="text-error">{error.varinats}</label>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center">
              <span className="font-bold font-14">
                {t("Quantity")}
                <span className="text-danger">*</span>
              </span>
              <InfoTooltip title={"Enter product Quantity"} />
            </div>
            <div className="d-flex flex-column gap-1">
              <InputField
              inputRef={inputElementQuantity}
                type="text"
                placeholder={t("Enter quantity")}
                name="quantity"
                value={fields.quantity}
                onChange={handleChangeQuantity}
              />
              {!fields?.quantity && error?.quantity ? (
                <label className="text-error">{error.quantity}</label>
              ) : null}
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-4 mt-4 width-65 ">
          {selectedProduct[0]?.country &&
          selectedProduct[0]?.country.currency ? (
            <div className="d-flex flex-column gap-4  ">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Country & Currency")}
                  </span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <InputField
                  placeholder={t("Enter country")}
                  name="CountryName"
                  value={
                    selectedProduct[0]?.country?.name +
                    " - " +
                    selectedProduct[0]?.country.currency
                  }
                  onChange={handleChange}
                  disabled
                />
              </div>
            </div>
          ) : null}
          {selectedProduct[0]?.brand && selectedProduct[0]?.brand ? (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Brand")}</span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField
                placeholder={t("Enter country")}
                name="brand"
                value={selectedProduct[0]?.brand?.default_name}
                onChange={handleChange}
                disabled
              />
            </div>
          ) : null}
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Delivery Address line 1")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Enter Delivery Address line 1"} />
              </div>
              <div className="d-flex flex-column gap-1">
                <InputField
                inputRef={inputElementAddress1}
                  placeholder={t("Enter delivery address line 1")}
                  name="deliveryAddressline1"
                  value={fields.deliveryAddressline1}
                  onChange={handleChange}
                />
                {!fields.deliveryAddressline1 && error.deliveryAddressline1 ? (
                  <label className="text-error">
                    {error.deliveryAddressline1}
                  </label>
                ) : null}
              </div>
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Delivery Address line 2")}
                </span>
                <InfoTooltip title={"Enter Delivery Address line 2"} />
              </div>
              <div className="d-flex flex-column gap-1">
                <InputField
                  placeholder={t("Enter delivery address line 2")}
                  name="deliveryAddressline2"
                  value={fields?.deliveryAddressline2}
                  onChange={handleChange}
                />
                {!fields?.deliveryAddressline2 &&
                error?.deliveryAddressline2 ? (
                  <label className="text-error">
                    {error?.deliveryAddressline2}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Pin code")}</span>
                <InfoTooltip title={"Enter pin code"} />
              </div>
              <InputField
                placeholder={t("Enter pincode")}
                name="pincode"
                value={fields.pincode}
                onChange={handleChange}
              />
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("City name")}</span>
                <InfoTooltip title={"Enter City name"} />
              </div>
              <InputField
                placeholder={t("Enter city name")}
                type="text"
                name="cityName"
                value={fields.cityName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-3  ">
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Email ID")}
                  <span className="text-danger">*</span>
                </span>
                <InfoTooltip title={"Enter Email ID"} />
              </div>
              <InputField
              inputRef={inputElementEmail}
                placeholder={t("Enter email ID")}
                type="email"
                name="emailid"
                value={fields.emailid}
                onChange={emailHandler}
              />
              {error.emailid ? (
                <label className="text-error">{error.emailid}</label>
              ) : null}
            </div>

            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Phone number")}
                {/* <span className="text-danger">*</span> */}
              </span>
              <InfoTooltip title={"Enter Phone number"} />
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
              <InputGroupTxt
                title={
                  fields?.countryCurrency?.phone_code
                    ? "+" + fields?.countryCurrency?.phone_code
                    : ""
                }
                placeholder={t("Enter store phone number")}
                type="number"
                name="phoneNumber"
                lang={props?.lang}
                value={fields.phoneNumber}
                maxLength={15}
                onChange={handleChange}
              />
              <div>
                <div className="d-flex flex-row justify-content-start text-dark gap-1 align-items-center">
                  <span>
                    <input
                      className="form-check-input cursor"
                      type="checkbox"
                      id="checkboxNoLabel"
                      // checked={products.filter(product => product?.isChecked !== true).length < 1}
                      // onChange={allCheckedHandle}
                    />
                  </span>

                  <span className="font-16 font-boldest text-muted">
                    {t("WhatsApp")}
                  </span>

                  <span>
                    <input
                      className="form-check-input cursor"
                      type="checkbox"
                      id="checkboxNoLabel"
                      // checked={products.filter(product => product?.isChecked !== true).length < 1}
                      // onChange={allCheckedHandle}
                    />
                  </span>

                  <span className="font-16 font-boldest text-muted">
                    {t("SMS")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <Button onClick={submit} text={t("Create Order")} type="primary" />
      </div>

      <ModalComp
        size="lg"
        showModal={showModal}
        // handleClose={handleClose}
        component={
          <ModelForMerchandiseSummery
            handleClose={handleClose}
            merchandiseSummery={merchandiseSummery}
          />
        }
      />
      <div
        className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg={toastMessage}
          imageType={"warning"}
        />
      </div>
      {/* {SelectedMerchandiseLoading === true && (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )} */}
    </div>
  );
};

export default MerchandiseOrder;
