import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import ChelsieHaley from "../../assests/img/ChelsieHaley.png";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import ReactSwitchButton from "../../components/Button/ReactSwitchButton";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import Select from "react-select";



const Profile = () => {
  const history = useNavigate();
  
  const [fields, setFields] = useState({
    userName: "",
    email: "",
    name: "",
    password: "",
  });

  const [roles, setRoles] = useState({role: ""});

  const [passShowHide, setPassShowHide] = useState(false);

  const [activeFlag, setActiveFlag] = useState({activeFlag : true});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addRoles = () => {
    history("");
  };
  ///users-management/roles

  const activeHandle = () => {
    setActiveFlag(!activeFlag);
  };

  const changeHandler = (e) => {
    const value = e.target.value;
    setFields({ ...fields, [e.target.name]: value });
  };

  const handleChangeRole = (e) => {
    setRoles({ ...roles, role:e});
  };

  const togglePassword = () => {
    setPassShowHide((prevState) => !prevState);
  };
  

  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-3 px-4 bg-light">
      <div className="d-flex">
        <span className="font-40 font-bold mb-2">Edit profile</span>
      </div>

      <div className="width-70 ">

   <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-5">
       <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <span>
            <img
              src={ChelsieHaley}
              alt="not found"
              className="img-fluid width-75"
            />
          </span>
          <div>
    <span className="font-15 font-boldest text-dark ">Samsung Haley</span><br/>
    <span className="font-15 font-bolder text-muted">@username</span>
    </div>
        </div>

      </div>

      <div className="d-flex flex-column gap-4 mt-4 w-100 ">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
                Change user name<span className="text-danger">*</span>
                </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <InputField placeholder={"Enter user name"} onChange={(e) => changeHandler(e)}/>
        </div>

        <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  Change Email<span className="text-danger">*</span>
                  <span className="font-bold font-14 text-muted">
                    (This will also be used for OTP Authentication)
                  </span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <InputField placeholder={"Enter email ID"} />
            </div>

        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">Name
            <span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <InputField placeholder={"Enter user name"} />
        </div>

        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Password<span className="text-danger">*</span>
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <div className="input-group  position-relative">
            <InputField
              type={passShowHide ? "text" : "password"}
              placeholder={"Example Sam.01@123"}
              name="password"
              value={fields.password}
              onChange={changeHandler}
            />
            <div className="input-group-append position-absolute position-absolute top-0 end-0">
              <button
                className="btn  bg-tranparent "
                type="button"
                onClick={togglePassword}
              >
                {passShowHide ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
            </div>
          </div>
          {/* {error.password ? (
            <label className="text-error">{error.password}</label>
          ) : null} */} 
        </div>

         <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">Role<span className="text-danger">*</span></span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <div>
            <Select
              noOptionsMessage={() => "Not found"}
              placeholder="Select role"
              // getOptionLabel={(giftcardtype) => giftcardtype.type_name}
              // options={giftcardtype}
              isClearable
              styles={CUSTOM_DROPDOWN_STYLE}
              value={roles.role}
              onChange={handleChangeRole}
            />
            {/* {!roles.rolee && error.role ? (
              <label className="text-error">{error.role}</label>
            ) : null} */}
          </div>
        </div>

        <div className="d-flex flex-row justify-content-between mb-2 gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-13">
                  Send notifications to user(Email will also be used for OTP
                  authentication)<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Maximum 100 characters. No HTML or emoji allowed"}
                />
              </div>
              <ReactSwitchButton onChange={() => activeHandle()} value={activeFlag} />
            </div>

            <div className="d-flex flex-row gap-3">
          <Button
            onClick={()=>addRoles()}
            text="Update"
            type="primary"
          />
          <Button
            text="Delete User"
            type="square"
          />
        </div>
      </div>
    </div>

      </div>
    </div>
  );
};

export default Profile;
