import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import WarningImg from "../../assests/img/warning.png";


const FloatNotClient = (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const gotToAddFloatPage = () => {
    //     // dispatch(fetchSupplierList());
    //     history("/float/currency-mapping/to-client");
    // }

    const goToNextGiftcard = () => {
        // dispatch(fetchSupplierList());
        history("/supplier-mapping-giftcard-product");
    }

    const gotToAddFloatPage = () => {
        history("/float/currency-mapping/to-client")
    }

    return (

        <div className="d-flex flex-column bg-white px-5 w-100 my-5">
            <div className="d-flex flex-column justify-content-start align-items-center gap-4">
                <div>
                    <img
                        src={WarningImg}
                        alt=""
                    />
                </div>
                <span className="font-bold text-dark font-17">
                    Client have no float balance, Do you want to add float balance?
                </span>
                <div className="d-flex flex-column gap-4">
                    <Button
                        text="Go to Add Float"
                        type={"primary"}
                        onClick={() => gotToAddFloatPage()}
                    />
                    <Button
                        text="Close"
                        type={"secondary"}
                        onClick={props.handleClose}
                    />
                </div>

            </div>
        </div >
    );
};

export default FloatNotClient;