import React from "react";
import Switch from "react-switch";

const ReactSwitchButton = (props) => {
  const { value, onChange, disabled, id } = props;
  return (
    <div className="switch-control">
      <Switch
        checked={value}
        onChange={() => onChange()}
        onColor="#0D6EFD"
        onHandleColor="#ffffff"
        handleDiameter={12}
        uncheckedIcon={false}
        checkedIcon={false}
        height={18}
        width={35}
        className="custom-iOS px-2"
        id={id ? id : "material-switch"}
        disabled={disabled}
      />
    </div>
  );
};

export default ReactSwitchButton;
