import React from 'react'
import nordwoodThemes from "../../assests/img/Signin/nordwood-themes-new.jpeg"
import { useTranslation } from "react-i18next";

const SigninLeftPanel=()=> {
  const {t}=useTranslation();
  return (
    <div className="col imgbox">
    <div className="d-flex flex-column">
        <img
            src={nordwoodThemes}
            alt="not found"
           className='center-fit'
        />
        <div className="d-flex flex-column bg-dark py-4 px-5 gap-3">
            <span className="text-white fs-4 font-22 font-bold">{t("Did you know?")}</span>
            <span className="font-14 shades-grey-dark-00">{t("In Ancient times,around 435 BC, Roman used leaves with engravings as a token which later were called gift cards.")}</span>
        </div>
    </div>
</div>
  )
}

export default SigninLeftPanel