import axios from "axios";
import {
  GET_CLIENT_MAPPED_GCLIST_REQUEST,
  GET_CLIENT_MAPPED_GCLIST_SUCCESS,
  GET_CLIENT_MAPPED_GCLIST_FAILURE,
  GET_CLIENT_MAP_GCLIST_DETAILS_REQUEST,
  GET_CLIENT_MAP_GCLIST_DETAILS_SUCCESS,
  GET_CLIENT_MAP_GCLIST_DETAILS_FAILURE,
  POST_GIFTACRD_MANAUL_IN_COUNT_REQUEST,
  POST_GIFTACRD_MANAUL_IN_COUNT_SUCCESS,
  POST_GIFTACRD_MANAUL_IN_COUNT_FAILURE,
  GET_CLIENT_MAPPED_GCLIST_DELETE,
  DELETE_GIFTCARD_DETAIL,
} from "./ClientGiftcardDistributionType";
import { baseUrl, getToken } from "../../../utils/prodURL";
// to get mapped giftcard list of client
export const getClientGcListRequest = () => {
  return {
    type: GET_CLIENT_MAPPED_GCLIST_REQUEST,
  };
};

export const getClientGcListSuccess = (data) => {
  return {
    type: GET_CLIENT_MAPPED_GCLIST_SUCCESS,
    payload: data,
  };
};

export const getClientGcListFailure = (error) => {
  return {
    type: GET_CLIENT_MAPPED_GCLIST_FAILURE,
    payload: error,
  };
};

export const getClientGcListDelete = () => {
  return {
    type: GET_CLIENT_MAPPED_GCLIST_DELETE,
    payload: [],
  };
};

export const getClientGcList = (id) => {
  return (dispatch) => {
    dispatch(getClientGcListRequest);

    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/client/${id}/mappedProducts`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        // console.log(
        //   "response client mapped Giftcard list" +
        //     JSON.stringify(response.data.result)
        // );
        if (response.data.code === 200) {
          dispatch(getClientGcListSuccess(response.data.result));
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getClientGcListFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const getClientGcListByType = (id, type) => {
  return (dispatch) => {
    dispatch(getClientGcListRequest);

    let productType = type && `?inventory_type=${type}`;
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/client/${id}/mappedProducts${productType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        // console.log(
        //   "response client mapped Giftcard list" +
        //     JSON.stringify(response.data.result)
        // );
        if (response.data.code === 200) {
          dispatch(getClientGcListSuccess(response.data.result));
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getClientGcListFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

export const removeGiftcardDetails = () => {
  return {
    type: DELETE_GIFTCARD_DETAIL,
  };
};

// to get giftcard list details mapped with client
export const getClientGcListDetailsRequest = () => {
  return {
    type: GET_CLIENT_MAP_GCLIST_DETAILS_REQUEST,
  };
};

export const getClientGcListDetailsSuccess = (data) => {
  return {
    type: GET_CLIENT_MAP_GCLIST_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getClientGcListDetailsFailure = (error) => {
  return {
    type: GET_CLIENT_MAP_GCLIST_DETAILS_FAILURE,
    payload: error,
  };
};

export const getClientGcListDetails = (id, getSelecedGiftcard) => {
  return (dispatch) => {
    dispatch(getClientGcListDetailsRequest());
    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/denomination/list?giftcard_ids=${id}&supplier_info=true&brand_info=true`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        // console.log(
        //   "response client mapped Giftcard list details" +
        //     JSON.stringify(response.data.result)
        // );
        if (response.data.code === 200) {
          dispatch(getClientGcListDetailsSuccess(response.data.result));
          getSelecedGiftcard(response.data.result);
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getClientGcListDetailsFailure(
            "Something went wrong!! Please try again!"
          )
        );
      });
  };
};

export const getClientGcDetailList = (
  id,
  skip,
  limit,
  clientmappedGiftcardList
) => {
  return (dispatch) => {
    dispatch(getClientGcListRequest());

    let url =
      skip !== undefined && limit !== undefined
        ? `${baseUrl}/v1/giftcards/client/${id}/mappedProducts?is_detailed=true&skip=${skip}&limit=${limit}`
        : `${baseUrl}/v1/giftcards/client/${id}/mappedProducts?is_detailed=true`;
    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          let data = {
            data: [...clientmappedGiftcardList, ...response.data.result],
             moredata: response.data.next_iteration
           // moredata: false
        }
          dispatch(getClientGcListSuccess(data.data));
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          getClientGcListFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};

// GiftCard Manual Inventory Count
export const postGiftcarManualInvCountRequest = () => {
  return {
    type: POST_GIFTACRD_MANAUL_IN_COUNT_REQUEST,
  };
};

export const postGiftcarManualInvCountSuccess = (data) => {
  return {
    type: POST_GIFTACRD_MANAUL_IN_COUNT_SUCCESS,
    payload: data,
  };
};

export const postGiftcarManualInvCountFailure = (error) => {
  return {
    type: POST_GIFTACRD_MANAUL_IN_COUNT_FAILURE,
    payload: error,
  };
};

export const postGiftCardManualInvCount = (
  giftcardInfo,
  getGiftCardManualInvCount
) => {
  return (dispatch) => {
    dispatch(postGiftcarManualInvCountRequest());

    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcard/manual_inventory/count?giftcard_id=${giftcardInfo?.giftcardId}&denomination_id=${giftcardInfo?.denominationId}&card_status=available`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log("response ", response?.data?.result);
        getGiftCardManualInvCount(response.data.result);
        dispatch(postGiftcarManualInvCountSuccess(response.data.result));
      })
      .catch((error) => {
        dispatch(
          postGiftcarManualInvCountFailure(
            "Something went wrong!! Please try again!"
          )
        );
      });
  };
};
