import {
    GET_ALL_CURRENCIES_REQUEST,
GET_ALL_CURRENCIES_SUCCESS,
GET_ALL_CURRENCIES_FAILURE
  } from "./currencyType";

    const initialCurrenciesState={
        loading: false,
        Currencies: [],
        error: ''
    }

const currenciesReducer = (state = initialCurrenciesState, action) => {

    switch (action.type) {
        case GET_ALL_CURRENCIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_CURRENCIES_SUCCESS:
             return {
                ...state,
                loading: false,
                Currencies: action.payload,
                error: ''

            }
        case GET_ALL_CURRENCIES_FAILURE:
            return {
                ...state,
                loading: false,
                Currencies: [],
                error: action.payload
            }
        default: return state
    }
}
export default currenciesReducer