/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/orange_tag.svg";
import { ReactComponent as CloseIcone } from "../../assests/svg/close.svg";
import { ReactComponent as BlueTag } from "../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as GreenTag } from "../../assests/svg/Tag/green_tag.svg";
import { ReactComponent as DeleteIconWhite } from "../../assests/svg/delete_black.svg";
import TextEditor from "../InputField/TextEditor";
import Button from "../Button/Button";
import InfoTooltip from "../Tooltip/InfoTooltip";
import BackButton from "../Button/BackButton";
import ModalComp from "../Models/ModalComp";
import MerchandiseModelPreview from "../../components/Models/MerchandiseModelPreview";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";
import Toasts from "../Toasts/Toasts";
import { setMerchandiseTermCondition } from "../../Redux/Merchandise/merchandiesAction";
import DragandDropMultiple from "../DragandDrop/DragandDropMultiple";
import ReactSwitchButton from "../Button/ReactSwitchButton";
import InputField from "../InputField/InputField";
import DragandDropVideo from "../DragandDrop/DragandDropVideo";
import DragHandle from "../../assests/img/drag-handle.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

const MerchandiseTermCondition = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputElementTerm = useRef(null);
  const merchandiseState = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseTandC
  );
  const merchandiseCatAttriState = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseCatAtt
  );
  const merchandiseNameDes = useSelector(
    (state) => state?.merchandiseCatAttriData?.merchandiseNameDes
  );

  const Preferredlanguage = useSelector((state) => state?.languages?.languages);

  const [videoFileFlag, setVideoFileFlag] = useState(
    merchandiseState?.videoFileFlag ? merchandiseState?.videoFileFlag : false
  );

  const [videoUrl, setVideoUrl] = useState({
    videoUrl: merchandiseState?.videoUrl ? merchandiseState?.videoUrl : "",
  });

  // for video url error msg
  const [youtubeError, setYoutubeError] = useState("");

  const videoFileHandler = () => {
    setVideoFileFlag((prevState) => !prevState);
  };

  const handleInputChangeState = (e) => {
    const youtubeRegex =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

    if (youtubeRegex.test(e.target.value)) {
      setYoutubeError("");
      setVideoUrl({ videoUrl: e.target.value });
    } else {
      setYoutubeError("Invalid youtube URL");
      setVideoUrl({ videoUrl: e.target.value });
    }
  };

  let defaultLang = localStorage.getItem("defaultLang");
  const edittorTxt = {
    value: EditorState.createEmpty(),
    lang: JSON.parse(defaultLang),
  };
  // for model preview
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [editorState, setEditorState] = useState(
    merchandiseState?.termCondition
      ? merchandiseState?.termCondition
      : [edittorTxt]
  );
  const [error, setError] = useState({
    editorState: "",
    productImg: "",
  });

  const [showToast, setShowToast] = useState(false);

  const [productImg, setProductImg] = useState(
    merchandiseState?.productImage ? merchandiseState?.productImage : []
  );
  const [productVideo, setProductVideo] = useState(
    merchandiseState?.productVideo ? merchandiseState?.productVideo : []
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    merchandiseNameDes?.preferredLanguage &&
      merchandiseNameDes?.preferredLanguage?.language_code !== "EN" &&
      handleDropdownOnClick(merchandiseNameDes?.preferredLanguage);
  }, [merchandiseNameDes?.preferredLanguage]);

  useEffect(() => {
    if (!merchandiseCatAttriState) {
      history("/add-merchandise");
    }
  }, [merchandiseCatAttriState]);

  const toggleShowToast = () => setShowToast(!showToast);

  const getDescription = (txt, code) => {
    let arr = [...editorState];
    setError({ editorState: "" });
    arr.map((item, i) => {
      if (code === item.lang.language_code) {
        arr[i].value = txt;
      }
    });
    setEditorState(arr);
  };

  const handleAddClick = (e) => {
    if (
      editorState.some((item) => item.lang.language_code === e.language_code)
    ) {
      // alert("Already added");
      toggleShowToast();
      return;
    } else {
      setEditorState([
        ...editorState,
        { value: EditorState.createEmpty(), lang: e },
      ]);
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...editorState];
    list.splice(index, 1);
    setEditorState(list);
  };

  const handleDropdownOnClick = (obj) => {
    handleAddClick(obj);
  };
  const validate = () => {
    let value = convertToRaw(editorState[0].value.getCurrentContent());
    if (!productImg.length > 0) {
      setError({ productImg: t("Please upload image") });
      return false;
    } else if (!value.blocks[0].text) {
      setError({ editorState: t("Please provide Terms & Condition") });
      if(inputElementTerm.current){
        inputElementTerm.current.focus()
      }
      return false;
    }
    return true;
  };

  const submit = () => {
    let value = validate();
    if (value) {
      let obj = {
        termCondition: editorState,
        productImage: productImg,
        productVideo: productVideo,
        videoUrl: videoUrl.videoUrl,
        videoFileFlag: videoFileFlag,
      };
      dispatch(setMerchandiseTermCondition(obj));
      handleShow();
    }
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(productImg);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);

    setProductImg(tempData);
  };

  const setMultipleImg = (e) => {
    let arr = [...productImg];
    if (arr.length > 0) {
      let imgArr = [...arr, ...e];
      setProductImg(imgArr);
    } else {
      setProductImg(e);
    }
  };

  const removeProductImg = (e) => {
    let array = [...productImg];
    if (e !== -1) {
      array.splice(e, 1);
      setProductImg(array);
    }
  };

  return (
    <div className="d-flex flex-column gap-2">
      {/* Card Image */}
      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <BlueTag />
            <span className="font-20 font-bold">{t("Product Images")}</span>
          </div>
          <div>
            <BackButton onClick={() => history(-1)} />
          </div>
        </div>

        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-14">
                {t("Product images")}
                <span className="text-danger">*</span>
                <span> {t("(size should be 600*200)")}</span>
              </span>
              <InfoTooltip title={"Upload product image"} />
            </div>
            <div className="d-flex flex-column">
              <DragandDropMultiple
                files={productImg}
                setFiles={setMultipleImg}
              />
              {!productImg.length > 0 && error.productImg ? (
                <label className="text-error">{error.productImg}</label>
              ) : null}
            </div>
            <div>
              {productImg.length > 0 && (
                <div className="d-flex flex-column gap-4 mt-4">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Sort images")}
                      <span className="text-danger">*</span>
                      <span> {t("(size should be 600*200)")}</span>
                    </span>
                    <InfoTooltip
                      title={"Maximum 100 characters. No HTML or emoji allowed"}
                    />
                  </div>
                  <div className="d-flex w-100">
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <table responsive="xl" className="w-100">
                        <Droppable droppableId="droppable-1">
                          {(provider) => (
                            <tbody
                              className="text-capitalize"
                              ref={provider.innerRef}
                              {...provider.droppableProps}
                            >
                              {productImg?.map((user, index) => (
                                <Draggable
                                  key={user.path}
                                  draggableId={user.path}
                                  index={index}
                                >
                                  {(provider) => (
                                    <tr
                                      key={index}
                                      id="tr-sort"
                                      className="d-flex flex-row align-items-center justify-content-between  gap-3 p-2"
                                      {...provider.draggableProps}
                                      ref={provider.innerRef}
                                    >
                                      <td>
                                        <div className="d-flex flex-row align-items-center gap-3">
                                          <span>
                                            <img
                                              src={user.preview}
                                              width={52}
                                              height={52}
                                              alt="Logo Not Found"
                                            />
                                          </span>
                                          <span>
                                            <div className="font-16 font-bolder">
                                              {user.path}
                                            </div>
                                            {/* <div className="text-muted">
                                      {user.size}
                                    </div> */}
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex flex-row justify-content-between align-items-center gap-3">
                                          <div
                                            onClick={() =>
                                              removeProductImg(index)
                                            }
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title="Remove image"
                                          >
                                            <DeleteIconWhite className="cursor" />
                                          </div>
                                          <div {...provider.dragHandleProps}>
                                            <span
                                              className="cursor"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="bottom"
                                              title="Sort image(move up/down)"
                                            >
                                              <img
                                                src={DragHandle}
                                                width={40}
                                                height={40}
                                                alt="Logo Not Found"
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provider.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <Tag />
            <span className="font-20 font-bold">{t("Product Video")}</span>
          </div>
        </div>

        <div className="d-flex flex-column gap-4 mt-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row justify-content-between align-items-centergap-2">
              <span className="font-bold font-14">
                {t("Choose Product Video URL/Video file")}
              </span>
              <ReactSwitchButton
                onChange={() => videoFileHandler()}
                value={videoFileFlag}
                // disabled={videoFileFlag}
              />
            </div>
            {!videoFileFlag && (
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Product video url")}
                  </span>
                  <InfoTooltip title={"Paste product video url"} />
                </div>

                <InputField
                  onChange={handleInputChangeState}
                  name="prodVideo"
                  placeholder={"Product video url"}
                  type="text"
                  // maxLength={TITLE_TEXT_LIMIT}
                  value={videoUrl.videoUrl}
                />
                {youtubeError ? (
                  <label className="text-error">{youtubeError}</label>
                ) : null}
                {!youtubeError && videoUrl.videoUrl ? (
                  <div className="w-100 h-auto">
                    <ReactPlayer
                      url={videoUrl.videoUrl}
                      className="video"
                      controls
                      width="580px"
                      height="360px"
                    />
                  </div>
                ) : null}
              </div>
            )}
            {videoFileFlag && (
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Product video")}
                    <span> {t("(size should be 100MB)")}</span>
                  </span>
                  <InfoTooltip title={"Upload product video"} />
                </div>
                <DragandDropVideo
                  files={productVideo}
                  setFiles={setProductVideo}
                  fileType=".mp4"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column bg-white shadow-sm position-relative rounded-3 py-4 px-4 w-100 gap-4">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex gap-3">
            <GreenTag />
            <span className="font-20 font-bold">{t("Terms & Condition")}</span>
          </div>
        </div>

        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              {/*  <span className="font-bold font-14">Message to reviewer</span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />*/}
            </div>
            {editorState.map((item, i) => (
              <div className="d-flex flex-column gap-3" key={i}>
                <div className="d-flex flex-row justify-content-between align-items-center gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Terms & Condition")} #{i + 1} {item.lang.language}
                      <span className="text-danger">{i === 0 ? "*" : ""}</span>
                    </span>
                    {/* <InfoTooltip title={"Maximum 100 characters. No HTML or emoji allowed"} /> */}
                  </div>
                  {editorState.length !== 1 && (
                    <div
                      className="cursor"
                      onClick={() => handleRemoveClick(i)}
                    >
                      {i !== 0 && <CloseIcone />}
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column gap-1">
                  <TextEditor
                    inputRef={inputElementTerm}
                    code={item.lang.language_code}
                    editorState={item.value}
                    getDescription={getDescription}
                    lang={props?.lang}
                  />
                  {error.editorState && i === 0 ? (
                    <label className="text-error">{error.editorState}</label>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex">
          <div className="btn-group w-100">
            <div
              className="btn border border-2 rounded-card-7 dropdown-toggle d-flex justify-content-between align-items-center py-2"
              data-bs-toggle="dropdown"
            >
              <span>{t("Add other language Terms & Condition")}</span>
            </div>
            <ul
              className={`dropdown-menu rounded-card-7 dropdown-menu-start ${
                props?.lang === "ar" ? "dropdown-menu-ar" : ""
              }`}
            >
              {Preferredlanguage &&
                Preferredlanguage.map((item, i) => (
                  <li key={i} onClick={() => handleDropdownOnClick(item)}>
                    <div className="dropdown-item cursor">{item.language}</div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="d-flex">
          <Button
            onClick={() => submit()}
            text={t("Save & Preview")}
            type="primary"
          />
        </div>
      </div>

      <ModalComp
        size="lg"
        showModal={showModal}
        // handleClose={handleClose}
        component={<MerchandiseModelPreview handleClose={handleClose} />}
      />

      <div
        className="position-fixed bottom-0 start-50 translate-middle-x  w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Allready Added."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default MerchandiseTermCondition;
