import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import PulseLoader from "../../../components/Loader/PulseLoader";
import Stores from "../../../components/Store/Stores";
import { getStoreList } from "../../../Redux/CreateStore/createStoreAction";

const StoreDashboard = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const createStore = useSelector((state) => state.createStore?.createStore);
  const loading = useSelector((state) => state.createStore?.loading);

  useEffect(() => {
    window.scrollTo(0, 0);
    // if(createStore.length===0){
    //   dispatch(getStoreList());
    // }
  }, []);

  const creatNewstore = () => {
    history("/store-creation");
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">Store Dashboard</span>
        </div>
        <div>
          <Button
            type="primary"
            text="+Create Store"
            onClick={() => creatNewstore()}
          />
        </div>
      </div>
      <div className="d-flex ">
        <Stores createStore={createStore} />
      </div>
      {/* {loading === false ? (
        <div className="d-flex ">
          <Stores createStore={createStore}/>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center"><PulseLoader type={"data"} /></h5>
        </div>
      )}*/}
    </div>
  );
};

export default StoreDashboard;
