import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../../assests/svg/Tag/tag.svg";
import Button from "../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../../components/Toasts/Toasts";
import { ReactComponent as LeftIcon } from "../../../../assests/svg/Products/left_lg.svg";
import InputField from "../../../../components/InputField/InputField";
import InputGroupTxt from "../../../../components/InputField/InputGroupTxt";
import applePhones from "../../../../assests/img/apple_colored.png";
import product_default from "../../../../assests/img/Product-default.png";
import Dropdown from "react-bootstrap/Dropdown";
import { putSupplierMerchandiseMappingProd } from "../../../../Redux/MappingProducts/Supplier/Merchandise/SuppMerchandiseAction";
import { postOrganizationMerchandiseMap } from "../../../../Redux/Organizations/organizationAction";

const OrganizationSKU = () => {

    const history = useNavigate();
  const dispatch = useDispatch();

  const productsListState = useSelector(
    (state) => state?.giftcardSuppMappingReducer?.selectedGiftCardList
  );
  const selectedSupplier = useSelector(
    (state) => state?.giftcardSuppMappingReducer?.selectedSupplier
  );

  const selectedOrganization = useSelector(
    (state) => state?.organizations?.selectedOrganization
  );

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const [products, setProducts] = useState([]);
  const [discountIndex, setDiscountIndex] = useState();
  const [supplierIndex, setSupplierIndex] = useState();

  const [error, setError] = useState({
    organizationSKU: "",
    organizationPrice: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    let arr = productsListState;
    let prodArr = [];
    let organization = {
      id: selectedOrganization?.id,
      organizationName: selectedOrganization?.default_title,
    };

    // eslint-disable-next-line array-callback-return
    arr &&
      arr.map((item, i) => {
        let obj = {
          product: item,
          organization: organization,
          organizationSKU: "",
          discountToClient: "",
          organizationPrice: "",
          hadayaPrice: "",
        };
        prodArr.push(obj);
      });

    setProducts(prodArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allCheckedHandle = (e) => {
    const { checked } = e.target;
    let arr = [...products];
    // eslint-disable-next-line array-callback-return
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = checked;
      });
    setProducts(arr);
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...products];

    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === parseInt(name)) {
        arr[i].isChecked = checked;
      }
    });
    setProducts(arr);
  };

  const inputChangeHandler = (e) => {
    let skuValue = (e.target.value = e.target.value.toUpperCase());
    let index = e.target.name;
    let arr = [...products];
    // eslint-disable-next-line array-callback-return
    arr.map((item, i) => {
      if (i === parseInt(index)) {
        arr[i].organizationSKU = skuValue;
      }
    });
    setProducts(arr);
  };

  // Discount Input handler
  const discountInputHandler = (e) => {
    let discountValue = e.target.value;
    let index = e.target.name;
    setDiscountIndex(parseInt(index));
    let arr = [...products];
    let regex = /^\d{0,2}(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      arr.map((item, i) => {
        if (i === parseInt(index)) {
          arr[i].discountToClient = discountValue;
        }
      });
      setProducts(arr);
    }
  };

  const priceInputeHandler = (e) => {
    let supplierPriceValue = e.target.value;
    let index = e.target.name;
    setSupplierIndex(parseInt(index));

    let arr = [...products];
    let regex = /^\d{0,6}(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      // eslint-disable-next-line array-callback-return
      arr.map((item, i) => {
        let discountPrice = parseFloat(item.discountToClient);
        let organizationPrice = parseFloat(supplierPriceValue);
        let percentageValue = organizationPrice ? ((discountPrice / 100) * organizationPrice).toFixed(
          2
        ) : 0;
        let hadayaPrice = organizationPrice - percentageValue;

        if (i === parseInt(index)) {
          arr[i].organizationPrice = supplierPriceValue;
          arr[i].hadayaPrice = hadayaPrice.toFixed(2);
        }
      });
      setProducts(arr);
    }
  };

  const [hadayaPrice, setHadayaPrice] = useState(0)
  // Discount calculation
  // useEffect(() => {
  //   let arr;
  //   let identifier = setTimeout(() => {
  //     arr = [...products];
  //     arr.map((item, i) => {
  //       let discountPrice = parseFloat(item.discountToClient);
  //       let organizationPrice = parseFloat(item.organizationPrice);
  //       let percentageValue = organizationPrice ? ((discountPrice / 100) * organizationPrice).toFixed(
  //         2
  //       ) : 0;
  //       let hadayaPrice = organizationPrice - percentageValue;

  //       setHadayaPrice(hadayaPrice)
  //       // let regex = /^\d{0,3}(\.\d{0,2})?$/;
  //       if (i === discountIndex && i === supplierIndex) {
  //         arr[i].hadayaPrice = hadayaPrice.toFixed(2);
  //       }
  //     });
  //   }, 500);

  //   return () => {
  //     clearTimeout(identifier);
  //   };

  // }, [products, supplierIndex]);

  useEffect(() => {

  }, [hadayaPrice])
  // END Discount calculation

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!selectedOrganization) {
      history("/organization-mapping-dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization]);

  const goBack = () => {
    history("/organization-mapping-dashboard");
  };

  const addRow = (item, index) => {
    let arr = [...products];

    let obj = {
      ...item,
      organization: "",
      organizationSKU: "",
      discountToClient: "",
      organizationPrice: "",
      isChecked: false,
    };

    arr.unshift(obj);
    setProducts(arr);
  };

  const deleteRow = (index) => {
    let arr = [...products];
    if (index > -1) {
      arr.splice(index, 1);
    }
    setProducts(arr);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </a>
  ));

  const validate = () => {
    let checkArr = [];

    // eslint-disable-next-line array-callback-return
    products &&
      products.map((item) => {
        if (item.isChecked) {
          checkArr.push(item);
        }
      });
    if (checkArr.length > 0) {
      //  let valSKU = checkArr && checkArr.every(v => v.organizationSKU);
      let valPrice = checkArr && checkArr.every((v) => v.organizationPrice);

      valPrice
        ? setError({ organizationPrice: "" })
        : setError({ organizationPrice: "Enter supplier price" });

      return valPrice ? true : false;
    } else {
      toggleShowToast();
      return false;
    }
  };

  const submit = () => {
    let value = validate();

    if (value) {
      let selectedProducts = [];
      // eslint-disable-next-line array-callback-return
      products &&
        products.map((item) => {
          if (item.isChecked === true) {
            selectedProducts.push(item);
          }
        });
      if (selectedProducts.length > 0) {
        dispatch(postOrganizationMerchandiseMap(selectedProducts));
        history("/organization-map-successfull");
      }
    }
  };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id?.slice(0, 10);
    return val;
  };


    return (
    <div className="d-flex flex-column justify-content-between gap-3 py-4 pb-3 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => goBack()} className="cursor" />
          <span className="font-bold font-32 my-2">Organization Mapping</span>
        </div>
      </div>

      <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100 gap-3">
        <div className="card-body d-flex justify-content-between flex-row">
          <div className="d-flex gap-3 my-2">
            <Tag />
            <span className="font-20 font-bold">Mapping merchandise</span>
          </div>
          {/* <div>
            <BackButton onClick={() => history(-1)} />
          </div> */}
        </div>
        <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
          <table responsive="xl" cellPadding={4} cellSpacing={0}>
            <tbody>
              <tr className="border-bottom">
                <td className="col-3">
                  <div className="d-flex flex-row justify-content-start text-dark gap-3">
                    <span>
                      <input
                        className="form-check-input cursor"
                        type="checkbox"
                        id="checkboxNoLabel"
                        checked={
                          products.filter(
                            (product) => product?.isChecked !== true
                          ).length < 1
                        }
                        onChange={allCheckedHandle}
                      />
                    </span>
                    <span className="font-12 font-boldest text-muted">
                      Name
                    </span>
                  </div>
                </td>
                <td className="col-2 px-4">
                  <div>
                    <span className="font-12 font-boldest text-muted">
                      Organization <br />
                      name
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-12 font-boldest text-muted">
                    Organization
                      <br /> SKU
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-12 font-boldest text-muted">
                    Organization
                      <br />
                      discount
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-12 font-boldest text-muted">
                    Organization
                      <br /> price
                    </span>
                  </div>
                </td>
                <td className="col-2">
                  <div>
                    <span className="font-12 text-success font-boldest">
                      Hadaya
                      <br /> price
                    </span>
                  </div>
                </td>
              </tr>
              {/* {products && products.map((item, index) => ( */}
              {products &&
                products?.map((item, index) => (
                  <tr key={index} className="gap-2 border-bottom">
                    <td className="col-3">
                      <div className="d-flex flex-row justify-content-start align-items-center py-3 gap-2 ">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="checkboxNoLabel"
                          checked={item.isChecked}
                          name={index}
                          onChange={handleCheck}
                        />
                        <img
                          src={
                            item?.product?.product?.images
                              ? item?.product?.product?.images[0]?.file_url
                              : product_default
                          }
                          alt="not found"
                          className="img-fluid"
                          width="50px"
                        />
                        <div className="d-flex flex-column align-items-start mt-2">
                          <span
                            className="font-bold text-dark font-12 d-inline-block text-truncate cursor"
                            title={item?.product?.product?.default_title}
                            style={{ maxWidth: "100px" }}
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {item?.product?.product?.default_title}
                          </span>
                          {item?.product?.variantValues && (
                            <span
                              className="font-12 text-muted visible cursor"
                              title={
                                item?.product?.variantValues &&
                                item?.product?.variantValues.join("|")
                              }
                            >
                              {trimIdHandler(
                                item?.product?.variantValues &&
                                  item?.product?.variantValues.join("|")
                              )}
                              <span>...</span>
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="col px-4">
                      <div className="d-flex  font-12">
                        <span
                          className="font-bold text-dark mb-1 d-inline-block text-truncate cursor"
                          title={item?.organization?.organizationName}
                          style={{ maxWidth: "100px" }}
                          data-toggle="tooltip"
                          data-placement="top"
                        >
                          {item?.organization?.organizationName}
                        </span>
                      </div>
                    </td>
                    <td className="col-2 p-2">
                      <div className="d-flex ">
                        <InputField
                          name={index}
                          placeholder="SKU"
                          type="text"
                          maxLength={25}
                          value={item.organizationSKU}
                          onChange={inputChangeHandler}
                        />
                      </div>
                    </td>
                    <td className="col-2 p-2">
                      <div className="d-flex w-45">
                        <InputGroupTxt
                          name={index}
                          placeholder={"Discount"}
                          type="text"
                          title={"%"}
                          maxLength={6}
                          value={item.discountToClient}
                          onChange={discountInputHandler}
                        />
                      </div>
                    </td>
                    <td className="col-2 p-2">
                      <div className="d-flex">
                        <InputGroupTxt
                          name={index}
                          title={
                            item?.product?.product?.country?.currency_symbol
                          }
                          placeholder={"Price"}
                          type="text"
                          maxLength={6}
                          value={item.organizationPrice}
                          onChange={priceInputeHandler}
                        />
                      </div>
                      <div className="d-flex">
                        {!item.organizationPrice && item.isChecked ? (
                          <label className="text-error-client-supplier">
                            {error.organizationPrice}
                          </label>
                        ) : null}
                      </div>
                    </td>

                    <td className="col-3 p-2">
                      <div className="d-flex text-success justify-content-between align-items-center">
                        <div>
                          {item?.hadayaPrice > 0 && item?.hadayaPrice}
                        </div>
                        <div className="d-flex flex-row px-2 cursor gap-2">
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title="">
                              {/* <Dropdown.Item className="cursor">
                                Edit
                              </Dropdown.Item> */}
                              <Dropdown.Item
                                className="cursor"
                                onClick={() => deleteRow(index)}
                              >
                                Delete
                              </Dropdown.Item>
                              {/* <Dropdown.Item
                                onClick={() => addRow(item, index)}
                              >
                                Add new row
                              </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex">
        <Button type="primary" text="Save and Exit" onClick={() => submit()} />
      </div>
      <div
        className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
        id="liveToast"
      >
        <Toasts
          position={"bottom-center"}
          showToast={showToast}
          toggleShowToast={toggleShowToast}
          warningMsg="Please select giftcards/products to map."
          imageType={"warning"}
        />
      </div>
    </div>
  );
};

export default OrganizationSKU;