/* eslint-disable array-callback-return */
import axios from 'axios'
import { baseUrl, getToken } from '../../utils/prodURL'
import {
  FETCH_ALL_ORGANIZATION_REQUEST,
  FETCH_ALL_ORGANIZATION_SUCCESS,
  FETCH_ALL_ORGANIZATION_FAILURE,
  POST_ALL_ORGANIZATION_REQUEST,
  POST_ALL_ORGANIZATION_SUCCESS,
  POST_ALL_ORGANIZATION_FAILURE,
  POST_ORGANIZATION_RESPONSE,
  PATCH_ORGANIZATION_REQUEST,
  PATCH_ORGANIZATION_SUCCESS,
  PATCH_ORGANIZATION_FAILURE,
  SET_SELECTED_ORGANIZATION,
  POST_ORGANIZATION_MAPPING_REQUEST,
  POST_ORGANIZATION_MAPPING_SUCCESS,
  POST_ORGANIZATION_MAPPING_FAILURE,
  POST_ORGANIZATION_MER_MAPPING_REQUEST,
  POST_ORGANIZATION_MER_MAPPING_SUCCESS,
  POST_ORGANIZATION_MER_MAPPING_FAILURE,
  GET_ALL_ORGANIZATION_REQUEST,
  GET_ALL_ORGANIZATION_SUCCESS,
  GET_ALL_ORGANIZATION_FAILURE,
  DELETE_ORGANIZARION_LIST,
  GET_ORGANIZARION_MAPPED_GIFTCARD_SUCCESS,
  GET_ORGANIZARION_MAPPED_GIFTCARD_REQUEST,
  GET_ORGANIZARION_MAPPED_GIFTCARD_FAILURE,
  GET_ORGANIZARION_MAPPED_GIFTCARD_DELETE,
  ORGANIZARION_FILTER_DATA,
  CLEAR_ORGANIZATION_LIST
} from "../../Redux/Organizations/organizationType";

export const fetchAllOrganizationRequest = () => {
  return {
    type: FETCH_ALL_ORGANIZATION_REQUEST
  }
}
export const fetchAllOrganizationSuccess = (data) => {
  return {
    type: FETCH_ALL_ORGANIZATION_SUCCESS,
    payload: data
  }
}
export const fetchAllOrganizationFailure = (error) => {
  return {
    type: FETCH_ALL_ORGANIZATION_FAILURE,
    payload: error
  }
}


export const setSelectedOrganization = (obj) => {
  return {
    type: SET_SELECTED_ORGANIZATION,
    payload: obj,
  };
};

export const getAllOrganizationList = (skip, limit, organizationState) => {
  return (dispatch) => {
    dispatch(fetchAllOrganizationRequest())
    let config = {
      method: 'get',
      url: `${baseUrl}/v1/organization?active_flag=true&skip=${skip}&limit=${limit}`,
      headers: {
        'Authorization': `Bearer ${getToken()}`
      }
    };
    axios(config)
      .then(response => {
        let data = {
          data: [...organizationState, ...response.data.result],
          moredata: response.data.next_iteration
        }
        dispatch(fetchAllOrganizationSuccess(data))
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(fetchAllOrganizationFailure(errorMsg))
      })

  }
}
export const postOrganizationResponse = (orgResponse) => {
  return {
    type: POST_ORGANIZATION_RESPONSE,
    payload: orgResponse,
  }
}
export const postOrganizationRequest = () => {
  return {
    type: POST_ALL_ORGANIZATION_REQUEST,
  }
}
export const postOrganizationSuccess = (orgResponse) => {
  return {
    type: POST_ALL_ORGANIZATION_SUCCESS,
    payload: orgResponse
  }
}
export const postOrganizationFailure = (error) => {
  return {
    type: POST_ALL_ORGANIZATION_FAILURE,
    payload: error
  }
}
export const postOrganization = (obj, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(postOrganizationRequest())
    let orgTitleArr = [];
    obj.organization.map((item, i) => {
      orgTitleArr.push({ [item?.language_id.language_code]: item?.organization_name })
    })

    let titleObj = Object.assign({}, ...orgTitleArr)


    let organizationObject = {
      active_flag: obj.activateFlag,
      default_title: obj?.organization[0]?.organization_name,
      organization_title: titleObj

    }

    let config = {
      method: 'post',
      url: `${baseUrl}/v1/organization`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: organizationObject
    }

    axios(config).then((response) => {
      if (response.status === 200) {
        dispatch(postOrganizationSuccess(response.data.result))
        goToSuccessfull();
        dispatch(getAllOrganizationList());
      }
    }).catch((error) => {
      dispatch(postOrganizationFailure("Something went wrong!! Please try again!!"))
    })
  }
}

// PATCH Organization 
export const patchPostOrganizationRequest = () => {
  return {
    type: PATCH_ORGANIZATION_REQUEST,
  };
};

export const patchPostOrganizationSuccess = (obj) => {
  return {
    type: PATCH_ORGANIZATION_SUCCESS,
    payload: obj,
  };
};

export const patchPostOrganizationFailure = (error) => {
  return {
    type: PATCH_ORGANIZATION_FAILURE,
    payload: error,
  };
};



export const patchPostOrganization = (obj, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(patchPostOrganizationRequest())

    let orgTitleArr = [];
    obj.organization.map((item, i) => {
      orgTitleArr.push({ [item?.language_id.language_code]: item?.organization_title })
    })

    let titleObj = Object.assign({}, ...orgTitleArr)


    let organizationObject = {
      active_flag: obj.activateFlag,
      default_title: obj?.organization[0]?.organization_title,
      organization_title: titleObj,
      // created_at: "2022-06-07T20:06:32.852103",
      // updated_at: "2022-06-07T20:06:32.852121"
    }

    let config = {
      method: 'patch',
      url: `${baseUrl}/v1/organization/${obj.id}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: organizationObject
    }

    axios(config).then((response) => {
      if (response.status === 200) {
        dispatch(patchPostOrganizationSuccess(response.data))
        goToSuccessfull();
        dispatch(getAllOrganizationList());
      }
    }).catch((error) => {
      dispatch(patchPostOrganizationFailure("Something went wrong!! Please try again!!"))
    })

  }
}

export const postOrganizationMapGiftcardRequest = () => {
  return {
    type: POST_ORGANIZATION_MAPPING_REQUEST,
  };
};

export const postOrganizationMapGiftcardSuccess = (data) => {
  return {
    type: POST_ORGANIZATION_MAPPING_SUCCESS,
    payload: data
  };
};

export const postOrganizationMapGiftcardFailure = (error) => {
  return {
    type: POST_ORGANIZATION_MAPPING_FAILURE,
    payload: error
  };
};

export const postOrganizationMapGiftcardResponse = (data) => {
  return {
    type: POST_ORGANIZATION_MAPPING_FAILURE,
    payload: data
  };
};

// Organization giftcar mapping
export const postOrganizationGiftCardMapping = (selectedProducts, goToSuccessScreen) => {
  return (dispatch) => {
    dispatch(postOrganizationMapGiftcardRequest);
    let payload = [];
    // eslint-disable-next-line array-callback-return
    selectedProducts.map((item) => {
      let obj = {
        "giftcard_id": item.product.id,
        "organization_list": [
          {
            "organization_id": item.organization?.id,
            "supplier_id": item?.supplier?.supplier_id,
            "discount": item.clientDiscount
          }
        ],
        "active_flag": true
      }

      payload.push(obj);
    });
    let config = {
      method: "post",
      url: `${baseUrl}/v1/giftcards/organization/mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        //   dispatch(postOrganizationMapGiftcardSuccess(response.data.result));
        if (response.status === 200) {
          dispatch(postOrganizationMapGiftcardResponse(response.data.result[0]))
          goToSuccessScreen();
        }
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(postOrganizationMapGiftcardFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};


export const postOrgMerchandiseMapRequest = () => {
  return {
    type: POST_ORGANIZATION_MER_MAPPING_REQUEST,
  };
};

export const postOrgMerchandiseMapSuccess = (data) => {
  return {
    type: POST_ORGANIZATION_MER_MAPPING_SUCCESS,
    payload: data
  };
};

export const postOrgMerchandiseMapFailure = () => {
  return {
    type: POST_ORGANIZATION_MER_MAPPING_FAILURE,
  };
};

export const postOrganizationMerchandiseMap = (data) => {
  return (dispatch) => {
    dispatch(postOrgMerchandiseMapRequest())
    let payload = [];

    data && data.map((item) => {
      let obj = {
        mapped_for: "merchandise",
        merchandise_id: item?.product?.productId,
        variant_id: item?.product?.variantId,
        organization_list: [
          {
            organization_id: item?.organization?.id,
            selling_price: item?.organizationPrice,
            margin: item?.hadayaPrice
          }
        ],
        active_flag: true,
      }

      payload.push(obj)
    })
    let config = {
      method: "post",
      url: `${baseUrl}/v1/merchandise/organization/mapping`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then((response) => {
        dispatch(postOrgMerchandiseMapSuccess(response.data.result[0]));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(postOrgMerchandiseMapFailure("Something went wrong!! Please try again!!")
        );
      });

  }
}

// get organization as a partner and fetching result from partner category 
export const getOrganizationListRequest = () => {
  return {
    type: GET_ALL_ORGANIZATION_REQUEST
  }
}
export const getOrganizationListSuccess = (data) => {
  return {
    type: GET_ALL_ORGANIZATION_SUCCESS,
    payload: data
  }
}
export const getOrganizationListFailure = (error) => {
  return {
    type: GET_ALL_ORGANIZATION_FAILURE,
    payload: error
  }
}

export const deleteOrganizationList = () => {
  return {
    type: DELETE_ORGANIZARION_LIST,
    payload: []
  }
}
export const setOrganizationFilterData = (data) => {
  return {
    type: ORGANIZARION_FILTER_DATA,
    payload: data
  }
}

export const getOrganizationList = () => {
  return (dispatch) => {
    dispatch(getOrganizationListRequest())
    let config = {
      method: 'get',
      url: `${baseUrl}/partner_category/organization/partner`,
      headers: {
        'Authorization': `Bearer ${getToken()}`
      }
    };
    axios(config)
      .then(response => {
        if (response.status === 200) {
          dispatch(getOrganizationListSuccess(response.data.result));
        }
      })
      .catch(error => {
        const errorMsg = error.message
        dispatch(getOrganizationListFailure(errorMsg))
      })

  }
}

export const organizationMappedGiftcardListRequest = () => {
  return {
    type: GET_ORGANIZARION_MAPPED_GIFTCARD_REQUEST
  }
}

export const organizationGiftcardListSuccess = (data) => {
  return {
    type: GET_ORGANIZARION_MAPPED_GIFTCARD_SUCCESS,
    payload: data
  }
}

export const organizationGiftcardListFailure = (error) => {
  return {
    type: GET_ORGANIZARION_MAPPED_GIFTCARD_FAILURE,
    payload: error
  }
}

export const organizationGiftcardListDelete = () => {
  return {
    type: GET_ORGANIZARION_MAPPED_GIFTCARD_DELETE,
    payload: []
  }
}

export const getOrganizationMappedGiftcardList = (organization_id) => {
  return (dispatch) => {
    dispatch(organizationMappedGiftcardListRequest());

    let config = {
      method: "get",
      url: `${baseUrl}/v1/giftcards/organization/${organization_id}/mappedProducts`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log("response ", response)
        dispatch(organizationGiftcardListSuccess({data: response.data.result}));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          organizationGiftcardListFailure("Something went wrong!! Please try again!!"));
      });


  }
}
export const getOrganizationMappedGiftcardDetailsList = (organization_id, skip, limit, organizationmappedGiftcardList) => {
  if (!organizationmappedGiftcardList) organizationmappedGiftcardList = []

  return (dispatch) => {
    dispatch(organizationMappedGiftcardListRequest());
    let url = skip !== undefined && limit !== undefined ?
      `${baseUrl}/v1/giftcards/organization/${organization_id}/mappedProducts?is_detailed=true&skip=${skip}&limit=${limit}` :
      `${baseUrl}/v1/giftcards/organization/${organization_id}/mappedProducts?is_detailed=true`

    let config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    axios(config)
      .then((response) => {
        let data = {
          data: [...organizationmappedGiftcardList, ...response.data.result],
          moredata: response.data.next_iteration
          // moredata: false
        }
        dispatch(organizationGiftcardListSuccess(data));
      })
      .catch((error) => {
        //  const errorMsg = error;
        dispatch(
          organizationGiftcardListFailure("Something went wrong!! Please try again!!"));
      });


  }
}

export const clearOrganizationList = () => {
  return {
    type: CLEAR_ORGANIZATION_LIST,
  };
};