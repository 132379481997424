/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tag } from "../../../../assests/svg/Tag/tag.svg";
import Button from "../../../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../../../../components/Toasts/Toasts";
import { ReactComponent as LeftIcon } from "../../../../assests/svg/Products/left_lg.svg";
// import BackButton from "../../../../components/Button/BackButton";
import applePhones from "../../../../assests/img/apple_colored.png";
import Dropdown from "react-bootstrap/Dropdown";
import InputField from "../../../../components/InputField/InputField";
import InputGroupTxt from "../../../../components/InputField/InputGroupTxt";
import { putSupplierGiftcardMappingProd } from "../../../../Redux/MappingProducts/Supplier/Giftcards/giftcardSuppMappingAction";
import { CUSTOM_DROPDOWN_STYLE } from "../../../../utils/CustomeStyles";
import Select from "react-select";

const GiftCardSupplierMapping = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const giftcardsListState = useSelector(
    (state) => state?.giftcardSuppMappingReducer?.noMappSupplierProduct
  );

  const suppliersListState = useSelector((state) => state?.suppliers?.supplier);



  const [products, setProducts] = useState([]);

  const [error, setError] = useState({
    supplierSKU: "",
    supplier: "",
    // supplierPrice: "",
    discountToClient: ""
  });

  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
    let arr = giftcardsListState;
    let prodArr = [];

    arr &&
      arr.map((item, i) => {
        let obj = {
          product: item,
          supplier: "",
          supplierSKU: "",
          discountToClient: "",
          // supplierPrice: "",
          hadayaPrice: "",
        };
        prodArr.push(obj);
      });

    setProducts(prodArr);
  }, []);


  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (!selectedSupplier) {
  //     history("/supplier-mapping-dashboard");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedSupplier]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </a>
  ));

  const handleChangeProduct = (e, { name }) => {
    let id = name
    let arr = [...products];

    arr.map((item, i) => {
      if (i === id) {
        arr[i].supplier = e;
      }
    });
    setProducts(arr);
  };


  const allCheckedHandle = (e) => {
    const { checked } = e.target;
    let arr = [...products];
    arr &&
      arr.map((item, i) => {
        arr[i].isChecked = checked;
      });
    setProducts(arr);
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    let arr = [...products];
    arr.map((item, i) => {
      if (i === parseInt(name)) {
        item.isChecked = checked;
      }
    });
    setProducts(arr);
  };



  // SKU Handler
  const inputChangeHandler = (e) => {
    let skuValue = (e.target.value = e.target.value.toUpperCase());
    let index = e.target.name;

    let arr = [...products];
    arr.map((item, i) => {
      if (i === parseInt(index)) {
        arr[i].supplierSKU = skuValue;
      }
    });
    setProducts(arr);
  };

  // Discount Input handler
  const discountInputHandler = (e) => {
    let discountValue = e.target.value;
    let index = e.target.name;

    let arr = [...products];
    arr.map((item, i) => {
      if (i === parseInt(index)) {
        arr[i].discountToClient = discountValue;
      }
    });
    setProducts(arr);
  };

  // const priceInputeHandler = (e) => {
  //   let supplierPriceValue = e.target.value;
  //   let index = e.target.name;
  //   setSupplierIndex(parseInt(index));
  //   let arr = [...products];
  //   let regex = /^\d{0,10}(\.\d{0,2})?$/;
  //   if (regex.test(e.target.value)) {
  //     // eslint-disable-next-line array-callback-return
  //     arr.map((item, i) => {
  //       if (i === parseInt(index)) {
  //         arr[i].supplierPrice = supplierPriceValue;
  //       }
  //     });
  //     setProducts(arr);
  //   }
  // };

  // Discount calculation
  // useEffect(() => {
  //   let arr;
  //   let identifier = setTimeout(() => {
  //     arr = [...products];
  //   // eslint-disable-next-line array-callback-return
  //     arr.map((item, i) => {
  //       let discountPrice = item.discountToClient;
  //       let supplierPrice = item.supplierPrice;

  //       let percentageValue = ((discountPrice / 100) * supplierPrice).toFixed(2);
  //       let hadayaPrice = supplierPrice - percentageValue;

  //       if(i === discountIndex && i === supplierIndex){
  //         arr[i].hadayaPrice = hadayaPrice
  //       }
  //     });
  //     setTempProducts(arr)

  //   }, 500);


  //   return () => {
  //     clearTimeout(identifier);
  //   };
  // }, [discountIndex, supplierIndex, products]);


  const addRow = (item) => {
    let arr = [...products];

    let obj = {
      ...item,
      supplier: "",
      supplierSKU: "",
      discountToClient: "",
      // supplierPrice: "",
      isChecked: false,
    };

    arr.unshift(obj);
    setProducts(arr);
  };

  const deleteRow = (index) => {
    let arr = [...products];
    if (index > -1) {
      arr.splice(index, 1);
    }
    setProducts(arr);
  };


  const validate = () => {
    let checkArr = [];

    products &&
      products.map((item) => {
        if (item.isChecked) {
          checkArr.push(item);
        }
      });
    if (checkArr.length > 0) {
      //  let valSKU = checkArr && checkArr.every(v => v.supplierSKU);
      let valPrice = checkArr && checkArr.every((v) => v.supplier);

      valPrice
        ? setError({ supplier: "" })
        : setError({ supplier: "Select supplier" });

      return valPrice ? true : false;
    } else {
      toggleShowToast();
      return false;
    }
  };

  const submit = () => {
    let value = validate();

    if (value) {
      let selectedProducts = [];
      products &&
        products.map((item) => {
          if (item.isChecked === true) {
            selectedProducts.push(item);
          }
        });
      if (selectedProducts.length > 0) {
        dispatch(
          putSupplierGiftcardMappingProd(selectedProducts)
        );
        history("/supplier-map-successfull");
      }
    }
  };
  return (
    products && (
      <div className="d-flex flex-column justify-content-between gap-3 py-4 pb-3 px-4 bg-light">
        <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
          <div className="d-flex flex-row align-items-center pb-2 gap-3">
            <LeftIcon
              onClick={() => history("/supplier-mapping-dashboard")}
              className="cursor"
            />
            <span className="font-bold font-32 my-2">Supplier mapping</span>
          </div>
        </div>

        <div className="d-flex flex-column  bg-white shadow-sm rounded-3 py-2 px-4 w-100 gap-3">
          <div className="card-body d-flex justify-content-between flex-row">
            <div className="d-flex gap-3 my-2">
              <Tag />
              <span className="font-20 font-bold">Mapping giftcard</span>
            </div>
          </div>
          <div className="card-body d-flex flex-column justify-content-between px-2 gap-2">
            <table responsive="xl" cellPadding={4} cellSpacing={0}>
              <tbody>
                <tr className="col border-bottom">
                  <td className="col-2">
                    <div className="d-flex flex-row justify-content-start text-dark gap-3">
                      <span>
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="checkboxNoLabel"
                          checked={
                            products.filter(
                              (product) => product?.isChecked !== true
                            ).length < 1
                          }
                          onChange={allCheckedHandle}
                        />
                      </span>
                      <span className="font-12 font-boldest text-muted">
                        Name
                      </span>
                    </div>
                  </td>
                  <td className="col-2 px-4">
                    <div>
                      <span className="font-12 font-boldest text-muted">
                        <div>Supplier Name</div>
                      </span>
                    </div>
                  </td>
                  <td className="col-3">
                    <div>
                      <span className="font-12 font-boldest text-muted">
                        <div>Supplier SKU</div>
                      </span>
                    </div>
                  </td>
                  <td className="col-3">
                    <div>
                      <span className="font-12 font-boldest text-muted">
                        <div>Supplier discount</div>
                      </span>
                    </div>
                  </td>
                  {/* <td className="col-2">
                    <div>
                      <span className="font-12 font-boldest text-muted">
                        <div>Supplier</div>
                        <div>Price</div>
                      </span>
                    </div>
                  </td> */}
                  {/* <td className="col-2">
                    <div>
                      <span className="font-12 text-success font-boldest">
                        <div>Hadaya</div>
                        <div>Price</div>
                      </span>
                    </div>
                  </td> */}
                </tr>
                {products &&
                  products?.map((item, index) => (
                    <tr key={index} className="gap-3 my-2 border-bottom">
                      <td className="col-4">
                        <div className="d-flex flex-row justify-content-start align-items-center py-1 gap-2">
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="checkboxNoLabel"
                            checked={item.isChecked}
                            name={index}
                            onChange={handleCheck}
                          />
                          <img
                            src={item?.product?.images[0]?.file_url ? item?.product?.images[0]?.file_url : applePhones}
                            alt="not found"
                            className="img-fluid"
                            width="50px"
                            height="50px"
                          />
                          <div className="d-flex flex-column justify-content-center align-items-start">
                            <span className="font-bold text-dark font-15 mt-2">
                              {item?.product?.default_title}
                            </span>
                            <span
                              className="font-bold text-muted font-10">
                              {item?.product?.giftcard_value_type}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="col-3 p-2">
                        <div className="d-flex flex-column">
                          <Select
                            placeholder="Select"
                            noOptionsMessage={() => "Not found"}
                            getOptionLabel={(suppliersListState) =>
                              suppliersListState.partner_name
                            }
                            options={suppliersListState}
                            styles={CUSTOM_DROPDOWN_STYLE}
                            value={item.supplier}
                            onChange={handleChangeProduct}
                            isClearable
                            name={index}
                          />
                          {!item.supplier && item.isChecked ? (
                            <label className="text-error">
                              {error.supplier}
                            </label>
                          ) : null}
                        </div>

                      </td>
                      <td className="col-2 p-2">
                        <div className="d-flex flex-column align-items-start">
                          <div className="d-flex ">
                            <InputField
                              name={index}
                              placeholder="SKU"
                              type="text"
                              maxLength={20}
                              value={item.supplierSKU}
                              onChange={inputChangeHandler}
                            />
                          </div>
                          {/* {!item.supplierSKU && item.isChecked ? (
                                                    <label className="text-error">{error.supplierSKU}</label>
                                                ) : null} */}
                        </div>
                      </td>
                      <td className="col-2 p-2">
                        <div className="d-flex flex-column align-items-start">
                          {/* <div className="d-flex">
                            <InputField
                              name={index}
                              placeholder="Discount"
                              type="text"
                              maxLength={20}
                              value={item.discountToClient}
                              onChange={discountInputHandler}
                            />
                          </div> */}
                          <div className="d-flex">
                            <InputGroupTxt
                              name={index}
                              placeholder="enter"
                              type="text"
                              title="%"
                              lang={props?.lang}
                              maxLength={20}
                              value={item.discountToClient}
                              onChange={discountInputHandler}
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          {/* {!item.discountToClient && item.isChecked ? (
                            <label className="text-error">
                              {error.discountToClient}
                            </label>
                          ) : null} */}
                        </div>
                      </td>

                      {/* <td className="col-1">
                        <div className="d-flex text-success flex-row px-2 cursor gap-2">
                        {item.hadayaPrice > 0 && `$${item?.hadayaPrice}`}
                        </div>
                      </td> */}
                      <td className="col-1">
                        <div className="d-flex flex-row px-2 cursor gap-2">
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title="">
                              {/* <Dropdown.Item className="cursor">Edit</Dropdown.Item>*/}
                              <Dropdown.Item
                                className="cursor"
                                onClick={() => deleteRow(index)}
                              >
                                Delete
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => addRow(item)}>
                                Add new row
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex">
          <Button
            type="primary"
            text="Save and Next"
            onClick={() => submit()}
          />
        </div>
        <div
          className="position-fixed bottom-0 start-50 mb-5 translate-middle-x w-100 "
          id="liveToast"
        >
          <Toasts
            position={"bottom-center"}
            showToast={showToast}
            toggleShowToast={toggleShowToast}
            warningMsg="Please select products"
            imageType={"warning"}
          />
        </div>
      </div>
    )
  );
};

export default GiftCardSupplierMapping;
