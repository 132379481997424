import { SET_MERCHANDISE_VARIENT, SET_MERCHANDISE_VARIENT_IMG, SET_MERCHANDISE_VARIENT_OBJ, SET_MERCHANDISE_VARIENT_TERM_IMG } from "./merchandiseVarientTypes";


const intialState = {
    loading: true,
    error: "",
    merchandiseVarient: null,
    merchandiseVarientObj: null,
    merchandiseVarientTermImg: null,
    merchandiseVarientImg: [],

};

const merchandiseVarientReducer = (state = intialState, action) => {
    switch (action.type) {

        case SET_MERCHANDISE_VARIENT:
            return {
                ...state,
                loading: false,
                error: "",
                merchandiseVarient: action.payload,
            };
        case SET_MERCHANDISE_VARIENT_OBJ:
            return {
                ...state,
                loading: false,
                error: "",
                merchandiseVarientObj: action.payload,
            };
        case SET_MERCHANDISE_VARIENT_TERM_IMG:
            return {
                ...state,
                loading: false,
                error: "",
                merchandiseVarientTermImg: action.payload,
            };
        case SET_MERCHANDISE_VARIENT_IMG:
            return {
                ...state,
                loading: false,
                error: "",
                merchandiseVarientImg: action.payload,
            };

        default:
            return state;
    }
};
export default merchandiseVarientReducer;
