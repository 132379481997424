/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import { ReactComponent as CopyButton } from "../../assests/svg/copyLink.svg";
import { ReactComponent as EditButton } from "../../assests/svg/edit.svg";
import SmallToast from "../Toast/SmallToast";
import CopyToClipboard from "react-copy-to-clipboard";
import Filter from "../Filter/Filter";
import {
  PRODUCT_STATUS_FILTERS_ACTIVE_DEACTIVE,
  SORT_TYPE,
} from "../../utils/ConstantData";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  clearBrandList,
  fetchBrand,
  setBrandFilterData,
} from "../../Redux/Brands/brandAction";
import PulseLoaders from "react-spinners/PulseLoader";
import PulseLoader from "../Loader/PulseLoader";
import {
  momentdateFormater,
  DataSortingHandler,
} from "../../utils/constantFunction";
import { useTranslation } from "react-i18next";
import SearcBar from "../InputField/SearcBar";
import RefreshButton from "../Button/RefreshButton";

const Brands = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useNavigate();

  const [id, setId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const brandsState = useSelector((state) => state.brands.brands);
  const hasmore = useSelector((state) => state?.brands?.next_iteration);
  const loading = useSelector((state) => state?.brands?.loading);
  const brandFilter = useSelector((state) => state?.brands?.brandFilter);
  // set infinite loader
  const [skip, setskip] = useState(
    brandsState.length === 0 ? -50 : brandsState.length - 50
  );
  const [limit, setlimit] = useState(50);
  const [products, setProducts] = useState();
  const [filterCardType, setFilterCardType] = useState("");
  const [permissions, setpermissions] = useState();

  // for sorting table data
  const [order, setOrder] = useState(SORT_TYPE?.descending);
  const [tableHeaderName, setTableHeaderName] = useState("");

  const sortingHandler = (
    tableHeaderName,
    setTableHeaderName,
    data,
    order,
    setOrder
  ) => {
    DataSortingHandler(
      tableHeaderName,
      setTableHeaderName,
      data,
      order,
      setOrder
    );
  };

  useEffect(() => { }, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (brandsState.length === 0) {
      fetchdata(skip);
    }
  }, []);

  useEffect(() => { }, [order]);

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => { }, [permissions]);

  useEffect(() => {
    setProducts(brandFilter[0]?.filterProduct);
  }, [brandFilter]);

  useEffect(() => {
    brandFilter && setFilterCardType(brandFilter[1]?.filterType);
  }, [brandFilter[1]?.filterType]);

  useEffect(() => {
    if (brandFilter.length === 0) {
      setProducts(brandsState);
    }
  }, [brandsState]);

  const filterCardTypeHandler = (e) => {
    setFilterCardType(e);
    let arr = [];
    brandsState &&
      brandsState.map((item, i) => {
        if (e?.value === item.active_flag) {
          arr.push(item);
        } else if (e?.value === null) {
          arr.push(item);
        }
      });
    dispatch(setBrandFilterData([{ filterProduct: arr }, { filterType: e }]));
    //  setProducts(arr);
  };

  const fetchdata = () => {
    setTimeout(async () => {
      dispatch(fetchBrand(skip + limit, limit, brandsState));
      setskip(skip + limit);
    }, 100);
  };

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const trimIdHandler = (item) => {
    let id = item;
    let val = id.slice(0, 5);
    return val;
  };

  const editButtonhandler = (item) => {
    history("/brand-edit", { state: { brandItem: item } });
  };

  const copyToClipboard = async (id) => {
    /* Copy the id and show copied id in toast */
    if (id !== null && id !== "undefined") {
      setId(id);
      var x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  };

  const refreshData = () => {
    dispatch(clearBrandList());
    setskip(0);
    setlimit(50);
    dispatch(fetchBrand(0, limit, []));
  };

  return loading === true && products?.length === 0 ? (
    <div className="d-flex flex-column justify-content-center align-item-center">
      <h5 className="text-center">
        <PulseLoader type={"data"} />
      </h5>
    </div>
  ) : (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 mb-3 w-100">
      <div className="d-flex flex-column  bg-white">
        <div className="card-body d-flex  flex-row justify-content-between align-item-center">
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div className="d-flex align-items-center gap-3 my-2">
              <Tag />
              <span className="font-20 font-bold">{t("Brands")}</span>
              <SearcBar
                type="text"
                name="search"
                lang={props?.lang}
                placeholder={t("Search by brand name")}
                onChange={searchHandler}
              />
            </div>
          </div>

          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div>
              {" "}
              <Filter
                data={PRODUCT_STATUS_FILTERS_ACTIVE_DEACTIVE}
                onChange={filterCardTypeHandler}
                title={filterCardType?.name}
                lang={props?.lang}
              />
            </div>
            <div>
              <RefreshButton onClick={refreshData} />
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={brandsState?.length || 0}
          next={fetchdata}
          hasMore={hasmore}
          loader={
            <div className="d-flex justify-content-center">
              {" "}
              <PulseLoaders
                color={"#594190"}
                loading={loading}
                size={10}
              />{" "}
            </div>
          }
        >
          <div className="row px-5 py-2 justify-content-between gap-3">
            <table responsive="xl">
              <tbody>
                <tr className="col-3 card-body gap-3 border-bottom py-4 justify-content-between">
                  <th
                    className={`card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "default_name"
                      ? "ascending"
                      : "descending"
                      }`}
                    onClick={() =>
                      sortingHandler(
                        "default_name",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Name")}
                  </th>
                  <th
                    className={`col-2 card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "id" ? "ascending" : "descending"
                      }`}
                    onClick={() =>
                      sortingHandler(
                        "id",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Brand Id")}
                  </th>
                  <th
                    className={`col-2 card-body gap-1 font-13 font-bold text-muted `}
                  >
                    {t("Status")}
                  </th>
                  <th
                    className={`col-2 card-body gap-1 font-13 font-bold text-muted cursor${tableHeaderName === "created_at"
                      ? "ascending"
                      : "descending"
                      }`}
                    onClick={() =>
                      sortingHandler(
                        "created_at",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Created at")}
                  </th>
                  <th
                    className={`col-2 card-body gap-1 font-13 font-bold text-muted cursor ${tableHeaderName === "updated_at"
                      ? "ascending"
                      : "descending"
                      }`}
                    onClick={() =>
                      sortingHandler(
                        "updated_at",
                        setTableHeaderName,
                        products,
                        order,
                        setOrder
                      )
                    }
                  >
                    {t("Updated at")}
                  </th>
                </tr>

                {products &&
                  products
                    ?.filter((item) => {
                      if (searchTerm === "") {
                        return item;
                      } else if (
                        item?.default_name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item, i) => (
                      <tr
                        className="card-body gap-3 border-bottom"
                        key={i}
                      >
                        <td className="card-body gap-1">
                          <div className="d-flex flex-column justify-content-start ">
                            <span
                              className="font-15 font-boldest text-dark d-inline-block text-truncate cursor"
                              title={item?.default_name}
                              style={{ maxWidth: "140px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                            >
                              {item?.default_name}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-start align-items-center">
                            <span
                              className="font-14 font-boldest text-dark visible mt-4"
                              title={item?.id}
                            >
                              {trimIdHandler(item?.id)}
                              <span>...</span>
                            </span>

                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Copy to clipboard"
                              className="cursor "
                              value={item?.id}
                              onClick={() => copyToClipboard(item.id)}
                            >
                              <CopyToClipboard text={item?.id}>
                                <CopyButton />
                              </CopyToClipboard>
                            </span>
                          </div>
                        </td>

                        <td className="card-body gap-1">
                          <span>
                            {item?.active_flag === true ? (
                              <span className="font-15 font-bold text-dark bg-04 bg-opacity-25 rounded-3 px-2">
                                {t("Active")}
                              </span>
                            ) : (
                              <span className="font-15 font-bold text-danger bg-04 bg-opacity-25 rounded-3 px-2">
                                {t("Deactive")}
                              </span>
                            )}
                          </span>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-contents-between align-items-center">
                            <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                              {momentdateFormater(item?.created_at)}
                            </span>
                          </div>
                        </td>
                        <td className="card-body gap-1">
                          <div className="d-flex flex-row justify-contents-between align-items-center">
                            <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 px-1 ">
                              {momentdateFormater(item.updated_at)}
                            </span>
                          </div>
                        </td>
                        {permissions?.brand?.create ? (
                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <span
                                className="cursor"
                                onClick={() => editButtonhandler(item)}
                              >
                                <EditButton />
                              </span>
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    ))}
              </tbody>
            </table>
            {!products?.length && (
              <div className="d-flex flex-row justify-content-center py-3">
                <span className="font-20 font-bold text-error">
                  {t("Brand not found")}
                </span>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
      <div>
        <SmallToast data={id ? id : ""} message={"Copied"} />
      </div>
    </div>
  );
};

export default Brands;
