import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllLanguage,
  postLanguage,
} from "../../Redux/Language/languageAction";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import InputField from "../InputField/InputField";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import ReactSwitchButton from "../Button/ReactSwitchButton";
import { useTranslation } from "react-i18next";

const AddLanguange = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputElementLangCode = useRef(null);
  const inputElementLangName = useRef(null);

  let defaultLang = localStorage.getItem("defaultLang");

  const [LanguageState, setLanguageState] = useState({
    languageCode: "",
    languageName: "",
  });

  const [activeFlag, setActiveFlag] = useState(true);

  const [error, setError] = useState({
    languageCode: "",
    languageName: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const languageHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.name === "languageCode") {
      setError({ ...error, languageCode: "" });
      setLanguageState({ ...LanguageState, [name]: value.toUpperCase() });
    } else if (name === "languageName") {
      setError({ ...error, languageName: "" });
      const firstLetter = value.charAt(0);
      const firstLetterCap = firstLetter.toUpperCase();
      const remainingLetters = value.slice(1);
      const capitalizedWord = firstLetterCap + remainingLetters;
      setLanguageState({ ...LanguageState, [name]: capitalizedWord });
    }
  };

  const activeHandle = () => {
    setActiveFlag(!activeFlag);
  };

  const validation = () => {
    if (!LanguageState?.languageCode) {
      setError({ ...error, languageCode: t("Please add language code") });
      if(inputElementLangCode.current){
        inputElementLangCode.current.focus()
      }
      return false;
    } else if (!LanguageState?.languageName) {
      setError({ ...error, languageName: t("Please add language name") });
      if(inputElementLangName.current){
        inputElementLangName.current.focus()
      }
      return false;
    }
    return true;
  };

  const languageSuccessfull = () => {
    history("/language-creation-success");
  };

  const submit = () => {
    let val = validation();
    if (val) {
      let date = new Date();
      const obj = {
        language_code: LanguageState?.languageCode,
        language: LanguageState?.languageName,
        active_flag: activeFlag,
        created_at: date,
        updated_at: date,
      };
      dispatch(postLanguage(obj, languageSuccessfull));
    } else {
    }
  };

  return (
    <div className="d-flex flex-column justify-content-start py-3 gap-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center pb-2 gap-3">
          <LeftIcon onClick={() => history(-1)} className="cursor" />
          <span className="font-bold font-32 my-2">{t("Add Language")}</span>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between gap-2">
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-65">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <Tag />
              <span className="font-20 font-bold">{t("Language")}</span>
            </div>
          </div>
          <div className="d-flex flex-column gap-4 mt-4">
            <div className="d-flex flex-column gap-1">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Language Code")}<span className="text-danger">*</span></span>
                <InfoTooltip title={"Enter Language Code"} />
              </div>
              <InputField
              inputRef={inputElementLangCode}
                onChange={languageHandler}
                name="languageCode"
                placeholder={t(`Enter language code`)}
                type="text"
                value={LanguageState.languageCode}
                maxLength={2}
              />
              {error.languageCode ? (
                <label className="text-error">{error.languageCode}</label>
              ) : null}
            </div>

            <div className="d-flex flex-column gap-1">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Language Name")}<span className="text-danger">*</span></span>
                <InfoTooltip title={"Enter Language Name"} />
              </div>
              <InputField
              inputRef={inputElementLangName}
                onChange={languageHandler}
                name="languageName"
                placeholder={t(`Enter language name`)}
                type="text"
                value={LanguageState?.languageName}
                maxLength={15}
              />
              {error.languageName ? (
                <label className="text-error">{error.languageName}</label>
              ) : null}
            </div>

            <div className="d-flex flex-row justify-content-between mb-2">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">{t("Active")}?</span>
                <InfoTooltip title={"Active/Inactive"} />
              </div>
              <ReactSwitchButton
                onChange={() => activeHandle()}
                value={activeFlag}
              />
            </div>

            <div className="d-flex">
              <Button
                onClick={() => submit()}
                text={t("Add Language")}
                type="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLanguange;
