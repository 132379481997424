import React from "react";

const SwitchButton = (props) => {
    const { onChange, value, disabled, checked } = props
    return (
        <div className="form-check form-switch">
            <input
                className="form-check-input py-2 "
                type="checkbox"
                role="switch"
                value={value}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
            />
        </div>
    );
};

export default SwitchButton;
