export const GET_GIFTCARD_DETAILS_REQUEST = "GET_GIFTCARD_DETAILS_REQUEST"
export const GET_GIFTCARD_DETAILS_SUCCESS = "GET_GIFTCARD_DETAILS_SUCCESS"
export const GET_GIFTCARD_DETAILS_FAILURE = "GET_GIFTCARD_DETAILS_FAILURE"


export const SET_STORE_DETAILS = "SET_STORE_DETAILS"


export const GET_GIFTCARD_TRANSACTIONS_REQUEST = "GET_GIFTCARD_TRANSACTIONS_REQUEST"
export const GET_GIFTCARD_TRANSACTIONS_SUCCESS = "GET_GIFTCARD_TRANSACTIONS_SUCCESS"
export const GET_GIFTCARD_TRANSACTIONS_FAILURE = "GET_GIFTCARD_TRANSACTIONS_FAILURE"


export const REDDEM_GIFTCARD_REQUEST = "REDDEM_GIFTCARD_REQUEST"
export const REDDEM_GIFTCARD_SUCCESS = "REDDEM_GIFTCARD_SUCCESS"
export const REDDEM_GIFTCARD_FAILURE = "REDDEM_GIFTCARD_FAILURE"


export const ACTIVATION_GIFTCARD_REQUEST = "ACTIVATION_GIFTCARD_REQUEST"
export const ACTIVATION_GIFTCARD_SUCCESS = "ACTIVATION_GIFTCARD_SUCCESS"
export const ACTIVATION_GIFTCARD_FAILURE = "ACTIVATION_GIFTCARD_FAILURE"

export const SET_SELL_CARD_DATA = "SET_SELL_CARD_DATA"
export const POST_SELL_GIFTCARD_REQUEST = "POST_SELL_GIFTCARD_REQUEST"
export const POST_SELL_GIFTCARD_SUCCESS = "POST_SELL_GIFTCARD_SUCCESS"
export const POST_SELL_GIFTCARD_FAILURE = "POST_SELL_GIFTCARD_FAILURE"