/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../../../assests/svg/Products/left_lg.svg";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import InputField from "../../../components/InputField/InputField";
import MyntraImg from "../../../assests/img/ImageGift.png";
import Button from "../../../components/Button/Button";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getDenomination } from "../../../Redux/Denominations/denominationAction";
import { SetCreateOrderData } from "../../../Redux/CreateOrder/CreateOrderAction";
import { strFirstLetterCap } from "../../../utils/constantFunction";
import { deliveryOption } from "../../../utils/ConstantData";
import PulseLoader from "../../../components/Loader/PulseLoader";
import Toasts from "../../../components/Toasts/Toasts";
import {
  getClientGcListDetails,
  postGiftCardManualInvCount,
} from "../../../Redux/Distributions/ClientGiftcardDistributions/ClientGiftcardDistributionAction";
import ModelForGiftCardSummery from "../../../components/Models/ModelForGiftCardSummery";
import ModalComp from "../../../components/Models/ModalComp";
import { fetchGiftCardSummeryById } from "../../../Redux/GiftCard/giftcardAction";
import { getOrganizationMappedGiftcardDetailsList } from "../../../Redux/Organizations/organizationAction";
import { useTranslation } from "react-i18next";

const CardDistributionProductforStore = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const inputElementProdType = useRef(null);
  const inputElementDenomi = useRef(null);
  const inputElementRang = useRef(null);
  const inputElementQuantity = useRef(null);
  const inputElementCountry = useRef(null);
  const inputElementDeliType = useRef(null);
  const inputElementDeliOptPhyAddress = useRef(null);
  const inputElementDeliOptDigital = useRef(null);
  const inputElementEmail = useRef(null);

  // reload data when user back to this page
  const giftcardOrderData = useSelector((state) => state.createOrder);
  const organizationmappedGiftcardList = useSelector(
    (state) => state?.organizations?.organizationMappedProdList
  );

  const selectedMapGiftcard = useSelector(
    (state) => state?.clientMapGcList?.selectedMapGcListDetails
  );

  const selectedStoreOrOrgData = useSelector(
    (state) => state.createStore?.selectedStoreOrOrg
  );

  const giftcardsState = useSelector((state) => state?.giftcard?.giftcards);
  console.log(
    "giftcardOrderData ",
    giftcardOrderData?.createorderData?.deliveryAddressline1
  );

  const giftcardsloading = useSelector((state) => state?.giftcard?.loading);
  const denominationState = useSelector(
    (state) => state?.denominations?.denomination
  );

  // for model
  const giftcardSummery = useSelector(
    (state) => state?.giftcard?.giftcardSummery
  );

  const [giftcartManualInvcount, setGiftcartManualInvcount] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = () => setShowToast(!showToast);
  const [selectedGiftcard, setSelectedGiftcard] = useState([]);
  const deliveryType = [{ name: "Physical" }, { name: "Digital" }];
  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);

  const [fields, setFields] = useState({
    product: giftcardOrderData?.createorderData?.selectedProduct
      ? giftcardOrderData?.createorderData?.selectedProduct
      : "",
    quantity: giftcardOrderData?.createorderData?.quantity
      ? giftcardOrderData?.createorderData?.quantity
      : "",
    denomination: giftcardOrderData?.createorderData?.denomination
      ? giftcardOrderData?.createorderData?.denomination
      : "",
    rangeDenomination: giftcardOrderData?.createorderData?.rangeDenomination
      ? giftcardOrderData?.createorderData?.rangeDenomination
      : "",
    countryCurrency: "",
    brand: "",
    organization: "",
    deliveryType: giftcardOrderData?.createorderData?.deliveryType
      ? giftcardOrderData?.createorderData?.deliveryType
      : "",
    deliveryAddressline1: giftcardOrderData?.createorderData
      ?.deliveryAddressline1
      ? giftcardOrderData?.createorderData?.deliveryAddressline1
      : "",
    deliveryAddressline2: giftcardOrderData?.createorderData
      ?.deliveryAddressline2
      ? giftcardOrderData?.createorderData?.deliveryAddressline2
      : "",
    cityName: "",
    emailid: selectedStoreOrOrgData?.seletedStore
      ? selectedStoreOrOrgData?.seletedStore?.email_id
      : "",
    phoneNumber: selectedStoreOrOrgData?.seletedStore
      ? selectedStoreOrOrgData?.seletedStore?.store_phone_no
      : "",
    pincode: "",
    deliveryOptions: giftcardOrderData?.createorderData?.deliveryOptions
      ? giftcardOrderData?.createorderData?.deliveryOptions
      : "",
  });


  const [error, setError] = useState({
    product: "",
    quantity: "",
    denomination: "",
    rangeDenomination: "",
    countryCurrency: "",
    brand: "",
    organization: "",
    deliveryType: "",
    deliveryOption: "",
    deliveryAddressline1: "",
    deliveryAddressline2: "",
    cityName: "",
    emailid: "",
    phoneNumber: "",
    pincode: "",
    deliveryOptions: "",
    manualInventoryDenomination: "",
  });


  // Drowdown giftcard list
  useEffect(() => {
    location.state?.selectedOrganization?.id &&
      dispatch(
        getOrganizationMappedGiftcardDetailsList(
          location.state?.selectedOrganization?.id
        )
      );
  }, []);

  useEffect(() => {
    if (!selectedStoreOrOrgData) {
      history("/gift-card-distribution");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreOrOrgData]);

  // Quantity
  useEffect(() => {
    if (giftcardOrderData?.createorderData?.quantity) {
      setFields({
        ...fields,
        quantity: giftcardOrderData?.createorderData?.quantity,
      });
    }
  }, [giftcardOrderData?.createorderData]);

  // // DeliveryType set after user back on this page
  // useEffect(() => {
  //   giftcardOrderData?.createorderData &&
  //     setFields({
  //       ...fields,
  //       deliveryType: giftcardOrderData?.createorderData?.deliveryType,
  //     });
  // }, [giftcardOrderData?.createorderData]);

  // Delivery address
  // useEffect(() => {
  //   if (fields?.deliveryType?.name === "Physical") {
  //     setFields({
  //       ...fields,
  //       deliveryAddressline1:
  //         selectedStoreOrOrgData &&
  //         selectedStoreOrOrgData?.seletedStore?.store_address,
  //       cityName:
  //         selectedStoreOrOrgData &&
  //         selectedStoreOrOrgData?.seletedStore?.city_name,
  //       pincode:
  //         selectedStoreOrOrgData &&
  //         selectedStoreOrOrgData?.seletedStore?.zip_code,
  //     });
  //   }
  // }, [fields?.deliveryType?.name]);

  // contact details
  useEffect(() => {
    setFields({
      ...fields,
      emailid:
        selectedStoreOrOrgData &&
        selectedStoreOrOrgData?.seletedStore?.email_id,
      phoneNumber:
        selectedStoreOrOrgData &&
        selectedStoreOrOrgData?.seletedStore?.store_phone_no,
    });
  }, [selectedStoreOrOrgData?.seletedStore]);

  // Selected Product set after come back
  useEffect(() => {
    giftcardOrderData?.createorderData?.selectedProduct &&
      giftcardsState &&
      giftcardsState.map((item) => {
        if (
          item.id === giftcardOrderData?.createorderData?.selectedProduct?.id
        ) {
          setFields({ ...fields, product: item });
          // dispatch(
          //   getClientGcListDetails(item?.giftcard?.id, getSelecedGiftcard)
          // );
        }
      });
  }, [giftcardsState, giftcardOrderData?.createorderData]);

  useEffect(() => {
    giftcardOrderData?.createorderData?.denomination &&
      denominationState &&
      denominationState.map((item) => {
        if (item.id === giftcardOrderData?.createorderData?.denomination?.id) {
          setFields({ ...fields, denomination: item });
        }
      });
  }, [denominationState, giftcardOrderData?.createorderData]);

  const getSelecedGiftcard = (data) => {
    setSelectedGiftcard(data);
  };

  //for drop down
  const handleChangeProduct = (e) => {
    setFields({
      ...fields,
      product: e,
      denomination: "",
      rangeDenomination: "",
      quantity: "",
    });
    setError({
      rangeDenomination: "",
      denomination: "",
      manualInventoryDenomination: "",
    });
    dispatch(getDenomination(e?.giftcard?.id));
    e && dispatch(getClientGcListDetails(e?.giftcard?.id, getSelecedGiftcard));
  };

  function handleRangeDenoChange(evt) {
    const value = evt.target.value;
    if (parseInt(value) <= fields?.product?.giftcard?.max_amount) {
      setFields({
        ...fields,
        [evt.target.name]: value,
      });
      setError({ rangeDenomination: "" });
    } else {
      setError({
        rangeDenomination:
          "Enter the denomination value between Min & Max Rang value.",
      });
      setFields({
        ...fields,
        [evt.target.name]: value,
      });
    }

    if (
      parseInt(value) > 0 &&
      parseInt(value) < fields?.product?.giftcard?.min_amount
    ) {
      setError({
        rangeDenomination:
          "Enter the denomination value between Min & Max Rang value.",
      });

      if(inputElementRang.current){
        inputElementRang.current.focus()
      }
    }
  }

  function handleChange(evt) {
    const value = evt.target.value;
    Object.keys(fields).map((item) => {
      if (item === evt.target.name) {
        let regex = /^\d{0,15}?$/;
        if (regex.test(evt.target.value)) {
          setFields({
            ...fields,
            [evt.target.name]: value,
          });
        }
      } else if (evt.target.name !== "phoneNumber") {
        setFields({
          ...fields,
          [evt.target.name]: value,
        });
      }
    });
  }

  function handleQuantityChange(evt) {
    const value = evt.target.value;
    setError({
      quantity:
        "",
    });

    let regex = /^\d{0,2}?$/;

    let qauntity = parseFloat(value);
    let manualInventoryCount = parseFloat(giftcartManualInvcount?.count);

    if (fields?.product?.registered_by_id === "manual_inventory") {
      if (manualInventoryCount >= qauntity) {
        setFields({
          ...fields,
          [evt.target.name]: value,
        });
      } else {
        setFields({
          ...fields,
          [evt.target.name]: "",
        });
        setToastMessage(
          "You not able to enter quantity more then your giftcard qauntity."
        );
        toggleShowToast();
      }
    } else if (fields?.product?.registered_by_id !== "manual_inventory") {
      if (regex.test(evt.target.value)) {
        setFields({
          ...fields,
          [evt.target.name]: value,
        });
      }
    }
  }

  // Getting giftcart manual inventory count
  const getGiftCardManualInvCount = (count) => {
    setGiftcartManualInvcount(count);
  };

  const handleChangeDenomination = (e) => {
    setFields({ ...fields, denomination: e });
    if (fields.product?.giftcard?.registered_by_id === "manual_inventory") {
      const giftcardInfo = {
        giftcardId: fields.product?.giftcard?.id,
        denominationId: e?.id,
        card_status: "availabel",
      };
      dispatch(
        postGiftCardManualInvCount(giftcardInfo, getGiftCardManualInvCount)
      );
    }
  };
  // const handleChangeBrand = (e) => {
  //   setFields({ ...fields, brand: e });
  //   // dispatch(fetchBrandCategoryByID(e.id));
  // };

  const handleChangeDelivaryType = (e) => {
    setFields({
      ...fields,
      deliveryType: e,
      deliveryAddressline1: "",
      deliveryAddressline2: "",
      pincode: "",
      deliveryOptions: "",
    });
  };

  const handleChangeDelivaryOptions = (e) => {
    setFields({ ...fields, deliveryOptions: e });
  };

  function handlePinNumberChange(evt) {
    const value = evt.target.value;
    let regex = /^\d{0,10}(\.\d{0,2})?$/;
    if (regex.test(evt.target.value)) {
      setFields({
        ...fields,
        [evt.target.name]: value,
      });
    }
  }

  function handleEmailIDChange(evt) {
    const target = evt.target;
    let value = target.value;
    const emailid = target.name;

    setFields({
      ...fields,
      [emailid]: value,
    });
    setError({ ...error, emailid: "" });
  }

  //for validations
  const validate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!fields.product?.giftcard) {
      setError({ product: "Please select product" });
      if(inputElementProdType.current){
        inputElementProdType.current.focus()
      }
      return false;
    } else if (
      !fields.denomination &&
      fields?.product?.giftcard?.giftcard_value_type === "denominations"
    ) {
      setError({ denomination: "Please select denomination" });
      if(inputElementDenomi.current){
        inputElementDenomi.current.focus()
      }
      return false;
    } else if (
      fields.product?.giftcard?.registered_by_id === "manual_inventory" &&
      giftcartManualInvcount?.count === 0
    ) {
      setError({ manualInventoryDenomination: "Quantity not available" });
      return false;
    } else if (
      !fields.rangeDenomination &&
      fields?.product?.giftcard?.giftcard_value_type === "range"
    ) {
      setError({ rangeDenomination: "Please enter denomination" });
      if(inputElementRang.current){
        inputElementRang.current.focus()
      }
      return false;
    } else if (error?.rangeDenomination) {
      return false;
    } else if (!fields.quantity) {
      setError({ quantity: "Please enter quantity" });
      if(inputElementQuantity.current){
        inputElementQuantity.current.focus()
      }
      return false;
    } else if (!fields.deliveryType) {
      setError({ deliveryType: "Please select deliveryType" });
      if(inputElementDeliType.current){
        inputElementDeliType.current.focus()
      }
      return false;
    } else if (
      !fields?.deliveryAddressline1 &&
      fields?.deliveryType?.name !== "Digital"
    ) {
      setError({ deliveryAddressline1: "Please enter address" });
      if(inputElementDeliOptPhyAddress.current){
        inputElementDeliOptPhyAddress.current.focus()
      }
      return false;
      // } else if (
      //   !fields?.deliveryAddressline2 &&
      //   fields?.deliveryType?.name !== "Digital"
      // ) {
      //   setError({ deliveryAddressline2: "Please enter address" });
      //   return false;
    } else if (
      !fields.deliveryOptions &&
      fields?.deliveryType?.name === "Digital"
    ) {
      setError({ deliveryOptions: "Please select delivery option" });
      if(inputElementDeliOptDigital.current){
        inputElementDeliOptDigital.current.focus()
      }
      return false;
    } else if (!fields.emailid) {
      setError({ emailid: "Please enter email id" });
      if(inputElementEmail.current){
        inputElementEmail.current.focus()
      }
      return false;
    } else if (!fields.emailid || emailRegex.test(fields.emailid) === false) {
      setError({ emailid: "Please enter valid email id" });
      return false;
    }

    return true;
  };

  const submit = () => {
    let data = fields.product;
    let quantity = fields.quantity;
    let denomination = fields.denomination;
    let val = validate();
    if (val) {
      let createOrderObj = {
        product: fields.product?.giftcard,
        quantity: fields.quantity,
        denomination: fields.denomination,
        countryCurrency: fields.countryCurrency,
        brand: "",
        deliveryType: fields.deliveryType,
        deliveryAddressline1: fields.deliveryAddressline1,
        deliveryAddressline2: fields.deliveryAddressline2,
        deliveryOptions: fields?.deliveryOptions,
        pincode: fields.pincode,
        cityName: fields.cityName,
        emailid: fields.emailid,
        phoneNumber: fields?.phoneNumber,
        selectedProduct: data,
        selectedQuantity: quantity,
        selectedDenomination: denomination,
        rangeDenomination: fields.rangeDenomination,
        selectedClient: location.state.selectedClient,
        selectedStore: selectedStoreOrOrgData?.seletedStore,
        selectedOrganization: selectedStoreOrOrgData?.selectedOrganization,
        store: location.state.type,
        clientType: location.state.clientType,
      };

      dispatch(SetCreateOrderData(createOrderObj));
      history("/order-summary");
    } else {
      setToastMessage("Mandatory fields are required.");
      toggleShowToast();
    }
  };

  const showModel = (id) => {
    dispatch(fetchGiftCardSummeryById(id));
    setShowModal(true);
  };

  return (
    <>
      {giftcardsloading === false ? (
        <div className="d-flex flex-column justify-content-start py-3 gap-3 px-4 bg-light">
          <div className="d-flex flex-column my-3">
            <div className="d-flex flex-row align-items-center gap-3">
              <LeftIcon
                onClick={() => history("/gift-card-distribution")}
                className="cursor"
              />
              <span className="font-bold font-32 ">
                {t("Card distribution")}
              </span>
            </div>
            <span className="font-bolder font-14 text-muted ">
              {t("Select Product & Attributes")}
            </span>
          </div>

          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
            <div className="d-flex flex-column gap-4 mt-4 width-65 ">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Select Product")}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip title={"Select any giftcard"} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <Select
                    ref={inputElementProdType}
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select product name")}
                    getOptionLabel={(organizationmappedGiftcardList) =>
                      organizationmappedGiftcardList?.giftcard?.default_title
                    }
                    options={organizationmappedGiftcardList}
                    styles={CUSTOM_DROPDOWN_STYLE}
                    value={fields.product}
                    onChange={handleChangeProduct}
                    isClearable
                  />
                  {!fields.product && error.product ? (
                    <label className="text-error">{error.product}</label>
                  ) : null}
                </div>
              </div>

              {fields?.product && fields?.product ? (
                <div className="d-flex">
                  <div className=" d-flex flex-column justify-content-between rounded-3  w-100">
                    <table responsive="xl">
                      <tbody>
                        <tr className="hover-row ">
                          <td>
                            <div className="d-flex flex-row justify-content-start align-items-center gap-3 py-1">
                              <span className="mx-1 rounded-3">
                                <img
                                  src={
                                    selectedMapGiftcard[0]?.images ? (
                                      selectedMapGiftcard[0]?.images[0].file_url
                                    ) : (
                                      <MyntraImg />
                                    )
                                  }
                                  width={60}
                                  height={60}
                                  alt="sign in pic not found"
                                  className="img-fluid "
                                />
                              </span>

                              <div className="d-flex flex-column justify-content-center">
                                <span
                                  className="font-16 font-boldest text-dark "
                                  onClick={() =>
                                    showModel(selectedMapGiftcard[0]?.id)
                                  }
                                >
                                  {selectedMapGiftcard[0]?.default_title &&
                                    selectedMapGiftcard[0]?.default_title}
                                </span>
                                <span className="font-13 font-bold text-dark">
                                  {
                                    strFirstLetterCap()
                                    // selectedMapGiftcard[0]?.giftcard_value_type
                                  }
                                  {/* <span> denomination</span> */}
                                </span>
                                <span className="font-13 font-bold text-dark">
                                  {selectedMapGiftcard[0]?.registered_by_id &&
                                  selectedMapGiftcard[0]?.registered_by_id ===
                                    "powered_by_ehadaya"
                                    ? "Powered by ehadaya"
                                    : selectedMapGiftcard[0]
                                        ?.registered_by_id ===
                                      "manual_inventory"
                                    ? "Manual inventory"
                                    : selectedMapGiftcard[0]
                                        ?.registered_by_id ===
                                      "3rd_party_suppliers"
                                    ? "3rd party suppliers"
                                    : null}
                                </span>
                                <span className="font-13 font-bold text-muted">
                                  {selectedMapGiftcard[0]?.id &&
                                    selectedMapGiftcard[0]?.id}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-start ">
                              <span className="font-15 font-bold text-dark bg-dark bg-opacity-25 rounded-3 p-1 ">
                                {selectedMapGiftcard[0]?.country?.currency +
                                  " - " +
                                  selectedMapGiftcard[0]?.country
                                    ?.currency_symbol}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                            <div className="d-flex justify-content-center ">
                              <span className="font-15 font-bold text-success bg-04 px-1 py-1 gap-2 rounded-3">
                                {fields?.product?.availability &&
                                fields?.product?.availability === "in_stock"
                                  ? "In stock"
                                  : fields?.product?.availability ===
                                    "out_of_stock"
                                  ? "Out of stock"
                                  : " "}
                              </span>
                            </div>
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}

              {fields?.product?.giftcard?.giftcard_value_type ===
              "denominations" ? (
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Denomination")}
                      <span className="text-danger">*</span>
                    </span>
                    <InfoTooltip title={"Enter the product denomination"} />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      ref={inputElementDenomi}
                      noOptionsMessage={() => "Not found"}
                      placeholder={t("Select or type product Denomination")}
                      getOptionLabel={(denominationState) =>
                        denominationState?.denomination_value
                      }
                      options={denominationState}
                      value={fields.denomination}
                      onChange={handleChangeDenomination}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                    />
                    {!fields.denomination && error.denomination ? (
                      <label className="text-error">{error.denomination}</label>
                    ) : null}
                    {fields?.product?.giftcard?.registered_by_id ===
                      "manual_inventory" &&
                    fields.denomination &&
                    giftcartManualInvcount ? (
                      <label
                        className={
                          giftcartManualInvcount.count > 0
                            ? `text-success`
                            : `text-error`
                        }
                      >
                        {giftcartManualInvcount.count > 0
                          ? `Available quantity : ${giftcartManualInvcount?.count}`
                          : `Quantity not available`}
                      </label>
                    ) : null}
                  </div>
                </div>
              ) : fields?.product?.giftcard?.giftcard_value_type === "range" ? (
                <div className="d-flex flex-column  ">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Range denomination")}
                        <span className="text-danger">*</span>
                      </span>
                      <span className="font-bold font-14">
                        {`(${t("Min")}:${
                          fields?.product?.giftcard?.min_amount
                        }-${t("Max")}:${
                          fields?.product?.giftcard?.max_amount
                        })`}{" "}
                      </span>
                      <InfoTooltip
                        title={"Enter range denomination giftcard"}
                      />
                    </div>
                    <InputField
                      inputRef={inputElementRang}
                      placeholder={t("Enter denomination range")}
                      name="rangeDenomination"
                      value={fields?.rangeDenomination}
                      onChange={handleRangeDenoChange}
                    />
                  </div>
                  {error?.rangeDenomination && error.rangeDenomination ? (
                    <label className="text-error">
                      {error.rangeDenomination}
                    </label>
                  ) : null}
                </div>
              ) : null}

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Quantity")}
                      <span className="text-danger">*</span>
                    </span>
                    <InfoTooltip title={"Enter the product quantity"} />
                  </div>
                  <InputField
                    inputRef={inputElementQuantity}
                    placeholder={t("Enter quantity")}
                    type="text"
                    name="quantity"
                    value={fields.quantity}
                    disabled={
                      fields?.product?.giftcard?.registered_by_id ===
                        "manual_inventory" && giftcartManualInvcount.count === 0
                        ? true
                        : false
                    }
                    onChange={handleQuantityChange}
                  />
                </div>
                {error.quantity ? (
                  <label className="text-error">{error.quantity}</label>
                ) : null}
              </div>
              <div className="d-flex flex-column gap-4">
                {fields?.product?.country?.name &&
                fields?.product?.country?.currency ? (
                  <div className="d-flex flex-column gap-4  ">
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">
                          {t("Country & Currency")}
                        </span>
                        <InfoTooltip title={"Enter country name"} />
                      </div>
                      <InputField
                        inputRef={inputElementCountry}
                        placeholder={t("Enter country")}
                        name="CountryName"
                        value={
                          fields?.product?.country?.name +
                          " - " +
                          fields?.product?.country?.currency
                        }
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>
                ) : null}

                {/* <div className="d-flex flex-column gap-4  ">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">Brand</span>
                  <InfoTooltip
                    title={"Maximum 100 characters. No HTML or emoji allowed"}
                  />
                </div>
                <InputField
                  placeholder={"Brand"}
                  name="brand"
                  value={fields?.product?.brand}
                  onChange={handleChange}
                  disabled
                />
              </div>
            </div>*/}
                {!location?.state?.selectedOrganization ? (
                  <div className="d-flex flex-column gap-4  ">
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <span className="font-bold font-14">
                          {t("Organization")}
                        </span>
                        <InfoTooltip title={"Select organization"} />
                      </div>
                      {/* <InputField
                        placeholder={"Enter organization"}
                        name="organization"
                        value={
                          location.state.selectedStore?.organisation_id
                            ? location.state.selectedStore?.organisation_id
                            : location.state.selectedClient.organisation
                        }
                        onChange={handleChange}
                        disabled
                      /> */}
                    </div>
                  </div>
                ) : null}
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Delivery type")}
                      <span className="text-danger">*</span>
                    </span>
                    <InfoTooltip title={"Select delivery type"} />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      ref={inputElementDeliType}
                      noOptionsMessage={() => "Not found"}
                      placeholder={t("Select delivery type")}
                      getOptionLabel={(deliveryType) => deliveryType.name}
                      options={deliveryType}
                      value={fields.deliveryType}
                      onChange={handleChangeDelivaryType}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                    />
                    {!fields.deliveryType && error.deliveryType ? (
                      <label className="text-error">{error.deliveryType}</label>
                    ) : null}
                  </div>
                </div>
              </div>

              {fields?.deliveryType?.name === "Physical" ? (
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-column gap-1">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Delivery Address line 1")}
                        <span className="text-danger">*</span>
                      </span>
                      <InfoTooltip title={"Enter physical address"} />
                    </div>
                    <InputField
                      inputRef={inputElementDeliOptPhyAddress}
                      placeholder={t("Enter delivery address line 1")}
                      name="deliveryAddressline1"
                      value={fields.deliveryAddressline1}
                      onChange={handleChange}
                    />
                    {!fields.deliveryAddressline1 &&
                    error.deliveryAddressline1 ? (
                      <label className="text-error">
                        {error.deliveryAddressline1}
                      </label>
                    ) : null}
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Delivery Address line 2")}
                        {/* <span className="text-danger">*</span> */}
                      </span>
                      <InfoTooltip title={"Enter physical address"} />
                    </div>
                    <InputField
                      placeholder={t("Enter delivery address line 2")}
                      name="deliveryAddressline2"
                      value={fields.deliveryAddressline2}
                      onChange={handleChange}
                    />
                    {!fields.deliveryAddressline2 &&
                    error.deliveryAddressline2 ? (
                      <label className="text-error">
                        {error.deliveryAddressline2}
                      </label>
                    ) : null}
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">{t("Pin code")}</span>
                      <InfoTooltip title={"Enter pin code"} />
                    </div>
                    <InputField
                      type="text"
                      placeholder={t("Enter pin code")}
                      name="pincode"
                      value={fields.pincode}
                      onChange={handlePinNumberChange}
                    />
                  </div>

                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("City name")}
                      </span>
                      <InfoTooltip title={"Enter city name"} />
                    </div>
                    <InputField
                      placeholder={t("Enter city name")}
                      type="text"
                      name="cityName"
                      value={fields.cityName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              ) : null}
              {fields?.deliveryType &&
              fields?.deliveryType?.name === "Digital" ? (
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Delivery options")}
                      <span className="text-danger">*</span>
                    </span>
                    <InfoTooltip title={"Choose giftcard delivery options"} />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      ref={inputElementDeliOptDigital}
                      noOptionsMessage={() => "Not found"}
                      placeholder={t("Select delivery type")}
                      getOptionLabel={(deliveryOption) => deliveryOption.name}
                      options={deliveryOption}
                      value={fields.deliveryOptions}
                      onChange={handleChangeDelivaryOptions}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                    />
                    {!fields.deliveryOptions && error.deliveryOptions ? (
                      <label className="text-error">
                        {error.deliveryOptions}
                      </label>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-1">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span className="font-bold font-14">
                        {t("Email ID")}
                        <span className="text-danger">*</span>
                      </span>
                      <InfoTooltip title={"Enter mail id"} />
                    </div>
                    <InputField
                      inputRef={inputElementEmail}
                      placeholder={t("Enter email ID")}
                      type="email"
                      name="emailid"
                      value={
                        fields.emailid
                        // location.state.selectedStore?.email_id
                        //   ? location.state.selectedStore?.email_id
                        //   : location.state.selectedClient.email
                      }
                      onChange={handleEmailIDChange}
                    />
                    {error.emailid ? (
                      <label className="text-error">{error.emailid}</label>
                    ) : null}
                  </div>
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Phone number")}
                    </span>
                    <InfoTooltip title={"Enter phone number"} />
                  </div>
                  <div className="d-flex flex-row gap-2 align-items-center w-100">
                    {/* <InputGroupTxt
                  title={
                    fields?.countryCurrency?.phone_code
                      ? "+" + fields?.countryCurrency?.phone_code
                      : ""
                  } */}
                    <InputField
                      placeholder={t("Phone number")}
                      type="number"
                      name="phoneNumber"
                      value={
                        fields.phoneNumber
                        // location.state.selectedStore?.store_phone_no
                        //   ? location.state.selectedStore?.store_phone_no
                        //   : location.state.selectedClient.contact_no
                      }
                      maxLength={15}
                      onChange={handleChange}
                    />
                    <div>
                      <div className="d-flex flex-row justify-content-start text-dark gap-1 align-items-center w-100">
                        <span>
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="checkboxNoLabel"
                            // checked={products.filter(product => product?.isChecked !== true).length < 1}
                            // onChange={allCheckedHandle}
                          />
                        </span>

                        <span className="font-16 font-boldest text-muted">
                          {t("WhatsApp")}
                        </span>

                        <span>
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="checkboxNoLabel"
                            // checked={products.filter(product => product?.isChecked !== true).length < 1}
                            // onChange={allCheckedHandle}
                          />
                        </span>

                        <span className="font-16 font-boldest text-muted">
                          {t("SMS")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <Button onClick={submit} text={t("Create Order")} type="primary" />
          </div>

          <ModalComp
            size="lg"
            showModal={showModal}
            // handleClose={handleClose}
            component={
              <ModelForGiftCardSummery
                handleClose={handleClose}
                giftcardSummery={giftcardSummery}
              />
            }
          />
          <div
            className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg={toastMessage}
              imageType={"warning"}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default CardDistributionProductforStore;
