/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import { PRODUCT_TYPE } from "../../../utils/ConstantData";
import {
  getOrganizationList,
  organizationGiftcardListDelete,
  setSelectedOrganization,
} from "../../../Redux/Organizations/organizationAction";
import AllOrganizationList from "../../../components/Organization/AllOrganizationList";
import { useTranslation } from "react-i18next";

const Organization = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputElementOrg = useRef(null);

  const organizationState = useSelector(
    (state) => state?.organizations?.organizationList
  );

  const [fields, setFields] = useState({
    productType: { name: "Giftcard" },
    organization: "",
  });

  const [error, setError] = useState({
    organization: "",
    productType: "",
  });

  const [permissions, setpermissions] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (organizationState.length === 0) {
      dispatch(getOrganizationList());
    }
  }, []);

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {
    dispatch(organizationGiftcardListDelete())
  }, [])

  useEffect(() => {
  }, [permissions]);

  const handleChangeProductCategory = (e) => {
    setFields({ ...fields, productType: e });
  };

  const handleChangeProduct = (e) => {
    setFields({ ...fields, organization: e });
  };

  const validate = () => {
    if (!fields.organization) {
      setError({ organization: t("Please select organization to map") });
      if(inputElementOrg.current){
        inputElementOrg.current.focus()
      }
      return false;
    } else if (!fields.productType) {
      setError({ productType: t("Please select product type") });
      return false;
    } 
    return true;
  };

  const goToCreateSupplier = () => {
    let val = validate();
    if (val) {
      dispatch(setSelectedOrganization(fields.organization));

      if (fields.productType.name === "Giftcard") {
        history("/organization-mapping-giftcard", {
          state: {
            organization: fields.organization,
          },
        });
      } else {
        // history("/organization-mapping-merchandise", {
        //   state: {
        //     selectedSupplier: fields.organization,
        //     productType: fields.productType,
        //   },
        // });
      }
    }
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">{t("Organization mapping")}</span>
        </div>
      </div>
      {permissions?.all_organization?.create ? (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-4 px-4 width-65">
          <div className="d-flex flex-column gap-3">

            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Select Organization")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Select Organization"}
                />
              </div>

              <div>
                <Select
                ref={inputElementOrg}
                  placeholder={t("Select organization")}
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(organizationState) =>
                    organizationState.partner_name
                  }
                  options={organizationState}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.supplier}
                  onChange={handleChangeProduct}
                  isClearable
                />
                {!fields.organization && error.organization ? (
                  <label className="text-error">{error.organization}</label>
                ) : null}
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Product Category")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Product Category"}
                />
              </div>
              <div>
                <Select
                  placeholder={t("Select product category")}
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(productType) => productType.name}
                  options={PRODUCT_TYPE}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.productType}
                  onChange={handleChangeProductCategory}
                  isClearable
                  isDisabled={true}
                />
                {!fields.productType && error.productType ? (
                  <label className="text-error">{error.productType}</label>
                ) : null}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row gap-3 mt-3">
            <Button
              type="primary"
              text={t("Continue")}
              onClick={() => goToCreateSupplier()}
            />
          </div>
        </div>
      ) : null}

      <div>
        <AllOrganizationList lang={props?.lang} />
      </div>
    </div>
  );
};

export default Organization;
