import {
  GET_PRODUCT_TYPE_REQUEST,
  GET_PRODUCT_TYPE_SUCCESS,
  GET_PRODUCT_TYPE_FAILURE
} from './prodtypeType';
import axios from "axios";
import { baseUrl, getToken } from "../../utils/prodURL";

export const getProductTypeRequest = () => {
  return {
    type: GET_PRODUCT_TYPE_REQUEST,
  };
};

export const getProductTypeSuccess = (producttype) => {
  return {
    type: GET_PRODUCT_TYPE_SUCCESS,
    payload: producttype,
  };
};

export const getProductTypeFailure = (error) => {
  return {
    type: GET_PRODUCT_TYPE_FAILURE,
    payload: error,
  };
};

export const fetchProductTypeList = (type) => {
  return (dispatch) => {
    dispatch(getProductTypeRequest());
    let prodType = type.replace(/"/g, '');

    let config = {
      method: "get",
      url: `${baseUrl}/v1/products/${prodType}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios(config)
      .then((response) => {
        //  console.log("response" + JSON.stringify(response.data.result));
        if (response.data.code === 200) {
          dispatch(getProductTypeSuccess(response.data.result))
        } else {
          dispatch(getProductTypeFailure("Something went wrong!! Please try again!!"));
        }
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          getProductTypeFailure("Something went wrong!! Please try again!!")
        );
      });
  };
};
