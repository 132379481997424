import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import PulseLoader from "../../../components/Loader/PulseLoader";
import Organizations from "../../../components/Organization/Organizations";
import { getAllOrganizationList } from "../../../Redux/Organizations/organizationAction";

const OrganizationDashboard = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const organizationState = useSelector(
    (state) => state?.organizations?.organizations
  );
  const loading = useSelector((state) => state?.organizations?.loading);

  useEffect(() => {
    window.scrollTo(0, 0);
    // if(organizationState.length===0){
    // dispatch(getAllOrganizationList());
    // }
  }, []);

  const creatNewOrganization = () => {
    history("/organization-creation");
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">Organization Dashboard</span>
        </div>
        <div>
          <Button
            type="primary"
            text="+Create Organization"
            onClick={() => creatNewOrganization()}
          />
        </div>
      </div>
      <div className="d-flex ">
        <Organizations organizationState={organizationState} />
      </div>
      {/*  {loading === false ? (
        <div className="d-flex ">
          <Organizations organizationState={organizationState}/>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center"><PulseLoader type={"data"} /></h5>
        </div>
      )}*/}
    </div>
  );
};

export default OrganizationDashboard;
