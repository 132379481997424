/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import AdminRole from "../../components/UserRole/AdminRole";
import { useTranslation } from "react-i18next";

const UserRoles = (props) => {
  const history = useNavigate();
  const { t } = useTranslation();

  const [permissions, setpermissions] = useState();
  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {

  }, [permissions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addUser = () => {
    history("/roles-management/user-creation");
  };

  return (
    <div className="d-flex flex-column justify-content-start pt-2 px-4 bg-light gap-3 w-100">
      <div className="d-flex flex-column justify-content-start  w-100">
        <div className="d-flex flex-row justify-content-between align-items-center mt-3 w-100">
          <div className="d-flex flex-row align-items-center gap-3">
            <span className="font-bold font-32 my-2">{t("Users")}</span>
          </div>
          {permissions?.user?.create ? (
            <div>
              <Button
                type="primary"
                text={t("+ Add user")}
                onClick={() => addUser()}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="d-flex mb-3 pb-4 ">
        <AdminRole lang={props?.lang} />
      </div>
    </div>
  );
};

export default UserRoles;
