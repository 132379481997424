import {
  FETCH_CLIENT_MAPPED_PRODCUT_REQUEST,
  FETCH_CLIENT_MAPPED_PRODCUT_SUCCESS,
  FETCH_CLIENT_MAPPED_PRODCUT_FAILURE,
  SET_SELECTED_CLIENT_DATA
} from "./ClientMerchandiseDistributionType";

const initialClientMerState = {
    loading: false,
    clientMappedMerchandise: null,
    distMerSelectedClientData: null,
    error: "",
}

export const clientMappedMerchandiseReducer = (state = initialClientMerState, action) => {
    switch(action.type) {
        case FETCH_CLIENT_MAPPED_PRODCUT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_CLIENT_MAPPED_PRODCUT_SUCCESS:
            return {
                ...state,
                loading: false,
                clientMappedMerchandise: action.payload,
                next_iteration: action.payload.moredata,
                error: ''
            }
        case FETCH_CLIENT_MAPPED_PRODCUT_FAILURE:
            return {
                ...state,
                loading: false,
                clientMappedMerchandise: [],
                error: action.payload
            }
        case SET_SELECTED_CLIENT_DATA:
            return {
                ...state,
                loading: false,
                clientMappedMerchandise: [],
                distMerSelectedClientData: action.payload,
                error: ""
            }
        default: return state
    }
}

export default clientMappedMerchandiseReducer