import {
    FETCH_SUPPLIER_MAPPING_REQUEST,
    FETCH_SUPPLIER_MAPPING_SUCCESS,
    FETCH_SUPPLIER_MAPPING_FAILURE,
    GET_GIFTCARD_SUPPLIER_MAPPING_REQUEST,
    GET_GIFTCARD_SUPPLIER_MAPPING_SUCCESS,
    GET_GIFTCARD_SUPPLIER_MAPPING_FAILURE,
    GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID
  } from "./SupplierMappingType";
  
  const initialState = {
    supplierMappingData: [],
    loading: false,
    error: "",
    merchandiseVariants:[],
    giftcards:[]
  };
  
  function SupplierMappingDataReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_SUPPLIER_MAPPING_REQUEST:
        return {
            ...state,
          loading: true,
          error: "",
        };
      case FETCH_SUPPLIER_MAPPING_SUCCESS:
        return {
            ...state,
          loading: false,
          supplierMappingData: action.payload,
          error: "",
        };
      case FETCH_SUPPLIER_MAPPING_FAILURE:
        return {
          ...state,
          loading: false,
          supplierMappingData: [],
          error: action.payload,
        };
        // get giftcards to mapped with supplier
        case GET_GIFTCARD_SUPPLIER_MAPPING_REQUEST:
          return {
              ...state,
            loading: true,
            error: "",
          };
        case GET_GIFTCARD_SUPPLIER_MAPPING_SUCCESS:
          return {
              ...state,
            loading: false,
            giftcards: action.payload,
            error: "",
          };
        case GET_GIFTCARD_SUPPLIER_MAPPING_FAILURE:
          return {
            ...state,
            loading: false,
            giftcards: [],
            error: action.payload,
          };

        // get variants
        case GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID:
          return {
              ...state,
            loading: false,
            merchandiseVariants: action.payload,
            error: "",
          };
      default:
        return state;
    }
  }
  
  export default SupplierMappingDataReducer;