import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import Dropdown from "../Dropdown/Dropdown";
import InfoTooltip from "../Tooltip/InfoTooltip";
import { ReactComponent as BlueIcon } from "../../assests/svg/Product/Blue_Icon.svg";
import { ReactComponent as Searchicon } from "../../assests/svg/search.svg";
import MyntraImg from "../../assests/img/ImageGift.png";
import { ReactComponent as FilterIcon } from "../../assests/svg/Filter_Icon.svg";

import Button from "../Button/Button";

const MappingProductToClient = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

 
const products=[{
  id:0,productStatus:"In stock",hadayaPrice:"$10",productId:"ehid_iphone353637",currency:"USD($)",currencyType:'Reloadable'
},
{
  id:1,productStatus:"In stock",hadayaPrice:"$10",productId:"ehid_iphone353637",currency:"USD($)",currencyType:'Reloadable'
},
{
  id:3,productStatus:"In stock",hadayaPrice:"$10",productId:"ehid_iphone353637",currency:"USD($)",currencyType:'Reloadable'
},
{
  id:4,productStatus:"In stock",hadayaPrice:"$10",productId:"ehid_iphone353637",currency:"USD($)",currencyType:'Reloadable'
},
{
  id:5,productStatus:"In stock",hadayaPrice:"$10",productId:"ehid_iphone353637",currency:"USD($)",currencyType:'Reloadable'
}
]

  return (
    <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100 mb-3">
      <div className="d-flex flex-coloumn justify-content-between align-items-center mx-2">
        <div className="d-flex gap-3">
          <Tag />
          <span className="font-20 font-bold">Currency & criteria #1</span>
        </div>
        <div className="d-flex flex-row gap-2">
          <span>
            <Button type="primary" text={"Allocate Float"} />
          </span>
          <span>
            <Button type="rounded" text={"Delete"} />
          </span>
        </div>
      </div>
      <div className="d-flex flex-column gap-4 mt-4 w-50 mx-5">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">Select Currencies</span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <Dropdown title={"Select product"} />
        </div>
      </div>
      <div className="d-flex flex-column gap-4 mt-4 w-50 mx-5">
        <span className="text-muted">Containing id</span>
      </div>
      <div className="d-flex flex-column gap-4 mt-4 w-50 mx-5">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <span className="font-bold font-14">
              Select Transactions criteria
            </span>
            <InfoTooltip
              title={"Maximum 100 characters. No HTML or emoji allowed"}
            />
          </div>
          <Dropdown title={"Select product"} />
        </div>
      </div>
      <div className="d-flex flex-column py-4">
        <div className="d-flex flex-row w-100  align-items-center">
          <div className="p-2 flex-grow-1">
            <div className="d-flex gap-3 my-2  align-items-center">
              <Tag />
              <span className="font-20 font-bold">Gift Cards</span>
              <span className="font-20 font-bold">
                <div className="Inputwithicon">
                  <div id="search">
                    <Searchicon />
                  </div>
                  <input
                    type="text"
                    className="input-custom w-100"
                    name="search"
                    placeholder="Search by name,email or client"
                  />
                </div>
              </span>
              <span>
                <Button type={"primary"} text={"Download CSV"} />
              </span>
            </div>
          </div>
          <div className="p-2">
            <Button type="secondary" text="All" />
          </div>
          <div className="p-2">
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle text-muted"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Currency
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <Link className="dropdown-item" to={""}>
                    {" "}
                    Apple 13
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={""}>
                    Apple 13
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={""}>
                    Apple 13
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="p-2">
            <span className="my-2">
              <FilterIcon  />
            </span>
          </div>
        </div>
        <div className="card-body d-flex flex-column justify-content-between align-item-center gap-5">
          <table responsive="xl">
            <tbody>
              <tr className="border-bottom">
                <td className="text-muted" colSpan={3}>
                  <div className="d-flex flex-row justify-content-start">
                    <span>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        aria-label="..."
                      />
                    </span>
                    <span className="mx-3">Product</span>
                  </div>{" "}
                </td>
                <td className="text-muted"> Status</td>
                <td className="text-muted">Hadaya Price</td>
                <td className="text-muted"> Product ID</td>
                <td className="text-muted"> Currency</td>
                <td className="text-muted"> Type</td>
              </tr>
            {products.map((item)=>(
            
              <tr key={item.id}>
                <td colSpan={3}>
                  <div className="d-flex flex-row justify-content-start align-items-center py-3">
                    <span>
                      {" "}
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        aria-label="..."
                      />
                    </span>
                    <span className="mx-3 rounded-3">
                    <img
              src={MyntraImg}
              alt="not found"
              className="img-fluid width-75"
            />
                    </span>
                    <div>
                    <span className="font-15 font-boldest text-dark ">Myntra Gift card</span><br/>
      <span className="font-13 font-bold text-muted">by Myntra Fashion</span>
      </div>
                  </div>
                </td>
                <td>
                <span className="font-15 font-bold text-success bg-04 px-1 mx-2 py-1 rounded-3">
                    {item.productStatus}
                  </span>
                </td>
                <td>
                <span className="font-20 font-bolder px-2 justify-content-center text-dark">{item.hadayaPrice}</span>
                </td>
                <td>
                  <span className="fs-light text-dark bg-dark bg-opacity-25 rounded-3 px-1 py-1">
                    {item.productId}
                  </span>
                </td>
                <td>
                  <span className="fs-light  text-dark bg-dark bg-opacity-25 rounded-3 gap-4 px-1 py-1">
                    {item.currency}
                  </span>
                  <span className="mx-1">
                    <BlueIcon />
                  </span>
                </td>
                <td>
                  <span className="fs-light  text-dark bg-dark bg-opacity-25 rounded-3 px-1 py-1">
                    {item.currencyType}
                  </span>
                </td>
              </tr>
            
              ) )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-row  justify-content-center align-item-center gap-3">
          <div className="d-flex flex-row  justify-content-center py-2">
            <Button type={"rounded"} text={"Load more"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MappingProductToClient;
