/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import { CUSTOM_DROPDOWN_STYLE } from "../../../utils/CustomeStyles";
import { setSelectedClient } from "../../../Redux/Client/clientAction";
import AllClientList from "../../../components/Client/AllClientList";
import Loader from "../../../components/Loader/Loader";
import { getClientGcListDelete } from "../../../Redux/Distributions/ClientGiftcardDistributions/ClientGiftcardDistributionAction";
import { useTranslation } from "react-i18next";

const ClientMappingDashboard = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputElementClient = useRef(null);
  const inputElementCategory = useRef(null);

  const clientsState = useSelector((state) => state?.clients?.client);

  const giftcardLoading = useSelector((state) => state?.giftcard.loading);

  const merchandiseLoading = useSelector(
    (state) => state?.merchadiseAllProduct?.loading
  );

  const productType = [{ name: "Giftcard" }, { name: "Merchandise" }];

  const [fields, setFields] = useState({
    productType: "",
    clients: "",
  });
  const [error, setError] = useState({
    productType: "",
    clients: "",
  });

  const [permissions, setpermissions] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  // Deleting client mapped product list from redux
  useEffect(() => {
    dispatch(getClientGcListDelete())
  }, [])

  useEffect(() => {
  }, [permissions]);



  const handleChangeProductCategory = (e) => {
    setFields({ ...fields, productType: e });
  };

  const handleChangeProduct = (e) => {
    setFields({ ...fields, clients: e });
  };
  const validate = () => {
    if (!fields.clients) {
      setError({ clients: t("Please select client to map") });
      if(inputElementClient.current){
        inputElementClient.current.focus()
      }
      return false;
    }else if (!fields.productType) {
      setError({ productType: t("Please select product type") });
      if(inputElementCategory.current){
        inputElementCategory.current.focus()
      }
      return false;
    }
    return true;
  };

  const goToClientToProductmap = () => {
    let val = validate();
    if (val) {
      dispatch(setSelectedClient(fields.clients));
      if (fields.productType.name === "Giftcard") {
        history("/clients-mapping-giftcards", {
          state: {
            clients: fields.clients,
          },
        });
      } else {
        history("/clients-mapping-merchandise-list");
      }
    }
  };

  return (
    <div className="d-flex flex-column justify-content-start py-4 pb-2 px-4 bg-light">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center pb-2 gap-3 my-3">
          <span className="font-40 font-bold">{t("Client mapping")}</span>
        </div>
      </div>
      {permissions?.all_clients?.create ? (
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-4 px-4 width-65">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Select Client")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Select client"}
                />
              </div>

              <div>
                <Select
                ref={inputElementClient}
                  placeholder={t("Select client")}
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(clientsState) => clientsState.partner_name}
                  options={clientsState}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.clients}
                  onChange={handleChangeProduct}
                  isClearable
                />
              </div>

              {!fields.clients && error.clients ? (
                <label className="text-error">{error.clients}</label>
              ) : null}
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-row align-items-center gap-2">
                <span className="font-bold font-14">
                  {t("Product Category")}<span className="text-danger">*</span>
                </span>
                <InfoTooltip
                  title={"Select Product Category"}
                />
              </div>
              <div>
                <Select
                ref={inputElementCategory}
                  placeholder={t("Select product category")}
                  noOptionsMessage={() => "Not found"}
                  getOptionLabel={(productType) => productType.name}
                  options={productType}
                  styles={CUSTOM_DROPDOWN_STYLE}
                  value={fields.productType}
                  onChange={handleChangeProductCategory}
                  isClearable
                />
              </div>
              {!fields.productType && error.productType ? (
                <label className="text-error">{error.productType}</label>
              ) : null}
            </div>
          </div>
          {merchandiseLoading === false && giftcardLoading === false && (
            <div className="d-flex flex-row gap-3 mt-3">
              <Button
                type="primary"
                text={t("Continue")}
                onClick={() => goToClientToProductmap()}
              />
            </div>
          )}
        </div>
      ) : null}

      <div>
        <AllClientList lang={props?.lang} clientsState={clientsState} />
      </div>
      {giftcardLoading === true && (
        <div className="position-fixed top-50 start-50">
          <Loader loading={giftcardLoading} type={"data"} />
        </div>
      )}

      {merchandiseLoading === true && (
        <div className="position-fixed top-50 start-50">
          <Loader loading={merchandiseLoading} type={"data"} />
        </div>
      )}

    </div>
  );
};

export default ClientMappingDashboard;
