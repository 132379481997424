// Input text limit
export const TITLE_TEXT_LIMIT = 30
export const KEYBENEFITS_TEXT_LIMIT = 30
export const PREFIX_TEXT_LIMIT = 8
export const SKU_UNIT_TEXT_LIMIT = 20


// default language
export const DEFAULT_LANG = {
    "language_code": "EN",
    "language": "English",
    "active_flag": true,
    "created_at": "2022-04-22T10:28:37.346000",
    "updated_at": "2022-04-22T10:28:37.346000",
    "id": "62b4a8c3329f3383205d13ae"
}

export const PRODUCT_TYPE = [{ name: "Giftcard" }, { name: "Merchandise" }]

export const VALIDITY_UNITS = [{ name: "Days" }, { name: "Months" }, { name: "Years" }]

export const Variants = [{ name: "Color" }, { name: "Memory" }, { name: "RAM" }]

export const GIFTCARD_REDEMPTION_METHOD = [
    { id: 1, name: "Online", value: "online" },
    { id: 2, name: "In Store", value: "in_store" },
    { id: 3, name: "Both", value: "both" },
];

export const SELL_GIFTCARD_REDEMPTION_METHOD = [
    { id: 1, name: "Online", value: "online" },
    { id: 2, name: "Offline", value: "offline" },
];

export const GIFTCARD_REDEMPTION_AMOUNT = [
    { id: 1, name: "Full Redeem", value: "redeem_full_value" },
    { id: 2, name: "Partial Redeem", value: "redeem_partial_value" },
];

export const DEFAULT_OPTION = [
    { id: 1, name: "Read", value: "read" },
    { id: 1, name: "Read/Write", value: "read/write" }
]

export const deliveryOption = [
    { id: 1, name: "Separate email for each giftcard" },
    { id: 2, name: "One email with giftcard PDF attachment" },
    { id: 3, name: "One email with excel attachment of giftcard URLs" },
    // { id: 4, name: "One email with giftcard URLs" }

];

export const TRANSACTION_CRITARIA_STATUS = [
    { name: "Created" },
    { name: "Successful" }
];
//{ name: "Created", value: "created" }
export const GIFTCARD_ORDER_STATUS = [

    { name: "Successful", value: "successful" },
    { name: "Pending", value: "pending" },
    { name: "In progress", value: "in_progress" },
    { name: "Error", value: "error" },
    { name: "Failed", value: "failed" },
    { name: "Cancelled", value: "cancelled" }
];
//{ name: "Created", value: "created" },
export const MERCHANDISE_ORDER_STATUS = [

    { name: "Successful", value: "successful" },
    { name: "In progress", value: "in_progress" },
    { name: "Shipped", value: "shipped" },
    { name: "Delivered", value: "delivered" },
    { name: "Cancelled", value: "cancelled" },
    { name: "Unable to fulfill", value: "unable_to_fulfill" },
];

export const STATUS = [
    { name: "Available" },
    { name: "Allocated" },
    { name: "Redeemed" },
    { name: "Expired" }
];

export const OrderFilter = [
    { id: 0, name: "All" },
    { id: 1, name: "Giftcard" },
    { id: 2, name: "Merchandise" },
];





//filters
export const PRODUCT_STATUS_FILTERS = [
    { id: 1, name: "Active", value: "active" },
    { id: 2, name: "Deactive", value: "deactive" },
];
export const PRODUCT_STATUS_FILTERS_ACTIVE_DEACTIVE = [
    { id: 0, name: "All", value: null },
    { id: 1, name: "Active", value: true },
    { id: 2, name: "Deactive", value: false },
];
export const DATE_FILTERS = [
    { id: 1, name: "Last 7 days", value: "7" },
    { id: 2, name: "Last 30 days", value: "30" },
    { id: 3, name: "All times", value: "all" }
];

//giftcard dashboard
export const GIFTCARD_FILTERS = [
    { id: 1, name: "Active", value: "active" },
    { id: 2, name: "Deactive", value: "deactive" },
    { id: 3, name: "In Stock", value: "in_stock" },
    { id: 4, name: "Out of Stock", value: "out_of_stock" }
];

export const GIFTCARD_TYPES_FILTERS = [
    { id: 0, name: "All", value: null },
    { id: 1, name: "Fixed Denomination", value: "denominations" },
    { id: 2, name: "Range", value: "range" }
];

//merchandise dashboard
export const MERCHANDISE_FILTERS = [
    { id: 1, name: "Active", value: "active" },
    { id: 2, name: "Deactive", value: "deactive" },
    { id: 3, name: "In Stock", value: "in_stock" },
    { id: 4, name: "Out of Stock", value: "out_of_stock" }
];

export const MERCHANDISE_TYPES_FILTERS = [
    { id: 0, name: "All", value: null },
    { id: 1, name: "Variant", value: true },
    { id: 2, name: "Without Variant", value: false }
];


// User Creation

export const USER_TYPE_CHOICES = [
    { id: 0, name: "Internal", value: "internal" },
    { id: 1, name: "Client", value: "client" },
    { id: 2, name: "Supplier", value: "supplier" },
    { id: 3, name: "Organization", value: "organization" },
    { id: 4, name: "Store", value: "organization_store" }
];

export const USER_SUB_TYPE_CHOICES = [
    { id: 0, name: "Customer", value: "customer" },
    { id: 1, name: "Store", value: "store" },
];

export const PRODUCT_TRANSACTION_DEBIT_CREDIT_FILTER = [
    { id: 0, name: "All", value: null },
    { id: 1, name: "Debit", value: "debit" },
    { id: 2, name: "Credit", value: "credit" },
];

export const SORT_TYPE = {
    ascending: 'ascending',
    descending: 'descending'
}

export const MODULE_DATA_SEARCH = [
    {
        id: 1,
        name: "Giftcards Dashboard",
        value: "giftcards_dashboard",
        url: "/product-dashboard"
    },
    {
        id: 2,
        name: "Merchandise Dashboard",
        value: "merchandise_dashboard",
        url: "/merchandise-dashboard"
    },
    {
        id: 3,
        name: "Manual Inventory",
        value: "manual_inventory",
        url: "/manual-inventory"
    },
    {
        id: 4,
        name: "Bulk Upload",
        value: "bulk_upload",
        url: "/bulk-upload"
    },
    {
        id: 5,
        name: "Add Giftcard",
        value: "add_giftcard",
        url: "/product-creation/name-description"
    },
    {
        id: 6,
        name: "Add Merchandise",
        value: "add_merchandise",
        url: "/add-merchandise"
    },
    {
        id: 7,
        name: "Giftcard Distribution",
        value: "giftcard_distribution",
        url: "/gift-card-distribution"
    },
    {
        id: 8,
        name: "Merchandise Distribution",
        value: "merchandise_distribution",
        url: "/merchandise-distribution"
    }
]