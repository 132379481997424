import {
  GET_EMAIL_TEMPLATE_FAILURE,
  GET_EMAIL_TEMPLATE_REQUEST,
  GET_EMAIL_TEMPLATE_SUCCESS,
  POST_EMAIL_TEMPLATE_FAILURE,
  POST_EMAIL_TEMPLATE_REQUEST,
  POST_EMAIL_TEMPLATE_SUCCESS,
} from "./customeEmailType";

const initialCurrenciesState = {
  loading: false,
  emailTemplate: null,
  customeEmailTemplate: "",
  error: "",
};

const customeEmailReducer = (state = initialCurrenciesState, action) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        emailTemplate: null,
        error: "",
      };
    case GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        emailTemplate: action.payload,
        error: "",
      };
    case GET_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        emailTemplate: null,
        error: action.payload,
      };

    //post
    case POST_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        customeEmailTemplate: "",
        error: "",
      };
    case POST_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        customeEmailTemplate: action.payload,
        error: "",
      };
    case POST_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        customeEmailTemplate: "",
        error: action.payload,
      };
    default:
      return state;
  }
};
export default customeEmailReducer;
