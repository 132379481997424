/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../components/InputField/InputField";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg";
import { ReactComponent as Tag } from "../../assests/svg/Tag/tag.svg";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import ReactSwitchButton from "../../components/Button/ReactSwitchButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserRoleDataByID,
  setUserRole,
  updateUserRole,
} from "../../Redux/UserManagement/userAction";
import { USER_TYPE_CHOICES } from "../../utils/ConstantData";
import Select from "react-select";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import PulseLoader from "../../components/Loader/PulseLoader";
import Toasts from "../../components/Toasts/Toasts";
import { useTranslation } from "react-i18next";

const EditRole = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const loader = useSelector((state) => state?.users?.loading);
  const userRoleData = useSelector((state) => state?.users?.userRoleDataByID);
  const modulePermission = { stage: 1, access: false };
  const subModulePermission = { read: false, create: false, delete: false, stage: 1, }

  const [fields, setFields] = useState({
    roleName: "",
    roleDescription: "",
  });
  const [dropdown, setDropdown] = useState({
    user_type: "",
  });
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  //module
  const [products_module, setProductsModule] = useState({
    stage: 1,
    access: false,
  });
  const [distribution_module, setDistributionModule] = useState({
    stage: 1,
    access: false,
  });
  const [products_attribute_module, setProductsAttributeModule] = useState({
    stage: 1,
    access: false,
  });
  const [manage_users_module, setManageUsersModule] = useState({
    stage: 1,
    access: false,
  });
  const [order_management_module, setOrderManagementModule] = useState({
    stage: 1,
    access: false,
  });
  const [float_management_module, setFloatManagementModule] = useState({
    stage: 1,
    access: false,
  });
  const [partners_module, setPartnersModule] = useState({
    stage: 1,
    access: false,
  });
  const [reports_module, setReportsModule] = useState({
    stage: 1,
    access: false,
  });
  const [epos_module, setEposModule] = useState({ stage: 1, access: false });
  const [SupplierDashboard, setSupplierDashboard] = useState({
    stage: 1,
    access: false,
  });
  const [clientDashboard, setclientDashboard] = useState({
    stage: 1,
    access: false,
  });
  const [organizationDashboard, setorganizationDashboard] = useState({
    stage: 1,
    access: false,
  });
  const [settings_module, setSettingsModule] = useState({
    stage: 1,
    access: false,
  });
  const [client_distribution_module, setClient_distribution_module] = useState({ stage: 1, access: false });

  // sub module
  // products_module
  const [giftcardFields, setGiftcardFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });
  const [merchandiseFields, setMerchandiseFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });
  const [manualInventoryFields, setManualInventoryFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });

  // distribution_module
  const [giftcardDistributionFields, setGiftcardDistributionFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });
  const [merchandiseDistributionFields, setMerchandiseDistributionFields] =
    useState({ read: false, create: false, delete: false, stage: 1 });

  // products_attribute_module
  const [brandFields, setBrandFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });
  const [brandCategoryFields, setBrandCategoryFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });

  // manage_users_module
  const [userFields, setUserFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });
  const [userRoleFields, setUserRoleFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });

  // order_management_module
  const [allOrdersFields, setAllOrdersFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });

  // float_management_module
  const [allFloatsFields, setAllFloatsFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });

  // partners_module
  const [allSuppliersFields, setAllSuppliersFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });
  const [allClientsFields, setAllClientsFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });
  const [allOrganizationFields, setAllOrganizationFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });

  // reports_module
  const [reportsFields, setReportsFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });

  // settings_module
  const [settingsFields, setSettingsFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });

  // settings_module
  const [eposFields, setEposFields] = useState({
    read: false,
    create: false,
    delete: false,
    stage: 1,
  });
  const [giftcardClientDistFields, setGiftcardClientDistFields] = useState({ read: false, create: false, delete: false, stage: 1 });
  const [merchandiseClientDistFields, setMerchandiseClientDistFields] = useState({ read: false, create: false, delete: false, stage: 1 });
  //error

  const [error, setError] = useState({
    roleName: "",
    roleDescription: "",
    user_type: "",
  });

  const productsModuleHandler = () => {
    setProductsModule({
      ...products_module,
      access: !products_module.access,
    });
    setGiftcardFields({
      ...giftcardFields,
      read: !giftcardFields.read,
      create: false,
      delete: false,
    });
    setMerchandiseFields({
      ...merchandiseFields,
      read: !merchandiseFields.read,
      create: false,
      delete: false,
    });
    setManualInventoryFields({
      ...manualInventoryFields,
      read: !manualInventoryFields.read,
      create: false,
      delete: false,
    });
  };

  const distributionModuleHandler = () => {
    setDistributionModule({
      ...distribution_module,
      access: !distribution_module.access,
    });
    setGiftcardDistributionFields({
      ...giftcardDistributionFields,
      read: !giftcardDistributionFields.read,
      create: false,
      delete: false,
    });
    setMerchandiseDistributionFields({
      ...merchandiseDistributionFields,
      read: !merchandiseDistributionFields.read,
      create: false,
      delete: false,
    });
  };

  const productsAttributeModuleHandler = () => {
    setProductsAttributeModule({
      ...products_attribute_module,
      access: !products_attribute_module.access,
    });
    setBrandFields({
      ...brandFields,
      read: !brandFields.read,
      create: false,
      delete: false,
    });
    setBrandCategoryFields({
      ...brandCategoryFields,
      read: !brandCategoryFields.read,
      create: false,
      delete: false,
    });
  };

  const manageUsersModuleHandler = () => {
    setManageUsersModule({
      ...manage_users_module,
      access: !manage_users_module.access,
    });
    setUserFields({
      ...userFields,
      read: !userFields.read,
      create: false,
      delete: false,
    });
    setUserRoleFields({
      ...userRoleFields,
      read: !userRoleFields.read,
      create: false,
      delete: false,
    });
  };

  const orderManagementModuleHandler = () => {
    setOrderManagementModule({
      ...order_management_module,
      access: !order_management_module.access,
    });
    setAllOrdersFields({
      ...allOrdersFields,
      read: !allOrdersFields.read,
      create: false,
      delete: false,
    });
  };

  const floatManagementModuleHandler = () => {
    setFloatManagementModule({
      ...float_management_module,
      access: !float_management_module.access,
    });
    setAllFloatsFields({
      ...allFloatsFields,
      read: !allFloatsFields.read,
      create: false,
      delete: false,
    });
  };

  const partnersModuleHandler = () => {
    setPartnersModule({
      ...partners_module,
      access: !partners_module.access,
    });
    setAllSuppliersFields({
      ...allSuppliersFields,
      read: !allSuppliersFields.read,
      create: false,
      delete: false,
    });
    setAllClientsFields({
      ...allClientsFields,
      read: !allClientsFields.read,
      create: false,
      delete: false,
    });
    setAllOrganizationFields({
      ...allOrganizationFields,
      read: !allOrganizationFields.read,
      create: false,
      delete: false,
    });
  };

  const reportsModuleHandler = () => {
    setReportsModule({
      ...reports_module,
      access: !reports_module.access,
    });
    setReportsFields({
      ...reportsFields,
      read: !reportsFields.read,
      create: false,
      delete: false,
    });
  };

  const settingsModuleHandler = () => {
    setSettingsModule({
      ...settings_module,
      access: !settings_module.access,
    });
    setSettingsFields({
      ...settingsFields,
      read: !settingsFields.read,
      create: false,
      delete: false,
    });
  };

  const clientDistributionModuleHandler = () => {
    setClient_distribution_module({
      ...client_distribution_module,
      access: !client_distribution_module.access
    });
    setGiftcardClientDistFields({
      ...giftcardClientDistFields,
      read: !giftcardClientDistFields.read,
      create: false,
      delete: false
    })
    setMerchandiseClientDistFields({
      ...merchandiseClientDistFields,
      read: !merchandiseClientDistFields.read,
      create: false,
      delete: false
    })

  }

  const eposModuleHandler = () => {
    setEposModule({
      ...epos_module,
      access: !epos_module.access,
    });
    setEposFields({
      ...eposFields,
      read: !eposFields.read,
      create: false,
      delete: false,
    });
  };
  const supplierModuleHandler = () => {
    setSupplierDashboard({
      ...SupplierDashboard,
      access: !SupplierDashboard.access,
    });
  };
  const clientModuleHandler = () => {
    setclientDashboard({
      ...clientDashboard,
      access: !clientDashboard.access,
    });
  };
  const organizationModuleHandler = () => {
    setorganizationDashboard({
      ...organizationDashboard,
      access: !organizationDashboard.access,
    });
  };

  // useEffect
  useEffect(() => {
    setFields({
      ...fields,
      roleName: userRoleData?.role_name ? userRoleData?.role_name : "",
      roleDescription: userRoleData?.description
        ? userRoleData?.description
        : "",
    });
  }, [userRoleData]);

  useEffect(() => {
    location?.state?.roleItem &&
      dispatch(getUserRoleDataByID(location?.state?.roleItem?.id));
  }, [location?.state?.roleItem]);

  useEffect(() => {
    userRoleData?.user_type &&
      USER_TYPE_CHOICES?.map((item) => {
        if (item?.value === userRoleData?.user_type) {
          setDropdown({ ...dropdown, user_type: item });
        }
      });
  }, [userRoleData, USER_TYPE_CHOICES]);

  useEffect(() => {
    setProductsModule(
      userRoleData?.permissions?.products_module
        ? userRoleData?.permissions?.products_module
        : modulePermission
    );
    setDistributionModule(userRoleData?.permissions?.distribution_module
      ? userRoleData?.permissions?.distribution_module
      : modulePermission)
    setProductsAttributeModule(userRoleData?.permissions?.products_attribute_module
      ? userRoleData?.permissions?.products_attribute_module
      : modulePermission)
    setManageUsersModule(userRoleData?.permissions?.manage_users_module
      ? userRoleData?.permissions?.manage_users_module
      : modulePermission)
    setOrderManagementModule(userRoleData?.permissions?.order_management_module
      ? userRoleData?.permissions?.order_management_module
      : modulePermission)
    setFloatManagementModule(userRoleData?.permissions?.float_management_module
      ? userRoleData?.permissions?.float_management_module
      : modulePermission)
    setPartnersModule(userRoleData?.permissions?.partners_module
      ? userRoleData?.permissions?.partners_module
      : modulePermission)
    setReportsModule(userRoleData?.permissions?.reports_module
      ? userRoleData?.permissions?.reports_module
      : modulePermission)
    setSettingsModule(userRoleData?.permissions?.settings_module
      ? userRoleData?.permissions?.settings_module
      : modulePermission)
    setEposModule(userRoleData?.permissions?.epos_module
      ? userRoleData?.permissions?.epos_module
      : modulePermission)
    setClient_distribution_module(userRoleData?.permissions?.client_distribution
      ? userRoleData?.permissions?.client_distribution
      : modulePermission)
    setclientDashboard(userRoleData?.permissions?.client_dashboard
      ? userRoleData?.permissions?.client_dashboard
      : modulePermission)
    setSupplierDashboard(userRoleData?.permissions?.supplier_dashboard
      ? userRoleData?.permissions?.supplier_dashboard
      : modulePermission)
    setorganizationDashboard(userRoleData?.permissions?.organization_dashboard
      ? userRoleData?.permissions?.organization_dashboard
      : modulePermission)
  }, [userRoleData]);
  useEffect(() => {
    setGiftcardFields(userRoleData?.permissions?.giftcard ? userRoleData?.permissions?.giftcard : subModulePermission)
    setMerchandiseFields(userRoleData?.permissions?.merchandise ? userRoleData?.permissions?.merchandise : subModulePermission)
    setManualInventoryFields(userRoleData?.permissions?.manual_inventory ? userRoleData?.permissions?.manual_inventory : subModulePermission)
    setGiftcardDistributionFields(userRoleData?.permissions?.giftcard_distribution ? userRoleData?.permissions?.giftcard_distribution : subModulePermission)
    setMerchandiseDistributionFields(userRoleData?.permissions?.merchandise_distribution ? userRoleData?.permissions?.merchandise_distribution : subModulePermission)
    setBrandFields(userRoleData?.permissions?.brand ? userRoleData?.permissions?.brand : subModulePermission)
    setBrandCategoryFields(userRoleData?.permissions?.brand_category ? userRoleData?.permissions?.brand_category : subModulePermission)
    setUserFields(userRoleData?.permissions?.user ? userRoleData?.permissions?.user : subModulePermission)
    setUserRoleFields(userRoleData?.permissions?.user_role ? userRoleData?.permissions?.user_role : subModulePermission)
    setAllOrdersFields(userRoleData?.permissions?.all_orders ? userRoleData?.permissions?.all_orders : subModulePermission)
    setAllSuppliersFields(userRoleData?.permissions?.all_suppliers ? userRoleData?.permissions?.all_suppliers : subModulePermission)
    setAllClientsFields(userRoleData?.permissions?.all_clients ? userRoleData?.permissions?.all_clients : subModulePermission)
    setAllOrganizationFields(userRoleData?.permissions?.all_organization ? userRoleData?.permissions?.all_organization : subModulePermission)
    setReportsFields(userRoleData?.permissions?.all_clients ? userRoleData?.permissions?.all_clients : subModulePermission)
    setAllFloatsFields(userRoleData?.permissions?.all_floats ? userRoleData?.permissions?.all_floats : subModulePermission)
    setGiftcardClientDistFields(userRoleData?.permissions?.giftcard_client_distribution ? userRoleData?.permissions?.giftcard_client_distribution : subModulePermission)
    setMerchandiseClientDistFields(userRoleData?.permissions?.merchandise_client_distribution ? userRoleData?.permissions?.merchandise_client_distribution : subModulePermission)

  }, [userRoleData])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeHandler = (e) => {
    const value = e.target?.value;
    setFields({ ...fields, [e.target.name]: value });
  };

  const handleChangeUserType = (e) => {
    setDropdown({ ...dropdown, user_type: e });
  };

  const validate = () => {
    if (!dropdown.user_type) {
      setError({ user_type: t("Please select user_type") });
      return false;
    }
    return true;
  };

  const submit = () => {
    let val = validate();
    let obj = {
      products_module: products_module,
      distribution_module: distribution_module,
      products_attribute_module: products_attribute_module,
      manage_users_module: manage_users_module,
      order_management_module: order_management_module,
      float_management_module: float_management_module,
      partners_module: partners_module,
      reports_module: reports_module,
      settings_module: settings_module,
      epos_module: epos_module,
      organization_dashboard: organizationDashboard,
      client_dashboard: clientDashboard,
      supplier_dashboard: SupplierDashboard,
      client_distribution: client_distribution_module,


      giftcard: giftcardFields,
      merchandise: merchandiseFields,
      manual_inventory: manualInventoryFields,
      giftcard_distribution: giftcardDistributionFields,
      merchandise_distribution: merchandiseDistributionFields,
      brand: brandFields,
      brand_category: brandCategoryFields,
      user: userFields,
      user_role: userRoleFields,
      all_orders: allOrdersFields,
      all_floats: allFloatsFields,
      all_suppliers: allSuppliersFields,
      all_clients: allClientsFields,
      all_organization: allOrganizationFields,
      giftcard_client_distribution: giftcardClientDistFields,
      merchandise_client_distribution: merchandiseClientDistFields,
      //reportsFields: reportsFields,
      //settingsFields: settingsFields
    };
    let payload = {
      user_type: dropdown.user_type,
      roleName: fields.roleName,
      roleId: userRoleData?.id,
      roleDescription: fields.roleDescription,
      permissions: obj,
    };
    if (val) {
      dispatch(updateUserRole(payload,
        gotoSucess,
        // showToastMessage
      ));
    }
  };

  const showToastMessage = (obj) => {
    setToastMessage(obj);
    toggleShowToast();
  };

  const gotoSucess = () => {
    history("/roles/update-success");
  };

  return (
    <>
      {loader === false ? (
        <div className="d-flex flex-column justify-content-start py-4 gap-3 px-4 bg-light">
          <div className="d-flex flex-row justify-content-between align-items-center pb-2 w-100">
            <div className="d-flex flex-row align-items-center pb-2 gap-3">
              <LeftIcon onClick={() => history(-1)} className="cursor" />
              <span className="font-bold font-32 my-2">{t("Edit Basic Role")}</span>
            </div>
          </div>

          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 gap-4 px-4 width-65">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("User type")}<span className="text-danger">*</span>
                  </span>
                  <InfoTooltip
                    title={"Select User type"}
                  />
                </div>
                <div className="d-flex flex-column gap-1">
                  <Select
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select user type")}
                    getOptionLabel={(e) => e.name}
                    options={USER_TYPE_CHOICES}
                    isClearable
                    styles={CUSTOM_DROPDOWN_STYLE}
                    value={dropdown.user_type}
                    onChange={handleChangeUserType}
                    isDisabled={true}
                  />
                  {!dropdown.user_type && error.user_type ? (
                    <label className="text-error">{error.user_type}</label>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Role name")}
                  </span>
                  <InfoTooltip
                    title={"Role name"}
                  />
                </div>
                <div className="d-flex flex-column gap-1">
                  <InputField
                    name="roleName"
                    value={fields.roleName}
                    disabled={true}
                  //onChange={(e) => changeHandler(e)}
                  />
                </div>
              </div>

              <div className="d-flex flex-column gap-2 ">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Role description")}</span>
                  <InfoTooltip
                    title={"Enter Role description"}
                  />
                </div>
                <InputField
                  name="roleDescription"
                  value={fields.roleDescription}
                  onChange={(e) => changeHandler(e)}
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 gap-4 px-4 w-100 mb-5">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex gap-3">
                <Tag />
                <span className="font-20 font-bold">{t("Edit permissions")}</span>
              </div>
            </div>
            <div className="d-flex flex-column px-3 py-3 gap-4">
              {/* <div className="d-flex justify-content-between px-5 py-3 gap-4"> */}
              <table responsive="d-flex xl table ">
                <thead>
                  <tr className="table-dark border-bottom">
                    <th className="col-6">
                      <span className="font-15 font-bold text-muted">{t("Module Name")}</span>
                    </th>
                    <th className="col-3">
                      {/* <span className="font-15 font-bold text-muted">Read</span> */}
                    </th>
                    <th className="col-3">
                      <span className="font-15 font-bold text-muted">{t("Active")}</span>
                    </th>
                    {/* <th className="col-2">
                    <span className="font-15 font-bold text-muted">Delete</span>
                  </th> */}
                  </tr>
                </thead>
                <tbody>

                  {/* product  */}

                  {dropdown?.user_type?.value !== "organization_store" ?
                    <>
                      <tr className="pt-3 table-warning gap-3 ">
                        <td  >
                          <span className="font-15 font-bold">{t("Product")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="pt-3">
                            <ReactSwitchButton
                              onChange={productsModuleHandler}
                              value={products_module.access}
                            />
                          </div>
                        </td>
                      </tr>
                      {products_module.access ?
                        <tr className="gap-4 ">
                          <td >
                            <span className="font-15 text-muted">{t("Giftcard")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor "
                                type="checkbox"
                                id="read"
                                checked={giftcardFields.read}
                                onChange={() => setGiftcardFields({ ...giftcardFields, read: !giftcardFields.read })}
                                disabled={giftcardFields.create || giftcardFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={giftcardFields.create}
                                onChange={() => setGiftcardFields({ ...giftcardFields, create: !giftcardFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={giftcardFields.delete}
                          onChange={() => setGiftcardFields({ ...giftcardFields, delete: !giftcardFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                      {products_module.access ?
                        <tr >
                          <td >
                            <span className="font-15  text-muted">{t("Merchandise")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={merchandiseFields.read}
                                onChange={() => setMerchandiseFields({ ...merchandiseFields, read: !merchandiseFields.read })}
                                disabled={merchandiseFields.create || merchandiseFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={merchandiseFields.create}
                                onChange={() => setMerchandiseFields({ ...merchandiseFields, create: !merchandiseFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={merchandiseFields.delete}
                          onChange={() => setMerchandiseFields({ ...merchandiseFields, delete: !merchandiseFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                      {products_module.access ?
                        <tr className="card-body border-bottom">
                          <td >
                            <span className="font-15  text-muted pb-2">{t("Manual Inventory")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={manualInventoryFields.read}
                                onChange={() => setManualInventoryFields({ ...manualInventoryFields, read: !manualInventoryFields.read })}
                                disabled={manualInventoryFields.create || manualInventoryFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={manualInventoryFields.create}
                                onChange={() => setManualInventoryFields({ ...manualInventoryFields, create: !manualInventoryFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={manualInventoryFields.delete}
                          onChange={() => setManualInventoryFields({ ...manualInventoryFields, delete: !manualInventoryFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                    </> : null}





                  {/* distribution */}
                  {dropdown?.user_type?.value !== "organization_store" ?
                    <>
                      <tr className="gap-3 py-4 pt-3">
                        <td  >
                          <span className="font-15 font-bold">{t("Distribution Module")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="py-3">
                            <ReactSwitchButton
                              id={"distribution_module"}
                              onChange={distributionModuleHandler}
                              value={distribution_module.access}
                            />
                          </div>
                        </td>
                      </tr>
                      {distribution_module.access ?
                        <tr >
                          <td >
                            <span className="font-15 text-muted">{t("Giftcard Distribution")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={giftcardDistributionFields.read}
                                onChange={() => setGiftcardDistributionFields({ ...giftcardDistributionFields, read: !giftcardDistributionFields.read })}
                                disabled={giftcardDistributionFields.create || giftcardDistributionFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={giftcardDistributionFields.create}
                                onChange={() => setGiftcardDistributionFields({ ...giftcardDistributionFields, create: !giftcardDistributionFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={giftcardDistributionFields.delete}
                          onChange={() => setGiftcardDistributionFields({ ...giftcardDistributionFields, delete: !giftcardDistributionFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}

                      {distribution_module.access ?
                        <tr className="card-body border-bottom">
                          <td >
                            <span className="font-15 text-muted">{t("Merchandise Distribution")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={merchandiseDistributionFields.read}
                                onChange={() => setMerchandiseDistributionFields({ ...merchandiseDistributionFields, read: !merchandiseDistributionFields.read })}
                                disabled={merchandiseDistributionFields.create || merchandiseDistributionFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={merchandiseDistributionFields.create}
                                onChange={() => setMerchandiseDistributionFields({ ...merchandiseDistributionFields, create: !merchandiseDistributionFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={merchandiseDistributionFields.delete}
                          onChange={() => setMerchandiseDistributionFields({ ...merchandiseDistributionFields, delete: !merchandiseDistributionFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                    </> : null}



                  {/* Products Attribute */}
                  {dropdown?.user_type?.value !== "organization_store" ?
                    <>
                      <tr>
                        <td  >
                          <span className="font-15 font-bold">{t("Products Attribute")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="py-3">
                            <ReactSwitchButton
                              id={"products_attribute_module"}
                              onChange={productsAttributeModuleHandler}
                              value={products_attribute_module.access}
                            />
                          </div>
                        </td>
                      </tr>
                      {products_attribute_module.access ?
                        <tr >
                          <td >
                            <span className="font-15 text-muted">{t("Brand")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={brandFields.read}
                                onChange={() => setBrandFields({ ...brandFields, read: !brandFields.read })}
                                disabled={brandFields.create || brandFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={brandFields.create}
                                onChange={() => setBrandFields({ ...brandFields, create: !brandFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={brandFields.delete}
                          onChange={() => setBrandFields({ ...brandFields, delete: !brandFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                      {products_attribute_module.access ?
                        <tr className="card-body border-bottom">
                          <td >
                            <span className="font-15  text-muted">{t("Brand Category")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={brandCategoryFields.read}
                                onChange={() => setBrandCategoryFields({ ...brandCategoryFields, read: !brandCategoryFields.read })}
                                disabled={brandCategoryFields.create || brandCategoryFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={brandCategoryFields.create}
                                onChange={() => setBrandCategoryFields({ ...brandCategoryFields, create: !brandCategoryFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={brandCategoryFields.delete}
                          onChange={() => setBrandCategoryFields({ ...brandCategoryFields, delete: !brandCategoryFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                    </> : null}



                  {/* Manage Roles */}
                  {dropdown?.user_type?.value === "internal" ?
                    <>
                      <tr>
                        <td  >
                          <span className="font-15 font-bold">{t("Manage Users")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="py-3">
                            <ReactSwitchButton
                              id={"manage_users_module"}
                              onChange={manageUsersModuleHandler}
                              value={manage_users_module.access}
                              disabled={dropdown?.user_type?.value === "internal" ? false : true}
                            />
                          </div>
                        </td>
                      </tr>
                      {manage_users_module.access ?
                        <tr >
                          <td >
                            <span className="font-15 text-muted">{t("Users")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={userFields.read}
                                onChange={() => setUserFields({ ...userFields, read: !userFields.read })}
                                disabled={userFields.create || userFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={userFields.create}
                                onChange={() => setUserFields({ ...userFields, create: !userFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={userFields.delete}
                          onChange={() => setUserFields({ ...userFields, delete: !userFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}

                      {manage_users_module.access ?
                        <tr className="card-body border-bottom">
                          <td >
                            <span className="font-15 text-muted">{t("User Role")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={userRoleFields.read}
                                onChange={() => setUserRoleFields({ ...userRoleFields, read: !userRoleFields.read })}
                                disabled={userRoleFields.create || userRoleFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={userRoleFields.create}
                                onChange={() => setUserRoleFields({ ...userRoleFields, create: !userRoleFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={userRoleFields.delete}
                          onChange={() => setUserRoleFields({ ...userRoleFields, delete: !userRoleFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                    </> : null}



                  {/* Order Management   */}
                  {dropdown?.user_type?.value === "internal" ?
                    <>
                      <tr>
                        <td  >
                          <span className="font-15 font-bold">{t("Order Management")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="py-3">
                            <ReactSwitchButton
                              id={"order_management_module"}
                              onChange={orderManagementModuleHandler}
                              value={order_management_module.access}
                              disabled={dropdown?.user_type?.value === "internal" ? false : true}
                            />
                          </div>
                        </td>
                      </tr>
                      {order_management_module.access ?
                        <tr className="card-body border-bottom">
                          <td >
                            <span className="font-15 text-muted">{t("All Orders")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={allOrdersFields.read}
                                onChange={() => setAllOrdersFields({ ...allOrdersFields, read: !allOrdersFields.read })}
                                disabled={allOrdersFields.create || allOrdersFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={allOrdersFields.create}
                                onChange={() => setAllOrdersFields({ ...allOrdersFields, create: !allOrdersFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={allOrdersFields.delete}
                          onChange={() => setAllOrdersFields({ ...allOrdersFields, delete: !allOrdersFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                    </> : null}



                  {/* Float Management */}
                  {dropdown?.user_type?.value === "internal" ?
                    <>
                      <tr>
                        <td  >
                          <span className="font-15 font-bold">{t("Float Management")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="py-3">
                            <ReactSwitchButton
                              id={"float_management_module"}
                              onChange={floatManagementModuleHandler}
                              value={float_management_module.access}
                              disabled={dropdown?.user_type?.value === "internal" ? false : true}
                            />
                          </div>
                        </td>
                      </tr>
                      {float_management_module.access ?
                        <tr >
                          <td >
                            <span className="font-15 text-muted">{t("Giftcard")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={allFloatsFields.read}
                                onChange={() => setAllFloatsFields({ ...allFloatsFields, read: !allFloatsFields.read })}
                                disabled={allFloatsFields.create || allFloatsFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={allFloatsFields.create}
                                onChange={() => setAllFloatsFields({ ...allFloatsFields, create: !allFloatsFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={allFloatsFields.delete}
                          onChange={() => setAllFloatsFields({ ...allFloatsFields, delete: !allFloatsFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                    </> : null}



                  {/* Partners */}
                  {dropdown?.user_type?.value === "internal" ?
                    <>
                      <tr>
                        <td  >
                          <span className="font-15 font-bold">{t("Partners")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="py-3">
                            <ReactSwitchButton
                              id={"partners_module"}
                              onChange={partnersModuleHandler}
                              value={partners_module.access}
                              disabled={dropdown?.user_type?.value === "internal" ? false : true}
                            />
                          </div>
                        </td>
                      </tr>
                      {partners_module.access ?
                        <tr className="gap-3">
                          <td >
                            <span className="font-15 text-muted">{t("All Suppliers")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={allSuppliersFields.read}
                                onChange={() => setAllSuppliersFields({ ...allSuppliersFields, read: !allSuppliersFields.read })}
                                disabled={allSuppliersFields.create || allSuppliersFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 ">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={allSuppliersFields.create}
                                onChange={() => setAllSuppliersFields({ ...allSuppliersFields, create: !allSuppliersFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={allSuppliersFields.delete}
                          onChange={() => setAllSuppliersFields({ ...allSuppliersFields, delete: !allSuppliersFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                      {partners_module.access ?
                        <tr >
                          <td >
                            <span className="font-15 text-muted">{t("All Clients")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 ">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={allClientsFields.read}
                                onChange={() => setAllClientsFields({ ...allClientsFields, read: !allClientsFields.read })}
                                disabled={allClientsFields.create || allClientsFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={allClientsFields.create}
                                onChange={() => setAllClientsFields({ ...allClientsFields, create: !allClientsFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={allClientsFields.delete}
                          onChange={() => setAllClientsFields({ ...allClientsFields, delete: !allClientsFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                      {partners_module.access ?
                        <tr className="card-body border-bottom">
                          <td >
                            <span className="font-15 text-muted">{t("All Organization")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={allOrganizationFields.read}
                                onChange={() => setAllOrganizationFields({ ...allOrganizationFields, read: !allOrganizationFields.read })}
                                disabled={allOrganizationFields.create || allOrganizationFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={allOrganizationFields.create}
                                onChange={() => setAllOrganizationFields({ ...allOrganizationFields, create: !allOrganizationFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={allOrganizationFields.delete}
                          onChange={() => setAllOrganizationFields({ ...allOrganizationFields, delete: !allOrganizationFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                    </> : null}



                  {/* Reports */}
                  {dropdown?.user_type?.value === "internal" ?
                    <>
                      <tr>
                        <td  >
                          <span className="font-15 font-bold">{t("Reports")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="py-3">
                            <ReactSwitchButton
                              id={"reports_module"}
                              onChange={reportsModuleHandler}
                              value={reports_module.access}
                            />
                          </div>
                        </td>
                      </tr>
                      {reports_module.access ?
                        <tr className="card-body border-bottom">
                          <td >
                            <span className="font-15 text-muted">{t("Reports")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={reportsFields.read}
                                onChange={() => setReportsFields({ ...reportsFields, read: !reportsFields.read })}
                                disabled={reportsFields.create || reportsFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            {/* <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="create"
                          checked={reportsFields.create}
                          onChange={() => setReportsFields({ ...reportsFields, create: !reportsFields.create , read: true})}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Write</span>

                      </div> */}
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={reportsFields.delete}
                          onChange={() => setReportsFields({ ...reportsFields, delete: !reportsFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                    </> : null}



                  {/* settings Module */}
                  {dropdown?.user_type?.value === "internal" ?
                    <>
                      <tr>
                        <td  >
                          <span className="font-15 font-bold">{t("Settings Module")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="py-3">
                            <ReactSwitchButton
                              id={"settings_module"}
                              onChange={settingsModuleHandler}
                              value={settings_module.access}
                            />
                          </div>
                        </td>
                      </tr>
                      {/*   {settings_module.access ?
                    <tr className="card-body border-bottom">
                      <td >
                        <span className="font-15 text-muted">Settings</span>
                      </td>
                      <td >
                        <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="read"
                            checked={settingsFields.read}
                            onChange={() => setSettingsFields({ ...settingsFields, read: !settingsFields.read })}
                            disabled={settingsFields.create || settingsFields.delete}
                          />
                          <span className="font-14 text-muted font-italic">Read</span>

                        </div>
                      </td>
                      <td >
                        <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="create"
                            checked={settingsFields.create}
                            onChange={() => setSettingsFields({ ...settingsFields, create: !settingsFields.create, read: true })}
                          // disabled={!switchButtons.customEmailFlag}
                          />
                          <span className="font-14 text-muted font-italic">Write</span>

                        </div>
                      </td>
                       <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={settingsFields.delete}
                          onChange={() => setSettingsFields({ ...settingsFields, delete: !settingsFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> 
                    </tr> : null}*/}
                    </> : null}




                  {/*  EPOS Module */}
                  {dropdown?.user_type?.value === "internal" || dropdown?.user_type?.value === "organization" || dropdown?.user_type?.value === "organization_store" ?
                    <>
                      <tr>
                        <td  >
                          <span className="font-15 font-bold">{t("EPOS Module")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="py-3">
                            <ReactSwitchButton
                              id={"epos_module"}
                              onChange={eposModuleHandler}
                              value={epos_module.access}
                              disabled={dropdown?.user_type?.value === "internal" || dropdown?.user_type?.value === "organization" || dropdown?.user_type?.value === "organization_store" ? false : true}
                            />
                          </div>
                        </td>
                      </tr>
                      {/*   {epos_module.access ?
                    <tr className="card-body border-bottom">
                      <td >
                        <span className="font-15 text-muted">EPOS Module</span>
                      </td>
                      <td >
                        <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="read"
                            checked={eposFields.read}
                            onChange={() => setEposFields({ ...eposFields, read: !eposFields.read })}
                            disabled={eposFields.create || eposFields.delete}
                          />
                          <span className="font-14 text-muted font-italic">Read</span>

                        </div>
                      </td>
                      <td >
                        <div className=" d-flex flex-row align-items-center gap-2 pb-2">
                          <input
                            className="form-check-input cursor"
                            type="checkbox"
                            id="create"
                            checked={eposFields.create}
                            onChange={() => setEposFields({ ...eposFields, create: !eposFields.create, read: true })}
                          />
                          <span className="font-14 text-muted font-italic">Write</span>

                        </div>
                      </td>
                       <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={eposFields.delete}
                          onChange={() => setEposFields({ ...eposFields, delete: !eposFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> 
                    </tr> : null}*/}
                    </> : null}




                  {/*  Supplier Dashboard */}
                  {dropdown.user_type?.value === "supplier"
                    ?
                    <tr>
                      <td  >
                        <span className="font-15 font-bold">{t("Supplier Dashboard")}</span>
                      </td>
                      <td></td>
                      <td></td>
                      <td >
                        <div className="py-3">
                          <ReactSwitchButton
                            id={"supplier_dashboard"}
                            onChange={supplierModuleHandler}
                            value={SupplierDashboard.access}
                          />
                        </div>
                      </td>
                    </tr> : null}

                  {/*  client Dashboard */}
                  {dropdown.user_type?.value === "client" ?
                    <tr>
                      <td  >
                        <span className="font-15 font-bold">{t("Client Dashboard")}</span>
                      </td>
                      <td></td>
                      <td></td>
                      <td >
                        <div className="py-3">
                          <ReactSwitchButton
                            id={"client_dashboard"}
                            onChange={clientModuleHandler}
                            value={clientDashboard.access}
                            disabled={dropdown?.user_type?.value === "client" ? false : true}
                          />
                        </div>
                      </td>
                    </tr> : null}

                  {/*  organization Dashboard */}
                  {dropdown.user_type?.value === "organization" ?
                    <tr>
                      <td  >
                        <span className="font-15 font-bold">{t("Organization Dashboard")}</span>
                      </td>
                      <td></td>
                      <td></td>
                      <td >
                        <div className="py-3">
                          <ReactSwitchButton
                            id={"organization_dashboard"}
                            onChange={organizationModuleHandler}
                            value={organizationDashboard.access}
                            disabled={dropdown?.user_type?.value === "organization" ? false : true}
                          />
                        </div>
                      </td>
                    </tr> : null}



                  {/* client distribution   */}
                  {dropdown?.user_type?.value === "client" ?
                    <>
                      <tr className="pt-3 table-warning gap-3 ">
                        <td  >
                          <span className="font-15 font-bold">{t("Client Distribution Model")}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td >
                          <div className="pt-3">
                            <ReactSwitchButton
                              onChange={clientDistributionModuleHandler}
                              value={client_distribution_module.access}
                            />
                          </div>
                        </td>
                      </tr>

                      {client_distribution_module.access ?
                        <tr className="gap-4 ">
                          <td >
                            <span className="font-15 text-muted">{t("Giftcard Client Distribution")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor "
                                type="checkbox"
                                id="read"
                                checked={giftcardClientDistFields.read}
                                onChange={() => setGiftcardClientDistFields({ ...giftcardClientDistFields, read: !giftcardClientDistFields.read })}
                                disabled={giftcardClientDistFields.create || giftcardClientDistFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={giftcardClientDistFields.create}
                                onChange={() => setGiftcardClientDistFields({ ...giftcardClientDistFields, create: !giftcardClientDistFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={giftcardFields.delete}
                          onChange={() => setGiftcardFields({ ...giftcardFields, delete: !giftcardFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}

                      {client_distribution_module.access ?
                        <tr >
                          <td >
                            <span className="font-15  text-muted">{t("Merchandise Client Distribution")}</span>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="read"
                                checked={merchandiseClientDistFields.read}
                                onChange={() => setMerchandiseClientDistFields({ ...merchandiseClientDistFields, read: !merchandiseClientDistFields.read })}
                                disabled={merchandiseClientDistFields.create || merchandiseClientDistFields.delete}
                              />
                              <span className="font-14 text-muted font-italic">{t("Read")}</span>

                            </div>
                          </td>
                          <td >
                            <div className=" d-flex flex-row align-items-center gap-2">
                              <input
                                className="form-check-input cursor"
                                type="checkbox"
                                id="create"
                                checked={merchandiseClientDistFields.create}
                                onChange={() => setMerchandiseClientDistFields({ ...merchandiseClientDistFields, create: !merchandiseClientDistFields.create, read: true })}
                              // disabled={!switchButtons.customEmailFlag}
                              />
                              <span className="font-14 text-muted font-italic">{t("Write")}</span>

                            </div>
                          </td>
                          {/* <td >
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <input
                          className="form-check-input cursor"
                          type="checkbox"
                          id="delete"
                          checked={merchandiseFields.delete}
                          onChange={() => setMerchandiseFields({ ...merchandiseFields, delete: !merchandiseFields.delete })}
                        // disabled={!switchButtons.customEmailFlag}
                        />
                        <span className="font-14 text-muted font-italic">Delete</span>
                      </div>
                    </td> */}
                        </tr> : null}
                    </> : null}

                </tbody>
              </table>
            </div>

            {/* </div> */}

            <div className="d-flex">
              <Button
                onClick={() => submit()}
                text={t("Update role")}
                type="primary"
              />
            </div>
          </div>
          <div
            className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
            id="liveToast"
          >
            <Toasts
              position={"bottom-center"}
              showToast={showToast}
              toggleShowToast={toggleShowToast}
              warningMsg={toastMessage}
              imageType={"warning"}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default EditRole;
