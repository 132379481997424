import {
  GET_ALL_USER_ROLE_FAILURE,
  GET_ALL_USER_ROLE_REQUEST,
  GET_ALL_USER_ROLE_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_ROLE_FAILURE,
  GET_USER_ROLE_REQUEST,
  GET_USER_ROLE_SUCCESS,
  GET_USER_SUCCESS,
  SET_USER_FAILURE,
  SET_USER_REQUEST,
  SET_USER_ROLE_FAILURE,
  SET_USER_ROLE_REQUEST,
  SET_USER_ROLE_SUCCESS,
  SET_USER_SUCCESS,
  GET_USER_ROLE_DATA_BYID_REQUEST,
  GET_USER_ROLE_DATA_BYID_SUCCESS,
  GET_USER_ROLE_DATA_BYID_FAILURE,
  UPDATE_USER_ROLE_DATA_REQUEST,
  UPDATE_USER_ROLE_DATA_SUCCESS,
  UPDATE_USER_ROLE_DATA_FAILURE,
  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
  CLEAR_USER_ROLES_LIST
} from "./userType";

const initialVatState = {
  loading: false,
  userList: null,
  userRole: null,
  userResponse: null,
  userRoleResponse: null,
  userPermissionResponse: null,
  userUpdateResponse: [],
  allUserRole: null,
  userRoleDataByID: [],
  updateUserRoleResponse: [],
  error: "",
};

const userReducer = (state = initialVatState, action) => {
  switch (action.type) {
    //Update user info
    case UPDATE_USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        userUpdateResponse: [],
        error: "",
      };
    case UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userUpdateResponse: action.payload,
        error: "",
      };
    case UPDATE_USER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        userUpdateResponse: [],
        error: action.payload,
      };

    //Update user role list
    case UPDATE_USER_ROLE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        updateUserRoleResponse: [],
        error: "",
      };
    case UPDATE_USER_ROLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        updateUserRoleResponse: action.payload,
        error: "",
      };
    case UPDATE_USER_ROLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        updateUserRoleResponse: [],
        error: action.payload,
      };
    //get user role list
    case GET_USER_ROLE_DATA_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        userRoleDataByID: [],
        error: "",
      };
    case GET_USER_ROLE_DATA_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        userRoleDataByID: action.payload,
        error: "",
      };
    case GET_USER_ROLE_DATA_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        userRoleDataByID: [],
        error: action.payload,
      };
    //get user list
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        userList: null,
        error: "",
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload,
        error: "",
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        userList: null,
        error: action.payload,
      };
    //set user
    case SET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        userResponse: null,
        error: "",
      };
    case SET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userResponse: action.payload,
        error: "",
      };
    case SET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        userResponse: null,
        error: action.payload,
      };

    //get user role
    case GET_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        userRole: null,
        error: "",
      };
    case GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        userRole: action.payload,
        error: "",
      };
    case GET_USER_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        userRole: null,
        error: action.payload,
      };
    //set user role
    case SET_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        userRoleResponse: null,
        error: "",
      };
    case SET_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        userRoleResponse: action.payload,
        error: "",
      };
    case SET_USER_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        userRoleResponse: null,
        error: action.payload,
      };

    //get user role
    case GET_ALL_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        allUserRole: null,
        error: "",
      };
    case GET_ALL_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        allUserRole: action.payload,
        error: "",
      };
    case GET_ALL_USER_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        allUserRole: null,
        error: action.payload,
      };
    case CLEAR_USER_ROLES_LIST:
      return {
        ...state,
        loading: false,
        allUserRole: null,
        error: "",
      };
    default:
      return state;
  }
};
export default userReducer;
