export const GET_CLIENT_PRODUCT_REQUEST = "GET_CLIENT_PRODUCT_REQUEST"
export const GET_CLIENT_PRODUCT_SUCCESS = "GET_CLIENT_PRODUCT_SUCCESS"
export const GET_CLIENT_PRODUCT_FAILURE = "GET_CLIENT_PRODUCT_FAILURE"

//client mapping  put in server 
export const PUT_CLIENT_PRODUCT_REQUEST = "PUT_CLIENT_PRODUCT_REQUEST";
export const PUT_CLIENT_PRODUCT_SUCCESS = "PUT_CLIENT_PRODUCT_SUCCESS";
export const PUT_CLIENT_PRODUCT_FAILURE = "PUT_CLIENT_PRODUCT_FAILURE";

// get merchandise variants by merchandise id
export const GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID = "GET_MERCHANDISE_VARIANTS_BY_MERCHANDISE_ID";
// get giftcard products based on product id
export const GET_GIFTCARD_CLIENT_MAPPING_DATA_REQUEST = "GET_GIFTCARD_CLIENT_MAPPING_DATA_REQUEST"
export const GET_GIFTCARD_CLIENT_MAPPING_DATA_SUCCESS = "GET_GIFTCARD_CLIENT_MAPPING_DATA_SUCCESS"
export const GET_GIFTCARD_CLIENT_MAPPING_DATA_FAILURE = "GET_GIFTCARD_CLIENT_MAPPING_DATA_FAILURE"
// put giftcard client mapping data in server 

export const PUT_GIFTCARD_CLIENT_MAPPING_REQUEST = "PUT_GIFTCARD_CLIENT_MAPPING_REQUEST"
export const PUT_GIFTCARD_CLIENT_MAPPING_SUCCESS = "PUT_GIFTCARD_CLIENT_MAPPING_SUCCESS"
export const PUT_GIFTCARD_CLIENT_MAPPING_FAILURE = "PUT_GIFTCARD_CLIENT_MAPPING_FAILURE"

// to get supplier and supplier price based on giftcard and merchandise id for giftcard
export const GET_SUPP_SUPPPRICE_CLIENT_GC_REQUEST = "GET_SUPP_SUPPPRICE_CLIENT_GC_REQUEST"
export const GET_SUPP_SUPPPRICE_CLIENT_GC_SUCCESS = "GET_SUPP_SUPPPRICE_CLIENT_GC_SUCCESS"
export const GET_SUPP_SUPPPRICE_CLIENT_GC_FAILURE = "GET_SUPP_SUPPPRICE_CLIENT_GC_FAILURE"

// to get supplier and supplier price based on giftcard and merchandise id for merchandise
export const GET_SUPP_SUPPPRICE_CLIENT_MER_REQUEST = "GET_SUPP_SUPPPRICE_CLIENT_GC_REQUEST"
export const GET_SUPP_SUPPPRICE_CLIENT_MER_SUCCESS = "GET_SUPP_SUPPPRICE_CLIENT_GC_SUCCESS"
export const GET_SUPP_SUPPPRICE_CLIENT_MER_FAILURE = "GET_SUPP_SUPPPRICE_CLIENT_GC_FAILURE"

// to get client mapped giftcard list
export const GET_CLIENT_MAPPED_GIFTCARD_REQUEST = "GET_CLIENT_MAPPED_GIFTCARD_REQUEST"
export const GET_CLIENT_MAPPED_GIFTCARD_SUCCESS = "GET_CLIENT_MAPPED_GIFTCARD_SUCCESS"
export const GET_CLIENT_MAPPED_GIFTCARD_FAILURE = "GET_CLIENT_MAPPED_GIFTCARD_FAILURE"