// to all giftcard list mapped with client
export const GET_CLIENT_MAPPED_GCLIST_REQUEST = "GET_CLIENT_MAPPED_GCLIST_REQUEST";
export const GET_CLIENT_MAPPED_GCLIST_SUCCESS = "GET_CLIENT_MAPPED_GCLIST_SUCCESS";
export const GET_CLIENT_MAPPED_GCLIST_FAILURE = "GET_CLIENT_MAPPED_GCLIST_FAILURE";
export const GET_CLIENT_MAPPED_GCLIST_DELETE = "GET_CLIENT_MAPPED_GCLIST_DELETE"

// to get client mapped giftcard list details
export const GET_CLIENT_MAP_GCLIST_DETAILS_REQUEST = "GET_CLIENT_MAP_GCLIST_DETAILS_REQUEST";
export const GET_CLIENT_MAP_GCLIST_DETAILS_SUCCESS = "GET_CLIENT_MAP_GCLIST_DETAILS_SUCCESS";
export const GET_CLIENT_MAP_GCLIST_DETAILS_FAILURE = "GET_CLIENT_MAP_GCLIST_DETAILS_FAILURE";

export const POST_GIFTACRD_MANAUL_IN_COUNT_REQUEST="POST_GIFTACRD_MANAUL_IN_COUNT_REQUEST"
export const POST_GIFTACRD_MANAUL_IN_COUNT_SUCCESS="POST_GIFTACRD_MANAUL_IN_COUNT_SUCCESS"
export const POST_GIFTACRD_MANAUL_IN_COUNT_FAILURE="POST_GIFTACRD_MANAUL_IN_COUNT_FAILURE"

export const DELETE_GIFTCARD_DETAIL = "DELETE_GIFTCARD_DETAIL"