import {
  GET_SAMPLE_BULK_UPLOAD_MER_REQUEST,
  GET_SAMPLE_BULK_UPLOAD_MER_SUCCESS,
  GET_SAMPLE_BULK_UPLOAD_MER_FAILURE,
  POST_MERCHANDISE_BULK_UPLOAD_REQUEST,
  POST_MERCHANDISE_BULK_UPLOAD_SUCCESS,
  POST_MERCHANDISE_BULK_UPLOAD_FAILURE,
  POST_MERCHANDISE_BULK_UPLOAD_ERROR,
  CLEAR_MERCHANDISE_BULK_UPLOAD_ERROR,
  GET_SAMPLE_BULK_UPLOAD_GIFTCARD_REQUEST,
  GET_SAMPLE_BULK_UPLOAD_GIFTCARD_SUCCESS,
  GET_SAMPLE_BULK_UPLOAD_GIFTCARD_FAILURE,
  POST_GIFTCARD_BULK_UPLOAD_REQUEST,
  POST_GIFTCARD_BULK_UPLOAD_SUCCESS,
  POST_GIFTCARD_BULK_UPLOAD_FAILURE,
  POST_GIFTCARD_BULK_UPLOAD_ERROR,
  CLEAR_GIFTCARD_BULK_UPLOAD_ERROR,
} from "./bulkUploadType";
import { baseUrl, getToken } from "../../utils/prodURL";
import axios from "axios";
import fileDownload from "js-file-download";

export const getSampleBulkUploadRequest = () => {
  return {
    type: GET_SAMPLE_BULK_UPLOAD_MER_REQUEST,
  };
};

export const getSampleBulkUploadRequestSuccess = (bulkUploadMerchandise) => {
  return {
    type: GET_SAMPLE_BULK_UPLOAD_MER_SUCCESS,
    payload: bulkUploadMerchandise,
  };
};

export const getSampleBulkUploadRequestFailure = (error) => {
  return {
    type: GET_SAMPLE_BULK_UPLOAD_MER_FAILURE,
    payload: error,
  };
};

export const getSampleBulkUpload = (getDownloadApiRes) => {
  return (dispatch) => {
    dispatch(getSampleBulkUploadRequest());

    axios
      .get(`${baseUrl}/v1/merchandise/bulkUpload/template`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        // console.log(res.status);
        getDownloadApiRes(res.status)
        fileDownload(res.data, "Manual_Inventory_Template.xlsx");
      })
      .catch((error) => {
        // const errorMsg = error;
        dispatch(
          getSampleBulkUploadRequestFailure(
            "Something went wrong!! Please try again!!"
          )
        );
      });
  };
};

// bulk upload merchandise file upload
export const postBulkUploadMerchandiseRequest = () => {
  return {
    type: POST_MERCHANDISE_BULK_UPLOAD_REQUEST,
  };
};

export const postBulkUploadMerchandiseSuccess = (data) => {
  return {
    type: POST_MERCHANDISE_BULK_UPLOAD_SUCCESS,
    payload: data,
  };
};

export const postBulkUploadMerchandiseFailure = (error) => {
  return {
    type: POST_MERCHANDISE_BULK_UPLOAD_FAILURE,
    payload: error,
  };
};

export const postBulkUploadMerchandiseErros = (postError) => {
  return {
    type: POST_MERCHANDISE_BULK_UPLOAD_ERROR,
    payload: postError,
  };
};

export const clearBulkUploadMerchandiseErros = () => {
  return {
    type: CLEAR_MERCHANDISE_BULK_UPLOAD_ERROR,
    payload: [],
  };
};

// bulk upload merchandise file upload
export const postBulkUploadMerchandise = (fileArr, goToSuccessfull) => {
  console.log("fileArr ", fileArr[0]);

  return (dispatch) => {
    dispatch(postBulkUploadMerchandiseRequest());

    const formData = new FormData();
    formData.append("file", fileArr[0]);
    // console.log(formData);

    let config = {
      method: "post",
      url: `${baseUrl}/v1/merchandise/bulkUpload`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then((response) => {
        // console.log("response response", response)
        if (response.data.code === 200) {
          dispatch(postBulkUploadMerchandiseSuccess(response.data));
          dispatch(clearBulkUploadMerchandiseErros());
          goToSuccessfull();
        }
        dispatch(postBulkUploadMerchandiseErros(response.data));
      })
      .catch((error) => {
        console.log("error ", error);
        console.log("Something went wrong");
      });
  };
};

export const getGiftcardSampleBulkUploadRequest = () => {
  return {
    type: GET_SAMPLE_BULK_UPLOAD_GIFTCARD_REQUEST,
  };
};

export const getGiftcardSampleBulkUploadSuccess = (data) => {
  return {
    type: GET_SAMPLE_BULK_UPLOAD_GIFTCARD_SUCCESS,
    payload: data,
  };
};

export const getGiftcardSampleBulkUploadFailure = (error) => {
  return {
    type: GET_SAMPLE_BULK_UPLOAD_GIFTCARD_FAILURE,
    payload: error,
  };
};

export const getGiftcardSampleBulkUpload = () => {
  return (dispatch) => {
    dispatch(getGiftcardSampleBulkUploadRequest());

    console.log("API is pending");
    // axios
    //   .get(
    //     `${baseUrl}/v1/merchandise/bulkUpload/template`,
    //     {
    //       responseType: "blob",
    //       headers: {
    //         Authorization: `Bearer ${getToken()}`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     // console.log(res);
    //     fileDownload(res.data, "Manual_Inventory_Template.xlsx");
    //   })
    //   .catch((error) => {
    //     // const errorMsg = error;
    //     dispatch(
    //       getGiftcardSampleBulkUploadFailure(
    //         "Something went wrong!! Please try again!!"
    //       )
    //     );
    //   });
  };
};

// bulk upload GIFTCARD file upload
export const postBulkUploadGiftcardRequest = () => {
  return {
    type: POST_GIFTCARD_BULK_UPLOAD_REQUEST,
  };
};

export const postBulkUploadGiftcardSuccess = (data) => {
  return {
    type: POST_GIFTCARD_BULK_UPLOAD_SUCCESS,
    payload: data,
  };
};

export const postBulkUploadGiftcardFailure = (error) => {
  return {
    type: POST_GIFTCARD_BULK_UPLOAD_FAILURE,
    payload: error,
  };
};

export const postBulkUploadGiftcardErrors = (postError) => {
  return {
    type: POST_GIFTCARD_BULK_UPLOAD_ERROR,
    payload: postError,
  };
};

export const clearBulkUploadGiftcardErrors = () => {
  return {
    type: CLEAR_GIFTCARD_BULK_UPLOAD_ERROR,
    payload: [],
  };
};

export const postBulkUploadGiftcard = (fileArr, goToSuccessfull) => {
  return (dispatch) => {
    dispatch(postBulkUploadGiftcardRequest())

    const formData = new FormData();
    formData.append("file", fileArr[0]);
    console.log(formData);

    let config = {
      method: "post",
      url: `${baseUrl}/v1/merchandise/bulkUpload`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    // axios(config)
    //   .then((response) => {
    //     // console.log("response response", response)
    //     if (response.data.code === 200) {
    //       dispatch(postBulkUploadGiftcardSuccess(response.data));
    //       dispatch(clearBulkUploadGiftcardErrors());
    //       goToSuccessfull();
    //     }
    //     dispatch(postBulkUploadGiftcardErrors(response.data));
    //   })
    //   .catch((error) => {
    //     console.log("error ", error);
    //     console.log("Something went wrong");
    //   });

  }
}
