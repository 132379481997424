/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { CUSTOM_DROPDOWN_STYLE } from "../../utils/CustomeStyles";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getUserRole, setUser } from "../../Redux/UserManagement/userAction";
import { USER_TYPE_CHOICES } from "../../utils/ConstantData";
import {
  fetchPartnerByCategory,
  fetchPartnerCategory,
} from "../../Redux/Partner/partnerAction";
import SignInToast from "../../components/Toasts/SignInToast";
import PulseLoader from "../../components/Loader/PulseLoader";
import { getStoreListMapToOrganization } from "../../Redux/CreateStore/createStoreAction";
import { useTranslation } from "react-i18next";

const CreateAdminuser = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputElementUserType = useRef(null);
  const inputElementPartner = useRef(null);
  const inputElementStore = useRef(null);
  const inputElementRole = useRef(null);
  const inputElementUserName = useRef(null);
  const inputElementFirstName = useRef(null);
  const inputElementLastName = useRef(null);
  const inputElementEmail = useRef(null);
  const inputElementPassword = useRef(null);

  const rolesState = useSelector((state) => state.users.userRole);
  const partnerCategory = useSelector(
    (state) => state.partners?.partnerCategory
  );
  const partnerList = useSelector((state) => state.partners?.partnerList);
  const loader = useSelector((state) => state?.users?.loading);
  const storeListByOrgnazation = useSelector(
    (state) => state.createStore?.storeListByOrgnazationId
  );

  const [fields, setFields] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    contact_no: "",
    password: "",
  });

  const [userObj, setUserObj] = useState({
    user_type: "",
    selectStore: "",
    selectedPartner: "",
    selectRole: "",
  });

  const [error, setError] = useState({
    user_type: "",
    selectStore: "",
    selectedPartner: "",
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    contact_no: "",
    password: "",
    selectRole: "",
  });

  const [passShowHide, setPassShowHide] = useState(false);
  const [switchButton, setSwitchButton] = useState({ activate: false });
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchPartnerCategory());
  }, []);

  useEffect(() => {}, [
    rolesState,
    partnerCategory,
    partnerList,
    storeListByOrgnazation,
  ]);

  const handleInputChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setFields({ ...fields, [name]: value });
  };

  function handlePhoneNumber(evt) {
    let regex = /^\d{0,15}?$/;
    const value = evt.target.value;
    if (regex.test(evt.target.value)) {
      setFields({
        ...fields,
        [evt.target.name]: value,
      });
    }
  }

  const handleChangeSelectRole = (e) => {
    setUserObj({ ...userObj, selectRole: e });
  };

  const handleChangeUserType = (e) => {
    setUserObj({
      ...userObj,
      user_type: e,
      selectRole: "",
      selectedPartner: "",
      selectStore: "",
    });
    e && dispatch(getUserRole(e?.value));
    if (e && e?.value !== "internal") {
      e &&
        dispatch(
          fetchPartnerByCategory(
            e?.value === "organization_store" ? "organization" : e?.value
          )
        );
    }
  };

  const handleChangeUserSubType = (e) => {
    setUserObj({ ...userObj, selectStore: e });
  };

  const handleChangeType = (e) => {
    setUserObj({ ...userObj, selectedPartner: e, selectStore: "" });
    if (
      (e?.id &&
        userObj?.user_type &&
        userObj?.user_type?.value === "organization") ||
      userObj?.user_type?.value === "organization_store"
    ) {
      e && dispatch(getStoreListMapToOrganization(e?.id));
    }
  };

  const togglePassword = () => {
    setPassShowHide((prevState) => !prevState);
  };

  const changeActivate = () => {
    setSwitchButton({ ...switchButton, activate: !switchButton.activate });
  };

  const generatePassword = () => {
    const alpha = "abcdefghijklmnopqrstuvwxyz";
    const calpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const num = "1234567890";
    const specials = ",.!@#$%^&*";
    const options = [
      alpha,
      alpha,
      alpha,
      calpha,
      calpha,
      num,
      num,
      num,
      specials,
      specials,
    ];
    let opt, choose;
    let pass = "";
    for (let i = 0; i < 10; i++) {
      opt = Math.floor(Math.random() * options.length);
      choose = Math.floor(Math.random() * options[opt].length);
      pass = pass + options[opt][choose];
      options.splice(opt, 1);
    }

    setFields({ ...fields, password: pass });
  };

  const validate = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var passRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,}$)"
    );

    if (!userObj.user_type) {
      setError({ user_type: t("Please select user type") });
      if(inputElementUserType.current){
        inputElementUserType.current.focus()
      }
      return false;
    } else if (
      !userObj.selectedPartner &&
      userObj?.user_type?.value !== "internal"
    ) {
      setError({ selectedPartner: t("Please select partner") });
      if(inputElementPartner.current){
        inputElementPartner.current.focus()
      }
      return false;
    } else if (
      !userObj.selectStore &&
      userObj?.user_type?.value === "organization_store"
    ) {
      setError({ selectStore: t("Please select store") });
      if(inputElementStore.current){
        inputElementStore.current.focus()
      }
      return false;
    } else if (!userObj.selectRole) {
      setError({ selectRole: t("Please select role") });
      if(inputElementRole.current){
        inputElementRole.current.focus()
      }
      return false;
    } else if (!fields.userName) {
      setError({ userName: t("Please enter user name") });
      if(inputElementUserName.current){
        inputElementUserName.current.focus()
      }
      return false;
    } else if (!fields.firstName) {
      setError({ firstName: t("Please enter first name") });
      if(inputElementFirstName.current){
        inputElementFirstName.current.focus()
      }
      return false;
    } else if (!fields.lastName) {
      setError({ lastName: t("Please enter last name") });
      if(inputElementLastName.current){
        inputElementLastName.current.focus()
      }
      return false;
    } else if (!fields.email) {
      setError({ email: t("Please enter email") });
      if(inputElementEmail.current){
        inputElementEmail.current.focus()
      }
      return false;
    } else if (!fields.email || emailRegex.test(fields.email) === false) {
      setError({ email: t("Enter valid email") });
      return false;
    } else if (!fields.password) {
      setError({ password: t("Please enter password") });
      if(inputElementPassword.current){
        inputElementPassword.current.focus()
      }
      return false;
    } else if (!fields.password || passRegex.test(fields.password) === false) {
      setError({
        password: t(
          `Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character`
        ),
      });
      return false;
    }
    return true;
  };

  const submit = () => {
    let val = validate();
    let obj = {
      user_type: userObj.user_type,
      selectStore: userObj.selectStore,
      selectedPartner: userObj.selectedPartner,
      selectRole: userObj.selectRole,
      userName: fields.userName,
      firstName: fields.firstName,
      lastName: fields.lastName,
      email: fields.email,
      contact_no: fields.contact_no,
      password: fields.password,
    };
    if (val) {
      dispatch(setUser(obj, gotoSucess, showMassage));
    }
  };

  const gotoSucess = () => {
    history("/users/creation-success");
  };

  const showMassage = (msg) => {
    setMessage(msg);
    toggleShowToast();
  };

  return (
    <>
      {loader === false ? (
        <div className="d-flex flex-column justify-content-start py-3 gap-3 px-4 bg-light">
          <div className="d-flex">
            <span className="font-40 font-bold mb-2">{t("Create User")}</span>
          </div>

          <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 width-70 mb-5">
            <div className="d-flex flex-column gap-4 mt-4 w-100 ">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("User type")}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip title={"Select User type"} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <Select
                    ref={inputElementUserType}
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select user type")}
                    getOptionLabel={(e) => e.name}
                    options={USER_TYPE_CHOICES}
                    isClearable
                    styles={CUSTOM_DROPDOWN_STYLE}
                    value={userObj.user_type}
                    onChange={handleChangeUserType}
                  />
                  {!userObj.user_type && error.user_type ? (
                    <label className="text-error">{error.user_type}</label>
                  ) : null}
                </div>
              </div>
              {userObj?.user_type?.value !== "internal" ? (
                <div div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Select")}{" "}
                      {userObj?.user_type?.name
                        ? userObj?.user_type?.value === "organization_store"
                          ? `${t("Organization")}`
                          : userObj?.user_type?.name
                        : `${t("partner")}`}
                      <span className="text-danger">*</span>
                    </span>
                    <InfoTooltip title={"Select partner"} />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      ref={inputElementPartner}
                      noOptionsMessage={() => "Not found"}
                      placeholder={t("Select user sub type")}
                      getOptionLabel={(e) => e.partner_name}
                      options={partnerList}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                      value={userObj.selectedPartner}
                      onChange={handleChangeType}
                    />
                    {!userObj.selectedPartner && error.selectedPartner ? (
                      <label className="text-error">
                        {error.selectedPartner}
                      </label>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {userObj?.user_type?.value === "organizations" ||
              userObj?.user_type?.value === "organization_store" ? (
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <span className="font-bold font-14">
                      {t("Select store")}
                      <span className="text-danger">*</span>
                    </span>
                    <InfoTooltip title={"Select Store"} />
                  </div>
                  <div className="d-flex flex-column gap-1">
                    <Select
                      ref={inputElementStore}
                      noOptionsMessage={() => "Not found"}
                      placeholder={t("Select user sub type")}
                      getOptionLabel={(e) => e.store_name}
                      options={storeListByOrgnazation}
                      isClearable
                      styles={CUSTOM_DROPDOWN_STYLE}
                      value={userObj.selectStore}
                      onChange={handleChangeUserSubType}
                    />
                    {!userObj.selectStore && error.selectStore ? (
                      <label className="text-error">{error.selectStore}</label>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Select role")}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip title={"Select role"} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <Select
                    ref={inputElementRole}
                    noOptionsMessage={() => "Not found"}
                    placeholder={t("Select role")}
                    getOptionLabel={(e) => e.role_name}
                    options={rolesState}
                    isClearable
                    styles={CUSTOM_DROPDOWN_STYLE}
                    value={userObj.selectRole}
                    onChange={handleChangeSelectRole}
                    isDisabled={!userObj.user_type}
                  />
                  {!userObj.selectRole && error.selectRole ? (
                    <label className="text-error">{error.selectRole}</label>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("User name")}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip title={"Enter User name"} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <InputField
                    inputRef={inputElementUserName}
                    placeholder={t("Please enter user name")}
                    name="userName"
                    onChange={handleInputChange}
                  />
                  {!fields.userName && error.userName ? (
                    <label className="text-error">{error.userName}</label>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("First name")}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip title={"Enter First name"} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <InputField
                  inputRef={inputElementFirstName}
                    placeholder={t("Please enter first name")}
                    name="firstName"
                    onChange={handleInputChange}
                  />
                  {!fields.firstName && error.firstName ? (
                    <label className="text-error">{error.firstName}</label>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Last name")}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip title={"Enter Last name"} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <InputField
                  inputRef={inputElementLastName}
                    placeholder={t("Please enter last name")}
                    name="lastName"
                    onChange={handleInputChange}
                  />
                  {!fields.lastName && error.lastName ? (
                    <label className="text-error">{error.lastName}</label>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Email")}
                    <span className="text-danger">*</span>
                    <span className="font-bold font-14 text-muted">
                      {t("(This will also be used for OTP Authentication)")}
                    </span>
                  </span>
                  <InfoTooltip title={"Enter Email"} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <InputField
                  inputRef={inputElementEmail}
                    placeholder={t("Please enter email id")}
                    name="email"
                    onChange={handleInputChange}
                  />
                  {error.email ? (
                    <label className="text-error">{error.email}</label>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">{t("Contact no")}</span>
                  <InfoTooltip title={"Enter Contact no"} />
                </div>
                <div className="d-flex flex-column gap-1">
                  <InputField
                    placeholder={t("Please enter contact number")}
                    name="contact_no"
                    type="number"
                    onChange={handlePhoneNumber}
                  />
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-2">
                  <span className="font-bold font-14">
                    {t("Password")}
                    <span className="text-danger">*</span>
                  </span>
                  <InfoTooltip title={"Enter or generate Password"} />
                </div>
                <div className="input-group  position-relative">
                  <InputField
                  inputRef={inputElementPassword}
                    type={passShowHide ? "text" : "password"}
                    placeholder={t("Example Sam.01@123")}
                    name="password"
                    value={fields.password}
                    onChange={handleInputChange}
                  />
                  <div
                    className={`input-group-append position-absolute position-absolute top-0 ${
                      props?.lang === "ar" ? "start-0" : "end-0"
                    }`}
                  >
                    <button
                      className="btn  bg-tranparent "
                      type="button"
                      onClick={togglePassword}
                    >
                      {passShowHide ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>
                  </div>
                </div>
                {error.password ? (
                  <label className="text-error">{error.password}</label>
                ) : null}
                <span
                  className="text-primary cursor"
                  onClick={() => generatePassword()}
                >
                  {t("Generate password")}
                </span>
              </div>

              {/* <div className="d-flex flex-row justify-content-between mb-2 border-bottom gap-3">
            <div className="d-flex flex-row align-items-center gap-2">
              <span className="font-bold font-13">
                Send notifications to user(Email will also be used for OTP
                authentication)<span className="text-danger">*</span>
              </span>
              <InfoTooltip
                title={"Maximum 100 characters. No HTML or emoji allowed"}
              />
            </div>
            <ReactSwitchButton
              onChange={changeActivate}
              value={switchButton.activate}
            />
          </div> */}

              <div className="d-flex ">
                <Button
                  onClick={() => submit()}
                  text={t("Create")}
                  type="primary"
                />
              </div>
            </div>
            <div
              className="position-fixed bottom-0 start-50 translate-middle-x w-100 "
              id="liveToast"
            >
              <SignInToast
                position={"bottom-center"}
                showToast={showToast}
                toggleShowToast={toggleShowToast}
                warningMsg={message}
                successMsg={message}
                imageType={"warning"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <h5 className="text-center">
            <PulseLoader type={"data"} />
          </h5>
        </div>
      )}
    </>
  );
};

export default CreateAdminuser;
