/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { ReactComponent as SuccessIcon } from "../../../assests/svg/Success.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const RedeemSuccess = () => {
  const { t } = useTranslation();
  const reedemGiftcardResponse = useSelector((state) => state.eposState?.reedemGiftcardResponse);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!reedemGiftcardResponse?.id) {
      history("/epos/ehadaya-interface");
    }
  }, [reedemGiftcardResponse]);


  const history = useNavigate();

  const goToHome = () => {
    history("/epos/ehadaya-interface");
  };
  return (
    <div className="d-flex flex-column  align-item-center bg-light w-100 py-5">
      <div className="d-flex flex-row w-100 justify-content-center">
        <SuccessIcon />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-dark font-bold font-40">
          {t("Gift card redeemed successfully")}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <span className="text-dark font-bold font-15 ">
          {t("Invoice ID")}: {reedemGiftcardResponse?.id}
        </span>
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"primary"} text={"Download Invoice & Print"} />
      </div>
      <div className="d-flex flex-row w-100 justify-content-center mt-2">
        <Button type={"rounded"} text={t("Back to home")} onClick={() => goToHome()} />
      </div>
    </div>
  );
};
export default RedeemSuccess;
