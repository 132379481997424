import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../../components/Button/Button";
import GoogleIncIcon from "../../../assests/img/GoogleInc.png";
import { ReactComponent as Tag } from "../../../assests/svg/Tag/blue_tag.svg";
import { ReactComponent as MailIcon } from "../../../assests/svg/mail.svg";
import { ReactComponent as GreenTag } from "../../../assests/svg/Tag/dark_green.svg";
import { useSelector, useDispatch } from "react-redux";
import { getPratnerFloats } from "../../../Redux/Float/floatAction";
import { useTranslation } from "react-i18next";

const GoogleIncCompo = (props) => {
  const { t } = useTranslation();
  const { float, floatFlag, prepaidClient, floatCurrencyNotMapped } = props;

  const history = useNavigate();
  const merchandiseDistributionData = useSelector(
    (state) => state?.createOrder?.distributionOfMerchandise
  );
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addFloat = () => {
    if (floatFlag === true) {
      history("/float/addfloat-details");
    }
  };

  return (
    <div className="card-body d-flex flex-column justify-content-between bg-white shadow-sm rounded-3 mb-2 gap-3">
      <div className="d-flex flex-row gap-3 py-2  align-items-center">
        <div className="d-flex flex-grow-1">
          <div className="d-flex flex-column gap-3 mb-1  py-2  px-2 align-items-center">
            <div className="d-flex flex-row mx-2 gap-4 justify-content-between">
              <img
                src={GoogleIncIcon}
                alt="not found"
                className="img-fluid rounded-3 "
              />
              <div>
                <span className="font-22 font-boldest text-dark ">
                  {merchandiseDistributionData?.selectedClient?.partner_name}
                </span>
                <br />
                <span className="font-15 font-bold">{t("Client ID")} # </span>
                <span className="font-13 font-bold text-muted">
                  {/* Client ID #54123 */}
                  {merchandiseDistributionData?.selectedClient?.id}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-3 mb-1  mx-2 align-items-end">
         {floatFlag&& <div className="d-flex">
            <Button
              type="rounded"
              text={t("+ Top up")}
              className="rounded-5 my-4 mt-6 fw-bolder"
              onClick={addFloat}
            />
          </div>}
          <span className="font-14 font-bold">
            <MailIcon /> {merchandiseDistributionData?.selectedClient?.email}
          </span>
          {prepaidClient === false && (
            <span className="font-14 font-bold text-pink">{t("PostPaid Client")}</span>
          )}
        </div>
      </div>

      <div className="d-flex flex-row gap-4">
        {floatFlag === true &&  floatCurrencyNotMapped === false && (
          <div className="d-flex gap-3 my-4 px-4 align-items-center">
            <GreenTag />
            <span className="font-20 font-bold">
              {t("Available Float Balance")}:
              <span className="font-20 font-bold text-pink ">
                {" "}
                {float?.country?.currency_symbol}
                {float?.balance}{" "}
              </span>
            </span>
          </div>
        )}
        {floatCurrencyNotMapped === true && (
          <div className="d-flex gap-3 my-4 px-4 align-items-center">
            <GreenTag />
            <span className="font-20 font-bold">
            {t("This client has not usd float balance")}
              {/* <span className="font-20 font-bold text-pink ">
                {" "}
                {float?.country?.currency_symbol}
                {float?.balance}{" "}
              </span> */}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default GoogleIncCompo;
