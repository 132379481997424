/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import OrganizationInfo from "../../../components/Organization/OrganizationInfo";
import StoreList from "../../../components/Organization/StoreList";
import OrganizationTransaction from "../../../components/Organization/OrganizationTransaction";
import OrganizationMappedGiftcard from "../../../components/Organization/OrganizationMappedGiftcard";
import PartnerDetailProductTableHeader from "../../../components/PartnerDetailProductTable/PartnerDetailProductTableHeader";
import OrganizationDetailGiftcardTable from "../../../components/PartnerDetailProductTable/OrganizationProductTable/OrganizationDetailGiftcardTable";
import { useTranslation } from "react-i18next";

const OrganizationDetails = (props) => {
  const history = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [tabs, setTabs] = useState("stores");

  const [permissions, setpermissions] = useState();
  const [prductFilterValue, setProductFilterValue] = useState({
    name: "Giftcard",
  });
  const [searchValue, setSearchValue] = useState("");

  const productFilterValueGethandler = (filterValue) => {
    setProductFilterValue(filterValue);
  };

  const getSearchValue = (SearchValue) => {
    setSearchValue(SearchValue);
  };

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [localStorage.getItem("permissions")]);

  useEffect(() => {

  }, [permissions]);

  const handleChangeTab = (tab) => {
    if (tab === "orders") {
      setTabs(tab);
    } else if (tab === "stores") {
      setTabs(tab);
    } else if (tab === "allProducts") {
      setTabs(tab);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(getStoreListMapToOrganization(location.state.selectedOrganization.id));
  }, []);
  // go to store creation
  const creatNewstore = () => {
    history("/store-creation");
  };

  const goToEditMappingScreen = () => {
    history("/organization-mapping-giftcard-edit", {
      state: {
        partnerId: location?.state?.selectedOrganization?.id,
        selectedOrganization: location?.state?.selectedOrganization
      }
    })
  }

  return (
    <div className="d-flex flex-column justify-content-between gap-3 py-3 px-4 bg-light">
      {permissions?.all_organization?.create ? (
        <div className="d-flex flex-row justify-content-end align-items-center w-100">
          <div>
            <Button
              type="primary"
              text={t("+Create Store")}
              onClick={() => creatNewstore()}
            />
          </div>
        </div>
      ) : null}

      <OrganizationInfo
        selectedOrganization={location.state.selectedOrganization}
      />

      <div className="d-flex flex-row w-100  align-items-center">
        <div className="d-flex flex-grow-1">
          <div className="d-flex flex-start gap-3 mb-1 align-items-center">
            {/* <div className="">
              <Button
                type={tabs==="allTransactions" ? "primary" : "rounded"}
                text="All transactions"
                className="rounded-5 my-4 mt-6 fw-bolder"
                onClick={() => setTabs("allTransactions")}
              />
            </div> */}

            <div className="">
              <Button
                type={tabs === "stores" ? "primary" : "rounded"}
                text={t("Stores")}
                className="rounded-6 my-4 mt-6 fw-bold"
                onClick={() => handleChangeTab("stores")}
              />
            </div>
            <div className="">
              <Button
                type={tabs === "orders" ? "primary" : "rounded"}
                text={t("Orders")}
                className="rounded-6 my-4 mt-6 fw-bold"
                onClick={() => handleChangeTab("orders")}
              />
            </div>
            <div className="">
              <Button
                type={tabs === "allProducts" ? "primary" : "rounded"}
                text={t("All products")}
                className="rounded-6 my-4 mt-6 fw-bold"
                onClick={() => handleChangeTab("allProducts")}
              />
            </div>
          </div>
        </div>
      </div>
      {/*  <MappedGiftCards />*/}
      {/* {tabs === "stores" ? (
        <StoreList selectedOrganization={location.state.selectedOrganization}/>
       
      )  : (
      
      <OrganizationTransaction selectedClient={location.state.selectedOrganization}/>
      )}*/}

      {tabs === "stores" ? (
        <StoreList lang={props?.lang} selectedOrganization={location.state.selectedOrganization} />
      ) : tabs === "orders" ? (
        <OrganizationTransaction
          lang={props?.lang}
          selectedClient={location.state.selectedOrganization}
        />
      ) : tabs === "allProducts" ? (
        // <OrganizationMappedGiftcard
        //   selectedOrganization={location.state.selectedOrganization}
        // />
        <div className="d-flex flex-column bg-white shadow-sm rounded-3 py-3 px-4 w-100">
          <PartnerDetailProductTableHeader
            lang={props?.lang}
            productFilterValueGethandler={productFilterValueGethandler}
            getSearchValue={getSearchValue}
            disabled={true}
            goToEditMappingScreen={goToEditMappingScreen}
          />
          <OrganizationDetailGiftcardTable
            giftcardTitle={t("Giftcard image/title")}
            giftcardId={t("Giftcard id")}
            countryCurrency={t("Country & currency")}
            inventoryType={t("Inventory type")}
            discount={t("Organization discount")}
            partnerId={
              location.state.selectedOrganization &&
              location.state.selectedOrganization?.id
            }
            searchValue={searchValue}
          />
        </div>
      ) : null}
    </div>
  );
};

export default OrganizationDetails;
