/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Layout from "../screens/Layout";
import SigninScr from "../screens/UserSignIn/SignInScr";
import Forgotpass from "../screens/UserSignIn/Forgotpass";
import Dashboard from "../screens/Dashboard/Dashboard";
import ProductDashbord from "../screens/Product/ProductDashbord";
import AddProductNameDes from "../screens/Product/AddProductNameDes";
import AddCategoryAttibutes from "../screens/Product/AddCategoryAttibutes";
import AddProductPricing from "../screens/Product/AddProductPricing";
import AddProductOtherFiles from "../screens/Product/AddProductOtherFiles";
import AddProductTermCondition from "../screens/Product/AddProductTermCondition";
import EditProductScreen from "../screens/Product/EditProductScreen";
import ClientDashboard from "../screens/Client/ClientsDashboard";
import ClientMappingVariants from "../components/Client/ClientMappingVariants";
import SupplierDashboard from "../screens/Supplier/SupplierDashboard";
import MerchandiseDashBoard from "../screens/Merchandise/MerchandiseDashBoard";
import CreateVarient from "../screens/Merchandise/CreateVarient";
import MerchandiseModelPreview from "../components/Models/MerchandiseModelPreview";
import AddVarientImage from "../components/Merchandise/AddVarientImage";
import MerchandiseMapping from "../screens/Merchandise/MerchandiseMapping";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import FloatTransactions from "../screens/Float/FloatTransactions";
import MappingProductToClient from "../components/Client/MappingProductToClient";
import CreateStore from "../screens/DistributionModel/GiftCard/CreateStore";
import MappingSKU from "../components/Supplier/MappingSKU";
import MappingVariants from "../components/Supplier/MappingVariants";
import MappingProdWithClient from "../components/Client/MappingProdWithClient";
import MappingMerchandiseWithPartner from "../screens/Merchandise/MappingMerchandiseWithPartner";
import SortVariantsImages from "../components/Merchandise/SortVariantsImages";
import MerchandiseDistribution from "../screens/DistributionModel/Merchandise/MerchandiseDistribution";
import SelectUser from "../screens/DistributionModel/Merchandise/SelectUser";
import OrderSummary from "../screens/DistributionModel/GiftCard/OrderSummary";
import MerchandiseOrder from "../screens/DistributionModel/Merchandise/MerchandiseOrder";
import ClientUserSelect from "../screens/DistributionModel/Clients/ClientUserSelect";
import ClientGoogleInc from "../screens/DistributionModel/Clients/ClientGoogleInc";
import OrderManagement from "../screens/Order/OrderManagement";
import GiftCardDistribution from "../screens/DistributionModel/GiftCard/GiftCardDistribution";
import CardDistributionProduct from "../screens/DistributionModel/GiftCard/CardDistributionProduct";
import ClientDistributDashBoard from "../screens/DistributionModel/Clients/ClientDistribution";
import MerchantOrderSummary from "../screens/DistributionModel/Merchandise/MerchantOrderSummary";
import GiftCardOrderSuccess from "../screens/DistributionModel/GiftCard/GiftCardOrderSuccess";
import MerchandiseOrderSuccess from "../screens/DistributionModel/Merchandise/MerchandiseOrderSuccess";
import OrderManagementGiftCard from "../screens/Order/GiftCardOrderManagement";
import BrandsDashBoard from "../screens/ProductsAttributes/Brands/BrandsDashBoard";
import AddBrand from "../screens/ProductsAttributes/Brands/AddBrand";
import BrandSuccessFull from "../screens/ProductsAttributes/Brands/BrandSuccessFull";
import AddPatner from "../screens/Partner/AddPatner";
import AddCategory from "../screens/ProductsAttributes/Categories/AddCategory";
import CategorySuccess from "../screens/ProductsAttributes/Categories/CategorySuccess";
import UserProfile from "../screens/UserRole/UserProfile";
import UserRoleDashboard from "../screens/UserRole/UserRoleDashboard";
import BulkUpload from "../screens/BulkUpload/BulkUpload";
import CreateAdminuser from "../screens/UserManagement/CreateAdminuser";
import UserRoles from "../screens/UserManagement/UserRoles";
import Profile from "../screens/UserManagement/Profile";
import Roles from "../screens/UserManagement/Roles";
import AddManualInventory from "../screens/Inventory/AddManualInventory";
import ProductSuccess from "../screens/Product/ProductSuccess";
import SupplierMapSuccess from "../screens/Supplier/SupplierMapSuccess";
import ClientMapSuccess from "../screens/Client/ClientMapSuccess";
import MerchandiseSuccess from "../screens/Merchandise/MerchandiseSuccess";
import ManualInventory from "../screens/Inventory/ManualInventory";
import AddRole from "../screens/UserManagement/AddRole";
import CurrencyToClient from "../screens/Float/CurrencyToClients";
import AddFloatDetail from "../screens/Float/AddFloatDetail";
import PartnerSuccess from "../screens/Partner/PartnerSuccessfull";
import OtpScr from "../screens/UserSignIn/OtpScr";
import Reports from "../screens/ReportsAnalytics/Reports";
import ClientCreation from "../screens/ReportsAnalytics/ClientCreation";
import GiftCardDetails from "../screens/ReportsAnalytics/GiftCardDetails";
import ReportDetails from "../screens/ReportsAnalytics/ReportDetails";
import BalanceDashBoard from "../screens/ReportsAnalytics/BalanceDashBoard";
import AddMerchantNameDes from "../screens/Merchandise/AddMerchantNameDes";
import AddMerchantAttributes from "../screens/Merchandise/AddMerchantAttributes";
import AddMerchantTermCondition from "../screens/Merchandise/AddMerchantTermCondition";
import SelectStore from "../screens/EPOS/RedeemGiftCards/SelectStore";
import RedeemSuccess from "../screens/EPOS/RedeemGiftCards/RedeemSuccessfull";
import SelectStoreModel from "../screens/EPOS/RedeemGiftCards/SelectStoreModel";
import EhadayaInterface from "../screens/EPOS/EhadayaInterface";
import CheckBalance from "../screens/EPOS/CheckBalance/CheckBalance";
import GiftCardBalance from "../screens/EPOS/CheckBalance/GiftCardBalance";
import MappingGiftcardWithSupplier from "../screens/Supplier/MappingGiftcardWithSupplier";
import MappingGiftcardWithClient from "../components/Client/MappingGiftcardWithClient";
import SupplierMappingDashboard from "../screens/MappingProduct/Supplier/SupplierMappingDashboard";
import GiftcardList from "../screens/MappingProduct/Supplier/Giftcard/GiftcardList";
import ClientGiftcardList from "../screens/MappingProduct/Client/Giftcard/ClientGiftcardList";
import MerchandiseList from "../screens/MappingProduct/Supplier/Merchandise/MerchandiseList";
import ClientMappingDashboard from "../screens/MappingProduct/Client/ClientMappingDashboard";
import GiftCardSKU from "../screens/MappingProduct/Supplier/Giftcard/GiftCardSKU";
import CategoryDashboard from "../screens/ProductsAttributes/Categories/CategoryDashboard";
import OrganizationDashboard from "../screens/ProductsAttributes/Organization/OrganizationDashboard";
import AddOrganization from "../screens/ProductsAttributes/Organization/AddOrganization";
import ClientMerchandiseList from "../screens/MappingProduct/Client/Merchandise/ClientMerchandiseList";
import MerchandiseSKU from "../screens/MappingProduct/Supplier/Merchandise/MerchandiseSKU";
import MerchandiseSuppMapping from "../screens/MappingProduct/NoSupplierMap/MerchandiseSuppMapping";
import EditBrand from "../screens/ProductsAttributes/Brands/EditBrand";
import EditCategory from "../screens/ProductsAttributes/Categories/EditCategory";
import EditOrganization from "../screens/ProductsAttributes/Organization/EditOrganization";
import StoresDashboard from "../screens/ProductsAttributes/Store/StoreDashboard";
import EditStore from "../screens/ProductsAttributes/Store/EditStore";
import MerchandiseEditScreen from "../screens/Merchandise/MerchandiseEditScreen";
import SellCard from "../screens/EPOS/Sell/SellCard";
import BuyerSellerInfo from "../screens/EPOS/Sell/BuyerSellerInfo";
import CardSuccessfull from "../screens/EPOS/Sell/CardSuccessfull";
import GiftCardReports from "../screens/EPOS/Reports/GiftCardReports";
import GiftCardTransactions from "../screens/EPOS/Reports/GiftCardTransactions";
import StoreReports from "../screens/EPOS/Reports/StoreReports";
import StoreTransaction from "../screens/EPOS/Reports/StoreTransaction";
import OrganizationSuccess from "../screens/ProductsAttributes/Organization/OrganizationSuccess";
import GiftCardSupplierMapping from "../screens/MappingProduct/Supplier/Giftcard/GiftCardSupplierMapping";
import SupplierDetails from "../screens/Supplier/SupplierDetails";
import ManualInventorySuccess from "../screens/Inventory/ManualInventorySuccess";
import FloatSuccess from "../screens/Float/FloatSuccess";
import ClientDetails from "../screens/Client/ClientDetails";
import EditCategorySuccess from "../screens/ProductsAttributes/Categories/EditCategorySuccess";
import EditBrandSuccess from "../screens/ProductsAttributes/Brands/EditBrandSuccess";
import EditPartner from "../screens/Partner/EditPartner";
import CardDistributionProductforStore from "../screens/DistributionModel/GiftCard/CardDistributionProductforStore";
import Organization from "../screens/MappingProduct/Organization/Organization";
import OrganizationGiftCardList from "../screens/MappingProduct/Organization/Giftcard/OrganizationGiftCardList";
import GiftCardSKUOrganization from "../screens/MappingProduct/Organization/Giftcard/GiftCardSKUOrganization";
import Email from "../screens/Email/Email";
import OrganizationMerchandiseList from "../screens/MappingProduct/Organization/Merchandise/OrganizationMerchandiseList";
import OrganizationSKU from "../screens/MappingProduct/Organization/Merchandise/OrganizationSKU";
import OrganizationDetails from "../screens/ProductsAttributes/Organization/OrganizationDetails";
import AllFloatTransactions from "../components/Float/AllFloatTransactions";
import ProductEditSuccess from "../screens/Product/ProductEditSuccess";
import StoreSuccess from "../screens/DistributionModel/GiftCard/StoreSuccess";
import UserSuccess from "../screens/UserManagement/UserSuccess";
import RoleSuccess from "../screens/UserManagement/RoleSuccess";
// import ErrorScreen from "../components/ErrorScreen/ErrorScreen";
// import AdminUserCreation from "../screens/UserRole/AdminUserCreation";
// import BulkDistribution from "../screens/DistributionModel/BulkDistribution/AddBulkDistribution";
// import Permission from "../screens/UserManagement/Permission";
// import MerchandiseClientMapp from "../screens/MappingProduct/NoSupplierMap/MerchandiseClientMapp";
// import DeleteLanguage from "../components/Setting/DeleteLanguage";
import ClientDashboardDetails from "../screens/Dashboard/ClientDashboardDetails";
import SupplierDashboardDetails from "../screens/Dashboard/SupplierDashboardDetails";
import StoreDashboard from "../screens/Dashboard/StoreDashboard";
import MerchandiseEditSuccess from "../screens/Merchandise/MerchandiseEditSuccess";
import BulkDistributioDashboard from "../screens/DistributionModel/BulkDistribution/BulkDistributioDashboard";
import AddBulkDistribution from "../screens/DistributionModel/BulkDistribution/AddBulkDistribution";
import UserEdit from "../components/UserRole/UserEdit";
import AddLanguange from "../components/Setting/AddLanguange";
import EditRole from "../screens/UserManagement/EditRole";
import UpdateUserRoleSuccess from "../screens/UserManagement/UpdateUserRoleSuccess";
import LanguageDashboard from "../components/Setting/LanguageDashboard";
import EditLanguage from "../components/Setting/EditLanguage";
import LanguageSuccess from "../components/Setting/LanguageSuccess";
import UserUpdateSuccess from "../screens/UserManagement/UserUpdateSuccess";
import PatchLanguageSuccess from "../components/Setting/PatchLanguageSuccess";
import PartnerCategoryDashboard from "../components/Setting/PartnerCategory/PartnerCategoryDashboard";
import AddPartnerCategory from "../components/Setting/PartnerCategory/AddPartnerCategory";
import PartnerCategoryEdit from "../components/Setting/PartnerCategory/PartnerCategoryEdit";
import PartnerCategorySuccess from "../components/Setting/PartnerCategory/PartnerCategorySuccess";
import PartnerCategoryEditSuccess from "../components/Setting/PartnerCategory/PartnerCategoryEditSuccess";
import OrganizationDashboardDetails from "../screens/Dashboard/OrganizationDashboardDetails";
import CurrencyToOrganization from "../screens/Float/OrganizationFloat/CurrencyToOrganization";
import AddGiftcardNumber from "../screens/EPOS/ActivationGiftcard/AddGiftcardNumber";
import GiftcardActivation from "../screens/EPOS/ActivationGiftcard/GiftcardActivation";
import AddGiftcardNumerForTopUp from "../screens/EPOS/TopupGiftcard/AddGiftcardNumerForTopUp";
import GiftcardTopUpActivation from "../screens/EPOS/TopupGiftcard/GiftcardTopUpActivation";
import AddFloatOrg from "../screens/Float/OrganizationFloat/AddFloatOrg";
import GiftCardSKUEditMapping from "../screens/MappingProduct/Supplier/Giftcard/GiftCardSKUEditMapping";
import EditMappingGiftcardWithClient from "../components/Client/EditMappingGiftcardWithClient";
import EditGiftCardSKUOrganization from "../screens/MappingProduct/Organization/Giftcard/EditGiftCardSKUOrganization";
import MerchandiseSKUEditMapping from "../screens/MappingProduct/Supplier/Merchandise/MerchandiseSKUEditMapping";
import EditMappingProdWithClient from "../components/Client/EditMappingProdWithClient";
import MerchandiseVariantsEditScreen from "../screens/Merchandise/MerchandiseVariantsEditScreen";
import MerchandiseBulkUploadSuccess from "../components/BulkUpload/MerchandiseBulkUploadSuccess";
import GiftcardBulkUploadSuccess from "../components/BulkUpload/GiftcardBulkUploadSuccess";

const Routing = () => {
  const [auth, setAuth] = useState(localStorage.getItem("loggedIn"));

  const [permissions, setpermissions] = useState()
  const [usertype, setusertype] = useState()
  const [lang, setLang] = useState()

  useEffect(() => {
    let user = localStorage.getItem("loggedIn");
    user && JSON.parse(user) ? setAuth(true) : setAuth(false);
  }, []);

  useEffect(() => {
    setpermissions(JSON.parse(localStorage.getItem("permissions")));
    setusertype(JSON.parse(localStorage.getItem("user")));
    setLang(localStorage.getItem("i18nextLng"));

  }, [localStorage.getItem("permissions"), localStorage.getItem("i18nextLng")]);


  useEffect(() => {
    let user = localStorage.getItem("loggedIn");
    user && JSON.parse(user) ? setAuth(true) : setAuth(false);
  }, [auth]);



  const handleLogout = () => {
    localStorage.clear();
    setAuth(false);
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* login */}
        {!auth ? (
          <Route element={<PublicRoutes />}>
            <Route path="/" element={<SigninScr />} />
            <Route path="/otp" element={<OtpScr />} />
            <Route path="/forget-pass" exact element={<Forgotpass />} />
          </Route>
        ) : (
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/" element={<Layout lang={lang} logout={() => handleLogout()} />}>
              {/* Dashboard */}
              {
                permissions?.client_dashboard?.access ?
                  <Route path="/" index element={<ClientDashboardDetails />} />
                  :
                  permissions?.supplier_dashboard?.access ?
                    <Route path="/" index element={<SupplierDashboardDetails lang={lang} />} />
                    :
                    permissions?.organization_dashboard?.access ?
                      <Route path="/" index element={<OrganizationDashboardDetails />} />
                      :
                      permissions?.store_dashboard?.access ?
                        <Route path="/" index element={<StoreDashboard />} />
                        :
                        <Route path="/" index element={<Dashboard lang={lang} />} />
              }

              {/* product */}
              {
                permissions?.products_module?.access ?
                  <>
                    {/* giftcard creation */}
                    {permissions?.giftcard?.read ? <Route path="/product-dashboard" exact element={<ProductDashbord lang={lang} />} /> : null}

                    {permissions?.giftcard?.create ?
                      <>
                        <Route path="/product-creation/name-description" exact element={<AddProductNameDes lang={lang} />} />
                        <Route path="/product-creation/category-attibutes" exact element={<AddCategoryAttibutes lang={lang} />} />
                        <Route path="/product-creation/pricing" exact element={<AddProductPricing lang={lang} />} />
                        <Route path="/product-creation/other-files" exact element={<AddProductOtherFiles />} />
                        <Route path="/product-creation/term-condition" exact element={<AddProductTermCondition lang={lang} />} />
                        <Route path="/product-edit" exact element={<EditProductScreen lang={lang} />} />
                        <Route path="/product-successfull" exact element={<ProductSuccess />} />
                        <Route path="/product-edit-successfull" exact element={<ProductEditSuccess />} />
                      </>
                      : null}

                    {/* merchandise dashboard*/}
                    {permissions?.merchandise?.read ? <Route path="/merchandise-dashboard" exact element={<MerchandiseDashBoard lang={lang} />} /> : null}

                    {/* merchandise creation */}
                    {permissions?.merchandise?.create ?
                      <>
                        <Route path="/add-merchandise" exact element={<AddMerchantNameDes lang={lang} />} />
                        <Route path="/merchandise-attribute" exact element={<AddMerchantAttributes lang={lang} />} />
                        <Route path="/merchandise/term-condition" exact element={<AddMerchantTermCondition lang={lang} />} />
                        <Route path="/add-variant" exact element={<CreateVarient />} />I
                        <Route path="/add-variant/term-condition" exact element={<MerchandiseModelPreview />} />
                        <Route path="/sort-variant-images/sort-variant" exact element={<SortVariantsImages />} />
                        <Route path="/add-variant/merchandise-mapping" exact element={<MerchandiseMapping lang={lang} />} />
                        <Route path="/merchandise-successfull" exact element={<MerchandiseSuccess />} />
                        <Route path="/merchandise-edit" extact element={<MerchandiseEditScreen lang={lang} />} />
                        <Route path="/merchandise-variants-edit" extact element={<MerchandiseVariantsEditScreen lang={lang} />} />
                        <Route path="/merchandise-edit-successfull" extact element={<MerchandiseEditSuccess />} />
                        <Route path="/add-variant/add-variant-image" exact element={<AddVarientImage />} />

                      </> : null}


                    {/* manual inventory dashboard*/}
                    {permissions?.manual_inventory?.read ? <Route path="/manual-inventory" exact element={<ManualInventory lang={lang} />} /> : null}

                    {/* manual inventory */}
                    {permissions?.manual_inventory?.create ?
                      <>
                        <Route path="/add-manual-inventory" exact element={<AddManualInventory />} />
                        <Route path="manual-inventory-success" exact element={<ManualInventorySuccess />} />
                      </> : null}
                  </>
                  : null
              }

              {/* distribution module */}
              {permissions?.distribution_module?.access ?
                <>
                  {/* giftCard distribution */}
                  {permissions?.giftcard_distribution?.create ?
                    <>
                      <Route path="/gift-card-distribution" exact element={<GiftCardDistribution />} />
                      <Route path="/card-distribution" exact element={<CardDistributionProduct />} />
                      <Route path="/card-distribution-store" exact element={<CardDistributionProductforStore />} />
                      <Route path="/order-summary" exact element={<OrderSummary />} />
                      <Route path="/giftcard-order-success" exact element={<GiftCardOrderSuccess />} />
                      <Route path="/product-edit" exact element={<EditProductScreen />} />
                    </> : null}


                  {/* merchandise distribution */}
                  {permissions?.merchandise_distribution?.create ?
                    <>
                      <Route path="/merchandise-distribution" exact element={<MerchandiseDistribution lang={lang} />} />
                      <Route path="/merchandise/select-user" exact element={<SelectUser />} />
                      <Route path="/merchandise-order" exact element={<MerchandiseOrder lang={lang} />} />
                      <Route path="/merchandise/order-summary" exact element={<MerchantOrderSummary />} />
                      <Route path="/merchandise/order-success" exact element={<MerchandiseOrderSuccess />} />
                    </> : null}


                  {/* client distribution */}
                  {permissions?.giftcard_distribution?.create || permissions?.merchandise_distribution?.create ?
                    <>
                      <Route path="/client-distribution-dashboard" exact element={<ClientDistributDashBoard />} />
                    </>
                    : null}


                  {/* bulk distribution */}
                  <Route path="/add-bulk-distribution" exact element={<AddBulkDistribution />} />
                  <Route path="/bulk-distribution" exact element={<BulkDistributioDashboard lang={lang} />} />
                </> : null}
              {/* END distribution module */}

              {/* Bulk Upload */}
              <Route path="/bulk-upload" exact element={<BulkUpload lang={lang} />} />
              <Route path="/bulk-upload/merchandise-success" exact element={<MerchandiseBulkUploadSuccess lang={lang} />} />
              <Route path="/bulk-upload/giftcard-success" exact element={<GiftcardBulkUploadSuccess lang={lang} />} />

              {/* Product Attribute */}
              {permissions?.products_attribute_module?.access ?
                <>
                  {/* category dashboard */}
                  {permissions?.brand_category?.read ? <Route path="/category-dashboard" exact element={<CategoryDashboard lang={lang} />} /> : null}


                  {/* category creation */}
                  {permissions?.brand_category?.create ?
                    <>
                      <Route path="/category-creation" exact element={<AddCategory lang={lang} />} />
                      <Route path="/category-create-success" exact element={<CategorySuccess />} />
                      <Route path="/category-edit" exact element={<EditCategory lang={lang} />} />
                      <Route path="/category-edit-success" exact element={<EditCategorySuccess />} />
                    </>
                    : null}

                  {/* brand dashboard*/}
                  {permissions?.brand?.read ? <Route path="/brand-dashboard" exact element={<BrandsDashBoard lang={lang} />} /> : null}

                  {/* brand creation */}
                  {permissions?.brand?.create ?
                    <>
                      <Route path="/brand-creation" exact element={<AddBrand lang={lang} />} />
                      <Route path="/brand-create-success" exact element={<BrandSuccessFull />} />
                      <Route path="/brand-edit" exact element={<EditBrand lang={lang} />} />
                      <Route path="/brand-edit-success" exact element={<EditBrandSuccess />} />
                    </>
                    : null}
                </> : null}

              {/* Manage Users Module*/}
              {permissions?.manage_users_module?.access && usertype?.user_type === "internal" ?
                <>
                  {/* user role */}
                  {/* {permissions?.user_role?.crea} */}
                  {/* <Route path="/user-admin-creation" exact element={<AdminUserCreation />} /> */}
                  <Route path="/user-profile" exact element={<UserProfile />} />

                  {/* user management */}

                  {permissions?.user?.read ?
                    <>
                      <Route path="/users-management/users" exact element={<UserRoles lang={lang} />} />
                    </> : null}

                  {/* user creation */}
                  {permissions?.user?.create ?
                    <>
                      <Route path="/roles-management/user-creation" exact element={<CreateAdminuser lang={lang} />} />
                      <Route path="/users-management/users-edit" exact element={<UserEdit />} />
                      <Route path="/users/creation-success" exact element={<UserSuccess />} />
                      <Route path="/users/updation-success" exact element={<UserUpdateSuccess />} />
                    </> : null}

                  {permissions?.user_role?.read ?
                    <>
                      <Route path="/users-management/roles" exact element={<Roles lang={lang} />} />
                      <Route path="/user-roles" exact element={<UserRoleDashboard />} />
                      <Route path="/roles-management/user-edit" exact element={<Profile />} />
                      <Route path="/roles/creation-success" exact element={<RoleSuccess />} />
                      <Route path="/roles/update-success" exact element={<UpdateUserRoleSuccess />} />

                    </> : null}

                  {/* role creation */}
                  {permissions?.user_role?.create ?
                    <>
                      <Route path="/users-management/add-role" exact element={<AddRole />} />
                      <Route path="/users-management/edit-role" exact element={<EditRole />} />
                    </>
                    : null}


                  {/* Permission */}
                  {/* <Route path="/management/permissions" exact element={<Permission />} /> */}
                </> : null}
              {/* END Manage Users Module*/}

              {/* Order Management Module */}
              {permissions?.order_management_module?.access && usertype?.user_type === "internal" ?
                <>
                  {/* order dashboard*/}
                  {permissions?.all_orders?.read ?
                    <>
                      <Route path="/order-management/dashboard" exact element={<OrderManagement lang={lang} />} />
                      {/* order details */}
                      <Route path="/order-management/gift-card" exact element={<OrderManagementGiftCard />} />
                    </> : null}
                </> : null}
              {/* END Order Management Module */}

              {/* Float Management Module */}
              {permissions?.float_management_module?.access && usertype?.user_type === "internal" ?
                <>
                  {/* float dashboard*/}
                  {permissions?.all_floats?.read ? <Route path="/float/float-transactions" exact element={<FloatTransactions lang={lang} />} /> : null}


                  {/* add float */}
                  {permissions?.all_floats?.create ?
                    <>
                      <Route path="/float/all-transactions" exact element={<AllFloatTransactions />} />
                      <Route path="/float/currency-mapping/to-client" exact element={<CurrencyToClient />} />
                      <Route path="/float/addfloat-details" exact element={<AddFloatDetail lang={lang} />} />
                      <Route path="/float-map-success" exact element={<FloatSuccess />} />
                      <Route path="/float/currency-mapping/to-organization" exact element={<CurrencyToOrganization />} />
                      <Route path="/float/addfloat-org-details" exact element={<AddFloatOrg lang={lang} />} />
                    </>
                    : null}
                </> : null}

              {/* Pertners Module */}
              {permissions?.partners_module?.access && usertype?.user_type === "internal" ?
                <>
                  {/* patner creation*/}
                  {permissions?.all_suppliers?.create || permissions?.all_clients?.create || permissions?.all_organization?.create ?
                    <>
                      <Route path="/partner-creation" exact element={<AddPatner lang={lang} />} />
                      <Route path="/partner-successfull" exact element={<PartnerSuccess />} />
                      <Route path="/edit-partner" exact element={<EditPartner lang={lang} />} />
                    </> : null}

                  {/* supplier */}
                  {permissions?.all_suppliers?.read ?
                    <>
                      <Route path="/supplier-mapping-dashboard" exact element={<SupplierMappingDashboard lang={lang} />} />
                      <Route path="/supplier-details" exact element={<SupplierDetails lang={lang} />} />
                    </> : null}
                  {permissions?.all_suppliers?.create ?
                    <>
                      <Route path="/supplier-mapping-giftcard" exact element={<GiftcardList lang={lang} />} />
                      <Route path="/supplier-mapping-giftcard-sku" exact element={<GiftCardSKU lang={lang} />} />
                      <Route path="/supplier-mapping-merchandise" exact element={<MerchandiseList lang={lang} />} />
                      <Route path="/supplier-mapping-merchandise-sku" exact element={<MerchandiseSKU lang={lang} />} />
                      <Route path="/supplier-mapping-merchandise-edit" exact element={<MerchandiseSKUEditMapping lang={lang} />} />
                      <Route path="/supplier-map-successfull" exact element={<SupplierMapSuccess />} />
                      {/* no mapping */}
                      <Route path="/supplier-mapping" exact element={<SupplierDashboard />} />
                      <Route path="/supplier-mapping-variants" exact element={<MappingVariants />} />
                      <Route path="/supplier-mapping-sku" exact element={<MappingSKU />} />
                      <Route path="/supplier-mapping-to-giftcard" exact element={<MappingGiftcardWithSupplier />} />
                      <Route path="/supplier-mapping-merchandise-product" exact element={<MerchandiseSuppMapping lang={lang} />} />
                      <Route path="/supplier-mapping-giftcard-product" exact element={<GiftCardSupplierMapping lang={lang} />} />
                      <Route path="/supplier-mapping-giftcard-edit" exact element={<GiftCardSKUEditMapping lang={lang} />} />
                    </> : null}


                  {/* organization dashboard */}
                  {permissions?.all_organization?.read ?
                    <>
                      <Route path="/organization-mapping-dashboard" exact element={<Organization lang={lang} />} />
                      <Route path="/organization-details" exact element={<OrganizationDetails lang={lang} />} />
                    </> : null}


                  {/* organization mapping */}
                  {permissions?.all_organization?.create ?
                    <>
                      <Route path="/organization-mapping-giftcard" exact element={<OrganizationGiftCardList lang={lang} />} />
                      <Route path="/organization-mapping-giftcard-sku" exact element={<GiftCardSKUOrganization lang={lang} />} />
                      <Route path="/organization-mapping-giftcard-edit" exact element={<EditGiftCardSKUOrganization lang={lang} />} />
                      <Route path="/organization-success" exact element={<OrganizationSuccess />} />
                      <Route path="/organization-mapping-merchandise" exact element={<OrganizationMerchandiseList />} />
                      <Route path="/organization-mapping-merchandise-sku" exact element={<OrganizationSKU />} />
                      <Route path="/organization-map-successfull" exact element={<OrganizationSuccess />} />
                    </> : null}


                  {/* clients dashboard*/}
                  {permissions?.all_clients?.read ?
                    <>
                      <Route path="/clients-mapping-dashboard" exact element={<ClientMappingDashboard lang={lang} />} />
                      <Route path="/clients-details" exact element={<ClientDetails lang={lang} />} />
                    </>
                    : null}

                  {/* clients mapping */}
                  {permissions?.all_clients?.create ?
                    <>
                      <Route path="/clients-mapping-giftcards" exact element={<ClientGiftcardList lang={lang} />} />
                      <Route path="/clients-mapping-giftcard-with-client" exact element={<MappingGiftcardWithClient lang={lang} />} />
                      <Route path="/clients-mapping-merchandise-list" exact element={<ClientMerchandiseList lang={lang} />} />
                      <Route path="/clients-mapping-product" exact element={<MappingProdWithClient lang={lang} />} />
                      <Route path="/clients-mapping-merchandise-edit" exact element={<EditMappingProdWithClient lang={lang} />} />
                      <Route path="/clients-dashboard" exact element={<ClientDashboard />} />
                      <Route path="/clients-mapping-variants" exact element={<ClientMappingVariants />} />
                      <Route path="/products-mapping-to-client" exact element={<MappingProductToClient />} />
                      <Route path="/clients-map-successfull" exact element={<ClientMapSuccess />} />
                      <Route path="/clients-mapping-giftcard-edit" exact element={<EditMappingGiftcardWithClient lang={lang} />} />
                    </> : null}
                  {/* no screens */}
                  <Route path="/client-user-select" exact element={<ClientUserSelect />} />
                  <Route path="/client-google-inc" exact element={<ClientGoogleInc />} />

                  {/* no merchandise screens */}
                  <Route path="/merchandise-mapping-partner" exact element={<MappingMerchandiseWithPartner />} />

                  {/* Organization dashboard*/}
                  {permissions?.all_organization?.read ?
                    <>
                      <Route path="/organization-dashboard" exact element={<OrganizationDashboard />} />
                    </> : null}

                  {/* Organization creation*/}
                  {permissions?.all_organization?.create ?
                    <>
                      <Route path="/organization-creation" exact element={<AddOrganization />} />
                      <Route path="/organization-edit" exact element={<EditOrganization />} />
                      <Route path="/organization-create-success" exact element={<OrganizationSuccess />}
                      />
                      {/* Store dashboard*/}
                      <Route path="/store-dashboard" exact element={<StoresDashboard />} />
                      {/* store creation */}
                      <Route path="/store-creation" exact element={<CreateStore lang={lang} />} />
                      <Route path="/store-creation-success" exact element={<StoreSuccess />} />
                      <Route path="/store-edit" exact element={<EditStore lang={lang} />} />
                    </> : null}
                </> : null}
              {/* END Pertners Module */}

              {/* Reports Module */}
              {permissions?.reports_module?.access ?
                <>
                  {/* reports dashBoard*/}

                  <>
                    <Route path="/reports/balance-dashBoard" exact element={<BalanceDashBoard />} />
                    {/* reports details */}
                    <Route path="/reports/reports-analytics" exact element={<Reports lang={lang} />} />
                    <Route path="/reports/client-creation" exact element={<ClientCreation />} />
                    <Route path="/reports/giftcard-details" exact element={<GiftCardDetails />} />
                    <Route path="/reports/report-details" exact element={<ReportDetails />} />
                  </>
                </> : null}
              {/* END Reports Module */}

              {/* EPOS Module */}
              {permissions?.epos_module?.access ?
                <>
                  {/* epos */}
                  <Route path="/epos/ehadaya-interface" exact element={<EhadayaInterface />} />
                  <Route path="/epos/select-store" exact element={<SelectStore />} />
                  <Route path="/epos/selectstore-model" exact element={<SelectStoreModel lang={lang} />} />
                  <Route path="/epos/redeem-success" exact element={<RedeemSuccess />} />
                  <Route path="/epos/check-balance" exact element={<CheckBalance />} />
                  <Route path="/epos/giftcard-balance" exact element={<GiftCardBalance lang={lang} />} />
                  <Route path="/epos/sell-card" exact element={<SellCard />} />
                  <Route path="/epos/buyer-seller-info" exact element={<BuyerSellerInfo />} />
                  <Route path="/epos/card-successfull" exact element={<CardSuccessfull />} />
                  <Route path="/epos/giftcard-reports" exact element={<GiftCardReports />} />
                  <Route path="/epos/giftcard-transactions" exact element={<GiftCardTransactions />} />
                  <Route path="/epos/store-reports" exact element={<StoreReports />} />
                  <Route path="/epos/store-transaction" exact element={<StoreTransaction />} />
                  <Route path="/epos/activation" exact element={<AddGiftcardNumber />} />
                  <Route path="/epos/activation-giftcard" exact element={<GiftcardActivation lang={lang} />} />
                  <Route path="/epos/topup-giftcard" exact element={<AddGiftcardNumerForTopUp />} />
                  <Route path="/epos/topup-activation-giftcard" exact element={<GiftcardTopUpActivation lang={lang} />} />

                </> : null}

              {/* Setting */}
              {permissions?.settings_module?.access && usertype?.user_type === "internal" ?
                <>
                  <Route path="/language" exact element={<LanguageDashboard lang={lang} />} />
                  <Route path="/add-language" exact element={<AddLanguange />} />
                  <Route path="/edit-language" exact element={<EditLanguage />} />
                  <Route path="/language-creation-success" exact element={<LanguageSuccess />} />
                  <Route path="/language-patch-success" exact element={<PatchLanguageSuccess />} />
                  {/* <Route path="/delete-language" exact element={<DeleteLanguage />} /> */}

                  {/* Partner Category */}
                  <Route path="/partner-category-dashboard" exact element={<PartnerCategoryDashboard lang={lang} />} />
                  <Route path="/add-partner-category" exact element={<AddPartnerCategory />} />
                  <Route path="/edit-partner-category" exact element={<PartnerCategoryEdit />} />
                  <Route path="/partnercategory-creation-success" exact element={<PartnerCategorySuccess />} />
                  <Route path="/partnercategory-patch-success" exact element={<PartnerCategoryEditSuccess />} />
                </>
                : null}
            </Route>

            {/*email */}
            <Route path="/email" exact element={<Email />} />

            <Route path="*" element={<h5>Screen Not Found</h5>} />
          </Route>
        )}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
