import React from "react";
import { ReactComponent as LeftIcon } from "../../assests/svg/Products/left_lg.svg"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = (props) => {
    const history = useNavigate();
    const { t } = useTranslation();
    const { title, steps } = props;

    const goBack = () => {
        history("/product-dashboard")
    }

    return (
        <div className="d-flex flex-row justify-content-between align-items-center py-4 bg-light">
            <div className="d-flex flex-row align-items-center gap-3">
                <LeftIcon onClick={() => goBack()} className="cursor" />
                <span className="font-bold font-32">{t("Giftcard product creation")}</span>
            </div>
            <div className="d-flex flex-row bg-white justify-content-between align-items-center rounded-circle shadow width-45 px-3 py-3">
                <span className="font-bolder font-22">{title}</span>

                <div className="d-flex flex-row align-items-center gap-2">

                    <span className="fw-bold">{t("Step")} {steps} {t("of 5")}</span>

                    <div className={`m-0 count-circle ${steps === 1 ? "step4" : " "} ${steps === 2 ? "step1" : " "} ${steps === 3 ? "step2" : ""} ${steps === 4 ? "step3" : " "} ${steps === 5 ? "" : ""}`}></div>

                </div>
            </div>

        </div>
    );
};

export default Header;
