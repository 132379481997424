import {
  GET_ALL_LANGUAGE_REQUEST,
  GET_ALL_LANGUAGE_SUCCESS,
  GET_ALL_LANGUAGE_FAILURE,
  POST_LANGUAGE_REQUEST,
  POST_LANGUAGE_SUCCESS,
  POST_LANGUAGE_FAILURE,
  PATCH_LANGUAGE_REQUEST,
  PATCH_LANGUAGE_SUCCESS,
  PATCH_LANGUAGE_FAILURE,
  CLEAR_LANGUAGE_LIST
} from "./languageType";

const initialLanguageState = {
  loading: false,
  languages: [],
  languageResponse: null,
  error: "",
};

const languageReducer = (state = initialLanguageState, action) => {
  switch (action.type) {
    case GET_ALL_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        languages: action.payload,
        error: "",
      };
    case GET_ALL_LANGUAGE_FAILURE:
      return {
        ...state,
        loading: false,
        languages: [],
        error: action.payload,
      };
    case CLEAR_LANGUAGE_LIST:
      return {
        ...state,
        loading: false,
        languages: [],
        error: action.payload,
      };
    case POST_LANGUAGE_REQUEST:
      return {
        loading: true,
      };
    case POST_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        languages: [],
        languageResponse: action.payload,
        error: "",
      };
    case POST_LANGUAGE_FAILURE:
      return {
        ...state,
        loading: false,
        languages: [],
        languageResponse: null,
        error: action.payload,
      }
    case PATCH_LANGUAGE_REQUEST:
      return {
        loading: true,
      }
    case PATCH_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        languages: [],
        languageResponse: action.payload,
        error: "",
      }
    case PATCH_LANGUAGE_FAILURE:
      return {
        ...state,
        loading: false,
        languages: [],
        languageResponse: null,
        error: action.payload,
      }
    default:
      return state;
  }
};
export default languageReducer;
