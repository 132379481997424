import {
  GET_CLIENT_MER_LIST_REQUEST,
  GET_CLIENT_MER_LIST_SUCCESS,
  GET_CLIENT_MER_LIST_FAILURE,
  SET_SELECTED_CLIENT
} from "./ClientMerchandiseType";

const initialClientMerchandiseListState = {
  loading: false,
  clientMerchandiseListByID: [],
  selectedClientName: "",
  error: "",
};

const clientMerchandiseListReducer = (state = initialClientMerchandiseListState, action) => {
  switch (action.type) {
    case GET_CLIENT_MER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_MER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clientMerchandiseListByID: action.payload,
        error: "",
      };
    case GET_CLIENT_MER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        clientMerchandiseList: [],
        error: action.payload,
      };
    // SET SELECTED CLIENT
    case SET_SELECTED_CLIENT:
      return {
        ...state,
        loading: false,
        selectedClientName: action.payload,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default clientMerchandiseListReducer;
