import React from "react";
import CardPreview from "../../components/Products/CardPreview";
import Header from "../../components/Products/Header";
import ProductOtherFiles from "../../components/Products/ProductOtherFiles";

const AddProductOtherFiles = () => {

    return (
        <div className="d-flex flex-column justify-content-start py-2 px-4 bg-light">
            <Header steps={4} title={"Other Files"} />
            <div className="d-flex flex-row mt-2 gap-2">
                <div className="width-65" >
                    <ProductOtherFiles />
                </div>
                <div className="width-35" >
                    <CardPreview />

                </div>
            </div>

        </div>
    );
};

export default AddProductOtherFiles;
